import Controller from '@ember/controller';

import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { queryManager } from 'ember-apollo-client';
import query from 'vault-client/graphql/queries/positions/index.graphql';
import searchQuery from 'vault-client/graphql/queries/search.graphql';
import accountFuzzyQuery from 'vault-client/graphql/queries/account-fuzzy-match.graphql';
import { DateTime } from 'luxon';
import getFilterDisplayProperty from 'vault-client/utils/get-filter-display-property';
import { inject as service } from '@ember/service';
import resetVaultTableScroll from 'vault-client/utils/reset-vault-table-scroll';
import instrumentOptionTypes from 'vault-client/utils/instrument-option-types';

import { getOwner } from '@ember/application';
import FuturePosition from 'vault-client/models/future-position';
import OptionPosition from 'vault-client/models/option-position';
import SwapPosition from 'vault-client/models/swap-position';
import SwaptionPosition from 'vault-client/models/swaption-position';
import { ModelFrom } from 'vault-client/utils/type-utils';
import BusinessesBusinessPositionsRoute from 'vault-client/routes/businesses/business/positions';
import OrganizationsOrganizationPositionsRoute from 'vault-client/routes/organizations/organization/positions';
import { gql, useQuery } from 'glimmer-apollo';
import { Query } from 'vault-client/types/graphql-types';

const SALES_CODE_SEARCH = gql`
	query BrokerageAccounts($searchText: String!, $scopeId: String!) {
		BrokerageAccounts(scopeId: $scopeId, where: { salesCode: { contains: $searchText } }) {
			id
			salesCode
		}
	}
`;
export default class PositionsIndexController extends Controller {
	@queryManager apollo: any;
	@service marketData: any;
	@service applicationScope: any;

	declare model: ModelFrom<OrganizationsOrganizationPositionsRoute> | ModelFrom<BusinessesBusinessPositionsRoute>;

	accountRoute: string = '';
	positionDetailRoute: string = '';

	queryParams = [
		'accountId',
		'instrumentId',
		'customerId',
		'productId',
		'salesCode',
		'monthExpiresAtStartDate',
		'monthExpiresAtEndDate',
		'side',
		'page',
		'sorts',
		'showClosedPositions',
		'size',
		'selectedInstrumentOptionTypes',
	];

	itemsFn = (rows: any) => {
		const owner = getOwner(this);

		return rows.map((row: any) => {
			if (row.Instrument?.__typename === 'Future') {
				return new FuturePosition(owner, row);
			}

			if (row.Instrument?.__typename === 'Option') {
				return new OptionPosition(owner, row);
			}

			if (row.Instrument?.__typename === 'Swap') {
				return new SwapPosition(owner, row);
			}

			if (row.Instrument?.__typename === 'Swaption') {
				return new SwaptionPosition(owner, row);
			}
			return;
		});
	};

	monthExpiresAtRangeOptions = [
		{
			displayName: 'All Months',
			startDate: '1900-01-01',
			endDate: '2999-12-31',
		},
		{
			displayName: 'Active Months',
			startDate: DateTime.now().toISODate(),
		},
		{
			displayName: 'Last Month',
			startDate: DateTime.local().minus({ months: 1 }).startOf('month').toISODate(),
			endDate: DateTime.local().minus({ months: 1 }).endOf('month').toISODate(),
		},
		{
			displayName: 'This Month',
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().endOf('month').toISODate(),
		},
		{
			displayName: 'Next Month',
			startDate: DateTime.local().plus({ months: 1 }).startOf('month').toISODate(),
			endDate: DateTime.local().plus({ months: 1 }).endOf('month').toISODate(),
		},
	];

	@tracked accountId: string | undefined;
	@tracked productId: string | undefined;
	@tracked instrumentId: string | undefined;
	@tracked salesCode: string | undefined;
	@tracked instrumentType: any = null;
	@tracked optionType = null;
	@tracked side = null;
	@tracked showClosedPositions = false;
	@tracked monthExpiresAtStartDate: string = DateTime.now().toISODate();
	@tracked monthExpiresAtEndDate: string | undefined;
	@tracked page = 0;
	@tracked sorts = [
		{ valuePath: 'displayExpiresAt', isAscending: true },
		{ valuePath: 'instrumentType', isAscending: true },
	];
	@tracked selectedInstrumentOptionTypes: Array<{
		name: string;
		instrumentType: string | null;
		optionType: string | null;
	}> = [
		{
			name: 'All',
			instrumentType: null,
			optionType: null,
		},
	];
	@tracked instrumentOptionTypes = instrumentOptionTypes;
	@tracked size = 100;

	get columns() {
		const baseColumns = [
			{
				id: 'a31b3e3d-7e9f-44d3-9f4f-989f8a58b6cb',
				name: 'Account Name',
				valuePath: 'Account.name',
				minWidth: 250,
				cellComponent: 'String',
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: '89953774-6373-49ef-89c1-953dbc338424',
				name: 'Sales Code',
				valuePath: 'Account.salesCode',
				minWidth: 125,
				cellComponent: 'String',
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: '92db1142-063c-496c-808b-d0307c929483',
				name: 'Month',
				valuePath: 'displayExpiresAt',
				minWidth: 100,
				cellComponent: 'MonthFormat',
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '4b5dcd30-d352-4f60-9c1c-22d149ecd7a0',
				name: 'Account',
				valuePath: 'Account.accountNumber',
				minWidth: 100,
				textAlign: 'left',
				isSortable: false,
				cellComponent: 'String',
				isFixed: '',
				isVisible: true,
				linkRoute: this.accountRoute,
				linkModelPath: 'Account.id',
			},
			{
				id: '97619c5e-2bf4-4fc5-a8d4-77e07381b4b8',
				name: 'Quantity',
				valuePath: 'quantityInContracts',
				minWidth: 100,
				textAlign: 'right',
				isSortable: false,
				cellComponent: 'String',
				isFixed: '',
				isVisible: true,
				linkRoute: this.positionDetailRoute,
				linkModelPath: 'positionId',
			},
			{
				id: '007ef7b6-cf8c-4ebc-b6c9-454c95623fa3',
				name: 'ABS Quantity',
				valuePath: 'absQuantityInContracts',
				minWidth: 115,
				textAlign: 'right',
				isSortable: false,
				cellComponent: 'String',
				isFixed: '',
				isVisible: false,
			},
			{
				id: '30caafa0-4121-4a8d-a1a3-74591cc5d24b',
				name: 'Side',
				valuePath: 'side',
				minWidth: 50,
				textAlign: 'right',
				isSortable: false,
				cellComponent: 'String',
				isFixed: '',
				isVisible: true,
			},
			{
				id: '3924e79f-86ec-40fb-9a1d-f4142d346493',
				name: 'Type',
				valuePath: 'instrumentType',
				minWidth: 90,
				textAlign: 'left',
				isSortable: true,
				cellComponent: 'String',
				isFixed: '',
				isVisible: true,
			},
			{
				id: '1d4fe441-8769-4f81-bbb1-5dde7ae1fd49',
				name: 'Symbol',
				valuePath: 'Instrument.exchangeSymbol',
				minWidth: 120,
				textAlign: 'left',
				isSortable: false,
				cellComponent: 'String',
				isFixed: '',
				isVisible: true,
			},
			{
				id: '824d5cd2-f8dd-4af2-93a3-4292d14bcf5a',
				name: 'Commodity',
				valuePath: 'Instrument.Product.name',
				minWidth: 120,
				textAlign: 'left',
				isSortable: false,
				cellComponent: 'String',
				isFixed: '',
				isVisible: true,
			},
			{
				id: '463fdb30-5781-4e61-870e-788a50852a6c',
				name: 'Strike',
				valuePath: 'Instrument.strike',
				minWidth: 100,
				cellComponent: 'PriceFormat',
				componentArgs: {
					fractionDigitsPath: 'Instrument.symbolGroup.fractionDigits',
					displayFactorPath: 'Instrument.symbolGroup.displayFactor',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},

			{
				id: '9f0ccc33-8da0-4fbb-b767-b292ccff6d5b',
				name: 'Avg. Trade Price',
				valuePath: 'currentWeightedAveragePrice',
				minWidth: 140,
				cellComponent: 'PriceFormat',
				componentArgs: {
					fractionDigitsPath: 'Instrument.symbolGroup.fractionDigits',
					displayFactorPath: 'Instrument.symbolGroup.displayFactor',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},

			{
				id: '70a5e84e-7810-4504-adfc-401c3475bfb1',
				name: 'Last Price',
				valuePath: 'marketDataInstrument',
				minWidth: 120,
				cellComponent: 'MarketPriceFormat',
				componentArgs: {
					fractionDigitsPath: 'Instrument.symbolGroup.fractionDigits',
					displayFactorPath: 'Instrument.symbolGroup.displayFactor',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'd6d0e4f0-4c36-4f66-b4b4-2942e1b29a93',
				name: 'Session Change',
				valuePath: 'marketDataInstrument',
				minWidth: 150,
				cellComponent: 'SessionChangeFormat',
				componentArgs: {
					fractionDigitsPath: 'Instrument.symbolGroup.fractionDigits',
					displayFactorPath: 'Instrument.symbolGroup.displayFactor',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'bb34a490-1ad3-43fb-86e6-26515adee7da',
				name: 'Unrealized P/L (EOD)',
				valuePath: 'unrealizedPl',
				minWidth: 180,
				cellComponent: 'IntlNumberFormat',
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '3c213d65-f7d4-4182-88ff-e7c0a801b7e4',
				name: 'Realized P/L (EOD)',
				valuePath: 'realizedPl',
				minWidth: 160,
				cellComponent: 'IntlNumberFormat',
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'b27881f5-3634-4c19-ab45-6befe49a0045',
				name: 'Net P/L (EOD)',
				valuePath: 'netPl',
				minWidth: 120,
				cellComponent: 'IntlNumberFormat',
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
		];
		return baseColumns;
	}

	get sortParams() {
		return this.sorts;
	}

	set sortParams(sorts) {
		this.sorts = sorts;
	}

	get currentPage() {
		return this.page;
	}

	set currentPage(page) {
		this.page = page;
	}

	get positionData() {
		return this.itemsFn(this.model.CurrentPositions);
	}

	get totalNumPositions() {
		return this.model.CurrentPositionCount.count;
	}

	get monthExpiresAtQueryParam() {
		return {
			startDate: this.monthExpiresAtStartDate,
			endDate: this.monthExpiresAtEndDate,
		};
	}

	get searchFilterQueryParams() {
		const obj: {
			[key: string]: any;
		} = {};

		const searchQueryParams = ['customerId', 'accountId', 'instrumentId', 'productId', 'salesCode'];
		searchQueryParams.forEach((param) => {
			//@ts-ignore
			const value = param === 'customerId' && this.applicationScope.globalCustomerId ? this.applicationScope.globalCustomerId : this[param];
			if (value) {
				obj[param] = {
					filterRule: 'equals',
					filterValue: value,
				};

				// set filterComponent property to specify component for custom display extended from search-filter
				const filterDisplayObj = getFilterDisplayProperty(param);
				if (filterDisplayObj && filterDisplayObj.customComponent) {
					obj[param].filterComponent = filterDisplayObj.customComponent;
				}
				// set filterLabel property to specify custom label for filter - default would be filterIdentifier (matches queryParam)
				if (filterDisplayObj && filterDisplayObj.label) {
					obj[param].filterLabel = filterDisplayObj.label;
				}
			}
		});

		return obj;
	}

	get scopeSearchFilterQueryParams() {
		if (this.applicationScope.globalCustomerId) {
			// @ts-ignore
			// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
			const { customerId, ...rest } = this.searchFilterQueryParams;
			return rest;
		} else {
			return this.searchFilterQueryParams;
		}
	}

	get currentInstrumentOptionTypes() {
		return this.instrumentOptionTypes.filter((type) =>
			this.selectedInstrumentOptionTypes.some(
				(selectedType) => selectedType.instrumentType === type.instrumentType && selectedType.optionType === type.optionType
			)
		);
	}

	isInstrumentOptionTypeSelected = (instrumentOptionType: {
		name: string;
		instrumentType: string | null;
		optionType: string | null;
	}): boolean => {
		return this.selectedInstrumentOptionTypes.some(
			(selectedType) =>
				selectedType.instrumentType === instrumentOptionType.instrumentType && selectedType.optionType === instrumentOptionType.optionType
		);
	};

	get selectedInstrumentOptionTypesString(): string {
		const types = this.selectedInstrumentOptionTypes;
		switch (types.length) {
			case 0:
				return 'All';
			case 1:
				return types[0].name;
			default:
				return `${types.length} selected`;
		}
	}

	get query() {
		return query;
	}

	@action
	async fetchSearchResults(searchText: any) {
		const variables = {
			query: searchText,
			typesToInclude: [
				'BasisInstrument',
				'CashInstrument',
				'CommitmentInstrument',
				'Future',
				'Option',
				'PhysicalInstrument',
				'SpreadInstrument',
				'SwapInstrument',
				'SwaptionInstrument',
				'Product',
			],
		};
		let scopedCustomerAccounts = [];

		if (!this.applicationScope.globalCustomerId) {
			variables.typesToInclude = [...variables.typesToInclude, 'BrokerageAccount', 'SwapAccount'];
		} else {
			const accountVariables = {
				where: {
					customerId: { equals: this.applicationScope.globalCustomerId },
					OR: [
						{
							accountNumber: { contains: searchText },
						},
						{
							name: { contains: searchText },
						},
						{
							id: { contains: searchText },
						},
					],
				},
			};

			scopedCustomerAccounts = (
				await this.apollo.watchQuery({
					query: accountFuzzyQuery,
					variables: accountVariables,
				})
			).Accounts.map((account: any) => {
				const newObj = {
					id: account.id,
					name: account.name,
					// does this encapsulate all accounts where type is different than would see in search api?
					type: account.type === ('Brokerage' || 'Swap') ? account.__typename : account.type,
					accountNumber: account.accountNumber,
				};
				return newObj;
			});
		}

		let salesCodeResults: { id: string; name: string; type: string }[] = [];

		await useQuery<{ BrokerageAccounts: Query['BrokerageAccounts'] }, { searchText: string; scopeId: string }>(this, () => [
			SALES_CODE_SEARCH,
			{
				variables: {
					searchText,
					scopeId: this.model.scopeId,
				},
				onComplete: (data) => {
					const uniqueSalesCodes = [
						...(data?.BrokerageAccounts.reduce((acc, account) => {
							const salesCode = account.salesCode;
							if (salesCode) {
								acc.add(salesCode);
							}

							return acc;
						}, new Set<string>()) || []),
					];

					salesCodeResults = uniqueSalesCodes.map((salesCode) => ({
						id: salesCode,
						name: salesCode,
						type: 'SalesCode',
					}));
				},
			},
		]).promise;

		const searchApiResults = (await this.apollo.watchQuery({ query: searchQuery, variables })).Search;
		return [...searchApiResults, ...scopedCustomerAccounts, ...salesCodeResults].sort((a, b) => {
			if (a.type < b.type) {
				return -1;
			} else if (a.type > b.type) {
				return 1;
			} else {
				return 0;
			}
		});
	}

	@action
	structureSearchResults(searchResults: any) {
		const map = new Map();

		// Move products to the front of the array so they are displayed above the other types
		const sortedSearchResults = searchResults.slice().sort((a: any, b: any) => {
			if (a.type === 'Product') {
				return -1;
			} else if (b.type === 'Product') {
				return 1;
			}
			return 0;
		});

		sortedSearchResults.forEach((item: any) => {
			if (map.has(item.type)) {
				map.get(item.type).push({
					id: item.id,
					name: item.name,
					type: item.type,
					accountNumber: item.accountNumber ?? null,
				});
			} else {
				map.set(item.type, [
					{
						id: item.id,
						name: item.name,
						type: item.type,
						accountNumber: item.accountNumber ?? null,
					},
				]);
			}
		});

		return map;
	}

	@action
	setSearchFilterQueryParam(searchResult: any) {
		const mappedSearchFilter = this.mapSearchResult(searchResult);
		//@ts-ignore
		this[mappedSearchFilter.filterIdentifier] = mappedSearchFilter.filterValue;
		this.setTablePageState();
	}

	mapSearchResult(searchResult: any) {
		let filterIdentifier;

		switch (searchResult.type) {
			case 'BasisInstrument':
				filterIdentifier = 'instrumentId';
				break;
			case 'BrokerageAccount':
				filterIdentifier = 'accountId';
				break;
			case 'CashInstrument':
				filterIdentifier = 'instrumentId';
				break;
			case 'CommitmentInstrument':
				filterIdentifier = 'instrumentId';
				break;
			case 'Future':
				filterIdentifier = 'instrumentId';
				break;
			case 'Option':
				filterIdentifier = 'instrumentId';
				break;
			case 'PhysicalInstrument':
				filterIdentifier = 'instrumentId';
				break;
			case 'SpreadInstrument':
				filterIdentifier = 'instrumentId';
				break;
			case 'SwapAccount':
				filterIdentifier = 'accountId';
				break;
			case 'SwapInstrument':
				filterIdentifier = 'instrumentId';
				break;
			case 'SwaptionInstrument':
				filterIdentifier = 'instrumentId';
				break;
			case 'Product':
				filterIdentifier = 'productId';
				break;
			case 'SalesCode':
				filterIdentifier = 'salesCode';
				break;
		}

		return {
			filterIdentifier,
			filterValue: searchResult.id,
		};
	}

	@action
	clearSearchFilterQueryParam(filterIdentifier: any) {
		//@ts-ignore
		this[`${filterIdentifier}`] = null;
		this.setTablePageState();
	}

	@action
	setMonthExpiresAtQueryParam(value: { startDate: string; endDate: string }) {
		this.monthExpiresAtStartDate = value.startDate;
		this.monthExpiresAtEndDate = value.endDate;
		this.setTablePageState();
	}

	@action
	setTablePageState(newPageVal = 0) {
		this.currentPage = newPageVal;
		resetVaultTableScroll('positions-table');
	}

	@action
	setInstrumentOptionType(instrumentType: any, optionType: any) {
		const selectedTypes = this.selectedInstrumentOptionTypes;

		if (instrumentType === null && optionType === null) {
			this.selectAll();
		} else {
			const existingOption = selectedTypes.find((type) => type.instrumentType === instrumentType && type.optionType === optionType);

			if (existingOption) {
				this.selectedInstrumentOptionTypes = selectedTypes.filter((type) => type !== existingOption);

				if (this.selectedInstrumentOptionTypes.length === 0) {
					this.selectAll();
				}
			} else {
				this.addSelection(instrumentType, optionType);
			}
		}
		this.setTablePageState();
	}

	// Helper Method to handle 'All' being selected
	selectAll() {
		this.selectedInstrumentOptionTypes = [
			{
				name: 'All',
				instrumentType: null,
				optionType: null,
			},
		];
	}

	// Helper Method to add a new selection
	addSelection(instrumentType: any, optionType: any) {
		const matchingType = this.instrumentOptionTypes.find(
			(type) => type.instrumentType === instrumentType && type.optionType === optionType
		);

		if (matchingType) {
			if (this.isAllSelected()) {
				this.selectedInstrumentOptionTypes = [];
			}

			this.selectedInstrumentOptionTypes = [...this.selectedInstrumentOptionTypes, matchingType];
		}
	}

	isAllSelected(): boolean {
		return (
			this.selectedInstrumentOptionTypes.length === 1 &&
			this.selectedInstrumentOptionTypes[0].instrumentType === null &&
			this.selectedInstrumentOptionTypes[0].optionType === null
		);
	}

	@action
	setPositionsSideSelect(positionsSideType: any, callback: any) {
		callback();
		this.side = positionsSideType;
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'positions-index-controller': PositionsIndexController;
	}
}
