import { gql } from 'glimmer-apollo';
import SearchFilterComponent from 'vault-client/components/vault/ui-search-filter';

const GET_GRAIN_ADVISOR = gql`
	query GetGrainAdvisor($id: String!) {
		GrainAdvisor(id: $id) {
			id
			name
		}
	}
`;

export default class GrainAdvisorIdFilterComponent extends SearchFilterComponent {
	query = GET_GRAIN_ADVISOR;
	valuePath = 'GrainAdvisor.name';
}
