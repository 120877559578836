import DRPInsuranceEndorsementsIndexController from 'vault-client/controllers/drp-insurance-endorsements';

export default class BusinessesBusinessDrpInsuranceEndorsements extends DRPInsuranceEndorsementsIndexController {
	drpInsuranceEndorsementRoute: string = 'businesses.business.drp-insurance-endorsement';
	insuranceRoutePath: string = 'businesses.business.insurance';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'businesses/business/drp-insurance-endorsements': BusinessesBusinessDrpInsuranceEndorsements;
	}
}
