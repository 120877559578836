import Route from '@ember/routing/route';
import { gql, useQuery } from 'glimmer-apollo';
import {
	Instrument,
	Query,
	Query_HedgeStrategyArgs,
	Swap,
	Future,
	Option,
	Swaption,
	Transaction,
	BrokerageAccount,
	SwapAccount,
	HedgeStrategyHistoricalPnlFilterDTO,
} from 'vault-client/types/graphql-types';
import { tracked } from '@glimmer/tracking';
import { service } from '@ember/service';
import { ModelFrom } from 'vault-client/utils/type-utils';
import MarketDataService from 'vault-client/services/market-data';
import { DateTime } from 'luxon';

const GET_HEDGE_STRATEGY = gql`
	query HedgeStrategy($id: String!, $historicalPnlWhere: HedgeStrategyHistoricalPnlFilterDTO) {
		HedgeStrategy(id: $id) {
			id
			name
			description
			insuranceNetPremium
			insuranceRealizedPnl
			insuranceUnrealizedPnl
			netPnl
			netPremium
			positionNetPremium
			positionRealizedPnl
			positionUnrealizedPnl
			productId
			realizedPnl
			startDate
			status
			unrealizedPnl
			updatedAt
			Product {
				id
				slug
				MostCurrentFuture {
					id
					barchartSymbol
					SymbolGroup {
						id
						fractionDigits
					}
				}
				StandardProductLotSpecification {
					id
					tickValue
					lotSize
					pointValue
				}
			}
			HedgeStrategyHistoricalPnls(where: $historicalPnlWhere, orderBy: { date: Asc }) {
				id
				date
				insuranceNetPremium
				insuranceRealizedPnl
				insuranceUnrealizedPnl
				netPnl
				netPremium
				positionNetPremium
				positionRealizedPnl
				positionUnrealizedPnl
				realizedPnl
				unrealizedPnl
			}
			InsuranceEndorsements {
				id
				pnl
				producerPremiumAmount
				type
				typeCode
				InsurancePolicy {
					id
					producerName
					policyNumber
					agencyName
					agentFullName
					State {
						id
						abbreviation
					}
					AIP {
						id
						name
					}
				}
				... on LgmInsuranceEndorsement {
					id
					perMonthData
					type
					coverageMonths
					grossMarginGuarantee
					totalTarget
					totalPremiumAmount
					totalExpectedGrossMargin
					pnl
					producerPremiumAmount
					salesEffectiveDate
					forecastedIndemnity
					deductibleAmount
					grossMarginGuarantee
					subsidyAmount
					revenueHedgeProductId
					RmaType {
						id
						typeName
						typeCode
						RmaCommodity {
							commodityYear
							commodityName
						}
					}
					InsurancePolicy {
						id
						agentFullName
						State {
							abbreviation
						}
					}
				}
				... on LrpInsuranceEndorsement {
					commodityCode
					id
					pnl
					totalPremiumAmount
					type
					coverageEndDate
					commodityPrice
					coveragePrice
					salesEffectiveDate
					insuredValue
					headCount
					targetWeightCwt
					lengthInWeeks
					subsidyAmount
					producerPremiumAmount
					indemnity
					revenueHedgeProductId
					RmaCommodity {
						commodityName
					}
					# api error - need to uncomment when fixed.
					# RmaType {
					# 	id
					# 	typeName
					# }
				}
			}
			Transactions {
				id
				tradeDate
				price
				contractQuantity
				unitQuantity
				feeTotal
				commissionTotal
				commissionAndFeeTotal
				Account {
					id
					name
					accountNumber
					... on BrokerageAccount {
						id
						salesCode
					}
					... on SwapAccount {
						SwapDealer {
							id
							name
						}
					}
				}
				Instrument {
					type
					Product {
						id
						name
						displayFactor
						fractionDigits
						optionsUnitValue
						pointValue
						StandardProductLotSpecification {
							pointValue
						}
						velaRootSymbol
						MostCurrentFuture {
							id
							name
							barchartSymbol
						}
					}
					SymbolGroup {
						id
						fractionDigits
						displayFactor
					}
					... on Option {
						optionType
						strike
						expiresAt
						displayExpiresAt
						barchartSymbol
						exchangeSymbol
						UnderlyingInstrument {
							id
							barchartSymbol
						}
					}
					... on Future {
						id
						exchangeSymbol
						barchartSymbol
						displayExpiresAt
					}
					... on Swap {
						id
						displayExpiresAt
						PriceInstrument {
							id
							... on Future {
								id
								barchartSymbol
							}
						}
					}
					... on Swaption {
						id
						strike
						displayExpiresAt
						optionType
						PriceInstrument {
							id
							... on Option {
								id
								barchartSymbol
							}
						}
					}
				}
			}
		}
	}
`;
interface QueryHedgeStrategyParams {
	strategy_id: string;
	historicalPnlWhere: HedgeStrategyHistoricalPnlFilterDTO;
}

type HedgeStrategyQuery = Query['HedgeStrategy'] & {
	HedgeStrategy: Query['HedgeStrategy'];
	historicalPnlWhere: HedgeStrategyHistoricalPnlFilterDTO;
};

type TransactionQuery = Transaction & {
	Instrument: Swap &
		Future &
		Swaption &
		Option & { PriceInstrument: Instrument & Swap & Future & Option & Swaption & { barchartSymbol: String } };
	PricingInstrument?: Swap & Future & Option & Swaption & { barchartSymbol: String };
	lastPrice?: number;
	Account: BrokerageAccount | SwapAccount;
};

type HedgeStrategyQueryArgs = Query_HedgeStrategyArgs & {
	historicalPnlWhere: HedgeStrategyHistoricalPnlFilterDTO;
};
export default class BusinessesBusinessHedgeStrategiesHedgeStrategyRoute extends Route {
	@service marketData!: MarketDataService;
	@tracked variables = { id: '', historicalPnlWhere: {} };
	@tracked mostCurrentBarchartSymbol: string = '';
	getHedgeStrategy = useQuery<HedgeStrategyQuery, HedgeStrategyQueryArgs>(this, () => [GET_HEDGE_STRATEGY, { variables: this.variables }]);

	async model(params: QueryHedgeStrategyParams) {
		this.variables = {
			id: params.strategy_id,
			historicalPnlWhere: {
				date: {
					lte: DateTime.now().toISO(),
				},
			},
		};

		await this.getHedgeStrategy.promise;
		return { hedgeStrategy: this.getHedgeStrategy, lastUpdatedAt: DateTime.now().toISO() };
	}

	afterModel(model: ModelFrom<BusinessesBusinessHedgeStrategiesHedgeStrategyRoute>): void {
		const transactions = model.hedgeStrategy?.data?.HedgeStrategy?.Transactions as TransactionQuery[];
		transactions?.forEach((transaction) => {
			const barchartSymbol = transaction.Instrument.PriceInstrument
				? transaction?.Instrument.PriceInstrument?.barchartSymbol
				: transaction?.Instrument?.barchartSymbol;
			if (!barchartSymbol) return;

			// this.registeredFutures.push(barchartSymbol);
			this.marketData.register(barchartSymbol);
		});

		this.mostCurrentBarchartSymbol = model.hedgeStrategy.data?.HedgeStrategy?.Product?.MostCurrentFuture?.barchartSymbol ?? '';
		this.marketData.register(this.mostCurrentBarchartSymbol);
	}

	willDestroy() {
		this.marketData.unregister(this.mostCurrentBarchartSymbol);
	}
}
