import TransactionsShowController from 'vault-client/controllers/transactions/show';

export default class OrganizationsOrganizationTransaction extends TransactionsShowController {
	accountRoute: string = 'organizations.organization.account';
	transactionsRoutePath: string = 'organizations.organization.transactions';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'organizations/organization/transaction': OrganizationsOrganizationTransaction;
	}
}
