import MilkCheckReconciliationRoute from 'vault-client/routes/milk/milk-check-reconciliation';

type ModelParams = {
	year: number;
	selectedLocationId: string | null;
	basisStartDate: string;
	basisEndDate: string;
};
export default class LocationsLocationMilkCheckReconciliationRoute extends MilkCheckReconciliationRoute {
	async model(params: ModelParams) {
		const locationParams = this.paramsFor('locations.location') as { id: string };
		const locationId = locationParams.id;

		const { startDate, endDate, futuresStartDate } = this.generateDates(params.year);

		this.variables = {
			startDate,
			endDate,
			futuresStartDate,
			entityId: locationId,
			scopeId: locationId,
			basisStartDate: params.basisStartDate,
			basisEndDate: params.basisEndDate,
		};

		await this.getMilkCheckReconciliation.promise;

		return {
			entityId: locationId,
			getMilkCheckReconciliation: this.getMilkCheckReconciliation,
		};
	}
}
