import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { DateTime } from 'luxon';
import { UiDateFilterOption } from 'vault-client/components/vault/ui-date-filter';
import { CellComponents, TableColumn } from 'vault-client/types/vault-table';
import { ModelFrom } from 'vault-client/utils/type-utils';
import { FeedIngredient, Seller } from 'vault-client/types/graphql-types';
import BusinessesBusinessLivestockFeedPurchasesRoute from 'vault-client/routes/businesses/business/livestock-feed-purchases';

type FeedPurchasesRow = {
	feedIngredient?: string;
	vender?: string;
	tons?: number;
	dmiTons?: number;
	futuresMonth?: string;
	deliveryStartDate?: string;
	deliveryEndDate?: string;
	salesType?: string;
	futuresPrice?: number;
	basis?: number;
	seller?: string;
	location?: string;
	delivery?: string;
	fob?: string;
	contractNumber?: string;
};

export default class LivestockFeedPurchasesController extends Controller {
	@tracked startDate: string = DateTime.now().startOf('month').toISODate();
	@tracked endDate: string = DateTime.now().plus({ months: 11 }).endOf('month').toISODate();
	@tracked selectedVendorId: string | null = null;
	@tracked selectedFeedIngredientId: string | null = null;

	declare model: ModelFrom<BusinessesBusinessLivestockFeedPurchasesRoute>;

	feedIngredientRoutePath = '';

	uuidNamespace = 'e27e345b-a2d6-4f48-beab-f66b8a1f4f4f';

	queryParams = ['startDate', 'endDate', 'selectedVendorId', 'selectedFeedIngredientId'];

	timePeriodOptions: UiDateFilterOption[] = [
		{
			displayName: 'Previous 24 Months',
			startDate: DateTime.now().minus({ months: 24 }).startOf('month').toISODate(),
			endDate: DateTime.now().minus({ months: 1 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Previous 18 Months',
			startDate: DateTime.now().minus({ months: 18 }).startOf('month').toISODate(),
			endDate: DateTime.now().minus({ months: 1 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Previous 12 Months',
			startDate: DateTime.now().minus({ months: 12 }).startOf('month').toISODate(),
			endDate: DateTime.now().minus({ months: 1 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Next 12 Months',
			startDate: DateTime.now().startOf('month').toISODate(),
			endDate: DateTime.now().plus({ months: 11 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Next 18 Months',
			startDate: DateTime.now().startOf('month').toISODate(),
			endDate: DateTime.now().plus({ months: 17 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Next 24 Months',
			startDate: DateTime.now().startOf('month').toISODate(),
			endDate: DateTime.now().plus({ months: 23 }).endOf('month').toISODate(),
		},
	];

	get tableRoute() {
		return `feed-purchases.${this.model.businessId}-table`;
	}

	get currentTimePeriodOption() {
		return (
			this.timePeriodOptions.find((option) => option.startDate === this.startDate && option.endDate === this.endDate) ?? {
				startDate: this.startDate,
				endDate: this.endDate,
			}
		);
	}

	get columns(): TableColumn[] {
		return [
			{
				id: '0b58e723-2e82-464d-9daa-4ca088b13525',
				name: 'Feed',
				valuePath: 'feedIngredient',
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
			{
				id: '061bc4d2-bf18-451e-8891-1a6c4701cd8c',
				name: 'Vendor',
				valuePath: 'vendor',
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
			{
				id: 'efbf325a-34c9-4d0c-9831-a3db748b76c8',
				name: 'Tons',
				valuePath: 'tons',
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'right',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
			{
				id: 'f7a6c023-3c3b-452d-9c68-4ca80956b263',
				name: 'DMI Tons',
				valuePath: 'dmiTons',
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'right',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
			{
				id: 'e8116fe9-ded6-4f46-9a5b-382acbf98898',
				name: 'Futures Month',
				valuePath: 'futuresMonth',
				cellComponent: CellComponents.MonthFormat,
				textAlign: 'left',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
			{
				id: '1d9bf3f1-1563-4ad5-9d5e-06d0e4489bf7',
				name: 'Delivery Start Date',
				valuePath: 'deliveryStartDate',
				cellComponent: CellComponents.MonthFormat,
				textAlign: 'left',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
			{
				id: '38d008bd-7bee-4853-9ae7-6eff49598047',
				name: 'Delivery End Date',
				valuePath: 'deliveryEndDate',
				cellComponent: CellComponents.MonthFormat,
				textAlign: 'left',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
			{
				id: '27178ef2-af09-4644-bda0-b717e6a22ab3',
				name: 'Sales Type',
				valuePath: 'salesType',
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
			{
				id: 'ad189006-723b-4cf8-85fd-76f1b4fa1121',
				name: 'Futures Price',
				valuePath: 'futuresPrice',
				cellComponent: CellComponents.MonthFormat,
				textAlign: 'right',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},

			{
				id: '062e1888-2ab0-4edf-a5c6-98f028f33b6c',
				name: 'Basis',
				valuePath: 'basis',
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'right',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
			{
				id: '7b84c29a-31d8-4bdd-a67f-4d3b89e51916',
				name: 'Seller',
				valuePath: 'seller',
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
			{
				id: '8092e263-5121-4f2b-acb5-78a6106a48f7',
				name: 'Location',
				valuePath: 'location',
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
			{
				id: '5c250f98-431a-4e00-8c2f-908f8a26efef',
				name: 'Delivery',
				valuePath: 'delivery',
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
			{
				id: '5d1b4601-2516-4248-9002-a48cc773d84b',
				name: 'FOB',
				valuePath: 'fob',
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
			{
				id: 'dcea839b-8c01-4c19-9c7c-baf7f9f824d4',
				name: 'Contract Number',
				valuePath: 'contractNumber',
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
		];
	}

	get selectedFeedIngredient(): FeedIngredient | null {
		return this.model.getFeedPurchases.data?.FeedIngredients.find((v) => v.id === this.selectedFeedIngredientId) ?? null;
	}

	get selectedVendor(): Seller | null {
		// TODO: Implement selected vendor search when relevant Seller query is known
		return null;
	}

	@action
	setSelectedVendor(vendor: Seller | null, callback?: () => void) {
		this.selectedVendorId = vendor?.id ?? null;

		if (callback) {
			callback();
		}
	}

	get rows(): FeedPurchasesRow[] {
		// TODO: Implement pulling purchases data from model
		return [];
	}

	@action
	setSelectedFeedIngredient(ingredient: FeedIngredient | null, callback?: () => void) {
		this.selectedFeedIngredientId = ingredient?.id ?? null;

		if (callback) {
			callback();
		}
	}

	@action
	setTimePeriod(option: UiDateFilterOption) {
		this.startDate = option.startDate;
		this.endDate = option.endDate;
	}
}
