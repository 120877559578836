import TransactionsIndexRoute from 'vault-client/routes/transactions';
import { TypeOfInstrument, TypeOfOption } from 'vault-client/types/graphql-types';

type SortObj = {
	valuePath: string;
	isAscending: boolean;
};

interface QueryParams {
	accountId?: string | null;
	instrumentId?: string | null;
	customerId?: string | null;
	productId?: string | null;
	salesCode?: string | null;
	instrumentType?: TypeOfInstrument | null;
	optionType?: TypeOfOption | null;
	side?: string | null;
	tradeDateStartDate: string;
	tradeDateEndDate: string;
	expMonthStartDate: string;
	expMonthEndDate: string;
	page: number;
	size: number;
	sorts: SortObj[];
	id: string;
}

export default class OrganizationsTransactions extends TransactionsIndexRoute {
	async model(params: QueryParams) {
		const organizationParams = this.paramsFor('organizations.organization') as { id: string };
		this.variables = {
			where: this.generateWhere(
				organizationParams.id,
				null,
				params.accountId,
				params.instrumentId,
				params.productId,
				params.salesCode,
				params.instrumentType,
				params.optionType,
				params.tradeDateStartDate,
				params.tradeDateEndDate,
				params.expMonthStartDate,
				params.expMonthEndDate,
				params.side
			),
			orderBy: this.generateOrderBy(params.sorts),
			offset: params.page * params.size,
			limit: params.size,
		};

		await this.getTransactions.promise;

		return { getTransactions: this.getTransactions, variables: this.variables, scopeId: organizationParams.id };
	}
}
