import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { ModelFrom } from 'vault-client/utils/type-utils';
import route, { GET_TARGETS } from 'vault-client/routes/vgs/grain-services/targets';
import { CellComponents, TableColumn } from 'vault-client/types/vault-table';
import resetVaultTableScroll from 'vault-client/utils/reset-vault-table-scroll';
import { action } from '@ember/object';
import getFilterDisplayProperty from 'vault-client/utils/get-filter-display-property';
import { queryManager } from 'ember-apollo-client';
import { gql, useQuery } from 'glimmer-apollo';
import Target from 'vault-client/models/vgs/target';

import {
	GrainTargetOrder,
	TypeOfGrainOrderSales,
	TypeOfSearchItem,
	Query,
	Query_SearchArgs,
	Query_GrainTargetOrdersArgs,
	Query_GrainAdvisorsArgs,
	Query_CropsArgs,
	Query_BuyersArgs,
	Query_GrainLocationsArgs,
	TypeOfGrainOrderStatus,
} from 'vault-client/types/graphql-types';

import { DateTime } from 'luxon';
import { inject as service } from '@ember/service';
import PermissionsService from 'vault-client/services/permissions';
import { SearchResult } from 'vault-client/types/vault-client';
import { getGrainOrderStatusDisplayValue, getSalesTypeDisplayValue } from 'vault-client/utils/vgs-utils';
import { generateFullName } from 'vault-client/utils/general';

const GET_SEARCH_QUERY = gql`
	query Search($query: String!, $typesToInclude: [TypeOfSearchItem!]) {
		Search(query: $query, typesToInclude: $typesToInclude) {
			id
			name
			type
			attributes
		}
	}
`;

const GET_GRAIN_ORDER_BR_OWNER = gql`
	query GrainTargetBrOwner($where: GrainTargetOrderFilterDTO) {
		GrainTargetOrders(where: $where) {
			id
			BuyerRelationsOwner {
				id
				firstName
				lastName
			}
		}
	}
`;

const GET_GRAIN_ADVISOR = gql`
	query GrainAdvisor($where: GrainAdvisorFilterDTO) {
		GrainAdvisors(where: $where) {
			id
			name
		}
	}
`;

export const GET_CROP = gql`
	query CropCategories($where: CropCategoryFilterDTO) {
		CropCategories(where: $where) {
			id
			name
		}
	}
`;

export const GET_BUYER = gql`
	query Buyer($where: BuyerFilterDTO) {
		Buyers(where: $where) {
			id
			name
		}
	}
`;

const GET_GRAIN_LOCATION = gql`
	query GrainLocation($where: GrainLocationFilterDTO) {
		GrainLocations(where: $where) {
			id
			name
		}
	}
`;

type GetTarget = {
	GrainTargetOrders: Query['GrainTargetOrders'];
};

type GetAdvisor = {
	GrainAdvisors: Query['GrainAdvisors'];
};

type GetCrop = {
	CropCategories: Query['CropCategories'];
};

type GetBuyer = {
	Buyers: Query['Buyers'];
};

type GetLocation = {
	GrainLocations: Query['GrainLocations'];
};

type DateFilterOptions = {
	displayName?: string | undefined;
	startDate?: string | undefined;
	endDate?: string | undefined;
};

export default class VgsGrainServicesTargetsIndex extends Controller {
	@queryManager apollo: any;
	@service declare permissions: PermissionsService;

	@tracked page = 0;
	@tracked size = 100;
	@tracked sorts = [{ valuePath: 'expirationDate', isAscending: true }];
	@tracked brOwnerId: string | null = null;
	@tracked grainAdvisorId: string | null = null;
	@tracked customerId: string | null = null;
	@tracked cropCategoryId: string | null = null;
	@tracked buyerId: string | null = null;
	@tracked grainLocationId: string | null = null;
	@tracked status: TypeOfGrainOrderStatus | null = TypeOfGrainOrderStatus.Pending;
	@tracked selectedSalesTypes: TypeOfGrainOrderSales[] = [];
	@tracked cropYearStart: number | null = 1900;
	@tracked cropYearEnd: number | null = 2999;
	@tracked updatedAtStart: string | null = '1900-01-01';
	@tracked updatedAtEnd: string | null = '2999-12-31';
	@tracked futuresMonthStart: string | null = '1900-01-01';
	@tracked futuresMonthEnd: string | null = '2999-12-31';
	@tracked deliveryStartDateStart: string | null = '1900-01-01';
	@tracked deliveryStartDateEnd: string | null = '2999-12-31';
	@tracked deliveryEndDateStart: string | null = '1900-01-01';
	@tracked deliveryEndDateEnd: string | null = '2999-12-31';
	@tracked targetIdToDelete: GrainTargetOrder | null = null;
	@tracked targetToClaim: GrainTargetOrder | null = null;

	updatedAtFilterOptions: DateFilterOptions[] = [
		{
			displayName: 'All Dates',
			startDate: '1900-01-01',
			endDate: '2999-12-31',
		},
		{
			displayName: 'Today',
			startDate: DateTime.local().startOf('day').toISODate(),
			endDate: DateTime.local().endOf('day').toISODate(),
		},
		{
			displayName: 'Yesterday',
			startDate: DateTime.local().minus({ days: 1 }).startOf('day').toISODate(),
			endDate: DateTime.local().minus({ days: 1 }).endOf('day').toISODate(),
		},
		{
			displayName: 'Last 7 days',
			startDate: DateTime.local().endOf('day').minus({ days: 7 }).startOf('day').toISODate(),
			endDate: DateTime.local().toISODate(),
		},
		{
			displayName: 'Last 30 days',
			startDate: DateTime.local().endOf('day').minus({ days: 30 }).startOf('day').toISODate(),
			endDate: DateTime.local().toISODate(),
		},
	];

	futuresMonthFilterOptions: DateFilterOptions[] = [
		{
			displayName: 'All Months',
			startDate: '1900-01-01',
			endDate: '2999-12-31',
		},
		{
			displayName: 'Current Month',
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().endOf('month').toISODate(),
		},
		{
			displayName: 'Next Month',
			startDate: DateTime.local().plus({ months: 1 }).startOf('month').toISODate(),
			endDate: DateTime.local().plus({ months: 1 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Next 2 Months',
			startDate: DateTime.local().plus({ months: 1 }).startOf('month').toISODate(),
			endDate: DateTime.local().plus({ months: 2 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Next 3 Months',
			startDate: DateTime.local().plus({ months: 1 }).startOf('month').toISODate(),
			endDate: DateTime.local().plus({ months: 3 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Next 6 Months',
			startDate: DateTime.local().plus({ months: 1 }).startOf('month').toISODate(),
			endDate: DateTime.local().plus({ months: 6 }).endOf('month').toISODate(),
		},
	];

	deliveryStartDateFilterOptions: DateFilterOptions[] = [
		{
			displayName: 'All Dates',
			startDate: '1900-01-01',
			endDate: '2999-12-31',
		},
		{
			displayName: 'Current Month',
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().endOf('month').toISODate(),
		},
		{
			displayName: 'Next 3 Months',
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().plus({ months: 3 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Next 6 Months',
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().plus({ months: 6 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Next 12 Months',
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().plus({ months: 12 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Next 24 Months',
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().plus({ months: 24 }).endOf('month').toISODate(),
		},
	];

	deliveryEndDateFilterOptions: DateFilterOptions[] = [
		{
			displayName: 'All Dates',
			startDate: '1900-01-01',
			endDate: '2999-12-31',
		},
		{
			displayName: 'Current Month',
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().endOf('month').toISODate(),
		},
		{
			displayName: 'Next 3 Months',
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().plus({ months: 3 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Next 6 Months',
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().plus({ months: 6 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Next 12 Months',
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().plus({ months: 12 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Next 24 Months',
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().plus({ months: 24 }).endOf('month').toISODate(),
		},
	];
	declare model: ModelFrom<route>;

	queryParams = [
		'brOwnerId',
		'grainAdvisorId',
		'customerId',
		'cropCategoryId',
		'buyerId',
		'grainLocationId',
		'status',
		'selectedSalesTypes',
		'cropYearStart',
		'cropYearEnd',
		'updatedAtStart',
		'updatedAtEnd',
		'futuresMonthStart',
		'futuresMonthEnd',
		'deliveryStartStart',
		'deliveryStartEnd',
		'deliveryEndStart',
		'deliveryEndEnd',
	];

	get columns() {
		let brOnlyButtonColumns: TableColumn[] = [];

		if (this.permissions.isBuyerRelations) {
			brOnlyButtonColumns = [
				{
					id: 'dace71a3-76fa-47be-af2a-530880f0a480',
					name: '',
					valuePath: '',
					minWidth: 120,
					textAlign: 'left',
					isSortable: false,
					cellComponent: CellComponents.Button,
					componentArgs: {
						size: 'xs',
						style: 'outline',
						text: 'Remove',
						fn: this.setTargetToDelete,
						disableFn: (datum: GrainTargetOrder) => {
							return !datum.Plan?.hasWriteAccess ? true : false;
						},
					},
					isFixed: '',
					isVisible: true,
				},
				{
					id: 'c971de08-85b0-4956-b3e3-0a6ef87b6abb',
					name: '',
					valuePath: 'Plan',
					minWidth: 120,
					textAlign: 'left',
					isSortable: false,
					cellComponent: CellComponents.Button,
					componentArgs: {
						size: 'xs',
						style: 'outline',
						text: 'Claim',
						fn: this.setTargetToClaim,
						disableFn: (datum: GrainTargetOrder) => {
							return !datum.Plan?.hasWriteAccess ? true : false;
						},
					},
					isFixed: '',
					isVisible: true,
				},
			];
		}

		const baseColumns: TableColumn[] = [
			{
				id: '2b455fdd-8ef1-47aa-87d9-fb9761e0b984',
				name: '',
				width: 100,
				textAlign: 'center',
				isSortable: false,
				cellComponent: CellComponents.Button,
				componentArgs: {
					size: 'xs',
					style: 'outline',
					text: 'Details',
					fn: () => {},
				},
				isFixed: '',
				isVisible: true,
				linkRoute: 'vgs.grain-services.targets.show',
				linkModelPath: 'id',
			},
			{
				id: 'ed04e14b-6ac8-47db-906b-0db8164f3a44',
				name: 'BR Owner',
				valuePath: 'BuyerRelationsOwner.id',
				minWidth: 100,
				width: 115,
				textAlign: 'center',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: '505f1c4a-aa47-4fc6-b892-bf152b956917',
						name: 'First Name',
						valuePath: 'BuyerRelationsOwner.firstName',
						minWidth: 115,
						width: 100,
						textAlign: 'left',
						isSortable: true,
						cellComponent: CellComponents.String,
						isFixed: '',
						isVisible: true,
					},
					{
						id: '7b486d00-8cc5-4790-8c1e-3a7456447f83',
						name: 'Last Name',
						valuePath: 'BuyerRelationsOwner.lastName',
						minWidth: 115,
						width: 100,
						textAlign: 'left',
						isSortable: true,
						cellComponent: CellComponents.String,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: '23d6889a-1510-4d67-864b-8440f1704285',
				name: 'Updated At',
				valuePath: 'StatusUpdates.updatedAt',
				minWidth: 80,
				width: 160,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.IntlDateTimeFormat,
				componentArgs: { month: 'numeric', day: 'numeric', year: 'numeric', hour: 'numeric', minute: 'numeric' },
				isFixed: '',
				isVisible: true,
			},
			{
				id: '1455bef4-daa2-455f-9a18-a952a72479ee',
				name: 'Status',
				valuePath: 'status',
				minWidth: 100,
				textAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.CustomFormat,
				componentArgs: {
					formatter: getGrainOrderStatusDisplayValue,
				},
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'f763655c-9d45-405e-9cb1-c6ac659ff2b1',
				name: 'Advisor',
				valuePath: '',
				minWidth: 115,
				width: 115,
				cellComponent: CellComponents.String,
				textAlign: 'center',
				isSortable: true,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: '208b66a6-3816-4eeb-94b4-374984459a38',
						name: 'First Name',
						valuePath: 'CropPlan.Customer.RelationshipOwner.firstName',
						minWidth: 115,
						width: 100,
						textAlign: 'left',
						isSortable: true,
						cellComponent: CellComponents.String,
						isFixed: '',
						isVisible: true,
					},
					{
						id: '0fd7341a-3ec2-407b-95c9-2e3fd93ffbe3',
						name: 'Last Name',
						valuePath: 'CropPlan.Customer.RelationshipOwner.lastName',
						minWidth: 115,
						width: 100,
						textAlign: 'left',
						isSortable: true,
						cellComponent: CellComponents.String,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: 'b6ad3020-f367-4d78-a1d9-7fb05e71af45',
				name: 'Customer',
				valuePath: 'CropPlan.Customer.name',
				minWidth: 100,
				width: 110,
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '3ccffe4f-cc0a-4600-93bf-53978123b0d0',
				name: 'Crop',
				valuePath: 'CropPlan.Crop.name',
				minWidth: 80,
				width: 80,
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '92b9af94-a335-4ebe-8e4b-eb49112bf355',
				name: 'Crop Year',
				valuePath: 'CropPlan.CropYear.year',
				minWidth: 80,
				width: 110,
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '12e2b59b-4aa3-44de-82e6-bbf0cf057635',
				name: 'Sales Type',
				valuePath: 'salesType',
				minWidth: 80,
				width: 115,
				cellComponent: CellComponents.CustomFormat,
				componentArgs: {
					formatter: getSalesTypeDisplayValue,
				},
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '7571231c-6d86-4184-a5ef-2b7f420f13d9',
				name: 'Bushels',
				valuePath: 'bushels',
				minWidth: 120,
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'afa6d216-76a8-4617-a558-c836dc4e3e7e',
				name: 'Futures Month',
				valuePath: 'futuresMonth',
				minWidth: 120,
				width: 140,
				cellComponent: CellComponents.MonthFormat,
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '4cb4ac31-6422-45c5-aced-9cac9f51da05',
				name: 'Futures Price',
				valuePath: 'futurePrice',
				minWidth: 120,
				width: 130,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'f37c44bf-15e2-4a87-a019-631e9391d98c',
				name: 'Basis',
				valuePath: 'basis',
				minWidth: 80,
				width: 80,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '30639fd6-306e-48de-b2ef-faef9e730872',
				name: 'Flat Price',
				valuePath: 'flatPrice',
				minWidth: 80,
				width: 80,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'bdd88829-4a9e-47ef-96cf-9d5cd73599be',
				name: 'Spread Gain',
				valuePath: 'spread',
				minWidth: 100,
				width: 125,
				cellComponent: CellComponents.String,
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'e70dcb6d-d5a1-478a-816b-920dc5075245',
				name: 'Delivery Start Date',
				valuePath: 'deliveryStartDate',
				minWidth: 120,
				width: 170,
				cellComponent: CellComponents.IntlDateTimeFormat,
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '0dd21917-b1c4-4731-87ec-be8a8426a497',
				name: 'Delivery End Date',
				valuePath: 'deliveryEndDate',
				minWidth: 120,
				width: 165,
				cellComponent: CellComponents.IntlDateTimeFormat,
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '0153a131-96b1-4304-92a4-5e9bacd9857e',
				name: 'Buyer',
				valuePath: 'Buyer.name',
				minWidth: 120,
				cellComponent: CellComponents.String,
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '8f70e26b-1c57-4f4a-a1a4-f9c310b476c4',
				name: 'Location',
				valuePath: 'Location.name',
				minWidth: 120,
				cellComponent: CellComponents.String,
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'c88a019d-004e-4217-8934-5ddd594be2f2',
				name: 'Contract Number',
				valuePath: 'contractNumber',
				minWidth: 120,
				width: 160,
				cellComponent: CellComponents.String,
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '01636a26-f034-40d9-843d-a30ef6b84fe7',
				name: 'Fees',
				valuePath: 'fees',
				minWidth: 80,
				width: 80,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'f71c067f-d268-4296-96a6-0e151031f041',
				name: 'Expires At',
				valuePath: 'expirationDate',
				minWidth: 120,
				width: 115,
				cellComponent: CellComponents.IntlDateTimeFormat,
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'c2cf7b7d-b45f-4394-8c73-862ae42feafb',
				name: 'Target Note',
				valuePath: 'targetNote',
				minWidth: 120,
				width: 120,
				cellComponent: CellComponents.String,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
		];

		const baseAndWriteColumns = [...baseColumns, ...brOnlyButtonColumns];
		return baseAndWriteColumns;
	}

	get currentPage() {
		return this.page;
	}

	set currentPage(page) {
		this.page = page;
	}

	get query() {
		return GET_TARGETS;
	}

	itemsFn = (targets: GrainTargetOrder[] | undefined) => {
		return targets?.map((target) => {
			const targetModel = new Target(target, null, null);

			// TODO: Consider adding a creation entry to StatusUpdates to let us get rid of this logic
			// If there is no Status Update, use the createdAt date
			if (targetModel.StatusUpdates.updatedAt === null) {
				targetModel.StatusUpdates.updatedAt = targetModel.createdAt;
			}
			return targetModel;
		});
	};

	get rows() {
		return this.itemsFn(this.model.getTargets.data?.GrainTargetOrders);
	}

	get totalNumTargets() {
		return this.model.getTargets.data?.GrainTargetOrderCount?.count;
	}

	get searchFilterQueryParams() {
		const obj: {
			[key: string]: any;
		} = {};
		const searchQueryParams = ['brOwnerId', 'grainAdvisorId', 'customerId', 'cropCategoryId', 'grainLocationId', 'buyerId'];
		searchQueryParams.forEach((param) => {
			//@ts-ignore
			const value = this[param];

			if (value) {
				obj[param] = {
					filterRule: 'contains',
					filterValue: value,
				};

				// set filterComponent property to specify component for custom display extended from search-filter
				const filterDisplayObj = getFilterDisplayProperty(param);

				if (filterDisplayObj && filterDisplayObj.customComponent) {
					obj[param].filterComponent = filterDisplayObj.customComponent;
				}
				// set filterLabel property to specify custom label for filter - default would be filterIdentifier (matches queryParam)
				if (filterDisplayObj && filterDisplayObj.label) {
					obj[param].filterLabel = filterDisplayObj.label;
				}
			}
		});

		return obj;
	}

	get searchPlaceholder() {
		return 'Filter by BR Owner, Advisor, Customer, Crop, Buyer, Location';
	}

	get searchPrompt() {
		return 'Type a search term to find targets by BR Owner, Customer, Crop, Buyer, Location or Advisor.';
	}

	get updatedAtSelectedDate() {
		return {
			startDate: this.updatedAtStart,
			endDate: this.updatedAtEnd,
		};
	}

	get futuresMonthSelectedDate() {
		return {
			startDate: this.futuresMonthStart,
			endDate: this.futuresMonthEnd,
		};
	}

	get deliveryStartDateSelectedDate() {
		return {
			startDate: this.deliveryStartDateStart,
			endDate: this.deliveryStartDateEnd,
		};
	}

	get deliveryEndDateSelectedDate() {
		return {
			startDate: this.deliveryEndDateStart,
			endDate: this.deliveryEndDateEnd,
		};
	}

	get salesTypes(): TypeOfGrainOrderSales[] {
		return [
			TypeOfGrainOrderSales.Cbot,
			TypeOfGrainOrderSales.Basis,
			TypeOfGrainOrderSales.Flat,
			TypeOfGrainOrderSales.Hta,
			TypeOfGrainOrderSales.Npe,
			TypeOfGrainOrderSales.MarketFlat,
			TypeOfGrainOrderSales.MarketHta,
		];
	}

	get selectedSalesTypesString() {
		if (this.selectedSalesTypes.length === 0) {
			return 'All';
		}

		if (this.selectedSalesTypes.length === 1) {
			return getSalesTypeDisplayValue(this.selectedSalesTypes[0]);
		}

		return `${this.selectedSalesTypes.length} Selected`;
	}

	@action
	addSelectedSalesType(salesType: TypeOfGrainOrderSales | null) {
		if (salesType === null) {
			this.selectedSalesTypes = [];
		} else if (this.selectedSalesTypes.includes(salesType)) {
			this.selectedSalesTypes = this.selectedSalesTypes.filter((v) => v !== salesType);
		} else {
			this.selectedSalesTypes = [...this.selectedSalesTypes, salesType];
		}

		if (this.selectedSalesTypes.length === this.salesTypes.length) {
			this.selectedSalesTypes = [];
		}
		this.setTablePageState();
	}

	@action
	async fetchSearchResults(searchText: any) {
		const searchQueryResults: SearchResult[] = [];
		const brOwnerResults: SearchResult[] = [];
		const advisorResults: SearchResult[] = [];
		const cropResults: SearchResult[] = [];
		const buyerResults: SearchResult[] = [];
		const grainLocationResults: SearchResult[] = [];

		const searchQuery = useQuery<{ Search: Query['Search'] }, Query_SearchArgs>(this, () => [
			GET_SEARCH_QUERY,
			{
				variables: { query: searchText, typesToInclude: [TypeOfSearchItem.Customer] },
				onComplete: (data): void => {
					searchQueryResults.push(
						...(data?.Search.filter((item) => {
							// Filter out non-VGS customers
							if (item.type === TypeOfSearchItem.Customer && !item.attributes?.isVgs) {
								return false;
							}

							return true;
						}).map((item) => {
							switch (item.type) {
								case TypeOfSearchItem.Customer:
									return {
										type: 'customer',
										name: item.name,
										id: item.id,
									};
								default:
									return {
										type: '',
										name: '',
										id: '',
									};
							}
						}) ?? [])
					);
				},
			},
		]);

		const brOwnerQuery = useQuery<GetTarget, Query_GrainTargetOrdersArgs>(this, () => [
			GET_GRAIN_ORDER_BR_OWNER,
			{
				variables: {
					where: {
						BuyerRelationsOwner: {
							OR: [{ firstName: { contains: searchText } }, { lastName: { contains: searchText } }],
						},
					},
				},
				onComplete: (data) => {
					brOwnerResults.push(
						...(data?.GrainTargetOrders.reduce<SearchResult[]>((acc, order) => {
							if (!acc.find((searchResult) => searchResult?.id === order.BuyerRelationsOwner?.id)) {
								acc.push({
									id: order.BuyerRelationsOwner?.id,
									name: generateFullName(order.BuyerRelationsOwner?.firstName, order.BuyerRelationsOwner?.lastName) ?? '',
									type: 'brOwner',
								});
							}
							return acc;
						}, []) ?? [])
					);
				},
			},
		]);

		const advisorQuery = useQuery<GetAdvisor, Query_GrainAdvisorsArgs>(this, () => [
			GET_GRAIN_ADVISOR,
			{
				variables: {
					where: {
						name: { contains: searchText },
					},
				},
				onComplete: (data): void => {
					advisorResults.push(
						...(data?.GrainAdvisors.map((advisor) => ({
							type: 'advisor',
							name: advisor.name,
							id: advisor.id,
						})) ?? [])
					);
				},
			},
		]);

		const cropQuery = useQuery<GetCrop, Query_CropsArgs>(this, () => [
			GET_CROP,
			{
				variables: {
					where: { name: { contains: searchText } },
				},

				onComplete: (data): void => {
					cropResults.push(
						...(data?.CropCategories.map((crop) => ({
							type: 'crop',
							name: crop.name,
							id: crop.id,
						})) ?? [])
					);
				},
			},
		]);

		const buyerQuery = useQuery<GetBuyer, Query_BuyersArgs>(this, () => [
			GET_BUYER,
			{
				variables: {
					where: {
						name: { contains: searchText },
					},
				},
				onComplete: (data): void => {
					buyerResults.push(
						...(data?.Buyers.map((buyer) => ({
							type: 'buyer',
							name: buyer.name,
							id: buyer.id,
						})) ?? [])
					);
				},
			},
		]);

		const grainLocationQuery = useQuery<GetLocation, Query_GrainLocationsArgs>(this, () => [
			GET_GRAIN_LOCATION,
			{
				variables: {
					where: {
						name: { contains: searchText },
					},
				},
				onComplete: (data): void => {
					grainLocationResults.push(
						...(data?.GrainLocations.map((location) => ({
							type: 'grainLocation',
							name: location.name,
							id: location.id,
						})) ?? [])
					);
				},
			},
		]);

		const promises = [
			searchQuery.promise,
			brOwnerQuery.promise,
			advisorQuery.promise,
			cropQuery.promise,
			buyerQuery.promise,
			grainLocationQuery.promise,
		];

		await Promise.all(promises);

		// return combined set
		return [...brOwnerResults, ...advisorResults, ...searchQueryResults, ...cropResults, ...buyerResults, ...grainLocationResults];
	}

	@action
	structureSearchResults(searchResults: any) {
		const map = new Map();

		searchResults.forEach((item: any) => {
			if (map.has(item.type)) {
				map.get(item.type).push({ id: item.id, name: item.name, type: item.type });
			} else {
				map.set(item.type, [{ id: item.id, name: item.name, type: item.type }]);
			}
		});

		return map;
	}

	@action
	setSearchFilterQueryParam(searchResult: any) {
		const mappedSearchFilter = this.mapSearchResult(searchResult);
		//@ts-ignore
		this[mappedSearchFilter.filterIdentifier] = mappedSearchFilter.filterValue;
		this.setTablePageState();
	}

	mapSearchResult(searchResult: any) {
		let filterIdentifier;

		switch (searchResult.type) {
			case 'brOwner':
				filterIdentifier = 'brOwnerId';
				break;
			case 'advisor':
				filterIdentifier = 'grainAdvisorId';
				break;
			case 'customer':
				filterIdentifier = 'customerId';
				break;
			case 'crop':
				filterIdentifier = 'cropCategoryId';
				break;
			case 'buyer':
				filterIdentifier = 'buyerId';
				break;
			case 'grainLocation':
				filterIdentifier = 'grainLocationId';
				break;
		}

		return {
			filterIdentifier,
			filterValue: searchResult.id,
		};
	}

	@action
	clearSearchFilterQueryParam(
		filterIdentifier: 'customerId' | 'cropCategoryId' | 'buyerId' | 'grainLocationId' | 'brOwnerId' | 'grainAdvisorId'
	) {
		//@ts-ignore
		this[`${filterIdentifier}`] = null;
		this.setTablePageState();
	}

	@action
	setTablePageState(newPageVal: number | null = null) {
		this.currentPage = newPageVal ?? 0;
		resetVaultTableScroll('targets-table');
	}

	@action
	setTargetStatusQueryParam(value: TypeOfGrainOrderStatus, callback: () => any) {
		callback();
		this.status = value;
		this.setTablePageState();
	}

	@action
	setCropYearStartDate(value: number) {
		this.cropYearStart = value;
		this.setTablePageState();
	}

	@action
	setCropYearEndDate(value: number) {
		this.cropYearEnd = value;
		this.setTablePageState();
	}

	@action
	setUpdatedAtDate(dateObj: { endDate: string; startDate: string }) {
		const { startDate, endDate } = dateObj;
		this.updatedAtStart = startDate;
		this.updatedAtEnd = endDate;
		this.setTablePageState();
	}

	@action
	setFuturesMonthDate(dateObj: { endDate: string; startDate: string }) {
		const { startDate, endDate } = dateObj;
		this.futuresMonthStart = startDate;
		this.futuresMonthEnd = endDate;
		this.setTablePageState();
	}
	@action
	setDeliveryStartDate(dateObj: { endDate: string; startDate: string }) {
		const { startDate, endDate } = dateObj;
		this.deliveryStartDateStart = startDate;
		this.deliveryStartDateEnd = endDate;
		this.setTablePageState();
	}
	@action
	setDeliveryEndDate(dateObj: { endDate: string; startDate: string }) {
		const { startDate, endDate } = dateObj;
		this.deliveryEndDateStart = startDate;
		this.deliveryEndDateEnd = endDate;
		this.setTablePageState();
	}
	@action
	setTargetToDelete(target: GrainTargetOrder) {
		this.targetIdToDelete = target ?? null;
	}
	@action
	setTargetToClaim(target: GrainTargetOrder) {
		this.targetToClaim = target ?? null;
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	interface Registry {
		'vgs/grain-services/targets': VgsGrainServicesTargetsIndex;
	}
}
