import InsurancePoliciesIndexController from 'vault-client/controllers/insurance-policies';

export default class OrganizationsOrganizationInsurancePolicies extends InsurancePoliciesIndexController {
	insurancePolicyRoute: string = 'organizations.organization.insurance-policy';
	insuranceRoutePath: string = 'organizations.organization.insurance';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'organizations/organization/insurance-policies': OrganizationsOrganizationInsurancePolicies;
	}
}
