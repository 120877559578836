import LgmInsuranceEndorsementsIndexRoute from 'vault-client/routes/lgm-insurance-endorsements';

type SortObj = {
	valuePath: string;
	isAscending: boolean;
};

interface QueryParams {
	agent?: string | null;
	aipId?: string | null;
	customerId?: string | null;
	salesEffectiveStartDate?: string | null;
	salesEffectiveEndDate?: string | null;
	stateId?: string | null;
	page: number;
	size: number;
	sorts: SortObj[];
	business_id: string;
}
export default class BusinessesBusinessLgmInsuranceEndorsements extends LgmInsuranceEndorsementsIndexRoute {
	async model(params: QueryParams) {
		const businessParams = this.paramsFor('businesses.business') as { business_id: string };
		const variables = {
			where: this.generateWhere(
				null,
				businessParams.business_id,
				null,
				params.aipId,
				params.agent,
				params.stateId,
				params.salesEffectiveStartDate,
				params.salesEffectiveEndDate
			),
			orderBy: this.generateOrderBy(params.sorts),
			offset: params.page * params.size,
			limit: params.size,
		};

		this.getLgmInsuranceEndorsementsQuery(variables);

		await this.getLgmInsuranceEndorsements.promise;

		return { getLgmInsuranceEndorsements: this.getLgmInsuranceEndorsements, variables: variables };
	}
}
