import Component from '@glimmer/component';
import { SortableEvent } from 'sortablejs';
import { TableColumn } from 'vault-client/types/vault-table';
import isTouchDevice from 'vault-client/utils/is-touch-device';

interface SortableListArgs {
	id: string;
	columns: TableColumn[];
	groupName: string;
	onAdd: SortableCallbackFunc;
	onUpdate: SortableCallbackFunc;
	scroll: string;
	parentList: string;
	parentListItem: string;
}

interface SortableCallbackFunc {
	// eslint-disable-next-line no-unused-vars
	(event: SortableEvent): void;
}

export default class SortableList extends Component<SortableListArgs> {
	get isTouchDevice(): boolean {
		return isTouchDevice();
	}
}
