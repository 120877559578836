import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{this.formatArray}}", {"contents":"{{this.formatArray}}","moduleName":"vault-client/components/format-array-of-strings.hbs","parseOptions":{"srcName":"vault-client/components/format-array-of-strings.hbs"}});
import Component from '@glimmer/component';

interface FormatArrayOfStringsArgs {
	arrayOfStrings: string[];
}

export default class FormatArrayOfStrings extends Component<FormatArrayOfStringsArgs> {
	get formatArray() {
		if (this.args.arrayOfStrings && Array.isArray(this.args.arrayOfStrings)) {
			const newArray: string[] = [];
			this.args.arrayOfStrings.map((string) => {
				const formattedString = string.replace(/([A-Z])/g, ' $1');
				newArray.push(`${formattedString}`);
			});
			return newArray.length > 0 ? newArray : '-';
		} else {
			return null;
		}
	}
}
