import { DateTime } from 'luxon';
import { GrainOrderActivity, TypeOfGrainOrderActivity, TypeOfGrainPlan } from 'vault-client/types/graphql-types';
import { getGrainOrderStatusDisplayValue, getSalesTypeDisplayValue } from './vgs-utils';
import { camelCaseToTitleCase, dedasherize, generateFullName, titleize } from 'vault-client/utils/general';

export type ActivityUtil = {
	messages?: Array<string> | Array<number> | null;
	isTarget?: boolean;
	isFeed?: boolean;
	isFillModificationRequest?: boolean;
} & GrainOrderActivity;

function formatKey(key: string, isModificationRequest: boolean) {
	// TODO: Specify feed and future price display values until BE changes the table names.
	if (key === 'feed_price') return 'Flat Price';
	if (key === 'future_price') return 'Futures Price';

	if (key === 'short_note') {
		if (isModificationRequest) return 'Modification Note';
		else return 'Target Note';
	}

	if (key.includes('_')) {
		// First remove "-" and "_" from the string, then uppercase the first character after a white space.
		return key.replace(/[-_]/g, ' ').replace(/(?:^|\s)+\S/g, (char) => char.toUpperCase());
	} else {
		// Handle camelCase property names
		return camelCaseToTitleCase(key);
	}
}

export default function activityMessage(activity: Array<GrainOrderActivity>) {
	const formatMonthYearArray = ['Futures Month'];
	const formatShortDateArray = ['Delivery Start Date', 'Delivery End Date'];
	const formatDollarArray = ['Basis', 'Fees', 'Flat Price', 'Hta Fee', 'Roll Fee', 'Spread', 'Future Price'];
	const formatNumberArray = ['Bushels', 'Tons'];

	const activities = activity.map((activity) => {
		const excludedItemsArray = [
			'last_updated_by',
			'location',
			'buyer',
			'created_at',
			'created_by',
			'updated_at',
			'seller',
			'is_fob',
			'data',
		];
		const activityObj: ActivityUtil = { ...activity };
		activityObj.data = { ...activity.data };
		activityObj.data.new = { ...activity.data.new, ...(activity.data.new?.data ?? {}) };
		activityObj.data.old = { ...activity.data.old, ...(activity.data.old?.data ?? {}) };
		activityObj.isFeed = activity.GrainPlan?.type === TypeOfGrainPlan.Feed;
		activityObj.isTarget = activity.type === TypeOfGrainOrderActivity.Target;
		activityObj.isFillModificationRequest = activity.type === TypeOfGrainOrderActivity.FillModificationRequest;

		if (activity.data.new && activity.data.old) {
			activityObj.data.new.location_name = activityObj.data.new.location ? activityObj.data.new.location?.name : null;
			activityObj.data.old.location_name = activityObj.data.old.location ? activityObj.data.old.location?.name : null;
			activityObj.data.new.buyer_name = activityObj.data.new.buyer ? activityObj.data.new.buyer?.name : null;
			activityObj.data.old.buyer_name = activityObj.data.old.buyer ? activityObj.data.old.buyer?.name : null;
			activityObj.data.new.seller_name = activityObj.data.new.seller ? activityObj.data.new.seller?.name : null;
			activityObj.data.old.seller_name = activityObj.data.old.seller ? activityObj.data.old.seller?.name : null;
			activityObj.data.old.buyer_relations_owner =
				activityObj.data.old.buyer_relations_owner?.firstName || activityObj.data.old.buyer_relations_owner?.lastName
					? generateFullName(activityObj.data.old.buyer_relations_owner?.firstName, activityObj.data.old.buyer_relations_owner?.lastName)
					: null;
		}

		// Always run to handle case where data.old is null and data.new.buyer_relations_owner is an empty object (default)
		activityObj.data.new.buyer_relations_owner =
			activity.data.new.buyer_relations_owner?.firstName || activity.data.new.buyer_relations_owner?.lastName
				? generateFullName(activity.data.new.buyer_relations_owner?.firstName, activity.data.new.buyer_relations_owner?.lastName)
				: null;

		!activityObj.isFeed ? excludedItemsArray.push('deliveryTerms') : null;
		!activityObj.isFillModificationRequest ? excludedItemsArray.push('type') : null;

		const messages: Array<string> = [];
		// eslint-disable-next-line prefer-const
		for (let [key, value] of Object.entries(activityObj.data.new)) {
			const diffCheck =
				activityObj.data.new && activityObj.data.old ? Object.is(activityObj.data.new[key], activityObj.data.old[key]) : false;
			const isIDProperty = key.toLowerCase().includes('id');
			if (diffCheck === false && !isIDProperty && !excludedItemsArray.find((item) => item === key)) {
				if (key === 'sales_type' && typeof value == 'string') {
					value = getSalesTypeDisplayValue(value);
				}

				if (key === 'status' && typeof value == 'string') {
					value = getGrainOrderStatusDisplayValue(value);
				}

				if (key === 'type' && typeof value == 'string') {
					value = titleize(dedasherize(value));
				}

				let formattedKey = formatKey(key, activityObj.isFillModificationRequest);
				//	if (typeof value != 'string') return null;
				if (typeof value == 'string') {
					const date = DateTime.fromISO(value);

					if (formatMonthYearArray.find((item) => item === formattedKey)) {
						value = date.toFormat('MMM yyyy');
					}

					if (formatShortDateArray.find((item) => item === formattedKey)) {
						value = date.toLocaleString();
					}
				} else if (typeof value == 'number') {
					if (formatDollarArray.find((item) => item === formattedKey)) {
						value = new Intl.NumberFormat('en-US', {
							style: 'currency',
							currency: 'USD',
							minimumFractionDigits: 2,
							maximumFractionDigits: 5,
						}).format(value);
					}

					if (formatNumberArray.find((item) => item === formattedKey)) {
						value = new Intl.NumberFormat().format(value as number);
					} else {
						null;
					}
				}

				activityObj.isFeed && formattedKey === 'Bushels' ? (formattedKey = 'Tons') : formattedKey;

				value == null || value == 'Invalid DateTime' ? messages.push(`${formattedKey}: -`) : messages.push(`${formattedKey}: ${value}`);
			}
		}
		activityObj.messages = messages;
		return activityObj;
	});

	return activities;
}
