import Controller from '@ember/controller';
import VgsFeedServicesModificationRequestsRoute from 'vault-client/routes/vgs/feed-services/modification-requests';
import { ModelFrom } from 'vault-client/utils/type-utils';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import PermissionsService from 'vault-client/services/permissions';
import { GrainFillOrderModificationStatus } from 'vault-client/types/graphql-types';
export default class VgsFeedServicesModificationRequests extends Controller {
	@service declare permissions: PermissionsService;
	declare model: ModelFrom<VgsFeedServicesModificationRequestsRoute>;

	queryParams = ['modificationStatus'];

	@tracked modificationStatus: GrainFillOrderModificationStatus | null = GrainFillOrderModificationStatus.New;

	get modificationRequests() {
		return this.model?.feedRequests?.data?.GrainFillOrderModificationRequests ?? null;
	}

	@action
	setModStatus(status: GrainFillOrderModificationStatus | null) {
		this.modificationStatus = status;
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	interface Registry {
		'vgs/feed-services/modification-requests': VgsFeedServicesModificationRequests;
	}
}
