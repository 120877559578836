import { registerDestructor } from '@ember/destroyable';
import Modifier from 'ember-modifier';
//@ts-ignore
import Sortable from 'sortablejs/modular/sortable.complete.esm.js';

function cleanup(instance: SetSortableListModifier): void {
	if (instance.sortableList) {
		//@ts-ignore
		instance.sortableList?.destroy();
	}
}
export default class SetSortableListModifier extends Modifier {
	sortableList = null;

	modify(element: HTMLElement, _positional: any, named: any) {
		const sortableSettings: any = {};

		// sortableJS autoscroll plugin options
		if (named.scroll) {
			sortableSettings.scroll = document.getElementById(named.scroll) || true;
		}
		if (named.forceAutoscrollFallback) {
			sortableSettings.forceAutoscrollFallback = named.forceAutoscrollFallback;
		}
		if (named.scrollFn) {
			sortableSettings.scrollFn = named.scrollFn;
		}
		if (named.scrollSensitivity) {
			sortableSettings.scrollSensitivity = named.scrollSensitivity;
		}
		if (named.scrollSpeed) {
			sortableSettings.scrollSpeed = named.scrollSpeed;
		}
		if (named.bubbleScroll) {
			sortableSettings.bubbleScroll = named.bubbleScroll;
		}

		// sortableJS base library options
		if (named.groupName) {
			sortableSettings.group = named.groupName;
		}
		if (named.sort) {
			sortableSettings.sort = named.sort;
		}
		if (named.delay) {
			sortableSettings.delay = named.delay;
		}
		if (named.delayOnTouchOnly) {
			sortableSettings.delayOnTouchOnly = named.delayOnTouchOnly;
		}
		if (named.touchStartThreshold) {
			sortableSettings.touchStartThreshold = named.touchStartThreshold;
		}
		if (named.disabled) {
			sortableSettings.disabled = named.disabled;
		}
		if (named.store) {
			sortableSettings.store = named.store;
		}
		if (named.animation) {
			sortableSettings.animation = named.animation;
		}
		if (named.easing) {
			sortableSettings.easing = named.easing;
		}
		if (named.handle) {
			sortableSettings.handle = named.handle;
		}
		if (named.filter) {
			sortableSettings.filter = named.filter;
		}
		if (named.preventOnFilter) {
			sortableSettings.preventOnFilter = named.preventOnFilter;
		}
		if (named.draggable) {
			sortableSettings.draggable = named.draggable;
		}
		if (named.dataIdAttr) {
			sortableSettings.dataIdAttr = named.dataIdAttr;
		}
		if (named.ghostClass) {
			sortableSettings.ghostClass = named.ghostClass;
		}
		if (named.chosenClass) {
			sortableSettings.chosenClass = named.chosenClass;
		}
		if (named.dragClass) {
			sortableSettings.dragClass = named.dragClass;
		}
		if (named.swapThreshold) {
			sortableSettings.swapThreshold = named.swapThreshold;
		}
		if (named.invertSwap) {
			sortableSettings.invertSwap = named.invertSwap;
		}
		if (named.invertedSwapThreshold) {
			sortableSettings.invertedSwapThreshold = named.invertedSwapThreshold;
		}
		if (named.direction) {
			sortableSettings.direction = named.direction;
		}
		if (named.forceFallback) {
			sortableSettings.forceFallback = named.forceFallback;
		}
		if (named.fallbackClass) {
			sortableSettings.allbackClass = named.fallbackClass;
		}
		if (named.fallbackOnBody) {
			sortableSettings.fallbackOnBody = named.fallbackOnBody;
		}
		if (named.fallbackTolerance) {
			sortableSettings.fallbackTolerance = named.fallbackTolerance;
		}
		if (named.dragoverBubble) {
			sortableSettings.dragoverBubble = named.dragoverBubble;
		}
		if (named.removeCloneOnHide) {
			sortableSettings.removeCloneOnHide = named.removeCloneOnHide;
		}
		if (named.emptyInsertThreshold) {
			sortableSettings.emptyInsertThreshold = named.emptyInsertThreshold;
		}
		if (named.setData) {
			sortableSettings.setData = (dataTransfer: any, dragEl: any) => {
				named.setData(dataTransfer, dragEl);
			};
		}
		if (named.onChoose) {
			sortableSettings.onChoose = (evt: any) => {
				named.onChoose(evt);
			};
		}
		if (named.onStart) {
			sortableSettings.onStart = (evt: any) => {
				named.onStart(evt);
			};
		}
		if (named.onEnd) {
			sortableSettings.onEnd = (evt: any) => {
				named.onEnd(evt);
			};
		}
		if (named.onAdd) {
			sortableSettings.onAdd = (evt: any) => {
				named.onAdd(evt);
			};
		}
		if (named.onUpdate) {
			sortableSettings.onUpdate = (evt: any) => {
				named.onUpdate(evt);
			};
		}
		if (named.onSort) {
			sortableSettings.onSort = (evt: any) => {
				named.onSort(evt);
			};
		}
		if (named.onRemove) {
			sortableSettings.onRemove = (evt: any) => {
				named.onRemove(evt);
			};
		}
		if (named.onFilter) {
			sortableSettings.onFilter = (evt: any) => {
				named.onFilter(evt);
			};
		}
		if (named.onMove) {
			sortableSettings.onMove = (evt: any, originalEvent: any) => {
				named.onMove(evt, originalEvent);
			};
		}
		if (named.onClone) {
			sortableSettings.onClone = (evt: any) => {
				named.onClone(evt);
			};
		}
		if (named.onChange) {
			sortableSettings.onChange = (evt: any) => {
				named.onChange(evt);
			};
		}

		// create sortable list
		if (element) {
			this.sortableList = new Sortable(element, sortableSettings);
		}

		registerDestructor(this, cleanup);
	}
}

// documentation for ember-modifier https://github.com/ember-modifier/ember-modifier
// documentation for base library options https://github.com/SortableJS/Sortable
// documentation for autoscroll plugin options https://github.com/SortableJS/Sortable/tree/master/plugins/AutoScroll
