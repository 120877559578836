import InsurancePoliciesIndexController from 'vault-client/controllers/insurance-policies';

export default class BusinessesBusinessInsurancePolicies extends InsurancePoliciesIndexController {
	insurancePolicyRoute: string = 'businesses.business.insurance-policy';
	insuranceRoutePath: string = 'businesses.business.insurance';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'businesses/business/insurance-policies': BusinessesBusinessInsurancePolicies;
	}
}
