import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { TableColumn, CellComponents } from 'vault-client/types/vault-table';
import { DateTime } from 'luxon';
import { action } from '@ember/object';
import getFilterDisplayProperty from 'vault-client/utils/get-filter-display-property';
import accountFuzzyQuery from 'vault-client/graphql/queries/account-fuzzy-match.graphql';
import { queryManager } from 'ember-apollo-client';
import { AggregateHistoricalPnlDTO, Side, TypeOfInstrument } from 'vault-client/types/graphql-types';
import { ModelFrom } from 'vault-client/utils/type-utils';
import ReportsPlByCalendarMonthIndexRoute from 'vault-client/routes/businesses/business/pl-by-calendar-month';

class Month {
	date: string;
	startingRows: AggregateHistoricalPnlDTO[] = [];
	endingRows: AggregateHistoricalPnlDTO[] = [];

	constructor(date: string) {
		this.date = date;
	}

	get shortOption(): AggregateHistoricalPnlDTO | undefined {
		return this.endingRows.find((row) => {
			return row.side == Side.Short && row.instrumentType == TypeOfInstrument.Option;
		});
	}

	get longOption(): AggregateHistoricalPnlDTO | undefined {
		return this.endingRows.find((row) => {
			return row.side == Side.Long && row.instrumentType == TypeOfInstrument.Option;
		});
	}

	get closedOption(): AggregateHistoricalPnlDTO | undefined {
		return this.endingRows.find((row) => {
			return row.side == Side.Closed && row.instrumentType == TypeOfInstrument.Option;
		});
	}

	get shortFuture(): AggregateHistoricalPnlDTO | undefined {
		return this.endingRows.find((row) => {
			return row.side == Side.Short && row.instrumentType == TypeOfInstrument.Future;
		});
	}

	get longFuture(): AggregateHistoricalPnlDTO | undefined {
		return this.endingRows.find((row) => {
			return row.side == Side.Long && row.instrumentType == TypeOfInstrument.Future;
		});
	}

	get closedFuture(): AggregateHistoricalPnlDTO | undefined {
		return this.endingRows.find((row) => {
			return row.side == Side.Closed && row.instrumentType == TypeOfInstrument.Future;
		});
	}

	get futureRealizedPnl() {
		return (this.shortFuture?.sum.realizedPnl ?? 0) + (this.longFuture?.sum.realizedPnl ?? 0) + (this.closedFuture?.sum.realizedPnl ?? 0);
	}

	get futureUnrealizedPnl() {
		return (this.shortFuture?.sum.unrealizedPnl ?? 0) + (this.longFuture?.sum.unrealizedPnl ?? 0);
	}

	get startingFees() {
		return this.startingRows.reduce((arr, row) => {
			return arr + (row.sum?.feeTotal ?? 0);
		}, 0);
	}

	get startingCommissions() {
		return this.startingRows.reduce((arr, row) => {
			return arr + (row.sum?.commissionTotal ?? 0);
		}, 0);
	}

	get endingFees() {
		return this.endingRows.reduce((arr, row) => {
			return arr + (row.sum?.feeTotal ?? 0);
		}, 0);
	}

	get endingCommissions() {
		return this.endingRows.reduce((arr, row) => {
			return arr + (row.sum?.commissionTotal ?? 0);
		}, 0);
	}

	get fees() {
		return this.endingFees - this.startingFees;
	}

	get commissions() {
		return this.endingCommissions - this.startingCommissions;
	}
}

export default class ReportsPlByCalendarMonthIndex extends Controller {
	@queryManager apollo: any;
	queryParams = ['calendarMonthStartDate', 'calendarMonthEndDate', 'expirationMonthStartDate', 'expirationMonthEndDate', 'accountId'];
	@tracked calendarMonthStartDate = DateTime.local().startOf('month').minus({ years: 1 }).toISODate();
	@tracked calendarMonthEndDate = DateTime.local().endOf('month').toISODate();
	@tracked expirationMonthStartDate = '1900-01-01';
	@tracked expirationMonthEndDate = '2999-12-31';
	@tracked organizationId: any;
	@tracked customerId: any;
	@tracked locationId: any;
	@tracked accountId: any;
	@tracked currentScope: string | null = '';
	declare model: ModelFrom<ReportsPlByCalendarMonthIndexRoute>;
	@tracked columns: TableColumn[] = [
		{
			id: 'eead64b2-12d6-43b7-a7bf-a9cdf8361ad2',
			name: 'Month',
			valuePath: 'date',
			minWidth: 90,
			cellComponent: CellComponents.MonthFormat,
			textAlign: 'left',
			linkRoute: 'businesses.business.pl-by-calendar-month.show',
			linkModelPath: 'date',
			isSortable: false,
			isFixed: 'left',
			isVisible: true,
		},
		{
			id: '33481662-d5c2-4484-a880-0cdcec8d55ef',
			name: 'Short Options',
			cellComponent: CellComponents.IntlNumberFormat,
			textAlign: 'right',
			isSortable: false,
			isFixed: '',
			isVisible: true,
			subcolumns: [
				{
					id: '33481662-d5c2-4484-a880-0cdcec8d55ef',
					name: 'Market Value',
					valuePath: 'shortOption.sum.marketValue',
					cellComponent: CellComponents.IntlNumberFormat,
					componentArgs: {
						style: 'currency',
						currency: 'USD',
						currencySign: 'accounting',
					},
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
				{
					id: '77d9554f-7122-4c79-90d1-d2aa3bf6e2a9',
					name: 'Unrealized P/L',
					valuePath: 'shortOption.sum.unrealizedPnl',
					cellComponent: CellComponents.IntlNumberFormat,
					componentArgs: {
						style: 'currency',
						currency: 'USD',
						currencySign: 'accounting',
					},
					textAlign: 'right',
					isSortable: false,
					isVisible: true,
					isFixed: '',
				},
			],
		},
		{
			id: '7db19a30-dd3d-466c-ac0d-47962c69eb59',
			name: 'Long Options',
			cellComponent: CellComponents.IntlNumberFormat,
			textAlign: 'right',
			isSortable: false,
			isFixed: '',
			isVisible: true,
			subcolumns: [
				{
					id: 'ed63c05f-fe6e-437f-a2b3-f5cb6b8d2018',
					name: 'Market Value',
					valuePath: 'longOption.sum.marketValue',
					cellComponent: CellComponents.IntlNumberFormat,
					componentArgs: {
						style: 'currency',
						currency: 'USD',
						currencySign: 'accounting',
					},
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},

				{
					id: '574B8A9A-27AE-41B0-82A2-4BC9A0E3CD36',
					name: 'Unrealized P/L',
					valuePath: 'longOption.sum.unrealizedPnl',
					cellComponent: CellComponents.IntlNumberFormat,
					componentArgs: {
						style: 'currency',
						currency: 'USD',
						currencySign: 'accounting',
					},
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
			],
		},

		{
			id: '6d8b9efa-8fb9-42d3-b6dc-fb7cd1a9894f',
			name: 'Futures',
			cellComponent: CellComponents.IntlNumberFormat,
			textAlign: 'right',
			isSortable: false,
			isVisible: true,
			isFixed: '',
			subcolumns: [
				{
					id: 'A4CABC4D-3724-41FF-A075-06268E1B3C1F',
					name: 'Realized P/L',
					valuePath: 'futureRealizedPnl',
					cellComponent: CellComponents.IntlNumberFormat,
					componentArgs: {
						style: 'currency',
						currency: 'USD',
						currencySign: 'accounting',
					},
					textAlign: 'right',
					isSortable: false,
					isVisible: true,
					isFixed: '',
				},

				{
					id: 'B17E5146-BC42-4152-9D53-1EBB10D6C80A',
					name: 'Unrealized P/L',
					valuePath: 'futureUnrealizedPnl',
					cellComponent: CellComponents.IntlNumberFormat,
					componentArgs: {
						style: 'currency',
						currency: 'USD',
						currencySign: 'accounting',
					},
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
			],
		},
		{
			id: 'cda5ab6f-e0bf-4080-b2d2-d6b3bc939df8',
			name: 'Commissions',
			cellComponent: CellComponents.IntlNumberFormat,
			textAlign: 'right',
			valuePath: 'commissions',
			componentArgs: {
				style: 'currency',
				currency: 'USD',
				currencySign: 'accounting',
			},
			isSortable: false,
			isVisible: true,
			isFixed: '',
		},
		{
			id: '30e856e7-9c56-492e-94e2-5116e0db64ce',
			name: 'Fees',
			cellComponent: CellComponents.IntlNumberFormat,
			valuePath: 'fees',
			textAlign: 'right',
			componentArgs: {
				style: 'currency',
				currency: 'USD',
				currencySign: 'accounting',
			},
			isSortable: false,
			isVisible: true,
			isFixed: '',
		},
	];

	calendarMonthEndDateRangeOptions = [
		{
			displayName: 'Last 12 months',
			startDate: DateTime.local().startOf('month').minus({ years: 1 }).toISODate(),
			endDate: DateTime.local().endOf('month').toISODate(),
		},
		{
			displayName: `Calendar Year (${DateTime.local().minus({ years: 1 }).year}) `,
			startDate: DateTime.local().minus({ years: 1 }).startOf('year').toISODate(),
			endDate: DateTime.local().minus({ years: 1 }).endOf('year').toISODate(),
		},
		{
			displayName: `Calendar Year (${DateTime.local().year}) `,
			startDate: DateTime.local().startOf('year').toISODate(),
			endDate: DateTime.local().endOf('year').toISODate(),
		},
	];

	expirationMonthDateRangeOptions = [
		{
			displayName: 'All Months',
			startDate: '1900-01-01',
			endDate: '2999-12-31',
		},
		{
			displayName: DateTime.local().startOf('month').toFormat('MMMM yyyy'),
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().endOf('month').toISODate(),
		},
		{
			displayName: DateTime.local().startOf('month').plus({ months: 1 }).toFormat('MMMM yyyy'),
			startDate: DateTime.local().startOf('month').plus({ months: 1 }).toISODate(),
			endDate: DateTime.local().endOf('month').plus({ months: 1 }).toISODate(),
		},
		{
			displayName: DateTime.local().startOf('month').plus({ months: 2 }).toFormat('MMMM yyyy'),
			startDate: DateTime.local().startOf('month').plus({ months: 2 }).toISODate(),
			endDate: DateTime.local().endOf('month').plus({ months: 2 }).toISODate(),
		},
		{
			displayName: DateTime.local().startOf('month').plus({ months: 3 }).toFormat('MMMM yyyy'),
			startDate: DateTime.local().startOf('month').plus({ months: 3 }).toISODate(),
			endDate: DateTime.local().endOf('month').plus({ months: 3 }).toISODate(),
		},
		{
			displayName: DateTime.local().startOf('month').plus({ months: 4 }).toFormat('MMMM yyyy'),
			startDate: DateTime.local().startOf('month').plus({ months: 4 }).toISODate(),
			endDate: DateTime.local().endOf('month').plus({ months: 4 }).toISODate(),
		},
		{
			displayName: DateTime.local().startOf('month').plus({ months: 5 }).toFormat('MMMM yyyy'),
			startDate: DateTime.local().startOf('month').plus({ months: 5 }).toISODate(),
			endDate: DateTime.local().endOf('month').plus({ months: 5 }).toISODate(),
		},
		{
			displayName: DateTime.local().startOf('month').plus({ months: 6 }).toFormat('MMMM yyyy'),
			startDate: DateTime.local().startOf('month').plus({ months: 6 }).toISODate(),
			endDate: DateTime.local().endOf('month').plus({ months: 6 }).toISODate(),
		},
	];

	get months(): Map<string, Month> {
		const m: Map<string, Month> = new Map();
		let currentDate = DateTime.fromISO(this.calendarMonthStartDate);
		let currentDateIso;
		const endDate = DateTime.fromISO(this.calendarMonthEndDate);

		while ((endDate.diff(currentDate).toObject().milliseconds ?? 0) > 0) {
			currentDateIso = currentDate.toISODate();
			m.set(currentDateIso, new Month(currentDateIso));
			currentDate = currentDate.plus({ month: 1 });
		}

		return m;
	}

	get searchFilterQueryParams() {
		const obj = {};
		const searchQueryParams = ['accountId'];
		searchQueryParams.forEach((param) => {
			// @ts-ignore
			const value = this[param];
			if (value) {
				// @ts-ignore
				obj[param] = {
					filterRule: 'equals',
					filterValue: value,
				};

				// set filterComponent property to specify component for custom display extended from search-filter
				const filterDisplayObj = getFilterDisplayProperty(param);
				if (filterDisplayObj && filterDisplayObj.customComponent) {
					// @ts-ignore
					obj[param].filterComponent = filterDisplayObj.customComponent;
				}
				// set filterLabel property to specify custom label for filter - default would be filterIdentifier (matches queryParam)
				if (filterDisplayObj && filterDisplayObj.label) {
					// @ts-ignore
					obj[param].filterLabel = filterDisplayObj.label;
				}
			}
		});

		return obj;
	}

	get scopeSearchFilterQueryParams() {
		if (this.customerId) {
			// @ts-ignore
			// eslint-disable-next-line no-unused-vars
			const { customerId, ...rest } = this.searchFilterQueryParams;
			return rest;
		} else {
			return this.searchFilterQueryParams;
		}
	}

	get searchPlaceholder() {
		return this.customerId ? 'Filter by account...' : 'Filter by business, account...';
	}

	get searchPrompt() {
		return this.customerId ? 'Type a search term to find values by account.' : 'Type a search term to find values by business or account.';
	}

	@action
	async fetchSearchResults(searchText: string) {
		let scopedCustomerAccounts = [];

		const accountVariables = {
			where: {
				customerId: { equals: this.customerId },
				OR: [
					{
						accountNumber: { contains: searchText },
					},
					{
						name: { contains: searchText },
					},
					{
						id: { contains: searchText },
					},
				],
			},
		};

		scopedCustomerAccounts = (
			await this.apollo.watchQuery({
				query: accountFuzzyQuery,
				variables: accountVariables,
			})
		).Accounts.map((account: any) => {
			const newObj = {
				id: account.id,
				name: account.name,
				// does this encapsulate all accounts where type is different than would see in search api?
				type: account.type === ('Brokerage' || 'Swap') ? account.__typename : account.type,
				accountNumber: account.accountNumber,
			};
			return newObj;
		});

		return [...scopedCustomerAccounts].sort((a, b) => {
			if (a.type < b.type) {
				return -1;
			} else if (a.type > b.type) {
				return 1;
			} else {
				return 0;
			}
		});
	}

	@action
	structureSearchResults(searchResults: any) {
		const map = new Map();

		searchResults.forEach((item: any) => {
			if (map.has(item.type)) {
				map.get(item.type).push({
					id: item.id,
					name: item.name,
					type: item.type,
					accountNumber: item.accountNumber ?? null,
				});
			} else {
				map.set(item.type, [
					{
						id: item.id,
						name: item.name,
						type: item.type,
						accountNumber: item.accountNumber ?? null,
					},
				]);
			}
		});

		return map;
	}

	@action
	setSearchFilterQueryParam(searchResult: any) {
		const mappedSearchFilter = this.mapSearchResult(searchResult);
		// @ts-ignore
		this[mappedSearchFilter.filterIdentifier] = mappedSearchFilter.filterValue;
	}

	mapSearchResult(searchResult: any) {
		let filterIdentifier;

		switch (searchResult.type) {
			case 'BasisInstrument':
				filterIdentifier = 'instrumentId';
				break;
			case 'BrokerageAccount':
				filterIdentifier = 'accountId';
				break;
			case 'CashInstrument':
				filterIdentifier = 'instrumentId';
				break;
			case 'CommitmentInstrument':
				filterIdentifier = 'instrumentId';
				break;
			case 'Customer':
				filterIdentifier = 'customerId';
				break;
			// case 'ForwardContract':
			//   filterIdentifier = '';
			//   break;
			case 'Future':
				filterIdentifier = 'instrumentId';
				break;
			// case 'Location':
			//   filterIdentifier = '';
			//   break;
			case 'Option':
				filterIdentifier = 'instrumentId';
				break;
			// case 'Organization':
			//   filterIdentifier = '';
			//   break;
			case 'PhysicalInstrument':
				filterIdentifier = 'instrumentId';
				break;
			case 'SpreadInstrument':
				filterIdentifier = 'instrumentId';
				break;
			case 'SwapAccount':
				filterIdentifier = 'accountId';
				break;
			case 'SwapInstrument':
				filterIdentifier = 'instrumentId';
				break;
			case 'SwaptionInstrument':
				filterIdentifier = 'instrumentId';
				break;
		}

		return {
			filterIdentifier,
			filterValue: searchResult.id,
		};
	}

	@action
	clearSearchFilterQueryParam(filterIdentifier: any) {
		// @ts-ignore
		this[`${filterIdentifier}`] = null;
	}

	get rows() {
		const months = this.months;

		this.model.AggregateHistoricalPnl.forEach((row: AggregateHistoricalPnlDTO) => {
			const date = row?.date || null;

			if (date === null) return;

			const month = months.get(date);
			month?.startingRows.push(row);

			const previousDate = DateTime.fromISO(date).minus({ month: 1 }).toISODate();
			const previousMonth = months.get(previousDate);
			previousMonth?.endingRows.push(row);
		});

		return Array.from(months.values());
	}

	get calendarMonthEndDateQueryParam() {
		return {
			startDate: this.calendarMonthStartDate,
			endDate: this.calendarMonthEndDate,
		};
	}

	get expirationMonthDateQueryParam() {
		return {
			startDate: this.expirationMonthStartDate,
			endDate: this.expirationMonthEndDate,
		};
	}

	@action
	setDateFilterQueryParam(queryParam: string, value: any) {
		//@ts-ignore
		this[`${queryParam}StartDate`] = value.startDate;
		//@ts-ignore
		this[`${queryParam}EndDate`] = value.endDate;
		// this.setTablePageState();
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'reports/pl-by-calendar-month/index': ReportsPlByCalendarMonthIndex;
	}
}
