import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { CellComponents, SortObj, TableColumn } from 'vault-client/types/vault-table';
import { DateTime } from 'luxon';
import { action } from '@ember/object';
import { ChartData, ChartOptions, Chart } from 'chart.js';
import { guidFor } from '@ember/object/internals';
import { getCustomLegend, getCustomTooltip } from 'vault-client/utils/chart-utils';

export default class MarketsProductCMESpot extends Controller {
	queryParams = ['showAllOptions'];

	@service router: any;
	@tracked sorts: SortObj[] = [{ valuePath: 'strike', isAscending: true }];
	@tracked showAllOptions = true;
	@tracked expirationDate!: string;
	@tracked optionName!: string;
	@tracked startDate: string = DateTime.now().minus({ month: 1 }).toISODate();
	@tracked endDate: string = DateTime.now().toISODate();
	@tracked model: any;

	@tracked columns: TableColumn[] = [
		{
			id: 'fbab8898-49d1-440f-bb76-7133dd34ef6b',
			name: 'Trade Date',
			valuePath: 'tradingDay',
			cellComponent: CellComponents.IntlDateTimeFormat,
			textAlign: 'left',
			isFixed: '',
			isVisible: true,
		},
		{
			id: '646b4c56-5aeb-4472-bde3-d015d8227d32',
			name: 'Last Price',
			valuePath: 'close',
			cellComponent: CellComponents.PriceFormat,
			componentArgs: {
				fractionDigitsPath: 'fractionDigits',
				displayFactorPath: 'displayFactor',
			},
			textAlign: 'right',
			isSortable: true,
			isFixed: '',
			isVisible: true,
		},
		{
			id: '2a9e7cfc-72fa-439c-b6f7-a65d8d75a297',
			name: 'Session Change',
			cellComponent: CellComponents.SessionChangeFormat,
			componentArgs: {
				fractionDigitsPath: 'fractionDigits',
				displayFactorPath: 'displayFactor',
			},
			valuePath: 'self',
			textAlign: 'right',
			isFixed: '',
			isVisible: true,
		},
		{
			id: 'c6da4051-a6d6-4213-b579-ad19575d61a8',
			name: 'High',
			cellComponent: CellComponents.PriceFormat,
			componentArgs: {
				fractionDigitsPath: 'fractionDigits',
				displayFactorPath: 'displayFactor',
			},
			valuePath: 'high',
			textAlign: 'center',
			isFixed: '',
			isVisible: true,
		},
		{
			id: '2fdc44cb-1ad6-4770-9a9a-29db56609f5e',
			name: 'Low',
			cellComponent: CellComponents.PriceFormat,
			componentArgs: {
				fractionDigitsPath: 'fractionDigits',
				displayFactorPath: 'displayFactor',
			},
			valuePath: 'low',
			textAlign: 'right',
			isFixed: '',
			isVisible: true,
		},
	];

	dateRangeOptions = [
		{
			label: '1 W',
			startDate: DateTime.now().minus({ day: 7 }).toISODate(),
			endDate: DateTime.now().toISODate(),
		},
		{
			label: '1 M',
			startDate: DateTime.now().minus({ month: 1 }).toISODate(),
			endDate: DateTime.now().toISODate(),
		},
		{
			label: '3 M',
			startDate: DateTime.now().minus({ months: 3 }).toISODate(),
			endDate: DateTime.now().toISODate(),
		},
		{
			label: 'YTD',
			startDate: DateTime.now().startOf('year').toISODate(),
			endDate: DateTime.now().toISODate(),
		},
	];

	id = guidFor(this);

	@action
	setDateRange(value: { startDate: string; endDate: string }) {
		if (value.startDate && value.endDate) {
			this.startDate = value.startDate;
			this.endDate = value.endDate;
		}
	}

	get tableData() {
		return (
			this.model.SpotInstrumentData?.map((day: any) => {
				return {
					...day,
					barchartSymbol: day.symbol,
					displayFactor: this.model.SpotInstrument.SymbolGroup.displayFactor,
					fractionDigits: this.model.SpotInstrument.SymbolGroup.fractionDigits,
				};
			}) ?? []
		);
	}

	get chartId() {
		return `${this.model.Product.name.toLowerCase()}-cme-spot-chart`;
	}

	get legendId() {
		return `${this.model.Product.name.toLowerCase()}-cme-spot-chart-legend`;
	}

	get labels() {
		const labelSet = new Set<string>();
		this.model.SpotInstrumentData?.map((day: any) => labelSet.add(day.tradingDay)) ?? [];
		this.model.FrontFutureData?.map((day: any) => labelSet.add(day.tradingDay)) ?? [];

		return Array.from(labelSet).sort((a, b) => (a < b ? -1 : 1));
	}

	get data() {
		const spotInstrumentLabel = `${this.model.Product.name} Cash`;
		const frontFutureLabel = `${this.model.Product.name} Front Future`;

		return {
			[spotInstrumentLabel]:
				this.model.SpotInstrumentData?.map((day: any) => ({
					y: this.model.SpotInstrument.SymbolGroup.displayFactor
						? day.close * this.model.SpotInstrument.SymbolGroup.displayFactor
						: day.close,
					x: day.tradingDay,
				})) ?? [],
			[frontFutureLabel]:
				this.model.FrontFutureData?.map((day: any) => ({
					y: day.close * this.model.FrontFuture.SymbolGroup.displayFactor,
					x: day.tradingDay,
				})) ?? [],
		};
	}

	get plugins() {
		const chartId = this.chartId;
		const legendId = this.legendId;

		return [
			{
				afterUpdate: getCustomLegend(chartId, legendId),
			},
		];
	}

	get chartData(): ChartData<'line'> {
		const datasets = [
			{
				tension: 0.3,
				label: `${this.model.Product.name} Cash`,
				data: this.data[`${this.model.Product.name} Cash`],
				backgroundColor: '#0068C9',
				borderColor: '#0068C9',
				pointBackgroundColor: '#E3E3E3',
			},
		];

		if (this.model.FrontFuture) {
			datasets.push({
				tension: 0.3,
				label: `${this.model.Product.name} Front Future`,
				data: this.data[`${this.model.Product.name} Front Future`],
				backgroundColor: '#019F9F',
				borderColor: '#019F9F',
				pointBackgroundColor: '#E3E3E3',
			});
		}

		return {
			labels: this.labels,
			datasets,
		};
	}

	get chartOptions(): ChartOptions<'line'> {
		return {
			maintainAspectRatio: false,
			spanGaps: true,
			responsive: true,
			normalized: true,
			interaction: {
				intersect: false,
				mode: 'nearest',
				axis: 'x',
			},
			layout: {
				padding: {
					left: 20,
					right: 20,
					bottom: 10,
				},
			},
			elements: {
				point: {
					radius: () => (this.labels.length > 100 ? 0 : 4),
				},
			},
			scales: {
				y: {
					ticks: {
						font: {
							size: 12,
						},
						color: getComputedStyle(document.documentElement).getPropertyValue('--brand-gray-800'),
						callback: (value: number) => {
							return value.toFixed(this.model.SpotInstrument.SymbolGroup.fractionDigits);
						},
					},
				},
				x: {
					type: 'time',
					time: {
						unit: 'day',
						displayFormats: {
							day: 'yyyy-LL-dd',
						},
					},
					ticks: {
						major: {
							enabled: true,
						},
						color: getComputedStyle(document.documentElement).getPropertyValue('--brand-gray-800'),
						font: {
							size: 12,
						},
						callback: (_label, index, ticks) => {
							const currentTick = ticks[index];
							const currentDateTime = DateTime.fromMillis(currentTick.value);
							const prevDateTime = index > 0 ? DateTime.fromMillis(ticks[index - 1].value) : null;

							if (!prevDateTime || prevDateTime.year !== currentDateTime.year) {
								currentTick.major = true;
								return currentDateTime.toFormat('LLL d, yyyy');
							} else {
								currentTick.major = false;
								return currentDateTime.toFormat('LLL d');
							}
						},
					},
					grid: {
						display: false,
					},
				},
			},
			plugins: {
				legend: {
					display: false,
				},
				tooltip: {
					enabled: false,
					callbacks: {
						title: (ctx) => {
							const isoDate = (ctx[0]?.raw as { x?: string | number } | undefined)?.x;
							if (!isoDate) return '';
							if (typeof isoDate === 'number') return isoDate.toString();
							return DateTime.fromISO(isoDate).toFormat('LLL d, yyyy');
						},
					},
					external: getCustomTooltip({
						valueFormatter: (val: { x?: string; y?: number }) => {
							const { y: price } = val;
							if (!price) return '';
							return price.toFixed(this.model.SpotInstrument.SymbolGroup.fractionDigits);
						},
					}),
				},
			},
		};
	}

	@action
	updateChart(chart: Chart) {
		chart.data.labels = this.labels;
		chart.data.datasets.forEach((dataset) => {
			dataset.data = this.data[dataset.label as string];
		});
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'markets/product/cme-spot': MarketsProductCMESpot;
	}
}
