import LgmInsuranceEndorsementsShowController from 'vault-client/controllers/lgm-insurance-endorsements/show';

export default class BusinessesBusinessLgmInsuranceEndorsement extends LgmInsuranceEndorsementsShowController {
	lgmInsuranceEndorsementsRoute: string = 'businesses.business.lgm-insurance-endorsements';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	interface Registry {
		'businesses/business/lgm-insurance-endorsement': BusinessesBusinessLgmInsuranceEndorsement;
	}
}
