import Route from '@ember/routing/route';
import query from 'vault-client/graphql/queries/reports/historical-percentiles.graphql';
import { queryManager } from 'ember-apollo-client';

export default class ReportsDairyHistoricalPercentilesFiveYear extends Route {
	@queryManager apollo: any;

	templateName: string = 'reports/dairy-historical-percentiles';

	async model(params: Array<string>) {
		const variables = {
			params: params,
			where: {
				slug: {
					in: [
						'us-dairy-butter',
						'us-dairy-nonfat-milk',
						'grain-corn',
						'grain-soybean-meal',
						'us-dairy-class-iii',
						'us-dairy-dry-whey',
						'us-dairy-cheese',
						'us-dairy-class-iv',
					],
				},
			},
		};

		return this.apollo.watchQuery({ query, variables });
	}
}
