import Route from '@ember/routing/route';
import { queryManager } from 'ember-apollo-client';
import query from 'vault-client/graphql/queries/reports/pl-by-calendar-and-display-month.graphql';
import { DateTime } from 'luxon';
import { HistoricalPnlFilterDTO } from 'vault-client/types/graphql-types';

interface RouteParams {
	calendar_month: string;
	accountId?: string;
}

interface QueryParams {
	where: HistoricalPnlFilterDTO;
}

export default class ReportsPlByCalendarMonthShowRoute extends Route {
	@queryManager apollo: any;

	async model(params: RouteParams) {
		const businessParams = this.paramsFor('businesses.business') as { business_id: string };

		const startDate = params.calendar_month;
		const date = DateTime.fromISO(startDate).plus({ month: 1 }).toISODate();

		// This limits the return months to those that are applicable.  The previous month could be applicable, based on when the actual expiration is.
		const displayStartDate = DateTime.fromISO(startDate).minus({ month: 1 }).toISODate();

		const variables: QueryParams = {
			where: {
				startDate,
				endDate: date,
				displayExpirationDate: {
					gte: displayStartDate,
				},
				date: {
					equals: date,
				},
				transactions: {
					Account: {
						customerId: {
							equals: businessParams.business_id,
						},
					},
				},
			},
		};

		const response = await this.apollo.watchQuery({ query, variables });

		return { calendarMonth: params.calendar_month, HistoricalPnl: response?.HistoricalPnl };
	}
}
