import Route from '@ember/routing/route';
import query from 'vault-client/graphql/queries/reports/unmapped-insurance-policies.graphql';
import { queryManager } from 'ember-apollo-client';
import { ProducerAndPolicyFilterDTO } from 'vault-client/types/graphql-types';
import { SortObj } from 'vault-client/types/vault-table';

export default class UnmappedInsurancePoliciesRoute extends Route {
	@queryManager apollo: any;

	templateName: string = 'reports/unmapped-insurance-policies';

	queryParams = {
		agent: { refreshModel: true },
		aipId: { refreshModel: true },
		stateId: { refreshModel: true },
		sorts: { refreshModel: true },
		page: { refreshModel: true },
		policyNumber: { refreshModel: true },
	};

	query = query;

	generateOrderBy(sorts: SortObj[]) {
		const orderBy: {
			[key: string]: any;
		} = {};

		sorts.forEach((sort) => {
			const value = sort.isAscending ? 'Asc' : 'Desc';
			orderBy[sort.valuePath] = value;
		});

		return orderBy;
	}

	generateWhere(
		organizationId: string | null,
		agent?: string | null,
		aipId?: string | null,
		policyNumber?: string | null,
		stateId?: string | null
	) {
		const where: ProducerAndPolicyFilterDTO = {};
		if (aipId) {
			where.aipId = {
				equals: aipId,
			};
		}

		if (policyNumber) {
			where.policyNumber = {
				equals: policyNumber,
			};
		}

		if (agent) {
			const nameArr = agent.split(' ');
			if (!where.AND) {
				where.AND = [];
			}
			where.AND.push({ agentFirstName: { equals: nameArr[0] } });
			where.AND.push({ agentLastName: { equals: nameArr[1] } });
		}

		if (stateId) {
			where.stateId = {
				equals: stateId,
			};
		}

		where.customerId = null;
		where.ownerId = { equals: organizationId ?? null };

		return where;
	}
}
