import Route from '@ember/routing/route';
import {
	ForecastedMilkProductionByMonthFilterDTO,
	ForecastedMilkProductionFilterDTO,
	ForecastedMilkProductionSetDTO,
	LocationEntityFilterDTO,
	Query,
} from 'vault-client/types/graphql-types';
import { useQuery, useMutation, gql } from 'glimmer-apollo';
import { tracked } from '@glimmer/tracking';

interface modelParams {
	id: string;
	productionMonthStartDate: string;
	productionMonthEndDate: string;
	selectedLocationId: string;
}

const GET_PRODUCTION = gql`
	query AggregateForecastedMilkProduction(
		$productionWhere: ForecastedMilkProductionByMonthFilterDTO
		$startDate: String
		$endDate: String
		$businessId: String!
		$scopeId: String!
	) {
		AggregateForecastedMilkProductionByMonths(
			calc: {
				avg: { averageDailyProductionPerCow: true }
				sum: {
					numberOfCows: true
					grossProduction: true
					grossProteinProduction: true
					grossButterfatProduction: true
					grossOtherSolidsProduction: true
				}
			}
			groupBy: { date: true }
			where: $productionWhere
			scopeId: $scopeId
			orderBy: { date: Asc }
		) {
			date
			sum {
				numberOfCows
				grossProduction
				grossProteinProduction
				grossButterfatProduction
				grossOtherSolidsProduction
			}
			avg {
				averageDailyProductionPerCow
			}
		}
		AggregateActualMilkProduction(
			calc: { sum: { grossProduction: true } }
			where: { date: { gte: $startDate, lte: $endDate } }
			groupBy: { firstDateOfMonth: true }
			scopeId: $scopeId
		) {
			firstDateOfMonth
			sum {
				grossProduction
			}
		}
		Locations(where: { customerId: { equals: $businessId } }) {
			id
			name
			CurrentUserPermissions {
				canWriteOperations
			}
		}
	}
`;

export type GetProductionQuery = {
	__typename?: 'Query';
	AggregateForecastedMilkProductionByMonths: Query['AggregateForecastedMilkProductionByMonths'];
	Locations: Query['Locations'];
	AggregateActualMilkProduction: Query['AggregateActualMilkProduction'];
};

type GetProductionQueryVariables = {
	productionWhere?: ForecastedMilkProductionFilterDTO;
	startDate?: string;
	endDate?: string;
	businessId?: string;
	scopeId?: string;
};

const SET_PRODUCTION = gql`
	mutation setAverageForecastedMilkProduction($startDate: String!, $endDate: String!, $productionPerCow: ForecastedMilkProductionSetDTO) {
		setAverageForecastedMilkProduction(startDate: $startDate, endDate: $endDate, productionPerCow: $productionPerCow) {
			id
		}
	}
`;

type SetProductionMutation = {
	__typename?: 'Mutation';

	setAverageForecastedMilkProduction?: {
		__typename?: 'setAverageForecastedMilkProduction';
		id: string;
	} | null;
};

type SetProductionMutationVariables = {
	startDate: string;
	endDate: string;
	productionPerCow?: ForecastedMilkProductionSetDTO;
};

export default class BusinessesBusinessProductionRoute extends Route {
	@tracked productionVariables: GetProductionQueryVariables = {};

	getProduction = useQuery<GetProductionQuery, GetProductionQueryVariables>(this, () => [
		GET_PRODUCTION,
		{ variables: this.productionVariables },
	]);

	setProduction = useMutation<SetProductionMutation, SetProductionMutationVariables>(this, () => [SET_PRODUCTION, {}]);

	generateProductionWhere(
		productionMonthStartDate: string | null,
		productionMonthEndDate: string | null
	): ForecastedMilkProductionByMonthFilterDTO {
		const where: ForecastedMilkProductionByMonthFilterDTO = {};

		if (productionMonthStartDate && productionMonthEndDate) {
			where.date = {
				gte: productionMonthStartDate,
				lte: productionMonthEndDate,
			};
		} else if (productionMonthStartDate) {
			where.date = { gte: productionMonthStartDate };
		} else if (productionMonthEndDate) {
			where.date = { lte: productionMonthEndDate };
		}

		return where;
	}

	generateLocationsWhere(businessId: string): LocationEntityFilterDTO {
		const where: LocationEntityFilterDTO = {};
		if (businessId) {
			where.customerId = { equals: businessId };
		}
		return where;
	}

	queryParams = {
		productionMonthStartDate: {
			refreshModel: true,
		},
		productionMonthEndDate: {
			refreshModel: true,
		},
		selectedLocationId: {
			refreshModel: true,
		},
	};

	async model(params: modelParams) {
		const businessParams = this.paramsFor('businesses.business') as { business_id: string };

		this.productionVariables = {
			startDate: params.productionMonthStartDate,
			endDate: params.productionMonthEndDate,
			productionWhere: this.generateProductionWhere(params.productionMonthStartDate, params.productionMonthEndDate),
			businessId: businessParams.business_id,
			scopeId: params.selectedLocationId || businessParams.business_id,
		};

		await this.getProduction.promise;

		return {
			getProduction: this.getProduction,
			businessId: businessParams.business_id,
		};
	}
}
