import Controller from '@ember/controller';
import { action } from '@ember/object';
import query from 'vault-client/graphql/queries/locations/inventory/index.graphql';
import resetVaultTableScroll from 'vault-client/utils/reset-vault-table-scroll';
import { TableColumn, CellComponents } from 'vault-client/types/vault-table';
import { ModelFrom } from 'vault-client/utils/type-utils';
import LocationsInventoryIndexRoute from 'vault-client/routes/locations/location/inventory';

export default class LocationsInventoryIndex extends Controller {
	declare model: ModelFrom<LocationsInventoryIndexRoute>;

	get columns(): TableColumn[] {
		const baseColumns: TableColumn[] = [
			{
				id: '86ade295-2b2f-4300-aff1-94eaa2dc066a',
				name: 'Product',
				valuePath: 'Product.name',
				minWidth: 100,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '21bf9422-cba5-42b9-b15e-bd16be284440',
				name: 'Quantity',
				valuePath: 'quantity',
				minWidth: 110,
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '38f80617-1f5e-42cb-9d6d-c8983b414cc1',
				name: 'Reporting Date/Time',
				valuePath: 'timestamp',
				width: 200,
				cellComponent: CellComponents.IntlDateTimeFormat,
				componentArgs: {
					day: 'numeric',
					month: 'numeric',
					year: 'numeric',
					hour: 'numeric',
					minute: 'numeric',
				},
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
		];
		return baseColumns;
	}

	get inventoryData() {
		return this.model.CurrentInventoryLevels;
	}

	get query() {
		return query;
	}

	@action
	setTablePageState() {
		resetVaultTableScroll('locations-inventory-index-table');
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'locations/inventory/index': LocationsInventoryIndex;
	}
}
