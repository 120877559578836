import { GrainCropPlan, GrainFillOrder, TypeOfGrainOrder, TypeOfGrainOrderStatus } from 'vault-client/types/graphql-types';
import Plan from './plan';
import Fill from './fill';

export default class CropPlan extends Plan {
	acres;
	aph;
	enrolledPercent;
	harvestBu;
	storageBu;
	Crop;
	CropYear;
	type;

	constructor(cropPlan: GrainCropPlan) {
		super(cropPlan);
		this.acres = cropPlan.acres;
		this.aph = cropPlan.aph;
		this.enrolledPercent = cropPlan.enrolledPercent;
		this.harvestBu = cropPlan.harvestBu;
		this.storageBu = cropPlan.storageBu;
		this.Crop = cropPlan.Crop;
		this.CropYear = cropPlan.CropYear;
		this.Fills = cropPlan.Orders.filter((order): order is GrainFillOrder => order.type === TypeOfGrainOrder.Fill).map(
			(fill) => new Fill(fill, cropPlan.acres, cropPlan.aph, fill.Notes, null, null, cropPlan.Customer ?? null)
		);
		this.type = cropPlan.type;
	}

	get production() {
		return this.acres * this.aph;
	}

	get enrolledProduction() {
		return this.production * (this.enrolledPercent ?? 0);
	}

	get percentSold() {
		const bushelsSold = this.Fills.reduce((acc: any, cur: any) => {
			return acc + (cur.bushels ?? 0);
		}, 0);

		return this.production === 0 ? 0 : bushelsSold / this.production;
	}

	get cropPlanName() {
		return `${this.CropYear?.year} ${this.Crop?.name}`;
	}

	get openTargets() {
		return this.Targets.filter(
			(t) =>
				t.status === TypeOfGrainOrderStatus.New ||
				t.status === TypeOfGrainOrderStatus.Pending ||
				t.status === TypeOfGrainOrderStatus.Submitted ||
				t.status === TypeOfGrainOrderStatus.Working
		);
	}
}
