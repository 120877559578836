import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { ModelFrom } from 'vault-client/utils/type-utils';
import PermissionGroupsShowRoute from 'vault-client/routes/permission-groups/show';
import { EntityMember, Maybe } from 'vault-client/types/graphql-types';

type EntityMemberData = EntityMember & { userEmail?: Maybe<string> | undefined };
export default class PermissionGroupsShow extends Controller {
	@tracked memberIdToRemove: string | null = null;
	@tracked memberDataToEdit: EntityMemberData | null = null;

	declare model: ModelFrom<PermissionGroupsShowRoute>;

	permissionGroupsRoutePath = '';

	get columns() {
		let baseColumns = [
			{
				id: 'f1cabfa8-a301-45e7-a89d-70a426b23460',
				name: 'First Name',
				valuePath: 'User.firstName',
				minWidth: 110,
				cellComponent: 'String',
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '23b470b9-98cd-458c-89dc-2c74b4ee0761',
				name: 'Last Name',
				valuePath: 'User.lastName',
				cellComponent: 'String',
				minWidth: 140,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '65edbb7b-e385-419c-b35a-6c82617e4b7e',
				name: 'Email',
				valuePath: 'User.email',
				cellComponent: 'String',
				width: 180,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'c4bb3b5e-607c-48fe-b9d2-7c175875e537',
				name: 'Insurance',
				minWidth: 110,
				width: 148,
				textAlign: '',
				isSortable: false,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: '35f1b630-e966-4fa4-81a5-0a60b5699acf',
						name: 'Read',
						valuePath: 'canReadInsurance',
						cellComponent: 'Check',
						minWidth: 55,
						width: 74,
						textAlign: 'center',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: '7d5f7aad-e2c8-4146-812a-86ccaa05b7cb',
						name: 'Write',
						valuePath: 'canWriteInsurance',
						cellComponent: 'Check',
						minWidth: 55,
						width: 74,
						textAlign: 'center',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: '3e057bb4-4fd1-4990-a536-7af7127de75b',
				name: 'Operations',
				minWidth: 110,
				width: 148,
				textAlign: '',
				isSortable: false,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: '2d4e2a6e-9b67-4e4f-9e44-a27df3856f9c',
						name: 'Read',
						valuePath: 'canReadOperations',
						cellComponent: 'Check',
						minWidth: 55,
						width: 74,
						textAlign: 'center',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: 'cd97056d-174d-49b7-aa58-bfd4b260a6be',
						name: 'Write',
						valuePath: 'canWriteOperations',
						cellComponent: 'Check',
						minWidth: 55,
						width: 74,
						textAlign: 'center',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: '0cddefef-120d-45c2-80b1-dec4c9ce7f7e',
				name: 'Brokerage',
				minWidth: 110,
				width: 148,
				textAlign: '',
				isSortable: false,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: 'd118b83e-790f-4fa7-9113-7a56b464e9be',
						name: 'Read',
						valuePath: 'canReadBrokerage',
						cellComponent: 'Check',
						minWidth: 55,
						width: 74,
						textAlign: 'center',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: 'bb52ef99-995f-48e0-ad66-2118fcc5dfb7',
						name: 'Write',
						valuePath: 'canWriteBrokerage',
						cellComponent: 'Check',
						minWidth: 55,
						width: 74,
						textAlign: 'center',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: 'a52aacb5-fc1c-4da8-b4ba-3c104fcec3d3',
				name: 'Crop / Feed Plans',
				minWidth: 110,
				width: 148,
				textAlign: '',
				isSortable: false,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: '7f40085d-a82e-4dac-9c30-a4d6c0aacf34',
						name: 'Read',
						valuePath: 'canReadFeedAndCropPlan',
						cellComponent: 'Check',
						minWidth: 55,
						width: 74,
						textAlign: 'center',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: '8bd36024-e245-42a8-982d-d8a88732184b',
						name: 'Write',
						valuePath: 'canWriteFeedAndCropPlan',
						cellComponent: 'Check',
						minWidth: 55,
						width: 74,
						textAlign: 'center',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: '592b54d7-e980-4ab7-a98b-217444233416',
				name: 'Swap',
				minWidth: 110,
				width: 148,
				textAlign: '',
				isSortable: false,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: '882af1da-0406-4377-ae59-e98e49032ced',
						name: 'Read',
						valuePath: 'canReadSwap',
						cellComponent: 'Check',
						minWidth: 55,
						width: 74,
						textAlign: 'center',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: '9e27e64e-830f-4135-a4b3-473dd312d7aa',
						name: 'Write',
						valuePath: 'canWriteSwap',
						cellComponent: 'Check',
						minWidth: 55,
						width: 74,
						textAlign: 'center',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
				],
			},

			{
				id: '12bf349c-8d11-414e-a6b2-b7dd2dca5197',
				name: 'Organization',
				minWidth: 110,
				width: 148,
				textAlign: '',
				isSortable: false,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: 'c6aa46ae-c368-4586-8e7b-dc0ce0314cfa',
						name: 'Read',
						valuePath: 'canReadOrganization',
						cellComponent: 'Check',
						minWidth: 55,
						width: 74,
						textAlign: 'center',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: 'df4a3802-83db-46fa-a1f8-6e6a0dbad93b',
						name: 'Write',
						valuePath: 'canWriteOrganization',
						cellComponent: 'Check',
						minWidth: 55,
						width: 74,
						textAlign: 'center',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: '967c42d8-c63f-40d6-9239-fb66c30f7011',
				name: 'Business',
				minWidth: 110,
				width: 148,
				textAlign: '',
				isSortable: false,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: '2894c7bf-99ae-4d6c-b987-8116ed4c7761',
						name: 'Read',
						valuePath: 'canReadBusiness',
						cellComponent: 'Check',
						minWidth: 55,
						width: 74,
						textAlign: 'center',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: '61dfcb2b-048a-4f6e-a431-7a3ac79be0c2',
						name: 'Write',
						valuePath: 'canWriteBusiness',
						cellComponent: 'Check',
						minWidth: 55,
						width: 74,
						textAlign: 'center',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: '35d303d4-3621-4ae4-8093-4e1ab941ad41',
				name: 'EntityGroup',
				minWidth: 110,
				width: 148,
				textAlign: '',
				isSortable: false,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: '990299e9-986e-4dc3-9713-3870547addca',
						name: 'Read',
						valuePath: 'canReadEntityGroup',
						cellComponent: 'Check',
						minWidth: 55,
						width: 74,
						textAlign: 'center',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: '69b51347-8054-4bfb-86ef-b4de168532e9',
						name: 'Write',
						valuePath: 'canWriteEntityGroup',
						cellComponent: 'Check',
						minWidth: 55,
						width: 74,
						textAlign: 'center',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: 'c5f54bd5-b177-4783-8699-907bedc821a2',
				name: '',
				valuePath: 'id',
				width: 80,
				textAlign: 'right',
				isSortable: false,
				cellComponent: 'Button',
				componentArgs: {
					size: 'xs',
					style: 'outline',
					text: 'Edit',
					fn: this.editMember,
				},
				isFixed: '',
				isVisible: true,
			},
			{
				id: '9ceebd2a-700f-4714-b513-6690e72ff664',
				name: '',
				valuePath: 'id',
				minWidth: 130,
				textAlign: 'right',
				isSortable: false,
				cellComponent: 'Button',
				componentArgs: {
					size: 'xs',
					style: 'outline',
					text: 'Remove',
					fn: this.removeMember,
				},
				isFixed: '',
				isVisible: true,
			},
		];

		if (!this.model.data?.EntityGroup.hasWriteAccess) {
			baseColumns = baseColumns.filter(
				(column) => column.id != '9ceebd2a-700f-4714-b513-6690e72ff664' && column.id != 'c5f54bd5-b177-4783-8699-907bedc821a2'
			);
		}

		return baseColumns;
	}

	get members() {
		return (
			this.model.data?.EntityGroup?.Members?.filter((member: EntityMember) => {
        if(!member.User && !member.userEmail ) {
          return false;
        }
        return true;
			}).map((member: EntityMember) => {
        const obj: EntityMemberData = { ...member };
				obj.userEmail = member.User?.email ?? member.userEmail;
				return obj;
      }) ?? []
		);
	}

	get currentUserPermissions() {
		return this.model.data?.EntityGroup?.CurrentUserPermissions;
	}

	@action
	removeMember(row: { id: string }) {
		this.memberIdToRemove = row.id;
	}

	@action
	editMember(row: EntityMemberData) {
		this.memberDataToEdit = row;
	}

	@action
	closeEditMemberGroupModal() {
		this.memberDataToEdit = null;
	}

	@action
	closeRemoveMemberModal() {
		this.memberIdToRemove = null;
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'permission-groups/show': PermissionGroupsShow;
	}
}
