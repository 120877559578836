import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Account from '../../models/account';
import { queryManager } from 'ember-apollo-client';
import resetVaultTableScroll from 'vault-client/utils/reset-vault-table-scroll';
import { CellComponents, TableColumn } from 'vault-client/types/vault-table';
import BusinessesAccounts from 'vault-client/routes/businesses/business/accounts';
import OrganizationsAccounts from 'vault-client/routes/organizations/organization/accounts';
import { ModelFrom } from 'vault-client/utils/type-utils';
import { GET_BROKERAGE_ACCOUNTS } from 'vault-client/routes/accounts';

export default class AccountsIndexController extends Controller {
	@queryManager apollo: any;
	@service permissions: any;
	queryParams = ['page', 'sorts', 'size'];

	query = GET_BROKERAGE_ACCOUNTS;

	declare model: ModelFrom<OrganizationsAccounts> | ModelFrom<BusinessesAccounts>;

	itemsFn = (rows: any): Account[] => {
		return rows.map((row: any) => {
			return new Account(row);
		});
	};

	accountRoutePath: string = '';

	@tracked page = 0;
	@tracked size = 100;
	@tracked sorts = [{ valuePath: 'accountNumber', isAscending: true }];

	get columns() {
		const baseColumns: TableColumn[] = [
			{
				id: '9c37fa96-4715-4e58-a0ba-93642002c825',
				name: 'Number',
				valuePath: 'accountNumber',
				minWidth: 120,
				textAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				linkRoute: this.accountRoutePath,
				linkModelPath: 'id',
			},
			{
				id: 'b6227e6e-90af-45d0-a0ff-3e7eb6b88154',
				name: 'FCM / Dealer',
				valuePath: 'custodianName',
				minWidth: 120,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '928393e8-c463-4ab2-810e-8f54a5608822',
				name: 'Account Name',
				valuePath: 'name',
				minWidth: 160,
				textAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '8d6ab3a2-cb9e-4434-83c5-dca9de35108e',
				name: 'Business Name',
				valuePath: 'customerName',
				minWidth: 160,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: false,
			},
			{
				id: '40ba52c1-701e-45a6-9af0-f2da7aad8f25',
				name: 'Net Liq',
				valuePath: 'liquidatingValue',
				minWidth: 120,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
				isTotaled: true,
			},
			{
				id: '9a572af7-64c9-479f-957e-f6ce2ce41484',
				name: 'Margin Excess',
				valuePath: 'marginExcessDeficit',
				minWidth: 160,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '03a5a3c8-ae63-4f7b-9213-0d8186741d0d',
				name: 'Is Related?',
				valuePath: 'isRelated',
				minWidth: 80,
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'e8f82632-435a-47c2-995b-aceccc868a89',
				name: 'Ending Account Balance',
				valuePath: 'currentBalance',
				minWidth: 160,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: false,
				isTotaled: true,
			},
		];
		return baseColumns;
	}

	get currentPage() {
		return this.page;
	}

	set currentPage(page) {
		this.page = page;
	}

	get accountData() {
		return this.itemsFn(this.model.getBrokerageAccounts.data?.Accounts);
	}

	get totalNumAccounts() {
		return this.model.getBrokerageAccounts.data?.AccountCount.count;
	}

	get columnTotals() {
		if (this.model.getBrokerageAccounts.data?.AccountCount.count == 0) return [];
		return [
			{
				liquidatingValue: this.model.getBrokerageAccounts.data?.AggregateBrokerageAccounts[0]?.sum.liquidatingValue ?? null,
				currentBalance: this.model.getBrokerageAccounts.data?.AggregateBrokerageAccounts[0]?.sum.currentBalance ?? null,
			},
		];
	}

	@action
	setTablePageState(newPageVal: number) {
		this.currentPage = newPageVal ?? 0;
		resetVaultTableScroll('accounts-table');
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'accounts-index-controller': AccountsIndexController;
	}
}
