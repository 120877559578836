import { getOwner } from '@ember/application';
import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';
import PermissionsService from 'vault-client/services/permissions';

export type Scope = 'organization' | 'business' | 'location' | null;

export default class ApplicationScope extends Service {
	@tracked organizationId: string | null = null;
	@tracked globalCustomerId: string | null = null;
	@tracked locationId: string | null = null;

	get permissions() {
		return getOwner(this).lookup('service:permissions') as PermissionsService;
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'application-scope': ApplicationScope;
	}
}
