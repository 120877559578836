import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.isLoading}}\n\t<div ...attributes>\n\t\t<Vault::UiLoadingSpinner />\n\t</div>\n{{else if (not (is-empty this.error))}}\n\t<div ...attributes>\n\t\t<Vault::UiSimpleWell class='max-w-120 relative transform -translate-y-1/2 top-1/2 m-auto'>\n\t\t\t<:header>\n\t\t\t\t<div class='text-center align-middle'>{{this.error}}</div>\n\t\t\t</:header>\n\t\t</Vault::UiSimpleWell>\n\t</div>\n{{else}}\n\t<div id={{this.legendId}}></div>\n\t<div class='relative' ...attributes>\n\t\t<Chart\n\t\t\t@id={{this.chartId}}\n\t\t\t@type='line'\n\t\t\t@chartData={{this.chartData}}\n\t\t\t@data={{this.historicalFuturesData}}\n\t\t\t@options={{this.chartOptions}}\n\t\t\t@plugins={{this.plugins}}\n\t\t\tdata-chart-type={{this.internalChartType}}\n\t\t/>\n\t</div>\n{{/if}}", {"contents":"{{#if this.isLoading}}\n\t<div ...attributes>\n\t\t<Vault::UiLoadingSpinner />\n\t</div>\n{{else if (not (is-empty this.error))}}\n\t<div ...attributes>\n\t\t<Vault::UiSimpleWell class='max-w-120 relative transform -translate-y-1/2 top-1/2 m-auto'>\n\t\t\t<:header>\n\t\t\t\t<div class='text-center align-middle'>{{this.error}}</div>\n\t\t\t</:header>\n\t\t</Vault::UiSimpleWell>\n\t</div>\n{{else}}\n\t<div id={{this.legendId}}></div>\n\t<div class='relative' ...attributes>\n\t\t<Chart\n\t\t\t@id={{this.chartId}}\n\t\t\t@type='line'\n\t\t\t@chartData={{this.chartData}}\n\t\t\t@data={{this.historicalFuturesData}}\n\t\t\t@options={{this.chartOptions}}\n\t\t\t@plugins={{this.plugins}}\n\t\t\tdata-chart-type={{this.internalChartType}}\n\t\t/>\n\t</div>\n{{/if}}","moduleName":"vault-client/components/historical-future-prices-chart.hbs","parseOptions":{"srcName":"vault-client/components/historical-future-prices-chart.hbs"}});
import Component from '@glimmer/component';
import { Chart, ChartOptions, ScriptableTooltipContext } from 'chart.js';
import { gql, useQuery } from 'glimmer-apollo';
import { DateTime, Interval } from 'luxon';
import { FutureFilterDTO, Query_FuturesArgs, Future, Product } from 'vault-client/types/graphql-types';
import { task } from 'ember-concurrency';
import { guidFor } from '@ember/object/internals';
import getCSSVariable from 'vault-client/utils/get-css-variable';
import { cached } from '@glimmer/tracking';
import { InternalChartType } from 'vault-client/types/vault-client';
import { calculateAutoLabelYOffset } from 'vault-client/utils/chart-utils';
import ENV from 'vault-client/config/environment';

interface SessionalFuturesChartArgs {
	id: string;
	productSlug: string;
	futureExpirationDate: string;
	colors?: string[];
}

const QUERY_FUTURES = gql`
	query queryFutures($where: FutureFilterDTO!, $limit: Float) {
		Futures(where: $where, limit: $limit, orderBy: { displayExpiresAt: Desc }) {
			id
			name
			expiresAt
			displayExpiresAt
			barchartSymbol
			Product {
				id
				name
			}
			SymbolGroup {
				id
				fractionDigits
				displayFactor
				barchartRootSymbol
			}
		}
	}
`;

type historicalPriceResponse = {
	symbol: string;
	timestamp: string;
	tradingDay: string;
	close: number;
}[];

type FuturesQuery = {
	__typename?: 'Query';
	Futures: Future[];
};

const DEFAULT_CHART_COLORS = [
	getCSSVariable('--brand-interactive-blue-70'),
	getCSSVariable('--brand-orange-40'),
	getCSSVariable('--brand-lime-40'),
	getCSSVariable('--brand-lemon-40'),
	getCSSVariable('--brand-teal-60'),
	getCSSVariable('--brand-purple-50'),
];

export default class thisSessionalFuturesChart extends Component<SessionalFuturesChartArgs> {
	queryFutures = useQuery<FuturesQuery, Query_FuturesArgs>(this, () => [QUERY_FUTURES, { variables: { where: this.futuresWhere } }]);
	internalChartType = InternalChartType.HistoricalFuturePrices;
	historicalFuturesData: { [year: string]: { day: string; price: number | null }[] } | null = null;
	error: string = '';

	constructor(owner: any, args: SessionalFuturesChartArgs) {
		super(owner, args);
		this.fetchfuturesBarchartDataTask.perform();
	}

	get colors(): string[] {
		return this.args.colors ?? DEFAULT_CHART_COLORS;
	}

	get month(): number {
		return DateTime.fromISO(this.args.futureExpirationDate).month;
	}

	get plugins() {
		const chartId = this.chartId;
		const legendId = this.legendId;

		return [
			{
				afterUpdate: function (chart: Chart) {
					function toggleDataset(event: PointerEvent): void {
						const index = (event.currentTarget as HTMLElement).dataset.index;
						if (index) {
							const meta = chart.getDatasetMeta(+index);
							meta.hidden = !meta.hidden ? true : false;
							chart.update();
						}
					}

					// Make sure we're applying the legend to the right chart
					if (chart.canvas.id === chartId) {
						const legend = document.getElementById(legendId)!;
						const ul = legend?.querySelector('ul') ?? document.createElement('ul');
						ul.classList.add('flex');

						// Remove old legend items
						while (ul.firstChild) {
							ul.firstChild.remove();
						}

						while (legend.firstChild) {
							legend.firstChild.remove();
						}

						chart.data.datasets.forEach((dataset, index) => {
							const legendItem = document.createElement('li');
							legendItem.classList.add('flex', 'flex-col', 'items-center', 'mr-6', 'text-brand-blue-80', 'cursor-pointer');

							// Strike out legend item if hidden
							const meta = chart.getDatasetMeta(index);
							if (meta.hidden) {
								legendItem.classList.add('line-through');
							}

							legendItem.dataset.index = index.toString(10);
							legendItem.onclick = toggleDataset;
							legendItem.innerHTML += `
								<div class="text-sm flex items-center">
									<span style="background-color: ${dataset.backgroundColor}" class="w-2 h-2 rounded-full inline-block mr-1"></span>
									${dataset.label}
								<div/>
							`;

							ul.append(legendItem);
						});

						return legend?.insertBefore(ul, legend.firstChild);
					}
					return;
				},
			},
		];
	}

	get chartId() {
		return this.args.id ?? `${guidFor(this)}-historical-future-prices-chart`;
	}

	get legendId() {
		return `${this.chartId}-legend`;
	}

	get tooltipId() {
		return `${this.chartId}-tooltip`;
	}

	get isLoading() {
		return this.fetchfuturesBarchartDataTask.last?.isRunning;
	}

	get currentFuture() {
		return this.futures.find((future) => future.displayExpiresAt === this.args.futureExpirationDate);
	}

	get labelStartDate() {
		const defaultStartDate = this.labelEndDate.minus({ days: 364 });

		if (!this.currentFuture || !this.historicalFuturesData || Object.keys(this.historicalFuturesData).length == 0) return defaultStartDate;

		const tradingStartDates = Object.keys(this.historicalFuturesData).map((year) => {
			const day = this.historicalFuturesData![year].firstObject?.day;
			return day
				? DateTime.fromISO(day)
						.set({ year: DateTime.now().year - 1 })
						.toISODate()
				: null;
		});

		let earliestTradingDate = null;
		let smallestDiff = Infinity;

		tradingStartDates.forEach((startDate) => {
			if (!startDate) return;

			const diff = Math.abs(DateTime.fromISO(startDate).diff(defaultStartDate, 'days').days);

			if (diff < smallestDiff) {
				smallestDiff = diff;
				earliestTradingDate = startDate;
			}
		});

		if (earliestTradingDate) {
			let interval = this.labelEndDate.startOf('day').diff(DateTime.fromISO(earliestTradingDate).startOf('day'), 'days').days;

			// If interval represents more than ~one year, increase startDate until it doesn't
			// Not 365 to account for small variation in dates (intervals of 366 and 367 are common)
			while (interval > 370) {
				if (interval > 370) {
					earliestTradingDate = DateTime.fromISO(earliestTradingDate).plus({ year: 1 }).toISODate();
				}
				interval = this.labelEndDate.startOf('day').diff(DateTime.fromISO(earliestTradingDate).startOf('day'), 'days').days;
			}
		}

		return earliestTradingDate ? DateTime.fromISO(earliestTradingDate) : defaultStartDate;
	}

	get labelEndDate() {
		let tradingEndDate = this.currentFuture ? DateTime.fromISO(this.currentFuture.expiresAt) : DateTime.now();
		if (tradingEndDate.year > DateTime.now().year) {
			tradingEndDate = tradingEndDate.set({ year: DateTime.now().year + 1 });
		}

		return tradingEndDate;
	}

	get numberOfYearsRetrieved() {
		return Object.keys(this.historicalFuturesData ?? {}).length;
	}

	get chartData() {
		const datasets = Object.keys(this.historicalFuturesData ?? {})
			.map((year, index) => {
				let data: {
					day: string;
					price: number | null;
				}[] = this.historicalFuturesData ? this.historicalFuturesData![year] : [];

				const isExpired = !!this.futures.find((future) => {
					return DateTime.fromISO(future.displayExpiresAt).year === Number(year) && future.expiresAt < DateTime.now().toISODate();
				});

				if (!isExpired && data.length > 0) {
					const finalDate = DateTime.fromISO(data[data.length - 1].day);
					const now = DateTime.now();
					const currentDate = DateTime.fromObject({ year: now.year, month: now.month, day: now.day }).minus({ day: 1 });
					const offset = Math.floor(currentDate.diff(finalDate, 'days').days);
					const desiredLength = this.annotationIndex - (offset > 0 ? offset : 0) + 1;

					if (data.length < desiredLength) {
						data = [...Array(desiredLength - data.length).fill({ day: '', price: null }), ...data];
					} else {
						data = data.slice(-desiredLength - 1);
					}
				} else if (isExpired && data.length > 0) {
					// Line up expired future data with its end date label
					let minLastDayOffsetIndex = 0;
					const lastDay = data[data.length - 1];
					const lastLabel = this.labels[this.labels.length - 1];

					if (lastDay && lastLabel) {
						minLastDayOffsetIndex = this.labels.length;

						const offset = minLastDayOffsetIndex - data.length;
						if (offset > 0) data = [...Array(offset).fill({ day: '', price: null }), ...data];
					}
				}

				const finalDataArr = data.map((value, i) => {
					return {
						tradeDate: value.day,
						y: value.price,
						x: i,
					};
				});

				return {
					label: year,
					data: finalDataArr,
					backgroundColor: this.colors[index],
					borderColor: this.colors[index],
				};
			})
			.sort((a, b) => (+a.label < +b.label ? -1 : 1));

		return {
			labels: this.labels,
			datasets,
		};
	}

	get annotationIndex() {
		let minOffset = Infinity;
		let minOffsetIndex = 0;
		const now = DateTime.now();
		const currentDateTime = DateTime.fromObject({ year: now.year, month: now.month, day: now.day });

		this.labels.forEach((date, index) => {
			const labelDateTime = DateTime.fromFormat(date, 'LLL dd');
			const diff =
				labelDateTime < currentDateTime
					? Interval.fromDateTimes(labelDateTime, currentDateTime)
					: Interval.fromDateTimes(currentDateTime, labelDateTime);
			const diffDays = diff.length('days');
			if (diffDays <= minOffset) {
				minOffset = diffDays;
				minOffsetIndex = index;
			}
		});

		return minOffsetIndex;
	}

	get chartOptions(): ChartOptions<'line'> {
		const getOrCreateTooltip = (chart: Chart) => {
			let tooltipEl: HTMLDivElement | null | undefined = chart.canvas.parentNode?.querySelector('div');
			if (!tooltipEl) {
				tooltipEl = document.createElement('div');
				tooltipEl.id = this.tooltipId;
				tooltipEl.classList.add(
					'line-chart-tooltip',
					'line-chart-tooltip-bottom',
					'border',
					'border-brand-blue-80',
					'bg-white',
					'text-brand-gray-90',
					'absolute',
					'pointer-events-none',
					'transform',
					'-translate-x-2/4',
					'transition-all',
					'ease-linear',
					'duration-50',
					'opacity-1',
					'z-10',
					'text-left',
					'p-2',
					'rounded-sm'
				);

				const tooltipUL = document.createElement('ul');
				tooltipUL.classList.add('tooltipul');

				tooltipEl.append(tooltipUL);
				chart.canvas.parentNode?.append(tooltipEl);
			}
			return tooltipEl;
		};

		const customTooltip = (context: ScriptableTooltipContext<'line'>) => {
			const { chart, tooltip } = context;
			const tooltipEl = getOrCreateTooltip(chart);
			const tooltipUL = tooltipEl.querySelector('ul')!;

			if (tooltip.opacity === 0) {
				tooltipEl.classList.replace('opacity-1', 'opacity-0');
				return;
			}

			if (tooltip.body) {
				const titleLines = tooltip.title || [];
				const dataPoints = tooltip.dataPoints;
				const dataIndex = dataPoints.find((v) => v.dataIndex)?.dataIndex ?? 0;
				const tooltipLI = document.createElement('li');
				tooltipLI.classList.add('whitespace-nowrap');

				// Title Loop
				titleLines.forEach((_title: string) => {
					const formattedTitle = `${this.labels.length - 1 - dataIndex} DTE`;
					tooltipUL.appendChild(tooltipLI);

					const tooltipSpan = document.createElement('span');
					tooltipSpan.classList.add('text-sm', 'font-semibold', 'text-brand-blue-80', 'block');
					tooltipLI.appendChild(tooltipSpan);

					const tooltipTitle = document.createTextNode(formattedTitle);
					tooltipSpan.appendChild(tooltipTitle);
				});

				// Body Loop
				const tooltipBodyP = document.createElement('p');
				dataPoints.forEach((dataPoint, i: number) => {
					const displayBlockSpan = document.createElement('span');
					displayBlockSpan.classList.add('block', 'text-xs');
					const colors = tooltip.labelColors[i];
					const colorCircle = document.createElement('span');
					const rawData = dataPoint.raw as { tradeDate: string; y: number; x: number };
					colorCircle.classList.add('rounded-full', 'w-2', 'h-2', 'inline-block', 'mr-2');
					colorCircle.style.background = colors.borderColor as string;
					colorCircle.style.border = colors.borderColor as string;

					const formattedValue = Intl.NumberFormat('en-US', {
						style: 'currency',
						currency: 'USD',
						maximumFractionDigits: this.fractionDigits,
						minimumFractionDigits: this.fractionDigits,
					}).format(rawData.y);
					const value = document.createTextNode(formattedValue);
					const label = document.createElement('span');
					label.classList.add('ml-1', 'font-sans-semibold');
					label.textContent = DateTime.fromISO(rawData.tradeDate).toLocaleString({
						month: 'short',
						day: 'numeric',
					});
					// Append color label and text
					displayBlockSpan.appendChild(colorCircle);
					displayBlockSpan.appendChild(value);
					displayBlockSpan.append(label);
					tooltipBodyP.appendChild(displayBlockSpan);
				});

				// Remove old children
				while (tooltipUL.firstChild) {
					tooltipUL.firstChild.remove();
				}

				// Add new children
				tooltipUL.appendChild(tooltipLI);
				tooltipLI.appendChild(tooltipBodyP);
				tooltipEl.classList.replace('opacity-0', 'opacity-1');

				// Position tooltip
				const position = context.chart.canvas.getBoundingClientRect();

				tooltipEl.style.left = context.chart.canvas.offsetLeft + tooltip.caretX + 'px';
				tooltipEl.style.bottom = position.height - 24 + 'px';
			}
		};

		return {
			maintainAspectRatio: false,
			spanGaps: true,
			responsive: true,
			normalized: true,
			interaction: {
				intersect: false,
				mode: 'index',
			},
			layout: {
				padding: {
					top: 35,
				},
			},
			datasets: {
				line: {
					pointRadius: 0,
					tension: 0.3,
				},
			},
			scales: {
				y: {
					ticks: {
						font: {
							size: 12,
						},
						color: getComputedStyle(document.documentElement).getPropertyValue('--brand-gray-800'),
						callback: (value: number) => {
							return new Intl.NumberFormat('en-US', {
								style: 'currency',
								currency: 'USD',
								maximumFractionDigits: this.fractionDigits,
								minimumFractionDigits: this.fractionDigits,
							}).format(value);
						},
					},
				},
				x: {
					grid: {
						display: false,
					},
					// Used to always start where the current future has data
					min: this.currentFutureFirstTradeDayIndex,
					ticks: {
						color: getComputedStyle(document.documentElement).getPropertyValue('--brand-gray-800'),
						font: {
							size: 12,
						},
					},
				},
			},
			plugins: {
				title: {
					display: true,
					text: this.product
						? `${this.monthNumbertoMonthName(this.month, 'long')} ${this.product.name} Historical Prices - ${this.numberOfYearsRetrieved} ${
								this.numberOfYearsRetrieved === 1 ? 'Year' : 'Years'
						  }`
						: '',
				},
				legend: {
					display: false,
				},
				tooltip: {
					external: customTooltip,
					enabled: false,
					displayColors: false,
					callbacks: {
						label: (context) => {
							return `${context.dataset.label}: ${Intl.NumberFormat('en-US', {
								style: 'currency',
								currency: 'USD',
								maximumFractionDigits: this.fractionDigits,
								minimumFractionDigits: this.fractionDigits,
							}).format(context.parsed.y)}`;
						},
					},
				},
				annotation: {
					clip: false,
					annotations: {
						line1: {
							display: true,
							type: 'line',
							adjustScaleRange: true,
							xMin: this.annotationIndex,
							xMax: this.annotationIndex,
							borderColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-lemon-50'),
							borderDash: [5],
							label: {
								content: 'Today',
								display: true,
								position: 'end',
								color: getComputedStyle(document.documentElement).getPropertyValue('--brand-gray-90'),
								borderColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-blue-80'),
								borderWidth: 1,
								backgroundColor: 'white',
								yAdjust: (ctx) => {
									return calculateAutoLabelYOffset(ctx, this.annotationIndex);
								},
								font: {
									size: 12,
								},
							},
						},
					},
				},
			},
		};
	}

	fetchfuturesBarchartDataTask = task(this, async () => {
		await this.queryFutures.promise;

		if (this.futures.length === 0) {
			this.error = `Could Not Find ${this.monthNumbertoMonthName(this.month, 'long')} Futures`;
			return;
		}

		const historicalFuturesDataPromises = this.futures.map((future) => {
			const barchartSymbol = future.barchartSymbol;
			if (!barchartSymbol) return Promise.resolve(new Response(null, { status: 400 }));

			const isExpired = future.expiresAt < DateTime.now().toISODate();
			const currentDateTime = DateTime.now();

			const endDate = isExpired ? DateTime.fromISO(future.expiresAt) : currentDateTime;
			let startDate = isExpired ? endDate.minus({ days: 364 }) : this.labelStartDate;

			if (startDate > endDate) {
				startDate = startDate.minus({ year: 1 });
			}

      const apiKey: string = ENV.barchartApiToken
			return fetch(
				'https://ondemand.websol.barchart.com/getHistory.json?' +
					new URLSearchParams({
						apikey: apiKey,
						symbol: barchartSymbol,
						type: 'daily',
						startDate: startDate.toFormat('yyyyLLdd'),
						endDate: endDate.toFormat('yyyyLLdd'),
						close: 'true',
					}),
				{
					method: 'GET',
					headers: {},
				}
			);
		});

		const historicalFuturesData: { [year: string]: { day: string; price: number | null }[] } = {};

		await Promise.all(historicalFuturesDataPromises).then(async (responses) => {
			for (const response of responses) {
				const contentType = response.headers.get('content-type');
				if (!response.ok || !contentType || !contentType.includes('application/json')) {
					console.warn(`Failed to Retrieve Historical Future Data`);
					return;
				}

				await response
					.json()
					.then((response) => response.results)
					.then((data: historicalPriceResponse) => {
						if (!data) return;

						let [min, max] = [Number.POSITIVE_INFINITY, Number.NEGATIVE_INFINITY];

						for (const day of data) {
							if (day.close < min) {
								min = day.close;
							}

							if (day.close > max) {
								max = day.close;
							}
						}

						const year = this.futureSymbolToYear[data[data.length - 1].symbol];

						data.map((day) => {
							const dayString = DateTime.fromISO(day.tradingDay).toISODate();
							if (historicalFuturesData[year.toString()] == undefined) {
								historicalFuturesData[year.toString()] = [];
							}

							historicalFuturesData[year.toString()].push({
								day: dayString,
								price: day.close ? parseFloat((day.close * this.displayFactor).toFixed(this.fractionDigits)) : null,
							});
						});
					});
			}
		});
		this.historicalFuturesData = historicalFuturesData;
	});

	@cached
	get labels(): string[] {
		if (!this.futures || this.futures.length === 0) return [];
		if (!this.historicalFuturesData || Object.keys(this.historicalFuturesData).length == 0) return [];

		const availableYears = Object.keys(this.historicalFuturesData);

		const daysDiff = this.labelEndDate.diff(this.labelStartDate, 'days').days;
		const numValues = Math.max(...availableYears.map((year) => this.historicalFuturesData![year].length));
		const interval = daysDiff / numValues;

		const labels = [];

		for (let i = 0; i < numValues; i++) {
			labels.push(this.labelStartDate.plus({ days: interval * (i ?? 1) }).toFormat('LLL dd'));
		}

		return labels;
	}

	get futuresWhere() {
		const where: FutureFilterDTO = {};
		where.isStandardContractSize = { equals: true };
		where.Product = {
			slug: {
				equals: this.args.productSlug,
			},
		};

		where.OR = [];

		const futureDateTime = DateTime.fromISO(this.args.futureExpirationDate);

		for (let i = 0; i < 5; i++) {
			where.OR.push({
				displayExpiresAt: {
					gte: futureDateTime.minus({ years: i }).startOf('month').toISODate(),
					lte: futureDateTime.minus({ years: i }).endOf('month').toISODate(),
				},
			});
		}

		return where;
	}

	get futures() {
		// Deep Copy and sort by earliest expiration day and month
		return (JSON.parse(JSON.stringify(this.queryFutures.data?.Futures ?? [])) as Future[]).sortBy('expiresAt');
	}

	get fractionDigits(): number {
		return this.queryFutures.data?.Futures.find((v) => !!v)?.SymbolGroup.fractionDigits ?? 0;
	}

	get displayFactor(): number {
		return this.queryFutures.data?.Futures.find((v) => !!v)?.SymbolGroup.displayFactor ?? 1;
	}

	get futureSymbolToYear() {
		return this.futures.reduce((acc, future) => {
			if (future.barchartSymbol) {
				acc[future.barchartSymbol] = DateTime.fromISO(future.displayExpiresAt).year;
			}
			return acc;
		}, {} as { [futureSymbol: string]: number });
	}

	get currentFutureFirstTradeDayIndex() {
		const currentFutureYear = DateTime.fromISO(this.args.futureExpirationDate).year;
		const index = this.chartData?.datasets
			?.find((dataset) => dataset.label === currentFutureYear.toString())
			?.data?.findIndex((v) => v.tradeDate !== '');

		return !!index && index !== -1 ? index : 0;
	}

	get product(): Product | null {
		return this.futures[0]?.Product || null;
	}

	monthNumbertoMonthName(monthNumber: number, format: 'short' | 'long' = 'short') {
		const date = new Date();
		date.setMonth(monthNumber - 1);

		return date.toLocaleString('en-US', {
			month: format,
		});
	}
}
