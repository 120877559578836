import Route from '@ember/routing/route';
import { tracked } from '@glimmer/tracking';
import { gql, useQuery } from 'glimmer-apollo';
import { CustomerEntityFilterDTO, Query, Query_CustomersArgs, SortByDirection } from 'vault-client/types/graphql-types';
import { SortObj } from 'vault-client/types/vault-table';

export const GET_CUSTOMERS = gql`
	query Customers($limit: Float, $offset: Float, $orderBy: CustomerEntitySortByDTO, $where: CustomerEntityFilterDTO) {
		Customers(where: $where, orderBy: $orderBy, limit: $limit, offset: $offset) {
			id
			name
			identificationNumber
			BusinessContacts(orderBy: { isPrimary: Desc }) {
				id
				firstName
				lastName
				email
				phone
			}
			Groups {
				id
				name
			}
			RelationshipOwner {
				id
				firstName
				lastName
			}
		}
		CustomerCount(where: $where) {
			count
		}
		GrainAdvisors {
			id
			name
		}
	}
`;

type GetCustomers = {
	__typename?: 'Query';
	Customers: Query['Customers'];
	CustomerCount: Query['CustomerCount'];
	GrainAdvisors: Query['GrainAdvisors'];
};

interface QueryParams {
	page: number;
	size: number;
	sorts: SortObj[];
	selectedAdvisorIds: string[];
	customerId: string;
	businessContactId: string;
}

export default class VgsCustomersIndex extends Route {
	@tracked variables: Query_CustomersArgs = {};

	queryParams = {
		page: { refreshModel: true },
		sorts: { refreshModel: true },
		size: { refreshModel: true },
		selectedAdvisorIds: { refreshModel: true },
		customerId: { refreshModel: true },
		businessContactId: { refreshModel: true },
	};

	getCustomers = useQuery<GetCustomers, Query_CustomersArgs>(this, () => [GET_CUSTOMERS, { variables: this.variables }]);

	query: any = GET_CUSTOMERS;

	generateWhere(params: QueryParams): CustomerEntityFilterDTO {
		const where: CustomerEntityFilterDTO = {
			isVgs: {
				equals: true,
			},
			...(params.selectedAdvisorIds?.length && { relationshipOwnerId: { in: params.selectedAdvisorIds } }),
			...(params.customerId && { id: { equals: params.customerId } }),
			...(params.businessContactId && { BusinessContacts: { id: { equals: params.businessContactId } } }),
		};

		return where;
	}

	generateOrderBy(sorts: SortObj[]) {
		const orderBy: {
			[key: string]: any;
		} = {};

		sorts.forEach((sort: any) => {
			const parts = sort.valuePath.split('.');
			const firstPart = parts.shift();

			let value: any = sort.isAscending ? SortByDirection.Asc : SortByDirection.Desc;

			while (parts.length > 0) {
				const part = parts.pop();
				const obj: {
					[key: string]: any;
				} = {};
				obj[part] = value;
				value = obj;
			}

			orderBy[firstPart] = value;
		});

		return orderBy;
	}

	async model(params: QueryParams) {
		this.variables = {
			orderBy: this.generateOrderBy(params.sorts),
			offset: params.page * params.size,
			limit: params.size,
			where: this.generateWhere(params),
		};

		await this.getCustomers.promise;

		return { getCustomers: this.getCustomers, variables: this.variables };
	}
}
