import Component from '@glimmer/component';

interface VaultTableEmptyStateWellArgs {}

export default class VaultTableEmptyStateWell extends Component<VaultTableEmptyStateWellArgs> {
	_defaultHeaderMessage = 'No Data Items Found';

	get defaultHeaderMessage(): string {
		return this._defaultHeaderMessage;
	}
}
