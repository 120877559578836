import { action } from '@ember/object';
import Component from '@glimmer/component';
import { getFocusableElementsInContainer } from 'vault-client/utils/general';

type Height = 'xs' | 'sm' | 'md' | 'lg' | 'xl';
interface UiModalArgs {
	height?: Height;
	modalContainerId?: string;
}

export default class UiModal extends Component<UiModalArgs> {
	_modalContainerId = 'modal_root';
	defaultHeight = 'md';

	get height() {
		return this.args.height ? this.args.height : this.defaultHeight;
	}

	get modalContainer(): HTMLElement | null {
		const element = this.args.modalContainerId
			? document.getElementById(this.args.modalContainerId)
			: document.getElementById(this._modalContainerId);
		return element;
	}

	// handling for if do not find HTMLElement to pass to in-element
	get renderOut() {
		return this.modalContainer ? true : false;
	}

	@action
	handleTab(event: KeyboardEvent) {
		const focusedElement = this.trapFocus(event, this.modalContainer);
		this.scrollElementIntoView(focusedElement);
	}

	trapFocus(event: KeyboardEvent, container: HTMLElement | null): HTMLElement | null {
		const focusableElements = getFocusableElementsInContainer(container);

		let focusedElement = event.target as HTMLElement | null;

		const firstFocusableEl = focusableElements?.[0] as HTMLElement | undefined;
		const lastFocusableEl = focusableElements?.[focusableElements.length - 1] as HTMLElement | undefined;

		// Do not trap focus if there are no focusable elements (error state, allows users to navigate to a different page)
		if (!firstFocusableEl || !lastFocusableEl) {
			return null;
		} else {
			if (event.shiftKey) {
				if (firstFocusableEl && document.activeElement && document.activeElement === firstFocusableEl) {
					lastFocusableEl.focus();
					focusedElement = lastFocusableEl;
					event.preventDefault();
				}
			} else {
				if (document.activeElement === lastFocusableEl) {
					firstFocusableEl.focus();
					focusedElement = firstFocusableEl;
					event.preventDefault();
				}
			}
		}

		return focusedElement;
	}

	scrollElementIntoView(element: HTMLElement | null) {
		element?.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
	}
}
