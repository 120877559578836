export type PricingUnit =
	| 'Bushel'
	| 'Ton'
	| 'CWT'
	| 'Pound'
	| 'Troy Ounce'
	| 'Point'
	| 'MBF'
	| 'MMBtu'
	| 'Barrel'
	| 'Gallon'
	| 'MXN Increment'
	| 'GBP Increment'
	| 'CAD Increment'
	| 'AUD Increment'
	| 'EUR Increment'
	| 'JPY Increment'
	| 'NZD Increment'
	| 'RUB Increment'
	| 'CHF Increment'
	| 'Kg';
type CurrencyUnit = 'Cents' | 'Dollars' | 'CAD' | 'EUR' | 'NZD';

const productSlugToPricingUnit: {
	[slug: string]: { currencyUnit: CurrencyUnit; pricingUnit: PricingUnit } | undefined;
} = {
	'grain-corn': { currencyUnit: 'Cents', pricingUnit: 'Bushel' },
	'grain-soybeans': { currencyUnit: 'Cents', pricingUnit: 'Bushel' },
	'grain-soybean-meal': { currencyUnit: 'Dollars', pricingUnit: 'Ton' },
	'livestock-lean-hogs': { currencyUnit: 'Dollars', pricingUnit: 'CWT' },
	'us-dairy-class-iii': { currencyUnit: 'Dollars', pricingUnit: 'CWT' },
	'us-dairy-class-iv': { currencyUnit: 'Dollars', pricingUnit: 'CWT' },
	'us-dairy-cheese': { currencyUnit: 'Dollars', pricingUnit: 'Pound' },
	'us-dairy-cheese-block-spot': { currencyUnit: 'Dollars', pricingUnit: 'Pound' },
	'us-dairy-cheese-block': { currencyUnit: 'Dollars', pricingUnit: 'Pound' },
	'us-dairy-butter': { currencyUnit: 'Dollars', pricingUnit: 'Pound' },
	'us-dairy-nonfat-milk': { currencyUnit: 'Dollars', pricingUnit: 'Pound' },
	'us-dairy-dry-whey': { currencyUnit: 'Dollars', pricingUnit: 'Pound' },
	'grain-srwi-soft-red-winter-wheat': { currencyUnit: 'Cents', pricingUnit: 'Bushel' },
	'grain-hrwi-hard-red-winter-wheat': { currencyUnit: 'Cents', pricingUnit: 'Bushel' },
	'grain-hard-red-spring-wheat': { currencyUnit: 'Cents', pricingUnit: 'Bushel' },
	'softs-coffee': { currencyUnit: 'Dollars', pricingUnit: 'Pound' },
	'metals-silver': { currencyUnit: 'Dollars', pricingUnit: 'Troy Ounce' },
	'grain-rough-rice': { currencyUnit: 'Dollars', pricingUnit: 'CWT' },
	'equity-s&p-500-index': { currencyUnit: 'Dollars', pricingUnit: 'Point' },
	'softs-lumber': { currencyUnit: 'Dollars', pricingUnit: 'MBF' },
	'energy-henry-hub-natural-gas-swap': { currencyUnit: 'Dollars', pricingUnit: 'MMBtu' },
	'bonds-us-5-year-treasury-note': { currencyUnit: 'Dollars', pricingUnit: 'Point' },
	'energy-crude-oil-wti': { currencyUnit: 'Dollars', pricingUnit: 'Barrel' },
	'energy-crude-oil-brent': { currencyUnit: 'Dollars', pricingUnit: 'Barrel' },
	'fx-mexican-peso': { currencyUnit: 'Dollars', pricingUnit: 'MXN Increment' },
	'grain-canola': { currencyUnit: 'CAD', pricingUnit: 'Ton' },
	'fx-british-pound': { currencyUnit: 'Dollars', pricingUnit: 'GBP Increment' },
	'metals-aluminum': { currencyUnit: 'Dollars', pricingUnit: 'Ton' },
	'metals-gold': { currencyUnit: 'Dollars', pricingUnit: 'Troy Ounce' },
	'eu-dairy-liquid-milk-europe': { currencyUnit: 'EUR', pricingUnit: 'Ton' },
	'fx-canadian-dollar': { currencyUnit: 'Dollars', pricingUnit: 'CAD Increment' },
	'equity-s&p-gsci': { currencyUnit: 'Dollars', pricingUnit: 'Point' },
	'equity-nasdaq-100-index': { currencyUnit: 'Dollars', pricingUnit: 'Point' },
	'equity-s&p-500-vix': { currencyUnit: 'Dollars', pricingUnit: 'Point' },
	'softs-sugar-11': { currencyUnit: 'Dollars', pricingUnit: 'Pound' },
	'nz-dairy-whole-milk-powder': { currencyUnit: 'Dollars', pricingUnit: 'Ton' },
	'fx-australian-dollar': { currencyUnit: 'Dollars', pricingUnit: 'AUD Increment' },
	'nz-dairy-liquid-milk': { currencyUnit: 'NZD', pricingUnit: 'Kg' },
	'metals-platinum': { currencyUnit: 'Dollars', pricingUnit: 'Troy Ounce' },
	'equity-dow-jones-$5-mini': { currencyUnit: 'Dollars', pricingUnit: 'Point' },
	'livestock-pork-cutouts': { currencyUnit: 'Cents', pricingUnit: 'Pound' },
	'grain-oats': { currencyUnit: 'Cents', pricingUnit: 'Bushel' },
	'softs-orange-juice': { currencyUnit: 'Dollars', pricingUnit: 'Pound' },
	'softs-cocoa': { currencyUnit: 'Dollars', pricingUnit: 'Ton' },
	'nz-dairy-anhydrous-milk-fat': { currencyUnit: 'Dollars', pricingUnit: 'Ton' },
	'eu-dairy-whey-powder-european': { currencyUnit: 'EUR', pricingUnit: 'Ton' },
	'grain-soybean-oil': { currencyUnit: 'Cents', pricingUnit: 'Pound' },
	'eu-dairy-skimmed-milk-european': { currencyUnit: 'EUR', pricingUnit: 'Ton' },
	'fx-euro': { currencyUnit: 'Dollars', pricingUnit: 'EUR Increment' },
	'fx-japanese-yen': { currencyUnit: 'Dollars', pricingUnit: 'JPY Increment' },
	'fx-newzealand-dollar': { currencyUnit: 'Dollars', pricingUnit: 'NZD Increment' },
	'energy-ny-harbor-ulsd': { currencyUnit: 'Dollars', pricingUnit: 'Gallon' },
	'equity-russell-2000-index': { currencyUnit: 'Dollars', pricingUnit: 'Point' },
	'nz-dairy-global-butter': { currencyUnit: 'Dollars', pricingUnit: 'Ton' },
	'energy-gasoline-rbob': { currencyUnit: 'Dollars', pricingUnit: 'Gallon' },
	'fx-russian-ruble': { currencyUnit: 'Dollars', pricingUnit: 'RUB Increment' },
	'fx-swiss-franc': { currencyUnit: 'Dollars', pricingUnit: 'CHF Increment' },
	'livestock-feeder-cattle': { currencyUnit: 'Cents', pricingUnit: 'Pound' },
	'metals-palladium': { currencyUnit: 'Dollars', pricingUnit: 'Troy Ounce' },
	'nz-dairy-skim-milk-powder': { currencyUnit: 'Dollars', pricingUnit: 'Ton' },
	'energy-chicago-ethanol': { currencyUnit: 'Dollars', pricingUnit: 'Gallon' },
	'eu-dairy-butter-european': { currencyUnit: 'EUR', pricingUnit: 'Ton' },
	'softs-cotton-2': { currencyUnit: 'Cents', pricingUnit: 'Pound' },
	'metals-copper': { currencyUnit: 'Dollars', pricingUnit: 'Pound' },
};

export default productSlugToPricingUnit;
