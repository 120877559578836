import Route from '@ember/routing/route';
import query from 'vault-client/graphql/queries/businesses/index.graphql';
import { queryManager } from 'ember-apollo-client';

export default class BusinessesIndexRoute extends Route {
	@queryManager apollo: any;

	async model() {
		return this.apollo.watchQuery({ query });
	}
}
