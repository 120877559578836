import Route from '@ember/routing/route';
import { tracked } from '@glimmer/tracking';
import { gql, useQuery } from 'glimmer-apollo';

import {
	Count,
	GrainTargetOrder,
	GrainTargetOrderFilterDTO,
	GrainTargetOrderSortByDTO,
	Query_GrainTargetOrdersArgs,
	SortByDirection,
	TypeOfGrainPlan,
} from 'vault-client/types/graphql-types';

type SortObj = {
	valuePath: string;
	isAscending: boolean;
};

export const GET_TARGETS = gql`
	query Targets($limit: Float, $offset: Float, $orderBy: GrainTargetOrderSortByDTO, $where: GrainTargetOrderFilterDTO) {
		GrainTargetOrders(orderBy: $orderBy, where: $where, limit: $limit, offset: $offset) {
			id
			bushels
			futuresMonth
			deliveryStartDate
			deliveryEndDate
			deliveryMonth
			salesType
			futurePrice
			fees
			spread
			basis
			expirationDate
			contractNumber
			planId
			status
			updatedAt
			BuyerRelationsOwner {
				email
				firstName
				id
				lastName
				roles
			}
			StatusUpdates {
				id
				updatedAt
			}

			Activity {
				type
				id
				data
				GrainPlan {
					id
					type
				}
			}
			Buyer {
				id
				name
			}
			DeliveryLocation {
				id
				name
			}
			Seller {
				id
				name
			}
			Location {
				id
				name
			}
			Plan {
				id
				type
				... on GrainCropPlan {
					acres
					aph
					cropId
					customerId
					Customer {
						id
						name
						RelationshipOwner {
							id
							firstName
							lastName
						}
					}
					Crop {
						id
						name
					}
					CropYear {
						id
						year
					}
				}
			}
		}
		GrainTargetOrderCount(where: $where) {
			count
		}
	}
`;

export type GetTargets = {
	__typename?: 'Query';
	GrainTargetOrderCount: Count;
	GrainTargetOrders: Array<GrainTargetOrder>;
};

export default class CropTargetsRoute extends Route {
	templateName = 'crop-targets/index';

	queryParams = {
		customerId: { refreshModel: true },
		page: { refreshModel: true },
		sorts: { refreshModel: true },
		size: { refreshModel: true },
	};

	getTargets = useQuery<GetTargets, Query_GrainTargetOrdersArgs>(this, () => [
		GET_TARGETS,
		{ variables: this.variables, pollInterval: 10000 },
	]);

	@tracked variables: Query_GrainTargetOrdersArgs = {};

	generateOrderBy(sorts: SortObj[]): GrainTargetOrderSortByDTO {
		const orderBy: {
			[key: string]: any;
		} = {};

		sorts.forEach((sort: any) => {
			const value = sort.isAscending ? SortByDirection.Asc : SortByDirection.Desc;
			orderBy[sort.valuePath] = value;
		});

		return orderBy;
	}

	generateWhere(organizationId: null | string, customerId: undefined | null | string): GrainTargetOrderFilterDTO {
		const where: GrainTargetOrderFilterDTO = {
			Plan: {
				type: {
					equals: TypeOfGrainPlan.Crop,
				},
				...(customerId && {
					customerId: {
						equals: customerId,
					},
				}),
				...(organizationId && {
					organizationId: {
						equals: organizationId,
					},
				}),
			},
		};

		return where;
	}
}
