import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { DateTime } from 'luxon';
import { UiDateFilterOption } from 'vault-client/components/vault/ui-date-filter';
import { CellComponents, TableColumn } from 'vault-client/types/vault-table';
import { ModelFrom } from 'vault-client/utils/type-utils';
import { FeedIngredient, Seller } from 'vault-client/types/graphql-types';
import BusinessesBusinessLivestockFeedOrdersRoute from 'vault-client/routes/businesses/business/livestock-feed-orders';
import { UiTagStatus } from 'vault-client/components/ui-tag';

type FeedOrderRow = {
	vendor?: string;
	updatedAt?: string;
	status?: string;
	expiresAt?: string;
	tons?: number;
	dmiTons?: number;
	futuresMonth?: string;
	deliveryStartDate?: string;
	deliveryEndDate?: string;
	salesType?: string;
	futuresPrice?: number;
	basis?: number;
	seller?: string;
	location?: string;
	delivery?: string;
	fob?: string;
	contractNumber?: string;
};

export default class LivestockFeedOrdersController extends Controller {
	@tracked startDate: string = DateTime.now().startOf('month').toISODate();
	@tracked endDate: string = DateTime.now().plus({ months: 11 }).endOf('month').toISODate();
	@tracked selectedVendorId: string | null = null;
	@tracked selectedFeedIngredientId: string | null = null;

	declare model: ModelFrom<BusinessesBusinessLivestockFeedOrdersRoute>;

	queryParams = ['startDate', 'endDate', 'selectedVendorId', 'selectedFeedIngredientId'];

	timePeriodOptions: UiDateFilterOption[] = [
		{
			displayName: 'Previous 24 Months',
			startDate: DateTime.now().minus({ months: 24 }).startOf('month').toISODate(),
			endDate: DateTime.now().minus({ months: 1 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Previous 18 Months',
			startDate: DateTime.now().minus({ months: 18 }).startOf('month').toISODate(),
			endDate: DateTime.now().minus({ months: 1 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Previous 12 Months',
			startDate: DateTime.now().minus({ months: 12 }).startOf('month').toISODate(),
			endDate: DateTime.now().minus({ months: 1 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Next 12 Months',
			startDate: DateTime.now().startOf('month').toISODate(),
			endDate: DateTime.now().plus({ months: 11 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Next 18 Months',
			startDate: DateTime.now().startOf('month').toISODate(),
			endDate: DateTime.now().plus({ months: 17 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Next 24 Months',
			startDate: DateTime.now().startOf('month').toISODate(),
			endDate: DateTime.now().plus({ months: 23 }).endOf('month').toISODate(),
		},
	];

	get selectedFeedIngredient(): FeedIngredient | null {
		return this.model.getFeedOrders.data?.FeedIngredients.find((v) => v.id === this.selectedFeedIngredientId) ?? null;
	}

	get selectedVendor(): Seller | null {
		// TODO: Implement selected vendor search when relevant Seller query is known
		return null;
	}

	get tableRoute() {
		return `feed-orders.${this.model.businessId}-table`;
	}

	get currentTimePeriodOption() {
		return (
			this.timePeriodOptions.find((option) => option.startDate === this.startDate && option.endDate === this.endDate) ?? {
				startDate: this.startDate,
				endDate: this.endDate,
			}
		);
	}

	get columns(): TableColumn[] {
		return [
			{
				id: '9b73388b-c2f6-4b1a-a427-a13e7d782ad4',
				name: 'Vendor',
				valuePath: 'vendor',
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
			{
				id: '335b3d66-0b8f-4f62-a67f-d0fc6f7596e3',
				name: 'Updated At',
				valuePath: 'updatedAt',
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
			{
				id: 'edd2c0c9-a957-4261-955e-36cea0939198',
				name: 'Status',
				valuePath: 'status',
				cellComponent: CellComponents.Tag,
				componentArgs: {
					statusFn: (text: string | undefined): UiTagStatus => {
						switch (text) {
							case 'Filled':
								return 'positive';
							case 'Pending':
								return 'warning';
							default:
								return 'neutral';
						}
					},
				},
				textAlign: 'left',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
			{
				id: '42551e3c-d9ea-4299-b96e-7007a08eb3f9',
				name: 'ExpiresAt',
				valuePath: 'expiresAt',
				cellComponent: CellComponents.MonthFormat,
				textAlign: 'right',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
			{
				id: '615e51f6-5b31-47b6-8b22-380cabf2d3dc',
				name: 'Tons',
				valuePath: 'tons',
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'right',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
			{
				id: 'a7ef3b1d-48ba-4ccf-a052-63d7e5f5a17b',
				name: 'DMI Tons',
				valuePath: 'dmiTons',
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'right',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
			{
				id: 'af2c33c4-d3ae-4661-9650-a50cf5dd89d7',
				name: 'Futures Month',
				valuePath: 'futuresMonth',
				cellComponent: CellComponents.MonthFormat,
				textAlign: 'left',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
			{
				id: 'bc20c4ed-0fcd-4073-945b-f79512654382',
				name: 'Delivery Start Date',
				valuePath: 'deliveryStartDate',
				cellComponent: CellComponents.MonthFormat,
				textAlign: 'left',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
			{
				id: '4824e2a8-6971-4db3-ae8e-68b78e71783a',
				name: 'Delivery End Date',
				valuePath: 'deliveryEndDate',
				cellComponent: CellComponents.MonthFormat,
				textAlign: 'left',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
			{
				id: '59dd7f4c-895a-4b31-af12-30e088e0e4e2',
				name: 'Sales Type',
				valuePath: 'salesType',
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
			{
				id: '26f7293a-2387-4607-981a-e7bd67ab7054',
				name: 'Futures Price',
				valuePath: 'futuresPrice',
				cellComponent: CellComponents.MonthFormat,
				textAlign: 'right',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
			{
				id: '391d6e88-26b4-40de-b13f-099c587c4518',
				name: 'Basis',
				valuePath: 'basis',
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'right',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
			{
				id: '548f6b9e-6f7b-430a-ab99-1e1e801dc8f4',
				name: 'Seller',
				valuePath: 'seller',
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
			{
				id: '4117c1bd-1da3-40f0-89d7-befe22da50ec',
				name: 'Location',
				valuePath: 'location',
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
			{
				id: 'f1fe9b1b-42e3-455f-9e91-54ff44e99252',
				name: 'Delivery',
				valuePath: 'delivery',
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
			{
				id: 'b4b94592-62bc-4996-84c1-45ad188f0914',
				name: 'FOB',
				valuePath: 'fob',
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
			{
				id: '9b4b53d0-fb81-41ba-9a2c-41b8954b344d',
				name: 'Contract Number',
				valuePath: 'contractNumber',
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isSortable: false,
				isVisible: true,
				isFixed: '',
			},
		];
	}

	@action
	setSelectedVendor(vendor: Seller | null, callback?: () => void) {
		this.selectedVendorId = vendor?.id ?? null;

		if (callback) {
			callback();
		}
	}

	get rows(): FeedOrderRow[] {
		// TODO: Implement pulling order data from model
		return [];
	}

	@action
	setSelectedFeedIngredient(ingredient: FeedIngredient | null, callback?: () => void) {
		this.selectedFeedIngredientId = ingredient?.id ?? null;

		if (callback) {
			callback();
		}
	}

	@action
	setTimePeriod(option: UiDateFilterOption) {
		this.startDate = option.startDate;
		this.endDate = option.endDate;
	}
}
