import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='mb-2' id={{this.legendId}}></div>\n<div class='relative' ...attributes>\n\t<canvas id={{this.chartId}} {{did-insert this.createChart}} {{did-update this.updateChart this.data}}></canvas>\n</div>", {"contents":"<div class='mb-2' id={{this.legendId}}></div>\n<div class='relative' ...attributes>\n\t<canvas id={{this.chartId}} {{did-insert this.createChart}} {{did-update this.updateChart this.data}}></canvas>\n</div>","moduleName":"vault-client/components/scenario-chart.hbs","parseOptions":{"srcName":"vault-client/components/scenario-chart.hbs"}});
import { guidFor } from '@ember/object/internals';
import { service } from '@ember/service';
import Component from '@glimmer/component';
import { DateTime } from 'luxon';
import ScenarioPricesService from 'vault-client/services/scenario-prices';
import { Future } from 'vault-client/types/graphql-types';
import { action } from '@ember/object';
import { dasherize } from '@ember/string';
import { Chart, ChartConfiguration, ChartData, ChartOptions } from 'chart.js';

import { getCustomLegend, getCustomTooltip } from 'vault-client/utils/chart-utils';

interface ScenarioChartArgs {
	id: string;
	futures: Future[];
	enableScenarioDragAndDrop?: boolean;
}

export default class ScenarioChart extends Component<ScenarioChartArgs> {
	@service('scenarioPrices') declare pricesService: ScenarioPricesService;
	chart: Chart | null = null;

	rounding = 5;
	id = guidFor(this);

	get futures() {
		return this.args.futures.sortBy('displayExpiresAt');
	}

	get product() {
		return this.futures.find((future) => future.Product != null)?.Product;
	}

	get chartId() {
		return this.args.id ?? `scenario-chart-${dasherize(this.product?.name ?? '')}-${this.id}`;
	}

	get legendId() {
		return this.args.id ? `${this.args.id}-legend` : `scenario-chart-legend-${dasherize(this.product?.name ?? '')}-${this.id}`;
	}

	get displayFactor() {
		return this.futures.find((future) => future.SymbolGroup.displayFactor != null)?.SymbolGroup.displayFactor ?? 1;
	}

	get fractionDigits() {
		return this.futures.find((future) => future.SymbolGroup.fractionDigits != null)?.SymbolGroup.fractionDigits ?? 2;
	}

	get marketPrices() {
		return this.futures.map((future) => {
			return this.barchartToDisplayPrice(this.getMarketPrice(future));
		});
	}

	get scenarioPrices() {
		return this.futures.map((future) => {
			return this.barchartToDisplayPrice(this.getScenarioPrice(future));
		});
	}

	get data() {
		return {
			market: this.marketPrices,
			scenario: this.scenarioPrices,
		};
	}

	get labels() {
		return this.futures.map((futures) => DateTime.fromISO(futures.displayExpiresAt).toFormat('LLL yyyy'));
	}
	get chartData(): ChartData<'line'> {
		const labels = this.labels;
		const datasets = [
			{
				label: 'Futures',
				data: this.data.market,
				backgroundColor: '#0068C9',
				borderColor: '#0068C9',
				tension: 0.3,
				pointRadius: 4,
				pointBackgroundColor: '#E3E3E3',
				dragData: false,
				order: 1,
			},
			{
				label: 'Scenario',
				data: this.data.scenario,
				backgroundColor: '#019F9F',
				borderColor: '#019F9F',
				pointRadius: 4,
				tension: 0.3,
				pointBackgroundColor: '#E3E3E3',
				pointHitRadius: 25,
				order: 0,
				dragData: this.args.enableScenarioDragAndDrop != null ? this.args.enableScenarioDragAndDrop : true,
			},
		];

		return {
			labels,
			datasets,
		};
	}

	get chartPlugins() {
		const chartId = this.chartId;
		const legendId = this.legendId;

		return [
			{
				afterUpdate: getCustomLegend(chartId, legendId),
			},
		];
	}

	get chartOptions(): ChartOptions<'line'> {
		const options = {
			maintainAspectRatio: false,
			spanGaps: true,
			responsive: true,
			interaction: {
				intersect: false,
				mode: 'index' as const,
			},
			scales: {
				y: {
					suggestedMax: () => {
						return Math.max(...this.scenarioPrices.filter((price) => price !== null)) * 1.2;
					},
					suggestedMin: () => {
						return Math.min(...this.scenarioPrices.filter((price) => price !== null)) * 0.8;
					},

					ticks: {
						autoSkip: false,
						font: {
							size: 12,
						},
						color: getComputedStyle(document.documentElement).getPropertyValue('--brand-gray-80'),
						callback: (value: number) => {
							return new Intl.NumberFormat('en-US', {
								style: 'currency',
								currency: 'USD',
								minimumFractionDigits: this.fractionDigits,
							}).format(value);
						},
					},
				},
				x: {
					ticks: {
						autoSkip: true,
						color: getComputedStyle(document.documentElement).getPropertyValue('--brand-gray-80'),
						font: {
							size: 12,
						},
					},
					grid: {
						display: true,
					},
				},
			},

			plugins: {
				dragData: {
					dragDataRound: this.fractionDigits,
					onDragStart: (e: Event) => {
						(e.target as HTMLElement).style.cursor = 'grabbing';
					},
					onDragEnd: (e: Event, _datasetIndex: number, index: number, value: number) => {
						(e.target as HTMLElement).style.cursor = 'default';
						const future = this.futures.objectAt(index);
						const barchartSymbol = future?.barchartSymbol;
						if (!barchartSymbol) {
							console.error(`Barchart symbol not found for future: ${JSON.stringify(future)}. Price could not be set`);
							return;
						}

						const price = this.displayToBarchartPrice(value);

						this.pricesService.setScenarioPrice(barchartSymbol, price);
					},
				},
				legend: {
					display: false,
					labels: {
						usePointStyle: true,
						boxWidth: 2,
					},
				},
				tooltip: {
					enabled: false,
					external: getCustomTooltip({
						valueFormatter: (val: number | bigint | string) => {
							if (typeof val === 'string') {
								return val;
							} else {
								return new Intl.NumberFormat(undefined, {
									minimumFractionDigits: this.fractionDigits,
									style: 'currency',
									currency: 'USD',
								}).format(val);
							}
						},
					}),
				},
			},
		};

		return options;
	}

	@action
	createChart(element: HTMLCanvasElement) {
		const config: ChartConfiguration = {
			type: 'line' as const,
			data: this.chartData,
			options: this.chartOptions,
			plugins: this.chartPlugins as any[],
		};

		const chart = new Chart(element, config);
		this.chart = chart;
	}

	@action
	updateChart() {
		this.chart?.data.datasets.forEach((dataset) => {
			if (dataset.label === 'Futures') {
				dataset.data = this.data.market;
			} else {
				dataset.data = this.data.scenario;
			}
		});

		if (this.chart?.data.labels) {
			this.chart.data.labels = this.labels;
		}
		this.chart?.update();
	}

	getScenarioPrice(future: Future) {
		const symbol = future.barchartSymbol;
		if (!symbol) return null;
		return this.pricesService.getScenarioPrice(symbol);
	}

	getMarketPrice(future: Future) {
		const symbol = future.barchartSymbol;
		if (!symbol) return null;
		return this.pricesService.getMarketPrice(symbol);
	}

	barchartToDisplayPrice(price: number | null) {
		if (price == null) return null;

		return price * this.displayFactor;
	}

	displayToBarchartPrice(price: number | null) {
		if (price == null) return null;

		return price / this.displayFactor;
	}
}
