import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import BusinessesBusinessAnimalGroupsRoute from 'vault-client/routes/businesses/business/animal-groups';
import { BusinessEntityRole, LivestockGroupVersion, SwineLivestockGroup } from 'vault-client/types/graphql-types';
import { CellComponents, TableColumn } from 'vault-client/types/vault-table';
import resetVaultTableScroll from 'vault-client/utils/reset-vault-table-scroll';
import { ModelFrom } from 'vault-client/utils/type-utils';

export default class BusinessesBusinessAnimalGroupsController extends Controller {
	declare model: ModelFrom<BusinessesBusinessAnimalGroupsRoute>;
	@tracked swineStageToMerge: SwineLivestockGroup | null = null;
	@tracked swineStageToSplit: SwineLivestockGroup | null = null;
	@tracked swineStageToEdit: SwineLivestockGroup | null = null;
	@tracked showVersionBanner: boolean = true;
	@tracked showWarningBanner: boolean = false;
	@tracked warningText: string = '';
	@tracked selectedVersion: LivestockGroupVersion | undefined = this.model.livestockGroupVersions.data?.LivestockGroupVersions.find(
		(version: LivestockGroupVersion) => version.isCurrent === true
	);

	baseColumns: TableColumn[] = [
		{
			id: '15egab7b-1e2f-4a70-5008-d8e723608evb',
			name: 'Name',
			valuePath: 'name',
			minWidth: 130,
			textAlign: 'left',
			headerTextAlign: 'left',
			isSortable: false,
			cellComponent: CellComponents.String,
			isFixed: '',
			isVisible: true,
		},
		{
			id: '3936161b-5d1f-4c3f-v311-57e3c3qa008f',
			name: 'Starting Week',
			valuePath: 'minAgeInWeeks',
			minWidth: 80,
			textAlign: 'left',
			headerTextAlign: 'left',
			isSortable: false,
			cellComponent: CellComponents.String,
			isFixed: '',
			isVisible: true,
		},
		{
			id: '3936161c-5d2f-4c3f-v311-56e3c3qw006f',
			name: 'Ending Week',
			valuePath: 'maxAgeInWeeks',
			minWidth: 80,
			textAlign: 'left',
			headerTextAlign: 'left',
			isSortable: false,
			cellComponent: CellComponents.String,
			isFixed: '',
			isVisible: true,
		},
		{
			id: '4926161b-5g1f-4j3f-v311-5e83c3qa118f',
			name: 'Comment',
			valuePath: 'description',
			minWidth: 130,
			textAlign: 'left',
			headerTextAlign: 'left',
			isSortable: false,
			cellComponent: CellComponents.String,
			isFixed: '',
			isVisible: true,
		},
		{
			id: '7926141b-5g1w-4j3f-v311-9e83c3ql118f',
			name: 'Mortality Rate',
			valuePath: 'deathRate',
			minWidth: 80,
			textAlign: 'right',
			headerTextAlign: 'right',
			isSortable: false,
			cellComponent: CellComponents.IntlNumberFormat,
			componentArgs: {
				style: 'percent',
				minimumFractionDigits: 0,
				maximumFractionDigits: 2,
			},
			isFixed: '',
			isVisible: true,
		},
	];

	get currentVersionColumns(): TableColumn[] {
		const allColumns: TableColumn[] = [...this.baseColumns];
		const buttonColumns: TableColumn[] = [
			{
				id: '2581k43e-a97e-4dae-a2ca-cd12bbsf7783',
				name: '',
				valuePath: 'id',
				minWidth: 110,
				textAlign: 'right',
				isSortable: false,
				cellComponent: CellComponents.Button,
				componentArgs: {
					size: 'xs',
					style: 'utility',
					text: 'Merge',
					fn: this.mergeStage,
				},
				isFixed: '',
				isVisible: true,
			},
			{
				id: '3ab6c9ab-2735-46cw-a54d-cb5ejedcab77',
				name: '',
				valuePath: 'id',
				minWidth: 110,
				textAlign: 'right',
				isSortable: false,
				cellComponent: CellComponents.Button,
				componentArgs: {
					size: 'xs',
					style: 'utility',
					text: 'Split',
					fn: this.splitStage,
				},
				isFixed: '',
				isVisible: true,
			},
			{
				id: '0a26c9ab-2735-43cb-a54d-cb5efedcqb55',
				name: '',
				valuePath: 'id',
				minWidth: 110,
				textAlign: 'right',
				isSortable: false,
				cellComponent: CellComponents.Button,
				componentArgs: {
					size: 'xs',
					style: 'utility',
					text: 'Edit',
					fn: this.editStage,
				},
				isFixed: '',
				isVisible: true,
			},
		];
		buttonColumns.forEach((column) => allColumns.push(column));
		return allColumns;
	}

	get previousVersionColumns(): TableColumn[] {
		const allColumns: TableColumn[] = [...this.baseColumns];
		const buttonColumns: TableColumn[] = [
			{
				id: '2581k43e-a97e-4dae-a2ca-cd12bbsf7783',
				name: '',
				valuePath: 'id',
				width: 80,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.EmptyCell,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '3ab6c9ab-2735-46cw-a54d-cb5ejedcab77',
				name: '',
				valuePath: 'id',
				width: 80,
				textAlign: 'right',
				isSortable: false,
				cellComponent: CellComponents.EmptyCell,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '0a26c9ab-2735-43cb-a54d-cb5efedcqb55',
				name: '',
				valuePath: 'id',
				width: 80,
				textAlign: 'right',
				isSortable: false,
				cellComponent: CellComponents.EmptyCell,
				isFixed: '',
				isVisible: true,
			},
		];
		buttonColumns.forEach((column) => allColumns.push(column));
		return allColumns;
	}

	get isCurrentVersion() {
		return this.selectedVersion?.isCurrent;
	}

	get swineStagesColumns(): TableColumn[] {
		if (this.isCurrentVersion) {
			return this.currentVersionColumns;
		}
		return this.previousVersionColumns;
	}

	get columnTotals() {
		const totalDeathRate = this.selectedVersionLivestockGroups?.reduce((total, group) => group.deathRate + total, 0);
		return [
			{
				deathRate: totalDeathRate,
			},
		];
	}

	get hasHogProducerRole() {
		return this.selectedVersion?.Business.businessRoles.includes(BusinessEntityRole.HogProducer);
	}

	get selectedVersionLivestockGroups() {
		return this.model.livestockGroups.data?.Customer.SwineLivestockGroups.filter((group) => group.versionId === this.selectedVersion?.id);
	}

	get lastUpdatedBy() {
		return this.selectedVersion?.LastUpdatedByUser
			? this.selectedVersion?.LastUpdatedByUser.firstName + ' ' + this.selectedVersion?.LastUpdatedByUser.lastName
			: this.selectedVersion?.CreatedByUser
			? this.selectedVersion?.CreatedByUser.firstName + ' ' + this.selectedVersion?.CreatedByUser.lastName
			: null;
	}

	get lastUpdatedAt() {
		return this.selectedVersion?.updatedAt ?? this.selectedVersion?.createdAt;
	}

	@action
	changeVersion(id: string, callback: () => void) {
		this.selectedVersion = this.model.livestockGroupVersions.data?.LivestockGroupVersions.find(
			(version: LivestockGroupVersion) => version.id === id
		);
		this.showVersionBanner = true;
		resetVaultTableScroll('business-settings-swine-stages-table');
		callback();
	}

	@action closeWarning() {
		this.showWarningBanner = false;
	}

	@action
	mergeStage(row: SwineLivestockGroup) {
		if (this.selectedVersionLivestockGroups?.length === 1) {
			this.showWarningBanner = true;
			this.warningText = 'Cannot merge if there is only one stage';
			setTimeout(() => {
				this.showWarningBanner = false;
			}, 6000);
			return;
		}
		this.swineStageToMerge = { ...row };
	}

	@action
	splitStage(row: SwineLivestockGroup) {
		if (row.minAgeInWeeks + 1 === row.maxAgeInWeeks) {
			this.showWarningBanner = true;
			this.warningText = 'Cannot split a one week period';
			setTimeout(() => {
				this.showWarningBanner = false;
			}, 6000);
			return;
		}
		this.swineStageToSplit = { ...row };
	}

	@action
	editStage(row: SwineLivestockGroup) {
		this.swineStageToEdit = { ...row };
	}

	@action
	closeMergeStageModal() {
		this.swineStageToMerge = null;
		this.selectCurrentVersion();
	}

	@action
	closeSplitStageModal() {
		this.swineStageToSplit = null;
		this.selectCurrentVersion();
	}

	@action
	closeEditStageModal() {
		this.swineStageToEdit = null;
	}

	@action
	selectCurrentVersion() {
		this.selectedVersion = this.model.livestockGroupVersions.data?.LivestockGroupVersions.find(
			(version: LivestockGroupVersion) => version.isCurrent === true
		);
	}

	@action
	toggleshowVersionBanner() {
		this.showVersionBanner = false;
	}
}
