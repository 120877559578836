import Route from '@ember/routing/route';
import { Product } from 'vault-client/types/graphql-types';
import ENV from 'vault-client/config/environment';

interface ModelParams {
	startDate: string;
	endDate: string;
}
export default class MarketsProductCMESpotRoute extends Route {
	queryParams = {
		startDate: {
			refreshModel: true,
		},
		endDate: {
			refreshModel: true,
		},
	};

	async model(params: ModelParams) {
		const { Product } = this.modelFor('markets.product') as { Product: Product };

		const spotInstrument = Product.CashInstrument;
		const frontFuture = Product.MostCurrentFuture;

		if (!spotInstrument?.barchartSymbol) {
			return {
				SpotInstrument: null,
				SpotInstrumentData: [],
				FrontFuture: null,
				FrontFutureData: [],
				Product: Product,
			};
		}

    const apiKey: string = ENV.barchartApiToken;
		const spotInstrumentParams = new URLSearchParams({
			apikey: apiKey,
			symbol: spotInstrument.barchartSymbol,
			startDate: params.startDate,
			endDate: params.endDate,
			type: 'daily',
			volume: 'sum',
		});

		const spotInstrumentHistoricalDataPromise = fetch(
			'https://ondemand.websol.barchart.com/getHistory.json?' + spotInstrumentParams.toString()
		)
			.then((res) => res.json())
			.then((json) => json.results);

		if (!frontFuture?.barchartSymbol) {
			const spotInstrumentHistoricalData = await spotInstrumentHistoricalDataPromise;
			return {
				SpotInstrument: spotInstrument,
				SpotInstrumentData: spotInstrumentHistoricalData,
				FrontFuture: frontFuture,
				FrontFutureData: [],
				Product: Product,
			};
		} else {
			const frontFutureParams = new URLSearchParams({
				apikey: apiKey,
				symbol: frontFuture.barchartSymbol,
				startDate: params.startDate,
				endDate: params.endDate,
				type: 'daily',
				volume: 'sum',
			});

			const frontFutureHistoricalDataPromise = fetch('https://ondemand.websol.barchart.com/getHistory.json?' + frontFutureParams.toString())
				.then((res) => res.json())
				.then((json) => json.results);

			const [spotInstrumentHistoricalData, frontFutureHistoricalData] = await Promise.all([
				spotInstrumentHistoricalDataPromise,
				frontFutureHistoricalDataPromise,
			]);

			return {
				SpotInstrument: spotInstrument,
				SpotInstrumentData: spotInstrumentHistoricalData,
				FrontFuture: frontFuture,
				FrontFutureData: frontFutureHistoricalData,
				Product: Product,
			};
		}
	}
}
