import ReportsInventoryHedgesByProduct from 'vault-client/controllers/reports/inventory-hedges-by-product';

export default class BusinessesBusinessInventoryHedgesByProduct extends ReportsInventoryHedgesByProduct {
	reportsRoute: string = 'businesses.business.reports';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'businesses/business/inventory-hedges-by-product': BusinessesBusinessInventoryHedgesByProduct;
	}
}
