import PositionsIndexController from 'vault-client/controllers/positions';

export default class BusinessesBusinessPositions extends PositionsIndexController {
	accountRoute: string = 'businesses.business.account';
	positionDetailRoute: string = 'businesses.business.position';
	brokerageRoutePath: string = 'businesses.business.brokerage';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'businesses/business/positions': BusinessesBusinessPositions;
	}
}
