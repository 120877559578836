import FeedCostsRoute from 'vault-client/routes/feed/costs';

export default class BusinessesBusinessFeedCostsRoute extends FeedCostsRoute {
	async model() {
		const { business_id: businessId } = this.paramsFor('businesses.business') as { business_id: string };

		this.fixedFeedCostCategoriesVars = {
			getFixedFeedCostsCategoriesWhere: this.generateFixedFeedCostsWhere(businessId),
		};

		await this.getFixedFeedCostsCategories.promise;

		return {
			getFixedFeedCosts: this.getFixedFeedCostsCategories,
			id: businessId,
		};
	}
}
