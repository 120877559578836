import Route from '@ember/routing/route';
import Transition from '@ember/routing/transition';
import { RouteQueryParam } from '@ember/routing/types';
import { formatISO, parseISO, startOfWeek } from 'date-fns';
import { gql, useQuery } from 'glimmer-apollo';
import { DateTime } from 'luxon';
import { tracked } from 'tracked-built-ins';
import BusinessesBusinessWeanedPigsController from 'vault-client/controllers/businesses/business/weaned-pigs';
import { Query, SwineLivestockPopulationChangeFilterDTO, TypeOfLivestockPopulationChangeReason } from 'vault-client/types/graphql-types';
import checkStorageAvailable from 'vault-client/utils/check-storage-available';
import { ModelFrom } from 'vault-client/utils/type-utils';

const GET_WEANED_PIGS = gql`
	query weanedPigs($customerId: String!, $where: SwineLivestockPopulationChangeFilterDTO) {
		Customer(id: $customerId) {
			id
			averageFinishAgeInWeeks
		}
		AggregateSwineLivestockPopulationChanges(
			calc: { sum: { totalValue: true, quantity: true } }
			groupBy: { dob: true, reasonType: true }
			where: $where
		) {
			sum {
				totalValue
				quantity
			}
			dob
			reasonType
		}
	}
`;

type GetWeanedPigsQuery = {
	__typename?: 'Query';
	Customer: Query['Customer'];
	AggregateSwineLivestockPopulationChanges: Query['AggregateSwineLivestockPopulationChanges'];
};

type GetWeanedPigsQueryArgs = {
	customerId?: string;
	where?: SwineLivestockPopulationChangeFilterDTO;
};

type ModelParams = {
	startDate: string;
	endDate: string;
};

export default class BusinessesBusinessWeanedPigsRoute extends Route {
	queryParams: { [key: string]: RouteQueryParam } = {
		startDate: {
			refreshModel: true,
		},
		endDate: {
			refreshModel: true,
		},
	};

	@tracked variables: GetWeanedPigsQueryArgs = {};

	getWeanedPigs = useQuery<GetWeanedPigsQuery, GetWeanedPigsQueryArgs>(this, () => [
		GET_WEANED_PIGS,
		{
			variables: this.variables,
		},
	]);

	generateWeanedPigsWhere(customerId: string, startDate: string, endDate: string): SwineLivestockPopulationChangeFilterDTO {
		return {
			businessId: {
				equals: customerId,
			},
			reasonType: {
				in: [TypeOfLivestockPopulationChangeReason.Birth, TypeOfLivestockPopulationChangeReason.Purchase],
			},
			dob: {
				gte: formatISO(startOfWeek(parseISO(startDate), { weekStartsOn: 0 }), { representation: 'date' }),
				lte: formatISO(startOfWeek(parseISO(endDate), { weekStartsOn: 0 }), { representation: 'date' }),
			},
		};
	}

	async model(params: ModelParams) {
		const businessParams = this.paramsFor('businesses.business') as { business_id: string };
		const { startDate: storedStartDate, endDate: storedEndDate } = this.retrieveStoredStartAndEndDate(businessParams.business_id);

		const startDate = storedStartDate ?? params.startDate;
		const endDate = storedEndDate ?? params.endDate;

		this.variables = {
			customerId: businessParams.business_id,
			where: this.generateWeanedPigsWhere(businessParams.business_id, startDate, endDate),
		};

		await this.getWeanedPigs.promise;

		const weeksUntilMarket = this.getWeanedPigs.data?.Customer?.averageFinishAgeInWeeks ?? 32;

		return {
			lastUpdatedAt: DateTime.now().toISODate(),
			customerId: businessParams.business_id,
			getWeanedPigs: this.getWeanedPigs,
			weeksUntilMarket,
			startDate,
			endDate,
		};
	}

	retrieveStoredStartAndEndDate(entityId: string) {
		const startAndEndDates: {
			startDate: string | null;
			endDate: string | null;
		} = { startDate: null, endDate: null };
		if (checkStorageAvailable('localStorage')) {
			const storedDateRange = window.localStorage.getItem(`${entityId}.weaned-pigs.dateRange`);
			const parsedObject = JSON.parse(storedDateRange ?? `{}`) as { startDate?: string; endDate?: string };
			const startDate = parsedObject?.startDate;
			const endDate = parsedObject?.endDate;
			if (startDate) {
				startAndEndDates.startDate = startDate;
			}

			if (endDate) {
				startAndEndDates.endDate = endDate;
			}
		}

		return startAndEndDates;
	}

	setupController(
		controller: BusinessesBusinessWeanedPigsController,
		model: ModelFrom<BusinessesBusinessWeanedPigsRoute>,
		transition: Transition<unknown>
	) {
		super.setupController(controller, model, transition);

		controller.startDate = model.startDate;
		controller.endDate = model.endDate;
	}
}
