import CropFillsIndexController from 'vault-client/controllers/crop-fills';

export default class BusinessesBusinessCropFillsIndexController extends CropFillsIndexController {
	marketingRoutePath: string = 'businesses.business.marketing';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'organizations/organization/crop-fills': BusinessesBusinessCropFillsIndexController;
	}
}
