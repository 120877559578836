import CropTargetsIndexController from 'vault-client/controllers/crop-targets';

export default class BusinessesBusinessCropTargets extends CropTargetsIndexController {
	cropsRoutePath: string = 'businesses.business.crops';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'businesses/business/crop-targets': BusinessesBusinessCropTargets;
	}
}
