import Route from '@ember/routing/route';
import { tracked } from '@glimmer/tracking';
import { gql, useQuery } from 'glimmer-apollo';
import {
	GrainFillOrderModificationRequestFilterDTO,
	GrainFillOrderModificationStatus,
	Query,
	Query_GrainFillOrderModificationRequestsArgs,
	TypeOfGrainPlan,
} from 'vault-client/types/graphql-types';

const GET_FEED_REQUESTS = gql`
	query GrainFillOrderModificationRequests($where: GrainFillOrderModificationRequestFilterDTO) {
		GrainFillOrderModificationRequests(where: $where, orderBy: { createdAt: Asc }) {
			id
			createdAt
			type
			fillId
			status
			type
			OwnedByUser {
				id
				firstName
				lastName
			}
			Location {
				id
				name
			}
			DeliveryLocation {
				id
				name
			}
			Seller {
				id
				name
			}
			data {
				basis
				bushels
				deliveryStartDate
				deliveryEndDate
				deliveryId
				futurePrice
				futuresMonth
				locationId
				flexFee
				htaFee
				rollFee
				spread
				sellerId
			}
			RequestedByUser {
				id
				firstName
				lastName
				email
			}
			GrainFillOrder {
				id
				createdAt
				contractNumber
				createdBy
				createdBy
				deliveryEndDate
				deliveryId
				deliveryMonth
				deliveryStartDate
				basis
				bushels
				futurePrice
				futuresMonth
				salesType
				flexFee
				htaFee
				rollFee
				spread
				sellerId
				DeliveryLocation {
					id
					name
				}
				Location {
					id
					name
				}
				Seller {
					id
					name
				}
				Plan {
					id
					type
					... on GrainFeedPlan {
						hasWriteAccess
						Customer {
							id
							name
						}
						FeedCategory {
							id
							name
						}
						startDate
					}
				}
				GrainFillOrderModificationRequests {
					id
					status
				}
				GrainTargetOrder {
					id
				}
			}
		}
	}
`;

type GetRequests = {
	__typename?: 'Query';
	GrainFillOrderModificationRequests: Query['GrainFillOrderModificationRequests'];
};

type ModificationRequestQueryParams = {
	modificationStatus: GrainFillOrderModificationStatus | null;
};
export default class VgsFeedServicesModificationRequests extends Route {
	@tracked variables: Query_GrainFillOrderModificationRequestsArgs = {};

	queryParams = {
		modificationStatus: {
			refreshModel: true,
		},
	};

	getFeedRequest = useQuery<GetRequests, Query_GrainFillOrderModificationRequestsArgs>(this, () => [
		GET_FEED_REQUESTS,
		{ variables: this.variables, pollInterval: 10000 },
	]);

	async model(params: ModificationRequestQueryParams) {
		const where: GrainFillOrderModificationRequestFilterDTO = {
			GrainFillOrder: {
				Plan: {
					type: {
						equals: TypeOfGrainPlan.Feed,
					},
				},
			},
			...(params.modificationStatus && {
				status: {
					equals: params.modificationStatus,
				},
			}),
		};

		this.variables = { where };

		await this.getFeedRequest.promise;

		return { feedRequests: this.getFeedRequest };
	}
}
