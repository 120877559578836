export default class Product {
	barchartRootSymbol = null;
	contractName = null;
	contractSize = null;
	cqgRootSymbol = null;
	dailyLimit = null;
	displayFactor = null;
	exchangeMargin = null;
	fractionDigits = null;
	id = null;
	name = null;
	optionsUnitValue = null;
	pointValue = null;
	slug = null;
	tickValue = null;
	tradingHours = null;
	velaRootSymbol = null;
	venue = null;
	CurrentFutures = null;
	ProductLotSpecifications;
	StandardProductLotSpecification;

	constructor(product: any) {
		this.barchartRootSymbol = product.barchartRootSymbol;
		this.contractName = product.contractName;
		this.contractSize = product.contractSize;
		this.cqgRootSymbol = product.cqgRootSymbol;
		this.dailyLimit = product.dailyLimit;
		this.displayFactor = product.displayFactor;
		this.exchangeMargin = product.exchangeMargin;
		this.fractionDigits = product.fractionDigits;
		this.id = product.id;
		this.name = product.name;
		this.optionsUnitValue = product.optionsUnitValue;
		this.pointValue = typeof product.pointValue === 'string' ? product.pointValue.replace(/[^0-9.]/g, '') : product.pointValue;
		this.slug = product.slug;
		this.tickValue = product.tickValue;
		this.tradingHours = product.tradingHours;
		this.velaRootSymbol = product.velaRootSymbol;
		this.venue = product.venue;
		this.CurrentFutures = product.CurrentFutures;
		this.ProductLotSpecifications = product.ProductLotSpecifications;
		this.StandardProductLotSpecification = product.StandardProductLotSpecification;
	}
}
