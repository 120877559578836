import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { Product } from 'vault-client/types/graphql-types';

interface CattleHistoricalPercentilesReportsModel {
	Products: Product[];
}
export default class ReportsCattleHistoricalPercentiles extends Controller {
	model: CattleHistoricalPercentilesReportsModel = this.model;

	@tracked numberOfYears = 10;

	reportsRoute: string = '';
	get liveCattle() {
		return this.model.Products.find((product: Product) => product.slug === 'livestock-live-cattle');
	}

	get feederCattle() {
		return this.model.Products.find((product: Product) => product.slug === 'livestock-feeder-cattle');
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'reports/cattle-historical-percentiles': ReportsCattleHistoricalPercentiles;
	}
}
