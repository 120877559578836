import BrokerageIndexRoute from 'vault-client/routes/brokerage';

export default class OrganizationsOrganizationBrokerageRoute extends BrokerageIndexRoute {
	async model() {
		const organizationParams = this.paramsFor('organizations.organization') as { id: string };
		const variables = this.generateWhere(organizationParams.id, null);

		const response = await this.apollo.watchQuery({ query: this.query, variables });

		return {
			...response,
			variables,
		};
	}
}
