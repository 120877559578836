import { gql } from '@apollo/client/core';
import Route from '@ember/routing/route';
import { tracked } from '@glimmer/tracking';
import { formatISO, subWeeks, startOfWeek, parseISO } from 'date-fns';
import { useQuery } from 'glimmer-apollo';
import { DateTime } from 'luxon';
import {
	ForecastedMilkProductionByMonthFilterDTO,
	InsuranceEndorsementAllocationRatioFilterDTO,
	Query,
	SwineLivestockPopulationChangeFilterDTO,
	TypeOfLivestockPopulationChangeReason,
} from 'vault-client/types/graphql-types';

const GET_LGM_ENDORSEMENTS = gql`
	query GetLgmEndorsements(
		$scopeId: String!
		$lgmEndorsementsWhere: InsuranceEndorsementAllocationRatioFilterDTO!
		$milkProductionWhere: ForecastedMilkProductionByMonthFilterDTO
		$swinePurchasesAndProducedWhere: SwineLivestockPopulationChangeFilterDTO
	) {
		InsuranceEndorsementAllocationRatios(
			scopeId: $scopeId
			orderBy: { InsuranceEndorsement: { salesEffectiveDate: Asc } }
			where: $lgmEndorsementsWhere
			limit: 2000
		) {
			id
			insuranceEndorsementId
			allocationRatio
			createdAt
			effectiveHedgeDate
			hasWriteAccess
			insurancePolicyId
			updatedAt
			RatioAdjustedInsuranceEndorsement {
				... on LgmInsuranceEndorsement {
					id
					RevenueHedgeProduct {
						id
						slug
					}
					InsurancePolicy {
						id
						producerName
					}
					RmaType {
						id
						typeName
						typeCode
					}
					perMonthData
					type
					coverageMonths
					grossMarginGuarantee
					totalTarget
					totalPremiumAmount
					totalExpectedGrossMargin
					pnl
					producerPremiumAmount
					deductibleAmount
					salesEffectiveDate
					revenueHedgeProductId
					indemnity
				}
			}
		}
		SwinePurchasesAndProduced: AggregateSwineLivestockPopulationChanges(
			calc: { sum: { quantity: true } }
			groupBy: { dob: true }
			orderBy: { dob: Asc }
			where: $swinePurchasesAndProducedWhere
		) {
			dob
			sum {
				quantity
			}
		}
		AggregateForecastedMilkProductionByMonths(
			calc: {
				sum: {
					grossProduction: true
					grossProteinProduction: true
					grossButterfatProduction: true
					grossOtherSolidsProduction: true
					grossClassiProduction: true
					grossClassiiProduction: true
					grossClassiiiProduction: true
					grossClassivProduction: true
				}
			}
			groupBy: { date: true }
			where: $milkProductionWhere
			scopeId: $scopeId
			orderBy: { date: Asc }
		) {
			date
			sum {
				grossProduction
				grossProteinProduction
				grossButterfatProduction
				grossOtherSolidsProduction
				grossClassiProduction
				grossClassiiProduction
				grossClassiiiProduction
				grossClassivProduction
			}
		}
	}
`;

interface GetLgmEndorsementsVariables {
	scopeId?: string;
	lgmEndorsementsWhere?: InsuranceEndorsementAllocationRatioFilterDTO;
	milkProductionWhere?: ForecastedMilkProductionByMonthFilterDTO;
	swinePurchasesAndProducedWhere?: SwineLivestockPopulationChangeFilterDTO;
}

interface GetLgmEndorsementsQuery {
	InsuranceEndorsementAllocationRatios: Query['InsuranceEndorsementAllocationRatios'];
	SwinePurchasesAndProduced: Query['AggregateSwineLivestockPopulationChanges'];
	AggregateForecastedMilkProductionByMonths: Query['AggregateForecastedMilkProductionByMonths'];
}

export default class LgmInsuranceMonths extends Route {
	templateName = 'lgm-insurance-months/index';

	queryParams = {
		startDate: {
			refreshModel: true,
		},
		endDate: {
			refreshModel: true,
		},
	};

	@tracked scopeId: string | null = null;
	@tracked variables: GetLgmEndorsementsVariables = {};

	getLgmEndorsements = useQuery<GetLgmEndorsementsQuery, GetLgmEndorsementsVariables>(this, () => [
		GET_LGM_ENDORSEMENTS,
		{
			variables: this.variables,
			fetchPolicy: 'no-cache',
		},
	]);

	generateLGMEndorsementWhere(startDate: string | null, endDate: string | null) {
		const where: InsuranceEndorsementAllocationRatioFilterDTO = {
			InsuranceEndorsement: {
				AsLgmInsuranceEndorsement: {
					id: {
						not: null,
					},
					Product: {
						slug: {
							in: ['livestock-lean-hogs', 'us-dairy-class-iii'],
						},
					},
				},
			},
			effectiveHedgeDate: {
				gte: startDate,
				lte: endDate,
			},
		};

		return where;
	}

	generateSwinePurchasesAndProducedWhere(
		customerId: string,
		startDate: string | null,
		endDate: string | null,
		averageFinishAgeInWeeks: number
	): SwineLivestockPopulationChangeFilterDTO {
		return {
			businessId: { equals: customerId },
			reasonType: {
				in: [TypeOfLivestockPopulationChangeReason.Purchase, TypeOfLivestockPopulationChangeReason.Birth],
			},
			dob: {
				...(startDate && {
					gte: formatISO(subWeeks(startOfWeek(parseISO(startDate), { weekStartsOn: 0 }), averageFinishAgeInWeeks), {
						representation: 'date',
					}),
				}),
				...(endDate && {
					lte: formatISO(subWeeks(startOfWeek(parseISO(endDate), { weekStartsOn: 0 }), averageFinishAgeInWeeks), {
						representation: 'date',
					}),
				}),
			},
		};
	}

	generateMilkProductionWhere(startDate: string | null, endDate: string | null): ForecastedMilkProductionByMonthFilterDTO {
		const productionWhere: ForecastedMilkProductionByMonthFilterDTO = {};

		if (startDate && endDate) {
			productionWhere.date = {
				gte: DateTime.fromISO(startDate).startOf('month').toISODate(),
				lte: DateTime.fromISO(endDate).endOf('month').toISODate(),
			};
		} else if (startDate) {
			productionWhere.date = {
				gte: DateTime.fromISO(startDate).startOf('month').toISODate(),
			};
		} else if (endDate) {
			productionWhere.date = {
				lte: DateTime.fromISO(endDate).endOf('month').toISODate(),
			};
		}

		return productionWhere;
	}
}
