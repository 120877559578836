import Controller from '@ember/controller';
import { ModelFrom } from 'vault-client/utils/type-utils';
import Target from 'vault-client/models/vgs/target';
import { inject as service } from '@ember/service';
import VgsFeedServicesTargetsShowRoute from 'vault-client/routes/vgs/feed-services/targets/show';
import { informationWasUpdated } from 'vault-client/utils/vgs-utils';
export default class VgsFeedServicesTargetsShow extends Controller {
	@service permissions: any;
	declare model: ModelFrom<VgsFeedServicesTargetsShowRoute>;

	get targetModel() {
		const targetData = this.model.target.data?.GrainTargetOrder;
		return targetData ? new Target(targetData, null, null) : null;
	}

	get filteredActivities() {
		return this.targetModel?.orderActivities?.filter((activity) => informationWasUpdated(activity)) ?? [];
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	interface Registry {
		'vgs/feed-services/targets/show': VgsFeedServicesTargetsShow;
	}
}
