import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { gql, useMutation } from 'glimmer-apollo';
import { ModelFrom } from 'vault-client/utils/type-utils';
import route from 'vault-client/routes/vgs/grain-services/crop-plans/show';
import PermissionsService from 'vault-client/services/permissions';
import { inject as service } from '@ember/service';
import { isFormValid, getInvalidElements } from '../utils/form-validation';
import {
	Buyer,
	GrainCropPlan,
	GrainLocation,
	GrainTargetOrderCreateDTO,
	Mutation,
	Mutation_createGrainTargetOrderArgs,
	TypeOfGrainOrderSales,
} from 'vault-client/types/graphql-types';
import { guidFor } from '@ember/object/internals';

const CREATE_CROP_TARGET = gql`
	mutation CreateGrainTargetOrder($data: GrainTargetOrderCreateDTO!) {
		createGrainTargetOrder(data: $data) {
			id
		}
	}
`;

interface AddGrainCropTargetButtonArgs {
	model: ModelFrom<route>;
	cropPlan: GrainCropPlan;
}

export default class AddGrainCropTargetButton extends Component<AddGrainCropTargetButtonArgs> {
	@service declare permissions: PermissionsService;

	componentId = guidFor(this);

	@tracked showModal = false;
	@tracked errorMessage: string | null = null;
	@tracked showConfirmationModal = false;

	@tracked bushels: string | null | undefined = null;
	@tracked futureMonth: string | null = null;
	@tracked deliveryMonth: string | null = null;
	@tracked deliveryStartDate: string | null = null;
	@tracked deliveryEndDate: string | null = null;
	@tracked salesType: TypeOfGrainOrderSales | null = null;
	@tracked futurePrice: string | null = null;
	@tracked spread: string | null = null;
	@tracked htaFee: string = '0';
	@tracked rollFee: string = '0';
	@tracked flexFee: string = '0';
	@tracked basis: string | null = null;
	@tracked buyer: Buyer | null = null;
	@tracked location: GrainLocation | null = null;
	@tracked expiresAt: string | null = null;
	@tracked contractNumber: string | null = null;
	@tracked targetNote: string | null = null;

	createCropTarget = useMutation<{ createGrainTargetOrder: Mutation['createGrainTargetOrder'] }, Mutation_createGrainTargetOrderArgs>(
		this,
		() => [
			CREATE_CROP_TARGET,
			{
				onComplete: (): void => {
					if (this.args.model) {
						this.args.model.getCropPlan.refetch();
					}
					this.closeModal();
				},
				onError: (error): void => {
					this.errorMessage = 'There was an error. Target not added.';
					console.error('Error while attempting to add Target', error.message);
				},
			},
		]
	);

	get disableConfirmAndSubmit(): boolean {
		return this.errorMessage != null || this.createCropTarget.loading;
	}

	@action
	openModal() {
		this.showModal = true;
	}

	@action
	closeModal() {
		this.showModal = false;
		this.errorMessage = null;
		this.showConfirmationModal = false;
		this.bushels = null;
		this.futureMonth = null;
		this.deliveryMonth = null;
		this.deliveryStartDate = null;
		this.deliveryEndDate = null;
		this.salesType = null;
		this.futurePrice = null;
		this.spread = null;
		this.htaFee = '0';
		this.rollFee = '0';
		this.flexFee = '0';
		this.basis = null;
		this.buyer = null;
		this.location = null;
		this.expiresAt = null;
		this.contractNumber = null;
		this.targetNote = null;
	}

	@action
	openConfirmationModal() {
		if (isFormValid(document)) {
			this.showConfirmationModal = true;
			this.showModal = false;
		} else {
			getInvalidElements(document);
		}
	}

	@action
	closeConfirmationModal() {
		this.showConfirmationModal = false;
		this.showModal = true;
		this.errorMessage = null;
	}

	@action
	confirm() {
		this.submitTarget();
	}

	@action
	async submitTarget() {
		if (!this.salesType) {
			this.errorMessage = 'Please select a sales type.';
			return;
		}
		const data: GrainTargetOrderCreateDTO = {
			planId: this.args.cropPlan.id,
			basis: this.basis ? parseFloat(this.basis) : null,
			bushels: this.bushels ? parseFloat(this.bushels) : null,
			buyerId: this.buyer ? this.buyer.id : undefined,
			expirationDate: this.expiresAt ? this.expiresAt : undefined,
			flexFee: this.flexFee ? parseFloat(this.flexFee) : 0,
			futurePrice: this.futurePrice ? parseFloat(this.futurePrice) : null,
			deliveryStartDate: this.deliveryStartDate ? this.deliveryStartDate : undefined,
			deliveryEndDate: this.deliveryEndDate ? this.deliveryEndDate : undefined,
			futuresMonth: this.futureMonth ? this.futureMonth + '-01' : undefined,
			htaFee: this.htaFee ? parseFloat(this.htaFee) : 0,
			locationId: this.location ? this.location.id : undefined,
			rollFee: this.rollFee ? parseFloat(this.rollFee) : 0,
			salesType: this.salesType,
			spread: this.spread ? parseFloat(this.spread) : null,
			shortNote: this.targetNote,
			lastUpdatedBy: this.permissions.userName,
			contractNumber: this.contractNumber,
		};
		await this.createCropTarget.mutate({
			data,
		});
	}
}
