import Controller from '@ember/controller';
import intlNumberFormat from 'vault-client/utils/intl-number-format';
import intlDateTimeFormat from 'vault-client/utils/intl-date-time-format';
import { inject as service } from '@ember/service';
import { TableColumn, CellComponents } from 'vault-client/types/vault-table';
import { ModelFrom } from 'vault-client/utils/type-utils';
import OrganizationLgmInsuranceEndorsementRoute from 'vault-client/routes/organizations/organization/lgm-insurance-endorsement';
import BusinessLgmInsuranceEndorsementRoute from 'vault-client/routes/businesses/business/lgm-insurance-endorsement';
import ApplicationScope from 'vault-client/services/application-scope';
export default class LgmInsuranceEndorsementsShow extends Controller {
	declare model: ModelFrom<OrganizationLgmInsuranceEndorsementRoute> | ModelFrom<BusinessLgmInsuranceEndorsementRoute>;
	@service permissions: any;
	@service declare applicationScope: ApplicationScope;

	lgmInsuranceEndorsementsRoute: string = '';

	get contractSpecifications(): TableColumn[] {
		return [
			{
				id: '9a1ce19a-e7ff-46ed-a577-5b6ce1afc1d1',
				name: '',
				valuePath: 'rowHeader',
				minWidth: 260,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isVisible: true,
				isFixed: '',
			},
			{
				id: '091af6ab-5238-4fee-9a65-879e511e5a18',
				name: '',
				valuePath: 'value',
				minWidth: 160,
				textAlign: 'right',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
		];
	}

	get endorsement() {
		return this.model.getLgmInsuranceEndorsement?.data?.LgmInsuranceEndorsement;
	}

	get contractSpecificationsData() {
		const endorsement = this.endorsement;

		return [
			{
				rowHeader: 'Sales Effective Date',
				value: intlDateTimeFormat({
					value: endorsement?.salesEffectiveDate,
					day: 'numeric',
					month: 'long',
					year: 'numeric',
					nullSkipFormat: true,
				}),
			},
			{
				rowHeader: 'End Date',
				value: intlDateTimeFormat({
					value: endorsement?.endDate,
					day: 'numeric',
					month: 'long',
					year: 'numeric',
					nullSkipFormat: true,
				}),
			},
			{
				rowHeader: 'Insured Value',
				value: intlNumberFormat({
					value: endorsement?.liability,
					maximumFractionDigits: '2',
					minimumFractionDigits: '2',
					style: 'currency',
					currency: 'USD',
					nullSkipFormat: true,
				}),
			},
			{
				rowHeader: 'Producer Premium',
				value: intlNumberFormat({
					value: endorsement?.producerPremiumAmount,
					maximumFractionDigits: '2',
					minimumFractionDigits: '2',
					style: 'currency',
					currency: 'USD',
					nullSkipFormat: true,
				}),
			},
			{
				rowHeader: 'Subsidy',
				value: intlNumberFormat({
					value: endorsement?.subsidyAmount,
					maximumFractionDigits: '2',
					minimumFractionDigits: '2',
					style: 'currency',
					currency: 'USD',
					nullSkipFormat: true,
				}),
			},
			{
				rowHeader: 'Total Gross Margin',
				value: intlNumberFormat({
					value: endorsement?.totalExpectedGrossMargin,
					maximumFractionDigits: '2',
					minimumFractionDigits: '2',
					style: 'currency',
					currency: 'USD',
					nullSkipFormat: true,
				}),
			},
			{
				rowHeader: 'Total Premium',
				value: intlNumberFormat({
					value: endorsement?.totalPremiumAmount,
					maximumFractionDigits: '2',
					minimumFractionDigits: '2',
					style: 'currency',
					currency: 'USD',
					nullSkipFormat: true,
				}),
			},
		];
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	interface Registry {
		'lgm-insurance-endorsements/show': LgmInsuranceEndorsementsShow;
	}
}
