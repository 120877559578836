import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { gql, useMutation } from 'glimmer-apollo';
import { ModelFrom } from 'vault-client/utils/type-utils';
import route from 'vault-client/routes/vgs/settings/sellers';
import { Mutation, Mutation_createSellerArgs } from 'vault-client/types/graphql-types';

const CREATE_SELLER = gql`
	mutation CreateSeller($data: SellerCreateDTO!) {
		createSeller(data: $data) {
			id
		}
	}
`;

interface AddSellerButtonArgs {
	model: ModelFrom<route>;
}

export default class AddSellerButton extends Component<AddSellerButtonArgs> {
	@tracked showModal = false;
	@tracked name: string | null = null;
	@tracked errorMessage: string | null = null;

	createSeller = useMutation<{ createSeller: Mutation['createSeller'] }, Mutation_createSellerArgs>(this, () => [
		CREATE_SELLER,
		{
			onComplete: (): void => {
				this.errorMessage = null;
				this.args.model.getSellers.refetch();
				this.closeModal();
			},
			onError: (error): void => {
				this.errorMessage = 'There was an error. Seller not added.';
				console.error('Error while attempting to add Seller', error.message);
			},
		},
	]);

	get submitError() {
		return !!this.errorMessage;
	}

	@action
	openModal() {
		this.showModal = true;
	}

	@action
	closeModal() {
		this.showModal = false;
		this.name = null;
		this.errorMessage = null;
	}

	@action
	async submit() {
		if (!this.name) {
			this.errorMessage = 'Please enter a name.';
			return;
		}

		const data = {
			name: this.name,
		};
		await this.createSeller.mutate({
			data,
		});
	}
}
