import { setOwner } from '@ember/application';
import { service } from '@ember/service';
import ScenarioPricesService from 'vault-client/services/scenario-prices';
import { Future } from 'vault-client/types/graphql-types';
import { roundTo } from 'vault-client/utils/round-to';

export default class ScenarioDerivedDrpEndorsement {
	quarterStartDate: string;
	quarterEndDate: string;
	effectiveHedgeDate: string;
	expectedRevenueGuarantee: number;
	classPriceWeightingFactor: number | null;
	componentPriceWeightingFactor: number | null;
	declaredCoveredMilkProduction: number;
	effectiveCoveredMilkProduction: number;
	yieldAdjustmentFactor: number;
	declaredButterfatTest: number | null;
	declaredProteinTest: number | null;
	producerPremiumAmount: number;
	totalPremiumAmount: number;
	isLong: boolean;
	protectionFactor: number | null;

	expectedClassIIIPrice: number | null = null;
	expectedClassIVPrice: number | null = null;

	classIIIFutures: Future[] = [];
	classIVFutures: Future[] = [];
	butterFutures: Future[] = [];
	cheeseFutures: Future[] = [];
	dryWheyFutures: Future[] = [];
	nonfatDryMilkFutures: Future[] = [];

	@service declare scenarioPrices: ScenarioPricesService;

	constructor(
		owner: any,
		quarterStartDate: string,
		quarterEndDate: string,
		effectiveHedgeDate: string,
		classIIIFutures: Future[],
		classIVFutures: Future[],
		butterFutures: Future[],
		cheeseFutures: Future[],
		dryWheyFutures: Future[],
		nonfatDryMilkFutures: Future[],
		expectedRevenueGuarantee: number,
		classPriceWeightingFactor: number | null,
		componentPriceWeightingFactor: number | null,
		declaredCoveredMilkProduction: number,
		effectiveCoveredMilkProduction: number,
		yieldAdjustmentFactor: number,
		declaredButterfatTest: number | null,
		declaredProteinTest: number | null,
		producerPremiumAmount: number,
		totalPremiumAmount: number,
		protectionFactor: number | null,
		isLong: boolean
	) {
		setOwner(this, owner);
		this.quarterStartDate = quarterStartDate;
		this.quarterEndDate = quarterEndDate;
		this.effectiveHedgeDate = effectiveHedgeDate;
		this.classIIIFutures = classIIIFutures;
		this.classIVFutures = classIVFutures;
		this.butterFutures = butterFutures;
		this.cheeseFutures = cheeseFutures;
		this.dryWheyFutures = dryWheyFutures;
		this.nonfatDryMilkFutures = nonfatDryMilkFutures;
		this.expectedRevenueGuarantee = expectedRevenueGuarantee;
		this.effectiveCoveredMilkProduction = effectiveCoveredMilkProduction;
		this.classPriceWeightingFactor = classPriceWeightingFactor;
		this.componentPriceWeightingFactor = componentPriceWeightingFactor;
		this.declaredCoveredMilkProduction = declaredCoveredMilkProduction;
		this.yieldAdjustmentFactor = yieldAdjustmentFactor;
		this.declaredButterfatTest = declaredButterfatTest;
		this.declaredProteinTest = declaredProteinTest;
		this.producerPremiumAmount = producerPremiumAmount;
		this.totalPremiumAmount = totalPremiumAmount;
		this.protectionFactor = protectionFactor;
		this.isLong = isLong;
	}

	get marketClassIIIPrice() {
		return this.getAverageFuturesMarketPrice(this.classIIIFutures);
	}

	get scenarioClassIIIPrice() {
		return this.getAverageFuturesScenarioPrice(this.classIIIFutures);
	}

	get marketClassIVPrice() {
		return this.getAverageFuturesMarketPrice(this.classIVFutures);
	}

	get scenarioClassIVPrice() {
		return this.getAverageFuturesScenarioPrice(this.classIVFutures);
	}

	get marketButterPrice() {
		return this.getAverageFuturesMarketPrice(this.butterFutures);
	}
	get scenarioButterPrice() {
		return this.getAverageFuturesScenarioPrice(this.butterFutures);
	}

	get marketCheesePrice() {
		return this.getAverageFuturesMarketPrice(this.cheeseFutures);
	}

	get scenarioCheesePrice() {
		return this.getAverageFuturesScenarioPrice(this.cheeseFutures);
	}

	get marketDryWheyPrice() {
		return this.getAverageFuturesMarketPrice(this.dryWheyFutures);
	}

	get scenarioDryWheyPrice() {
		return this.getAverageFuturesScenarioPrice(this.dryWheyFutures);
	}

	get marketNonfatDryMilkPrice() {
		return this.getAverageFuturesMarketPrice(this.nonfatDryMilkFutures);
	}

	get scenarioNonfatDryMilkPrice() {
		return this.getAverageFuturesScenarioPrice(this.nonfatDryMilkFutures);
	}

	get marketButterfatPrice() {
		if (this.marketButterPrice === null) return null;
		return roundTo((this.marketButterPrice - 0.1715) * 1.222, 4);
	}

	get scenarioButterfatPrice() {
		if (this.scenarioButterPrice === null) return null;
		return roundTo((this.scenarioButterPrice - 0.1715) * 1.222, 4);
	}

	get marketProteinPrice() {
		if (this.marketCheesePrice == null || this.marketButterfatPrice == null) return null;
		return roundTo(
			(this.marketCheesePrice - 0.2003) * 1.383 + ((this.marketCheesePrice - 0.2003) * 1.572 - this.marketButterfatPrice * 0.9) * 1.17,
			4
		);
	}

	get scenarioProteinPrice() {
		if (this.scenarioCheesePrice == null || this.scenarioButterfatPrice == null) return null;
		return roundTo(
			(this.scenarioCheesePrice - 0.2003) * 1.383 +
				((this.scenarioCheesePrice - 0.2003) * 1.572 - this.scenarioButterfatPrice * 0.9) * 1.17,
			4
		);
	}

	get marketNonfatSolidsPrice() {
		if (this.marketNonfatDryMilkPrice == null) return null;
		return roundTo((this.marketNonfatDryMilkPrice - 0.1678) * 0.99, 4);
	}

	get scenarioNonfatSolidsPrice() {
		if (this.scenarioNonfatDryMilkPrice == null) return null;
		return roundTo((this.scenarioNonfatDryMilkPrice - 0.1678) * 0.99, 4);
	}

	get marketOtherSolidsPrice() {
		if (this.marketDryWheyPrice == null) return null;
		return roundTo((this.marketDryWheyPrice - 0.1991) * 1.03, 4);
	}

	get scenarioOtherSolidsPrice() {
		if (this.scenarioDryWheyPrice == null) return null;
		return roundTo((this.scenarioDryWheyPrice - 0.1991) * 1.03, 4);
	}

	get marketNonfatSolidsPricePortion() {
		if (this.marketNonfatSolidsPrice == null || this.declaredProteinTest == null) return null;
		return roundTo(this.marketNonfatSolidsPrice * (this.declaredProteinTest * 100 + 5.7), 4);
	}

	get scenarioNonfatSolidsPricePortion() {
		if (this.scenarioNonfatSolidsPrice == null || this.declaredProteinTest == null) return null;
		return roundTo(this.scenarioNonfatSolidsPrice * (this.declaredProteinTest * 100 + 5.7), 4);
	}

	get marketOtherSolidsPricePortion() {
		if (this.marketOtherSolidsPrice == null) return null;
		return roundTo(this.marketOtherSolidsPrice * 5.7, 4);
	}

	get scenarioOtherSolidsPricePortion() {
		if (this.scenarioOtherSolidsPrice == null) return null;
		return roundTo(this.scenarioOtherSolidsPrice * 5.7, 4);
	}

	get marketButterfatPricePortion() {
		if (this.marketButterfatPrice == null || this.declaredButterfatTest == null) return null;
		return roundTo(this.marketButterfatPrice * this.declaredButterfatTest * 100, 4);
	}
	get scenarioButterfatPricePortion() {
		if (this.scenarioButterfatPrice == null || this.declaredButterfatTest == null) return null;
		return roundTo(this.scenarioButterfatPrice * this.declaredButterfatTest * 100, 4);
	}

	get marketProteinPricePortion() {
		if (this.marketProteinPrice == null || this.declaredProteinTest == null) return null;
		return roundTo(this.marketProteinPrice * this.declaredProteinTest * 100, 4);
	}

	get scenarioProteinPricePortion() {
		if (this.scenarioProteinPrice == null || this.declaredProteinTest == null) return null;
		return roundTo(this.scenarioProteinPrice * this.declaredProteinTest * 100, 4);
	}

	get marketClassPricePortion() {
		if (this.marketClassIIIPrice == null || this.marketClassIVPrice == null) return null;

		return roundTo(
			this.marketClassIIIPrice * (this.classPriceWeightingFactor ?? 0) +
				this.marketClassIVPrice * (1 - (this.classPriceWeightingFactor ?? 0)),
			4
		);
	}

	get scenarioClassPricePortion() {
		if (this.scenarioClassIIIPrice == null || this.scenarioClassIVPrice == null) return null;

		return roundTo(
			this.scenarioClassIIIPrice * (this.classPriceWeightingFactor ?? 0) +
				this.scenarioClassIVPrice * (1 - (this.classPriceWeightingFactor ?? 0)),
			4
		);
	}

	get marketComponentClassIVPricePortion() {
		if (this.marketButterfatPricePortion == null || this.marketNonfatSolidsPricePortion == null) return null;

		return roundTo(
			this.marketButterfatPricePortion + this.marketNonfatSolidsPricePortion * (1 - (this.componentPriceWeightingFactor ?? 1)),
			4
		);
	}

	get scenarioComponentClassIVPricePortion() {
		if (this.scenarioButterfatPricePortion == null || this.scenarioNonfatSolidsPricePortion == null) return null;

		return roundTo(
			this.scenarioButterfatPricePortion + this.scenarioNonfatSolidsPricePortion * (1 - (this.componentPriceWeightingFactor ?? 1)),
			4
		);
	}

	get marketComponentClassIIIPricePortion() {
		if (this.marketButterfatPricePortion == null || this.marketProteinPricePortion == null || this.marketOtherSolidsPricePortion == null)
			return null;

		return roundTo(
			this.marketButterfatPricePortion +
				this.marketProteinPricePortion +
				this.marketOtherSolidsPricePortion * (this.componentPriceWeightingFactor ?? 1),
			4
		);
	}

	get scenarioComponentClassIIIPricePortion() {
		if (
			this.scenarioButterfatPricePortion == null ||
			this.scenarioProteinPricePortion == null ||
			this.scenarioOtherSolidsPricePortion == null
		)
			return null;

		return roundTo(
			this.scenarioButterfatPricePortion +
				this.scenarioProteinPricePortion +
				this.scenarioOtherSolidsPricePortion * (this.componentPriceWeightingFactor ?? 1),
			4
		);
	}

	get marketActualMilkRevenue() {
		if (this.classPriceWeightingFactor !== null) {
			if (this.marketClassPricePortion == null) return null;
			return Math.round(roundTo(this.marketClassPricePortion * this.declaredCoveredMilkProduction * this.yieldAdjustmentFactor, 4) / 100);
		} else {
			if (this.marketComponentClassIIIPricePortion == null || this.marketComponentClassIVPricePortion == null) return null;
			return Math.round(
				((this.marketComponentClassIIIPricePortion + this.marketComponentClassIVPricePortion) *
					this.declaredCoveredMilkProduction *
					this.yieldAdjustmentFactor) /
					100
			);
		}
	}

	get scenarioActualMilkRevenue() {
		if (this.classPriceWeightingFactor !== null) {
			if (this.scenarioClassPricePortion == null) return null;
			return Math.round(roundTo(this.scenarioClassPricePortion * this.declaredCoveredMilkProduction * this.yieldAdjustmentFactor, 4) / 100);
		} else {
			if (this.scenarioComponentClassIIIPricePortion == null || this.scenarioComponentClassIVPricePortion == null) return null;
			return Math.round(
				((this.scenarioComponentClassIIIPricePortion + this.scenarioComponentClassIVPricePortion) *
					this.declaredCoveredMilkProduction *
					this.yieldAdjustmentFactor) /
					100
			);
		}
	}

	get marketIndemnity() {
		if (this.expectedRevenueGuarantee >= (this.marketActualMilkRevenue ?? 0)) {
			return Math.round((this.expectedRevenueGuarantee - (this.marketActualMilkRevenue ?? 0)) * (this.protectionFactor ?? 0));
		} else {
			return 0;
		}
	}

	get scenarioIndemnity() {
		if (this.expectedRevenueGuarantee >= (this.scenarioActualMilkRevenue ?? 0)) {
			return Math.round((this.expectedRevenueGuarantee - (this.scenarioActualMilkRevenue ?? 0)) * (this.protectionFactor ?? 0));
		} else {
			return 0;
		}
	}

	get marketEstimatedPnl() {
		if (this.isLong) {
			return this.marketIndemnity - this.producerPremiumAmount;
		} else {
			return this.totalPremiumAmount - this.marketIndemnity;
		}
	}

	get scenarioEstimatedPnl() {
		if (this.isLong) {
			return this.scenarioIndemnity - this.producerPremiumAmount;
		} else {
			return this.totalPremiumAmount - this.scenarioIndemnity;
		}
	}

	getAverageFuturesMarketPrice(futures: Future[]) {
		const prices: number[] = [];

		futures.forEach((future) => {
			const symbol = future.barchartSymbol;
			const displayFactor = future?.SymbolGroup.displayFactor ?? 1;
			if (!symbol) return;

			const price = this.scenarioPrices.getMarketPrice(symbol);
			if (price) {
				prices.push(price * displayFactor);
			}
		});

		return prices.length > 0 ? prices.reduce((a, b) => a + b, 0) / prices.length : null;
	}

	getAverageFuturesScenarioPrice(futures: Future[]) {
		const prices: number[] = [];

		futures.forEach((future) => {
			const symbol = future.barchartSymbol;
			const displayFactor = future?.SymbolGroup.displayFactor ?? 1;
			if (!symbol) return;

			const price = this.scenarioPrices.getScenarioPrice(symbol);
			if (price) {
				prices.push(price * displayFactor);
			}
		});

		return prices.length > 0 ? prices.reduce((a, b) => a + b, 0) / prices.length : null;
	}

	getFutureMarketPrice(future: Future | null) {
		const symbol = future?.barchartSymbol;
		const displayFactor = future?.SymbolGroup.displayFactor ?? 1;

		if (!symbol) return 0;

		const price = this.scenarioPrices.getMarketPrice(symbol);

		return price ? price * displayFactor : null;
	}

	getFutureScenarioPrice(future: Future | null) {
		const symbol = future?.barchartSymbol;
		const displayFactor = future?.SymbolGroup.displayFactor ?? 1;

		if (!symbol) return 0;

		const price = this.scenarioPrices.getScenarioPrice(symbol);

		return price ? price * displayFactor : null;
	}
}
