/* eslint-disable ember/no-controller-access-in-routes */
import Route from '@ember/routing/route';
import query from 'vault-client/graphql/queries/insurance-overview/show.graphql';
import { queryManager } from 'ember-apollo-client';
import { DateTime } from 'luxon';
export default class InsuranceQuarterRoute extends Route {
	@queryManager apollo: any;

	setupController(controller: any, model: any, transition: any) {
		super.setupController(controller, model, transition);

		const scopeParams = this.paramsFor('application');

		this.controller.setProperties({
			//@ts-ignore
			organizationId: scopeParams.organizationId,
			//@ts-ignore
			globalCustomerId: scopeParams.globalCustomerId,
			//@ts-ignore
			locationId: scopeParams.locationId,
			quarterStartDate: transition.to.params.quarter_start_date,
		});
	}

	// Required due to Ember.js bug: https://github.com/emberjs/ember.js/issues/19582
	//@ts-ignore
	activate(transition: any) {
		transition.queryParamsOnly = false;
	}

	async model(params: any) {
		const scopeParams = this.paramsFor('application');

		const quarterStartDate = params.quarter_start_date;
		const quarterEndDate = DateTime.fromISO(params.quarter_start_date).endOf('quarter');

		let productionWhere = {};
		let endorsementWhere = {};
		//@ts-ignore
		if (scopeParams.locationId) {
			//@ts-ignore
			productionWhere = { locationId: { equals: scopeParams.locationId } };
			endorsementWhere = {
				InsurancePolicy: {
					//@ts-ignore
					customerId: { equals: scopeParams.globalCustomerId },
				},
			};
			//@ts-ignore
		} else if (scopeParams.globalCustomerId) {
			productionWhere = {
				//@ts-ignore
				Location: { customerId: { equals: scopeParams.globalCustomerId } },
			};
			endorsementWhere = {
				InsurancePolicy: {
					//@ts-ignore
					customerId: { equals: scopeParams.globalCustomerId },
				},
			};
			//@ts-ignore
		} else if (scopeParams.organizationId) {
			productionWhere = {
				Location: {
					//@ts-ignore
					Customer: { organizationId: { equals: scopeParams.organizationId } },
				},
			};
			endorsementWhere = {
				InsurancePolicy: {
					OR: [
						//@ts-ignore
						{ ownerId: { equals: scopeParams.organizationId } },
						{
							Customer: {
								//@ts-ignore
								organizationId: { equals: scopeParams.organizationId },
							},
						},
					],
				},
			};
		}

		//@ts-ignore
		endorsementWhere['quarterStartDate'] = { equals: quarterStartDate };
		//@ts-ignore
		productionWhere['AND'] = [{ date: { gte: quarterStartDate } }, { date: { lte: quarterEndDate } }];

		const variables = { productionWhere, endorsementWhere };

		return this.apollo.watchQuery({ query, variables });
	}
}
