import ExposureShowController from 'vault-client/controllers/exposure/show';

export default class BusinessesBusinessExposureController extends ExposureShowController {
	exposuresRoutePath = 'businesses.business.exposures';
	exposureHedgeMonthDetailRoutePath = 'businesses.business.exposure-hedge-month-detail';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'businesses/business/exposure': BusinessesBusinessExposureController;
	}
}
