export default function sendBrowserNotification(message: string) {
	if ('Notification' in window) {
		if (Notification.permission === 'granted') {
			new Notification(message);
		} else if (Notification.permission !== 'denied') {
			Notification.requestPermission().then((permission: NotificationPermission) => {
				if (permission === 'granted') {
					new Notification(message);
				}
			});
		}
	} else {
		console.warn('This browser does not support notifications.');
	}
}
