import { ColumnListMap, TableColumn } from 'vault-client/types/vault-table';
import { set } from '@ember/object';
import { SortableEvent } from 'sortablejs';

function getColumnList(columnList: ColumnListMap, listName: string): TableColumn[] | null | undefined {
	return columnList.has(listName) ? columnList.get(listName) : null;
}

function getSubcolumnList(columnList: ColumnListMap, listEl: HTMLElement): TableColumn[] | null {
	// find subcolumns parent list
	const parentListName = listEl.dataset.parentList || '';
	// find subcolumns parent item and set oldList to subcolumns list
	const parentItemId = listEl.dataset.parentItem;
	return columnList.get(parentListName)?.find((col: TableColumn) => col.id == parentItemId)?.subcolumns || null;
}

export default function updateColumnArray(columnListHash: ColumnListMap, sortableEvent: SortableEvent): TableColumn[] {
	const columnList = new Map(columnListHash);
	const { oldIndex, newIndex, from, to, item } = sortableEvent;
	const oldListName = from.id;
	const newListName = to.id;
	let oldList: TableColumn[] | null = [];
	let newList: TableColumn[] | null = [];

	// get oldList (from) and get newList (to)
	oldList = getColumnList(columnList, oldListName) || getSubcolumnList(columnList, from);
	newList = getColumnList(columnList, newListName) || getSubcolumnList(columnList, to);

	if (oldList && newList) {
		const element = oldIndex != undefined ? oldList[oldIndex] : null;

		if (element) {
			// set isFixed property
			if (newListName == 'frozenLeftColumns') {
				set(element, 'isFixed', 'left');
			} else if (newListName == 'frozenRightColumns') {
				set(element, 'isFixed', 'right');
			} else {
				set(element, 'isFixed', '');
			}

			// set isVisible property
			// element.isVisible = newListName == 'hiddenColumns' ? false : true;

			// if element has subcolumn list - set subcolumn list ul element new data-parent-list
			if (element.subcolumns && element.subcolumns.length > 0) {
				const subColumnListEl = document.getElementById(`${item.id}-subcolumns`);
				if (subColumnListEl) {
					subColumnListEl.dataset.parentList = newListName;
				}
			}

			// set ordering
			if (oldIndex != undefined && newIndex != undefined) {
				oldList.splice(oldIndex, 1);
				newList.splice(newIndex, 0, element);
			}
		}
	}

	return [...columnList.values()].flat() as TableColumn[];
}
