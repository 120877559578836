import SwineController from 'vault-client/controllers/swine';
import BusinessesBusinessRoute from 'vault-client/routes/businesses/business';
import { ModelFrom } from 'vault-client/utils/type-utils';

export default class BusinessesBusinessSwineController extends SwineController {
	weanedPigsPath = 'businesses.business.weaned-pigs';
	pigMarketingPath = 'businesses.business.pig-marketing';
	pigCohortsPath = 'businesses.business.pig-cohorts';

	declare model: ModelFrom<BusinessesBusinessRoute>;

	get businessName() {
		return this.model.Customer.name;
	}

	get pageTitle() {
		return `Swine | ${this.businessName}`;
	}
}
