import Component from '@glimmer/component';
import { htmlSafe } from '@ember/template';

interface UiDetailsItemComponentArgs {
	colSpacing?: boolean;
}

export default class UiDetailsItemComponent extends Component<UiDetailsItemComponentArgs> {
	_defaultColSpacing = 'wide';

	get colSpacing() {
		return this.args.colSpacing ?? this._defaultColSpacing;
	}

	get colSpacingClass() {
		return htmlSafe(`${this.colSpacing}-col-spacing`);
	}
}
