export default function tonsPerFuturesContract(rootSymbolOrProductSlug: string) {
	/** Short Tons (2000 lbs) per Future's Contract */
	switch (rootSymbolOrProductSlug) {
		case 'ZM':
		case 'grain-soybean-meal':
			return 100;
		case 'ZC':
		case 'grain-corn':
			return 140; // 5,000 bushels ( 56 lbs / bushel);
		default:
			console.warn(`Tons per contract not found for: ${rootSymbolOrProductSlug}`);
			return 0;
	}
}
