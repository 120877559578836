import { action } from '@ember/object';
import { service } from '@ember/service';
import { capitalize } from '@ember/string';
import Component from '@glimmer/component';
import PermissionsService from 'vault-client/services/permissions';
import {
	GrainCropPlan,
	GrainFillOrder,
	GrainFillOrderModificationRequest,
	GrainFillOrderModificationStatus,
} from 'vault-client/types/graphql-types';

interface GrainModificationRequestWrapperArgs {
	modificationRequests: GrainFillOrderModificationRequest[];
	fill?: GrainFillOrder;
	userName?: string;
	isFillPage?: boolean;
}

export default class GrainModificationRequestWrapper extends Component<GrainModificationRequestWrapperArgs> {
	@service declare permissions: PermissionsService;

	get omrData() {
		return (
			this.args.modificationRequests
				?.map((mod) => ({
					...mod,
					canceledVolume:
						mod.GrainFillOrder.bushels && mod.data.bushels ? (+mod.GrainFillOrder.bushels || 0) - (+mod.data.bushels || 0) : 0,
				}))
				.sort((a, b) =>
					a.status === 'PendingCancellation' && b.status !== 'PendingCancellation'
						? -1
						: b.status === 'PendingCancellation' && a.status !== 'PendingCancellation'
						? 1
						: new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
				) ?? []
		);
	}

	get pendingBushelAmount() {
		const pendingBushelSum = this.args.fill?.GrainFillOrderModificationRequests?.reduce((acc, request) => {
			if (
				request.status === GrainFillOrderModificationStatus.Pending ||
				request.status === GrainFillOrderModificationStatus.New ||
				request.status === GrainFillOrderModificationStatus.Working
			) {
				if (request.type == 'Cancel') {
					acc += request.data.bushels && this.args.fill?.bushels ? this.args.fill.bushels - request.data.bushels : 0;
				} else {
					acc += request.data.bushels ? request.data.bushels : 0;
				}
			}
			return acc;
		}, 0);

		return pendingBushelSum ?? 0;
	}

	get fillBushelAmount() {
		return this.args.fill?.bushels ? this.args.fill.bushels : 0;
	}

	get availableBushelAmount() {
		return this.fillBushelAmount - this.pendingBushelAmount;
	}

	@action
	generateModificationRequestName(mod: GrainFillOrderModificationRequest) {
		const plan = mod.GrainFillOrder.Plan as GrainCropPlan;
		return `Grain Fill for ${plan?.CropYear?.year} ${capitalize(plan.Crop.name)}`;
	}
}
