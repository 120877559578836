/* eslint-disable ember/no-controller-access-in-routes */
import Route from '@ember/routing/route';
import query from 'vault-client/graphql/queries/reports/drp-performance-by-aip.graphql';
import { queryManager } from 'ember-apollo-client';
import { DateTime } from 'luxon';
import { DerivedDrpEndorsementFilterDTO } from 'vault-client/types/graphql-types';

export default class ReportsDrpPerformanceByAIPRoute extends Route {
	@queryManager apollo: any;

	queryParams = {
		agent: {
			refreshModel: true,
		},
		stateId: {
			refreshModel: true,
		},
		reinsuranceYear: {
			refreshModel: true,
		},
		quarterStartDate: {
			refreshModel: true,
		},
		quarterEndDate: {
			refreshModel: true,
		},
	};

	query = query;

	templateName: string = 'reports/drp-performance-by-aip';

	generateWhere(
		organizationId: null | string,
		customerId: undefined | null | string,
		quarterStartDate: string | null | undefined,
		quarterEndDate: string | null | undefined,
		agent: string | null | undefined,
		stateId: string | null | undefined,
		reinsuranceYear: number | null | undefined
	): DerivedDrpEndorsementFilterDTO {
		let where: DerivedDrpEndorsementFilterDTO = {};

		if (customerId) {
			where = {
				InsurancePolicy: {
					customerId: { equals: customerId },
				},
			};
		} else if (organizationId) {
			where = {
				InsurancePolicy: {
					OR: [
						{ ownerId: { equals: organizationId } },
						{
							Customer: {
								organizationId: { equals: organizationId },
							},
						},
					],
				},
			};
		}

		if (quarterStartDate && quarterEndDate) {
			if (!where.AND) {
				where.AND = [];
			}
			where.AND.push({
				quarterEndDate: {
					gte: quarterStartDate,
				},
			});
			where.AND.push({
				quarterEndDate: {
					lte: DateTime.fromISO(quarterEndDate).endOf('quarter').toISODate(),
				},
			});
		} else if (quarterStartDate) {
			where.quarterEndDate = { gte: quarterStartDate };
		} else if (quarterEndDate) {
			where.quarterEndDate = { lte: quarterEndDate };
		}

		if (agent) {
			const nameArr = agent.split(' ');

			if (!where.AND) {
				where.AND = [];
			}
			where.AND.push({
				InsurancePolicy: { agentFirstName: { equals: nameArr[0] } },
			});
			where.AND.push({
				InsurancePolicy: { agentLastName: { equals: nameArr[1] } },
			});
		}

		if (stateId) {
			if (where.InsurancePolicy) {
				where.InsurancePolicy.stateId = { equals: stateId };
			} else {
				where.InsurancePolicy = {
					stateId: {
						equals: stateId,
					},
				};
			}
		}

		if (reinsuranceYear) {
			where.reinsuranceYear = { equals: Number(reinsuranceYear) };
		}

		return where;
	}

	// Required due to Ember.js bug: https://github.com/emberjs/ember.js/issues/19582
	//@ts-ignore
	activate(transition: any) {
		transition.queryParamsOnly = false;
	}
}
