import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import RouterService from '@ember/routing/router-service';
export default class MarketsProductIndexRoute extends Route {
	@service declare router: RouterService;

	async beforeModel() {
		this.router.transitionTo('markets.product.overview');
	}
}
