import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='px-4' id={{this.legendId}}></div>\n<div class='relative' ...attributes>\n\t<Chart\n\t\t@id={{this.chartId}}\n\t\t@type='bar'\n\t\t@chartData={{this.chartData}}\n\t\t@data={{this.data}}\n\t\t@options={{this.chartOptions}}\n\t\t@plugins={{this.plugins}}\n\t\t@updateChart={{this.updateChart}}\n\t/>\n</div>", {"contents":"<div class='px-4' id={{this.legendId}}></div>\n<div class='relative' ...attributes>\n\t<Chart\n\t\t@id={{this.chartId}}\n\t\t@type='bar'\n\t\t@chartData={{this.chartData}}\n\t\t@data={{this.data}}\n\t\t@options={{this.chartOptions}}\n\t\t@plugins={{this.plugins}}\n\t\t@updateChart={{this.updateChart}}\n\t/>\n</div>","moduleName":"vault-client/components/percent-hedged-with-prices-chart.hbs","parseOptions":{"srcName":"vault-client/components/percent-hedged-with-prices-chart.hbs"}});
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import Component from '@glimmer/component';
import { Chart, ChartConfiguration, ChartData, ChartOptions } from 'chart.js';
import { DateTime } from 'luxon';
import { HedgeMonth } from 'vault-client/controllers/feed/overview';
import arraysEqual from 'vault-client/utils/arrays-equal';
import { getCustomLegend, getCustomTooltip, CustomTooltipOptions } from 'vault-client/utils/chart-utils';
import getCSSVariable from 'vault-client/utils/get-css-variable';

interface PercentHedgedWithPricesChartArgs {
	hedgeMonths: HedgeMonth[];
	legendId: string;
	chartId: string;
}

export default class PercentHedgedWithPricesChart extends Component<PercentHedgedWithPricesChartArgs> {
	get guid(): string {
		return guidFor(this);
	}

	get chartId(): string {
		return this.args.chartId ?? `percent-hedged-with-prices-chart-${this.guid}`;
	}

	get legendId(): string {
		return this.args.legendId ?? `percent-hedged-with-prices-chart-legend-${this.guid}`;
	}

	get hedgeMonths(): HedgeMonth[] {
		return this.args.hedgeMonths.sortBy('date');
	}

	get labels(): string[] {
		return this.hedgeMonths.map((month) => month.date).sort();
	}

	get fractionDigits(): number {
		return this.args.hedgeMonths.find((month) => month)?.fractionDigits ?? 0;
	}

	get displayFactor(): number {
		return this.args.hedgeMonths.find((month) => month)?.displayFactor ?? 1;
	}

	get data(): {
		futuresHedged: number[];
		callsHedged: number[];
		physicalPurchased: number[];
		marketPrice: (number | null)[];
		netHedgePrice: (number | null)[];
	} {
		return this.hedgeMonths.reduce<{
			futuresHedged: number[];
			callsHedged: number[];
			physicalPurchased: number[];
			marketPrice: (number | null)[];
			netHedgePrice: (number | null)[];
		}>(
			(acc, month) => {
				acc.futuresHedged.push(month.percentFuturesHedged);
				acc.callsHedged.push(month.percentCallsHedged);
				acc.physicalPurchased.push(month.percentPurchased);
				acc.marketPrice.push(month.marketPrice ? month.marketPrice : null);
				acc.netHedgePrice.push(month.hedgePrice ? month.hedgePrice : null);
				return acc;
			},
			{
				futuresHedged: [],
				callsHedged: [],
				physicalPurchased: [],
				marketPrice: [],
				netHedgePrice: [],
			}
		);
	}

	get chartData(): ChartData {
		const labels = this.labels;
		const data = this.data;

		const datasets: ChartData['datasets'] = [
			{
				type: 'bar',
				label: 'Futures Hedged',
				data: data.futuresHedged,
				backgroundColor: getCSSVariable('--brand-interactive-blue-70'),
				yAxisID: 'percentHedged',
				order: 2,
			},
			{
				type: 'bar',
				label: 'Calls Hedged',
				data: data.callsHedged,
				backgroundColor: getCSSVariable('--brand-orange-40'),
				yAxisID: 'percentHedged',
				order: 2,
			},
			{
				type: 'bar',
				label: 'Purchased',
				data: data.physicalPurchased,
				backgroundColor: getCSSVariable('--brand-lime-40'),
				yAxisID: 'percentHedged',
				order: 2,
			},
			{
				type: 'line',
				label: 'Market Price',
				data: data.marketPrice,
				backgroundColor: getCSSVariable('--brand-lemon-40'),
				borderColor: getCSSVariable('--brand-lemon-40'),
				pointRadius: 0,
				tension: 0.3,
				yAxisID: 'prices',
				order: 1,
			},
			{
				type: 'line',
				label: 'Net Hedge Price',
				data: data.netHedgePrice,
				backgroundColor: getCSSVariable('--brand-teal-60'),
				borderColor: getCSSVariable('--brand-teal-60'),
				pointRadius: 0,
				tension: 0.3,
				yAxisID: 'prices',
				order: 1,
			},
		];

		return {
			labels,
			datasets,
		};
	}

	get chartOptions(): ChartOptions<'bar'> {
		const customTooltipOptions: CustomTooltipOptions = {
			valueFormatter: (val: number | string, dataPoint) => {
				if (val == null) return '-';
				if (typeof val === 'string') return val;
				if (dataPoint?.dataset.label === 'Market Price' || dataPoint?.dataset.label === 'Net Hedge Price') {
					const displayFactor = this.displayFactor;
					return Intl.NumberFormat('en-US', {
						style: 'currency',
						currency: 'USD',
						minimumFractionDigits: this.fractionDigits,
						maximumFractionDigits: this.fractionDigits,
					}).format(val * displayFactor);
				} else {
					return Intl.NumberFormat('en-US', {
						style: 'percent',
						minimumFractionDigits: 0,
						maximumFractionDigits: 2,
					}).format(val);
				}
			},
			titleFormatter: (title: string) => {
				const date = DateTime.fromISO(title);
				return date.toFormat('LLL yyyy');
			},
		};

		return {
			responsive: true,
			maintainAspectRatio: false,
			interaction: {
				mode: 'index',
				intersect: false,
			},
			layout: {
				padding: {
					left: 16,
					right: 16,
					bottom: 16,
				},
			},
			plugins: {
				datalabels: {
					color: 'white',
					display: function (context) {
						const chartsToShow = ['Futures Hedged', 'Calls Hedged', 'Purchased'];
						const value = context?.dataset?.data[context.dataIndex]?.toString() ?? '0';
						return chartsToShow.includes(context?.dataset?.label ?? '') && parseFloat(value) > 0.05;
					},
					formatter: function (value: number) {
						return Math.round(value * 100) + '%';
					},
				},
				tooltip: {
					mode: 'index',
					external: getCustomTooltip(customTooltipOptions),
					enabled: false,
					displayColors: false,
				},
				legend: {
					display: false,
				},
			},
			scales: {
				x: {
					stacked: true,
					ticks: {
						callback: function (val: number) {
							const date = this.getLabelForValue(val);
							return DateTime.fromISO(date).toFormat('LLL yyyy');
						},
					},
					grid: {
						display: false,
					},
				},
				percentHedged: {
					axis: 'y',
					stacked: true,
					display: true,
					suggestedMin: 0,
					suggestedMax: 1,
					ticks: {
						callback: function (val: number) {
							return Intl.NumberFormat('en-US', {
								style: 'percent',
								minimumFractionDigits: 0,
								maximumFractionDigits: 0,
							}).format(val);
						},
					},
				},
				prices: {
					type: 'linear',
					display: true,
					position: 'right',
					axis: 'y',
					grid: {
						display: false,
					},
					suggestedMin: this.priceMin,
					suggestedMax: this.priceMax,
					beginAtZero: false,
					ticks: {
						callback: (val: number) => {
							const priceWithDisplayFactor = (val ?? 0) * this.displayFactor;
							return Intl.NumberFormat('en-US', {
								style: 'currency',
								currency: 'USD',
								minimumFractionDigits: this.fractionDigits,
								maximumFractionDigits: this.fractionDigits,
							}).format(priceWithDisplayFactor);
						},
					},
				},
			},
		};
	}

	get priceMin(): number | undefined {
		const low = Math.min(
			...this.args.hedgeMonths.reduce<number[]>((acc, month) => {
				const low = month.fiftyTwoWeekLow ?? null;
				if (low) {
					acc.push(low);
				}

				return acc;
			}, [])
		);

		return low != null ? low * 0.8 : undefined; // add 20% buffer lower than lowest value
	}

	get priceMax(): number | undefined {
		const high = Math.max(
			...this.args.hedgeMonths.reduce<number[]>((acc, month) => {
				const high = month.fiftyTwoWeekHigh ?? null;
				if (high != null) {
					acc.push(high);
				}

				return acc;
			}, [])
		);

		return high != null ? high * 1.2 : undefined; // add 20% buffer higher than highest value
	}

	get plugins(): ChartConfiguration['plugins'] {
		const chartId = this.chartId;
		const legendId = this.legendId;
		return [
			{
				id: 'custom-legend',
				afterUpdate: getCustomLegend(chartId, legendId),
			},
		];
	}

	@action
	updateChart(chart: Chart) {
		const newDatasetLabels = this.chartData.datasets.map((set) => set.label).sort();
		const currentDatasetLabels = chart.data.datasets.map((set) => set.label).sort();
		if (arraysEqual(newDatasetLabels, currentDatasetLabels)) {
			chart.data.datasets.forEach((dataset) => {
				dataset.data = this.chartData.datasets.find((set) => set.label === dataset.label)?.data ?? [];
			});
		} else {
			chart.data = this.chartData;
		}

		const newChartLabels = this.labels;
		const currentChartLabels = chart.data.labels ?? [];
		if (!arraysEqual(newChartLabels, currentChartLabels)) {
			chart.data.labels = newChartLabels;
		}

		// @ts-ignore
		const currentSuggestedPriceMin = chart.options!.scales!.prices!.suggestedMin;
		// @ts-ignore
		const currentSuggestedPriceMax = chart.options!.scales!.prices!.suggestedMax;
		const newSuggestedPriceMin = this.priceMin;
		const newSuggestedPriceMax = this.priceMax;

		if (currentSuggestedPriceMin !== newSuggestedPriceMin || currentSuggestedPriceMax !== newSuggestedPriceMax) {
			// @ts-ignore
			chart.options!.scales!.prices!.suggestedMin = newSuggestedPriceMin;
			// @ts-ignore
			chart.options!.scales!.prices!.suggestedMax = newSuggestedPriceMax;
		}

		chart.update('none');
	}
}
