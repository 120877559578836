import ReportsDrpPerformanceByPlanYearController from 'vault-client/controllers/reports/drp-performance-by-plan-year';

export default class OrganizationsOrganizationDrpPerformanceByPlanYear extends ReportsDrpPerformanceByPlanYearController {
	reportsRoute: string = 'organizations.organization.reports';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'organizations/organization/drp-performance-by-plan-year': OrganizationsOrganizationDrpPerformanceByPlanYear;
	}
}
