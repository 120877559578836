import Route from '@ember/routing/route';
import { tracked } from '@glimmer/tracking';
import { gql, useQuery } from 'glimmer-apollo';
import {
	GrainFillOrderFilterDTO,
	Query,
	Query_GrainFillOrdersArgs,
	SortByDirection,
	TypeOfGrainOrderSales,
	TypeOfGrainPlan,
} from 'vault-client/types/graphql-types';
import { SortObj } from 'vault-client/types/vault-table';

export const GET_FILLS = gql`
	query GrainGrainFillOrdersIndex($where: GrainFillOrderFilterDTO, $limit: Float, $orderBy: GrainFillOrderSortByDTO, $offset: Float) {
		GrainFillOrders(where: $where, limit: $limit, orderBy: $orderBy, offset: $offset) {
			id
			bushels
			futuresMonth
			deliveryStartDate
			deliveryEndDate
			deliveryMonth
			salesType
			futurePrice
			fees
			spread
			basis
			planId
			contractNumber
			targetId
			GrainFillOrderActivity(orderBy: { performedAt: Desc }) {
				id
				data
				performedAt
				type
				GrainPlan {
					id
					type
				}
			}
			Buyer {
				id
				name
			}
			Location {
				id
				name
			}
			Plan {
				... on GrainCropPlan {
					id
					Crop {
						id
						name
					}
					CropYear {
						id
						year
					}
					Customer {
						id
						name
						RelationshipOwner {
							id
							firstName
							lastName
						}
					}
				}
			}
		}
		GrainFillOrderCount(where: $where) {
			count
		}
		GrainAdvisors(orderBy: { name: Asc }) {
			id
			name
		}
	}
`;

export type GetFills = {
	__typename?: 'Query';

	GrainFillOrders: Query['GrainFillOrders'];
	GrainFillOrderCount: Query['GrainFillOrderCount'];
	GrainAdvisors: Query['GrainAdvisors'];
};

interface QueryParams {
	page: number;
	size: number;
	sorts: SortObj[];
	selectedGrainAdvisorIds: string[];
	customerId: string;
	cropCategoryId: string;
	buyerId: string;
	grainLocationId: string;
	selectedSalesTypes: TypeOfGrainOrderSales[];
	cropYearStart: number;
	cropYearEnd: number;
	futuresMonthStart: string;
	futuresMonthEnd: string;
	deliveryStartDateStart: string;
	deliveryStartDateEnd: string;
	deliveryEndDateStart: string;
	deliveryEndDateEnd: string;
}

export default class VgsGrainServicesFillsIndex extends Route {
	@tracked variables: Query_GrainFillOrdersArgs = {};

	queryParams = {
		page: { refreshModel: true },
		sorts: { refreshModel: true },
		size: { refreshModel: true },
		selectedGrainAdvisorIds: { refreshModel: true },
		customerId: { refreshModel: true },
		cropCategoryId: { refreshModel: true },
		buyerId: { refreshModel: true },
		grainLocationId: { refreshModel: true },
		selectedSalesTypes: { refreshModel: true },
		cropYearStart: { refreshModel: true },
		cropYearEnd: { refreshModel: true },
		futuresMonthStart: { refreshModel: true },
		futuresMonthEnd: { refreshModel: true },
		deliveryStartDateStart: { refreshModel: true },
		deliveryStartDateEnd: { refreshModel: true },
		deliveryEndDateStart: { refreshModel: true },
		deliveryEndDateEnd: { refreshModel: true },
	};

	getFills = useQuery<GetFills, Query_GrainFillOrdersArgs>(this, () => [GET_FILLS, { variables: this.variables }]);

	generateWhere(params: QueryParams) {
		// Convert date ranges to null if they use the default values.
		const futuresMonthStart = params.futuresMonthStart !== '1900-01-01' ? params.futuresMonthStart : null;
		const futuresMonthEnd = params.futuresMonthEnd !== '2999-12-31' ? params.futuresMonthEnd : null;
		const deliveryStartDateStart = params.deliveryStartDateStart !== '1900-01-01' ? params.deliveryStartDateStart : null;
		const deliveryStartDateEnd = params.deliveryStartDateEnd !== '2999-12-31' ? params.deliveryStartDateEnd : null;
		const deliveryEndDateStart = params.deliveryEndDateStart !== '1900-01-01' ? params.deliveryEndDateStart : null;
		const deliveryEndDateEnd = params.deliveryEndDateEnd !== '2999-12-31' ? params.deliveryEndDateEnd : null;
		const where: GrainFillOrderFilterDTO = {};

		if (params.buyerId) {
			where.Buyer = {
				id: {
					equals: params.buyerId,
				},
			};
		}

		if (params.grainLocationId) {
			where.Location = {
				id: {
					equals: params.grainLocationId,
				},
			};
		}

		if (params.selectedSalesTypes?.length) {
			where.salesType = {
				in: params.selectedSalesTypes,
			};
		}

		where.Plan = {
			type: {
				equals: TypeOfGrainPlan.Crop,
			},
			AsGrainCropPlan: {
				...(params.cropYearStart &&
					params.cropYearEnd && {
						CropYear: {
							year: {
								gte: params.cropYearStart,
								lte: params.cropYearEnd,
							},
						},
					}),
				...(params.cropCategoryId && {
					CropCategory: {
						id: {
							equals: params.cropCategoryId,
						},
					},
				}),

				...((params.selectedGrainAdvisorIds?.length || params.customerId) && {
					Customer: {
						...(params.selectedGrainAdvisorIds?.length && {
							RelationshipOwner: {
								id: {
									in: params.selectedGrainAdvisorIds,
								},
							},
						}),
						...(params.customerId && {
							id: {
								equals: params.customerId,
							},
						}),
					},
				}),
			},
		};

		if (futuresMonthStart && futuresMonthEnd) {
			where.futuresMonth = {
				...(futuresMonthStart && { gte: futuresMonthStart }),
				...(futuresMonthEnd && { lte: futuresMonthEnd }),
			};
		}

		if (deliveryStartDateStart && deliveryStartDateEnd) {
			where.deliveryStartDate = {
				...(deliveryStartDateStart && { gte: deliveryStartDateStart }),
				...(deliveryStartDateEnd && { lte: deliveryStartDateEnd }),
			};
		}

		if (deliveryEndDateStart && deliveryEndDateEnd) {
			where.deliveryEndDate = {
				...(deliveryEndDateStart && { gte: deliveryEndDateStart }),
				...(deliveryEndDateEnd && { lte: deliveryEndDateEnd }),
			};
		}
		return where;
	}

	generateOrderBy(sorts: SortObj[]) {
		const orderBy: {
			[key: string]: any;
		} = {};

		sorts.forEach((sort: any) => {
			const parts = sort.valuePath.split('.');
			const firstPart = parts.shift();

			let value: any = sort.isAscending ? SortByDirection.Asc : SortByDirection.Desc;

			while (parts.length > 0) {
				const part = parts.pop();
				const obj: {
					[key: string]: any;
				} = {};

				if (part === 'Crop') {
					obj['CropCategory'] = value;
				} else {
					obj[part] = value;
				}
				value = obj;
			}

			if (firstPart === 'Plan') {
				orderBy['Plan'] = { AsGrainCropPlan: { ...value } };
			} else {
				orderBy[firstPart] = value;
			}
		});

		return orderBy;
	}

	async model(params: QueryParams) {
		this.variables = {
			orderBy: this.generateOrderBy(params.sorts),
			offset: params.page * params.size,
			limit: params.size,
			where: this.generateWhere(params),
		};

		await this.getFills.promise;

		return { getFills: this.getFills, variables: this.variables };
	}
}
