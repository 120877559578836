/**
 * Generate and download CSV
 * @param {array} columns
 * @param {array} rows
 * @param {string} fileName
 */
export default function generateCSV(columns, rows, fileName) {
	let csvRows = [];

	//creates headers
	const headers = columns.map((column) => column.name);
	csvRows.push(headers.join(','));

	//creates escaped rows for csv format
	for (const row of rows) {
		const values = columns.map((column) => {
			const escaped = ('' + column.value(row)).replace(/"/g, '\\"');
			return `"${escaped}"`;
		});
		csvRows.push(values.join(','));
	}

	const formattedCSVData = csvRows.join('\n');

	//create blob
	const blob = new Blob([formattedCSVData], { type: 'text/csv' });
	const url = window.URL.createObjectURL(blob);
	const a = document.createElement('a');
	a.setAttribute('hidden', '');
	a.setAttribute('href', url);
	a.setAttribute('download', fileName);
	document.body.appendChild(a);
	a.click();
	document.body.removeChild(a);

	return;
}
