import DRPInsuranceEndorsementsShowController from 'vault-client/controllers/drp-insurance-endorsements/show';

export default class BusinessesBusinessDrpInsuranceEndorsement extends DRPInsuranceEndorsementsShowController {
	drpInsuranceEndorsementsRoute: string = 'businesses.business.drp-insurance-endorsements';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'businesses/business/drp-insurance-endorsement': BusinessesBusinessDrpInsuranceEndorsement;
	}
}
