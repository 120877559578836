import Controller from '@ember/controller';
import InsurancePolicyFile from 'vault-client/models/insurance-policy-file';
import { action } from '@ember/object';
import BusinessesInsurancePoliciesRoute from 'vault-client/routes/businesses/business/insurance-policies';
import OrganizationsInsurancePoliciesRoute from 'vault-client/routes/organizations/organization/insurance-policies';
import { ModelFrom } from 'vault-client/utils/type-utils';

export default class InsurancePoliciesShowController extends Controller {
	insurancePoliciesRoute: string = '';
	declare model: ModelFrom<BusinessesInsurancePoliciesRoute> | ModelFrom<OrganizationsInsurancePoliciesRoute>;

	get columns() {
		const baseColumns = [
			{
				id: '49413fbc-aedf-4e1d-b627-0f01128008b6',
				name: 'Crop Year',
				valuePath: 'reinsuranceYear',
				minWidth: 225,
				textAlign: 'left',
				isSortable: false,
				cellComponent: 'String',
				isFixed: '',
				isVisible: true,
			},
			{
				id: '5497af00-16df-46ea-bd9d-2b3381caaac6',
				name: 'Date',
				valuePath: 'createdAt',
				minWidth: 225,
				cellComponent: 'IntlDateTimeFormat',
				componentArgs: { month: 'long', day: 'numeric', year: 'numeric' },
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '1b508f43-644f-4701-9123-039639201766',
				name: 'Type',
				valuePath: 'description',
				minWidth: 225,
				textAlign: 'left',
				isSortable: false,
				cellComponent: 'String',
				isFixed: '',
				isVisible: true,
			},
			{
				id: '4ce51483-4217-4f4d-9683-04d321fcf0f9',
				name: '',
				valuePath: 'url',
				minWidth: 225,
				textAlign: 'left',
				isSortable: false,
				cellComponent: 'Button',
				componentArgs: {
					size: 'xs',
					style: 'outline',
					text: 'Download',
					fn: this.downloadBtnTempAction,
				},
				isFixed: '',
				isVisible: true,
			},
		];
		return baseColumns;
	}

	get insuranceFiles() {
		return this.model.InsurancePolicy?.InsuranceFiles.map((file: any) => new InsurancePolicyFile(file))
			.sortBy('reinsuranceYear', 'createdAt')
			.reverse();
	}

	get insuranceFileScroll(): boolean | null | undefined {
		return this.insuranceFiles?.length > 8;
	}

	@action
	async downloadBtnTempAction(rowData: any) {
		const aDownloadPdf = document.createElement('a');
		aDownloadPdf.href = rowData.url;
		aDownloadPdf.target = '_blank';
		aDownloadPdf.download = rowData.url.split('/').pop();
		document.body.appendChild(aDownloadPdf);
		aDownloadPdf.click();
		document.body.removeChild(aDownloadPdf);
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'insurance-policies-show-controller': InsurancePoliciesShowController;
	}
}
