import Route from '@ember/routing/route';
import { tracked } from '@glimmer/tracking';
import { gql, useQuery } from 'glimmer-apollo';
import { Query_TransactionArgs, Transaction } from 'vault-client/types/graphql-types';

const GET_TRANSACTION = gql`
	query Transaction($id: String!) {
		Transaction(id: $id) {
			id
			contractQuantity
			unitQuantity
			price
			tradeDate
			updatedAt
			inputSource
			hasWriteAccess
			Fees {
				id
				type
				value
			}
			Account {
				id
				accountNumber
				name
				Owner {
					CurrentUserPermissions {
						canWriteSwap
					}
				}
			}
			Instrument {
				id
				name
				Product {
					id
					name
					displayFactor
					fractionDigits
					optionsUnitValue
					pointValue
					StandardProductLotSpecification {
						pointValue
					}
					velaRootSymbol
				}
				... on Option {
					optionType
					strike
					expiresAt
					displayExpiresAt
					barchartSymbol
					exchangeSymbol
					UnderlyingInstrument {
						id
						barchartSymbol
					}
				}
				... on Future {
					expiresAt
					displayExpiresAt
					barchartSymbol
					exchangeSymbol
				}
				... on Swap {
					expiresAt
					displayExpiresAt
					PriceInstrument {
						id
						... on Future {
							barchartSymbol
						}
					}
				}
				... on Swaption {
					expiresAt
					displayExpiresAt
					strike
					optionType
					PriceInstrument {
						id
						... on Future {
							barchartSymbol
						}
					}
				}
			}
		}
	}
`;

type GetTransactionQuery = {
	__typename?: 'Query';

	Transaction: Transaction | null;
};

export default class TransactionsShowRoute extends Route {
	@tracked id: string = '';

	templateName: string = 'transactions/show';

	getTransaction = useQuery<GetTransactionQuery, Query_TransactionArgs>(this, () => [
		GET_TRANSACTION,
		{
			variables: { id: this.id },
		},
	]);
}
