import { action } from '@ember/object';
import Component from '@glimmer/component';
import { htmlSafe } from '@ember/template';

interface UiInputWithButtonArgs {
	datum: { [key: string]: unknown };
	conditionalStyleFn?: (datum: { [key: string]: any }, ...args: any[]) => string;
	onClickFn: (month: unknown, value: string) => void;
	setValueFn?: (month: unknown, value: string) => void;
	inputStyle?: string;
	inputType?: string;
	valuePath: string;
	type?: Type;
	buttonText: string;
	buttonStyle?: Style;
	width?: Width;
	disabled?: boolean;
}

type Style = 'primary' | 'outline' | 'utility' | 'plain' | 'plain-subdued' | 'destructive' | 'destructive-plain' | 'plain-contrast';
type Type = 'button' | 'submit' | 'reset';
type Width = 'standard' | 'full';

export default class UiInputWithButton extends Component<UiInputWithButtonArgs> {
	defaultStyle = 'primary';
	defaultType = 'button';
	defaultWidth = 'standard';
	defaultDisabled = false;

	get buttonStyle() {
		return this.args.buttonStyle ?? this.defaultStyle;
	}

	get inputStyle(): any {
		return htmlSafe(
			(this.args.conditionalStyleFn ? this.args.conditionalStyleFn(this.args.datum) : '') + ' ' + (this.args.inputStyle ?? '')
		);
	}

	get inputType() {
		return this.args.inputType ?? 'text';
	}

	get type() {
		return this.args.type ?? this.defaultType;
	}

	get buttonText() {
		return this.args.buttonText;
	}

	get width() {
		return this.args.width ?? this.defaultWidth;
	}

	@action
	onInputChange(e: Event) {
		if (this.args.setValueFn) {
			const target = e.target as HTMLInputElement;
			this.args.setValueFn(this.args.datum, target.value);
		}
	}

	@action
	buttonOnClick() {
		this.args.onClickFn(this.args.datum, this.args.valuePath);
	}
}
