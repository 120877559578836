import { service } from '@ember/service';
import { getOwner } from '@ember/application';
import Transition from '@ember/routing/transition';
import ToolsSwinePriceScenariosController from 'vault-client/controllers/tools/swine-price-scenarios';
import BusinessBusinessToolsSwinePriceScenariosRoute from 'vault-client/routes/tools/swine-price-scenarios';
import { retrieveAddedHypotheticalPositionsFromStorage } from 'vault-client/utils/price-scenario-utils';
import { ModelFrom } from 'vault-client/utils/type-utils';
import ScenarioPricesService from 'vault-client/services/scenario-prices';

export default class BusinessesBusinessSwinePriceScenarios extends BusinessBusinessToolsSwinePriceScenariosRoute {
	templateName = 'tools/swine-price-scenarios';

	@service declare scenarioPrices: ScenarioPricesService;

	async setupController(
		controller: ToolsSwinePriceScenariosController,
		model: ModelFrom<BusinessesBusinessSwinePriceScenarios>,
		transition: Transition
	) {
		super.setupController(controller, model, transition);

		const currentPositions = this.createCurrentPositions(
			model.getSwinePriceScenariosData.data?.AllocatedFuturePositions ?? [],
			model.getSwinePriceScenariosData.data?.AllocatedOptionPositions ?? [],
			model.getSwinePriceScenariosData.data?.AllocatedSwapPositions ?? [],
			model.getSwinePriceScenariosData.data?.AllocatedSwaptionPositions ?? []
		);

		// With allocations, it is possible for us to base pricing on a future that is outside of the date range of our scenario.
		// To ensure that we have all the necessary data, we will fetch the prices for all the symbols in our current positions.
		const currentPositionsBarchartSymbols = currentPositions
			.map((position) => position.barchartSymbol)
			.filter((symbol): symbol is string => typeof symbol === 'string');

		const barchartSymbols = model.getSwinePriceScenariosData.data?.Futures.map((future) => future.barchartSymbol ?? null) ?? [];
		const filteredBarchartSymbols = barchartSymbols.filter((symbol): symbol is string => typeof symbol === 'string');

		const uniqueBarchartSymbols = [...new Set([...filteredBarchartSymbols, ...currentPositionsBarchartSymbols])];
		await this.scenarioPrices.addInstruments(uniqueBarchartSymbols);

		retrieveAddedHypotheticalPositionsFromStorage(model.entityId, controller._addedHypotheticalPositions, getOwner(this));
	}
}
