import PositionsIndexRoute from 'vault-client/routes/positions';

type SortObj = {
	valuePath: string;
	isAscending: boolean;
};

interface Params {
	accountId?: string | null;
	instrumentId?: string | null;
	customerId?: string | null;
	productId?: string | null;
	salesCode?: string | null;
	side?: string | null;
	monthExpiresAtStartDate: string;
	monthExpiresAtEndDate: string;
	sorts: SortObj[];
	showClosedPositions: boolean;
	page: number;
	size: number;
	id: string;
	selectedInstrumentOptionTypes: Array<{
		name: string;
		instrumentType: string | null;
		optionType: string | null;
	}>;
}

export default class BusinessesPositions extends PositionsIndexRoute {
	async model(params: Params) {
		const businessParams = this.paramsFor('businesses.business') as { business_id: string };
		const variables = {
			where: this.generateWhere(
				null,
				businessParams.business_id,
				params.accountId,
				params.instrumentId,
				params.productId,
				params.salesCode,
				params.monthExpiresAtStartDate,
				params.monthExpiresAtEndDate,
				params.showClosedPositions,
				params.side,
				params.selectedInstrumentOptionTypes
			),
			orderBy: this.generateOrderBy(params.sorts),
			offset: params.page * params.size,
			limit: params.size,
		};

		const response = await this.apollo.watchQuery({ query: this.query, variables });

		return {
			...response,
			variables,
			scopeId: businessParams.business_id,
		};
	}
}
