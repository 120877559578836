import { DateTime } from 'luxon';

export default function intlDateTimeFormat(params) {
	if (params.nullSkipFormat && !params.value) {
		return params.value;
	} else {
		const locale = params.locale ? params.locale : 'en-US';
		const luxonDate = DateTime.fromISO(params.value);
		const date = luxonDate.toJSDate();
		let options = {};

		options['weekday'] = params.weekday;
		options['year'] = params.year;
		options['month'] = params.month;
		options['day'] = params.day;
		options['hour'] = params.hour;
		options['minute'] = params.minute;
		options['second'] = params.second;

		return new Intl.DateTimeFormat(locale, options).format(date);
	}
}
