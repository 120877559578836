import ReportsPriceScenariosController from 'vault-client/controllers/reports/price-scenarios';

export default class BusinessesBusinessReportsPriceScenariosController extends ReportsPriceScenariosController {
	reportsRoutePath = 'businesses.business.reports';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'businesses/business/reports/price-scenarios': BusinessesBusinessReportsPriceScenariosController;
	}
}
