import Component from '@glimmer/component';
import { tracked } from 'tracked-built-ins';
import { action } from '@ember/object';
import { DateTime, Interval } from 'luxon';
import { gql, QueryResource, useMutation } from 'glimmer-apollo';
import {
	LedgerEntrySetByMonth,
	DateIncrement,
	LedgerEntrySetByMonthDateAndAmount,
	Query_LedgerMilkCheckCategoriesArgs,
	Query_LedgerFeedCategoriesArgs,
	Mutation_setLedgerForecastedEntriesByMonthArgs,
	Mutation,
} from 'vault-client/types/graphql-types';

const SET_FORECASTED_LEDGER_ENTRIES_MUTATION = gql`
	mutation SetLedgerForecastedEntriesByMonth($data: LedgerEntrySetByMonth!) {
		setLedgerForecastedEntriesByMonth(data: $data)
	}
`;

interface SetForecastedLedger {
	startDate: string;
	endDate: string;
	amount: number;
	categoryId: string;
	datesAndAmounts?: LedgerEntrySetByMonthDateAndAmount[];
	entityId: string;
	increment: DateIncrement;
}

type ValueObject = {
	id: string;
	name: string;
	calculationType: 'DairyCWT' | null;
	value: any;
	growthPercentage: string;
	event: any;
	type?: string;
	description?: string | null;
	startDate?: string;
	endDate?: string;
	amount?: number;
	categoryId?: string;
	entityId?: string;
	increment?: DateIncrement;
	datesAndAmounts?: LedgerEntrySetByMonthDateAndAmount;
};
interface SetLedgerForecastedValuesButtonArgs {
	data: LedgerEntrySetByMonth;
	entityId: string;
	selectedRows: any[];
	model: QueryResource<unknown, unknown>;
	ledgerCategories: Query_LedgerMilkCheckCategoriesArgs[] | Query_LedgerFeedCategoriesArgs[];
	updateSelectedRows: (values: any[]) => void;
}

enum GrowthType {
	perMonth = 'perMonth',
	perYear = 'perYear',
}

type CustomMutationResult = { networkError?: { result: { errors: any[] } } } | null;

export default class SetLedgerForecastedValuesButton extends Component<SetLedgerForecastedValuesButtonArgs> {
	@tracked showModal = false;
	@tracked errorMessage: string | null | undefined = null;
	@tracked valueObjects: ValueObject[] = [];
	@tracked updatingValues = false;
	@tracked variables = {};

	setForecastedEntriesMutation = useMutation<Mutation['setLedgerForecastedEntriesByMonth'], Mutation_setLedgerForecastedEntriesByMonthArgs>(
		this,
		() => [
			SET_FORECASTED_LEDGER_ENTRIES_MUTATION,
			{
				update: (cache) => {
					cache.evict({ fieldName: 'AggregateLedgerForecastedEntries' });
					cache.evict({ fieldName: 'AggregateLedgerEntries' });
					cache.evict({ fieldName: 'LedgerEntries' });
					cache.evict({ fieldName: 'LedgerEntryCount' });
					cache.evict({ fieldName: 'LedgerForecastedEntries' });
					cache.evict({ fieldName: 'LedgerForecastedEntryCount' });
					cache.gc();
				},
			},
		]
	);

	get monthsToUpdate(): SetForecastedLedger[] {
		const orderedSelectedMonths = this.args.selectedRows.sortBy('month');
		const firstMonth = orderedSelectedMonths[0];
		const startDate = DateTime.fromISO(firstMonth.month);

		const updatedValueObjects = this.valueObjects.filter((object) => {
			return object.value.trim();
		});

		const months = this.args.selectedRows.map((row) => {
			const currentDate = DateTime.fromISO(row.month);
			const dateInterval = Interval.fromDateTimes(startDate, currentDate);

			return updatedValueObjects.map((valueObject) => {
				const options = valueObject.event?.target.options as HTMLOptionsCollection;
				const growthType = options ? (options[options.selectedIndex].value as GrowthType) : GrowthType.perYear;

				let diffUnits;

				switch (growthType) {
					case GrowthType.perMonth:
						diffUnits = dateInterval.length('months');
						break;
					case GrowthType.perYear:
						diffUnits = dateInterval.length('years');
						break;
				}

				const growthPercentage = parseFloat(valueObject.growthPercentage) / 100 ?? 0;

				const amount = parseFloat(valueObject.value) + parseFloat(valueObject.value) * diffUnits * growthPercentage;

				const update = {
					startDate: currentDate.toISODate(),
					endDate: currentDate.endOf('month').toISODate(),
					amount,
					categoryId: valueObject.id,
					entityId: this.args.entityId ? this.args.entityId : '',
					increment: DateIncrement.Month,
				};
				return update;
			});
		});

		return months.flat(1);
	}

	@action
	openModal() {
		this.showModal = true;
		this.valueObjects = this.args.ledgerCategories.map((category: any) => {
			return tracked({
				id: category.id,
				name: category.name,
				calculationType: category.calculationType ?? null,
				value: '',
				growthPercentage: '0',
				event: null,
				description: category.description,
				type: category.type ?? '',
			});
		});
	}

	@action
	closeModal() {
		this.errorMessage = null;
		this.showModal = false;
	}

	@action
	async submit() {
		const newMonthArrayObj: LedgerEntrySetByMonth[] = [];

		this.monthsToUpdate.forEach((month) => {
			const newMonthObj: any = {};
			const result = newMonthArrayObj.find((category) => category.categoryId === month.categoryId);

			if (result && result.datesAndAmounts) {
				result.datesAndAmounts.push({ date: month.startDate, amount: month.amount });
			} else {
				newMonthObj.datesAndAmounts = [];
				newMonthObj.categoryId = month.categoryId;
				newMonthObj.entityId = month.entityId;
				newMonthObj.datesAndAmounts.push({ date: month.startDate, amount: month.amount });
			}
			newMonthArrayObj.push(newMonthObj);
		});

		const mutationResults = (await Promise.all(
			newMonthArrayObj.map((category) => {
				return category.categoryId ? this.setForecastedEntriesMutation.mutate({ data: category }) : null;
			})
		)) as CustomMutationResult[];

		const hasErrors = mutationResults?.some(
			(result) => result?.networkError?.result?.errors && result?.networkError?.result?.errors.length > 0
		);

		if (!hasErrors) {
			this.args.updateSelectedRows([]);
			this.closeModal();
		} else {
			this.errorMessage = 'Error setting forecasted ledger values.';
			console.error('Error while attempting to set forecasted ledger values.');
		}
	}
}
