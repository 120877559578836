import Component from '@glimmer/component';
import { action } from '@ember/object';

interface VaultUiFormArgs {
	onSubmit: onSubmitFunc;
	disabled?: boolean;
}

interface onSubmitFunc {
	// eslint-disable-next-line no-unused-vars
	(): void;
}

export default class VaultUiForm extends Component<VaultUiFormArgs> {
	@action
	submit(event: MouseEvent | KeyboardEvent) {
		event.preventDefault();

		if (this.args.onSubmit) {
			this.args.onSubmit();
		} else {
			throw new Error('Form submission not handled.');
		}
	}
}
