export default class TableColumn {
	columnName;
	valueKey;

	constructor(columnName: any, valueKey: any) {
		this.columnName = columnName;
		this.valueKey = valueKey;
	}

	formatValue(value: any) {
		return value;
	}
}
