import MilkCheckReconciliationController from 'vault-client/controllers/milk/milk-check-reconciliation';

export default class LocationsLocationMilkCheckReconciliationController extends MilkCheckReconciliationController {}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'locations/location/milk-check-reconciliation': LocationsLocationMilkCheckReconciliationController;
	}
}
