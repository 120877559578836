import { modifier } from 'ember-modifier';

// This modifier is built on the assumption that the object(s) that will be loaded will push the HTMLElement we are observing out of the viewport once they are loaded
export default modifier(function loadMoreOnScroll(
	element: HTMLElement,
	[currentCount, increment, maxCount, loadMoreItemsFn, activateLoadingSpinnerFn, stopLoadingSpinnerFn]: [
		number,
		number,
		number,
		(increment: number) => void,
		() => void,
		() => void
	]
) {
	const observe = (observersArray: IntersectionObserverEntry[]) => {
		if (observersArray[0].intersectionRatio > 0) {
			currentCount += increment;
			activateLoadingSpinnerFn();
			// count += increment;
			loadMoreItemsFn(increment);
			// Stop observing the HTMLElement to allow the object(s) to finish loading without continuing to call the loadMoreItemsFn
			observer.unobserve(element);
		} else if (observersArray[0].intersectionRatio == 0) {
			stopLoadingSpinnerFn();
			// Start observing HTMLElement again once object(s) are loaded and the HTMLElement is driven out of the viewport
			observer.observe(element);
		}

		if (currentCount > maxCount) {
			// Stop observing HTMLElement when you have loaded all the possible object(s)
			stopLoadingSpinnerFn();
			observer.unobserve(element);
		}
	};

	const observer = new IntersectionObserver(observe, {});
	observer.observe(element);
});

// Documenting the inputs:
// element is the HTMLElement we are observing. When this element comes into the viewport, more object(s) will be loaded. This includes if the element is in the viewport the first time the page loads
// currentCount is the starting count at the time the HTMLElement we are observing is created on the DOM.
// This should be 0 if that HTMLElement is in the viewport when the page is initially loaded
// and should be = number of object(s) already loaded if the HTMLElement is NOT in the viewport when the page is initially loaded
// increment is how many more object(s) we want to load when the HTMLElement is in the viewport
// maxCount is the maximum number of object(s) that can be loaded
// loadMoreItemsFn is the function that will cause more object(s) to be loaded
// activateLoadingSpinnerFn and stopLoadingSpinnerFn are the functions that start and stop the loading spinner to indicate that there is more object(s) loading
