import PermissionGroupsShow from 'vault-client/controllers/permission-groups/show';

export default class OrganizationsOrganizationPermissionGroup extends PermissionGroupsShow {
	permissionGroupsRoutePath = 'organizations.organization.permission-groups';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'organizations/organization/permission-group': OrganizationsOrganizationPermissionGroup;
	}
}
