import Route from '@ember/routing/route';
import MarketsProductSeasonalityController from 'vault-client/controllers/markets/product/seasonality';
import { Product } from 'vault-client/types/graphql-types';
export default class MarketsProductSeasonalityRoute extends Route {
	async model() {
		const { Product } = this.modelFor('markets.product') as { Product: Product };
		return Product;
	}

	resetController(controller: MarketsProductSeasonalityController, isExiting: boolean) {
		if (isExiting) {
			controller.numberOfFuturesToLoad = 0;
		}
	}
}
