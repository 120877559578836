import { modifier } from 'ember-modifier';

export default modifier(function tableborder(_element: HTMLElement, [columnMeta]: any) {
	const parentNode: any | undefined = columnMeta?._node?.parent;
	const subNodes: any[] | undefined = columnMeta?._node?.parent?.subcolumnNodes;
	const columnNodes: any[] | undefined | any = columnMeta?._node;
	if (columnNodes?.subcolumnNodes) {
		columnNodes?.element?.classList.add('parent-column');
	}
	// We need to reference columnMeta.width or resizing the columns removes the border
	if (columnMeta?.width) {
		const bodyColumns = document.querySelectorAll(`#bodyColumnBorder-${parentNode?.column?.id}`);
		const footerColumns = document.querySelectorAll(`#footerColumnBorder-${parentNode?.column?.id}`);
		const numSubColumns = parentNode.isRoot ? null : parentNode.subcolumnNodes?.length;
		// add the border to the parent column
		if (columnNodes?.parent?.subcolumnNodes) {
			const nextSiblingColspan = parentNode?.element?.nextElementSibling?.getAttribute('colspan');
			const previousSiblingColspan = parentNode?.element?.previousElementSibling?.getAttribute('colspan');
			const previousSibling = parentNode?.element?.previousElementSibling;
			// doing this to eliminate double borders
			if (nextSiblingColspan > 1 && previousSiblingColspan >= 1) {
				previousSibling?.classList.add('last-sub-column');
				// table cell border right will take precedence over next siblings border left
			} else {
				previousSibling?.classList.add('last-sub-column');
				parentNode?.element?.classList.add('parent-column');
			}
		}
		// only add borders to the first and last subcolumn body cells
		if (numSubColumns) {
			bodyColumns.forEach((node, index) => {
				if (index % numSubColumns == 0) {
					node.parentElement?.classList.add('first-sub-column');
					// table cell border right will take precedence over next siblings border left
					node.parentElement?.previousElementSibling?.classList.add('last-sub-column');
				} else if ((index + 1) % numSubColumns == 0) {
					node.parentElement?.classList.add('last-sub-column');
				}
			});
			footerColumns.forEach((node, index) => {
				if (index % numSubColumns == 0) {
					node.parentElement?.classList.add('first-sub-column');
					// table cell border right will take precedence over next siblings border left
					node.parentElement?.previousElementSibling?.classList.add('last-sub-column');
				} else if ((index + 1) % numSubColumns == 0) {
					node.parentElement?.classList.add('last-sub-column');
				}
			});
		}
		// add the border to the first and the last subColumn
		if (subNodes && subNodes.length > 0) {
			subNodes.forEach((node) => {
				if (node.subcolumnNodes) {
					const firstObject = node.subcolumnNodes.firstObject;
					const lastObject = node.subcolumnNodes.lastObject;
					const middleObjects = node.subcolumnNodes.slice(1, node.subcolumnNodes.length - 1);
					const nextSiblingColSpan = node.element?.nextElementSibling?.getAttribute('colspan');
					const previousSibling = node?.element?.previousElementSibling;
					if (firstObject && lastObject && node.subcolumnNodes.length > 1) {
						if (parentNode?.element?.nextElementSibling && nextSiblingColSpan > 1) {
							firstObject.element?.classList?.add('first-sub-column');
						} else if (nextSiblingColSpan == 1) {
							firstObject.element?.classList?.add('first-sub-column');
							lastObject.element?.classList?.add('last-sub-column');
							// table cell border right will take precedence over next siblings border left
							previousSibling?.classList?.add('last-sub-column');
						} else {
							firstObject.element?.classList?.add('first-sub-column');
							lastObject.element?.classList?.add('last-sub-column');
						}
						middleObjects.forEach((node: any) => {
							node.element?.classList?.add('middle-sub-column');
						});
					} else if (firstObject && node.subcolumnNodes.length == 1) {
						firstObject.element?.classList?.add('parent-column');
					}
				}
			});
		}
	}
});
