import Route from '@ember/routing/route';
import Transition from '@ember/routing/transition';
import { tracked } from '@glimmer/tracking';
import { gql, useQuery } from 'glimmer-apollo';
import BusinessesBusinessAnimalGroupsController from 'vault-client/controllers/businesses/business/animal-groups';
import {
	CustomerEntity,
	InputMaybe,
	LivestockGroupVersion,
	Query_LivestockGroupVersionsArgs,
	SortByDirection,
	SwineLivestockGroupSortByDTO,
	TypeOfLivestockGroup,
} from 'vault-client/types/graphql-types';
import { ModelFrom } from 'vault-client/utils/type-utils';

const GET_LIVESTOCK_GROUPS = gql`
	query LivestockGroups($customerId: String!, $orderBy: SwineLivestockGroupSortByDTO) {
		Customer(id: $customerId) {
			SwineLivestockGroups(orderBy: $orderBy) {
				businessId
				createdAt
				deathRate
				description
				hasWriteAccess
				id
				maxAgeInWeeks
				minAgeInWeeks
				name
				type
				updatedAt
				versionId
			}
		}
	}
`;

const GET_LIVESTOCK_GROUP_VERSIONS = gql`
	query LivestockGroupVersions($where: LivestockGroupVersionFilterDTO) {
		LivestockGroupVersions(where: $where) {
			isCurrent
			CreatedByUser {
				firstName
				lastName
			}
			LastUpdatedByUser {
				lastName
				firstName
			}
			businessId
			Business {
				businessRoles
			}
			createdAt
			description
			endDate
			hasWriteAccess
			id
			updatedAt
			type
			startDate
			name
			livestockGroupType
		}
	}
`;

type GetLivestockGroupsQuery = {
	__typename?: 'Query';
	Customer: CustomerEntity;
};

type GetLivestockGroupVersionsQuery = {
	__typename?: 'Query';
	LivestockGroupVersions: LivestockGroupVersion[];
};

type Query_SwineLivestockGroupsArgs = {
	customerId: string;
	orderBy?: InputMaybe<SwineLivestockGroupSortByDTO>;
};

export default class BusinessesBusinessAnimalGroupsRoute extends Route {
	@tracked currentVersionVariables: Query_SwineLivestockGroupsArgs = {
		customerId: '',
		orderBy: {
			minAgeInWeeks: SortByDirection.Asc,
		},
	};
	@tracked variables: Query_LivestockGroupVersionsArgs = {
		where: {
			livestockGroupType: {
				equals: TypeOfLivestockGroup.Swine,
			},
			businessId: {
				equals: '',
			},
		},
	};
	getLivestockGroups = useQuery<GetLivestockGroupsQuery, Query_SwineLivestockGroupsArgs>(this, () => [
		GET_LIVESTOCK_GROUPS,
		{ variables: this.currentVersionVariables },
	]);
	getLivestockGroupVersions = useQuery<GetLivestockGroupVersionsQuery, Query_LivestockGroupVersionsArgs>(this, () => [
		GET_LIVESTOCK_GROUP_VERSIONS,
		{ variables: this.variables },
	]);

	async model() {
		const businessParams = this.paramsFor('businesses.business') as { business_id: string };
		this.currentVersionVariables = {
			customerId: businessParams.business_id,
			orderBy: {
				minAgeInWeeks: SortByDirection.Asc,
			},
		};
		this.variables = {
			where: {
				livestockGroupType: {
					equals: TypeOfLivestockGroup.Swine,
				},
				businessId: {
					equals: businessParams.business_id,
				},
			},
		};

		await this.getLivestockGroups.promise;
		await this.getLivestockGroupVersions.promise;

		return { livestockGroups: this.getLivestockGroups, livestockGroupVersions: this.getLivestockGroupVersions };
	}

	setupController(
		controller: BusinessesBusinessAnimalGroupsController,
		model: ModelFrom<BusinessesBusinessAnimalGroupsRoute>,
		transition: Transition<unknown>
	): void {
		super.setupController(controller, model, transition);
		this.setSelectedVersionIfNeeded(controller, model);
	}

	setSelectedVersionIfNeeded(controller: BusinessesBusinessAnimalGroupsController, model: ModelFrom<BusinessesBusinessAnimalGroupsRoute>) {
		const selectedVersion = controller.selectedVersion;

		const versionExistsForBusiness = !!model.livestockGroupVersions.data?.LivestockGroupVersions.find(
			(version) => version.id === selectedVersion?.id
		);

		if (selectedVersion == null || !versionExistsForBusiness) {
			controller.selectedVersion = model.livestockGroupVersions.data?.LivestockGroupVersions.find((version) => version.isCurrent === true);
		}
	}
}
