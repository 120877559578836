import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

interface UiInputTrailingButtonArgs {
	defaultValue: number | string;
	// eslint-disable-next-line no-unused-vars
	onClick: (value: string | number) => void;
	id?: string;
	label?: string;
	disabled?: boolean;
	buttonText?: string;
	placeholder?: string;
	type?: string;
	icon?: string;
	iconPrefix?: string;
	iconSize?: string;
	// eslint-disable-next-line no-unused-vars
	validator?: (value: string | number) => boolean;
}

export default class UiInputTrailingButton extends Component<UiInputTrailingButtonArgs> {
	@tracked _value: string | number | undefined = undefined;

	get buttonText() {
		return this.args.buttonText ?? 'Submit';
	}

	get type() {
		return this.args.type ?? 'text';
	}
	get isDisabled() {
		if (this.args.disabled !== undefined) {
			return this.args.disabled;
		}

		return !this.inputIsValid || this.value === this.args.defaultValue;
	}

	get inputIsValid() {
		if (this._value === undefined || (this.args.validator && !this.args.validator(this.value))) {
			return false;
		}

		return true;
	}

	get value(): string | number {
		return this._value === undefined ? this.args.defaultValue : this._value;
	}

	set value(inputValue: string | number | undefined) {
		this._value = inputValue;
	}

	@action
	handleKeyUp(event: KeyboardEvent) {
		if (event.key === 'Enter') {
			this.submitValue();
		}
	}

	@action
	submitValue() {
		if (!this.inputIsValid) {
			return;
		}

		this.args.onClick(this.value);
	}
}
