import MilkCheckReconciliationController from 'vault-client/controllers/milk/milk-check-reconciliation';

export default class BusinessesBusinessMilkCheckReconciliationController extends MilkCheckReconciliationController {
	milkRoutePath = 'businesses.business.milk';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'businesses/business/milk-check-reconciliation': BusinessesBusinessMilkCheckReconciliationController;
	}
}
