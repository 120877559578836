import { DateTime } from 'luxon';
import { DerivedDrpEndorsement } from 'vault-client/types/graphql-types';

export default class DerivedInsuranceEndorsement {
	classPriceWeightingFactor;
	componentPriceWeightingFactor;
	coverageLevelPercent;
	declaredButterfatTest;
	declaredCoveredMilkProduction;
	declaredProteinTest;
	effectiveCoveredMilkProduction;
	effectiveExpectedRevenueGuarantee;
	pnl;
	id;
	indemnity;
	InsuranceEndorsement;
	InsurancePolicy;
	producerPremiumAmount;
	projectedRevenue;
	quarterStartDate;
	salesEffectiveDate;
	totalPremiumAmount;
	declaredShare;
	protectionFactor;
	expectedRevenueGuarantee;
	lastYield;
	actualYield;
	insuredYield;
	currentYield;
	indemnityEffectButter;
	indemnityEffectButterfat;
	indemnityEffectCheese;
	indemnityEffectClassIii;
	indemnityEffectClassIv;
	indemnityEffectExcessProtein;
	indemnityEffectNonfatSolids;
	indemnityEffectOtherSolids;
	indemnityEffectProtein;
	insuredButterfatPrice;
	insuredButterPrice;
	insuredCheesePrice;
	insuredClassIiiPrice;
	insuredClassIvPrice;
	insuredNonfatSolidsPrice;
	insuredOtherSolidsPrice;
	insuredProteinPrice;

	butterfatPounds;
	classIiiPounds;
	classIvPounds;
	excessButterPounds;
	excessCheesePounds;
	excessProteinPoundsAfterCheese;
	proteinPounds;
	otherSolidsPounds;
	nonfatSolidsPounds;

	constructor(endorsement: DerivedDrpEndorsement | null) {
		this.classPriceWeightingFactor = endorsement?.classPriceWeightingFactor;
		this.componentPriceWeightingFactor = endorsement?.componentPriceWeightingFactor;
		this.coverageLevelPercent = endorsement?.coverageLevelPercent;
		this.declaredButterfatTest = endorsement?.declaredButterfatTest;
		this.declaredCoveredMilkProduction = endorsement?.declaredCoveredMilkProduction;
		this.declaredProteinTest = endorsement?.declaredProteinTest;
		this.effectiveCoveredMilkProduction = endorsement?.effectiveCoveredMilkProduction;
		this.effectiveExpectedRevenueGuarantee = endorsement?.effectiveExpectedRevenueGuarantee;
		this.pnl = endorsement?.pnl;
		this.id = endorsement?.id;
		this.indemnity = endorsement?.indemnity;
		this.InsuranceEndorsement = endorsement?.InsuranceEndorsement;
		this.InsurancePolicy = endorsement?.InsurancePolicy;
		this.producerPremiumAmount = endorsement?.producerPremiumAmount;
		this.quarterStartDate = endorsement?.quarterStartDate;
		this.salesEffectiveDate = endorsement?.salesEffectiveDate;
		this.totalPremiumAmount = endorsement?.totalPremiumAmount;
		this.declaredShare = endorsement?.declaredShare;
		this.protectionFactor = endorsement?.protectionFactor;
		this.expectedRevenueGuarantee = endorsement?.expectedRevenueGuarantee;
		this.lastYield = endorsement?.lastYield;
		this.actualYield = endorsement?.actualYield;
		this.insuredYield = endorsement?.insuredYield;
		this.currentYield = endorsement?.currentYield;
		this.indemnityEffectButter = endorsement?.indemnityEffectButter;
		this.indemnityEffectButterfat = endorsement?.indemnityEffectButterfat;
		this.indemnityEffectCheese = endorsement?.indemnityEffectCheese;
		this.indemnityEffectClassIii = endorsement?.indemnityEffectClassIii;
		this.indemnityEffectClassIv = endorsement?.indemnityEffectClassIv;
		this.indemnityEffectExcessProtein = endorsement?.indemnityEffectExcessProtein;
		this.indemnityEffectNonfatSolids = endorsement?.indemnityEffectNonfatSolids;
		this.indemnityEffectOtherSolids = endorsement?.indemnityEffectOtherSolids;
		this.indemnityEffectProtein = endorsement?.indemnityEffectProtein;
		this.insuredButterfatPrice = endorsement?.insuredButterfatPrice;
		this.insuredButterPrice = endorsement?.insuredButterPrice;
		this.insuredCheesePrice = endorsement?.insuredCheesePrice;
		this.insuredClassIiiPrice = endorsement?.insuredClassIiiPrice;
		this.insuredClassIvPrice = endorsement?.insuredClassIvPrice;
		this.insuredNonfatSolidsPrice = endorsement?.insuredNonfatSolidsPrice;
		this.insuredOtherSolidsPrice = endorsement?.insuredOtherSolidsPrice;
		this.insuredProteinPrice = endorsement?.insuredProteinPrice;

		this.butterfatPounds = endorsement?.butterfatPounds;
		this.classIiiPounds = endorsement?.classIiiPounds;
		this.classIvPounds = endorsement?.classIvPounds;
		this.excessButterPounds = endorsement?.excessButterPounds;
		this.excessCheesePounds = endorsement?.excessCheesePounds;
		this.excessProteinPoundsAfterCheese = endorsement?.excessProteinPoundsAfterCheese;
		this.proteinPounds = endorsement?.proteinPounds;
		this.otherSolidsPounds = endorsement?.otherSolidsPounds;
		this.nonfatSolidsPounds = endorsement?.nonfatSolidsPounds;

		this.projectedRevenue = endorsement?.projectedRevenue;
	}

	get quarterFormat() {
		return this.quarterStartDate
			? `${DateTime.fromISO(this.quarterStartDate, { zone: 'utc' }).year} Q${
					DateTime.fromISO(this.quarterStartDate, { zone: 'utc' }).quarter
			  }`
			: '';
	}

	get classIiiPercentage() {
		return this.classPriceWeightingFactor != null ? this.classPriceWeightingFactor : this.componentPriceWeightingFactor;
	}

	get classIvPercentage() {
		return this.classPriceWeightingFactor != null ? 1 - this.classPriceWeightingFactor : 1 - (this.componentPriceWeightingFactor ?? 0);
	}

	get butterfatPercentage() {
		return this.declaredButterfatTest && this.declaredButterfatTest > 0 ? this.declaredButterfatTest : null;
	}

	get proteinPercentage() {
		return this.declaredProteinTest && this.declaredProteinTest > 0 ? this.declaredProteinTest : null;
	}

	get effectiveExpectedRevenueGuaranteeCwt() {
		return this.effectiveCoveredMilkProduction
			? ((this.effectiveExpectedRevenueGuarantee ?? 0) / this.effectiveCoveredMilkProduction) * 100
			: null;
	}

	get producerPremiumAmountCwt() {
		return this.effectiveCoveredMilkProduction ? ((this.producerPremiumAmount ?? 0) / this.effectiveCoveredMilkProduction) * 100 : null;
	}

	get totalPremiumAmountCwt() {
		return this.effectiveCoveredMilkProduction ? ((this.totalPremiumAmount ?? 0) / this.effectiveCoveredMilkProduction) * 100 : null;
	}

	get indemnityCwt() {
		return this.effectiveCoveredMilkProduction ? ((this.indemnity ?? 0) / this.effectiveCoveredMilkProduction) * 100 : null;
	}

	get pnlCwt() {
		return this.effectiveCoveredMilkProduction ? ((this.pnl ?? 0) / this.effectiveCoveredMilkProduction) * 100 : null;
	}

	get projectedRevenueCwt() {
		return this.effectiveCoveredMilkProduction ? ((this.projectedRevenue ?? 0) / this.effectiveCoveredMilkProduction) * 100 : null;
	}

	get yieldChange() {
		return this.insuredYield && this.currentYield ? (this.currentYield - this.insuredYield) / this.insuredYield : null;
	}

	get classIiiContracts() {
		return (this.classIiiPounds ?? 0) / 200000;
	}

	get classIvContracts() {
		return (this.classIvPounds ?? 0) / 200000;
	}

	get butterContracts() {
		return (this.excessButterPounds ?? 0) / 20000;
	}

	get cheeseContracts() {
		return (this.excessCheesePounds ?? 0) / 20000;
	}
}
