import Controller from '@ember/controller';
import { DateTime, Interval } from 'luxon';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import {
	County,
	ForecastedMilkUtilization,
	Future,
	LocationEntity,
	LocationEntityCreateDTO,
	Mutation_createLocationArgs,
	AggregateLedgerForecastedEntryDTO,
	AggregateForecastedMilkProductionByMonthDTO,
	MilkOrder,
	ForecastedMilkProductionByMonth,
} from 'vault-client/types/graphql-types';
import { getOwner } from '@ember/application';
import { CellComponents, TableColumn } from 'vault-client/types/vault-table';
import MilkCheck from 'vault-client/models/milk-check';
import { gql, useMutation } from 'glimmer-apollo';
import BusinessesBusinessMilkCheckRoute from 'vault-client/routes/businesses/business/milk-check';
import { ModelFrom } from 'vault-client/utils/type-utils';
import {
	calculateMonthlyWeightedPricesAndBasisValues,
	calculateMonthlyWeightedProjectedValues,
	calculateUtilizationValuesFromForecastedProduction,
} from 'vault-client/utils/milk-check-utils';
export interface IMonthObject {
	date: string;
	utilizations?: ForecastedMilkUtilization;
	classiiiFuture: Future | null;
	classivFuture: Future | null;
	butterFuture: Future | null;
	advancedNonfatDryMilkFuture: Future | null;
	advancedButterFuture: Future | null;
	advancedCheeseFuture: Future | null;
	advancedDryWheyFuture: Future | null;
	monthlyCalculatedAmount?: number | null;
	grossButterfatProduction: number | null | undefined;
	grossOtherSolidsProduction: number | null | undefined;
	grossProteinProduction: number | null | undefined;
	forecastedBlendedMilkPrice: number | null | undefined;
}

interface IMonthObjects {
	[key: string]: IMonthObject;
}

const CREATE_LOCATION = gql`
	mutation createLocation($data: LocationEntityCreateDTO!) {
		createLocation(data: $data) {
			id
			name
		}
	}
`;

type CreateLocationMutation = {
	__typename?: 'Mutation';
	createLocation: {
		id: string;
		name: string;
		__typename: 'LocationEntity';
	};
};

export default class BusinessesBusinessMilkCheck extends Controller {
	declare model: ModelFrom<BusinessesBusinessMilkCheckRoute>;
	@tracked productionMonthStartDate = DateTime.local().startOf('month').toISODate();
	@tracked productionMonthEndDate = DateTime.local().plus({ months: 23 }).endOf('month').toISODate();
	@tracked newLocationName: string | null = null;
	@tracked selectedLocationId: string = '';
	@tracked selectedMilkOrder: MilkOrder | null = null;
	@tracked _selectedRows: MilkCheck[] | null = [];
	@tracked selectedCounty: County | null = null;

	milkRoutePath = 'businesses.business.milk';

	@tracked columns: TableColumn[] = [
		{
			id: '6236f662-e981-4998-9699-67980f03cda3',
			name: 'Month',
			valuePath: 'date',
			minWidth: 80,
			cellComponent: CellComponents.MonthFormat,
			textAlign: 'left',
			isSortable: false,
			isFixed: '',
			isVisible: true,
		},
		{
			id: '6c7b09cc-e0e9-4ad0-ab09-d8af4fc459b6',
			name: 'Class Utilizations',
			cellComponent: CellComponents.String,
			isVisible: true,
			isFixed: '',
			subcolumns: [
				{
					id: '9851c92b-e94c-4f2b-a875-b963c813dda1',
					name: 'I',
					valuePath: 'milkUtilizations.classiUtilization',
					cellComponent: CellComponents.IntlNumberFormat,
					width: 40,
					componentArgs: {
						style: 'percent',
					},
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
				{
					id: '7a4f3159-a0cd-4f67-9736-08293600facd',
					name: 'II',
					valuePath: 'milkUtilizations.classiiUtilization',
					cellComponent: CellComponents.IntlNumberFormat,
					width: 40,
					componentArgs: {
						style: 'percent',
					},
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
				{
					id: '4fc42c8c-b6c9-4ade-82c2-b34f361f311b',
					name: 'III',
					valuePath: 'milkUtilizations.classiiiUtilization',
					cellComponent: CellComponents.IntlNumberFormat,
					width: 40,
					componentArgs: {
						style: 'percent',
					},
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
				{
					id: '5a6d2456-83cc-4c44-a6f2-224ef11c45ee',
					name: 'IV',
					valuePath: 'milkUtilizations.classivUtilization',
					cellComponent: CellComponents.IntlNumberFormat,
					width: 40,
					componentArgs: {
						style: 'percent',
					},
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
			],
		},
		{
			id: '1320a037-862d-4362-a5ae-244cce64c9ab',
			name: 'Class Prices',
			cellComponent: CellComponents.String,
			isFixed: '',
			isVisible: true,
			subcolumns: [
				{
					id: '9995417a-6f3f-4f5a-b7e5-4cd5aa1c0f27',
					name: 'I',
					valuePath: 'classiPrice',
					cellComponent: CellComponents.IntlNumberFormat,
					width: 40,
					componentArgs: {
						style: 'currency',
						currency: 'USD',
					},
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
				{
					id: '20f1ca62-9698-4767-a91b-1fe5221f1b8f',
					name: 'II',
					valuePath: 'classiiPrice',
					cellComponent: CellComponents.IntlNumberFormat,
					width: 40,
					componentArgs: {
						style: 'currency',
						currency: 'USD',
					},
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
				{
					id: '86aee41e-1e86-429f-b1ee-b654a7f62abc',
					name: 'III',
					valuePath: 'classiiiPrice',
					cellComponent: CellComponents.IntlNumberFormat,
					width: 40,
					componentArgs: {
						style: 'currency',
						currency: 'USD',
					},
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
				{
					id: '8aff7d48-1de9-4b43-a55c-e8b538504e6a',
					name: 'IV',
					valuePath: 'classivPrice',
					cellComponent: CellComponents.IntlNumberFormat,
					width: 40,
					componentArgs: {
						style: 'currency',
						currency: 'USD',
					},
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
			],
		},
		{
			id: '72b9d21c-aa67-4fcd-b6e2-781ab36d7279',
			name: 'Projected Prices',
			cellComponent: CellComponents.String,
			isFixed: '',
			isVisible: true,
			subcolumns: [
				{
					id: 'a75dcbc9-18ec-40f9-bb55-14279d2539f5',
					name: 'Blended',
					valuePath: 'blendedPrice',
					width: 70,
					cellComponent: CellComponents.IntlNumberFormat,
					componentArgs: {
						style: 'currency',
						currency: 'USD',
					},
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
				{
					id: '0f897dd9-af8e-40a5-850c-e19f5be4e233',
					name: 'Forecasted Basis',
					valuePath: 'everAgBasis',
					minWidth: 70,
					cellComponent: CellComponents.IntlNumberFormat,
					componentArgs: {
						style: 'currency',
						currency: 'USD',
					},
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
				{
					id: 'b9587719-5186-45fa-856d-c25f6c866963',
					name: 'Prem / Ded',
					valuePath: 'monthlyCalculatedAmountCWT',
					minWidth: 70,
					cellComponent: CellComponents.IntlNumberFormat,
					componentArgs: {
						style: 'currency',
						currency: 'USD',
					},
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
				{
					id: '016c2c88-e885-4ad9-8e3a-068c995f4a51',
					name: 'Mailbox',
					valuePath: 'mailboxPrice',
					minWidth: 70,
					cellComponent: CellComponents.IntlNumberFormat,
					componentArgs: {
						style: 'currency',
						currency: 'USD',
					},
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
			],
		},
	];

	queryParams = ['productionMonthStartDate', 'productionMonthEndDate', 'selectedLocationId'];

	productionMonthRangeOptions = [
		{
			displayName: 'Next 24 Months',
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().plus({ months: 23 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Previous 24 Months',
			startDate: DateTime.local().minus({ months: 24 }).startOf('month').toISODate(),
			endDate: DateTime.local().endOf('month').toISODate(),
		},
		{
			displayName: 'Next 12 Months',
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().plus({ months: 11 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Previous 12 Months',
			startDate: DateTime.local().minus({ months: 12 }).startOf('month').toISODate(),
			endDate: DateTime.local().endOf('month').toISODate(),
		},
		{
			displayName: `Calendar Year (${DateTime.local().year}) `,
			startDate: DateTime.local().startOf('year').toISODate(),
			endDate: DateTime.local().endOf('year').toISODate(),
		},
	];

	get currentDateRange() {
		return {
			startDate: DateTime.fromISO(this.productionMonthStartDate).toISODate(),
			endDate: DateTime.fromISO(this.productionMonthEndDate).toISODate(),
		};
	}

	get currentLocation(): LocationEntity | undefined {
		return this.model.getMilkCheckData.data?.Locations.find((location: LocationEntity) => location.id === this.selectedLocationId);
	}

	get initialCustomBasis() {
		return this.model.getMilkCheckData.data?.Locations.find((location: LocationEntity) => location.id === this.selectedLocationId)
			?.customBasis;
	}

	get initialRollingBasisMonths() {
		return this.model.getMilkCheckData.data?.Locations.find((location: LocationEntity) => location.id === this.selectedLocationId)
			?.rollingBasisNumberOfMonths;
	}

	get shouldIncludePremiumsAndDeductions() {
		// Only include premiums and deductions in two cases:
		//    - If a user has one location
		//    - If a user does not have a location selected (All)
		// Varying production amounts between locations will make these cwt values wonky.

		return (this.model.getMilkCheckData.data?.Locations.length ?? 0) === 1 || !this.selectedLocationId;
	}

	get milkChecks() {
		const monthObjects: IMonthObjects = {};
		const months = Interval.fromDateTimes(
			DateTime.fromISO(this.productionMonthStartDate).startOf('month'),
			DateTime.fromISO(this.productionMonthEndDate).endOf('month')
		)
			.splitBy({ month: 1 })
			.map((d) => d.start.toISODate());

		months.forEach((month) => {
			monthObjects[month] = {
				date: month,
				classiiiFuture: null,
				classivFuture: null,
				butterFuture: null,
				advancedNonfatDryMilkFuture: null,
				advancedButterFuture: null,
				advancedCheeseFuture: null,
				advancedDryWheyFuture: null,
				grossButterfatProduction: null,
				grossOtherSolidsProduction: null,
				grossProteinProduction: null,
				forecastedBlendedMilkPrice: null,
			};
		});

		this.model.getMilkCheckData.data?.ClassIIIFutures?.forEach((future: Future) => {
			const monthObject = monthObjects[future.displayExpiresAt];

			if (monthObject === undefined) return;

			monthObject.classiiiFuture = future;
		});

		this.model.getMilkCheckData.data?.ClassIVFutures?.forEach((future: Future) => {
			const monthObject = monthObjects[future.displayExpiresAt];

			if (monthObject === undefined) return;

			monthObject.classivFuture = future;
		});

		this.model.getMilkCheckData.data?.ButterFutures?.forEach((future: Future) => {
			const monthObject = monthObjects[future.displayExpiresAt];
			if (monthObject) {
				monthObject.butterFuture = future;
			}

			const advancedDate = DateTime.fromISO(future.displayExpiresAt).plus({ month: 1 }).toISODate();
			const advancedMonthObject = monthObjects[advancedDate];
			if (advancedMonthObject) {
				advancedMonthObject.advancedButterFuture = future;
			}
		});

		this.model.getMilkCheckData.data?.NonfatDryMilkFutures?.forEach((future: Future) => {
			const advancedDate = DateTime.fromISO(future.displayExpiresAt).plus({ month: 1 }).toISODate();
			const monthObject = monthObjects[advancedDate];

			if (monthObject === undefined) return;

			monthObject.advancedNonfatDryMilkFuture = future;
		});

		this.model.getMilkCheckData.data?.CheeseFutures?.forEach((future: Future) => {
			const advancedDate = DateTime.fromISO(future.displayExpiresAt).plus({ month: 1 }).toISODate();
			const monthObject = monthObjects[advancedDate];

			if (monthObject === undefined) return;

			monthObject.advancedCheeseFuture = future;
		});

		this.model.getMilkCheckData.data?.DryWheyFutures?.forEach((future: Future) => {
			const advancedDate = DateTime.fromISO(future.displayExpiresAt).plus({ month: 1 }).toISODate();
			const monthObject = monthObjects[advancedDate];

			if (monthObject === undefined) return;

			monthObject.advancedDryWheyFuture = future;
		});

		if (this.shouldIncludePremiumsAndDeductions) {
			this.model.getLedgerEntries.data?.AggregateLedgerForecastedEntries?.forEach((entry: AggregateLedgerForecastedEntryDTO) => {
				let dateObject = {};

				if (entry.year && entry.month) {
					dateObject = { year: entry.year, month: entry.month, day: 1 };
				}

				const formattedDate = DateTime.fromObject(dateObject).toISODate();
				const monthObject = monthObjects[formattedDate];

				if (monthObject == undefined) return;

				monthObject.monthlyCalculatedAmount = entry.sum.calculatedAmount;
			});
		}

		const monthlyWeightedPricesAndBasisValues = calculateMonthlyWeightedPricesAndBasisValues(
			this.productionMonthStartDate,
			this.productionMonthEndDate,
			this.model.getMilkCheckData.data?.ForecastedMilkProductionByMonths ?? [],
			this.model.getMilkCheckData.data?.ActualBlendedMilkPrices ?? []
		);

		const monthlyUtilizations = calculateUtilizationValuesFromForecastedProduction(
			this.model.getMilkCheckData.data?.ForecastedMilkProductionByMonths ?? []
		);

		const weightedProjectedValues = calculateMonthlyWeightedProjectedValues(
			this.model.getMilkCheckData.data?.ForecastedMilkProductionByMonths ?? []
		);

		this.model.getMilkCheckData.data?.ForecastedMilkProductionByMonths.forEach((production: ForecastedMilkProductionByMonth) => {
			const date = DateTime.fromISO(production.date).toISODate();
			const monthObject = monthObjects[date];
			if (monthObject) {
				monthObject.forecastedBlendedMilkPrice = weightedProjectedValues[date].blendedPrice ?? 0;
			}
		});

		let results = Object.entries(monthObjects).map(([date, monthObject]) => {
			const weightedPricesAndBasisValues = monthlyWeightedPricesAndBasisValues.find((v) => v.date === date);
			const utilizationValues = monthlyUtilizations.find((v) => v.date === date);

			return new MilkCheck(
				getOwner(this),
				date,
				weightedPricesAndBasisValues?.locationCurrentBasis ?? 0,
				{
					classiUtilization: utilizationValues?.classIUtilization ?? null,
					classiiUtilization: utilizationValues?.classIIUtilization ?? null,
					classiiiUtilization: utilizationValues?.classIIIUtilization ?? null,
					classivUtilization: utilizationValues?.classIVUtilization ?? null,
				} as ForecastedMilkUtilization,
				monthObject.classiiiFuture,
				monthObject.classivFuture,
				monthObject.butterFuture,
				monthObject.advancedNonfatDryMilkFuture,
				monthObject.advancedButterFuture,
				monthObject.advancedCheeseFuture,
				monthObject.advancedDryWheyFuture,
				utilizationValues?.classIDifferential ?? 0,
				monthObject.monthlyCalculatedAmount,
				{ sum: { grossProduction: weightedPricesAndBasisValues?.grossProduction ?? 0 } } as AggregateForecastedMilkProductionByMonthDTO,
				utilizationValues?.otherSolidsPercentage ?? 0,
				utilizationValues?.butterfatPercentage ?? 0,
				utilizationValues?.proteinPercentage ?? 0,
				monthObject?.forecastedBlendedMilkPrice ?? 0
			);
		});

		results = results.filter((v): v is MilkCheck => !!v);

		return results;
	}

	get selectedRows(): MilkCheck[] | null {
		return this.selectedLocationId ? this._selectedRows : null;
	}

	set selectedRows(value: MilkCheck[] | null) {
		this._selectedRows = value;
	}

	@action
	setProductionMonthQueryParam(value: { startDate: string; endDate: string }) {
		this.productionMonthStartDate = value.startDate;
		this.productionMonthEndDate = value.endDate;
	}

	@action
	setSelectedLocationId(id: string, callback: () => void) {
		if (id !== this.selectedLocationId) {
			this.selectedLocationId = id;
			this.clearSelectedRows();
		}
		callback();
	}

	@action
	setInitialSelectedLocationId() {
		this.selectedLocationId =
			this.model.getMilkCheckData.data?.Locations.length === 1 ? this.model.getMilkCheckData.data.Locations[0].id : '';
	}

	clearSelectedRows() {
		this._selectedRows = [];
	}

	@action
	clear() {
		this.selectedCounty = null;
		this.selectedLocationId = '';
		this.selectedMilkOrder = null;
	}

	@action
	async submit() {
		if (!this.selectedCounty || !this.selectedCounty.id || !this.selectedCounty.name) {
			console.warn('County not selected');
			return;
		}

		const createLocation = useMutation<CreateLocationMutation, Mutation_createLocationArgs>(this, () => [
			CREATE_LOCATION,
			{
				onComplete: (data): void => {
					this.selectedLocationId = data?.createLocation.id || '';
				},
			},
		]);

		const data: LocationEntityCreateDTO = {
			countyId: this.selectedCounty.id,
			customerId: this.model.businessId,
			milkOrderId: this.selectedMilkOrder?.id ?? null,
			name: this.selectedCounty.name,
		};

		await createLocation.mutate({ data });

		this.model.getMilkCheckData.refetch();
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'businesses/business/milk-check': BusinessesBusinessMilkCheck;
	}
}
