import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

import { inject as service } from '@ember/service';
import { queryManager } from 'ember-apollo-client';
import searchQuery from 'vault-client/graphql/queries/search.graphql';
import accountFuzzyQuery from 'vault-client/graphql/queries/account-fuzzy-match.graphql';
import { DateTime } from 'luxon';
import getFilterDisplayProperty from '../../utils/get-filter-display-property';
import resetVaultTableScroll from 'vault-client/utils/reset-vault-table-scroll';
import instrumentOptionTypes from 'vault-client/utils/instrument-option-types';
import { getOwner } from '@ember/application';
import FutureTransaction from 'vault-client/models/future-transaction';
import OptionTransaction from 'vault-client/models/option-transaction';
import SwapTransaction from 'vault-client/models/swap-transaction';
import SwaptionTransaction from 'vault-client/models/swaption-transaction';
import { GET_TRANSACTIONS } from 'vault-client/routes/transactions';
import BusinessesTransactions from 'vault-client/routes/businesses/business/transactions';
import OrganizationsTransactions from 'vault-client/routes/organizations/organization/transactions';
import { ModelFrom } from 'vault-client/utils/type-utils';
import { Account, Query, TypeOfAccount } from 'vault-client/types/graphql-types';
import { gql, useQuery } from 'glimmer-apollo';

const SALES_CODE_SEARCH = gql`
	query BrokerageAccounts($searchText: String!, $scopeId: String!) {
		BrokerageAccounts(scopeId: $scopeId, where: { salesCode: { contains: $searchText } }) {
			id
			salesCode
		}
	}
`;

export default class TransactionsIndexController extends Controller {
	@queryManager apollo: any;
	@service permissions: any;
	@service applicationScope: any;

	declare model: ModelFrom<OrganizationsTransactions> | ModelFrom<BusinessesTransactions>;

	query = GET_TRANSACTIONS;

	queryParams = [
		'accountId',
		'instrumentId',
		'customerId',
		'productId',
		'salesCode',
		'instrumentType',
		'optionType',
		'tradeDateStartDate',
		'tradeDateEndDate',
		'expMonthStartDate',
		'expMonthEndDate',
		'side',
		'page',
		'sorts',
		'size',
	];

	itemsFn = (rows: any) => {
		const owner = getOwner(this);
		return rows.map((row: any) => {
			if (row.Instrument.__typename === 'Future') {
				return new FutureTransaction(owner, row, null, null);
			}

			if (row.Instrument.__typename === 'Option') {
				//@ts-ignore
				return new OptionTransaction(owner, row, null, null);
			}

			if (row.Instrument.__typename === 'Swap') {
				return new SwapTransaction(owner, row, null, null);
			}

			if (row.Instrument.__typename === 'Swaption') {
				return new SwaptionTransaction(owner, row, null, null);
			}
			return;
		});
	};

	tradeDateRangeOptions = [
		{ displayName: 'All Days', startDate: '1900-01-01', endDate: '2999-12-31' },
		{
			displayName: 'Previous 7 Days',
			startDate: DateTime.local().minus({ weeks: 1 }).toISODate(),
			endDate: DateTime.local().toISODate(),
		},
		{
			displayName: 'Previous 30 Days',
			startDate: DateTime.local().minus({ months: 1 }).toISODate(),
			endDate: DateTime.local().toISODate(),
		},
		{
			displayName: 'Previous 90 Days',
			startDate: DateTime.local().minus({ months: 3 }).toISODate(),
			endDate: DateTime.local().toISODate(),
		},
		{
			displayName: 'Previous 12 Months',
			startDate: DateTime.local().minus({ months: 12 }).startOf('month').toISODate(),
			endDate: DateTime.local().toISODate(),
		},
		{
			displayName: 'Previous 24 Months',
			startDate: DateTime.local().minus({ months: 24 }).startOf('month').toISODate(),
			endDate: DateTime.local().toISODate(),
		},
	];

	expMonthRangeOptions = [
		{ displayName: 'All Months', startDate: '1900-01-01', endDate: '2999-12-31' },
		{
			displayName: 'Last Month',
			startDate: DateTime.local().minus({ months: 1 }).startOf('month').toISODate(),
			endDate: DateTime.local().minus({ months: 1 }).endOf('month').toISODate(),
		},
		{
			displayName: 'This Month',
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().endOf('month').toISODate(),
		},
		{
			displayName: 'Next Month',
			startDate: DateTime.local().plus({ months: 1 }).startOf('month').toISODate(),
			endDate: DateTime.local().plus({ months: 1 }).endOf('month').toISODate(),
		},
	];

	@tracked accountId: string | undefined;
	@tracked productId: string | undefined;
	@tracked instrumentId: string | undefined;
	@tracked salesCode: string | undefined;
	@tracked instrumentType = null;
	@tracked optionType = null;
	@tracked side = null;
	@tracked tradeDateStartDate = '1900-01-01';
	@tracked tradeDateEndDate = '2999-12-31';
	@tracked expMonthStartDate = '1900-01-01';
	@tracked expMonthEndDate = '2999-12-31';
	@tracked currentScope = '';
	@tracked page = 0;
	@tracked size = 100;
	@tracked sorts = [{ valuePath: 'tradeDate', isAscending: false }];
	@tracked instrumentOptionTypes = instrumentOptionTypes;
	transactionShowRoute = '';
	accountShowRoute = '';

	get columns() {
		const baseColumns = [
			{
				id: '16e04fc4-feca-465b-8129-01bc1b718297',
				name: 'Account Name',
				valuePath: 'Account.name',
				minWidth: 250,
				cellComponent: 'String',
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: 'c6f4c82e-bea0-49a3-86dc-54bc5a07dde7',
				name: 'Sales Code',
				valuePath: 'Account.salesCode',
				minWidth: 125,
				cellComponent: 'String',
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: '6884625e-8d49-4267-b062-742a7d043667',
				name: 'Trade Day',
				valuePath: 'tradeDate',
				minWidth: 120,
				textAlign: 'left',
				isSortable: true,
				cellComponent: 'String',
				isFixed: '',
				isVisible: true,
				linkRoute: this.transactionShowRoute,
				linkModelPath: 'id',
			},
			{
				id: 'efe92c73-87fa-4cb6-ae72-02765eed297c',
				name: 'Account',
				valuePath: 'Account.accountNumber',
				minWidth: 100,
				textAlign: 'left',
				isSortable: false,
				cellComponent: 'String',
				isFixed: '',
				isVisible: true,
				linkRoute: this.accountShowRoute,
				linkModelPath: 'Account.id',
			},
			{
				id: '1bb741b0-cdce-42e0-a70a-6574844f3517',
				name: 'Commodity',
				valuePath: 'Instrument.Product.name',
				minWidth: 210,
				textAlign: 'left',
				isSortable: false,
				cellComponent: 'String',
				isFixed: '',
				isVisible: true,
			},
			{
				id: '34064e74-a95d-415b-8f3f-2795110e9450',
				name: 'Exp Month',
				valuePath: 'Instrument.displayExpiresAt',
				minWidth: 120,
				cellComponent: 'MonthFormat',
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '5ae34212-6c94-40b0-80d3-46231ddbbfe9',
				name: 'B / S',
				valuePath: 'side',
				minWidth: 80,
				textAlign: 'left',
				isSortable: false,
				cellComponent: 'String',
				isFixed: '',
				isVisible: true,
			},
			{
				id: '29da2860-aeee-4c7c-b758-33e57646f40b',
				name: 'Contracts',
				valuePath: 'quantityInContracts',
				minWidth: 110,
				textAlign: 'right',
				isSortable: false,
				cellComponent: 'String',
				isFixed: '',
				isVisible: true,
			},
			{
				id: '51869204-c07a-47da-b055-03a15b2c9d27',
				name: 'ABS Quantity',
				valuePath: 'absQuantityInContracts',
				minWidth: 115,
				textAlign: 'right',
				isSortable: false,
				cellComponent: 'String',
				isFixed: '',
				isVisible: false,
			},
			{
				id: 'dd42d708-0443-4f76-8ec0-447afbd1e91a',
				name: 'Type',
				valuePath: 'Instrument.instrumentType',
				minWidth: 90,
				textAlign: 'left',
				isSortable: false,
				cellComponent: 'String',
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'f5544f60-54b9-4cbc-a4a0-80e4dc693723',
				name: 'Symbol',
				valuePath: 'Instrument.exchangeSymbol',
				minWidth: 120,
				textAlign: 'left',
				isSortable: false,
				cellComponent: 'String',
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'b6e1fa48-ef1e-4131-a468-a3ecfeb704da',
				name: 'Strike',
				valuePath: 'Instrument.strike',
				minWidth: 80,
				cellComponent: 'PriceFormat',
				componentArgs: {
					fractionDigitsPath: 'Instrument.symbolGroup.fractionDigits',
					displayFactorPath: 'Instrument.symbolGroup.displayFactor',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '81b751cf-be21-41a0-baf2-3ee0adac938a',
				name: 'Trade Price',
				valuePath: 'price',
				minWidth: 130,
				cellComponent: 'PriceFormat',
				componentArgs: {
					fractionDigitsPath: 'Instrument.symbolGroup.fractionDigits',
					displayFactorPath: 'Instrument.symbolGroup.displayFactor',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '780540cb-693a-4777-84cd-2c28e1debeb9',
				name: 'Commission',
				valuePath: 'commission',
				minWidth: 120,
				cellComponent: 'IntlNumberFormat',
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '04a0d7fe-104a-4d8f-b070-120c87420f82',
				name: 'Fees',
				valuePath: 'nonCommissionFees',
				minWidth: 100,
				cellComponent: 'IntlNumberFormat',
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '0fc0293d-5e5c-4b94-aa62-a2a365ea4444',
				name: 'Gross P/L',
				valuePath: 'grossPl',
				minWidth: 120,
				cellComponent: 'IntlNumberFormat',
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'acf39a72-d1fe-4bf3-9afe-e67daf3162fa',
				name: 'Net P/L',
				valuePath: 'netPl',
				minWidth: 120,
				cellComponent: 'IntlNumberFormat',
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
		];
		return baseColumns;
	}

	get sortParams() {
		return this.sorts;
	}

	set sortParams(sorts) {
		this.sorts = sorts;
	}

	get currentPage() {
		return this.page;
	}

	set currentPage(page) {
		this.page = page;
	}

	get transactionData() {
		return this.itemsFn(this.model.getTransactions.data?.Transactions ?? []);
	}

	get totalNumTransactions(): number {
		return this.model.getTransactions.data?.TransactionCount.count ?? 0;
	}

	get searchFilterQueryParams() {
		const obj: {
			[key: string]: any;
		} = {};
		const searchQueryParams = ['customerId', 'accountId', 'instrumentId', 'productId', 'salesCode'];
		searchQueryParams.forEach((param) => {
			// if applicable setting filter for customerId for customer scope
			// @ts-ignore
			const value = param === 'customerId' && this.applicationScope.globalCustomerId ? this.applicationScope.globalCustomerId : this[param];

			if (value) {
				obj[param] = {
					filterRule: 'equals',
					filterValue: value,
				};

				// set filterComponent property to specify component for custom display extended from search-filter
				const filterDisplayObj = getFilterDisplayProperty(param);
				if (filterDisplayObj && filterDisplayObj.customComponent) {
					obj[param].filterComponent = filterDisplayObj.customComponent;
				}
				// set filterLabel property to specify custom label for filter - default would be filterIdentifier (matches queryParam)
				if (filterDisplayObj && filterDisplayObj.label) {
					obj[param].filterLabel = filterDisplayObj.label;
				}
			}
		});

		return obj;
	}

	get scopedSearchFilterQueryParams() {
		if (this.applicationScope.globalCustomerId) {
			// @ts-ignore
			// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
			const { customerId, ...rest } = this.searchFilterQueryParams;
			return rest;
		} else {
			return this.searchFilterQueryParams;
		}
	}

	get currentInstrumentOptionType() {
		return this.instrumentOptionTypes.find((type) => type.instrumentType === this.instrumentType && type.optionType === this.optionType);
	}

	get tradeDateQueryParam() {
		return {
			startDate: this.tradeDateStartDate,
			endDate: this.tradeDateEndDate,
		};
	}

	get expMonthQueryParam() {
		return {
			startDate: this.expMonthStartDate,
			endDate: this.expMonthEndDate,
		};
	}

	@action
	async fetchSearchResults(searchText: string) {
		const variables = {
			query: searchText,
			typesToInclude: [
				'BasisInstrument',
				'CashInstrument',
				'CommitmentInstrument',
				'Future',
				'Option',
				'PhysicalInstrument',
				'SpreadInstrument',
				'SwapInstrument',
				'SwaptionInstrument',
				'Product',
			],
		};
		let scopedAccountResults = [];

		if (!this.applicationScope.globalCustomerId) {
			variables.typesToInclude = [...variables.typesToInclude, 'BrokerageAccount', 'SwapAccount'];
		} else {
			const accountVariables = {
				where: {
					customerId: { equals: this.applicationScope.globalCustomerId },
					OR: [
						{
							accountNumber: { contains: searchText },
						},
						{
							name: { contains: searchText },
						},
						{
							id: { contains: searchText },
						},
					],
				},
			};

			scopedAccountResults = (
				await this.apollo.watchQuery({
					query: accountFuzzyQuery,
					variables: accountVariables,
				})
			).Accounts.map((account: Account) => {
				const newObj = {
					id: account.id,
					name: account.name,
					// does this encapsulate all accounts where type is different than would see in search api?
					type: account.type === TypeOfAccount.Brokerage || account.type === TypeOfAccount.Swap ? account.__typename : account.type,
					accountNumber: account.accountNumber,
				};
				return newObj;
			});
		}

		let salesCodeResults: { id: string; name: string; type: string }[] = [];

		await useQuery<{ BrokerageAccounts: Query['BrokerageAccounts'] }, { searchText: string; scopeId: string }>(this, () => [
			SALES_CODE_SEARCH,
			{
				variables: {
					searchText,
					scopeId: this.model.scopeId,
				},
				onComplete: (data) => {
					const uniqueSalesCodes = [
						...(data?.BrokerageAccounts.reduce((acc, account) => {
							const salesCode = account.salesCode;
							if (salesCode) {
								acc.add(salesCode);
							}

							return acc;
						}, new Set<string>()) || []),
					];

					salesCodeResults = uniqueSalesCodes.map((salesCode) => ({
						id: salesCode,
						name: salesCode,
						type: 'SalesCode',
					}));
				},
			},
		]).promise;

		const searchApiResults = (await this.apollo.watchQuery({ query: searchQuery, variables })).Search;

		return [...searchApiResults, ...scopedAccountResults, ...salesCodeResults].sort((a, b) => {
			if (a.type < b.type) {
				return -1;
			} else if (a.type > b.type) {
				return 1;
			} else {
				return 0;
			}
		});
	}

	@action
	structureSearchResults(searchResults: any) {
		const map = new Map();

		// Move products to the front of the array so they are displayed above the other types
		const sortedSearchResults = searchResults.slice().sort((a: any, b: any) => {
			if (a.type === 'Product') {
				return -1;
			} else if (b.type === 'Product') {
				return 1;
			}
			return 0;
		});

		sortedSearchResults.forEach((item: any) => {
			if (map.has(item.type)) {
				map.get(item.type).push({
					id: item.id,
					name: item.name,
					type: item.type,
					accountNumber: item.accountNumber ?? null,
				});
			} else {
				map.set(item.type, [
					{
						id: item.id,
						name: item.name,
						type: item.type,
						accountNumber: item.accountNumber ?? null,
					},
				]);
			}
		});

		return map;
	}

	@action
	setSearchFilterQueryParam(searchResult: any) {
		const mappedSearchFilter = this.mapSearchResult(searchResult);
		//@ts-ignore
		this[mappedSearchFilter.filterIdentifier] = mappedSearchFilter.filterValue;
		this.setTablePageState();
	}

	mapSearchResult(searchResult: any) {
		let filterIdentifier;

		switch (searchResult.type) {
			case 'BasisInstrument':
				filterIdentifier = 'instrumentId';
				break;
			case 'BrokerageAccount':
				filterIdentifier = 'accountId';
				break;
			case 'CashInstrument':
				filterIdentifier = 'instrumentId';
				break;
			case 'CommitmentInstrument':
				filterIdentifier = 'instrumentId';
				break;
			case 'Future':
				filterIdentifier = 'instrumentId';
				break;
			case 'Option':
				filterIdentifier = 'instrumentId';
				break;
			case 'PhysicalInstrument':
				filterIdentifier = 'instrumentId';
				break;
			case 'SpreadInstrument':
				filterIdentifier = 'instrumentId';
				break;
			case 'SwapAccount':
				filterIdentifier = 'accountId';
				break;
			case 'SwapInstrument':
				filterIdentifier = 'instrumentId';
				break;
			case 'SwaptionInstrument':
				filterIdentifier = 'instrumentId';
				break;
			case 'Product':
				filterIdentifier = 'productId';
				break;
			case 'SalesCode':
				filterIdentifier = 'salesCode';
				break;
		}

		return {
			filterIdentifier,
			filterValue: searchResult.id,
		};
	}

	@action
	clearSearchFilterQueryParam(filterIdentifier: string) {
		//@ts-ignore
		this[`${filterIdentifier}`] = null;
		this.setTablePageState();
	}

	@action
	setTradeDateQueryParam(value: { startDate: string; endDate: string }) {
		this.tradeDateStartDate = value.startDate;
		this.tradeDateEndDate = value.endDate;
		this.setTablePageState();
	}

	@action
	setExpMonthQueryParam(value: { startDate: string; endDate: string }) {
		this.expMonthStartDate = value.startDate;
		this.expMonthEndDate = value.endDate;
		this.setTablePageState();
	}

	@action
	setTablePageState(newPageVal = 0) {
		this.currentPage = newPageVal;
		resetVaultTableScroll('transactions-table');
	}

	@action
	setInstrumentOptionType(instrumentType: any, optionType: any, callback: any) {
		callback();
		this.instrumentType = instrumentType;
		this.optionType = optionType;
	}

	@action
	setTransactionsSideSelect(transactionsSideType: any, callback: any) {
		callback();
		this.side = transactionsSideType;
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'transactions-index-controller': TransactionsIndexController;
	}
}
