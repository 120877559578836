import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if (has-block)}}\n\t{{yield this.netChange this.percentChange}}\n{{else}}\n\t{{#if this.hasChange}}\n\t\t{{#if this.hasPositiveChange}}\n\t\t\t<span class='text-brand-success-50'>\n\t\t\t\t<Price @price={{this.netChange}} @displayFactor={{@displayFactor}} @fractionDigits={{@fractionDigits}} />\n\t\t\t\t(<IntlNumberFormat @value={{this.percentChange}} @style='percent' @minimumFractionDigits='2' />)\n\t\t\t\t<FaIcon @icon='chevron-up' />\n\t\t\t</span>\n\t\t{{else}}\n\t\t\t<span class='text-brand-error-50'>\n\t\t\t\t<Price @price={{this.netChange}} @displayFactor={{@displayFactor}} @fractionDigits={{@fractionDigits}} />\n\t\t\t\t(<IntlNumberFormat @value={{this.percentChange}} @style='percent' @minimumFractionDigits='2' />)\n\t\t\t\t<FaIcon @icon='chevron-down' />\n\t\t\t</span>\n\t\t{{/if}}\n\t{{else}}\n\t\t-\n\t{{/if}}\n{{/if}}", {"contents":"{{#if (has-block)}}\n\t{{yield this.netChange this.percentChange}}\n{{else}}\n\t{{#if this.hasChange}}\n\t\t{{#if this.hasPositiveChange}}\n\t\t\t<span class='text-brand-success-50'>\n\t\t\t\t<Price @price={{this.netChange}} @displayFactor={{@displayFactor}} @fractionDigits={{@fractionDigits}} />\n\t\t\t\t(<IntlNumberFormat @value={{this.percentChange}} @style='percent' @minimumFractionDigits='2' />)\n\t\t\t\t<FaIcon @icon='chevron-up' />\n\t\t\t</span>\n\t\t{{else}}\n\t\t\t<span class='text-brand-error-50'>\n\t\t\t\t<Price @price={{this.netChange}} @displayFactor={{@displayFactor}} @fractionDigits={{@fractionDigits}} />\n\t\t\t\t(<IntlNumberFormat @value={{this.percentChange}} @style='percent' @minimumFractionDigits='2' />)\n\t\t\t\t<FaIcon @icon='chevron-down' />\n\t\t\t</span>\n\t\t{{/if}}\n\t{{else}}\n\t\t-\n\t{{/if}}\n{{/if}}","moduleName":"vault-client/components/session-change.hbs","parseOptions":{"srcName":"vault-client/components/session-change.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

interface Args {
	symbol: any;
	priceStyle?: Intl.NumberFormatOptions['style'];
	priceCurrency?: Intl.NumberFormatOptions['currency'];
}

export default class SessionChangeComponent extends Component<Args> {
	@service marketData: any;

	constructor(owner: any, args: any) {
		super(owner, args);

		if (this.args.symbol) {
			this.marketData.register(this.args.symbol);
		}
	}

	get netChange() {
		const marketDatum = this.marketData.getMarketDatum(this.args.symbol);

		if (marketDatum) {
			return marketDatum.netChange;
		}

		return null;
	}

	get percentChange() {
		const marketDatum = this.marketData.getMarketDatum(this.args.symbol);

		if (marketDatum) {
			return marketDatum.percentChange / 100;
		}

		return null;
	}

	get hasChange() {
		return this.netChange > 0 || this.netChange < 0;
	}

	get hasPositiveChange() {
		return this.netChange > 0;
	}

	willDestroy() {
		super.willDestroy();
		if (this.args.symbol) {
			this.marketData.unregister(this.args.symbol);
		}
	}
}

// export default Component.extend({
// 	netChange: null, percentChange: null, fractionalDecimals: null,

// 	// This is only needed when we aren't getting the price from the formatted instrument prices.
// 	// Currently, this is needed for spot prices.
// 	formattedNetChange: computed('netChange', function () {
// 			if (this.fractionalDecimals) {
// 					return Intl.NumberFormat('en-US', {
// 							minimumFractionDigits: this.fractionalDecimals,
// 							maximumFractionDigits: this.fractionalDecimals
// 					}).format(this.netChange);
// 			} else {
// 					return this.netChange;
// 			}
// 	}),

// });
