import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n\tclass='accessibility-toggle flex row align-middle px-4 py-2.5 text-brand-gray-70 border-b border-brand-gray-40 bg-brand-white tool-tip-reveal'\n\taria-describedby='accessibility-toggle-tooltip'\n>\n\t<label id='accessibility-toggle-label' class='mr-2 font-sans-semibold text-base'>\n\t\tAccessibility Mode\n\t</label>\n\t<UiToggle @selected={{this.enabled}} {{on 'click' this.toggleAccessibilityMode}} />\n\t<Vault::UiToolTip\n\t\t@id='accessibility-toggle-tooltip'\n\t\taria-hidden='true'\n\t\tclass='top-9 left-0 !text-base'\n\t\t@text={{this.accessibilityTooltipText}}\n\t/>\n</div>", {"contents":"<div\n\tclass='accessibility-toggle flex row align-middle px-4 py-2.5 text-brand-gray-70 border-b border-brand-gray-40 bg-brand-white tool-tip-reveal'\n\taria-describedby='accessibility-toggle-tooltip'\n>\n\t<label id='accessibility-toggle-label' class='mr-2 font-sans-semibold text-base'>\n\t\tAccessibility Mode\n\t</label>\n\t<UiToggle @selected={{this.enabled}} {{on 'click' this.toggleAccessibilityMode}} />\n\t<Vault::UiToolTip\n\t\t@id='accessibility-toggle-tooltip'\n\t\taria-hidden='true'\n\t\tclass='top-9 left-0 !text-base'\n\t\t@text={{this.accessibilityTooltipText}}\n\t/>\n</div>","moduleName":"vault-client/components/accessibility-toggle.hbs","parseOptions":{"srcName":"vault-client/components/accessibility-toggle.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import AccessibilityService from 'vault-client/services/accessibility';
import { analyticsCustomTracking } from 'vault-client/utils/analytics-tracking';

export default class AccessibilityToggleComponent extends Component {
	@service declare accessibility: AccessibilityService;
	@tracked enabled: boolean;

	constructor(owner: unknown, args: Object) {
		super(owner, args);
		this.enabled = this.accessibility.isAccessibilityModeEnabled;
	}

	@action
	toggleAccessibilityMode() {
		this.accessibility.toggleAccessibilityMode();

		if (this.accessibility.isAccessibilityModeEnabled) {
			analyticsCustomTracking('Accessibility Mode Enabled', {});
		} else {
			analyticsCustomTracking('Accessibility Mode Disabled', {});
		}
	}

	get accessibilityTooltipText() {
		if (this.enabled) {
			return 'Accessibility mode is on. Turn off to return to default view.';
		} else {
			return 'Accessibility mode is off. Turn on to make tables easier to read.';
		}
	}
}
