import CropFillsIndexController from 'vault-client/controllers/crop-fills';

export default class OrganizationsOrganizationCropFillsIndexController extends CropFillsIndexController {
	marketingRoutePath: string = 'organizations.organization.marketing';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'organizations/organization/crop-fills': OrganizationsOrganizationCropFillsIndexController;
	}
}
