import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { Future, LrpInsuranceEndorsement, Product, Query } from 'vault-client/types/graphql-types';
import { gql, useQuery } from 'glimmer-apollo';
import DeltaService from 'vault-client/services/delta';
import { DateTime } from 'luxon';
import { setOwner } from '@ember/application';
import { task } from 'ember-concurrency';

// This query fetches the current futures for a product
export const CURRENT_FUTURES = gql`
	query currentFutures($id: String!) {
		Product(id: $id) {
			id
			CurrentFutures {
				id
				expiresAt
			}
			StandardProductLotSpecification {
				id
				lotSize
			}
		}
	}
`;

type CurrentFuturesQuery = {
	Product: Query['Product'];
};

type CurrentFuturesQueryVariables = {
	id: string;
};

// This query fetches the two put options that are closest to the coverage price
export const NEAR_OPTIONS = gql`
	query nearOptions($underlyingInstrumentId: String!, $coveragePrice: Float!) {
		topOption: Options(
			where: { underlyingInstrumentId: { equals: $underlyingInstrumentId }, strike: { gte: $coveragePrice }, optionType: { equals: Put } }
			limit: 1
			orderBy: { strike: Asc }
		) {
			id
			barchartSymbol
			strike
			expiresAt
		}
		bottomOption: Options(
			where: { underlyingInstrumentId: { equals: $underlyingInstrumentId }, strike: { lte: $coveragePrice }, optionType: { equals: Put } }
			limit: 1
			orderBy: { strike: Desc }
		) {
			id
			barchartSymbol
			strike
			expiresAt
		}
	}
`;

type NearOptionsQuery = {
	topOption: Query['Options'];
	bottomOption: Query['Options'];
};

type NearOptionsQueryVariables = {
	underlyingInstrumentId?: string;
	coveragePrice: number;
};

export default class LrpEndorsementModel {
	@service('delta') declare deltaService: DeltaService;

	@tracked endorsement: LrpInsuranceEndorsement;
	@tracked product: Product | undefined;
	@tracked productFromModelClass: Product | null = null;
	@tracked currentFutures: Future[] = [];
	@tracked frontOptions: NearOptionsQuery | null = null;
	@tracked backOptions: NearOptionsQuery | null = null;

	constructor(owner: any, endorsement: LrpInsuranceEndorsement, product?: Product | undefined) {
		setOwner(this, owner);
		this.endorsement = endorsement;
		this.product = product;
		this.retrieveData.perform();
	}

	retrieveData = task(async () => {
		await this.fetchCurrentFutures.perform();
		await Promise.all([this.fetchFrontOptions.perform(), this.fetchBackOptions.perform()]);
	});

	fetchCurrentFutures = task(async () => {
		// Fetch the current futures, so that we know which options are closest to the endorsement's end date
		const currentFuturesQuery = useQuery<CurrentFuturesQuery, CurrentFuturesQueryVariables>(this, () => [
			CURRENT_FUTURES,
			{
				variables: {
					id: this.endorsement.revenueHedgeProductId,
				},
				onComplete: (response): void => {
					this.currentFutures = response?.Product?.CurrentFutures ?? [];
					this.productFromModelClass = response?.Product ?? null;
				},
				onError: (error): void => {
					console.error('Error fetching front options');
					console.error(error);
				},
			},
		]);

		await currentFuturesQuery.promise;
	});

	fetchFrontOptions = task(async () => {
		const frontFutureId = this.frontFuture?.id;
		if (!frontFutureId) {
			console.warn('No front future Id found for endorsement');
			return;
		}

		const coveragePrice = this.endorsement.coveragePrice;
		if (!coveragePrice) {
			console.warn('No coverage price found for endorsement');
			return;
		}

		// Fetch the put options that are closest to the coverage price for the front future
		const frontOptionsQuery = useQuery<NearOptionsQuery, NearOptionsQueryVariables>(this, () => [
			NEAR_OPTIONS,
			{
				variables: {
					underlyingInstrumentId: frontFutureId,
					coveragePrice: coveragePrice,
				},
				onComplete: (response): void => {
					const topOptionSymbol = response?.topOption?.firstObject?.barchartSymbol;
					const bottomOptionSymbol = response?.bottomOption?.firstObject?.barchartSymbol;

					if (topOptionSymbol) {
						this.deltaService.register(topOptionSymbol);
					}

					if (bottomOptionSymbol) {
						this.deltaService.register(bottomOptionSymbol);
					}

					this.frontOptions = response ?? null;
				},
				onError: (error): void => {
					console.error('Error fetching front options');
					console.error(error);
				},
			},
		]);

		await frontOptionsQuery.promise;
	});

	fetchBackOptions = task(async () => {
		const backFutureId = this.backFuture?.id;
		if (!backFutureId) {
			console.warn('No front future Id found for endorsement');
			return;
		}

		const coveragePrice = this.endorsement.coveragePrice;
		if (!coveragePrice) {
			console.warn('No coverage price found for endorsement');
			return;
		}

		// Fetch the put options that are closest to the coverage price for the back future
		const backOptions = useQuery<NearOptionsQuery, NearOptionsQueryVariables>(this, () => [
			NEAR_OPTIONS,
			{
				variables: {
					underlyingInstrumentId: this.backFuture?.id || '',
					coveragePrice: this.endorsement.coveragePrice,
				},
				onComplete: (response): void => {
					const topOptionSymbol = response?.topOption?.firstObject?.barchartSymbol;
					const bottomOptionSymbol = response?.bottomOption?.firstObject?.barchartSymbol;

					if (topOptionSymbol) {
						this.deltaService.register(topOptionSymbol);
					}

					if (bottomOptionSymbol) {
						this.deltaService.register(bottomOptionSymbol);
					}

					this.backOptions = response ?? null;
				},
			},
		]);

		await backOptions.promise;
	});

	// Get the future that is closest to the endorsement's end date, but not after it
	get frontFuture() {
		// Get the closest future to the endorsement's end date, but not after it
		const futures = this.currentFutures;
		if (!futures) {
			return null;
		}
		const sortedFutures = futures.sortBy('expiresAt').reverse();

		const future = sortedFutures.find((future) => future.expiresAt <= this.endorsement.coverageEndDate);
		return future ?? null;
	}

	// Get the future that is closest to the endorsement's end date, but not before it
	get backFuture() {
		const futures = this.currentFutures;
		if (!futures) {
			return null;
		}
		const sortedFutures = futures.sortBy('expiresAt');

		const future = sortedFutures.find((future) => future.expiresAt >= this.endorsement.coverageEndDate);
		return future ?? null;
	}

	// Get the symbols for the options that are closest to the coverage price for the front and back futures
	get optionSymbols() {
		return [
			this.frontOptions?.topOption?.firstObject?.barchartSymbol,
			this.frontOptions?.bottomOption?.firstObject?.barchartSymbol,
			this.backOptions?.topOption?.firstObject?.barchartSymbol,
			this.backOptions?.bottomOption?.firstObject?.barchartSymbol,
		].filter(Boolean);
	}

	// Calculate the delta for the endorsement, assuming a quantity equal to a standard contract size.
	get contractDelta() {
		// // Front and Back Future are requird for the delta calculation. If either is not present, return null.
		// if (!this.frontFuture || !this.backFuture) return null;

		const frontOptionExpiresAt =
			this.frontOptions?.topOption?.firstObject?.expiresAt || this.frontOptions?.bottomOption?.firstObject?.expiresAt;
		const backOptionExpiresAt =
			this.backOptions?.topOption?.firstObject?.expiresAt || this.backOptions?.bottomOption?.firstObject?.expiresAt;
		const endorsementEndDate = DateTime.fromISO(this.endorsement.coverageEndDate);

		const frontOptionExpiresAtDate = frontOptionExpiresAt ? DateTime.fromISO(frontOptionExpiresAt) : null;
		const backOptionExpiresAtDate = backOptionExpiresAt ? DateTime.fromISO(backOptionExpiresAt) : null;

		const frontTopDelta = this.deltaService.getDelta(this.frontOptions?.topOption?.firstObject?.barchartSymbol || '');
		const frontBottomDelta = this.deltaService.getDelta(this.frontOptions?.bottomOption?.firstObject?.barchartSymbol || '');

		const backTopDelta = this.deltaService.getDelta(this.backOptions?.topOption?.firstObject?.barchartSymbol || '');
		const backBottomDelta = this.deltaService.getDelta(this.backOptions?.bottomOption?.firstObject?.barchartSymbol || '');

		const frontBottomStrike = this.frontOptions?.bottomOption?.firstObject?.strike;
		const frontTopStrike = this.frontOptions?.topOption?.firstObject?.strike;

		const backBottomStrike = this.backOptions?.bottomOption?.firstObject?.strike;
		const backTopStrike = this.backOptions?.topOption?.firstObject?.strike;

		const frontBottomDeltaWeight = frontBottomStrike ? this.endorsement.coveragePrice - frontBottomStrike : null;
		const frontTopDeltaWeight = frontTopStrike ? frontTopStrike - this.endorsement.coveragePrice : null;

		const backBottomDeltaWeight = backBottomStrike ? this.endorsement.coveragePrice - backBottomStrike : null;
		const backTopDeltaWeight = backTopStrike ? backTopStrike - this.endorsement.coveragePrice : null;

		// Calculate the weighted average of the deltas for the front and back options
		// Top weights intentionally go with bottom weights and vice versa
		// These should probably be renamed.
		const frontDelta =
			frontTopDelta && frontBottomDelta && frontBottomDeltaWeight && frontTopDeltaWeight
				? (frontTopDelta * frontBottomDeltaWeight + frontBottomDelta * frontTopDeltaWeight) / (frontTopDeltaWeight + frontBottomDeltaWeight)
				: frontTopDelta ?? frontBottomDelta;
		const backDelta =
			backTopDelta && backBottomDelta && backBottomDeltaWeight && backTopDeltaWeight
				? (backTopDelta * backBottomDeltaWeight + backBottomDelta * backTopDeltaWeight) / (backTopDeltaWeight + backBottomDeltaWeight)
				: backTopDelta ?? backBottomDelta;

		// If the front option expires before today, return the back delta
		if (frontOptionExpiresAtDate && frontOptionExpiresAtDate < DateTime.local()) {
			return backDelta;
		}

		// Return the weighted average of the front and back deltas, weighted by the time between the endorsement end date and the option expiration date
		const frontWeight = frontOptionExpiresAtDate ? endorsementEndDate.diff(frontOptionExpiresAtDate, 'days').days : 0;
		const backWeight = backOptionExpiresAtDate ? Math.abs(endorsementEndDate.diff(backOptionExpiresAtDate, 'days').days) : 0;
		const totalWeight = frontWeight + backWeight;

		if (totalWeight === 0) return null;

		// Front weights intentionally go with back weights and vice versa
		// These should probably be renamed.
		return (frontDelta * backWeight + backDelta * frontWeight) / totalWeight;
	}

	// Calculate the delta for the endorsement taking into account the quantity of the endorsement
	get delta() {
		const endorsementQuantity = this.endorsement.headCount * this.endorsement.targetWeightCwt * 100;
		const lotSize =
			this.product?.StandardProductLotSpecification?.lotSize || this.productFromModelClass?.StandardProductLotSpecification?.lotSize || 1;

		const effectiveNumberOfContracts = endorsementQuantity / lotSize;
		return this.contractDelta ? effectiveNumberOfContracts * this.contractDelta : null;
	}

	// Make sure we unsubscribe from the delta service when this is destroyed.
	willDestroy() {
		this.optionSymbols.forEach((symbol) => {
			this.deltaService.unregister(symbol);
		});
	}
}
