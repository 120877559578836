import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Vault::UiButton\n\t{{on 'click' this.openModal}}\n\t@size={{@size}}\n\t@leftIconHref='/icons/add-outline.svg#addOutline'\n\t@leftIconClass='icon-white'\n\t@style='primary'\n\t...attributes\n>{{yield}}</Vault::UiButton>\n<CreateEditFeedIngredientModal\n\t@feedIngredient={{@feedIngredient}}\n\t@customerId={{@customerId}}\n\t@customerRole={{@customerRole}}\n\t@model={{@model}}\n\t@onClose={{this.onClose}}\n\t@showModal={{this.showModal}}\n\t@onSubmit={{@onSubmit}}\n/>", {"contents":"<Vault::UiButton\n\t{{on 'click' this.openModal}}\n\t@size={{@size}}\n\t@leftIconHref='/icons/add-outline.svg#addOutline'\n\t@leftIconClass='icon-white'\n\t@style='primary'\n\t...attributes\n>{{yield}}</Vault::UiButton>\n<CreateEditFeedIngredientModal\n\t@feedIngredient={{@feedIngredient}}\n\t@customerId={{@customerId}}\n\t@customerRole={{@customerRole}}\n\t@model={{@model}}\n\t@onClose={{this.onClose}}\n\t@showModal={{this.showModal}}\n\t@onSubmit={{@onSubmit}}\n/>","moduleName":"vault-client/components/create-edit-feed-ingredient-button.hbs","parseOptions":{"srcName":"vault-client/components/create-edit-feed-ingredient-button.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { FeedIngredient } from 'vault-client/types/graphql-types';
import { QueryResource } from 'glimmer-apollo';
import { tracked } from '@glimmer/tracking';
import { analyticsCustomTracking } from 'vault-client/utils/analytics-tracking';

interface createEditFeedIngredientButtonArgs {
	customerId: string;
	customerRole: string;
	model: QueryResource<unknown, unknown>;
	feedIngredient?: FeedIngredient;
	onClose?: () => void;
	onSubmit?: (ingredient: FeedIngredient | null) => void;
}

export default class createEditFeedIngredientButton extends Component<createEditFeedIngredientButtonArgs> {
	@tracked showModal = false;

	@action
	openModal() {
		this.showModal = true;
		if (this.args.feedIngredient) {
			analyticsCustomTracking('Opened Edit Feed Ingredient Modal', {
				'Business Role': this.args.customerRole,
			});
		} else {
			analyticsCustomTracking('Opened Add Feed Ingredient Modal', {
				'Business Role': this.args.customerRole,
			});
		}
	}

	@action
	onClose() {
		if (this.args.onClose) {
			this.args.onClose();
		}

		this.showModal = false;
	}
}
