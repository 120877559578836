import Route from '@ember/routing/route';
import query from 'vault-client/graphql/queries/organizations/index.graphql';
// @ts-ignore
import { queryManager } from 'ember-apollo-client';

export default class OrganizationsIndexRoute extends Route {
	@queryManager apollo: any;

	async model() {
		return this.apollo.watchQuery({ query });
	}
}
