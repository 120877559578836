import Transition from '@ember/routing/transition';
import FeedTargetsRoute from 'vault-client/routes/feed/targets';
import BusinessesBusinessRoute from '../business';
import { ModelFrom } from 'vault-client/utils/type-utils';

export default class BusinessesBusinessFeedTargetsRoute extends FeedTargetsRoute {

  beforeModel(transition: Transition<unknown>): void | Promise<unknown> {
		super.beforeModel(transition);

		const { businessId } = this.modelFor('businesses.business') as ModelFrom<BusinessesBusinessRoute>;
		if (!businessId) throw new Error(`BusinessId not found`);
		this.scopeId = businessId;
	}
}
