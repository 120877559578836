import Controller from '@ember/controller';
import { ModelFrom } from 'vault-client/utils/type-utils';
import Route from 'vault-client/routes/vgs/settings/advisors/show';
import { TableColumn, CellComponents } from 'vault-client/types/vault-table';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { EntityMember } from 'vault-client/types/graphql-types';
import PermissionsService from 'vault-client/services/permissions';
import { inject as service } from '@ember/service';

export default class VgsSettingsAdvisorsShow extends Controller {
	declare model: ModelFrom<Route>;
	@service declare permissions: PermissionsService;
	@tracked permissionGroupMembershipToDelete: EntityMember | null = null;

	get columns(): TableColumn[] {
		return [
			{
				id: '04db78a3-fa52-4a80-a23d-c3f3208591b6',
				name: 'Permission Group',
				valuePath: 'Entity.name',
				width: 140,
				minWidth: 140,
				textAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '70737f50-5a83-445b-9122-3f42bd12b677',
				name: '',
				valuePath: '',
				minWidth: 120,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.Button,
				componentArgs: {
					size: 'xs',
					style: 'outline',
					text: 'Delete',
					fn: this.setPermissionGroupMembershipToDelete,
				},
				isFixed: '',
				isVisible: true,
			},
		];
	}

	get rows() {
		return this.permissionGroupMemberships;
	}

	get advisor() {
		return this.model.getAdvisor.data?.GrainAdvisor ?? null;
	}

	get entityUsers() {
		return this.model.getAdvisor.data?.EntityUsers ?? null;
	}

	get permissionGroupMemberships() {
		return this.model.getAdvisor.data?.EntityMembers ?? [];
	}

	@action
	setPermissionGroupMembershipToDelete(EntityMembership: EntityMember | null) {
		this.permissionGroupMembershipToDelete = EntityMembership ?? null;
	}
}
