import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<LinkTo\n\t@route={{@linkRoute}}\n\t@current-when={{this.isActive}}\n\tclass='text-xs sm:text-sm leading-4 sm:leading-5 font-bold rounded-md py-1.5 px-1 sm:px-2.5 outline-none whitespace-nowrap text-brand-blue-70 hover:text-brand-interactive-blue-70 hover:bg-brand-gray-10 focus-visible:text-brand-interactive-blue-70 focus-visible:ring-1 focus-visible:ring-brand-interactive-blue-70 focus-visible:ring-inset active-link:text-brand-blue-60'\n\t...attributes\n>\n\t{{yield}}\n</LinkTo>", {"contents":"<LinkTo\n\t@route={{@linkRoute}}\n\t@current-when={{this.isActive}}\n\tclass='text-xs sm:text-sm leading-4 sm:leading-5 font-bold rounded-md py-1.5 px-1 sm:px-2.5 outline-none whitespace-nowrap text-brand-blue-70 hover:text-brand-interactive-blue-70 hover:bg-brand-gray-10 focus-visible:text-brand-interactive-blue-70 focus-visible:ring-1 focus-visible:ring-brand-interactive-blue-70 focus-visible:ring-inset active-link:text-brand-blue-60'\n\t...attributes\n>\n\t{{yield}}\n</LinkTo>","moduleName":"vault-client/components/ui-primary-menu-item.hbs","parseOptions":{"srcName":"vault-client/components/ui-primary-menu-item.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

interface UiPrimaryMenuItemArgs {
	linkRoute: string;
	activeRoute?: string;
	activeCheck?: () => boolean;
}

export default class UiPrimaryMenuItem extends Component<UiPrimaryMenuItemArgs> {
	@service router: any;

	get isActive(): boolean {
		if (this.args.activeCheck) {
			return this.args.activeCheck();
		} else if (this.args.activeRoute) {
			return this.router.currentRouteName === this.args.activeRoute;
		} else {
			return this.router.currentRouteName === this.args.linkRoute;
		}
	}
}
