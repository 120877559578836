import Route from '@ember/routing/route';
import { gql, useQuery } from 'glimmer-apollo';
import { tracked } from '@glimmer/tracking';
import {
	LrpInsuranceEndorsementFilterDTO,
	LrpInsuranceEndorsementSortByDTO,
	Count,
	LrpInsuranceEndorsement,
	Query_LrpEndorsementsArgs,
	Query,
	Query_HedgeStrategyArgs,
	TypeOfInsuranceProgram,
} from 'vault-client/types/graphql-types';
import { DateTime } from 'luxon';

type SortObj = {
	valuePath: string;
	isAscending: boolean;
};

export const GET_HEDGE_STRATEGY = gql`
	query HedgeStrategy($id: String!, $hsInsuranceWhere: InsuranceEndorsementFilterDTO) {
		HedgeStrategy(id: $id) {
			id
			businessId
			productId
			name
			InsuranceEndorsements(where: $hsInsuranceWhere) {
				id
				pnl
				producerPremiumAmount
				type
				hedgeStrategyId
				InsurancePolicy {
					producerName
					State {
						id
						abbreviation
					}
					AIP {
						id
						name
					}
				}
				RmaType {
					id
					typeName
					RmaCommodity {
						commodityName
					}
				}
				... on LrpInsuranceEndorsement {
					id
					commodityCode
					pnl
					totalPremiumAmount
					type
					salesEffectiveDate
					insurancePolicyId
					coverageEndDate
					coveragePrice
					headCount
					targetWeightCwt
					insuredValue
					lengthInWeeks
					subsidyAmount
					producerPremiumAmount
					indemnity
					hedgeStrategyId
					revenueHedgeProductId
					InsurancePolicy {
						producerName
						policyNumber
						agencyName
						agentFullName
						AIP {
							id
							name
						}
					}
					State {
						id
						abbreviation
						name
						rmaStateCode
					}
				}
			}
		}
	}
`;

export const GET_LRP_ENDORSEMENTS = gql`
	query LrpInsuranceEndorsements(
		$orderBy: LrpInsuranceEndorsementSortByDTO
		$where: LrpInsuranceEndorsementFilterDTO
		$offset: Float
		$limit: Float
	) {
		LrpInsuranceEndorsements(orderBy: $orderBy, where: $where, offset: $offset, limit: $limit) {
			id
			InsurancePolicy {
				id
				producerName
				policyNumber
				agencyName
				agentFullName
				AIP {
					id
					name
				}
			}
			insurancePolicyId
			coverageEndDate
			coveragePrice
			headCount
			salesEffectiveDate
			targetWeightCwt
			insuredValue
			lengthInWeeks
			subsidyAmount
			totalPremiumAmount
			producerPremiumAmount
			indemnity
			pnl
			hedgeStrategyId
			revenueHedgeProductId
			State {
				id
				abbreviation
				name
				rmaStateCode
			}

			RmaType {
				id
				typeName
				RmaCommodity {
					id
					commodityName
				}
			}
			RmaCommodity {
				id
				commodityName
			}
		}
		LrpInsuranceEndorsementCount(where: $where) {
			count
		}
	}
`;

type GetLRPEndorsementsQuery = {
	__typename?: 'Query';

	LrpInsuranceEndorsementCount: Count;
	LrpInsuranceEndorsements?: LrpInsuranceEndorsement[];
};

type HedgeStrategyQuery = Query['HedgeStrategy'] & {
	HedgeStrategy: Query['HedgeStrategy'];
};

interface QueryParams {
	strategy_id: string;
	customerId?: string | null;
	page: number;
	size: number;
	sorts: SortObj[];
	id: string;
	aipId: string;
	agent: string;
	stateId: string;
	salesEffectiveStartDate: string;
	salesEffectiveEndDate: string;
	coverageEndStartDate: string;
	coverageEndEndDate: string;
}

type HedgeStrategyArg = Query_HedgeStrategyArgs & {
	hsInsuranceWhere: {
		type?: string;
	};
	id: string;
};

export default class BusinessesBusinessHedgeStrategiesEditLrpEndorsementsIndexRoute extends Route {
	@tracked variables = {};
	@tracked hedgeStrategyVariables = { id: '', hsInsuranceWhere: {} };
	@tracked errorMessage: string | null = null;
	@tracked productId: string | null | undefined = null;

	queryParams = {
		agent: { refreshModel: true },
		aipId: { refreshModel: true },
		customerId: { refreshModel: true },
		salesEffectiveStartDate: { refreshModel: true },
		salesEffectiveEndDate: { refreshModel: true },
		coverageEndStartDate: { refreshModel: true },
		coverageEndEndDate: { refreshModel: true },
		stateId: { refreshModel: true },
		page: { refreshModel: true },
		sorts: { refreshModel: true },
		size: { refreshModel: true },
	};

	getHedgeStrategy = useQuery<HedgeStrategyQuery, HedgeStrategyArg>(this, () => [
		GET_HEDGE_STRATEGY,
		{ variables: this.hedgeStrategyVariables },
	]);

	getLrpEndorsements = useQuery<GetLRPEndorsementsQuery, Query_LrpEndorsementsArgs>(this, () => [
		GET_LRP_ENDORSEMENTS,
		{
			variables: this.variables,
			onComplete: () => {
				this.errorMessage = null;
			},
			onError: (error) => {
				this.errorMessage = 'There was an error. LRP insurance policies were not retrieved.';
				console.error('Error while attempting to retrieve LRP insurance policies.', error.message);
			},
		},
	]);

	generateOrderBy(sorts: SortObj[]): LrpInsuranceEndorsementSortByDTO {
		const orderBy: {
			[key: string]: any;
		} = {};

		sorts.forEach((sort: any) => {
			const value = sort.isAscending ? 'Asc' : 'Desc';
			orderBy[sort.valuePath] = value;
		});

		return orderBy;
	}

	generateWhere(
		strategyId: string | null,
		customerId: string | undefined,
		productId: string | null | undefined,
		aipId: string | null | undefined,
		agent: string | null | undefined,
		stateId: string | null | undefined,
		salesEffectiveStartDate: string | null | undefined,
		salesEffectiveEndDate: string | null | undefined,
		coverageEndStartDate: string | null | undefined,
		coverageEndEndDate: string | null | undefined
	) {
		const where: LrpInsuranceEndorsementFilterDTO = {
			AsInsuranceEndorsement: {},
			//  we need to filter out all endorsements that have a hedgeStrategy except if the hedgeStrategy belongs to the endorsement
			OR: [
				{
					hedgeStrategyId: {
						equals: strategyId,
					},
				},
				{
					hedgeStrategyId: {
						equals: null,
					},
				},
			],
		};

		if (customerId) {
			if (!where.Policy) {
				where.Policy = {};
			}
			where.Policy.customerId = {
				equals: customerId,
			};
		}
		// if the hedge strategy already has been associated with a Product/Commodity only show endorsements respective to the associated Product/Commodity
		if (productId) {
			where.revenueHedgeProductId = {
				equals: productId,
			};
		}

		if (aipId) {
			if (!where.Policy) {
				where.Policy = {};
			}
			where.Policy.aipId = {
				equals: aipId,
			};
		}

		if (agent) {
			if (!where.Policy) {
				where.Policy = {};
			}
			where.Policy.agentFullName = {
				equals: agent,
			};
		}

		if (stateId) {
			if (!where.Policy) {
				where.Policy = {};
			}
			where.Policy.stateId = {
				equals: stateId,
			};
		}

		if (salesEffectiveStartDate && salesEffectiveEndDate) {
			if (!where.AND) {
				where.AND = [];
			}
			where.AND.push({
				salesEffectiveDate: { gte: salesEffectiveStartDate },
			});
			where.AND.push({
				salesEffectiveDate: { lte: salesEffectiveEndDate },
			});
		} else if (salesEffectiveStartDate) {
			where.salesEffectiveDate = {
				gte: salesEffectiveStartDate,
			};
		} else if (salesEffectiveEndDate) {
			where.salesEffectiveDate = {
				lte: salesEffectiveEndDate,
			};
		}

		if (coverageEndStartDate && coverageEndEndDate) {
			if (!where.AND) {
				where.AND = [];
			}
			where.AND.push({
				coverageEndDate: { gte: coverageEndStartDate },
			});
			where.AND.push({
				coverageEndDate: { lte: coverageEndEndDate },
			});
		} else if (coverageEndStartDate) {
			where.coverageEndDate = {
				gte: coverageEndStartDate,
			};
		} else if (coverageEndEndDate) {
			where.salesEffectiveDate = {
				lte: coverageEndEndDate,
			};
		}

		return where;
	}

	async model(params: QueryParams) {
		this.hedgeStrategyVariables = { id: params.strategy_id, hsInsuranceWhere: { type: { equals: TypeOfInsuranceProgram.Lrp } } };

		await this.getHedgeStrategy.promise;

		this.productId = this.getHedgeStrategy?.data?.HedgeStrategy?.productId;

		this.variables = {
			where: this.generateWhere(
				params.strategy_id,
				this.getHedgeStrategy?.data?.HedgeStrategy?.businessId,
				this.getHedgeStrategy?.data?.HedgeStrategy?.productId,
				params.aipId,
				params.agent,
				params.stateId,
				params.salesEffectiveStartDate,
				params.salesEffectiveEndDate,
				params.coverageEndStartDate,
				params.coverageEndEndDate
			),
			orderBy: this.generateOrderBy(params.sorts),
			offset: params.page * params.size,
			limit: params.size,
		};

		await this.getLrpEndorsements.promise;

		return {
			LrpInsuranceEndorsements: this.getLrpEndorsements,
			HedgeStrategy: this.getHedgeStrategy,
			lastUpdatedAt: DateTime.now().toISO(),
		};
	}
}
