import Position from './position';
import Swaption from './swaption';

export default class SwaptionPosition extends Position {

	get marketDataInstrument() {
		const swaptionInstrument = this.Instrument as Swaption;
		return swaptionInstrument.priceInstrument ? swaptionInstrument.priceInstrument : undefined;
	}
}
