import { UserSortByDTO } from 'vault-client/types/graphql-types';

type SortObj = {
	valuePath: string;
	isAscending: boolean;
};

interface Params {
	sorts: SortObj[];
}

export default function usersIndexQuery(params?: Params): { orderBy: UserSortByDTO } {
	//
	// set sorting
	//
	const orderBy: {
		[key: string]: any;
	} = {};

	if (params) {
		params.sorts.forEach((sort) => {
			const value = sort.isAscending ? 'Asc' : 'Desc';
			orderBy[sort.valuePath] = value;
		});
	}

	return { orderBy };
}
