import CropPlansIndexController from 'vault-client/controllers/crop-plans';

export default class OrganizationsOrganizationCropPlans extends CropPlansIndexController {
	cropPlanRoutePath: string = 'organizations.organization.crop-plan';
	marketingRoutePath: string = 'organizations.organization.marketing';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'Organizations/organization/crop-plans': OrganizationsOrganizationCropPlans;
	}
}
