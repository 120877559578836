import Route from '@ember/routing/route';
import { queryManager } from 'ember-apollo-client';
import query from 'vault-client/graphql/queries/markets/product.graphql';
import { Product } from 'vault-client/types/graphql-types';
import RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';

export default class MarketsProductRoute extends Route {
	@service declare router: RouterService;
	@queryManager apollo: any;

	async model(params: { product_id: string }) {
		// If productId is `all-products`, a user navigated here using an old bookmark
		// and actually wants to go to markets.index
		if (params.product_id === 'all-products') {
			return this.router.transitionTo('markets.index');
		}

		const variables = {
			productId: params.product_id,
		};

		return this.apollo.watchQuery({ query, variables }) as { Product: Product };
	}
}
