import ReportsDairyHistoricalPercentilesFiveYear from 'vault-client/controllers/reports/dairy-historical-percentiles';

export default class BusinessesBusinessDairyHistoricalPercentiles extends ReportsDairyHistoricalPercentilesFiveYear {
	reportsRoute: string = 'businesses.business.reports';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'businesses/business/dairy-historical-percentiles': BusinessesBusinessDairyHistoricalPercentiles;
	}
}
