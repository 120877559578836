import MilkCheckReconciliationRoute from 'vault-client/routes/milk/milk-check-reconciliation';

type ModelParams = {
	year: number;
	selectedLocationId: string | null;
	basisStartDate: string;
	basisEndDate: string;
};
export default class BusinessesBusinessMilkCheckReconciliationRoute extends MilkCheckReconciliationRoute {
	async model(params: ModelParams) {
		const businessParams = this.paramsFor('businesses.business') as { business_id: string };
		const businessId = businessParams.business_id;

		const { startDate, endDate, futuresStartDate } = this.generateDates(params.year);

		this.variables = {
			startDate,
			endDate,
			futuresStartDate,
			entityId: businessId,
			scopeId: params.selectedLocationId ?? businessId,
			basisStartDate: params.basisStartDate,
			basisEndDate: params.basisEndDate,
		};

		await this.getMilkCheckReconciliation.promise;

		return {
			entityId: businessId,
			getMilkCheckReconciliation: this.getMilkCheckReconciliation,
		};
	}
}
