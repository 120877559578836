import { DateTime } from 'luxon';
import FeedBoughtHedgedMonth from 'vault-client/models/feed-bought-hedged-month';
import {
	AggregateForecastedSwineLivestockFeedUsageDTO,
	AggregateForecastedHedgedAndCappedVolumeDTO,
	TypeOfInstrument,
	AggregateFeedIngredientForecastedUsageDTO,
	AggregateAllocatedForecastedHedgedAndCappedVolumeDTO,
} from 'vault-client/types/graphql-types';
import tonsPerFuturesContract from './tons-per-futures-contract';

// TODO: Add handling for Other ingredients without a hedged product

export function generateFeedBoughtHedgedMonths(
	productSlug: string | null,
	startDate: string,
	endDate: string,
	feedUsages: AggregateForecastedSwineLivestockFeedUsageDTO[] | AggregateFeedIngredientForecastedUsageDTO[],
	hedgedAndCappedVolumes: AggregateForecastedHedgedAndCappedVolumeDTO[] | AggregateAllocatedForecastedHedgedAndCappedVolumeDTO[],
	lbsPerBushel?: number
) {
	const monthObjsMap: Record<string, FeedBoughtHedgedMonth> = {};

	let currDateTime = DateTime.fromISO(startDate).startOf('month');
	const endDateTime = DateTime.fromISO(endDate).startOf('month');
	const tonsPerContract = tonsPerFuturesContract(productSlug ?? '');

	while (currDateTime <= endDateTime) {
		const date = currDateTime.toISODate();
		monthObjsMap[date] = new FeedBoughtHedgedMonth(date, lbsPerBushel, tonsPerContract);
		currDateTime = currDateTime.plus({ month: 1 }).startOf('month');
	}

	feedUsages.forEach((value) => {
		// Dairy Feed Values
		if (value.__typename === 'AggregateFeedIngredientForecastedUsageDTO') {
			if (value.FeedIngredient?.FeedCategory?.HedgeProduct?.slug !== productSlug) return;

			let date: string | null = null;
			if (value.date) {
				date = value.date;
			} else if (value.month && value.year) {
				date = DateTime.fromObject({ month: value.month, year: value.year }).toISODate();
			}

			if (!date) return;

			const firstOfMonth = DateTime.fromISO(date).startOf('month').toISODate();
			const dryMatterPercent = value.FeedIngredient.dryMatterPercent;

			if (!firstOfMonth || dryMatterPercent == null) {
				throw new Error(
					`Date or DryMatterPercent not found for AggregateFeedIngredientForecastedUsages. Please ensure that it was requested`
				);
			}

			const monthObj = monthObjsMap[firstOfMonth];
			const quantityInTons = value.sum.dmiUsageInTons ?? 0;
			const lbsPerShortTon = 2000;

			if (monthObj) {
				monthObj.usage += quantityInTons * lbsPerShortTon;
			}
		}
		// Livestock Feed Values
		else if (value.__typename === 'AggregateForecastedSwineLivestockFeedUsageDTO') {
			if (value.Ingredient?.FeedCategory?.HedgeProduct?.slug !== productSlug) return;

			const firstOfMonth = value.firstDateOfMonth;
			const dryMatterPercent = value.Ingredient.dryMatterPercent;

			if (!firstOfMonth || dryMatterPercent == null) {
				throw new Error(
					`firstOfMonth or DryMatterPercent not found for AggregateFeedIngredientForecastedUsages. Please ensure that it was requested`
				);
			}

			const monthObj = monthObjsMap[firstOfMonth];

			if (monthObj) {
				monthObj.usage += (value.sum.quantityInLbs ?? 0) * dryMatterPercent;
			}
		}
	});

	hedgedAndCappedVolumes.forEach((value) => {
		if (!value.date || !value?.Product?.slug) {
			console.error('Date or Product Slug not found when adding hedged and capped volumes. Please ensure they were requested.');
			return;
		}

		if (value.Product.slug !== productSlug) return;

		const date = DateTime.fromISO(value.date, { zone: 'utc' }).startOf('month').toISODate();

		const monthObj = monthObjsMap[date];

		// Convert contract units to lbs (bushels, short tons, etc)
		let unitMultiplier = 1;

		if (productSlug === 'grain-corn') {
			unitMultiplier = 56; // lbs per bushel
		} else if (productSlug === 'grain-soybean-meal') {
			unitMultiplier = 2000; // lbs per short ton
		}

		if (monthObj) {
			if (value.instrumentType === TypeOfInstrument.Future) {
				monthObj.futuresHedged = (value.sum.naturallyShortHedged ?? 0) * unitMultiplier;
			} else if (value.instrumentType === TypeOfInstrument.Option) {
				monthObj.callsHedged = (value.sum.naturallyShortHedged ?? 0) * unitMultiplier;
			}
		}
	});

	return Object.values(monthObjsMap).sortBy('date');
}
