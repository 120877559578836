import AccountsIndexController from 'vault-client/controllers/accounts';

export default class OrganizationsOrganizationAccounts extends AccountsIndexController {
	accountRoutePath: string = 'organizations.organization.account';
	brokerageRoutePath: string = 'organizations.organization.brokerage';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'organizations/organization/accounts': OrganizationsOrganizationAccounts;
	}
}
