import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { gql, useQuery } from 'glimmer-apollo';
import { Seller } from 'vault-client/types/graphql-types';

const GET_SELLERS = gql`
	query Sellers {
		Sellers(orderBy: { name: Asc }) {
			id
			name
		}
	}
`;
interface SellerSelectArgs {
	selected: string;
	selectedId: string;
}

export default class SellerSelect extends Component<SellerSelectArgs> {
	@tracked sellers: Seller[] | null = [];
	@tracked selected: string | Seller | null = this.args.selected;

	get currentlySelected() {
		if (this.args.selected) {
			return this.args.selected;
		}

		if (this.args.selectedId) {
			return this.sellers?.find((seller) => seller.id == this.args.selectedId) ?? null;
		}

		return null;
	}
	set currentlySelected(value) {
		this.selected = value;
	}

	@action
	async fetchSellers() {
		await useQuery<{ Sellers: Seller[] }>(this, () => [
			GET_SELLERS,
			{
				onComplete: (data) => {
					if (data?.Sellers) {
						this.sellers = data.Sellers;
					} else {
						this.sellers = [];
					}
				},
				onError: (error) => {
					console.error('Error on Seller select', error.message);
				},
			},
		]).promise;
	}
}
