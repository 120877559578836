import ReportsDryWheyStrategicInventoryModel from 'vault-client/controllers/reports/dry-whey-strategic-inventory-model';

export default class BusinessesBusinessDryWheyStrategicInventoryModel extends ReportsDryWheyStrategicInventoryModel {
	reportsRoute: string = 'businesses.business.reports';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'businesses/business/dry-whey-strategic-inventory-model': BusinessesBusinessDryWheyStrategicInventoryModel;
	}
}
