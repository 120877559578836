import Route from '@ember/routing/route';
import { tracked } from '@glimmer/tracking';
import { gql, useQuery } from 'glimmer-apollo';
import { Query, Query_GrainLocationsArgs, SortByDirection } from 'vault-client/types/graphql-types';
import { SortObj } from 'vault-client/types/vault-table';

export const GET_GRAIN_LOCATIONS = gql`
	query GrainLocations($orderBy: GrainLocationSortByDTO) {
		GrainLocations(orderBy: $orderBy) {
			id
			name
		}
	}
`;

type getLocations = {
	__typename?: 'Query';

	GrainLocations: Query['GrainLocations'];
};

interface QueryParams {
	sorts: SortObj[];
}

export default class VgsSettingsLocations extends Route {
	@tracked variables: Query_GrainLocationsArgs = {};

	getLocations = useQuery<getLocations, Query_GrainLocationsArgs>(this, () => [GET_GRAIN_LOCATIONS, { variables: this.variables }]);

	queryParams = {
		sorts: { refreshModel: true },
	};

	generateOrderBy(sorts: SortObj[]) {
		const orderBy: {
			[key: string]: any;
		} = {};

		sorts.forEach((sort: any) => {
			const parts = sort.valuePath.split('.');
			const firstPart = parts.shift();

			let value: any = sort.isAscending ? SortByDirection.Asc : SortByDirection.Desc;

			while (parts.length > 0) {
				const part = parts.pop();
				const obj: {
					[key: string]: any;
				} = {};
				obj[part] = value;
				value = obj;
			}

			orderBy[firstPart] = value;
		});

		return orderBy;
	}

	async model(params: QueryParams) {
		this.variables = {
			orderBy: this.generateOrderBy(params.sorts),
		};

		await this.getLocations.promise;

		return { getLocations: this.getLocations, variables: this.variables };
	}
}
