import { getOwner } from '@ember/application';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { DateTime, Interval } from 'luxon';
import { TrackedArray, TrackedMap } from 'tracked-built-ins';
import HypotheticalPosition from 'vault-client/models/hypothetical-position';
import ScenarioDerivedDrpEndorsement from 'vault-client/models/scenario-derived-drp-endorsement';
import ScenarioMilkCheck from 'vault-client/models/scenario-milk-check';
import BusinessesBusinessPriceScenariosRoute from 'vault-client/routes/businesses/business/price-scenarios';
import ReportsOrganizationsOrganizationPriceScenariosRoute from 'vault-client/routes/organizations/organization/price-scenarios';
import ScenarioPricesService from 'vault-client/services/scenario-prices';
import {
	AggregateLedgerEntryDTO,
	ForecastedMilkProductionByMonth,
	ForecastedMilkUtilization,
	Future,
	TypeOfInstrument,
	TypeOfOption,
} from 'vault-client/types/graphql-types';
import checkStorageAvailable from 'vault-client/utils/check-storage-available';
import {
	calculateMonthlyWeightedPricesAndBasisValues,
	calculateUtilizationValuesFromForecastedProduction,
} from 'vault-client/utils/milk-check-utils';
import { ModelFrom } from 'vault-client/utils/type-utils';

export type PricesStore = TrackedMap<string, Record<string, { price: number | null; scenarioPrice: number | null }>>;

type ActiveChart = 'Net P&L' | 'Milk Check';
type PositionType = 'Futures (Forward)' | 'Call' | 'Put';
type ActiveComponentChart = 'Cheese' | 'Whey' | 'Nonfat' | 'Butter';

export default class ReportsPriceScenariosController extends Controller {
	declare model: ModelFrom<BusinessesBusinessPriceScenariosRoute> | ModelFrom<ReportsOrganizationsOrganizationPriceScenariosRoute>;
	@service declare scenarioPrices: ScenarioPricesService;

	id = guidFor(this);
	reportsRoutePath = '';

	@tracked activeChart: ActiveChart = 'Net P&L';
	@tracked activeComponentChart: ActiveComponentChart = 'Cheese';
	@tracked _currentPositions: HypotheticalPosition[] = new TrackedArray([]);
	@tracked _addedHypotheticalPositions: HypotheticalPosition[] = new TrackedArray([]);
	@tracked startDate = DateTime.now().startOf('month').toISODate();
	@tracked endDate = DateTime.now().plus({ month: 23 }).startOf('month').toISODate();
	@tracked showSetPricesModal = false;
	@tracked rawSetFuturePrice: string = '';
	@tracked setPricesModalError: string | null = null;

	queryParams = ['startDate', 'endDate'];

	get products() {
		return [
			{
				name: 'Class III Milk',
				productSlug: 'us-dairy-class-iii',
				volumeUnit: 'Pounds',
				pricingUnit: 'CWT',
				months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
			},
			{
				name: 'Class IV Milk',
				productSlug: 'us-dairy-class-iv',
				volumeUnit: 'Pounds',
				pricingUnit: 'CWT',
				months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
			},
			{
				name: 'Cash-Settled Cheese',
				productSlug: 'us-dairy-cheese',
				volumeUnit: 'Pounds',
				pricingUnit: 'Pound',
				months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
			},
			{
				name: 'Cash-Settled Butter',
				productSlug: 'us-dairy-butter',
				volumeUnit: 'Pounds',
				pricingUnit: 'Pound',
				months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
			},
			{
				name: 'Nonfat Dry Milk',
				productSlug: 'us-dairy-nonfat-milk',
				volumeUnit: 'Pounds',
				pricingUnit: 'Pound',
				months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
			},
			{
				name: 'Dry Whey',
				productSlug: 'us-dairy-dry-whey',
				volumeUnit: 'Pounds',
				pricingUnit: 'Pound',
				months: ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'],
			},
		];
	}

	get entityId() {
		return this.model.entityId;
	}

	// TODO: This will be refactored out when we have the P&L chart added in a future story
	get months() {
		const months = [];
		let currentDate = DateTime.fromISO(this.startDate);
		const endDate = DateTime.fromISO(this.endDate);

		while (currentDate <= endDate) {
			months.push(currentDate.startOf('month').toISODate());
			currentDate = currentDate.plus({ month: 1 });
		}

		return months;
	}

	get activeChartTitle() {
		switch (this.activeChart) {
			case 'Net P&L':
				return 'Combined Net P&L: Hedge Adjusted (Current & Scenario)';
			case 'Milk Check':
				return 'Milk Check: Hedge Adjusted (Current & Scenario)';
		}
	}

	get activeComponentChartTitle() {
		switch (this.activeComponentChart) {
			case 'Cheese':
				return 'Monthly Price of Cash Settled Cheese';
			case 'Whey':
				return 'Monthly Price of Dry Whey';
			case 'Nonfat':
				return 'Monthly Price of Nonfat Dry Milk';
			case 'Butter':
				return 'Monthly Price of Cash Settled Butter';
		}
	}

	get setPriceModalComponentString() {
		switch (this.activeComponentChart) {
			case 'Cheese':
				return 'Cash Settled Cheese';
			case 'Whey':
				return 'Dry Whey';
			case 'Nonfat':
				return 'Nonfat Dry Milk';
			case 'Butter':
				return 'Cash Settled Butter';
		}
	}

	get classIIIFutures() {
		return (
			this.model.getPriceScenariosData.data?.Futures.filter(
				(future) => future?.Product?.slug === 'us-dairy-class-iii' && future.displayExpiresAt >= this.startDate
			) ?? []
		);
	}

	get classIVFutures() {
		return (
			this.model.getPriceScenariosData.data?.Futures.filter(
				(future) => future?.Product?.slug === 'us-dairy-class-iv' && future.displayExpiresAt >= this.startDate
			) ?? []
		);
	}

	get activeComponentChartFutures() {
		return (
			this.model.getPriceScenariosData.data?.Futures.filter(
				(future) => future?.Product?.slug === this.activeComponentProductSlug && future.displayExpiresAt >= this.startDate
			) ?? []
		);
	}

	get componentChartOptions(): ActiveComponentChart[] {
		return ['Cheese', 'Whey', 'Nonfat', 'Butter'];
	}

	get activeComponentProductSlug() {
		switch (this.activeComponentChart) {
			case 'Whey':
				return 'us-dairy-dry-whey';
			case 'Nonfat':
				return 'us-dairy-nonfat-milk';
			case 'Cheese':
				return 'us-dairy-cheese';
			case 'Butter':
				return 'us-dairy-butter';
		}
	}

	get chartLabels() {
		return this.months.map((month) => DateTime.fromISO(month).toFormat('LLL yyyy'));
	}

	get netPnlData() {
		return {
			'Current P&L': this.currentNetPnl,
			'Scenario P&L': this.scenarioNetPnl,
		};
	}

	get currentMilkCheckPrices() {
		return this.calculateFinalMilkCheckPrices(this.milkChecks, this.monthlyCurrentPositionsPnl, this.monthlyCurrentInsurancePnl, 'market');
	}

	get scenarioMilkCheckPrices() {
		return this.calculateFinalMilkCheckPrices(
			this.milkChecks,
			this.monthlyHypotheticalPositionsPnl,
			this.monthlyScenarioInsurancePnl,
			'scenario'
		);
	}

	get milkCheckData() {
		return {
			Current: this.currentMilkCheckPrices,
			Scenario: this.scenarioMilkCheckPrices,
		};
	}

	get currentNetPnl() {
		return this.calculateNetPnl(
			this.milkChecks,
			this.monthlyCurrentPositionsPnl,
			this.monthlyCurrentInsurancePnl,
			this.model.getPriceScenariosData.data?.MonthlyRevenuesAndExpenses ?? [],
			'market'
		);
	}

	get scenarioNetPnl() {
		return this.calculateNetPnl(
			this.milkChecks,
			this.monthlyHypotheticalPositionsPnl,
			this.monthlyScenarioInsurancePnl,
			this.model.getPriceScenariosData.data?.MonthlyRevenuesAndExpenses ?? [],
			'scenario'
		);
	}

	get milkChecks(): ScenarioMilkCheck[] {
		return this.generateScenarioMilkChecks(
			this.model.getPriceScenariosData.data?.ForecastedMilkProductionByMonths ?? [],
			this.model.getPriceScenariosData.data?.MonthlyPremiumsAndDeductions ?? []
		);
	}

	get monthlyCurrentPositionsPnl() {
		const monthlyPnl: Record<string, number | null> = {};

		const setMonthlyPnl = (date: string, value: number | null) => {
			const currValue = monthlyPnl[date];
			if (currValue) {
				monthlyPnl[date] = currValue + (value ?? 0);
			} else {
				monthlyPnl[date] = value;
			}
		};

		this._currentPositions.forEach((position) => {
			setMonthlyPnl(position.date, position.marketPnlInDollars);
		});

		return monthlyPnl;
	}

	get monthlyHypotheticalPositionsPnl() {
		const monthlyPnl: Record<string, number | null> = {};

		const setMonthlyPnl = (date: string, value: number | null) => {
			const currValue = monthlyPnl[date];
			if (currValue) {
				monthlyPnl[date] = currValue + (value ?? 0);
			} else {
				monthlyPnl[date] = value;
			}
		};

		this._currentPositions.forEach((position) => {
			setMonthlyPnl(position.date, position.scenarioPnlInDollars);
		});

		this._addedHypotheticalPositions.forEach((position) => {
			setMonthlyPnl(position.date, position.scenarioPnlInDollars);
		});

		return monthlyPnl;
	}

	get monthlyCurrentInsurancePnl() {
		const monthlyCurrentInsurancePnl: Record<string, { pnl: number | null; production: number | null }> = {};

		// Get total pnl and production or a quarter
		const addMonthlyPnl = (date: string, pnl: number | null, production: number | null) => {
			const currValue = monthlyCurrentInsurancePnl[date];
			if (currValue) {
				currValue.pnl = (currValue.pnl ?? 0) + (pnl ?? 0);
				currValue.production = (currValue.production ?? 0) + (production ?? 0);
			} else {
				monthlyCurrentInsurancePnl[date] = { pnl, production };
			}
		};

		this.scenarioDRPEndorsements.forEach((endorsement) => {
			const effectiveHedgeDate = endorsement.effectiveHedgeDate;
			addMonthlyPnl(effectiveHedgeDate, endorsement.marketEstimatedPnl, endorsement.effectiveCoveredMilkProduction);
		});

		// Divide total pnl by total cwt production to get an average pnl per cwt across policies
		const monthlyCwtPnl = Object.entries(monthlyCurrentInsurancePnl).reduce<Record<string, number | null>>(
			(acc, [date, { pnl, production }]) => {
				acc[date] = production !== 0 ? (pnl ?? 0) / ((production ?? 0) / 100) : null;
				return acc;
			},
			{}
		);

		return monthlyCwtPnl;
	}

	get monthlyScenarioInsurancePnl() {
		const monthlyScenarioInsurancePnl: Record<string, { pnl: number | null; production: number | null }> = {};

		const addMonthlyPnl = (date: string, pnl: number | null, production: number | null) => {
			const currValue = monthlyScenarioInsurancePnl[date];
			if (currValue) {
				currValue.pnl = (currValue.pnl ?? 0) + (pnl ?? 0);
				currValue.production = (currValue.production ?? 0) + (production ?? 0);
			} else {
				monthlyScenarioInsurancePnl[date] = { pnl, production };
			}
		};

		this.scenarioDRPEndorsements.forEach((endorsement) => {
			const effectiveHedgeDate = endorsement.effectiveHedgeDate;
			addMonthlyPnl(effectiveHedgeDate, endorsement.scenarioEstimatedPnl, endorsement.effectiveCoveredMilkProduction);
		});

		const monthlyCwtPnl = Object.entries(monthlyScenarioInsurancePnl).reduce<Record<string, number | null>>(
			(acc, [date, { pnl, production }]) => {
				acc[date] = production !== 0 ? (pnl ?? 0) / ((production ?? 0) / 100) : null;
				return acc;
			},
			{}
		);

		return monthlyCwtPnl;
	}

	get monthlyProduction() {
		const monthlyProduction: Record<string, number> = {};

		this.model.getPriceScenariosData.data?.ForecastedMilkProductionByMonths.forEach((production) => {
			const grossProduction = production.grossProduction;
			const date = production.date;

			if (!date) return;

			if (!monthlyProduction[date]) {
				monthlyProduction[date] = grossProduction;
			} else {
				monthlyProduction[date] = monthlyProduction[date] + grossProduction;
			}
		});

		return monthlyProduction;
	}

	get activeComponentDisplayFactor() {
		return this.activeComponentChartFutures.find((future) => future.SymbolGroup.displayFactor)?.SymbolGroup.displayFactor ?? 1;
	}

	get butterDisplayFactor() {
		return (
			this.model.getPriceScenariosData.data?.Futures.find((future) => future.Product.slug === 'us-dairy-butter')?.SymbolGroup
				.displayFactor ?? 1
		);
	}

	get nonfatDisplayFactor() {
		return (
			this.model.getPriceScenariosData.data?.Futures.find((future) => future.Product.slug === 'us-dairy-nonfat-milk')?.SymbolGroup
				.displayFactor ?? 1
		);
	}

	get cheeseDisplayFactor() {
		return (
			this.model.getPriceScenariosData.data?.Futures.find((future) => future.Product.slug === 'us-dairy-cheese')?.SymbolGroup
				.displayFactor ?? 1
		);
	}

	get wheyDisplayFactor() {
		return (
			this.model.getPriceScenariosData.data?.Futures.find((future) => future.Product.slug === 'us-dairy-dry-whey')?.SymbolGroup
				.displayFactor ?? 1
		);
	}

	get cornDisplayFactor() {
		return (
			this.model.getPriceScenariosData.data?.Futures.find((future) => future.Product.slug === 'grain-corn')?.SymbolGroup.displayFactor ?? 1
		);
	}

	get soybeanMealDisplayFactor() {
		return (
			this.model.getPriceScenariosData.data?.Futures.find((future) => future.Product.slug === 'grain-soybean-meal')?.SymbolGroup
				.displayFactor ?? 1
		);
	}

	get addedHypotheticalPositions() {
		return this._addedHypotheticalPositions;
	}

	get scenarioDRPEndorsements(): ScenarioDerivedDrpEndorsement[] {
		const futuresCache = new Map<
			string,
			{
				classIIIFutures: Future[];
				classIVFutures: Future[];
				butterFutures: Future[];
				cheeseFutures: Future[];
				dryWheyFutures: Future[];
				nonfatDryMilkFutures: Future[];
			}
		>();

		const _scenarioEndorsements: ScenarioDerivedDrpEndorsement[] = [];

		this.model.getPriceScenariosData.data?.AllocatedDrpEndorsements.forEach((endorsement) => {
			const effectiveHedgeDate = endorsement?.effectiveHedgeDate;
			const ratioAdjustedDerivedDrpEndorsement = endorsement.RatioAdjustedDerivedDrpInsuranceEndorsement;
			const quarterStartDate = ratioAdjustedDerivedDrpEndorsement?.quarterStartDate;
			const quarterEndDate = ratioAdjustedDerivedDrpEndorsement?.quarterEndDate;
			const expectedRevenueGuarantee = ratioAdjustedDerivedDrpEndorsement?.expectedRevenueGuarantee;
			const effectiveCoveredMilkProduction = ratioAdjustedDerivedDrpEndorsement?.effectiveCoveredMilkProduction ?? 0;
			const declaredCoveredMilkProduction = ratioAdjustedDerivedDrpEndorsement?.declaredCoveredMilkProduction;
			const yieldAdjustmentFactor = ratioAdjustedDerivedDrpEndorsement?.yieldAdjustmentFactor;
			const isLong = ratioAdjustedDerivedDrpEndorsement?.isLong;
			const classPriceWeightingFactor = ratioAdjustedDerivedDrpEndorsement?.classPriceWeightingFactor ?? null;
			const componentPriceWeightingFactor = ratioAdjustedDerivedDrpEndorsement?.componentPriceWeightingFactor ?? null;
			const declaredButterfatTest = ratioAdjustedDerivedDrpEndorsement?.declaredButterfatTest ?? null;
			const declaredProteinTest = ratioAdjustedDerivedDrpEndorsement?.declaredProteinTest ?? null;
			const producerPremiumAmount = ratioAdjustedDerivedDrpEndorsement?.producerPremiumAmount ?? 0;
			const totalPremiumAmount = ratioAdjustedDerivedDrpEndorsement?.totalPremiumAmount ?? 0;
			const protectionFactor = ratioAdjustedDerivedDrpEndorsement?.protectionFactor ?? null;

			if (
				!quarterStartDate ||
				!quarterEndDate ||
				!expectedRevenueGuarantee ||
				!declaredCoveredMilkProduction ||
				!yieldAdjustmentFactor ||
				!isLong
			)
				return;

			let classIIIFutures: Future[] = [];
			let classIVFutures: Future[] = [];
			let butterFutures: Future[] = [];
			let cheeseFutures: Future[] = [];
			let dryWheyFutures: Future[] = [];
			let nonfatDryMilkFutures: Future[] = [];

			const cacheEntry = futuresCache.get(quarterEndDate);

			if (cacheEntry) {
				classIIIFutures = cacheEntry.classIIIFutures;
				classIVFutures = cacheEntry.classIVFutures;
				butterFutures = cacheEntry.butterFutures;
				cheeseFutures = cacheEntry.cheeseFutures;
				dryWheyFutures = cacheEntry.dryWheyFutures;
				nonfatDryMilkFutures = cacheEntry.nonfatDryMilkFutures;
			} else {
				classIIIFutures = this.findQuarterFutures('us-dairy-class-iii', quarterEndDate);
				classIVFutures = this.findQuarterFutures('us-dairy-class-iv', quarterEndDate);
				butterFutures = this.findQuarterFutures('us-dairy-butter', quarterEndDate);
				cheeseFutures = this.findQuarterFutures('us-dairy-cheese', quarterEndDate);
				dryWheyFutures = this.findQuarterFutures('us-dairy-dry-whey', quarterEndDate);
				nonfatDryMilkFutures = this.findQuarterFutures('us-dairy-nonfat-milk', quarterEndDate);

				futuresCache.set(quarterEndDate, {
					classIIIFutures,
					classIVFutures,
					butterFutures,
					cheeseFutures,
					dryWheyFutures,
					nonfatDryMilkFutures,
				});
			}

			_scenarioEndorsements.push(
				new ScenarioDerivedDrpEndorsement(
					getOwner(this),
					quarterStartDate,
					quarterEndDate,
					effectiveHedgeDate,
					classIIIFutures,
					classIVFutures,
					butterFutures,
					cheeseFutures,
					dryWheyFutures,
					nonfatDryMilkFutures,
					expectedRevenueGuarantee,
					classPriceWeightingFactor,
					componentPriceWeightingFactor,
					declaredCoveredMilkProduction,
					effectiveCoveredMilkProduction,
					yieldAdjustmentFactor,
					declaredButterfatTest,
					declaredProteinTest,
					producerPremiumAmount,
					totalPremiumAmount,
					protectionFactor,
					isLong
				)
			);
		});

		return _scenarioEndorsements;
	}

	@action
	removeHypotheticalPosition(id: string) {
		const index = this._addedHypotheticalPositions.findIndex((position) => position.id === id);
		this._addedHypotheticalPositions.removeAt(index);
		this.storeAddedHypotheticalPositions(this.entityId, this._addedHypotheticalPositions);
	}

	@action
	clearHypotheticalPositions() {
		this._addedHypotheticalPositions.clear();
		this.storeAddedHypotheticalPositions(this.entityId, this._addedHypotheticalPositions);
	}

	calculateFinalMilkCheckPrices(
		milkChecks: ScenarioMilkCheck[],
		monthlyPositionsPnl: Record<string, number | null>,
		monthlyInsurancePnl: Record<string, number | null>,
		priceProperty: 'market' | 'scenario'
	) {
		const map: {
			[date: string]: {
				positionsPnl: number | null;
				insurancePnl: number | null;
				revenuesAndExpenses: number;
				monthlyProduction: number;
				mailboxPrice: number | null;
			};
		} = {};

		// Populate map
		let currDate = DateTime.fromISO(this.startDate);
		const endDate = DateTime.fromISO(this.endDate);

		while (currDate <= endDate) {
			const date = currDate.startOf('month').toISODate();
			map[date] = {
				positionsPnl: null,
				insurancePnl: null,
				revenuesAndExpenses: 0,
				monthlyProduction: this.monthlyProduction[date] ?? 0,
				mailboxPrice: null,
			};
			currDate = currDate.plus({ month: 1 });
		}

		milkChecks.forEach((milkCheck) => {
			if (!map[milkCheck.date]) return;
			map[milkCheck.date].mailboxPrice = priceProperty === 'market' ? milkCheck.marketMailboxPrice : milkCheck.scenarioMailboxPrice;
		});

		Object.entries(monthlyPositionsPnl).forEach(([date, pnl]) => {
			if (!map[date]) return;
			map[date].positionsPnl = pnl;
		});

		Object.entries(monthlyInsurancePnl).forEach(([date, pnl]) => {
			if (!map[date]) return;
			map[date].insurancePnl = pnl;
		});

		const sortedEntries = Object.entries(map).sort(([dateA, _valuesA], [dateB, _valuesB]) => (dateA < dateB ? -1 : 1));

		const mappedEntries = sortedEntries.map(([_date, values]) => {
			let milkCheckPrice = 0;

			if (values.monthlyProduction) {
				milkCheckPrice += values.mailboxPrice ?? 0;
				milkCheckPrice += values.insurancePnl ?? 0;
				milkCheckPrice += (values.positionsPnl ?? 0) / (values.monthlyProduction / 100);
			}

			return milkCheckPrice;
		});

		return mappedEntries;
	}

	calculateNetPnl(
		milkChecks: ScenarioMilkCheck[],
		monthlyPositionsPnl: Record<string, number | null>,
		monthlyInsurancePnl: Record<string, number | null>,
		monthlyRevenuesAndExpenses: AggregateLedgerEntryDTO[],
		priceProperty: 'market' | 'scenario'
	) {
		const map: {
			[date: string]: {
				positionsPnl: number | null;
				insurancePnl: number | null;
				revenuesAndExpenses: number;
				mailboxPrice: number | null;
				monthlyProduction: number;
			};
		} = {};

		// Populate map
		let currDate = DateTime.fromISO(this.startDate);
		const endDate = DateTime.fromISO(this.endDate);

		while (currDate <= endDate) {
			const date = currDate.startOf('month').toISODate();
			map[date] = {
				positionsPnl: null,
				insurancePnl: null,
				revenuesAndExpenses: 0,
				mailboxPrice: null,
				monthlyProduction: this.monthlyProduction[date] ?? 0,
			};
			currDate = currDate.plus({ month: 1 });
		}

		monthlyRevenuesAndExpenses.forEach((entry) => {
			const month = entry.month;
			const year = entry.year;

			if (!month || !year) return;

			const dateString = DateTime.fromObject({ month, year }).toISODate();

			if (!map[dateString]) return;

			map[dateString].revenuesAndExpenses = entry.sum.calculatedAmount ?? 0;
		});

		milkChecks.forEach((milkCheck) => {
			if (!map[milkCheck.date]) return;
			map[milkCheck.date].mailboxPrice = priceProperty === 'market' ? milkCheck.marketMailboxPrice : milkCheck.scenarioMailboxPrice;
		});

		Object.entries(monthlyPositionsPnl).forEach(([date, pnl]) => {
			if (!map[date]) return;
			map[date].positionsPnl = pnl;
		});

		Object.entries(monthlyInsurancePnl).forEach(([date, pnl]) => {
			if (!map[date]) return;
			map[date].insurancePnl = pnl;
		});

		const sortedEntries = Object.entries(map).sort(([dateA, _valuesA], [dateB, _valuesB]) => (dateA < dateB ? -1 : 1));

		const mappedEntries = sortedEntries.map(([_date, values]) => {
			let netPnl = 0;

			if (values.monthlyProduction) {
				netPnl += values.mailboxPrice ?? 0;
				netPnl += values.insurancePnl ?? 0;
				netPnl += ((values.positionsPnl ?? 0) + values.revenuesAndExpenses) / (values.monthlyProduction / 100);
			}

			return netPnl;
		});

		return mappedEntries;
	}

	@action
	onSetPricesModalSubmit() {
		this.setPricesModalError = '';
		const displayPrice = parseFloat(this.rawSetFuturePrice);
		if (!isFinite(displayPrice)) {
			this.setPricesModalError = 'Price could not be parsed. Please ensure it is included.';
		}

		const barchartPrice = displayPrice / this.activeComponentDisplayFactor;

		this.setFuturePricesForCurrentComponent(barchartPrice);
		this.rawSetFuturePrice = '';
		this.showSetPricesModal = false;
	}

	@action
	onSetPricesModalCancel() {
		this.setPricesModalError = '';
		this.rawSetFuturePrice = '';
		this.showSetPricesModal = false;
	}

	@action
	setFuturePricesForCurrentComponent(price: number) {
		const barchartSymbol = this.activeComponentChartFutures.map((future) => future.barchartSymbol);
		const filteredBarchartSymbols = barchartSymbol.filter((x): x is string => typeof x === 'string');
		this.scenarioPrices.setScenarioPrices(filteredBarchartSymbols, price);
	}

	@action
	resetFuturePricesForCurrentComponent() {
		const barchartSymbol = this.activeComponentChartFutures.map((future) => future.barchartSymbol);
		const filteredBarchartSymbols = barchartSymbol.filter((x): x is string => typeof x === 'string');
		this.scenarioPrices.setScenarioPricesToMarket(filteredBarchartSymbols);
	}

	@action
	onAddHypotheticalPositionSubmit(
		productSlug: string,
		positionType: PositionType,
		side: 'Bought' | 'Sold',
		contracts: number,
		pricePerPricingUnit: number,
		strikePrice: number | null,
		months: string[]
	) {
		months.forEach((month) => {
			const future = this.findNearestFuture(productSlug, month);

			if (!future) {
				console.warn(`Could not find future for given month and product: ${productSlug} - ${month}`);
				return;
			}

			const quantity = side === 'Bought' ? contracts : contracts * -1;
			const futureDisplayExpiresAt = future.displayExpiresAt;
			const instrumentType = positionType !== 'Futures (Forward)' ? TypeOfInstrument.Option : TypeOfInstrument.Future;
			const barchartSymbol = future.barchartSymbol ?? null;
			const optionType = ((positionType: PositionType) => {
				switch (positionType) {
					case 'Call':
						return TypeOfOption.Call;
					case 'Put':
						return TypeOfOption.Put;
					default:
						return null;
				}
			})(positionType);

			this._addedHypotheticalPositions.push(
				new HypotheticalPosition(
					getOwner(this),
					month,
					futureDisplayExpiresAt,
					productSlug,
					instrumentType,
					barchartSymbol,
					future.Product.StandardProductLotSpecification.pointValue ?? 0,
					future.Product.StandardProductLotSpecification.lotSize ?? 0,
					quantity,
					pricePerPricingUnit,
					0,
					0,
					optionType,
					strikePrice
				)
			);
		});

		this.storeAddedHypotheticalPositions(this.entityId, this._addedHypotheticalPositions);
	}

	generateScenarioMilkChecks(
		forecastedMilkProductionByMonths: ForecastedMilkProductionByMonth[],
		monthlyPremiumsAndDeductions: AggregateLedgerEntryDTO[]
	) {
		const startDateTime = DateTime.fromISO(this.startDate);
		const endDateTime = DateTime.fromISO(this.endDate);
		const months = Interval.fromDateTimes(startDateTime.startOf('month'), endDateTime.endOf('month'))
			.splitBy({ month: 1 })
			.map((d) => d.start);

		const milkChecksMap: Map<string, ScenarioMilkCheck> = new Map();

		const monthlyBasisValues = calculateMonthlyWeightedPricesAndBasisValues(
			this.startDate,
			this.endDate,
			forecastedMilkProductionByMonths,
			[]
		);
		const monthlyUtilizationValues = calculateUtilizationValuesFromForecastedProduction(forecastedMilkProductionByMonths);

		months.forEach((date) => {
			const dateString = date.toISODate();
			const advancedDateString = date.minus({ month: 1 }).toISODate();
			const basisValues = monthlyBasisValues.find((v) => v.date === date.toISODate());
			const utilizationValues = monthlyUtilizationValues.find((v) => v.date === date.toISODate());

			const utilizations = {
				classiUtilization: utilizationValues?.classIUtilization ?? 0,
				classiiUtilization: utilizationValues?.classIIUtilization ?? 0,
				classiiiUtilization: utilizationValues?.classIIIUtilization ?? 0,
				classivUtilization: utilizationValues?.classIVUtilization ?? 0,
			} as ForecastedMilkUtilization;

			let butterFuture: Future | null = null;
			let advancedButterFuture: Future | null = null;

			let cheeseFuture: Future | null = null;
			let advancedCheeseFuture: Future | null = null;

			let dryWheyFuture: Future | null = null;
			let advancedDryWheyFuture: Future | null = null;

			let nonfatDryMilkFuture: Future | null = null;
			let advancedNonfatDryMilkFuture: Future | null = null;

			this.model.getPriceScenariosData.data?.Futures.forEach((future) => {
				const productSlug = future.Product.slug;

				if (productSlug === 'us-dairy-butter' && !butterFuture && future.displayExpiresAt >= dateString) {
					butterFuture = future;
				} else if (productSlug === 'us-dairy-butter' && !advancedButterFuture && future.displayExpiresAt >= advancedDateString) {
					advancedButterFuture = future;
				} else if (productSlug === 'us-dairy-cheese' && !cheeseFuture && future.displayExpiresAt >= dateString) {
					cheeseFuture = future;
				} else if (productSlug === 'us-dairy-cheese' && !advancedCheeseFuture && future.displayExpiresAt >= advancedDateString) {
					advancedCheeseFuture = future;
				} else if (productSlug === 'us-dairy-dry-whey' && !dryWheyFuture && future.displayExpiresAt >= dateString) {
					dryWheyFuture = future;
				} else if (productSlug === 'us-dairy-dry-whey' && !advancedDryWheyFuture && future.displayExpiresAt >= advancedDateString) {
					advancedDryWheyFuture = future;
				} else if (productSlug === 'us-dairy-nonfat-milk' && !nonfatDryMilkFuture && future.displayExpiresAt >= dateString) {
					nonfatDryMilkFuture = future;
				} else if (
					productSlug === 'us-dairy-nonfat-milk' &&
					!advancedNonfatDryMilkFuture &&
					future.displayExpiresAt >= advancedDateString
				) {
					advancedNonfatDryMilkFuture = future;
				}
			});

			milkChecksMap.set(
				dateString,
				new ScenarioMilkCheck(
					getOwner(this),
					dateString,
					utilizations,
					basisValues?.grossProduction ?? 0,
					utilizationValues?.classIDifferential ?? 0,
					basisValues?.locationCurrentBasis ?? 0,
					butterFuture,
					advancedButterFuture,
					nonfatDryMilkFuture,
					advancedNonfatDryMilkFuture,
					cheeseFuture,
					advancedCheeseFuture,
					dryWheyFuture,
					advancedDryWheyFuture,
					utilizationValues?.otherSolidsPercentage ?? null,
					utilizationValues?.butterfatPercentage ?? null,
					utilizationValues?.proteinPercentage ?? null
				)
			);
		});

		monthlyPremiumsAndDeductions.forEach((aggregateLedgerForecastedMilkEntry) => {
			if (!aggregateLedgerForecastedMilkEntry.month || !aggregateLedgerForecastedMilkEntry.year) return;
			const date = DateTime.fromObject({
				month: aggregateLedgerForecastedMilkEntry.month,
				year: aggregateLedgerForecastedMilkEntry.year,
			}).toISODate();

			if (date) {
				const milkCheck = milkChecksMap.get(date);

				if (milkCheck) {
					milkCheck.monthlyPremiumsAndDeductions = { ...aggregateLedgerForecastedMilkEntry.sum } as AggregateLedgerEntryDTO;
				}
			}
		});

		return Array.from(milkChecksMap, ([_date, milkCheck]) => milkCheck).sortBy('date');
	}

	@action
	formatCurrency(val: number | null) {
		if (val === null) return '';
		return Intl.NumberFormat(undefined, { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(
			val
		);
	}

	@action
	formatDate(val: string | null) {
		if (val === null) return '';

		return DateTime.fromISO(val).toFormat('LLL yyyy');
	}

	findNearestFuture(productSlug: string, month: string) {
		// Futures are sorted from API, allowing a >= check for finding the nearest future
		return (
			this.model.getPriceScenariosData.data?.Futures.find((future) => {
				return future.Product.slug === productSlug && future.displayExpiresAt >= month;
			}) ?? null
		);
	}

	findQuarterFutures(productSlug: string, month: string) {
		const quarterStartDate = DateTime.fromISO(month).startOf('quarter').toISODate();

		return (
			this.model.getPriceScenariosData.data?.Futures.filter((future) => {
				return (
					future.Product.slug === productSlug &&
					DateTime.fromISO(future.displayExpiresAt).startOf('quarter').toISODate() === quarterStartDate
				);
			}) ?? []
		);
	}

	productUnitsPerContract(product: string) {
		switch (product) {
			case 'grain-corn':
				return 5000;
			case 'grain-soybean-meal':
				return 100;
			case 'us-dairy-dry-whey':
				return 44000;
			case 'us-dairy-nonfat-milk':
				return 44000;
			case 'us-dairy-cheese':
				return 20000;
			case 'us-dairy-butter':
				return 20000;
			case 'us-dairy-class-iii':
				return 200000;
			case 'us-dairy-class-iv':
				return 200000;
			default:
				return 44000;
		}
	}

	storeAddedHypotheticalPositions(entityId: string | null, hypotheticalPositions: HypotheticalPosition[]) {
		if (!entityId) return;
		const localStorageString = `price-scenarios-hypothetical-positions.${entityId}`;

		if (!checkStorageAvailable('localStorage')) return;

		const stringifiedPositions = JSON.stringify(hypotheticalPositions.map((position) => position.toJson()));

		window.localStorage.setItem(localStorageString, stringifiedPositions);
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	interface Registry {
		'reports/price-scenarios': ReportsPriceScenariosController;
	}
}
