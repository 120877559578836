/* eslint-disable prettier/prettier */
import Component from '@glimmer/component';

interface UiBannerArgs {
	layout: Layout;
	style: Style;
}

type Layout = 'horizontal' | 'vertical';
type Style = 'light' | 'contrast' | 'success' | 'error' | 'warning';

export default class UiBanner extends Component<UiBannerArgs> {
	_defaultLayout: Layout = 'horizontal';
	_defaultStyle: Style = 'light';

	get layout(): Layout {
		return this.args.layout ?? this._defaultLayout;
	}

	get style(): Style {
		return this.args.style ?? this._defaultStyle;
	}
}
