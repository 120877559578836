import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import resetVaultTableScroll from 'vault-client/utils/reset-vault-table-scroll';
import { ModelFrom } from 'vault-client/utils/type-utils';
import OrganizationPermissionGroups from 'vault-client/routes/organizations/organization/permission-groups';

type SortObj = {
	valuePath: string;
	isAscending: boolean;
};

export default class BusinessPermissionGroups extends Controller {
	declare model: ModelFrom<OrganizationPermissionGroups>;
	@service permissions: any;
	@service applicationScope: any;
	settingsRoutePath: string = 'organizations.organization.settings';
	queryParams = ['sorts'];

	@tracked sorts: SortObj[] = [{ valuePath: 'name', isAscending: true }];

	get columns() {
		const baseColumns = [
			{
				id: '278eeefa-4afb-405d-bcd6-25cf15c56305',
				name: 'Name',
				valuePath: 'name',
				minWidth: 225,
				textAlign: 'left',
				headerTextAlign: 'left',
				isSortable: true,
				cellComponent: 'String',
				isFixed: '',
				isVisible: true,
				linkRoute: 'organizations.organization.permission-group',
				linkModelPath: 'id',
			},
		];

		return baseColumns;
	}

	get sortParams() {
		return this.sorts;
	}

	set sortParams(sorts) {
		this.sorts = sorts;
	}

	get entityGroupsData() {
		return this.model?.data?.EntityGroups;
	}

	@action
	setTablePageState() {
		resetVaultTableScroll('entity-groups-index-table');
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'businesses/permission-groups': BusinessPermissionGroups;
	}
}
