import Component from '@glimmer/component';
import { gql, useQuery } from 'glimmer-apollo';
import { Buyer } from 'vault-client/types/graphql-types';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

const GET_GRAIN_BUYERS = gql`
	query Buyers {
		Buyers(orderBy: { name: Asc }) {
			id
			name
		}
	}
`;

interface GrainBuyerSelectArgs {
	selected: string;
	selectedId: string;
}

type BuyersQuery = {
	__typename?: 'Query';
	Buyers: Buyer[];
};

export default class GrainBuyerSelect extends Component<GrainBuyerSelectArgs> {
	@tracked buyers: Buyer[] | null = [];
	@tracked selected: string | Buyer | null | undefined = this.args.selected;

	get currentlySelected() {
		if (this.args.selected) {
			return this.args.selected;
		}

		if (this.args.selectedId) {
			return this.buyers?.find((buyer) => buyer.id == this.args.selectedId);
		}

		return null;
	}
	set currentlySelected(value) {
		this.selected = value;
	}

	@action
	async fetchBuyers() {
		await useQuery<BuyersQuery>(this, () => [
			GET_GRAIN_BUYERS,
			{
				onComplete: (data) => {
					if (data?.Buyers) {
						this.buyers = data.Buyers;
					} else {
						this.buyers = [];
					}
				},
				onError: (error) => {
					console.error('Error on Buyer select', error.message);
				},
			},
		]).promise;
	}
}
