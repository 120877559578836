import Route from '@ember/routing/route';
import { gql } from 'glimmer-apollo';
import query from 'vault-client/graphql/queries/exposure/show.graphql';
import { queryManager } from 'ember-apollo-client';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

const GET_FEED_TRANSACTIONS = gql`
	query FeedTransactions($scopeId: String!, $startDate: String!, $endDate: String!, $slug: String!) {
		FeedTransactions(
			scopeId: $scopeId
			where: {
				OR: [{ deliveryStartDate: { gte: $startDate, lte: $endDate } }, { deliveryEndDate: { gte: $startDate, lte: $endDate } }]
				FeedIngredient: { FeedCategory: { HedgeProduct: { slug: { equals: $slug } } } }
			}
		) {
			id
			tons
			perMonthData
			seller
			location
			flatPrice
			deliveryStartDate
			deliveryEndDate
			FeedIngredient {
				id
				name
			}
			Business {
				id
				name
			}
		}
	}
`;

export interface FeedTransactionQueryArgs {
	scopeId: string;
	slug: string;
	startDate: string | undefined;
	endDate: string | undefined;
}

export default class ExposureShowRoute extends Route {
	@service applicationScope: any;
	@queryManager apollo: any;
	@tracked FeedTransactionVariables: FeedTransactionQueryArgs = {
		scopeId: '',
		slug: '',
		startDate: '',
		endDate: '',
	};

	scopeId: string | null = null;

	templateName = 'exposure/show';

	query = query;
	feedTransactionQuery = GET_FEED_TRANSACTIONS;

	queryParams = {
		accountId: {
			refreshModel: true,
		},
		exposureMonthStartDate: {
			refreshModel: true,
		},
		exposureMonthEndDate: {
			refreshModel: true,
		},
		sorts: { refreshModel: true },
	};
}
