import Route from '@ember/routing/route';
import query from 'vault-client/graphql/queries/reports/historical-percentiles.graphql';
import { queryManager } from 'ember-apollo-client';

export default class ReportsCattleHistoricalPercentiles extends Route {
	@queryManager apollo: any;

	templateName: string = 'reports/cattle-historical-percentiles';

	async model(params: Array<string>) {
		const variables = {
			params: params,
			where: {
				slug: {
					in: ['livestock-live-cattle', 'livestock-feeder-cattle'],
				},
			},
		};

		return this.apollo.watchQuery({ query, variables });
	}
}
