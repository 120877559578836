import ReportsInventoryHedgesByProduct from 'vault-client/controllers/reports/inventory-hedges-by-product';

export default class OrganizationsOrganizationInventoryHedgesByProduct extends ReportsInventoryHedgesByProduct {
	reportsRoute: string = 'organizations.organization.reports';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'organizations/organization/inventory-hedges-by-product': OrganizationsOrganizationInventoryHedgesByProduct;
	}
}
