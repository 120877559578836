import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Vault::UiButton {{on 'click' this.toggleModal}} @style={{@style}} @size={{@size}}>{{yield}}</Vault::UiButton>\n<EditVgsCustomerModal @showModal={{this.showModal}} @onClose={{this.closeModal}} @onSubmit={{this.closeModal}} @customer={{@customer}} />", {"contents":"<Vault::UiButton {{on 'click' this.toggleModal}} @style={{@style}} @size={{@size}}>{{yield}}</Vault::UiButton>\n<EditVgsCustomerModal @showModal={{this.showModal}} @onClose={{this.closeModal}} @onSubmit={{this.closeModal}} @customer={{@customer}} />","moduleName":"vault-client/components/edit-vgs-customer-button.hbs","parseOptions":{"srcName":"vault-client/components/edit-vgs-customer-button.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from 'tracked-built-ins';
import { CustomerEntity } from 'vault-client/types/graphql-types';

interface EditVgsCustomerButtonArgs {
	customer: CustomerEntity;
}

export default class EditVgsCustomerButton extends Component<EditVgsCustomerButtonArgs> {
	@tracked showModal = false;

	@action
	openModal() {
		this.showModal = true;
	}

	@action
	closeModal() {
		this.showModal = false;
	}

	@action
	toggleModal() {
		if (this.showModal) {
			this.closeModal();
		} else {
			this.openModal();
		}
	}
}
