import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';

import { inject as service } from '@ember/service';
import AuthService from 'vault-client/services/auth';

export default class LoginRoute extends Route {
	@service declare auth: AuthService;
	@service declare router: RouterService;

	async beforeModel() {
		await this.auth.requireAuthentication().then(() => this.router.transitionTo('index'));
	}
}
