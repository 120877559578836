import ReportsDrpPerformanceByAIPRoute from 'vault-client/routes/reports/drp-performance-by-aip';
interface QueryParams {
	agent: string | null;
	stateId: string | null;
	reinsuranceYear: number | null;
	quarterStartDate: string | null;
	quarterEndDate: string | null;
}
export default class OrganizationsOrganizationDrpPerformanceByAip extends ReportsDrpPerformanceByAIPRoute {
	async model(params: QueryParams) {
		const organizationParams = this.paramsFor('organizations.organization') as { id: string };
		const variables = {
			endorsementWhere: this.generateWhere(
				organizationParams.id,
				null,
				params.quarterStartDate,
				params.quarterEndDate,
				params.agent,
				params.stateId,
				params.reinsuranceYear
			),
		};
		return this.apollo.watchQuery({ query: this.query, variables });
	}
}
