import Route from '@ember/routing/route';
import { gql } from 'glimmer-apollo';
import { tracked } from '@glimmer/tracking';
import {
	LrpInsuranceEndorsementFilterDTO,
	LrpInsuranceEndorsementSortByDTO,
	Count,
	AggregateLrpInsuranceEndorsementDTO,
	LrpInsuranceEndorsement,
} from 'vault-client/types/graphql-types';

type SortObj = {
	valuePath: string;
	isAscending: boolean;
};

export const GET_LRP_ENDORSEMENTS = gql`
	query LrpInsuranceEndorsements(
		$orderBy: LrpInsuranceEndorsementSortByDTO
		$where: LrpInsuranceEndorsementFilterDTO
		$offset: Float
		$limit: Float
	) {
		LrpInsuranceEndorsements(orderBy: $orderBy, where: $where, offset: $offset, limit: $limit) {
			id
			InsurancePolicy {
				producerName
				policyNumber
				agencyName
				agentFullName
				AIP {
					id
					name
				}
			}
			insurancePolicyId
			coverageEndDate
			coveragePrice
			headCount
			salesEffectiveDate
			targetWeightCwt
			insuredValue
			lengthInWeeks
			subsidyAmount
			totalPremiumAmount
			producerPremiumAmount
			indemnity
			pnl
			State {
				id
				abbreviation
				name
				rmaStateCode
			}
			RmaCommodity {
				id
				commodityName
			}
			RmaType {
				id
				typeName
			}
		}
		LrpInsuranceEndorsementCount(where: $where) {
			count
		}
		AggregateLrpInsuranceEndorsements(
			where: $where
			calc: {
				sum: { totalPremiumAmount: true, insuredValue: true, subsidyAmount: true, indemnity: true, pnl: true, producerPremiumAmount: true }
			}
			groupBy: {}
		) {
			sum {
				totalPremiumAmount
				insuredValue
				subsidyAmount
				indemnity
				pnl
				producerPremiumAmount
			}
		}
	}
`;

export type GetLRPEndorsementsQuery = {
	__typename?: 'Query';

	AggregateLrpInsuranceEndorsements: AggregateLrpInsuranceEndorsementDTO[];
	LrpInsuranceEndorsementCount: Count;
	LrpInsuranceEndorsements: LrpInsuranceEndorsement[];
};

export default class LRPInsuranceEndorsementsIndexRoute extends Route {
	@tracked errorMessage: string | null = null;

	templateName = 'lrp-insurance-endorsements/index';

	queryParams = {
		agent: { refreshModel: true },
		aipId: { refreshModel: true },
		customerId: { refreshModel: true },
		salesEffectiveStartDate: { refreshModel: true },
		salesEffectiveEndDate: { refreshModel: true },
		coverageEndStartDate: { refreshModel: true },
		coverageEndEndDate: { refreshModel: true },
		stateId: { refreshModel: true },
		page: { refreshModel: true },
		sorts: { refreshModel: true },
	};

	generateOrderBy(sorts: SortObj[]): LrpInsuranceEndorsementSortByDTO {
		const orderBy: {
			[key: string]: any;
		} = {};

		sorts.forEach((sort: any) => {
			const value = sort.isAscending ? 'Asc' : 'Desc';
			orderBy[sort.valuePath] = value;
		});

		return orderBy;
	}

	generateWhere(
		locationId: string | null,
		customerId: string | null,
		organizationId: string | null,
		aipId: string | null | undefined,
		agent: string | null | undefined,
		stateId: string | null | undefined,
		salesEffectiveStartDate: string | null | undefined,
		salesEffectiveEndDate: string | null | undefined,
		coverageEndStartDate: string | null | undefined,
		coverageEndEndDate: string | null | undefined
	) {
		//
		// set filtering
		//
		const where: LrpInsuranceEndorsementFilterDTO = {
			AsInsuranceEndorsement: {},
		};

		if (locationId) {
			where.Policy = {
				Customer: {
					Locations: {
						id: { equals: locationId },
					},
				},
			};
		} else if (customerId) {
			where.Policy = {
				customerId: { equals: customerId },
			};
		} else if (organizationId) {
			where.Policy = {
				OR: [
					{
						Customer: {
							organizationId: {
								equals: organizationId,
							},
						},
					},
					{
						ownerId: {
							equals: organizationId,
						},
					},
				],
			};
		}

		if (customerId) {
			if (!where.Policy) {
				where.Policy = {};
			}
			where.Policy.customerId = {
				equals: customerId,
			};
		}

		if (aipId) {
			if (!where.Policy) {
				where.Policy = {};
			}
			where.Policy.aipId = {
				equals: aipId,
			};
		}

		if (agent) {
			if (!where.Policy) {
				where.Policy = {};
			}
			where.Policy.agentFullName = {
				equals: agent,
			};
		}

		if (stateId) {
			if (!where.Policy) {
				where.Policy = {};
			}
			where.Policy.stateId = {
				equals: stateId,
			};
		}

		if (salesEffectiveStartDate && salesEffectiveEndDate) {
			if (!where.AND) {
				where.AND = [];
			}
			where.AND.push({
				salesEffectiveDate: { gte: salesEffectiveStartDate },
			});
			where.AND.push({
				salesEffectiveDate: { lte: salesEffectiveEndDate },
			});
		} else if (salesEffectiveStartDate) {
			where.salesEffectiveDate = {
				gte: salesEffectiveStartDate,
			};
		} else if (salesEffectiveEndDate) {
			where.salesEffectiveDate = {
				lte: salesEffectiveEndDate,
			};
		}

		if (coverageEndStartDate && coverageEndEndDate) {
			if (!where.AND) {
				where.AND = [];
			}
			where.AND.push({
				coverageEndDate: { gte: coverageEndStartDate },
			});
			where.AND.push({
				coverageEndDate: { lte: coverageEndEndDate },
			});
		} else if (coverageEndStartDate) {
			where.coverageEndDate = {
				gte: coverageEndStartDate,
			};
		} else if (coverageEndEndDate) {
			where.salesEffectiveDate = {
				lte: coverageEndEndDate,
			};
		}

		return where;
	}
}
