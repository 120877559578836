import { tracked } from '@glimmer/tracking';
import Target from './foundation-target';
import Fill from './foundation-fill';

export default class FoundationPlan {
	@tracked id;
	@tracked acres;
	@tracked aph;
	@tracked breakeven;
	@tracked goal;
	@tracked enrolledPercent;
	@tracked harvestBu;
	@tracked storageBu;

	@tracked Crop;
	@tracked CropYear;
	@tracked Customer;
	@tracked Fills;
	@tracked Targets;
	@tracked FeedTargets: any;

	@tracked tons: any;
	@tracked startDate: any;
	@tracked Ingredient: any;
	FoundationsCustomer: any;

	constructor(production: any) {
		this.id = production.id;
		this.acres = production.acres;
		this.aph = production.aph;
		this.breakeven = production.breakeven;
		this.goal = production.goal;
		this.enrolledPercent = production.enrolledPercent;
		this.harvestBu = production.harvestBu;
		this.storageBu = production.storageBu;

		this.Crop = production.Crop;
		this.CropYear = production.CropYear;
		this.Customer = production.Customer;
		this.FoundationsCustomer = production.FoundationsCustomer;

		this.Fills = production.Fills?.map((fill: any) => {
			return new Fill(fill);
		}).sort((a: any, b: any) => (a.delivery_start_date > b.delivery_start_date ? 1 : -1));
		this.Targets = production.Targets?.map((target: any) => {
			return new Target(target);
		});
	}

	get production() {
		return this.acres * this.aph;
	}

	get enrolledProduction() {
		return this.production * this.enrolledPercent;
	}

	get bushelsFilled() {
		return this.Fills.reduce((acc: any, cur: any) => {
			return acc + cur.bushels;
		}, 0);
	}

	get fillsWithFuturePrices() {
		return this.Fills.filter((fill: any) => {
			return fill.future_price;
		});
	}

	get fillsWithFlatPrices() {
		return this.Fills.filter((fill: any) => {
			return fill.flat_price;
		});
	}

	get percentSold() {
		const bushelsSold = this.Fills.reduce((acc: any, cur: any) => {
			return acc + cur.bushels;
		}, 0);

		return bushelsSold / this.production;
	}

	get averageFuturePriceSold() {
		let revenue = 0;
		let bushels = 0;

		this.fillsWithFuturePrices.forEach((fill: any) => {
			bushels += fill.bushels;
			revenue += fill.bushels * fill.future_price;
		});

		if (bushels) {
			return revenue / bushels;
		} else {
			return null;
		}
	}

	get averageFlatPriceSold() {
		let revenue = 0;
		let bushels = 0;

		this.fillsWithFlatPrices.forEach((fill: any) => {
			bushels += fill.bushels;
			revenue += fill.bushels * fill.flat_price;
		});

		if (bushels) {
			return revenue / bushels;
		} else {
			return null;
		}
	}

	get sumGrossRevenue() {
		return this.Fills.reduce((acc: any, cur: any) => {
			return acc + cur.grossRevenue;
		}, 0);
	}
}
