import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { CellComponents, TableColumn } from 'vault-client/types/vault-table';
import { ModelFrom } from 'vault-client/utils/type-utils';
import route from 'vault-client/routes/vgs/settings/permissions';
import PermissionsService from 'vault-client/services/permissions';

export default class VgsSettingsPermissions extends Controller {
	declare model: ModelFrom<route>;

	@service declare permissions: PermissionsService;
	@tracked sorts = [{ valuePath: 'name', isAscending: true }];
	@tracked permissionGroupIdToRemove: string | null = null;
	@tracked showError: boolean = false;

	get columns() {
		const baseColumns: TableColumn[] = [
			{
				id: '9977b936-f768-4172-99b9-8fb8d40ff396',
				name: 'Group Name',
				valuePath: 'name',
				minWidth: 120,
				textAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '8c8e8165-4e42-49ce-b5bb-a10745c5dac4',
				name: '',
				valuePath: 'id',
				minWidth: 130,
				textAlign: 'right',
				isSortable: false,
				cellComponent: CellComponents.Button,
				componentArgs: {
					size: 'xs',
					style: 'secondary',
					text: 'Remove',
					fn: this.removePermissionGroup,
				},
				isFixed: '',
				isVisible: true,
			},
		];
		return baseColumns;
	}

	get rows() {
		return this.model.getPermissionGroups.data?.EntityGroups;
	}

	get organizationId() {
		const organizations = this.model.getPermissionGroups.data?.Organizations;
		return organizations?.length === 1 ? organizations[0].id : null;
	}

	@action
	removePermissionGroup(row: any) {
		this.permissionGroupIdToRemove = row.id;
	}

	@action
	closeRemovePermissionGroupModal() {
		this.permissionGroupIdToRemove = null;
	}

	@action
	toggleShowError() {
		this.showError = !this.showError;
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	interface Registry {
		'vgs/settings/permissions': VgsSettingsPermissions;
	}
}
