import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @target}}\n\t<Vault::UiConfirmation as |confirmation|>\n\t\t<confirmation.title>Remove Target</confirmation.title>\n\t\t<confirmation.body>Are you sure that you want to remove this Target?\n\t\t\t<br />\n\t\t\t<span class='font-sans-semibold'>\n\t\t\t\t{{@target.CropYear.year}} {{@target.Crop.name}}\n\t\t\t</span>\n\t\t\t<br />\n\t\t\tYou cannot undo this action\n\t\t</confirmation.body>\n\t\t<confirmation.cancel @style='plain' {{on 'click' this.closeConfirmation}}>Cancel</confirmation.cancel>\n\t\t<confirmation.action\n\t\t\t@style='destructive'\n\t\t\t@disabled={{this.submitting}}\n\t\t\t{{on 'click' this.submitDeleteTarget}}\n\t\t>Remove</confirmation.action>\n\t</Vault::UiConfirmation>\n{{/if}}", {"contents":"{{#if @target}}\n\t<Vault::UiConfirmation as |confirmation|>\n\t\t<confirmation.title>Remove Target</confirmation.title>\n\t\t<confirmation.body>Are you sure that you want to remove this Target?\n\t\t\t<br />\n\t\t\t<span class='font-sans-semibold'>\n\t\t\t\t{{@target.CropYear.year}} {{@target.Crop.name}}\n\t\t\t</span>\n\t\t\t<br />\n\t\t\tYou cannot undo this action\n\t\t</confirmation.body>\n\t\t<confirmation.cancel @style='plain' {{on 'click' this.closeConfirmation}}>Cancel</confirmation.cancel>\n\t\t<confirmation.action\n\t\t\t@style='destructive'\n\t\t\t@disabled={{this.submitting}}\n\t\t\t{{on 'click' this.submitDeleteTarget}}\n\t\t>Remove</confirmation.action>\n\t</Vault::UiConfirmation>\n{{/if}}","moduleName":"vault-client/components/vgs-delete-target-modal.hbs","parseOptions":{"srcName":"vault-client/components/vgs-delete-target-modal.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { gql, useMutation } from 'glimmer-apollo';
import { GrainOrder, DeletedResource } from 'vault-client/types/graphql-types';

interface DeleteTargetModalArgs {
	target: GrainOrder | null;
	closeModalFn: Function;
}

interface DeleteTargetMutationArgs {
	id: string;
}

const DELETE_TARGET = gql`
	mutation DeleteGrainTargetOrder($id: String!) {
		deleteGrainTargetOrder(id: $id) {
			id
		}
	}
`;

export default class VgsDeleteTargetModalComponent extends Component<DeleteTargetModalArgs> {
	@tracked submitting = false;

	deleteTarget = useMutation<DeletedResource, DeleteTargetMutationArgs>(this, () => [
		DELETE_TARGET,
		{
			update: (cache) => {
				cache.evict({ fieldName: 'GrainTargetOrder' });
				cache.evict({ fieldName: 'GrainTargetOrders' });
				cache.evict({ fieldName: 'GrainCropPlan' });
				cache.evict({ fieldName: 'GrainCropPlans' });
				cache.evict({ fieldName: 'GrainFeedPlan' });
				cache.evict({ fieldName: 'GrainFeedPlans' });
				cache.gc();
			},
		},
	]);

	@action
	async submitDeleteTarget() {
		this.submitting = true;
		const { id } = this.args.target ?? {};

		if (id) {
			await this.deleteTarget.mutate({
				id,
			});
		}

		this.closeConfirmation();
		this.submitting = false;
	}

	@action
	closeConfirmation() {
		this.args.closeModalFn();
	}
}
