import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @entityGroupEntryId}}\n\t<Vault::UiConfirmation as |confirmation|>\n\t\t<span {{did-insert this.fetchEntityGroupEntry @entityGroupEntryId}}></span>\n\t\t<confirmation.title>Remove Confirmation</confirmation.title>\n\t\t<confirmation.body>Are you sure you want to remove this permission group?\n\t\t\t<br />({{this.entityGroupEntry.EntityGroupEntry.Group.name}})<br />\n\t\t\tThis action is irreversible.\n\t\t\t<br />\n\n\t\t</confirmation.body>\n\t\t{{#if this.errorMessage}}\n\t\t\t<confirmation.error>{{this.errorMessage}}</confirmation.error>\n\t\t{{/if}}\n\t\t<confirmation.cancel @style='plain' {{on 'click' this.closeConfirmation}}>Cancel</confirmation.cancel>\n\t\t<confirmation.action @style='destructive' {{on 'click' this.removeEntityGroupEntry}}>Remove Permission Group</confirmation.action>\n\t</Vault::UiConfirmation>\n{{/if}}", {"contents":"{{#if @entityGroupEntryId}}\n\t<Vault::UiConfirmation as |confirmation|>\n\t\t<span {{did-insert this.fetchEntityGroupEntry @entityGroupEntryId}}></span>\n\t\t<confirmation.title>Remove Confirmation</confirmation.title>\n\t\t<confirmation.body>Are you sure you want to remove this permission group?\n\t\t\t<br />({{this.entityGroupEntry.EntityGroupEntry.Group.name}})<br />\n\t\t\tThis action is irreversible.\n\t\t\t<br />\n\n\t\t</confirmation.body>\n\t\t{{#if this.errorMessage}}\n\t\t\t<confirmation.error>{{this.errorMessage}}</confirmation.error>\n\t\t{{/if}}\n\t\t<confirmation.cancel @style='plain' {{on 'click' this.closeConfirmation}}>Cancel</confirmation.cancel>\n\t\t<confirmation.action @style='destructive' {{on 'click' this.removeEntityGroupEntry}}>Remove Permission Group</confirmation.action>\n\t</Vault::UiConfirmation>\n{{/if}}","moduleName":"vault-client/components/remove-entity-group-entry-modal.hbs","parseOptions":{"srcName":"vault-client/components/remove-entity-group-entry-modal.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import GET_ENTITY_GROUP_ENTRY from 'vault-client/graphql/queries/entity-group-entry-get.graphql';
import { tracked } from '@glimmer/tracking';
import { gql, useMutation, useQuery } from 'glimmer-apollo';
import { EntityGroupEntry, Mutation_deleteEntityGroupEntryArgs, Query_EntityGroupEntryArgs } from 'vault-client/types/graphql-types';
interface RemoveEntityGroupEntryModalArgs {
	entityGroupEntryId: string;
	closeModalFn: Function;
	model: any;
}

const DELETE_ENTITY_GROUP_ENTRY = gql`
	mutation deleteEntityGroupEntry($id: String!) {
		deleteEntityGroupEntry(id: $id) {
			id
		}
	}
`;

type DeleteEntityGroupEntryMutation = {
	__typename?: 'Mutation';

	deleteEntityGroupEntry?: {
		id: string;
	} | null;
};

type GetEntityGroupEntry = {
	__typename?: 'Query';

	entityGroupEntry?: EntityGroupEntry | null;
};

export default class RemoveEntityGroupEntryModal extends Component<RemoveEntityGroupEntryModalArgs> {
	@tracked entityGroupEntry: any;
	@tracked errorMessage: string = '';

	deleteEntityGroupEntry = useMutation<DeleteEntityGroupEntryMutation, Mutation_deleteEntityGroupEntryArgs>(this, () => [
		DELETE_ENTITY_GROUP_ENTRY,
		{
			/* options */
		},
	]);

	getEntityGroupEntry = useQuery<GetEntityGroupEntry, Query_EntityGroupEntryArgs>(this, () => [
		GET_ENTITY_GROUP_ENTRY,
		{
			variables: { id: this.args.entityGroupEntryId },
		},
	]);

	@action
	async fetchEntityGroupEntry() {
		await this.getEntityGroupEntry.promise;
		this.entityGroupEntry = this.getEntityGroupEntry.data;
	}

	@action
	async removeEntityGroupEntry() {
		this.errorMessage = '';
		try {
			await this.deleteEntityGroupEntry.mutate({
				id: this.args.entityGroupEntryId,
			});
			await this.args.model.refetch();
			this.closeConfirmation();
		} catch (error) {
			console.error('Failed to remove entity group entry:', error.message);
			this.errorMessage = 'Failed to remove entity group entry: ' + error.message;
		}
	}

	@action
	closeConfirmation() {
		this.errorMessage = '';
		this.args.closeModalFn();
	}
}
