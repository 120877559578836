import LivestockFeedOrdersRoute from 'vault-client/routes/livestock-feed/orders';
import { FeedIngredient, Seller } from 'vault-client/types/graphql-types';

interface ModelParams {
	startDate: string;
	endDate: string;
	selectedVendorId: Seller | null;
	selectedFeedIngredientId: FeedIngredient | null;
}

export default class BusinessesBusinessFeedOrdersRoute extends LivestockFeedOrdersRoute {
	async model(_params: ModelParams) {
		const { business_id: businessId } = this.paramsFor('businesses.business') as { business_id: string };
		this.variables = {
			feedIngredientsWhere: this.generateFeedIngredientsWhere(businessId),
		};

		await this.getFeedOrders.promise;

		return {
			getFeedOrders: this.getFeedOrders,
			businessId,
		};
	}
}
