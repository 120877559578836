import DrpPerformanceBySalesEffectiveDateMonth from 'vault-client/controllers/reports/drp-performance-by-sales-effective-date-month';

export default class BusinessesBusinessDrpPerformanceBySalesEffectiveDateMonth extends DrpPerformanceBySalesEffectiveDateMonth {
	reportsRoute: string = 'businesses.business.reports';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'businesses/businesss/drp-performance-by-sales-effective-date-month': BusinessesBusinessDrpPerformanceBySalesEffectiveDateMonth;
	}
}
