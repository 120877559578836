import Route from '@ember/routing/route';
import query from 'vault-client/graphql/queries/reports/historical-percentiles-by-product.graphql';
import { queryManager } from 'ember-apollo-client';
import { Future } from 'vault-client/types/graphql-types';
import { getOwner } from '@ember/application';
import HistoricalPercentilesByProductMonth from 'vault-client/models/historical-percentiles-by-product-month';

interface queryParams {
	productId: String;
}

export default class ReportsHistoricalPercentilesByProductRoute extends Route {
	@queryManager apollo: any;

	queryParams = {
		productId: { refreshModel: true },
	};

	templateName: string = 'reports/historical-percentiles-by-product';

	async model(params: queryParams) {
		let variables;
		if (params.productId) {
			variables = {
				where: {
					id: {
						equals: params.productId,
					},
				},
			};
		} else {
			variables = {
				where: {
					slug: {
						equals: 'us-dairy-class-iii',
					},
				},
			};
		}

		return this.apollo.watchQuery({ query, variables });
	}

	setupController(controller: any, model: any, transition: any) {
		super.setupController(controller, model, transition);
		const product = model.Products[0];
		const months = product.CurrentFutures.map((future: Future) => {
			return new HistoricalPercentilesByProductMonth(getOwner(this), product, future);
		});

		controller.months = months;
	}
}
