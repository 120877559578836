import Controller from '@ember/controller';
import StrategicInventoryModel from 'vault-client/models/strategic-inventory-model';
import { getOwner } from '@ember/application';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
//@ts-ignore
import { Product } from 'types/graphql-types';
import isTouchDevice from 'vault-client/utils/is-touch-device';
import { TableColumn, CellComponents } from 'vault-client/types/vault-table';
import { ModelFrom } from 'vault-client/utils/type-utils';
import ReportsNfdmStrategicInventoryModel from 'vault-client/routes/reports/nfdm-strategic-inventory-model';

interface HtmlElement {
	target: HTMLInputElement;
}

export default class ReportsNFDMStrategicInventoryModel extends Controller {
	@tracked carryCost: number = 0.01;
	@tracked currentPriceInput: string = 'Front Future Price';
	@tracked numberOfYears: number = 5;
	declare model: ModelFrom<ReportsNfdmStrategicInventoryModel>;
	reportsRoute: string = '';
	get columns(): TableColumn[] {
		const baseColumns: TableColumn[] = [
			{
				id: '8619684d-d6ab-47c8-af57-7831e392b7cd',
				name: 'Month',
				valuePath: 'displayExpiresAt',
				minWidth: 90,
				cellComponent: CellComponents.MonthFormat,
				textAlign: 'left',
				isSortable: false,
				isFixed: !isTouchDevice() ? 'left' : '',
				isVisible: true,
			},
			{
				id: '6ed982cf-43c7-4246-bbdf-fe9004c7368c',
				name: 'Futures',
				valuePath: 'currentFuturePrice',
				minWidth: 110,
				cellComponent: CellComponents.PriceFormat,
				componentArgs: {
					fractionDigitsPath: 'product.MostCurrentFuture.SymbolGroup.fractionDigits',
					displayFactorPath: 'noDisplayFactor',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'b5214144-f5f0-4285-b8f1-05897b3877b5',
				name: 'Futures - Carry',
				valuePath: 'futuresMinusCarryCost',
				minWidth: 150,
				cellComponent: CellComponents.PriceFormat,
				componentArgs: {
					fractionDigitsPath: 'product.MostCurrentFuture.SymbolGroup.fractionDigits',
					displayFactorPath: 'noDisplayFactor',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'f26b1ee5-5348-4016-9586-bee5349e5d4d',
				name: 'Current',
				valuePath: 'currentPrice',
				minWidth: 120,
				cellComponent: CellComponents.PriceFormat,
				componentArgs: {
					fractionDigitsPath: 'product.MostCurrentFuture.SymbolGroup.fractionDigits',
					displayFactorPath: 'noDisplayFactor',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '6c28e040-1ef8-4509-a7c9-d50066e4ffa4',
				name: '1 Mo Carry Incentive',
				valuePath: 'oneMonthCarryIncentive',
				minWidth: 200,
				cellComponent: CellComponents.PriceFormat,
				componentArgs: {
					fractionDigitsPath: 'product.MostCurrentFuture.SymbolGroup.fractionDigits',
					displayFactorPath: 'noDisplayFactor',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '3ae346c1-2adc-4758-8732-bfda5f00e01b',
				name: 'Strategy',
				valuePath: 'riskManagementStrategy',
				minWidth: 120,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
		];
		return baseColumns;
	}

	prevSims: {
		oneMonthCarryIncentive: number | null;
		displayExpiresAt: string;
		symbol: string;
	}[] = [];

	get spotProduct() {
		const products: Product[] = this.model.Products;
		const cashProduct = products?.find((product: Product) => product?.slug === 'us-dairy-grade-a-non-fat-dry-milk');
		return cashProduct ?? null;
	}

	get futuresProduct() {
		const products: Product[] = this.model.Products;
		return products?.find((product) => product.slug === 'us-dairy-nonfat-milk');
	}

	get frontFuture() {
		return this.futuresProduct?.CurrentFutures[0];
	}

	get months() {
		const owner = getOwner(this);
		const product = this.futuresProduct;
		return product?.CurrentFutures?.reduce((acc: any, future: any) => {
			const previousSims = acc.slice(-2);
			const currentSim = new StrategicInventoryModel(
				owner,
				product,
				future,
				this.carryCost,
				previousSims,
				this.spotProduct,
				this.currentPriceInput,
				this.numberOfYears
			);

			acc.push(currentSim);
			return acc;
		}, []);
	}

	@action
	currentPriceRadioInput(e: HtmlElement) {
		return (this.currentPriceInput = e.target.value);
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'reports/strategic-inventory-model': ReportsNFDMStrategicInventoryModel;
	}
}
