import Route from '@ember/routing/route';
import query from 'vault-client/graphql/queries/insurance-overview/show.graphql';
import { queryManager } from 'ember-apollo-client';
import { DateTime } from 'luxon';

interface RouteParams {
	quarter_start_date: string;
}

export default class BusinessesBusinessInsuranceOverviewShowRoute extends Route {
	@queryManager apollo: any;

	async model(params: RouteParams) {
		const businessParams = this.paramsFor('businesses.business') as { business_id: string };

		const quarterStartDate = params.quarter_start_date;
		const quarterEndDate = DateTime.fromISO(params.quarter_start_date).endOf('quarter');

		let productionWhere = {};
		let endorsementWhere = {};
		if (businessParams.business_id) {
			productionWhere = {
				Location: { customerId: { equals: businessParams.business_id } },
				AND: [{ date: { gte: quarterStartDate } }, { date: { lte: quarterEndDate } }],
			};
			endorsementWhere = {
				InsurancePolicy: {
					customerId: { equals: businessParams.business_id },
				},
				quarterStartDate: { equals: quarterStartDate },
			};
		}

		const variables = { productionWhere, endorsementWhere };

		const response = await this.apollo.watchQuery({ query, variables });
		response['quarterStartDate'] = params.quarter_start_date;
		response['businessId'] = businessParams['business_id'];
		return response;
	}
}
