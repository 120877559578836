import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @fixedFeedCategory}}\n\t<Vault::UiConfirmation as |confirmation|>\n\t\t<confirmation.title>Delete Category</confirmation.title>\n\t\t<confirmation.body>Are you sure you want to delete this category?<br />\n\t\t\t<span class='font-sans-semibold'>\n\t\t\t\t{{@fixedFeedCategory.name}}\n\t\t\t</span>\n\t\t\t<br />This action is irreversible\n\t\t</confirmation.body>\n\t\t<confirmation.cancel @style='plain' {{on 'click' this.closeConfirmation}}>Cancel</confirmation.cancel>\n\t\t<confirmation.action @style='destructive' {{on 'click' this.deleteFixedFeedCategory}}>Delete Category</confirmation.action>\n\t</Vault::UiConfirmation>\n{{/if}}", {"contents":"{{#if @fixedFeedCategory}}\n\t<Vault::UiConfirmation as |confirmation|>\n\t\t<confirmation.title>Delete Category</confirmation.title>\n\t\t<confirmation.body>Are you sure you want to delete this category?<br />\n\t\t\t<span class='font-sans-semibold'>\n\t\t\t\t{{@fixedFeedCategory.name}}\n\t\t\t</span>\n\t\t\t<br />This action is irreversible\n\t\t</confirmation.body>\n\t\t<confirmation.cancel @style='plain' {{on 'click' this.closeConfirmation}}>Cancel</confirmation.cancel>\n\t\t<confirmation.action @style='destructive' {{on 'click' this.deleteFixedFeedCategory}}>Delete Category</confirmation.action>\n\t</Vault::UiConfirmation>\n{{/if}}","moduleName":"vault-client/components/delete-fixed-feed-category-button.hbs","parseOptions":{"srcName":"vault-client/components/delete-fixed-feed-category-button.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { gql, useMutation } from 'glimmer-apollo';
import { DeletedResource, LedgerFeedCategory } from 'vault-client/types/graphql-types';

const DELETE_LEDGER_FEED_CATEGORY = gql`
	mutation DeleteLedgerFeedCategory($id: String!) {
		deleteLedgerFeedCategory(id: $id) {
			id
		}
	}
`;

type DeleteFixedFeedCategoryButtonComponentArgs = {
	fixedFeedCategory: LedgerFeedCategory;
	closeModalFn: Function;
};

type DeleteFixedFeedCostMutationArgs = {
	id: string;
};
export default class DeleteFixedFeedCategoryButtonComponent extends Component<DeleteFixedFeedCategoryButtonComponentArgs> {
	@tracked showConfirmation = false;

	deleteFixedFeedMutation = useMutation<DeletedResource, DeleteFixedFeedCostMutationArgs>(this, () => [
		DELETE_LEDGER_FEED_CATEGORY,
		{
			update: (cache) => {
				cache.evict({ fieldName: 'LedgerFeedCategory' });
				cache.evict({ fieldName: 'LedgerFeedCategories' });

				cache.gc();
			},
		},
	]);

	@action
	closeConfirmation() {
		this.args.closeModalFn();
	}

	@action
	async deleteFixedFeedCategory() {
		await this.deleteFixedFeedMutation.mutate({ id: this.args.fixedFeedCategory.id });
		this.closeConfirmation();
	}
}
