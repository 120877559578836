import UnmappedInsurancePoliciesController from 'vault-client/controllers/organizations/organization/unmapped-insurance-policies';

export default class BusinessesBusinessUnmappedInsurancePoliciesController extends UnmappedInsurancePoliciesController {
	reportsRoutePath: string = 'businesses.business.reports';
	insurancePolicyRoutePath: string = 'businesses.business.insurance-policy';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'businesses/business/unmapped-insurance-policies': BusinessesBusinessUnmappedInsurancePoliciesController;
	}
}
