import { setOwner } from '@ember/application';
import { guidFor } from '@ember/object/internals';
import { service } from '@ember/service';
import ScenarioPricesService from 'vault-client/services/scenario-prices';
import { AggregateFeedIngredientForecastedUsageDTO } from 'vault-client/types/graphql-types';

export default class ScenarioFeedExpenses {
	LBS_PER_TON = 2000; // Short ton

	id: string;
	date: string;
	feedUsage: AggregateFeedIngredientForecastedUsageDTO[] = [];
	soybeanMealFutureBarchartSymbol: string | null = null;
	cornFutureBarchartSymbol: string | null = null;

	@service declare scenarioPrices: ScenarioPricesService;

	constructor(owner: any, date: string, soybeanMealFutureBarchartSymbol: string | null, cornFutureBarchartSymbol: string | null) {
		setOwner(this, owner);
		this.id = guidFor(this);
		this.date = date;
		this.soybeanMealFutureBarchartSymbol = soybeanMealFutureBarchartSymbol;
		this.cornFutureBarchartSymbol = cornFutureBarchartSymbol;
	}

	get scenarioCornFuturePrice() {
		if (this.cornFutureBarchartSymbol == null) return null;

		return this.scenarioPrices.getScenarioPrice(this.cornFutureBarchartSymbol);
	}

	get marketCornFuturePrice() {
		if (this.cornFutureBarchartSymbol == null) return null;

		return this.scenarioPrices.getMarketPrice(this.cornFutureBarchartSymbol);
	}

	get scenarioSoybeanMealFuturePrice() {
		if (this.soybeanMealFutureBarchartSymbol == null) return null;

		return this.scenarioPrices.getScenarioPrice(this.soybeanMealFutureBarchartSymbol);
	}

	get marketSoybeanMealFuturePrice() {
		if (this.soybeanMealFutureBarchartSymbol == null) return null;

		return this.scenarioPrices.getMarketPrice(this.soybeanMealFutureBarchartSymbol);
	}

	get scenarioFeedExpenses() {
		return this.scenarioCornExpenses + this.scenarioSoybeanMealExpenses;
	}

	get marketFeedExpenses() {
		return this.marketCornExpenses + this.marketSoybeanMealExpenses;
	}

	get scenarioCornExpenses() {
		const cornFuturePrice = this.scenarioCornFuturePrice;
		if (!cornFuturePrice) return 0;

		const cornExpensesInDollars = this.getCornExpenses(cornFuturePrice);
		return cornExpensesInDollars;
	}

	get marketCornExpenses() {
		const cornFuturePrice = this.marketCornFuturePrice;
		if (!cornFuturePrice) return 0;

		const cornExpensesInDollars = this.getCornExpenses(cornFuturePrice);
		return cornExpensesInDollars;
	}

	get scenarioSoybeanMealExpenses() {
		const soybeanMealFuturePrice = this.scenarioSoybeanMealFuturePrice;
		if (!soybeanMealFuturePrice) return 0;

		const soybeanMealExpensesInDollars = this.getSoybeanMealExpenses(soybeanMealFuturePrice);
		return soybeanMealExpensesInDollars;
	}

	get marketSoybeanMealExpenses() {
		const soybeanMealFuturePrice = this.marketSoybeanMealFuturePrice;
		if (!soybeanMealFuturePrice) return 0;

		const soybeanMealExpensesInDollars = this.getSoybeanMealExpenses(soybeanMealFuturePrice);
		return soybeanMealExpensesInDollars;
	}

	getCornExpenses(cornFuturePrice: number) {
		const lbsPerBushel = 56;
		const expenses = this.feedUsage
			.filter((usage) => usage.FeedIngredient?.FeedCategory?.HedgeProduct?.slug === 'grain-corn')
			.reduce((total, usage) => {
				const usageInLbs = usage.sum.dmiUsageInLb ?? 0;
				const cmePercentageBasis =
					usage.FeedIngredient?.cmePercentageBasis ?? usage.FeedIngredient?.FeedCategory?.defaultCmePercentageBasis;
				const cmeUsdBasis = usage.FeedIngredient?.cmeUsdBasis ?? usage.FeedIngredient?.FeedCategory?.defaultCmeUsdBasis;
				const flatPricePerTon = usage.FeedIngredient?.flatPricePerTon ?? usage.FeedIngredient?.FeedCategory?.defaultFlatPricePerTon;

				if (flatPricePerTon) {
					total += flatPricePerTon * (usageInLbs / this.LBS_PER_TON);
					return total;
				}

				let centsPerBushel;

				if (cmePercentageBasis) {
					centsPerBushel = cornFuturePrice * cmePercentageBasis;
				} else if (cmeUsdBasis) {
					centsPerBushel = cornFuturePrice + cmeUsdBasis;
				} else {
					centsPerBushel = cornFuturePrice;
				}

				const centsPerLb = centsPerBushel / lbsPerBushel;
				const dollarsPerLb = centsPerLb / 100;
				total += usageInLbs * dollarsPerLb;
				return total;
			}, 0);

		return expenses;
	}

	getSoybeanMealExpenses(soybeanMealFuturePrice: number) {
		const expenses = this.feedUsage
			.filter((usage) => usage.FeedIngredient?.FeedCategory?.HedgeProduct?.slug === 'grain-soybean-meal')
			.reduce((total, usage) => {
				const usageInLbs = usage.sum.dmiUsageInLb ?? 0;
				const cmePercentageBasis =
					usage.FeedIngredient?.cmePercentageBasis ?? usage.FeedIngredient?.FeedCategory?.defaultCmePercentageBasis;
				const cmeUsdBasis = usage.FeedIngredient?.cmeUsdBasis ?? usage.FeedIngredient?.FeedCategory?.defaultCmeUsdBasis;
				const flatPricePerTon = usage.FeedIngredient?.flatPricePerTon ?? usage.FeedIngredient?.FeedCategory?.defaultFlatPricePerTon;

				if (flatPricePerTon) {
					total += flatPricePerTon * (usageInLbs / this.LBS_PER_TON);
					return total;
				}

				let dollarsPerTon;

				if (cmePercentageBasis) {
					dollarsPerTon = soybeanMealFuturePrice * cmePercentageBasis;
				} else if (cmeUsdBasis) {
					dollarsPerTon = soybeanMealFuturePrice + cmeUsdBasis;
				} else {
					dollarsPerTon = soybeanMealFuturePrice;
				}

				total += (usageInLbs / this.LBS_PER_TON) * dollarsPerTon;
				return total;
			}, 0);

		return expenses;
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
//@ts-ignore
declare module 'ember-data/types/registries/model' {
	export default interface ModelRegistry {
		'scenario-feed-expenses': ScenarioFeedExpenses;
	}
}
