import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if (and this.rows (gt this.rows.length 0))}}\n\t<Vault::UiCard class='mx-3 mb-3 contract-specs' as |card|>\n\t\t<card.header as |header|>\n\t\t\t<header.title>Contract Specs</header.title>\n\t\t</card.header>\n\t\t<card.body as |body|>\n\t\t\t<body.content>\n\t\t\t\t{{#if this.isLoading}}\n\t\t\t\t\t<Vault::UiLoadingSpinner />\n\t\t\t\t{{else}}\n\t\t\t\t\t<VaultTable @id='{{this.uid}}-contract-specs' @rows={{this.rows}} @columns={{this.columns}} @tableRoute='contract-specs' as |vt|>\n\t\t\t\t\t\t<vt.emptyStateWell>\n\t\t\t\t\t\t\t<:headerMessage>No Contract Specs Found</:headerMessage>\n\t\t\t\t\t\t</vt.emptyStateWell>\n\t\t\t\t\t\t<vt.belowTable />\n\t\t\t\t\t</VaultTable>\n\t\t\t\t{{/if}}\n\t\t\t</body.content>\n\t\t</card.body>\n\t</Vault::UiCard>\n{{/if}}", {"contents":"{{#if (and this.rows (gt this.rows.length 0))}}\n\t<Vault::UiCard class='mx-3 mb-3 contract-specs' as |card|>\n\t\t<card.header as |header|>\n\t\t\t<header.title>Contract Specs</header.title>\n\t\t</card.header>\n\t\t<card.body as |body|>\n\t\t\t<body.content>\n\t\t\t\t{{#if this.isLoading}}\n\t\t\t\t\t<Vault::UiLoadingSpinner />\n\t\t\t\t{{else}}\n\t\t\t\t\t<VaultTable @id='{{this.uid}}-contract-specs' @rows={{this.rows}} @columns={{this.columns}} @tableRoute='contract-specs' as |vt|>\n\t\t\t\t\t\t<vt.emptyStateWell>\n\t\t\t\t\t\t\t<:headerMessage>No Contract Specs Found</:headerMessage>\n\t\t\t\t\t\t</vt.emptyStateWell>\n\t\t\t\t\t\t<vt.belowTable />\n\t\t\t\t\t</VaultTable>\n\t\t\t\t{{/if}}\n\t\t\t</body.content>\n\t\t</card.body>\n\t</Vault::UiCard>\n{{/if}}","moduleName":"vault-client/components/contract-specs.hbs","parseOptions":{"srcName":"vault-client/components/contract-specs.hbs"}});
import Component from '@glimmer/component';
import { guidFor } from '@ember/object/internals';
import { CellComponents, TableColumn } from 'vault-client/types/vault-table';
import { task } from 'ember-concurrency';
import { tracked } from '@glimmer/tracking';
import ENV from 'vault-client/config/environment';

interface ContractSpecsArgs {
	barchartRootSymbol: string;
	excludedProps?: string[];
}

interface Row {
	label: string;
	data: string | number;
}

export default class ContractSpecs extends Component<ContractSpecsArgs> {
	constructor(owner: unknown, args: ContractSpecsArgs) {
		super(owner, args);
		this.fetchBarchartFutureSpecs.perform();
	}

	@tracked rows: Row[] | null = null;

	get isLoading() {
		return this.fetchBarchartFutureSpecs.last?.isRunning;
	}

	get uid() {
		return guidFor(this);
	}

	get columns(): TableColumn[] {
		return [
			{
				id: 'be3fef1b-b0da-4132-aa56-352de2fd8027',
				name: '',
				valuePath: 'label',
				cellComponent: CellComponents.String,
				minWidth: 130,
				textAlign: 'center',
				dark: true,
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'fd4165a7-768e-41bb-8a07-a2dd11ab24f3',
				name: '',
				valuePath: 'data',
				cellComponent: CellComponents.Paragraph,
				minWidth: 130,
				textAlign: 'center',
				light: true,
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
		];
	}

	fetchBarchartFutureSpecs = task(this, async () => {
    const apiKey: string = ENV.barchartApiToken
		await fetch(
			'https://ondemand.websol.barchart.com/getFuturesSpecifications.json?' +
				new URLSearchParams({
					apikey: apiKey,
					symbols: this.args.barchartRootSymbol,
				}),
			{
				method: 'GET',
				headers: {},
			}
		)
			.then((response) => response.json())
			.then((response) => response.results[0])
			.then((results: Record<string, string | number>) => {
				const camelToTitle = (camelCase: string) =>
					camelCase
						.replace(/([A-Z])/g, (match) => ` ${match}`.toUpperCase())
						.replace(/^./, (match) => match.toUpperCase())
						.trim();

				const excludedProps: { [key: string]: number } = {
					futuresCategory: 1,
					exchangeSymbol: 1,
				};

				const userExcludedProps = this.args.excludedProps;

				if (userExcludedProps) {
					for (const prop of userExcludedProps) {
						excludedProps[prop] = 1;
					}
				}

				const tempRows: Row[] = [];

				for (const prop in results) {
					if (excludedProps[prop]) continue;
					tempRows.push({
						label: camelToTitle(prop).toUpperCase(),
						data: results[prop],
					});
				}
				this.rows = tempRows;
			})
			.catch((error) => {
				console.warn('Failed to retrieve Contract Spec Rows. \n' + error);
				this.rows = [];
			});
	});
}
