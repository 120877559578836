export function waitForUserButtonClickChoice(cancelButtonId: string, submitButtonId: string) {
	const cancelButton = document.getElementById(cancelButtonId);
	const submitButton = document.getElementById(submitButtonId);

	const firstPromise = new Promise<string>((resolve, reject) => {
		if (cancelButton) {
			cancelButton.onclick = () => {
				resolve('cancel');
			};
		} else {
			reject('cancel button not found');
		}
	});

	const secondPromise = new Promise<string>((resolve, reject) => {
		if (submitButton) {
			submitButton.onclick = () => {
				resolve('confirm');
			};
		} else {
			reject('submit button not found');
		}
	});

	return Promise.race([firstPromise, secondPromise])
		.then((value: 'cancel' | 'confirm') => {
			return value;
		})
		.catch((reason: 'cancel button not found' | 'submit button not found') => {
			console.log(`The following error occured: ${reason}`);
			return reason;
		})
		.finally(() => {
			if (cancelButton) {
				cancelButton.onclick = null;
			}
			if (submitButton) {
				submitButton.onclick = null;
			}
		});
}
