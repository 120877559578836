import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { ModelFrom } from 'vault-client/utils/type-utils';
import route, { GET_CROP_PLANS } from 'vault-client/routes/vgs/grain-services/crop-plans/index';
import { CellComponents, TableColumn } from 'vault-client/types/vault-table';
import resetVaultTableScroll from 'vault-client/utils/reset-vault-table-scroll';
import { action } from '@ember/object';
import CropPlanModel from 'vault-client/models/vgs/crop-plan';
import { gql, useQuery } from 'glimmer-apollo';
import getFilterDisplayProperty from 'vault-client/utils/get-filter-display-property';
import { GrainCropPlan, Query, Query_CropsArgs, Query_SearchArgs, TypeOfSearchItem } from 'vault-client/types/graphql-types';
import { inject as service } from '@ember/service';
import PermissionsService from 'vault-client/services/permissions';

interface SearchResult {
	name: string;
	type: string;
	id: string;
}

const GET_SEARCH_QUERY = gql`
	query Search($query: String!, $typesToInclude: [TypeOfSearchItem!]) {
		Search(query: $query, typesToInclude: $typesToInclude) {
			id
			name
			type
			attributes
		}
	}
`;

const GET_CROP = gql`
	query CropCategories($where: CropCategoryFilterDTO) {
		CropCategories(where: $where) {
			id
			name
		}
	}
`;

type GetCrops = {
	CropCategories: Query['CropCategories'];
};

export default class VgsGrainServicesCropPlansIndex extends Controller {
	@tracked page = 0;
	@tracked size = 100;
	@tracked sorts = [{ valuePath: 'id', isAscending: true }];
	@tracked selectedAdvisorIds: string[] = [];
	@tracked selectedCropYearIds: string[] = [];
	@tracked customerId: string | null = null;
	@tracked cropCategoryId: string | null = null;
	@tracked planIdToDelete: GrainCropPlan | null = null;

	queryParams = ['selectedAdvisorIds', 'selectedCropYearIds', 'customerId', 'cropCategoryId'];

	declare model: ModelFrom<route>;
	@service declare permissions: PermissionsService;

	get columns() {
		let brOnlyButtonColumns: TableColumn[] = [];
		if (this.permissions.isBuyerRelations) {
			brOnlyButtonColumns = [
				{
					id: '84d35b00-6308-464a-bcb9-8115266f6505',
					name: '',
					valuePath: '',
					minWidth: 120,
					textAlign: 'left',
					isSortable: false,
					cellComponent: CellComponents.Button,
					componentArgs: {
						size: 'xs',
						style: 'outline',
						text: 'Remove',
						fn: this.setPlanToDelete,
						disableFn: (datum: GrainCropPlan) => {
							return !datum.hasWriteAccess ? true : false;
						},
					},
					isFixed: '',
					isVisible: true,
				},
			];
		}

		const baseColumns: TableColumn[] = [
			{
				id: '41127689-0dcb-4b59-ba4e-50bae90ce10b',
				name: '',
				width: 100,
				textAlign: 'center',
				isSortable: false,
				cellComponent: CellComponents.Button,
				componentArgs: {
					size: 'xs',
					style: 'outline',
					text: 'Details',
					fn: () => {},
				},
				isFixed: '',
				isVisible: true,
				linkRoute: 'vgs.grain-services.crop-plans.show',
				linkModelPath: 'id',
			},
			{
				id: '14999741-0aef-4f3f-8cbd-4b9249e2fbfb',
				name: 'Customer',
				valuePath: 'Customer.name',
				width: 150,
				minWidth: 150,
				textAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				linkRoute: 'vgs.customers.show',
				linkModelPath: 'Customer.id',
			},
			{
				id: '85fe3b7b-169b-4a3d-9f00-81b4108e2a56',
				name: 'Advisor',
				valuePath: '',
				minWidth: 115,
				width: 115,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: '2211ca86-65ef-482f-b3aa-6e90189cc096',
						name: 'First Name',
						valuePath: 'Customer.RelationshipOwner.firstName',
						minWidth: 115,
						width: 115,
						textAlign: 'left',
						isSortable: false,
						cellComponent: CellComponents.String,
						isFixed: '',
						isVisible: true,
					},
					{
						id: 'c65e2740-83c1-4109-a35b-ba3775a8128a',
						name: 'Last Name',
						valuePath: 'Customer.RelationshipOwner.lastName',
						minWidth: 115,
						width: 115,
						textAlign: 'left',
						isSortable: false,
						cellComponent: CellComponents.String,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: '849e6476-4916-4c54-8047-957f6f58fd3e',
				name: 'Crop',
				valuePath: 'Crop.name',
				width: 80,
				minWidth: 80,
				textAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '63577dc2-353b-428f-bdfc-c9215ea90da2',
				name: 'Year',
				valuePath: 'CropYear.year',
				width: 80,
				minWidth: 80,
				textAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '81032036-d041-40a6-b613-60815a37867f',
				name: 'Acres',
				valuePath: 'acres',
				width: 90,
				minWidth: 90,
				textAlign: 'right',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'b9185ffd-70e6-426b-bf08-dc3666c3dfcb',
				name: 'APH',
				valuePath: 'aph',
				width: 80,
				minWidth: 80,
				textAlign: 'right',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'debe87aa-be5f-4b22-bf17-accfb8fdc131',
				name: 'Production',
				valuePath: 'production',
				width: 110,
				minWidth: 110,
				textAlign: 'right',
				isSortable: false,
				cellComponent: CellComponents.IntlNumberFormat,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '7702b730-961e-445e-9318-dc73d9c8d1b1',
				name: 'RP %',
				valuePath: 'enrolledPercent',
				width: 90,
				minWidth: 90,
				textAlign: 'right',
				isSortable: true,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'percent',
				},
				isFixed: '',
				isVisible: true,
			},
			{
				id: '8b979b0d-00fb-400b-a8a5-73aee6a653f8',
				name: 'RP Production',
				valuePath: 'enrolledProduction',
				width: 125,
				minWidth: 125,
				textAlign: 'right',
				isSortable: false,
				cellComponent: CellComponents.IntlNumberFormat,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '80eea6f4-169c-434f-8650-e212dd77a899',
				name: 'Filled',
				valuePath: 'bushelsFilled',
				width: 80,
				minWidth: 80,
				textAlign: 'right',
				isSortable: false,
				cellComponent: CellComponents.IntlNumberFormat,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'c6ba59fb-b18c-4faa-836b-906a0ceafd4e',
				name: '% Sold',
				valuePath: 'percentSold',
				width: 80,
				minWidth: 80,
				textAlign: 'right',
				isSortable: false,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'percent',
				},
				isFixed: '',
				isVisible: true,
			},
			{
				id: '8bdaa537-ae27-46eb-b16a-c70651903b8b',
				name: 'Harvest Bu.',
				valuePath: 'harvestBu',
				width: 120,
				minWidth: 120,
				textAlign: 'right',
				isSortable: true,
				cellComponent: CellComponents.IntlNumberFormat,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '3476041a-cdd7-440c-ab41-92cc26db3a79',
				name: 'Storage Bu.',
				valuePath: 'storageBu',
				width: 120,
				minWidth: 120,
				textAlign: 'right',
				isSortable: true,
				cellComponent: CellComponents.IntlNumberFormat,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'e8f82632-435a-47c2-995b-aceccc868a89',
				name: 'Breakeven',
				valuePath: 'breakEven',
				width: 120,
				minWidth: 120,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: false,
			},
			{
				id: 'b7b35f9e-4668-4261-bb41-c76f074da522',
				name: 'Goal',
				valuePath: 'goal',
				width: 80,
				minWidth: 80,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: false,
			},
			{
				id: '41fed28e-fefa-4b82-b745-e9c630ab5d0f',
				name: 'Avg. Future Price',
				valuePath: 'averageFuturePriceSold',
				width: 140,
				minWidth: 140,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
					minimumFractionDigits: 2,
					maximumFractionDigits: 5,
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: '3c267600-f12f-43c0-a42b-91fc2f1d291a',
				name: 'Avg. Flat Price',
				valuePath: 'averageFlatPriceSold',
				width: 130,
				minWidth: 130,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
					minimumFractionDigits: 2,
					maximumFractionDigits: 5,
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
		];
		const baseAndWriteColumns = [...baseColumns, ...brOnlyButtonColumns];
		return baseAndWriteColumns;
	}

	get currentPage() {
		return this.page;
	}

	set currentPage(page) {
		this.page = page;
	}

	itemsFn = (plans: GrainCropPlan[] | undefined) => {
		return (
			plans?.map((plan: GrainCropPlan) => {
				return new CropPlanModel(plan);
			}) ?? []
		);
	};

	get rows() {
		const plans = this.model.getCropPlans.data?.GrainCropPlans;
		return this.itemsFn(plans);
	}

	get totalNumCropPlans() {
		return this.model.getCropPlans.data?.GrainCropPlanCount?.count;
	}

	get advisors() {
		return this.model.getCropPlans.data?.GrainAdvisors ?? [];
	}

	get query() {
		return GET_CROP_PLANS;
	}

	get cropYears() {
		return this.model.getCropPlans.data?.GrainCropYears ?? [];
	}

	get selectedAdvisorsString() {
		if (this.selectedAdvisorIds.length === 0) {
			return 'All';
		}

		if (this.selectedAdvisorIds.length === 1) {
			return this.advisors.find((advisor) => advisor.id === this.selectedAdvisorIds[0])?.name ?? '';
		}

		return `${this.selectedAdvisorIds.length} Selected`;
	}

	get selectedCropYearsString() {
		if (this.selectedCropYearIds.length === 0) {
			return 'All';
		}

		if (this.selectedCropYearIds.length === 1) {
			return this.cropYears.find((advisor) => advisor.id === this.selectedCropYearIds[0])?.year ?? '';
		}

		return `${this.selectedCropYearIds.length} Selected`;
	}

	@action
	async fetchSearchResults(searchText: string) {
		const customerResults: SearchResult[] = [];
		const cropResults: SearchResult[] = [];

		const customerQuery = useQuery<{ Search: Query['Search'] }, Query_SearchArgs>(this, () => [
			GET_SEARCH_QUERY,
			{
				variables: {
					query: searchText,
					typesToInclude: [TypeOfSearchItem.Customer],
				},
				onComplete: (data): void => {
					customerResults.push(
						...(data?.Search.filter((item) => {
							// Filter out non-VGS customers
							if (item.type === TypeOfSearchItem.Customer && !item.attributes?.isVgs) {
								return false;
							}

							return true;
						}).map((customer) => ({
							type: 'customer',
							name: customer.name,
							id: customer.id,
						})) ?? [])
					);
				},
			},
		]);

		const cropsQuery = useQuery<GetCrops, Query_CropsArgs>(this, () => [
			GET_CROP,
			{
				variables: {
					where: {
						name: { contains: searchText },
					},
				},
				onComplete: (data): void => {
					cropResults.push(
						...(data?.CropCategories.map((crop) => ({
							type: 'crop',
							name: crop.name,
							id: crop.id,
						})) ?? [])
					);
				},
			},
		]);

		const promises = [customerQuery.promise, cropsQuery.promise];

		await Promise.all(promises);

		return [...customerResults, ...cropResults];
	}

	mapSearchResult(searchResult: SearchResult) {
		let filterIdentifier: keyof VgsGrainServicesCropPlansIndex | null = null;

		switch (searchResult.type) {
			case 'customer':
				filterIdentifier = 'customerId';
				break;
			case 'crop':
				filterIdentifier = 'cropCategoryId';
				break;
		}

		return {
			filterIdentifier,
			filterValue: searchResult?.id ?? searchResult.name,
		};
	}

	@action
	setSearchFilterQueryParam(searchResult: SearchResult) {
		const mappedSearchFilter = this.mapSearchResult(searchResult);

		if (mappedSearchFilter.filterIdentifier) {
			this[mappedSearchFilter.filterIdentifier] = mappedSearchFilter.filterValue;
			this.setTablePageState();
		}
	}

	@action
	clearSearchFilterQueryParam(filterIdentifier: 'customerId' | 'cropCategoryId') {
		this[`${filterIdentifier}`] = null;
		this.setTablePageState();
	}

	@action
	addSelectedAdvisorId(advisorId: string | null) {
		if (advisorId === null) {
			this.selectedAdvisorIds = [];
		} else if (this.selectedAdvisorIds.includes(advisorId)) {
			this.selectedAdvisorIds = this.selectedAdvisorIds.filter((v) => v !== advisorId);
		} else {
			this.selectedAdvisorIds = [...this.selectedAdvisorIds, advisorId];
		}

		// If all advisors are selected, clear the selected array so that 'All' is used
		if (this.selectedAdvisorIds.length === this.advisors.length) {
			this.selectedAdvisorIds = [];
		}
		this.setTablePageState();
	}

	@action
	addSelectedCropYearId(cropYearId: string | null) {
		if (cropYearId === null) {
			this.selectedCropYearIds = [];
		} else if (this.selectedCropYearIds.includes(cropYearId)) {
			this.selectedCropYearIds = this.selectedCropYearIds.filter((v) => v !== cropYearId);
		} else {
			this.selectedCropYearIds = [...this.selectedCropYearIds, cropYearId];
		}

		if (this.selectedCropYearIds.length === this.cropYears.length) {
			this.selectedCropYearIds = [];
		}

		this.setTablePageState();
	}

	get searchFilterQueryParams() {
		const obj: {
			[key: string]: any;
		} = {};
		const searchQueryParams = ['customerId', 'cropCategoryId'] as const;
		searchQueryParams.forEach((param) => {
			const value = this[param];

			if (value) {
				obj[param] = {
					filterRule: 'equals',
					filterValue: value,
				};

				// set filterComponent property to specify component for custom display extended from search-filter
				const filterDisplayObj = getFilterDisplayProperty(param);

				if (filterDisplayObj && filterDisplayObj.customComponent) {
					obj[param].filterComponent = filterDisplayObj.customComponent;
				}
				// set filterLabel property to specify custom label for filter - default would be filterIdentifier (matches queryParam)
				if (filterDisplayObj && filterDisplayObj.label) {
					obj[param].filterLabel = filterDisplayObj.label;
				}
			}
		});

		return obj;
	}

	@action
	structureSearchResults(searchResults: SearchResult[]) {
		const map = new Map();

		searchResults.forEach((item) => {
			if (map.has(item.type)) {
				map.get(item.type).push({ name: item.name, type: item.type, id: item.id });
			} else {
				map.set(item.type, [{ name: item.name, type: item.type, id: item.id }]);
			}
		});

		return map;
	}

	@action
	setTablePageState(newPageVal: number | null = null) {
		this.currentPage = newPageVal ?? 0;
		resetVaultTableScroll('crop-plans-index-table');
	}

	@action
	setPlanToDelete(plan: GrainCropPlan) {
		this.planIdToDelete = plan ?? null;
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	interface Registry {
		'vgs/grain-services/crop-plans/index': VgsGrainServicesCropPlansIndex;
	}
}
