import CropFillsRoute from 'vault-client/routes/crop-fills';

type SortObj = {
	valuePath: string;
	isAscending: boolean;
};

interface QueryParams {
	page: number;
	size: number;
	sorts: SortObj[];
	id: string;
	customerId?: string;
}

export default class OrganizationsCropFills extends CropFillsRoute {
	async model(params: QueryParams) {
		const organizationParams = this.paramsFor('organizations.organization') as { id: string };
		this.variables = {
			where: this.generateWhere(organizationParams.id, null),
			orderBy: this.generateOrderBy(params.sorts),
			offset: params.page * params.size,
			limit: params.size,
		};

		await this.getGrainFills.promise;

		return {
			getGrainFills: this.getGrainFills,
			variables: this.variables,
		};
	}
}
