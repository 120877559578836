import InsuranceOverview from 'vault-client/routes/insurance-overview';

interface QueryParams {
	agent: string | null;
	aipId: string | null;
	stateId: string | null;
	quarterStartDate: string | null;
	quarterEndDate: string | null;
	customerId: string | null;
	id: string | null;
}

export default class BusinessInsuranceQuarters extends InsuranceOverview {
	async model(params: QueryParams) {
		const businessParams = this.paramsFor('businesses.business') as { business_id: string };
		const variables = {
			productionWhere: this.generateProductionWhere(
				params.agent,
				params.aipId,
				params.stateId,
				params.quarterStartDate,
				params.quarterEndDate
			),
			classProductionWhere: this.generateClassProductionWhere(params.agent, params.stateId, params.quarterStartDate, params.quarterEndDate),
			endorsementWhere: this.generateEndorsementWhere(
				params.agent,
				params.aipId,
				params.stateId,
				params.quarterStartDate,
				params.quarterEndDate
			),
			scopeId: businessParams.business_id,
		};

		return this.apollo.watchQuery({ query: this.query, variables });
	}
}
