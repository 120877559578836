import Component from '@glimmer/component';

interface UiTooltipArgs {
	id: string;
	text: string;
	renderOut?: boolean;
	triggerId?: string;
}

export default class UiTooltip extends Component<UiTooltipArgs> {
	get tooltipRoot() {
		return document.getElementById('tooltip_root');
	}
}
