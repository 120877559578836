/* eslint-disable ember/no-classic-classes */
import Route from '@ember/routing/route';
import query from 'vault-client/graphql/queries/reports/unmapped-accounts.graphql';
import { queryManager } from 'ember-apollo-client';
import { SortObj } from 'vault-client/types/vault-table';
import { AccountFilterDTO, TypeOfAccount } from 'vault-client/types/graphql-types';

interface ModelParams {
	fcmId: string | null;
	accountId: string | null;
	size: number;
	page: number;
	sorts: SortObj[];
}

export default class UnmappedAccountsRoute extends Route {
	@queryManager apollo: any;

	templateName: string = 'reports/unmapped-accounts';

	queryParams = {
		page: { refreshModel: true },
		size: { refreshModel: true },
		sorts: { refreshModel: true },
		fcmId: { refreshModel: true },
		accountId: { refreshModel: true },
	};

	query = query;

	generateOrderBy(sorts: SortObj[]) {
		const orderBy: {
			[key: string]: any;
		} = {};

		sorts.forEach((sort) => {
			const value = sort.isAscending ? 'Asc' : 'Desc';
			orderBy[sort.valuePath] = value;
		});

		return orderBy;
	}

	generateWhere(fcmId: string | null, accountId: string | null): AccountFilterDTO {
		const where: AccountFilterDTO = {};

		if (fcmId) {
			where.fcmId = {
				equals: fcmId,
			};
		}

		if (accountId) {
			where.id = {
				equals: accountId,
			};
		}

		where.customerId = { equals: null };
		where.type = { equals: TypeOfAccount.Brokerage };

		return where;
	}

	async model(params: ModelParams) {
		const variables = {
			where: this.generateWhere(params.fcmId, params.accountId),
			orderBy: this.generateOrderBy(params.sorts),
			offset: params.page * params.size,
			limit: params.size,
		};

		const results = await this.apollo.watchQuery({ query: this.query, variables });

		return {
			...results,
			variables,
		};
	}
}
