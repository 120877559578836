import { htmlSafe } from '@ember/template';
import Component from '@glimmer/component';

interface ColorizedPercentArgs {
	value: number;
	inverted?: boolean;
}

export default class ColorizedPercent extends Component<ColorizedPercentArgs> {
	get style() {
		let value;
		if (this.args.inverted) {
			value = 1 - this.args.value;
		} else {
			value = this.args.value;
		}

		const hue = (value * 120).toString(10);

		return htmlSafe(`color: hsl(${hue},100%,35%)`);
	}
}
