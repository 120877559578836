import SearchFilterComponent from 'vault-client/components/vault/ui-search-filter';
import { queryManager } from 'ember-apollo-client';
import query from 'vault-client/graphql/queries/account-get.graphql';
import { action } from '@ember/object';

export default class AccountIdFilterComponent extends SearchFilterComponent {
	@queryManager apollo: any;

	@action
	async fetchItem() {
		let value;
		if (this.args.filterValues.filterValue) {
			try {
				const variables = { id: this.args.filterValues.filterValue };
				const result = await this.apollo.watchQuery({ query, variables });
				const account = result?.Account;

				if (account) {
					const { accountNumber, name } = account;
					value = `${name}\u00A0\u00A0\u00A0(\u00A0${accountNumber}\u00A0)`;
				} else {
					value = '(Account not found)';
				}

				this._value = value ?? this.args.filterValues.filterValue;
			} catch (e) {
				console.error(e);
				this._value = '(Account not found)';
			}
		} else {
			this._value = 'N/A';
		}
	}
}
