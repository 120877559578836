import LivestockFeedOverviewDetailRoute from 'vault-client/routes/livestock-feed/overview/detail';
import { FeedIngredientBase } from 'vault-client/types/vault-client';

interface ModelParams {
	ingredient_base: FeedIngredientBase;
	startDate: string;
	endDate: string;
}
export default class BusinessesBusinessLivestockFeedOverviewDetailRoute extends LivestockFeedOverviewDetailRoute {
	async model(params: ModelParams) {
		const { business_id: businessId } = this.paramsFor('businesses.business') as { business_id: string | undefined };
		if (!businessId) throw new Error(`BusinessId not found`);

		this.variables = {
			livestockFeedIngredientsWhere: this.generateForecastedLivestockFeedIngredientsWhere(
				params.ingredient_base,
				businessId,
				params.startDate,
				params.endDate
			),
			forecastedHedgedAndCappedVolumeWhere: this.generateForecastedHedgedAndCappedVolumeWhere(
				params.ingredient_base,
				businessId,
				params.startDate,
				params.endDate
			),
			productsWhere: this.generateProductsWhere(params.ingredient_base),
		};

		await this.getFeedDetails.promise;

		return {
			ingredientBase: params.ingredient_base,
			getFeedDetails: this.getFeedDetails,
			businessId,
		};
	}
}
