import { DateTime } from 'luxon';
import {
	CustomerEntity,
	GrainCropPlan,
	GrainFeedPlan,
	GrainFillOrder,
	GrainOrderNote,
	Maybe,
	TypeOfGrainPlan,
} from 'vault-client/types/graphql-types';
import activityMessage from 'vault-client/utils/activity-message';
export default class Fill {
	DeliveryLocation;
	Buyer;
	Seller;
	Location;
	Target;
	Plan;
	GrainFillOrderModificationRequests;
	basis;
	bushels;
	contractNumber;
	createdAt;
	createdBy;
	updatedAt;
	_deliveryStartDate;
	_deliveryEndDate;
	delivery_month;
	fees;
	flatPrice;
	flexFee;
	futurePrice;
	futuresMonth;
	htaFee;
	rollFee;
	salesType;
	spread;
	deliveryTerms;
	orderActivity;
	fillNotes;
	targetNotes;
	id;
	planAcres;
	planAph;
	planVolume;
	target_id;
	Customer;

	constructor(
		fill: GrainFillOrder,
		planAcres: number | null,
		planAph: number | null,
		fillNotes: GrainOrderNote[] | null,
		targetNotes: GrainOrderNote[] | null,
		planVolume: number | null,
		Customer: Maybe<CustomerEntity>
	) {
		this.Buyer = fill.Buyer;
		this.Seller = fill.Seller;
		this.DeliveryLocation = fill.DeliveryLocation;
		this.Location = fill.Location;
		this.DeliveryLocation = fill.DeliveryLocation;
		this.Target = fill.GrainTargetOrder;
		this.Plan = fill.Plan as Maybe<GrainFeedPlan> | Maybe<GrainCropPlan>;
		this.GrainFillOrderModificationRequests = fill.GrainFillOrderModificationRequests ?? [];
		this.Customer = Customer;
		this.basis = fill?.basis;
		this.bushels = fill?.bushels;
		this.contractNumber = fill?.contractNumber;
		this.createdAt = fill?.createdAt;
		this.createdBy = fill?.createdBy;
		this.updatedAt = fill?.updatedAt;
		this._deliveryStartDate = fill?.deliveryStartDate;
		this._deliveryEndDate = fill?.deliveryEndDate;
		this.delivery_month = fill?.deliveryMonth;
		this.fees = fill?.fees;
		this.flatPrice = fill?.flatPrice;
		this.flexFee = fill?.flexFee;
		this.futurePrice = fill?.futurePrice;
		this.futuresMonth = fill?.futuresMonth;
		this.htaFee = fill?.htaFee;
		this.rollFee = fill?.rollFee;
		this.salesType = fill?.salesType;
		this.spread = fill?.spread;
		this.deliveryTerms = fill?.deliveryTerms;
		this.orderActivity = fill?.Activity;
		this.fillNotes = fillNotes;
		this.targetNotes = targetNotes;
		this.id = fill?.id;
		this.planAcres = planAcres;
		this.planAph = planAph;
		this.planVolume = planVolume;
		this.target_id = fill?.targetId;
	}

	get deliveryStartDate() {
		return this._deliveryStartDate ?? null;
	}

	get deliveryEndDate() {
		return this._deliveryEndDate ?? null;
	}

	get netPrice() {
		return (this.futurePrice ?? 0) + (this.basis ?? 0) + this.fees + (this.spread ?? 0);
	}

	get grossRevenue() {
		//returning 0 if falsy here because this would be the behavior on VGS
		return this.netPrice && this.bushels ? this.netPrice * this.bushels : 0;
	}

	get feedMonth() {
		if (this.Plan?.type === TypeOfGrainPlan.Crop) return null;

		const plan = this.Plan as GrainFeedPlan;
		const startDate = plan?.startDate;
		if (!startDate) return null;

		const date = DateTime.fromISO(startDate);
		const month = date.toFormat('MMM');
		return month;
	}

	get feedYear() {
		if (this.Plan?.type === TypeOfGrainPlan.Crop) return null;

		const plan = this.Plan as GrainFeedPlan;
		return plan.feedYear;
	}

	get percentFilled() {
		// VGS tables display 0% when nullish
		const bushels = this.bushels ?? 0;
		const acres = this.planAcres ?? 0;
		const aph = this.planAph ?? 0;

		return bushels / (acres * aph);
	}

	get feedPercent() {
		const bushels = this.bushels ?? 0;
		const tons = this.planVolume ?? 0;
		return bushels / tons;
	}

	get orderActivities() {
		// merge target activities with fill activities
		return this.orderActivity && this.Target
			? activityMessage(
					[...this.Target.Activity, ...this.orderActivity].sort(
						(a, b) => new Date(b.performedAt).getTime() - new Date(a.performedAt).getTime()
					)
			  )
			: null;
	}

	get notesAggregate() {
		const notes = [this.fillNotes, this.targetNotes];

		return notes.flat().sort((a, b) => (new Date(a?.createdAt).getTime() < new Date(b?.createdAt).getTime() ? 1 : !a?.createdAt ? 1 : -1));
	}
}
