import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
// @ts-ignore
import { queryManager } from 'ember-apollo-client';
import { State } from 'vault-client/types/graphql-types';
import { CellComponents, SortObj, TableColumn } from 'vault-client/types/vault-table';
import { ModelFrom } from 'vault-client/utils/type-utils';
import ReportsLrpCalculatorRoute from 'vault-client/routes/reports/lrp-calculator';

interface CommodityType {
	name: string;
	code: string;
	min: number;
	max: number;
}

interface Commodity {
	name: string;
	code: string;
	commodityTypes: CommodityType[];
}

interface Row {
	cashPrice: number;
	result: string;
	premiumOwed: number;
	indemnificationReceived: number;
	breakevenCwt: number;
	profitCwtLrp: number;
	profitHeadLrp: number;
	profitCwt: number;
	profitHead: number;
}

interface LrpDailyPrice {
	id: string;
	commodityCode: string;
	commodityTypeCode: string;
	typeCode: string;
	stateCode: string;
	endDate: string;
	salesEffectiveDate: string;
	insurancePlanCode: string;
	intervalCode: string;
	endorsementLengthCount: number;
	expectedEndingValueAmount: number;
	coveragePrice: number;
	livestockCoverageLevelPercent: number;
	livestockRate: number;
	costPerCwtAmount: number;
}

class LrpPremium {
	coveragePrice: number;
	endDate: string;
	expectedEndingValueAmount: number;
	insuredValue: number;
	rate: number;
	totalPremium: number;
	livestockRate: number;
	subsidy: number;
	producerPremium: number;
	producerPremiumPerHead: number;
	producerPremiumPerCwt: number;

	constructor(coverageLevel: LrpDailyPrice, numberOfHead: number, targetWeight: number) {
		this.coveragePrice = coverageLevel.coveragePrice;
		this.endDate = coverageLevel.endDate;
		this.expectedEndingValueAmount = coverageLevel.expectedEndingValueAmount;

		this.insuredValue = numberOfHead * targetWeight * coverageLevel.coveragePrice;
		this.rate = coverageLevel.livestockRate;
		this.totalPremium = Math.round(this.insuredValue * this.rate);

		let subsidyRate: number | undefined;

		const coverageLevelPercent = coverageLevel.livestockCoverageLevelPercent;
		if (coverageLevelPercent >= 0.95) {
			subsidyRate = 0.35;
		} else if (coverageLevelPercent >= 0.9) {
			subsidyRate = 0.4;
		} else if (coverageLevelPercent >= 0.85) {
			subsidyRate = 0.45;
		} else if (coverageLevelPercent >= 0.8) {
			subsidyRate = 0.5;
		} else if (coverageLevelPercent >= 0.7) {
			subsidyRate = 0.55;
		}

		this.livestockRate = coverageLevel.livestockRate;
		if (!subsidyRate) throw new Error('Missing Subsidy Rate');

		this.subsidy = Math.round(this.totalPremium * subsidyRate);
		this.producerPremium = this.totalPremium - this.subsidy;
		this.producerPremiumPerHead = this.producerPremium / numberOfHead;
		this.producerPremiumPerCwt = this.producerPremium / numberOfHead / targetWeight;
	}
}

export default class ReportsLrpCalculator extends Controller {
	@queryManager apollo: any;
	declare model: ModelFrom<ReportsLrpCalculatorRoute>;

	queryParams = ['_commodityCode', '_commodityTypeCode', 'targetWeightCwt', 'stateCode'];
	reportsRoute: string = '';

	commodities = [
		{
			name: 'Feeder Cattle',
			code: '0801',
			commodityTypes: [
				{
					name: 'Steers Weight (< 600 LBS)',
					code: '809',
					min: 1,
					max: 5.99,
				},
				{
					name: 'Steers Weight (600 - 900 LBS)',
					code: '810',
					min: 6,
					max: 9,
				},
				{
					name: 'Heifers Weight (< 600 LBS)',
					code: '811',
					min: 1,
					max: 5.99,
				},
				{
					name: 'Heifers Weight (600 - 900 LBS)',
					code: '812',
					min: 6,
					max: 9,
				},
				{
					name: 'Brahman Weight (< 600 LBS)',
					code: '813',
					min: 1,
					max: 5.99,
				},
				{
					name: 'Brahman Weight (600 - 900 LBS)',
					code: '814',
					min: 6,
					max: 9,
				},
				{
					name: 'Dairy Weight (< 600 LBS)',
					code: '815',
					min: 1,
					max: 5.99,
				},
				{
					name: 'Dairy Weight (600 - 900 LBS)',
					code: '816',
					min: 6,
					max: 9,
				},
				{
					name: 'Unborn Steers & Heifers',
					code: '817',
					min: 1,
					max: 5.99,
				},
				{
					name: 'Unborn Brahman',
					code: '818',
					min: 1,
					max: 5.99,
				},
				{
					name: 'Unborn Dairy',
					code: '819',
					min: 1,
					max: 5.99,
				},
			],
		},
		{
			name: 'Fed Cattle',
			code: '0802',
			commodityTypes: [
				{
					name: 'Steers & Heifers  (1,000 - 1,400 LBS)',
					code: '820',
					min: 10,
					max: 14,
				},
			],
		},
		{
			name: 'Swine',
			code: '0815',
			commodityTypes: [
				{
					name: 'Unborn Swine',
					code: '821',
					min: 1.5,
					max: 2.25,
				},
				{
					name: 'No Type Specified',
					code: '997',
					min: 1.5,
					max: 2.25,
				},
			],
		},
	];

	@tracked showInputs = false;
	@tracked stateCode = '26';
	@tracked headCount = '500';
	@tracked targetWeightCwt: number | null = 3.25;
	@tracked breakevenCwt: string | null = '149.00';
	@tracked coverageEndDate: string | null = null;
	@tracked coveragePrice: number | null = null;
	@tracked sorts: SortObj[] = [{ valuePath: 'cashPrice', isAscending: false }];
	@tracked _commodityCode: string | null = this.commodities[0].code;
	@tracked _commodityTypeCode: string | null = this.commodities[0].commodityTypes[0].code;
	@tracked columns: TableColumn[] = [
		{
			name: 'Settlement Price',
			id: '67ae79bb-af3e-4973-94b9-26c51589fe8c',
			cellComponent: CellComponents.IntlNumberFormat,
			componentArgs: {
				style: 'currency',
				currency: 'USD',
				currencySign: 'accounting',
			},
			width: 120,
			valuePath: 'cashPrice',
			isFixed: 'left',
			isVisible: true,
			textAlign: 'right',
		},
		{
			id: 'cd8335f8-0392-480b-a969-c589cf146605',
			name: 'With LRP Coverage',
			cellComponent: CellComponents.String,
			isFixed: '',
			isVisible: true,
			subcolumns: [
				{
					name: 'Result',
					id: 'b3c44083-af05-4a12-aa15-2773050a19f3',
					width: 150,
					cellComponent: CellComponents.String,
					valuePath: 'result',
					isFixed: '',
					isVisible: true,
					textAlign: 'left',
				},
				{
					name: 'Premium Owed',
					id: 'e252f4a2-138c-402b-9336-d1f6f2ed056f',
					width: 150,
					cellComponent: CellComponents.IntlNumberFormat,
					componentArgs: {
						style: 'currency',
						currency: 'USD',
						currencySign: 'accounting',
					},
					valuePath: 'premiumOwed',
					isFixed: '',
					isVisible: true,
					textAlign: 'right',
				},
				{
					name: 'Loss Received',
					id: '24b4b7f8-26a1-42a8-aa5a-f403661959c9',
					width: 150,
					cellComponent: CellComponents.IntlNumberFormat,
					componentArgs: {
						style: 'currency',
						currency: 'USD',
						currencySign: 'accounting',
					},
					valuePath: 'indemnificationReceived',
					isFixed: '',
					isVisible: true,
					textAlign: 'right',
				},
				{
					name: 'Profit / Cwt.',
					id: '264c4371-575e-4255-b576-604653c6c7a7',
					width: 135,
					cellComponent: CellComponents.IntlNumberFormat,
					componentArgs: {
						style: 'currency',
						currency: 'USD',
						currencySign: 'accounting',
					},
					valuePath: 'profitCwtLrp',
					isFixed: '',
					isVisible: true,
					textAlign: 'right',
				},
				{
					name: 'Profit / Head',
					id: 'e374025b-a741-4bd5-802a-12508aed11f9',
					width: 135,
					cellComponent: CellComponents.IntlNumberFormat,
					componentArgs: {
						style: 'currency',
						currency: 'USD',
						currencySign: 'accounting',
					},
					valuePath: 'profitHeadLrp',
					isFixed: '',
					isVisible: true,
					textAlign: 'right',
				},
			],
		},
		{
			id: '16630c96-3ce8-4725-beac-00ebc26d3b48',
			name: 'Without LRP Coverage',
			cellComponent: CellComponents.String,
			isFixed: '',
			isVisible: true,
			subcolumns: [
				{
					name: 'Profit / Cwt.',
					id: 'f41f10d3-77c4-4254-b0d6-b50b475ad5b8',
					width: 135,
					cellComponent: CellComponents.IntlNumberFormat,
					componentArgs: {
						style: 'currency',
						currency: 'USD',
						currencySign: 'accounting',
					},
					valuePath: 'profitCwt',
					isFixed: '',
					isVisible: true,
					textAlign: 'right',
				},
				{
					name: 'Profit / Head',
					id: '243198c1-0c0b-49f3-b3ac-b4fdd017ee96',
					width: 135,
					cellComponent: CellComponents.IntlNumberFormat,
					componentArgs: {
						style: 'currency',
						currency: 'USD',
						currencySign: 'accounting',
					},
					valuePath: 'profitHead',
					isFixed: '',
					isVisible: true,
					textAlign: 'right',
				},
			],
		},
	];

	get commodityCode() {
		return this._commodityCode;
	}

	set commodityCode(code) {
		this._commodityCode = code;
		this._commodityTypeCode = this.commodities.find((commodity) => commodity.code == code)?.commodityTypes[0].code || null;
		this.targetWeightCwt = this.commodities.find((commodity) => commodity.code == code)?.commodityTypes[0].min || null;
	}

	get commodityTypeCode() {
		return this._commodityTypeCode;
	}

	get selectedState() {
		const state = this.model.States.find((state: State) => state.rmaStateCode === this.stateCode);
		return state || null;
	}

	get selectedCommodityType() {
		const type = this.commodity?.commodityTypes.find((type) => type.code === this.commodityTypeCode);
		return type || null;
	}

	set commodityTypeCode(code) {
		this._commodityTypeCode = code;
		this.targetWeightCwt = this.targetWeightMin;
	}

	get targetWeightMin() {
		const _commodity = this.commodity;
		const commodityType = _commodity?.commodityTypes.find((type) => this._commodityTypeCode === type.code);
		return commodityType?.min || null;
	}

	get targetWeightMax() {
		const _commodity = this.commodity;
		const commodityType = _commodity?.commodityTypes.find((type) => this._commodityTypeCode === type.code);
		return commodityType?.max || null;
	}

	get state() {
		return this.model.States.find((state: State) => {
			return state.rmaStateCode == this.stateCode;
		});
	}

	get commodity() {
		return this.commodities.find((commodity) => commodity.code == this.commodityCode);
	}

	get coverageLevelClasses() {
		return (this.model.MostRecentLrpDailyPrices as LrpDailyPrice[]).map((coverageLevel: LrpDailyPrice) => {
			return new LrpPremium(coverageLevel, +this.headCount, this.targetWeightCwt || 0);
		});
	}

	get salesEffectiveDate() {
		return this.model.MostRecentLrpDailyPrices[0]?.salesEffectiveDate;
	}

	get coverageLevels() {
		return this.coverageLevelClasses.sort((a, b) => a.coveragePrice - b.coveragePrice).sort((a, b) => a.endDate.localeCompare(b.endDate));
	}

	get coverageEndDates() {
		return [...new Set(this.coverageLevelClasses.map((coverageLevel) => coverageLevel.endDate))].sort();
	}

	get coveragePrices() {
		return this.coverageLevelClasses
			.filter((coverageLevel) => coverageLevel.endDate === this.coverageEndDate)
			.map((coverageLevel) => coverageLevel.coveragePrice)
			.sort();
	}

	get coverageLevel() {
		if (!this.coverageEndDate || !this.coveragePrice) return null;

		return (
			this.coverageLevels.find((coverageLevel) => {
				return coverageLevel.endDate === this.coverageEndDate && coverageLevel.coveragePrice === this.coveragePrice;
			}) || null
		);
	}

	get rows() {
		if (!this.coverageLevel || !this.breakevenCwt || this.breakevenCwt === '' || !this.targetWeightCwt) return [];

		const min = Math.round(this.coverageLevel.coveragePrice) - 15;
		const max = Math.round(this.coverageLevel.coveragePrice) + 10;
		const rows: Row[] = [];

		for (let i = min; i < max; i++) {
			const coveragePrice = this.coverageLevel.coveragePrice;
			const producerPremiumPerCwt = this.coverageLevel.producerPremiumPerCwt;

			const cashPrice = i;
			const indemnificationPayment = cashPrice > coveragePrice ? 0 : coveragePrice - cashPrice;
			const premiumOwed =
				cashPrice > coveragePrice || cashPrice > coveragePrice - producerPremiumPerCwt ? producerPremiumPerCwt - indemnificationPayment : 0;
			const indemnificationReceived =
				cashPrice < coveragePrice - producerPremiumPerCwt ? indemnificationPayment - producerPremiumPerCwt : 0;
			const breakevenCwt = parseFloat(this.breakevenCwt);
			const profitCwtLrp =
				cashPrice > coveragePrice
					? cashPrice - producerPremiumPerCwt - breakevenCwt
					: cashPrice > coveragePrice - producerPremiumPerCwt
					? cashPrice - premiumOwed - breakevenCwt
					: cashPrice + indemnificationReceived - breakevenCwt;
			const profitHeadLrp = profitCwtLrp * this.targetWeightCwt;
			const result = !indemnificationPayment ? 'Premium Due' : !premiumOwed ? 'No Premium Due' : 'Some Premium Due';

			const profitCwt = cashPrice - breakevenCwt;
			const profitHead = profitCwt * this.targetWeightCwt;

			rows.push({
				cashPrice,
				result,
				premiumOwed,
				indemnificationReceived,
				breakevenCwt,
				profitCwtLrp,
				profitHeadLrp,
				profitCwt,
				profitHead,
			});
		}

		return rows;
	}

	@action
	toggleShowInputs() {
		this.showInputs = !this.showInputs;
	}

	@action
	setStateCode(state: State) {
		if (state.rmaStateCode) {
			this.stateCode = state.rmaStateCode;
		}
	}

	@action
	setCommodityTypeCode(type: CommodityType) {
		this.commodityTypeCode = type.code;
	}

	@action
	setCommodityCode(commodity: Commodity) {
		this.commodityCode = commodity.code;
	}

	@action
	setCoverageEndDate(endDate: string) {
		this.coverageEndDate = endDate;
		this.coveragePrice = this.coveragePrices[0];
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'reports/lrp-calculator': ReportsLrpCalculator;
	}
}
