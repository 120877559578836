import ExposureIndexController from 'vault-client/controllers/exposure/index';

export default class OrganizationsOrganizationExposuresController extends ExposureIndexController {
	exposureRoutePath = 'organizations.organization.exposure';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'organizations/organization/exposures': OrganizationsOrganizationExposuresController;
	}
}
