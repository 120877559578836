/* eslint-disable ember/no-controller-access-in-routes */
import Route from '@ember/routing/route';
import { gql, useQuery } from 'glimmer-apollo';
import { tracked } from '@glimmer/tracking';
import { EntityGroupFilterDTO, Query_EntityGroupsArgs, EntityGroup, OrganizationEntity } from 'vault-client/types/graphql-types';

type SortObj = {
	valuePath: string;
	isAscending: boolean;
};

interface ModelParams {
	sorts: SortObj[];
	id: string;
}
type GetEntityGroupArgs = Query_EntityGroupsArgs | { organizationId: string };

const GET_ENTITY_GROUP_QUERY = gql`
	query EntityGroups($orderBy: EntityGroupSortByDTO, $where: EntityGroupFilterDTO, $organizationId: String!) {
		EntityGroups(orderBy: $orderBy, where: $where) {
			id
			name
			hasWriteAccess
		}
		Organization(id: $organizationId) {
			hasWriteAccess
			id
			CurrentUserPermissions {
				canWriteEntityGroup
			}
		}
	}
`;

type GetEntityGroupQuery = {
	__typename?: 'Query';

	EntityGroups: EntityGroup[];
	Organization: OrganizationEntity;
};

export default class OrganizationPermissionGroups extends Route {
	@tracked variables: GetEntityGroupArgs = {
		where: {},
		orderBy: {},
		organizationId: '',
	};

	queryParams = {
		sorts: { refreshModel: true },
	};

	getEntityGroup = useQuery<GetEntityGroupQuery, GetEntityGroupArgs>(this, () => [
		GET_ENTITY_GROUP_QUERY,
		{
			variables: this.variables,
		},
	]);

	async model(params: ModelParams) {
		const organizationParams: { id: string } = this.paramsFor('organizations.organization') as { id: string };
		const where: EntityGroupFilterDTO = {};
		const orderBy: {
			[key: string]: any;
		} = {};

		where.organizationId = { equals: organizationParams.id };

		if (params.sorts) {
			params.sorts.forEach((sort) => {
				const value = sort.isAscending ? 'Asc' : 'Desc';
				orderBy[sort.valuePath] = value;
			});
		}

		this.variables = {
			where,
			orderBy,
			organizationId: organizationParams.id,
		};

		await this.getEntityGroup.promise;

		return this.getEntityGroup;
	}
}
