import Controller from '@ember/controller';
import { ModelFrom } from 'vault-client/utils/type-utils';
import OrganizationsOrganizationExposureHedgeMonthDetailRoute from 'vault-client/routes/organizations/organization/exposure-hedge-month-detail';
import BusinessesBusinessExposureHedgeMonthDetailRoute from 'vault-client/routes/businesses/business/exposure-hedge-month-detail';
import { cached, tracked } from '@glimmer/tracking';
import intlDateTimeFormat from 'vault-client/utils/intl-date-time-format';
import { CellComponents, TableColumn } from 'vault-client/types/vault-table';
import { DateTime } from 'luxon';
import Big from 'big.js';
import { calculateMonthlyWeightedPricesAndBasisValues } from 'vault-client/utils/milk-check-utils';
import { roundTo } from 'vault-client/utils/round-to';
import {
	Future,
	LgmInsuranceEndorsement,
	LrpInsuranceEndorsement,
	DrpInsuranceEndorsement,
	DerivedDrpEndorsement,
	Swap,
	Swaption,
	Option,
	TypeOfInstrument,
	CurrentAllocationPosition,
	PositionComponentAllocation,
	AggregateForecastedMilkProductionByMonthDTO,
	ForecastedMilkProductionByMonth,
	InsuranceEndorsementAllocationRatio,
	AggregateEntityAllocatedExposureRatioDTO,
	TypeOfOption,
	LrpEndorsement,
	Query_insuranceRatioScenarioPricingForFixedPriceSetsArgs,
	Query,
	InsuranceRatioScenarioPricingOutput,
} from 'vault-client/types/graphql-types';
import { service } from '@ember/service';
import MarketDataService from 'vault-client/services/market-data';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';
import PermissionsService from 'vault-client/services/permissions';
import productSlugToPricingUnit from 'vault-client/utils/product-slug-to-pricing-unit';
import DeltaService from 'vault-client/services/delta';
import InsuranceQuarter from 'vault-client/models/insurance-quarter';
import { ChartData, ChartDataset } from 'chart.js';
import { guidFor } from '@ember/object/internals';
import { gql, useQuery } from 'glimmer-apollo';
import LrpEndorsementModel from 'vault-client/models/lrp-endorsement';
import { getOwner } from '@ember/application';
import LgmEndorsementModel from 'vault-client/models/lgm-endorsement';

const CORN_LBS_PER_BUSHEL = 56;
const LBS_PER_SHORT_TON = 2000;

export type LgmInsuranceEndorsementByCoverageMonth = {
	coverageMonth?: string;
	startDate?: string;
	endDate?: string;
	target?: number;
	monthIndex?: number;
	expectedGrossMargin?: number;
	corn?: number;
	sbm?: number;
	expectedFeedCost?: number;
	children?: Array<PerMonthData>;
	coverageMonths?: Array<string>;
	perMonthData?: Array<PerMonthData>;
	pnl?: number | null;
	RatioAdjustedInsuranceEndorsement?: LgmInsuranceEndorsement | undefined;
};

type LrpInsuranceEndorsementAllocationRatios = {
	effectiveHedgeDate: string;
	RatioAdjustedInsuranceEndorsement: LrpInsuranceEndorsement;
};
type DrpInsuranceEndorsementAllocationRatios = {
	effectiveHedgeDate: string;
	RatioAdjustedInsuranceEndorsement: DrpInsuranceEndorsement;
	RatioAdjustedDerivedDrpInsuranceEndorsement: DerivedDrpEndorsement;
};

type NetHedgePrice = {
	['Market Price']: { x: number; y: number }[];
	['Net Hedge Price']: { x: number; y: number }[];
};
type NetHedgePl = {
	Brokerage: { x: number; y: number }[];
	LRP: { x: number; y: number }[];
	LGM: { x: number; y: number }[];
	'Net Hedge': { x: number; y: number }[];
};
export interface PerMonthData {
	coverageMonth?: string | null;
	startDate?: string | null;
	endDate?: string | null;
	monthIndex?: number | null;
	target?: number | null;
	corn?: number | null;
	expectedFeedCost?: number | null;
	expectedGrossMargin?: number | null;
	sbm?: number | null;
	delta?: number | null;
}

interface SwineRevenue {
	quantity: number | null;
	revenuePerHead: number | null;
	totalValue: number | null;
}

interface MilkPricesAndProduction {
	classIIIRevenue: number;
	classIIIProductionPerDay: any;
	classIIIProduction: number;
	averageClassIIIPrice: number | null;
	classIVRevenue: number;
	classIVProductionPerDay: number;
	classIVProduction: number;
	averageClassIVPrice: number | null;
}

interface PercentHedgedData {
	Brokerage?: number;
	DRP?: number;
	LGM?: number;
	LRP?: number;
	Physical?: number;
}
interface MilkExposureAndHedgeValuesArgs {
	startDate: string;
	endDate: string;
	endorsementData: InsuranceEndorsementAllocationRatio[];
	productionData: ForecastedMilkProductionByMonth[];
	classiiiExposureRatio: AggregateEntityAllocatedExposureRatioDTO | null;
	classivExposureRatio: AggregateEntityAllocatedExposureRatioDTO | null;
	butterExposureRatio: AggregateEntityAllocatedExposureRatioDTO | null;
	cheeseExposureRatio: AggregateEntityAllocatedExposureRatioDTO | null;
	dryWheyExposureRatio: AggregateEntityAllocatedExposureRatioDTO | null;
	nonfatExposureRatio: AggregateEntityAllocatedExposureRatioDTO | null;
	classiiiBrokerageHedgedVolume: number | null;
	classivBrokerageHedgedVolume: number | null;
}

const INSURANCE_SCENARIO_PRICING = gql`
	query insuranceRatioScenarioPricingForFixedPriceSets($input: InsuranceRatioScenarioPricingFixedPriceSetsInput!) {
		insuranceRatioScenarioPricingForFixedPriceSets(input: $input) {
			totalPnl
		}
	}
`;

type insuranceRatioScenarioQuery = Query['insuranceScenarioPricingForFixedPriceSets'] & {
	insuranceRatioScenarioPricingForFixedPriceSets: InsuranceRatioScenarioPricingOutput[];
};

// Extends Insurance Quarter. We need the same functionallity, but the timeframe may be shorter/longer than a quarter.
// Added helpers to get this working without aggregate endpoints
class InsuranceCoverage extends InsuranceQuarter {
	cheeseGrossProtection: number = 0;
	butterGrossProtection: number = 0;

	constructor(startDate: string, endDate: string) {
		super(startDate);
		this.startDate = startDate;
		this.endDate = endDate;
	}

	get butterVolumeHedged() {
		return this.butterGrossProtection;
	}

	get cheeseVolumeHedged() {
		return this.cheeseGrossProtection;
	}

	addForecastedMilkProductionByMonths(production: ForecastedMilkProductionByMonth[]) {
		const aggregatedProduction = production.reduce<AggregateForecastedMilkProductionByMonthDTO>(
			(acc, month) => {
				acc.sum!.grossProduction! += month.grossProduction ?? 0;
				acc.sum!.grossButterfatProduction! += month.grossButterfatProduction ?? 0;
				acc.sum!.grossProteinProduction! += month.grossProteinProduction ?? 0;
				acc.sum!.grossOtherSolidsProduction! += month.grossOtherSolidsProduction ?? 0;
				acc.sum!.grossClassiProduction! += month.grossClassiProduction ?? 0;
				acc.sum!.grossClassiiProduction! += month.grossClassiiProduction ?? 0;
				acc.sum!.grossClassiiiProduction! += month.grossClassiiiProduction ?? 0;
				acc.sum!.grossClassivProduction! += month.grossClassivProduction ?? 0;

				return acc;
			},
			{
				avg: {},
				max: {},
				min: {},
				sum: {
					grossProduction: 0,
					grossButterfatProduction: 0,
					grossProteinProduction: 0,
					grossOtherSolidsProduction: 0,
					grossClassiProduction: 0,
					grossClassiiProduction: 0,
					grossClassiiiProduction: 0,
					grossClassivProduction: 0,
				},
			},
		);

		this.addProductionData(aggregatedProduction);
	}

	addAllocatedEndorsements(allocatedEndorsements: InsuranceEndorsementAllocationRatio[]) {
		allocatedEndorsements.forEach((endorsement) => {
			this.cheeseGrossProtection += endorsement.RatioAdjustedDerivedDrpInsuranceEndorsement?.cheeseGrossProtection ?? 0;
			this.butterGrossProtection += endorsement.RatioAdjustedDerivedDrpInsuranceEndorsement?.butterGrossProtection ?? 0;
			this.addAllocatedEndorsementData(endorsement);
		});
	}
}
class MilkExposureAndDrpHedgeValues {
	insuranceCoverage: InsuranceCoverage;

	classiiiExposureRatio: AggregateEntityAllocatedExposureRatioDTO | null;
	classivExposureRatio: AggregateEntityAllocatedExposureRatioDTO | null;
	butterExposureRatio: AggregateEntityAllocatedExposureRatioDTO | null;
	cheeseExposureRatio: AggregateEntityAllocatedExposureRatioDTO | null;
	dryWheyExposureRatio: AggregateEntityAllocatedExposureRatioDTO | null;
	nonfatExposureRatio: AggregateEntityAllocatedExposureRatioDTO | null;

	classiiiBrokerageHedgedVolume: number;
	classivBrokerageHedgedVolume: number;

	constructor(data: MilkExposureAndHedgeValuesArgs) {
		// Insurance
		this.insuranceCoverage = new InsuranceCoverage(data.startDate, data.endDate);
		this.insuranceCoverage.addAllocatedEndorsements(data.endorsementData);
		this.insuranceCoverage.addForecastedMilkProductionByMonths(data.productionData);

		// Exposure
		this.classiiiExposureRatio = data.classiiiExposureRatio;
		this.classivExposureRatio = data.classivExposureRatio;
		this.butterExposureRatio = data.butterExposureRatio;
		this.cheeseExposureRatio = data.cheeseExposureRatio;
		this.dryWheyExposureRatio = data.dryWheyExposureRatio;
		this.nonfatExposureRatio = data.nonfatExposureRatio;

		// Class III and IV Brokerage
		this.classiiiBrokerageHedgedVolume = data.classiiiBrokerageHedgedVolume ?? 0;
		this.classivBrokerageHedgedVolume = data.classivBrokerageHedgedVolume ?? 0;
	}

	// Class III
	get classiiiExposure() {
		return this.classiiiExposureRatio?.sum?.productionExposure;
	}

	get classiiiPercentHedgedDrp() {
		return this.insuranceCoverage.classIiiPercentInsured;
	}

	get classiiiPercentHedgedBrokerage() {
		if (!this.classiiiExposure) return null;
		return this.classiiiBrokerageHedgedVolume / this.classiiiExposure;
	}

	// Class IV
	get classivExposure() {
		return this.classivExposureRatio?.sum?.productionExposure;
	}

	get classivPercentHedgedDrp() {
		return this.insuranceCoverage.classIvPercentInsured;
	}

	get classivPercentHedgedBrokerage() {
		if (!this.classivExposure) return null;
		return this.classivBrokerageHedgedVolume / this.classivExposure;
	}

	// Butter
	get butterExposure() {
		const productionExposure = this.butterExposureRatio?.sum?.productionExposure;
		const classiiiExposure = this.classiiiExposure;
		const classivExposure = this.classivExposure;

		if (productionExposure == null && classiiiExposure == null && classivExposure == null) return null;

		return (productionExposure || 0) + (classiiiExposure || 0) * 0.080532099445 + ((classivExposure || 0) * 0.035) / 1.211;
	}

	get butterPercentHedgedDrp() {
		const butterExposure = this.butterExposure;
		const butterVolumeHedgedDrp = this.insuranceCoverage.butterVolumeHedged;

		if (!butterExposure) return null;
		return butterVolumeHedgedDrp / butterExposure;
	}

	// Cheese
	get cheeseExposure() {
		const productionExposure = this.cheeseExposureRatio?.sum?.productionExposure;
		const classiiiExposure = this.classiiiExposure;

		if (productionExposure == null && classiiiExposure == null) return null;

		return (productionExposure || 0) + (classiiiExposure || 0) * 0.0963933096;
	}

	get cheesePercentHedgedDrp() {
		const cheeseExposure = this.cheeseExposure;
		const cheeseVolumeHedgedDrp = this.insuranceCoverage.cheeseVolumeHedged;

		if (!cheeseExposure) return null;
		return cheeseVolumeHedgedDrp / cheeseExposure;
	}

	// Dry Whey
	get dryWheyExposure() {
		const productionExposure = this.dryWheyExposureRatio?.sum?.productionExposure;
		const classiiiExposure = this.classiiiExposure;

		if (productionExposure == null && classiiiExposure == null) return null;

		return (productionExposure || 0) + (classiiiExposure || 0) * 0.05864305;
	}

	// Nonfat Milk
	get nonfatExposure() {
		const productionExposure = this.nonfatExposureRatio?.sum?.productionExposure;
		const classivExposure = this.classivExposure;

		if (productionExposure == null && classivExposure == null) return null;

		return (productionExposure || 0) + ((classivExposure || 0) * 0.08685) / 0.99;
	}
}

enum ChartDisplayData {
	HedgePl = 'Hedge P/L',
	HedgePrice = 'Hedge Price',
}

export default class ExposureExposureHedgeMonthDetailController extends Controller {
	queryParams = ['exposureMonthStartDate', 'exposureMonthEndDate'];

	lgmInsuranceEndorsementsRoutePath = '';
	lgmInsuranceEndorsementRoutePath = '';
	lrpInsuranceEndorsementsRoutePath = '';
	lrpInsuranceEndorsementRoutePath = '';
	drpInsuranceEndorsementsRoutePath = '';
	drpInsuranceEndorsementRoutePath = '';
	accountsRoutePath = '';
	positionDetailRoutePath = '';
	positionsRoutePath = '';
	feedContractsRoutePath = '';

	productsWithProductionSupport = [
		'livestock-lean-hogs',
		'us-dairy-butter',
		'us-dairy-class-iv',
		'us-dairy-class-iii',
		'us-dairy-cheese-barrel',
		'us-dairy-cheese-block',
		'us-dairy-nonfat-milk',
		'us-dairy-dry-whey',
		'us-dairy-cheese',
	];

	@service marketData!: MarketDataService;
	@service delta!: DeltaService;
	@service declare router: RouterService;
	@service declare permissions: PermissionsService;

	@tracked activeChartDisplayData = ChartDisplayData.HedgePl;
	@tracked exposureMonthStartDate: string = DateTime.local().startOf('month').toISODate();
	@tracked exposureMonthEndDate: string = DateTime.local().endOf('month').toISODate();

	id = guidFor(this);

	dateRangeOptions = [
		{
			displayName: 'Last Month',
			startDate: DateTime.local().minus({ month: 1 }).startOf('month').toISODate(),
			endDate: DateTime.local().minus({ month: 1 }).endOf('month').toISODate(),
		},
		{
			displayName: 'This Month',
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().endOf('month').toISODate(),
		},
		{
			displayName: 'Next Month',
			startDate: DateTime.local().plus({ month: 1 }).startOf('month').toISODate(),
			endDate: DateTime.local().plus({ month: 1 }).endOf('month').toISODate(),
		},
		{
			displayName: 'This Month & Next Month',
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().plus({ month: 1 }).endOf('month').toISODate(),
		},
		{
			displayName: 'This Month & Last Month',
			startDate: DateTime.local().minus({ month: 1 }).startOf('month').toISODate(),
			endDate: DateTime.local().endOf('month').toISODate(),
		},
		{
			displayName: 'Next 3 Months',
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().plus({ month: 2 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Last 3 Months',
			startDate: DateTime.local().minus({ month: 3 }).startOf('month').toISODate(),
			endDate: DateTime.local().minus({ month: 1 }).endOf('month').toISODate(),
		},
	];

	declare model:
		| ModelFrom<OrganizationsOrganizationExposureHedgeMonthDetailRoute>
		| ModelFrom<BusinessesBusinessExposureHedgeMonthDetailRoute>;

	get applyGrowClassForOverviewSection() {
		return this.showSwineProductionCard || this.showMilkProductionCard || this.showTotalPositionDeltaCard;
	}

	get hedgePlChartId() {
		return `hedge-pl-chart-${this.id}`;
	}

	get hedgePriceChartId() {
		return `hedge-price-chart-${this.id}`;
	}

	get activeChartId() {
		return this.activeChartDisplayData === ChartDisplayData.HedgePl ? this.hedgePlChartId : this.hedgePriceChartId;
	}

	get legendId() {
		return `charts-legend-${this.id}`;
	}

	get chartDisplayDataOptions(): ChartDisplayData[] {
		return [ChartDisplayData.HedgePl, ChartDisplayData.HedgePrice];
	}

	get dateRangeDisplayString() {
		const { startDate, endDate } = this.dateFilterCurrentValue;

		const startDateTime = DateTime.fromISO(startDate);
		const endDateTime = DateTime.fromISO(endDate);

		if (startDateTime.month === endDateTime.month && startDateTime.year === endDateTime.year) {
			return startDateTime.toFormat('MMMM yyyy');
		} else {
			return `${startDateTime.toFormat('MMMM yyyy')} - ${endDateTime.toFormat('MMMM yyyy')}`;
		}
	}

	get showPercentHedgedChart() {
		return (
			this.productsWithProductionSupport.includes(this.model.slug) || (this.isFeedProduct && this.forecastedFeedUsageInPricingUnits.gt(0))
		);
	}

	get product() {
		return this.model.getExposureHedgeMonthDetail.data?.Products[0];
	}

	get pricingInstrument() {
		return this.model.getExposureHedgeMonthDetail.data?.PricingFuture[0] ?? this.product?.CashInstrument ?? null;
	}

	get pricingInstrumentFractionDigits() {
		return this.pricingInstrument?.SymbolGroup?.fractionDigits ?? 0;
	}

	get pricingInstrumentDisplayFactor() {
		return this.pricingInstrument?.SymbolGroup?.displayFactor ?? 1;
	}

	get pricingInstrumentPrice() {
		if (this.pricingInstrument?.barchartSymbol) {
			return this.marketData.getLatestPrice(this.pricingInstrument.barchartSymbol) ?? null;
		}

		return null;
	}

	get productPricingUnit() {
		return productSlugToPricingUnit[this.model.slug]?.pricingUnit ?? 'Pound';
	}

	get exposureProducts() {
		return this.model.getExposureHedgeMonthDetail.data?.ExposureProducts;
	}

	get selectedDateRange() {
		return this.dateRangeOptions.find(
			(option) => option.startDate === this.exposureMonthStartDate && option.endDate === this.exposureMonthEndDate,
		);
	}

	get dateFilterCurrentValue() {
		const selectedDateRange = this.selectedDateRange;
		return {
			startDate: selectedDateRange?.startDate ?? this.exposureMonthStartDate,
			endDate: selectedDateRange?.endDate ?? this.exposureMonthEndDate,
		};
	}

	get lgmColumns(): TableColumn[] {
		const baseColumns = [
			{
				id: 'e3333aac-1a6b-4dfb-ba3a-f037b2d5e596',
				name: 'Sales Date',
				valuePath: 'salesEffectiveDate',
				cellComponent: CellComponents.IntlDateTimeFormat,
				componentArgs: { month: 'numeric', day: 'numeric', year: 'numeric' },
				width: 115,
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
				linkRoute: this.lgmInsuranceEndorsementRoutePath,
				linkModelPath: 'insuranceEndorsementId',
			},
			{
				id: '053c0f4a-9c95-4cc6-a519-f6a248d40306',
				name: 'Coverage Period',
				valuePath: 'coverageMonth',
				cellComponent: CellComponents.String,
				width: 155,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'd975e985-a124-4b3e-b525-ab460317ffbc',
				name: 'Target',
				valuePath: 'target',
				width: 80,
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'a08284ff-44f9-4f96-bd54-cf9de7b138f2',
				name: 'RMA Type',
				valuePath: 'rmaTypeName',
				width: 125,
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '148B502B-0D51-4B2C-92C5-C0C325485C69',
				name: 'Delta Quantity',
				valuePath: 'endorsement.delta',
				width: 120,
				cellComponent: CellComponents.NumericFormat,
				componentArgs: {
					maximumFractionDigits: '1',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '95cf0b71-f1b1-44ca-81b5-4d0db6cbf298',
				name: 'Producer Premium',
				valuePath: 'producerPremiumAmount',
				width: 160,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '7d9118e8-f67a-4789-b365-71cbf81c4540',
				name: 'Gross Margin Guarantee',
				valuePath: 'grossMarginGuarantee',
				width: 165,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'e78b5219-76b1-4bde-92a7-94a851c093bc',
				name: 'Est. Indemnity',
				valuePath: 'indemnity',
				width: 165,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '12ae6896-f84e-4f43-961e-47b62cf5ff41',
				name: 'P/L (EOD)',
				valuePath: 'pnl',
				width: 110,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
				isTotaled: true,
			},
			{
				id: '3f4de6ce-826f-4315-8e70-b0ce1727d213',
				name: 'Producer',
				valuePath: 'producerName',
				cellComponent: CellComponents.String,
				width: 125,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: 'a08284ff-44f9-4f96-bd54-cf9de7b138f2',
				name: 'RMA Type',
				valuePath: 'rmaTypeName',
				width: 125,
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: '822c0a02-739b-4451-9acc-fad4b137587f',
				name: 'Total Gross Margin',
				valuePath: 'totalExpectedGrossMargin',
				width: 165,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: false,
			},
		];

		return baseColumns;
	}

	get lrpColumns(): TableColumn[] {
		const baseColumns = [
			{
				id: 'bc880155-f7ac-40e7-b50a-f5b321fa6d81',
				name: 'Sales Date',
				valuePath: 'salesEffectiveDate',
				cellComponent: CellComponents.IntlDateTimeFormat,
				componentArgs: { month: 'numeric', day: 'numeric', year: 'numeric' },
				width: 115,
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
				linkRoute: this.lrpInsuranceEndorsementRoutePath,
				linkModelPath: 'insuranceEndorsementId',
			},
			{
				id: '795ef295-d23e-43af-9371-89e6c1de112b',
				name: 'Coverage End Date',
				valuePath: 'coverageEndDate',
				cellComponent: CellComponents.IntlDateTimeFormat,
				componentArgs: { month: 'numeric', day: 'numeric', year: 'numeric' },
				width: 165,
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'afd5f5fa-b11a-4aef-b615-28f5648f69a1',
				name: 'price',
				valuePath: 'price',
				width: 110,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
				isTotaled: true,
			},
			{
				id: 'd64dcd54-36e6-4610-bcf6-8825f5cdfd45',
				name: 'Commodity',
				valuePath: 'commodityName',
				cellComponent: CellComponents.String,
				width: 110,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '3047fa83-2436-440b-92de-eaa3e838a88a',
				name: 'Head Count',
				valuePath: 'headCount',
				width: 110,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'decimal',
					minimumFractionDigits: 3,
					maximumFractionDigits: 3,
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '5AE479CF-2929-4984-9E5A-65A333619E8E',
				name: 'Delta Quantity',
				valuePath: 'endorsement.delta',
				width: 70,
				cellComponent: CellComponents.NumericFormat,
				componentArgs: {
					maximumFractionDigits: '1',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '6b201cbb-32c8-4aaf-af66-eae4a258d0e4',
				name: 'Target Weight (CWT)',
				valuePath: 'targetWeightCwt',
				width: 185,
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '2dfd8d0b-c8a9-4e83-8703-a78b0a4ad16c',
				name: 'Total Premium',
				valuePath: 'totalPremium',
				width: 140,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
				isTotaled: true,
			},
			{
				id: 'fb07f078-2444-4592-9009-cac480159a43',
				name: 'Est. Indemnity',
				valuePath: 'indemnity',
				width: 160,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
				isTotaled: true,
			},
			{
				id: '0409572a-9f4d-4812-a693-403908f42032',
				name: 'P/L',
				valuePath: 'pnl',
				width: 110,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
				isTotaled: true,
			},
			{
				id: 'f242ac2e-2c27-466c-8159-5d16358932fc',
				name: 'Producer',
				valuePath: 'producerName',
				cellComponent: CellComponents.String,
				width: 125,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: 'd64dcd54-36e6-4610-bcf6-8825f5cdfd45',
				name: 'Commodity',
				valuePath: 'commodityName',
				cellComponent: CellComponents.String,
				width: 110,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: '669e4200-2b35-444d-93e0-2a36ea39bfdd',
				name: 'Insured Value',
				valuePath: 'insuredValue',
				width: 130,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: false,
				isTotaled: true,
			},
		];
		return baseColumns;
	}

	get drpColumns(): TableColumn[] {
		const baseColumns = [
			{
				id: 'd25b89bd-71f2-4aff-8a45-7f8b1f015526',
				name: 'Producer',
				valuePath: 'producerName',
				cellComponent: CellComponents.String,
				width: 125,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '76470d20-b1ae-4dc0-9ae1-87226d443029',
				name: 'Sales Date',
				valuePath: 'salesEffectiveDate',
				cellComponent: CellComponents.IntlDateTimeFormat,
				componentArgs: { month: 'numeric', day: 'numeric', year: 'numeric' },
				width: 115,
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
				linkRoute: this.drpInsuranceEndorsementRoutePath,
				linkModelPath: 'insuranceEndorsementId',
			},
			{
				id: 'f85e5313-1e1e-44c0-b1c0-38686a9e537a',
				name: 'Quarter',
				valuePath: 'quarter',
				cellComponent: CellComponents.QuarterFormat,
				componentArgs: { month: 'short', year: 'numeric' },
				width: 115,
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '35b51778-2ad1-40ca-a5aa-934c050a19bf',
				name: 'Milk Production',
				isFixed: '',
				isVisible: true,
				cellComponent: CellComponents.String,
				subcolumns: [
					{
						id: '2ea2a91d-8d3e-425c-9c88-852160c32013',
						name: 'Effective',
						valuePath: 'effectiveCoveredMilkProduction',
						width: 110,
						cellComponent: CellComponents.IntlNumberFormat,
						textAlign: 'right',
						isSortable: true,
						isFixed: '',
						isVisible: true,
					},
					{
						id: '8fb9c49e-7412-4094-bbe4-e5c191c6f744',
						name: 'Declared',
						valuePath: 'declaredCoveredMilkProduction',
						width: 110,
						cellComponent: CellComponents.IntlNumberFormat,
						textAlign: 'right',
						isSortable: true,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: '994dda05-c053-4a48-a031-226b8fdf547b',
				name: 'Protected Price',
				valuePath: 'protectedPrice',
				width: 150,
				isFixed: '',
				isVisible: true,
				isSortable: true,
				textAlign: 'right',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
					minimumFractionDigits: '2',
					maximumFractionDigits: '2',
				},
			},
			{
				id: 'c2477e39-3775-4841-a728-d49121f99b92',
				name: 'Producer Premium',
				isFixed: '',
				isVisible: true,
				cellComponent: CellComponents.String,
				subcolumns: [
					{
						id: '16e914d2-61ae-42c5-bace-ce667c3812b1',
						name: 'Total',
						valuePath: 'producerPremiumAmount',
						width: 90,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							minimumFractionDigits: '0',
							maximumFractionDigits: '0',
						},
						textAlign: 'right',
						isSortable: true,
						isFixed: '',
						isVisible: true,
					},
					{
						id: 'e05a65b7-a9f3-4438-bdb1-d58cf827ff17',
						name: 'CWT',
						valuePath: 'producerPremiumAmountCwt',
						width: 75,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							minimumFractionDigits: '2',
							maximumFractionDigits: '2',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: '56c17a22-f6b2-4917-b072-af8e9fd840a7',
				name: 'Est. Indemnity',
				isFixed: '',
				isVisible: true,
				cellComponent: CellComponents.String,
				subcolumns: [
					{
						id: 'f0741de1-4999-4520-a999-22c63c8826c3',
						name: 'Total',
						valuePath: 'indemnity',
						width: 90,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							minimumFractionDigits: '0',
							maximumFractionDigits: '0',
						},
						textAlign: 'right',
						isSortable: true,
						isFixed: '',
						isVisible: true,
					},
					{
						id: '1f7eff84-3769-4f8f-ace1-9a3742c50476',
						name: 'CWT',
						valuePath: 'indemnityCwt',
						width: 75,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							minimumFractionDigits: '2',
							maximumFractionDigits: '2',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: '71b41e25-33eb-4201-8655-1954f89dd74e',
				name: 'Estimated P/L',
				isFixed: '',
				isVisible: true,
				cellComponent: CellComponents.String,
				subcolumns: [
					{
						id: '0d3784d5-ccb6-4359-a28d-9d20f72adde7',
						name: 'Total',
						valuePath: 'pnl',
						width: 90,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							minimumFractionDigits: '0',
							maximumFractionDigits: '0',
							currencySign: 'accounting',
						},
						textAlign: 'right',
						isSortable: true,
						isFixed: '',
						isVisible: true,
					},
					{
						id: 'ee3d4989-ecd7-429c-b1d8-3c7a735499cc',
						name: 'CWT',
						valuePath: 'pnlCwt',
						width: 85,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							minimumFractionDigits: '2',
							maximumFractionDigits: '2',
							currencySign: 'accounting',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
				],
			},
		];
		return baseColumns;
	}

	get brokerageColumns(): TableColumn[] {
		const baseColumns = [
			{
				id: '4bee87d8-d2b6-4ad0-ac9a-c9b61ba1f830',
				name: 'Account',
				valuePath: 'Account.accountNumber',
				cellComponent: CellComponents.String,
				width: 100,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'eeb471ce-6dc4-4295-839d-0b15ea3b5648',
				name: 'Trade Day',
				valuePath: 'effectiveHedgeDate',
				cellComponent: CellComponents.IntlDateTimeFormat,
				componentArgs: { day: 'numeric', month: 'numeric', year: 'numeric' },
				width: 130,
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'ef0db338-2639-48ee-af19-fe99c6af16a2',
				name: 'Commodity',
				valuePath: 'productName',
				cellComponent: CellComponents.String,
				width: 100,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'd12ef5b6-7619-4c99-ada5-ce948417f966',
				name: 'Contracts',
				valuePath: 'contractQuantity',
				width: 100,
				cellComponent: CellComponents.IntlNumberFormat,
				linkRoute: this.positionDetailRoutePath,
				linkModelPath: 'positionId',
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '6955F7C4-7CDD-4F47-9793-D768C0552EC8',
				name: 'Delta Quantity',
				valuePath: 'delta',
				width: 70,
				cellComponent: CellComponents.NumericFormat,
				componentArgs: {
					maximumFractionDigits: '1',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '3f3b2b94-746a-4731-a8e6-f6a0b4b0bc9c',
				name: 'Type',
				valuePath: 'Instrument.type',
				cellComponent: CellComponents.String,
				width: 80,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'e1215276-00b6-4924-9e85-f64a90b6982f',
				name: 'Exp Month',
				valuePath: 'expirationDate',
				cellComponent: CellComponents.MonthFormat,
				width: 110,
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '8dc5de07-7ebf-4bf1-a2df-9ea32eed494f',
				name: 'Avg Trade Price',
				valuePath: 'openSideWeightedAveragePrice',
				width: 135,
				cellComponent: CellComponents.PriceFormat,
				componentArgs: {
					fractionDigitsPath: 'Instrument.SymbolGroup.fractionDigits',
					displayFactorPath: 'Instrument.SymbolGroup.displayFactor',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'adf1c2e7-f142-43ee-8c55-52494601e1a0',
				name: 'Strike',
				valuePath: 'Instrument.strike',
				width: 70,
				cellComponent: CellComponents.PriceFormat,
				componentArgs: {
					fractionDigitsPath: 'Instrument.SymbolGroup.fractionDigits',
					displayFactorPath: 'Instrument.SymbolGroup.displayFactor',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'c826a6af-abf0-4ec9-86a9-d22edbd93dee',
				name: 'Last Price',
				valuePath: 'marketDataInstrument',
				width: 105,
				cellComponent: CellComponents.MarketPriceFormat,
				componentArgs: {
					fractionDigitsPath: 'Instrument.SymbolGroup.fractionDigits',
					displayFactorPath: 'Instrument.SymbolGroup.displayFactor',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '1bded9e4-6890-4bcd-9583-4cc033969e79',
				name: 'Gross P/L',
				valuePath: 'grossPnl',
				width: 80,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'a20ac394-a8c0-44b1-947f-259f7bb1bf98',
				name: 'Session Change',
				valuePath: 'marketDataInstrument',
				width: 140,
				cellComponent: CellComponents.SessionChangeFormat,
				componentArgs: {
					fractionDigitsPath: 'Instrument.SymbolGroup.fractionDigits',
					displayFactorPath: 'Instrument.SymbolGroup.displayFactor',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: '486e0ae1-8287-4c84-8402-c20031d0b420',
				name: 'Side',
				valuePath: 'positionSide',
				cellComponent: CellComponents.String,
				width: 70,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: 'ed25ea97-0760-4cdf-a996-e6be35017a87',
				name: 'Symbol',
				valuePath: 'Instrument.exchangeSymbol',
				cellComponent: CellComponents.String,
				width: 80,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: 'c6eb3ae0-0973-4558-891c-d473e4b5fc51',
				name: 'Contract Month',
				valuePath: 'effectiveHedgeDate',
				cellComponent: CellComponents.IntlDateTimeFormat,
				componentArgs: { month: 'short', year: 'numeric' },
				width: 130,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
		];
		return baseColumns;
	}

	get physicalColumns(): TableColumn[] {
		return [
			{
				id: 'e49e5156-bb35-4609-ac5b-24f1edcd8a94',
				name: 'Business',
				cellComponent: CellComponents.String,
				width: 200,
				valuePath: 'Business.name',
				textAlign: 'left',
				isFixed: '',
				isSortable: false,
				isVisible: true,
			},
			{
				id: '874685e5-b7e8-4b8c-a64e-1e466298e1d0',
				name: 'Contract Number',
				cellComponent: CellComponents.String,
				valuePath: 'contractIdentifier',
				width: 150,
				textAlign: 'left',
				isFixed: '',
				isSortable: false,
				isVisible: true,
			},
			{
				id: '82c71a1e-6344-4359-838a-35c98e9df271',
				name: 'Ingredient',
				cellComponent: CellComponents.String,
				valuePath: 'FeedIngredient.name',
				width: 150,
				textAlign: 'left',
				isFixed: '',
				isSortable: false,
				isVisible: true,
			},
			{
				id: '60eba939-8e07-404b-a8ea-01d0bf08890c',
				name: 'Tons',
				cellComponent: CellComponents.IntlNumberFormat,
				valuePath: 'tons',
				width: 70,
				textAlign: 'right',
				isFixed: '',
				isSortable: false,
				isVisible: true,
			},
			{
				id: '5ca873a6-a953-4532-8e03-34da26d9996d',
				name: 'Flat Price',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
				},
				valuePath: 'flatPrice',
				textAlign: 'right',
				width: 100,
				isFixed: '',
				isSortable: false,
				isVisible: true,
			},
			{
				id: '1b66e4b5-96ad-4601-aeb2-a95f3135343b',
				name: 'Delivery Start Date',
				cellComponent: CellComponents.IntlDateTimeFormat,
				valuePath: 'deliveryStartDate',
				width: 160,
				textAlign: 'left',
				isFixed: '',
				isSortable: false,
				isVisible: true,
			},
			{
				id: 'a92af239-365d-4370-8716-a550ca3e3daa',
				name: 'Delivery End Date',
				cellComponent: CellComponents.IntlDateTimeFormat,
				valuePath: 'deliveryEndDate',
				width: 160,
				textAlign: 'left',
				isFixed: '',
				isSortable: false,
				isVisible: true,
			},
			{
				id: 'a90020c0-db36-4cd6-8d4a-af3c92e9bb9e',
				name: 'Seller',
				cellComponent: CellComponents.String,
				valuePath: 'seller',
				width: 125,
				textAlign: 'left',
				isFixed: '',
				isSortable: false,
				isVisible: true,
			},
			{
				id: '5e35058f-292d-4ed5-b920-c1154de41b39',
				name: 'Location',
				cellComponent: CellComponents.String,
				valuePath: 'location',
				width: 160,
				textAlign: 'left',
				isFixed: '',
				isSortable: false,
				isVisible: true,
			},
		];
	}

	@cached
	get lrpRows() {
		const data = this.model.getLrpEndorsementAllocationRatios?.data
			?.InsuranceEndorsementAllocationRatios as unknown as LrpInsuranceEndorsementAllocationRatios[];
		if (!data) return null;
		return data.map((endorsement: LrpInsuranceEndorsementAllocationRatios) => {
			const { RatioAdjustedInsuranceEndorsement, ...endorsementObj } = endorsement;

			return {
				...endorsementObj,
				producerName: RatioAdjustedInsuranceEndorsement?.InsurancePolicy.producerName,
				coverageEndDate: RatioAdjustedInsuranceEndorsement?.coverageEndDate,
				price: RatioAdjustedInsuranceEndorsement?.coveragePrice,
				commodityName: RatioAdjustedInsuranceEndorsement?.RmaCommodity?.commodityName,
				headCount: RatioAdjustedInsuranceEndorsement?.headCount,
				insuredValue: RatioAdjustedInsuranceEndorsement?.insuredValue,
				totalPremium: RatioAdjustedInsuranceEndorsement?.totalPremiumAmount,
				pnl: RatioAdjustedInsuranceEndorsement?.pnl,
				salesEffectiveDate: RatioAdjustedInsuranceEndorsement?.salesEffectiveDate,
				indemnity: RatioAdjustedInsuranceEndorsement?.indemnity,
				targetWeightCwt: RatioAdjustedInsuranceEndorsement?.targetWeightCwt,
				owner: getOwner(this),
				product: this.model.getExposureHedgeMonthDetail.data?.Products.firstObject,

				endorsement: new LrpEndorsementModel(
					getOwner(this),
					endorsement.RatioAdjustedInsuranceEndorsement,
					this.model.getExposureHedgeMonthDetail.data?.Products.firstObject,
				),
			};
		});
	}

	get drpRows() {
		const data = this.model.getDrpEndorsementAllocationRatios?.data
			?.InsuranceEndorsementAllocationRatios as unknown as DrpInsuranceEndorsementAllocationRatios[];
		const getCwt = (value: number | null | undefined, production: number | null | undefined) => {
			if (production) {
				return ((value ?? 0) / production) * 100;
			}
			return null;
		};
		if (!data) return null;
		return data.map((endorsement: DrpInsuranceEndorsementAllocationRatios) => {
			const { RatioAdjustedDerivedDrpInsuranceEndorsement, RatioAdjustedInsuranceEndorsement, ...endorsementObj } = endorsement;
			const expectedPrice = this.getExpectedPrice(RatioAdjustedDerivedDrpInsuranceEndorsement);
			const converageLevel = RatioAdjustedDerivedDrpInsuranceEndorsement.coverageLevelPercent ?? null;
			const protectedPrice = expectedPrice ? expectedPrice * (converageLevel ?? 0) : null;
			return {
				...endorsementObj,
				producerName: RatioAdjustedInsuranceEndorsement?.InsurancePolicy.producerName,
				salesEffectiveDate: RatioAdjustedInsuranceEndorsement?.salesEffectiveDate,
				quarter: RatioAdjustedInsuranceEndorsement?.DerivedEndorsement?.quarterStartDate,
				effectiveCoveredMilkProduction: RatioAdjustedInsuranceEndorsement?.DerivedEndorsement?.effectiveCoveredMilkProduction,
				declaredCoveredMilkProduction: RatioAdjustedInsuranceEndorsement?.DerivedEndorsement?.declaredCoveredMilkProduction,
				producerPremiumAmount: RatioAdjustedInsuranceEndorsement?.producerPremiumAmount,
				producerPremiumAmountCwt: getCwt(
					RatioAdjustedInsuranceEndorsement?.producerPremiumAmount,
					RatioAdjustedDerivedDrpInsuranceEndorsement?.effectiveCoveredMilkProduction,
				),
				protectedPrice,
				indemnity: RatioAdjustedDerivedDrpInsuranceEndorsement?.indemnity,
				indemnityCwt: getCwt(
					RatioAdjustedDerivedDrpInsuranceEndorsement?.indemnity,
					RatioAdjustedDerivedDrpInsuranceEndorsement?.effectiveCoveredMilkProduction,
				),
				pnl: RatioAdjustedDerivedDrpInsuranceEndorsement?.pnl,
				pnlCwt: getCwt(
					RatioAdjustedDerivedDrpInsuranceEndorsement?.pnl,
					RatioAdjustedDerivedDrpInsuranceEndorsement?.effectiveCoveredMilkProduction,
				),
			};
		});
	}

	@cached
	get lgmRows() {
		const data = this.model.getLgmEndorsementAllocationRatios?.data
			?.InsuranceEndorsementAllocationRatios as unknown as LgmInsuranceEndorsementByCoverageMonth[];
		if (!data) {
			return null;
		}
		const endorsements: any[] = [];

		data.map((endorsement: LgmInsuranceEndorsementByCoverageMonth) => {
			const { RatioAdjustedInsuranceEndorsement, perMonthData, ...endorsementObj } = endorsement;
			//breaking endorsement up into separate row items based on coverage month and `perMonthData`. This is because the users expects to see the endorsement broken up by coverage month and there can be multiple months on a single LGM endorsement.

			const endorsementModel = new LgmEndorsementModel(
				getOwner(this),
				//@ts-ignore
				RatioAdjustedInsuranceEndorsement,
				this.model.getExposureHedgeMonthDetail.data?.Products.firstObject,
			);
			const coverageMonthCount = endorsement.RatioAdjustedInsuranceEndorsement?.coverageMonths?.length;

			const firstCoverageMonth =
				RatioAdjustedInsuranceEndorsement &&
				Array.isArray(RatioAdjustedInsuranceEndorsement?.coverageMonths) &&
				RatioAdjustedInsuranceEndorsement.coverageMonths.length > 0
					? RatioAdjustedInsuranceEndorsement?.coverageMonths[0]
					: null;

			const lastCoverageMonth =
				RatioAdjustedInsuranceEndorsement &&
				Array.isArray(RatioAdjustedInsuranceEndorsement?.coverageMonths) &&
				RatioAdjustedInsuranceEndorsement.coverageMonths.length > 1
					? RatioAdjustedInsuranceEndorsement?.coverageMonths[RatioAdjustedInsuranceEndorsement?.coverageMonths.length - 1]
					: null;

			const targetSum = RatioAdjustedInsuranceEndorsement?.perMonthData?.reduce((acc, { target }) => acc + (target ?? 0), 0);

			const cornSum = RatioAdjustedInsuranceEndorsement?.perMonthData?.reduce((acc, { corn }) => acc + (corn ?? 0), 0);

			const sbmSum = RatioAdjustedInsuranceEndorsement?.perMonthData?.reduce((acc, { sbm }) => acc + (sbm ?? 0), 0);

			const expectedFeedCostSum = RatioAdjustedInsuranceEndorsement?.perMonthData?.reduce(
				(acc, { expectedFeedCost }) => acc + (expectedFeedCost ?? 0),
				0,
			);

			const expectedGrossMarginSum = RatioAdjustedInsuranceEndorsement?.perMonthData?.reduce(
				(acc, { expectedGrossMargin }) => acc + (expectedGrossMargin ?? 0),
				0,
			);

			const pnl = endorsement?.RatioAdjustedInsuranceEndorsement?.pnl;

			const newEndorsementObj = {
				...endorsementObj,
				isCollapsed: true,
				target: targetSum,
				corn: cornSum,
				sbm: sbmSum,
				expectedFeedCost: expectedFeedCostSum,
				grossMarginGuarantee: RatioAdjustedInsuranceEndorsement?.grossMarginGuarantee,
				indemnity: RatioAdjustedInsuranceEndorsement?.indemnity,
				expectedGrossMargin: expectedGrossMarginSum,
				rmaTypeName: RatioAdjustedInsuranceEndorsement?.RmaType?.typeName,
				producerPremiumAmount: RatioAdjustedInsuranceEndorsement?.producerPremiumAmount,
				totalExpectedGrossMargin: RatioAdjustedInsuranceEndorsement?.totalExpectedGrossMargin,
				pnl,
				producerName: RatioAdjustedInsuranceEndorsement?.InsurancePolicy.producerName,
				salesEffectiveDate: RatioAdjustedInsuranceEndorsement?.salesEffectiveDate,
				coverageMonth: `${
					firstCoverageMonth
						? intlDateTimeFormat({
								value: firstCoverageMonth,
								month: 'short',
								year: 'numeric',
							})
						: '-'
				} thru ${
					lastCoverageMonth
						? intlDateTimeFormat({
								value: lastCoverageMonth,
								month: 'short',
								year: 'numeric',
							})
						: '-'
				}`,
				endorsement: endorsementModel,
			};
			const childrenArray: PerMonthData[] = [];

			if (coverageMonthCount) {
				for (let i = 0; i < coverageMonthCount; i++) {
					const child: PerMonthData | null | undefined = {};
					const { coverageMonths, perMonthData } = RatioAdjustedInsuranceEndorsement
						? RatioAdjustedInsuranceEndorsement
						: { coverageMonths: [], perMonthData: [] };

					child.coverageMonth = coverageMonths?.length
						? intlDateTimeFormat({
								value: coverageMonths[i],
								month: 'short',
								year: 'numeric',
							})
						: null;
					child.monthIndex = perMonthData?.length ? perMonthData[i].monthIndex : null;
					child.target = perMonthData?.length ? perMonthData[i].target : null;
					child.corn = perMonthData?.length ? perMonthData[i].corn : null;
					child.expectedFeedCost = perMonthData?.length ? perMonthData[i].expectedFeedCost : null;
					child.expectedGrossMargin = perMonthData?.length ? perMonthData[i].expectedGrossMargin : null;
					child.sbm = perMonthData?.length ? perMonthData[i].sbm : null;

					childrenArray.push(child);
				}
			}

			newEndorsementObj.children = childrenArray;
			endorsements.push(newEndorsementObj);
		});
		return endorsements;
	}

	get brokerageRows() {
		const data = this.model.getExposureHedgeMonthDetail?.data?.CurrentAllocationPositions;
		if (!data) return null;
		return data.map((position) => {
			const { instrument, barchartSymbol } = this.getInstrumentAndBarchartSymbol(position);
			instrument as Future | Option | Swap | Swaption;
			if (barchartSymbol) {
				this.delta.register(barchartSymbol);
			}

			let instrumentDelta = null;

			if (instrument.type === TypeOfInstrument.Future || instrument.type === TypeOfInstrument.Swap) {
				instrumentDelta = 1;
			}

			if (instrument.type === TypeOfInstrument.Option && barchartSymbol) {
				instrumentDelta = this.delta.getDelta(barchartSymbol);
			}

			const allocationDelta = instrumentDelta && position.contractQuantity ? position.contractQuantity * instrumentDelta : null;
			return {
				...position,
				marketDataInstrument: instrument,
				isCollapsed: true,
				delta: allocationDelta,
				productName: position.Product.name,
				expirationDate: instrument.displayExpiresAt,
				children: position.PositionComponentAllocations.map((allocation) => {
					const { instrument: allocationInstrument } = this.getInstrumentAndBarchartSymbol(allocation);
					let positionSide: 'Closed' | 'Long' | 'Short';
					if (allocation.netContractQuantity > 0) {
						positionSide = 'Long';
					} else if (allocation.netContractQuantity < 0) {
						positionSide = 'Short';
					} else {
						positionSide = 'Closed';
					}

					// Since the child's market data will always match the parent's, only pass the price, quantity, side, and symbolGroup
					return {
						id: allocation.id,
						Instrument: { SymbolGroup: allocationInstrument.SymbolGroup },
						openSideWeightedAveragePrice: allocation.price,
						contractQuantity: allocation.netContractQuantity,
						positionSide,
					};
				}),
			};
		});
	}

	get physicalRows() {
		return this.model.getFeedTransactions?.data?.FeedTransactions ?? [];
	}

	get showTotalPositionDeltaCard() {
		return this.model.isLrp && this.model.isLgm;
	}

	get totalPositionDelta() {
		if (this.brokerageRows?.length == 0 && this.lrpRows?.length == 0 && this.lgmRows?.length == 0) return null;

		const brokerageTotalDelta =
			this.brokerageRows?.reduce((acc, position) => {
				acc += position.delta ?? 0;
				return acc;
			}, 0) ?? 0;

		const lrpTotalDelta =
			this.lrpRows?.reduce((acc, endorsement) => {
				acc += endorsement.endorsement.delta ?? 0;
				return acc;
			}, 0) ?? 0;

		const lgmTotalDelta =
			this.lgmRows?.reduce((acc, endorsement) => {
				acc += endorsement.endorsement.delta || 0;
				return acc;
			}, 0) ?? 0;

		return brokerageTotalDelta + lrpTotalDelta + lgmTotalDelta;
	}

	get showSwineProductionCard() {
		return this.model.slug === 'livestock-lean-hogs';
	}

	get milkProductionCardTitle() {
		if (this.model.slug === 'us-dairy-class-iii') {
			return 'Class III Milk Production';
		} else if (this.model.slug === 'us-dairy-class-iv') {
			return 'Class IV Milk Production';
		} else {
			return '';
		}
	}

	get showMilkProductionCard() {
		return this.model.slug === 'us-dairy-class-iii' || this.model.slug === 'us-dairy-class-iv';
	}

	get modelLastUpdatedAt() {
		try {
			return DateTime.fromISO(this.model.lastUpdatedAt).toLocaleString(DateTime.DATETIME_FULL);
		} catch {
			return '';
		}
	}

	get grossPlBrokerage() {
		const grossPl =
			this.model.getExposureHedgeMonthDetail?.data?.CurrentAllocationPositions.reduce((acc, position) => {
				acc += position.grossPnl ?? 0;
				return acc;
			}, 0) ?? 0;

		return this.productionExposureInPricingUnits ? Big(grossPl).div(this.productionExposureInPricingUnits).toNumber() : 0;
	}

	get netPlLgm() {
		const netPl =
			this.model.getLgmEndorsementAllocationRatios?.data?.InsuranceEndorsementAllocationRatios?.reduce((acc, endorsement) => {
				acc += endorsement.RatioAdjustedInsuranceEndorsement.pnl ?? 0;
				return acc;
			}, 0) ?? 0;

		return this.productionExposureInPricingUnits ? Big(netPl).div(this.productionExposureInPricingUnits).toNumber() : 0;
	}

	get netPlLrp() {
		const netPl =
			this.model.getLrpEndorsementAllocationRatios?.data?.InsuranceEndorsementAllocationRatios?.reduce((acc, endorsement) => {
				acc += endorsement.RatioAdjustedInsuranceEndorsement.pnl ?? 0;
				return acc;
			}, 0) ?? 0;

		return this.productionExposureInPricingUnits ? Big(netPl).div(this.productionExposureInPricingUnits).toNumber() : 0;
	}

	get fiftyTwoWeekRange(): { high?: number; low?: number } {
		const barchartSymbol = this.pricingInstrument?.barchartSymbol;
		const range: { high?: number; low?: number } = {};

		if (!barchartSymbol) return range;

		const datum = this.marketData.getMarketDatum(barchartSymbol) as { fiftyTwoWkHigh: number; fiftyTwoWkLow: number };

		const high = datum?.fiftyTwoWkHigh ?? null;
		const low = datum?.fiftyTwoWkLow ?? null;

		if (high !== null) {
			range.high = high;
		}

		if (low !== null) {
			range.low = low;
		}

		return range;
	}

	get netPnlDrp() {
		const netPl =
			this.model.getDrpEndorsementAllocationRatios?.data?.InsuranceEndorsementAllocationRatios?.reduce((acc, endorsement) => {
				const pnl = endorsement.RatioAdjustedDerivedDrpInsuranceEndorsement?.pnl ?? 0;
				acc += pnl;
				return acc;
			}, 0) ?? 0;

		// Divide netPnl ($) by DRP Exposure (cwt) to get netPnl in $ per Cwt (pricing unit)
		return this.productionExposureInPricingUnits ? Big(netPl).div(this.productionExposureInPricingUnits).toNumber() : 0;
	}

	get hedgePlPerUnitData() {
		if (this.model.isLrp || this.model.isLgm) {
			return {
				CurrentPrice: this.pricingInstrumentPrice,
				Brokerage: this.grossPlBrokerage,
				LRP: this.netPlLrp,
				LGM: this.netPlLgm,
			};
		} else if (this.model.isDrp) {
			return {
				CurrentPrice: this.pricingInstrumentPrice,
				Brokerage: this.grossPlBrokerage,
				DRP: this.netPnlDrp,
			};
		} else {
			return {
				CurrentPrice: this.pricingInstrumentPrice,
				Brokerage: this.grossPlBrokerage,
			};
		}
	}

	get swineRevenue(): SwineRevenue {
		const revenue = this.model.getExposureHedgeMonthDetail.data?.SwineRevenue[0];
		const quantity = revenue?.sum.quantity ? -revenue?.sum.quantity : null;
		const totalValue = revenue?.sum.totalValue ? -revenue?.sum.totalValue : null;
		const revenuePerHead = quantity
			? Big(totalValue ?? 0)
					.div(quantity)
					.toNumber()
			: null;

		return {
			quantity,
			revenuePerHead,
			totalValue,
		};
	}

	get daysInRange(): number {
		if (!this.exposureMonthStartDate || !this.exposureMonthEndDate) {
			return 0;
		}

		return roundTo(DateTime.fromISO(this.exposureMonthEndDate).diff(DateTime.fromISO(this.exposureMonthStartDate), 'days').days, 0) + 1;
	}

	get milkPricesAndProduction(): MilkPricesAndProduction {
		const { classIIIRevenue, classIVRevenue, classIIIProduction, classIVProduction } = calculateMonthlyWeightedPricesAndBasisValues(
			this.exposureMonthStartDate,
			this.exposureMonthEndDate,
			this.model.getExposureHedgeMonthDetail.data?.ForecastedMilkProductionByMonths ?? [],
			this.model.getExposureHedgeMonthDetail.data?.ActualBlendedMilkPrices ?? [],
		).reduce(
			(totals, month) => {
				const classIIIProductionCwt = (month.grossClassIIIProduction ?? 0) / 100;
				const classIVProductionCwt = (month.grossClassIVProduction ?? 0) / 100;

				totals.classIIIRevenue += (month.classIIIPrice ?? this.getNearestFuturePrice(month.date, 'class-iii') ?? 0) * classIIIProductionCwt;
				totals.classIVRevenue += (month.classIVPrice ?? this.getNearestFuturePrice(month.date, 'class-iv') ?? 0) * classIVProductionCwt;
				totals.classIIIProduction += month.grossClassIIIProduction ?? 0;
				totals.classIVProduction += month.grossClassIVProduction ?? 0;
				return totals;
			},
			{
				classIIIRevenue: 0,
				classIVRevenue: 0,
				classIIIProduction: 0,
				classIVProduction: 0,
			},
		);

		const classIIIProductionPerDay = Big(classIIIProduction).div(this.daysInRange).toNumber();
		const classIVProductionPerDay = Big(classIVProduction).div(this.daysInRange).toNumber();

		const averageClassIIIPrice = classIIIRevenue ? Big(classIIIRevenue).div(Big(classIIIProduction).div(100)).toNumber() : null;
		const averageClassIVPrice = classIVRevenue ? Big(classIVRevenue).div(Big(classIVProduction).div(100)).toNumber() : null;

		return {
			classIIIRevenue,
			classIIIProductionPerDay,
			classIIIProduction,
			averageClassIIIPrice,
			classIVRevenue,
			classIVProductionPerDay,
			classIVProduction,
			averageClassIVPrice,
		};
	}

	get milkValues(): {
		cwtPerDay: number | null;
		totalCwt: number | null;
		price: number | null;
		projectedRevenue: number | null;
	} {
		const milkPricesAndProduction = this.milkPricesAndProduction;

		if (this.model.slug === 'us-dairy-class-iii') {
			return {
				cwtPerDay: Big(milkPricesAndProduction.classIIIProductionPerDay).div(100).toNumber(),
				totalCwt: Big(milkPricesAndProduction.classIIIProduction).div(100).toNumber(),
				price: milkPricesAndProduction.averageClassIIIPrice,
				projectedRevenue: milkPricesAndProduction.classIIIRevenue,
			};
		} else if (this.model.slug === 'us-dairy-class-iv') {
			return {
				cwtPerDay: Big(milkPricesAndProduction.classIVProductionPerDay).div(100).toNumber(),
				totalCwt: Big(milkPricesAndProduction.classIVProduction).div(100).toNumber(),
				price: milkPricesAndProduction.averageClassIVPrice,
				projectedRevenue: milkPricesAndProduction.classIVRevenue,
			};
		} else {
			return {
				cwtPerDay: null,
				totalCwt: null,
				price: null,
				projectedRevenue: null,
			};
		}
	}

	get isDrpProduct() {
		return this.model.isDrp;
	}

	get isLrpProduct() {
		return this.model.isLgm;
	}

	get isLgmProduct() {
		return this.model.isLgm;
	}

	get isPhysicalProduct() {
		return this.model.isPhysical;
	}

	get isFeedProduct() {
		return this.model.isFeed;
	}

	get hasExposure() {
		return !!this.productionExposure;
	}

	get productionExposureInPricingUnits() {
		const productionExposure = this.hedgedData?.productionExposure ?? 0;

		if (productSlugToPricingUnit[this.model.slug]?.pricingUnit === 'CWT') {
			return productionExposure / 100;
		} else {
			return productionExposure;
		}
	}

	get hedgedData() {
		return this.model.getExposureHedgeMonthDetail.data?.AggregateEntityAllocatedExposureRatios[0]?.sum ?? null;
	}

	@cached
	get milkExposureAndDrpHedgeValues(): MilkExposureAndDrpHedgeValues | null {
		const selectedProductExposure = this.model.getDairyRelatedData?.data?.SelectedProductExposure[0] ?? null;

		let classiiiBrokerageHedgedVolume = 0;
		let classivBrokerageHedgedVolume = 0;
		this.model.getDairyRelatedData?.data?.AggregateDairyBrokerageHedgedValues.forEach((forecastedHedgedAndCappedVolume) => {
			if (forecastedHedgedAndCappedVolume?.Product && forecastedHedgedAndCappedVolume.Product.slug === 'us-dairy-class-iii') {
				classiiiBrokerageHedgedVolume += forecastedHedgedAndCappedVolume?.sum.naturallyLongHedged ?? 0;
			} else if (forecastedHedgedAndCappedVolume?.Product && forecastedHedgedAndCappedVolume.Product.slug === 'us-dairy-class-iv') {
				classivBrokerageHedgedVolume += forecastedHedgedAndCappedVolume?.sum.naturallyLongHedged ?? 0;
			}
		});

		const data: MilkExposureAndHedgeValuesArgs = {
			startDate: this.exposureMonthStartDate,
			endDate: this.exposureMonthEndDate,
			endorsementData: this.model.getDrpEndorsementAllocationRatios?.data?.InsuranceEndorsementAllocationRatios ?? [],
			productionData: this.model.getExposureHedgeMonthDetail.data?.ForecastedMilkProductionByMonths ?? [],
			classiiiExposureRatio: this.model.getDairyRelatedData?.data?.ClassIIIExposure[0] ?? null,
			classivExposureRatio: this.model.getDairyRelatedData?.data?.ClassIVExposure[0] ?? null,
			butterExposureRatio: this.model.slug === 'us-dairy-butter' ? selectedProductExposure : null,
			cheeseExposureRatio: this.model.slug === 'us-dairy-cheese' ? selectedProductExposure : null,
			dryWheyExposureRatio: this.model.slug === 'us-dairy-dry-whey' ? selectedProductExposure : null,
			nonfatExposureRatio: this.model.slug === 'us-dairy-nonfat-milk' ? selectedProductExposure : null,
			classiiiBrokerageHedgedVolume,
			classivBrokerageHedgedVolume,
		};

		return new MilkExposureAndDrpHedgeValues(data);
	}

	get drpPercentHedged() {
		const milkExposureAndHedgeValues = this.milkExposureAndDrpHedgeValues;
		switch (this.model.slug) {
			case 'us-dairy-class-iii':
				return milkExposureAndHedgeValues?.classiiiPercentHedgedDrp ?? null;
			case 'us-dairy-class-iv':
				return milkExposureAndHedgeValues?.classivPercentHedgedDrp ?? null;
			case 'us-dairy-butter':
				return milkExposureAndHedgeValues?.butterPercentHedgedDrp ?? null;
			case 'us-dairy-cheese':
				return milkExposureAndHedgeValues?.cheesePercentHedgedDrp ?? null;
			default:
				return null;
		}
	}

	get productionExposure() {
		if (this.model.isFeed) {
			return this.forecastedFeedUsageInPricingUnits;
		} else if (this.model.isDairyProduct) {
			const milkExposureAndHedgeValues = this.milkExposureAndDrpHedgeValues;
			switch (this.model.slug) {
				case 'us-dairy-class-iii':
					return milkExposureAndHedgeValues?.classiiiExposure ?? null;
				case 'us-dairy-class-iv':
					return milkExposureAndHedgeValues?.classivExposure ?? null;
				case 'us-dairy-butter':
					return milkExposureAndHedgeValues?.butterExposure ?? null;
				case 'us-dairy-cheese':
					return milkExposureAndHedgeValues?.cheeseExposure ?? null;
				case 'us-dairy-dry-whey':
					return milkExposureAndHedgeValues?.dryWheyExposure ?? null;
				case 'us-dairy-nonfat-milk':
					return milkExposureAndHedgeValues?.nonfatExposure ?? null;
				default:
					return null;
			}
		} else {
			return this.hedgedData?.productionExposure ?? null;
		}
	}

	get percentHedgedData(): PercentHedgedData {
		const hedgedData = this.hedgedData;

		// Production exposure uses the dairy dashboard logic for dairy products
		// which rolls in class exposure for the relevant dairy products, on top of API data returned production exposure
		// Uses ForecastedFeedUsage for feed products
		const productionExposure = this.productionExposure ? Big(this.productionExposure) : null;
		const brokerageVolumeHedged = hedgedData?.brokerageVolumeHedged ? Big(hedgedData.brokerageVolumeHedged) : null;
		const lgmVolumeHedged = hedgedData?.lgmVolumeHedged ? Big(hedgedData.lgmVolumeHedged) : null;
		const lrpVolumeHedged = hedgedData?.lrpVolumeHedged ? Big(hedgedData.lrpVolumeHedged) : null;
		const physicalVolumeHedged = hedgedData?.physicalVolumeHedged ? Big(hedgedData.physicalVolumeHedged) : null;

		let brokeragePercentage =
			(productionExposure ? brokerageVolumeHedged?.div(productionExposure).toNumber() : brokerageVolumeHedged?.toNumber()) ?? 0;

		// Use the exact same logic as the dairy dashboard for class III and IV brokerage percentage hedged
		if (this.model.slug === 'us-dairy-class-iii') {
			brokeragePercentage = this.milkExposureAndDrpHedgeValues?.classiiiPercentHedgedBrokerage ?? 0;
		} else if (this.model.slug === 'us-dairy-class-iv') {
			brokeragePercentage = this.milkExposureAndDrpHedgeValues?.classivPercentHedgedBrokerage ?? 0;
		}

		const percentHedgedData: PercentHedgedData = {};

		if (this.isDrpProduct) {
			percentHedgedData['DRP'] = this.drpPercentHedged ?? 0;
		}

		if (this.isLgmProduct) {
			const lgmPercentage =
				(productionExposure && !productionExposure.eq(0)
					? lgmVolumeHedged?.div(productionExposure).toNumber()
					: lgmVolumeHedged?.toNumber()) ?? 0;
			percentHedgedData['LGM'] = lgmPercentage;
		}

		if (this.isLrpProduct) {
			const lrpPercentage =
				(productionExposure && !productionExposure.eq(0)
					? lrpVolumeHedged?.div(productionExposure).toNumber()
					: lrpVolumeHedged?.toNumber()) ?? 0;
			percentHedgedData['LRP'] = lrpPercentage;
		}

		if (this.isPhysicalProduct || this.isFeedProduct) {
			const physicalPercentage =
				(productionExposure && !productionExposure.eq(0)
					? physicalVolumeHedged?.div(productionExposure).toNumber()
					: physicalVolumeHedged?.toNumber()) ?? 0;
			percentHedgedData['Physical'] = physicalPercentage;
		}

		percentHedgedData['Brokerage'] = brokeragePercentage;

		return percentHedgedData;
	}

	get totalPercentHedged() {
		return Object.values(this.percentHedgedData)
			.reduce<Big>((a, b) => a.plus(b), Big(0))
			.toNumber();
	}

	get forecastedFeedUsageInPricingUnits() {
		const dairyDmiUsageInTons =
			this.model.getForecastedFeedUsage?.data?.DairyFeedUsage.reduce((acc, usage) => {
				acc = acc.plus(usage.sum.dmiUsageInTons ?? 0);
				return acc;
			}, Big(0)) ?? Big(0);

		const swineDmiUsageInTons =
			this.model.getForecastedFeedUsage?.data?.SwineFeedUsage.reduce((acc, usage) => {
				const dmiUsageInTons = Big(usage.sum.quantityInTons ?? 0).times(usage.Ingredient?.dryMatterPercent ?? 1);
				acc = acc.plus(dmiUsageInTons);
				return acc;
			}, Big(0)) ?? Big(0);

		const totalDmiUsageInTons = dairyDmiUsageInTons.plus(swineDmiUsageInTons);

		const pricingUnit = productSlugToPricingUnit[this.model.slug]?.pricingUnit;

		if (pricingUnit === 'Bushel') {
			return totalDmiUsageInTons.times(LBS_PER_SHORT_TON).div(CORN_LBS_PER_BUSHEL);
		} else if (pricingUnit === 'Pound') {
			return totalDmiUsageInTons.times(LBS_PER_SHORT_TON);
		} else {
			// Return Tons by default
			return totalDmiUsageInTons;
		}
	}

	//
	//	Scenarios
	//

	get productId() {
		return this.model.getExposureHedgeMonthDetail.data?.Products.firstObject?.id;
	}

	get tickValue() {
		return this.model.getExposureHedgeMonthDetail.data?.Products.firstObject?.StandardProductLotSpecification?.tickValue;
	}

	get fractionDigits() {
		return this.pricingInstrumentFractionDigits;
	}

	get transactions() {
		return this.model.getExposureHedgeMonthDetail?.data?.CurrentAllocationPositions;
	}

	get brokerageTransactionInstruments() {
		return this.transactions?.map((transaction) => {
			return transaction.Instrument;
		});
	}

	get lrpEndorsementAllocations() {
		return this.model.getLrpEndorsementAllocationRatios?.data?.InsuranceEndorsementAllocationRatios?.map(
			(endorsement) => endorsement.RatioAdjustedInsuranceEndorsement,
		);
	}

	get lrpEndorsementAllocationIds() {
		return this.model.getLrpEndorsementAllocationRatios?.data?.InsuranceEndorsementAllocationRatios?.map((endorsement) => endorsement.id);
	}

	get inflectionPoints() {
		const brokerageOptions =
			this.brokerageTransactionInstruments?.filter((instrument): instrument is Option | Swaption => {
				return instrument.type === TypeOfInstrument.Option || instrument.type === TypeOfInstrument.Swaption;
			}) ?? [];

		const brokerageStrikes = brokerageOptions.map((option) => {
			return option.strike;
		});

		const lrpStrikes =
			this.lrpEndorsementAllocations?.map((endorsement: LrpEndorsement) => {
				return endorsement.coveragePrice;
			}) || [];

		// Include most recent close price if available.  This will handle most LGM-only use cases.
		let currentPrice = undefined;
		if (this.pricingInstrument?.barchartSymbol) {
			const marketDatum = this.marketData.getMarketDatum(this.pricingInstrument?.barchartSymbol);
			currentPrice = marketDatum?.close ?? marketDatum?.previousClose;
		}

		// DGC: For now, we're not including LGM inflection points.
		const points: number[] = [currentPrice, ...brokerageStrikes, ...lrpStrikes]
			.filter((strike): strike is number => strike !== undefined)
			.uniq();
		return points;
	}

	// This should be every price between the maximum and minimum price in the inflectionPoints array, as well as a buffer of 2 over the max and under the min.
	// At minimum, it should contain minNumberOfPrices.
	get pricePoints() {
		const points: number[] = [];
		const minNumberOfPrices = 200;
		if (!this.tickValue) return points;
		// ternary to support the case where there are no inflection points as with LGM
		const naturalMinPrice = this.inflectionPoints.length ? Math.min(...this.inflectionPoints) : 0;
		const naturalMaxPrice = this.inflectionPoints.length ? Math.max(...this.inflectionPoints) : 0;
		const naturalTickRange = (naturalMaxPrice - naturalMinPrice) / this.tickValue;
		let bufferTicks = Math.ceil(naturalTickRange * 0.1);
		if (naturalTickRange < minNumberOfPrices) {
			bufferTicks = Math.ceil((minNumberOfPrices - naturalTickRange) / 2);
		}

		const minPrice = naturalMinPrice - bufferTicks * this.tickValue * 4;
		const maxPrice = naturalMaxPrice + bufferTicks * this.tickValue * 4;

		points.push(minPrice, maxPrice, ...this.inflectionPoints);
		return points.sort((a, b) => a - b);
	}

	get hasBrokerageData() {
		return this.transactions && this.transactions?.length > 0;
	}

	get hasLrpData() {
		return this.lrpEndorsementAllocations && this.lrpEndorsementAllocations?.length > 0;
	}

	get hasLgmData() {
		return this.lgmEndorsementAllocations && this.lgmEndorsementAllocations?.length > 0;
	}

	get showHedgeAnalysisChart() {
		return this.hasBrokerageData || this.hasLrpData || this.hasLgmData;
	}

	get pointValue() {
		return this.model.getExposureHedgeMonthDetail.data?.Products.firstObject?.StandardProductLotSpecification?.pointValue || 1;
	}

	// Calculate P/L for each price point for each brokerage transaction
	get brokeragePLs() {
		return this.pricePoints.map((price) => {
			return (
				this.transactions?.reduce((total, transaction) => {
					let plPerPoint = 0;
					let premium = 0;
					const pointValue = this.pointValue;
					if (transaction.Instrument.type === TypeOfInstrument.Option || transaction.Instrument.type === TypeOfInstrument.Swaption) {
						const option = transaction.Instrument as Option | Swaption;
						premium = transaction.contractQuantity * transaction.lifetimeWeightedAveragePrice * pointValue;

						if (option.optionType === TypeOfOption.Call) {
							plPerPoint = transaction.contractQuantity * Math.max(0, price - option.strike);
						} else {
							plPerPoint = transaction.contractQuantity * Math.max(0, option.strike - price);
						}
					}

					if (transaction.Instrument.type === TypeOfInstrument.Future || transaction.Instrument.type === TypeOfInstrument.Swap) {
						plPerPoint = transaction.contractQuantity * (price - transaction.lifetimeWeightedAveragePrice);
					}

					return plPerPoint * pointValue - premium + total;
				}, 0) || 0
			);
		});
	}

	get lrpInsuranceScenarioVariables(): Query_insuranceRatioScenarioPricingForFixedPriceSetsArgs {
		return {
			input: {
				//@ts-ignore
				fixedPriceSets: this.pricePoints.map((price) => {
					return {
						fixedPricesByProduct: {
							price: price ?? 0,
							productId: this.productId,
						},
						identifier: price ? price.toString() : '0',
					};
				}),
				/** A filter used to determine the endorsements for which P/L values will be calculated. This filter will be applied in addition to the `scopeId`. If more than 1000 endorsements are returned by the filter, an error will be thrown. */
				insuranceEndorsementAllocationRatioFilter: {
					id: {
						in: this.lrpEndorsementAllocationIds || [],
					},
				},
			},
		};
	}

	rawLrpInsurancePls = useQuery<insuranceRatioScenarioQuery, Query_insuranceRatioScenarioPricingForFixedPriceSetsArgs>(this, () => [
		INSURANCE_SCENARIO_PRICING,
		{
			variables: this.lrpInsuranceScenarioVariables,
		},
	]);

	get lrpInsurancePls() {
		return (
			this.rawLrpInsurancePls.data?.insuranceRatioScenarioPricingForFixedPriceSets?.map((insuranceScenario) => {
				return insuranceScenario.totalPnl;
			}) || []
		);
	}

	get lgmEndorsementAllocations() {
		return this.model.getLgmEndorsementAllocationRatios?.data?.InsuranceEndorsementAllocationRatios?.map(
			(endorsement) => endorsement.RatioAdjustedInsuranceEndorsement,
		);
	}

	get lgmEndorsementAllocationIds() {
		return this.model.getLgmEndorsementAllocationRatios?.data?.InsuranceEndorsementAllocationRatios?.map((ratio) => ratio.id);
	}

	get lgmInsuranceScenarioVariables(): Query_insuranceRatioScenarioPricingForFixedPriceSetsArgs {
		return {
			input: {
				//@ts-ignore
				fixedPriceSets: this.pricePoints.map((price) => {
					return {
						fixedPricesByProduct: {
							price: price ?? 0,
							productId: this.productId,
						},
						identifier: price ? price.toString() : '0',
					};
				}),
				/** A filter used to determine the endorsements for which P/L values will be calculated. This filter will be applied in addition to the `scopeId`. If more than 1000 endorsements are returned by the filter, an error will be thrown. */
				insuranceEndorsementAllocationRatioFilter: {
					id: {
						in: this.lgmEndorsementAllocationIds || [],
					},
				},
			},
		};
	}

	rawLgmInsurancePls = useQuery<insuranceRatioScenarioQuery, Query_insuranceRatioScenarioPricingForFixedPriceSetsArgs>(this, () => [
		INSURANCE_SCENARIO_PRICING,
		{
			variables: this.lgmInsuranceScenarioVariables,
		},
	]);

	get lgmInsurancePls() {
		return (
			this.rawLgmInsurancePls.data?.insuranceRatioScenarioPricingForFixedPriceSets?.map((insuranceScenario) => {
				return insuranceScenario.totalPnl;
			}) || []
		);
	}

	get netPls() {
		// Sum lrpInsurancePls and brokeragePLs
		const netPls: number[] = [];
		this.pricePoints.forEach((_, i) => {
			netPls.push(this.lrpInsurancePls[i] + this.brokeragePLs[i] + this.lgmInsurancePls[i]);
		});

		return netPls;
	}

	get hedgeQuantity() {
		const lrpQuantity =
			this.lrpEndorsementAllocations?.reduce(
				(acc, endorsement: LrpEndorsement) => acc + endorsement.headCount * endorsement.targetWeightCwt * 100,
				0,
			) || 0;

		let lgmQuantity = 0;

		// LGM logic for Lean Hog strategies
		// We need to figure out a better source for this data, especially with the use of the magic value.
		// Can the API surface it?
		if (this.model.slug === 'livestock-lean-hogs') {
			const lgmSwineTargetWeight = 260;
			lgmQuantity =
				this.lgmEndorsementAllocations?.reduce(
					(acc, endorsement: LgmInsuranceEndorsement) => acc + endorsement.totalTarget * lgmSwineTargetWeight,
					0,
				) || 0;
		}

		// LGM Type Codes
		//Live Cattle
		//807 (calf finishing),
		//808 (yearling finishing),

		//Feeder Cattle without Unborn Steers & Heifers (I'm uncertain how Unborn Steers & Heifers affects the quantity of feeder cattle.)
		//809 (Steers Weight 1)
		//810 (Steers Weight 2)
		//811 (Heifers Weight 1)
		//812 (Heifers Weight 2)
		//813 (Brahman Weight 1)
		//814 (Brahman Weight 2)
		//815 (Dairy Weight 1)
		//816 (Dairy Weight 2)
		//817 (Unborn Steers & Heifers) - not used just for reference incase it is needed in the future
		// LGM logic for Feeder Cattle
		// Source: https://extension.missouri.edu/media/wysiwyg/Extensiondata/Pub/pdf/agguides/agecon/g00461.pdf

		if (this.model.slug === 'livestock-feeder-cattle') {
			const yearlingFinishingTargetWeight = 750;
			const calfFinishingTargetWeight = 550;
			lgmQuantity =
				this.lgmEndorsementAllocations?.reduce((acc, endorsement: LgmInsuranceEndorsement) => {
					// Type Code 810, 812, 814, 816 should be Yearling
					if (
						endorsement.RmaType.typeCode == '810' ||
						endorsement.RmaType.typeCode == '814' ||
						endorsement.RmaType.typeCode == '812' ||
						endorsement.RmaType.typeCode == '816'
					) {
						return acc + endorsement.totalTarget * yearlingFinishingTargetWeight;
					}

					// Type Code 809m 811, 813, 815 should be Calf
					if (
						endorsement.RmaType.typeCode == '809' ||
						endorsement.RmaType.typeCode == '811' ||
						endorsement.RmaType.typeCode == '813' ||
						endorsement.RmaType.typeCode == '815'
					) {
						return acc + endorsement.totalTarget * calfFinishingTargetWeight;
					}
					console.error('We found an LGM endorsement without a matching type code.');
					return acc;
				}, 0) || 0;
		}

		// LGM logic for Live Cattle
		// Source: https://extension.missouri.edu/media/wysiwyg/Extensiondata/Pub/pdf/agguides/agecon/g00461.pdf
		if (this.model.slug === 'livestock-live-cattle') {
			const yearlingFinishingTargetWeight = 1250;
			const calfFinishingTargetWeight = 1150;
			lgmQuantity =
				this.lgmEndorsementAllocations?.reduce((acc, endorsement: LgmInsuranceEndorsement) => {
					// Type Code 808 should be Yearling
					if (endorsement.RmaType.typeCode == '808') {
						return acc + endorsement.totalTarget * yearlingFinishingTargetWeight;
					}

					// Type Code 807 should be Calf
					if (endorsement.RmaType.typeCode == '807') {
						return acc + endorsement.totalTarget * calfFinishingTargetWeight;
					}
					console.error('We found an LGM endorsement without a matching type code.');
					return acc;
				}, 0) || 0;
		}

		const brokerageToInclude = this.transactions?.filter(
			(transaction) =>
				transaction.Instrument.type == TypeOfInstrument.Future ||
				transaction.Instrument.type == TypeOfInstrument.Swap ||
				(transaction.Instrument.type == TypeOfInstrument.Option && transaction.contractQuantity > 0) ||
				(transaction.Instrument.type == TypeOfInstrument.Swaption && transaction.contractQuantity > 0),
		);
		const brokerageQuantity =
			brokerageToInclude?.reduce((acc, transaction: CurrentAllocationPosition) => acc + transaction.unitQuantity, 0) || 0;

		const lotSize = this.model.getExposureHedgeMonthDetail.data?.Products.firstObject?.StandardProductLotSpecification?.lotSize || 1;

		return (lrpQuantity + brokerageQuantity + lgmQuantity) / lotSize;
	}

	get hedgePricePl() {
		const pointValue = this.model.getExposureHedgeMonthDetail.data?.Products.firstObject?.StandardProductLotSpecification?.pointValue || 1;
		return this.netPls.map((pl) => pl / pointValue / Math.abs(this.hedgeQuantity));
	}

	// hedge pl chart
	get netHedgePlRawData() {
		const dataObj: NetHedgePl = { Brokerage: [], LRP: [], LGM: [], 'Net Hedge': [] };

		if (this.hasBrokerageData) {
			dataObj.Brokerage = this.brokeragePLs.map((pl, idx) => ({ x: this.pricePoints[idx], y: pl }));
		}

		if (this.hasLrpData) {
			dataObj.LRP = this.lrpInsurancePls.map((pl, idx) => ({ x: this.pricePoints[idx], y: pl }));
		}

		if (this.hasLgmData) {
			dataObj.LGM = this.lgmInsurancePls.map((pl, idx) => ({ x: this.pricePoints[idx], y: pl }));
		}

		dataObj['Net Hedge'] = this.netPls.map((pl, idx) => ({ x: this.pricePoints[idx], y: pl }));

		return dataObj;
	}

	get netHedgePlChartData(): ChartData<'line'> {
		//@ts-ignore DGC: We need to update this type.
		const datasets: ChartDataset<'line', { x: number; y: number }[]>[] = [];

		if (this.netHedgePlRawData && this.hasBrokerageData) {
			datasets.push({
				label: 'Brokerage',
				data: this.netHedgePlRawData.Brokerage,
				borderColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-interactive-blue-70'),
				backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-interactive-blue-70'),
				pointRadius: 0,
				tension: 0.1,
			});
		}

		if (this.netHedgePlRawData && this.hasLrpData) {
			datasets.push({
				label: 'LRP',
				data: this.netHedgePlRawData.LRP,
				borderColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-lime-40'),
				backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-lime-40'),
				pointRadius: 0,
				tension: 0.1,
			});
		}

		if (this.netHedgePlRawData && this.hasLgmData) {
			datasets.push({
				label: 'LGM',
				data: this.netHedgePlRawData.LGM,
				borderColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-lemon-40'),
				backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-lemon-40'),
				pointRadius: 0,
				tension: 0.1,
			});
		}

		datasets.push({
			label: 'Net Hedge',
			data: this.netHedgePlRawData['Net Hedge'],
			borderColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-teal-60'),
			backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-teal-60'),
			pointRadius: 0,
			tension: 0.1,
		});

		return {
			datasets,
		};
	}

	get isNaturallyLong() {
		const hasLrp = this.lrpEndorsementAllocations?.length || false;

		// If an LRP endorsement exists, the strategy is naturally long.
		if (hasLrp) {
			return true;
		}

		const hasLgm = this.lgmEndorsementAllocations?.length || false;
		const isCorn = this.model.slug === 'grain-corn';
		const isSBM = this.model.slug === 'grain-soybean-meal';

		// If an LGM endorsement exists and the product isn't corn or SBM, the strategy is naturally long.
		if (hasLgm && !isCorn && !isSBM) {
			return true;
		}

		const netCallQuantity =
			this.transactions?.reduce((acc, transaction) => {
				if (transaction.Instrument.type === 'Option') {
					if ((transaction.Instrument as Option).optionType === TypeOfOption.Call) {
						return acc + transaction.contractQuantity;
					}
				}
				return acc;
			}, 0) || 0;

		const netPutQuantity =
			this.transactions?.reduce((acc, transaction) => {
				if (transaction.Instrument.type === 'Option') {
					if ((transaction.Instrument as Option).optionType === TypeOfOption.Put) {
						return acc + transaction.contractQuantity;
					}
				}
				return acc;
			}, 0) || 0;

		// If the net quantity of puts is greater than the net quantity of calls, the strategy is naturally long.
		if (netPutQuantity > netCallQuantity) {
			return true;
		}

		// If the net quantity of calls is greater than the net quantity of puts, the strategy is naturally short.
		if (netCallQuantity > netPutQuantity) {
			return false;
		}

		const netFutureQuantity =
			this.transactions?.reduce((acc, transaction) => {
				if (transaction.Instrument.type === 'Future') {
					return acc + transaction.contractQuantity;
				}
				return acc;
			}, 0) || 0;

		if (netFutureQuantity < 0) {
			return true;
		}

		if (netFutureQuantity > 0) {
			return false;
		}

		console.log('Unable to determine if exposure is naturally long or short.  Defaulting to long.');
		return true;
	}

	get netHedgePriceRawData() {
		const dataObj: NetHedgePrice = { 'Market Price': [], 'Net Hedge Price': [] };
		dataObj['Market Price'] = this.pricePoints.map((price) => ({ x: price, y: price }));
		const percentHedged = Math.min(this.totalPercentHedged, 1);

		if (this.isNaturallyLong) {
			// If naturally long, you're trying to increase the hedge price, so the net hedge price is the market price plus the hedge price P/L.
			dataObj['Net Hedge Price'] = this.pricePoints.map((price, idx) => ({
				x: price,
				y: price + (this.hedgePricePl[idx] ?? 0) * percentHedged,
			}));
		} else {
			dataObj['Net Hedge Price'] = this.pricePoints.map((price, idx) => ({
				x: price,
				y: price - (this.hedgePricePl[idx] ?? 0) * percentHedged,
			}));
		}

		return dataObj;
	}

	get netHedgePriceChartData(): ChartData<'line'> {
		//@ts-ignore DGC: We need to update this type.
		const datasets: ChartDataset<'line', (number | null)[]>[] = this.netHedgePlRawData
			? [
					{
						label: 'Market Price',
						data: this.netHedgePriceRawData['Market Price'],
						borderColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-interactive-blue-70'),
						backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-interactive-blue-70'),
						pointRadius: 0,
						tension: 0.1,
					},
					{
						label: 'Net Hedge Price',
						data: this.netHedgePriceRawData['Net Hedge Price'],
						borderColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-lime-40'),
						backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-lime-40'),
						pointRadius: 0,
						tension: 0.1,
					},
				]
			: [];

		return {
			datasets,
		};
	}

	getInstrumentAndBarchartSymbol(position: CurrentAllocationPosition | PositionComponentAllocation) {
		// Retrieve the PriceInstrument and barchartSymbol from the CurrentAllocationPosition
		let barchartSymbol: string | null = null;
		let instrument: Future | Option | Swap | Swaption | null = null;
		let priceInstrument: Future | Option | null = null;

		if (position.Instrument.type === TypeOfInstrument.Swap || position.Instrument.type === TypeOfInstrument.Swaption) {
			instrument = position.Instrument as Swap | Swaption;
			priceInstrument = instrument.PriceInstrument as Future | Option;
			barchartSymbol = priceInstrument?.barchartSymbol ?? null;
		} else {
			instrument = position.Instrument as Future | Option;
			barchartSymbol = instrument?.barchartSymbol ?? null;
		}

		return { instrument, barchartSymbol };
	}

	getNearestFuturePrice(date: string, product: 'class-iii' | 'class-iv') {
		let futures: Future[] = [];

		if (product === 'class-iii') {
			futures = this.model.getExposureHedgeMonthDetail.data?.ClassIIIFutures ?? [];
		} else if (product === 'class-iv') {
			futures = this.model.getExposureHedgeMonthDetail.data?.ClassIVFutures ?? [];
		}

		const future = futures.find((future) => future.displayExpiresAt >= date) ?? null;

		return this.marketData.getLatestPrice(future?.barchartSymbol ?? '');
	}

	getExpectedPrice(endorsement: DerivedDrpEndorsement) {
		const slug = this.product?.slug;
		if (!slug) return null;

		if (slug === 'us-dairy-class-iii') {
			return endorsement.expectedClassIiiPrice ?? null;
		} else if (slug === 'us-dairy-class-iv') {
			return endorsement.expectedClassIvPrice ?? null;
		} else if (slug === 'us-dairy-butter') {
			return endorsement.expectedButterPrice ?? null;
		} else if (slug === 'us-dairy-cheese') {
			return endorsement.expectedCheesePrice ?? null;
		} else {
			return null;
		}
	}

	@action
	changeProduct(slug: string) {
		this.router.transitionTo(this.router.currentRoute.name, this.model.scopeId, slug, {
			queryParams: { exposureMonthStartDate: this.exposureMonthStartDate, exposureMonthEndDate: this.exposureMonthEndDate },
		});
	}

	@action
	setDateRange(value: { startDate: string; endDate: string }) {
		this.exposureMonthStartDate = value.startDate ?? null;
		this.exposureMonthEndDate = value.endDate ?? null;
	}
}
