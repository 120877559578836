import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { TableColumn, CellComponents } from 'vault-client/types/vault-table';
import { DateTime } from 'luxon';
import { HistoricalPnl, Side, TypeOfInstrument } from 'vault-client/types/graphql-types';
import { ModelFrom } from 'vault-client/utils/type-utils';
import ReportsPlByCalendarMonthShowRoute from 'vault-client/routes/businesses/business/pl-by-calendar-month/show';

class Month {
	date: string;
	historicalPnlRows: HistoricalPnl[] = [];

	constructor(date: string) {
		this.date = date;
	}

	get shortOption(): HistoricalPnl | undefined {
		return this.historicalPnlRows.find((row) => {
			return row.side == Side.Short && row.instrumentType == TypeOfInstrument.Option;
		});
	}

	get longOption(): HistoricalPnl | undefined {
		return this.historicalPnlRows.find((row) => {
			return row.side == Side.Long && row.instrumentType == TypeOfInstrument.Option;
		});
	}

	get shortFuture(): HistoricalPnl | undefined {
		return this.historicalPnlRows.find((row) => {
			return row.side == Side.Short && row.instrumentType == TypeOfInstrument.Future;
		});
	}

	get longFuture(): HistoricalPnl | undefined {
		return this.historicalPnlRows.find((row) => {
			return row.side == Side.Long && row.instrumentType == TypeOfInstrument.Future;
		});
	}

	get closedFuture(): HistoricalPnl | undefined {
		return this.historicalPnlRows.find((row) => {
			return row.side == Side.Closed && row.instrumentType == TypeOfInstrument.Future;
		});
	}

	get futureRealizedPnl() {
		return (this.shortFuture?.realizedPnl ?? 0) + (this.longFuture?.realizedPnl ?? 0) + (this.closedFuture?.realizedPnl ?? 0);
	}

	get futureUnrealizedPnl() {
		return (this.shortFuture?.unrealizedPnl ?? 0) + (this.longFuture?.unrealizedPnl ?? 0);
	}

	get fees() {
		return (
			(this.shortOption?.feeTotal ?? 0) +
			(this.longOption?.feeTotal ?? 0) +
			(this.shortFuture?.feeTotal ?? 0) +
			(this.longFuture?.feeTotal ?? 0)
		);
	}

	get commissions() {
		return (
			(this.shortOption?.commissionTotal ?? 0) +
			(this.longOption?.commissionTotal ?? 0) +
			(this.shortFuture?.commissionTotal ?? 0) +
			(this.longFuture?.commissionTotal ?? 0)
		);
	}
}

export default class ReportsPlByCalendarMonthShow extends Controller {
	declare model: ModelFrom<ReportsPlByCalendarMonthShowRoute>;
	@tracked columns: TableColumn[] = [
		{
			id: 'eead64b2-12d6-43b7-a7bf-a9cdf8361ad2',
			name: 'Month',
			valuePath: 'date',
			minWidth: 90,
			cellComponent: CellComponents.MonthFormat,
			textAlign: 'left',
			isSortable: false,
			isFixed: 'left',
			isVisible: true,
		},
		{
			id: '33481662-d5c2-4484-a880-0cdcec8d55ef',
			name: 'Short Options',
			cellComponent: CellComponents.IntlNumberFormat,
			textAlign: 'right',
			isSortable: false,
			isFixed: '',
			isVisible: true,
			subcolumns: [
				{
					id: '33481662-d5c2-4484-a880-0cdcec8d55ef',
					name: 'Market Value',
					valuePath: 'shortOption.marketValue',
					cellComponent: CellComponents.IntlNumberFormat,
					componentArgs: {
						style: 'currency',
						currency: 'USD',
						currencySign: 'accounting',
					},
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
				{
					id: '77d9554f-7122-4c79-90d1-d2aa3bf6e2a9',
					name: 'Unrealized P/L',
					valuePath: 'shortOption.unrealizedPnl',
					cellComponent: CellComponents.IntlNumberFormat,
					componentArgs: {
						style: 'currency',
						currency: 'USD',
						currencySign: 'accounting',
					},
					textAlign: 'right',
					isSortable: false,
					isVisible: true,
					isFixed: '',
				},
			],
		},
		{
			id: '7db19a30-dd3d-466c-ac0d-47962c69eb59',
			name: 'Long Options',
			valuePath: 'longOption.marketValue',
			cellComponent: CellComponents.IntlNumberFormat,
			textAlign: 'right',
			isSortable: false,
			isFixed: '',
			isVisible: true,
			subcolumns: [
				{
					id: 'ed63c05f-fe6e-437f-a2b3-f5cb6b8d2018',
					name: 'Market Value',
					valuePath: 'longOption.marketValue',
					cellComponent: CellComponents.IntlNumberFormat,
					componentArgs: {
						style: 'currency',
						currency: 'USD',
						currencySign: 'accounting',
					},
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},

				{
					id: '574B8A9A-27AE-41B0-82A2-4BC9A0E3CD36',
					name: 'Unrealized P/L',
					valuePath: 'longOption.unrealizedPnl',
					cellComponent: CellComponents.IntlNumberFormat,
					componentArgs: {
						style: 'currency',
						currency: 'USD',
						currencySign: 'accounting',
					},
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
			],
		},

		{
			id: '6d8b9efa-8fb9-42d3-b6dc-fb7cd1a9894f',
			name: 'Futures',
			cellComponent: CellComponents.IntlNumberFormat,
			textAlign: 'right',
			isSortable: false,
			isVisible: true,
			isFixed: '',
			subcolumns: [
				{
					id: 'A4CABC4D-3724-41FF-A075-06268E1B3C1F',
					name: 'Realized P/L',
					valuePath: 'futureRealizedPnl',
					cellComponent: CellComponents.IntlNumberFormat,
					componentArgs: {
						style: 'currency',
						currency: 'USD',
						currencySign: 'accounting',
					},
					textAlign: 'right',
					isSortable: false,
					isVisible: true,
					isFixed: '',
				},

				{
					id: 'B17E5146-BC42-4152-9D53-1EBB10D6C80A',
					name: 'Unrealized P/L',
					valuePath: 'futureUnrealizedPnl',
					cellComponent: CellComponents.IntlNumberFormat,
					componentArgs: {
						style: 'currency',
						currency: 'USD',
						currencySign: 'accounting',
					},
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
			],
		},
	];

	get calendarMonth(): string {
		return this.model.calendarMonth;
	}

	get months(): Map<string, Month> {
		const m: Map<string, Month> = new Map();
		// Start with the previous month, for display contract that expire the next month.
		const startDate = DateTime.fromISO(this.calendarMonth).minus({ month: 1 });
		let startDateIso;

		for (let i = 0; i < 25; i++) {
			startDateIso = startDate.plus({ months: i }).toISODate();
			m.set(startDateIso, new Month(startDateIso));
		}

		return m;
	}

	get rows() {
		const months = this.months;

		this.model.HistoricalPnl.forEach((row: HistoricalPnl) => {
			const displayExpirationDate = row?.displayExpirationDate || null;

			if (displayExpirationDate === null) return;

			const month = months.get(displayExpirationDate);
			month?.historicalPnlRows.push(row);
		});

		return Array.from(months.values());
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	interface Registry {
		'reports/pl-by-calendar-month/show': ReportsPlByCalendarMonthShow;
	}
}
