/* eslint-disable ember/no-controller-access-in-routes */
import Route from '@ember/routing/route';
import { queryManager } from 'ember-apollo-client';
import { inject as service } from '@ember/service';
import { DateTime } from 'luxon';

import query from 'vault-client/graphql/queries/positions/index.graphql';
import { CurrentPositionFilterDTO, CurrentPositionSortByDTO, TypeOfInstrument, TypeOfOption } from 'vault-client/types/graphql-types';

type SortObj = {
	valuePath: string;
	isAscending: boolean;
};

export default class PositionsIndexRoute extends Route {
	@service applicationScope: any;
	@queryManager apollo: any;

	templateName: string = 'positions/index';

	queryParams = {
		accountId: { refreshModel: true },
		instrumentId: { refreshModel: true },
		customerId: { refreshModel: true },
		productId: { refreshModel: true },
		salesCode: { refreshModel: true },
		side: { refreshModel: true },
		monthExpiresAtStartDate: { refreshModel: true },
		monthExpiresAtEndDate: { refreshModel: true },
		page: { refreshModel: true },
		sorts: { refreshModel: true },
		showClosedPositions: { refreshModel: true },
		selectedInstrumentOptionTypes: { refreshModel: true },
	};

	query = query;

	generateOrderBy(sorts: SortObj[]): CurrentPositionSortByDTO {
		const orderBy: {
			[key: string]: any;
		} = {};

		sorts.forEach((sort: any) => {
			const value = sort.isAscending ? 'Asc' : 'Desc';
			orderBy[sort.valuePath] = value;
		});

		return orderBy;
	}

	generateWhere(
		organizationId: null | string,
		customerId: undefined | null | string,
		accountId: string | null | undefined,
		instrumentId: string | null | undefined,
		productId: string | null | undefined,
		salesCode: string | null | undefined,
		monthExpiresAtStartDate: string | null,
		monthExpiresAtEndDate: string | null,
		showClosedPositions: boolean | null,
		side: string | null | undefined,
		selectedInstrumentOptionTypes?: Array<{
			name: string;
			instrumentType: string | null;
			optionType: string | null;
		}>
	): CurrentPositionFilterDTO {
		const where: CurrentPositionFilterDTO = {};

		if (customerId) {
			if (!where.Account) {
				where.Account = {};
			}
			where.Account.customerId = { equals: customerId };
		} else if (organizationId) {
			where.Account = {
				OR: [
					{
						Customer: {
							organizationId: { equals: organizationId },
						},
					},
					{
						ownerId: { equals: organizationId },
					},
				],
			};
		}

		if (accountId) {
			where.accountId = { equals: accountId };
		}

		if (instrumentId) {
			where.instrumentId = { equals: instrumentId };
		}

		if (productId) {
			where.productId = { equals: productId };
		}

		if (salesCode) {
			where.Account = {
				...(where.Account || {}),
				salesCode: { equals: salesCode },
			};
		}

		if (selectedInstrumentOptionTypes?.length === 1) {
			if (selectedInstrumentOptionTypes[0].instrumentType) {
				where.instrumentType = { equals: selectedInstrumentOptionTypes[0].instrumentType as TypeOfInstrument };
			}

			if (selectedInstrumentOptionTypes[0].optionType) {
				where.optionType = { equals: selectedInstrumentOptionTypes[0].optionType as TypeOfOption };
			}
		} else if (selectedInstrumentOptionTypes !== undefined && selectedInstrumentOptionTypes.length > 1) {
			// if user selected multiple instrument types
			const groupedSelections: { [key: string]: string[] } = {};
			selectedInstrumentOptionTypes.forEach((selection) => {
				if (selection.instrumentType !== null) {
					if (!groupedSelections[selection.instrumentType]) {
						groupedSelections[selection.instrumentType] = [];
					}

					if (selection.optionType !== null) {
						groupedSelections[selection.instrumentType].push(selection.optionType);
					}
				}
			});

			// Generate where clause based on grouped selections
			const whereConditions = Object.keys(groupedSelections).map((instrumentType) => {
				const optionTypes = groupedSelections[instrumentType];
				return optionTypes.length > 1
					? {
							instrumentType: { equals: instrumentType as TypeOfInstrument },
							optionType: { in: optionTypes.map((ot) => ot as TypeOfOption) },
					  }
					: {
							instrumentType: { equals: instrumentType as TypeOfInstrument },
							optionType: { equals: optionTypes[0] as TypeOfOption },
					  };
			});
			// Add to existing where conditions
			if (whereConditions.length > 0) {
				where.OR = whereConditions;
			}
		}

		if (monthExpiresAtStartDate && monthExpiresAtEndDate) {
			if (!where.AND) {
				where.AND = [];
			}
			where.AND.push({
				expiresAt: { gte: monthExpiresAtStartDate },
			});
			where.AND.push({
				expiresAt: { lte: monthExpiresAtEndDate },
			});
		} else if (monthExpiresAtStartDate) {
			where.expiresAt = { gte: monthExpiresAtStartDate };
		} else if (monthExpiresAtEndDate) {
			where.expiresAt = { lte: monthExpiresAtEndDate };
		}

		if (!showClosedPositions) {
			where.quantity = {
				not: {
					equals: 0,
				},
			};
			where.expiresAt = { gte: DateTime.now().toISODate() };
		}

		if (side) {
			where.quantity = side === 'Long' ? { gt: 0 } : { lt: 0 };
		}

		return where;
	}
}
