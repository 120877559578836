import Route from '@ember/routing/route';
import {
	Future,
	LocationEntityFilterDTO,
	LedgerForecastedEntryFilterDTO,
	AggregateLedgerForecastedEntryDTO,
	TypeOfLedgerCategory,
	LocationEntity,
	ForecastedMilkProductionByMonthFilterDTO,
	Query,
} from 'vault-client/types/graphql-types';
import { DateTime } from 'luxon';
import { useQuery, gql } from 'glimmer-apollo';
import { tracked } from '@glimmer/tracking';

interface modelParams {
	id: string;
	productionMonthStartDate: string;
	productionMonthEndDate: string;
	selectedLocationId: string;
}

const GET_MILK_CHECK_BUSINESS_DATA = gql`
	query ForecastedMilkCheckBusinessData(
		$locationsWhere: LocationEntityFilterDTO
		$startDate: String!
		$endDate: String!
		$scopeId: String!
	) {
		Locations(where: $locationsWhere) {
			id
			name
			currentBasis
			rollingBasisNumberOfMonths
			customBasis
			County {
				name
				classIDifferential
			}
		}
		ForecastedMilkProductionByMonths(where: { date: { gte: $startDate, lte: $endDate } }, orderBy: { date: Asc }, scopeId: $scopeId) {
			id
			numberOfCows
			grossProduction
			grossProteinProduction
			grossOtherSolidsProduction
			grossButterfatProduction
			date
			classiUtilization
			classiiUtilization
			classiiiUtilization
			classivUtilization
			forecastedBlendedMilkPrice
			grossClassiProduction
			grossClassiiProduction
			grossClassiiiProduction
			grossClassivProduction
			proteinPercent
			otherSolidsPercent
			butterfatPercent
			UsdaActualMilkPrice {
				id
				classiPrice
				classiiPrice
				classiiiPrice
				classivPrice
			}
			Entity {
				id
				type
				... on LocationEntity {
					currentBasis
					County {
						classIDifferential
					}
				}
			}
		}
		ActualBlendedMilkPrices(scopeId: $scopeId, where: { date: { gte: $startDate, lte: $endDate } }) {
			id
			date
			price
			basis
			Entity {
				id
				type
			}
		}
		ClassIIIFutures: Futures(
			where: {
				displayExpiresAt: { gte: $startDate, lte: $endDate }
				Product: { slug: { equals: "us-dairy-class-iii" } }
				isStandardContractSize: { equals: true }
			}
			orderBy: { displayExpiresAt: Asc }
		) {
			id
			displayExpiresAt
			barchartSymbol
			Product {
				id
			}
			SymbolGroup {
				displayFactor
			}
		}
		ClassIVFutures: Futures(
			where: {
				displayExpiresAt: { gte: $startDate, lte: $endDate }
				Product: { slug: { equals: "us-dairy-class-iv" } }
				isStandardContractSize: { equals: true }
			}
			orderBy: { displayExpiresAt: Asc }
		) {
			id
			displayExpiresAt
			barchartSymbol
			Product {
				id
			}
			SymbolGroup {
				displayFactor
			}
		}
		ButterFutures: Futures(
			where: {
				displayExpiresAt: { gte: $startDate, lte: $endDate }
				Product: { slug: { equals: "us-dairy-butter" } }
				isStandardContractSize: { equals: true }
			}
			orderBy: { displayExpiresAt: Asc }
		) {
			id
			displayExpiresAt
			barchartSymbol
			Product {
				id
			}
			SymbolGroup {
				displayFactor
			}
		}
		NonfatDryMilkFutures: Futures(
			where: {
				displayExpiresAt: { gte: $startDate, lte: $endDate }
				Product: { slug: { equals: "us-dairy-nonfat-milk" } }
				isStandardContractSize: { equals: true }
			}
			orderBy: { displayExpiresAt: Asc }
		) {
			id
			displayExpiresAt
			barchartSymbol
			Product {
				id
			}
			SymbolGroup {
				displayFactor
			}
		}
		CheeseFutures: Futures(
			where: {
				displayExpiresAt: { gte: $startDate, lte: $endDate }
				Product: { slug: { equals: "us-dairy-cheese" } }
				isStandardContractSize: { equals: true }
			}
			orderBy: { displayExpiresAt: Asc }
		) {
			id
			displayExpiresAt
			barchartSymbol
			Product {
				id
			}
			SymbolGroup {
				displayFactor
			}
		}
		DryWheyFutures: Futures(
			where: {
				displayExpiresAt: { gte: $startDate, lte: $endDate }
				Product: { slug: { equals: "us-dairy-dry-whey" } }
				isStandardContractSize: { equals: true }
			}
			orderBy: { displayExpiresAt: Asc }
		) {
			id
			displayExpiresAt
			barchartSymbol
			Product {
				id
			}
			SymbolGroup {
				displayFactor
			}
		}
	}
`;

const GET_MILK_LEDGER_ENTRIES = gql`
	query LedgerEntries($ledgerEntriesWhere: LedgerForecastedEntryFilterDTO, $scopeId: String!) {
		AggregateLedgerForecastedEntries(
			where: $ledgerEntriesWhere
			calc: { sum: { calculatedAmount: true } }
			groupBy: { month: true, year: true }
			scopeId: $scopeId
		) {
			sum {
				calculatedAmount
			}
			month
			year
		}
	}
`;

export type GetMilkCheckDataQuery = {
	__typename?: 'Query';
	Locations: LocationEntity[];
	ForecastedMilkProductionByMonths: Query['ForecastedMilkProductionByMonths'];
	ActualBlendedMilkPrices: Query['ActualBlendedMilkPrices'];
	ClassIIIFutures: Future[];
	ClassIVFutures: Future[];
	ButterFutures: Future[];
	NonfatDryMilkFutures: Future[];
	CheeseFutures: Future[];
	DryWheyFutures: Future[];
};

export type GetLedgerEntriesQuery = {
	__typename?: 'Query';
	AggregateLedgerForecastedEntries?: AggregateLedgerForecastedEntryDTO[];
};

type GetMilkCheckDataQueryVariables = {
	productionWhere?: ForecastedMilkProductionByMonthFilterDTO;
	locationsWhere?: LocationEntityFilterDTO;
	startDate: string;
	endDate: string;
	scopeId?: string;
};

type GetLedgerEntriesQueryVariables = {
	ledgerEntriesWhere?: LedgerForecastedEntryFilterDTO;
	scopeId?: string | null;
};

export default class BusinessesBusinessMilkCheckRoute extends Route {
	@tracked utilizationVariables: GetMilkCheckDataQueryVariables = { startDate: '', endDate: '' };
	@tracked ledgerEntriesVariables: GetLedgerEntriesQueryVariables = {};

	queryParams = {
		productionMonthStartDate: {
			refreshModel: true,
		},
		productionMonthEndDate: {
			refreshModel: true,
		},
		selectedLocationId: {
			refreshModel: true,
		},
	};

	getMilkCheckData = useQuery<GetMilkCheckDataQuery, GetMilkCheckDataQueryVariables>(this, () => [
		GET_MILK_CHECK_BUSINESS_DATA,
		{ variables: this.utilizationVariables },
	]);

	getLedgerEntries = useQuery<GetLedgerEntriesQuery, GetLedgerEntriesQueryVariables>(this, () => [
		GET_MILK_LEDGER_ENTRIES,
		{ variables: this.ledgerEntriesVariables },
	]);

	generateLocationsWhere(businessId: string | null): LocationEntityFilterDTO {
		const where: LocationEntityFilterDTO = {};

		if (businessId) {
			where.customerId = {
				equals: businessId,
			};
		}

		return where;
	}

	generateLedgerEntriesWhere(startDate: string, endDate: string): LedgerForecastedEntryFilterDTO {
		let where: LedgerForecastedEntryFilterDTO = {};
		where = {
			LedgerCategory: {
				type: {
					equals: TypeOfLedgerCategory.MilkCheck,
				},
			},
			date: {
				gte: startDate,
				lte: endDate,
			},
		};

		return where;
	}

	async model(params: modelParams) {
		const businessParams = this.paramsFor('businesses.business') as { business_id: string };

		this.utilizationVariables = {
			locationsWhere: this.generateLocationsWhere(businessParams.business_id),
			startDate: DateTime.fromISO(params.productionMonthStartDate).minus({ month: 1 }).startOf('month').toISODate(),
			endDate: params.productionMonthEndDate,
			scopeId: params.selectedLocationId || businessParams.business_id,
		};

		this.ledgerEntriesVariables = {
			ledgerEntriesWhere: this.generateLedgerEntriesWhere(params.productionMonthStartDate, params.productionMonthEndDate),
			scopeId: businessParams.business_id,
		};

		await this.getMilkCheckData.promise;
		await this.getLedgerEntries.promise;

		return {
			getMilkCheckData: this.getMilkCheckData,
			getLedgerEntries: this.getLedgerEntries,
			businessId: businessParams.business_id,
		};
	}
}
