import { action } from '@ember/object';
import { service } from '@ember/service';
import { capitalize } from '@ember/string';
import Component from '@glimmer/component';
import { DateTime } from 'luxon';
import PermissionsService from 'vault-client/services/permissions';
import { GrainFeedPlan, GrainFillOrderModificationRequest } from 'vault-client/types/graphql-types';
interface FeedModificationRequestWrapperArgs {
	modificationRequests: GrainFillOrderModificationRequest[];
}
export default class FeedModificationRequestWrapper extends Component<FeedModificationRequestWrapperArgs> {
	@service declare permissions: PermissionsService;

	get omrData() {
		return (
			this.args.modificationRequests?.map((mod) => ({
				...mod,
				canceledVolume: (mod.GrainFillOrder.bushels || 0) - (mod.data.bushels || 0),
			})) ?? []
		);
	}

	@action
	generateModificationRequestName(mod: GrainFillOrderModificationRequest) {
		const plan = mod.GrainFillOrder.Plan as GrainFeedPlan;
		return `Feed Fill for ${DateTime.fromISO(plan.startDate).year} ${capitalize(plan.FeedCategory.name)}`;
	}
}
