import InsuranceOverview from 'vault-client/controllers/insurance-overview';

export default class OrganizationsOrganizationInsuranceOverviewController extends InsuranceOverview {
	insuranceRoutePath = 'organizations.organization.insurance';
	insuranceQuarterRoutePath = 'organizations.organization.insurance-overview-by-business';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'organizations/organization/insurance-overview': OrganizationsOrganizationInsuranceOverviewController;
	}
}
