import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Vault::UiButton @style='destructive' @size={{@size}} {{on 'click' this.openConfirmation}}>{{yield}}</Vault::UiButton>\n{{#if this.showConfirmation}}\n\t<Vault::UiConfirmation as |confirmation|>\n\t\t<confirmation.title>Delete Confirmation</confirmation.title>\n\t\t<confirmation.body>Are you sure you want to delete this location?\n\t\t\t<br />This action is irreversible.\n\t\t</confirmation.body>\n\t\t<confirmation.cancel @style='plain' {{on 'click' this.closeConfirmation}}>Cancel</confirmation.cancel>\n\t\t<confirmation.action @style='destructive' {{on 'click' this.deleteLocation}}>Delete Location</confirmation.action>\n\t</Vault::UiConfirmation>\n{{/if}}", {"contents":"<Vault::UiButton @style='destructive' @size={{@size}} {{on 'click' this.openConfirmation}}>{{yield}}</Vault::UiButton>\n{{#if this.showConfirmation}}\n\t<Vault::UiConfirmation as |confirmation|>\n\t\t<confirmation.title>Delete Confirmation</confirmation.title>\n\t\t<confirmation.body>Are you sure you want to delete this location?\n\t\t\t<br />This action is irreversible.\n\t\t</confirmation.body>\n\t\t<confirmation.cancel @style='plain' {{on 'click' this.closeConfirmation}}>Cancel</confirmation.cancel>\n\t\t<confirmation.action @style='destructive' {{on 'click' this.deleteLocation}}>Delete Location</confirmation.action>\n\t</Vault::UiConfirmation>\n{{/if}}","moduleName":"vault-client/components/delete-location-button.hbs","parseOptions":{"srcName":"vault-client/components/delete-location-button.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { gql, useMutation } from 'glimmer-apollo';
import { DeletedResource, Mutation_deleteLocationArgs } from 'vault-client/types/graphql-types';
import RouterService from '@ember/routing/router-service';

interface DeleteLocationButtonArgs {
	locationId: string;
	customerId: string;
}

const DELETE_LOCATION_MUTATION = gql`
	mutation DeleteOperation($id: String!) {
		deleteLocation(id: $id) {
			id
		}
	}
`;

type DeleteLocation = {
	__typename?: 'Mutation';

	deletedResource: DeletedResource;
};

export default class DeleteLocationButton extends Component<DeleteLocationButtonArgs> {
	@service declare router: RouterService;
	@tracked showConfirmation = false;

	deleteLocationMutation = useMutation<DeleteLocation, Mutation_deleteLocationArgs>(this, () => [
		DELETE_LOCATION_MUTATION,
		{
			variables: { id: this.args.locationId },
			update: (cache) => {
				cache.evict({ fieldName: 'Locations' });
				cache.evict({ fieldName: 'Location' });
				cache.evict({ fieldName: 'ForecastedMilkProductionByMonth' });
				cache.evict({ fieldName: 'AggregateForecastedMilkProductionByMonth' });
				cache.evict({ fieldName: 'AggregateActualMilkProduction' });
				cache.evict({ fieldName: 'AggregateForecastedMilkProductionByMonth' });
				cache.evict({ fieldName: 'AggregateForecastedMilkProductionByMonths' });
				cache.gc();
			},
		},
	]);

	@action
	openConfirmation() {
		this.showConfirmation = true;
	}

	@action
	closeConfirmation() {
		this.showConfirmation = false;
	}

	@action
	async deleteLocation() {
		await this.deleteLocationMutation.mutate();
		this.router.transitionTo('businesses.business.locations', this.args.customerId);
	}
}
