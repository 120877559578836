export default {
  "possibleTypes": {
    "IAccount": [
      "Account",
      "BrokerageAccount",
      "ForwardContractAccount",
      "SwapAccount"
    ],
    "IEntity": [
      "CustomerEntity",
      "Entity",
      "EntityGroup",
      "LocationEntity",
      "OrganizationEntity"
    ],
    "IFeedIngredientUsage": [
      "FeedIngredientActualUsage",
      "FeedIngredientForecastedUsage",
      "FeedIngredientUsage"
    ],
    "IForecastedLivestockFeedUsage": [
      "ForecastedLivestockFeedUsage",
      "ForecastedSwineLivestockFeedUsage"
    ],
    "IGrainOrder": [
      "GrainFillOrder",
      "GrainOrder",
      "GrainTargetOrder"
    ],
    "IGrainOrderActivity": [
      "GrainFillOrderActivity",
      "GrainFillOrderModificationRequestActivity",
      "GrainOrderActivity",
      "GrainTargetOrderActivity"
    ],
    "IGrainPlan": [
      "GrainCropPlan",
      "GrainFeedPlan",
      "GrainPlan"
    ],
    "IInstrument": [
      "BasisInstrument",
      "CashInstrument",
      "CommitmentInstrument",
      "Future",
      "Instrument",
      "Option",
      "PhysicalInstrument",
      "SpreadInstrument",
      "Swap",
      "Swaption"
    ],
    "IInsuranceEndorsement": [
      "DrpEndorsement",
      "DrpInsuranceEndorsement",
      "InsuranceEndorsement",
      "LgmInsuranceEndorsement",
      "LrpEndorsement",
      "LrpInsuranceEndorsement"
    ],
    "IInsuranceHistoricalPremium": [
      "InsuranceHistoricalDrpPremium",
      "InsuranceHistoricalPremium"
    ],
    "IInsuranceHistoricalPremiumInput": [
      "InsuranceHistoricalDrpPremiumInput",
      "InsuranceHistoricalPremiumInput"
    ],
    "ILedgerCategory": [
      "LedgerCategory",
      "LedgerExpenseCategory",
      "LedgerFeedCategory",
      "LedgerMilkCheckCategory",
      "LedgerRevenueCategory"
    ],
    "ILedgerEntry": [
      "LedgerActualEntry",
      "LedgerEntry",
      "LedgerForecastedEntry"
    ],
    "ILivestockGroup": [
      "LivestockGroup",
      "SwineLivestockGroup"
    ],
    "ILivestockGroupFeedUsage": [
      "LivestockGroupFeedUsage",
      "SwineLivestockGroupFeedUsage"
    ],
    "ILivestockPopulationChange": [
      "LivestockPopulationChange",
      "SwineLivestockPopulationChange"
    ],
    "INotificationSetting": [
      "ApplePushNotificationSetting",
      "EmailNotificationSetting",
      "FirebaseTokenNotificationSetting",
      "NotificationSetting",
      "SmsNotificationSetting"
    ],
    "INotificationSubscription": [
      "DrpNewDailyPricesSubscription",
      "DrpSubscription",
      "LrpNewDailyPricesSubscription",
      "LrpSubscription",
      "NotificationSubscription"
    ],
    "IUsdaReport": [
      "UsdaMarsReport",
      "UsdaMprReport",
      "UsdaReport"
    ],
    "IVersion": [
      "BusinessDataVersion",
      "FeedIngredientVersion",
      "LivestockGroupFeedUsageVersion",
      "LivestockGroupVersion"
    ]
  }
};