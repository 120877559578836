import Controller from '@ember/controller';
import Route from '@ember/routing/route';
import { tracked } from '@glimmer/tracking';
import { gql, useQuery } from 'glimmer-apollo';
import { Count, CustomerEntity, OrganizationEntity, Query_CustomersArgs } from 'vault-client/types/graphql-types';

type SortObj = {
	valuePath: string;
	isAscending: boolean;
};

interface QueryParams {
	sorts: SortObj[];
	size: number;
	page: number;
}

const GET_CUSTOMERS_QUERY = gql`
	query Customers($limit: Float = 50, $offset: Float, $where: CustomerEntityFilterDTO, $orderBy: CustomerEntitySortByDTO) {
		Customers(limit: $limit, offset: $offset, orderBy: $orderBy, where: $where) {
			id
			name
			businessRoles
			hasWriteAccess
			ParentOrganization {
				id
				name
			}
		}
		CustomerCount(where: $where) {
			count
		}
	}
`;

type GetCustomers = {
	__typename?: 'Query';

	Customers: CustomerEntity[];
	CustomerCount: Count;
};

export default class CustomersIndexRoute extends Route {
	@tracked variables: Query_CustomersArgs = {};
	getCustomers = useQuery<GetCustomers, Query_CustomersArgs>(this, () => [GET_CUSTOMERS_QUERY, { variables: this.variables }]);

	queryParams = {
		page: { refreshModel: true },
		sorts: { refreshModel: true },
	};

	async model(params: QueryParams) {
		const { Organization: organization } = this.modelFor('organizations.organization') as {
			Organization: OrganizationEntity | undefined | null;
		};
		const orderBy: {
			[key: string]: any;
		} = {};
		if (params.sorts && Array.isArray(params.sorts)) {
			params.sorts.forEach((sort: any) => {
				const value = sort.isAscending ? 'Asc' : 'Desc';
				orderBy[sort.valuePath] = value;
			});
		}

		const organizationId = organization?.id;

		const where = organizationId ? { organizationId: { equals: organizationId } } : {};

		this.variables = {
			where,
			offset: params.page * params.size,
			limit: params.size,
			orderBy,
		};

		await this.getCustomers.promise;

		return { Customers: this.getCustomers, Organization: organization };
	}

	resetController(controller: Controller, isExiting: boolean): void {
		if (isExiting) {
			controller.set('page' as keyof Controller, 0);
		}
	}
}
