import LRPInsuranceEndorsementsShowController from 'vault-client/controllers/lrp-insurance-endorsements/show';

export default class BusinessesBusinessLrpInsuranceEndorsement extends LRPInsuranceEndorsementsShowController {
	lrpInsuranceEndorsementsRoute: string = 'businesses.business.lrp-insurance-endorsements';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'businesses/business/lrp-insurance-endorsement': BusinessesBusinessLrpInsuranceEndorsement;
	}
}
