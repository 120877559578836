import { setOwner } from '@ember/application';
import { inject as service } from '@ember/service';
import { ForecastedMilkUtilization, Future, AggregateForecastedMilkProductionByMonthDTO } from 'vault-client/types/graphql-types';

export default class MilkCheck {
	@service marketData: any;
	date: string;
	id: string;
	classiDifferential: number;
	milkUtilizations: ForecastedMilkUtilization;
	registeredSymbols: string[] = [];
	classiiiFuture: Future | null;
	classivFuture: Future | null;
	butterFuture: Future | null;
	advancedNonfatDryMilkFuture: Future | null;
	advancedButterFuture: Future | null;
	advancedCheeseFuture: Future | null;
	advancedDryWheyFuture: Future | null;
	monthlyCalculatedAmount?: number | null | undefined;
	forecastedBlendedMilkPrice?: number | null | undefined;
	grossProduction?: number | null;
	grossProductionActual: number | null = null;
	classIActualPrice: number | null = null;
	classIIActualPrice: number | null = null;
	classIIIActualPrice: number | null = null;
	classIVActualPrice: number | null = null;
	blendedPriceActual: number | null = null;
	monthlyActualAmounts: number | null = null;
	// Location based basis (current or custom)
	everAgBasis: number | null = null;
	numberOfCowsForecasted: number | null = null;
	numberOfCowsActual: number | null = null;
	otherSolidsPercent: number | null = null;
	butterfatPercent: number | null = null;
	proteinPercent: number | null = null;
	constructor(
		owner: any,
		date: string,
		everAgBasis: number | null,
		milkUtilizations: ForecastedMilkUtilization,
		classiiiFuture: Future | null,
		classivFuture: Future | null,
		butterFuture: Future | null,
		advancedNonfatDryMilkFuture: Future | null,
		advancedButterFuture: Future | null,
		advancedCheeseFuture: Future | null,
		advancedDryWheyFuture: Future | null,
		classIDifferential: number = 0,
		monthlyCalculatedAmount: number | null | undefined,
		production: AggregateForecastedMilkProductionByMonthDTO | undefined | null,
		otherSolidsPercent: number | null,
		butterfatPercent: number | null,
		proteinPercent: number | null,
		forecastedBlendedMilkPrice?: number | null
	) {
		setOwner(this, owner);
		this.id = date;
		this.date = date;
		this.everAgBasis = everAgBasis;
		this.milkUtilizations = milkUtilizations;
		this.classiiiFuture = classiiiFuture;
		this.classivFuture = classivFuture;
		this.butterFuture = butterFuture;
		this.advancedNonfatDryMilkFuture = advancedNonfatDryMilkFuture;
		this.advancedButterFuture = advancedButterFuture;
		this.advancedCheeseFuture = advancedCheeseFuture;
		this.advancedDryWheyFuture = advancedDryWheyFuture;
		this.classiDifferential = classIDifferential;
		this.monthlyCalculatedAmount = monthlyCalculatedAmount ?? null;
		this.grossProduction = production?.sum?.grossProduction;
		this.otherSolidsPercent = otherSolidsPercent;
		this.butterfatPercent = butterfatPercent;
		this.proteinPercent = proteinPercent;
		this.forecastedBlendedMilkPrice = forecastedBlendedMilkPrice;

		if (classiiiFuture?.barchartSymbol && !this.registeredSymbols.includes(classiiiFuture.barchartSymbol)) {
			this.marketData.register(classiiiFuture.barchartSymbol);
			this.registeredSymbols.push(classiiiFuture.barchartSymbol);
		}

		if (classivFuture?.barchartSymbol && !this.registeredSymbols.includes(classivFuture.barchartSymbol)) {
			this.marketData.register(classivFuture.barchartSymbol);
			this.registeredSymbols.push(classivFuture.barchartSymbol);
		}

		if (butterFuture?.barchartSymbol && !this.registeredSymbols.includes(butterFuture.barchartSymbol)) {
			this.marketData.register(butterFuture.barchartSymbol);
			this.registeredSymbols.push(butterFuture.barchartSymbol);
		}

		if (advancedNonfatDryMilkFuture?.barchartSymbol && !this.registeredSymbols.includes(advancedNonfatDryMilkFuture.barchartSymbol)) {
			this.marketData.register(advancedNonfatDryMilkFuture.barchartSymbol);
			this.registeredSymbols.push(advancedNonfatDryMilkFuture.barchartSymbol);
		}

		if (advancedButterFuture?.barchartSymbol && !this.registeredSymbols.includes(advancedButterFuture.barchartSymbol)) {
			this.marketData.register(advancedButterFuture.barchartSymbol);
			this.registeredSymbols.push(advancedButterFuture.barchartSymbol);
		}

		if (advancedCheeseFuture?.barchartSymbol && !this.registeredSymbols.includes(advancedCheeseFuture.barchartSymbol)) {
			this.marketData.register(advancedCheeseFuture.barchartSymbol);
			this.registeredSymbols.push(advancedCheeseFuture.barchartSymbol);
		}

		if (advancedDryWheyFuture?.barchartSymbol && !this.registeredSymbols.includes(advancedDryWheyFuture.barchartSymbol)) {
			this.marketData.register(advancedDryWheyFuture.barchartSymbol);
			this.registeredSymbols.push(advancedDryWheyFuture.barchartSymbol);
		}
	}

	/* The NDPSR provides current wholesale market prices for basic dairy commodities. These commodity prices are used in the following formulas to calculate the USDA’s Class and Component Prices used in the Federal Milk Marketing Order Program. */

	// SOURCE: https://www.ams.usda.gov/sites/default/files/media/PriceFormula2019.pdf
	/*
  • Commodity Prices (Butter, Nonfat Dry Milk, Cheddar Cheese, and Dry Whey)
◦ Commodity prices are calculated on the NDPSR ¹.
◦ The prices are weighted averages for the previous four or five weeks (depending on date of last
publication), weighted by sales volume in pounds.
◦ Cheddar cheese in 500-pound barrels is adjusted to 38 percent moisture content.
◦ For more information, see the Price Formulas publication ².
• Butterfat Price = (Butter Price – 0.1715) x 1.211
• Nonfat Solids Price = (NFDM Price – 0.1678) x 0.99
• Protein Price = ((Cheese Price – 0.2003) x 1.383) + ((((Cheese Price – 0.2003) x 1.572) – Butterfat Price x 0.9)
x 1.17)
• Other Solids Price = (Dry Whey Price-0.1991) x 1.03
• Somatic Cell Adjustment Rate = Cheese Price x 0.0005
• Class II Price = (Class II Skim Milk Price x 0.965)+(Class II Butterfat Price x 3.5)
◦ Class II Skim Milk Price = See the Announcement of Advanced Prices and Pricing Factors for the
applicable month.
◦ Class II Butterfat Price = Butterfat Price+0.007
• Class III Price = (Class III Skim Milk Price x 0.965)+(Butterfat Price x 3.5)
◦ Class III Skim Milk Price = (Protein Price x 3.1)+(Other Solids Price x 5.9)
• Class IV Price = (Class IV Skim Milk Price x 0.965)+(Butterfat Price x 3.5)
◦ Class IV Skim Milk Price = Nonfat Solids Price x 9  */

	get classiiiPrice() {
		const barchartSymbol = this.classiiiFuture?.barchartSymbol;
		if (!barchartSymbol) return null;

		const marketDatum = this.marketData.getMarketDatum(barchartSymbol);
		const lastPrice = marketDatum?.lastPrice as number | undefined;

		// Far out futures return 0 instead of null or undefined so falsy check is used.
		if (!lastPrice) return null;

		return this.classiiiFuture?.SymbolGroup?.displayFactor ? lastPrice * this.classiiiFuture?.SymbolGroup.displayFactor : lastPrice;
	}

	get classivPrice() {
		const barchartSymbol = this.classivFuture?.barchartSymbol;
		if (!barchartSymbol) return null;

		const marketDatum = this.marketData.getMarketDatum(barchartSymbol);
		const lastPrice = marketDatum?.lastPrice as number | undefined;

		if (!lastPrice) return null;

		return this.classivFuture?.SymbolGroup?.displayFactor ? lastPrice * this.classivFuture?.SymbolGroup.displayFactor : lastPrice;
	}

	get butterPrice() {
		const barchartSymbol = this.butterFuture?.barchartSymbol;
		if (!barchartSymbol) return null;

		const marketDatum = this.marketData.getMarketDatum(barchartSymbol);
		const lastPrice = marketDatum?.lastPrice as number | undefined;

		if (!lastPrice) return null;

		return this.butterFuture?.SymbolGroup?.displayFactor ? lastPrice * this.butterFuture?.SymbolGroup.displayFactor : lastPrice;
	}

	get advancedNonfatDryMilkPrice() {
		const barchartSymbol = this.advancedNonfatDryMilkFuture?.barchartSymbol;
		if (!barchartSymbol) return null;

		const marketDatum = this.marketData.getMarketDatum(barchartSymbol);
		const lastPrice = marketDatum?.lastPrice as number | undefined;

		if (!lastPrice) return null;

		return this.advancedNonfatDryMilkFuture?.SymbolGroup?.displayFactor
			? lastPrice * this.advancedNonfatDryMilkFuture?.SymbolGroup.displayFactor
			: lastPrice;
	}

	get advancedClassivSkimMilkPricingFactor() {
		if (this.advancedNonfatDryMilkPrice == null) return null;
		const advancedNonfatSolidsPrice = (this.advancedNonfatDryMilkPrice - 0.1678) * 0.99;
		return advancedNonfatSolidsPrice * 9;
	}

	get classiiPrice() {
		if (this.butterPrice == null || this.advancedClassivSkimMilkPricingFactor == null) {
			return null;
		}

		const classiiSkimMilkPrice = this.advancedClassivSkimMilkPricingFactor + 0.7;

		const butterfatPrice = (this.butterPrice - 0.1715) * 1.211;
		const classiiButterfatPrice = butterfatPrice + 0.007;

		return classiiSkimMilkPrice * 0.965 + classiiButterfatPrice * 3.5;
	}

	get advancedDryWheyPrice() {
		const barchartSymbol = this.advancedDryWheyFuture?.barchartSymbol;
		if (!barchartSymbol) return null;

		const marketDatum = this.marketData.getMarketDatum(barchartSymbol);
		const lastPrice = marketDatum?.lastPrice as number | undefined;

		if (!lastPrice) return null;

		return this.advancedDryWheyFuture?.SymbolGroup?.displayFactor
			? lastPrice * this.advancedDryWheyFuture?.SymbolGroup.displayFactor
			: lastPrice;
	}

	get advancedButterPrice() {
		const barchartSymbol = this.advancedButterFuture?.barchartSymbol;
		if (!barchartSymbol) return null;

		const marketDatum = this.marketData.getMarketDatum(barchartSymbol);
		const lastPrice = marketDatum?.lastPrice as number | undefined;

		if (!lastPrice) return null;

		return this.advancedButterFuture?.SymbolGroup?.displayFactor
			? lastPrice * this.advancedButterFuture?.SymbolGroup.displayFactor
			: lastPrice;
	}

	get advancedCheesePrice() {
		const barchartSymbol = this.advancedCheeseFuture?.barchartSymbol;
		if (!barchartSymbol) return null;

		const marketDatum = this.marketData.getMarketDatum(barchartSymbol);
		const lastPrice = marketDatum?.lastPrice as number | undefined;

		if (!lastPrice) return null;

		return this.advancedCheeseFuture?.SymbolGroup?.displayFactor
			? lastPrice * this.advancedCheeseFuture?.SymbolGroup.displayFactor
			: lastPrice;
	}

	get classiPrice() {
		if (this.baseClassiPrice == null) return null;
		return this.classiDifferential + this.baseClassiPrice;
	}

	get advancedOtherSolidsPrice() {
		if (this.advancedDryWheyPrice == null) return null;
		return (this.advancedDryWheyPrice - 0.1991) * 1.03;
	}

	get advancedButterfatPrice() {
		if (this.advancedButterPrice == null) return null;
		return (this.advancedButterPrice - 0.1715) * 1.211;
	}

	get advancedProteinPrice() {
		if (this.advancedCheesePrice == null || this.advancedButterfatPrice == null) return null;
		return (
			(this.advancedCheesePrice - 0.2003) * 1.383 + ((this.advancedCheesePrice - 0.2003) * 1.572 - this.advancedButterfatPrice * 0.9) * 1.17
		);
	}

	get baseClassiPrice() {
		if (
			this.advancedButterPrice == null ||
			this.advancedCheesePrice == null ||
			this.advancedDryWheyPrice == null ||
			this.advancedClassivSkimMilkPricingFactor == null ||
			this.advancedOtherSolidsPrice == null ||
			this.advancedButterfatPrice == null ||
			this.advancedProteinPrice == null
		)
			return null;

		const advancedClassiiiSkimMilkPricingFactor = this.advancedProteinPrice * 3.1 + this.advancedOtherSolidsPrice * 5.9;

		const baseSkimMilkPriceForClassi = (advancedClassiiiSkimMilkPricingFactor + this.advancedClassivSkimMilkPricingFactor) / 2 + 0.74;

		return baseSkimMilkPriceForClassi * 0.965 + this.advancedButterfatPrice * 3.5;
	}

	get blendedPrice() {
		if (this.forecastedBlendedMilkPrice && this.forecastedBlendedMilkPrice !== 0) {
			return this.forecastedBlendedMilkPrice;
		}

		if (
			this.milkUtilizations.classiUtilization != undefined &&
			this.milkUtilizations.classiiUtilization != undefined &&
			this.milkUtilizations.classiiiUtilization != undefined &&
			this.milkUtilizations.classivUtilization != undefined &&
			this.classiPrice != null &&
			this.classiiPrice != null &&
			this.classiiiPrice != null &&
			this.classivPrice != null &&
			this.componentDifferential != null
		) {
			let adjustedPrice =
				this.classiPrice * this.milkUtilizations.classiUtilization +
				this.classiiPrice * this.milkUtilizations.classiiUtilization +
				this.classiiiPrice * this.milkUtilizations.classiiiUtilization +
				this.classivPrice * this.milkUtilizations.classivUtilization;
			adjustedPrice += this.componentDifferential;
			return adjustedPrice;
		} else {
			return null;
		}
	}

	get blendedPriceForecastedActualBased() {
		if (
			this.milkUtilizations.classiUtilization != undefined &&
			this.milkUtilizations.classiiUtilization != undefined &&
			this.milkUtilizations.classiiiUtilization != undefined &&
			this.milkUtilizations.classivUtilization != undefined &&
			this.classIActualPrice != null &&
			this.classIIActualPrice != null &&
			this.classIIIActualPrice != null &&
			this.classIVActualPrice != null &&
			this.componentDifferential != null
		) {
			return (
				this.classIActualPrice * this.milkUtilizations.classiUtilization +
				this.classIIActualPrice * this.milkUtilizations.classiiUtilization +
				this.classIIIActualPrice * this.milkUtilizations.classiiiUtilization +
				this.classIVActualPrice * this.milkUtilizations.classivUtilization +
				this.componentDifferential
			);
		} else {
			return null;
		}
	}

	get monthlyCalculatedAmountCWT() {
		return this.monthlyCalculatedAmount && this.grossProduction ? this.monthlyCalculatedAmount / (this.grossProduction / 100) : 0;
	}

	get monthlyActualAmountCWT() {
		return this.grossProductionActual ? (this.monthlyActualAmounts ?? 0) / (this.grossProductionActual / 100) : 0;
	}

	get mailboxPrice() {
		return (this.everAgBasis ?? 0) + (this.blendedPrice ?? 0) + this.monthlyCalculatedAmountCWT;
	}

	get mailboxPriceForecastedActualBased() {
		return (this.everAgBasis ?? 0) + (this.blendedPriceForecastedActualBased ?? 0) + this.monthlyCalculatedAmountCWT;
	}

	get mailboxPriceActual() {
		if (this.blendedPriceActual == null && this.monthlyActualAmountCWT == null) return null;

		return (this.blendedPriceActual ?? 0) + this.monthlyActualAmountCWT;
	}

	get everAgBasisActualBased(): number | null {
		return (this.blendedPriceActual ?? 0) - (this.blendedPrice ?? 0);
	}

	get classIIIBasis(): number | null {
		return (this.blendedPriceActual ?? 0) - (this.classIIIActualPrice ?? 0);
	}

	get classIVBasis(): number | null {
		return (this.blendedPriceActual ?? 0) - (this.classIVActualPrice ?? 0);
	}

	get componentDifferential() {
		const standardOtherSolidsPercentage = 0.0569;
		const standardButterFatPercentage = 0.035;
		const standardProteinPercentage = 0.0299;
		if (
			this.advancedOtherSolidsPrice == null ||
			this.advancedButterfatPrice == null ||
			this.advancedProteinPrice == null ||
			this.otherSolidsPercent == null ||
			this.butterfatPercent == null ||
			this.proteinPercent == null
		)
			return null;

		if (this.otherSolidsPercent == 0 && this.butterfatPercent == 0 && this.proteinPercent == 0) {
			return 0;
		}

		const farm =
			this.advancedOtherSolidsPrice * this.otherSolidsPercent +
			this.advancedButterfatPrice * this.butterfatPercent +
			this.advancedProteinPrice * this.proteinPercent;
		const standard =
			this.advancedOtherSolidsPrice * standardOtherSolidsPercentage +
			this.advancedButterfatPrice * standardButterFatPercentage +
			this.advancedProteinPrice * standardProteinPercentage;

		return (farm - standard) * 100;
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
//@ts-ignore
declare module 'ember-data/types/registries/model' {
	export default interface ModelRegistry {
		'milk-check': MilkCheck;
	}
}
