const instrumentOptionTypes = [
	{
		name: 'All',
		instrumentType: null,
		optionType: null,
	},
	{
		name: 'Futures',
		instrumentType: 'Future',
		optionType: null,
	},
	{
		name: 'Put Options',
		instrumentType: 'Option',
		optionType: 'Put',
	},
	{
		name: 'Call Options',
		instrumentType: 'Option',
		optionType: 'Call',
	},
	{
		name: 'Swaps',
		instrumentType: 'Swap',
		optionType: null,
	},
	{
		name: 'Put Swaptions',
		instrumentType: 'Swaption',
		optionType: 'Put',
	},
	{
		name: 'Call Swaptions',
		instrumentType: 'Swaption',
		optionType: 'Call',
	},
];

export default instrumentOptionTypes;
