import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import resetVaultTableScroll from 'vault-client/utils/reset-vault-table-scroll';
import { SortObj, TableColumn, CellComponents } from 'vault-client/types/vault-table';
import { BusinessEntityRole } from 'vault-client/types/graphql-types';
import BusinessesBusinessLocationsRoute from 'vault-client/routes/businesses/business/locations';
import { ModelFrom } from 'vault-client/utils/type-utils';

const queryParams = ['sorts'];

export default class LocationsIndex extends Controller {
	queryParams = queryParams;

	declare model: ModelFrom<BusinessesBusinessLocationsRoute>;

	@tracked organizationId: string | null = null;
	@tracked globalCustomerId: string | null = null;
	@tracked locationId: string | null = null;
	@tracked sorts: SortObj[] = [];

	settingsRoutePath = 'businesses.business.settings';

	get columns(): TableColumn[] {
		const baseColumns: TableColumn[] = [
			{
				id: '8cfd3f6c-46fe-4129-8893-49c09a93f201',
				name: 'Name',
				valuePath: 'name',
				minWidth: 225,
				textAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				linkRoute:
					this.model.businessRoles.includes(BusinessEntityRole.DairyProcessor) &&
					!this.model.businessRoles.includes(BusinessEntityRole.DairyProducer)
						? 'locations.location.inventory.index'
						: 'locations.location.production',
				linkModelPath: 'id',
				linkQuery: {
					locationId: {
						rowKey: 'id',
					},
				},
			},
			{
				id: '83ee26f5-7d5e-47da-be1e-2b37add02a6c',
				name: 'County',
				valuePath: 'County.name',
				minWidth: 225,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '946da76f-54f0-4ebd-bb01-31c75caaa5d4',
				name: 'State',
				valuePath: 'County.State.name',
				minWidth: 225,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
		];
		return baseColumns;
	}

	get sortParams() {
		return this.sorts;
	}

	set sortParams(sorts) {
		this.sorts = sorts;
	}

	get locations() {
		return this.model.locations.data?.Locations;
	}

	get locationCount() {
		return this.model.locations.data?.LocationCount.count;
	}

	@action
	setTablePageState() {
		resetVaultTableScroll('locations-index-table');
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'locations/index': LocationsIndex;
	}
}
