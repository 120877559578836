import Position from './position';
//@ts-ignore
import Swap from './swap';

export default class SwapPosition extends Position {
	get marketDataInstrument() {
		const swapInstrument = this.Instrument as Swap;
		return swapInstrument.priceInstrument ? swapInstrument.priceInstrument : undefined;
	}
}
