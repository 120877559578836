import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<PowerSelect\n\t@selected={{this.selectedItem}}\n\t@searchEnabled={{true}}\n\t@search={{this.fetchItems}}\n\t@allowClear={{false}}\n\t@searchField='name'\n\t@searchPlaceholder='Find swap account...'\n\t@onChange={{this.setItem}}\n\t@renderInPlace={{@renderInPlace}}\n\tas |item|\n>\n\t{{item.name}}\n</PowerSelect>", {"contents":"<PowerSelect\n\t@selected={{this.selectedItem}}\n\t@searchEnabled={{true}}\n\t@search={{this.fetchItems}}\n\t@allowClear={{false}}\n\t@searchField='name'\n\t@searchPlaceholder='Find swap account...'\n\t@onChange={{this.setItem}}\n\t@renderInPlace={{@renderInPlace}}\n\tas |item|\n>\n\t{{item.name}}\n</PowerSelect>","moduleName":"vault-client/components/swap-account-select.hbs","parseOptions":{"srcName":"vault-client/components/swap-account-select.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { queryManager } from 'ember-apollo-client';
import query from 'vault-client/graphql/queries/search.graphql';
import swapAccountQuery from 'vault-client/graphql/queries/swap-account-get.graphql';

interface Args {
	selected: any;
	onChange: any;
}

export default class SwapAccountSelectComponent extends Component<Args> {
	@queryManager apollo: any;
	@tracked _selectedItem;

	constructor(owner: any, args: Args) {
		super(owner, args);
		if (args.selected) {
			this._selectedItem = args.selected ?? null;
		}
	}

	get selectedItem() {
		return this._selectedItem ? this.fetchItem(this._selectedItem) : null;
	}

	async fetchItem(id: any) {
		const variables = { id };
		return (await this.apollo.watchQuery({ query: swapAccountQuery, variables })).SwapAccount;
	}

	@action
	async fetchItems(searchText: any) {
		const variables = {
			query: searchText,
			limit: 20,
			typesToInclude: ['SwapAccount'],
		};
		return (await this.apollo.watchQuery({ query, variables })).Search;
	}

	@action
	async setItem({ id }: any) {
		this._selectedItem = id;
		this.args.onChange(id);
	}
}
