import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Vault::UiButton {{on 'click' this.openModal}} @size={{@size}} @style='destructive' ...attributes>{{yield}}</Vault::UiButton>\n{{#if this.showModal}}\n\t<Vault::UiConfirmation as |confirmation|>\n\t\t<confirmation.title>Delete Advisor Confirmation</confirmation.title>\n\t\t<confirmation.body>Are you sure you want to delete this Advisor?\n\t\t</confirmation.body>\n\t\t<confirmation.cancel @style='plain' {{on 'click' this.closeConfirmation}}>\n\t\t\tCancel\n\t\t</confirmation.cancel>\n\t\t<confirmation.action @style='destructive' @disabled={{this.isSubmitting}} {{on 'click' this.confirmDelete}}>\n\t\t\t{{#if this.isSubmitting}}\n\t\t\t\t<span class='mr-2'>Submitting...</span>\n\t\t\t\t<FaIcon @icon='spinner' @spin={{true}} />\n\t\t\t{{else}}\n\t\t\t\tDelete Advisor\n\t\t\t{{/if}}</confirmation.action>\n\t</Vault::UiConfirmation>\n{{/if}}", {"contents":"<Vault::UiButton {{on 'click' this.openModal}} @size={{@size}} @style='destructive' ...attributes>{{yield}}</Vault::UiButton>\n{{#if this.showModal}}\n\t<Vault::UiConfirmation as |confirmation|>\n\t\t<confirmation.title>Delete Advisor Confirmation</confirmation.title>\n\t\t<confirmation.body>Are you sure you want to delete this Advisor?\n\t\t</confirmation.body>\n\t\t<confirmation.cancel @style='plain' {{on 'click' this.closeConfirmation}}>\n\t\t\tCancel\n\t\t</confirmation.cancel>\n\t\t<confirmation.action @style='destructive' @disabled={{this.isSubmitting}} {{on 'click' this.confirmDelete}}>\n\t\t\t{{#if this.isSubmitting}}\n\t\t\t\t<span class='mr-2'>Submitting...</span>\n\t\t\t\t<FaIcon @icon='spinner' @spin={{true}} />\n\t\t\t{{else}}\n\t\t\t\tDelete Advisor\n\t\t\t{{/if}}</confirmation.action>\n\t</Vault::UiConfirmation>\n{{/if}}","moduleName":"vault-client/components/delete-grain-advisor-button.hbs","parseOptions":{"srcName":"vault-client/components/delete-grain-advisor-button.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { gql, useMutation } from 'glimmer-apollo';
import { Mutation, Mutation_updateEntityUserArgs, EntityUser } from 'vault-client/types/graphql-types';
import { inject as service } from '@ember/service';
import RouterService from '@ember/routing/router-service';

const DELETE_ADVISOR = gql`
	mutation RemoveAdvisor($data: EntityUserUpdateDTO!, $id: String!) {
		updateEntityUser(data: $data, id: $id) {
			id
		}
	}
`;

type DeleteGrainAdvisorButtonComponentArgs = {
	entityUsers: EntityUser[];
};

export default class DeleteGrainAdvisorButtonComponent extends Component<DeleteGrainAdvisorButtonComponentArgs> {
	@service declare router: RouterService;
	@tracked showModal = false;
	@tracked isSubmitting = false;
	deleteAdvisor = useMutation<{ updateEntityUser: Mutation['updateEntityUser'] }, Mutation_updateEntityUserArgs>(this, () => [
		DELETE_ADVISOR,
		{
			update: (cache) => {
				cache.evict({ fieldName: 'GrainAdvisor' });
				cache.evict({ fieldName: 'GrainAdvisors' });
				cache.gc();
			},
			onError: (error): void => {
				console.error('Error while attempting to delete Advisor', error.message);
				this.isSubmitting = false;
			},
		},
	]);

	@action
	openModal() {
		this.showModal = true;
	}

	@action
	closeConfirmation() {
		this.showModal = false;
	}

	@action
	async confirmDelete() {
		this.isSubmitting = true;
		const entityUsers = this.args.entityUsers;
		if (entityUsers) {
			const promises = entityUsers.map((entityUser) => {
				if (entityUser.id) {
					// Filter out the "GrainAdvisor" role only
					const updatedRoles = entityUser.roles.filter((role) => role !== 'GrainAdvisor');
					return this.deleteAdvisor.mutate({
						data: {
							roles: updatedRoles,
						},
						id: entityUser.id,
					});
				} else {
					throw new Error('entityUser.id is not defined');
				}
			});

			await Promise.all(promises);

			this.isSubmitting = false;

			this.router.transitionTo('vgs.settings.advisors.index');
		} else {
			this.isSubmitting = false;
			throw new Error('entityUserId is not defined');
		}
	}
}
