import LRPInsuranceEndorsementsShowController from 'vault-client/controllers/lrp-insurance-endorsements/show';

export default class OrganizationsOrganizationLrpInsuranceEndorsement extends LRPInsuranceEndorsementsShowController {
	lrpInsuranceEndorsementsRoute: string = 'organizations.organization.lrp-insurance-endorsements';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'organizations/organization/lrp-insurance-endorsement': OrganizationsOrganizationLrpInsuranceEndorsement;
	}
}
