import Route from '@ember/routing/route';
import { gql, useQuery } from 'glimmer-apollo';
import { tracked } from 'tracked-built-ins';
import { Query_SwineLivestockGroupContractsArgs, SwineLivestockGroupContract } from 'vault-client/types/graphql-types';

const GET_SWINE_PRODUCTION_CONTRACTS_QUERY = gql`
	query SwineLivestockGroupContracts($where: SwineLivestockGroupContractFilterDTO) {
		SwineLivestockGroupContracts(where: $where) {
			id
			name
			startDate
			basisPricingType
			businessId
			contractedQuantity
			contractee
			createdAt
			endDate
			periodAllocationType
			forecastPricingType
			forecastPricingValue
			hasWriteAccess
			note
		}
	}
`;

type GetSwineProductionContracts = {
	__typename?: 'Query';

	SwineLivestockGroupContracts: SwineLivestockGroupContract[];
};

export default class BusinessesBusinessProductionContractsRoute extends Route {
	@tracked id: string = '';

	getSwineProductionContracts = useQuery<GetSwineProductionContracts, Query_SwineLivestockGroupContractsArgs>(this, () => [
		GET_SWINE_PRODUCTION_CONTRACTS_QUERY,
		{
			variables: {
				where: {
					businessId: {
						equals: this.id,
					},
				},
			},
		},
	]);

	async model() {
		const { business_id: businessId } = this.paramsFor('businesses.business') as { business_id: string };
		this.id = businessId;
		await this.getSwineProductionContracts.promise;

		return { contracts: this.getSwineProductionContracts, businessId: businessId };
	}
}
