import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<span {{did-insert this.fetchCropYears}}>\n\t<PowerSelect\n\t\t@selected={{@selected}}\n\t\t@options={{this.cropYears}}\n\t\t@searchEnabled='true'\n\t\t@renderInPlace={{true}}\n\t\t@searchField='name'\n\t\t@placeholder='Select a Crop Year'\n\t\t@onChange={{@onChange}}\n\t\tas |cropYear|\n\t>\n\t\t{{cropYear.year}}\n\t</PowerSelect>\n</span>", {"contents":"<span {{did-insert this.fetchCropYears}}>\n\t<PowerSelect\n\t\t@selected={{@selected}}\n\t\t@options={{this.cropYears}}\n\t\t@searchEnabled='true'\n\t\t@renderInPlace={{true}}\n\t\t@searchField='name'\n\t\t@placeholder='Select a Crop Year'\n\t\t@onChange={{@onChange}}\n\t\tas |cropYear|\n\t>\n\t\t{{cropYear.year}}\n\t</PowerSelect>\n</span>","moduleName":"vault-client/components/vgs-crop-year-select.hbs","parseOptions":{"srcName":"vault-client/components/vgs-crop-year-select.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { gql, useQuery } from 'glimmer-apollo';
import { GrainCropYear, Query, Query_GrainCropYearsArgs } from 'vault-client/types/graphql-types';

interface VgsCropYearSelectArgs {
	onChange: (cropYear: GrainCropYear) => void;
	selected: GrainCropYear | null;
}

const GET_CROP_YEARS = gql`
	query GrainCropYears {
		GrainCropYears(orderBy: { year: Asc }) {
			id
			year
		}
	}
`;

export default class VgsCropYearSelect extends Component<VgsCropYearSelectArgs> {
	@tracked cropYears: GrainCropYear[] = [];
	@tracked errorMessage: string | null = null;

	getCropYears = useQuery<{ GrainCropYears: Query['GrainCropYears'] }, Query_GrainCropYearsArgs>(this, () => [
		GET_CROP_YEARS,
		{
			onComplete: (data) => {
				this.cropYears = data?.GrainCropYears ?? [];
			},
			onError: (error) => {
				console.error(error.message);
			},
		},
	]);

	@action
	async fetchCropYears() {
		await this.getCropYears.promise;
	}
}
