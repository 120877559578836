import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<PowerSelect\n\t@selected={{this.selectedItem}}\n\t@searchEnabled={{true}}\n\t@search={{this.fetchItems}}\n\t@allowClear={{false}}\n\t@searchField='name'\n\t@searchPlaceholder='Find future...'\n\t@onChange={{this.setItem}}\n\t@renderInPlace={{@renderInPlace}}\n\tas |item|\n>\n\t{{item.name}}\n</PowerSelect>", {"contents":"<PowerSelect\n\t@selected={{this.selectedItem}}\n\t@searchEnabled={{true}}\n\t@search={{this.fetchItems}}\n\t@allowClear={{false}}\n\t@searchField='name'\n\t@searchPlaceholder='Find future...'\n\t@onChange={{this.setItem}}\n\t@renderInPlace={{@renderInPlace}}\n\tas |item|\n>\n\t{{item.name}}\n</PowerSelect>","moduleName":"vault-client/components/future-select.hbs","parseOptions":{"srcName":"vault-client/components/future-select.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { queryManager } from 'ember-apollo-client';
import query from 'vault-client/graphql/queries/search.graphql';
import futureQuery from 'vault-client/graphql/queries/future-get.graphql';

interface Args {
	selected: any;
	onChange: any;
}

export default class FutureSelectComponent extends Component<Args> {
	@queryManager apollo: any;
	@tracked selectedItem;

	constructor(owner: any, args: Args) {
		super(owner, args);
		if (this.selectedItem == null && !!this.args.selected) {
			this.selectedItem = this.args.selected;
		}
	}

	async fetchItem(id: any) {
		const variables = { id };
		return (await this.apollo.watchQuery({ query: futureQuery, variables })).Future;
	}

	@action
	async fetchItems(searchText: any) {
		const variables = {
			query: searchText,
			limit: 20,
			typesToInclude: ['Future'],
		};
		return (await this.apollo.watchQuery({ query, variables })).Search;
	}

	@action
	async setItem({ id }: any) {
		this.selectedItem = this.fetchItem(id);
		this.args.onChange(id);
	}
}
