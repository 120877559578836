import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { gql } from 'glimmer-apollo';
import { tracked } from '@glimmer/tracking';
import {
	AggregateDerivedDrpEndorsementDTO,
	Count,
	DerivedDrpEndorsement,
	DerivedDrpEndorsementFilterDTO,
	DerivedDrpEndorsementSortByDTO,
} from 'vault-client/types/graphql-types';
import { DateTime } from 'luxon';

type SortObj = {
	valuePath: string;
	isAscending: boolean;
};

export const GET_INSURANCE_ENDORSEMENTS = gql`
	query InsuranceEndorsements(
		$offset: Float
		$limit: Float
		$orderBy: DerivedDrpEndorsementSortByDTO
		$where: DerivedDrpEndorsementFilterDTO
	) {
		DerivedDrpEndorsements(offset: $offset, limit: $limit, orderBy: $orderBy, where: $where) {
			classPriceWeightingFactor
			componentPriceWeightingFactor
			coverageLevelPercent
			declaredButterfatTest
			declaredCoveredMilkProduction
			declaredProteinTest
			effectiveCoveredMilkProduction
			effectiveExpectedRevenueGuarantee
			pnl
			id
			indemnity
			InsurancePolicy {
				id
				producerName
				policyNumber
				agencyName
				agentFullName
				AIP {
					id
					name
				}
			}
			producerPremiumAmount
			projectedRevenue
			quarterStartDate
			salesEffectiveDate
			totalPremiumAmount
		}
		DerivedDrpEndorsementCount(where: $where) {
			count
		}
		AggregateDerivedDrpEndorsements(
			calc: {
				sum: {
					declaredCoveredMilkProduction: true
					effectiveCoveredMilkProduction: true
					effectiveExpectedRevenueGuarantee: true
					pnl: true
					indemnity: true
					producerPremiumAmount: true
					totalPremiumAmount: true
				}
			}
			where: $where
			groupBy: {}
		) {
			quarterStartDate
			quarterEndDate
			sum {
				declaredCoveredMilkProduction
				effectiveCoveredMilkProduction
				pnl
				indemnity
				producerPremiumAmount
				totalPremiumAmount
				effectiveExpectedRevenueGuarantee
			}
		}
	}
`;

export type GetInsuranceEndorsementsQuery = {
	__typename?: 'Query';

	AggregateDerivedDrpEndorsements: AggregateDerivedDrpEndorsementDTO[];
	DerivedDrpEndorsementCount: Count;
	DerivedDrpEndorsements: DerivedDrpEndorsement[];
};

export default class DRPInsuranceEndorsementsIndexRoute extends Route {
	@service applicationScope: any;
	@tracked errorMessage: string | null = null;

	queryParams = {
		agent: { refreshModel: true },
		aipId: { refreshModel: true },
		quarterStartDate: { refreshModel: true },
		quarterEndDate: { refreshModel: true },
		salesEffectiveStartDate: { refreshModel: true },
		salesEffectiveEndDate: { refreshModel: true },
		stateId: { refreshModel: true },
		page: { refreshModel: true },
		sorts: { refreshModel: true },
		type: { refreshModel: true },
		size: { refreshModel: true },
	};

	templateName = 'drp-insurance-endorsements/index';

	generateOrderBy(sorts: SortObj[]): DerivedDrpEndorsementSortByDTO {
		const orderBy: {
			[key: string]: any;
		} = {};

		sorts.forEach((sort: any) => {
			const value = sort.isAscending ? 'Asc' : 'Desc';
			orderBy[sort.valuePath] = value;
		});

		return orderBy;
	}

	generateWhere(
		organizationId: null | string,
		customerId: undefined | null | string,
		aipId: string | null | undefined,
		type: string | null | undefined,
		agent: string | null | undefined,
		stateId: string | null | undefined,
		salesEffectiveStartDate: string | null | undefined,
		salesEffectiveEndDate: string | null | undefined,
		quarterStartDate: string | null | undefined,
		quarterEndDate: string | null | undefined
	): DerivedDrpEndorsementFilterDTO {
		const where: DerivedDrpEndorsementFilterDTO = {
			DrpInsuranceEndorsement: {},
		};

		if (customerId) {
			where.InsurancePolicy = {
				customerId: { equals: customerId },
			};
		} else if (organizationId) {
			where.InsurancePolicy = {
				OR: [
					{
						Customer: {
							organizationId: {
								equals: organizationId,
							},
						},
					},
					{
						ownerId: {
							equals: organizationId,
						},
					},
				],
			};
		}

		if (customerId) {
			if (!where.InsurancePolicy) {
				where.InsurancePolicy = {};
			}
			where.InsurancePolicy.customerId = {
				equals: customerId,
			};
		}

		if (aipId) {
			if (!where.InsurancePolicy) {
				where.InsurancePolicy = {};
			}
			where.InsurancePolicy.aipId = {
				equals: aipId,
			};
		}

		if (agent) {
			const nameArr = agent.split(' ');
			if (!where.AND) {
				where.AND = [];
			}
			where.AND.push({
				InsurancePolicy: { agentFirstName: { equals: nameArr[0] } },
			});
			where.AND.push({
				InsurancePolicy: { agentLastName: { equals: nameArr[1] } },
			});
		}

		if (stateId) {
			if (!where.InsurancePolicy) {
				where.InsurancePolicy = {};
			}
			where.InsurancePolicy.stateId = {
				equals: stateId,
			};
		}

		if (type) {
			if (type === 'Class') {
				where.classPriceWeightingFactor = { gte: 0 };
			} else if (type === 'Component') {
				where.componentPriceWeightingFactor = { gte: 0 };
			}
		}

		if (salesEffectiveStartDate && salesEffectiveEndDate) {
			if (!where.AND) {
				where.AND = [];
			}
			where.AND.push({
				salesEffectiveDate: { gte: salesEffectiveStartDate },
			});
			where.AND.push({
				salesEffectiveDate: { lte: salesEffectiveEndDate },
			});
		} else if (salesEffectiveStartDate) {
			where.salesEffectiveDate = {
				gte: salesEffectiveStartDate,
			};
		} else if (salesEffectiveEndDate) {
			where.salesEffectiveDate = {
				lte: salesEffectiveEndDate,
			};
		}

		if (quarterStartDate && quarterEndDate) {
			if (!where.AND) {
				where.AND = [];
			}
			where.AND.push({
				quarterEndDate: { gte: quarterStartDate },
			});
			where.AND.push({
				quarterEndDate: {
					lte: DateTime.fromISO(quarterEndDate).endOf('quarter').toISODate(),
				},
			});
		} else if (quarterStartDate) {
			where.quarterEndDate = {
				gte: quarterStartDate,
			};
		} else if (quarterEndDate) {
			where.quarterEndDate = {
				lte: DateTime.fromISO(quarterEndDate).endOf('quarter').toISODate(),
			};
		}

		return where;
	}
}
