import Controller from '@ember/controller';
import StrategicBuyingModel from 'vault-client/models/strategic-buying-model';
import { getOwner } from '@ember/application';
import { tracked } from '@glimmer/tracking';
import { Product } from 'vault-client/types/graphql-types';
import isTouchDevice from 'vault-client/utils/is-touch-device';
import { TableColumn, CellComponents } from 'vault-client/types/vault-table';
import ReportsClassIvStrategicBuyingModelRoute from 'vault-client/routes/reports/class-iv-strategic-buying-model';
import { ModelFrom } from 'vault-client/utils/type-utils';

export default class ReportsClassIvStrategicBuyingModel extends Controller {
	@tracked carryCost: number = 0.01;
	@tracked numberOfYears: number = 5;
	reportsRoute: string = '';
	declare model: ModelFrom<ReportsClassIvStrategicBuyingModelRoute>;
	get columns(): TableColumn[] {
		const baseColumns: TableColumn[] = [
			{
				id: 'fd6a2149-d985-4682-b62a-e4eb54884ba9',
				name: 'Month',
				valuePath: 'displayExpiresAt',
				minWidth: 120,
				cellComponent: CellComponents.MonthFormat,
				textAlign: 'left',
				isSortable: false,
				isFixed: !isTouchDevice() ? 'left' : '',
				isVisible: true,
			},
			{
				id: 'c14edec3-334c-4b0f-8110-1ac01d704db5',
				name: 'Futures',
				valuePath: 'currentFuturePrice',
				minWidth: 120,
				cellComponent: CellComponents.PriceFormat,
				componentArgs: {
					fractionDigitsPath: 'product.MostCurrentFuture.SymbolGroup.fractionDigits',
					displayFactorPath: 'noDisplayFactor',
				},
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '4b472ac5-f760-415e-932c-efe3eddbc776',
				name: 'Strategy',
				valuePath: 'riskManagementStrategy',
				minWidth: 120,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
		];
		return baseColumns.filter((column) => column.isVisible);
	}

	currentPriceInput: string = 'Front Future Price';
	canBuySpot: boolean = false;

	prevSims: {
		oneMonthCarryIncentive: number | null;
		displayExpiresAt: string;
		symbol: string;
	}[] = [];

	get spotProduct() {
		return null;
	}

	get futuresProduct() {
		const products: Product[] = this.model.Products;
		return products?.find((product) => product.slug === 'us-dairy-class-iv');
	}

	get frontFuture() {
		return this.futuresProduct?.CurrentFutures[0];
	}

	get months() {
		const owner = getOwner(this);
		const product = this.futuresProduct;
		return product?.CurrentFutures?.reduce((acc: any, future: any) => {
			const previousSims = acc.slice(-2);
			const currentSim = new StrategicBuyingModel(
				owner,
				product,
				future,
				this.carryCost,
				previousSims,
				this.spotProduct,
				this.currentPriceInput,
				this.canBuySpot,
				this.numberOfYears
			);

			acc.push(currentSim);
			return acc;
		}, []);
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'reports/class-iv-strategic-buying-model': ReportsClassIvStrategicBuyingModel;
	}
}
