import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { debounce } from '@ember/runloop';
import { queryManager } from 'ember-apollo-client';
import organizationsQuery from 'vault-client/graphql/queries/organizations-get.graphql';
import { OrganizationEntity } from 'vault-client/types/graphql-types';

interface UiOrgSelectComponentArgs {
	organizationId: string;
	setOrganizationId: (organizationID: string) => void;
	dropdownOriginRight?: boolean;
	currentValue?: string;
	defaultValue?: any;
	firstOption?: string;
	size?: 'default' | 'narrow';
	disabled?: boolean;
}

export default class UiOrgSelectComponent extends Component<UiOrgSelectComponentArgs> {
	@tracked dropdownOpen = false;
	@tracked organizations: OrganizationEntity[] = [];

	@queryManager apollo: any;

	get dropdownOriginRight() {
		return this.args.dropdownOriginRight ?? true;
	}

	get size(): 'default' | 'narrow' {
		return this.args.size || 'default';
	}

	@action
	async fetchOrganizations() {
		this.organizations = ((await this.apollo.watchQuery({ query: organizationsQuery })).Organizations as OrganizationEntity[]).sortBy(
			'name'
		);
	}

	get currentOrganization() {
		return (
			this.organizations.find((org) => {
				return org.id === this.args.organizationId;
			})?.name || ''
		);
	}

	@action
	handleOutsideClick() {
		if (!this.dropdownOpen) return;
		debounce(this.closeDropDown, 150);
	}

	@action
	toggleDropdown() {
		this.dropdownOpen = !this.dropdownOpen;
	}

	@action
	closeDropDown() {
		this.dropdownOpen = false;
	}

	@action
	handleDropdownClick(organizationId: string) {
		this.closeDropDown();
		this.args.setOrganizationId(organizationId);
	}

	@action
	handleDropdownKeyDown(event: KeyboardEvent) {
		const elements = [...((event.currentTarget as HTMLElement)?.getElementsByClassName('ui-dropdown-item') || [])];
		const length = elements.length - 1;
		let activeItem = elements.findIndex((el) => el.matches(':focus'));

		if (event.key === 'Down' || event.key === 'ArrowDown') {
			event.preventDefault();
			activeItem = activeItem === length ? 0 : ++activeItem;
			(elements[activeItem] as HTMLElement).focus({ preventScroll: false });
		} else if ((event.key === 'Up' || event.key === 'ArrowUp') && activeItem > 0) {
			event.preventDefault();
			(elements[--activeItem] as HTMLElement).focus({ preventScroll: false });
		} else if (event.key === 'Esc' || event.key === 'Escape') {
			event.preventDefault();
			(event?.srcElement as HTMLElement).blur();
			this.closeDropDown();
		} else if (event.shiftKey && event.key === 'Tab') {
			if (event.shiftKey && activeItem === 0) {
				this.closeDropDown();
			}
		} else if (event.key === 'Tab') {
			if (activeItem === length) {
				this.closeDropDown();
			}
		}
	}

	@action
	focusOnFirstOption() {
		if (this.args.firstOption) {
			document.getElementById(this.args.firstOption)?.focus();
		}
	}
}
