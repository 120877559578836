import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { ModelFrom } from 'vault-client/utils/type-utils';
import route, { GET_FEED_PLANS } from 'vault-client/routes/vgs/feed-services/feed-plans/index';
import { CellComponents, TableColumn } from 'vault-client/types/vault-table';
import resetVaultTableScroll from 'vault-client/utils/reset-vault-table-scroll';
import { action } from '@ember/object';
import FeedPlanModel from 'vault-client/models/vgs/feed-plan';
import getFilterDisplayProperty from 'vault-client/utils/get-filter-display-property';
import { gql, useQuery } from 'glimmer-apollo';
import luxon, { DateTime } from 'luxon';
import { intervalFromDateTime } from 'vault-client/utils/interval-from-date-time';
import { GrainFeedPlan, Query, Query_SearchArgs, TypeOfSearchItem } from 'vault-client/types/graphql-types';
import { inject as service } from '@ember/service';
import PermissionsService from 'vault-client/services/permissions';
import GetAvailableFeedYearsService from 'vault-client/services/get-available-feed-years';

interface SearchResult {
	name: string;
	type: string;
	id: string;
}

const GET_SEARCH_QUERY = gql`
	query Search($query: String!, $typesToInclude: [TypeOfSearchItem!]) {
		Search(query: $query, typesToInclude: $typesToInclude) {
			id
			name
			type
			attributes
		}
	}
`;
export default class VgsFeedServicesFeedPlansIndex extends Controller {
	@service declare permissions: PermissionsService;
	@service('get-available-feed-years') declare getAvailableFeedYears: GetAvailableFeedYearsService;
	@tracked page = 0;
	@tracked size = 100;
	@tracked sorts = [];
	@tracked customerId: string | null = null;
	@tracked planIdToDelete: GrainFeedPlan | null = null;
	@tracked selectedFeedYears: number[] = [];
	@tracked selectedFeedCategoryIds: string[] = [];
	@tracked month: string | null = null;

	queryParams = ['customer', 'selectedIngredients', 'selectedFeedCategories', 'selectedFeedYears', 'month'];

	declare model: ModelFrom<route>;

	months = luxon.Info.months().map((month, idx) => ({ id: (idx + 1).toString(), name: month }));
	years = intervalFromDateTime(DateTime.now().minus({ year: 5 }), DateTime.now().plus({ year: 5 }), { year: 1 }).map((dt) =>
		dt.year.toString()
	);

	itemsFn = (plans: GrainFeedPlan[] | undefined) => {
		return (
			plans?.map((plan: GrainFeedPlan) => {
				return new FeedPlanModel(plan);
			}) ?? []
		);
	};

	get columns() {
		let brOnlyButtonColumns: TableColumn[] = [];
		if (this.permissions.isBuyerRelations) {
			brOnlyButtonColumns = [
				{
					id: '8b3a205d-2f36-482d-9e61-dd5947e5bd9d',
					name: '',
					valuePath: '',
					minWidth: 120,
					textAlign: 'left',
					isSortable: false,
					cellComponent: CellComponents.Button,
					componentArgs: {
						size: 'xs',
						style: 'outline',
						text: 'Remove',
						fn: this.setPlanToDelete,
						disableFn: (datum: GrainFeedPlan) => {
							return !datum.hasWriteAccess ? true : false;
						},
					},
					isFixed: '',
					isVisible: true,
				},
			];
		}
		const baseColumns: TableColumn[] = [
			{
				id: '8f8dfa90-34fb-47e9-a24a-3febb50f38a7',
				name: '',
				width: 100,
				textAlign: 'center',
				isSortable: false,
				cellComponent: CellComponents.Button,
				componentArgs: {
					size: 'xs',
					style: 'outline',
					text: 'Details',
					fn: () => {},
				},
				isFixed: '',
				isVisible: true,
				linkRoute: 'vgs.feed-services.feed-plans.show',
				linkModelPath: 'id',
			},
			{
				id: 'b4d0fe24-5c5e-465d-bf1a-398cafad5fb4',
				name: 'Feed Year',
				valuePath: 'feedYear',
				minWidth: 110,
				width: 110,
				textAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '83a0facb-0583-4b68-8fcb-a50d9863abfd',
				name: 'Customer',
				valuePath: 'Customer.name',
				width: 160,
				minWidth: 160,
				textAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				linkRoute: 'vgs.customers.show',
				linkModelPath: 'Customer.id',
			},
			{
				id: 'g81cee1e-b471-4124-9776-658fd6673a57',
				name: 'Month',
				valuePath: 'startDate',
				width: 100,
				minWidth: 100,
				textAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.IntlDateTimeFormat,
				componentArgs: {
					month: 'short',
					year: 'numeric',
				},
				isFixed: '',
				isVisible: true,
			},
			{
				id: '615152d6-1db9-4d3f-beed-9a43904eb8f5',
				name: 'Ingredient',
				valuePath: 'FeedCategory.name',
				width: 120,
				minWidth: 120,
				textAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '98a63ebd-846d-41bd-91ec-acdb9807930f',
				name: 'Avg. Future Price',
				valuePath: 'averageFuturePriceSold',
				width: 140,
				minWidth: 140,
				textAlign: 'right',
				isSortable: false,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
					minimumFractionDigits: 2,
					maximumFractionDigits: 5,
				},
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'b9185ffd-70e6-426b-bf08-dc3666c3dfcb',
				name: 'Avg. Flat Price',
				valuePath: 'averageFlatPriceSold',
				width: 125,
				minWidth: 125,
				textAlign: 'right',
				isSortable: false,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
					minimumFractionDigits: 2,
					maximumFractionDigits: 5,
				},
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'a6185e9c-e7cc-4a85-ac53-3930e0e8ede9',
				name: 'Avg. Basis',
				valuePath: 'averageBasis',
				width: 110,
				minWidth: 110,
				textAlign: 'right',
				isSortable: false,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				isFixed: '',
				isVisible: true,
			},
			{
				id: '3bf91f4d-d4fa-45b8-b2a5-16f9792d90cc',
				name: 'Total Est. Feed Usage',
				valuePath: 'tons',
				width: 165,
				minWidth: 165,
				textAlign: 'right',
				isSortable: false,
				cellComponent: CellComponents.IntlNumberFormat,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'c0c7bcf2-bfd8-44e2-b6fd-1422661753c2',
				name: 'Total Tons Flat Priced',
				valuePath: 'totalTonsFlatPriced',
				width: 165,
				minWidth: 165,
				textAlign: 'right',
				isSortable: false,
				cellComponent: CellComponents.IntlNumberFormat,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'd41b22ea-6dd5-4420-bfce-6e02cd02c7c7',
				name: '% Priced',
				valuePath: 'percentPriced',
				width: 90,
				minWidth: 90,
				textAlign: 'right',
				isSortable: false,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'percent',
				},
				isFixed: '',
				isVisible: true,
			},
			{
				id: '3cfa6352-4e83-4053-8397-056123fc0901',
				name: 'Physical Tons Open',
				valuePath: 'openUsage',
				width: 150,
				minWidth: 150,
				textAlign: 'right',
				isSortable: false,
				cellComponent: CellComponents.IntlNumberFormat,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'b8e65c76-3b9e-4fc7-ba17-42dfb099af5f',
				name: '% Physical Tons Open',
				valuePath: 'percentOpen',
				width: 170,
				minWidth: 170,
				textAlign: 'right',
				isSortable: false,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'percent',
				},
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'bc6d337d-33a5-4e1a-b507-8ab7bfa529b5',
				name: 'Basis Only Purchased',
				valuePath: 'basisOnlyPurchased',
				width: 165,
				minWidth: 165,
				textAlign: 'right',
				isSortable: false,
				cellComponent: CellComponents.IntlNumberFormat,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '9644a40f-6f5c-4adb-91e3-a96d8b50b1c8',
				name: 'Basis Exposure',
				valuePath: 'basisExposure',
				width: 140,
				minWidth: 140,
				textAlign: 'right',
				isSortable: false,
				cellComponent: CellComponents.IntlNumberFormat,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '371a9525-64bb-46e3-8816-08bf3c776d55',
				name: '% Basis Exposure',
				valuePath: 'percentBasisExposure',
				width: 125,
				minWidth: 125,
				textAlign: 'right',
				isSortable: false,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'percent',
				},
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'e21014ed-62e1-496a-8766-31987b1840dc',
				name: 'Total Tons - Pending/Working',
				valuePath: 'totalTonsOpen',
				width: 220,
				minWidth: 220,
				textAlign: 'right',
				isSortable: false,
				cellComponent: CellComponents.IntlNumberFormat,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '27a90a18-2018-4dc3-982c-eba3b95f182a',
				name: '% Pending/Working',
				valuePath: 'percentTonsOpen',
				width: 160,
				minWidth: 160,
				textAlign: 'right',
				isSortable: false,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'percent',
				},
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'ba600262-8dfe-40c1-81eb-198311d53e8d',
				name: 'Futures Only Purchased',
				valuePath: 'futuresOnlyPurchased',
				width: 180,
				minWidth: 180,
				textAlign: 'right',
				isSortable: false,
				cellComponent: CellComponents.IntlNumberFormat,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '8f421598-6a04-4cf5-9ac3-3d5edb9df5b0',
				name: 'Futures Exposure',
				valuePath: 'futuresExposure',
				width: 150,
				minWidth: 150,
				textAlign: 'right',
				isSortable: false,
				cellComponent: CellComponents.IntlNumberFormat,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '726ec619-aa67-4e5c-a733-63cbe973fd2d',
				name: '% Futures Exposure',
				valuePath: 'percentFuturesExposure',
				width: 160,
				minWidth: 160,
				textAlign: 'right',
				isSortable: false,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'percent',
				},
				isFixed: '',
				isVisible: true,
			},
		];
		const baseAndWriteColumns = [...baseColumns, ...brOnlyButtonColumns];
		return baseAndWriteColumns;
	}

	get currentPage() {
		return this.page;
	}

	set currentPage(page) {
		this.page = page;
	}

	get rows() {
		const plans = this.model.getFeedPlans.data?.GrainFeedPlans;
		return this.itemsFn(plans);
	}

	get totalNumFeedPlans() {
		return this.model.getFeedPlans.data?.GrainFeedPlanCount?.count;
	}

	get query() {
		return GET_FEED_PLANS;
	}

	get selectedMonth() {
		const val = this.months.find((month) => month.id === this.month) ?? null;

		return val;
	}

	get feedCategories() {
		return this.model.getFeedPlans.data?.FeedCategories ?? [];
	}

	get selectedFeedCategoriesString() {
		if (this.selectedFeedCategoryIds.length === 0) {
			return 'All';
		}

		if (this.selectedFeedCategoryIds.length === 1) {
			return this.feedCategories.find((feedCategory) => feedCategory.id === this.selectedFeedCategoryIds[0])?.name ?? '';
		}

		return `${this.selectedFeedCategoryIds.length} Selected`;
	}

	get selectedFeedYearsString() {
		if (this.selectedFeedYears.length === 0) {
			return 'All';
		}

		if (this.selectedFeedYears.length === 1) {
			return this.selectedFeedYears[0];
		}

		return `${this.selectedFeedYears.length} Selected`;
	}

	@action
	addSelectedFeedYears(feedYear: number | null) {
		if (feedYear === null) {
			this.selectedFeedYears = [];
		} else if (this.selectedFeedYears.includes(feedYear)) {
			this.selectedFeedYears = this.selectedFeedYears.filter((v) => v !== feedYear);
		} else {
			this.selectedFeedYears = [...this.selectedFeedYears, feedYear];
		}

		if (this.selectedFeedYears.length === this.getAvailableFeedYears.feedYears.length) {
			this.selectedFeedYears = [];
		}
		this.setTablePageState();
	}

	@action
	addSelectedFeedCategoryId(feedCategoryId: string | null) {
		if (feedCategoryId === null) {
			this.selectedFeedCategoryIds = [];
		} else if (this.selectedFeedCategoryIds.includes(feedCategoryId)) {
			this.selectedFeedCategoryIds = this.selectedFeedCategoryIds.filter((v) => v !== feedCategoryId);
		} else {
			this.selectedFeedCategoryIds = [...this.selectedFeedCategoryIds, feedCategoryId];
		}

		if (this.feedCategories.length === this.selectedFeedCategoryIds.length) {
			this.selectedFeedCategoryIds = [];
		}
		this.setTablePageState();
	}

	@action
	async fetchSearchResults(searchText: string) {
		const customerResults: SearchResult[] = [];

		const customerQuery = useQuery<{ Search: Query['Search'] }, Query_SearchArgs>(this, () => [
			GET_SEARCH_QUERY,
			{
				variables: {
					query: searchText,
					typesToInclude: [TypeOfSearchItem.Customer],
				},
				onComplete: (data): void => {
					customerResults.push(
						...(data?.Search.filter((item) => {
							// Filter out non-VGS customers
							if (item.type === TypeOfSearchItem.Customer && !item.attributes?.isVgs) {
								return false;
							}

							return true;
						}).map((customer) => ({
							type: 'customer',
							name: customer.name,
							id: customer.id,
						})) ?? [])
					);
				},
			},
		]);

		const promises = [customerQuery.promise];

		await Promise.all(promises);

		return [...customerResults];
	}

	mapSearchResult(searchResult: SearchResult) {
		let filterIdentifier: keyof VgsFeedServicesFeedPlansIndex | null = null;

		switch (searchResult.type) {
			case 'customer':
				filterIdentifier = 'customerId';
				break;
		}

		return {
			filterIdentifier,
			filterValue: searchResult.id ?? searchResult.name,
		};
	}

	@action
	setSearchFilterQueryParam(searchResult: SearchResult) {
		const mappedSearchFilter = this.mapSearchResult(searchResult);

		if (mappedSearchFilter.filterIdentifier) {
			this[mappedSearchFilter.filterIdentifier] = mappedSearchFilter.filterValue;
			this.setTablePageState();
		}
	}

	@action
	clearSearchFilterQueryParam(filterIdentifier: 'customerId') {
		this[`${filterIdentifier}`] = null;
		this.setTablePageState();
	}

	get searchFilterQueryParams() {
		const obj: {
			[key: string]: any;
		} = {};
		const searchQueryParams = ['customerId'] as const;
		searchQueryParams.forEach((param) => {
			const value = this[param];

			if (value) {
				obj[param] = {
					filterRule: 'equals',
					filterValue: value,
				};

				// set filterComponent property to specify component for custom display extended from search-filter
				const filterDisplayObj = getFilterDisplayProperty(param);

				if (filterDisplayObj && filterDisplayObj.customComponent) {
					obj[param].filterComponent = filterDisplayObj.customComponent;
				}
				// set filterLabel property to specify custom label for filter - default would be filterIdentifier (matches queryParam)
				if (filterDisplayObj && filterDisplayObj.label) {
					obj[param].filterLabel = filterDisplayObj.label;
				}
			}
		});

		return obj;
	}

	@action
	structureSearchResults(searchResults: SearchResult[]) {
		const map = new Map();

		searchResults.forEach((item) => {
			if (map.has(item.type)) {
				map.get(item.type).push({ name: item.name, type: item.type, id: item.id });
			} else {
				map.set(item.type, [{ name: item.name, type: item.type, id: item.id }]);
			}
		});

		return map;
	}

	@action
	setTablePageState(newPageVal: number | null = null) {
		this.currentPage = newPageVal ?? 0;
		resetVaultTableScroll('targets-table');
	}

	@action
	setPlanToDelete(plan: GrainFeedPlan) {
		this.planIdToDelete = plan ?? null;
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	interface Registry {
		'vgs/feed-services/feed-plans/index': VgsFeedServicesFeedPlansIndex;
	}
}
