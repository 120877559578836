import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { gql, useMutation } from 'glimmer-apollo';
import { DateTime } from 'luxon';
import { UiTab } from 'vault-client/components/vault/ui-tabs';
import BusinessesBusinessProductionContractsRoute from 'vault-client/routes/businesses/business/production-contracts';
import {
	Mutation_updateSwineLivestockGroupContractArgs,
	SwineLivestockGroupContract,
	TypeOfContractBasis,
	TypeOfContractPricing,
} from 'vault-client/types/graphql-types';
import { ModelFrom } from 'vault-client/utils/type-utils';

const UPDATE_SWINE_LIVESTOCK_GROUP_CONTRACT_COMMENT_MUTATION = gql`
	mutation UpdateSwineLivestockGroupContract($data: SwineLivestockGroupContractUpdateDTO!, $id: String!) {
		updateSwineLivestockGroupContract(data: $data, id: $id) {
			id
		}
	}
`;

type Updateswinelivestockgroupcontractcommentmutation = {
	__typename?: 'Mutation';

	id: string | null;
};

export const TYPE_OF_CONTRACT_PRICING_LABELS = {
	[TypeOfContractPricing.CutoutUsda]: 'Cutout Price Discovery',
	[TypeOfContractPricing.FlatPricePerLb]: 'Flat Price (per CWT)',
	[TypeOfContractPricing.LeanHogFuture]: 'Flat Futures Basis',
	[TypeOfContractPricing.LeanHogIndex]: 'CME Index Price Discovery',
	[TypeOfContractPricing.LeanHogUsdaNational]: 'Cash Price Discovery - National',
	[TypeOfContractPricing.LeanHogUsdaIowaMinnesota]: 'Cash Price Discovery - Iowa/Minnesota',
	[TypeOfContractPricing.LeanHogUsdaWesternCornbelt]: 'Cash Price Discovery - Western Cornbelt',
	[TypeOfContractPricing.LeanHogUsdaEasternCornbelt]: 'Cash Price Discovery - Eastern Cornbelt',
};
export default class BusinessesBusinessProductionContractsController extends Controller {
	declare model: ModelFrom<BusinessesBusinessProductionContractsRoute>;
	@tracked dropdownOpen: boolean = false;
	@tracked showError: boolean = false;
	@tracked activeContract: SwineLivestockGroupContract | null = null;
	@tracked _activeTab: UiTab | null = null;
	@tracked activeContractComment: string | undefined;
	_previousContractComment: string | undefined;
	@tracked contractToDelete: SwineLivestockGroupContract | null = null;
	@tracked isTextAreaDisabled: boolean = true;
	@tracked contractToUploadPDF: string | null = null;
	@tracked businessId: string | null = null;
	@tracked yearStartDate: string = '1900-01-01';
	@tracked yearEndDate: string = '2999-12-31';
	dateToday: Date = new Date();

	updateswinelivestockgroupcontractcommentmutation = useMutation<
		Updateswinelivestockgroupcontractcommentmutation,
		Mutation_updateSwineLivestockGroupContractArgs
	>(this, () => [
		UPDATE_SWINE_LIVESTOCK_GROUP_CONTRACT_COMMENT_MUTATION,
		{
			onComplete: (): void => {
				this.disableTextArea();
				this._previousContractComment = this.activeContractComment;
				this.model.contracts.refetch();
			},
			onError: (error): void => {
				this.activeContractComment = this._previousContractComment;
				this.showError = true;
				console.error('Error while attempting to edit comment', error.message);
			},
		},
	]);

	get yearDateRangeOptions() {
		const yearDateRangeOptions = [
			{
				displayName: 'All Years',
				startDate: '1900-01-01',
				endDate: '2999-12-31',
			},
			{
				displayName: 'Before 2020',
				startDate: '1900-01-01',
				endDate: '2019-12-31',
			},
		];

		const currentYear = DateTime.local().year;
		const twoYearsAfterCurrentYear = DateTime.local().plus({ year: 2 }).year;

		// Dynamically add options from 2020 to two years after the current year
		for (let year = 2020; year <= twoYearsAfterCurrentYear; year++) {
			yearDateRangeOptions.push({
				displayName: year <= currentYear ? `Calendar Year ${year}` : `Calendar Year (${year})`,
				startDate: DateTime.local(year).startOf('year').toISODate(),
				endDate: DateTime.local(year).endOf('year').toISODate(),
			});
		}
		return yearDateRangeOptions;
	}

	get hasHogProducerRole() {
		return true;
	}

	get activeContracts() {
		const _activeContracs = this.model.contracts.data?.SwineLivestockGroupContracts.filter(
			(contract) => new Date(contract.startDate) < this.dateToday && new Date(contract.endDate) > this.dateToday
		);
		return this.filterDisplayArray(_activeContracs ?? []);
	}

	get upcomingContracts() {
		const _upcomingContracts = this.model.contracts.data?.SwineLivestockGroupContracts.filter(
			(contract) => new Date(contract.startDate) > this.dateToday
		);
		return this.filterDisplayArray(_upcomingContracts ?? []);
	}

	get expiredContracts() {
		const _expiredContracts = this.model.contracts.data?.SwineLivestockGroupContracts.filter(
			(contract) => new Date(contract.endDate) < this.dateToday
		);
		return this.filterDisplayArray(_expiredContracts ?? []);
	}

	get displayedContracts() {
		switch (this.activeTab?.label) {
			case 'Active Contracts':
				return this.activeContracts;
			case 'Upcoming Contracts':
				return this.upcomingContracts;
			case 'Expired Contracts':
				return this.expiredContracts;
			default:
				return this.activeContracts;
		}
	}

	get contractTypes(): UiTab[] {
		return [
			{ label: 'Active Contracts', tag: { status: 'positive', text: this.activeContracts.length.toString() } },
			{ label: 'Upcoming Contracts', tag: { color: 'lemon', text: this.upcomingContracts.length.toString() } },
			{ label: 'Expired Contracts', tag: { color: 'orange', text: this.expiredContracts.length.toString() } },
		];
	}

	get tabsComparatorFn() {
		return (tab1: UiTab, tab2: UiTab) => {
			return tab1.label === tab2.label ? true : false;
		};
	}

	get activeTab() {
		return this._activeTab ?? this.contractTypes[0];
	}

	set activeTab(tab) {
		this._activeTab = tab;
		this.dropdownOpen = false;
	}

	get yearFilterDate() {
		return {
			startDate: this.yearStartDate,
			endDate: this.yearEndDate,
		};
	}

	@action
	setYearFilterDate(value: { startDate?: string; endDate?: string }) {
		if (value.startDate) this.yearStartDate = value.startDate;
		if (value.endDate) this.yearEndDate = value.endDate;
	}

	@action
	getStartYear(date: string) {
		return date.substring(date.length - 4);
	}

	@action
	getEndYear(date: string) {
		return date.substring(date.length - 4);
	}

	@action
	getPricingStructure(forecastPricingType: TypeOfContractPricing, basisPricingType: TypeOfContractBasis, forecastPricingValue: number) {
		let stringToConcat: string = '';
		if (basisPricingType && basisPricingType == TypeOfContractBasis.Percent) {
			stringToConcat = forecastPricingValue * 100 + '%';
		} else {
			stringToConcat = forecastPricingValue >= 0 ? '$' + forecastPricingValue : '-$' + forecastPricingValue * -1;
		}
		return `${TYPE_OF_CONTRACT_PRICING_LABELS[forecastPricingType as keyof typeof TYPE_OF_CONTRACT_PRICING_LABELS]}\n${stringToConcat}`;
	}

	@action
	filterDisplayArray(contracts: SwineLivestockGroupContract[]): SwineLivestockGroupContract[] {
		return contracts.filter((contract: any) => {
			return new Date(contract.startDate) <= new Date(this.yearEndDate) && new Date(contract.endDate) >= new Date(this.yearStartDate);
		});
	}

	@action
	toggleDropdown(contract: any) {
		if (this.activeContract == contract) {
			this.dropdownOpen = !this.dropdownOpen;
		} else {
			if (this.dropdownOpen) {
				//if a card is already expanded, close it first
				this.dropdownOpen = !this.dropdownOpen;
			}
			this.activeContract = contract;
			this.activeContractComment = this.activeContract?.note;
			this._previousContractComment = this.activeContractComment;
			this.dropdownOpen = !this.dropdownOpen;
		}
	}

	@action
	disableTextArea() {
		this.isTextAreaDisabled = true;
	}

	@action
	cancelComment() {
		this.activeContractComment = this._previousContractComment;
		this.disableTextArea();
	}

	@action
	editComment() {
		this.isTextAreaDisabled = false;
		document.getElementById(this.activeContract?.name + '-comment-text-area')?.focus();
	}

	@action
	async updateComment() {
		if (this.activeContract) {
			await this.updateswinelivestockgroupcontractcommentmutation.mutate({
				id: this.activeContract.id,
				data: {
					note: this.activeContractComment,
				},
			});
		}
	}

	@action
	toggleShowError() {
		this.showError = !this.showError;
	}

	@action
	addContract() {
		this.businessId = this.model.businessId;
	}

	@action
	deleteContract() {
		this.contractToDelete = this.activeContract;
	}

	@action
	uploadContractPDF() {
		//placeholder until pdf functionality is implemented
		this.contractToUploadPDF = '1234';
	}

	@action
	closeDropdown() {
		this.dropdownOpen = false;
	}
}
