import FuturePosition from '../custom-models/FuturePosition';
import OptionPosition from '../custom-models/OptionPosition';

export default function transactionsToPositions(owner, trades) {
	let tradesById = {};

	trades.forEach((trade) => {
		if (tradesById[trade.Instrument.id]) {
			tradesById[trade.Instrument.id].push(trade);
		} else {
			tradesById[trade.Instrument.id] = [trade];
		}
	});

	return Object.values(tradesById).map((_trades) => {
		const instrument = _trades[0].Instrument;

		if (instrument.__typename === 'Future') {
			return new FuturePosition(owner, _trades);
		}

		if (instrument.__typename === 'Option') {
			return new OptionPosition(owner, _trades);
		}

		return new this(owner, _trades);
	});
}
