import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='text-white text-sm font-bold rounded-md px-2.5 text-center w-min {{this.backgroundColor}}'>\n\t<IntlNumberFormat @value={{(or @value 0)}} @style='percent' @maximumFractionDigits={{0}} />\n</div>", {"contents":"<div class='text-white text-sm font-bold rounded-md px-2.5 text-center w-min {{this.backgroundColor}}'>\n\t<IntlNumberFormat @value={{(or @value 0)}} @style='percent' @maximumFractionDigits={{0}} />\n</div>","moduleName":"vault-client/components/percent-insured-badge.hbs","parseOptions":{"srcName":"vault-client/components/percent-insured-badge.hbs"}});
import Component from '@glimmer/component';

interface PercentInsuredBadgeArgs {
	value: number;
}

export default class PercentInsuredBadge extends Component<PercentInsuredBadgeArgs> {
	get backgroundColor() {
		if (this.args.value <= 0) {
			return 'bg-brand-gray-60';
		} else if (this.args.value < 1.2) {
			return 'bg-brand-blue-60';
		} else if (this.args.value < 1.35) {
			return 'bg-brand-blue-70';
		} else {
			return 'bg-red-700';
		}
	}
}
