import Controller from '@ember/controller';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import ENV from 'vault-client/config/environment';
import { queryManager } from 'ember-apollo-client';
import query from 'vault-client/graphql/queries/search.graphql';
import accountQuery from 'vault-client/graphql/queries/account-get.graphql';
import customerQuery from 'vault-client/graphql/queries/customer-get.graphql';
import locationQuery from 'vault-client/graphql/queries/location-get.graphql';
import { tracked } from '@glimmer/tracking';
import checkStorageAvailable from 'vault-client/utils/check-storage-available';
import ApplicationScope from 'vault-client/services/application-scope';
import RouterService from '@ember/routing/router-service';
import { SearchItem } from 'vault-client/types/graphql-types';
import { ErrorHandler } from '@apollo/client/link/error';
import AuthService from 'vault-client/services/auth';
import PermissionsService from 'vault-client/services/permissions';
import AccessibilityService from 'vault-client/services/accessibility';
import { analyticsCustomTracking } from 'vault-client/utils/analytics-tracking';

export default class ApplicationController extends Controller {
	@service features: any;
	@service declare permissions: PermissionsService;
	@service declare auth: AuthService;
	@service declare router: RouterService;
	@service declare applicationScope: ApplicationScope;
	@service media: any;
	@service errorHandler!: ErrorHandler;
	@service declare accessibility: AccessibilityService;

	@queryManager apollo: any;

	queryParams = ['globalCustomerId', 'locationId'];

	@tracked isLoading = false;
	@tracked userProfileSize = 'default';
	@tracked organizations = [];
	@tracked showMobileSearch = false;
	openMobileSearchMQL: null | MediaQueryList = null;

	get accessibilityModeEnabled(): boolean {
		return this.accessibility.isAccessibilityModeEnabled;
	}

	get containerWidth(): string {
		return this.accessibilityModeEnabled ? 'max-w-screen-accessibility' : 'max-w-screen-2xl';
	}

	get sidenavHeaderTooltipDestination() {
		return document.getElementById('tool-tip-destination');
	}

	@action
	openMobileSearch() {
		this.showMobileSearch = true;

		//@ts-ignore
		this.openMobileSearchMQL = window.matchMedia(`(max-width: ${parseInt(ENV.APP.screenSize.medium, 10) - 1}px)`);
		this.openMobileSearchMQL.addEventListener(
			'change',
			(e) => {
				//@ts-ignore
				if (!e.match) {
					this.closeMobileSearch();
					this.openMobileSearchMQL = null;
				}
			},
			{ once: true },
		);
	}

	@action
	closeMobileSearch() {
		this.showMobileSearch = false;
	}

	@action
	async invalidateSession() {
		this.auth.logout();
	}

	get idToken() {
		return this.auth.idToken;
	}

	get isAuthenticated() {
		return this.auth.isAuthenticated;
	}

	get userName() {
		return this.permissions.userName ?? '';
	}

	@action
	structureSearchResults(searchResults: any) {
		const map = new Map();

		// Move products to the front of the array so they are displayed above the other types
		const sortedSearchResults = searchResults.slice().sort((a: any, b: any) => {
			if (a.type === 'Product') {
				return -1;
			} else if (b.type === 'Product') {
				return 1;
			}
			return 0;
		});

		sortedSearchResults.forEach((item: any) => {
			let type;
			switch (item.type) {
				case 'Customer':
					type = 'Business';
					break;
				case 'Location':
					type = 'Operation';
					break;
				default:
					type = item.type;
					break;
			}

			if (map.has(type)) {
				map.get(type).push({ ...item });
			} else {
				map.set(type, [{ ...item }]);
			}
		});

		return map;
	}

	@action
	async fetchSearchResults(searchText: any) {
		const variables = {
			query: searchText,
			typesToInclude: ['BrokerageAccount', 'Customer', 'Organization', 'SwapAccount', 'Product', 'InsurancePolicy'],
		};
		return (await this.apollo.watchQuery({ query, variables })).Search;
	}

	@action
	handleBackBtnClick(param: any, route: any, handleResultClick: any) {
		//@ts-ignore
		this[param] = undefined;
		this.router.transitionTo(route);
		handleResultClick();
	}

	@action
	getRouteModels(type: string, item: SearchItem): string[] {
		// If these item are returned by the search API, user should have access
		if (type === 'Product' || type === 'Business' || type === 'Organization') {
			return [item.id];
		}

		const customerId = item.attributes.customerId;

		// If customerId is null, then ownerId contains organizationId
		if (!customerId) {
			return [item.attributes.ownerId, item.id];
		}

		// Ensure user can access the desired customer
		if (this.permissions.canAccessCustomer(customerId)) {
			return [item.attributes.customerId, item.id];
		}

		// Fallback organization to current organizationId in application scope, or first Org the user has access to
		const fallbackOrganizationId = this.applicationScope.organizationId ?? this.permissions.firstMemberOrganization;
		if (fallbackOrganizationId) {
			return [fallbackOrganizationId, item.id];
		}

		throw new Error('Could not find fallback organization in getRouteModels. This may be a permission issue.');
	}

	@action
	getRoutePath(type: string, customerId: string) {
		const routePrefix = customerId && this.permissions.canAccessCustomer(customerId) ? 'businesses.business' : 'organizations.organization';

		switch (type) {
			case 'BrokerageAccount':
				return routePrefix + '.account';
			case 'SwapAccount':
				return routePrefix + '.account';
			case 'InsurancePolicy':
				return routePrefix + '.insurance-policy';
			case 'Business':
				return 'businesses.business.dashboard';
			case 'Organization':
				return 'organizations.organization.details-and-members';
			case 'Product':
				return 'markets.product';
		}

		console.warn(`Type ${type} not handled in getRoutePath`);
		return 'index';
	}

	@action
	async setScope(type: any, itemId: any, handleResultClick: any) {
		if (type === 'Business') {
			const variables = { id: itemId };
			const { organizationId } = (
				await this.apollo.watchQuery({
					query: customerQuery,
					variables,
				})
			).Customer;

			if (this.applicationScope.organizationId && organizationId !== this.applicationScope.organizationId) {
				// if orgId set (org permissioned uers) and orgIds do not match, set to item orgId
				this.applicationScope.organizationId = organizationId;
			}
			if (this.applicationScope.locationId) {
				// if locationId set, reset location scope
				this.applicationScope.locationId = null;
			}

			// set globalCustomerId
			this.applicationScope.globalCustomerId = itemId;
		} else if (type === 'Organization') {
			this.applicationScope.organizationId = itemId;
			this.applicationScope.globalCustomerId = null;
			this.applicationScope.locationId = null;
		} else if (type === 'BrokerageAccount' || type === 'SwapAccount') {
			const variables = { id: itemId };
			const Result = (await this.apollo.watchQuery({ query: accountQuery, variables })).Account;

			if (this.applicationScope.organizationId && this.applicationScope.organizationId !== Result?.Owner?.id) {
				// if orgId set (org permissioned uers) and orgIds do not match, set to new orgId and reset customerId and locationId
				this.applicationScope.organizationId = Result?.Owner?.id ?? this.applicationScope.organizationId;
				if (this.applicationScope.globalCustomerId) {
					this.applicationScope.globalCustomerId = null;
				}
				if (this.applicationScope.locationId) {
					this.applicationScope.locationId = null;
				}
			} else if (
				this.applicationScope.globalCustomerId &&
				(!Result?.Customer || Result?.Customer.id !== this.applicationScope.globalCustomerId)
			) {
				// if globalCustomerId set (orgId not present or matches so not in previous else if)
				// and account does not belong to customer or searched for account does not belong to current customer scope
				// reset globalCustomerId (and locationId if present) to move up a scope
				this.applicationScope.globalCustomerId = null;
				if (this.applicationScope.locationId) {
					this.applicationScope.locationId = null;
				}
			} else if (this.applicationScope.locationId) {
				// if locationId set (orgId not present or matches and globalCustomerId present but mathces so not in previous else ifs)
				this.applicationScope.locationId = null;
			}
		} else if (type === 'Operation') {
			const variables = { id: itemId };
			const result = (await this.apollo.watchQuery({ query: locationQuery, variables })).Location;

			if (this.applicationScope.organizationId && this.applicationScope.organizationId !== result?.Customer.organizationId) {
				// if orgId set (org permissioned uers) and orgIds do not match
				this.applicationScope.organizationId = result?.Customer.organizationId;
			}
			if (this.applicationScope.globalCustomerId !== result?.customerId) {
				// customerIds do not match
				this.applicationScope.globalCustomerId = result?.customerId;
			}

			// set locationId
			this.applicationScope.locationId = itemId;
		}

		//tracks search was used and result value
		analyticsCustomTracking('Search Used', {
			'Global Search': `${type} ${itemId}`,
		});

		handleResultClick();
	}

	@action
	isMyDataActive() {
		return !this.isMarketsActive() && !this.isVGSActive() && this.router.currentRouteName != 'not-found';
	}

	@action
	isMarketsActive() {
		const activeRouteNames = [
			'markets',
			'markets.product',
			'markets.product.futures.index',
			'markets.product.futures.future',
			'markets.product.overview',
			'markets.product.cme-spot',
		];
		const currentRouteName = this.router.currentRouteName;
		return activeRouteNames.some((activeRouteName) => {
			return activeRouteName === currentRouteName;
		});
	}

	@action
	isVGSActive() {
		const currentRouteName = this.router.currentRouteName;

		const baseRoute = currentRouteName.split('.').firstObject;

		return baseRoute === 'vgs';
	}

	@action
	setOrganizationId(id: any) {
		this.applicationScope.organizationId = id;

		if (checkStorageAvailable('localStorage')) {
			window.localStorage.setItem('organizationId', id);
		}

		this.router.transitionTo('index');
	}

	@action
	setInitialOrganizationId() {
		if (this.applicationScope.organizationId) return;

		let storedOrganiztionId: string | null = null;

		if (checkStorageAvailable('localStorage')) {
			const tempStoredOrganiztionId = window.localStorage.getItem('organizationId');
			if (tempStoredOrganiztionId && this.permissions.canAccessOrganization(tempStoredOrganiztionId)) {
				storedOrganiztionId = tempStoredOrganiztionId;
			}
		}

		this.applicationScope.organizationId = storedOrganiztionId || this.permissions.firstMemberOrganization;
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'application-controller': ApplicationController;
	}
}
