import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<IntlNumberFormat\n\t@value={{this.price}}\n\t@minimumFractionDigits={{this.fractionDigits}}\n\t@currency={{@currency}}\n\t@style={{@style}}\n\t@maximumFractionDigits={{this.fractionDigits}}\n/>", {"contents":"<IntlNumberFormat\n\t@value={{this.price}}\n\t@minimumFractionDigits={{this.fractionDigits}}\n\t@currency={{@currency}}\n\t@style={{@style}}\n\t@maximumFractionDigits={{this.fractionDigits}}\n/>","moduleName":"vault-client/components/price.hbs","parseOptions":{"srcName":"vault-client/components/price.hbs"}});
import Component from '@glimmer/component';

interface Args {
	price: any;
	displayFactor: any;
	fractionDigits: any;
}

export default class PriceComponent extends Component<Args> {
	get price() {
		const price = this.args.price;

		if (this.args.price === undefined) {
			throw Error('The price argument of PriceComponent is required.');
		}

		//
		//	If a displayFactor is included, use it to modify the price.
		//
		if (this.args.displayFactor) {
			return this.args.displayFactor * price;
		}

		return price;
	}

	get fractionDigits() {
		return this.args.fractionDigits;
	}
}
