import Component from '@glimmer/component';

interface VaultTableEmptyStateTextBlockArgs {}

export default class VaultTableEmptyStateTextBlock extends Component<VaultTableEmptyStateTextBlockArgs> {
	_defaultHeaderMessage = 'No Data Items Found';

	get defaultHeaderMessage(): string {
		return this._defaultHeaderMessage;
	}
}
