import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @hedgeStrategy}}\n\t<Vault::UiConfirmation as |confirmation|>\n\t\t<confirmation.title>Remove hedge strategy</confirmation.title>\n\t\t<confirmation.body>Are you sure that you want to remove this hedge strategy?\n\t\t\t<br />\n\t\t\t<span class='font-sans-semibold'>\n\t\t\t\t{{@hedgeStrategy.name}}\n\t\t\t</span>\n\t\t\t<br />\n\t\t\tYou cannot undo this action\n\t\t</confirmation.body>\n\t\t<confirmation.cancel @style='plain' {{on 'click' this.closeConfirmation}}>Cancel</confirmation.cancel>\n\t\t<confirmation.action\n\t\t\t@style='destructive'\n\t\t\t@disabled={{this.submitting}}\n\t\t\t{{on 'click' this.removeHedgeStrategy}}\n\t\t>Remove</confirmation.action>\n\t</Vault::UiConfirmation>\n{{/if}}", {"contents":"{{#if @hedgeStrategy}}\n\t<Vault::UiConfirmation as |confirmation|>\n\t\t<confirmation.title>Remove hedge strategy</confirmation.title>\n\t\t<confirmation.body>Are you sure that you want to remove this hedge strategy?\n\t\t\t<br />\n\t\t\t<span class='font-sans-semibold'>\n\t\t\t\t{{@hedgeStrategy.name}}\n\t\t\t</span>\n\t\t\t<br />\n\t\t\tYou cannot undo this action\n\t\t</confirmation.body>\n\t\t<confirmation.cancel @style='plain' {{on 'click' this.closeConfirmation}}>Cancel</confirmation.cancel>\n\t\t<confirmation.action\n\t\t\t@style='destructive'\n\t\t\t@disabled={{this.submitting}}\n\t\t\t{{on 'click' this.removeHedgeStrategy}}\n\t\t>Remove</confirmation.action>\n\t</Vault::UiConfirmation>\n{{/if}}","moduleName":"vault-client/components/remove-hedge-strategy-modal.hbs","parseOptions":{"srcName":"vault-client/components/remove-hedge-strategy-modal.hbs"}});
import Component from '@glimmer/component';
import { gql, useMutation } from 'glimmer-apollo';
import { Mutation } from 'vault-client/types/graphql-types';
import { action } from '@ember/object';

interface RemoveHedgeStrategyModalArgs {
	hedgeStrategy: {
		id: string;
		name: string;
	};
	closeModalFn: () => void;
	showError: () => void;
	model: any;
}

const DELETE_HEDGE_STRATEGY = gql`
	mutation deleteHedgeStrategy($id: String!) {
		deleteHedgeStrategy(id: $id) {
			id
		}
	}
`;

export default class RemoveHedgeStrategyComponent extends Component<RemoveHedgeStrategyModalArgs> {
	deleteHedgeStrategy = useMutation<Mutation['deleteHedgeStrategy'], { id: string }>(this, () => [
		DELETE_HEDGE_STRATEGY,
		{
			update: (cache) => {
				cache.evict({ fieldName: 'HedgeStrategy' });
				cache.evict({ fieldName: 'HedgeStrategies' });
				cache.evict({ fieldName: 'HedgeStrategyCount' });
				cache.gc();
			},
			onComplete: (): void => {
				this.closeConfirmation();
			},
			onError: (error): void => {
				console.error('Error while attempting to delete Permission Group', error.message);
				this.args.showError();
				this.closeConfirmation();
			},
		},
	]);

	@action
	async removeHedgeStrategy() {
		await this.deleteHedgeStrategy.mutate({ id: this.args.hedgeStrategy.id });
	}

	@action
	closeConfirmation() {
		this.args.closeModalFn();
	}
}
