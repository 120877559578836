import { tracked } from '@glimmer/tracking';

export default class BrokerageAccount {
	@tracked tradeDate;
	@tracked price;
	@tracked quantity;

	@tracked Instrument;

	cash_balance: any;
	open_trade_equity: any;
	options_market_value: any;

	constructor(trade: any) {
		this.tradeDate = trade.tradeDate;
		this.quantity = trade.quantity;
		this.price = trade.price;

		this.Instrument = trade.Instrument;
	}

	get netLiq() {
		return this.cash_balance + this.open_trade_equity + this.options_market_value;
	}
}
