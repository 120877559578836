import Route from '@ember/routing/route';
import { tracked } from '@glimmer/tracking';
import { gql, useQuery } from 'glimmer-apollo';
import {
	AllocatedForecastedHedgedAndCappedVolumeFilterDTO,
	FeedIngredientForecastedUsageFilterDTO,
	ProductFilterDTO,
	Query,
	TypeOfAllocatedHedge,
	TypeOfInstrument,
	TypeOfOption,
} from 'vault-client/types/graphql-types';
import { FeedIngredientBase } from 'vault-client/types/vault-client';

const FEED_OVERVIEW_DETAILS_QUERY = gql`
	query AggregateFeedIngredientForecastedUsages(
		$feedIngredientsWhere: FeedIngredientForecastedUsageFilterDTO
		$forecastedHedgedAndCappedVolumeWhere: AllocatedForecastedHedgedAndCappedVolumeFilterDTO
		$productsWhere: ProductFilterDTO
		$entityId: String!
	) {
		AggregateFeedIngredientForecastedUsages(
			where: $feedIngredientsWhere
			calc: { sum: { dmiUsageInTons: true } }
			groupBy: {
				month: true
				year: true
				FeedIngredient: { id: true, dryMatterPercent: true, FeedCategory: { HedgeProduct: { slug: true } } }
			}
			scopeId: $entityId
		) {
			year
			month
			sum {
				dmiUsageInTons
			}
			FeedIngredient {
				id
				dryMatterPercent
				FeedCategory {
					HedgeProduct {
						slug
					}
				}
			}
		}
		AggregateAllocatedForecastedHedgedAndCappedVolumes(
			calc: { sum: { naturallyShortHedged: true } }
			groupBy: { date: true, instrumentType: true, optionType: true, Product: { slug: true } }
			where: $forecastedHedgedAndCappedVolumeWhere
		) {
			optionType
			instrumentType
			date
			sum {
				naturallyShortHedged
			}
			Product {
				slug
			}
		}
		Products(where: $productsWhere) {
			id
			slug
		}
	}
`;

type feedOverviewDetailsQuery = {
	__typename?: 'Query';
	AggregateFeedIngredientForecastedUsages: Query['AggregateFeedIngredientForecastedUsages'];
	AggregateAllocatedForecastedHedgedAndCappedVolumes: Query['AggregateAllocatedForecastedHedgedAndCappedVolumes'];
	Products: Query['Products'];
};

type FeedOverviewDetailsQuery_Args = {
	feedIngredientsWhere?: FeedIngredientForecastedUsageFilterDTO;
	forecastedHedgedAndCappedVolumeWhere?: AllocatedForecastedHedgedAndCappedVolumeFilterDTO;
	productsWhere?: ProductFilterDTO;
	entityId?: string;
};

export default class FeedOverviewDetailRoute extends Route {
	queryParams = {
		startDate: { refreshModel: true },
		endDate: { refreshModel: true },
	};

	@tracked variables: FeedOverviewDetailsQuery_Args = {};

	// See `update-feed-usage-button` for comment on fetchPolicy
	getFeedDetails = useQuery<feedOverviewDetailsQuery, FeedOverviewDetailsQuery_Args>(this, () => [
		FEED_OVERVIEW_DETAILS_QUERY,
		{
			variables: this.variables,
			fetchPolicy: 'network-only',
		},
	]);

	templateName = 'feed/overview/detail';

	generateProductsWhere(ingredientBase: FeedIngredientBase): ProductFilterDTO {
		let productSlug: string | null = null;

		if (ingredientBase === FeedIngredientBase.Corn) {
			productSlug = 'grain-corn';
		} else if (ingredientBase === FeedIngredientBase.SoybeanMeal) {
			productSlug = 'grain-soybean-meal';
		}

		return {
			slug: {
				equals: productSlug,
			},
		};
	}

	generateForecastedFeedIngredientsWhere(
		ingredientBase: FeedIngredientBase,
		startDate: string,
		endDate: string
	): FeedIngredientForecastedUsageFilterDTO {
		const where: FeedIngredientForecastedUsageFilterDTO = {};
		where.date = { gte: startDate, lte: endDate };
		where.FeedIngredient = {};

		switch (ingredientBase) {
			case FeedIngredientBase.Corn:
				where.FeedIngredient.FeedCategory = {
					HedgeProduct: {
						slug: {
							equals: 'grain-corn',
						},
					},
				};
				break;

			case FeedIngredientBase.SoybeanMeal:
				where.FeedIngredient.FeedCategory = {
					HedgeProduct: {
						slug: {
							equals: 'grain-soybean-meal',
						},
					},
				};
				break;

			default:
				where.FeedIngredient.FeedCategory = {
					HedgeProduct: {
						AND: [
							{
								slug: {
									not: {
										equals: 'grain-corn',
									},
								},
							},
							{
								slug: {
									not: {
										equals: 'grain-soybean-meal',
									},
								},
							},
						],
					},
				};
		}

		return where;
	}

	generateForecastedHedgedAndCappedVolumeWhere(
		ingredientBase: FeedIngredientBase,
		entityId: string,
		startDate: string,
		endDate: string
	): AllocatedForecastedHedgedAndCappedVolumeFilterDTO {
		let productSlug: string | null = null;

		if (ingredientBase === FeedIngredientBase.Corn) {
			productSlug = 'grain-corn';
		} else if (ingredientBase === FeedIngredientBase.SoybeanMeal) {
			productSlug = 'grain-soybean-meal';
		}

		const forecastedHedgedAndCappedVolumeWhere: AllocatedForecastedHedgedAndCappedVolumeFilterDTO = {
			entityId: {
				equals: entityId,
			},
			hedgeType: {
				equals: TypeOfAllocatedHedge.Brokerage,
			},
			instrumentType: {
				in: [TypeOfInstrument.Future, TypeOfInstrument.Option],
			},
			OR: [
				{
					optionType: {
						equals: null,
					},
				},
				{
					optionType: {
						equals: TypeOfOption.Call,
					},
				},
			],
			date: {
				gte: startDate,
				lte: endDate,
			},
		};

		if (productSlug) {
			forecastedHedgedAndCappedVolumeWhere.Product = {
				slug: {
					equals: productSlug,
				},
			};
		} else {
			forecastedHedgedAndCappedVolumeWhere.productId = { equals: null };
		}

		return forecastedHedgedAndCappedVolumeWhere;
	}
}
