import Fill from 'vault-client/models/vgs/fill';
import {
	CustomerEntity,
	GrainCropPlan,
	GrainFeedPlan,
	GrainFillOrder,
	GrainTargetOrder,
	TypeOfGrainOrder,
} from 'vault-client/types/graphql-types';

export default class Plan {
	id: string;
	breakEven: number | null;
	goal: number | null;
	Customer: CustomerEntity | null;
	Fills: Fill[];
	Targets: GrainTargetOrder[];
	hasWriteAccess: boolean;

	constructor(plan: GrainCropPlan | GrainFeedPlan) {
		this.id = plan.id;
		this.breakEven = plan.breakEven ?? null;
		this.goal = plan.goal ?? null;

		this.Customer = plan.Customer ?? null;

		this.Fills = plan.Orders.filter((order): order is GrainFillOrder => order.type === TypeOfGrainOrder.Fill).map(
			(fill) => new Fill(fill, null, null, null, null, null, null)
		);
		this.Targets = plan.Orders.filter((order): order is GrainTargetOrder => order.type === TypeOfGrainOrder.Target);
		this.hasWriteAccess = plan.hasWriteAccess;
	}

	get fillsWithBasisPrices() {
		return this.Fills.filter((fill: Fill) => {
			return fill.basis;
		});
	}

	get fillsWithFuturePrices() {
		return this.Fills.filter((fill: Fill) => {
			return fill.futurePrice;
		});
	}

	get fillsWithFlatPrices() {
		return this.Fills.filter((fill: Fill) => {
			return fill.flatPrice;
		});
	}

	get averageFuturePriceSold() {
		let revenue = 0;
		let bushels = 0;

		this.fillsWithFuturePrices.forEach((fill: Fill) => {
			bushels += fill.bushels ? fill.bushels : 0;
			revenue += fill.bushels && fill.futurePrice ? fill.bushels * fill?.futurePrice : 0;
		});

		if (bushels) {
			return revenue / bushels;
		} else {
			return null;
		}
	}

	get averageFlatPriceSold() {
		let revenue = 0;
		let bushels = 0;

		this.fillsWithFlatPrices.forEach((fill: Fill) => {
			bushels += fill.bushels ? fill.bushels : 0;
			revenue += fill.bushels && fill.flatPrice ? fill.bushels * fill.flatPrice : 0;
		});

		if (bushels) {
			return revenue / bushels;
		} else {
			return null;
		}
	}

	get averageBasis() {
		let revenue = 0;
		let bushels = 0;

		this.fillsWithBasisPrices.forEach((fill: Fill) => {
			bushels += fill.bushels ? fill.bushels : 0;
			revenue += fill.bushels && fill.basis ? fill.bushels * fill.basis : 0;
		});

		if (bushels) {
			return revenue / bushels;
		} else {
			return null;
		}
	}

	get sumGrossRevenue() {
		if (this.Fills) {
			return this.Fills?.reduce((acc: any, cur: any) => {
				return acc + cur.grossRevenue;
			}, 0);
		}
	}

	get bushelsFilled() {
		return this.Fills.reduce((acc: any, cur: any) => {
			return acc + cur.bushels;
		}, 0);
	}
}
