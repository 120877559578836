import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='mb-2' id={{this.legendId}}></div>\n<div class='relative' ...attributes>\n\t<Chart\n\t\t@type='line'\n\t\t@chartData={{this.chartData}}\n\t\t@options={{this.chartOptions}}\n\t\t@plugins={{this.plugins}}\n\t\t@data={{@data}}\n\t\t@id={{this.chartId}}\n\t\t@updateChart={{if this.fullUpdate this.fullChartUpdate undefined}}\n\t/>\n</div>", {"contents":"<div class='mb-2' id={{this.legendId}}></div>\n<div class='relative' ...attributes>\n\t<Chart\n\t\t@type='line'\n\t\t@chartData={{this.chartData}}\n\t\t@options={{this.chartOptions}}\n\t\t@plugins={{this.plugins}}\n\t\t@data={{@data}}\n\t\t@id={{this.chartId}}\n\t\t@updateChart={{if this.fullUpdate this.fullChartUpdate undefined}}\n\t/>\n</div>","moduleName":"vault-client/components/line-with-markers-chart.hbs","parseOptions":{"srcName":"vault-client/components/line-with-markers-chart.hbs"}});
import Component from '@glimmer/component';
import { Chart, ChartData, ChartOptions, ScriptableTooltipContext } from 'chart.js';
import { guidFor } from '@ember/object/internals';
import { action } from '@ember/object';

interface LineWithMarkersChartArgs {
	id: string;
	data: { [xLabel: string]: (number | null)[] | null };
	labels: string[];
	xScaleType?: 'linear' | 'logarithmic' | 'category' | undefined;
	xScaleOptions?: Record<string, any>;
	yScaleType?: 'linear' | 'logarithmic' | 'category' | undefined;
	yScaleOptions?: Record<string, any>;
	colors?: string[];
	selectedLabel?: string | number;
	legendSubheading?: string | number;
	tooltipSubheading?: string | number;
	drawOrder?: number[];
	legendId?: string;
	fullUpdate?: boolean;
	tooltipTitleCallback?: (_title: string) => string;
	tooltipValueFormatCallback?: (value: unknown) => string;
}

const DEFAULT_CHART_COLORS = ['#0068C9', '#019F9F', '#DF722C', '#F6BD41', '#4E994B', '#A56EFF'];

export default class LineWithMarkersChart extends Component<LineWithMarkersChartArgs> {
	constructor(owner: any, args: LineWithMarkersChartArgs) {
		super(owner, args);
	}

	get colors(): string[] {
		return this.args.colors ?? DEFAULT_CHART_COLORS;
	}

	get chartId(): string {
		return this.args.id ?? `line-with-markers-chart-${guidFor(this)}`;
	}

	get legendId(): string {
		return this.args.legendId ?? `line-with-markers-chart-legend-${guidFor(this)}`;
	}

	get plugins() {
		const chartId = this.chartId;
		const legendId = this.legendId;
		const selectedLabel = this.args.selectedLabel;
		const legendSubheading = this.args.legendSubheading;
		return [
			{
				afterUpdate: function (chart: Chart) {
					function toggleDataset(event: PointerEvent): void {
						const index = (event.currentTarget as HTMLElement).dataset.index;
						if (index) {
							const meta = chart.getDatasetMeta(+index);
							meta.hidden = !meta.hidden ? true : false;
							chart.update();
						}
					}

					// Make sure we're applying the legend to the right chart
					if (chart.canvas.id === chartId) {
						const legend = document.getElementById(legendId)!;
						const ul = legend?.querySelector('ul') ?? document.createElement('ul');
						ul.classList.add('flex');

						// Remove old legend items
						while (ul.firstChild) {
							ul.firstChild.remove();
						}

						while (legend.firstChild) {
							legend.firstChild.remove();
						}

						const selectedLabelIndex = selectedLabel ? chart.data?.labels?.findIndex((v) => v === selectedLabel) : null;

						chart.data.datasets.forEach((dataset, index) => {
							const legendItem = document.createElement('li');
							legendItem.classList.add('flex', 'flex-col', 'items-center', 'mr-6', 'text-brand-blue-80', 'cursor-pointer');

							// Strike out legend item if hidden
							const meta = chart.getDatasetMeta(index);
							if (meta.hidden) {
								legendItem.classList.add('line-through');
							}

							legendItem.dataset.index = index.toString(10);
							legendItem.onclick = toggleDataset;

							legendItem.innerHTML += `
									<div class="text-sm flex items-center">
										<span style="background-color: ${dataset.backgroundColor}" class="w-2 h-2 rounded-full inline-block mr-1"></span>
										${dataset.label}
									<div/>
										${
											selectedLabelIndex
												? `
												<div class="ml-4 font-bold text-brand-dark">
													${dataset.data[selectedLabelIndex]}
												</div>
												`
												: ``
										}
							`;

							ul.append(legendItem);
						});

						if (legendSubheading) {
							const subheader = document.createElement('div');
							subheader.innerHTML = `
								<div class="text-xs" style="color: #8C8D8E;">
									${legendSubheading}
								</div>
							`;
							legend?.appendChild(subheader);
						}

						return legend?.insertBefore(ul, legend.firstChild);
					}
					return;
				},
			},
		];
	}

	get labels() {
		return this.args.labels;
	}

	get fullUpdate() {
		return this.args.fullUpdate != null ? this.args.fullUpdate : true;
	}

	get datasetLabels() {
		const labelSet = new Set();
		Object.keys(this.args.data ?? {}).forEach((datasetLabel) => {
			labelSet.add(datasetLabel);
		});

		return Array.from(labelSet.values());
	}

	@action
	fullChartUpdate(chart: Chart<'line'>) {
		// Tries to avoid fully updating the chart if the labels and datasets have not changed. Full refreshes (setting chartData) causes the
		// hidden datasets to reapply and for the chart to "jump"
		const newDatasetLabels = this.chartData.datasets.map((dataset) => dataset.label).sort();
		const currentDatasetLabels = chart.data.datasets.map((dataset) => dataset.label).sort();
		const currentChartLabels = chart.data.labels ?? [];
		const newChartLabels = this.chartData.labels ?? [];

		let fullUpdate = false;

		// Check if dataset names are different
		if (newDatasetLabels.length !== currentDatasetLabels.length || newDatasetLabels.some((x, index) => x !== currentDatasetLabels[index])) {
			fullUpdate = true;
		}

		// Check if chart labels are different
		if (newChartLabels.length !== currentChartLabels.length || newChartLabels.some((x, index) => x !== currentChartLabels[index])) {
			fullUpdate = true;
		}

		if (fullUpdate) {
			chart.data = this.chartData;
		} else {
			chart.data.datasets.forEach((dataset) => {
				dataset.data = this.args.data[dataset.label as string] ?? [];
			});
		}

		chart.update('none');
	}

	get chartData(): ChartData<'line'> {
		const labels = this.labels;
		const datasets = Object.keys(this.args.data)
			.map((datasetLabel, index) => {
				const dataset = {
					tension: 0.3,
					label: datasetLabel,
					pointRadius: 4,
					pointBackgroundColor: '#E3E3E3',
					data: this.args.data[datasetLabel],
				} as any;

				dataset.backgroundColor = this.colors[index];
				dataset.borderColor = this.colors[index];
				if (this.args.drawOrder) {
					dataset.order = this.args.drawOrder[index];
				}

				return dataset;
			})
			.sort((a, b) => (+a.label < +b.label ? -1 : 1));

		return {
			labels,
			datasets,
		};
	}

	get chartOptions(): ChartOptions {
		const getOrCreateTooltip = (chart: Chart) => {
			let tooltipEl: HTMLDivElement | null | undefined = chart.canvas.parentNode?.querySelector('div');
			if (!tooltipEl) {
				tooltipEl = document.createElement('div');
				tooltipEl.classList.add(
					'line-chart-tooltip',
					'line-chart-tooltip-bottom',
					'border',
					'border-brand-blue-80',
					'bg-white',
					'text-brand-gray-90',
					'absolute',
					'pointer-events-none',
					'transform',
					'-translate-x-2/4',
					'transition-all',
					'ease-linear',
					'duration-50',
					'opacity-1',
					'z-10',
					'text-left',
					'p-2',
					'rounded-sm'
				);

				const tooltipUL = document.createElement('ul');
				tooltipUL.classList.add('tooltipul');

				tooltipEl.append(tooltipUL);
				chart.canvas.parentNode?.append(tooltipEl);
			}
			return tooltipEl;
		};

		const customTooltip = (context: ScriptableTooltipContext<'line'>) => {
			const { chart, tooltip } = context;
			const tooltipEl = getOrCreateTooltip(chart);
			const tooltipUL = tooltipEl.querySelector('ul')!;

			if (tooltip.opacity === 0) {
				tooltipEl.classList.replace('opacity-1', 'opacity-0');
				return;
			}

			if (tooltip.body) {
				const titleLines = tooltip.title || [];
				const dataPoints = tooltip.dataPoints;
				const tooltipLI = document.createElement('li');
				tooltipLI.classList.add('whitespace-nowrap');

				// Title Loop
				titleLines.forEach((title: string) => {
					const formattedTitle = this.args.tooltipTitleCallback ? this.args.tooltipTitleCallback(title) : title;
					tooltipUL.appendChild(tooltipLI);

					const tooltipSpan = document.createElement('span');
					tooltipSpan.classList.add('text-sm', 'font-semibold', 'text-brand-blue-80', 'block');
					tooltipLI.appendChild(tooltipSpan);

					const tooltipTitle = document.createTextNode(formattedTitle);
					tooltipSpan.appendChild(tooltipTitle);

					if (this.args.tooltipSubheading) {
						const tooltipSubHeadingSpan = document.createElement('span');
						tooltipSubHeadingSpan.classList.add('text-brand-grey-60', 'text-xs', 'block');

						const tooltipSubHeading = document.createTextNode(this.args.tooltipSubheading.toString());

						tooltipSubHeadingSpan.appendChild(tooltipSubHeading);
						tooltipLI.appendChild(tooltipSubHeadingSpan);
					}
				});

				// Body Loop
				const tooltipBodyP = document.createElement('p');
				dataPoints.forEach((dataPoint, i: number) => {
					const displayBlockSpan = document.createElement('span');
					displayBlockSpan.classList.add('block', 'text-xs');
					const colors = tooltip.labelColors[i];
					const colorCircle = document.createElement('span');
					colorCircle.classList.add('rounded-full', 'w-2', 'h-2', 'inline-block', 'mr-2');
					colorCircle.style.background = colors.borderColor as string;
					colorCircle.style.border = colors.borderColor as string;

					const formattedValue = this.args.tooltipValueFormatCallback
						? this.args.tooltipValueFormatCallback(dataPoint.raw)
						: dataPoint.formattedValue;

					const textLabel = document.createTextNode(`${formattedValue} ${dataPoint.dataset.label}`);

					// Append color label and text
					displayBlockSpan.appendChild(colorCircle);
					displayBlockSpan.appendChild(textLabel);
					tooltipBodyP.appendChild(displayBlockSpan);
				});

				// Remove old children
				while (tooltipUL.firstChild) {
					tooltipUL.firstChild.remove();
				}

				// Add new children
				tooltipUL.appendChild(tooltipLI);
				tooltipLI.appendChild(tooltipBodyP);
				tooltipEl.classList.replace('opacity-0', 'opacity-1');

				// Position tooltip
				const position = context.chart.canvas.getBoundingClientRect();
				const { width: tooltipElWidth, height: tooltipElHeight } = tooltipEl.getBoundingClientRect();

				let offsetX = 0;

				if (tooltip.caretX < tooltipElWidth / 3) {
					offsetX = tooltip.caretX + tooltipElWidth / 2 + 12;

					tooltipEl.classList.remove('line-chart-tooltip-bottom', 'line-chart-tooltip-right');
					tooltipEl.classList.add('line-chart-tooltip-left');
				} else if (position.width - tooltip.caretX < tooltipElWidth / 3) {
					offsetX = tooltip.caretX - tooltipElWidth / 2 - 12;

					tooltipEl.classList.remove('line-chart-tooltip-bottom', 'line-chart-tooltip-left');
					tooltipEl.classList.add('line-chart-tooltip-right');
				} else {
					offsetX = tooltip.caretX;
					tooltipEl.classList.remove('line-chart-tooltip-right', 'line-chart-tooltip-left');
					tooltipEl.classList.add('line-chart-tooltip-bottom');
				}

				tooltipEl.style.left = offsetX + 'px';
				tooltipEl.style.top = -tooltipElHeight + 'px';
			}
		};

		const options: ChartOptions<'line'> = {
			maintainAspectRatio: false,
			spanGaps: true,
			responsive: true,
			interaction: {
				intersect: false,
				mode: 'index',
			},
			scales: {
				y: {
					ticks: {
						autoSkip: false,
						font: {
							size: 12,
						},
						color: getComputedStyle(document.documentElement).getPropertyValue('--brand-gray-80'),
					},
				},
				x: {
					ticks: {
						autoSkip: false,
						color: getComputedStyle(document.documentElement).getPropertyValue('--brand-gray-80'),
						font: {
							size: 12,
						},
					},
					grid: {
						display: false,
					},
				},
			},
			plugins: {
				legend: {
					display: false,
					labels: {
						usePointStyle: true,
						boxWidth: 2,
					},
				},
				tooltip: {
					enabled: false,
					external: customTooltip,
				},
			},
		};

		if (this.args.xScaleType) {
			options.scales!.x!.type = this.args.xScaleType;
		}

		if (this.args.xScaleOptions) {
			options.scales!.x = { ...options.scales!.x, ...this.args.xScaleOptions };
		}

		if (this.args.yScaleType) {
			options.scales!.y!.type = this.args.yScaleType;
		}

		if (this.args.yScaleOptions) {
			options.scales!.y = { ...options.scales!.y, ...this.args.yScaleOptions };
		}

		return options;
	}
}
