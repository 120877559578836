import Controller from '@ember/controller';
import { DateTime, Interval } from 'luxon';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import resetVaultTableScroll from 'vault-client/utils/reset-vault-table-scroll';
import { TableColumn, CellComponents } from 'vault-client/types/vault-table';
import { County, LocationEntity, LocationEntityCreateDTO, MilkOrder, Mutation_createLocationArgs } from 'vault-client/types/graphql-types';
import { gql, useMutation } from 'glimmer-apollo';
import { ModelFrom } from 'vault-client/utils/type-utils';
import BusinessProductionRoute from 'vault-client/routes/businesses/business/production';
import ApplicationSpinner from 'vault-client/services/application-spinner';
import { inject } from '@ember/service';

interface AggregateProduction {
	date: string;
	numberOfCowsForecasted: number;
	productionPerCowForecasted: number;
	grossButterfatForecasted: number;
	grossProteinForecasted: number;
	grossOtherSolidsForecasted: number;
	grossProductionForecasted: number;
	grossProductionActual: number;
}

export class ProductionRow {
	date: DateTime;
	numberOfCows: number;
	poundsPerCowPerDay: number;
	grossProductionForecasted: number;
	grossProductionActual: number;
	percentButterfat: number | null;
	percentProtein: number | null;
	percentOtherSolids: number | null;
	dateIso: string;

	constructor(production: AggregateProduction) {
		this.dateIso = production.date;
		this.date = DateTime.fromISO(production.date);

		this.numberOfCows = production.numberOfCowsForecasted;
		this.poundsPerCowPerDay = production.productionPerCowForecasted;
		this.grossProductionActual = production.grossProductionActual;
		this.grossProductionForecasted = production.grossProductionForecasted;
		this.percentButterfat = this.grossProductionForecasted ? production.grossButterfatForecasted / this.grossProductionForecasted : null;
		this.percentProtein = this.grossProductionForecasted ? production.grossProteinForecasted / this.grossProductionForecasted : null;
		this.percentOtherSolids = this.grossProductionForecasted
			? production.grossOtherSolidsForecasted / this.grossProductionForecasted
			: null;
	}

	get productionPerDay() {
		return this.grossProductionForecasted / this.date.daysInMonth;
	}

	get productionPerCowForecastedPerDay() {
		return this.numberOfCows == 0 ? 0 : Math.round(this.productionPerDay / this.numberOfCows);
	}

	get id() {
		return this.date;
	}
}

const CREATE_LOCATION = gql`
	mutation createLocation($data: LocationEntityCreateDTO!) {
		createLocation(data: $data) {
			id
			name
		}
	}
`;

type CreateLocationMutation = {
	__typename?: 'Mutation';
	createLocation: {
		id: string;
		name: string;
		__typename: 'Location';
	};
};

export default class BusinessesBusinessProductionController extends Controller {
	@tracked productionMonthStartDate = DateTime.local().startOf('month').toISODate();

	@tracked productionMonthEndDate = DateTime.local().plus({ months: 23 }).endOf('month').toISODate();

	@tracked selectedLocationId: string = '';

	@tracked _selectedRows: ProductionRow[] | null = [];

	@tracked selectedCounty: County | null = null;

	@tracked newLocationName: string | null = null;

	@tracked selectedMilkOrder: MilkOrder | null = null;

	@tracked errorMessage: string | null = null;

	@tracked isLoading = false;

	@inject declare applicationSpinner: ApplicationSpinner;

	milkRoutePath = 'businesses.business.milk';

	declare model: ModelFrom<BusinessProductionRoute>;

	createLocation = useMutation<CreateLocationMutation, Mutation_createLocationArgs>(this, () => [
		CREATE_LOCATION,
		{
			onComplete: (data): void => {
				this.selectedLocationId = data?.createLocation.id || '';
			},
			onError: (error) => {
				this.errorMessage = error.message;
			},
			update: (cache) => {
				cache.evict({ fieldName: 'Locations' });
				cache.evict({ fieldName: 'Location' });
				cache.gc();
			},
		},
	]);

	get columns(): TableColumn[] {
		const baseColumns: TableColumn[] = [
			{
				id: '639e2891-9ccc-4845-8124-5d64edf5db09',
				name: 'Month',
				valuePath: 'date',
				minWidth: 100,
				cellComponent: CellComponents.MonthFormat,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '91039cf4-ba2c-4d08-bb30-f98036dc8897',
				name: 'Cows',
				valuePath: 'numberOfCows',
				minWidth: 100,
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'c8e1eda3-c7e6-40ac-89d7-e45684a1aeda',
				name: 'Forecasted Production (LBS)',
				isFixed: '',
				isVisible: true,
				cellComponent: CellComponents.String,
				subcolumns: [
					{
						id: '95c6685e-6f18-4806-a010-37eea8336944',
						name: 'Cow / Day',
						valuePath: 'productionPerCowForecastedPerDay',
						minWidth: 110,
						cellComponent: CellComponents.IntlNumberFormat,
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: '3109b7bd-5849-4d0c-a2e9-cd70af0b3ca0',
						name: 'Per Day',
						valuePath: 'productionPerDay',
						minWidth: 110,
						cellComponent: CellComponents.IntlNumberFormat,
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: '5a5abe8c-2eb5-4773-9c8d-60a1c887f641',
						name: 'Total',
						valuePath: 'grossProductionForecasted',
						minWidth: 110,
						cellComponent: CellComponents.IntlNumberFormat,
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: '639e2891-9ccc-4845-8124-5d64edf5db09',
				name: 'Actual Production (LBS)',
				valuePath: 'grossProductionActual',
				minWidth: 180,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					maximumFractionDigits: 0,
					minimumFractionDigits: 0,
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'c0c30a9b-5f3c-4f52-b50d-1496353b5939',
				name: 'Components',
				isFixed: '',
				isVisible: true,
				cellComponent: CellComponents.String,
				subcolumns: [
					{
						id: 'd5160e3f-093c-4987-9eb1-35f2f02b6190',
						name: 'Butterfat',
						valuePath: 'percentButterfat',
						minWidth: 110,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'percent',
							minimumFractionDigits: '3',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: 'c398266c-7a3a-4e46-9dc5-6ad5a3520db6',
						name: 'Protein',
						valuePath: 'percentProtein',
						minWidth: 110,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'percent',
							minimumFractionDigits: '3',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: 'c4d71382-d7c0-447e-a173-8f0ed6c9da63',
						name: 'Other Solids',
						valuePath: 'percentOtherSolids',
						minWidth: 110,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'percent',
							minimumFractionDigits: '3',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
				],
			},
		];
		return baseColumns;
	}

	queryParams = ['productionMonthStartDate', 'productionMonthEndDate', 'selectedLocationId'];

	productionMonthRangeOptions = [
		{
			displayName: 'Next 24 Months',
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().plus({ months: 23 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Previous 24 Months',
			startDate: DateTime.local().minus({ months: 24 }).startOf('month').toISODate(),
			endDate: DateTime.local().endOf('month').toISODate(),
		},
		{
			displayName: 'Next 12 Months',
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().plus({ months: 11 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Previous 12 Months',
			startDate: DateTime.local().minus({ months: 12 }).startOf('month').toISODate(),
			endDate: DateTime.local().endOf('month').toISODate(),
		},
		{
			displayName: `Calendar Year (${DateTime.local().year}) `,
			startDate: DateTime.local().startOf('year').toISODate(),
			endDate: DateTime.local().endOf('year').toISODate(),
		},
	];

	get locations() {
		return this.model.getProduction.data?.Locations ?? [];
	}

	get currentLocation(): LocationEntity | undefined {
		return this.model.getProduction.data?.Locations.find((location: LocationEntity) => location.id === this.selectedLocationId);
	}

	get canEditProduction() {
		return !!this.currentLocation && this.currentLocation.CurrentUserPermissions.canWriteOperations;
	}

	get productionMonthQueryParam() {
		return {
			startDate: this.productionMonthStartDate,
			endDate: this.productionMonthEndDate,
		};
	}

	get monthsInDateRange() {
		return Interval.fromDateTimes(
			DateTime.fromISO(this.productionMonthStartDate).startOf('month'),
			DateTime.fromISO(this.productionMonthEndDate).endOf('month')
		)
			.splitBy({ month: 1 })
			.map((d) => d.start.toISODate());
	}

	get production() {
		// If we do not have any locations, return empty array to trigger table empty state
		if (this.locations.length === 0) return [];

		const aggregateProductions: Record<string, AggregateProduction> = {};

		const getOrCreateAggregateProduction = (date: string) => {
			if (!aggregateProductions[date]) {
				aggregateProductions[date] = {
					date,
					numberOfCowsForecasted: 0,
					productionPerCowForecasted: 0,
					grossButterfatForecasted: 0,
					grossProteinForecasted: 0,
					grossOtherSolidsForecasted: 0,
					grossProductionForecasted: 0,
					grossProductionActual: 0,
				};
			}

			return aggregateProductions[date];
		};

		this.model.getProduction.data?.AggregateForecastedMilkProductionByMonths.forEach((production) => {
			const date = production.date;
			if (!date) {
				console.warn('Date not found for AggregateForecastedMilkProductionByMonths');
				return;
			}

			const aggregatePosition = getOrCreateAggregateProduction(date);

			aggregatePosition.numberOfCowsForecasted = production.sum.numberOfCows ?? 0;
			aggregatePosition.productionPerCowForecasted = production.avg.averageDailyProductionPerCow ?? 0;
			aggregatePosition.grossButterfatForecasted = production.sum.grossButterfatProduction ?? 0;
			aggregatePosition.grossProteinForecasted = production.sum.grossProteinProduction ?? 0;
			aggregatePosition.grossOtherSolidsForecasted = production.sum.grossOtherSolidsProduction ?? 0;
			aggregatePosition.grossProductionForecasted = production.sum.grossProduction ?? 0;
		});

		this.model.getProduction?.data?.AggregateActualMilkProduction.forEach((production) => {
			const date = production.firstDateOfMonth;
			if (!date) {
				console.warn(`Date not returned for Aggregate Actual Milk Production`);
				return;
			}
			const aggregatePosition = getOrCreateAggregateProduction(date);
			aggregatePosition.grossProductionActual = production.sum.grossProduction ?? 0;
		});

		this.monthsInDateRange.forEach((date) => getOrCreateAggregateProduction(date));

		return Object.values(aggregateProductions)
			.sort((a, b) => (a.date < b.date ? -1 : 1))
			.map((v) => new ProductionRow(v));
	}

	get selectedRows(): ProductionRow[] | null {
		return this.canEditProduction ? this._selectedRows : null;
	}

	set selectedRows(value: ProductionRow[] | null) {
		this._selectedRows = value;
	}

	@action
	setSelectedLocationId(id: string, callback: () => void) {
		if (id !== this.selectedLocationId) {
			this.selectedLocationId = id;
			this.setTablePageState();
		}
		callback();
	}

	@action
	setInitialSelectedLocationId() {
		this.selectedLocationId = this.model.getProduction.data?.Locations.length === 1 ? this.model.getProduction.data.Locations[0].id : '';
	}

	@action
	setProductionMonthQueryParam(value: { startDate: string; endDate: string }) {
		this.productionMonthStartDate = value.startDate;
		this.productionMonthEndDate = value.endDate;
		this.setTablePageState();
	}

	@action
	setTablePageState() {
		this.clearSelectedRows();
		resetVaultTableScroll('business-production-table');
	}

	clearSelectedRows() {
		this._selectedRows = [];
	}

	@action
	clear() {
		this.selectedCounty = null;
		this.newLocationName = null;
		this.selectedMilkOrder = null;
		this.errorMessage = null;
		this.isLoading = false;
	}

	@action
	async submit() {
		this.errorMessage = null;

		if (!this.selectedCounty || !this.selectedCounty.id || !this.selectedCounty.name) {
			console.warn('County not selected');
			this.errorMessage = 'County not selected';
			return;
		}

		this.isLoading = true;
		this.applicationSpinner.start();

		const data: LocationEntityCreateDTO = {
			countyId: this.selectedCounty.id,
			customerId: this.model.businessId,
			milkOrderId: this.selectedMilkOrder?.id ?? null,
			name: this.newLocationName || this.selectedCounty.name,
		};

		await this.createLocation.mutate({ data });
		await this.retryModelUpdate().catch(() => {
			console.warn(`Could not refresh model`);
		});

		this.applicationSpinner.stop();
		this.clear();
	}

	// Adding location can take 30 ~ 45 seconds, so this will hopefully work for the majority of cases
	// 20 retries at 2.5 second intervals (max 50 seconds allotted)
	async retryModelUpdate() {
		for (let i = 0; i < 20; i++) {
			await new Promise((r) => setTimeout(r, 2500));
			this.applicationSpinner.start();
			await this.model.getProduction.refetch();

			if (this.model.getProduction.data?.Locations.length && this.model.getProduction.data?.Locations.length > 0) {
				return Promise.resolve();
			}
		}

		return Promise.reject();
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'businesses/business/production': BusinessesBusinessProductionController;
	}
}
