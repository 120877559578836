import { getOwner } from '@ember/application';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';

import { gql, useQuery } from 'glimmer-apollo';
import { DateTime } from 'luxon';
import { TrackedArray } from 'tracked-built-ins/.';
import HypotheticalPosition from 'vault-client/models/hypothetical-position';
import BusinessBusinessToolsSwinePriceScenariosRoute from 'vault-client/routes/tools/swine-price-scenarios';
import ScenarioPricesService from 'vault-client/services/scenario-prices';
import {
	Future,
	FuturesCurvePointInput,
	InsuranceRatioScenarioPricingOutput,
	Option,
	Query,
	Query_insuranceRatioScenarioPricingForFuturesCurveArgs,
	Swap,
	Swaption,
	TypeOfInstrument,
	TypeOfInsuranceEndorsement,
	TypeOfLivestockPopulationChangeReason,
	TypeOfOption,
} from 'vault-client/types/graphql-types';
import checkStorageAvailable from 'vault-client/utils/check-storage-available';
import { ModelFrom } from 'vault-client/utils/type-utils';

type ActiveChart = 'CWT' | 'Head' | 'Total';
type ActiveComponentChart = 'Lean Hogs' | 'Corn' | 'Soybean Meal';
type PositionType = 'Futures (Forward)' | 'Call' | 'Put';

const optionPl = function (
	pointValue: number,
	tradePrice: number,
	strike: number,
	underlyingPrice: number,
	contractQuantity: number,
	optionType: TypeOfOption
): number {
	const premium = contractQuantity * tradePrice * pointValue;
	let plPerPoint = 0;

	if (optionType === TypeOfOption.Call) {
		plPerPoint = contractQuantity * Math.max(0, underlyingPrice - strike);
	} else {
		plPerPoint = contractQuantity * Math.max(0, strike - underlyingPrice);
	}

	const pl = plPerPoint * pointValue - premium;
	return pl;
};

const INSURANCE_SCENARIO_PRICING = gql`
	query insuranceRatioScenarioPricingForFuturesCurve($input: InsuranceRatioScenarioPricingFuturesCurveInput!) {
		insuranceRatioScenarioPricingForFuturesCurve(input: $input) {
			perEndorsementRatioPnl {
				date
				pnl
			}
		}
	}
`;

type insuranceRatioScenarioQuery = Query['insuranceRatioScenarioPricingForFuturesCurve'] & {
	insuranceRatioScenarioPricingForFuturesCurve: InsuranceRatioScenarioPricingOutput;
};

export default class ToolsSwinePriceScenariosController extends Controller {
	declare model: ModelFrom<BusinessBusinessToolsSwinePriceScenariosRoute>;
	@tracked startDate = DateTime.now().startOf('month').toISODate();
	@tracked endDate = DateTime.now().plus({ month: 12 }).startOf('month').toISODate();
	@tracked activeChart: ActiveChart = 'Total';
	@tracked activeComponentChart: ActiveComponentChart = 'Lean Hogs';
	@tracked _addedHypotheticalPositions: HypotheticalPosition[] = new TrackedArray([]);
	@tracked showSetPricesModal = false;
	@tracked setPricesModalError: string | null = null;
	@tracked rawSetFuturePrice: string = '';

	chartData: { labels: string[]; datasets: any[] } = { labels: [], datasets: [] };

	@service declare scenarioPrices: ScenarioPricesService;

	get products() {
		return [
			{
				name: 'Lean Hogs',
				productSlug: 'livestock-lean-hogs',
				volumeUnit: 'Pounds',
				pricingUnit: 'CWT',
				months: ['February', 'April', 'May', 'June', 'July', 'August', 'October', 'December'],
			},
			{
				name: 'Corn',
				productSlug: 'grain-corn',
				volumeUnit: 'Bushels',
				pricingUnit: 'Bushel',
				months: ['March', 'May', 'July', 'September', 'December'],
			},
			{
				name: 'Soybean Meal',
				productSlug: 'grain-soybean-meal',
				volumeUnit: 'Tons',
				pricingUnit: 'Ton',
				months: ['January', 'March', 'May', 'July', 'August', 'September', 'October', 'December'],
			},
		];
	}

	get entityId() {
		return this.model.entityId;
	}

	get activeComponentProductSlug() {
		switch (this.activeComponentChart) {
			case 'Lean Hogs':
				return 'livestock-lean-hogs';
			case 'Corn':
				return 'grain-corn';
			case 'Soybean Meal':
				return 'grain-soybean-meal';
		}
	}

	@action
	formatCurrency(val: number | null) {
		if (val === null) return '';
		return Intl.NumberFormat(undefined, { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(
			val
		);
	}

	get componentChartOptions(): ActiveComponentChart[] {
		return this.products.map((product) => {
			return product.name as ActiveComponentChart;
		});
	}

	get activeComponentChartTitle() {
		switch (this.activeComponentChart) {
			case 'Lean Hogs':
				return 'Monthly Price of Lean Hogs';
			case 'Corn':
				return 'Monthly Price of Corn';
			case 'Soybean Meal':
				return 'Monthly Price of Soybean Meal';
		}
	}

	get activeComponentChartFutures() {
		return (
			this.model.getSwinePriceScenariosData.data?.Futures.filter(
				(future) => future?.Product?.slug === this.activeComponentProductSlug && future.displayExpiresAt >= this.startDate
			) ?? []
		);
	}

	// DGC: This should come from the server, now that we have this.
	productUnitsPerContract(product: string) {
		switch (product) {
			case 'grain-corn':
				return 5000;
			case 'grain-soybean-meal':
				return 100;
			default:
				return 40000;
		}
	}

	findNearestFuture(productSlug: string, month: string) {
		return (
			this.model.getSwinePriceScenariosData.data?.Futures.find((future) => {
				return future.Product.slug === productSlug && future.displayExpiresAt >= month;
			}) ?? null
		);
	}

	get activeComponentDisplayFactor() {
		return this.activeComponentChartFutures.find((future) => future.SymbolGroup.displayFactor)?.SymbolGroup.displayFactor ?? 1;
	}

	get activeChartTitle(): string {
		return 'Combined Net P&L: Hedge Adjusted (Current & Scenario)';
	}

	get netPnlData() {
		const pnlMonths = this.projectedPnlRawData;

		return {
			'Current P&L': pnlMonths['Net P/L'],
			'Scenario P&L': pnlMonths['Scenario P/L'],
			'Hedge P&L': pnlMonths['Hedge P/L'],
			'Production P&L': pnlMonths['Production P/L'],
		};
	}

	get projectedPnlRawData() {
		const data: {
			'Net P/L': (number | null)[];
			'Hedge P/L': (number | null)[];
			'Scenario P/L': (number | null)[];
			'Production P/L': (number | null)[];
		} = {
			'Net P/L': [],
			'Hedge P/L': [],
			'Scenario P/L': [],
			'Production P/L': [],
		};

		this.pnlMonths.forEach((month) => {
			if (this.activeChart === 'Total') {
				data['Net P/L'].push(month.netPnl ?? 0);
				data['Hedge P/L'].push(month.totalHedged ?? 0);
				data['Scenario P/L'].push(month.scenarioPnl ?? 0);
				data['Production P/L'].push(month.productionPnl ?? 0);
			} else if (this.activeChart === 'Head') {
				data['Net P/L'].push(month.netPnlPerHead ?? 0);
				data['Hedge P/L'].push(month.totalHedgedPerHead ?? 0);
				data['Scenario P/L'].push(month.scenarioPnlPerHead ?? 0);
				data['Production P/L'].push(month.productionPnlPerHead ?? 0);
			} else if (this.activeChart === 'CWT') {
				data['Net P/L'].push(month.netPnlCwt ?? 0);
				data['Hedge P/L'].push(month.totalHedgedCwt ?? 0);
				data['Scenario P/L'].push(month.scenarioPnlCwt ?? 0);
				data['Production P/L'].push(month.productionPnlCwt ?? 0);
			}
		});

		return data;
	}

	get scenarioFuturesCurve(): Array<FuturesCurvePointInput> {
		return (
			this.model.getSwinePriceScenariosData.data?.Futures.map((future) => {
				const value: FuturesCurvePointInput = {
					contractMonth: future.displayExpiresAt,
					price: future.barchartSymbol ? this.scenarioPrices.getScenarioPrice(future.barchartSymbol) || 0 : 0,
					productId: future.Product.id,
				};

				return value;
			}) || []
		);
	}

	get insuranceScenarioVariables(): Query_insuranceRatioScenarioPricingForFuturesCurveArgs {
		return {
			input: {
				futuresCurve: this.scenarioFuturesCurve,

				/** A filter used to determine the endorsements for which P/L values will be calculated. This filter will be applied in addition to the `scopeId`. If more than 1000 endorsements are returned by the filter, an error will be thrown. */
				insuranceEndorsementAllocationRatioFilter: {
					effectiveHedgeDate: { gte: this.startDate, lte: this.endDate },
					InsuranceEndorsement: {
						type: {
							in: [TypeOfInsuranceEndorsement.Lgm, TypeOfInsuranceEndorsement.Lrp],
						},
						OR: [
							{
								AsLrpInsuranceEndorsement: {
									RmaType: {
										RmaCommodity: {
											commodityName: {
												equals: 'Swine',
											},
										},
									},
								},
							},
							{
								AsLgmInsuranceEndorsement: {
									RmaType: {
										RmaCommodity: {
											commodityName: {
												equals: 'Swine',
											},
										},
									},
								},
							},
						],
					},
					InsurancePolicy: {
						customerId: { equals: this.model.entityId },
					},
				},
			},
		};
	}

	rawInsurancePls = useQuery<insuranceRatioScenarioQuery, Query_insuranceRatioScenarioPricingForFuturesCurveArgs>(this, () => [
		INSURANCE_SCENARIO_PRICING,
		{
			variables: this.insuranceScenarioVariables,
		},
	]);

	get insurancePls() {
		return this.rawInsurancePls.data?.insuranceRatioScenarioPricingForFuturesCurve?.perEndorsementRatioPnl || [];
	}

	get pnlMonths() {
		const pnlMonthsMap = new Map<string, PnlMonthArgs>();

		let currDateTime = DateTime.fromISO(this.startDate).startOf('month');
		const endDateTime = DateTime.fromISO(this.endDate).startOf('month');

		while (currDateTime <= endDateTime) {
			const date = currDateTime.toISODate();
			const pnlMonthArgs: PnlMonthArgs = {
				date,
				avgLbsPerHead: this.model.getSwinePriceScenariosData.data?.Customer?.averageFinishWeightInLbs ?? 0,
				numPigs: null,
				swineSales: null,
				swinePurchases: null,
				cmeSwineRevenue: null,
				cmeSwineHedged: null,
				nonFeedRevenue: null,
				insurancePnl: null,
				feedExpenses: null,
				cmeFeedRevenue: null,
				cmeFeedHedged: null,
				nonFeedExpenses: null,
				scenarioTotalHedged: 0,
				totalHedged: 0,
			};

			pnlMonthsMap.set(date, pnlMonthArgs);
			currDateTime = currDateTime.plus({ month: 1 }).startOf('month');
		}

		//
		//	Calculate Current P/L for Allocated Positions
		//

		this.model.getSwinePriceScenariosData.data?.AllocatedFuturePositions.forEach((position) => {
			const date = position.effectiveHedgeDate;
			if (!date) return;
			const startOfMonth = DateTime.fromISO(date).startOf('month').toISODate();
			const pnlMonth = pnlMonthsMap.get(startOfMonth);
			if (!pnlMonth) return;

			pnlMonth.totalHedged += position.grossPnl ?? 0;
		});

		this.model.getSwinePriceScenariosData.data?.AllocatedOptionPositions.forEach((position) => {
			const date = position.effectiveHedgeDate;
			if (!date) return;
			const startOfMonth = DateTime.fromISO(date).startOf('month').toISODate();
			const pnlMonth = pnlMonthsMap.get(startOfMonth);
			if (!pnlMonth) return;

			pnlMonth.totalHedged += position.grossPnl ?? 0;
		});

		this.model.getSwinePriceScenariosData.data?.AllocatedSwapPositions.forEach((position) => {
			const date = position.effectiveHedgeDate;
			if (!date) return;
			const startOfMonth = DateTime.fromISO(date).startOf('month').toISODate();
			const pnlMonth = pnlMonthsMap.get(startOfMonth);
			if (!pnlMonth) return;

			pnlMonth.totalHedged += position.grossPnl ?? 0;
		});

		this.model.getSwinePriceScenariosData.data?.AllocatedSwaptionPositions.forEach((position) => {
			const date = position.effectiveHedgeDate;
			if (!date) return;
			const startOfMonth = DateTime.fromISO(date).startOf('month').toISODate();
			const pnlMonth = pnlMonthsMap.get(startOfMonth);
			if (!pnlMonth) return;

			pnlMonth.totalHedged += position.grossPnl ?? 0;
		});

		//
		//	Calculate Scenario P/L for Allocated Positions
		//

		this.model.getSwinePriceScenariosData.data?.AllocatedFuturePositions.forEach((position) => {
			const date = position.effectiveHedgeDate;
			if (!date) return;
			const startOfMonth = DateTime.fromISO(date).startOf('month').toISODate();
			const pnlMonth = pnlMonthsMap.get(startOfMonth);
			if (!pnlMonth) return;

			const barchartSymbol = (position.Instrument as Future)?.barchartSymbol || '';

			const scenarioPrice = this.scenarioPrices.getScenarioPrice(barchartSymbol);

			if (scenarioPrice) {
				const pointValue =
					position?.Product?.ProductLotSpecifications.find((specs) => specs?.id === position?.Instrument?.productLotSpecificationId)
						?.pointValue ?? 1;
				const pl = position.contractQuantity * (scenarioPrice - position.lifetimeWeightedAveragePrice) * pointValue;
				pnlMonth.scenarioTotalHedged += pl ?? 0;
			}
		});

		this.model.getSwinePriceScenariosData.data?.AllocatedOptionPositions.forEach((position) => {
			const date = position.effectiveHedgeDate;
			if (!date) return;
			const startOfMonth = DateTime.fromISO(date).startOf('month').toISODate();
			const pnlMonth = pnlMonthsMap.get(startOfMonth);
			if (!pnlMonth) return;

			const underlyingBarchartSymbol = ((position.Instrument as Option)?.UnderlyingInstrument as Future)?.barchartSymbol || '';
			const underlyingScenarioPrice = this.scenarioPrices.getScenarioPrice(underlyingBarchartSymbol);

			if (underlyingScenarioPrice) {
				const pointValue =
					position?.Product?.ProductLotSpecifications.find((specs) => specs?.id === position?.Instrument?.productLotSpecificationId)
						?.pointValue ?? 1;

				let pl = 0;

				if (position.optionType) {
					pl = optionPl(
						pointValue,
						position.lifetimeWeightedAveragePrice,
						(position.Instrument as Option).strike,
						underlyingScenarioPrice,
						position.contractQuantity,
						position.optionType
					);
				}

				pnlMonth.scenarioTotalHedged += pl ?? 0;
			}
		});

		this.model.getSwinePriceScenariosData.data?.AllocatedSwapPositions.forEach((position) => {
			const date = position.effectiveHedgeDate;
			if (!date) return;
			const startOfMonth = DateTime.fromISO(date).startOf('month').toISODate();
			const pnlMonth = pnlMonthsMap.get(startOfMonth);
			if (!pnlMonth) return;

			const barchartSymbol = ((position.Instrument as Swap)?.PriceInstrument as Future)?.barchartSymbol || '';
			const scenarioPrice = this.scenarioPrices.getScenarioPrice(barchartSymbol);

			if (scenarioPrice) {
				const pointValue =
					position?.Product?.ProductLotSpecifications.find((specs) => specs?.id === position?.Instrument?.productLotSpecificationId)
						?.pointValue ?? 1;
				const pl = position.contractQuantity * (scenarioPrice - position.lifetimeWeightedAveragePrice) * pointValue;
				pnlMonth.scenarioTotalHedged += pl ?? 0;
			}
		});

		this.model.getSwinePriceScenariosData.data?.AllocatedSwaptionPositions.forEach((position) => {
			const date = position.effectiveHedgeDate;
			if (!date) return;
			const startOfMonth = DateTime.fromISO(date).startOf('month').toISODate();
			const pnlMonth = pnlMonthsMap.get(startOfMonth);
			if (!pnlMonth) return;

			const underlyingBarchartSymbol = ((position.Instrument as Swaption)?.PriceInstrument as Future)?.barchartSymbol || '';
			const underlyingScenarioPrice = this.scenarioPrices.getScenarioPrice(underlyingBarchartSymbol);

			if (underlyingScenarioPrice) {
				const pointValue =
					position?.Product?.ProductLotSpecifications.find((specs) => specs?.id === position?.Instrument?.productLotSpecificationId)
						?.pointValue ?? 1;
				let pl = 0;

				if (position.optionType) {
					pl = optionPl(
						pointValue,
						position.lifetimeWeightedAveragePrice,
						(position.Instrument as Swaption).strike,
						underlyingScenarioPrice,
						position.contractQuantity,
						position.optionType
					);
				}

				pnlMonth.scenarioTotalHedged += pl ?? 0;
			}
		});

		//
		//	Calculate P/L for Hypothetical Positions
		//

		this._addedHypotheticalPositions.forEach((position) => {
			const pnlMonth = pnlMonthsMap.get(position.futureDisplayExpiresAt);
			if (!pnlMonth) return;

			pnlMonth.totalHedged += position.marketPnlInDollars;
		});

		this._addedHypotheticalPositions.forEach((position) => {
			const pnlMonth = pnlMonthsMap.get(position.futureDisplayExpiresAt);
			if (!pnlMonth) return;

			pnlMonth.scenarioTotalHedged += position.scenarioPnlInDollars;
		});

		//
		// Calculate feedExpenses based on AggregateForecastedSwineLivestockFeedUsageAtFinish
		//
		this.model.getSwinePriceScenariosData.data?.AggregateForecastedSwineLivestockFeedUsageAtFinish.forEach((feedUsage) => {
			const startOfMonth = feedUsage.firstDateOfMonth;
			// Reverse totalExpense sign so it shows as a negative value, similar to non Feed Expenses
			const totalExpense = feedUsage.sum.totalExpenseInUsd ? -feedUsage.sum.totalExpenseInUsd : null;

			if (!startOfMonth || totalExpense == null) return;

			const pnlMonth = pnlMonthsMap.get(startOfMonth);
			if (!pnlMonth) return;

			pnlMonth.feedExpenses = pnlMonth.feedExpenses ? pnlMonth.feedExpenses + totalExpense : totalExpense;
		});

		//
		//	Calculate Insurance P/L
		//
		this.model.getSwinePriceScenariosData.data?.InsuranceEndorsementAllocationRatios.forEach((lrpEndorsement) => {
			const effectiveHedgeDate = lrpEndorsement.effectiveHedgeDate;
			const pnlMonth = pnlMonthsMap.get(effectiveHedgeDate);
			if (!pnlMonth) return;

			pnlMonth.totalHedged += lrpEndorsement.RatioAdjustedInsuranceEndorsement.pnl ?? 0;
		});

		this.insurancePls.forEach((insurancePl) => {
			const pnlMonth = pnlMonthsMap.get(insurancePl.date);
			if (!pnlMonth) return;
			pnlMonth.scenarioTotalHedged += insurancePl.pnl;
		});

		//
		//	Calculate Swine Sales and Purchases based on SwineSalesPurchasesAndProduced
		//
		this.model.getSwinePriceScenariosData.data?.SwineSalesPurchasesAndProduced.forEach((swinePopulationChange) => {
			if (swinePopulationChange.reasonType === TypeOfLivestockPopulationChangeReason.Sale) {
				const saleDate = swinePopulationChange.date;
				const totalValue = swinePopulationChange.sum.totalValue != null ? Math.abs(swinePopulationChange.sum.totalValue) : null;

				if (!saleDate || totalValue == null) return;

				const date = DateTime.fromISO(saleDate).startOf('month').toISODate();
				const pnlMonth = pnlMonthsMap.get(date);
				if (!pnlMonth) return;

				const numSold = swinePopulationChange.sum.quantity != null ? Math.abs(swinePopulationChange.sum.quantity) : 0;

				pnlMonth.numPigs = pnlMonth.numPigs ? pnlMonth.numPigs + numSold : numSold;
				pnlMonth.swineSales = pnlMonth.swineSales ? pnlMonth.swineSales + totalValue : totalValue;
			} else if (
				swinePopulationChange.reasonType === TypeOfLivestockPopulationChangeReason.Purchase ||
				swinePopulationChange.reasonType === TypeOfLivestockPopulationChangeReason.Birth
			) {
				const dob = swinePopulationChange.dob;
				if (!dob) return;

				const expectedMarketingDate = DateTime.fromISO(dob).plus({
					weeks: this.model.averageFinishAgeInWeeks ?? 0,
				});

				const totalValue = swinePopulationChange.sum.totalValue;

				if (!expectedMarketingDate || totalValue == null) return;

				const date = expectedMarketingDate.startOf('month').toISODate();
				const pnlMonth = pnlMonthsMap.get(date);
				if (!pnlMonth) return;

				pnlMonth.swinePurchases = pnlMonth.swinePurchases ? pnlMonth.swinePurchases - totalValue : -totalValue;
			}
		});

		//
		//	Calculate nonFeedExpenses
		//
		this.model.getSwinePriceScenariosData.data?.AggregateExpenseLedgerEntries.forEach((expense) => {
			const month = expense.month;
			const year = expense.year;
			const expenseAmount = expense.sum.calculatedAmount;

			if (!month || !year || expenseAmount == null) return;

			const date = DateTime.fromObject({ month, year }).toISODate();
			const pnlMonth = pnlMonthsMap.get(date);
			if (!pnlMonth) return;

			pnlMonth.nonFeedExpenses = pnlMonth.nonFeedExpenses ? pnlMonth.nonFeedExpenses + expenseAmount : expenseAmount;
		});

		//
		//	Calculate Non-Feed Revenue.
		//
		this.model.getSwinePriceScenariosData.data?.AggregateRevenueLedgerEntries.forEach((revenue) => {
			const month = revenue.month;
			const year = revenue.year;
			const revenueAmount = revenue.sum.calculatedAmount;

			if (!month || !year || revenueAmount == null) return;

			const date = DateTime.fromObject({ month, year }).toISODate();
			const pnlMonth = pnlMonthsMap.get(date);
			if (!pnlMonth) return;

			pnlMonth.nonFeedRevenue = pnlMonth.nonFeedRevenue ? pnlMonth.nonFeedRevenue + revenueAmount : revenueAmount;
		});

		return Array.from(pnlMonthsMap.values())
			.sortBy('date')
			.map((args) => {
				return new PnlMonth(
					args.date,
					args.avgLbsPerHead ?? 0,
					args.numPigs,
					args.swineSales,
					args.swinePurchases,
					args.cmeSwineRevenue,
					args.cmeSwineHedged,
					args.nonFeedRevenue,
					args.insurancePnl,
					args.feedExpenses,
					args.cmeFeedRevenue,
					args.cmeFeedHedged,
					args.nonFeedExpenses,
					args.scenarioTotalHedged,
					args.totalHedged
				);
			});
	}

	get months() {
		const months = [];
		let currentDate = DateTime.fromISO(this.startDate);
		const endDate = DateTime.fromISO(this.endDate);

		while (currentDate <= endDate) {
			months.push(currentDate.startOf('month').toISODate());
			currentDate = currentDate.plus({ month: 1 });
		}

		return months;
	}

	get chartLabels() {
		return this.months.map((month) => DateTime.fromISO(month).toFormat('LLL yyyy'));
	}

	@action
	onAddHypotheticalPositionSubmit(
		productSlug: string,
		positionType: PositionType,
		side: 'Bought' | 'Sold',
		contracts: number,
		pricePerPricingUnit: number,
		strikePrice: number | null,
		months: string[]
	) {
		months.forEach((month) => {
			const future = this.findNearestFuture(productSlug, month);

			if (!future) {
				console.warn(`Could not find future for given month and product: ${productSlug} - ${month}`);
				return;
			}

			const quantity = side === 'Bought' ? contracts : contracts * -1;
			const futureDisplayExpiresAt = future.displayExpiresAt;
			const instrumentType = positionType !== 'Futures (Forward)' ? TypeOfInstrument.Option : TypeOfInstrument.Future;
			const barchartSymbol = future.barchartSymbol ?? null;
			const pointValue = future.Product.StandardProductLotSpecification.pointValue ?? 0;
			const lotSize = future.Product.StandardProductLotSpecification.lotSize ?? 0;
			const optionType = ((positionType: PositionType) => {
				switch (positionType) {
					case 'Call':
						return TypeOfOption.Call;
					case 'Put':
						return TypeOfOption.Put;
					default:
						return null;
				}
			})(positionType);

			this._addedHypotheticalPositions.push(
				new HypotheticalPosition(
					getOwner(this),
					month,
					futureDisplayExpiresAt,
					productSlug,
					instrumentType,
					barchartSymbol,
					pointValue,
					lotSize,
					quantity ?? 0,
					pricePerPricingUnit,
					0,
					0,
					optionType,
					strikePrice
				)
			);
		});

		this.storeAddedHypotheticalPositions(this.entityId, this._addedHypotheticalPositions);
	}

	@action
	removeHypotheticalPosition(id: string) {
		const index = this._addedHypotheticalPositions.findIndex((position) => position.id === id);
		this._addedHypotheticalPositions.removeAt(index);
		this.storeAddedHypotheticalPositions(this.entityId, this._addedHypotheticalPositions);
	}

	@action
	clearHypotheticalPositions() {
		this._addedHypotheticalPositions.clear();
		this.storeAddedHypotheticalPositions(this.entityId, this._addedHypotheticalPositions);
	}

	storeAddedHypotheticalPositions(entityId: string | null, hypotheticalPositions: HypotheticalPosition[]) {
		if (!entityId) return;
		const localStorageString = `swine-price-scenarios-hypothetical-positions.${entityId}`;

		if (!checkStorageAvailable('localStorage')) return;

		const stringifiedPositions = JSON.stringify(hypotheticalPositions.map((position) => position.toJson()));

		window.localStorage.setItem(localStorageString, stringifiedPositions);
	}

	@action
	resetFuturePricesForCurrentComponent() {
		const barchartSymbol = this.activeComponentChartFutures.map((future) => future.barchartSymbol);
		const filteredBarchartSymbols = barchartSymbol.filter((x): x is string => typeof x === 'string');
		this.scenarioPrices.setScenarioPricesToMarket(filteredBarchartSymbols);
	}

	@action
	setFuturePricesForCurrentComponent(price: number) {
		const barchartSymbol = this.activeComponentChartFutures.map((future) => future.barchartSymbol);
		const filteredBarchartSymbols = barchartSymbol.filter((x): x is string => typeof x === 'string');
		this.scenarioPrices.setScenarioPrices(filteredBarchartSymbols, price);
	}

	@action
	onSetPricesModalSubmit() {
		this.setPricesModalError = '';
		const displayPrice = parseFloat(this.rawSetFuturePrice);
		if (!isFinite(displayPrice)) {
			this.setPricesModalError = 'Price could not be parsed. Please ensure it is included.';
		}

		const barchartPrice = displayPrice / this.activeComponentDisplayFactor;

		this.setFuturePricesForCurrentComponent(barchartPrice);
		this.rawSetFuturePrice = '';
		this.showSetPricesModal = false;
	}

	@action
	onSetPricesModalCancel() {
		this.setPricesModalError = '';
		this.rawSetFuturePrice = '';
		this.showSetPricesModal = false;
	}
}

export class PnlMonth {
	id: string;
	date: string;
	swineSales: number | null = null;
	swinePurchases: number | null = null;
	cmeSwineRevenue: number | null = null;
	cmeSwineHedged: number | null = null;
	nonFeedRevenue: number | null = null;
	insurancePnl: number | null = null;
	feedExpenses: number | null = null;
	cmeFeedRevenue: number | null = null;
	cmeFeedHedged: number | null = null;
	nonFeedExpenses: number | null = null;
	numPigs: number | null = null;
	avgLbsPerHead: number | null = null;
	scenarioTotalHedged: number;
	totalHedged: number;

	constructor(
		date: string,
		avgLbsPerHead: number,
		numPigs: number | null,
		swineSales: number | null,
		swinePurchases: number | null,
		cmeSwineRevenue: number | null,
		cmeSwineHedged: number | null,
		nonFeedRevenue: number | null,
		insurancePnl: number | null,
		feedExpenses: number | null,
		cmeFeedRevenue: number | null,
		cmeFeedHedged: number | null,
		nonFeedExpenses: number | null,
		scenarioTotalHedged: number,
		totalHedged: number
	) {
		this.id = date;
		this.date = date;
		this.numPigs = numPigs;
		this.avgLbsPerHead = avgLbsPerHead ?? 0;
		this.swineSales = swineSales;
		this.swinePurchases = swinePurchases;
		this.cmeSwineRevenue = cmeSwineRevenue;
		this.cmeSwineHedged = cmeSwineHedged;
		this.nonFeedRevenue = nonFeedRevenue;
		this.insurancePnl = insurancePnl;
		this.feedExpenses = feedExpenses;
		this.cmeFeedRevenue = cmeFeedRevenue;
		this.cmeFeedHedged = cmeFeedHedged;
		this.nonFeedExpenses = nonFeedExpenses;
		this.scenarioTotalHedged = scenarioTotalHedged;
		this.totalHedged = totalHedged;
	}

	get netPnl() {
		if (this.totalRevenue == null && this.totalHedged == null && this.totalExpenses == null) return null;
		return (this.totalRevenue ?? 0) + (this.totalHedged ?? 0) + (this.totalExpenses ?? 0);
	}

	get netPnlPerHead() {
		return this.getValuePerHead(this.netPnl);
	}

	get netPnlCwt() {
		return this.getValuePerCwt(this.netPnl);
	}

	get totalHedgedPerHead() {
		return this.getValuePerHead(this.totalHedged);
	}

	get totalHedgedCwt() {
		return this.getValuePerCwt(this.totalHedged);
	}

	get scenarioPnl() {
		// DGC: Total Revenue should change for scenarios, but we don't have that implemented.

		if (this.totalRevenue == null && this.scenarioTotalHedged == null && this.totalExpenses == null) return null;
		return (this.totalRevenue ?? 0) + (this.scenarioTotalHedged ?? 0) + (this.totalExpenses ?? 0);
	}

	get scenarioPnlPerHead() {
		return this.getValuePerHead(this.scenarioPnl);
	}

	get scenarioPnlCwt() {
		return this.getValuePerCwt(this.scenarioPnl);
	}

	get productionPnl() {
		return (this.swineSales ?? 0) + (this.swinePurchases ?? 0) + (this.feedExpenses ?? 0);
	}

	get productionPnlPerHead() {
		return this.getValuePerHead(this.productionPnl);
	}

	get productionPnlCwt() {
		return this.getValuePerCwt(this.productionPnl);
	}

	get otherRevenue() {
		if (this.nonFeedRevenue == null) return null;
		return this.nonFeedRevenue ?? 0;
	}

	get totalRevenue() {
		if (this.swineSales == null && this.otherRevenue == null) return null;

		return (this.swineSales ?? 0) + (this.otherRevenue ?? 0);
	}

	get otherExpenses() {
		if (this.nonFeedExpenses == null && this.swinePurchases == null) return null;
		return (this.nonFeedExpenses ?? 0) + (this.swinePurchases ?? 0);
	}

	get totalExpenses() {
		if (this.feedExpenses == null && this.otherExpenses === null) return null;
		return (this.feedExpenses ?? 0) + (this.otherExpenses ?? 0);
	}

	get cmeSwine() {
		if (this.cmeSwineHedged == null && this.cmeSwineRevenue == null) return null;
		return (this.cmeSwineHedged ?? 0) + (this.cmeSwineRevenue ?? 0);
	}

	get cmeFeed() {
		if (this.cmeFeedHedged == null && this.cmeFeedRevenue == null) return null;
		return (this.cmeFeedHedged ?? 0) + (this.cmeFeedRevenue ?? 0);
	}

	get totalLbs() {
		if (this.numPigs == null || this.avgLbsPerHead == null) return null;
		return this.numPigs * this.avgLbsPerHead;
	}

	getValuePerHead(value: number | null) {
		if (value == null || this.numPigs == null) return null;

		if (this.numPigs === 0) return 0;

		return value / this.numPigs;
	}

	getValuePerCwt(value: number | null) {
		if (value == null || this.totalLbs == null) return null;
		if (this.totalLbs === 0) return 0;
		return value / (this.totalLbs / 100);
	}
}

export interface PnlMonthArgs {
	date: string;
	numPigs: number | null;
	avgLbsPerHead: number | null;
	swineSales: number | null;
	swinePurchases: number | null;
	cmeSwineRevenue: number | null;
	cmeSwineHedged: number | null;
	nonFeedRevenue: number | null;
	insurancePnl: number | null;
	feedExpenses: number | null;
	cmeFeedRevenue: number | null;
	cmeFeedHedged: number | null;
	nonFeedExpenses: number | null;
	scenarioTotalHedged: number;
	totalHedged: number;
}
