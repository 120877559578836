export default function intlNumberFormat(params) {
	if (params.nullSkipFormat && (params.value === null || params.value === 0 || params.value === undefined)) {
		return params.value;
	} else {
		let options = {};

		Object.assign(
			options,
			params.style && {
				style: params.style,
			},
			params.localeMatcher && {
				localeMatcher: params.localeMatcher,
			},
			params.currency && {
				currency: params.currency,
			},
			params.currencyDisplay && {
				currencyDisplay: params.currencyDisplay,
			},
			params.useGrouping && {
				useGrouping: params.useGrouping,
			},
			params.minimumIntegerDigits && {
				minimumIntegerDigits: params.minimumIntegerDigits,
			},
			params.minimumFractionDigits && {
				minimumFractionDigits: params.minimumFractionDigits,
			},
			params.maximumFractionDigits && {
				maximumFractionDigits: params.maximumFractionDigits,
			},
			params.minimumSignificantDigits && {
				minimumSignificantDigits: params.minimumSignificantDigits,
			},
			params.maximumSignificantDigits && {
				maximumSignificantDigits: params.maximumSignificantDigits,
			},
			params.currencySign && {
				currencySign: params.currencySign,
			}
		);

		if (params.locales) {
			return new Intl.NumberFormat(params.locales, options).format(params.value);
		} else {
			return new Intl.NumberFormat(undefined, options).format(params.value);
		}
	}
}
