import InsurancePoliciesShowController from 'vault-client/controllers/insurance-policies/show';

export default class BusinessesBusinessInsurancePolicy extends InsurancePoliciesShowController {
	insurancePoliciesRoute: string = 'businesses.business.insurance-policies';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'businesses/business/insurance-policy': BusinessesBusinessInsurancePolicy;
	}
}
