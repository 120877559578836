import { helper } from '@ember/component/helper';
import { TypeOfGrainOrderStatus } from 'vault-client/types/graphql-types';
import { getGrainOrderStatusDisplayValue } from 'vault-client/utils/vgs-utils';

export default helper(function formatGrainOrderStatus(positional: [unknown]) {
	const status = positional[0];
	if (!status || typeof status !== 'string') return status;

	if (!(Object.values(TypeOfGrainOrderStatus) as string[]).includes(status)) return status;

	return getGrainOrderStatusDisplayValue(status as TypeOfGrainOrderStatus);
});
