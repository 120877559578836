import Route from '@ember/routing/route';
import { tracked } from '@glimmer/tracking';
import { gql, useQuery } from 'glimmer-apollo';

import { GrainTargetOrderFilterDTO, GrainTargetOrder } from 'vault-client/types/graphql-types';

const GET_TARGET = gql`
	query GrainOrder($id: String!) {
		GrainTargetOrder(id: $id) {
			id
			deliveryTerms
			bushels
			futuresMonth
			deliveryStartDate
			deliveryEndDate
			deliveryMonth
			salesType
			futurePrice
			htaFee
			spread
			rollFee
			flexFee
			basis
			feedPrice
			expirationDate
			contractNumber
			shortNote
			Notes(orderBy: { createdAt: Desc }) {
				id
				note
				writtenBy
				createdAt
			}
			BuyerRelationsOwner {
				email
				firstName
				id
				lastName
				roles
			}
			StatusUpdates {
				id
				updatedAt
			}
			status
			Seller {
				id
				name
			}
			Location {
				id
				name
			}
			Activity(orderBy: { performedAt: Desc }) {
				data
				type
				id
				operationType
				performedAt
				performedByUserId
				orderId
				GrainPlan {
					id
					type
				}
				User {
					email
					firstName
					id
					lastName
					phoneNumber
					roles
				}
			}
			Plan {
				type
				... on GrainFeedPlan {
					id
					customerId
					feedCategoryId
					startDate
					hasWriteAccess
					feedYear
					Customer {
						id
						name
						RelationshipOwner {
							id
							firstName
							lastName
						}
					}
					FeedCategory {
						id
						name
					}
				}
			}
		}
	}
`;

type Query_TargetArgs = {
	where: GrainTargetOrderFilterDTO;
};

type getTarget = {
	__typename?: 'Query';
	GrainTargetOrder: GrainTargetOrder;
};
interface QueryParams {
	id: number;
	showClosed: boolean;
}
export default class VgsFeedServicesTargetsShow extends Route {
	@tracked variables: any = {};

	getTarget = useQuery<getTarget, Query_TargetArgs>(this, () => [GET_TARGET, { variables: this.variables, pollInterval: 10000 }]);

	async model(params: QueryParams) {
		const targetId = params.id;

		this.variables = {
			id: targetId,
		};

		await this.getTarget.promise;

		return { target: this.getTarget };
	}
}
