import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { gql, useMutation } from 'glimmer-apollo';
import { ModelFrom } from 'vault-client/utils/type-utils';
import route from 'vault-client/routes/vgs/settings/locations';
import { GrainLocationCreateDTO, Mutation, Mutation_createGrainLocationArgs } from 'vault-client/types/graphql-types';

const CREATE_GRAIN_LOCATION = gql`
	mutation CreateGrainLocation($data: GrainLocationCreateDTO!) {
		createGrainLocation(data: $data) {
			id
		}
	}
`;

interface AddGrainLocationButtonArgs {
	model: ModelFrom<route>;
}

export default class AddGrainLocationButton extends Component<AddGrainLocationButtonArgs> {
	@tracked showModal = false;
	@tracked name: string | null = null;
	@tracked errorMessage: string | null = null;

	createLocation = useMutation<{ createGrainLocation: Mutation['createGrainLocation'] }, Mutation_createGrainLocationArgs>(this, () => [
		CREATE_GRAIN_LOCATION,
		{
			onComplete: (): void => {
				this.errorMessage = null;
				this.args.model.getLocations.refetch();
				this.closeModal();
			},
			onError: (error): void => {
				this.errorMessage = 'There was an error. Location not added.';
				console.error('Error while attempting to add Location', error.message);
			},
		},
	]);

	get submitError() {
		return !!this.errorMessage;
	}

	@action
	openModal() {
		this.showModal = true;
	}

	@action
	closeModal() {
		this.showModal = false;
		this.name = null;
		this.errorMessage = null;
	}

	@action
	async submit() {
		this.errorMessage = null;
		if (!this.name) {
			this.errorMessage = 'Name is required.';
			return;
		}

		const data: GrainLocationCreateDTO = {
			name: this.name,
		};

		await this.createLocation.mutate({
			data,
		});
	}
}
