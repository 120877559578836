import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @buyer}}\n\t<Vault::UiConfirmation as |confirmation|>\n\t\t<confirmation.title>Remove Buyer</confirmation.title>\n\t\t<confirmation.body>Are you sure that you want to remove this Buyer?\n\t\t\t<br />\n\t\t\t<span class='font-sans-semibold'>\n\t\t\t\t{{@buyer.name}}\n\t\t\t</span>\n\t\t\t<br />\n\t\t\tYou cannot undo this action\n\t\t</confirmation.body>\n\t\t<confirmation.cancel @style='plain' {{on 'click' this.closeConfirmation}}>Cancel</confirmation.cancel>\n\t\t<confirmation.action @style='destructive' @disabled={{this.submitting}} {{on 'click' this.removeBuyer}}>\n\t\t\t{{#if this.isSubmitting}}\n\t\t\t\t<span class='mr-2'>Submitting...</span>\n\t\t\t\t<FaIcon @icon='spinner' @spin={{true}} />\n\t\t\t{{else}}\n\t\t\t\tRemove Buyer\n\t\t\t{{/if}}</confirmation.action>\n\t</Vault::UiConfirmation>\n{{/if}}", {"contents":"{{#if @buyer}}\n\t<Vault::UiConfirmation as |confirmation|>\n\t\t<confirmation.title>Remove Buyer</confirmation.title>\n\t\t<confirmation.body>Are you sure that you want to remove this Buyer?\n\t\t\t<br />\n\t\t\t<span class='font-sans-semibold'>\n\t\t\t\t{{@buyer.name}}\n\t\t\t</span>\n\t\t\t<br />\n\t\t\tYou cannot undo this action\n\t\t</confirmation.body>\n\t\t<confirmation.cancel @style='plain' {{on 'click' this.closeConfirmation}}>Cancel</confirmation.cancel>\n\t\t<confirmation.action @style='destructive' @disabled={{this.submitting}} {{on 'click' this.removeBuyer}}>\n\t\t\t{{#if this.isSubmitting}}\n\t\t\t\t<span class='mr-2'>Submitting...</span>\n\t\t\t\t<FaIcon @icon='spinner' @spin={{true}} />\n\t\t\t{{else}}\n\t\t\t\tRemove Buyer\n\t\t\t{{/if}}</confirmation.action>\n\t</Vault::UiConfirmation>\n{{/if}}","moduleName":"vault-client/components/delete-grain-buyer-button.hbs","parseOptions":{"srcName":"vault-client/components/delete-grain-buyer-button.hbs"}});
import Component from '@glimmer/component';
import { gql, useMutation } from 'glimmer-apollo';
import { Mutation } from 'vault-client/types/graphql-types';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';

interface DeleteGrainBuyerButtonArgs {
	buyer: {
		id: string;
		name: string;
	};
	closeModalFn: () => void;
}

const DELETE_GRAIN_BUYER = gql`
	mutation deleteGrainBuyer($id: String!) {
		deleteBuyer(id: $id) {
			id
		}
	}
`;

export default class DeleteGrainBuyerButtonComponent extends Component<DeleteGrainBuyerButtonArgs> {
	@tracked isSubmitting = false;
	deleteBuyer = useMutation<Mutation['deleteHedgeStrategy'], { id: string }>(this, () => [
		DELETE_GRAIN_BUYER,
		{
			update: (cache) => {
				cache.evict({ fieldName: 'Buyer' });
				cache.evict({ fieldName: 'Buyers' });
				cache.gc();
			},
			onComplete: (): void => {
				this.closeConfirmation();
			},
			onError: (error): void => {
				console.error('Error while attempting to delete Buyer', error.message);
				this.closeConfirmation();
			},
		},
	]);

	@action
	async removeBuyer() {
		this.isSubmitting = true;
		if (this.args.buyer.id) {
			const deleteBuyer = this.deleteBuyer.mutate({ id: this.args.buyer.id });
			await deleteBuyer;
			this.isSubmitting = false;
		} else {
			this.isSubmitting = false;
			console.error('Error while attempting to delete Buyer. No Buyer Id defined.');
			throw new Error('Buyer Id is not defined');
		}
	}

	@action
	closeConfirmation() {
		this.args.closeModalFn();
	}
}
