import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{this.formattedValue}}", {"contents":"{{this.formattedValue}}","moduleName":"vault-client/components/market-data-property.hbs","parseOptions":{"srcName":"vault-client/components/market-data-property.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

interface MarketDataPropertyArgs {
	rowData: any;
	valuePath: string;
	property: string;
	formatter?: (value: unknown) => string | number;
}

export default class MarketDataPropertyComponent extends Component<MarketDataPropertyArgs> {
	@service marketData: any;

	registeredSymbols: string[] = [];

	registerSymbol(symbol: string) {
		if (!this.registeredSymbols.includes(symbol)) {
			this.marketData.register(symbol);
			this.registeredSymbols.push(symbol);
		}
	}

	getByCompositeKey(obj: any, key: string) {
		if (!key) {
			return null;
		}

		return key.split('.').reduce((prev, itm) => (prev ? prev[itm] : null), obj) || null;
	}

	get formattedValue() {
		const symbol = this.args.rowData?.barchartSymbol ?? this.getByCompositeKey(this.args.rowData, `${this.args.valuePath}.barchartSymbol`);
		this.registerSymbol(symbol);

		const marketDatum = this.marketData.getMarketDatum(symbol);
		let value;

		// This is hacky and should be cleaned up.
		const showValue =
			this.getByCompositeKey(this.args.rowData, `${this.args.valuePath}.instrumentType`) == 'Future' ||
			!this.getByCompositeKey(this.args.rowData, `${this.args.valuePath}.hasExpired`);

		if (marketDatum) {
			value = marketDatum[this.args.property];
		}

		if (value && showValue) {
			if (this.args.formatter) {
				return this.args.formatter(value);
			} else {
				return isNaN(+value) ? value : Intl.NumberFormat().format(value);
			}
		} else {
			return '-';
		}
	}

	willDestroy() {
		super.willDestroy();
		this.registeredSymbols.forEach((symbol) => {
			this.marketData.unregister(symbol);
		});
	}
}
