import Route from '@ember/routing/route';
import { AccountFilterDTO, CurrentPositionFilterDTO, TransactionFilterDTO } from 'vault-client/types/graphql-types';
import { queryManager } from 'ember-apollo-client';
import query from 'vault-client/graphql/queries/brokerage/index.graphql';
import { DateTime } from 'luxon';

export default class BrokerageIndexRoute extends Route {
	@queryManager apollo: any;

	templateName = 'brokerage/index';

	query = query;

	generateWhere(
		organizationId: null | string,
		customerId: undefined | null | string
	): { positionsWhere: CurrentPositionFilterDTO; transactionsWhere: TransactionFilterDTO; accountsWhere: AccountFilterDTO } {
		const positionsWhere: CurrentPositionFilterDTO = {};
		const transactionsWhere: TransactionFilterDTO = {};
		const accountsWhere: AccountFilterDTO = {};

		if (customerId) {
			if (!positionsWhere.Account) {
				positionsWhere.Account = {};
			}

			transactionsWhere.Account = {
				customerId: { equals: customerId },
			};

			positionsWhere.Account.customerId = { equals: customerId };
			accountsWhere.customerId = { equals: customerId };
		} else if (organizationId) {
			positionsWhere.Account = {
				OR: [
					{
						Customer: {
							organizationId: { equals: organizationId },
						},
					},
					{
						ownerId: { equals: organizationId },
					},
				],
			};

			transactionsWhere.Account = {
				OR: [
					{
						Customer: {
							organizationId: { equals: organizationId },
						},
					},
					{
						ownerId: { equals: organizationId },
					},
				],
			};

			accountsWhere.OR = [
				{
					Customer: { organizationId: { equals: organizationId } },
				},
				{
					ownerId: { equals: organizationId },
				},
			];
		}

		positionsWhere.quantity = { not: { equals: 0 } };
		positionsWhere.displayExpiresAt = { gte: DateTime.local().toISODate() };

		return { positionsWhere, transactionsWhere, accountsWhere };
	}
}
