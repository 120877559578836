import ReportsButterStrategicBuyingModel from 'vault-client/controllers/reports/butter-strategic-buying-model';

export default class BusinessesBusinessButterStrategicBuyingModel extends ReportsButterStrategicBuyingModel {
	reportsRoute: string = 'businesses.business.reports';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'businesses/business/butter-strategic-buying-model': BusinessesBusinessButterStrategicBuyingModel;
	}
}
