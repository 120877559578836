import Controller from '@ember/controller';
import VgsGrainServicesFillsShowRoute from 'vault-client/routes/vgs/grain-services/fills/show';
import { ModelFrom } from 'vault-client/utils/type-utils';
import Fill from 'vault-client/models/vgs/fill';
import { inject as service } from '@ember/service';
import { action } from '@ember/object';
import PermissionsService from 'vault-client/services/permissions';
import RouterService from '@ember/routing/router-service';
import { informationWasUpdated } from 'vault-client/utils/vgs-utils';
import { GrainFillOrderModificationStatus, TypeOfGrainOrder } from 'vault-client/types/graphql-types';
export default class VgsGrainServicesFillsShow extends Controller {
	@service declare permissions: PermissionsService;
	@service declare router: RouterService;
	declare model: ModelFrom<VgsGrainServicesFillsShowRoute>;

	get fillModel() {
		const fillData = this.model.getGrainFill.data?.GrainFillOrder;
		const fillNotesData = this.fillNotes;
		const targetNotesData = this.targetNotes;

		return fillData ? new Fill(fillData, null, null, fillNotesData, targetNotesData, null, null) : null;
	}

	get disableEdit() {
		return this.modificationRequests && this.modificationRequests?.length > 0;
	}

	get modificationRequests() {
		return this.model.getGrainFill.data?.GrainFillOrder?.GrainFillOrderModificationRequests?.filter(
			(req) =>
				req.status != GrainFillOrderModificationStatus.Cancelled &&
				req.status != GrainFillOrderModificationStatus.Rejected &&
				req.status != GrainFillOrderModificationStatus.Filled
		);
	}

	get filteredActivities() {
		return this.fillModel?.orderActivities?.filter((activity) => informationWasUpdated(activity)) ?? [];
	}

	get fillNotes() {
		return (
			this.model.getGrainFill.data?.GrainFillOrder?.Notes.filter((orderNotes) => {
				return orderNotes.Order.type == TypeOfGrainOrder.Fill;
			}) ?? []
		);
	}

	get targetNotes() {
		return (
			this.model.getGrainFill.data?.GrainFillOrder?.GrainTargetOrder.Notes.filter((orderNotes) => {
				return orderNotes.Order.type == TypeOfGrainOrder.Target;
			}) ?? []
		);
	}

	@action
	transitionTo(id: number) {
		this.router.transitionTo('vgs.grain-services.crop-plans.show', id);
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	interface Registry {
		'vgs/grain-services/fills/show': VgsGrainServicesFillsShow;
	}
}
