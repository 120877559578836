import AccountsShowController from 'vault-client/controllers/accounts/show';

export default class BusinessesBusinessAccount extends AccountsShowController {
	accountsRoute = 'businesses.business.accounts';
	positionsRoute = 'businesses.business.positions';
	transactionsRoute = 'businesses.business.transactions';
	transactionRoute = 'businesses.business.transaction';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'businesses/business/account': BusinessesBusinessAccount;
	}
}
