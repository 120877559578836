import ReportsNfdmStrategicBuyingModel from 'vault-client/controllers/reports/nfdm-strategic-buying-model';

export default class OrganizationsOrganizationNfdmStrategicBuyingModel extends ReportsNfdmStrategicBuyingModel {
	reportsRoute: string = 'organizations.organization.reports';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'organizations/organization/nfdm-strategic-buying-model': OrganizationsOrganizationNfdmStrategicBuyingModel;
	}
}
