import Component from '@glimmer/component';
import { useQuery, gql } from 'glimmer-apollo';
import { InsuranceHistoricalDrpPremiumFilterDTO, DairyRPPracticeCodeType, PracticeRequestDTO } from 'vault-client/types/graphql-types';

interface DrpNetCoverageLevelsCardArgs {}

const FETCH_DRP_PRACTICES = gql`
	query fetchDrpPractices($where: PracticeRequestDTO!) {
		fetchDrpPractices(where: $where) {
			name
			expectedClassIIIPrice
			expectedClassIVPrice
			practiceAbbreviation
			practiceCode
			quarter
			year
		}
	}
`;

type FetchDrpPracticesQuery = {
	__typename?: 'Query';
	fetchDrpPractices: DairyRPPracticeCodeType[];
};

type FetchDrpPracticesQueryVariables = {
	where?: PracticeRequestDTO;
};

const HISTORICAL_DRP_PREMIUMS = gql`
	query historicalDrpPremiums($where: InsuranceHistoricalDrpPremiumFilterDTO) {
		InsuranceHistoricalDrpPremiums(where: $where) {
			insurancePracticeDisplayDate
			date
			protectedPrice
			netPremium
		}
	}
`;

type HistoricalDrpPremiumQuery = {
	__typename?: 'Query';
	historicalDrpPremiums: {
		__typename?: 'InsuranceHistoricalDrpPremium';
		insurancePracticeDisplayDate: string;
		date: string;
		protectedPrice: number;
		netPremium: number;
	}[];
};

type HistoricalDrpPremiumQueryVariables = {
	where: InsuranceHistoricalDrpPremiumFilterDTO;
};

export default class DrpNetCoverageLevelsCard extends Component<DrpNetCoverageLevelsCardArgs> {
	stateAbbreviation = 'MO';
	coverageLevel = 0.95;

	fetchDrpPractices = useQuery<FetchDrpPracticesQuery, FetchDrpPracticesQueryVariables>(this, () => [
		FETCH_DRP_PRACTICES,
		{ variables: { where: { stateAbbreviation: this.stateAbbreviation } } },
	]);
	getClassIiiHistoricalPremiums = useQuery<HistoricalDrpPremiumQuery, HistoricalDrpPremiumQueryVariables>(this, () => [
		HISTORICAL_DRP_PREMIUMS,
		{
			variables: {
				where: {
					State: {
						abbreviation: {
							equals: this.stateAbbreviation,
						},
					},
					coverageLevel: {
						equals: this.coverageLevel,
					},
					InsuranceHistoricalPremiumInput: {
						isFullyClassIii: {
							equals: true,
						},
					},
					InsurancePractice: {
						reinsuranceYear: {
							equals: this.fetchDrpPractices?.data?.fetchDrpPractices[0].reinsuranceYear,
						},
						practiceCode: {
							in: this.fetchDrpPractices?.data?.fetchDrpPractices.map((p) => p.practiceCode),
						},
					},
				},
			},
		},
	]);

	getClassIvHistoricalPremiums = useQuery<HistoricalDrpPremiumQuery, HistoricalDrpPremiumQueryVariables>(this, () => [
		HISTORICAL_DRP_PREMIUMS,
		{
			variables: {
				where: {
					State: {
						abbreviation: {
							equals: this.stateAbbreviation,
						},
					},
					coverageLevel: {
						equals: this.coverageLevel,
					},
					InsuranceHistoricalPremiumInput: {
						isFullyClassIv: {
							equals: true,
						},
					},
					InsurancePractice: {
						reinsuranceYear: {
							equals: this.fetchDrpPractices?.data?.fetchDrpPractices[0].reinsuranceYear,
						},
						practiceCode: {
							in: this.fetchDrpPractices?.data?.fetchDrpPractices.map((p) => p.practiceCode),
						},
					},
				},
			},
		},
	]);

	// async getCoverageLevels(stateId: string, coverageLevel: number): Promise<{ historicalDrpPremiums: InsuranceHistoricalDrpPremium[] }> {
	// 	const where: InsuranceHistoricalDrpPremiumFilterDTO = {
	// 		stateId: { equals: stateId },
	// 		coverageLevel: { equals: coverageLevel },
	// 		InsuranceHistoricalPremiumInput: {
	// 			isFullyClassIii: { equals: true },
	// 		},
	// 		insurancePracticeDisplayDate: {
	// 			gt: DateTime.now().toISODate(),
	// 		},
	// 	};

	// 	const variables = {
	// 		where,
	// 	};

	// 	return this.apollo.watchQuery({ query: historicalPremiumQuery, variables });
	// }

	// async getCurrentPractices(stateAbbreviation: string): Promise<{ fetchDrpPractices: DairyRPPracticeCodeType[] }> {
	// 	const where: PracticeRequestDTO = {
	// 		stateAbbreviation,
	// 	};
	// 	const variables = {
	// 		where,
	// 	};
	// 	return this.apollo.watchQuery({ query: currentPracticesQuery, variables });
	// }

	get classThreeData() {
		return this.getClassIiiHistoricalPremiums.data;
	}
}
