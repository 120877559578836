import { getOwner } from '@ember/application';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { cached, tracked } from '@glimmer/tracking';
import { DateTime, Interval } from 'luxon';
import { CoreScaleOptions, Scale } from 'chart.js';
import BusinessesBusinessMilkCheckReconciliationRoute from 'vault-client/routes/businesses/business/milk-check-reconciliation';
import LocationsLocationMilkCheckReconciliationRoute from 'vault-client/routes/locations/location/milk-check-reconciliation';
import {
	AggregateForecastedMilkProductionByMonthDTO,
	AggregateLedgerForecastedEntryDTO,
	CustomerEntity,
	ForecastedMilkProductionByMonth,
	ForecastedMilkUtilization,
	Future,
	LocationEntity,
	TypeOfEntity,
	TypeOfLedgerEntry,
} from 'vault-client/types/graphql-types';
import { CellComponents, TableColumn } from 'vault-client/types/vault-table';
import { ModelFrom } from 'vault-client/utils/type-utils';
import {
	calculateMonthlyWeightedPricesAndBasisValues,
	calculateMonthlyWeightedProjectedValues,
	calculateUtilizationValuesFromForecastedProduction,
} from 'vault-client/utils/milk-check-utils';
import MilkCheck from 'vault-client/models/milk-check';

type Unit = 'Per CWT' | 'Per Cow' | 'Total';
type AvailableChart = 'Mailbox Price' | 'Class I' | 'Class II' | 'Blended Milk Price Basis';
interface MilkCheckReconciliationRow {
	id: string;
	month: string;
	classIProjected: number | null;
	classIActual: number | null;
	classIIProjected: number | null;
	classIIActual: number | null;
	classIIIActual: number | null;
	classIVActual: number | null;
	blendedProjected: number | null;
	blendedActual: number | null;
	forecastedBasis: number | null;
	actualBasis: number | null;
	classIIIBasis: number | null;
	classIVBasis: number | null;
	premiumsAndDeductionsProjected: number | null;
	premiumsAndDeductionsActual: number | null;
	mailboxProjected: number | null;
	mailboxActual: number | null;
}

interface BasisRow {
	rolling: string;
	classIII: number | null;
	classIV: number | null;
	everAg: number | null;
}

interface IMonthObject {
	date: string;
	utilizations?: ForecastedMilkUtilization;
	classiiiFuture: Future | null;
	classivFuture: Future | null;
	butterFuture: Future | null;
	advancedNonfatDryMilkFuture: Future | null;
	advancedButterFuture: Future | null;
	advancedCheeseFuture: Future | null;
	advancedDryWheyFuture: Future | null;
	productionForecasted: AggregateForecastedMilkProductionByMonthDTO | undefined;
	productionActual: number | null;
	monthlyCalculatedAmount?: number | null;
	monthlyActualAmounts: number | null;
	numberOfCowsForecasted: number | null;
	numberOfCowsActual: number | null;
	weightedClassIDifferential: number | null;
	grossClassIProductionForecasted: number | null;
	grossClassIIProductionForecasted: number | null;
	grossClassIIIProductionForecasted: number | null;
	grossClassIVProductionForecasted: number | null;
	forecastedBlendedMilkPrice: number | null;
}

export default class MilkCheckReconciliationController extends Controller {
	@tracked year = DateTime.now().year;
	@tracked activeUnit: Unit = 'Per CWT';
	@tracked activeChart: AvailableChart = 'Mailbox Price';
	@tracked selectedRows: MilkCheckReconciliationRow[] = [];
	@tracked selectedLocationId: string | null = null;
	@tracked basisStartDate = DateTime.now().minus({ month: 36 }).startOf('month').toISODate();
	@tracked basisEndDate = DateTime.now().endOf('month').toISODate();

	declare model: ModelFrom<BusinessesBusinessMilkCheckReconciliationRoute> | ModelFrom<LocationsLocationMilkCheckReconciliationRoute>;

	milkRoutePath: string | null = null;

	queryParams = ['year', 'selectedLocationId', 'basisStartDate', 'basisEndDate'];

	get availableUnits(): Unit[] {
		return ['Per CWT', 'Per Cow', 'Total'];
	}

	get availableCharts(): AvailableChart[] {
		return ['Mailbox Price', 'Class I', 'Class II', 'Blended Milk Price Basis'];
	}

	get chartLabels() {
		// Only chart months that have USDA Price Data available
		return this.rowsWithUSDAActualPrices.map((row) => row.month);
	}

	get isChartDataEmpty() {
		return !Object.values(this.activeChartData).some((v) => v && v.length > 0);
	}

	get locations() {
		if (this.model.getMilkCheckReconciliation.data?.Entity?.type === TypeOfEntity.Location) return [];

		return (this.model.getMilkCheckReconciliation.data?.Entity as CustomerEntity | undefined)?.Locations ?? [];
	}

	get shouldIncludePremiumsAndDeductions() {
		// Only include premiums and deductions in two cases:
		//    - If a user has one location
		//    - If a user does not have a location selected (All)
		// Varying production amounts between locations will make these cwt values wonky.

		return this.locations.length === 1 || !this.selectedLocationId;
	}

	get rowsWithUSDAActualPrices() {
		// Check if actual prices were set. This essentially checks if ForecastedMilkProductionByMonths returned actuals
		return this.milkCheckReconciliationRows.filter(
			(row) => row.classIActual || row.classIIActual || row.classIIIActual || row.classIVActual
		);
	}

	get activeChartData(): Record<string, (number | null)[] | undefined> {
		// Only chart months that have USDA Price Data available
		const rows = this.rowsWithUSDAActualPrices;
		switch (this.activeChart) {
			case 'Mailbox Price':
				return {
					Projected: rows.map((row) => row.mailboxProjected),
					Actual: rows.map((row) => row.mailboxActual),
				};
			case 'Class I':
				return {
					Projected: rows.map((row) => row.classIProjected),
					Actual: rows.map((row) => row.classIActual),
				};
			case 'Class II':
				return {
					Projected: rows.map((row) => row.classIIProjected),
					Actual: rows.map((row) => row.classIIActual),
				};
			case 'Blended Milk Price Basis':
				return {
					'Class III': rows.map((row) => row.classIIIBasis),
					'Class IV': rows.map((row) => row.classIVBasis),
					'Ever.Ag Blend': rows.map((row) => row.actualBasis),
				};
			default:
				return {};
		}
	}

	get xScaleOptions(): {
		ticks: {
			callback: (this: Scale<CoreScaleOptions>, _value: any, index: number) => string;
		};
	} {
		return {
			ticks: {
				callback: function (_value: any, index: number) {
					const currDate = this.getLabelForValue(index) as string | null;
					const prevIndex = index - 1;
					const prevDate = prevIndex >= 0 ? this.getLabelForValue(prevIndex) : null;

					const currDateTime = currDate ? DateTime.fromISO(currDate) : null;
					const currYear = currDateTime?.year;
					const prevDateTime = prevDate ? DateTime.fromISO(prevDate) : null;
					const prevYear = prevDateTime?.year ?? null;

					if (!prevYear || prevYear !== currYear) {
						return currDateTime?.toFormat('LLL yyyy') ?? '';
					} else {
						return currDateTime?.toFormat('LLL') ?? '';
					}
				},
			},
		};
	}

	get yScaleOptions(): {
		ticks: {
			callback: (this: Scale<CoreScaleOptions>, value: any, index: number) => string;
		};
	} {
		return {
			ticks: {
				callback: (value: any) => {
					if (this.activeUnit !== 'Per CWT' && this.activeChart === 'Mailbox Price') {
						const val = Intl.NumberFormat(undefined, {
							style: 'currency',
							currency: 'USD',
							compactDisplay: 'short',
							notation: 'compact',
						}).format(value);

						return val;
					} else {
						const val = Intl.NumberFormat(undefined, {
							style: 'currency',
							currency: 'USD',
							minimumFractionDigits: 2,
							maximumFractionDigits: 2,
						}).format(value);

						return val;
					}
				},
			},
		};
	}

	get activeMonths(): string[] {
		const labels: string[] = [];
		let currDateTime = this.startDateTime;
		const endDateTime = this.endDateTime;

		while (currDateTime <= endDateTime) {
			labels.push(currDateTime.toISODate());
			currDateTime = currDateTime.plus({ month: 1 });
		}

		return labels;
	}

	get startDateTime() {
		return DateTime.fromObject({ year: this.year, month: DateTime.now().month }).minus({ months: 37 }).startOf('month');
	}

	get endDateTime() {
		return DateTime.fromObject({ year: this.year, month: DateTime.now().month }).endOf('month');
	}

	get currentLocation() {
		if (this.model.getMilkCheckReconciliation.data?.Entity?.type === TypeOfEntity.Customer) {
			const customer = this.model.getMilkCheckReconciliation.data?.Entity as CustomerEntity;
			return customer.Locations.find((location) => location.id === this.selectedLocationId) ?? null;
		} else if (this.model.getMilkCheckReconciliation.data?.Entity?.type === TypeOfEntity.Location) {
			return this.model.getMilkCheckReconciliation.data.Entity as LocationEntity;
		}
		return null;
	}

	get locationString() {
		const location = this.currentLocation;

		if (!location) return null;

		const countyName = location.County.name;
		const stateName = location.County.State.name;
		const classIDifferential = location.County.classIDifferential;
		const classIDifferentialString = classIDifferential
			? `(${new Intl.NumberFormat(undefined, {
					style: 'currency',
					currency: 'USD',
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
			  }).format(classIDifferential)} Class I Differential)`
			: '';
		return `Prices reflect ${countyName}, ${stateName} ${classIDifferentialString}`;
	}

	get basisRollingAverages(): BasisRow[] {
		const datesWithActualBlendedPrice =
			[...new Set(this.model.getMilkCheckReconciliation.data?.ActualBlendedMilkPrices.filter((v) => v.price).map((v) => v.date))] ?? [];
		const datesWithActualUSDAData =
			[...new Set(this.model.getMilkCheckReconciliation.data?.BasisRangeUsdaActualMilkPrices.map((v) => v.date))] ?? [];

		// Limit months to the past 36 calendar months that have actuals
		const startDate = this.basisStartDate;
		const endDate = this.basisEndDate;
		const months = this.milkChecks.filter(
			(milkCheck) => milkCheck.date >= startDate && milkCheck.date <= endDate && datesWithActualUSDAData.includes(milkCheck.date)
		);

		// Sort desc
		months.sort((a, b) => (a.date < b.date ? 1 : -1));

		// Get N months, then filter out months without Actual Blended Prices
		const pastTwelveMonths = months.slice(0, 12).filter((milkCheck) => datesWithActualBlendedPrice.includes(milkCheck.date));
		const pastTwentyFourMonths = months.slice(0, 24).filter((milkCheck) => datesWithActualBlendedPrice.includes(milkCheck.date));
		const pastThirtySixMonths = months.slice(0, 36).filter((milkCheck) => datesWithActualBlendedPrice.includes(milkCheck.date));

		return [
			{
				rolling: '12 Mo',
				classIII:
					pastTwelveMonths.length > 0
						? pastTwelveMonths.reduce((acc, month) => acc + (month.classIIIBasis ?? 0), 0) / pastTwelveMonths.length
						: null,
				classIV:
					pastTwelveMonths.length > 0
						? pastTwelveMonths.reduce((acc, month) => acc + (month.classIVBasis ?? 0), 0) / pastTwelveMonths.length
						: null,
				everAg:
					pastTwelveMonths.length > 0
						? pastTwelveMonths.reduce((acc, month) => acc + (month.everAgBasisActualBased ?? 0), 0) / pastTwelveMonths.length
						: null,
			},
			{
				rolling: '24 Mo',
				classIII:
					pastTwentyFourMonths.length > 0
						? pastTwentyFourMonths.reduce((acc, month) => acc + (month.classIIIBasis ?? 0), 0) / pastTwentyFourMonths.length
						: null,
				classIV:
					pastTwentyFourMonths.length > 0
						? pastTwentyFourMonths.reduce((acc, month) => acc + (month.classIVBasis ?? 0), 0) / pastTwentyFourMonths.length
						: null,
				everAg:
					pastTwentyFourMonths.length > 0
						? pastTwentyFourMonths.reduce((acc, month) => acc + (month.everAgBasisActualBased ?? 0), 0) / pastTwentyFourMonths.length
						: null,
			},
			{
				rolling: '36 Mo',
				classIII:
					pastThirtySixMonths.length > 0
						? pastThirtySixMonths.reduce((acc, month) => acc + (month.classIIIBasis ?? 0), 0) / pastThirtySixMonths.length
						: null,
				classIV:
					pastThirtySixMonths.length > 0
						? pastThirtySixMonths.reduce((acc, month) => acc + (month.classIVBasis ?? 0), 0) / pastThirtySixMonths.length
						: null,
				everAg:
					pastThirtySixMonths.length > 0
						? pastThirtySixMonths.reduce((acc, month) => acc + (month.everAgBasisActualBased ?? 0), 0) / pastThirtySixMonths.length
						: null,
			},
		];
	}

	get basisAverageColumns(): TableColumn[] {
		return [
			{
				id: 'ce1f9276-2842-4867-a36e-4855051ba0f2',
				name: 'Rolling',
				valuePath: 'rolling',
				width: 120,
				textAlign: 'center',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '44e53687-9a05-4e79-848c-6970daabf769',
				name: 'Class III',
				valuePath: 'classIII',
				width: 120,
				textAlign: 'right',
				isSortable: false,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				isFixed: '',
				isVisible: true,
			},
			{
				id: '61eddc8b-0f59-49a8-abc5-c855ed82f9ff',
				name: 'Class IV',
				valuePath: 'classIV',
				width: 120,
				textAlign: 'right',
				isSortable: false,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				isFixed: '',
				isVisible: true,
			},
			{
				id: '0d15b893-6b0c-4801-8baf-e674d6db0b0b',
				name: 'Blend',
				valuePath: 'everAg',
				width: 120,
				textAlign: 'right',
				isSortable: false,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				isFixed: '',
				isVisible: true,
			},
		];
	}

	get milkCheckReconciliationColumns(): TableColumn[] {
		return [
			{
				id: '4c741236-216a-4f05-8336-7becb52fc68e',
				name: 'Month',
				valuePath: 'month',
				width: 100,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.MonthFormat,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '9a6967df-43f4-4aa0-b149-c2a512ebe1d0',
				name: 'Class I',
				valuePath: '',
				textAlign: 'center',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: '6b5b7ebf-a893-464d-910e-9925f153ebee',
						name: 'Proj.',
						valuePath: 'classIProjected',
						width: 90,
						textAlign: 'right',
						isSortable: false,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							currencySign: 'accounting',
						},
						isFixed: '',
						isVisible: true,
					},
					{
						id: 'ba351ce6-1dd3-4768-92e3-8be0820b88be',
						name: 'Actual',
						valuePath: 'classIActual',
						width: 90,
						textAlign: 'right',
						isSortable: false,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							currencySign: 'accounting',
						},
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: 'cf155671-1f12-47e2-b3a0-85de68618840',
				name: 'Class II',
				valuePath: '',
				textAlign: 'center',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: '17250da7-ee4e-46b6-b264-d73409b5ba60',
						name: 'Proj.',
						valuePath: 'classIIProjected',
						width: 90,
						textAlign: 'right',
						isSortable: false,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							currencySign: 'accounting',
						},
						isFixed: '',
						isVisible: true,
					},
					{
						id: 'fbb1e981-cdcb-4c50-813b-9c02f2515c86',
						name: 'Actual',
						valuePath: 'classIIActual',
						width: 90,
						textAlign: 'right',
						isSortable: false,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							currencySign: 'accounting',
						},
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: '858d017c-c85a-407a-a8fa-8fc63e3db9ea',
				name: 'Class III',
				valuePath: '',
				textAlign: 'center',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: '28aa5fb2-8d01-4192-82ae-d23692bd7bbf',
						name: 'Actual',
						valuePath: 'classIIIActual',
						width: 90,
						textAlign: 'right',
						isSortable: false,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							currencySign: 'accounting',
						},
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: '981cc0b7-bea6-4431-945d-c7a65f2ff3dd',
				name: 'Class IV',
				valuePath: '',
				textAlign: 'center',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: '4a52d35e-d4a4-413e-b722-85d1eaba7d83',
						name: 'Actual',
						valuePath: 'classIVActual',
						width: 90,
						textAlign: 'right',
						isSortable: false,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							currencySign: 'accounting',
						},
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: '519a5856-a11f-4a8d-b54a-2b8483ce82eb',
				name: 'Blended Price',
				valuePath: '',
				textAlign: 'center',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: 'c297c472-afa9-4f70-ac2b-6f087aa4a4c8',
						name: 'Proj.',
						valuePath: 'blendedProjected',
						width: 90,
						textAlign: 'right',
						isSortable: false,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							currencySign: 'accounting',
						},
						isFixed: '',
						isVisible: true,
					},
					{
						id: '87aa470c-eec6-4f0d-b9ee-46bd3f71e25c',
						name: 'Actual',
						valuePath: 'blendedActual',
						width: 90,
						textAlign: 'right',
						isSortable: false,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							currencySign: 'accounting',
						},
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: 'c0b53b8b-54fd-4244-96b2-e001bd41c5e2',
				name: 'Basis',
				valuePath: '',
				textAlign: 'center',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: 'c5e670ef-142e-4adc-bcb4-cf2053d93df6',
						name: 'Forecasted',
						valuePath: 'forecastedBasis',
						width: 110,
						textAlign: 'right',
						isSortable: false,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							currencySign: 'accounting',
						},
						isFixed: '',
						isVisible: true,
					},
					{
						id: '15e102e6-733e-4e99-b41c-b6654fb2fb7a',
						name: 'Blend',
						valuePath: 'actualBasis',
						width: 100,
						textAlign: 'right',
						isSortable: false,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							currencySign: 'accounting',
						},
						isFixed: '',
						isVisible: true,
					},
					{
						id: '4f1079b0-9e77-47f4-9a2e-50cec44cac24',
						name: 'Class III',
						valuePath: 'classIIIBasis',
						width: 100,
						textAlign: 'right',
						isSortable: false,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							currencySign: 'accounting',
						},
						isFixed: '',
						isVisible: true,
					},
					{
						id: '2be3e620-824d-40d3-89ae-67a70aa86d64',
						name: 'Class IV',
						valuePath: 'classIVBasis',
						width: 100,
						textAlign: 'right',
						isSortable: false,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							currencySign: 'accounting',
						},
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: '40e874c5-e264-4454-910c-12c1a343925e',
				name: 'Prem / Ded',
				valuePath: '',
				textAlign: 'center',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: '8237caa9-3c35-4fc0-89b4-8bfb5a80356f',
						name: 'Proj.',
						valuePath: 'premiumsAndDeductionsProjected',
						width: 90,
						textAlign: 'right',
						isSortable: false,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							currencySign: 'accounting',
						},
						isFixed: '',
						isVisible: true,
					},
					{
						id: '11eae54f-2ed9-4a4d-9aca-c51ba668ffec',
						name: 'Actual',
						valuePath: 'premiumsAndDeductionsActual',
						width: 90,
						textAlign: 'right',
						isSortable: false,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							currencySign: 'accounting',
						},
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: '4eb4e839-1afb-451a-8d43-f5519c6a53da',
				name: 'Mailbox Price',
				valuePath: '',
				textAlign: 'center',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: '7e5738fe-c216-474c-8d11-85ac94899812',
						name: 'Proj.',
						valuePath: 'mailboxProjected',
						width: 90,
						textAlign: 'right',
						isSortable: false,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							currencySign: 'accounting',
						},
						isFixed: '',
						isVisible: true,
					},
					{
						id: 'cc6f0c2e-4f12-4796-8fbc-9d492edf3388',
						name: 'Actual',
						valuePath: 'mailboxActual',
						width: 90,
						textAlign: 'right',
						isSortable: false,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							currencySign: 'accounting',
						},
						isFixed: '',
						isVisible: true,
					},
				],
			},
		];
	}

	@cached
	get milkCheckReconciliationRows(): MilkCheckReconciliationRow[] {
		const unit = this.activeUnit;
		// Get most recent two years of active month data
		return this.milkChecks
			.filter((v) => this.activeMonths.includes(v.date))
			.slice(-24)
			.map((milkCheck) => {
				// Use forecasted production for actuals calculations for now
				const productionCwtForecasted = milkCheck.grossProduction ? milkCheck.grossProduction / 100 : null;
				const productionCwtActual = milkCheck.grossProductionActual ? milkCheck.grossProductionActual / 100 : null;

				const numberOfCowsForecasted = milkCheck.numberOfCowsForecasted;
				const numberOfCowsActual = milkCheck.numberOfCowsForecasted;

				let premiumsAndDeductionsProjected = milkCheck.monthlyCalculatedAmount ?? null;
				let premiumsAndDeductionsActual = milkCheck.monthlyActualAmounts ?? null;
				let mailboxProjected = milkCheck.mailboxPrice ?? 0;
				let mailboxActual = milkCheck.mailboxPriceActual ?? 0;

				if (unit === 'Per CWT') {
					premiumsAndDeductionsProjected =
						premiumsAndDeductionsProjected && productionCwtForecasted ? premiumsAndDeductionsProjected / productionCwtForecasted : null;
					premiumsAndDeductionsActual =
						premiumsAndDeductionsActual && productionCwtActual ? premiumsAndDeductionsActual / productionCwtActual : null;
				} else if (unit === 'Per Cow') {
					premiumsAndDeductionsProjected =
						premiumsAndDeductionsProjected && numberOfCowsForecasted ? premiumsAndDeductionsProjected / numberOfCowsForecasted : null;
					premiumsAndDeductionsActual =
						premiumsAndDeductionsActual && numberOfCowsForecasted ? premiumsAndDeductionsActual / numberOfCowsForecasted : null;
					mailboxProjected =
						productionCwtForecasted && numberOfCowsForecasted ? (mailboxProjected * productionCwtForecasted) / numberOfCowsForecasted : 0;
					mailboxActual = productionCwtActual && numberOfCowsActual ? (mailboxActual * productionCwtActual) / numberOfCowsActual : 0;
				} else if (unit === 'Total') {
					// If production found, calculate total using mailbox price. If not, display premiums and deductions
					mailboxProjected = productionCwtForecasted ? mailboxProjected * productionCwtForecasted : premiumsAndDeductionsProjected ?? 0;
					mailboxActual = productionCwtActual ? mailboxActual * productionCwtActual : premiumsAndDeductionsActual ?? 0;
				}

				return {
					id: milkCheck.date,
					month: milkCheck.date,
					classIProjected: milkCheck.classiPrice,
					classIActual: milkCheck.classIActualPrice,
					classIIProjected: milkCheck.classiiPrice,
					classIIActual: milkCheck.classIIActualPrice,
					classIIIActual: milkCheck.classIIIActualPrice,
					classIVActual: milkCheck.classIVActualPrice,
					blendedProjected: milkCheck.forecastedBlendedMilkPrice ?? milkCheck.blendedPrice,
					blendedActual: milkCheck.blendedPriceActual,
					forecastedBasis: milkCheck.everAgBasis,
					actualBasis: milkCheck.everAgBasisActualBased,
					classIIIBasis: milkCheck.classIIIBasis,
					classIVBasis: milkCheck.classIVBasis,
					premiumsAndDeductionsProjected,
					premiumsAndDeductionsActual,
					mailboxProjected,
					mailboxActual,
				};
			});
	}

	get milkChecks() {
		const monthObjects: { [key: string]: IMonthObject | undefined } = {};

		const getOrCreateMonthObj = (date: string) => {
			let monthObject = monthObjects[date];

			if (monthObject === undefined) {
				monthObject = {
					date,
					classiiiFuture: null,
					classivFuture: null,
					butterFuture: null,
					advancedNonfatDryMilkFuture: null,
					advancedButterFuture: null,
					advancedCheeseFuture: null,
					advancedDryWheyFuture: null,
					productionForecasted: undefined,
					productionActual: null,
					monthlyActualAmounts: null,
					numberOfCowsForecasted: null,
					numberOfCowsActual: null,
					weightedClassIDifferential: null,
					grossClassIProductionForecasted: null,
					grossClassIIProductionForecasted: null,
					grossClassIIIProductionForecasted: null,
					grossClassIVProductionForecasted: null,
					forecastedBlendedMilkPrice: null,
				};
				monthObjects[date] = monthObject;
			}

			return monthObject;
		};

		const startDate = DateTime.min(this.startDateTime, DateTime.fromISO(this.basisStartDate));
		const endDate = DateTime.max(this.endDateTime, DateTime.fromISO(this.basisEndDate));
		const monthRange = Interval.fromDateTimes(startDate.startOf('month'), endDate.endOf('month'))
			.splitBy({ month: 1 })
			.map((d) => d.start.toISODate());

		// Create all month objects;
		monthRange.forEach((date) => {
			getOrCreateMonthObj(date);
		});

		const weightedMonthlyPricesAndBasisValues = calculateMonthlyWeightedPricesAndBasisValues(
			monthRange.firstObject ?? null,
			monthRange.lastObject ?? null,
			this.model.getMilkCheckReconciliation.data?.ForecastedMilkProductionByMonths ?? [],
			this.model.getMilkCheckReconciliation.data?.ActualBlendedMilkPrices ?? []
		);

		const weightedUtilizationValues = calculateUtilizationValuesFromForecastedProduction(
			this.model.getMilkCheckReconciliation.data?.ForecastedMilkProductionByMonths ?? []
		);

		const weightedProjectedValues = calculateMonthlyWeightedProjectedValues(
			this.model.getMilkCheckReconciliation.data?.ForecastedMilkProductionByMonths ?? []
		);

		this.model.getMilkCheckReconciliation.data?.ForecastedMilkProductionByMonths.forEach((month) => {
			const date = month.date;
			const classIDifferential = (month.Entity as LocationEntity | undefined)?.County.classIDifferential ?? 0;
			const numberOfCowsForecasted = month.numberOfCows;
			const grossProduction = month.grossProduction ?? 0;
			const grossClassIProduction = month.grossClassiProduction ?? 0;
			const grossClassIIProduction = month.grossClassiiProduction ?? 0;
			const grossClassIIIProduction = month.grossClassiiiProduction ?? 0;
			const grossClassIVProduction = month.grossClassivProduction ?? 0;
			const weightedClassIDifferential = classIDifferential * grossClassIProduction;

			const monthObject = monthObjects[date];
			if (!monthObject) return;

			if (!monthObject?.productionForecasted?.sum?.grossProduction) {
				monthObject.productionForecasted = {
					sum: {
						grossProduction: grossProduction,
					},
				} as AggregateForecastedMilkProductionByMonthDTO;
			} else {
				monthObject.productionForecasted.sum.grossProduction += grossProduction;
			}

			monthObject.grossClassIProductionForecasted = monthObject.grossClassIProductionForecasted
				? monthObject.grossClassIProductionForecasted + grossClassIProduction
				: grossClassIProduction;

			monthObject.grossClassIIProductionForecasted = monthObject.grossClassIIProductionForecasted
				? monthObject.grossClassIIProductionForecasted + grossClassIIProduction
				: grossClassIIProduction;

			monthObject.grossClassIIIProductionForecasted = monthObject.grossClassIIIProductionForecasted
				? monthObject.grossClassIIIProductionForecasted + grossClassIIIProduction
				: grossClassIIIProduction;

			monthObject.grossClassIVProductionForecasted = monthObject.grossClassIVProductionForecasted
				? monthObject.grossClassIVProductionForecasted + grossClassIVProduction
				: grossClassIVProduction;

			monthObject.weightedClassIDifferential = monthObject.weightedClassIDifferential
				? monthObject.weightedClassIDifferential + weightedClassIDifferential
				: weightedClassIDifferential;

			monthObject.numberOfCowsForecasted = monthObject.numberOfCowsForecasted
				? monthObject.numberOfCowsForecasted + numberOfCowsForecasted
				: numberOfCowsForecasted;
		});

		if (this.shouldIncludePremiumsAndDeductions) {
			this.model.getMilkCheckReconciliation.data?.AggregateLedgerEntries?.forEach((entry: AggregateLedgerForecastedEntryDTO) => {
				if (!entry.year || !entry.month) {
					console.error(`Month or Year not found for ledger entry`);
					return;
				}

				const formattedDate = DateTime.fromObject({ year: entry.year, month: entry.month, day: 1 }).toISODate();
				const monthObject = monthObjects[formattedDate];
				if (!monthObject) return;

				if (entry.type === TypeOfLedgerEntry.Actual) {
					monthObject.monthlyActualAmounts = entry.sum.calculatedAmount ?? null;
				} else if (entry.type === TypeOfLedgerEntry.Forecasted) {
					monthObject.monthlyCalculatedAmount = entry.sum.calculatedAmount ?? null;
				}
			});
		}

		this.model.getMilkCheckReconciliation.data?.ClassIIIFutures?.forEach((future: Future) => {
			const monthObject = monthObjects[future.displayExpiresAt];

			if (monthObject === undefined) {
				return;
			}

			monthObject.classiiiFuture = future;
		});

		this.model.getMilkCheckReconciliation.data?.ClassIVFutures?.forEach((future: Future) => {
			const monthObject = monthObjects[future.displayExpiresAt];

			if (monthObject === undefined) {
				return;
			}

			monthObject.classivFuture = future;
		});

		this.model.getMilkCheckReconciliation.data?.ButterFutures?.forEach((future: Future) => {
			const monthObject = monthObjects[future.displayExpiresAt];
			const advancedDate = DateTime.fromISO(future.displayExpiresAt).plus({ month: 1 }).toISODate();
			const advancedMonthObject = monthObjects[advancedDate];

			if (monthObject) {
				monthObject.butterFuture = future;
			}

			if (advancedMonthObject) {
				advancedMonthObject.advancedButterFuture = future;
			}
		});

		this.model.getMilkCheckReconciliation.data?.NonfatDryMilkFutures?.forEach((future: Future) => {
			const advancedDate = DateTime.fromISO(future.displayExpiresAt).plus({ month: 1 }).toISODate();
			const advancedMonthObject = monthObjects[advancedDate];

			if (advancedMonthObject) {
				advancedMonthObject.advancedNonfatDryMilkFuture = future;
			}
		});

		this.model.getMilkCheckReconciliation.data?.CheeseFutures?.forEach((future: Future) => {
			const advancedDate = DateTime.fromISO(future.displayExpiresAt).plus({ month: 1 }).toISODate();
			const advancedMonthObject = monthObjects[advancedDate];

			if (advancedMonthObject) {
				advancedMonthObject.advancedCheeseFuture = future;
			}
		});

		this.model.getMilkCheckReconciliation.data?.DryWheyFutures?.forEach((future: Future) => {
			const advancedDate = DateTime.fromISO(future.displayExpiresAt).plus({ month: 1 }).toISODate();
			const advancedMonthObject = monthObjects[advancedDate];

			if (advancedMonthObject) {
				advancedMonthObject.advancedDryWheyFuture = future;
			}
		});

		this.model.getMilkCheckReconciliation.data?.AggregateActualMilkProduction.forEach((production) => {
			const date = production.firstDateOfMonth;
			const monthObject = date ? monthObjects[date] : null;

			if (monthObject) {
				monthObject.productionActual = production.sum.grossProduction ?? 0;
			}
		});

		this.model.getMilkCheckReconciliation.data?.ForecastedMilkProductionByMonths.forEach((production: ForecastedMilkProductionByMonth) => {
			const date = DateTime.fromISO(production.date).toISODate();
			const monthObject = monthObjects[date];
			if (monthObject) {
				monthObject.forecastedBlendedMilkPrice = weightedProjectedValues[date].blendedPrice ?? 0;
			}
		});

		const results = Object.entries(monthObjects).map(([date, monthObject]) => {
			const weightedPricesAndBasisValues = weightedMonthlyPricesAndBasisValues.find((v) => v.date === date);
			const weightedUtilizations = weightedUtilizationValues.find((v) => v.date === date);

			if (monthObject) {
				const milkCheck = new MilkCheck(
					getOwner(this),
					date,
					weightedPricesAndBasisValues?.locationCurrentBasis ?? 0,
					{
						classiUtilization: weightedUtilizations?.classIUtilization ?? 0,
						classiiUtilization: weightedUtilizations?.classIIUtilization ?? 0,
						classiiiUtilization: weightedUtilizations?.classIIIUtilization ?? 0,
						classivUtilization: weightedUtilizations?.classIVUtilization ?? 0,
					} as ForecastedMilkUtilization,
					monthObject.classiiiFuture,
					monthObject.classivFuture,
					monthObject.butterFuture,
					monthObject.advancedNonfatDryMilkFuture,
					monthObject.advancedButterFuture,
					monthObject.advancedCheeseFuture,
					monthObject.advancedDryWheyFuture,
					weightedUtilizations?.classIDifferential ?? 0,
					monthObject.monthlyCalculatedAmount,
					monthObject.productionForecasted,
					weightedUtilizations?.otherSolidsPercentage ?? 0,
					weightedUtilizations?.butterfatPercentage ?? 0,
					weightedUtilizations?.proteinPercentage ?? 0,
					monthObject.forecastedBlendedMilkPrice
				);

				milkCheck.grossProductionActual = monthObject.productionActual;
				milkCheck.monthlyActualAmounts = monthObject.monthlyActualAmounts ?? null;
				milkCheck.classIActualPrice = weightedPricesAndBasisValues?.classIPrice ?? null;
				milkCheck.classIIActualPrice = weightedPricesAndBasisValues?.classIIPrice ?? null;
				milkCheck.classIIIActualPrice = weightedPricesAndBasisValues?.classIIIPrice ?? null;
				milkCheck.classIVActualPrice = weightedPricesAndBasisValues?.classIVPrice ?? null;
				milkCheck.blendedPriceActual = weightedPricesAndBasisValues?.finalBlendedPrice ?? null;
				milkCheck.numberOfCowsForecasted = monthObject.numberOfCowsForecasted ?? null;
				milkCheck.numberOfCowsActual = monthObject.numberOfCowsActual ?? null;
				return milkCheck;
			}

			// This should never happen.
			console.error("We're missing some utilizations.");
			return;
		});

		// Sort ascending and remove undefined
		return results.filter((milkCheck): milkCheck is MilkCheck => !!milkCheck).sort((a, b) => (a.date < b.date ? -1 : 1));
	}

	@action
	onRowSelect(selection: MilkCheckReconciliationRow[]) {
		const row = selection[0];
		// Unselect row if clicked while active
		if (row != undefined && row.id === this.selectedRows[0]?.id) {
			this.selectedRows = [];
		} else {
			this.selectedRows = selection;
		}
	}

	@action
	tooltipTitleCallback(date: string) {
		return DateTime.fromISO(date).toFormat('LLL yyyy');
	}

	@action
	tooltipValueFormatCallback(val: number) {
		return Intl.NumberFormat(undefined, { style: 'currency', currency: 'USD', minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(
			val
		);
	}

	@action
	setInitialSelectedLocationId() {
		if (this.model.getMilkCheckReconciliation.data?.Entity?.type === TypeOfEntity.Location) return;

		const customer = this.model.getMilkCheckReconciliation?.data?.Entity as CustomerEntity | undefined;
		if (!customer) return;

		if (customer.Locations.length === 1) {
			this.selectedLocationId = customer.Locations[0].id;
		}
	}

	@action
	setSelectedLocationId(id: string | null, callback: () => void) {
		this.selectedLocationId = id;
		callback();
		this.clearSelectedRows();
	}

	@action
	onYearPickerChange(year: number) {
		this.year = year;
		this.clearSelectedRows();
	}

	clearSelectedRows() {
		this.selectedRows = [];
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'milk/milk-check-reconciliation': MilkCheckReconciliationController;
	}
}
