import Controller from '@ember/controller';
import StrategicBuyingModel from '../../models/strategic-buying-model';
import { getOwner } from '@ember/application';
import { tracked } from '@glimmer/tracking';
//@ts-ignore
import { Product } from 'types/graphql-types';
import isTouchDevice from 'vault-client/utils/is-touch-device';
import { TableColumn, CellComponents } from 'vault-client/types/vault-table';
import { ModelFrom } from 'vault-client/utils/type-utils';
import ReportsClassIiiStrategicBuyingModelRoute from 'vault-client/routes/reports/class-iii-strategic-buying-model';

export default class ReportsClassIiiStrategicBuyingModel extends Controller {
	@tracked carryCost: number = 0.01;
	@tracked numberOfYears: number = 5;
	reportsRoute: string = '';
	declare model: ModelFrom<ReportsClassIiiStrategicBuyingModelRoute>;
	get columns(): TableColumn[] {
		const baseColumns: TableColumn[] = [
			{
				id: 'e617a149-411e-488a-a80a-2fa00bac3ff9',
				name: 'Month',
				valuePath: 'displayExpiresAt',
				minWidth: 120,
				cellComponent: CellComponents.MonthFormat,
				textAlign: 'left',
				isSortable: false,
				isFixed: !isTouchDevice() ? 'left' : '',
				isVisible: true,
			},
			{
				id: 'f693b1f0-abcb-4de1-9f6d-82bd7b11448f',
				name: 'Futures',
				valuePath: 'currentFuturePrice',
				minWidth: 120,
				cellComponent: CellComponents.PriceFormat,
				componentArgs: {
					fractionDigitsPath: 'product.MostCurrentFuture.SymbolGroup.fractionDigits',
					displayFactorPath: 'noDisplayFactor',
				},
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '29ecaf53-ee2b-402d-8a38-d0843e4f393d',
				name: 'Strategy',
				valuePath: 'riskManagementStrategy',
				minWidth: 120,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
		];
		return baseColumns.filter((column) => column.isVisible);
	}

	currentPriceInput: string = 'Front Future Price';
	canBuySpot: boolean = false;

	prevSims: {
		oneMonthCarryIncentive: number | null;
		displayExpiresAt: string;
		symbol: string;
	}[] = [];

	get spotProduct() {
		return null;
	}

	get futuresProduct() {
		const products: Product[] = this.model.Products;
		return products?.find((product) => product.slug === 'us-dairy-class-iii');
	}

	get frontFuture() {
		return this.futuresProduct?.CurrentFutures[0];
	}

	get months() {
		const owner = getOwner(this);
		const product = this.futuresProduct;
		return product?.CurrentFutures?.reduce((acc: any, future: any) => {
			const previousSims = acc.slice(-2);
			const currentSim = new StrategicBuyingModel(
				owner,
				product,
				future,
				this.carryCost,
				previousSims,
				this.spotProduct,
				this.currentPriceInput,
				this.canBuySpot,
				this.numberOfYears
			);

			acc.push(currentSim);
			return acc;
		}, []);
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'reports/class-iii-strategic-buying-model': ReportsClassIiiStrategicBuyingModel;
	}
}
