import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { gql, useMutation, useQuery } from 'glimmer-apollo';
import { EntityGroup, Mutation, Mutation_deleteEntityGroupArgs, Query, Query_EntityGroupArgs } from 'vault-client/types/graphql-types';

const DELETE_PERMISSION_GROUP = gql`
	mutation DeleteEntityGroup($id: String!) {
		deleteEntityGroup(id: $id) {
			id
		}
	}
`;

const GET_PERMISSION_GROUP = gql`
	query EntityGroup($id: String!) {
		EntityGroup(id: $id) {
			id
			name
		}
	}
`;

interface RemoveGrainPermissionGroupModalArgs {
	permissionGroupId: string;
	closeModalFn: () => void;
	showError: () => void;
	model: any;
}

export default class RemoveGrainPermissionGroupModal extends Component<RemoveGrainPermissionGroupModalArgs> {
	@tracked permissionGroup: EntityGroup | null = null;
	deleteEntityGroupEntry = useMutation<{ deleteEntityGroup: Mutation['deleteEntityGroup'] }, Mutation_deleteEntityGroupArgs>(this, () => [
		DELETE_PERMISSION_GROUP,
		{
			onComplete: (): void => {
				this.args.model.getPermissionGroups.refetch();
				this.closeConfirmation();
			},
			onError: (error): void => {
				console.error('Error while attempting to delete Permission Group', error.message);
				this.args.showError();
				this.closeConfirmation();
			},
		},
	]);

	getPermissionGroup = useQuery<{ EntityGroup: Query['EntityGroup'] }, Query_EntityGroupArgs>(this, () => [
		GET_PERMISSION_GROUP,
		{
			variables: { id: this.args.permissionGroupId },
		},
	]);

	@action
	async fetchPermissionGroup() {
		await this.getPermissionGroup.promise;
		this.permissionGroup = this.getPermissionGroup.data?.EntityGroup ?? null;
	}

	@action
	async removePermissionGroup() {
		await this.deleteEntityGroupEntry.mutate({
			id: this.args.permissionGroupId,
		});
	}

	@action
	closeConfirmation() {
		this.args.closeModalFn();
	}
}
