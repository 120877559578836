import { setOwner } from '@ember/application';
import { guidFor } from '@ember/object/internals';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import ScenarioPricesService from 'vault-client/services/scenario-prices';
import { TypeOfInstrument, TypeOfOption } from 'vault-client/types/graphql-types';
import { roundTo } from 'vault-client/utils/round-to';

export type HypotheticalPositionJsonFormat = {
	date: string;
	futureDisplayExpiresAt: string;
	productSlug: string;
	instrumentType: TypeOfInstrument;
	barchartSymbol: string | null;
	pointValue: number;
	lotSize: number;
	quantity: number;
	price: number;
	commissionAndFeeTotal: number | null;
	realizedPnl: number | null;
	optionType: TypeOfOption | null;
	strike: number | null;
};

export default class HypotheticalPosition {
	id = guidFor(this);
	date: string; // date this position should be associated with
	futureDisplayExpiresAt: string; // displayExpiresAt of the relevant month (underlying for options)
	productSlug: string;
	@tracked quantity: number; // number of contracts
	@tracked price: number;
	pointValue: number;
	lotSize: number;
	commissionAndFeeTotal: number | null;
	realizedPnl: number | null;
	optionType: TypeOfOption | null;
	barchartSymbol: string | null = null;
	instrumentType: TypeOfInstrument;
	strike: number | null;

	@service declare scenarioPrices: ScenarioPricesService;

	constructor(
		owner: any,
		date: string,
		futureDisplayExpiresAt: string,
		productSlug: string,
		instrumentType: TypeOfInstrument,
		barchartSymbol: string | null,
		pointValue: number,
		lotSize: number,
		quantity: number,
		price: number,
		commissionAndFeeTotal: number | null,
		realizedPnl: number | null,
		optionType: TypeOfOption | null,
		strike: number | null
	) {
		setOwner(this, owner);
		this.date = date;
		this.futureDisplayExpiresAt = futureDisplayExpiresAt;
		this.productSlug = productSlug;
		this.instrumentType = instrumentType;
		this.barchartSymbol = barchartSymbol;
		this.pointValue = pointValue;
		this.lotSize = lotSize;
		this.quantity = quantity;
		this.price = price;
		this.commissionAndFeeTotal = commissionAndFeeTotal;
		this.realizedPnl = realizedPnl;
		this.optionType = optionType;
		this.strike = strike;
	}

	get isLong() {
		return this.quantity > 0;
	}

	get side(): 'Bought' | 'Sold' {
		return this.isLong ? 'Bought' : 'Sold';
	}

	get quantityTitle() {
		if (this.productSlug === 'grain-corn') {
			return 'Bushels';
		} else if (this.productSlug === 'grain-soybean-meal') {
			return 'Tons';
		} else {
			return 'Pounds';
		}
	}

	get quantityInUnits() {
		const absQuantity = Math.abs(this.quantity);

		return absQuantity * this.lotSize;
	}

	get scenarioInstrumentPrice() {
		if (this.barchartSymbol == null) return null;

		return this.scenarioPrices.getScenarioPrice(this.barchartSymbol);
	}

	get marketInstrumentPrice() {
		if (this.barchartSymbol == null) return null;

		return this.scenarioPrices.getMarketPrice(this.barchartSymbol);
	}

	get productTitle() {
		switch (this.productSlug) {
			case 'us-dairy-class-iii':
				return 'Class III Milk';
			case 'us-dairy-class-iv':
				return 'Class IV Milk';
			case 'us-dairy-cheese':
				return 'Cash-Settled Cheese';
			case 'grain-corn':
				return 'Corn';
			case 'grain-soybean-meal':
				return 'Soybean Meal';
			case 'us-dairy-butter':
				return 'Cash-Settled Butter';
			case 'us-dairy-nonfat-milk':
				return 'Nonfat Dry Milk';
			case 'us-dairy-dry-whey':
				return 'Dry Whey';
			case 'livestock-lean-hogs':
				return 'Lean Hogs';
			default:
				return '';
		}
	}

	get instrumentTypeLabel() {
		if (this.instrumentType === TypeOfInstrument.Future) {
			return 'Futures (Forward)';
		} else if (this.instrumentType === TypeOfInstrument.Option && this.optionType === TypeOfOption.Call) {
			return 'Call';
		} else if (this.instrumentType === TypeOfInstrument.Option && this.optionType === TypeOfOption.Put) {
			return 'Put';
		}

		return '';
	}

	get totalPrice() {
		return Math.abs(this.price) * this.pointValue * Math.abs(this.quantity);
	}

	get marketPnlInDollars(): number {
		return this.calculatePnlInDollars(this.marketInstrumentPrice);
	}

	get scenarioPnlInDollars(): number {
		return this.calculatePnlInDollars(this.scenarioInstrumentPrice);
	}

	calculatePnlInDollars(instrumentPrice: number | null) {
		if (instrumentPrice === null) return 0;

		const quantity = this.quantity;
		const pointValue = this.pointValue;

		let pnl = this.realizedPnl ?? 0;

		if (this.instrumentType === TypeOfInstrument.Future) {
			pnl += pointValue * Math.abs(quantity) * (this.price - instrumentPrice) * (this.isLong ? -1 : 1);
		} else if (this.instrumentType === TypeOfInstrument.Option && this.optionType) {
			if (this.strike == null) return 0;

			const strikePriceDiff = this.strike - instrumentPrice;

			if (this.optionType === TypeOfOption.Put && strikePriceDiff > 0) {
				pnl += pointValue * Math.abs(quantity) * strikePriceDiff * (this.isLong ? 1 : -1);
			}

			if (this.optionType === TypeOfOption.Call && strikePriceDiff < 0) {
				pnl += pointValue * Math.abs(quantity) * strikePriceDiff * (this.isLong ? -1 : 1);
			}

			if (this.isLong) {
				pnl -= this.totalPrice + (this.commissionAndFeeTotal ?? 0);
			} else {
				pnl += this.totalPrice + (this.commissionAndFeeTotal ?? 0);
			}
		}

		return roundTo(pnl, 2);
	}

	toJson(): HypotheticalPositionJsonFormat {
		return {
			date: this.date,
			futureDisplayExpiresAt: this.futureDisplayExpiresAt,
			productSlug: this.productSlug,
			instrumentType: this.instrumentType,
			barchartSymbol: this.barchartSymbol,
			pointValue: this.pointValue,
			lotSize: this.lotSize,
			quantity: this.quantity,
			price: this.price,
			commissionAndFeeTotal: this.commissionAndFeeTotal,
			realizedPnl: this.realizedPnl,
			optionType: this.optionType,
			strike: this.strike,
		};
	}

	static fromJson(obj: HypotheticalPositionJsonFormat, owner: any) {
		return new HypotheticalPosition(
			owner,
			obj.date,
			obj.futureDisplayExpiresAt,
			obj.productSlug,
			obj.instrumentType,
			obj.barchartSymbol,
			obj.pointValue,
			obj.lotSize,
			obj.quantity,
			obj.price,
			obj.commissionAndFeeTotal,
			obj.realizedPnl,
			obj.optionType,
			obj.strike
		);
	}
}
