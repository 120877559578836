import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{this.formattedValue}}", {"contents":"{{this.formattedValue}}","moduleName":"vault-client/components/market-price-format.hbs","parseOptions":{"srcName":"vault-client/components/market-price-format.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

interface MarketPriceFormatArgs {
	symbol?: string;
	rowData?: any;
	valuePath?: string;
	side?: string;
	fractionDigits?: string;
	fractionDigitsPath?: string;
	displayFactor?: string;
	displayFactorPath?: string;
	intlNumberFormatOptions?: Intl.NumberFormatOptions;
}

export default class MarketPriceFormat extends Component<MarketPriceFormatArgs> {
	@service marketData: any;

	registeredSymbols: string[] = [];

	registerSymbol(symbol: string) {
		if (!this.registeredSymbols.includes(symbol)) {
			this.marketData.register(symbol);
			this.registeredSymbols.push(symbol);
		}
	}

	getByCompositeKey(obj: any, key: string) {
		if (!key) {
			return null;
		}

		return key.split('.').reduce((prev, itm) => (prev ? prev[itm] : null), obj) || null;
	}

	get formattedValue() {
		const symbol = this.args.symbol
			? this.args.symbol
			: this.getByCompositeKey(this.args.rowData, `${this.args.valuePath}.barchartSymbol`) ?? this.args.rowData.barchartSymbol;
		this.registerSymbol(symbol);

		const marketDatum = this.marketData.getMarketDatum(symbol);
		let value;
		let showValue;
		// This is hacky and should be cleaned up.
		if (this.args.rowData && this.args.valuePath) {
			showValue =
				(this.getByCompositeKey(this.args.rowData, `${this.args.valuePath}.instrumentType`) == 'Future' ??
					this.args.rowData.instrumentType) ||
				!(this.getByCompositeKey(this.args.rowData, `${this.args.valuePath}.hasExpired`) ?? this.args.rowData.hasExpired);
		} else {
			showValue = null;
		}

		if (marketDatum) {
			switch (this.args.side) {
				case 'last':
					value = marketDatum.lastPrice;
					break;
				case 'high':
					value = marketDatum.high;
					break;
				case 'low':
					value = marketDatum.low;
					break;
				case 'open':
					value = marketDatum.open;
					break;
				case 'close':
					value = marketDatum.close;
					break;
				case 'settlement':
					value = marketDatum.settlement;
					break;
				case 'previousSettlement':
					value = marketDatum.previousSettlement;
					break;
				default:
					value = marketDatum.lastPrice;
			}
		}

		if (value) {
			const fractionDigits = this.args.fractionDigits
				? this.args.fractionDigits
				: showValue && this.args.fractionDigitsPath
				? this.getByCompositeKey(this.args.rowData, this.args.fractionDigitsPath)
				: null;

			const displayFactor = this.args.displayFactor
				? this.args.displayFactor
				: showValue && this.args.displayFactorPath
				? this.getByCompositeKey(this.args.rowData, this.args.displayFactorPath)
				: null;

			const price = displayFactor ? displayFactor * value : value;

			return new Intl.NumberFormat(undefined, {
				minimumFractionDigits: fractionDigits,
				maximumFractionDigits: fractionDigits,
				...(this.args.intlNumberFormatOptions ?? {}),
			}).format(price);
		} else {
			return '-';
		}
	}

	willDestroy() {
		super.willDestroy();
		this.registeredSymbols.forEach((symbol) => {
			this.marketData.unregister(symbol);
		});
	}
}
