import PositionsIndexController from 'vault-client/controllers/positions';

export default class OrganizationsOrganizationPositions extends PositionsIndexController {
	accountRoute: string = 'organizations.organization.account';
	positionDetailRoute: string = 'organizations.organization.position';
	brokerageRoutePath: string = 'organizations.organization.brokerage';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'organizations/organization/positions': OrganizationsOrganizationPositions;
	}
}
