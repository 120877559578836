import Route from '@ember/routing/route';
import query from 'vault-client/graphql/queries/reports/dairy-margin.graphql';
import { queryManager } from 'ember-apollo-client';

export default class ReportsDairyMargin extends Route {
	@queryManager apollo: any;

	templateName: string = 'reports/dairy-margin';

	async model() {
		const variables = {
			where: {
				slug: {
					in: ['us-dairy-class-iii', 'grain-corn', 'grain-soybean-meal'],
				},
			},
		};

		return this.apollo.watchQuery({ query, variables });
	}
}
