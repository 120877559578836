import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

interface Filter {
	filterRule: string;
	filterValue: string;
	filterLabel: string;
	filterComponent?: string;
}

interface UiSearchFiltersComponentArgs {
	searchFilters: Record<string, Filter>;
	// eslint-disable-next-line no-unused-vars
	clearFilter: (arg: string) => void;
}

export default class UiSearchFiltersComponent extends Component<UiSearchFiltersComponentArgs> {
	@tracked _orderedFilters: string[] = [];

	get searchFilters() {
		const filters = this.args.searchFilters; // { filterIdentifier: { filterRule, filterValue, filterComponent } }
		const arr = [];

		for (const filter in filters) {
			const map = new Map<string, Filter>();
			if (filters[filter].filterComponent) {
				map.set(filter, {
					...filters[filter],
				});
			} else {
				map.set(filter, {
					filterComponent: 'vault/ui-search-filter',
					...filters[filter],
				});
			}

			arr.push(map);
		}
		return arr;
	}

	get orderedFilters() {
		const arr: Map<string, Filter>[] = [];
		this._orderedFilters.forEach((orderedFilter) => {
			const setFilter = this.searchFilters.find((filter) => filter.has(orderedFilter));
			arr.push(setFilter!);
		});
		return arr;
	}

	@action
	setFilterOrder() {
		for (const filter in this.args.searchFilters) {
			if (!this._orderedFilters.includes(filter)) {
				this._orderedFilters = [...this._orderedFilters, filter];
			}
		}
	}

	@action
	clearFilter(filter: string) {
		this.args.clearFilter(filter);
		this.removeFilterFromOrder(filter);
	}

	removeFilterFromOrder(filter: string) {
		const idx = this._orderedFilters.indexOf(filter);
		this._orderedFilters = [...this._orderedFilters.slice(0, idx), ...this._orderedFilters.slice(idx + 1)];
	}
}
