import { AccountFilterDTO, AccountSortByDTO } from 'vault-client/types/graphql-types';

type SortObj = {
	valuePath: string;
	isAscending: boolean;
};

interface Params {
	accountId?: string | null;
	fcmId?: string | null;
	sorts: SortObj[];
}

export default function unmappedAccountsReportQuery(params: Params): { orderBy: AccountSortByDTO; where: AccountFilterDTO } {
	const orderBy: {
		[key: string]: any;
	} = {};

	const where: AccountFilterDTO = {};

	params.sorts.forEach((sort) => {
		const value = sort.isAscending ? 'Asc' : 'Desc';
		orderBy[sort.valuePath] = value;
	});

	if (params.fcmId) {
		where.fcmId = {
			equals: params.fcmId,
		};
	}

	if (params.accountId) {
		where.id = {
			equals: params.accountId,
		};
	}

	where.customerId = { equals: null };
	// @ts-ignore
	where.type = { equals: 'Brokerage' };

	return { orderBy, where };
}
