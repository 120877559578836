import ReportsClassIvStrategicBuyingModel from 'vault-client/controllers/reports/class-iv-strategic-buying-model';

export default class BusinessesBusinessClassIvStrategicBuyingModel extends ReportsClassIvStrategicBuyingModel {
	reportsRoute: string = 'businesses.business.reports';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'businesses/business/class-iv-strategic-buying-model': BusinessesBusinessClassIvStrategicBuyingModel;
	}
}
