import ReportsLrpCalculator from 'vault-client/controllers/reports/lrp-calculator';

export default class OrganizationsOrganizationLrpCalculator extends ReportsLrpCalculator {
	reportsRoute: string = 'organizations.organization.reports';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'organizations/organization/lrp-calculator': OrganizationsOrganizationLrpCalculator;
	}
}
