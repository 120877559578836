import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { DateTime } from 'luxon';
import { queryManager } from 'ember-apollo-client';
import { CellComponents, SortObj, TableColumn } from 'vault-client/types/vault-table';
import getFilterDisplayProperty from 'vault-client/utils/get-filter-display-property';
import searchQuery from 'vault-client/graphql/queries/search.graphql';
import agentFuzzyQuery from 'vault-client/graphql/queries/agent-fuzzy-match.graphql';
import stateFuzzyQuery from 'vault-client/graphql/queries/state-fuzzy-match.graphql';
import { AggregateDerivedDrpEndorsementDTO } from 'vault-client/types/graphql-types';
import { ModelFrom } from 'vault-client/utils/type-utils';
import OrganizationsOrganizationDrpPerformanceBySalesEffectiveDateMonthRoute from 'vault-client/routes/organizations/organization/drp-performance-by-sales-effective-date-month';
import BusinessesBusinessDrpPerformanceBySalesEffectiveDateMonthRoute from 'vault-client/routes/businesses/business/drp-performance-by-sales-effective-date-month';

interface IMonthObject {
	month: string;
	sum: {
		effectiveCoveredMilkProduction: number;
		declaredCoveredMilkProduction: number;
		pnl: number;
		indemnity: number;
		producerPremiumAmount: number;
		totalPremiumAmount: number;
	};
}

interface IMonthObjects {
	[key: string]: IMonthObject;
}

interface DateRangeOption {
	displayName: string;
	startDate: string;
	endDate: string;
}

interface SearchFilterIdentifiers {
	aipId: string | null;
	agent: string | null;
	stateId: string | null;
}

type SearchFilterIdentifier = keyof SearchFilterIdentifiers;

function isSearchFilterIdentifier(key: unknown): key is SearchFilterIdentifier {
	const searchFilterIdentifiers = ['aipId', 'agent', 'stateId'];
	if (typeof key === 'string' && searchFilterIdentifiers.includes(key)) {
		return true;
	}
	return false;
}
interface searchResult {
	id: string;
	name: string;
	type: string;
}

type setDatePrefix = 'quarter' | 'salesEffective';

export default class DrpPerformanceBySalesEffectiveDateMonthController extends Controller implements SearchFilterIdentifiers {
	declare model:
		| ModelFrom<OrganizationsOrganizationDrpPerformanceBySalesEffectiveDateMonthRoute>
		| ModelFrom<BusinessesBusinessDrpPerformanceBySalesEffectiveDateMonthRoute>;
	queryParams = [
		'agent',
		'aipId',
		'quarterStartDate',
		'quarterEndDate',
		'salesEffectiveStartDate',
		'salesEffectiveEndDate',
		'stateId',
		'sorts',
		'type',
	];

	reportsRoute: string = '';

	columns: TableColumn[] = [
		{
			id: '4565ffe6-fdd7-46c2-8510-4d8f7a5945a7',
			name: 'Month',
			valuePath: 'month',
			minWidth: 80,
			textAlign: 'left',
			isSortable: true,
			cellComponent: CellComponents.MonthFormat,
			isFixed: '',
			isVisible: true,
		},
		{
			id: 'f611cb7d-f664-4e5b-ac54-86ed97741772',
			name: 'Milk Production',
			isFixed: '',
			isVisible: true,
			cellComponent: CellComponents.String,
			subcolumns: [
				{
					id: 'e9a3545c-23e8-421d-aa5d-1eb1a8549d31',
					name: 'Effective',
					valuePath: 'sum.effectiveCoveredMilkProduction',
					width: 130,
					cellComponent: CellComponents.IntlNumberFormat,
					componentArgs: {
						minimumFractionDigits: 0,
						maximumFractionDigits: 0,
					},
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
				{
					id: 'cf7a6cda-f85d-487e-9cc0-d444772be685',
					name: 'Declared',
					valuePath: 'sum.declaredCoveredMilkProduction',
					width: 130,
					cellComponent: CellComponents.IntlNumberFormat,
					componentArgs: {
						minimumFractionDigits: 0,
						maximumFractionDigits: 0,
					},
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
			],
		},
		{
			id: '524c8867-0409-41cf-adda-2f6ae9997196',
			name: 'Producer Premium',
			valuePath: 'sum.producerPremiumAmount',
			minWidth: 130,
			textAlign: 'right',
			isSortable: false,
			cellComponent: CellComponents.IntlNumberFormat,
			componentArgs: {
				style: 'currency',
				currency: 'USD',
				currencySign: 'accounting',
			},
			isFixed: '',
			isVisible: true,
		},
		{
			id: 'b29498f1-2860-41cd-9a58-2a7b166cb292',
			name: 'Total Premium',
			valuePath: 'sum.totalPremiumAmount',
			minWidth: 130,
			textAlign: 'right',
			isSortable: false,
			cellComponent: CellComponents.IntlNumberFormat,
			componentArgs: {
				style: 'currency',
				currency: 'USD',
				currencySign: 'accounting',
			},
			isFixed: '',
			isVisible: true,
		},
		{
			id: 'fc6d79b9-ef4c-4b4a-bab9-7ae34e6ce7d5',
			name: 'Indemnity',
			valuePath: 'sum.indemnity',
			minWidth: 130,
			textAlign: 'right',
			isSortable: false,
			cellComponent: CellComponents.IntlNumberFormat,
			componentArgs: {
				style: 'currency',
				currency: 'USD',
				currencySign: 'accounting',
			},
			isFixed: '',
			isVisible: true,
		},
		{
			id: '0836b691-8e85-4a0d-bb0a-cdb133e7fc5d',
			name: 'Profit/Loss',
			valuePath: 'sum.pnl',
			minWidth: 130,
			textAlign: 'right',
			isSortable: false,
			cellComponent: CellComponents.IntlNumberFormat,
			componentArgs: {
				style: 'currency',
				currency: 'USD',
				currencySign: 'accounting',
			},
			isFixed: '',
			isVisible: true,
		},
	];

	quarterEndDateRangeOptions = [
		{
			displayName: 'All Quarters',
			startDate: '1900-01-01',
			endDate: '2999-12-31',
		},
		{
			displayName: 'Open Quarters',
			startDate: DateTime.local().startOf('quarter').toISODate(),
			endDate: '2999-12-31',
		},
		// Previous Quarter
		{
			displayName: DateTime.local().startOf('quarter').minus({ quarter: 1 }).toFormat('yyyy Qq'),
			startDate: DateTime.local().startOf('quarter').minus({ quarter: 1 }).toISODate(),
			endDate: DateTime.local().endOf('quarter').minus({ quarter: 1 }).toISODate(),
		},
		// Current Quarter
		{
			displayName: DateTime.local().startOf('quarter').toFormat('yyyy Qq'),
			startDate: DateTime.local().startOf('quarter').toISODate(),
			endDate: DateTime.local().endOf('quarter').toISODate(),
		},

		// +1 Quarter
		{
			displayName: DateTime.local().startOf('quarter').plus({ quarter: 1 }).toFormat('yyyy Qq'),
			startDate: DateTime.local().startOf('quarter').plus({ quarter: 1 }).toISODate(),
			endDate: DateTime.local().endOf('quarter').plus({ quarter: 1 }).toISODate(),
		},
		// +2 Quarter
		{
			displayName: DateTime.local().startOf('quarter').plus({ quarter: 2 }).toFormat('yyyy Qq'),
			startDate: DateTime.local().startOf('quarter').plus({ quarter: 2 }).toISODate(),
			endDate: DateTime.local().endOf('quarter').plus({ quarter: 2 }).toISODate(),
		},
		// +3 Quarter
		{
			displayName: DateTime.local().startOf('quarter').plus({ quarter: 3 }).toFormat('yyyy Qq'),
			startDate: DateTime.local().startOf('quarter').plus({ quarter: 3 }).toISODate(),
			endDate: DateTime.local().endOf('quarter').plus({ quarter: 3 }).toISODate(),
		},
		// +4 Quarter
		{
			displayName: DateTime.local().startOf('quarter').plus({ quarter: 4 }).toFormat('yyyy Qq'),
			startDate: DateTime.local().startOf('quarter').plus({ quarter: 4 }).toISODate(),
			endDate: DateTime.local().endOf('quarter').plus({ quarter: 4 }).toISODate(),
		},
		// +5 Quarter
		{
			displayName: DateTime.local().startOf('quarter').plus({ quarter: 5 }).toFormat('yyyy Qq'),
			startDate: DateTime.local().startOf('quarter').plus({ quarter: 5 }).toISODate(),
			endDate: DateTime.local().endOf('quarter').plus({ quarter: 5 }).toISODate(),
		},
		// +6 Quarter
		{
			displayName: DateTime.local().startOf('quarter').plus({ quarter: 6 }).toFormat('yyyy Qq'),
			startDate: DateTime.local().startOf('quarter').plus({ quarter: 6 }).toISODate(),
			endDate: DateTime.local().endOf('quarter').plus({ quarter: 6 }).toISODate(),
		},
		{
			displayName: `Calendar Year (${DateTime.local().year}) `,
			startDate: DateTime.local().startOf('year').toISODate(),
			endDate: DateTime.local().endOf('year').toISODate(),
		},
	];

	salesEffectiveDateRangeOptions = [
		{
			displayName: 'All Dates',
			startDate: '1900-01-01',
			endDate: '2999-12-31',
		},
		{
			displayName: 'Previous 7 Days',
			startDate: DateTime.local().minus({ days: 7 }).toISODate(),
			endDate: DateTime.local().toISODate(),
		},
		{
			displayName: 'Previous 30 Days',
			startDate: DateTime.local().minus({ days: 30 }).toISODate(),
			endDate: DateTime.local().toISODate(),
		},
		{
			displayName: 'Previous 90 Days',
			startDate: DateTime.local().minus({ days: 90 }).toISODate(),
			endDate: DateTime.local().toISODate(),
		},
		{
			displayName: 'Previous 12 Months',
			startDate: DateTime.local().minus({ months: 12 }).startOf('month').toISODate(),
			endDate: DateTime.local().toISODate(),
		},
		{
			displayName: 'Previous 24 Months',
			startDate: DateTime.local().minus({ months: 24 }).startOf('month').toISODate(),
			endDate: DateTime.local().toISODate(),
		},
	];

	@queryManager apollo: any;

	@tracked agent: string | null = null;
	@tracked aipId: string | null = null;
	@tracked quarterStartDate = '1900-01-01';
	@tracked quarterEndDate = '2999-12-31';
	@tracked salesEffectiveStartDate = DateTime.local().minus({ months: 12 }).startOf('month').toISODate();
	@tracked salesEffectiveEndDate = DateTime.local().toISODate();
	@tracked stateId: string | null = null;
	@tracked sorts: SortObj[] = [{ valuePath: 'salesEffectiveDate', isAscending: true }];
	@tracked type: string | null = null;
	@tracked organizationId: string | null = null;
	@tracked globalCustomerId: string | null = null;
	@tracked locationId: string | null = null;
	@tracked currentScope = '';

	get searchPlaceholder() {
		return 'Filter by AIP, agent name, and state...';
	}

	get searchPrompt() {
		return 'Type a search term to examine performance by AIP, agent name, and state....';
	}

	get aggregateEndorsementData() {
		const monthObj: IMonthObjects = {};

		(this.model.AggregateDerivedDrpEndorsements as AggregateDerivedDrpEndorsementDTO[])?.forEach((obj) => {
			const date = DateTime.fromISO(obj.salesEffectiveDate || '')
				.startOf('month')
				.toISODate();
			if (date) {
				if (!monthObj[date]) {
					monthObj[date] = {
						month: date,
						sum: {
							effectiveCoveredMilkProduction: 0,
							declaredCoveredMilkProduction: 0,
							pnl: 0,
							indemnity: 0,
							producerPremiumAmount: 0,
							totalPremiumAmount: 0,
						},
					};
				}

				monthObj[date].sum.effectiveCoveredMilkProduction += obj.sum.effectiveCoveredMilkProduction || 0;
				monthObj[date].sum.declaredCoveredMilkProduction += obj.sum.declaredCoveredMilkProduction || 0;
				monthObj[date].sum.pnl += obj.sum.pnl || 0;
				monthObj[date].sum.indemnity += obj.sum.indemnity || 0;
				monthObj[date].sum.producerPremiumAmount += obj.sum.producerPremiumAmount || 0;
				monthObj[date].sum.totalPremiumAmount += obj.sum.totalPremiumAmount || 0;
			}
		});

		const aggregateEndorsementData: IMonthObject[] = [];

		for (const month in monthObj) {
			aggregateEndorsementData.push(monthObj[month]);
		}

		return aggregateEndorsementData;
	}

	get searchFilterQueryParams() {
		const obj: {
			[param: string]: { [filterProperty: string]: string };
		} = {};

		const searchQueryParams = ['aipId', 'stateId', 'agent'];
		searchQueryParams.forEach((param) => {
			const value = this[param as keyof DrpPerformanceBySalesEffectiveDateMonthController];

			if (value) {
				obj[param] = {
					filterRule: 'equals',
					filterValue: value,
				};

				// set filterComponent property to specify component for custom display extended from search-filter
				const filterDisplayObj = getFilterDisplayProperty(param);
				if (filterDisplayObj && filterDisplayObj.customComponent) {
					obj[param].filterComponent = filterDisplayObj.customComponent;
				}
				// set filterLabel property to specify custom label for filter - default would be filterIdentifier (matches queryParam)
				if (filterDisplayObj && filterDisplayObj.label) {
					obj[param].filterLabel = filterDisplayObj.label;
				}
			}
		});

		return obj;
	}

	get quarterEndDateQueryParam() {
		return {
			startDate: this.quarterStartDate,
			endDate: this.quarterEndDate,
		};
	}

	get salesEffectiveDateQueryParam() {
		return {
			startDate: this.salesEffectiveStartDate,
			endDate: this.salesEffectiveEndDate,
		};
	}

	@action
	structureSearchResults(searchResults: searchResult[]) {
		const map = new Map();

		searchResults.forEach((item) => {
			if (map.has(item.type)) {
				map.get(item.type).push({ id: item.id, name: item.name, type: item.type });
			} else {
				map.set(item.type, [{ id: item.id, name: item.name, type: item.type }]);
			}
		});

		return map;
	}

	@action
	async fetchSearchResults(searchText: string) {
		let searchApiResults = [];
		const agentResults = [];
		const stateResults: any[] = [];

		// get fuzzy match agents
		const agentVariables = {
			where: {
				OR: [
					{ agentFirstName: { contains: searchText } },
					{ agentLastName: { contains: searchText } },
					{ agentFullName: { contains: searchText } },
				],
			},
		};
		const agentsArr = (
			await this.apollo.watchQuery({
				query: agentFuzzyQuery,
				variables: agentVariables,
			})
		).InsurancePolicies.map((agent: any) => `${agent.agentFirstName} ${agent.agentLastName}`);

		const agentSet = new Set(agentsArr);

		for (const agent of agentSet) {
			const obj = {
				type: 'Agent',
				name: agent,
			};
			agentResults.push(obj);
		}

		// get fuzzy match state
		const stateVariables = {
			where: {
				OR: [{ abbreviation: { contains: searchText } }, { name: { contains: searchText } }, { id: { contains: searchText } }],
			},
		};
		const states = (
			await this.apollo.watchQuery({
				query: stateFuzzyQuery,
				variables: stateVariables,
			})
		).States;
		states.forEach((state: any) => {
			const obj = {
				type: 'State',
				id: state.id,
				name: state.name,
			};
			stateResults.push(obj);
		});

		// get search API matches
		const variables = {
			query: searchText,
			typesToInclude: ['Aip'],
		};

		searchApiResults = (await this.apollo.watchQuery({ query: searchQuery, variables })).Search;

		// return combined set
		return [...agentResults, ...searchApiResults, ...stateResults];
	}

	@action
	setSearchFilterQueryParam(searchResult: searchResult) {
		const mappedSearchFilter = this.mapSearchResult(searchResult);
		if (mappedSearchFilter) {
			this[mappedSearchFilter.filterIdentifier] = mappedSearchFilter.filterValue;
		}
	}

	mapSearchResult(searchResult: searchResult): { filterIdentifier: SearchFilterIdentifier; filterValue: string } | null {
		let filterIdentifier: SearchFilterIdentifier;

		switch (searchResult.type) {
			case 'Aip':
				filterIdentifier = 'aipId';
				break;
			case 'Agent':
				filterIdentifier = 'agent';
				break;
			case 'State':
				filterIdentifier = 'stateId';
				break;
			default:
				return null;
		}

		return {
			filterIdentifier,
			filterValue: searchResult.id ?? searchResult.name,
		};
	}

	@action
	clearSearchFilterQueryParam(filterIdentifier: string) {
		if (isSearchFilterIdentifier(filterIdentifier)) {
			this[filterIdentifier] = null;
		}
	}

	@action
	setSorts(sorts: SortObj[]) {
		this.sorts = sorts;
	}

	@action
	setDateFilterQueryParam(queryParam: setDatePrefix, value: DateRangeOption) {
		this[`${queryParam}StartDate`] = value.startDate;
		this[`${queryParam}EndDate`] = value.endDate;
	}

	@action
	setTypeQueryParam(value: string, callback: () => any) {
		callback();
		this.type = value;
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'reports/drp-performance-by-sales-effective-date-month': DrpPerformanceBySalesEffectiveDateMonthController;
	}
}
