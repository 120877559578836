import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Vault::UiButton @style='destructive' {{on 'click' this.openConfirmation}}>{{yield}}</Vault::UiButton>\n{{#if this.showConfirmation}}\n\t<Vault::UiConfirmation as |confirmation|>\n\t\t<confirmation.title>Delete Confirmation</confirmation.title>\n\t\t<confirmation.body>Are you sure you want to delete this transaction?\n\t\t\t<br />This action is irreversible.\n\t\t</confirmation.body>\n\t\t<confirmation.cancel @style='plain' {{on 'click' this.closeConfirmation}}>Cancel</confirmation.cancel>\n\t\t<confirmation.action @style='destructive' {{on 'click' this.deleteCustomer}}>Delete Transaction</confirmation.action>\n\t</Vault::UiConfirmation>\n{{/if}}", {"contents":"<Vault::UiButton @style='destructive' {{on 'click' this.openConfirmation}}>{{yield}}</Vault::UiButton>\n{{#if this.showConfirmation}}\n\t<Vault::UiConfirmation as |confirmation|>\n\t\t<confirmation.title>Delete Confirmation</confirmation.title>\n\t\t<confirmation.body>Are you sure you want to delete this transaction?\n\t\t\t<br />This action is irreversible.\n\t\t</confirmation.body>\n\t\t<confirmation.cancel @style='plain' {{on 'click' this.closeConfirmation}}>Cancel</confirmation.cancel>\n\t\t<confirmation.action @style='destructive' {{on 'click' this.deleteCustomer}}>Delete Transaction</confirmation.action>\n\t</Vault::UiConfirmation>\n{{/if}}","moduleName":"vault-client/components/delete-transaction-button.hbs","parseOptions":{"srcName":"vault-client/components/delete-transaction-button.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { gql, useMutation } from 'glimmer-apollo';
import { Mutation_deleteTransactionArgs } from 'vault-client/types/graphql-types';

interface Args {
	transactionId: string;
	transactionsRoutePath: string;
}

const DELETE_TRANSACTION = gql`
	mutation deleteTransaction($id: String!) {
		deleteTransaction(id: $id) {
			id
		}
	}
`;

type DeleteTransactionMutation = {
	__typename?: 'Mutation';

	id: string | null;
};

export default class DeleteTransactionButtonComponent extends Component<Args> {
	@service router: any;
	@tracked showConfirmation = false;

	deleteTransaction = useMutation<DeleteTransactionMutation, Mutation_deleteTransactionArgs>(this, () => [
		DELETE_TRANSACTION,
		{
			update: (cache) => {
				cache.evict({ fieldName: 'TransactionCount' });
				cache.evict({ fieldName: 'Transactions' });
				cache.gc();
			},
			variables: { id: this.args.transactionId },
		},
	]);

	@action
	openConfirmation() {
		this.showConfirmation = true;
	}

	@action
	closeConfirmation() {
		this.showConfirmation = false;
	}

	@action
	async deleteCustomer() {
		await this.deleteTransaction.mutate({
			id: this.args.transactionId,
		});

		this.router.transitionTo(this.args.transactionsRoutePath);
	}
}
