import Big from 'big.js';
import { productSlugToContractSize } from 'vault-client/utils/cme-utils';

function lgmTotalTargetToContractUnit(totalTarget: number, commodityCode: string, typeCode?: string) {
	if ((commodityCode === '0801' || commodityCode === '0803') && typeCode === '807') {
		// Feeder Cattle, Calf finishing: Assumed marketing weight of 11.5 cwt, unless stated otherwise in "the Special Provisions"
		return totalTarget * 1150;
	} else if ((commodityCode === '0801' || commodityCode === '0803') && typeCode === '808') {
		// Feeder Cattle, Yearling finishing: Assumed marketing weight of 12.5 cwt, unless stated otherwise in "the Special Provisions"
		return totalTarget * 1250;
	} else if (commodityCode === '0802') {
		// Fed Cattle: Assumed marketing weight of 12.5 cwt, unless stated otherwise in "the Special Provisions"
		return totalTarget * 1250;
	} else if (commodityCode === '0815') {
		// Swine / Lean Hogs: Assumed marketing weight of 74% of 260 lbs, unless stated otherwise in "the Special Provisions"
		return totalTarget * 260 * 0.74;
	} else if (commodityCode === '0847') {
		// Dairy target values are CWT of milk, we'll convert it to lbs
		return totalTarget * 100;
	} else {
		console.error(`Unknown commodityCode or typecode in lgmTotalTargetToContractUnit: ${commodityCode}: ${typeCode}`);
		return null;
	}
}

function lgmTotalTargetToContracts(totalTarget: number, commodityCode: string, typeCode: string | null, productSlug: string) {
	const contractUnits = lgmTotalTargetToContractUnit(totalTarget, commodityCode, typeCode ? typeCode : undefined);
	const contractSize = productSlugToContractSize(productSlug);
	if (!contractUnits) {
		return null;
	}

	if (contractSize === null) {
		return null;
	}

	if (contractSize === 0) {
		console.warn(`Contract size is 0 for product slug: ${productSlug}`);
		return 0;
	}

	const contracts = new Big(contractUnits).div(contractSize);
	return contracts.toNumber();
}

function productSlugToCommodityCode(productSlug: string) {
	switch (productSlug) {
		case 'livestock-feeder-cattle':
			return '0801';
		case 'livestock-live-cattle':
			return '0802';
		case 'livestock-lean-hogs':
			return '0815';
		case 'us-dairy-class-iii':
			return '0847';
		default:
			return null;
	}
}

export { lgmTotalTargetToContractUnit, lgmTotalTargetToContracts, productSlugToCommodityCode };
