import Component from '@glimmer/component';
import { tracked } from 'tracked-built-ins';
import { action } from '@ember/object';
import { queryManager } from 'ember-apollo-client';
import mutation from 'vault-client/graphql/mutations/ledger-forecasted-entries-create.graphql';
import { DateTime, Interval } from 'luxon';
import { DateIncrement, LedgerEntrySetDTO, LedgerRevenueCategory, TypeOfLedgerCalculation } from 'vault-client/types/graphql-types';
import { TYPE_OF_CALCULATIONS_LABELS } from 'vault-client/controllers/businesses/business/business-settings';

interface UpdateRevenuesButtonArgs {
	revenueCategories: LedgerRevenueCategory[];
	selectedRows: any[];
	model: any;
	// eslint-disable-next-line no-unused-vars
	updateSelectedRows: (values: any[]) => void;
}

interface createLedgerForecastedEntries {
	data: LedgerEntrySetDTO;
}

interface ValueObject {
	id: string;
	name: string;
	isStatic: boolean;
	calculationType: TypeOfLedgerCalculation | null;
	value: any;
	growthPercentage: string;
	event: any;
	description?: string | null;
}

// eslint-disable-next-line no-unused-vars
enum GrowthType {
	// eslint-disable-next-line no-unused-vars
	perMonth = 'perMonth',
	// eslint-disable-next-line no-unused-vars
	perYear = 'perYear',
}

export default class UpdateRevenuesButton extends Component<UpdateRevenuesButtonArgs> {
	@queryManager apollo: any;

	@tracked showModal = false;
	@tracked valueObjects: ValueObject[] = [];
	@tracked updatingValues = false;

	get monthsToUpdate(): LedgerEntrySetDTO[] {
		const orderedSelectedMonths = this.args.selectedRows.sortBy('month');
		const firstMonth = orderedSelectedMonths[0];
		const startDate = DateTime.fromISO(firstMonth.month);

		const updatedValueObjects = this.valueObjects.filter((object) => {
			return object.value.trim();
		});

		const months = this.args.selectedRows.map((row) => {
			const currentDate = DateTime.fromISO(row.month);
			const dateInterval = Interval.fromDateTimes(startDate, currentDate);

			return updatedValueObjects.map((valueObject) => {
				const options = valueObject.event?.target.options as HTMLOptionsCollection;
				const growthType = options ? (options[options.selectedIndex].value as GrowthType) : GrowthType.perYear;

				let diffUnits;

				switch (growthType) {
					case GrowthType.perMonth:
						diffUnits = dateInterval.length('months');
						break;
					case GrowthType.perYear:
						diffUnits = dateInterval.length('years');
						break;
				}

				const growthPercentage = parseFloat(valueObject.growthPercentage) / 100 ?? 0;

				const amount = parseFloat(valueObject.value) + parseFloat(valueObject.value) * diffUnits * growthPercentage;

				const update: LedgerEntrySetDTO = {
					startDate: currentDate.toISODate(),
					endDate: currentDate.endOf('month').toISODate(),
					amount,
					categoryId: valueObject.id,
					entityId: this.args.model.data.Customer.id,
					increment: DateIncrement.Month,
				};
				return update;
			});
		});

		return months.flat(1);
	}

	@action
	openModal() {
		this.showModal = true;

		this.valueObjects = this.args.revenueCategories.map((category) => {
			return tracked({
				id: category.id,
				name: category.name,
				isStatic: category.isStatic,
				calculationType: category.calculationType ?? null,
				value: '',
				growthPercentage: '0',
				event: null,
				description: category.description,
			});
		});
	}

	@action
	closeModal() {
		this.showModal = false;

		this.valueObjects = [];
	}

	@action
	getReadableUnit(dynamicValueObject: ValueObject): string {
		return `${TYPE_OF_CALCULATIONS_LABELS[dynamicValueObject.calculationType as keyof typeof TYPE_OF_CALCULATIONS_LABELS]}`;
	}

	@action
	async submit() {
		this.updatingValues = true;
		const updatePromises: Promise<any>[] = [];

		for (let i = 0; i < this.monthsToUpdate.length; i++) {
			const variables: createLedgerForecastedEntries = {
				data: this.monthsToUpdate[i],
			};

			// Batch promise resolution into groups of 3 to prevent db deadlocks. Can be removed when API has better solution
			if (i % 3 === 0) {
				await Promise.all(updatePromises);
				updatePromises.clear();
			}

			updatePromises.push(
				this.apollo.mutate({
					mutation,
					variables,
				})
			);
		}

		// Concurrently send requests.
		await Promise.all(updatePromises);

		// Refresh model, now that there is new data.
		await this.args.model.refetch();

		// Reset selected months.
		this.args.updateSelectedRows([]);

		this.updatingValues = false;
		this.showModal = false;
	}
}
