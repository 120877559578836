import ExposureExposureHedgeMonthDetailController from 'vault-client/controllers/exposure/exposure-hedge-month-detail';

export default class BusinessesBusinessExposureHedgeMonthDetailController extends ExposureExposureHedgeMonthDetailController {
	exposureRoutePath = 'businesses.business.exposure';
	exposureHedgeMonthDetailRoutePath = 'businesses.business.exposure-hedge-month-detail';
	lgmInsuranceEndorsementsRoutePath = 'businesses.business.lgm-insurance-endorsements';
	lgmInsuranceEndorsementRoutePath = 'businesses.business.lgm-insurance-endorsement';
	lrpInsuranceEndorsementsRoutePath = 'businesses.business.lrp-insurance-endorsements';
	lrpInsuranceEndorsementRoutePath = 'businesses.business.lrp-insurance-endorsement';
	drpInsuranceEndorsementsRoutePath = 'businesses.business.drp-insurance-endorsements';
	drpInsuranceEndorsementRoutePath = 'businesses.business.drp-insurance-endorsement';
	accountsRoutePath = 'businesses.business.accounts';
	positionDetailRoutePath = 'businesses.business.position';
	positionsRoutePath = 'businesses.business.positions';
	feedContractsRoutePath = 'businesses.business.feed-contracts';
}
