/**
 * Caculate the percentile from an array of numbers
 * @param arr
 * @param percentile
 * @returns
 */
function percentile(arr: number[], percentile: number): number {
	const sortedArray = arr.sort((a, b) => a - b);
	const position = (sortedArray.length - 1) * percentile;

	// If a whole number, return the value at that location.
	if (Number.isInteger(position)) {
		return sortedArray[position];
	}

	if (percentile > 1) {
		return sortedArray.lastObject ?? 0;
	}

	const base = Math.floor(position);
	const remainder = position - base;

	return sortedArray[base] + remainder * (sortedArray[base + 1] - sortedArray[base]);
}

function percentRank(arr: number[], value: number): number | null {
	const sortedArray = arr.sort((a, b) => a - b);

	for (let i = 0; i < sortedArray.length; i++) {
		if (value == sortedArray[i]) {
			return i / (sortedArray.length - 1);
		}
	}

	let lowerValue;
	let upperValue;

	for (let i = 0; i < sortedArray.length; i++) {
		if (value > sortedArray[i] && sortedArray[i + 1] && sortedArray[i + 1] > value) {
			lowerValue = sortedArray[i];
			upperValue = sortedArray[i + 1];

			const lowerValueRank = percentRank(sortedArray, lowerValue);
			const upperValueRank = percentRank(sortedArray, upperValue);

			if (lowerValueRank != null && upperValueRank != null) {
				return ((upperValue - value) * lowerValueRank + (value - lowerValue) * upperValueRank) / (upperValue - lowerValue);
			}
		}

		if (value > sortedArray[i] && sortedArray[i + 1] === undefined) {
			return 1;
		}

		if (value < sortedArray[i] && sortedArray[i - 1] === undefined) {
			return 0;
		}
	}

	return null;
}

export { percentile, percentRank };
