import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{this.formattedValue}}", {"contents":"{{this.formattedValue}}","moduleName":"vault-client/components/quarter-format.hbs","parseOptions":{"srcName":"vault-client/components/quarter-format.hbs"}});
import Component from '@glimmer/component';
import { DateTime } from 'luxon';

interface QuarterFormatArgs {
	value: string | null;
	displayMonthName: boolean | undefined;
}

export default class QuarterFormat extends Component<QuarterFormatArgs> {
	get formattedValue() {
		if (this.args.value) {
			const luxonDate = DateTime.fromISO(this.args.value);
			if (this.args.displayMonthName) {
				return `${luxonDate.toLocaleString({ month: 'long' })}`;
			} else {
				return `${luxonDate.year} Q${luxonDate.quarter}`;
			}
		} else {
			return '-';
		}
	}
}
