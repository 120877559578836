function getRandomNumbers(desiredTotal: number, numValues: number) {
	const randomValues = [...Array(numValues)].map(() => Math.random());

	const total = randomValues.reduce((a, b) => a + b);

	const adjustment = desiredTotal / total;

	return randomValues.map((x) => (x *= adjustment));
}

export { getRandomNumbers };
