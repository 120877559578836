/* Convert CME symbol prefix to API product slug */
const symbolPrefixToProductSlug: {
	[prefix: string]: string | undefined;
} = {
	DC: 'grain-corn',
	ZM: 'grain-soybean-meal',
	DG: 'us-dairy-dry-whey',
	DF: 'us-dairy-nonfat-milk',
	BJ: 'us-dairy-cheese',
	BD: 'us-dairy-butter',
};

export default symbolPrefixToProductSlug;
