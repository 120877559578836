import LgmInsuranceEndorsementsShowController from 'vault-client/controllers/lgm-insurance-endorsements/show';

export default class OrganizationsOrganizationLgmInsuranceEndorsement extends LgmInsuranceEndorsementsShowController {
	lgmInsuranceEndorsementsRoute: string = 'organizations.organization.lgm-insurance-endorsements';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	interface Registry {
		'organizations/organization/lgm-insurance-endorsement': OrganizationsOrganizationLgmInsuranceEndorsement;
	}
}
