import Route from '@ember/routing/route';
import { tracked } from '@glimmer/tracking';
import { gql, useQuery } from 'glimmer-apollo';
import { Query, Query_GrainFillOrderArgs, Query_GrainOrderNotesArgs } from 'vault-client/types/graphql-types';

const GET_GRAIN_FILL = gql`
	query GrainGrainFillOrder($id: String!) {
		GrainFillOrder(id: $id) {
			id
			bushels
			futuresMonth
			deliveryStartDate
			deliveryEndDate
			deliveryMonth
			salesType
			futurePrice
			htaFee
			spread
			rollFee
			flexFee
			basis
			flatPrice
			contractNumber
			targetId
			Buyer {
				id
				name
			}
			Location {
				id
				name
			}
			Activity(orderBy: { performedAt: Desc }) {
				id
				type
				performedAt
				User {
					id
					firstName
					lastName
				}
				GrainPlan {
					id
					type
				}
				data
			}
			Plan {
				... on GrainCropPlan {
					id
					type
					hasWriteAccess
					Crop {
						id
						name
					}
					CropYear {
						id
						year
					}
					Customer {
						id
						name
						RelationshipOwner {
							id
							firstName
							lastName
						}
					}
				}
			}
			GrainTargetOrder {
				id
				Notes(orderBy: { createdAt: Desc }) {
					orderId
					id
					note
					writtenBy
					createdAt
					Order {
						id
						type
					}
				}
				Activity(orderBy: { performedAt: Desc }) {
					id
					data
					type
					operationType
					performedAt
					performedAt
					GrainPlan {
						id
						type
					}
					User {
						firstName
						lastName
					}
				}
			}
			Notes(orderBy: { createdAt: Desc }) {
				orderId
				id
				note
				writtenBy
				createdAt
				Order {
					id
					type
				}
			}
			GrainFillOrderModificationRequests {
				id
				createdAt
				type
				fillId
				status
				shortNote
				OwnedByUser {
					id
					firstName
					lastName
				}
				RequestedByUser {
					id
					firstName
					lastName
					phoneNumber
				}
				Location {
					id
					name
				}
				Seller {
					id
					name
				}
				Buyer {
					id
					name
				}
				data {
					basis
					bushels
					buyerId
					deliveryStartDate
					deliveryEndDate
					deliveryId
					flexFee
					futurePrice
					futuresMonth
					htaFee
					deliveryTerms
					locationId
					sellerId
					spread
					rollFee
				}
				GrainFillOrder {
					id
					basis
					bushels
					buyerId
					contractNumber
					createdAt
					createdBy
					deliveryEndDate
					deliveryMonth
					deliveryStartDate
					fees
					fees
					flatPrice
					flexFee
					futurePrice
					futuresMonth
					htaFee
					deliveryTerms
					locationId
					Plan {
						id
						type
						hasWriteAccess
						... on GrainCropPlan {
							id
							Crop {
								id
								name
							}
							CropYear {
								id
								year
							}
						}
					}
					rollFee
					salesType
					sellerId
					spread
					targetId
					Location {
						id
						name
					}
					Buyer {
						id
						name
					}
				}
			}
			GrainTargetOrder {
				id
				Activity(orderBy: { performedAt: Desc }) {
					id
					data
					type
					operationType
					performedAt
					performedAt
					GrainPlan {
						id
						type
					}
					User {
						id
						firstName
						lastName
					}
				}
			}
		}
	}
`;

type GetFill = {
	__typename?: 'Query';

	GrainFillOrder: Query['GrainFillOrder'];
};

export default class VgsGrainServicesFillsShow extends Route {
	@tracked variables: Query_GrainFillOrderArgs = {
		id: '',
	};

	@tracked notesVariables: Query_GrainOrderNotesArgs = {};

	getGrainFill = useQuery<GetFill, Query_GrainFillOrderArgs>(this, () => [GET_GRAIN_FILL, { variables: this.variables }]);

	async model(params: { id: string }) {
		this.variables = {
			id: params.id,
		};
		this.notesVariables = {
			where: {
				Order: {
					id: {
						equals: params.id,
					},
				},
			},
		};

		await this.getGrainFill.promise;

		return { getGrainFill: this.getGrainFill };
	}
}
