import { User } from '@auth0/auth0-spa-js/dist/typings/global';
import RouteInfo from '@ember/routing/route-info';
import mixpanel from 'mixpanel-browser';
import posthog from 'posthog-js';
import ENV from 'vault-client/config/environment';


const analyticsPageView = (page: string, params?: any[], currentRoute?: RouteInfo): void => {
  if (shouldLogEvent()) {
    mixpanel.track('Page View', {
      Page: page,
      Params: params,
    });
  }
  posthog.capture('$pageview', {
    page: page,
    params: params,
    local: currentRoute?.localName ?? '',
    base: currentRoute?.parent?.localName ??'',
    context: currentRoute?.parent?.parent?.localName ?? '',
  });
};

const analyticsCustomTracking = (eventTitle: string, eventPropertyObj: any): void => {
  if (shouldLogEvent()) {
    mixpanel.track(eventTitle, eventPropertyObj);
  }
  posthog.capture(eventTitle, eventPropertyObj);
};

const setAnalyticsDistinctId = (user: User): void => {
  if (shouldLogEvent()) {
    mixpanel.identify(user?.sub);
    mixpanel.people.set({
      $email: user?.email ?? '',
      $name: (user?.given_name && user?.family_name) ? user?.given_name + ' ' + user?.family_name : '',
      $first_name: user?.given_name ?? '',
      $family_name: user?.family_name ?? '',
      $distinct_id: user?.sub ?? '',
    });
  }

  posthog.identify(user?.sub, {
    email: user?.email ?? '',
    name: (user?.given_name && user?.family_name) ? user?.given_name + ' ' + user?.family_name : '',
    first_name: user?.given_name ?? '',
    last_name: user?.family_name ?? '',
  });
};

const removeSensitiveParams = (url: string): string => {
  if (!url) return '';
  if (url.indexOf('#') === -1) return url;

  const queryString = url.split('#')[1];
  const searchParams = new URLSearchParams(queryString);

  // Sensitive parameters to be hidden
  const sensitiveParams = ['access_token'];

  sensitiveParams.forEach(param => {
    if (searchParams.has(param)) {
      searchParams.set(param, '');
    }
  });
  return url.split('#')[0] + '#' + searchParams.toString();
}

const shouldLogEvent = () => {
  const hostName = window?.location?.hostname;
  const isProductionHostName = hostName === ENV.APP.PRODUCTION_HOST_NAME;
  return ENV.environment === 'production' && isProductionHostName;
};

export { analyticsPageView, analyticsCustomTracking, setAnalyticsDistinctId, removeSensitiveParams };
