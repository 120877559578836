import AccountsShowController from 'vault-client/controllers/accounts/show';

export default class OrganizationsOrganizationAccount extends AccountsShowController {
	accountsRoute = 'organizations.organization.accounts';
	positionsRoute = 'organizations.organization.positions';
	transactionsRoute = 'organizations.organization.transactions';
	transactionRoute = 'organizations.organization.transaction';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'organizations/organization/account': OrganizationsOrganizationAccount;
	}
}
