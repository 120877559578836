import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { CellComponents, TableColumn } from 'vault-client/types/vault-table';
import { ModelFrom } from 'vault-client/utils/type-utils';
import route from 'vault-client/routes/vgs/settings/locations';
import PermissionsService from 'vault-client/services/permissions';
export default class VgsSettingsLocations extends Controller {
	declare model: ModelFrom<route>;

	@service declare permissions: PermissionsService;
	@tracked sorts = [{ valuePath: 'name', isAscending: true }];

	get columns() {
		const baseColumns: TableColumn[] = [
			{
				id: '946d4d0b-27e0-424e-93af-7b017ac3a0cb',
				name: 'ID',
				valuePath: 'id',
				minWidth: 80,
				textAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '9977b936-f768-4172-99b9-8fb8d40ff396',
				name: 'Locations',
				valuePath: 'name',
				minWidth: 120,
				textAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
		];
		return baseColumns;
	}

	get rows() {
		return this.model.getLocations.data?.GrainLocations ?? [];
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	interface Registry {
		'vgs/settings/locations': VgsSettingsLocations;
	}
}
