import { InventoryLevelFilterDTO, InventoryLevelSortByDTO } from 'vault-client/types/graphql-types';

type SortObj = {
	valuePath: string;
	isAscending: boolean;
};

interface Params {
	id?: string;
	sorts?: SortObj[];
}

interface ScopeParams {
	organizationId: string | null;
	globalCustomerId: string | null;
	locationId: string | null;
}

export default function locationsInventoryIndexQuery(
	params?: Params,
	scopeParams?: ScopeParams
): {
	orderBy: InventoryLevelSortByDTO;
	where: InventoryLevelFilterDTO;
} {
	//
	// set sorting
	//
	const orderBy: {
		[key: string]: any;
	} = {};

	if (params?.sorts) {
		params.sorts.forEach((sort) => {
			const value = sort.isAscending ? 'Asc' : 'Desc';
			orderBy[sort.valuePath] = value;
		});
	}

	//
	// set filtering
	//

	const where: InventoryLevelFilterDTO = {};

	if (scopeParams) {
		if (scopeParams.locationId) {
			where.locationId = { equals: scopeParams.locationId };
		}
	}

	// if (params) {
	// }

	return { orderBy, where };
}
