import { inject as service } from '@ember/service';
import { DateTime } from 'luxon';
import { setOwner } from '@ember/application';
import { next } from '@ember/runloop';

export default class InsurancePractice {
	@service marketData: any;

	id: any;
	startDate: any;
	endDate: any;
	reinsuranceYear: any;
	practiceCode: any;

	classThreeSymbols: any;
	classFourSymbols: any;
	cheeseSymbols: any;
	butterSymbols: any;
	dryWheySymbols: any;

	marketDataService: any;

	constructor(owner: any, practice: any) {
		setOwner(this, owner);

		this.id = practice.id;
		this.startDate = practice.startDate;
		this.endDate = practice.endDate;
		this.reinsuranceYear = practice.reinsuranceYear;
		this.practiceCode = practice.practiceCode;

		const luxonStartDate = DateTime.fromISO(this.startDate);
		const quarter = luxonStartDate.quarter;
		const year = luxonStartDate.year - 2000; // I'm pretty confortable this won't be running in 80 years.

		let symbolSuffixes: any[] = [];

		switch (quarter) {
			case 1:
				symbolSuffixes = [`F${year}`, `G${year}`, `H${year}`];
				break;
			case 2:
				symbolSuffixes = [`J${year}`, `K${year}`, `M${year}`];
				break;
			case 3:
				symbolSuffixes = [`N${year}`, `Q${year}`, `U${year}`];
				break;
			case 4:
				symbolSuffixes = [`V${year}`, `X${year}`, `Z${year}`];
				break;
		}

		this.classThreeSymbols = symbolSuffixes.map((suffix) => `DL${suffix}`);
		this.classFourSymbols = symbolSuffixes.map((suffix) => `DK${suffix}`);
		this.cheeseSymbols = symbolSuffixes.map((suffix) => `BJ${suffix}`);
		this.butterSymbols = symbolSuffixes.map((suffix) => `BD${suffix}`);
		this.dryWheySymbols = symbolSuffixes.map((suffix) => `DG${suffix}`);

		this.marketDataService = this.marketData;

		next(this, function () {
			const symbols = [
				...this.classThreeSymbols,
				...this.classFourSymbols,
				...this.cheeseSymbols,
				...this.butterSymbols,
				...this.dryWheySymbols,
			];

			symbols.forEach((symbol) => {
				this.marketDataService.register(symbol);
			});
		});
	}

	get currentClassThreePrice() {
		const symbols = this.classThreeSymbols;

		const monthOne = this.marketData.getMarketDatum(symbols[0])?.lastPrice;
		const monthTwo = this.marketData.getMarketDatum(symbols[1])?.lastPrice;
		const monthThree = this.marketData.getMarketDatum(symbols[2])?.lastPrice;

		if (monthOne === undefined || monthTwo === undefined || monthThree === undefined) {
			return null;
		}

		const avg = (monthOne + monthTwo + monthThree) / 3;

		return avg.toFixed(2); // To match the RMA's values.
	}

	get currentClassFourPrice() {
		const symbols = this.classFourSymbols;

		const monthOne = this.marketData.getMarketDatum(symbols[0])?.lastPrice;
		const monthTwo = this.marketData.getMarketDatum(symbols[1])?.lastPrice;
		const monthThree = this.marketData.getMarketDatum(symbols[2])?.lastPrice;

		if (monthOne === undefined || monthTwo === undefined || monthThree === undefined) {
			return null;
		}

		const avg = (monthOne + monthTwo + monthThree) / 3;

		return avg.toFixed(2); // To match the RMA's values.
	}

	get currentCheesePrice() {
		const symbols = this.cheeseSymbols;

		const monthOne = this.marketData.getMarketDatum(symbols[0])?.lastPrice;
		const monthTwo = this.marketData.getMarketDatum(symbols[1])?.lastPrice;
		const monthThree = this.marketData.getMarketDatum(symbols[2])?.lastPrice;

		if (monthOne === undefined || monthTwo === undefined || monthThree === undefined) {
			return null;
		}

		return (monthOne + monthTwo + monthThree) / 3;
	}

	get currentButterPrice() {
		const symbols = this.butterSymbols;

		const monthOne = this.marketData.getMarketDatum(symbols[0])?.lastPrice;
		const monthTwo = this.marketData.getMarketDatum(symbols[1])?.lastPrice;
		const monthThree = this.marketData.getMarketDatum(symbols[2])?.lastPrice;

		if (monthOne === undefined || monthTwo === undefined || monthThree === undefined) {
			return null;
		}

		const avg = (monthOne + monthTwo + monthThree) / 3;

		return avg / 100; // To change into the correct magnitude.
	}

	get currentDryWheyPrice() {
		const symbols = this.dryWheySymbols;

		const monthOne = this.marketData.getMarketDatum(symbols[0])?.lastPrice;
		const monthTwo = this.marketData.getMarketDatum(symbols[1])?.lastPrice;
		const monthThree = this.marketData.getMarketDatum(symbols[2])?.lastPrice;

		if (monthOne === undefined || monthTwo === undefined || monthThree === undefined) {
			return null;
		}

		const avg = (monthOne + monthTwo + monthThree) / 3;
		return avg / 100; // To change into correct magnitude.
	}

	get currentButterfatPrice() {
		if (!this.currentButterPrice) return null;
		//(Butter Price - Butter Make Allowance ) x Butter Manufacturing Yield
		return (this.currentButterPrice - 0.1715) * 1.211;
	}

	get currentProteinPrice() {
		if (!this.currentCheesePrice || !this.currentButterfatPrice) return null;
		// ((Cheese Price - Cheese Make Allowance) x Cheese Manufacturing Yield Casein+ ((((Cheese Price - Cheese Make Allowance) x Cheese Manufacturing Yield Butterfat)) - Butterfat Price x Butterfat Retention Rate) x Butterfat-to-Protein Ratio
		return (
			(this.currentCheesePrice - 0.2003) * 1.383 + ((this.currentCheesePrice - 0.2003) * 1.572 - this.currentButterfatPrice * 0.9) * 1.17
		);
	}

	get currentOtherSolidsPrice() {
		if (!this.currentDryWheyPrice) return null;
		// (Dry Whey Price - Dry Whey Make Allowance) x Dry Whey Manufacturing Yield
		return (this.currentDryWheyPrice - 0.1991) * 1.03;
	}
}
