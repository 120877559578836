import EmberRouter from '@ember/routing/router';
import config from 'vault-client/config/environment';

export default class Router extends EmberRouter {
	location = config.locationType;
	rootURL = config.rootURL;
}

Router.map(function () {
	this.route('login');
	this.route('callback');

	this.route('organizations', function () {
		this.route('organization', { path: '/:id' }, function () {
			this.route('settings');
			this.route('details-and-members');
			this.route('permission-groups');
			this.route('permission-group', { path: '/permission-groups/:entity_group_id' });
			this.route('businesses');
			this.route('exposures');
			this.route('exposure', { path: '/exposure/:slug' });
			this.route('accounts');
			this.route('brokerage');
			this.route('transactions');
			this.route('positions');
			this.route('insurance');
			this.route('insurance-overview');
			this.route('insurance-overview-by-business');
			this.route('insurance-policies');
			this.route('drp-insurance-endorsements');
			this.route('lrp-insurance-endorsements');
			this.route('lgm-insurance-endorsements');
			this.route('insurance-documents');
			this.route('marketing');
			this.route('crop-plans');
			this.route('crop-targets');
			this.route('crop-fills');
			this.route('feed-contracts');
			this.route('position', { path: 'positions/:position_id' });
			this.route('account', { path: '/accounts/:account_id' });
			this.route('crop-plan', { path: '/crop-plans/:crop_plan_id' });
			this.route('drp-insurance-endorsement', { path: '/drp-insurance-endorsements/:endorsement_id' });
			this.route('lrp-insurance-endorsement', { path: '/lrp-insurance-endorsements/:endorsement_id' });
			this.route('lgm-insurance-endorsement', { path: '/lgm-insurance-endorsements/:endorsement_id' });
			this.route('insurance-policy', { path: '/insurance-policies/:policy_id' });
			this.route('transaction', { path: '/transactions/:transaction_id' });
			this.route('exposure-hedge-month-detail', { path: 'exposure-hedge-month-detail/:slug' });

			//
			//	Reports
			//
			this.route('reports');
			this.route('unmapped-insurance-policies');
			this.route('unmapped-accounts');
			this.route('butter-strategic-buying-model');
			this.route('butter-strategic-inventory-model');
			this.route('cheese-block-strategic-inventory-model');
			this.route('cheese-strategic-inventory-model');
			this.route('dry-whey-strategic-inventory-model');
			this.route('nfdm-strategic-inventory-model');
			this.route('cheese-block-strategic-buying-model');
			this.route('cheese-strategic-buying-model');
			this.route('class-iii-strategic-buying-model');
			this.route('class-iv-strategic-buying-model');
			this.route('dry-whey-strategic-buying-model');
			this.route('nfdm-strategic-buying-model');
			this.route('cattle-historical-percentiles');
			this.route('dairy-historical-percentiles');
			this.route('dairy-margin');
			this.route('drp-performance-by-aip');
			this.route('drp-performance-by-plan-year');
			this.route('drp-performance-by-sales-effective-date-month');
			this.route('historical-percentiles-by-product');
			this.route('inventory-hedges-by-product');
			this.route('lrp-calculator');

			this.route('not-found', { path: '/*' });
		});
	});

	this.route('businesses', function () {
		this.route('business', { path: '/:business_id' }, function () {
			this.route('dashboard');
			this.route('settings');
			this.route('business-settings');
			this.route('milk');
			this.route('production');
			this.route('milk-check');
			this.route('milk-check-reconciliation');
			this.route('milk-premiums');
			this.route('exposures');
			this.route('exposure', { path: '/exposure/:slug' });
			this.route('accounts');
			this.route('brokerage');
			this.route('transactions');
			this.route('positions');
			this.route('insurance');
			this.route('insurance-quarters');
			this.route('lgm-insurance-months');
			this.route('insurance-policies');
			this.route('drp-insurance-endorsements');
			this.route('lrp-insurance-endorsements');
			this.route('lgm-insurance-endorsements');
			this.route('insurance-documents');
			this.route('crops', function () {
				this.route('index');
			});
			this.route('crop-plans');
			this.route('crop-targets');
			this.route('crop-fills');
			this.route('feed-contracts');
			this.route('feed-targets');
			this.route('position', { path: 'positions/:position_id' });
			this.route('account', { path: '/accounts/:account_id' });
			this.route('crop-plan', { path: '/crop-plans/:crop_plan_id' });
			this.route('drp-insurance-endorsement', { path: '/drp-insurance-endorsements/:endorsement_id' });
			this.route('lrp-insurance-endorsement', { path: '/lrp-insurance-endorsements/:endorsement_id' });
			this.route('lgm-insurance-endorsement', { path: '/lgm-insurance-endorsements/:endorsement_id' });
			this.route('insurance-policy', { path: '/insurance-policies/:policy_id' });
			this.route('transaction', { path: '/transactions/:transaction_id' });
			this.route('hedge-strategies', function () {
				this.route('hedge-strategy', { path: '/:strategy_id' });
				this.route('edit-brokerage-transactions', function () {
					this.route('index', { path: '/:strategy_id' });
				});
				this.route('edit-lgm-endorsements', function () {
					this.route('index', { path: '/:strategy_id' });
				});
				this.route('edit-lrp-endorsements', function () {
					this.route('index', { path: '/:strategy_id' });
				});
			});

			this.route('exposure-hedge-month-detail', { path: 'exposure-hedge-month-detail/:slug' });
			//
			//	Reports
			//
			this.route('reports');
			this.route('swine-price-scenarios');
			this.route('price-scenarios');
			this.route('unmapped-insurance-policies');
			this.route('unmapped-accounts');
			this.route('butter-strategic-buying-model');
			this.route('butter-strategic-inventory-model');
			this.route('cheese-block-strategic-inventory-model');
			this.route('cheese-strategic-inventory-model');
			this.route('dry-whey-strategic-inventory-model');
			this.route('nfdm-strategic-inventory-model');
			this.route('cheese-block-strategic-buying-model');
			this.route('cheese-strategic-buying-model');
			this.route('class-iii-strategic-buying-model');
			this.route('class-iv-strategic-buying-model');
			this.route('dry-whey-strategic-buying-model');
			this.route('nfdm-strategic-buying-model');
			this.route('cattle-historical-percentiles');
			this.route('dairy-historical-percentiles');
			this.route('dairy-margin');
			this.route('drp-performance-by-aip');
			this.route('drp-performance-by-plan-year');
			this.route('drp-performance-by-sales-effective-date-month');
			this.route('historical-percentiles-by-product');
			this.route('inventory-hedges-by-product');
			this.route('lrp-calculator');
			this.route('locations');
			this.route('insurance-overview');
			this.route('operations');
			this.route('revenues');
			this.route('expenses');
			this.route('pl-by-calendar-month', function () {
				this.route('show', { path: '/:calendar_month' });
			});
			this.route('insurance-overview-show', { path: '/insurance-overview/:quarter_start_date' });
			this.route('livestock-feed');
			this.route('livestock-feed-overview-detail', { path: '/livestock-feed-overview/:ingredient_base' });
			this.route('livestock-feed-orders');
			this.route('livestock-feed-purchases');
			this.route('livestock-feed-expenses');
			this.route('not-found', { path: '/*' });
			this.route('production-contracts');
			this.route('animal-groups');
			this.route('pig-dashboard');
			this.route('swine');
			this.route('weaned-pigs');
			this.route('pig-cohorts');
			this.route('pig-cohort', { path: '/pig-cohorts/:birth_week_start_date' });
			this.route('pig-marketing');
			this.route('projected-pl');
			this.route('feed');
			this.route('feed-overview');
			this.route('feed-overview-detail', { path: '/feed-overview/:ingredient_base' });
			this.route('feed-costs');
			this.route('feed-ingredient', { path: '/feed-ingredients/:ingredient_id' });
			this.route('fields');
		});
	});

	this.route('users', function () {
		this.route('show', { path: '/:id' });
	});

	this.route('locations', function () {
		this.route('location', { path: '/:id' }, function () {
			this.route('settings');
			this.route('production');
			this.route('inventory.index', { path: '/inventory' });
			this.route('milk-check');
			this.route('milk-check-reconciliation');
			this.route('not-found', { path: '/*' });
		});
		this.route('index', { path: '/' });
	});

	this.route('markets', function () {
		this.route('product', { path: '/:product_id' }, function () {
			this.route('overview');
			this.route('futures', function () {
				this.route('future', { path: '/:future_id' });
			});
			this.route('cme-spot');
			this.route('seasonality');
		});
	});

	this.route('not-found', { path: '/*' });

	this.route('vgs', function () {
		this.route('customers', function () {
			this.route('index');
			this.route('show', { path: '/:id' });
		});

		this.route('grain-services', function () {
			this.route('fills', function () {
				this.route('index');
				this.route('show', { path: '/:id' });
			});

			this.route('targets', function () {
				this.route('index');
				this.route('show', { path: '/:id' });
			});
			this.route('crop-plans', function () {
				this.route('index');
				this.route('show', { path: '/:id' });
			});
			this.route('modification-requests');
		});

		this.route('feed-services', function () {
			this.route('modification-requests');
			this.route('feed-plans', function () {
				this.route('index');
				this.route('show', { path: '/:id' });
			});
			this.route('fills', function () {
				this.route('index');
				this.route('show', { path: '/:id' });
			});
			this.route('targets', function () {
				this.route('index');
				this.route('show', { path: '/:id' });
			});
		});

		this.route('settings', function () {
			this.route('advisors', function () {
				this.route('show', { path: '/:id' });
			});
			this.route('buyers');
			this.route('sellers');
			this.route('crops');
			this.route('ingredients');
			this.route('locations');
			this.route('permissions');
		});
	});
});
