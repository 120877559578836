import InsuranceIndexController from 'vault-client/controllers/insurance';

export default class BusinessesBusinessInsuranceController extends InsuranceIndexController {
	insuranceOverviewRoutePath = 'businesses.business.insurance-overview';
	policiesRoutePath = 'businesses.business.insurance-policies';
	drpEndorsementsRoutePath = 'businesses.business.drp-insurance-endorsements';
	lrpEndorsementsRoutePath = 'businesses.business.lrp-insurance-endorsements';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'businesses/business/insurance': BusinessesBusinessInsuranceController;
	}
}
