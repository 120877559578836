import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { CellComponents, TableColumn } from 'vault-client/types/vault-table';
import { ModelFrom } from 'vault-client/utils/type-utils';
import CropPlansShowRoute from 'vault-client/routes/vgs/grain-services/crop-plans/show';
import CropPlanModel from 'vault-client/models/vgs/crop-plan';
import Target from 'vault-client/models/vgs/target';
import PermissionsService from 'vault-client/services/permissions';
import { action } from '@ember/object';
import { saveAs } from 'file-saver';
import { GrainOrderNote, GrainTargetOrder } from 'vault-client/types/graphql-types';
import { generateFullName } from 'vault-client/utils/general';
import { getGrainOrderStatusDisplayValue, getSalesTypeDisplayValue } from 'vault-client/utils/vgs-utils';

export default class VgsGrainServicesCropPlansShow extends Controller {
	declare model: ModelFrom<CropPlansShowRoute>;
	@service declare permissions: PermissionsService;

	queryParams = ['showClosed'];
	@tracked showClosed = false;

	@tracked targetNotes: GrainOrderNote[] | null = null;
	@tracked fillNotes: GrainOrderNote[] | null = null;
	@tracked targetIdToRemove: string | null = null;
	@tracked isPrinting = false;
	@tracked showDeleteConfirmation = false;

	get targets(): TableColumn[] {
		const brOnlyColumns: TableColumn[] = [
			{
				id: 'e9fc1fcd-a9f0-4ee9-a5ed-ca4df0021f17',
				name: '',
				minWidth: 130,
				textAlign: 'center',
				cellComponent: CellComponents.Button,
				componentArgs: {
					size: 'xs',
					style: 'secondary',
					text: 'Remove',
					fn: this.removeTarget,
				},
				isFixed: '',
				isVisible: true,
			},
		];
		const baseColumns: TableColumn[] = [
			{
				id: '12d7bcda-85ea-45e2-b69c-54e5da7446e6',
				name: 'Status',
				valuePath: 'status',
				width: 90,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.CustomFormat,
				componentArgs: {
					formatter: getGrainOrderStatusDisplayValue,
				},
				isFixed: '',
				isVisible: true,
			},
			{
				id: '89431535-d84b-4767-af4b-03a7c9487686',
				name: '%',
				valuePath: 'percent',
				width: 70,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'percent',
					minimumFractionDigits: '0',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '4595c7af-dbaf-4241-827c-db9222da4bc3',
				name: 'Expires At',
				valuePath: 'expirationDate',
				width: 100,
				cellComponent: CellComponents.IntlDateTimeFormat,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'ae18c053-f087-4462-8577-82e0f203a63a',
				name: 'Bushels',
				valuePath: 'bushels',
				width: 90,
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '4082fff9-a324-4bb9-93c0-fe37a05cc449',
				name: 'Futures Month',
				valuePath: 'futuresMonth',
				width: 130,
				cellComponent: CellComponents.MonthFormat,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '525f27d4-95fd-4b7a-9802-4c87c1288d0b',
				name: 'Delivery Start Date',
				valuePath: 'deliveryStartDate',
				width: 155,
				cellComponent: CellComponents.IntlDateTimeFormat,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '5ad59a7e-f415-495d-afa3-9341bffa2746',
				name: 'Delivery End Date',
				valuePath: 'deliveryEndDate',
				width: 155,
				cellComponent: CellComponents.IntlDateTimeFormat,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'e9771fc7-9586-4deb-b5f9-7ecebb26b682',
				name: 'Sales Type',
				valuePath: 'salesType',
				width: 105,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.CustomFormat,
				componentArgs: {
					formatter: getSalesTypeDisplayValue,
				},
				isFixed: '',
				isVisible: true,
			},
			{
				id: '638c81de-9f80-4c9e-b837-71a73bdaae9e',
				name: 'Futures Price',
				valuePath: 'futurePrice',
				width: 120,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
					minimumFractionDigits: 2,
					maximumFractionDigits: 5,
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'af95be47-11b0-4f10-9497-6f5384545f1c',
				name: 'Basis',
				valuePath: 'basis',
				width: 85,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '425ed254-1d15-4bc3-bf75-e17a89468f86',
				name: 'Buyer',
				valuePath: 'Buyer.name',
				width: 105,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '97e5796d-c1e3-4730-8f8e-339c57d4c62f',
				name: 'Location',
				valuePath: 'Location.name',
				width: 115,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '170b9163-67f9-4537-ad9b-c41ed0846fc4',
				name: 'Contract Number',
				valuePath: 'contractNumber',
				width: 160,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '24a87e5c-13d2-4674-9ea0-c685aa815e92',
				name: 'Target Note',
				valuePath: 'targetNote',
				minWidth: 120,
				width: 120,
				cellComponent: CellComponents.String,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '5c401d38-d7e2-41af-936a-7c0842acdbe0',
				name: '',
				minWidth: 130,
				textAlign: 'center',
				isSortable: false,
				cellComponent: CellComponents.Button,
				componentArgs: {
					size: 'xs',
					style: 'secondary',
					text: 'Details',
					fn: () => {},
				},
				isFixed: '',
				isVisible: true,
				linkRoute: 'vgs.grain-services.targets.show',
				linkModelPath: 'id',
			},
		];

		const baseAndWriteColumns = [...baseColumns, ...brOnlyColumns];

		return this.cropPlan?.hasWriteAccess && this.permissions.isBuyerRelations ? baseAndWriteColumns : baseColumns;
	}

	get fills(): TableColumn[] {
		const baseColumns: TableColumn[] = [
			{
				id: '1fe9f75f-f7d1-4808-962b-9f9bfa3e95e9',
				name: 'Contract Number',
				valuePath: 'contractNumber',
				width: 160,
				textAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			// {
			// 	id: 'e3c4d688-6db1-47b7-ad6c-d321cd896f45',
			// 	name: 'Date Sold',
			// 	valuePath: 'dateSold',
			// 	width: 110,
			// 	cellComponent: CellComponents.IntlDateTimeFormat,
			// 	textAlign: 'left',
			// 	isSortable: true,
			// 	isFixed: '',
			// 	isVisible: false,
			// },
			{
				id: 'c6cad7e6-9578-488b-861e-93cb0cf0e353',
				name: 'Bushels',
				valuePath: 'bushels',
				width: 100,
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '6944c7fa-fad6-448a-a4f8-c25304d3791f',
				name: '%',
				valuePath: 'percentFilled',
				width: 80,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'percent',
					minimumFractionDigits: '0',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '345c5d1d-79db-4701-bf00-c28577c35b02',
				name: 'Futures Month',
				valuePath: 'futuresMonth',
				width: 145,
				cellComponent: CellComponents.MonthFormat,
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '84bc4bc0-51bb-4c64-ad11-9346383d6153',
				name: 'Delivery Start Date',
				valuePath: 'deliveryStartDate',
				width: 170,
				cellComponent: CellComponents.IntlDateTimeFormat,
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'abb6ea90-add2-42c4-851f-36a717b57e49',
				name: 'Delivery End Date',
				valuePath: 'deliveryEndDate',
				width: 170,
				cellComponent: CellComponents.IntlDateTimeFormat,
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '5cc488ac-cd03-442a-9011-a2daa9817b69',
				name: 'Sales Type',
				valuePath: 'salesType',
				width: 120,
				textAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.CustomFormat,
				componentArgs: {
					formatter: getSalesTypeDisplayValue,
				},
				isFixed: '',
				isVisible: true,
			},
			{
				id: '6d666b27-2e02-4e99-83d5-f85393fb763b',
				name: 'Futures Price',
				valuePath: 'futurePrice',
				width: 135,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
					minimumFractionDigits: 2,
					maximumFractionDigits: 5,
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '2aed83b6-228e-4f8e-abe9-d7da687c0719',
				name: 'Basis',
				valuePath: 'basis',
				width: 90,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'ce698c7d-443e-4ea6-8042-d6a50d41010e',
				name: 'Flat Price',
				valuePath: 'flatPrice',
				width: 95,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
					minimumFractionDigits: 2,
					maximumFractionDigits: 5,
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'e0208628-0f66-4a29-9b2d-fe926bcc4d91',
				name: 'Buyer',
				valuePath: 'Buyer.name',
				width: 110,
				textAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '40a5dff8-42dd-43b1-b130-1e69ca3d1784',
				name: 'Location',
				valuePath: 'Location.name',
				width: 170,
				textAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '845376af-66c6-4021-b63d-f15ce4f656a9',
				name: '',
				minWidth: 130,
				textAlign: 'center',
				isSortable: true,
				cellComponent: CellComponents.Button,
				componentArgs: {
					size: 'xs',
					style: 'secondary',
					text: 'Details',
					fn: () => {},
				},
				isFixed: '',
				isVisible: true,
				linkRoute: 'vgs.grain-services.fills.show',
				linkModelPath: 'id',
			},
		];
		return baseColumns;
	}

	get businessContact() {
		return (
			this.cropPlan?.Customer?.BusinessContacts.find((contact) => contact.isPrimary) ?? this.cropPlan?.Customer?.BusinessContacts[0] ?? null
		);
	}

	get businessContactName() {
		return generateFullName(this.businessContact?.firstName, this.businessContact?.lastName);
	}

	get cropPlan() {
		const cropPlan = this.model.getCropPlan.data?.GrainCropPlan;
		return cropPlan ? new CropPlanModel(cropPlan) : null;
	}

	get targetData() {
		return this.cropPlan?.Targets.map((target: GrainTargetOrder) => new Target(target, this.acres ?? 0, this.aph ?? 0)) ?? [];
	}

	get fillData() {
		return (
			this.cropPlan?.Fills.sort((a, b) => {
				if (!a.futuresMonth) return 1;
				if (!b.futuresMonth) return -1;
				if (new Date(a.futuresMonth).getTime() > new Date(b.futuresMonth).getTime()) return 1;

				return -1;
			}).sort((a, b) => {
				if (!a.deliveryStartDate) return 1;
				if (!b.deliveryStartDate) return -1;
				const aStartDate = new Date(a.deliveryStartDate).getTime();
				const bStartDate = new Date(b.deliveryStartDate).getTime();

				if (aStartDate === bStartDate) return 0;

				if (aStartDate > bStartDate) return 1;
				return -1;
			}) ?? []
		);
	}

	get acres() {
		return this.cropPlan?.acres;
	}

	get aph() {
		return this.cropPlan?.aph;
	}

	get modificationRequests() {
		const req =
			this.cropPlan?.Fills?.map((fill) => fill.GrainFillOrderModificationRequests)
				.filter((mod) => mod.length > 0)
				.flat()
				.sort((a, b) => (a.createdAt > b.createdAt ? -1 : 0)) ?? [];
		return req;
	}

	@action
	async print() {
		this.isPrinting = true;
		const productionObject = {
			id: this.cropPlan?.id,
			acres: this.cropPlan?.acres,
			aph: this.cropPlan?.aph,
			breakeven: this.cropPlan?.breakEven,
			goal: this.cropPlan?.goal,
			enrolledPercent: this.cropPlan?.enrolledPercent,
			harvestBu: this.cropPlan?.harvestBu,
			storageBu: this.cropPlan?.storageBu,
			Crop: this.cropPlan?.Crop,
			CropYear: this.cropPlan?.CropYear,
			Customer: this.cropPlan?.Customer,
			production: this.cropPlan?.production,
			averageFuturePriceSold: this.cropPlan?.averageFuturePriceSold,
			averageFlatPriceSold: this.cropPlan?.averageFlatPriceSold,
			percentSold: this.cropPlan?.percentSold,
			bushelsFilled: this.cropPlan?.bushelsFilled,
			sumGrossRevenue: this.cropPlan?.sumGrossRevenue,
			Fills: this.fillData
				.map((fill) => {
					return {
						basis: fill.basis,
						bushels: fill.bushels,
						contractNumber: fill.contractNumber,
						deliveryMonth: fill.delivery_month,
						deliveryStartDate: fill.deliveryStartDate,
						deliveryEndDate: fill.deliveryEndDate,
						fees: fill.fees,
						flatPrice: fill.flatPrice,
						futurePrice: fill.futurePrice,
						futuresMonth: fill.futuresMonth,
						salesType: fill.salesType,
						spread: fill.spread,
						Buyer: fill.Buyer,
						Location: fill.Location,
						netPrice: fill.netPrice,
						grossRevenue: fill.grossRevenue,
						percentProduction: fill.bushels && this.cropPlan?.production ? fill.bushels / this.cropPlan?.production : 0,
					};
				})
				.sort((a, b) => {
					if (!a.futuresMonth) return 1;
					if (!b.futuresMonth) return -1;
					if (new Date(a.futuresMonth).getTime() > new Date(b.futuresMonth).getTime()) return 1;

					return -1;
				})
				.sort((a, b) => {
					if (!a.deliveryStartDate) return 1;
					if (!b.deliveryStartDate) return -1;

					const aStartDate = new Date(a.deliveryStartDate).getTime();
					const bStartDate = new Date(b.deliveryStartDate).getTime();

					if (aStartDate === bStartDate) return 0;

					if (aStartDate > bStartDate) return 1;
					return -1;
				}),
			Targets: this.targetData.map((target: Target) => {
				return {
					basis: target.basis,
					bushels: target.bushels,
					contractNumber: target.contractNumber,
					deliveryStartDate: target.deliveryStartDate,
					deliveryEndDate: target.deliveryEndDate,
					fees: target.fees,
					futurePrice: target.futurePrice,
					futuresMonth: target.futuresMonth,
					spread: target.spread,
					salesType: target.salesType,
					Buyer: target.Buyer,
					Location: target.Location,
					netPrice: target.netPrice,
					grossRevenue: target.grossRevenue,
					percentProduction: target.bushels && this.cropPlan?.production ? target.bushels / this.cropPlan?.production : 0,
				};
			}),
		};

		const response = await fetch('/.netlify/functions/generate_crop_plan', {
			method: 'POST',
			body: JSON.stringify(productionObject),
		});

		const responseBlob = await response.blob();
		const fileName = `${this.cropPlan?.CropYear?.year} ${this.cropPlan?.Crop?.name} Crop Plan - ${this.cropPlan?.Customer?.name}.pdf`;
		await saveAs(responseBlob, fileName);
		this.isPrinting = false;
	}

	@action
	removeTarget(row: Target) {
		this.targetIdToRemove = row.id;
	}

	@action
	closeRemoveTargetModal() {
		this.targetIdToRemove = null;
	}

	@action
	deletePlan() {
		this.showDeleteConfirmation = true;
	}

	@action
	closeDeletePlanModalFn() {
		this.showDeleteConfirmation = false;
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	interface Registry {
		'vgs/grain-services/crop-plans/show': VgsGrainServicesCropPlansShow;
	}
}
