import ReportsDairyMargin from 'vault-client/controllers/reports/dairy-margin';

export default class BusinessesBusinessDairyMargin extends ReportsDairyMargin {
	reportsRoute: string = 'businesses.business.reports';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'businesses/business/dairy-margin': BusinessesBusinessDairyMargin;
	}
}
