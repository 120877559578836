import { DateTime } from 'luxon';

export default class FoundationTarget {
	Buyer: any;
	Seller: any;
	Delivery: any;
	Location: any;
	CropPlan: any;
	FeedPlan: any;
	TargetActivity: any;
	TargetStatus: any;

	basis: any;
	bushels: any;
	contract_number: any;
	created_at: any;
	created_by: any;
	delivery_month: any;
	_delivery_start_date: any;
	_delivery_end_date: any;
	expires_at: any;
	fees: any;
	flex_fee: any;
	future_price: any;
	futures_month: any;
	hta_fee: any;
	id: any;
	roll_fee: any;
	sales_type: any;
	spread_gain: any;

	constructor(target: any) {
		this.Buyer = target.Buyer;
		this.Seller = target.Seller;
		this.Delivery = target.Delivery;
		this.Location = target.Location;
		this.CropPlan = target.CropPlan;
		this.FeedPlan = target.FeedPlan;
		this.TargetActivity = target.TargetActivity;
		this.TargetStatus = target.TargetStatus;

		this.basis = target.basis;
		this.bushels = target.bushels;
		this.contract_number = target.contractNumber;
		this.created_at = target.createdAt;
		this.created_by = target.createdBy;
		this.delivery_month = target.deliveryMonth;
		this._delivery_start_date = target.deliveryStartDate;
		this._delivery_end_date = target.deliveryEndDate;
		this.expires_at = target.expirationDate;
		this.fees = target.fees;
		this.flex_fee = target.flexFee;
		this.future_price = target.futurePrice;
		this.futures_month = target.futuresMonth;
		this.hta_fee = target.htaFee;
		this.id = target.id;
		this.roll_fee = target.rollFee;
		this.sales_type = target.salesType;
		this.spread_gain = target.spreadGain;
	}

	get deliveryStartDate() {
		return this._delivery_start_date ?? this.delivery_month;
	}

	get deliveryEndDate() {
		return this._delivery_end_date ?? DateTime.fromISO(this.delivery_month).endOf('month').toISODate();
	}

	get netPrice() {
		return this.future_price + this.basis + this.fees + this.spread_gain;
	}

	get grossRevenue() {
		return this.netPrice * this.bushels;
	}
}
