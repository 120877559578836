import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @permissionGroupMembership}}\n\t<Vault::UiConfirmation as |confirmation|>\n\t\t<confirmation.title>Remove Permission Group Membership</confirmation.title>\n\t\t<confirmation.body>Are you sure that you want to remove this permission group membership?\n\t\t\t<br />\n\t\t\t<span class='font-sans-semibold'>\n\t\t\t\t{{@permissionGroupMembership.Entity.name}}\n\t\t\t</span>\n\t\t\t<br />\n\t\t\tYou cannot undo this action\n\t\t</confirmation.body>\n\t\t<confirmation.cancel @style='plain' {{on 'click' this.closeConfirmation}}>Cancel</confirmation.cancel>\n\t\t<confirmation.action\n\t\t\t@style='destructive'\n\t\t\t@disabled={{this.submitting}}\n\t\t\t{{on 'click' this.removePermissionGroupMembership}}\n\t\t>Remove</confirmation.action>\n\t</Vault::UiConfirmation>\n{{/if}}", {"contents":"{{#if @permissionGroupMembership}}\n\t<Vault::UiConfirmation as |confirmation|>\n\t\t<confirmation.title>Remove Permission Group Membership</confirmation.title>\n\t\t<confirmation.body>Are you sure that you want to remove this permission group membership?\n\t\t\t<br />\n\t\t\t<span class='font-sans-semibold'>\n\t\t\t\t{{@permissionGroupMembership.Entity.name}}\n\t\t\t</span>\n\t\t\t<br />\n\t\t\tYou cannot undo this action\n\t\t</confirmation.body>\n\t\t<confirmation.cancel @style='plain' {{on 'click' this.closeConfirmation}}>Cancel</confirmation.cancel>\n\t\t<confirmation.action\n\t\t\t@style='destructive'\n\t\t\t@disabled={{this.submitting}}\n\t\t\t{{on 'click' this.removePermissionGroupMembership}}\n\t\t>Remove</confirmation.action>\n\t</Vault::UiConfirmation>\n{{/if}}","moduleName":"vault-client/components/remove-grain-advisor-permission-group-membership-modal.hbs","parseOptions":{"srcName":"vault-client/components/remove-grain-advisor-permission-group-membership-modal.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { gql, useMutation } from 'glimmer-apollo';
import { EntityMember, Mutation, Mutation_deleteEntityMemberArgs } from 'vault-client/types/graphql-types';

interface RemoveGrainAdvisorPermissionGroupMembershipModalArgs {
	permissionGroupMembership: EntityMember | null;
	closeModalFn: () => void;
}

const DELETE_PERMISSION_GROUP_MEMBERSHIP = gql`
	mutation deleteEntityMember($id: String!) {
		deleteEntityMember(id: $id) {
			id
		}
	}
`;

export default class RemoveGrainAdvisorPermissionGroupMembershipModal extends Component<RemoveGrainAdvisorPermissionGroupMembershipModalArgs> {
	@tracked submitting = false;

	deletePermissionGroupMembership = useMutation<{ deleteEntityMember: Mutation['deleteEntityMember'] }, Mutation_deleteEntityMemberArgs>(
		this,
		() => [
			DELETE_PERMISSION_GROUP_MEMBERSHIP,
			{
				update: (cache) => {
					cache.evict({ fieldName: 'GrainAdvisor' });
					cache.evict({ fieldName: 'EntityGroups' });
					cache.gc();
				},
			},
		]
	);

	@action
	async removePermissionGroupMembership() {
		this.submitting = true;
		const { id } = this.args.permissionGroupMembership ?? {};

		if (id) {
			await this.deletePermissionGroupMembership.mutate({
				id,
			});
		}

		this.closeConfirmation();
		this.submitting = false;
	}

	@action
	closeConfirmation() {
		this.args.closeModalFn();
	}
}
