import Service from '@ember/service';
import { gql, useQuery } from 'glimmer-apollo';
import { Query } from 'vault-client/types/graphql-types';

export const GET_CROP_YEARS = gql`
	query GrainCropPlans {
		GrainCropPlans(distinctOn: { yearId: true }) {
			id
			yearId
			CropYear {
				id
				year
			}
		}
	}
`;

type GetCropYears = {
	__typename?: 'Query';
	GrainCropPlans: Query['GrainCropPlans'];
};

export default class GetAvailableCropYearsService extends Service {
	getCropYears = useQuery<GetCropYears>(this, () => [GET_CROP_YEARS]);

	get cropYears() {
		const availableCropYears = new Set<number>();
		this.getCropYears.data?.GrainCropPlans.forEach((plan) => {
			plan.CropYear ? availableCropYears.add(plan.CropYear.year) : null;
		});
		return Array.from(availableCropYears).sort();
	}
}

// Don't remove this declaration: this is what enables TypeScript to resolve
// this service using `Owner.lookup('service:get-available-crop-years')`, as well
// as to check when you pass the service name as an argument to the decorator,
// like `@service('get-available-crop-years') declare altName: GetAvailableCropYearsService;`.
declare module '@ember/service' {
	interface Registry {
		'get-available-crop-years': GetAvailableCropYearsService;
	}
}
