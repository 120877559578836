import InsuranceDocumentsIndex from 'vault-client/controllers/insurance-documents';

export default class BusinessesBusinessInsuranceDocuments extends InsuranceDocumentsIndex {
	insuranceRoutePath: string = 'businesses.business.insurance';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'businesses/business/insurance-documents': BusinessesBusinessInsuranceDocuments;
	}
}
