import HypotheticalPosition, { HypotheticalPositionJsonFormat } from 'vault-client/models/hypothetical-position';
import checkStorageAvailable from './check-storage-available';

function retrieveAddedHypotheticalPositionsFromStorage(
	entityId: string | null,
	hypotheticalPositions: HypotheticalPosition[],
	owner: any
): HypotheticalPosition[] {
	if (!entityId || !checkStorageAvailable('localStorage')) return hypotheticalPositions;

	const localStorageString = `swine-price-scenarios-hypothetical-positions.${entityId}`;

	const temp = window.localStorage.getItem(localStorageString);
	if (!temp) return hypotheticalPositions;

	const hypotheticalPositionObjects = JSON.parse(temp) as HypotheticalPositionJsonFormat[];

	hypotheticalPositionObjects.forEach((obj: HypotheticalPositionJsonFormat) => {
		if (obj) {
			hypotheticalPositions.push(HypotheticalPosition.fromJson(obj, owner));
		}
	});

	return hypotheticalPositions;
}

export { retrieveAddedHypotheticalPositionsFromStorage };
