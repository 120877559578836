import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import resetVaultTableScroll from 'vault-client/utils/reset-vault-table-scroll';
import BusinessesCropFills from 'vault-client/routes/businesses/business/crop-fills';
import { ModelFrom } from 'vault-client/utils/type-utils';
import OrganizationsCropFills from 'vault-client/routes/organizations/organization/crop-fills';
import Fill from 'vault-client/models/vgs/fill';
import { GrainCropPlan, GrainFillOrder } from 'vault-client/types/graphql-types';
import PermissionsService from 'vault-client/services/permissions';
import { GET_CROP_FILLS } from 'vault-client/routes/crop-fills';
import { CellComponents } from 'vault-client/types/vault-table';
import { getSalesTypeDisplayValue } from 'vault-client/utils/vgs-utils';

export default class CropFillsIndexController extends Controller {
	@service declare permissions: PermissionsService;

	@tracked size = 100;
	@tracked page = 0;
	@tracked sorts = [];

	queryParams = ['customerId', 'page', 'sorts', 'size'];

	declare model: ModelFrom<BusinessesCropFills> | ModelFrom<OrganizationsCropFills>;

	itemsFn = (rows: GrainFillOrder[]) => {
		return rows.map((row) => {
			const plan = row.Plan as GrainCropPlan;
			return new Fill(row, plan.acres, plan.aph, null, null, null, plan.Customer ?? null);
		});
	};

	get columns() {
		const baseColumns = [
			{
				id: 'ae3d9770-23be-447d-b958-8d341a23d701',
				name: 'Business',
				valuePath: 'Plan.Customer.name',
				width: 200,
				textAlign: 'left',
				isSortable: false,
				cellComponent: 'String',
				isFixed: '',
				isVisible: true,
				linkRoute: 'businesses.business.dashboard',
				linkModelPath: 'Plan.Customer.id',
			},
			{
				id: 'cc51577c-6a55-4bb3-b993-e702a741f3f5',
				name: 'Year',
				valuePath: 'Plan.CropYear.year',
				width: 80,
				textAlign: 'left',
				isSortable: false,
				cellComponent: 'String',
				isFixed: '',
				isVisible: true,
			},
			{
				id: '3f267077-6afc-42dd-adca-22a303c7fa28',
				name: 'Crop',
				valuePath: 'Plan.Crop.name',
				width: 100,
				textAlign: 'left',
				isSortable: false,
				cellComponent: 'String',
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'c6cad7e6-9578-488b-861e-93cb0cf0e353',
				name: 'Bushels',
				valuePath: 'bushels',
				width: 110,
				cellComponent: 'IntlNumberFormat',
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '345c5d1d-79db-4701-bf00-c28577c35b02',
				name: 'Futures Month',
				valuePath: 'futuresMonth',
				width: 150,
				cellComponent: 'MonthFormat',
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '84bc4bc0-51bb-4c64-ad11-9346383d6153',
				name: 'Delivery Start Date',
				valuePath: 'deliveryStartDate',
				width: 200,
				cellComponent: 'IntlDateTimeFormat',
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'abb6ea90-add2-42c4-851f-36a717b57e49',
				name: 'Delivery End Date',
				valuePath: 'deliveryEndDate',
				width: 200,
				cellComponent: 'IntlDateTimeFormat',
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '5cc488ac-cd03-442a-9011-a2daa9817b69',
				name: 'Sales Type',
				valuePath: 'salesType',
				width: 100,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.CustomFormat,
				componentArgs: {
					formatter: getSalesTypeDisplayValue,
				},
				isFixed: '',
				isVisible: true,
			},
			{
				id: '6d666b27-2e02-4e99-83d5-f85393fb763b',
				name: 'Futures Price',
				valuePath: 'futurePrice',
				width: 140,
				cellComponent: 'IntlNumberFormat',
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '8de081ca-3c9e-4e6e-976e-69dfc7322224',
				name: 'Flat Price',
				valuePath: 'flatPrice',
				width: 140,
				cellComponent: 'IntlNumberFormat',
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'e62bc02a-59af-4395-b7b0-5a6abe4d656d',
				name: 'Fees',
				valuePath: 'fees',
				width: 140,
				cellComponent: 'IntlNumberFormat',
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '9c9690a7-4cac-4158-8b1d-d15152b7a7a3',
				name: 'Spread Gain',
				valuePath: 'spread',
				width: 140,
				cellComponent: 'IntlNumberFormat',
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '2aed83b6-228e-4f8e-abe9-d7da687c0719',
				name: 'Basis',
				valuePath: 'basis',
				width: 140,
				cellComponent: 'IntlNumberFormat',
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'e0208628-0f66-4a29-9b2d-fe926bcc4d91',
				name: 'Buyer',
				valuePath: 'Buyer.name',
				width: 180,
				textAlign: 'left',
				isSortable: false,
				cellComponent: 'String',
				isFixed: '',
				isVisible: true,
			},
			{
				id: '40a5dff8-42dd-43b1-b130-1e69ca3d1784',
				name: 'Location',
				valuePath: 'Location.name',
				width: 180,
				textAlign: 'left',
				isSortable: false,
				cellComponent: 'String',
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'c331946e-6bdf-45e1-aa5d-79c3da657d32',
				name: 'Delivery',
				valuePath: 'DeliveryLocation.name',
				width: 180,
				textAlign: 'left',
				isSortable: false,
				cellComponent: 'String',
				isFixed: '',
				isVisible: true,
			},
			{
				id: '1fe9f75f-f7d1-4808-962b-9f9bfa3e95e9',
				name: 'Contract Number',
				valuePath: 'contractNumber',
				width: 200,
				textAlign: 'left',
				isSortable: false,
				cellComponent: 'String',
				isFixed: '',
				isVisible: true,
			},
		];
		return baseColumns;
	}

	get sortParams() {
		return this.sorts;
	}

	set sortParams(sorts) {
		this.sorts = sorts;
	}

	get currentPage() {
		return this.page;
	}

	set currentPage(page) {
		this.page = page;
	}

	get grainFillData() {
		return this.itemsFn(this.model.getGrainFills.data?.GrainFillOrders ?? []);
	}

	get totalNumGrainFills() {
		return this.model.getGrainFills.data?.GrainFillOrderCount.count ?? 0;
	}

	get query() {
		return GET_CROP_FILLS;
	}

	get formattedQueryParams() {
		return this.model.variables;
	}

	@action
	setTablePageState(newPageVal = 0) {
		this.currentPage = newPageVal;
		resetVaultTableScroll('crop-fills-index-table');
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	interface Registry {
		'crop-fills-index': CropFillsIndexController;
	}
}
