import BrokerageIndexController from 'vault-client/controllers/brokerage/index';

export default class OrganizationsOrganizationBrokerage extends BrokerageIndexController {
	accountsRoutePath: string = 'organizations.organization.accounts';
	positionsRoutePath: string = 'organizations.organization.positions';
	transactionsRoutePath: string = 'organizations.organization.transactions';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'organizations/organization/brokerage': OrganizationsOrganizationBrokerage;
	}
}
