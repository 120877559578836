import Route from '@ember/routing/route';
import {
	SwineLivestockPopulationForecastByWeek,
	Query_SwineLivestockPopulationForecastsByWeekArgs,
	SortByDirection,
} from 'vault-client/types/graphql-types';
import { gql, useQuery } from 'glimmer-apollo';
import { tracked } from '@glimmer/tracking';

const GET_PIG_COHORTS = gql`
	query SwineLivestockPopulationForecastsByWeek(
		$where: SwineLivestockPopulationForecastByWeekFilterDTO
		$orderBy: SwineLivestockPopulationForecastByWeekSortByDTO
	) {
		SwineLivestockPopulationForecastsByWeek(where: $where, orderBy: $orderBy, limit: 2000) {
			ageInWeeks
			date
			updatedAt
			startQuantity
			readableWeek
			livestockGroupId
			id
			hasWriteAccess
			exactStartQuantity
			exactEndQuantity
			endQuantity
			dob
			createdAt
			businessId
			Business {
				id
			}
		}
		SwineLivestockPopulationChanges(limit: 2000) {
			dobWeek
		}
	}
`;

type GetSwineLivestockPopulationForecastsByWeekQuery = {
	__typename?: 'Query';
	SwineLivestockPopulationForecastsByWeek: SwineLivestockPopulationForecastByWeek[];
};
export default class BusinessesBusinessPigCohorts extends Route {
	@tracked variables: Query_SwineLivestockPopulationForecastsByWeekArgs = {};

	getPigCohorts = useQuery<GetSwineLivestockPopulationForecastsByWeekQuery, Query_SwineLivestockPopulationForecastsByWeekArgs>(this, () => [
		GET_PIG_COHORTS,
		{ variables: this.variables },
	]);

	queryParams = {
		startDate: {
			refreshModel: true,
		},
		endDate: {
			refreshModel: true,
		},
	};

	async model() {
		const { business_id } = this.paramsFor('businesses.business') as { business_id: string };

		this.variables = {
			where: {
				businessId: {
					equals: business_id,
				},
			},
			orderBy: {
				dob: SortByDirection.Asc,
				date: SortByDirection.Asc,
			},
		};
		await this.getPigCohorts.promise;
		const cohorts = this.getPigCohorts;

		return { cohorts };
	}
}
