import FeedOrdersRoute from 'vault-client/routes/feed/orders';

export default class BusinessesBusinessFeedOrdersRoute extends FeedOrdersRoute {
	async model() {
		const { business_id: businessId } = this.paramsFor('businesses.business') as { business_id: string };
		this.variables = {
			feedIngredientsWhere: this.generateFeedIngredientsWhere(businessId),
		};

		await this.getFeedOrders.promise;

		return {
			getFeedOrders: this.getFeedOrders,
			businessId,
		};
	}
}
