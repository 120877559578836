import Service, { service } from '@ember/service';
import { getClient, gql, useQuery } from 'glimmer-apollo';
import { GrainFillOrderModificationRequest, Query, TypeOfGrainPlan } from 'vault-client/types/graphql-types';
import PermissionsService from './permissions';
import sendBrowserNotification from 'vault-client/utils/send-browser-notification';

const NEW_MODIFICATION_REQUESTS = gql`
	query PendingCancellationGrainFillOrderModificationRequests {
		GrainFillOrderModificationRequests(where: { status: { equals: New } }) {
			id
			status
			RequestedByUser {
				firstName
				lastName
			}
			type
			GrainFillOrder {
				id
				Plan {
					id
					type
				}
			}
		}
	}
`;

type GetPendingCancellationModificationRequests = {
	GrainFillOrderModificationRequests: Query['GrainFillOrderModificationRequests'];
};
export default class NewFillModificationRequestMonitorService extends Service {
	@service declare permissions: PermissionsService;
	cache = getClient(this).cache;
	previouslyExistingFillModifications: string[] | null = null;

	getNewModificationRequests = useQuery<GetPendingCancellationModificationRequests>(this, () => [
		NEW_MODIFICATION_REQUESTS,
		{ onComplete: (data) => this.updateFillModifications(data?.GrainFillOrderModificationRequests ?? []), pollInterval: 10000 },
	]);

	get grainCount() {
		return (
			this.getNewModificationRequests.data?.GrainFillOrderModificationRequests?.filter(
				(modReq) => modReq.GrainFillOrder?.Plan?.type === TypeOfGrainPlan.Crop,
			).length ?? null
		);
	}

	updateFillModifications(fillModifications: GrainFillOrderModificationRequest[]) {
		const newFillModificationIds = fillModifications.map((v) => v.id);

		if (this.previouslyExistingFillModifications === null) {
			this.previouslyExistingFillModifications = newFillModificationIds;
			return;
		}

		const uniqueNewModifications = this.filterNewRequests(this.previouslyExistingFillModifications, newFillModificationIds);
		if (uniqueNewModifications.length == 0) return;

		uniqueNewModifications.forEach((id) => {
			const newModification = fillModifications.find((modification) => modification.id === id);

			this.previouslyExistingFillModifications = newFillModificationIds;
			this.evictFillModificationRequestsCache();

			if (
				newModification &&
				newModification.RequestedByUser?.firstName == this.permissions.me?.firstName &&
				newModification.RequestedByUser?.lastName == this.permissions.me?.lastName
			)
				return;

			if (newModification) {
				sendBrowserNotification(
					`${newModification.RequestedByUser?.firstName} ${newModification.RequestedByUser?.lastName} requested a new ${newModification?.type} fill modification request.`,
				);
			}
		});
	}

	filterNewRequests(previouslyExistingFillModifications: string[], newFillModificationIds: string[]) {
		const existingModificationsSet = new Set(previouslyExistingFillModifications);

		const uniqueNewModifications = newFillModificationIds.filter((id) => !existingModificationsSet.has(id));

		return uniqueNewModifications;
	}

	evictFillModificationRequestsCache() {
		this.cache.evict({ fieldName: 'GrainFillOrderModificationRequests' });
		this.cache.evict({ fieldName: 'AggregateGrainFillOrderModificationRequests' });
		this.cache.evict({ fieldName: 'GrainFillOrderModificationRequest' });
		this.cache.gc();
	}
}
// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'new-fill-modification-request-monitor-service': NewFillModificationRequestMonitorService;
	}
}
