import Route from '@ember/routing/route';
import { RouteQueryParam } from '@ember/routing/types';
import { tracked } from '@glimmer/tracking';
import { gql, useQuery } from 'glimmer-apollo';
import { DateTime } from 'luxon';
import {
	CustomerEntity,
	Query,
	SwineLivestockPopulationChangeFilterDTO,
	TypeOfInstrument,
	TypeOfOption,
	TypeOfLivestockPopulationChangeReason,
	TypeOfLivestockPopulationChangeValue,
	AggregateEntityAllocatedExposureRatioByOwnerDTO,
	CurrentAllocationPositionFilterDTO,
} from 'vault-client/types/graphql-types';

interface ModelParams {
	startDate: string;
	endDate: string;
}

export const GET_PIGS_MARKETING = gql`
	query PigsMarketing(
		$customerId: String!
		$startDate: String!
		$endDate: String!
		$aggregateSwineLivestockPopulationChangesWhere: SwineLivestockPopulationChangeFilterDTO
		$aggregateCurrentAllocationPositionsWhere: CurrentAllocationPositionFilterDTO
	) {
		Customer(id: $customerId) {
			averageFinishAgeInWeeks
			averageFinishWeightInLbs
		}
		AggregateEntityAllocatedExposureRatiosByOwner(
			calc: { sum: { totalVolumeHedged: true, totalPercentVolumeHedged: true } }
			groupBy: { date: true }
			scopeId: $customerId
			where: { startDate: $startDate, endDate: $endDate, Product: { slug: { equals: "livestock-lean-hogs" } } }
		) {
			date
			sum {
				totalVolumeHedged
				totalPercentVolumeHedged
			}
		}
		AggregateCurrentAllocationPositions(
			calc: { sum: { grossPnl: true, contractQuantity: true } }
			groupBy: { effectiveHedgeDate: true }
			where: $aggregateCurrentAllocationPositionsWhere
		) {
			sum {
				grossPnl
				contractQuantity
			}
			effectiveHedgeDate
		}
		AggregateSwineLivestockPopulationChanges(
			calc: { sum: { quantity: true, totalValue: true } }
			groupBy: { date: true }
			where: $aggregateSwineLivestockPopulationChangesWhere
		) {
			date
			sum {
				quantity
				totalValue
			}
		}
		AggregateLrpInsuranceEndorsements(
			calc: { sum: { pnl: true } }
			groupBy: { salesEffectiveDate: true, coverageEndDate: true }
			where: {
				OR: [{ salesEffectiveDate: { gte: $startDate, lte: $endDate } }, { coverageEndDate: { gte: $startDate, lte: $endDate } }]
				Policy: { customerId: { equals: $customerId } }
				commodityCode: { equals: "0815" }
			} # Swine Code used as Commodity Name is causing an ISE
		) {
			salesEffectiveDate
			coverageEndDate
			sum {
				pnl
			}
		}
	}
`;

type GetPigsMarketingQuery = {
	Customer: CustomerEntity;
	AggregateEntityAllocatedExposureRatiosByOwner: AggregateEntityAllocatedExposureRatioByOwnerDTO[];
	AggregateCurrentAllocationPositions: Query['AggregateCurrentAllocationPositions'];
	AggregateSwineLivestockPopulationChanges: Query['AggregateSwineLivestockPopulationChanges'];
	AggregateLrpInsuranceEndorsements: Query['AggregateLrpInsuranceEndorsements'];
};

type GetPigsMarketingQueryArgs = {
	customerId?: string;
	aggregateSwineLivestockPopulationChangesWhere?: SwineLivestockPopulationChangeFilterDTO;
	aggregateCurrentAllocationPositionsWhere?: CurrentAllocationPositionFilterDTO;
	startDate?: string;
	endDate?: string;
};

export default class BusinessesBusinessPigMarketingRoute extends Route {
	@tracked variables: GetPigsMarketingQueryArgs = {};

	queryParams: { [key: string]: RouteQueryParam } = {
		startDate: {
			refreshModel: true,
		},
		endDate: {
			refreshModel: true,
		},
	};

	getPigsMarketing = useQuery<GetPigsMarketingQuery, GetPigsMarketingQueryArgs>(this, () => [
		GET_PIGS_MARKETING,
		{
			variables: this.variables,
		},
	]);

	async model(params: ModelParams) {
		const businessParams = this.paramsFor('businesses.business') as { business_id: string };

		this.variables = {
			customerId: businessParams.business_id,
			aggregateSwineLivestockPopulationChangesWhere: this.generateAggregateSwineLivestockPopulationChangesWhere(
				businessParams.business_id,
				params.startDate,
				params.endDate
			),
			aggregateCurrentAllocationPositionsWhere: this.generateAggregateCurrentAllocationPositionsWhere(
				businessParams.business_id,
				params.startDate,
				params.endDate
			),
			startDate: params.startDate,
			endDate: params.endDate,
		};

		await this.getPigsMarketing.promise;

		return {
			businessId: businessParams.business_id,
			lastUpdatedAt: DateTime.now().toISO(),
			getPigsMarketing: this.getPigsMarketing,
		};
	}

	generateAggregateCurrentAllocationPositionsWhere(
		businessId: string,
		startDate: string,
		endDate: string
	): CurrentAllocationPositionFilterDTO {
		return {
			businessId: {
				equals: businessId,
			},
			Product: { slug: { equals: 'livestock-lean-hogs' } },
			effectiveHedgeDate: { gte: startDate, lte: endDate },
			OR: [
				{ instrumentType: { equals: TypeOfInstrument.Future }, contractQuantity: { lt: 0 } },
				{ instrumentType: { equals: TypeOfInstrument.Option }, optionType: { equals: TypeOfOption.Put }, contractQuantity: { gt: 0 } },
			],
		};
	}

	generateAggregateSwineLivestockPopulationChangesWhere(
		businessId: string,
		startDate: string,
		endDate: string
	): SwineLivestockPopulationChangeFilterDTO {
		return {
			date: {
				gte: startDate,
				lte: endDate,
			},
			reasonType: {
				equals: TypeOfLivestockPopulationChangeReason.Sale,
			},
			valueType: { equals: TypeOfLivestockPopulationChangeValue.Forecasted },
			businessId: {
				equals: businessId,
			},
		};
	}
}
