const doc = {
  "kind": "Document",
  "definitions": [
    {
      "kind": "OperationDefinition",
      "operation": "query",
      "name": {
        "kind": "Name",
        "value": "ForecastedMilkUtilizations"
      },
      "variableDefinitions": [
        {
          "kind": "VariableDefinition",
          "variable": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "where"
            }
          },
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "ForecastedMilkUtilizationFilterDTO"
            }
          },
          "directives": []
        },
        {
          "kind": "VariableDefinition",
          "variable": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "locationId"
            }
          },
          "type": {
            "kind": "NonNullType",
            "type": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "String"
              }
            }
          },
          "directives": []
        },
        {
          "kind": "VariableDefinition",
          "variable": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "startDate"
            }
          },
          "type": {
            "kind": "NonNullType",
            "type": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "String"
              }
            }
          },
          "directives": []
        },
        {
          "kind": "VariableDefinition",
          "variable": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "endDate"
            }
          },
          "type": {
            "kind": "NonNullType",
            "type": {
              "kind": "NamedType",
              "name": {
                "kind": "Name",
                "value": "String"
              }
            }
          },
          "directives": []
        }
      ],
      "directives": [],
      "selectionSet": {
        "kind": "SelectionSet",
        "selections": [
          {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "Location"
            },
            "arguments": [
              {
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "id"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "locationId"
                  }
                }
              }
            ],
            "directives": [],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [
                {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "id"
                  },
                  "arguments": [],
                  "directives": []
                },
                {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "name"
                  },
                  "arguments": [],
                  "directives": []
                },
                {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "County"
                  },
                  "arguments": [],
                  "directives": [],
                  "selectionSet": {
                    "kind": "SelectionSet",
                    "selections": [
                      {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        },
                        "arguments": [],
                        "directives": []
                      },
                      {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "classIDifferential"
                        },
                        "arguments": [],
                        "directives": []
                      },
                      {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "name"
                        },
                        "arguments": [],
                        "directives": []
                      },
                      {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "State"
                        },
                        "arguments": [],
                        "directives": [],
                        "selectionSet": {
                          "kind": "SelectionSet",
                          "selections": [
                            {
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "id"
                              },
                              "arguments": [],
                              "directives": []
                            },
                            {
                              "kind": "Field",
                              "name": {
                                "kind": "Name",
                                "value": "name"
                              },
                              "arguments": [],
                              "directives": []
                            }
                          ]
                        }
                      }
                    ]
                  }
                }
              ]
            }
          },
          {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "ForecastedMilkUtilizations"
            },
            "arguments": [
              {
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "where"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "where"
                  }
                }
              },
              {
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "orderBy"
                },
                "value": {
                  "kind": "ObjectValue",
                  "fields": [
                    {
                      "kind": "ObjectField",
                      "name": {
                        "kind": "Name",
                        "value": "year"
                      },
                      "value": {
                        "kind": "EnumValue",
                        "value": "Asc"
                      }
                    },
                    {
                      "kind": "ObjectField",
                      "name": {
                        "kind": "Name",
                        "value": "month"
                      },
                      "value": {
                        "kind": "EnumValue",
                        "value": "Asc"
                      }
                    }
                  ]
                }
              }
            ],
            "directives": [],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [
                {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "date"
                  },
                  "arguments": [],
                  "directives": []
                },
                {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "classiUtilization"
                  },
                  "arguments": [],
                  "directives": []
                },
                {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "classiiUtilization"
                  },
                  "arguments": [],
                  "directives": []
                },
                {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "classiiiUtilization"
                  },
                  "arguments": [],
                  "directives": []
                },
                {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "classivUtilization"
                  },
                  "arguments": [],
                  "directives": []
                }
              ]
            }
          },
          {
            "kind": "Field",
            "alias": {
              "kind": "Name",
              "value": "ClassIIIFutures"
            },
            "name": {
              "kind": "Name",
              "value": "Futures"
            },
            "arguments": [
              {
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "where"
                },
                "value": {
                  "kind": "ObjectValue",
                  "fields": [
                    {
                      "kind": "ObjectField",
                      "name": {
                        "kind": "Name",
                        "value": "AND"
                      },
                      "value": {
                        "kind": "ListValue",
                        "values": [
                          {
                            "kind": "ObjectValue",
                            "fields": [
                              {
                                "kind": "ObjectField",
                                "name": {
                                  "kind": "Name",
                                  "value": "displayExpiresAt"
                                },
                                "value": {
                                  "kind": "ObjectValue",
                                  "fields": [
                                    {
                                      "kind": "ObjectField",
                                      "name": {
                                        "kind": "Name",
                                        "value": "gte"
                                      },
                                      "value": {
                                        "kind": "Variable",
                                        "name": {
                                          "kind": "Name",
                                          "value": "startDate"
                                        }
                                      }
                                    }
                                  ]
                                }
                              }
                            ]
                          },
                          {
                            "kind": "ObjectValue",
                            "fields": [
                              {
                                "kind": "ObjectField",
                                "name": {
                                  "kind": "Name",
                                  "value": "displayExpiresAt"
                                },
                                "value": {
                                  "kind": "ObjectValue",
                                  "fields": [
                                    {
                                      "kind": "ObjectField",
                                      "name": {
                                        "kind": "Name",
                                        "value": "lte"
                                      },
                                      "value": {
                                        "kind": "Variable",
                                        "name": {
                                          "kind": "Name",
                                          "value": "endDate"
                                        }
                                      }
                                    }
                                  ]
                                }
                              }
                            ]
                          },
                          {
                            "kind": "ObjectValue",
                            "fields": [
                              {
                                "kind": "ObjectField",
                                "name": {
                                  "kind": "Name",
                                  "value": "isStandardContractSize"
                                },
                                "value": {
                                  "kind": "ObjectValue",
                                  "fields": [
                                    {
                                      "kind": "ObjectField",
                                      "name": {
                                        "kind": "Name",
                                        "value": "equals"
                                      },
                                      "value": {
                                        "kind": "BooleanValue",
                                        "value": true
                                      }
                                    }
                                  ]
                                }
                              }
                            ]
                          }
                        ]
                      }
                    },
                    {
                      "kind": "ObjectField",
                      "name": {
                        "kind": "Name",
                        "value": "Product"
                      },
                      "value": {
                        "kind": "ObjectValue",
                        "fields": [
                          {
                            "kind": "ObjectField",
                            "name": {
                              "kind": "Name",
                              "value": "slug"
                            },
                            "value": {
                              "kind": "ObjectValue",
                              "fields": [
                                {
                                  "kind": "ObjectField",
                                  "name": {
                                    "kind": "Name",
                                    "value": "equals"
                                  },
                                  "value": {
                                    "kind": "StringValue",
                                    "value": "us-dairy-class-iii",
                                    "block": false
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ],
            "directives": [],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [
                {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "id"
                  },
                  "arguments": [],
                  "directives": []
                },
                {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "displayExpiresAt"
                  },
                  "arguments": [],
                  "directives": []
                },
                {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "barchartSymbol"
                  },
                  "arguments": [],
                  "directives": []
                },
                {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "Product"
                  },
                  "arguments": [],
                  "directives": [],
                  "selectionSet": {
                    "kind": "SelectionSet",
                    "selections": [
                      {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        },
                        "arguments": [],
                        "directives": []
                      },
                      {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "displayFactor"
                        },
                        "arguments": [],
                        "directives": []
                      }
                    ]
                  }
                }
              ]
            }
          },
          {
            "kind": "Field",
            "alias": {
              "kind": "Name",
              "value": "ClassIVFutures"
            },
            "name": {
              "kind": "Name",
              "value": "Futures"
            },
            "arguments": [
              {
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "where"
                },
                "value": {
                  "kind": "ObjectValue",
                  "fields": [
                    {
                      "kind": "ObjectField",
                      "name": {
                        "kind": "Name",
                        "value": "AND"
                      },
                      "value": {
                        "kind": "ListValue",
                        "values": [
                          {
                            "kind": "ObjectValue",
                            "fields": [
                              {
                                "kind": "ObjectField",
                                "name": {
                                  "kind": "Name",
                                  "value": "displayExpiresAt"
                                },
                                "value": {
                                  "kind": "ObjectValue",
                                  "fields": [
                                    {
                                      "kind": "ObjectField",
                                      "name": {
                                        "kind": "Name",
                                        "value": "gte"
                                      },
                                      "value": {
                                        "kind": "Variable",
                                        "name": {
                                          "kind": "Name",
                                          "value": "startDate"
                                        }
                                      }
                                    }
                                  ]
                                }
                              }
                            ]
                          },
                          {
                            "kind": "ObjectValue",
                            "fields": [
                              {
                                "kind": "ObjectField",
                                "name": {
                                  "kind": "Name",
                                  "value": "displayExpiresAt"
                                },
                                "value": {
                                  "kind": "ObjectValue",
                                  "fields": [
                                    {
                                      "kind": "ObjectField",
                                      "name": {
                                        "kind": "Name",
                                        "value": "lte"
                                      },
                                      "value": {
                                        "kind": "Variable",
                                        "name": {
                                          "kind": "Name",
                                          "value": "endDate"
                                        }
                                      }
                                    }
                                  ]
                                }
                              }
                            ]
                          },
                          {
                            "kind": "ObjectValue",
                            "fields": [
                              {
                                "kind": "ObjectField",
                                "name": {
                                  "kind": "Name",
                                  "value": "isStandardContractSize"
                                },
                                "value": {
                                  "kind": "ObjectValue",
                                  "fields": [
                                    {
                                      "kind": "ObjectField",
                                      "name": {
                                        "kind": "Name",
                                        "value": "equals"
                                      },
                                      "value": {
                                        "kind": "BooleanValue",
                                        "value": true
                                      }
                                    }
                                  ]
                                }
                              }
                            ]
                          }
                        ]
                      }
                    },
                    {
                      "kind": "ObjectField",
                      "name": {
                        "kind": "Name",
                        "value": "Product"
                      },
                      "value": {
                        "kind": "ObjectValue",
                        "fields": [
                          {
                            "kind": "ObjectField",
                            "name": {
                              "kind": "Name",
                              "value": "slug"
                            },
                            "value": {
                              "kind": "ObjectValue",
                              "fields": [
                                {
                                  "kind": "ObjectField",
                                  "name": {
                                    "kind": "Name",
                                    "value": "equals"
                                  },
                                  "value": {
                                    "kind": "StringValue",
                                    "value": "us-dairy-class-iv",
                                    "block": false
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ],
            "directives": [],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [
                {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "id"
                  },
                  "arguments": [],
                  "directives": []
                },
                {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "displayExpiresAt"
                  },
                  "arguments": [],
                  "directives": []
                },
                {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "barchartSymbol"
                  },
                  "arguments": [],
                  "directives": []
                },
                {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "Product"
                  },
                  "arguments": [],
                  "directives": [],
                  "selectionSet": {
                    "kind": "SelectionSet",
                    "selections": [
                      {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        },
                        "arguments": [],
                        "directives": []
                      },
                      {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "displayFactor"
                        },
                        "arguments": [],
                        "directives": []
                      }
                    ]
                  }
                }
              ]
            }
          },
          {
            "kind": "Field",
            "alias": {
              "kind": "Name",
              "value": "ButterFutures"
            },
            "name": {
              "kind": "Name",
              "value": "Futures"
            },
            "arguments": [
              {
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "where"
                },
                "value": {
                  "kind": "ObjectValue",
                  "fields": [
                    {
                      "kind": "ObjectField",
                      "name": {
                        "kind": "Name",
                        "value": "AND"
                      },
                      "value": {
                        "kind": "ListValue",
                        "values": [
                          {
                            "kind": "ObjectValue",
                            "fields": [
                              {
                                "kind": "ObjectField",
                                "name": {
                                  "kind": "Name",
                                  "value": "displayExpiresAt"
                                },
                                "value": {
                                  "kind": "ObjectValue",
                                  "fields": [
                                    {
                                      "kind": "ObjectField",
                                      "name": {
                                        "kind": "Name",
                                        "value": "gte"
                                      },
                                      "value": {
                                        "kind": "Variable",
                                        "name": {
                                          "kind": "Name",
                                          "value": "startDate"
                                        }
                                      }
                                    }
                                  ]
                                }
                              }
                            ]
                          },
                          {
                            "kind": "ObjectValue",
                            "fields": [
                              {
                                "kind": "ObjectField",
                                "name": {
                                  "kind": "Name",
                                  "value": "displayExpiresAt"
                                },
                                "value": {
                                  "kind": "ObjectValue",
                                  "fields": [
                                    {
                                      "kind": "ObjectField",
                                      "name": {
                                        "kind": "Name",
                                        "value": "lte"
                                      },
                                      "value": {
                                        "kind": "Variable",
                                        "name": {
                                          "kind": "Name",
                                          "value": "endDate"
                                        }
                                      }
                                    }
                                  ]
                                }
                              }
                            ]
                          },
                          {
                            "kind": "ObjectValue",
                            "fields": [
                              {
                                "kind": "ObjectField",
                                "name": {
                                  "kind": "Name",
                                  "value": "isStandardContractSize"
                                },
                                "value": {
                                  "kind": "ObjectValue",
                                  "fields": [
                                    {
                                      "kind": "ObjectField",
                                      "name": {
                                        "kind": "Name",
                                        "value": "equals"
                                      },
                                      "value": {
                                        "kind": "BooleanValue",
                                        "value": true
                                      }
                                    }
                                  ]
                                }
                              }
                            ]
                          }
                        ]
                      }
                    },
                    {
                      "kind": "ObjectField",
                      "name": {
                        "kind": "Name",
                        "value": "Product"
                      },
                      "value": {
                        "kind": "ObjectValue",
                        "fields": [
                          {
                            "kind": "ObjectField",
                            "name": {
                              "kind": "Name",
                              "value": "slug"
                            },
                            "value": {
                              "kind": "ObjectValue",
                              "fields": [
                                {
                                  "kind": "ObjectField",
                                  "name": {
                                    "kind": "Name",
                                    "value": "equals"
                                  },
                                  "value": {
                                    "kind": "StringValue",
                                    "value": "us-dairy-butter",
                                    "block": false
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ],
            "directives": [],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [
                {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "id"
                  },
                  "arguments": [],
                  "directives": []
                },
                {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "displayExpiresAt"
                  },
                  "arguments": [],
                  "directives": []
                },
                {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "barchartSymbol"
                  },
                  "arguments": [],
                  "directives": []
                },
                {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "Product"
                  },
                  "arguments": [],
                  "directives": [],
                  "selectionSet": {
                    "kind": "SelectionSet",
                    "selections": [
                      {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        },
                        "arguments": [],
                        "directives": []
                      },
                      {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "displayFactor"
                        },
                        "arguments": [],
                        "directives": []
                      }
                    ]
                  }
                }
              ]
            }
          },
          {
            "kind": "Field",
            "alias": {
              "kind": "Name",
              "value": "NonfatDryMilkFutures"
            },
            "name": {
              "kind": "Name",
              "value": "Futures"
            },
            "arguments": [
              {
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "where"
                },
                "value": {
                  "kind": "ObjectValue",
                  "fields": [
                    {
                      "kind": "ObjectField",
                      "name": {
                        "kind": "Name",
                        "value": "AND"
                      },
                      "value": {
                        "kind": "ListValue",
                        "values": [
                          {
                            "kind": "ObjectValue",
                            "fields": [
                              {
                                "kind": "ObjectField",
                                "name": {
                                  "kind": "Name",
                                  "value": "displayExpiresAt"
                                },
                                "value": {
                                  "kind": "ObjectValue",
                                  "fields": [
                                    {
                                      "kind": "ObjectField",
                                      "name": {
                                        "kind": "Name",
                                        "value": "gte"
                                      },
                                      "value": {
                                        "kind": "Variable",
                                        "name": {
                                          "kind": "Name",
                                          "value": "startDate"
                                        }
                                      }
                                    }
                                  ]
                                }
                              }
                            ]
                          },
                          {
                            "kind": "ObjectValue",
                            "fields": [
                              {
                                "kind": "ObjectField",
                                "name": {
                                  "kind": "Name",
                                  "value": "displayExpiresAt"
                                },
                                "value": {
                                  "kind": "ObjectValue",
                                  "fields": [
                                    {
                                      "kind": "ObjectField",
                                      "name": {
                                        "kind": "Name",
                                        "value": "lte"
                                      },
                                      "value": {
                                        "kind": "Variable",
                                        "name": {
                                          "kind": "Name",
                                          "value": "endDate"
                                        }
                                      }
                                    }
                                  ]
                                }
                              }
                            ]
                          },
                          {
                            "kind": "ObjectValue",
                            "fields": [
                              {
                                "kind": "ObjectField",
                                "name": {
                                  "kind": "Name",
                                  "value": "isStandardContractSize"
                                },
                                "value": {
                                  "kind": "ObjectValue",
                                  "fields": [
                                    {
                                      "kind": "ObjectField",
                                      "name": {
                                        "kind": "Name",
                                        "value": "equals"
                                      },
                                      "value": {
                                        "kind": "BooleanValue",
                                        "value": true
                                      }
                                    }
                                  ]
                                }
                              }
                            ]
                          }
                        ]
                      }
                    },
                    {
                      "kind": "ObjectField",
                      "name": {
                        "kind": "Name",
                        "value": "Product"
                      },
                      "value": {
                        "kind": "ObjectValue",
                        "fields": [
                          {
                            "kind": "ObjectField",
                            "name": {
                              "kind": "Name",
                              "value": "slug"
                            },
                            "value": {
                              "kind": "ObjectValue",
                              "fields": [
                                {
                                  "kind": "ObjectField",
                                  "name": {
                                    "kind": "Name",
                                    "value": "equals"
                                  },
                                  "value": {
                                    "kind": "StringValue",
                                    "value": "us-dairy-nonfat-milk",
                                    "block": false
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ],
            "directives": [],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [
                {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "id"
                  },
                  "arguments": [],
                  "directives": []
                },
                {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "displayExpiresAt"
                  },
                  "arguments": [],
                  "directives": []
                },
                {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "barchartSymbol"
                  },
                  "arguments": [],
                  "directives": []
                },
                {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "Product"
                  },
                  "arguments": [],
                  "directives": [],
                  "selectionSet": {
                    "kind": "SelectionSet",
                    "selections": [
                      {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        },
                        "arguments": [],
                        "directives": []
                      },
                      {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "displayFactor"
                        },
                        "arguments": [],
                        "directives": []
                      }
                    ]
                  }
                }
              ]
            }
          },
          {
            "kind": "Field",
            "alias": {
              "kind": "Name",
              "value": "CheeseFutures"
            },
            "name": {
              "kind": "Name",
              "value": "Futures"
            },
            "arguments": [
              {
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "where"
                },
                "value": {
                  "kind": "ObjectValue",
                  "fields": [
                    {
                      "kind": "ObjectField",
                      "name": {
                        "kind": "Name",
                        "value": "AND"
                      },
                      "value": {
                        "kind": "ListValue",
                        "values": [
                          {
                            "kind": "ObjectValue",
                            "fields": [
                              {
                                "kind": "ObjectField",
                                "name": {
                                  "kind": "Name",
                                  "value": "displayExpiresAt"
                                },
                                "value": {
                                  "kind": "ObjectValue",
                                  "fields": [
                                    {
                                      "kind": "ObjectField",
                                      "name": {
                                        "kind": "Name",
                                        "value": "gte"
                                      },
                                      "value": {
                                        "kind": "Variable",
                                        "name": {
                                          "kind": "Name",
                                          "value": "startDate"
                                        }
                                      }
                                    }
                                  ]
                                }
                              }
                            ]
                          },
                          {
                            "kind": "ObjectValue",
                            "fields": [
                              {
                                "kind": "ObjectField",
                                "name": {
                                  "kind": "Name",
                                  "value": "displayExpiresAt"
                                },
                                "value": {
                                  "kind": "ObjectValue",
                                  "fields": [
                                    {
                                      "kind": "ObjectField",
                                      "name": {
                                        "kind": "Name",
                                        "value": "lte"
                                      },
                                      "value": {
                                        "kind": "Variable",
                                        "name": {
                                          "kind": "Name",
                                          "value": "endDate"
                                        }
                                      }
                                    }
                                  ]
                                }
                              }
                            ]
                          },
                          {
                            "kind": "ObjectValue",
                            "fields": [
                              {
                                "kind": "ObjectField",
                                "name": {
                                  "kind": "Name",
                                  "value": "isStandardContractSize"
                                },
                                "value": {
                                  "kind": "ObjectValue",
                                  "fields": [
                                    {
                                      "kind": "ObjectField",
                                      "name": {
                                        "kind": "Name",
                                        "value": "equals"
                                      },
                                      "value": {
                                        "kind": "BooleanValue",
                                        "value": true
                                      }
                                    }
                                  ]
                                }
                              }
                            ]
                          }
                        ]
                      }
                    },
                    {
                      "kind": "ObjectField",
                      "name": {
                        "kind": "Name",
                        "value": "Product"
                      },
                      "value": {
                        "kind": "ObjectValue",
                        "fields": [
                          {
                            "kind": "ObjectField",
                            "name": {
                              "kind": "Name",
                              "value": "slug"
                            },
                            "value": {
                              "kind": "ObjectValue",
                              "fields": [
                                {
                                  "kind": "ObjectField",
                                  "name": {
                                    "kind": "Name",
                                    "value": "equals"
                                  },
                                  "value": {
                                    "kind": "StringValue",
                                    "value": "us-dairy-cheese",
                                    "block": false
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ],
            "directives": [],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [
                {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "id"
                  },
                  "arguments": [],
                  "directives": []
                },
                {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "displayExpiresAt"
                  },
                  "arguments": [],
                  "directives": []
                },
                {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "barchartSymbol"
                  },
                  "arguments": [],
                  "directives": []
                },
                {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "Product"
                  },
                  "arguments": [],
                  "directives": [],
                  "selectionSet": {
                    "kind": "SelectionSet",
                    "selections": [
                      {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        },
                        "arguments": [],
                        "directives": []
                      },
                      {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "displayFactor"
                        },
                        "arguments": [],
                        "directives": []
                      }
                    ]
                  }
                }
              ]
            }
          },
          {
            "kind": "Field",
            "alias": {
              "kind": "Name",
              "value": "DryWheyFutures"
            },
            "name": {
              "kind": "Name",
              "value": "Futures"
            },
            "arguments": [
              {
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "where"
                },
                "value": {
                  "kind": "ObjectValue",
                  "fields": [
                    {
                      "kind": "ObjectField",
                      "name": {
                        "kind": "Name",
                        "value": "AND"
                      },
                      "value": {
                        "kind": "ListValue",
                        "values": [
                          {
                            "kind": "ObjectValue",
                            "fields": [
                              {
                                "kind": "ObjectField",
                                "name": {
                                  "kind": "Name",
                                  "value": "displayExpiresAt"
                                },
                                "value": {
                                  "kind": "ObjectValue",
                                  "fields": [
                                    {
                                      "kind": "ObjectField",
                                      "name": {
                                        "kind": "Name",
                                        "value": "gte"
                                      },
                                      "value": {
                                        "kind": "Variable",
                                        "name": {
                                          "kind": "Name",
                                          "value": "startDate"
                                        }
                                      }
                                    }
                                  ]
                                }
                              }
                            ]
                          },
                          {
                            "kind": "ObjectValue",
                            "fields": [
                              {
                                "kind": "ObjectField",
                                "name": {
                                  "kind": "Name",
                                  "value": "displayExpiresAt"
                                },
                                "value": {
                                  "kind": "ObjectValue",
                                  "fields": [
                                    {
                                      "kind": "ObjectField",
                                      "name": {
                                        "kind": "Name",
                                        "value": "lte"
                                      },
                                      "value": {
                                        "kind": "Variable",
                                        "name": {
                                          "kind": "Name",
                                          "value": "endDate"
                                        }
                                      }
                                    }
                                  ]
                                }
                              }
                            ]
                          },
                          {
                            "kind": "ObjectValue",
                            "fields": [
                              {
                                "kind": "ObjectField",
                                "name": {
                                  "kind": "Name",
                                  "value": "isStandardContractSize"
                                },
                                "value": {
                                  "kind": "ObjectValue",
                                  "fields": [
                                    {
                                      "kind": "ObjectField",
                                      "name": {
                                        "kind": "Name",
                                        "value": "equals"
                                      },
                                      "value": {
                                        "kind": "BooleanValue",
                                        "value": true
                                      }
                                    }
                                  ]
                                }
                              }
                            ]
                          }
                        ]
                      }
                    },
                    {
                      "kind": "ObjectField",
                      "name": {
                        "kind": "Name",
                        "value": "Product"
                      },
                      "value": {
                        "kind": "ObjectValue",
                        "fields": [
                          {
                            "kind": "ObjectField",
                            "name": {
                              "kind": "Name",
                              "value": "slug"
                            },
                            "value": {
                              "kind": "ObjectValue",
                              "fields": [
                                {
                                  "kind": "ObjectField",
                                  "name": {
                                    "kind": "Name",
                                    "value": "equals"
                                  },
                                  "value": {
                                    "kind": "StringValue",
                                    "value": "us-dairy-dry-whey",
                                    "block": false
                                  }
                                }
                              ]
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              }
            ],
            "directives": [],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [
                {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "id"
                  },
                  "arguments": [],
                  "directives": []
                },
                {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "displayExpiresAt"
                  },
                  "arguments": [],
                  "directives": []
                },
                {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "barchartSymbol"
                  },
                  "arguments": [],
                  "directives": []
                },
                {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "Product"
                  },
                  "arguments": [],
                  "directives": [],
                  "selectionSet": {
                    "kind": "SelectionSet",
                    "selections": [
                      {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "id"
                        },
                        "arguments": [],
                        "directives": []
                      },
                      {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "displayFactor"
                        },
                        "arguments": [],
                        "directives": []
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ],
  "loc": {
    "start": 0,
    "end": 2472
  }
};
export default doc;
