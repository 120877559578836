import Controller from '@ember/controller';
import StrategicBuyingModel from '../../models/strategic-buying-model';
import { getOwner } from '@ember/application';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
//@ts-ignore
import { Product } from 'types/graphql-types';
import isTouchDevice from 'vault-client/utils/is-touch-device';
import { TableColumn, CellComponents } from 'vault-client/types/vault-table';
import ReportsDryWheyStrategicBuyingModelRoute from 'vault-client/routes/reports/dry-whey-strategic-buying-model';
import { ModelFrom } from 'vault-client/utils/type-utils';

interface HtmlElement {
	target: HTMLInputElement;
}

export default class ReportsDryWheyStrategicBuyingModel extends Controller {
	@tracked carryCost: number = 0.01;
	@tracked currentPriceInput: string = 'Front Future Price';
	@tracked canBuySpot: boolean = false;
	@tracked numberOfYears: number = 5;
	reportsRoute: string = '';
	declare model: ModelFrom<ReportsDryWheyStrategicBuyingModelRoute>;
	get columns(): TableColumn[] {
		const baseColumns: TableColumn[] = [
			{
				id: 'eb94e357-b523-49a4-a989-cf8edc12f2f1',
				name: 'Month',
				valuePath: 'displayExpiresAt',
				minWidth: 120,
				cellComponent: CellComponents.MonthFormat,
				textAlign: 'left',
				isSortable: false,
				isFixed: !isTouchDevice() ? 'left' : '',
				isVisible: true,
			},
			{
				id: '1d7a52a2-8cdc-4613-9058-922c22b9c85c',
				name: 'Futures',
				valuePath: 'currentFuturePrice',
				minWidth: 120,
				cellComponent: CellComponents.PriceFormat,
				componentArgs: {
					fractionDigitsPath: 'product.MostCurrentFuture.SymbolGroup.fractionDigits',
					displayFactorPath: 'noDisplayFactor',
				},
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '39007fd7-7669-4855-b083-d7ef2fd298c5',
				name: 'Futures - Carry',
				valuePath: 'futuresMinusCarryCost',
				minWidth: 120,
				cellComponent: CellComponents.PriceFormat,
				componentArgs: {
					fractionDigitsPath: 'product.MostCurrentFuture.SymbolGroup.fractionDigits',
					displayFactorPath: 'noDisplayFactor',
				},
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: this.canBuySpot,
			},
			{
				id: '43b4f3de-041d-4dfd-ac89-ab34bc9f7a13',
				name: 'Current',
				valuePath: 'currentPrice',
				minWidth: 120,
				cellComponent: CellComponents.PriceFormat,
				componentArgs: {
					fractionDigitsPath: 'product.MostCurrentFuture.SymbolGroup.fractionDigits',
					displayFactorPath: 'noDisplayFactor',
				},
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: this.canBuySpot,
			},
			{
				id: 'fee11064-48ff-4a19-b793-bd9ceec94516',
				name: '1 Mo Carry Incentive',
				valuePath: 'oneMonthCarryIncentive',
				minWidth: 120,
				cellComponent: CellComponents.PriceFormat,
				componentArgs: {
					fractionDigitsPath: 'product.MostCurrentFuture.SymbolGroup.fractionDigits',
					displayFactorPath: 'noDisplayFactor',
				},
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: this.canBuySpot,
			},
			{
				id: 'ea9dffec-c524-4bb4-9296-4859a9a01f15',
				name: 'Strategy',
				valuePath: 'riskManagementStrategy',
				minWidth: 120,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
		];
		return baseColumns.filter((column) => column.isVisible);
	}

	prevSims: {
		oneMonthCarryIncentive: number | null;
		displayExpiresAt: string;
		symbol: string;
	}[] = [];

	get spotProduct() {
		const products: Product[] = this.model.Products;
		const cashProduct = products?.find((product: Product) => product?.slug === 'us-dairy-dry-whey');
		return cashProduct ?? null;
	}

	get futuresProduct() {
		const products: Product[] = this.model.Products;
		return products?.find((product) => product.slug === 'us-dairy-dry-whey');
	}

	get frontFuture() {
		return this.futuresProduct?.CurrentFutures[0];
	}

	get months() {
		const owner = getOwner(this);
		const product = this.futuresProduct;
		return product?.CurrentFutures?.reduce((acc: any, future: any) => {
			const previousSims = acc.slice(-2);
			const currentSim = new StrategicBuyingModel(
				owner,
				product,
				future,
				this.carryCost,
				previousSims,
				this.spotProduct,
				this.currentPriceInput,
				this.canBuySpot,
				this.numberOfYears
			);

			acc.push(currentSim);
			return acc;
		}, []);
	}

	@action
	currentPriceRadioInput(e: HtmlElement) {
		return (this.currentPriceInput = e.target.value);
	}

	@action
	buySpotCheck(e: HtmlElement) {
		// update the 'Front Future Price' radio button to be selected when 'Can Buy Spot' is unchecked
		if (!e.target.checked) {
			this.currentPriceInput = 'Front Future Price';
			const element = document.getElementById('frontFuturePriceInput') as HTMLInputElement;
			element!.checked = true;
		}
		return (this.canBuySpot = e.target.checked);
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'reports/dry-whey-strategic-buying-model': ReportsDryWheyStrategicBuyingModel;
	}
}
