import Route from '@ember/routing/route';
import { tracked } from '@glimmer/tracking';
import { gql, useQuery } from 'glimmer-apollo';
import {
	GrainFillOrderFilterDTO,
	GrainFillOrderSortByDTO,
	Query,
	TypeOfGrainOrderSales,
	TypeOfGrainPlan,
	SortByDirection,
} from 'vault-client/types/graphql-types';

type SortObj = {
	valuePath: string;
	isAscending: boolean;
};

export const GET_FILLS = gql`
	query GrainFillOrdersIndex($where: GrainFillOrderFilterDTO, $limit: Float, $orderBy: GrainFillOrderSortByDTO, $offset: Float) {
		GrainFillOrders(where: $where, limit: $limit, orderBy: $orderBy, offset: $offset) {
			id
			deliveryTerms
			bushels
			futuresMonth
			deliveryStartDate
			deliveryEndDate
			deliveryMonth
			salesType
			futurePrice
			fees
			spread
			basis
			planId
			contractNumber
			targetId
			Seller {
				id
				name
			}
			Location {
				id
				name
			}
			Plan {
				... on GrainFeedPlan {
					id
					breakEven
					customerId
					goal
					feedCategoryId
					startDate
					tons
					feedYear
					Customer {
						id
						name
						RelationshipOwner {
							id
							firstName
							lastName
						}
					}
					FeedCategory {
						id
						name
					}
				}
			}
		}
		GrainFillOrderCount(where: $where) {
			count
		}
		FeedCategories(orderBy: { name: Asc }) {
			id
			name
		}
	}
`;

type GetFills = {
	__typename?: 'Query';
	GrainFillOrders: Query['GrainFillOrders'];
	GrainFillOrderCount: Query['GrainFillOrderCount'];
	FeedCategories: Query['FeedCategories'];
};

interface QueryParams {
	page: number;
	size: number;
	sorts: SortObj[];
	grainAdvisorId: string;
	customerId: string;
	selectedFeedCategoryIds: string[];
	futuresMonthStart: string;
	futuresMonthEnd: string;
	deliveryStartDateStart: string;
	deliveryStartDateEnd: string;
	deliveryEndDateStart: string;
	deliveryEndDateEnd: string;
	selectedSalesTypes: TypeOfGrainOrderSales[];
	sellerId: string;
	grainLocationId: string;
	selectedFeedYears: number[];
}

type Query_FillsArgs = {
	where?: GrainFillOrderFilterDTO;
	limit?: number;
	orderBy?: GrainFillOrderSortByDTO;
	offset?: number;
};
export default class VgsFeedServicesFillsIndex extends Route {
	@tracked variables: Query_FillsArgs = {};

	queryParams = {
		page: { refreshModel: true },
		sorts: { refreshModel: true },
		size: { refreshModel: true },
		grainAdvisorId: { refreshModel: true },
		customerId: { refreshModel: true },
		selectedFeedCategoryIds: { refreshModel: true },
		futuresMonthStart: { refreshModel: true },
		futuresMonthEnd: { refreshModel: true },
		deliveryStartDateStart: { refreshModel: true },
		deliveryStartDateEnd: { refreshModel: true },
		deliveryEndDateStart: { refreshModel: true },
		deliveryEndDateEnd: { refreshModel: true },
		selectedSalesTypes: { refreshModel: true },
		sellerId: { refreshModel: true },
		grainLocationId: { refreshModel: true },
		selectedFeedYears: { refreshModel: true },
	};

	getFills = useQuery<GetFills, Query_FillsArgs>(this, () => [GET_FILLS, { variables: this.variables }]);

	generateWhere(params: QueryParams): GrainFillOrderFilterDTO {
		// Convert date ranges to null if they use the default values.
		const futuresMonthStart = params.futuresMonthStart !== '1900-01-01' ? params.futuresMonthStart : null;
		const futuresMonthEnd = params.futuresMonthEnd !== '2999-12-31' ? params.futuresMonthEnd : null;
		const deliveryStartDateStart = params.deliveryStartDateStart !== '1900-01-01' ? params.deliveryStartDateStart : null;
		const deliveryStartDateEnd = params.deliveryStartDateEnd !== '2999-12-31' ? params.deliveryStartDateEnd : null;
		const deliveryEndDateStart = params.deliveryEndDateStart !== '1900-01-01' ? params.deliveryEndDateStart : null;
		const deliveryEndDateEnd = params.deliveryEndDateEnd !== '2999-12-31' ? params.deliveryEndDateEnd : null;

		const where: GrainFillOrderFilterDTO = { planId: {} };

		if (params.selectedSalesTypes?.length) {
			where.salesType = { in: params.selectedSalesTypes };
		}

		if (params.sellerId) {
			where.sellerId = { equals: params.sellerId };
		}

		if (params.grainLocationId) {
			where.locationId = { equals: params.grainLocationId };
		}

		if (futuresMonthStart || futuresMonthEnd) {
			where.futuresMonth = {
				...(futuresMonthStart && { gte: futuresMonthStart }),
				...(futuresMonthEnd && { lte: futuresMonthEnd }),
			};
		}

		if (deliveryStartDateStart || deliveryStartDateEnd) {
			where.deliveryStartDate = {
				...(deliveryStartDateStart && { gte: deliveryStartDateStart }),
				...(deliveryStartDateEnd && { lte: deliveryStartDateEnd }),
			};
		}

		if (deliveryEndDateStart || deliveryEndDateEnd) {
			where.deliveryEndDate = {
				...(deliveryEndDateStart && { gte: deliveryEndDateStart }),
				...(deliveryEndDateEnd && { lte: deliveryEndDateEnd }),
			};
		}

		if (params.selectedFeedCategoryIds?.length || params.grainAdvisorId || params.customerId || params.selectedFeedYears?.length) {
			where.Plan = {
				...(params.grainAdvisorId && {
					Customer: {
						...(params.grainAdvisorId && { relationshipOwnerId: { equals: params.grainAdvisorId } }),
					},
				}),
				...(params.customerId && { customerId: { equals: params.customerId } }),
				...(params.selectedFeedCategoryIds?.length && { feedCategoryId: { in: params.selectedFeedCategoryIds } }),
				...(params.selectedFeedYears?.length && { feedYear: { in: params.selectedFeedYears } }),
			};
		}

		where.Plan = { ...where.Plan, type: { equals: TypeOfGrainPlan.Feed } };

		return where;
	}

	generateOrderBy(sorts: SortObj[]) {
		const orderBy: {
			[key: string]: any;
		} = {};

		sorts.forEach((sort: any) => {
			const parts = sort.valuePath.split('.');
			const firstPart = parts.shift();

			let value: any = sort.isAscending ? SortByDirection.Asc : SortByDirection.Desc;

			while (parts.length > 0) {
				const part = parts.pop();
				const obj: {
					[key: string]: any;
				} = {};
				obj[part] = value;
				value = obj;
			}

			if (firstPart === 'Plan') {
				orderBy.Plan = { AsGrainFeedPlan: { ...value } };
			} else {
				orderBy[firstPart] = value;
			}
		});

		return orderBy;
	}

	async model(params: QueryParams) {
		this.variables = {
			orderBy: this.generateOrderBy(params.sorts),
			offset: params.page * params.size,
			limit: params.size,
			where: this.generateWhere(params),
		};

		await this.getFills.promise;

		return { getFills: this.getFills, variables: this.variables };
	}
}
