import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { gql, useMutation } from 'glimmer-apollo';
import { GrainTargetOrderUpdateDTO, TypeOfGrainOrderStatus, GrainTargetOrder } from 'vault-client/types/graphql-types';

const SET_TARGET_STATUS_MUTATION = gql`
	mutation UpdateGrainTargetOrder($data: GrainTargetOrderUpdateDTO!, $updateGrainTargetOrderId: String!) {
		updateGrainTargetOrder(data: $data, id: $updateGrainTargetOrderId) {
			id
			status
			lastUpdatedBy
		}
	}
`;

interface SetStatusButtonArgs {
	data: GrainTargetOrderUpdateDTO;
	target: GrainTargetOrder;
}

interface SetStatusMutationArgs {
	data: GrainTargetOrderUpdateDTO;
	updateGrainTargetOrderId: string;
}

export default class SetStatusButton extends Component<SetStatusButtonArgs> {
	@tracked showModal = false;
	@tracked status: TypeOfGrainOrderStatus | null = null;
	@tracked errorMessage: string | null = null;

	get availableStatuses() {
		return Object.values(TypeOfGrainOrderStatus).filter(
			(value) =>
				value !== TypeOfGrainOrderStatus.PendingCancellation &&
				value !== TypeOfGrainOrderStatus.Filled &&
				(this.args.target.status === TypeOfGrainOrderStatus.PendingCancellation || value !== TypeOfGrainOrderStatus.Cancelled),
		);
	}

	setTargetStatus = useMutation<GrainTargetOrderUpdateDTO, SetStatusMutationArgs>(this, () => [
		SET_TARGET_STATUS_MUTATION,
		{
			onComplete: () => {
				this.closeModal();
			},
			onError: (error): void => {
				this.errorMessage = 'There was an error. New status not set.';
				console.error('Error while attempting to set target status', error.message);
			},
			update: (cache) => {
				cache.evict({ fieldName: 'GrainTargetOrder' });
				cache.evict({ fieldName: 'GrainTargetOrders' });
				cache.evict({ fieldName: 'GrainCropPlan' });
				cache.evict({ fieldName: 'GrainCropPlans' });
				cache.evict({ fieldName: 'GrainFeedPlan' });
				cache.evict({ fieldName: 'GrainFeedPlans' });
				cache.gc();
			},
		},
	]);

	@action
	openModal() {
		this.showModal = true;
		this.status = this.args.target?.status;
	}

	@action
	closeModal() {
		this.showModal = false;
		this.status = null;
	}

	@action
	async submit() {
		if (this.args.target) {
			const statusMutation = this.setTargetStatus.mutate({
				data: {
					status: this.status,
				},
				updateGrainTargetOrderId: this.args.target.id,
			});

			await statusMutation;
		} else {
			throw new Error('No Target available to set Target Status');
		}
	}
}
