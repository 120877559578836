import { action } from '@ember/object';
import { gql, useQuery } from 'glimmer-apollo';
import SearchFilterComponent from 'vault-client/components/vault/ui-search-filter';
import { Query, Query_GrainTargetOrdersArgs } from 'vault-client/types/graphql-types';
import { generateFullName } from 'vault-client/utils/general';

const GET_GRAIN_ORDER_BR_OWNER = gql`
	query GrainTargetBrOwner($where: GrainTargetOrderFilterDTO) {
		GrainTargetOrders(where: $where) {
			id
			BuyerRelationsOwner {
				id
				firstName
				lastName
			}
		}
	}
`;

export default class BrOwnerIdFilterComponent extends SearchFilterComponent {
	query = GET_GRAIN_ORDER_BR_OWNER;
	valuePath = '';

	@action
	async fetchItem() {
		let value;
		if (!this.args.filterValues.filterValue) {
			this._value = 'N/A';
			return;
		}

		const query = this.query;

		if (query) {
			const getGrainTargetOrders = useQuery<{ GrainTargetOrders: Query['GrainTargetOrders'] }, Query_GrainTargetOrdersArgs>(this, () => [
				query,
				{
					variables: { where: { buyerRelationsOwnerId: { equals: this.args.filterValues.filterValue } } },
				},
			]);
			await getGrainTargetOrders.promise;

			const brOwner = getGrainTargetOrders.data?.GrainTargetOrders[0]?.BuyerRelationsOwner ?? null;

			const name = generateFullName(brOwner?.firstName, brOwner?.lastName) ?? '';

			value = name;
			this._value = value ?? this.args.filterValues.filterValue;
		} else {
			this._value = this.args.filterValues.filterValue;
		}
	}
}
