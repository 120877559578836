import Route from '@ember/routing/route';
import { tracked } from '@glimmer/tracking';
import { gql, useQuery } from 'glimmer-apollo';
import { FeedCategory, Maybe } from 'vault-client/types/graphql-types';
import {
	GrainTargetOrder,
	GrainTargetOrderSortByDTO,
	GrainTargetOrderFilterDTO,
	SortByDirection,
	TypeOfGrainOrderSales,
	TypeOfGrainOrderStatus,
	TypeOfGrainPlan,
	Count,
} from 'vault-client/types/graphql-types';
import { SortObj } from 'vault-client/types/vault-table';
import { DateTime } from 'luxon';

export const GET_TARGETS = gql`
	query Targets($limit: Float, $offset: Float, $orderBy: GrainTargetOrderSortByDTO, $where: GrainTargetOrderFilterDTO) {
		GrainTargetOrders(orderBy: $orderBy, where: $where, limit: $limit, offset: $offset) {
			id
			deliveryTerms
			bushels
			futuresMonth
			deliveryStartDate
			deliveryEndDate
			deliveryMonth
			salesType
			futurePrice
			basis
			feedPrice
			expirationDate
			contractNumber
			planId
			status
			updatedAt
			shortNote
			BuyerRelationsOwner {
				email
				firstName
				id
				lastName
				roles
			}
			StatusUpdates {
				id
				updatedAt
			}
			Activity {
				id
				data
				type
				performedAt
				GrainPlan {
					id
					type
				}
			}
			Seller {
				id
				name
			}
			Location {
				id
				name
			}
			BuyerRelationsOwner {
				id
				email
				firstName
				lastName
				roles
			}
			Plan {
				type
				... on GrainFeedPlan {
					id
					customerId
					feedCategoryId
					startDate
					hasWriteAccess
					feedYear
					Customer {
						id
						name
						RelationshipOwner {
							id
							firstName
							lastName
						}
					}
					FeedCategory {
						id
						name
					}
				}
			}
		}
		GrainTargetOrderCount(where: $where) {
			count
		}
		FeedCategories(orderBy: { name: Asc }) {
			id
			name
		}
	}
`;

export type GetTargets = {
	__typename?: 'Query';
	GrainTargetOrderCount: Count;
	GrainTargetOrders: Array<GrainTargetOrder>;
	FeedCategories: FeedCategory[];
};

interface QueryParams {
	page: number;
	size: number;
	sorts: SortObj[];
	grainAdvisorId: string;
	customerId: string;
	crop: string;
	status: TypeOfGrainOrderStatus;
	selectedSalesTypes: TypeOfGrainOrderSales[];
	selectedFeedCategories: string[];
	updatedAtDateStart: string;
	updatedAtDateEnd: string;
	futuresMonthStart: string;
	futuresMonthEnd: string;
	deliveryStartDateStart: string;
	deliveryStartDateEnd: string;
	deliveryEndDateStart: string;
	deliveryEndDateEnd: string;
	sellerId: string;
	grainLocationId: string;
	brOwnerId: string;
}

export type Query_TargetsArgs = {
	limit?: Maybe<number>;
	offset?: Maybe<number>;
	orderBy?: GrainTargetOrderSortByDTO;
	where?: GrainTargetOrderFilterDTO;
};

export default class VgsFeedServicesTargetsIndex extends Route {
	@tracked variables: Query_TargetsArgs = {};

	queryParams = {
		page: { refreshModel: true },
		sorts: { refreshModel: true },
		size: { refreshModel: true },
		brOwnerId: { refreshModel: true },
		grainAdvisorId: { refreshModel: true },
		customerId: { refreshModel: true },
		selectedFeedCategories: { refreshModel: true },
		status: { refreshModel: true },
		futuresMonthStart: { refreshModel: true },
		futuresMonthEnd: { refreshModel: true },
		deliveryStartDateStart: { refreshModel: true },
		deliveryStartDateEnd: { refreshModel: true },
		deliveryEndDateStart: { refreshModel: true },
		deliveryEndDateEnd: { refreshModel: true },
		selectedSalesTypes: { refreshModel: true },
		updatedAtDateStart: { refreshModel: true },
		updatedAtDateEnd: { refreshModel: true },
		sellerId: { refreshModel: true },
		grainLocationId: { refreshModel: true },
	};

	getTargets = useQuery<GetTargets, Query_TargetsArgs>(this, () => [GET_TARGETS, { variables: this.variables, pollInterval: 10000 }]);

	generateOrderBy(sorts: SortObj[]) {
		const orderBy: {
			[key: string]: any;
		} = {};

		sorts.forEach((sort: any) => {
			const parts = sort.valuePath.split('.');
			const firstPart = parts.shift();

			let value: any = sort.isAscending ? SortByDirection.Asc : SortByDirection.Desc;

			while (parts.length > 0) {
				const part = parts.pop();
				const obj: {
					[key: string]: any;
				} = {};
				obj[part] = value;
				value = obj;
			}

			if (firstPart === 'Plan') {
				orderBy.Plan = { AsGrainFeedPlan: { ...value } };
			} else {
				orderBy[firstPart] = value;
			}
		});

		return orderBy;
	}

	generateWhere(params: QueryParams) {
		// Convert date ranges to null if they use the default values.
		const futuresMonthStart = params.futuresMonthStart !== '1900-01-01' ? params.futuresMonthStart : null;
		const futuresMonthEnd = params.futuresMonthEnd !== '2999-12-31' ? params.futuresMonthEnd : null;
		const deliveryStartDateStart = params.deliveryStartDateStart !== '1900-01-01' ? params.deliveryStartDateStart : null;
		const deliveryStartDateEnd = params.deliveryStartDateEnd !== '2999-12-31' ? params.deliveryStartDateEnd : null;
		const deliveryEndDateStart = params.deliveryEndDateStart !== '1900-01-01' ? params.deliveryEndDateStart : null;
		const deliveryEndDateEnd = params.deliveryEndDateEnd !== '2999-12-31' ? params.deliveryEndDateEnd : null;
		const updatedAtStart =
			params.updatedAtDateStart !== '1900-01-01' ? DateTime.fromISO(params.updatedAtDateStart).startOf('day').toISO() : null;
		const updatedAtEnd = params.updatedAtDateEnd !== '2999-12-31' ? DateTime.fromISO(params.updatedAtDateEnd).endOf('day').toISO() : null;

		const where: GrainTargetOrderFilterDTO = { planId: {} };

		if (params.brOwnerId) {
			where.BuyerRelationsOwner = {
				id: { equals: params.brOwnerId },
			};
		}

		if (params.status) {
			where.status = {
				equals: params.status,
			};
		}
		if (params.selectedSalesTypes?.length) {
			where.salesType = {
				in: params.selectedSalesTypes,
			};
		}

		if (params.sellerId) {
			where.Seller = { id: { equals: params.sellerId } };
		}

		if (params.grainLocationId) {
			where.Location = { id: { equals: params.grainLocationId } };
		}

		if (futuresMonthStart || futuresMonthEnd) {
			where.futuresMonth = {
				...(futuresMonthStart && { gte: futuresMonthStart }),
				...(futuresMonthEnd && { lte: futuresMonthEnd }),
			};
		}

		if (deliveryStartDateStart || deliveryStartDateEnd) {
			where.deliveryStartDate = {
				...(deliveryStartDateStart && { gte: deliveryStartDateStart }),
				...(deliveryStartDateEnd && { lte: deliveryStartDateEnd }),
			};
		}

		if (deliveryEndDateStart || deliveryEndDateEnd) {
			where.deliveryStartDate = {
				...(deliveryEndDateStart && { gte: deliveryEndDateStart }),
				...(deliveryEndDateEnd && { lte: deliveryEndDateEnd }),
			};
		}

		if (updatedAtStart && updatedAtEnd) {
			where.StatusUpdates = {
				updatedAt: {
					...(updatedAtStart && { gte: updatedAtStart }),
					...(updatedAtEnd && { lte: updatedAtEnd }),
				},
			};
		}

		if (params.selectedFeedCategories?.length || params.grainAdvisorId || params.customerId) {
			where.Plan = {
				...((params.grainAdvisorId || params.customerId) && {
					Customer: {
						...(params.grainAdvisorId && { relationshipOwnerId: { equals: params.grainAdvisorId } }),
						...(params.customerId && { id: { equals: params.customerId } }),
					},
				}),
				AsGrainFeedPlan: {
					...(params.selectedFeedCategories?.length && { FeedCategory: { id: { in: params.selectedFeedCategories } } }),
				},
			};
		}
		where.Plan = { ...where.Plan, type: { equals: TypeOfGrainPlan.Feed } };
		return where;
	}

	async model(params: QueryParams) {
		this.variables = {
			orderBy: this.generateOrderBy(params.sorts),
			offset: params.page * params.size,
			limit: params.size,
			where: this.generateWhere(params),
		};

		await this.getTargets.promise;

		return { getTargets: this.getTargets, variables: this.variables };
	}
}
