import Route from '@ember/routing/route';
import { tracked } from '@glimmer/tracking';
import { gql, useQuery } from 'glimmer-apollo';
import { DateTime } from 'luxon';
import { Query } from 'vault-client/types/graphql-types';

export const GET_MILK_CHECK_RECONCILIATION = gql`
	query MilkCheckReconciliation(
		$entityId: String!
		$scopeId: String!
		$startDate: String!
		$futuresStartDate: String!
		$endDate: String!
		$basisStartDate: String!
		$basisEndDate: String!
	) {
		Entity(id: $entityId) {
			id
			name
			type
			... on LocationEntity {
				County {
					id
					classIDifferential
					name
					State {
						id
						name
					}
				}
			}
			... on CustomerEntity {
				Locations {
					id
					name
					County {
						id
						classIDifferential
						name
						State {
							id
							name
						}
					}
				}
			}
		}
		ForecastedMilkProductionByMonths(
			scopeId: $scopeId
			where: { OR: [{ date: { gte: $startDate, lte: $endDate } }, { date: { gte: $basisStartDate, lte: $basisEndDate } }] }
			orderBy: { date: Asc }
		) {
			id
			numberOfCows
			grossProduction
			date
			classiUtilization
			classiiUtilization
			classiiiUtilization
			classivUtilization
			forecastedBlendedMilkPrice
			grossClassiProduction
			grossClassiiProduction
			grossClassiiiProduction
			grossClassivProduction
			grossOtherSolidsProduction
			grossProteinProduction
			grossButterfatProduction
			otherSolidsPercent
			proteinPercent
			butterfatPercent
			UsdaActualMilkPrice {
				id
				classiPrice
				classiiPrice
				classiiiPrice
				classivPrice
			}
			Entity {
				id
				type
				... on LocationEntity {
					currentBasis
					County {
						classIDifferential
					}
				}
			}
		}
		BasisRangeUsdaActualMilkPrices: UsdaActualMilkPrices(
			where: { date: { gte: $basisStartDate, lte: $basisEndDate } }
			orderBy: { date: Desc }
		) {
			id
			date
			classiPrice
			classiiPrice
			classiiiPrice
			classivPrice
		}
		ActualBlendedMilkPrices(
			scopeId: $scopeId
			where: { OR: [{ date: { gte: $startDate, lte: $endDate } }, { date: { gte: $basisStartDate, lte: $basisEndDate } }] }
		) {
			id
			date
			price
			basis
			Entity {
				id
				type
			}
		}
		AggregateLedgerEntries(
			where: { LedgerCategory: { type: { equals: MilkCheck } }, date: { gte: $startDate, lte: $endDate } }
			calc: { sum: { calculatedAmount: true } }
			groupBy: { month: true, year: true, type: true }
			scopeId: $entityId
		) {
			type
			sum {
				calculatedAmount
			}
			month
			year
		}
		ClassIIIFutures: Futures(
			where: {
				OR: [
					{ displayExpiresAt: { gte: $futuresStartDate, lte: $endDate } }
					{ displayExpiresAt: { gte: $basisStartDate, lte: $basisEndDate } }
				]
				Product: { slug: { equals: "us-dairy-class-iii" } }
				isStandardContractSize: { equals: true }
			}
			orderBy: { displayExpiresAt: Asc }
		) {
			id
			displayExpiresAt
			barchartSymbol
			Product {
				id
			}
			SymbolGroup {
				displayFactor
			}
		}
		ClassIVFutures: Futures(
			where: {
				OR: [
					{ displayExpiresAt: { gte: $futuresStartDate, lte: $endDate } }
					{ displayExpiresAt: { gte: $basisStartDate, lte: $basisEndDate } }
				]
				Product: { slug: { equals: "us-dairy-class-iv" } }
				isStandardContractSize: { equals: true }
			}
			orderBy: { displayExpiresAt: Asc }
		) {
			id
			displayExpiresAt
			barchartSymbol
			Product {
				id
			}
			SymbolGroup {
				displayFactor
			}
		}
		ButterFutures: Futures(
			where: {
				OR: [
					{ displayExpiresAt: { gte: $futuresStartDate, lte: $endDate } }
					{ displayExpiresAt: { gte: $basisStartDate, lte: $basisEndDate } }
				]
				Product: { slug: { equals: "us-dairy-butter" } }
				isStandardContractSize: { equals: true }
			}
			orderBy: { displayExpiresAt: Asc }
		) {
			id
			displayExpiresAt
			barchartSymbol
			Product {
				id
			}
			SymbolGroup {
				displayFactor
			}
		}
		NonfatDryMilkFutures: Futures(
			where: {
				OR: [
					{ displayExpiresAt: { gte: $futuresStartDate, lte: $endDate } }
					{ displayExpiresAt: { gte: $basisStartDate, lte: $basisEndDate } }
				]
				Product: { slug: { equals: "us-dairy-nonfat-milk" } }
				isStandardContractSize: { equals: true }
			}
			orderBy: { displayExpiresAt: Asc }
		) {
			id
			displayExpiresAt
			barchartSymbol
			Product {
				id
			}
			SymbolGroup {
				displayFactor
			}
		}
		CheeseFutures: Futures(
			where: {
				OR: [
					{ displayExpiresAt: { gte: $futuresStartDate, lte: $endDate } }
					{ displayExpiresAt: { gte: $basisStartDate, lte: $basisEndDate } }
				]
				Product: { slug: { equals: "us-dairy-cheese" } }
				isStandardContractSize: { equals: true }
			}
			orderBy: { displayExpiresAt: Asc }
		) {
			id
			displayExpiresAt
			barchartSymbol
			Product {
				id
			}
			SymbolGroup {
				displayFactor
			}
		}
		DryWheyFutures: Futures(
			where: {
				OR: [
					{ displayExpiresAt: { gte: $futuresStartDate, lte: $endDate } }
					{ displayExpiresAt: { gte: $basisStartDate, lte: $basisEndDate } }
				]
				Product: { slug: { equals: "us-dairy-dry-whey" } }
				isStandardContractSize: { equals: true }
			}
			orderBy: { displayExpiresAt: Asc }
		) {
			id
			displayExpiresAt
			barchartSymbol
			Product {
				id
			}
			SymbolGroup {
				displayFactor
			}
		}
		AggregateActualMilkProduction(
			calc: { sum: { grossProduction: true } }
			where: { date: { gte: $startDate, lte: $endDate } }
			groupBy: { firstDateOfMonth: true }
			scopeId: $entityId
		) {
			firstDateOfMonth
			sum {
				grossProduction
			}
		}
	}
`;

type GetMilkCheckReconciliationVariables = {
	futuresStartDate: string;
	basisStartDate: string;
	basisEndDate: string;
	startDate: string;
	endDate: string;
	entityId: string;
	scopeId: string;
};

type GetMilkCheckReconciliationQuery = {
	Entity: Query['Entity'];
	ForecastedMilkProductionByMonths: Query['ForecastedMilkProductionByMonths'];
	ActualBlendedMilkPrices: Query['ActualBlendedMilkPrices'];
	AggregateLedgerEntries: Query['AggregateLedgerForecastedEntries'];
	BasisRangeUsdaActualMilkPrices: Query['UsdaActualMilkPrices'];
	ClassIIIFutures: Query['Futures'];
	ClassIVFutures: Query['Futures'];
	ButterFutures: Query['Futures'];
	NonfatDryMilkFutures: Query['Futures'];
	CheeseFutures: Query['Futures'];
	DryWheyFutures: Query['Futures'];
	AggregateActualMilkProduction: Query['AggregateActualMilkProduction'];
};

export default class MilkCheckReconciliationRoute extends Route {
	templateName: string = 'milk/milk-check-reconciliation';

	queryParams = {
		year: { refreshModel: true },
		selectedLocationId: { refreshModel: true },
	};

	@tracked variables: GetMilkCheckReconciliationVariables = {
		startDate: '',
		endDate: '',
		futuresStartDate: '',
		entityId: '',
		scopeId: '',
		basisStartDate: '',
		basisEndDate: '',
	};

	getMilkCheckReconciliation = useQuery<GetMilkCheckReconciliationQuery, GetMilkCheckReconciliationVariables>(this, () => [
		GET_MILK_CHECK_RECONCILIATION,
		{
			variables: this.variables,
			onError: (error) => {
				console.error(error.message);
			},
		},
	]);

	generateDates(year: number) {
		const dateTime = DateTime.fromObject({ year, month: DateTime.now().month });

		return {
			startDate: dateTime.minus({ months: 37 }).startOf('month').toISODate(),
			endDate: dateTime.endOf('month').toISODate(),
			futuresStartDate: dateTime.minus({ month: 38 }).startOf('month').toISODate(),
		};
	}
}
