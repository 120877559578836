import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { County, LocationEntity, LocationEntityUpdateDTO, MilkOrder, Mutation_updateLocationArgs } from 'vault-client/types/graphql-types';
import { gql, useMutation } from 'glimmer-apollo';

interface UpdateLocationButtonArgs {
	location: LocationEntity;
	model: any;
}

const UPDATE_LOCATION_MUTATION = gql`
	mutation UpdateOperation($id: String!, $data: LocationEntityUpdateDTO!) {
		updateLocation(id: $id, data: $data) {
			id
		}
	}
`;

type UpdateLocationMutation = {
	__typename?: 'Mutation';

	updateLocation: LocationEntity;
};

export default class UpdateLocationButton extends Component<UpdateLocationButtonArgs> {
	@tracked name: string = this.args.location.name;
	@tracked selectedCounty: County | null = this.args.location.County;
	@tracked selectedMilkOrder: MilkOrder | null = this.args.location.MilkOrder;
	@tracked errorMessage: string | null = null;
	@tracked showModal = false;

	updateLocation = useMutation<UpdateLocationMutation, Mutation_updateLocationArgs>(this, () => [
		UPDATE_LOCATION_MUTATION,
		{
			variables: { data: {}, id: '' },
			update: (cache) => {
				cache.evict({ fieldName: 'Locations' });
				cache.evict({ fieldName: 'Location' });
				cache.evict({ fieldName: 'ForecastedMilkProductionByMonth' });
				cache.evict({ fieldName: 'AggregateForecastedMilkProductionByMonth' });
				cache.gc();
			},
		},
	]);

	@action
	openModal() {
		this.showModal = true;
	}

	@action
	closeModal() {
		this.showModal = false;
		this.resetModal();
	}

	resetModal() {
		this.name = this.args.location.name;
		this.selectedCounty = this.args.location.County;
		this.selectedMilkOrder = this.args.location.MilkOrder;
	}

	@action
	async submit() {
		if (!this.selectedCounty) {
			console.warn('No county selected');
			this.errorMessage = 'No county selected';
			return;
		}

		const data: LocationEntityUpdateDTO = {
			name: this.name,
			countyId: this.selectedCounty.id,
		};

		if (this.selectedMilkOrder) {
			data.milkOrderId = this.selectedMilkOrder.id;
		}

		await this.updateLocation.mutate({
			id: this.args.location.id,
			data: data,
		});

		await this.args.model.refetch();

		this.closeModal();
	}
}
