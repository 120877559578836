import Controller from '@ember/controller';
import { CellComponents } from 'vault-client/types/vault-table';

export default class BusinessesBusinessMilk extends Controller {
	get columns() {
		const baseColumns = [
			{
				id: 'e5f27aaf-9ea7-4181-9e8e-8e1253db98b8',
				name: 'Name',
				valuePath: 'name',
				minWidth: 225,
				textAlign: 'left',
				headerTextAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				linkRoutePath: 'linkRoute',
				linkModelPath: 'id',
			},
		];

		return baseColumns;
	}

	get rows() {
		return [
			{
				name: 'Milk Production',
				linkRoute: 'businesses.business.production',
			},
			{
				name: 'Milk Check Calculator',
				linkRoute: 'businesses.business.milk-check',
			},
			{
				name: 'Milk Check Reconciliation',
				linkRoute: 'businesses.business.milk-check-reconciliation',
			},
		];
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'businesses/business/milk': BusinessesBusinessMilk;
	}
}
