import Route from '@ember/routing/route';
import { tracked } from '@glimmer/tracking';
import { gql, useQuery } from 'glimmer-apollo';
import { GrainCropPlanSortByDTO, Query, Query_GrainCropPlansArgs } from 'vault-client/types/graphql-types';

type SortObj = {
	valuePath: string;
	isAscending: boolean;
};

interface GetCropPlans {
	GrainCropPlans: Query['GrainCropPlans'];
	GrainCropPlanCount: Query['GrainCropPlanCount'];
}

export const GET_CROP_PLANS_QUERY = gql`
	query GrainCropPlans($scopeId: String, $offset: Float, $limit: Float = 50, $where: GrainCropPlanFilterDTO) {
		GrainCropPlans(scopeId: $scopeId, offset: $offset, limit: $limit, where: $where) {
			id
			acres
			aph
			breakEven
			Crop {
				id
				name
			}
			CropYear {
				id
				year
			}
			Customer {
				id
				name
			}
			enrolledPercent
			goal
			harvestBu
			storageBu
			Orders {
				id
				type
				bushels
				futurePrice
				basis
				... on GrainFillOrder {
					flatPrice
					locationId
				}
			}
		}
		GrainCropPlanCount(scopeId: $scopeId, where: $where) {
			count
		}
	}
`;

export default class CropPlansRoute extends Route {
	@tracked variables: Query_GrainCropPlansArgs = {};

	templateName = 'crop-plans/index';

	queryParams = {
		customerId: { refreshModel: true },
		page: { refreshModel: true },
		sorts: { refreshModel: true },
		size: { refreshModel: true },
	};

	getCropPlans = useQuery<GetCropPlans, Query_GrainCropPlansArgs>(this, () => [
		GET_CROP_PLANS_QUERY,
		{ variables: this.variables, pollInterval: 10000 },
	]);

	generateOrderBy(sorts: SortObj[]): GrainCropPlanSortByDTO {
		const orderBy: {
			[key: string]: any;
		} = {};

		sorts.forEach((sort: any) => {
			const value = sort.isAscending ? 'Asc' : 'Desc';
			orderBy[sort.valuePath] = value;
		});

		return orderBy;
	}
}
