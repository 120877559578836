import Controller from '@ember/controller';
import DairyMargin from 'vault-client/models/dairy-margin';
import { getOwner } from '@ember/application';
import { tracked } from '@glimmer/tracking';
import { DateTime } from 'luxon';
import { Product, Future } from 'vault-client/types/graphql-types';
import isTouchDevice from 'vault-client/utils/is-touch-device';
import { TableColumn, CellComponents } from 'vault-client/types/vault-table';
import { guidFor } from '@ember/object/internals';
import { Chart, ScriptableTooltipContext } from 'chart.js';
import { ModelFrom } from 'vault-client/utils/type-utils';
import ReportsDairyMarginRoute from 'vault-client/routes/reports/dairy-margin';

interface Month {
	Future: Future;
	Product: Product;
	marginPercentRank: number;
	margin: number;
	twentyFifthPercentileHistoricalMargin: number;
	fiftiethPercentileHistoricalMargin: number;
	seventyFifthPercentileHistoricalMargin: number;
	ninetiethPercentileHistoricalMargin: number;
	classIIIFuture: Future;
}
export default class ReportsDairyMargin extends Controller {
	declare model: ModelFrom<ReportsDairyMarginRoute>;
	@tracked numberOfYears: number = 10;
	reportsRoute: string = '';
	get columns(): TableColumn[] {
		const baseColumns: TableColumn[] = [
			{
				id: 'd1d149d6-1f1e-4de7-af17-6d42d4caef0b',
				name: 'Month',
				valuePath: 'classIIIFuture.displayExpiresAt',
				minWidth: 90,
				cellComponent: CellComponents.MonthFormat,
				textAlign: 'left',
				isSortable: false,
				isFixed: !isTouchDevice() ? 'left' : '',
				isVisible: true,
			},
			{
				id: '8b284640-6835-4625-8688-d6c993438c3f',
				name: 'Prices',
				textAlign: 'center',
				isSortable: false,
				isFixed: '',
				isVisible: true,
				cellComponent: CellComponents.String,
				subcolumns: [
					{
						id: 'c0b2d402-5c97-4d64-9ffc-b55c85e0b1b6',
						name: 'Class III',
						valuePath: 'classIIIPrice',
						minWidth: 100,
						cellComponent: CellComponents.PriceFormat,
						componentArgs: {
							fractionDigitsPath: 'classIIIFuture.SymbolGroup.fractionDigits',
							displayFactorPath: 'classIIIFuture.SymbolGroup.displayFactor',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: '817eeb38-cc2f-4407-8507-e5bdef3ae6da',
						name: 'Corn',
						valuePath: 'cornPrice',
						minWidth: 100,
						cellComponent: CellComponents.PriceFormat,
						componentArgs: {
							fractionDigitsPath: 'cornFuture.SymbolGroup.fractionDigits',
							displayFactorPath: 'cornFuture.SymbolGroup.displayFactor',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: '6d96005a-91a7-4781-95d2-e6b336d6d88d',
						name: 'Soybean Meal',
						valuePath: 'soybeanMealPrice',
						minWidth: 100,
						cellComponent: CellComponents.PriceFormat,
						componentArgs: {
							fractionDigitsPath: 'soybeanMealFuture.SymbolGroup.fractionDigits',
							displayFactorPath: 'soybeanMealFuture.SymbolGroup.displayFactor',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: '2a29edf0-e757-4d84-bf40-9ac2ae56a497',
						name: 'Margin',
						valuePath: 'margin',
						width: 140,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							currencySign: 'accounting',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: '1d61536a-16a5-41f0-a80e-684fbf8a177f',
				name: 'Percentiles',
				textAlign: 'center',
				isSortable: false,
				isFixed: '',
				isVisible: true,
				cellComponent: CellComponents.String,
				subcolumns: [
					{
						id: '11810537-81dd-4279-ac2b-39dfe3b87c6a',
						name: '25th',
						valuePath: 'twentyFifthPercentileHistoricalMargin',
						width: 140,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							currencySign: 'accounting',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: '75ee993f-c289-459d-9fd4-b66a8b81b4ef',
						name: '50th',
						valuePath: 'fiftiethPercentileHistoricalMargin',
						width: 140,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							currencySign: 'accounting',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: '85abd4a0-78e7-4304-8ef8-4793ebb9c10a',
						name: '75th',
						valuePath: 'seventyFifthPercentileHistoricalMargin',
						width: 140,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							currencySign: 'accounting',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: '5b3ab887-2a39-476f-a5aa-2d807f7c95f1',
						name: '90th',
						valuePath: 'ninetiethPercentileHistoricalMargin',
						width: 140,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							currencySign: 'accounting',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
				],
			},
		];
		return baseColumns;
	}

	productsByMonth: Product | never[] = [];

	get plugins() {
		const chartId = this.chartId;
		const legendId = this.legendId;

		return [
			{
				afterUpdate: function (chart: Chart) {
					function toggleDataset(event: PointerEvent): void {
						const index = (event.currentTarget as HTMLElement).dataset.index;
						if (index) {
							const meta = chart.getDatasetMeta(+index);
							meta.hidden = !meta.hidden ? true : false;
							chart.update();
						}
					}

					// Make sure we're applying the legend to the right chart
					if (chart.canvas.id === chartId) {
						const legend = document.getElementById(legendId)!;
						const ul = legend?.querySelector('ul') ?? document.createElement('ul');
						ul.classList.add('flex');

						// Remove old legend items
						while (ul.firstChild) {
							ul.firstChild.remove();
						}

						while (legend.firstChild) {
							legend.firstChild.remove();
						}

						chart.data.datasets.forEach((dataset, index) => {
							const legendItem = document.createElement('li');
							legendItem.classList.add('flex', 'flex-col', 'items-center', 'mr-6', 'text-brand-interactive-blue-70', 'cursor-pointer');

							// Strike out legend item if hidden
							const meta = chart.getDatasetMeta(index);
							if (meta.hidden) {
								legendItem.classList.add('line-through');
							}

							legendItem.dataset.index = index.toString(10);
							legendItem.onclick = toggleDataset;
							legendItem.innerHTML += `
								<div class="text-sm flex items-center">
									<span style="background-color: ${dataset.backgroundColor}" class="w-2 h-2 rounded-full inline-block mr-1"></span>
									${dataset.label}
								<div/>
							`;

							ul.append(legendItem);
						});

						return legend?.insertBefore(ul, legend.firstChild);
					}
					return;
				},
			},
		];
	}

	get chartId() {
		return `${guidFor(this)}-dairy-margin`;
	}

	get legendId() {
		return `${this.chartId}-legend`;
	}

	get tooltipId() {
		return `${this.chartId}-tooltip`;
	}

	get chartOptions() {
		const getOrCreateTooltip = (chart: Chart) => {
			let tooltipEl: HTMLDivElement | null | undefined = chart.canvas.parentNode?.querySelector('div');
			if (!tooltipEl) {
				tooltipEl = document.createElement('div');
				tooltipEl.id = this.tooltipId;
				tooltipEl.classList.add(
					'line-chart-tooltip',
					'line-chart-tooltip-bottom',
					'border',
					'border-brand-interactive-blue-70',
					'bg-white',
					'text-brand-grey-90',
					'absolute',
					'pointer-events-none',
					'transform',
					'-translate-x-2/4',
					'transition-all',
					'ease-linear',
					'duration-50',
					'opacity-1',
					'z-10',
					'text-left',
					'p-2',
					'rounded-sm'
				);

				const tooltipUL = document.createElement('ul');
				tooltipUL.classList.add('tooltipul');

				tooltipEl.append(tooltipUL);
				chart.canvas.parentNode?.append(tooltipEl);
			}
			return tooltipEl;
		};

		const customTooltip = (context: ScriptableTooltipContext<'line'>) => {
			const { chart, tooltip } = context;
			const tooltipEl = getOrCreateTooltip(chart);
			const tooltipUL = tooltipEl.querySelector('ul')!;

			if (tooltip.opacity === 0) {
				tooltipEl.classList.replace('opacity-1', 'opacity-0');
				return;
			}

			if (tooltip.body) {
				const titleLines = tooltip.title || [];
				const dataPoints = tooltip.dataPoints;
				const tooltipLI = document.createElement('li');
				tooltipLI.classList.add('whitespace-nowrap');

				// Title Loop
				titleLines.forEach((title: string) => {
					const formattedTitle = title;
					tooltipUL.appendChild(tooltipLI);

					const tooltipSpan = document.createElement('span');
					tooltipSpan.classList.add('text-sm', 'font-semibold', 'text-brand-interactive-blue-70', 'block');
					tooltipLI.appendChild(tooltipSpan);

					const tooltipTitle = document.createTextNode(formattedTitle);
					tooltipSpan.appendChild(tooltipTitle);
				});

				// Body Loop
				const tooltipBodyP = document.createElement('p');
				dataPoints.forEach((dataPoint, i: number) => {
					const displayBlockSpan = document.createElement('span');
					displayBlockSpan.classList.add('block', 'text-xs');
					const colors = tooltip.labelColors[i];
					const colorCircle = document.createElement('span');
					colorCircle.classList.add('rounded-full', 'w-2', 'h-2', 'inline-block', 'mr-2');
					colorCircle.style.background = colors.borderColor as string;
					colorCircle.style.border = colors.borderColor as string;

					const formattedValue = Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' }).format(dataPoint.raw as number);

					const textLabel = document.createTextNode(`${formattedValue} ${dataPoint.dataset.label}`);

					// Append color label and text
					displayBlockSpan.appendChild(colorCircle);
					displayBlockSpan.appendChild(textLabel);
					tooltipBodyP.appendChild(displayBlockSpan);
				});

				// Remove old children
				while (tooltipUL.firstChild) {
					tooltipUL.firstChild.remove();
				}

				// Add new children
				tooltipUL.appendChild(tooltipLI);
				tooltipLI.appendChild(tooltipBodyP);
				tooltipEl.classList.replace('opacity-0', 'opacity-1');

				// Position tooltip
				const position = context.chart.canvas.getBoundingClientRect();

				tooltipEl.style.left = context.chart.canvas.offsetLeft + tooltip.caretX + 'px';
				tooltipEl.style.bottom = position.height - 24 + 'px';
			}
		};
		const options = {
			maintainAspectRatio: false,
			responsive: true,
			legend: {
				display: false,
			},
			scales: {
				x: {
					type: 'category',
					stacked: false,
					ticks: {
						fontColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-gray-60'),
						fontSize: 12,
					},

					gridLines: {
						color: getComputedStyle(document.documentElement).getPropertyValue('--brand-gray-20'),
					},
				},
				y: {
					type: 'linear',
					stacked: false,
					ticks: {
						fontColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-gray-60'),
						fontSize: 12,
						userCallback: (value: number) => {
							return new Intl.NumberFormat(undefined, {
								style: 'currency',
								currency: 'USD',
							}).format(value);
						},
					},
				},
			},
			plugins: {
				legend: {
					display: false,
				},
				tooltip: {
					external: customTooltip,
					enabled: false,
					displayColors: false,
					callbacks: {
						title: (tooltipItem: any) => {
							return `${tooltipItem[0].label}`;
						},
						label: (tooltipItem: any) => {
							return `Price: ${new Intl.NumberFormat(undefined, {
								style: 'currency',
								currency: 'USD',
							}).format(tooltipItem.value)}`;
						},
						afterLabel: (tooltipItem: any, data: any) => {
							if (data?.datasets[tooltipItem.datasetIndex].afterLabel) {
								return this.months
									.map(
										(month: Month) =>
											` Percentile: ${new Intl.NumberFormat(undefined, {
												style: 'percent',
											}).format(month.marginPercentRank)}`
									)
									.filter((percentRank: number, index: number) => tooltipItem.index === index && percentRank);
							}
						},
					},
				},
			},
		};
		return options;
	}

	get months() {
		const owner = getOwner(this);
		if (this.classIIIProduct && this.cornProduct && this.soybeanMealProduct) {
			// Using Class III current available futures months to create the months - dmw
			const month = this.classIIIProduct.CurrentFutures.map((future: Future) => {
				const monthDisplayExpiresAt = future.displayExpiresAt;
				const classIIIFuture = future;
				// If the current contract month isn't available for corn then use the next available month -dmw
				const cornFuture = this.cornProduct.CurrentFutures.find(
					(future: Future) => future.displayExpiresAt >= monthDisplayExpiresAt && future.displayExpiresAt !== undefined
				);
				// If the current contract month isn't available for soybeanMeal then use the next available month - dmw
				const soybeanMealFuture = this.soybeanMealProduct.CurrentFutures.find(
					(future: Future) => future.displayExpiresAt >= monthDisplayExpiresAt && future.displayExpiresAt !== undefined
				);

				return new DairyMargin(
					owner,
					classIIIFuture,
					cornFuture,
					soybeanMealFuture,
					this.classIIIProduct,
					this.cornProduct,
					this.soybeanMealProduct,
					this.numberOfYears
				);
			}).slice(0, 24);
			return month;
		} else {
			return null;
		}
	}

	get products() {
		return this.model.Products;
	}

	get classIIIProduct() {
		return this.model.Products.find((product: Product) => product.slug === 'us-dairy-class-iii');
	}

	get cornProduct() {
		return this.model.Products.find((product: Product) => product.slug === 'grain-corn');
	}

	get soybeanMealProduct() {
		return this.model.Products.find((product: Product) => product.slug === 'grain-soybean-meal');
	}

	get data() {
		return {
			Margin: this.months.map((month: Month) => {
				return new Intl.NumberFormat().format(month.margin);
			}),
			'25th Percentile': this.months.map((month: Month) => {
				return new Intl.NumberFormat().format(month.twentyFifthPercentileHistoricalMargin);
			}),
			'50th Percentile': this.months.map((month: Month) => {
				return new Intl.NumberFormat().format(month.fiftiethPercentileHistoricalMargin);
			}),
			'75th Percentile': this.months.map((month: Month) => {
				return new Intl.NumberFormat().format(month.seventyFifthPercentileHistoricalMargin);
			}),
			'90th Percentile': this.months.map((month: Month) => {
				return new Intl.NumberFormat().format(month.ninetiethPercentileHistoricalMargin);
			}),
		};
	}

	get chartData() {
		const labels = this.months.map((month: Month) => {
			const luxonDate = DateTime.fromISO(month.classIIIFuture.displayExpiresAt);
			const date = luxonDate.toJSDate();

			const formattedDate = new Intl.DateTimeFormat('en-US', {
				month: 'short',
				year: 'numeric',
			}).format(date);
			return formattedDate;
		});

		const datasets = [
			{
				backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-interactive-blue-70'),
				borderColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-interactive-blue-70'),
				borderWidth: 4,
				pointStyle: 'circle',
				fill: false,
				type: 'line',
				label: 'Margin',
				afterLabel: true,
				data: this.data['Margin'],
			},
			{
				backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-orange-40'),
				borderColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-orange-40'),
				borderWidth: 2,
				radius: 0,
				pointStyle: 'circle',
				fill: false,
				type: 'line',
				label: '25th Percentile',
				data: this.data['25th Percentile'],
			},
			{
				backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-lime-40'),
				borderColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-lime-40'),
				borderWidth: 2,
				radius: 0,
				pointStyle: 'circle',
				fill: false,
				type: 'line',
				label: '50th Percentile',
				data: this.data['50th Percentile'],
			},
			{
				backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-lemon-40'),
				borderColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-lemon-40'),
				borderWidth: 2,
				radius: 0,
				pointStyle: 'circle',
				fill: false,
				type: 'line',
				label: '75th Percentile',
				data: this.data['75th Percentile'],
			},
			{
				backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-teal-40'),
				borderColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-teal-40'),
				borderWidth: 2,
				radius: 0,
				pointStyle: 'circle',
				fill: false,
				type: 'line',
				label: '90th Percentile',
				data: this.data['90th Percentile'],
			},
		];

		return {
			labels,
			datasets,
		};
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'reports/dairy-margin': ReportsDairyMargin;
	}
}
