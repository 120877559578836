import ExposureShowController from 'vault-client/controllers/exposure/show';

export default class OrganizationsOrganizationExposureController extends ExposureShowController {
	exposuresRoutePath = 'organizations.organization.exposures';
	exposureRoutePath = 'organizations.organization.exposure';

	// Setting this to null disables the link within the month column
	exposureHedgeMonthDetailRoutePath = null;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'organizations/organization/exposure': OrganizationsOrganizationExposureController;
	}
}
