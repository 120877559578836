import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='p-8' ...attributes>\n\t<div>\n\t\t<span class='text-xl font-sans-semibold mr-2 {{this.percentHedgedTotalFontColor}}'>\n\t\t\t<IntlNumberFormat @style='percent' @value={{this.percentages.Total}} />\n\t\t</span>\n\t\t<span class='text-brand-gray-100'>\n\t\t\tHedged\n\t\t</span>\n\t</div>\n\t<div class='relative shadow border rounded-md {{@containerClasses}}' id='{{this.chartId}}-container'>\n\t\t<BarChart\n\t\t\t@chartData={{this.chartData}}\n\t\t\t@options={{this.chartOptions}}\n\t\t\t@data={{this.percentages}}\n\t\t\t@id={{this.chartId}}\n\t\t\t@plugins={{this.plugins}}\n\t\t\t@updateChart={{this.updateChart}}\n\t\t/>\n\t</div>\n\t<div class='mt-4' id={{this.legendId}}></div>\n</div>", {"contents":"<div class='p-8' ...attributes>\n\t<div>\n\t\t<span class='text-xl font-sans-semibold mr-2 {{this.percentHedgedTotalFontColor}}'>\n\t\t\t<IntlNumberFormat @style='percent' @value={{this.percentages.Total}} />\n\t\t</span>\n\t\t<span class='text-brand-gray-100'>\n\t\t\tHedged\n\t\t</span>\n\t</div>\n\t<div class='relative shadow border rounded-md {{@containerClasses}}' id='{{this.chartId}}-container'>\n\t\t<BarChart\n\t\t\t@chartData={{this.chartData}}\n\t\t\t@options={{this.chartOptions}}\n\t\t\t@data={{this.percentages}}\n\t\t\t@id={{this.chartId}}\n\t\t\t@plugins={{this.plugins}}\n\t\t\t@updateChart={{this.updateChart}}\n\t\t/>\n\t</div>\n\t<div class='mt-4' id={{this.legendId}}></div>\n</div>","moduleName":"vault-client/components/percent-hedged-chart.hbs","parseOptions":{"srcName":"vault-client/components/percent-hedged-chart.hbs"}});
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import Component from '@glimmer/component';
import Big from 'big.js';
import { Chart, ChartData, ChartDataset, ChartOptions } from 'chart.js';
import { CustomTooltipOptions, getCustomLegend, getCustomTooltip } from 'vault-client/utils/chart-utils';
import getCSSVariable from 'vault-client/utils/get-css-variable';

interface PercentHedgedChartArgs {
	// Data structured as object with label and percentage as keys and values
	// Example: { 'DRP': 0.5, 'Brokerage': 0.4 }
	// Unhedged will be calculated as 1 - sum of all percentages
	data: { [key: string]: number | null };
	// Used to determine if unhedged should be 100% or 0% if all percentages are 0
	hasExposure: boolean;
	chartId?: string;
	containerClasses?: string;
}

export default class PercentHedgedChart extends Component<PercentHedgedChartArgs> {
	guid = guidFor(this);

	get legendId(): string {
		return `percent-hedged-legend-${this.guid}`;
	}

	get chartId(): string {
		return this.args.chartId || `percent-hedged-chart-${this.guid}`;
	}

	get percentHedgedTotalFontColor(): string {
		const totalHedged = this.percentages.Total;

		if (totalHedged > 1.1) {
			return 'text-brand-error-50';
		} else {
			return 'text-brand-black';
		}
	}

	get percentages(): {
		[key: string]: number;
		Unhedged: number;
		Total: number;
	} {
		const data = this.args.data;
		const labels = Object.keys(data);
		const percentages = Object.values(data).map((value) => value || 0);
		const total = percentages.reduce((a, b) => a.plus(b), Big(0));
		const unhedged = Big(1).minus(total).gt(0) && this.args.hasExposure ? Big(1).minus(total) : Big(0);
		const allPercentages = [total?.toNumber(), unhedged?.toNumber(), ...percentages];

		return ['Total', 'Unhedged', ...labels].reduce(
			(acc, label, i) => {
				acc[label] = allPercentages[i];
				return acc;
			},
			{ Unhedged: 0, Total: 0 } as {
				[key: string]: number;
				Unhedged: number;
				Total: number;
			}
		);
	}
	get chartData(): ChartData<'bar'> {
		const { Total, ...data } = this.percentages;
		const labels = Object.keys(data);

		const datasets: ChartDataset<'bar', number[]>[] = labels
			.map((label) => {
				const backgroundColor = this.getColorForLabel(label);
				const borderColor = backgroundColor === getCSSVariable('--brand-white') ? getCSSVariable('--brand-black') : backgroundColor;
				return {
					label,
					data: [data[label] ?? 0],
					backgroundColor,
					borderColor,
					fill: true,
				};
			})
			.reverse();

		return {
			labels: [''],
			datasets,
		};
	}

	get chartOptions(): ChartOptions<'bar'> {
		const customTooltipOptions: CustomTooltipOptions = {
			valueFormatter: (val: number) => {
				if (typeof val === 'string') return val;
				return Intl.NumberFormat('en-US', {
					style: 'percent',
					minimumFractionDigits: 0,
					maximumFractionDigits: 0,
				}).format(val || 0);
			},
			useBorderColorForBorder: true,
		};

		return {
			indexAxis: 'y',
			responsive: true,
			maintainAspectRatio: false,
			interaction: {
				intersect: false,
				mode: 'index',
			},
			datasets: {
				bar: {
					borderSkipped: 'middle',
					borderRadius: 6,
					categoryPercentage: 1,
					barPercentage: 1,
				},
			},
			scales: {
				x: {
					display: false,
					stacked: true,
					max: this.percentages.Total > 1 ? this.percentages.Total : 1,
				},
				y: {
					display: false,
					stacked: true,
				},
			},
			plugins: {
				tooltip: {
					mode: 'index',
					external: getCustomTooltip(customTooltipOptions),
					enabled: false,
					displayColors: false,
				},
				legend: {
					display: false,
				},
			},
		};
	}

	get plugins() {
		const chartId = this.chartId;
		const legendId = this.legendId;
		const valueFormatter = (val: number | null) => {
			if (val === null) return '';
			return Intl.NumberFormat('en-US', {
				style: 'percent',
				minimumFractionDigits: 0,
				maximumFractionDigits: 0,
			}).format(val);
		};
		return [
			{
				afterUpdate: getCustomLegend(chartId, legendId, valueFormatter),
			},
		];
	}

	@action
	updateChart(chart: Chart) {
		chart.data = this.chartData;
		chart.options = this.chartOptions;
		chart.update('none');
	}

	getColorForLabel(label: string): string {
		switch (label) {
			// All
			case 'Brokerage':
				return getCSSVariable('--brand-lime-40');
			case 'Unhedged':
				return getCSSVariable('--brand-white');
			// Milk Related
			case 'DRP':
				return getCSSVariable('--brand-teal-60');
			// Swine Related
			case 'LGM':
				return getCSSVariable('--brand-purple-50');
			case 'LRP':
				return getCSSVariable('--brand-teal-60');
			// Feed Related
			case 'Physical':
				return getCSSVariable('--brand-teal-60');
			default:
				return getCSSVariable('--brand-orange-40');
		}
	}
}
