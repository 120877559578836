import { gql } from 'glimmer-apollo';
import SearchFilterComponent from 'vault-client/components/vault/ui-search-filter';

const GET_BUYER = gql`
	query GetBuyer($id: String!) {
		Buyer(id: $id) {
			id
			name
		}
	}
`;

export default class BuyerIdFilterComponent extends SearchFilterComponent {
	query = GET_BUYER;
	valuePath = 'Buyer.name';
}
