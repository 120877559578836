import Route from '@ember/routing/route';
import { tracked } from '@glimmer/tracking';
import { gql, useQuery } from 'glimmer-apollo';
import {
	Query,
	Query_GrainFillOrderModificationRequestsArgs,
	GrainFillOrderModificationStatus,
	GrainFillOrderModificationRequestFilterDTO,
	TypeOfGrainPlan,
} from 'vault-client/types/graphql-types';

const GET_GRAIN_REQUESTS = gql`
	query GrainFillOrderModificationRequests($where: GrainFillOrderModificationRequestFilterDTO) {
		GrainFillOrderModificationRequests(where: $where, orderBy: { createdAt: Desc }) {
			id
			fillId
			updatedAt
			type
			status
			createdAt
			shortNote
			OwnedByUser {
				id
				firstName
				lastName
			}
			Buyer {
				id
				name
			}
			Seller {
				id
				name
			}
			Location {
				id
				name
			}
			data {
				basis
				bushels
				buyerId
				contractNumber
				deliveryEndDate
				deliveryId
				deliveryMonth
				deliveryStartDate
				flexFee
				futurePrice
				futuresMonth
				htaFee
				lastUpdatedBy
				lastUpdatedById
				locationId
				rollFee
				spread
			}
			RequestedByUser {
				id
				firstName
				lastName
			}
			GrainFillOrder {
				id

				Activity {
					id
					type
					orderId
					performedAt
					performedByUserId
					type
					GrainPlan {
						id
						type
					}
					User {
						id
						email
						firstName
						lastName
					}
				}
				Buyer {
					id
					name
				}
				DeliveryLocation {
					id
					name
				}
				GrainFillOrderActivity {
					id
					operationType
					orderId
					performedAt
					performedByUserId
					type
					data
					GrainPlan {
						id
						type
					}
					User {
						id
						firstName
						lastName
					}
				}
				Location {
					id
					name
				}
				Plan {
					id
					type
					... on GrainCropPlan {
						hasWriteAccess
						Crop {
							id
							name
						}
						CropYear {
							id
							year
						}
						Customer {
							id
							name
						}
					}
				}
				basis
				bushels
				buyerId
				contractNumber
				createdAt
				createdBy
				deliveryEndDate
				deliveryId
				deliveryMonth
				deliveryStartDate
				fees
				flatPrice
				flexFee
				futurePrice
				futuresMonth
				htaFee
				lastUpdatedBy
				lastUpdatedById
				locationId
				planId
				rollFee
				salesType
				sellerId
				spread
				targetId
				type
				updatedAt
			}
		}
	}
`;

type GetRequests = {
	__typename?: 'Query';
	GrainFillOrderModificationRequests: Query['GrainFillOrderModificationRequests'];
};

type ModificationRequestQueryParams = {
	status: GrainFillOrderModificationStatus | null;
};

export default class VgsGrainServicesModificationRequests extends Route {
	@tracked variables: any = {};

	queryParams = {
		status: {
			refreshModel: true,
		},
	};

	getGrainRequest = useQuery<GetRequests, Query_GrainFillOrderModificationRequestsArgs>(this, () => [
		GET_GRAIN_REQUESTS,
		{ variables: this.variables, pollInterval: 10000 },
	]);

	async model(params: ModificationRequestQueryParams) {
		const where: GrainFillOrderModificationRequestFilterDTO = {
			GrainFillOrder: {
				Plan: {
					type: {
						equals: TypeOfGrainPlan.Crop,
					},
				},
			},
			...(params.status && {
				status: {
					equals: params.status,
				},
			}),
		};

		this.variables = { where };

		await this.getGrainRequest.promise;

		return { grainRequests: this.getGrainRequest };
	}
}
