import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { gql, useMutation } from 'glimmer-apollo';
import { Mutation, Mutation_createBuyerArgs } from 'vault-client/types/graphql-types';

const CREATE_BUYER = gql`
	mutation CreateBuyer($data: BuyerCreateDTO!) {
		createBuyer(data: $data) {
			id
		}
	}
`;

export default class AddBuyerButton extends Component {
	@tracked showModal = false;
	@tracked name: string | null = null;
	@tracked errorMessage: string | null = null;

	createBuyer = useMutation<{ createBuyer: Mutation['createGrainBuyer'] }, Mutation_createBuyerArgs>(this, () => [
		CREATE_BUYER,
		{
			onComplete: (): void => {
				this.errorMessage = null;
				this.closeModal();
			},
			update: (cache) => {
				cache.evict({ fieldName: 'Buyers' });
				cache.evict({ fieldName: 'Buyer' });
				cache.gc();
			},
			onError: (error): void => {
				this.errorMessage = 'There was an error. Grain Buyer not added.';
				console.error('Error while attempting to add Grain Buyer', error.message);
			},
		},
	]);

	get submitError() {
		return !!this.errorMessage;
	}

	@action
	openModal() {
		this.showModal = true;
	}

	@action
	closeModal() {
		this.showModal = false;
		this.name = null;
		this.errorMessage = null;
	}

	@action
	async submit() {
		this.errorMessage = null;

		if (!this.name) {
			this.errorMessage = 'Please fill out all fields.';
			return;
		}

		const variables: Mutation_createBuyerArgs = {
			data: {
				name: this.name,
			},
		};
		await this.createBuyer.mutate(variables);
	}
}
