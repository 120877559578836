import Controller from '@ember/controller';
import { action } from '@ember/object';
import { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { DateTime } from 'luxon';
import { UiDateFilterOption } from 'vault-client/components/vault/ui-date-filter';
import BusinessesBusinessLgmInsuranceMonths from 'vault-client/routes/businesses/business/lgm-insurance-months';
import { LgmInsuranceEndorsement } from 'vault-client/types/graphql-types';
import { MediaService } from 'vault-client/types/vault-client';
import { TableColumn, CellComponents } from 'vault-client/types/vault-table';
import { intervalFromDateTime } from 'vault-client/utils/interval-from-date-time';
import { ModelFrom } from 'vault-client/utils/type-utils';

interface LGMMonthRow {
	date: string;
	grossProduction: number;
	effectiveCoveredLeanHogsLbs: number;
	effectiveCoveredMilkProduction: number;
	leanHogsPercentHedged: number;
	classIIIPercentHedged: number;
	producerPremiumAmount: number;
	producerPremiumAmountCwt: number | null;
	indemnity: number;
	indemnityCwt: number | null;
	pnl: number;
	pnlCwt: number | null;
}

export default abstract class LgmInsuranceMonths extends Controller {
	// This is a placeholder for the actual logic that determines whether to show lean hog or class III endorsements
	// This logic needs to be implemented at the organization/business level
	abstract swineMode: boolean;
	abstract insuranceRoutePath: string;
	declare model: ModelFrom<BusinessesBusinessLgmInsuranceMonths>;

	@service declare media: MediaService;

	@tracked startDate = DateTime.now().minus({ months: 1 }).startOf('month').toISODate();
	@tracked endDate = DateTime.now().plus({ months: 11 }).endOf('month').toISODate();

	queryParams = ['startDate', 'endDate'];

	get dateRanges(): UiDateFilterOption[] {
		return [
			{
				displayName: 'Open Months',
				startDate: DateTime.now().minus({ months: 1 }).startOf('month').toISODate(),
				endDate: DateTime.now().plus({ months: 11 }).endOf('month').toISODate(),
			},
			{
				displayName: 'Last Year',
				startDate: DateTime.now().minus({ year: 1 }).startOf('year').toISODate(),
				endDate: DateTime.now().minus({ year: 1 }).endOf('year').toISODate(),
			},
			{
				displayName: 'Current Year',
				startDate: DateTime.now().startOf('year').toISODate(),
				endDate: DateTime.now().endOf('year').toISODate(),
			},
			{
				displayName: 'Next Year',
				startDate: DateTime.now().plus({ year: 1 }).startOf('year').toISODate(),
				endDate: DateTime.now().plus({ year: 1 }).endOf('year').toISODate(),
			},
		];
	}

	get currentDateRange() {
		return {
			startDate: this.startDate,
			endDate: this.endDate,
		};
	}

	get endorsements() {
		const endorsements = this.model.getLgmEndorsements.data?.InsuranceEndorsementAllocationRatios ?? [];
		const productSlug = this.swineMode ? 'livestock-lean-hogs' : 'us-dairy-class-iii';
		return endorsements.filter(
			(endorsement) => (endorsement.RatioAdjustedInsuranceEndorsement as LgmInsuranceEndorsement).RevenueHedgeProduct.slug === productSlug,
		);
	}

	get lgmMonthsColumns(): TableColumn[] {
		return [
			{
				id: '0425d824-4640-45ab-a282-628fc6bb9e6c',
				name: 'Month',
				valuePath: 'date',
				minWidth: 100,
				cellComponent: CellComponents.MonthFormat,
				isVisible: true,
				isSortable: false,
				isFixed: 'left',
			},
			{
				id: '991f1e47-7589-412f-a2b4-a46f3b242562',
				name: 'Production',
				valuePath: 'grossProduction',
				minWidth: 120,
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
				isTotaled: true,
			},
			...(this.swineMode
				? [
						{
							id: '50eb45e4-f812-4e9f-985b-2e52fa8e2a76',
							name: 'Insured LBS',
							valuePath: 'effectiveCoveredLeanHogsLbs',
							minWidth: 120,
							cellComponent: CellComponents.IntlNumberFormat,
							textAlign: 'right',
							isSortable: false,
							isFixed: '',
							isVisible: true,
							isTotaled: true,
						},
				  ]
				: [
						{
							id: '97112480-4e67-4c76-b110-22ce42e1153c',
							name: 'Insured Milk',
							valuePath: 'effectiveCoveredMilkProduction',
							minWidth: 130,
							cellComponent: CellComponents.IntlNumberFormat,
							textAlign: 'right',
							isSortable: false,
							isFixed: '',
							isVisible: true,
							isTotaled: true,
						},
				  ]),
			{
				id: '8c86144b-7ffd-4c3b-b3f0-a7b4cc292d84',
				name: 'Insured %',
				cellComponent: CellComponents.String,
				textAlign: 'center',
				isSortable: false,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					...(this.swineMode
						? [
								{
									id: '4d09287c-2b26-4387-838f-1f142358ce76',
									name: 'Lean Hogs',
									valuePath: 'leanHogsPercentHedged',
									minWidth: 100,
									cellComponent: CellComponents.IntlNumberFormat,
									componentArgs: {
										style: 'percent',
									},
									textAlign: 'right',
									isSortable: false,
									isFixed: '',
									isVisible: true,
									isTotaled: true,
								},
						  ]
						: [
								{
									id: 'ba7e7cab-11d8-4b01-8111-d0f5e7fad966',
									name: 'Class III',
									valuePath: 'classIIIPercentHedged',
									minWidth: 100,
									cellComponent: CellComponents.IntlNumberFormat,
									componentArgs: {
										style: 'percent',
									},
									textAlign: 'right',
									isSortable: false,
									isFixed: '',
									isVisible: true,
									isTotaled: true,
								},
						  ]),
				],
			},
			{
				id: '0405a3df-3945-4de0-83d7-1a301ed250b7',
				name: 'Producer Premium',
				cellComponent: CellComponents.String,
				textAlign: 'center',
				isSortable: false,
				isFixed: '',
				isVisible: true,
				isTotaled: true,
				subcolumns: [
					{
						id: 'b23a44fb-6fa3-4822-899c-f85914f1aa5a',
						name: 'Total',
						valuePath: 'producerPremiumAmount',
						minWidth: 130,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							currencySign: 'accounting',
							maximumFractionDigits: 0,
						},
						textAlign: 'right',
						isSortable: true,
						isFixed: '',
						isVisible: true,
						isTotaled: true,
					},
					{
						id: '74949e6c-84f9-4bd4-95bc-ad31327ab2b2',
						name: 'CWT',
						valuePath: 'producerPremiumAmountCwt',
						minWidth: 70,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							currencySign: 'accounting',
						},
						textAlign: 'right',
						isSortable: true,
						isFixed: '',
						isVisible: true,
						isTotaled: true,
					},
				],
			},
			{
				id: 'eb6ab6a4-c488-4bfe-9a36-e58b51e59342',
				name: 'Indemnity',
				cellComponent: CellComponents.String,
				textAlign: 'center',
				isSortable: false,
				isFixed: '',
				isVisible: true,
				isTotaled: true,
				subcolumns: [
					{
						id: 'b8c6ead6-fc61-4874-acbd-846a1ede96d1',
						name: 'Total',
						valuePath: 'indemnity',
						minWidth: 130,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							currencySign: 'accounting',
							maximumFractionDigits: 0,
						},
						textAlign: 'right',
						isSortable: true,
						isFixed: '',
						isVisible: true,
						isTotaled: true,
					},
					{
						id: 'd9130939-ac88-4cb8-9c40-33da65488121',
						name: 'CWT',
						valuePath: 'indemnityCwt',
						minWidth: 70,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							currencySign: 'accounting',
						},
						textAlign: 'right',
						isSortable: true,
						isFixed: '',
						isVisible: true,
						isTotaled: true,
					},
				],
			},
			{
				id: 'e21fc352-74fb-47e7-b424-d633eb9d2073',
				name: 'Profit/Loss',
				cellComponent: CellComponents.String,
				textAlign: 'center',
				isSortable: false,
				isFixed: '',
				isVisible: true,
				isTotaled: true,
				subcolumns: [
					{
						id: 'def322c4-bf69-463f-bd6f-c3c0af5a1bdc',
						name: 'Total',
						valuePath: 'pnl',
						minWidth: 130,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							currencySign: 'accounting',
							maximumFractionDigits: 0,
						},
						textAlign: 'right',
						isSortable: true,
						isFixed: '',
						isVisible: true,
						isTotaled: true,
					},
					{
						id: '7ab6eaa1-f38e-4141-8996-47466c498a68',
						name: 'CWT',
						valuePath: 'pnlCwt',
						minWidth: 70,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							currencySign: 'accounting',
						},
						textAlign: 'right',
						isSortable: true,
						isFixed: '',
						isVisible: true,
						isTotaled: true,
					},
				],
			},
		];
	}

	get lgmMonths() {
		const endorsements = this.endorsements;
		const months = intervalFromDateTime(DateTime.fromISO(this.startDate), DateTime.fromISO(this.endDate), {
			month: 1,
		}).reduce<Record<string, LGMMonthRow>>((acc, dateTime) => {
			const date = dateTime.toISODate();
			acc[date] = {
				date,
				grossProduction: 0,
				effectiveCoveredLeanHogsLbs: 0,
				effectiveCoveredMilkProduction: 0,
				leanHogsPercentHedged: 0,
				classIIIPercentHedged: 0,
				producerPremiumAmount: 0,
				producerPremiumAmountCwt: null,
				indemnity: 0,
				indemnityCwt: null,
				pnl: 0,
				pnlCwt: null,
			};
			return acc;
		}, {});

		// Add policy data to months
		endorsements.forEach((ratio) => {
			const lgmInsuranceEndorsement = ratio.RatioAdjustedInsuranceEndorsement as LgmInsuranceEndorsement;
			const date = ratio.effectiveHedgeDate;
			const effectiveCoveredLeanHogsLbs = this.swineMode
				? lgmInsuranceEndorsement.totalTarget * this.model.Customer.averageFinishWeightInLbs
				: 0;

			// Convert the effectiveCoveredMilkProduction from cwt to lbs
			const effectiveCoveredMilkProduction = !this.swineMode ? lgmInsuranceEndorsement.totalTarget * 100 : 0;
			const producerPremiumAmount = lgmInsuranceEndorsement.producerPremiumAmount;
			const indemnity = lgmInsuranceEndorsement.indemnity ?? 0;
			const pnl = lgmInsuranceEndorsement.pnl ?? 0;

			const month = months[date];
			if (month) {
				month.date = date;
				month.effectiveCoveredLeanHogsLbs += effectiveCoveredLeanHogsLbs;
				month.effectiveCoveredMilkProduction += effectiveCoveredMilkProduction;
				month.producerPremiumAmount += producerPremiumAmount;
				month.indemnity += indemnity;
				month.pnl += pnl;
			}
		});

		// Add production data to months
		if (this.swineMode) {
			this.model.getLgmEndorsements.data?.SwinePurchasesAndProduced.forEach((swinePopulation) => {
				const dob = swinePopulation.dob;
				if (!dob) return;

				const expectedMarketingDate = DateTime.fromISO(dob).plus({
					weeks: this.model.Customer.averageFinishAgeInWeeks,
				});

				const numSwine = swinePopulation.sum.quantity ?? 0;
				const month = months[expectedMarketingDate.toISODate()];
				if (month) {
					month.grossProduction += numSwine;
				}
			});
		} else {
			this.model.getLgmEndorsements.data?.AggregateForecastedMilkProductionByMonths.forEach((milkProduction) => {
				const date = milkProduction.date ?? '';
				const grossProduction = milkProduction.sum.grossProduction ?? 0;
				const month = months[date];
				if (month) {
					month.grossProduction += grossProduction;
				}
			});
		}

		// Calculate percentages
		Object.values(months).forEach((month) => {
			const grossProduction = month.grossProduction;
			const grossProductionLbs = this.swineMode ? grossProduction * this.model.Customer.averageFinishWeightInLbs : grossProduction;

			if (!grossProduction) return;

			month.leanHogsPercentHedged = month.effectiveCoveredLeanHogsLbs / grossProductionLbs;
			month.classIIIPercentHedged = month.effectiveCoveredMilkProduction / grossProductionLbs;

			const effectiveCoveredProductionCwt = this.swineMode
				? month.effectiveCoveredLeanHogsLbs / 100
				: month.effectiveCoveredMilkProduction / 100;

			month.pnlCwt = effectiveCoveredProductionCwt ? month.pnl / effectiveCoveredProductionCwt : null;
			month.producerPremiumAmountCwt = effectiveCoveredProductionCwt ? month.producerPremiumAmount / effectiveCoveredProductionCwt : null;
			month.indemnityCwt = effectiveCoveredProductionCwt ? month.indemnity / effectiveCoveredProductionCwt : null;
		});

		return Object.values(months).sortBy('date');
	}

	get lgmMonthTotals(): [Omit<LGMMonthRow, 'date'>] {
		let producerPremiumAmountCwtCount = 0;
		let indemnityCwtCount = 0;
		let pnlCwtCount = 0;
		let leanHogsPercentHedgedCount = 0;
		let classIIIPercentHedgedCount = 0;

		let producerPremiumAmountCwtTotal: number | null = null;
		let indemityAmountCwtTotal: number | null = null;
		let pnlCwtTotal: number | null = null;
		let leanHogsPercentHedgedTotal = 0;
		let classIIIPercentHedgedTotal = 0;

		const totals = this.lgmMonths.reduce<Omit<LGMMonthRow, 'date'>>(
			(acc, month) => {
				if (month.producerPremiumAmountCwt) {
					producerPremiumAmountCwtTotal = (producerPremiumAmountCwtTotal || 0) + month.producerPremiumAmountCwt;
					producerPremiumAmountCwtCount++;
				}

				if (month.indemnityCwt) {
					indemityAmountCwtTotal = (indemityAmountCwtTotal || 0) + month.indemnityCwt;
					indemnityCwtCount++;
				}

				if (month.pnlCwt) {
					pnlCwtTotal = (pnlCwtTotal || 0) + month.pnlCwt;
					pnlCwtCount++;
				}

				if (month.leanHogsPercentHedged) {
					leanHogsPercentHedgedTotal += month.leanHogsPercentHedged;
					leanHogsPercentHedgedCount++;
				}

				if (month.classIIIPercentHedged) {
					classIIIPercentHedgedTotal += month.classIIIPercentHedged;
					classIIIPercentHedgedCount++;
				}

				acc.grossProduction += month.grossProduction;
				acc.effectiveCoveredLeanHogsLbs += month.effectiveCoveredLeanHogsLbs;
				acc.effectiveCoveredMilkProduction += month.effectiveCoveredMilkProduction;
				acc.producerPremiumAmount += month.producerPremiumAmount;
				acc.indemnity += month.indemnity;
				acc.pnl += month.pnl;

				return acc;
			},
			{
				grossProduction: 0,
				effectiveCoveredLeanHogsLbs: 0,
				effectiveCoveredMilkProduction: 0,
				producerPremiumAmount: 0,
				producerPremiumAmountCwt: null,
				indemnity: 0,
				indemnityCwt: null,
				pnl: 0,
				pnlCwt: null,
				leanHogsPercentHedged: 0,
				classIIIPercentHedged: 0,
			},
		);

		const producerPremiumAmountCwtAvg =
			producerPremiumAmountCwtTotal && producerPremiumAmountCwtCount ? producerPremiumAmountCwtTotal / producerPremiumAmountCwtCount : null;
		const indemityAmountCwtAvg = indemityAmountCwtTotal && indemnityCwtCount ? indemityAmountCwtTotal / indemnityCwtCount : null;
		const pnlCwtAvg = pnlCwtTotal && pnlCwtCount ? pnlCwtTotal / pnlCwtCount : null;
		const leanHogsPercentHedgedAvg =
			leanHogsPercentHedgedTotal && leanHogsPercentHedgedCount ? leanHogsPercentHedgedTotal / leanHogsPercentHedgedCount : 0;
		const classIIIPercentHedgedAvg =
			classIIIPercentHedgedTotal && classIIIPercentHedgedCount ? classIIIPercentHedgedTotal / classIIIPercentHedgedCount : 0;

		totals.producerPremiumAmountCwt = producerPremiumAmountCwtAvg;
		totals.indemnityCwt = indemityAmountCwtAvg;
		totals.pnlCwt = pnlCwtAvg;
		totals.leanHogsPercentHedged = leanHogsPercentHedgedAvg;
		totals.classIIIPercentHedged = classIIIPercentHedgedAvg;

		return [totals];
	}

	@action
	setStartAndEndDate(value: { startDate: string; endDate: string }) {
		this.startDate = value.startDate;
		this.endDate = value.endDate;
	}
}
