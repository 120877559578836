/* eslint-disable ember/no-controller-access-in-routes */
import Route from '@ember/routing/route';
import query from 'vault-client/graphql/queries/reports/drp-performance-by-sales-effective-date-month.graphql';
import { queryManager } from 'ember-apollo-client';
import { DateTime } from 'luxon';
import { DerivedDrpEndorsementFilterDTO, DerivedDrpEndorsementSortByDTO } from 'vault-client/types/graphql-types';
import { SortObj } from 'vault-client/types/vault-table';

export default class ReportsDrpPerformanceBySalesEffectiveDateMonthRoute extends Route {
	@queryManager apollo: any;

	queryParams = {
		agent: {
			refreshModel: true,
		},
		aipId: {
			refreshModel: true,
		},
		quarterStartDate: {
			refreshModel: true,
		},
		quarterEndDate: {
			refreshModel: true,
		},
		salesEffectiveStartDate: {
			refreshModel: true,
		},
		salesEffectiveEndDate: {
			refreshModel: true,
		},
		sorts: {
			refreshModel: true,
		},
		stateId: {
			refreshModel: true,
		},
		type: {
			refreshModel: true,
		},
	};

	query = query;

	templateName: string = 'reports/drp-performance-by-sales-effective-date-month';

	generateOrderBy(sorts: SortObj[]): DerivedDrpEndorsementSortByDTO {
		const orderBy: {
			[key: string]: any;
		} = {};

		sorts.forEach((sort: any) => {
			if (sort.valuePath === 'month') {
				orderBy['salesEffectiveDate'] = sort.isAscending ? 'Asc' : 'Desc';
			} else {
				orderBy[sort.valuePath] = sort.isAscending ? 'Asc' : 'Desc';
			}
		});

		return orderBy;
	}

	generateWhere(
		organizationId: null | string,
		customerId: undefined | null | string,
		quarterStartDate: string | null | undefined,
		quarterEndDate: string | null | undefined,
		agent: string | null | undefined,
		stateId: string | null | undefined,
		aipId: string | null | undefined,
		type: string | null | undefined,
		salesEffectiveStartDate: string | null | undefined,
		salesEffectiveEndDate: string | null | undefined
	): DerivedDrpEndorsementFilterDTO {
		const where: DerivedDrpEndorsementFilterDTO = {};

		if (customerId) {
			where.InsurancePolicy = {
				customerId: { equals: customerId },
			};
		} else if (organizationId) {
			where.InsurancePolicy = {
				OR: [
					{
						Customer: {
							organizationId: {
								equals: organizationId,
							},
						},
					},
					{
						ownerId: {
							equals: organizationId,
						},
					},
				],
			};
		}

		if (aipId) {
			if (!where.InsurancePolicy) {
				where.InsurancePolicy = {};
			}
			where.InsurancePolicy.aipId = {
				equals: aipId,
			};
		}

		if (agent) {
			const nameArr = agent.split(' ');
			if (!where.AND) {
				where.AND = [];
			}
			where.AND.push({
				InsurancePolicy: { agentFirstName: { equals: nameArr[0] } },
			});
			where.AND.push({
				InsurancePolicy: { agentLastName: { equals: nameArr[1] } },
			});
		}

		if (stateId) {
			if (!where.InsurancePolicy) {
				where.InsurancePolicy = {};
			}
			where.InsurancePolicy.stateId = {
				equals: stateId,
			};
		}

		if (type) {
			if (type === 'Class') {
				where.classPriceWeightingFactor = { gte: 0 };
			} else if (type === 'Component') {
				where.componentPriceWeightingFactor = { gte: 0 };
			}
		}

		if (salesEffectiveStartDate && salesEffectiveEndDate) {
			if (!where.AND) {
				where.AND = [];
			}
			where.AND.push({
				salesEffectiveDate: { gte: salesEffectiveStartDate },
			});
			where.AND.push({
				salesEffectiveDate: { lte: salesEffectiveEndDate },
			});
		} else if (salesEffectiveStartDate) {
			where.salesEffectiveDate = {
				gte: salesEffectiveStartDate,
			};
		} else if (salesEffectiveEndDate) {
			where.salesEffectiveDate = {
				lte: salesEffectiveEndDate,
			};
		}

		if (quarterStartDate && quarterEndDate) {
			if (!where.AND) {
				where.AND = [];
			}
			where.AND.push({
				quarterEndDate: { gte: quarterStartDate },
			});
			where.AND.push({
				quarterEndDate: {
					lte: DateTime.fromISO(quarterEndDate).endOf('quarter').toISODate(),
				},
			});
		} else if (quarterStartDate) {
			where.quarterEndDate = {
				gte: quarterStartDate,
			};
		} else if (quarterEndDate) {
			where.quarterEndDate = {
				lte: DateTime.fromISO(quarterEndDate).endOf('quarter').toISODate(),
			};
		}

		return where;
	}

	// Required due to Ember.js bug: https://github.com/emberjs/ember.js/issues/19582
	//@ts-ignore
	activate(transition: any) {
		transition.queryParamsOnly = false;
	}
}
