import Route from '@ember/routing/route';
import { gql, useQuery } from 'glimmer-apollo';
import { tracked } from 'tracked-built-ins';
import { FeedIngredientFilterDTO, Query } from 'vault-client/types/graphql-types';

// TODO: Add Sellers and Feed Orders to query
const GET_FEED_ORDERS = gql`
	query LivestockFeedOrders($feedIngredientsWhere: FeedIngredientFilterDTO!) {
		FeedIngredients(where: $feedIngredientsWhere) {
			id
			name
		}
	}
`;

export type GetFeedOrdersQuery = {
	__typename?: 'Query';
	FeedIngredients: Query['FeedIngredients'];
};

type GetFeedOrdersQueryVariables = {
	feedIngredientsWhere?: FeedIngredientFilterDTO;
};

export default class LivestockFeedOrdersRoute extends Route {
	templateName = 'livestock-feed/orders';

	queryParams = {
		startDate: { refreshModel: true },
		endDate: { refreshModel: true },
		selectedVendorId: { refreshModel: true },
		selectedFeedIngredientId: { refreshModel: true },
	};

	@tracked variables: GetFeedOrdersQueryVariables = {};

	getFeedOrders = useQuery<GetFeedOrdersQuery, GetFeedOrdersQueryVariables>(this, () => [GET_FEED_ORDERS, { variables: this.variables }]);

	generateFeedIngredientsWhere(businessId: string | null) {
		const where: FeedIngredientFilterDTO = {};

		if (businessId) {
			where.customerId = { equals: businessId };
		}

		where.FeedIngredientVersion = {
			isCurrent: {
				equals: true,
			},
		};

		return where;
	}
}
