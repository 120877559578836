import Route from '@ember/routing/route';
import { gql, useQuery } from 'glimmer-apollo';
import { tracked } from '@glimmer/tracking';
import { Query_LrpEndorsementArgs, LrpInsuranceEndorsement } from 'vault-client/types/graphql-types';

interface ModelParams {
	endorsement_id: string;
}

const GET_LRP_ENDORSEMENT_QUERY = gql`
	query LRPEndorsement($id: String!) {
		LrpEndorsement(id: $id) {
			id
			coverageEndDate
			coveragePrice
			headCount
			salesEffectiveDate
			targetWeightCwt
			insuredValue
			lengthInWeeks
			subsidyAmount
			totalPremiumAmount
			producerPremiumAmount
			InsurancePolicy {
				id
				producerName
				State {
					id
					abbreviation
				}
			}
		}
	}
`;

type GetLrpEndorsementQuery = {
	__typename?: 'Query';

	LrpEndorsement: LrpInsuranceEndorsement;
};

export default class LRPInsuranceEndorsementsShowRoute extends Route {
	@tracked errorMessage: string | null = null;
	@tracked variables: Query_LrpEndorsementArgs = {
		id: '',
		scopeId: '',
	};

	templateName: string = 'lrp-insurance-endorsements/show';

	getLrpEndorsement = useQuery<GetLrpEndorsementQuery, Query_LrpEndorsementArgs>(this, () => [
		GET_LRP_ENDORSEMENT_QUERY,
		{
			variables: this.variables,
			onComplete: () => {
				this.errorMessage = null;
			},
			onError: (error) => {
				this.errorMessage = 'There was an error. LRP endorsement was not retrieved.';
				console.error('Error while attempting to retrieve LRP endorsement.', error.message);
			},
		},
	]);

	async model(params: ModelParams) {
		this.variables = {
			id: params.endorsement_id,
		};

		await this.getLrpEndorsement.promise;

		return this.getLrpEndorsement;
	}
}
