import ExposureShowRoute, { FeedTransactionQueryArgs } from 'vault-client/routes/exposure/show';
import {
	DerivedDrpEndorsementFilterDTO,
	FutureFilterDTO,
	ProductFilterDTO,
	InsuranceEndorsementAllocationRatioFilterDTO,
	TypeOfInsuranceEndorsement,
	CurrentAllocationPositionFilterDTO,
	Query,
} from 'vault-client/types/graphql-types';
import { DateTime } from 'luxon';
import { InsuranceProducts } from 'vault-client/utils/exposure-utils';

type SortObj = {
	valuePath: string;
	isAscending: boolean;
};

interface QueryParams {
	slug: string;
	accountId?: string | null;
	exposureMonthStartDate?: string | null;
	exposureMonthEndDate?: string | null;
	sorts: SortObj[];
	id: string;
}

export default class BusinessesBusinessExposureRoute extends ExposureShowRoute {
	beforeModel() {
		this.scopeId = (this.paramsFor('businesses.business') as { id: string } | undefined)?.id ?? null;
	}

	async model(params: QueryParams) {
		const businessParams = this.paramsFor('businesses.business') as { business_id: string };

		const productWhere: ProductFilterDTO = {};
		const futureWhere: FutureFilterDTO = {};
		const positionWhere: CurrentAllocationPositionFilterDTO = {};
		const endorsementWhere: DerivedDrpEndorsementFilterDTO = {};
		const lgmEndorsementRatiosWhere: InsuranceEndorsementAllocationRatioFilterDTO = {
			InsuranceEndorsement: {
				type: {
					equals: TypeOfInsuranceEndorsement.Lgm,
				},
			},
		};

		const lrpEndorsementRatiosWhere: InsuranceEndorsementAllocationRatioFilterDTO = {
			InsuranceEndorsement: {
				type: {
					equals: TypeOfInsuranceEndorsement.Lrp,
				},
			},
		};

		if (businessParams.business_id) {
			endorsementWhere.InsurancePolicy = {
				customerId: {
					equals: businessParams.business_id,
				},
			};
			positionWhere.businessId = {
				equals: businessParams.business_id,
			};
		}

		if (params.slug) {
			productWhere.slug = { equals: params.slug };
			futureWhere.Product = {
				slug: { equals: params.slug },
			};
			positionWhere.Product = {
				slug: {
					equals: params.slug,
				},
			};

			lgmEndorsementRatiosWhere.InsuranceEndorsement = {
				...lgmEndorsementRatiosWhere.InsuranceEndorsement,
				AsLgmInsuranceEndorsement: {
					Product: {
						slug: {
							equals: params.slug,
						},
					},
				},
			};

			lrpEndorsementRatiosWhere.InsuranceEndorsement = {
				...lrpEndorsementRatiosWhere.InsuranceEndorsement,
				AsLrpInsuranceEndorsement: {
					commodityCode: {
						equals: InsuranceProducts.find((product) => product.slug === params.slug)?.code ?? null,
					},
				},
			};
		}

		if (params.accountId) {
			positionWhere.accountId = {
				equals: params.accountId,
			};
		}

		const exposureMonthStartDate = params.exposureMonthStartDate;
		const exposureMonthEndDate = params.exposureMonthEndDate;
		if (exposureMonthStartDate && exposureMonthEndDate) {
			if (!futureWhere.AND) {
				futureWhere.AND = [];
			}
			if (!endorsementWhere.AND) {
				endorsementWhere.AND = [];
			}
			if (!positionWhere.AND) {
				positionWhere.AND = [];
			}

			futureWhere.AND.push({
				displayExpiresAt: { gte: exposureMonthStartDate },
			});
			futureWhere.AND.push({
				displayExpiresAt: { lte: exposureMonthEndDate },
			});
			futureWhere.AND.push({
				isStandardContractSize: { equals: true },
			});
			endorsementWhere.AND.push({
				quarterStartDate: {
					gte: DateTime.fromISO(exposureMonthStartDate).startOf('quarter').toISODate(),
				},
			});
			endorsementWhere.AND.push({
				quarterStartDate: { lte: exposureMonthEndDate },
			});
			positionWhere.AND.push({
				effectiveHedgeDate: { gte: exposureMonthStartDate },
			});
			positionWhere.AND.push({
				effectiveHedgeDate: { lte: exposureMonthEndDate },
			});

			lgmEndorsementRatiosWhere.effectiveHedgeDate = {
				gte: exposureMonthStartDate,
				lte: exposureMonthEndDate,
			};

			lrpEndorsementRatiosWhere.effectiveHedgeDate = {
				gte: exposureMonthStartDate,
				lte: exposureMonthEndDate,
			};
		} else if (exposureMonthStartDate) {
			futureWhere.displayExpiresAt = {
				gte: exposureMonthStartDate,
			};
			endorsementWhere.quarterStartDate = {
				gte: DateTime.fromISO(exposureMonthStartDate).startOf('quarter').toISODate(),
			};
			positionWhere.effectiveHedgeDate = {
				gte: exposureMonthStartDate,
			};
			lgmEndorsementRatiosWhere.effectiveHedgeDate = {
				gte: exposureMonthStartDate,
			};
			lrpEndorsementRatiosWhere.effectiveHedgeDate = {
				gte: exposureMonthStartDate,
			};
		} else if (exposureMonthEndDate) {
			futureWhere.displayExpiresAt = {
				lte: exposureMonthEndDate,
			};
			endorsementWhere.quarterStartDate = {
				lte: DateTime.fromISO(exposureMonthEndDate).startOf('quarter').toISODate(),
			};
			positionWhere.effectiveHedgeDate = {
				lte: exposureMonthEndDate,
			};
			lgmEndorsementRatiosWhere.effectiveHedgeDate = {
				lte: exposureMonthEndDate,
			};
			lrpEndorsementRatiosWhere.effectiveHedgeDate = {
				lte: exposureMonthEndDate,
			};
		}

		const variables = {
			scopeId: businessParams.business_id,
			productWhere,
			futureWhere,
			positionWhere,
			endorsementWhere,
			lgmEndorsementRatiosWhere,
			lrpEndorsementRatiosWhere,
		};

		const feedTransactionVariables: FeedTransactionQueryArgs = {
			scopeId: businessParams.business_id,
			slug: params.slug,
			startDate: params.exposureMonthStartDate ?? undefined,
			endDate: params.exposureMonthEndDate ?? undefined,
		};

		const [generalResult, feedTransactionResult] = await Promise.all([
			this.apollo.watchQuery({ query: this.query, variables }),
			this.apollo.watchQuery({ query: this.feedTransactionQuery, variables: feedTransactionVariables }),
		]);

		const data = {
			Products: generalResult.Products,
			Futures: generalResult.Futures,
			AggregateDerivedDrpEndorsements: generalResult.AggregateDerivedDrpEndorsements,
			AggregateCurrentAllocationPositions: generalResult.AggregateCurrentAllocationPositions,
			LgmInsuranceEndorsements: generalResult.LgmInsuranceEndorsementAllocationRatios,
			LrpInsuranceEndorsements: generalResult.LrpInsuranceEndorsementAllocationRatios,
			FeedTransactions: feedTransactionResult.FeedTransactions,
		} as {
			Products: Query['Products'];
			Futures: Query['Futures'];
			AggregateDerivedDrpEndorsements: Query['AggregateDerivedDrpEndorsements'];
			AggregateCurrentAllocationPositions: Query['AggregateCurrentAllocationPositions'];
			LgmInsuranceEndorsements: Query['InsuranceEndorsementAllocationRatios'];
			LrpInsuranceEndorsements: Query['InsuranceEndorsementAllocationRatios'];
			FeedTransactions: Query['FeedTransactions'];
		};

		return data;
	}
}
