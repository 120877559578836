import Product from './product';
import {
	Future,
	Option,
	Swap,
	Swaption,
	Instrument as InstrumentInterface,
	InstrumentSymbolGroup,
	BasisInstrument,
	CashInstrument,
	PhysicalInstrument,
	SpreadInstrument,
} from 'vault-client/types/graphql-types';

export default class Instrument {
	id: string;
	name: string;

	symbolGroup: InstrumentSymbolGroup;

	Product: Product | null = null;

	constructor(
		instrument:
			| InstrumentInterface
			| Future
			| Option
			| Swap
			| Swaption
			| BasisInstrument
			| CashInstrument
			| PhysicalInstrument
			| SpreadInstrument
	) {
		this.id = instrument.id;
		this.name = instrument.name;
		this.symbolGroup = instrument.SymbolGroup;

		if (instrument.Product) {
			this.Product = new Product(instrument.Product);
		}
	}
}
