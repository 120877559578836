import Route from '@ember/routing/route';
import { ForecastedMilkProductionByMonthFilterDTO, ForecastedMilkProductionSetDTO, Query } from 'vault-client/types/graphql-types';
import { useQuery, gql, useMutation } from 'glimmer-apollo';
import { tracked } from '@glimmer/tracking';

interface modelParams {
	id: string;
	productionMonthStartDate: string;
	productionMonthEndDate: string;
}

const GET_PRODUCTION = gql`
	query AggregateForecastedMilkProduction(
		$where: ForecastedMilkProductionByMonthFilterDTO
		$locationId: String!
		$startDate: String
		$endDate: String
	) {
		AggregateForecastedMilkProductionByMonths(
			calc: {
				avg: { numberOfCows: true, averageDailyProductionPerCow: true }
				sum: { grossProduction: true, grossProteinProduction: true, grossButterfatProduction: true, grossOtherSolidsProduction: true }
			}
			groupBy: { date: true }
			where: $where
			scopeId: $locationId
			orderBy: { date: Asc }
		) {
			date
			sum {
				grossProduction
				grossProteinProduction
				grossButterfatProduction
				grossOtherSolidsProduction
			}
			avg {
				numberOfCows
				averageDailyProductionPerCow
			}
		}
		AggregateActualMilkProduction(
			calc: { sum: { grossProduction: true } }
			where: { date: { gte: $startDate, lte: $endDate } }
			groupBy: { firstDateOfMonth: true }
			scopeId: $locationId
		) {
			firstDateOfMonth
			sum {
				grossProduction
			}
		}
		Location(id: $locationId) {
			id
			CurrentUserPermissions {
				canWriteOperations
			}
		}
	}
`;

export type GetProductionQuery = {
	__typename?: 'Query';
	AggregateForecastedMilkProductionByMonths: Query['AggregateForecastedMilkProductionByMonths'];
	AggregateActualMilkProduction: Query['AggregateActualMilkProduction'];
	Location: Query['Location'];
};

type GetProductionQueryVariables = {
	where?: ForecastedMilkProductionByMonthFilterDTO;
	startDate?: string;
	endDate?: string;
	locationId: string;
};

const SET_PRODUCTION = gql`
	mutation setAverageForecastedMilkProduction($startDate: String!, $endDate: String!, $productionPerCow: ForecastedMilkProductionSetDTO) {
		setAverageForecastedMilkProduction(startDate: $startDate, endDate: $endDate, productionPerCow: $productionPerCow) {
			id
		}
	}
`;

type SetProductionMutation = {
	__typename?: 'Mutation';

	setAverageForecastedMilkProduction?: {
		__typename?: 'setAverageForecastedMilkProduction';
		id: string;
	} | null;
};

type SetProductionMutationVariables = {
	startDate: string;
	endDate: string;
	productionPerCow?: ForecastedMilkProductionSetDTO;
};

export default class LocationsLocationProductionRoute extends Route {
	@tracked variables: GetProductionQueryVariables = { locationId: 'none' };

	getProduction = useQuery<GetProductionQuery, GetProductionQueryVariables>(this, () => [GET_PRODUCTION, { variables: this.variables }]);

	setProduction = useMutation<SetProductionMutation, SetProductionMutationVariables>(this, () => [
		SET_PRODUCTION,
		{
			/* options */
		},
	]);

	generateWhere(productionMonthStartDate: string | null, productionMonthEndDate: string | null): ForecastedMilkProductionByMonthFilterDTO {
		const where: ForecastedMilkProductionByMonthFilterDTO = {};

		if (productionMonthStartDate && productionMonthEndDate) {
			where.date = {
				gte: productionMonthStartDate,
				lte: productionMonthEndDate,
			};
		} else if (productionMonthStartDate) {
			where.date = { gte: productionMonthStartDate };
		} else if (productionMonthEndDate) {
			where.date = { lte: productionMonthEndDate };
		}

		return where;
	}

	queryParams = {
		productionMonthStartDate: {
			refreshModel: true,
		},
		productionMonthEndDate: {
			refreshModel: true,
		},
	};

	async model(params: modelParams) {
		const locationParams = this.paramsFor('locations.location') as { id: string };

		this.variables = {
			where: this.generateWhere(params.productionMonthStartDate, params.productionMonthEndDate),
			startDate: params.productionMonthStartDate,
			endDate: params.productionMonthEndDate,
			locationId: locationParams.id,
		};

		await this.getProduction.promise;

		return this.getProduction;
	}
}
