import Plan from './plan';
import { DateTime } from 'luxon';
import Fill from 'vault-client/models/vgs/fill';
import { GrainFeedPlan, GrainFillOrder, GrainTargetOrder, TypeOfGrainOrder } from 'vault-client/types/graphql-types';

export default class FeedPlan extends Plan {
	id;
	feedYear;
	tons;
	startDate;
	FeedCategory;
	Customer;
	Fills;
	Targets;
	totalTonsFlatPriced;
	percentPriced;
	basisOnlyPurchased;
	basisExposure;
	percentBasisExposure;
	totalTonsOpen;
	percentTonsOpen;
	futuresOnlyPurchased;
	futuresExposure;
	percentFuturesExposure;
	type;

	constructor(feed: GrainFeedPlan) {
		super(feed);

		this.id = feed.id;
		this.feedYear = feed.feedYear;
		this.startDate = feed.startDate;
		this.tons = feed.tons;
		this.totalTonsFlatPriced = feed.totalTonsFlatPriced;
		this.percentPriced = feed.percentPriced;
		this.basisOnlyPurchased = feed.basisOnlyPurchased;
		this.basisExposure = feed.basisExposure;
		this.percentBasisExposure = feed.percentBasisExposure;
		this.totalTonsOpen = feed.totalTonsOpen;
		this.percentTonsOpen = feed.percentTonsOpen;
		this.futuresOnlyPurchased = feed.futuresOnlyPurchased;
		this.futuresExposure = feed.futuresExposure;
		this.percentFuturesExposure = feed.percentFuturesExposure;
		this.FeedCategory = feed.FeedCategory;
		this.Customer = feed.Customer ?? null;
		this.Fills = feed.Orders.filter((order): order is GrainFillOrder => order.type === TypeOfGrainOrder.Fill).map(
			(fill) => new Fill(fill, null, null, null, null, feed.tons, null)
		);
		this.Targets = feed.Orders.filter((order): order is GrainTargetOrder => order.type === TypeOfGrainOrder.Target);
		this.type = feed.type;
	}

	get feedPlanDisplayId() {
		const date = DateTime.fromISO(this.startDate);
		const monthYear = date.toFormat('MMM yyyy');

		if (this.FeedCategory && this.Customer) {
			return `${this.Customer.name} ${this.FeedCategory.name} ${monthYear}`;
		} else {
			return this.id;
		}
	}

	get feedMonth() {
		const date = DateTime.fromISO(this.startDate);
		const month = date.toFormat('MMM');
		return month;
	}

	get tonsFilled() {
		return this.Fills.reduce((acc, cur) => {
			return cur.bushels ? acc + cur.bushels : acc + 0;
		}, 0);
	}

	get openUsage() {
		return this.tons - this.tonsFilled;
	}

	get percentPurchased() {
		const tonsPurchased = this.Fills.reduce((acc, cur) => {
			return cur.bushels ? acc + cur.bushels : acc + 0;
		}, 0);

		if (this.tons === null || this.tons == 0) {
			return null;
		}

		return tonsPurchased / this.tons;
	}

	get pendingTargets() {
		const filteredOpenTargets = this.Targets.filter(
			(t) => t.status === 'New' || t.status === 'Pending' || t.status === 'Submitted' || t.status === 'Working'
		);

		return filteredOpenTargets.reduce((acc, cur) => {
			return cur.bushels ? acc + cur.bushels : acc + 0;
		}, 0);
	}

	get percentPendingTargets() {
		return this.pendingTargets / this.tons;
	}

	get percentOpen() {
		if (!this.tons) return 0;

		return (this.openUsage || 0) / this.tons;
	}
}
