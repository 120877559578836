import { DateTime } from 'luxon';

export default class FoundationFill {
	Buyer: any;
	Seller: any;
	Delivery: any;
	Location: any;
	Target: any;
	CropPlan: any;
	FeedPlan: any;
	FillActivity: any;

	basis: any;
	bushels: any;
	contract_number: any;
	created_at: any;
	created_by: any;
	delivery_month: any;
	_delivery_start_date: any;
	_delivery_end_date: any;
	fees: any;
	flat_price: any;
	flex_fee: any;
	future_price: any;
	futures_month: any;
	hta_fee: any;
	roll_fee: any;
	sales_type: any;
	spread_gain: any;

	id: any;

	constructor(fill: any) {
		this.Buyer = fill.Buyer;
		this.Seller = fill.Seller;
		this.Delivery = fill.Delivery;
		this.Location = fill.Location;
		this.Target = fill.Target;
		this.CropPlan = fill.CropPlan;
		this.FeedPlan = fill.FeedPlan;
		this.FillActivity = fill.FillActivity;

		this.basis = fill.basis;
		this.bushels = fill.bushels;
		this.contract_number = fill.contractNumber;
		this.created_at = fill.createdAt;
		this.created_by = fill.createdBy;
		this.delivery_month = fill.deliveryMonth;
		this._delivery_start_date = fill.deliveryStartDate;
		this._delivery_end_date = fill.deliveryEndDate;
		this.fees = fill.fees;
		this.flat_price = fill.flatPrice;
		this.flex_fee = fill.flexFee;
		this.future_price = fill.futurePrice;
		this.futures_month = fill.futuresMonth;
		this.hta_fee = fill.htaFee;
		this.roll_fee = fill.rollFee;
		this.sales_type = fill.salesType;
		this.spread_gain = fill.spreadGain;

		this.id = fill.id;
	}

	get deliveryStartDate() {
		return this._delivery_start_date ?? this.delivery_month;
	}

	get deliveryEndDate() {
		return this._delivery_end_date ?? DateTime.fromISO(this.delivery_month).endOf('month').toISODate();
	}

	get netPrice() {
		return this.future_price + this.basis + this.fees + this.spread_gain;
	}

	get grossRevenue() {
		return this.netPrice * this.bushels;
	}
}
