import Controller from '@ember/controller';
import query from 'vault-client/graphql/queries/users/index.graphql';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import UsersIndexQuery from 'vault-client/utils/users-index-query';
import resetVaultTableScroll from 'vault-client/utils/reset-vault-table-scroll';
import { ModelFrom } from 'vault-client/utils/type-utils';
import UsersIndexRoute from 'vault-client/routes/users';

export default class UsersIndexController extends Controller {
	@service permissions: any;
	declare model: ModelFrom<UsersIndexRoute>;

	queryParams = ['sorts'];

	itemsFn = (rows: any) => {
		return rows;
	};

	@tracked sorts = [];
	get columns() {
		const baseColumns = [
			{
				id: 'be568a81-f251-47ab-b9a8-f42222635647',
				name: 'Email',
				valuePath: 'email',
				minWidth: 225,
				textAlign: '',
				isSortable: true,
				cellComponent: 'String',
				isFixed: '',
				isVisible: true,
				linkRoute: 'users.show',
				linkModelPath: 'id',
			},
			{
				id: 'ffa14cea-86ba-4d00-93ff-3d96ac8f0d6e',
				name: 'First Name',
				valuePath: 'firstName',
				minWidth: 140,
				textAlign: '',
				isSortable: true,
				cellComponent: 'String',
				isFixed: '',
				isVisible: true,
			},
			{
				id: '96f16959-11f7-4d8d-bf80-066a8e84cd18',
				name: 'Last Name',
				valuePath: 'lastName',
				minWidth: 140,
				textAlign: '',
				isSortable: true,
				cellComponent: 'String',
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'e10715e9-f06e-47cb-b5c2-8dfdfd70f0aa',
				name: 'Phone Number',
				valuePath: 'phoneNumber',
				minWidth: 140,
				textAlign: '',
				isSortable: true,
				cellComponent: 'String',
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'c6dccec9-2820-47a4-bb59-7e3b6abd2aaf',
				name: 'Roles',
				valuePath: 'roles',
				minWidth: 100,
				textAlign: '',
				isSortable: true,
				cellComponent: 'String',
				isFixed: '',
				isVisible: true,
			},
		];
		return baseColumns;
	}

	get sortParams() {
		return this.sorts;
	}

	set sortParams(sorts) {
		this.sorts = sorts;
	}

	get userData() {
		return this.itemsFn(this.model.Users);
	}

	get query() {
		return query;
	}

	get formattedQueryParams() {
		const queryParams: {
			[key: string]: any;
		} = {};
		this.queryParams.forEach((param) => {
			if (param !== 'page') {
				// @ts-ignore
				queryParams[param] = this[param];
			}
		});

		// @ts-ignore
		return UsersIndexQuery(queryParams);
	}

	@action
	setTablePageState() {
		resetVaultTableScroll('users-table');
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'users-index-controller': UsersIndexController;
	}
}
