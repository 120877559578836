import CropPlansRoute from 'vault-client/routes/crop-plans';

type SortObj = {
	valuePath: string;
	isAscending: boolean;
};

interface QueryParams {
	agent?: string | null;
	aipId?: string | null;
	customerId?: string | null;
	quarterStartDate: string;
	quarterEndDate: string;
	salesEffectiveStartDate: string;
	salesEffectiveEndDate: string;
	stateId?: string | null;
	type?: string | null;
	page: number;
	size: number;
	sorts: SortObj[];
	id: string;
}

export default class BusinessesCropPlans extends CropPlansRoute {
	async model(params: QueryParams) {
		const businessParams = this.paramsFor('businesses.business') as { business_id: string };

		this.variables = {
			scopeId: businessParams.business_id,
			orderBy: params.sorts ? this.generateOrderBy(params.sorts) : null,
			offset: params.page * params.size,
			limit: params.size,
		};

		await this.getCropPlans.promise;

		return {
			getCropPlans: this.getCropPlans,
			variables: this.variables,
		};
	}
}
