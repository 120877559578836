const cropToUnderlyingProduct: { [key: string]: string | null | undefined } = {
	Corn: 'grain-corn',
	corn: 'grain-corn',
	Soybeans: 'grain-soybeans',
	soy: 'grain-soybeans',
	Wheat: 'grain-chicago-soft-red-winter-wheat',
	'Spring Wheat': 'grain-hard-red-spring-wheat',
};

export default cropToUnderlyingProduct;
