import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<span {{did-insert this.fetchAIPs}}>\n\n\t<PowerSelect\n\t\t@selected={{@selected}}\n\t\t@options={{this.AIPs}}\n\t\t@searchEnabled={{false}}\n\t\t@allowClear={{true}}\n\t\t@searchField='name'\n\t\t@placeholder='All AIPs'\n\t\t@onChange={{@onChange}}\n\t\t@renderInPlace={{@renderInPlace}}\n\t\tas |aip|\n\t>\n\t\t{{aip.name}}\n\t</PowerSelect>\n\n</span>", {"contents":"<span {{did-insert this.fetchAIPs}}>\n\n\t<PowerSelect\n\t\t@selected={{@selected}}\n\t\t@options={{this.AIPs}}\n\t\t@searchEnabled={{false}}\n\t\t@allowClear={{true}}\n\t\t@searchField='name'\n\t\t@placeholder='All AIPs'\n\t\t@onChange={{@onChange}}\n\t\t@renderInPlace={{@renderInPlace}}\n\t\tas |aip|\n\t>\n\t\t{{aip.name}}\n\t</PowerSelect>\n\n</span>","moduleName":"vault-client/components/aip-select.hbs","parseOptions":{"srcName":"vault-client/components/aip-select.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { queryManager } from 'ember-apollo-client';
import query from 'vault-client/graphql/queries/aip-select.graphql';

interface Args {
	selectedAIP: any;
	updateAIP: any;
}

export default class AipSelectComponent extends Component<Args> {
	@queryManager apollo: any;

	@tracked AIPs = [];
	@tracked _selectedAIP: any;

	get selectedAIP() {
		if (this._selectedAIP == null) {
			return this.AIPs.find(({ name }) => name == this.args.selectedAIP);
		} else {
			return this._selectedAIP;
		}
	}

	set selectedAIP(value) {
		this._selectedAIP = value;
	}

	@action
	async fetchAIPs() {
		const AIPs = (await this.apollo.watchQuery({ query })).AIPs;
		this.AIPs = AIPs.sortBy('name');
	}

	@action
	onChange(selected: any) {
		const updateAIP = this.args.updateAIP;

		updateAIP(selected);
	}
}
