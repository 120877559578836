import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { CellComponents, TableColumn } from 'vault-client/types/vault-table';
import { ModelFrom } from 'vault-client/utils/type-utils';
import route from 'vault-client/routes/vgs/settings/ingredients';
import PermissionsService from 'vault-client/services/permissions';

export default class VgsSettingsIngredients extends Controller {
	declare model: ModelFrom<route>;

	@service declare permissions: PermissionsService;
	@tracked sorts = [{ valuePath: 'name', isAscending: true }];

	get columns() {
		const baseColumns: TableColumn[] = [
			{
				id: 'acbf7589-c443-4dd2-a084-b5c9483434aa',
				name: 'ID',
				valuePath: 'id',
				minWidth: 80,
				textAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'f0ea9268-1e29-4fbf-999a-80ec3a06b889',
				name: 'Ingredients',
				valuePath: 'name',
				minWidth: 120,
				textAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
		];
		return baseColumns;
	}

	get rows() {
		return this.model.getIngredients.data?.FeedCategories;
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	interface Registry {
		'vgs/settings/ingredients': VgsSettingsIngredients;
	}
}
