import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { QueryResource, gql, useMutation } from 'glimmer-apollo';
import { GrainCropPlan, Mutation, Mutation_updateGrainCropPlanArgs } from 'vault-client/types/graphql-types';
import { getInvalidElements, isFormValid } from 'vault-client/utils/form-validation';

const UPDATE_GRAIN_CROP_PLAN = gql`
	mutation UpdateGrainCropPlan($data: GrainCropPlanUpdateDTO!, $id: String!) {
		updateGrainCropPlan(data: $data, id: $id) {
			id
		}
	}
`;

interface EditVgsCropPlanButtonArgs {
	model: QueryResource<any, any>;
	cropPlan: GrainCropPlan;
}

export default class EditVgsCropPlanButton extends Component<EditVgsCropPlanButtonArgs> {
	@tracked showModal = false;
	@tracked errorMessage: string | null = null;

	updateGrainCropPlan = useMutation<{ updateGrainCropPlan: Mutation['updateGrainCropPlan'] }, Mutation_updateGrainCropPlanArgs>(
		this,
		() => [
			UPDATE_GRAIN_CROP_PLAN,
			{
				onComplete: (): void => {
					this.errorMessage = null;
					this.args.model.refetch();
					this.closeModal();
				},
				onError: (error): void => {
					this.errorMessage = 'There was an error. Crop Plan did not update.';
					console.error('Error while attempting to edit Crop Plan', error.message);
				},
			},
		]
	);

	@tracked aph: string | null = this.args.cropPlan.aph.toString() ?? null;
	@tracked acres: string | null = this.args.cropPlan.acres.toString() ?? null;
	@tracked percentEnrolled: string | null = this.args.cropPlan.enrolledPercent
		? (this.args.cropPlan.enrolledPercent * 100).toString()
		: null;
	@tracked harvestBu: string | null = this.args.cropPlan.harvestBu?.toString() ?? null;
	@tracked storageBu: string | null = this.args.cropPlan.storageBu?.toString() ?? null;
	@tracked breakEven: string | null = this.args.cropPlan.breakEven?.toString() ?? null;
	@tracked goal: string | null = this.args.cropPlan.goal?.toString() ?? null;

	get submitError() {
		return !!this.errorMessage;
	}

	@action
	openModal() {
		this.showModal = true;
		this.resetModal();
	}

	@action
	closeModal() {
		this.showModal = false;
		this.errorMessage = null;
		this.resetModal();
	}

	@action
	resetModal() {
		this.aph = this.args.cropPlan.aph.toString();
		this.acres = this.args.cropPlan.acres.toString();
		this.percentEnrolled = this.args.cropPlan.enrolledPercent ? (this.args.cropPlan.enrolledPercent * 100).toString() : null;
		this.harvestBu = this.args.cropPlan.harvestBu?.toString() ?? null;
		this.storageBu = this.args.cropPlan.storageBu?.toString() ?? null;
		this.breakEven = this.args.cropPlan.breakEven?.toString() ?? null;
		this.goal = this.args.cropPlan.goal?.toString() ?? null;
	}

	@action
	async submit() {
		if (isFormValid(document)) {
			const variables: Mutation_updateGrainCropPlanArgs = {
				id: this.args.cropPlan.id,
				data: {
					acres: this.acres ? parseFloat(this.acres) : null,
					aph: this.aph ? parseFloat(this.aph) : null,
					breakEven: this.breakEven ? parseFloat(this.breakEven) : null,
					enrolledPercent: this.percentEnrolled ? parseFloat(this.percentEnrolled) / 100 : null,
					goal: this.goal ? parseFloat(this.goal) : null,
					harvestBu: this.harvestBu ? parseFloat(this.harvestBu) : null,
					storageBu: this.storageBu ? parseFloat(this.storageBu) : null,
				},
			};

			this.updateGrainCropPlan.mutate(variables);
		} else {
			getInvalidElements(document);
		}
	}
}
