import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div>\n\t<div class='mb-8' id={{this.legendId}}></div>\n\t<div class='relative'>\n\t\t<BarChart\n\t\t\t@id={{this.chartId}}\n\t\t\t@data={{@data}}\n\t\t\t@chartData={{this.chartData}}\n\t\t\t@options={{this.chartOptions}}\n\t\t\t@plugins={{this.plugins}}\n\t\t\t@updateChart={{this.updateChart}}\n\t\t/>\n\t</div>\n</div>", {"contents":"<div>\n\t<div class='mb-8' id={{this.legendId}}></div>\n\t<div class='relative'>\n\t\t<BarChart\n\t\t\t@id={{this.chartId}}\n\t\t\t@data={{@data}}\n\t\t\t@chartData={{this.chartData}}\n\t\t\t@options={{this.chartOptions}}\n\t\t\t@plugins={{this.plugins}}\n\t\t\t@updateChart={{this.updateChart}}\n\t\t/>\n\t</div>\n</div>","moduleName":"vault-client/components/hedge-pl-per-unit-chart.hbs","parseOptions":{"srcName":"vault-client/components/hedge-pl-per-unit-chart.hbs"}});
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import Component from '@glimmer/component';
import { Chart, ChartData, ChartDataset, ChartOptions } from 'chart.js';
import arraysEqual from 'vault-client/utils/arrays-equal';
import { CustomTooltipOptions, extendLineChartToEdgesPlugin, getCustomLegend, getCustomTooltip } from 'vault-client/utils/chart-utils';
import getCSSVariable from 'vault-client/utils/get-css-variable';

interface HedgePriceChartArgs {
	data: {
		CurrentPrice: number;
		Brokerage?: number;
		DRP?: number;
		LRP?: number;
		LGM?: number;
	};
	fiftyTwoWeekHigh?: number;
	fiftyTwoWeekLow?: number;
	chartId?: string;
	fractionDigits?: number;
	displayFactor?: number;
	isNaturallyLong?: boolean;
}

type CustomDataType = (number[] | number)[];
type DataLabel = 'Current Price' | 'Revenue' | 'Brokerage' | 'Net Hedge' | 'LRP' | 'LGM' | 'DRP' | 'Physical';

const LABEL_ORDER: DataLabel[] = ['Current Price', 'Revenue', 'Brokerage', 'LRP', 'LGM', 'DRP', 'Physical', 'Net Hedge'];

export default class HedgePriceChart extends Component<HedgePriceChartArgs> {
	guid = guidFor(this);

	BAR_CHART_COLOR_MAP = {
		// Standard
		'Current Price': getCSSVariable('--brand-interactive-blue-70'),
		Revenue: getCSSVariable('--brand-orange-40'),
		Brokerage: getCSSVariable('--brand-lime-40'),
		'Net Hedge': getCSSVariable('--brand-lemon-40'),

		// Swine Related
		LRP: getCSSVariable('--brand-teal-60'),
		LGM: getCSSVariable('--brand-purple-50'),

		// Milk Related
		DRP: getCSSVariable('--brand-teal-60'),

		// Feed Related
		Physical: getCSSVariable('--brand-teal-60'),
	};

	get displayFactor() {
		return this.args.displayFactor ?? 1;
	}

	get chartId(): string {
		return this.args.chartId ?? `hedge-price-chart-${this.guid}`;
	}

	get legendId(): string {
		return this.chartId + '-legend';
	}

	get labels(): DataLabel[] {
		const dataLabelsPassedIn = Object.keys(this.args.data ?? {});
		return [
			'Current Price',
			...LABEL_ORDER.filter((label) => {
				return dataLabelsPassedIn.includes(label);
			}),
			'Net Hedge',
		];
	}

	get chartMin() {
		// This is determined by the minimum value that will be displayed on the chart, utilizing the waterfall logic
		const allDataPoints = this.chartData.datasets.flatMap((dataset) => dataset.data.flat());
		return Math.min(...allDataPoints);
	}

	get netHedge() {
		let hasHedges = false;
		const netHedge = Object.entries(this.args.data ?? {}).reduce((acc, [key, val]) => {
			if (key !== 'CurrentPrice' && val != null && val !== 0) {
				hasHedges = true;
			}

			if (key === 'CurrentPrice') {
				// Apply the display factor to the current price
				acc += val * this.displayFactor;
			} else if (key === 'Brokerage') {
				// If the exposure is naturally short, we should subtract the brokerage revenue.
				if (this.args.isNaturallyLong === false) {
					acc -= val;
				} else {
					acc += val;
				}
			} else {
				acc += val;
			}

			return acc;
		}, 0);

		return hasHedges ? netHedge : 0;
	}

	get rawDataValues(): Record<string, number | null> {
		const { CurrentPrice, ...rest } = this.args.data;
		return {
			'Current Price': CurrentPrice * this.displayFactor,
			...rest,
			'1 Year High': this.args.fiftyTwoWeekHigh ? this.args.fiftyTwoWeekHigh * this.displayFactor : null,
			'1 Year Low': this.args.fiftyTwoWeekLow ? this.args.fiftyTwoWeekLow * this.displayFactor : null,
			'Net Hedge': this.netHedge,
		};
	}

	get chartData(): ChartData<'bar' | 'line', CustomDataType> {
		const labels: DataLabel[] = this.labels;
		const rawDataValues = this.rawDataValues;
		let previousValue = 0;

		const datasets: ChartDataset<'bar' | 'line', CustomDataType>[] = Object.keys(this.args.data ?? {}).map(
			(key: keyof HedgePriceChartArgs['data']) => {
				const label: DataLabel = key === 'CurrentPrice' ? 'Current Price' : key;
				const value = rawDataValues[label] ?? 0;

				const offset = previousValue;
				previousValue += value;

				return {
					label,
					data: labels.map((orderedLabel) => (orderedLabel === label ? [offset, offset + value] : 0)),
					type: 'bar',
					backgroundColor: this.BAR_CHART_COLOR_MAP[label],
					borderColor: this.BAR_CHART_COLOR_MAP[label],
					grouped: false,
				};
			}
		);

		const netHedgeDataset: ChartDataset<'bar', CustomDataType> = {
			label: 'Net Hedge',
			data: labels.map((orderedLabel) => (orderedLabel === 'Net Hedge' ? rawDataValues['Net Hedge'] ?? 0 : 0)),
			type: 'bar',
			backgroundColor: this.BAR_CHART_COLOR_MAP['Net Hedge'],
			borderColor: this.BAR_CHART_COLOR_MAP['Net Hedge'],
		};

		datasets.push(netHedgeDataset);

		const fiftyTwoWeekHigh = rawDataValues['1 Year High'];
		const fiftyTwoWeekLow = rawDataValues['1 Year Low'];

		if (fiftyTwoWeekLow) {
			const fiftyTwoWeekLowDataset: ChartDataset<'line', CustomDataType> = {
				type: 'line',
				label: '1 Year Low',
				data: Array.from({ length: labels.length }, () => fiftyTwoWeekLow),
				backgroundColor: getCSSVariable('--brand-error-50'),
				borderColor: getCSSVariable('--brand-error-50'),
				pointRadius: 0,
				pointHoverRadius: 0,
				order: -2,
				// Pulls this line out of the stack logic
				stack: 'do-not-stack-1',
			};

			datasets.push(fiftyTwoWeekLowDataset);
		}

		if (fiftyTwoWeekHigh) {
			const fiftyTwoWeekHighDataset: ChartDataset<'line', CustomDataType> = {
				type: 'line',
				label: '1 Year High',
				data: Array.from({ length: labels.length }, () => fiftyTwoWeekHigh),
				backgroundColor: getCSSVariable('--brand-success-40'),
				borderColor: getCSSVariable('--brand-success-40'),
				pointRadius: 0,
				pointHoverRadius: 0,
				order: -1,
				// Pulls this line out of the stack logic
				stack: 'do-not-stack-2',
			};

			datasets.push(fiftyTwoWeekHighDataset);
		}

		return {
			labels: this.labels,
			datasets,
		};
	}

	get chartOptions(): ChartOptions<'bar'> {
		const tooltipOptions: CustomTooltipOptions = {
			valueFormatter: (value: number | string | null) => {
				if (value == null) return '-';
				if (typeof value === 'string') return value;

				return this.formatPriceValue(value);
			},
			titleFormatter: (_title: string) => '',
			dataPointFilter: (dataPoint) => {
				// Only show the 1 year high and low plus the dataset correlated to the x position of the tooltip
				const label = dataPoint.dataset.label;
				const index = dataPoint.dataIndex;
				if (label === '1 Year Low' || label === '1 Year High') {
					return true;
				}

				return label === this.labels[index];
			},
			valueExtractor: (dataPoint) => {
				const label = dataPoint.dataset.label;
				if (!label) return null;
				return this.rawDataValues[label] ?? 0;
			},
		};

		return {
			maintainAspectRatio: false,
			responsive: true,
			clip: false,
			interaction: {
				intersect: false,
				mode: 'nearest',
				axis: 'x',
			},
			scales: {
				x: {
					type: 'category',
					labels: this.chartData.labels as string[],
					grid: {
						display: false,
					},
					stacked: false,
				},
				y: {
					stacked: true,
					ticks: {
						callback: this.formatPriceValue,
					},
					min: this.chartMin,
				},
			},
			plugins: {
				legend: {
					display: false,
				},
				tooltip: {
					enabled: false,
					external: getCustomTooltip(tooltipOptions),
				},
			},
		};
	}

	get plugins() {
		const chartId = this.chartId;
		const legendId = this.legendId;

		return [
			{
				afterUpdate: getCustomLegend(chartId, legendId, this.formatPriceValue, this.legendValueExtractor),
			},
			extendLineChartToEdgesPlugin,
		];
	}

	@action
	updateChart(chart: Chart<'bar' | 'line', CustomDataType>) {
		const newDatasetLabels = this.chartData.datasets.map((set) => set.label).sort();
		const currentDatasetLabels = chart.data.datasets.map((set) => set.label).sort();

		if (arraysEqual(newDatasetLabels, currentDatasetLabels)) {
			chart.data.datasets.forEach((dataset) => {
				dataset.data = this.chartData.datasets.find((set) => set.label === dataset.label)?.data ?? [];
			});
		} else {
			chart.data = this.chartData;
		}

		chart.options = this.chartOptions;
		chart.update('none');
	}

	@action
	formatPriceValue(value: number | string | null) {
		if (value == null) {
			return '-';
		} else if (typeof value === 'string') {
			return value;
		}

		return new Intl.NumberFormat('en-US', {
			style: 'currency',
			currency: 'USD',
			maximumFractionDigits: this.args.fractionDigits ?? 2,
			minimumFractionDigits: this.args.fractionDigits ?? 2,
		}).format(value);
	}

	@action
	legendValueExtractor(dataset: ChartDataset) {
		const label = dataset.label;
		if (!label) return null;
		return this.rawDataValues[label] ?? 0;
	}
}
