import Controller from '@ember/controller';
import { CellComponents, TableColumn } from 'vault-client/types/vault-table';
import { tracked } from '@glimmer/tracking';
import { DateTime } from 'luxon';
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { SwineLivestockPopulationForecastByWeek } from 'vault-client/types/graphql-types';
import { ModelFrom } from 'vault-client/utils/type-utils';
import BusinessesBusinessPigCohortsRoute from 'vault-client/routes/businesses/business/pig-cohorts';

interface Cohort {
	date?: string | undefined;
	weeks?: SwineLivestockPopulationForecastByWeek | undefined;
	idx?: number;
}
export default class BusinessesBusinessPigCohorts extends Controller {
	pigCohortsRoutePath: string = 'businesses.business.pig-cohorts';

	declare model: ModelFrom<BusinessesBusinessPigCohortsRoute>;
	queryParams = ['startDate', 'endDate'];
	weekArray = [];

	@tracked startDate: string = DateTime.local().toISODate();
	@tracked endDate: string = DateTime.local().plus({ weeks: 52 }).toISODate();
	@tracked _columns: TableColumn[] | null = [
		{
			id: '6f4c1c60-7c2f-42f5-866c-1e467e98d4f4',
			name: 'Date',
			valuePath: 'date',
			minWidth: 80,
			maxWidth: 160,
			cellComponent: CellComponents.IntlDateTimeFormat,
			textAlign: 'left',
			isSortable: false,
			isFixed: 'left',
			isVisible: true,
		},
		{
			id: 'ee12c44a-d89c-4a6d-bf80-59e3a0727d48',
			name: 'Age in Weeks',
			cellComponent: CellComponents.String,
			isVisible: true,
			textAlign: 'left',
			isFixed: '',
			subcolumns: this.weekAgeSubColumns,
		},
	];

	get columns() {
		return this._columns;
	}

	get rows() {
		const weeks: SwineLivestockPopulationForecastByWeek[] | undefined = this.model.cohorts.data?.SwineLivestockPopulationForecastsByWeek;
		const weekDateArray: Array<any> = [];
		const filteredWeeks = weeks?.filter((week: any) => {
			return week.date >= this.startDate && week.date <= this.endDate;
		});

		// Create an array of dates without duplicates to determine rows
		filteredWeeks?.forEach((week: any) => {
			if (!weekDateArray.includes(week.date)) {
				weekDateArray.push(week.date);
			}
		});

		const rowsArray: Cohort[] = [];

		// Create objects for each row from the weekDateArray
		weekDateArray.forEach((week: SwineLivestockPopulationForecastByWeek) => {
			// filter for matched dates and build new object with weeks
			const matchedDates = filteredWeeks?.filter((date: any) => {
				return date.date === week;
			});
			const cohortObj: Cohort = {};
			const weeks: any = {};
			matchedDates?.forEach((cohort: SwineLivestockPopulationForecastByWeek, idx: number) => {
				if (idx === 0) {
					cohortObj.date = cohort.date;
				}

				weeks[`${cohort.ageInWeeks}`] = cohort;
				cohortObj.weeks = weeks;
			});
			rowsArray.push(cohortObj);
		});
		return rowsArray;
	}

	get dateParams() {
		return {
			startDate: this.startDate,
			endDate: this.endDate,
		};
	}

	dateRangeOptions = [
		{
			displayName: 'All Weeks',
			startDate: '1900-01-01',
			endDate: '2999-12-31',
		},
		{
			displayName: 'Next 4 Weeks',
			startDate: DateTime.local().toISODate(),
			endDate: DateTime.local().plus({ weeks: 4 }).toISODate(),
		},
		{
			displayName: 'Next 12 Weeks',
			startDate: DateTime.local().toISODate(),
			endDate: DateTime.local().plus({ weeks: 12 }).toISODate(),
		},
		{
			displayName: 'Next 26 Weeks',
			startDate: DateTime.local().toISODate(),
			endDate: DateTime.local().plus({ weeks: 26 }).toISODate(),
		},
		{
			displayName: 'Next 52 Weeks',
			startDate: DateTime.local().toISODate(),
			endDate: DateTime.local().plus({ weeks: 52 }).toISODate(),
		},
	];

	get weekAgeSubColumns() {
		const subColumnsArray = [];
		const weeks: SwineLivestockPopulationForecastByWeek[] | undefined = this.model.cohorts.data?.SwineLivestockPopulationForecastsByWeek;

		let maxWeeks = 0;

		weeks?.forEach((week: any) => {
			maxWeeks = Math.max(maxWeeks, week.ageInWeeks);
		});

		for (let i = 1; i <= maxWeeks; i++) {
			const columnObj = {
				id: weeks ? `${guidFor(weeks[i])}` : `cohort-subcolumn${i}`,
				name: `Week ${i}`,
				valuePath: `weeks.${i}.endQuantity`,
				cellComponent: CellComponents.IntlNumberFormat,
				width: 90,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
				linkRoute: 'businesses.business.pig-cohort',
				linkModelPath: `weeks.${i}.dob`,
			} as TableColumn;

			subColumnsArray.push(columnObj);
		}

		return subColumnsArray;
	}

	@action
	setDateRange(value: { startDate: string; endDate: string }) {
		if (value.startDate && value.endDate) {
			this.startDate = value.startDate;
			this.endDate = value.endDate;
		}
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	interface Registry {
		'businesses/business/pig-cohorts': BusinessesBusinessPigCohorts;
	}
}
