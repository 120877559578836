import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { htmlSafe } from '@ember/template';
import { debounce } from '@ember/runloop';

interface UiComboboxComponentArgs {
	uid: string;
	label: string;
	placeholder: string;
	inputType: string;
	step: string;
	defaultValue?: null | string;
	currentValue?: string | null;
	optionsSize?: number;
	width?: string;
	noOptions?: boolean;
	// eslint-disable-next-line no-unused-vars
	onChange: (arg1: string | null) => any;
}

export default class UiComboboxComponent extends Component<UiComboboxComponentArgs> {
	_width = 'w-full';
	_optionsSize = 6;
	@tracked currentInput: string | null = null;
	@tracked optionsOpen = false;

	constructor(owner: unknown, args: UiComboboxComponentArgs) {
		super(owner, args);

		if (this.args.currentValue) {
			this.currentInput = this.args.currentValue;
		} else if (this.args.defaultValue) {
			this.currentInput = this.args.defaultValue;
		}
	}

	get width() {
		if (this.args.width) {
			return htmlSafe(this.args.width);
		} else {
			return htmlSafe(this._width);
		}
	}

	get optionsSize() {
		return this.args.optionsSize ? this.args.optionsSize : this._optionsSize;
	}

	@action
	handleComboOnSelect(e: Event) {
		if (e) {
			this.currentInput = (e.target as HTMLSelectElement).value;
			this.args.onChange(this.currentInput);
		}
	}

	setCurrentInput(e: Event) {
		this.currentInput = (e.target as HTMLInputElement).value;
		this.args.onChange(this.currentInput);
	}

	@action
	handleInput(e: Event) {
		debounce(this, this.setCurrentInput, e, 500);
	}

	@action
	handleKeyDown(event: KeyboardEvent) {
		if (event.key === 'Enter') {
			event.preventDefault();
			(event.srcElement as HTMLElement)?.blur();
			this.closeOptions();
			this.focusComboboxOptions();
		}
	}

	@action
	focusComboboxOptions() {
		if (this.optionsOpen && !this.args.noOptions) {
			document.getElementById(`${this.args.uid}-options`)?.focus();
		} else {
			document.getElementById(`${this.args.uid}-button`)?.focus();
		}
	}

	@action
	toggleOptions() {
		this.optionsOpen = !this.optionsOpen;
	}

	@action
	closeOptions() {
		this.optionsOpen = false;
	}

	@action
	handleSelectClick() {
		this.closeOptions();
		this.focusComboboxOptions();
	}
}
