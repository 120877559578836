import Route from '@ember/routing/route';
import { tracked } from '@glimmer/tracking';
import { gql, useQuery } from 'glimmer-apollo';
import { LgmInsuranceEndorsement } from 'vault-client/types/graphql-types';

interface ModelParams {
	endorsement_id: string;
}
export const GET_LGM_INSURANCE_ENDORSEMENT = gql`
	query LgmInsuranceEndorsement($lgmInsuranceEndorsementId: String!) {
		LgmInsuranceEndorsement(id: $lgmInsuranceEndorsementId) {
			totalPremiumAmount
			totalExpectedGrossMargin
			salesEffectiveDate
			id
			liability
			subsidyAmount
			coverageMonths
			startDate
			endDate
			producerPremiumAmount
			InsurancePolicy {
				stateId
				producerName
				aipId
				agentFullName
				State {
					name
					id
					abbreviation
				}
				id
				AIP {
					name
					id
				}
			}
		}
	}
`;

type GetLgmInsuranceEndorsement = {
	__typename?: 'Query';
	LgmInsuranceEndorsement: LgmInsuranceEndorsement;
};
interface LgmQueryArgs {
	lgmInsuranceEndorsementId?: string | null | undefined;
}
export default class LgmInsuranceEndorsementsShow extends Route {
	templateName: string = 'lgm-insurance-endorsements/show';

	@tracked variables = { lgmInsuranceEndorsementId: '' };
	getLgmInsuranceEndorsement = useQuery<GetLgmInsuranceEndorsement, LgmQueryArgs>(this, () => [
		GET_LGM_INSURANCE_ENDORSEMENT,
		{ variables: this.variables },
	]);

	async model(params: ModelParams) {
		this.variables = {
			lgmInsuranceEndorsementId: params.endorsement_id,
		};

		await this.getLgmInsuranceEndorsement.promise;

		return { getLgmInsuranceEndorsement: this.getLgmInsuranceEndorsement };
	}
}
