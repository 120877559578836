import DRPInsuranceEndorsementsIndexRoute, {
	GET_INSURANCE_ENDORSEMENTS,
	GetInsuranceEndorsementsQuery,
} from 'vault-client/routes/drp-insurance-endorsements';
import { Query_InsuranceEndorsementsArgs } from 'vault-client/types/graphql-types';
import { useQuery } from 'glimmer-apollo';
import { tracked } from '@glimmer/tracking';

type SortObj = {
	valuePath: string;
	isAscending: boolean;
};

interface QueryParams {
	agent?: string | null;
	aipId?: string | null;
	customerId?: string | null;
	quarterStartDate: string;
	quarterEndDate: string;
	salesEffectiveStartDate: string;
	salesEffectiveEndDate: string;
	stateId?: string | null;
	type?: string | null;
	page: number;
	size: number;
	sorts: SortObj[];
	id: string;
}

export default class OrganizationsDrpInsuranceEndorsements extends DRPInsuranceEndorsementsIndexRoute {
	@tracked variables = {};
	getInsuranceEndorsements = useQuery<GetInsuranceEndorsementsQuery, Query_InsuranceEndorsementsArgs>(this, () => [
		GET_INSURANCE_ENDORSEMENTS,
		{
			variables: this.variables,
			onComplete: () => {
				this.errorMessage = null;
			},
			onError: (error) => {
				this.errorMessage = 'There was an error. Insurance policies were not retrieved.';
				console.error('Error while attempting to retrieve insurance policies.', error.message);
			},
		},
	]);

	async model(params: QueryParams) {
		const organizationParams = this.paramsFor('organizations.organization') as { id: string };
		this.variables = {
			where: this.generateWhere(
				organizationParams.id,
				null,
				params.aipId,
				params.type,
				params.agent,
				params.stateId,
				params.salesEffectiveStartDate,
				params.salesEffectiveEndDate,
				params.quarterStartDate,
				params.quarterEndDate
			),
			orderBy: this.generateOrderBy(params.sorts),
			offset: params.page * params.size,
			limit: params.size,
		};

		await this.getInsuranceEndorsements.promise;

		return {
			getInsuranceEndorsements: this.getInsuranceEndorsements,
			variables: this.variables,
		};
	}
}
