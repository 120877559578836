/* eslint-disable ember/no-empty-glimmer-component-classes */
import Component from '@glimmer/component';
interface UiPageHeaderActionsArgs {}

export default class UiPageHeaderActions extends Component<UiPageHeaderActionsArgs> {
	get actionsWrapper(): HTMLElement | null {
		return document.getElementById('ui-page-header-actions');
	}

	get childButtons(): HTMLButtonElement[] {
		if (!this.actionsWrapper) return [];

		return [...this.actionsWrapper.getElementsByTagName('button')];
	}
}
