function roundTo(num: number, decimals: number) {
	const x = 10 ** decimals;
	return Math.round((num + Number.EPSILON) * x) / x;
}

function roundPercentagesToWholeLRM(percentages: number[]) {
	// Round the given percentages so that they add up to 1 using the Largest Remainder Method
	// https://en.wikipedia.org/wiki/Largest_remainder_method

	const adjustedPercentages = percentages.map((p) => p * 100);

	const approxDecimals = adjustedPercentages.map((v, i) => {
		return {
			index: i,
			decimal: v % 1,
		};
	});

	approxDecimals.sortBy('decimal');

	const floorPercentages = adjustedPercentages.map((v) => Math.floor(v));
	const floorSum = floorPercentages.reduce((a, b) => a + b, 0);
	const remainder = 100 - floorSum;
	let approxDecimalIndex = 0;

	for (let i = 0; i < Math.abs(remainder); i++) {
		const highestDecimalObject = approxDecimalIndex <= approxDecimals.length - 1 ? approxDecimals[approxDecimalIndex++] : approxDecimals[0];

		if (highestDecimalObject) {
			const index = highestDecimalObject.index;

			if (remainder > 0) {
				floorPercentages[index] += 1;
			} else {
				floorPercentages[index] -= 1;
			}
		}
	}

	return floorPercentages.map((v) => v / 100);
}

export { roundTo, roundPercentagesToWholeLRM };
