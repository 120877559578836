import SearchFilterComponent from 'vault-client/components/vault/ui-search-filter';
import { queryManager } from 'ember-apollo-client';
import query from 'vault-client/graphql/queries/instrument-get.graphql';
import { action } from '@ember/object';

export default class InstrumentIdFilterComponent extends SearchFilterComponent {
	@queryManager apollo: any;

	@action
	async fetchItem() {
		let value;
		if (this.args.filterValues.filterValue) {
			const variables = { id: this.args.filterValues.filterValue };
			value = (await this.apollo.watchQuery({ query, variables })).Instrument.name;
			this._value = value ?? this.args.filterValues.filterValue;
		} else {
			this._value = 'N/A';
		}
	}
}
