import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { queryManager } from 'ember-apollo-client';
import query from 'vault-client/graphql/queries/reports/unmapped-insurance-policies.graphql';
import searchQuery from 'vault-client/graphql/queries/search.graphql';
import agentFuzzyQuery from 'vault-client/graphql/queries/agent-fuzzy-match.graphql';
import stateFuzzyQuery from 'vault-client/graphql/queries/state-fuzzy-match.graphql';
import getFilterDisplayProperty from 'vault-client/utils/get-filter-display-property';
import { InsurancePolicy, ProducerAndPolicyFilterDTO, State, StateFilterDTO } from 'vault-client/types/graphql-types';
import { SortObj } from 'vault-client/types/vault-table';
import resetVaultTableScroll from 'vault-client/utils/reset-vault-table-scroll';
import { ModelFrom } from 'vault-client/utils/type-utils';
import OrganizationsOrganizationUnmappedInsurancePoliciesRoute from 'vault-client/routes/organizations/organization/unmapped-insurance-policies';
import BusinessesBusinessUnmappedInsurancePoliciesRoute from 'vault-client/routes/businesses/business/unmapped-insurance-policies';

interface SearchFilterIdentifiers {
	agent: string | null;
	aipId: string | null;
	policyNumber: string | null;
	stateId: string | null;
}

type SearchFilterIdentifier = keyof SearchFilterIdentifiers;

function isSearchFilterIdentifier(key: unknown): key is SearchFilterIdentifier {
	const searchFilterIdentifiers = ['agent', 'aipId', 'policyNumber', 'stateId'];
	if (typeof key === 'string' && searchFilterIdentifiers.includes(key)) {
		return true;
	}
	return false;
}
interface Agent {
	agentFirstName: string;
	agentLastName: string;
}

interface searchResult {
	id?: string;
	name?: string;
	type: string;
}

export default class UnmappedInsurancePoliciesController extends Controller {
	queryParams = ['agent', 'aipId', 'page', 'policyNumber', 'stateId', 'sorts', 'size'];

	declare model:
		| ModelFrom<BusinessesBusinessUnmappedInsurancePoliciesRoute>
		| ModelFrom<OrganizationsOrganizationUnmappedInsurancePoliciesRoute>;
	@queryManager apollo: any;

	size = 100;

	@tracked agent: string | null = null;
	@tracked aipId: string | null = null;
	@tracked page = 0;
	@tracked policyNumber: string | null = null;
	@tracked stateId: string | null = null;
	@tracked currentScope = '';
	@tracked sorts: SortObj[] = [{ valuePath: 'policyNumber', isAscending: true }];

	reportsRoutePath: string = 'reports';
	insurancePolicyRoutePath: string = 'insurance-policies.show';

	get columns() {
		const baseColumns = [
			{
				id: 'd5b5214d-eee0-4eb0-8944-64c5ff81123f',
				name: 'Policy Number',
				valuePath: 'policyNumber',
				minWidth: 150,
				textAlign: 'left',
				isSortable: true,
				cellComponent: 'String',
				isFixed: '',
				isVisible: true,
				linkRoute: this.insurancePolicyRoutePath,
				linkModelPath: 'id',
			},
			{
				id: 'c5c6999e-08df-431f-ad72-e8910020c6be',
				name: 'Producer',
				valuePath: 'producerName',
				minWidth: 180,
				textAlign: 'left',
				isSortable: true,
				cellComponent: 'String',
				isFixed: '',
				isVisible: true,
			},
			{
				id: '3ee15717-f972-4c4c-8631-957e78057be9',
				name: 'Type',
				valuePath: 'programType',
				minWidth: 80,
				textAlign: 'left',
				isSortable: false,
				cellComponent: 'String',
				isFixed: '',
				isVisible: true,
			},
			{
				id: '9616ddcd-3388-41e4-a30d-98ef11062e44',
				name: 'State',
				valuePath: 'State.abbreviation',
				minWidth: 80,
				textAlign: 'left',
				isSortable: false,
				cellComponent: 'String',
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'e358aedc-596c-4b2f-b66f-65071f6559b3',
				name: 'AIP',
				valuePath: 'AIP.name',
				minWidth: 120,
				textAlign: 'left',
				isSortable: false,
				cellComponent: 'String',
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'e98239ac-fefe-46a8-89c7-5c928e418762',
				name: 'Agent',
				valuePath: '',
				minWidth: 105,
				textAlign: '',
				isSortable: false,
				cellComponent: 'String',
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: '5079d69a-7d65-44c5-b2a4-77267b89393a',
						name: 'First Name',
						valuePath: 'agentFirstName',
						minWidth: 105,
						textAlign: 'left',
						isSortable: true,
						cellComponent: 'String',
						isFixed: '',
						isVisible: true,
					},
					{
						id: '4eedcd9a-ffb3-49b1-a85a-42bcf2414ee4',
						name: 'Last Name',
						valuePath: 'agentLastName',
						minWidth: 105,
						textAlign: 'left',
						isSortable: true,
						cellComponent: 'String',
						isFixed: '',
						isVisible: true,
					},
				],
			},
		];
		return baseColumns;
	}

	get currentPage(): number {
		return this.page;
	}

	set currentPage(page: number) {
		this.page = page;
	}

	get insurancePolicyData() {
		return this.model.InsurancePolicies;
	}

	get totalNumInsurancePolicies() {
		return this.model.InsurancePolicyCount.count;
	}

	get searchFilterQueryParams() {
		const obj: {
			[param: string]: { [filterProperty: string]: string };
		} = {};
		const searchQueryParams = ['agent', 'aipId', 'policyNumber', 'stateId'];
		searchQueryParams.forEach((param) => {
			const value = this[param as keyof UnmappedInsurancePoliciesController];

			if (value) {
				obj[param] = {
					filterRule: 'equals',
					filterValue: value,
				};

				// set filterComponent property to specify component for custom display extended from search-filter
				const filterDisplayObj = getFilterDisplayProperty(param);
				if (filterDisplayObj && filterDisplayObj.customComponent) {
					obj[param].filterComponent = filterDisplayObj.customComponent;
				}
				// set filterLabel property to specify custom label for filter - default would be filterIdentifier (matches queryParam)
				if (filterDisplayObj && filterDisplayObj.label) {
					obj[param].filterLabel = filterDisplayObj.label;
				}
			}
		});

		return obj;
	}

	get searchPlaceholder() {
		return 'Filter by AIP, agent, state, policy number...';
	}

	get searchPrompt() {
		return 'Type a search term to find policies by AIP, agent name, state, or policy number.';
	}

	get query() {
		return query;
	}

	get formattedQueryParams() {
		return this.model.variables;
	}

	@action
	updateSort(sorts: SortObj[]) {
		this.sorts = sorts;
	}

	@action
	async fetchSearchResults(searchText: string) {
		let searchApiResults = [];
		const agentResults: searchResult[] = [];
		const stateResults: searchResult[] = [];
		const insurancePolicyResults: searchResult[] = [];

		// get fuzzy match agents
		const agentVariables: { where: ProducerAndPolicyFilterDTO } = {
			where: {
				OR: [
					{ agentFirstName: { contains: searchText } },
					{ agentLastName: { contains: searchText } },
					{ agentFullName: { contains: searchText } },
				],
			},
		};
		const agentsArr = (
			(
				await this.apollo.watchQuery({
					query: agentFuzzyQuery,
					variables: agentVariables,
				})
			).InsurancePolicies as Agent[]
		).map((agent) => `${agent.agentFirstName} ${agent.agentLastName}`);

		const agentSet = new Set(agentsArr);

		for (const agent of agentSet) {
			const obj = {
				type: 'Agent',
				name: agent,
			};

			agentResults.push(obj);
		}

		// get fuzzy match state
		const stateVariables: { where: StateFilterDTO } = {
			where: {
				OR: [{ abbreviation: { contains: searchText } }, { name: { contains: searchText } }, { id: { contains: searchText } }],
			},
		};

		const states = (
			await this.apollo.watchQuery({
				query: stateFuzzyQuery,
				variables: stateVariables,
			})
		).States as State[];

		states.forEach((state) => {
			const obj = {
				type: 'State',
				id: state.id,
				name: state.name,
			};

			stateResults.push(obj);
		});

		// get insurance policy matches by policy number
		const insurancePolicyVariables: { where: ProducerAndPolicyFilterDTO; limit: number } = {
			where: {
				policyNumber: { contains: searchText },
			},
			limit: 5,
		};

		let insurancePolicies = (
			await this.apollo.watchQuery({
				query: query,
				variables: insurancePolicyVariables,
			})
		).InsurancePolicies as InsurancePolicy[];

		// Remove Insurance policies with duplciate policy numbers
		insurancePolicies = insurancePolicies.filter((value, idx, arr) => arr.findIndex((p) => p.policyNumber === value.policyNumber) === idx);

		insurancePolicies.forEach((policy) => {
			const obj = {
				type: 'InsurancePolicy',
				id: policy.policyNumber,
				name: policy.policyNumber,
			};

			insurancePolicyResults.push(obj);
		});

		// get search API matches
		const variables = {
			query: searchText,
			limit: 10,
			typesToInclude: ['Aip'],
		};
		searchApiResults = (await this.apollo.watchQuery({ query: searchQuery, variables })).Search;

		// return combined set
		return [...agentResults, ...searchApiResults, ...insurancePolicyResults, ...stateResults];
	}

	@action
	structureSearchResults(searchResults: searchResult[]) {
		const map = new Map();

		searchResults.forEach((item) => {
			if (map.has(item.type)) {
				map.get(item.type).push({ id: item.id, name: item.name, type: item.type });
			} else {
				map.set(item.type, [{ id: item.id, name: item.name, type: item.type }]);
			}
		});

		return map;
	}

	@action
	setSearchFilterQueryParam(searchResult: searchResult) {
		const mappedSearchFilter = this.mapSearchResult(searchResult);
		if (mappedSearchFilter) {
			this[mappedSearchFilter.filterIdentifier] = mappedSearchFilter.filterValue;
		}
	}

	mapSearchResult(searchResult: searchResult): { filterIdentifier: SearchFilterIdentifier; filterValue: string } | null {
		let filterIdentifier: SearchFilterIdentifier;

		switch (searchResult.type) {
			case 'Agent':
				filterIdentifier = 'agent';
				break;
			case 'Aip':
				filterIdentifier = 'aipId';
				break;
			case 'State':
				filterIdentifier = 'stateId';
				break;
			case 'InsurancePolicy':
				filterIdentifier = 'policyNumber';
				break;
			default:
				return null;
		}

		if (searchResult.id || searchResult.name) {
			return {
				filterIdentifier,
				filterValue: (searchResult.id || searchResult.name) as string,
			};
		}

		return null;
	}

	@action
	clearSearchFilterQueryParam(filterIdentifier: string) {
		if (isSearchFilterIdentifier(filterIdentifier)) {
			this[filterIdentifier] = null;
		}
	}

	@action
	setTablePageState(newPageVal?: number) {
		this.currentPage = newPageVal ?? 0;
		resetVaultTableScroll('unmapped-insurance-policy-table');
	}
}
