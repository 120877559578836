import { add, formatISO, getWeek, getYear, parseISO, setDay } from 'date-fns';

export default class SwineMarketingWeek {
	_birthWeek: Date;
	weeksUntilMarket;
	produced: null | number;
	producedTotalCost: null | number;

	purchased: null | number;
	purchasedTotalCost: null | number;

	constructor(
		birthDate: string,
		weeksUntilMarket: number,
		produced?: number,
		producedTotalCost?: number,
		purchased?: number,
		purchasedTotalCost?: number
	) {
		this._birthWeek = parseISO(birthDate);
		this.weeksUntilMarket = weeksUntilMarket;
		this.produced = produced ?? null;
		this.producedTotalCost = producedTotalCost ?? null;
		this.purchased = purchased ?? null;
		this.purchasedTotalCost = purchasedTotalCost ?? null;
	}

	get id() {
		return this.birthWeek;
	}

	get birthWeek() {
		return formatISO(this._birthWeek, { representation: 'date' });
	}

	get readableBirthWeek() {
		return `w${getWeek(this._birthWeek, { weekStartsOn: 0, firstWeekContainsDate: 4 })} ${getYear(
			setDay(this._birthWeek, 4, { weekStartsOn: 0 })
		)}`;
	}

	get _marketingWeek() {
		return add(this._birthWeek, { weeks: this.weeksUntilMarket });
	}

	get readableMarketingWeek() {
		return `w${getWeek(this._marketingWeek, { weekStartsOn: 0, firstWeekContainsDate: 4 })} ${getYear(
			setDay(this._marketingWeek, 4, { weekStartsOn: 0 })
		)}`;
	}

	get producedCostPerHead() {
		return this.produced && this.producedTotalCost ? this.producedTotalCost / this.produced : null;
	}

	get purchasedCostPerHead() {
		return this.purchased && this.purchasedTotalCost ? this.purchasedTotalCost / this.purchased : null;
	}

	get purchasedAndProduced() {
		return this.purchased || this.produced ? (this.purchased ?? 0) + (this.produced ?? 0) : null;
	}

	get purchasedAndProducedTotalCost() {
		return this.purchasedTotalCost || this.producedTotalCost ? (this.purchasedTotalCost ?? 0) + (this.producedTotalCost ?? 0) : null;
	}

	get purchasedAndProducedCostPerHead() {
		return this.purchasedAndProducedTotalCost && this.purchasedAndProduced
			? this.purchasedAndProducedTotalCost / this.purchasedAndProduced
			: null;
	}
}
