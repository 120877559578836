import { DateTime } from 'luxon';
import {
	AggregateDerivedDrpEndorsementDTO,
	AggregateForecastedMilkProductionByMonthDTO,
	InsuranceEndorsementAllocationRatio,
} from 'vault-client/types/graphql-types';

export default class InsuranceQuarter {
	startDate: string;
	endDate: string;
	effectiveCoveredMilkProduction: number = 0;
	classIiiEffectiveCoveredMilkProduction: number = 0;
	classIvEffectiveCoveredMilkProduction: number = 0;
	pnl: number = 0;
	indemnity: number = 0;
	producerPremiumAmount: number = 0;

	grossProduction: number = 0;
	grossButterfat: number = 0;
	grossProtein: number = 0;
	grossOtherSolids: number = 0;

	grossClassIPounds: number = 0;
	grossClassIiPounds: number = 0;
	grossClassIiiPounds: number = 0;
	grossClassIvPounds: number = 0;

	constructor(startDate: string) {
		this.startDate = startDate;
		this.endDate = DateTime.fromISO(startDate).endOf('quarter').toISODate();
	}

	get percentInsured() {
		if (this.grossProduction) {
			return this.effectiveCoveredMilkProduction / this.grossProduction;
		} else {
			return null;
		}
	}

	get classIiiEffectiveUtilization() {
		return this.grossClassIPounds / 2 + this.grossClassIiiPounds;
	}

	get classIiiPercentInsured() {
		if (this.classIiiEffectiveUtilization) {
			return this.classIiiEffectiveCoveredMilkProduction / this.classIiiEffectiveUtilization;
		} else {
			return null;
		}
	}

	get classIvEffectiveUtilization() {
		return this.grossClassIPounds / 2 + this.grossClassIiPounds + this.grossClassIvPounds;
	}

	get classIvPercentInsured() {
		if (this.classIvEffectiveUtilization) {
			return this.classIvEffectiveCoveredMilkProduction / this.classIvEffectiveUtilization;
		} else {
			return null;
		}
	}

	get producerPremiumAmountCwt() {
		return this.effectiveCoveredMilkProduction ? (this.producerPremiumAmount ?? 0) / (this.effectiveCoveredMilkProduction / 100) : null;
	}

	get indemnityCwt() {
		return this.effectiveCoveredMilkProduction ? (this.indemnity ?? 0) / (this.effectiveCoveredMilkProduction / 100) : null;
	}

	get pnlCwt() {
		return this.effectiveCoveredMilkProduction ? (this.pnl ?? 0) / (this.effectiveCoveredMilkProduction / 100) : null;
	}

	addEndorsementData(data: AggregateDerivedDrpEndorsementDTO) {
		this.effectiveCoveredMilkProduction += data.sum.effectiveCoveredMilkProduction ?? 0;
		this.pnl += data.sum.pnl ?? 0;
		this.indemnity += data.sum.indemnity ?? 0;
		this.producerPremiumAmount += data.sum.producerPremiumAmount ?? 0;

		if (data.classPriceWeightingFactor == 1 || data.componentPriceWeightingFactor == 1) {
			this.classIiiEffectiveCoveredMilkProduction += data.sum.effectiveCoveredMilkProduction ?? 0;
		} else {
			this.classIvEffectiveCoveredMilkProduction += data.sum.effectiveCoveredMilkProduction ?? 0;
		}
	}

	addAllocatedEndorsementData(data: InsuranceEndorsementAllocationRatio) {
		const ratioAdjustedDerivedDrpInsuranceEndorsement = data.RatioAdjustedDerivedDrpInsuranceEndorsement;

		if (!ratioAdjustedDerivedDrpInsuranceEndorsement) return;

		this.effectiveCoveredMilkProduction += ratioAdjustedDerivedDrpInsuranceEndorsement?.effectiveCoveredMilkProduction ?? 0;
		this.pnl += ratioAdjustedDerivedDrpInsuranceEndorsement?.pnl ?? 0;
		this.indemnity += ratioAdjustedDerivedDrpInsuranceEndorsement?.indemnity ?? 0;
		this.producerPremiumAmount += ratioAdjustedDerivedDrpInsuranceEndorsement?.producerPremiumAmount ?? 0;

		if (
			ratioAdjustedDerivedDrpInsuranceEndorsement?.classPriceWeightingFactor == 1 ||
			ratioAdjustedDerivedDrpInsuranceEndorsement?.componentPriceWeightingFactor == 1
		) {
			this.classIiiEffectiveCoveredMilkProduction += ratioAdjustedDerivedDrpInsuranceEndorsement?.effectiveCoveredMilkProduction ?? 0;
		} else {
			this.classIvEffectiveCoveredMilkProduction += ratioAdjustedDerivedDrpInsuranceEndorsement?.effectiveCoveredMilkProduction ?? 0;
		}
	}

	addProductionData(data: AggregateForecastedMilkProductionByMonthDTO) {
		this.grossProduction += data.sum.grossProduction ?? 0;
		this.grossButterfat += data.sum.grossButterfatProduction ?? 0;
		this.grossProtein += data.sum.grossProteinProduction ?? 0;
		this.grossOtherSolids += data.sum.grossOtherSolidsProduction ?? 0;
		this.grossClassIPounds += data.sum.grossClassiProduction ?? 0;
		this.grossClassIiPounds += data.sum.grossClassiiProduction ?? 0;
		this.grossClassIiiPounds += data.sum.grossClassiiiProduction ?? 0;
		this.grossClassIvPounds += data.sum.grossClassivProduction ?? 0;
	}
}

export class InsuranceMonth extends InsuranceQuarter {
	constructor(startDate: string) {
		super(startDate);
		this.endDate = DateTime.fromISO(startDate).endOf('month').toISODate();
	}
}
export class InsuranceQuarterWithMonths {
	startDate: string;
	endDate: string;

	insuranceMonths: InsuranceMonth[] = [];

	constructor(startDate: string, insuranceMonths: InsuranceMonth[] = []) {
		this.startDate = startDate;
		this.endDate = DateTime.fromISO(startDate).endOf('quarter').toISODate();
		this.insuranceMonths = insuranceMonths;
	}

	get effectiveCoveredMilkProduction() {
		return this.insuranceMonths.reduce((sum, month) => sum + month.effectiveCoveredMilkProduction, 0);
	}

	get classIiiEffectiveCoveredMilkProduction() {
		return this.insuranceMonths.reduce((sum, month) => sum + month.classIiiEffectiveCoveredMilkProduction, 0);
	}

	get classIvEffectiveCoveredMilkProduction() {
		return this.insuranceMonths.reduce((sum, month) => sum + month.classIvEffectiveCoveredMilkProduction, 0);
	}

	get pnl() {
		return this.insuranceMonths.reduce((sum, month) => sum + month.pnl, 0);
	}

	get indemnity() {
		return this.insuranceMonths.reduce((sum, month) => sum + month.indemnity, 0);
	}

	get producerPremiumAmount() {
		return this.insuranceMonths.reduce((sum, month) => sum + month.producerPremiumAmount, 0);
	}

	get grossProduction() {
		return this.insuranceMonths.reduce((sum, month) => sum + month.grossProduction, 0);
	}

	get grossButterfat() {
		return this.insuranceMonths.reduce((sum, month) => sum + month.grossButterfat, 0);
	}

	get grossProtein() {
		return this.insuranceMonths.reduce((sum, month) => sum + month.grossProtein, 0);
	}

	get grossOtherSolids() {
		return this.insuranceMonths.reduce((sum, month) => sum + month.grossOtherSolids, 0);
	}

	get grossClassIPounds() {
		return this.insuranceMonths.reduce((sum, month) => sum + month.grossClassIPounds, 0);
	}

	get grossClassIiPounds() {
		return this.insuranceMonths.reduce((sum, month) => sum + month.grossClassIiPounds, 0);
	}

	get grossClassIiiPounds() {
		return this.insuranceMonths.reduce((sum, month) => sum + month.grossClassIiiPounds, 0);
	}

	get grossClassIvPounds() {
		return this.insuranceMonths.reduce((sum, month) => sum + month.grossClassIvPounds, 0);
	}

	get percentInsured() {
		if (this.grossProduction) {
			return this.effectiveCoveredMilkProduction / this.grossProduction;
		} else {
			return null;
		}
	}

	get classIiiEffectiveUtilization() {
		return this.grossClassIPounds / 2 + this.grossClassIiiPounds;
	}

	get classIiiPercentInsured() {
		if (this.classIiiEffectiveUtilization) {
			return this.classIiiEffectiveCoveredMilkProduction / this.classIiiEffectiveUtilization;
		} else {
			return null;
		}
	}

	get classIvEffectiveUtilization() {
		return this.grossClassIPounds / 2 + this.grossClassIiPounds + this.grossClassIvPounds;
	}

	get classIvPercentInsured() {
		if (this.classIvEffectiveUtilization) {
			return this.classIvEffectiveCoveredMilkProduction / this.classIvEffectiveUtilization;
		} else {
			return null;
		}
	}

	get producerPremiumAmountCwt() {
		return this.effectiveCoveredMilkProduction ? (this.producerPremiumAmount ?? 0) / (this.effectiveCoveredMilkProduction / 100) : null;
	}

	get indemnityCwt() {
		return this.effectiveCoveredMilkProduction ? (this.indemnity ?? 0) / (this.effectiveCoveredMilkProduction / 100) : null;
	}

	get pnlCwt() {
		return this.effectiveCoveredMilkProduction ? (this.pnl ?? 0) / (this.effectiveCoveredMilkProduction / 100) : null;
	}

	addAllocatedEndorsementData(data: InsuranceEndorsementAllocationRatio) {
		const insuranceMonth = this.insuranceMonths.find((month) => month.startDate === data.effectiveHedgeDate);
		if (!insuranceMonth) return;

		insuranceMonth.effectiveCoveredMilkProduction += data.RatioAdjustedDerivedDrpInsuranceEndorsement?.effectiveCoveredMilkProduction ?? 0;
		insuranceMonth.pnl += data.RatioAdjustedDerivedDrpInsuranceEndorsement?.pnl ?? 0;
		insuranceMonth.indemnity += data.RatioAdjustedDerivedDrpInsuranceEndorsement?.indemnity ?? 0;
		insuranceMonth.producerPremiumAmount += data.RatioAdjustedDerivedDrpInsuranceEndorsement?.producerPremiumAmount ?? 0;

		if (
			data.RatioAdjustedDerivedDrpInsuranceEndorsement?.classPriceWeightingFactor == 1 ||
			data.RatioAdjustedDerivedDrpInsuranceEndorsement?.componentPriceWeightingFactor == 1
		) {
			insuranceMonth.classIiiEffectiveCoveredMilkProduction +=
				data.RatioAdjustedDerivedDrpInsuranceEndorsement?.effectiveCoveredMilkProduction ?? 0;
		} else {
			insuranceMonth.classIvEffectiveCoveredMilkProduction +=
				data.RatioAdjustedDerivedDrpInsuranceEndorsement?.effectiveCoveredMilkProduction ?? 0;
		}
	}

	addProductionData(data: AggregateForecastedMilkProductionByMonthDTO) {
		const insuranceMonth = this.insuranceMonths.find((month) => month.startDate === data.date);
		if (!insuranceMonth) return;

		insuranceMonth.grossProduction += data.sum.grossProduction ?? 0;
		insuranceMonth.grossButterfat += data.sum.grossButterfatProduction ?? 0;
		insuranceMonth.grossProtein += data.sum.grossProteinProduction ?? 0;
		insuranceMonth.grossOtherSolids += data.sum.grossOtherSolidsProduction ?? 0;
		insuranceMonth.grossClassIPounds += data.sum.grossClassiProduction ?? 0;
		insuranceMonth.grossClassIiPounds += data.sum.grossClassiiProduction ?? 0;
		insuranceMonth.grossClassIiiPounds += data.sum.grossClassiiiProduction ?? 0;
		insuranceMonth.grossClassIvPounds += data.sum.grossClassivProduction ?? 0;
	}
}
