import Component from '@glimmer/component';
import { htmlSafe } from '@ember/template';

interface UiDetailsComponentArgs {
	numOfCols?: number;
}

export default class UiDetailsComponent extends Component<UiDetailsComponentArgs> {
	// styles for when a number of columns are explicitly set. If no columns are set default to 3
	get gridClass() {
		switch (this.args.numOfCols) {
			case 1:
				return htmlSafe(`mx-4 grid grid-cols-1 gap-x-4`);
			case 2:
				return htmlSafe(`mx-4 grid grid-cols-2 gap-x-4`);
			case 3:
				return htmlSafe(`mx-4 grid-cols-2 gap-x-4 sm:grid-cols-3 sm:mx-8 grid lg:gap-x-24 xl:gap-x-32 2xl:grid-cols-3 2xl:gap-x-8`);
			case 4:
				return htmlSafe(`mx-4 grid-cols-2 gap-x-4 sm:mx-8 grid lg:grid-cols-4 lg:gap-x-24 xl:gap-x-32 2xl:gap-x-8`);
			case 5:
				return htmlSafe(`mx-4 grid-cols-2 gap-x-4 sm:mx-8 grid lg:grid-cols-5 lg:gap-x-24 xl:gap-x-32 2xl:gap-x-8`);
			case 6:
				return htmlSafe(`mx-4 grid-cols-2 gap-x-4 sm:mx-8 grid lg:grid-cols-4 lg:gap-x-24 xl:gap-x-32 xl:grid-cols-6 2xl:gap-x-8`);
			default:
				return htmlSafe(`mx-4 grid-cols-2 gap-x-4 sm:grid-cols-3 sm:mx-8 grid lg:gap-x-24 xl:gap-x-32 2xl:grid-cols-3 2xl:gap-x-8`);
		}
	}
}
