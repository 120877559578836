import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { setOwner } from '@ember/application';
import { Account, Transaction as TransactionInterface } from 'vault-client/types/graphql-types';
import Big from 'big.js';
import MarketDataService from 'vault-client/services/market-data';

export default class Transaction {
	@service declare marketData: MarketDataService;

	@tracked id;
	@tracked tradeDate;
	@tracked price;
	@tracked quantityInContracts;
	@tracked quantityInUnits;

	@tracked Fees;
	@tracked Account;
	@tracked _feeTotal;
	@tracked _commissionTotal;
	@tracked _useEODPrice;

	constructor(owner: any, transaction: TransactionInterface, account: Account | null, useEODPrice = false) {
		setOwner(this, owner);

		this.id = transaction.id;
		this.tradeDate = transaction.tradeDate;
		this.quantityInContracts = transaction.contractQuantity;
		this.quantityInUnits = transaction.unitQuantity;

		this.price = transaction.price;

		this.Fees = transaction.Fees;
		this.Account = account ?? transaction.Account;

		this._feeTotal = transaction.feeTotal;
		this._commissionTotal = transaction.commissionTotal;

		this._useEODPrice = useEODPrice;
	}

	get commission() {
		if (this._commissionTotal != null) return this._commissionTotal;

		// If we're trying to calculate this, make sure we have all of the data from GraphQL.
		if (!Array.isArray(this.Fees)) {
			throw new Error('Fee relation is required to calculate commission.');
		}

		const applicableFees = this.Fees.filter((fee) => {
			return fee.type == 'Commission';
		});

		return applicableFees.reduce((acc, fee) => {
			return acc + fee.value;
		}, 0);
	}

	get nonCommissionFees() {
		if (this._feeTotal != null) return this._feeTotal;

		// If we're trying to calculate this, make sure we have all of the data from GraphQL.
		if (!Array.isArray(this.Fees)) {
			throw new Error('Fee relation is required to calculate fees.');
		}

		const applicableFees = this.Fees.filter((fee) => {
			return fee.type != 'Commission';
		});

		return applicableFees.reduce((acc, fee) => {
			return acc + fee.value;
		}, 0);
	}

	get side() {
		return this.quantity >= 0 ? 'Bought' : 'Sold';
	}

	get quantity() {
		return this.quantityInContracts;
	}

	get absQuantityInContracts(): number | Big {
		return Math.abs(this.quantityInContracts);
	}

	getMarketPrice(barchartSymbol: string) {
		return this._useEODPrice ? this.marketData.getEODPrice(barchartSymbol) : this.marketData.getLatestPrice(barchartSymbol);
	}
}
