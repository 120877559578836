import Service from '@ember/service';
import { createAuth0Client, Auth0Client, IdToken, User } from '@auth0/auth0-spa-js';
import config from 'vault-client/config/environment';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';

export default class Auth extends Service {
	@service router: any;
	@tracked scheduledPoll: any = null;
	user: User | null = null;
	accessToken: string | null = null;
	idToken: IdToken | null = null;
	_auth0: Auth0Client | null = null;

	async createAuthClient() {
		if (!this._auth0) {
			const client = await createAuth0Client({
				domain: config.auth0.domain,
				clientId: config.auth0.clientId,
				useRefreshTokens: true,
				useRefreshTokensFallback: true,
				cacheLocation: 'localstorage',
				authorizationParams: {
					audience: `https://backend.ever.ag`,
					responseType: 'token',
					redirect_uri: `${window.location.origin}/callback`,
					scope: 'openid profile email',
				},
			});

			this._auth0 = client;
		}

		// refreshTokens makes a getTokenSilently call which we need for the cookies to populate
		// on a fresh load. createAuth0Client should automatically handle this, but it isn't appearing to
		// - Preston

		await this.refreshTokens();

		return Promise.resolve();
	}

	get auth0() {
		return this._auth0;
	}

	get isAuthenticated() {
		return this.accessToken != null;
	}

	@action
	async requireAuthentication() {
		return this.checkLogin();
	}

	@action
	async login() {
		return this.auth0?.loginWithRedirect();
	}

	@action
	handleAuthentication() {
		return this.auth0?.handleRedirectCallback().then(async () => {
			await this._updateState();
		});
	}

	@action
	async checkLogin() {
		// check to see if a user is authenticated with Auth0
		return await this.auth0
			?.getTokenSilently()
			.then(() => Promise.resolve())
			.catch(async () => {
				await this.refreshTokens()
					.then(() => Promise.resolve())
					.catch(() => Promise.reject());
			});
	}

	@action
	logout() {
		this.auth0?.logout({
			clientId: config.auth0.clientId,
			logoutParams: {
				returnTo: window.location.origin,
			},
		});
	}

	@action
	async refreshTokens() {
		// Refresh Auth0 token and navigate to login if that process fails
		try {
			await this.auth0
				?.getTokenSilently()
				.then(() => this._updateState())
				.then(() => Promise.resolve());
		} catch (error) {
			await this.login().then(() => Promise.reject());
		}
	}

	async _updateState() {
		// Set state based on most recent info
		this.user = (await this.auth0?.getUser()) ?? null;
		const idToken = await this.auth0?.getIdTokenClaims();
		this.accessToken = (await this.auth0?.getTokenSilently().catch(() => this.login())) ?? null;
		this.idToken = idToken ?? null;
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
	interface Registry {
		auth: Auth;
	}
}
