import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { gql, useQuery } from 'glimmer-apollo';
import {
	LgmInsuranceEndorsement,
	LgmInsuranceEndorsementSortByDTO,
	LgmInsuranceEndorsementFilterDTO,
} from 'vault-client/types/graphql-types';

type SortObj = {
	valuePath: string;
	isAscending: boolean;
};

export const GET_LGM_INSURANCE_ENDORSEMENTS = gql`
	query LgmInsuranceEndorsements(
		$limit: Float
		$offset: Float
		$orderBy: LgmInsuranceEndorsementSortByDTO
		$where: LgmInsuranceEndorsementFilterDTO
	) {
		LgmInsuranceEndorsements(orderBy: $orderBy, limit: $limit, offset: $offset, where: $where) {
			id
			updatedAt
			type
			totalExpectedGrossMargin
			subsidyAmount
			stateId
			startDate
			salesEffectiveDate
			rmaTypeId
			rmaStatus
			reinsuranceYear
			producerPremiumAmount
			policyId
			perMonthData
			liability
			isShort
			isLong
			integrationInstallationId
			insurancePracticeId
			insurancePolicyId
			inputSource
			hasWriteAccess
			grossMarginGuarantee
			endDate
			effectiveHedgeMonth
			deductibleAmount
			createdAt
			coverageMonths
			ccSubsidyReductionPercent
			ccSubsidyReductionAmount
			bfrVfrSubsidyAmount
			baseSubsidyAmount
			aoExpenseSubsidyAmount
			agentId
			forecastedIndemnity
			pnl
			InsurancePractice {
				id
				startDate
				reinsuranceYear
				programType
				practiceCode
				practiceAbbreviation
				endDate
				commodityCode
				InsuranceEndorsements {
					id
				}
			}
			InsurancePolicy {
				id
				agentFullName
				producerName
				aipId
				agencyName
				AIP {
					name
					id
				}
				State {
					name
					abbreviation
					rmaStateCode
				}
			}
			InsuranceAgent {
				id
				fullName
			}
			RmaType {
				typeName
				commodityCode
				RmaCommodity {
					commodityYear
					commodityName
				}
			}
		}
		LgmInsuranceEndorsementCount(where: $where) {
			count
		}
	}
`;

type GetLgmInsuranceEndorsements = {
	__typename?: 'Query';
	customers: Array<LgmInsuranceEndorsement>;
};
interface LgmQueryArgs {
	where: LgmInsuranceEndorsementFilterDTO;
	orderBy: LgmInsuranceEndorsementSortByDTO;
	offset: number;
	limit: number;
}
export default class LgmInsuranceEndorsementsIndex extends Route {
	@service applicationScope: any;
	@tracked variables: any = {};

	templateName = 'lgm-insurance-endorsements/index';

	queryParams = {
		page: { refreshModel: true },
		sorts: { refreshModel: true },
		size: { refreshModel: true },
		salesEffectiveStartDate: { refreshModel: true },
		salesEffectiveEndDate: { refreshModel: true },
		agent: { refreshModel: true },
		aipId: { refreshModel: true },
		stateId: { refreshModel: true },
		customerId: { refreshModel: true },
	};

	getLgmInsuranceEndorsements: any = {};

	getLgmInsuranceEndorsementsQuery(vars: LgmQueryArgs) {
		return (this.getLgmInsuranceEndorsements = useQuery<GetLgmInsuranceEndorsements, LgmQueryArgs>(this, () => [
			GET_LGM_INSURANCE_ENDORSEMENTS,
			{ variables: vars },
		]));
	}

	generateWhere(
		locationId: string | null,
		customerId: string | null,
		organizationId: string | null,
		aipId: string | null | undefined,
		agent: string | null | undefined,
		stateId: string | null | undefined,
		salesEffectiveStartDate: string | null | undefined,
		salesEffectiveEndDate: string | null | undefined
	): LgmInsuranceEndorsementFilterDTO {
		const where: LgmInsuranceEndorsementFilterDTO = {};
		if (locationId) {
			where.Policy = {
				Customer: {
					Locations: {
						id: { equals: locationId },
					},
				},
			};
		} else if (customerId) {
			where.Policy = {
				customerId: { equals: customerId },
			};
		} else if (organizationId) {
			where.Policy = {
				OR: [
					{
						Customer: {
							organizationId: {
								equals: organizationId,
							},
						},
					},
					{
						ownerId: {
							equals: organizationId,
						},
					},
				],
			};
		}

		if (customerId) {
			if (!where.Policy) {
				where.Policy = {};
			}
			where.Policy.customerId = {
				equals: customerId,
			};
		}

		if (aipId) {
			if (!where.Policy) {
				where.Policy = {};
			}
			where.Policy.aipId = {
				equals: aipId,
			};
		}

		if (agent) {
			if (!where.Policy) {
				where.Policy = {};
			}
			where.Policy.agentFullName = {
				equals: agent,
			};
		}

		if (stateId) {
			if (!where.Policy) {
				where.Policy = {};
			}
			where.Policy.stateId = {
				equals: stateId,
			};
		}

		if (salesEffectiveStartDate && salesEffectiveEndDate) {
			if (!where.AND) {
				where.AND = [];
			}
			where.AND.push({
				salesEffectiveDate: { gte: salesEffectiveStartDate },
			});
			where.AND.push({
				salesEffectiveDate: { lte: salesEffectiveEndDate },
			});
		} else if (salesEffectiveStartDate) {
			where.salesEffectiveDate = {
				gte: salesEffectiveStartDate,
			};
		} else if (salesEffectiveEndDate) {
			where.salesEffectiveDate = {
				lte: salesEffectiveEndDate,
			};
		}

		return where;
	}

	generateOrderBy(sorts: SortObj[]) {
		const orderBy: {
			[key: string]: any;
		} = {};

		sorts.forEach((sort: any) => {
			const parts = sort.valuePath.split('.');
			const firstPart = parts.shift();

			let value: unknown = sort.isAscending ? 'Asc' : 'Desc';

			while (parts.length > 0) {
				const part = parts.pop();
				const obj: {
					[key: string]: any;
				} = {};
				obj[part] = value;
				value = obj;
			}

			orderBy[firstPart] = value;
		});

		return orderBy;
	}
}
