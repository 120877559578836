export default class Account {
	id: any;
	accountNumber: any;
	fcm: any;
	swapDealer: any;
	name: any;
	customerName: any;
	marginExcessDeficit: any;
	liquidatingValue: any;
	isRelated: string | null;
	currentBalance: number | null;

	constructor(account: any) {
		this.id = account.id;
		this.accountNumber = account.accountNumber;
		this.fcm = account.FCM;
		this.swapDealer = account.SwapDealer;
		this.name = account.name;
		this.customerName = account.Customer?.name;
		this.marginExcessDeficit = account.marginExcessDeficit ?? null;
		this.liquidatingValue = account.liquidatingValue ?? null;
		this.isRelated = account.isParentAccount ? 'Yes' : null;
		this.currentBalance = account.currentBalance ?? null;
	}

	get custodianName() {
		return this.fcm?.name || this.swapDealer?.name;
	}
}
