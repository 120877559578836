import { modifier } from 'ember-modifier';

export default modifier((element) => {
	const shadowClasses = ['shadow-md'];

	const parent = element.parentElement;
	const pageContentContainer = parent?.getElementsByClassName('scrollable-page-content-container')[0];
	if (!pageContentContainer) return;

	function updateShadow() {
		const scrollTop = pageContentContainer?.scrollTop ?? 0;

		if (scrollTop) {
			element.classList.add(...shadowClasses);
		} else {
			element.classList.remove(...shadowClasses);
		}
	}

	pageContentContainer.addEventListener('scroll', updateShadow, { passive: true });

	return () => {
		pageContentContainer.removeEventListener('click', updateShadow);
	};
});
