/* eslint-disable ember/no-controller-access-in-routes */
import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { queryManager } from 'ember-apollo-client';
import query from 'vault-client/graphql/queries/insurance-documents/index.graphql';

import { InsuranceFileFilterDTO, InsuranceFileSortByDTO } from 'vault-client/types/graphql-types';

type SortObj = {
	valuePath: string;
	isAscending: boolean;
};

export default class InsuranceDocumentsIndex extends Route {
	@service applicationScope: any;
	@queryManager apollo: any;

	templateName = 'insurance-documents/index';

	query = query;

	queryParams = {
		aipId: { refreshModel: true },
		page: { refreshModel: true },
		policyNumber: { refreshModel: true },
		sorts: { refreshModel: true },
		size: { refreshModel: true },
	};

	generateOrderBy(sorts: SortObj[]): InsuranceFileSortByDTO {
		const orderBy: {
			[key: string]: any;
		} = {};

		sorts.forEach((sort: any) => {
			const value = sort.isAscending ? 'Asc' : 'Desc';
			orderBy[sort.valuePath] = value;
		});

		return orderBy;
	}

	generateWhere(
		organizationId: null | string,
		customerId: undefined | null | string,
		aipId: string | null | undefined,
		policyNumber: string | null | undefined
	): InsuranceFileFilterDTO {
		const where: InsuranceFileFilterDTO = {};

		if (customerId) {
			where.InsurancePolicy = {
				customerId: { equals: customerId },
			};
		} else if (organizationId) {
			where.InsurancePolicy = {
				OR: [
					{
						Customer: {
							organizationId: {
								equals: organizationId,
							},
						},
					},
					{
						ownerId: {
							equals: organizationId,
						},
					},
				],
			};
		}
		if (aipId) {
			if (!where.InsurancePolicy) {
				where.InsurancePolicy = {};
			}
			where.InsurancePolicy.aipId = { equals: aipId };
		}

		if (policyNumber) {
			if (!where.InsurancePolicy) {
				where.InsurancePolicy = {};
			}
			where.InsurancePolicy.policyNumber = { equals: policyNumber };
		}

		return where;
	}
}
