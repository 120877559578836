import CropTargetsIndexController from 'vault-client/controllers/crop-targets';

export default class OrganizationsOrganizationCropTargets extends CropTargetsIndexController {
	marketingRoutePath: string = 'organizations.organization.marketing';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'Organizations/organization/crop-targets': OrganizationsOrganizationCropTargets;
	}
}
