import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<PowerSelect\n\t@selected={{this.selectedItem}}\n\t@searchEnabled={{true}}\n\t@search={{this.fetchItems}}\n\t@allowClear={{true}}\n\t@placeholder='Select A Business'\n\t@searchField='name'\n\t@searchPlaceholder='Find business...'\n\t@onChange={{this.setItem}}\n\t@renderInPlace={{@renderInPlace}}\n\tas |item|\n>\n\t{{item.name}}\n</PowerSelect>", {"contents":"<PowerSelect\n\t@selected={{this.selectedItem}}\n\t@searchEnabled={{true}}\n\t@search={{this.fetchItems}}\n\t@allowClear={{true}}\n\t@placeholder='Select A Business'\n\t@searchField='name'\n\t@searchPlaceholder='Find business...'\n\t@onChange={{this.setItem}}\n\t@renderInPlace={{@renderInPlace}}\n\tas |item|\n>\n\t{{item.name}}\n</PowerSelect>","moduleName":"vault-client/components/customer-select.hbs","parseOptions":{"srcName":"vault-client/components/customer-select.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { queryManager } from 'ember-apollo-client';
import query from 'vault-client/graphql/queries/search.graphql';
import customerQuery from 'vault-client/graphql/queries/customer-get.graphql';

interface Args {
	selected: boolean;
	onChange: any;
}

export default class CustomerSelectComponent extends Component<Args> {
	@queryManager apollo: any;
	@tracked _selectedItem;

	constructor(owner: any, args: Args) {
		super(owner, args);
		if (this.args.selected) {
			this._selectedItem = this.args.selected;
		}
	}

	// eslint-disable-next-line getter-return
	get selectedItem() {
		if (this._selectedItem) {
			return this.fetchItem(this._selectedItem);
		} else {
			return null;
		}
	}

	async fetchItem(id: any) {
		const variables = { id };
		return (await this.apollo.watchQuery({ query: customerQuery, variables })).Customer;
	}

	@action
	async fetchItems(searchText: any) {
		const variables = {
			query: searchText,
			limit: 20,
			typesToInclude: ['Customer'],
		};
		return (await this.apollo.watchQuery({ query, variables })).Search;
	}

	@action
	setItem(searchItem: any) {
		if (searchItem) {
			this._selectedItem = searchItem.id;
			this.args.onChange(searchItem.id);
		} else {
			this._selectedItem = searchItem;
			this.args.onChange(searchItem);
		}
	}
}
