import Route from '@ember/routing/route';
//@ts-ignore
import { queryManager } from 'ember-apollo-client';
//@ts-ignore
import query from 'vault-client/graphql/queries/users/index.graphql';
import UsersIndexQuery from 'vault-client/utils/users-index-query';
export default class UsersIndexRoute extends Route {
	@queryManager apollo: any;

	queryParams = {
		sorts: { refreshModel: true },
	};

	async model(params: any) {
		const variables = {
			...UsersIndexQuery(params),
		};

		return await this.apollo.watchQuery({ query, variables });
	}
}
