import Route from '@ember/routing/route';
import { tracked } from '@glimmer/tracking';
import { gql, useQuery } from 'glimmer-apollo';
import { GrainOrderFilterDTO, Query, TypeOfGrainOrderStatus } from 'vault-client/types/graphql-types';
export const GET_FEED_PLAN = gql`
	query GrainFeedPlan($id: String!, $ordersWhere: GrainOrderFilterDTO) {
		GrainFeedPlan(id: $id) {
			id
			breakEven
			customerId
			goal
			feedCategoryId
			startDate
			tons
			hasWriteAccess
			tons
			totalTonsFlatPriced
			percentPriced
			totalTonsOpen
			basisOnlyPurchased
			basisExposure
			percentBasisExposure
			futuresOnlyPurchased
			futuresExposure
			percentTonsOpen
			percentFuturesExposure
			type
			feedYear
			Orders(where: $ordersWhere, orderBy: { createdAt: Asc }) {
				id
				type
				deliveryTerms
				createdAt
				updatedAt
				bushels
				futuresMonth
				deliveryStartDate
				deliveryEndDate
				deliveryMonth
				salesType
				futurePrice
				fees
				flatPrice
				futurePrice
				spread
				basis
				contractNumber
				planId
				flatPrice
				shortNote
				Seller {
					id
					name
				}
				Location {
					id
					name
				}
				DeliveryLocation {
					id
					name
				}
				... on GrainTargetOrder {
					expirationDate
					status
					Plan {
						... on GrainFeedPlan {
							id
							FeedCategory {
								id
								name
							}
							Customer {
								id
								name
							}
						}
					}
				}
			}
			FeedCategory {
				id
				name
			}
			Customer {
				id
				name
				RelationshipOwner {
					id
					firstName
					lastName
					email
				}
				BusinessContacts(orderBy: { isPrimary: Asc }) {
					firstName
					lastName
					phone
					email
					isPrimary
				}
			}
		}
	}
`;

type GetFeedPlan = {
	__typename?: 'Query';
	GrainFeedPlan: Query['GrainFeedPlan'];
};

type Query_FeedPlansArgs = {
	id?: string;
	ordersWhere?: GrainOrderFilterDTO;
};

interface QueryParams {
	id: string;
	showClosed: boolean;
	showCancelledFillsClosed: boolean;
}

export default class VgsFeedServicesFeedPlansShow extends Route {
	@tracked variables: Query_FeedPlansArgs = {};

	queryParams = {
		showClosed: {
			refreshModel: true,
		},
		showCancelledFillsClosed: { refreshModel: true },
	};

	getFeedPlan = useQuery<GetFeedPlan, Query_FeedPlansArgs>(this, () => [GET_FEED_PLAN, { variables: this.variables, pollInterval: 10000 }]);

	async model(params: QueryParams) {
		const ordersWhere: GrainOrderFilterDTO = {
			OR: [
				...(params.showClosed
					? [
							{
								AsGrainTargetOrder: {},
							},
					  ]
					: [
							{
								AsGrainTargetOrder: {
									status: {
										notIn: [TypeOfGrainOrderStatus.Filled, TypeOfGrainOrderStatus.Cancelled],
									},
								},
							},
					  ]),
				...(params.showCancelledFillsClosed
					? [{ AsGrainFillOrder: {} }]
					: [
							{
								AsGrainFillOrder: {
									bushels: {
										not: {
											equals: 0,
										},
									},
								},
							},
					  ]),
			],
		};

		this.variables = {
			id: params.id,
			ordersWhere,
		};

		await this.getFeedPlan.promise;

		return { getFeedPlan: this.getFeedPlan };
	}
}
