import LgmInsuranceEndorsementsIndexController from 'vault-client/controllers/lgm-insurance-endorsements/index';

export default class OrganizationsOrganizationLgmInsuranceEndorsements extends LgmInsuranceEndorsementsIndexController {
	lgmInsuranceEndorsementRoute: string = 'organizations.organization.lgm-insurance-endorsement';
	// insuranceRoutePath: string = 'organizations.organization.insurance';
	// insurancePolicyRoutePath: string = 'organizations.organization.insurance-policy';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	interface Registry {
		'organizations/organization/lgm-insurance-endorsements': OrganizationsOrganizationLgmInsuranceEndorsements;
	}
}
