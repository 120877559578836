import Controller from '@ember/controller';
import { action } from '@ember/object';
import { ModelFrom } from 'vault-client/utils/type-utils';
import BusinessesBusinessHedgeStrategies from 'vault-client/routes/businesses/business/hedge-strategies/index';
import { CellComponents, TableColumn } from 'vault-client/types/vault-table';
import { tracked } from '@glimmer/tracking';
import { HedgeStrategy } from 'vault-client/types/graphql-types';

export default class BusinessesBusinessHedgeStrategiesIndexController extends Controller {
	declare model: ModelFrom<BusinessesBusinessHedgeStrategies>;

	queryParams = ['showInactive'];

	@tracked showInactive: boolean = false;
	@tracked showCreateNewHedgeStrategyModal: boolean = false;
	@tracked hedgeStrategyToRemove: HedgeStrategy | null = null;
	@tracked showError: boolean = false;

	get hedgeStrategyColumns(): TableColumn[] {
		return [
			{
				id: '452e09a5-c9a3-42f8-a41e-7c8fbb9d31e0',
				name: 'Strategy Name',
				valuePath: 'name',
				cellComponent: CellComponents.String,
				width: 100,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
				linkRoute: 'businesses.business.hedge-strategies.hedge-strategy',
				linkModelPath: 'id',
			},
			{
				id: 'a0909ada-04c0-42cd-a82d-5ab5fe094633',
				name: 'Date Created',
				valuePath: 'createdAt',
				minWidth: 80,
				cellComponent: CellComponents.IntlDateTimeFormat,
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'fabef69d-e076-48a6-bf39-4e20c995b1de',
				name: 'Product',
				valuePath: 'productName',
				minWidth: 100,
				textAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '66a2b9ed-a12c-4c6a-aa84-8554b6cb0002',
				name: 'Strategy Start Date',
				valuePath: 'startDate',
				minWidth: 80,
				cellComponent: CellComponents.IntlDateTimeFormat,
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'f1d923f2-cc2c-436f-b073-b545653bc0d9',
				name: 'Strategy End Date',
				valuePath: 'endDate',
				minWidth: 80,
				cellComponent: CellComponents.IntlDateTimeFormat,
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '22b37ae5-1fc6-45c3-bb75-985dba1f10ad',
				name: 'Net P/L (EOD)',
				valuePath: 'netPnl',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'ea98574f-0a15-41fb-b599-4de7dcd540f9',
				name: 'Strategy Description',
				valuePath: 'description',
				minWidth: 140,
				textAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: false,
			},
			{
				id: 'a3148de3-4e86-4e38-a0ac-ca8e4eaef3f5',
				name: 'Strategy Status',
				valuePath: 'status',
				minWidth: 140,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: false,
			},
			{
				id: 'c1d1e6d4-3d9e-4f7e-8e3d-0d5f7b5c4f5e',
				name: '',
				minWidth: 30,
				width: 30,
				cellComponent: CellComponents.Button,
				componentArgs: {
					size: 'xs',
					style: 'outline',
					text: 'Remove',
					fn: this.removeHedgeStrategy,
				},
				textAlign: 'center',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
		];
	}

	get hedgeStrategiesCount() {
		return this.model.hedgeStrategies.data?.HedgeStrategyCount.count;
	}

	get hedgeStrategies() {
		const data = this.model.hedgeStrategies.data?.HedgeStrategies;

		if (this.hedgeStrategiesCount && this.hedgeStrategiesCount < 1) return null;

		return data?.map((hedgeStrategy) => {
			const { ...hedgeStrategyData } = hedgeStrategy;
			return {
				...hedgeStrategyData,
				name: hedgeStrategy.name,
				createdAt: hedgeStrategy.createdAt,
				productName: hedgeStrategy?.Product?.name,
				startDate: hedgeStrategy.startDate,
				endDate: hedgeStrategy.endDate,
				netPnl: hedgeStrategy.netPnl,
				description: hedgeStrategy.description,
				status: hedgeStrategy.status,
			};
		});
	}

	@action
	removeHedgeStrategy(row: any) {
		this.hedgeStrategyToRemove = row;
	}

	@action
	closeRemoveHedgeStrategyModal() {
		this.hedgeStrategyToRemove = null;
	}

	@action
	toggleShowError() {
		this.showError = !this.showError;
	}
}
