import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @ledgerCategory}}\n\t<Vault::UiConfirmation as |confirmation|>\n\t\t<confirmation.body>\n\t\t\t<p class='h6 font-sans-semibold'>Are you sure you want to remove this ledger category?</p>\n\t\t\t<br />\n\t\t\t<p class='h5'>{{@ledgerCategory.name}}</p>\n\t\t\t{{#if @ledgerCategory.description}}\n\t\t\t\t<p class='h6 font-sans-semibold'>{{@ledgerCategory.description}}</p>\n\t\t\t{{/if}}\n\t\t\t<br />\n\t\t\t<p>This action is irreversible.</p>\n\t\t</confirmation.body>\n\t\t<confirmation.cancel @style='plain' {{on 'click' this.closeConfirmation}}>Cancel</confirmation.cancel>\n\t\t<confirmation.action @style='destructive' {{on 'click' this.removeCategory}}>Remove Category</confirmation.action>\n\t</Vault::UiConfirmation>\n{{/if}}", {"contents":"{{#if @ledgerCategory}}\n\t<Vault::UiConfirmation as |confirmation|>\n\t\t<confirmation.body>\n\t\t\t<p class='h6 font-sans-semibold'>Are you sure you want to remove this ledger category?</p>\n\t\t\t<br />\n\t\t\t<p class='h5'>{{@ledgerCategory.name}}</p>\n\t\t\t{{#if @ledgerCategory.description}}\n\t\t\t\t<p class='h6 font-sans-semibold'>{{@ledgerCategory.description}}</p>\n\t\t\t{{/if}}\n\t\t\t<br />\n\t\t\t<p>This action is irreversible.</p>\n\t\t</confirmation.body>\n\t\t<confirmation.cancel @style='plain' {{on 'click' this.closeConfirmation}}>Cancel</confirmation.cancel>\n\t\t<confirmation.action @style='destructive' {{on 'click' this.removeCategory}}>Remove Category</confirmation.action>\n\t</Vault::UiConfirmation>\n{{/if}}","moduleName":"vault-client/components/remove-ledger-category-modal.hbs","parseOptions":{"srcName":"vault-client/components/remove-ledger-category-modal.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import {
	LedgerCategory,
	Mutation_deleteLedgerExpenseCategoryArgs,
	Mutation_deleteLedgerMilkCheckCategoryArgs,
	Mutation_deleteLedgerRevenueCategoryArgs,
	TypeOfLedgerCategory,
} from 'vault-client/types/graphql-types';
import { gql, useMutation } from 'glimmer-apollo';

interface RemoveLedgerCategoryModalArgs {
	ledgerCategory: LedgerCategory;
	model: any;
	closeModalFn: () => void;
}

const DELETE_LEDGER_REVENUE_CATEGORY = gql`
	mutation DeleteLedgerRevenueCategory($id: String!) {
		deleteLedgerRevenueCategory(id: $id) {
			id
		}
	}
`;

const DELETE_LEDGER_EXPENSE_CATEGORY = gql`
	mutation DeleteLedgerExpenseCategory($id: String!) {
		deleteLedgerExpenseCategory(id: $id) {
			id
		}
	}
`;

const DELETE_LEDGER_MILK_PREMIUMS_AND_DEDUCTIONS_CATEGORY = gql`
	mutation DeleteLedgerMilkCheckCategory($id: String!) {
		deleteLedgerMilkCheckCategory(id: $id) {
			id
		}
	}
`;

type DeleteLedgerRevenueCategoryMutation = {
	__typename?: 'Mutation';
	deleteLedgerRevenueCategory: {
		id: string;
		__typename: 'DeletedResource';
	};
};

type DeleteLedgerExpenseCategoryMutation = {
	__typename?: 'Mutation';
	deleteLedgerExpenseCategory: {
		id: string;
		__typename: 'DeletedResource';
	};
};

type DeleteLedgerMilkPremiumsAndDeductionsCategoryMutation = {
	__typename?: 'Mutation';
	deleteLedgerMilkCheckCategory: {
		id: string;
		__typename: 'DeletedResource';
	};
};

export default class RemoveLedgerCategoryModal extends Component<RemoveLedgerCategoryModalArgs> {
	deleteLedgerRevenueCategory = useMutation<DeleteLedgerRevenueCategoryMutation, Mutation_deleteLedgerRevenueCategoryArgs>(this, () => [
		DELETE_LEDGER_REVENUE_CATEGORY,
		{
			/* options */
			update: (cache) => {
				cache.evict({ fieldName: 'AggregateLedgerEntries' });
				cache.evict({ fieldName: 'LedgerEntry' });
				cache.evict({ fieldName: 'LedgerEntries' });
				cache.evict({ fieldName: 'LedgerEntryCount' });

				cache.evict({ fieldName: 'LedgerCategory' });
				cache.evict({ fieldName: 'LedgerCategories' });
				cache.evict({ fieldName: 'LedgerCategoryCount' });

				cache.evict({ fieldName: 'LedgerRevenueCategory' });
				cache.evict({ fieldName: 'LedgerRevenueCategories' });
				cache.evict({ fieldName: 'LedgerRevenueCategoryCount' });
				cache.gc();
			},
		},
	]);

	deleteLedgerExpenseCategory = useMutation<DeleteLedgerExpenseCategoryMutation, Mutation_deleteLedgerExpenseCategoryArgs>(this, () => [
		DELETE_LEDGER_EXPENSE_CATEGORY,
		{
			/* options */
			update: (cache) => {
				cache.evict({ fieldName: 'AggregateLedgerEntries' });
				cache.evict({ fieldName: 'LedgerEntry' });
				cache.evict({ fieldName: 'LedgerEntries' });
				cache.evict({ fieldName: 'LedgerEntryCount' });

				cache.evict({ fieldName: 'LedgerCategory' });
				cache.evict({ fieldName: 'LedgerCategories' });
				cache.evict({ fieldName: 'LedgerCategoryCount' });

				cache.evict({ fieldName: 'LedgerExpenseCategory' });
				cache.evict({ fieldName: 'LedgerExpenseCategories' });
				cache.evict({ fieldName: 'LedgerExpenseCategoryCount' });
				cache.gc();
			},
		},
	]);

	deleteLedgerMilkPremiumsAndDeductionsCategory = useMutation<
		DeleteLedgerMilkPremiumsAndDeductionsCategoryMutation,
		Mutation_deleteLedgerMilkCheckCategoryArgs
	>(this, () => [
		DELETE_LEDGER_MILK_PREMIUMS_AND_DEDUCTIONS_CATEGORY,
		{
			/* options */
			update: (cache) => {
				cache.evict({ fieldName: 'AggregateLedgerEntries' });
				cache.evict({ fieldName: 'LedgerEntry' });
				cache.evict({ fieldName: 'LedgerEntries' });
				cache.evict({ fieldName: 'LedgerEntryCount' });

				cache.evict({ fieldName: 'LedgerCategory' });
				cache.evict({ fieldName: 'LedgerCategories' });
				cache.evict({ fieldName: 'LedgerCategoryCount' });

				cache.evict({ fieldName: 'LedgerMilkCheckCategory' });
				cache.evict({ fieldName: 'LedgerMilkCheckCategories' });
				cache.evict({ fieldName: 'LedgerMilkCheckCategoryCount' });
				cache.gc();
			},
		},
	]);

	getDeleteLedgerCategoryMethod() {
		switch (this.args.ledgerCategory.type) {
			case TypeOfLedgerCategory.Revenue:
				return this.deleteLedgerRevenueCategory;
			case TypeOfLedgerCategory.Expense:
				return this.deleteLedgerExpenseCategory;
			case TypeOfLedgerCategory.MilkCheck:
				return this.deleteLedgerMilkPremiumsAndDeductionsCategory;
			default:
				return null;
		}
	}

	@action
	async removeCategory() {
		const deleteLedgerCategoryMethod = this.getDeleteLedgerCategoryMethod();

		if (deleteLedgerCategoryMethod && deleteLedgerCategoryMethod.mutate) {
			await deleteLedgerCategoryMethod.mutate({
				id: this.args.ledgerCategory.id,
			});

			await this.args.model.refetch();
		}

		this.closeConfirmation();
	}

	@action
	closeConfirmation() {
		this.args.closeModalFn();
	}
}
