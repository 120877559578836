import { DateTime } from 'luxon';
import { GrainCropPlan, GrainFeedPlan, GrainTargetOrder, TypeOfGrainPlan } from 'vault-client/types/graphql-types';
import activityMessage from 'vault-client/utils/activity-message';

export default class Target {
	id;
	Buyer;
	Seller;
	DeliveryLocation;
	CropPlan;
	FeedPlan;
	Location;
	status;
	basis;
	bushels;
	contractNumber;
	createdAt;
	createdBy;
	_deliveryStartDate;
	_deliveryEndDate;
	deliveryMonth;
	expirationDate;
	feedPrice;
	fees;
	flexFee;
	futurePrice;
	futuresMonth;
	flatPrice;
	htaFee;
	rollFee;
	salesType;
	spread;
	deliveryTerms;
	startDate;
	BuyerRelationsOwner;
	orderActivity;
	targetNote;
	notes;
	acres;
	aph;
	StatusUpdates;
	Plan;
	updatedAt;

	constructor(target: GrainTargetOrder, acres: number | null, aph: number | null) {
		const getMostRecentStatusUpdate = (target: GrainTargetOrder) => {
			return target.StatusUpdates?.reduce<string | null>((mostRecentUpdate, curr) => {
				if (mostRecentUpdate === null || mostRecentUpdate === undefined) {
					return curr.updatedAt;
				} else {
					return curr.updatedAt > mostRecentUpdate ? curr.updatedAt : mostRecentUpdate;
				}
			}, null);
		};
		this.Buyer = target?.Buyer;
		this.Seller = target?.Seller;
		this.Location = target?.Location;
		this.DeliveryLocation = target?.DeliveryLocation;
		this.CropPlan = target?.Plan && target.Plan.type === TypeOfGrainPlan.Crop ? (target.Plan as GrainCropPlan) : null;
		this.FeedPlan = target?.Plan && target.Plan.type === TypeOfGrainPlan.Feed ? (target.Plan as GrainFeedPlan) : null;
		this.status = target.status;
		this.updatedAt = target.updatedAt;
		this.StatusUpdates = {
			updatedAt: getMostRecentStatusUpdate(target),
		};
		this.basis = target.basis;
		this.bushels = target.bushels;
		this.contractNumber = target.contractNumber;
		this.createdAt = target.createdAt;
		this.createdBy = target?.createdBy;
		this._deliveryStartDate = target?.deliveryStartDate;
		this._deliveryEndDate = target?.deliveryEndDate;
		this.deliveryMonth = target?.deliveryMonth;
		this.expirationDate = target?.expirationDate;
		this.feedPrice = target?.feedPrice;
		this.fees = target?.fees;
		this.flexFee = target?.flexFee;
		this.futurePrice = target?.futurePrice;
		this.futuresMonth = target?.futuresMonth;
		this.flatPrice = target?.Plan && target.Plan.type === TypeOfGrainPlan.Crop ? target.cropFlatPrice : target?.feedPrice;
		this.htaFee = target?.htaFee;
		this.id = target?.id;
		this.rollFee = target?.rollFee;
		this.salesType = target?.salesType;
		this.spread = target?.spread;
		this.deliveryTerms = target?.deliveryTerms;
		this.startDate = this.FeedPlan ? this.FeedPlan?.startDate : null;
		this.BuyerRelationsOwner = target?.BuyerRelationsOwner;
		this.orderActivity = target?.Activity;
		this.targetNote = target?.shortNote;
		this.notes = target?.Notes;
		this.acres = acres;
		this.aph = aph;
		this.Plan = target?.Plan;
	}

	get deliveryStartDate() {
		// Handle existing fills where delivery_month was used.
		return this._deliveryStartDate ?? this.deliveryMonth ?? null;
	}

	get deliveryEndDate() {
		return this._deliveryEndDate ?? (this.deliveryMonth ? DateTime.fromISO(this.deliveryMonth).endOf('month').toISODate() : null);
	}

	get netPrice() {
		return (this.futurePrice ?? 0) + (this.basis ?? 0) + (this.fees ?? 0) + (this.spread ?? 0);
	}

	get grossRevenue() {
		//returning 0 if falsy here because this would be the behavior on VGS
		return this.netPrice && this.bushels ? this.netPrice * this.bushels : 0;
	}

	get feedMonth() {
		if (this.FeedPlan) {
			const date = DateTime.fromISO(this.FeedPlan.startDate);
			const month = date.toFormat('MMM');
			return month;
		} else {
			return null;
		}
	}

	get feedYear() {
		if (this.FeedPlan) {
			return this.FeedPlan.feedYear;
		} else {
			return null;
		}
	}

	get orderActivities() {
		return this.orderActivity ? activityMessage(this.orderActivity) : null;
	}

	get percent() {
		// following how these values are displayed on VGS. If acres and aph are set and bushels are not display 0. If there are no acres and aph then display a -.
		const bushels = this.bushels ?? 0;
		const acres = this.acres ?? false;
		const aph = this.aph ?? false;

		return (bushels || bushels === 0) && acres && aph ? bushels / (acres * aph) : null;
	}

	get displayname() {
		if (this.CropPlan) {
			return `${this.CropPlan?.Crop.name} ${this.CropPlan?.CropYear.year}`;
		} else if (this.FeedPlan) {
			return `${this.feedMonth} ${this.feedYear} ${this.FeedPlan?.FeedCategory.name}`;
		} else {
			return '';
		}
	}
}
