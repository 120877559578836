import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { gql, useMutation } from 'glimmer-apollo';
import {
	GrainTargetOrder,
	GrainTargetOrderUpdateDTO,
	GrainLocation,
	Buyer,
	Seller,
	TypeOfGrainOrderSales,
	GrainOrderDeliveryTerms,
} from 'vault-client/types/graphql-types';
import { objectCompareStringify } from 'vault-client/utils/general';
import { getInvalidElements, isFormValid } from '../utils/form-validation';
import { guidFor } from '@ember/object/internals';
import Big from 'big.js';
import Target from 'vault-client/models/vgs/target';

const UPDATE_TARGET_MUTATION = gql`
	mutation UpdateGrainTargetOrder($id: String!, $data: GrainTargetOrderUpdateDTO!) {
		updateGrainTargetOrder(id: $id, data: $data) {
			id
		}
	}
`;

type EditTargetButtonArgs = {
	target: Target;
	model: any;
};

interface EditVgsTargetVars {
	id: string;
	data: GrainTargetOrderUpdateDTO;
}

export default class EditTargetButton extends Component<EditTargetButtonArgs> {
	@tracked showModal = false;
	@tracked showConfirmationModal = false;
	@tracked errorMessage: string | null = null;
	@tracked deliveryTerms: GrainOrderDeliveryTerms | null | undefined = this.args.target.deliveryTerms;
	@tracked deliveryStartDate: string | null | undefined = this.args.target.deliveryStartDate;
	@tracked deliveryEndDate: string | null | undefined = this.args.target.deliveryEndDate;

	componentId = guidFor(this);

	updateTargetMutation = useMutation<GrainTargetOrder, EditVgsTargetVars>(this, () => [
		UPDATE_TARGET_MUTATION,
		{
			onComplete: () => {
				this.errorMessage = null;
				this.closeModal();
			},
			onError: (error): void => {
				this.errorMessage = 'There was an error. Target not updated.';
				console.error('Error while attempting to update target.', error.message);
			},
			update: (cache) => {
				cache.evict({ fieldName: 'GrainTargetOrder' });
				cache.evict({ fieldName: 'GrainTargetOrders' });
				cache.gc();
			},
		},
	]);

	@tracked _bushels: string | null | undefined = this.args.target.bushels?.toString();
	get bushels() {
		return this._bushels ? this._bushels : this.args.target.bushels?.toString();
	}
	set bushels(bushels) {
		this._bushels = bushels;
	}

	@tracked _futuresMonth: string | null | undefined = this.args.target.futuresMonth?.slice(0, -3);
	get futuresMonth() {
		if (this._futuresMonth) {
			return this._futuresMonth;
		} else {
			return this.args.target.futuresMonth ? this.args.target.futuresMonth?.slice(0, -3) : this.args.target.futuresMonth;
		}
	}
	set futuresMonth(futureMonth) {
		this._futuresMonth = futureMonth;
	}

	@tracked _salesType = this.args.target.salesType;

	get salesType() {
		return this._salesType ? this._salesType : this.args.target.salesType;
	}
	set salesType(salesType: TypeOfGrainOrderSales) {
		this._salesType = salesType;
	}

	@tracked _futurePrice: string | null | undefined;
	get futurePrice() {
		return this._futurePrice != undefined ? this._futurePrice : this.args.target.futurePrice?.toString();
	}
	set futurePrice(futurePrice) {
		this._futurePrice = futurePrice;
	}

	@tracked _spread: string | null | undefined = null;
	get spread() {
		return this._spread ?? this.args.target.spread?.toString();
	}
	set spread(spread) {
		this._spread = spread;
	}

	@tracked _htaFee: string | null = null;
	get htaFee() {
		return this._htaFee ?? this.args.target.htaFee?.toString();
	}
	set htaFee(htaFee) {
		this._htaFee = htaFee;
	}

	@tracked _rollFee: string | null = null;
	get rollFee() {
		return this._rollFee ?? this.args.target.rollFee?.toString();
	}
	set rollFee(rollFee) {
		this._rollFee = rollFee;
	}

	@tracked _flexFee: string | null = null;
	get flexFee() {
		return this._flexFee ?? this.args.target.flexFee?.toString();
	}
	set flexFee(flexFee) {
		this._flexFee = flexFee;
	}

	@tracked _basis: string | null | undefined = null;
	get basis() {
		return this._basis ?? this.args.target.basis?.toString();
	}
	set basis(basis) {
		this._basis = basis;
	}

	get isFlatPriceDisabled() {
		return this.futurePrice && this.basis;
	}

	@tracked _flatPrice: string | number | null | undefined = null;
	get flatPrice() {
		if (this.futurePrice && this.basis) return Big(this.futurePrice).add(this.basis).toNumber();
		else if (this._flatPrice) return this._flatPrice;
		else {
			return this.args.target.flatPrice;
		}
	}
	set flatPrice(flatPrice) {
		this._flatPrice = flatPrice;
	}

	get showPricesError() {
		//only allow the following combinations: futures price only, basis only, flat price only, or futures pice and basis together
		if (this.futurePrice && this.basis) {
			return false;
		} else if (this.futurePrice || this.basis) {
			return this.flatPrice ? true : false;
		}
		return false;
	}

	@tracked _buyer: Buyer | null | undefined = null;
	get buyer() {
		return this._buyer ? this._buyer : this.args.target.Buyer ? this.args.target.Buyer : null;
	}
	set buyer(buyer) {
		this._buyer = buyer;
	}

	@tracked _seller: Seller | null | undefined = null;
	get seller() {
		return this._seller ? this._seller : this.args.target.Seller ? this.args.target.Seller : null;
	}
	set seller(seller) {
		this._seller = seller;
	}

	@tracked _location: GrainLocation | null | undefined = null;
	get location() {
		return this._location ? this._location : this.args.target.Location ? this.args.target.Location : null;
	}
	set location(location) {
		this._location = location;
	}
	// expiration date needs to be updated this way because getter/setter is not allowing changing this value by text input if there are 31 days in the original month but not in the new month the user is trying to change to.
	@tracked expirationDate: string | null | undefined = this.args.target.expirationDate;

	@tracked _contractNumber: string | null | undefined = null;
	get contractNumber() {
		return this._contractNumber ? this._contractNumber : this.args.target.contractNumber;
	}
	set contractNumber(contractNumber) {
		this._contractNumber = contractNumber;
	}

	@tracked _targetNote: string | null | undefined = null;
	get targetNote() {
		return this._targetNote ? this._targetNote : this.args.target.targetNote;
	}
	set targetNote(note) {
		this._targetNote = note;
	}

	get disableConfirmAndSubmit(): boolean {
		return this.errorMessage != null || this.updateTargetMutation.loading || this.showPricesError;
	}

	get currentSet() {
		return {
			basis: this.args.target.basis ?? null,
			bushels: this.args.target.bushels ?? null,
			buyerId: this.args.target.Buyer?.id ?? undefined,
			sellerId: this.args.target.Seller?.id ?? undefined,
			expirationDate: this.args.target.expirationDate,
			flexFee: this.args.target.flexFee ?? '0',
			futurePrice: this.args.target.futurePrice,
			deliveryStartDate: this.args.target.deliveryStartDate,
			deliveryEndDate: this.args.target.deliveryEndDate,
			futuresMonth: this.args.target?.futuresMonth ? this.args.target.futuresMonth.slice(0, -3) + '-01' : null,
			htaFee: this.args.target.htaFee ?? '0',
			locationId: this.args.target.Location ? this.args.target.Location.id : null,
			rollFee: this.args.target.rollFee ?? '0',
			salesType: this.args.target.salesType,
			spread: this.args.target.spread ?? null,
			contractNumber: this.args.target.contractNumber,
			deliveryTerms: this.args.target.deliveryTerms,
			shortNote: this.args.target.targetNote,
		};
	}

	get updateSet() {
		return {
			basis: this._basis ? +this._basis : null,
			bushels: this._bushels ? +this._bushels : null,
			buyerId: this._buyer ? this._buyer.id : undefined,
			sellerId: this._seller ? this._seller.id : undefined,
			expirationDate: this.expirationDate ? this.expirationDate : undefined,
			flexFee: this.flexFee ? +this.flexFee : 0,
			futurePrice: this._futurePrice ? +this._futurePrice : null,
			feedFlatPrice: !this.futurePrice && !this.basis && this.flatPrice != null ? Big(this.flatPrice).toNumber() ?? null : null,
			deliveryStartDate: this.deliveryStartDate ? this.deliveryStartDate : undefined,
			deliveryEndDate: this.deliveryEndDate ? this.deliveryEndDate : undefined,
			futuresMonth: this._futuresMonth ? this._futuresMonth + '-01' : undefined,
			htaFee: this.htaFee ? +this.htaFee : 0,
			locationId: this._location ? this._location.id : undefined,
			rollFee: this.rollFee ? +this.rollFee : 0,
			salesType: this._salesType,
			spread: this._spread ? +this._spread : null,
			contractNumber: this._contractNumber,
			deliveryTerms: this.deliveryTerms,
			shortNote: this.targetNote,
		};
	}

	@action
	openModal() {
		this.showModal = true;
		this.resetModal();
	}

	@action
	closeModal() {
		this.showModal = false;
		this.errorMessage = null;
		this.showConfirmationModal = false;
		this.resetModal();
	}

	@action
	resetModal() {
		this._bushels = this.args.target.bushels?.toString();
		this._futuresMonth = this.args.target.futuresMonth?.slice(0, -3);
		this.deliveryStartDate = this.args.target.deliveryStartDate;
		this.deliveryEndDate = this.args.target.deliveryEndDate;
		this._spread = this.args.target.spread?.toString();
		this._htaFee = this.args.target.htaFee?.toString();
		this._rollFee = this.args.target.rollFee?.toString();
		this._flexFee = this.args.target.flexFee?.toString();
		this._basis = this.args.target.basis?.toString();
		this._buyer = this.args.target.Buyer;
		this._seller = this.args.target.Seller;
		this._location = this.args.target.Location;
		this.expirationDate = this.args.target.expirationDate;
		this._contractNumber = this.args.target.contractNumber;
		this._futurePrice = this.args.target.futurePrice?.toString();
		this._salesType = this.args.target.salesType;
		this.deliveryTerms = this.args.target.deliveryTerms;
		this._targetNote = this.args.target.targetNote;
	}

	@action
	openConfirmationModal() {
		if (isFormValid(document)) {
			if (objectCompareStringify(this.updateSet, this.currentSet)) {
				this.errorMessage = 'No changes were made to the Target';
				return;
			}
			this.showConfirmationModal = true;
			this.showModal = false;
		} else {
			getInvalidElements(document);
		}
	}

	@action
	closeConfirmationModal() {
		this.showConfirmationModal = false;
		this.showModal = false;
		this.errorMessage = null;
	}

	@action
	confirm() {
		this.submitTarget();
	}
	@action
	async submitTarget() {
		if (this.args.target) {
			const variables: EditVgsTargetVars = {
				id: this.args.target.id,
				data: this.updateSet,
			};
			const _updateTargetMutation = this.updateTargetMutation.mutate(variables);
			await _updateTargetMutation;
		}
	}
}
