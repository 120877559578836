import Controller from '@ember/controller';
import { DateTime, Interval } from 'luxon';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import query from 'vault-client/graphql/queries/locations/production.graphql';
import resetVaultTableScroll from 'vault-client/utils/reset-vault-table-scroll';
import { TableColumn, CellComponents } from 'vault-client/types/vault-table';
import { ModelFrom } from 'vault-client/utils/type-utils';
import LocationsLocationProductionRoute from 'vault-client/routes/locations/location/production';

interface AggregateProduction {
	date: string;
	numberOfCowsForecasted: number;
	productionPerCowForecasted: number;
	grossButterfatForecasted: number;
	grossProteinForecasted: number;
	grossOtherSolidsForecasted: number;
	grossProductionForecasted: number;
	grossProductionActual: number;
}

export class ProductionRow {
	date: DateTime;
	numberOfCows: number;
	poundsPerCowPerDay: number;
	grossProductionForecasted: number;
	grossProductionActual: number;
	percentButterfat: number | null;
	percentProtein: number | null;
	percentOtherSolids: number | null;
	dateIso: string;

	constructor(production: AggregateProduction) {
		this.dateIso = production.date;
		this.date = DateTime.fromISO(production.date);

		this.numberOfCows = production.numberOfCowsForecasted;
		this.poundsPerCowPerDay = production.productionPerCowForecasted;
		this.grossProductionActual = production.grossProductionActual;
		this.grossProductionForecasted = production.grossProductionForecasted;
		this.percentButterfat = this.grossProductionForecasted ? production.grossButterfatForecasted / this.grossProductionForecasted : null;
		this.percentProtein = this.grossProductionForecasted ? production.grossProteinForecasted / this.grossProductionForecasted : null;
		this.percentOtherSolids = this.grossProductionForecasted
			? production.grossOtherSolidsForecasted / this.grossProductionForecasted
			: null;
	}

	get productionPerDay() {
		return this.grossProductionForecasted / this.date.daysInMonth;
	}

	get id() {
		return this.date;
	}
}

export default class LocationsLocationProductionController extends Controller {
	@tracked productionMonthStartDate = DateTime.local().startOf('month').toISODate();
	@tracked productionMonthEndDate = DateTime.local().plus({ months: 23 }).endOf('month').toISODate();
	@tracked selectedRows: ProductionRow[] = [];

	declare model: ModelFrom<LocationsLocationProductionRoute>;

	get showUpdateValuesButton() {
		return this.selectedRows.length > 0;
	}

	get columns(): TableColumn[] {
		const baseColumns: TableColumn[] = [
			{
				id: '9f504d3b-9d4a-46a7-a87d-69e43ca9e7b2',
				name: 'Month',
				valuePath: 'date',
				minWidth: 100,
				cellComponent: CellComponents.MonthFormat,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '699e2c6e-48c6-4e29-bdef-976d6c52c251',
				name: 'Cows',
				valuePath: 'numberOfCows',
				minWidth: 100,
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '6e2aebba-1539-4eaa-b230-ba494d5f5d45',
				name: 'Forecasted Production (LBS)',
				isFixed: '',
				isVisible: true,
				cellComponent: CellComponents.String,
				subcolumns: [
					{
						id: 'd649628a-db16-4a84-b5d7-ef8f9a01b42c',
						name: 'Cow / Day',
						valuePath: 'poundsPerCowPerDay',
						minWidth: 110,
						cellComponent: CellComponents.IntlNumberFormat,
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: '7b0b91a0-adf8-4d2f-b20d-db9e5c4cf46c',
						name: 'Per Day',
						valuePath: 'productionPerDay',
						minWidth: 110,
						cellComponent: CellComponents.IntlNumberFormat,
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: '2560d16f-8d5d-46c7-9288-67d9ec4e6a79',
						name: 'Total',
						valuePath: 'grossProductionForecasted',
						minWidth: 110,
						cellComponent: CellComponents.IntlNumberFormat,
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: '639e2891-9ccc-4845-8124-5d64edf5db09',
				name: 'Actual Production (LBS)',
				valuePath: 'grossProductionActual',
				minWidth: 180,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					maximumFractionDigits: 0,
					minimumFractionDigits: 0,
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '79a58287-c786-4a6a-b5d1-2f03b88b1fa9',
				name: 'Components',
				isFixed: '',
				isVisible: true,
				cellComponent: CellComponents.String,
				subcolumns: [
					{
						id: '2a67d67d-330f-4917-a31f-96ef3b0e5dd5',
						name: 'Butterfat',
						valuePath: 'percentButterfat',
						minWidth: 110,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'percent',
							minimumFractionDigits: '3',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: 'bd3da4ac-b3ad-4d6d-8d00-e30100fa8bae',
						name: 'Protein',
						valuePath: 'percentProtein',
						minWidth: 110,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'percent',
							minimumFractionDigits: '3',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: '00c36c6a-023d-44ea-9fee-e990c29bdeaa',
						name: 'Other Solids',
						valuePath: 'percentOtherSolids',
						minWidth: 110,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'percent',
							minimumFractionDigits: '3',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
				],
			},
		];
		return baseColumns;
	}

	queryParams = ['productionMonthStartDate', 'productionMonthEndDate'];

	productionMonthRangeOptions = [
		{
			displayName: 'Next 24 Months',
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().plus({ months: 23 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Previous 24 Months',
			startDate: DateTime.local().minus({ months: 24 }).startOf('month').toISODate(),
			endDate: DateTime.local().endOf('month').toISODate(),
		},
		{
			displayName: 'Next 12 Months',
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().plus({ months: 11 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Previous 12 Months',
			startDate: DateTime.local().minus({ months: 12 }).startOf('month').toISODate(),
			endDate: DateTime.local().endOf('month').toISODate(),
		},
		{
			displayName: `Calendar Year (${DateTime.local().year}) `,
			startDate: DateTime.local().startOf('year').toISODate(),
			endDate: DateTime.local().endOf('year').toISODate(),
		},
	];

	get canEditProduction() {
		return !!this.model.data?.Location?.CurrentUserPermissions.canWriteOperations;
	}

	get productionMonthQueryParam() {
		return {
			startDate: this.productionMonthStartDate,
			endDate: this.productionMonthEndDate,
		};
	}

	get monthsInDateRange() {
		return Interval.fromDateTimes(
			DateTime.fromISO(this.productionMonthStartDate).startOf('month'),
			DateTime.fromISO(this.productionMonthEndDate).endOf('month')
		)
			.splitBy({ month: 1 })
			.map((d) => d.start.toISODate());
	}

	get production() {
		const aggregateProductions: Record<string, AggregateProduction> = {};

		const getOrCreateAggregateProduction = (date: string) => {
			if (!aggregateProductions[date]) {
				aggregateProductions[date] = {
					date,
					numberOfCowsForecasted: 0,
					productionPerCowForecasted: 0,
					grossButterfatForecasted: 0,
					grossProteinForecasted: 0,
					grossOtherSolidsForecasted: 0,
					grossProductionForecasted: 0,
					grossProductionActual: 0,
				};
			}

			return aggregateProductions[date];
		};

		this.model.data?.AggregateForecastedMilkProductionByMonths.forEach((production) => {
			const date = production.date;
			if (!date) {
				console.warn('Date not found for AggregateForecastedMilkProductionByMonths');
				return;
			}
			const aggregatePosition = getOrCreateAggregateProduction(date);

			aggregatePosition.numberOfCowsForecasted = production.avg.numberOfCows ?? 0;
			aggregatePosition.productionPerCowForecasted = production.avg.averageDailyProductionPerCow ?? 0;
			aggregatePosition.grossButterfatForecasted = production.sum.grossButterfatProduction ?? 0;
			aggregatePosition.grossProteinForecasted = production.sum.grossProteinProduction ?? 0;
			aggregatePosition.grossOtherSolidsForecasted = production.sum.grossOtherSolidsProduction ?? 0;
			aggregatePosition.grossProductionForecasted = production.sum.grossProduction ?? 0;
		});

		this.model.data?.AggregateActualMilkProduction.forEach((production) => {
			const date = production.firstDateOfMonth;
			if (!date) {
				console.warn(`Date not returned for Aggregate Actual Milk Production`);
				return;
			}
			const aggregatePosition = getOrCreateAggregateProduction(date);
			aggregatePosition.grossProductionActual = production.sum.grossProduction ?? 0;
		});

		this.monthsInDateRange.forEach((date) => getOrCreateAggregateProduction(date));

		return Object.values(aggregateProductions)
			.sort((a, b) => (a.date < b.date ? -1 : 1))
			.map((v) => new ProductionRow(v));
	}

	get query() {
		return query;
	}

	@action
	setProductionMonthQueryParam(value: { startDate: string; endDate: string }) {
		this.productionMonthStartDate = value.startDate;
		this.productionMonthEndDate = value.endDate;
		this.setTablePageState();
	}

	@action
	setTablePageState() {
		resetVaultTableScroll('locations-production-table');
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'locations/location/production': LocationsLocationProductionController;
	}
}
