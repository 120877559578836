import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { queryManager } from 'ember-apollo-client';
import { DateTime } from 'luxon';
import DerivedInsuranceEndorsement from 'vault-client/models/derived-insurance-endorsement';
import InsuranceQuarter from 'vault-client/models/insurance-quarter';
import BusinessesBusinessInsuranceRoute from 'vault-client/routes/businesses/business/insurance';
import OrganizationsOrganizationInsuranceRoute from 'vault-client/routes/organizations/organization/insurance';
import { AggregateDerivedDrpEndorsementDTO, BusinessEntityRole, DerivedDrpEndorsement } from 'vault-client/types/graphql-types';
import { ModelFrom } from 'vault-client/utils/type-utils';

export default class InsuranceIndexController extends Controller {
	@queryManager apollo: any;
	@service permissions: any;
	declare model: ModelFrom<OrganizationsOrganizationInsuranceRoute> & ModelFrom<BusinessesBusinessInsuranceRoute>;

	insuranceOverviewRoutePath = '';
	policiesRoutePath = '';
	drpEndorsementsRoutePath = '';
	lrpEndorsementsRoutePath = '';

	get quarters() {
		const endorsementData = this.model.AggregateDerivedDrpEndorsements;
		const productionData = this.model.AggregateForecastedMilkProductionByMonths;

		const quarters: any = {};

		const firstQuarterDate = endorsementData[0]?.quarterStartDate;

		const lastQuarterDate = endorsementData[endorsementData.length - 1]?.quarterStartDate;

		let currentQuarterDate = firstQuarterDate;
		if (currentQuarterDate && lastQuarterDate) {
			while (currentQuarterDate <= lastQuarterDate) {
				quarters[currentQuarterDate] = new InsuranceQuarter(currentQuarterDate);
				currentQuarterDate = DateTime.fromISO(currentQuarterDate).plus({ quarter: 1 }).toISODate();
			}
		}

		//
		//  Add endorsement data to quarter
		//

		endorsementData.forEach((data: AggregateDerivedDrpEndorsementDTO) => {
			if (data.quarterStartDate) {
				quarters[data.quarterStartDate].addEndorsementData(data);
			}
		});

		//
		//  Add production data to quarter
		//

		productionData.forEach((data) => {
			if (data.date) {
				const startOfQuarter = DateTime.fromISO(data.date).startOf('quarter').toISODate();
				quarters[startOfQuarter]?.addProductionData(data);
			}
		});

		return Object.values(quarters);
	}

	get drpEndorsements() {
		return this.model?.DerivedDrpEndorsements.map((endorsement: DerivedDrpEndorsement) => {
			return new DerivedInsuranceEndorsement(endorsement);
		});
	}

	get showDRPEndorsements() {
		// Show DRP endorsements if Customer is null (org scope) or contains role of Dairy Producer
		return this.model?.Customer == null || this.model?.Customer?.businessRoles?.includes(BusinessEntityRole.DairyProducer);
	}

	get showLRPEndorsements() {
		// Show LRP endorsements if Customer is null (org scope) or contains roles of either HogProducer or CattleProducer
		return (
			this.model?.Customer == null ||
			this.model?.Customer?.businessRoles?.some((role: BusinessEntityRole) =>
				[BusinessEntityRole.HogProducer, BusinessEntityRole.CattleProducer].includes(role)
			)
		);
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'insurance-index-controller': InsuranceIndexController;
	}
}
