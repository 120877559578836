import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { CellComponents, TableColumn } from 'vault-client/types/vault-table';
import { ModelFrom } from 'vault-client/utils/type-utils';
import route from 'vault-client/routes/vgs/settings/buyers';
import PermissionsService from 'vault-client/services/permissions';
import { action } from '@ember/object';
import { Buyer, UserRole } from 'vault-client/types/graphql-types';
export default class VgsSettingsBuyers extends Controller {
	declare model: ModelFrom<route>;

	@service declare permissions: PermissionsService;
	@tracked sorts = [{ valuePath: 'name', isAscending: true }];
	@tracked buyerToRemove: Buyer | null = null;

	get columns() {
		const writeColumns: TableColumn[] = [
			{
				id: 'd0f8d561-5e00-40a1-b12e-b39f1393afab',
				name: '',
				minWidth: 110,
				maxWidth: 110,
				cellComponent: CellComponents.Button,
				componentArgs: {
					size: 'xs',
					style: 'outline',
					text: 'Remove',
					fn: this.removeBuyer,
				},
				textAlign: 'center',
				isSortable: false,
				isFixed: 'right',
				isVisible: true,
			},
		];

		const baseColumns: TableColumn[] = [
			{
				id: '946d4d0b-27e0-424e-93af-7b017ac3a0cb',
				name: 'ID',
				valuePath: 'id',
				minWidth: 80,
				textAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '9977b936-f768-4172-99b9-8fb8d40ff396',
				name: 'Buyers Name',
				valuePath: 'name',
				minWidth: 120,
				textAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
		];

		if (this.permissions.me?.roles.includes(UserRole.BuyerRelations)) {
			return [...baseColumns, ...writeColumns];
		} else {
			return baseColumns;
		}
	}

	get rows() {
		return this.model.getBuyers.data?.Buyers ?? [];
	}

	@action
	removeBuyer(row: Buyer) {
		this.buyerToRemove = row;
	}

	@action
	closeDeleteBuyerModal() {
		this.buyerToRemove = null;
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	interface Registry {
		'vgs/settings/buyers': VgsSettingsBuyers;
	}
}
