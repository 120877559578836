import LivestockFeedPurchasesRoute from 'vault-client/routes/livestock-feed/purchases';
import { FeedIngredient, Seller } from 'vault-client/types/graphql-types';

interface ModelParams {
	startDate: string;
	endDate: string;
	selectedVendorId: Seller | null;
	selectedFeedIngredientId: FeedIngredient | null;
}

export default class BusinessesBusinessLivestockFeedPurchasesRoute extends LivestockFeedPurchasesRoute {
	async model(_params: ModelParams) {
		const { business_id: businessId } = this.paramsFor('businesses.business') as { business_id: string };
		this.variables = {
			feedIngredientsWhere: this.generateFeedIngredientsWhere(businessId),
		};

		await this.getFeedPurchases.promise;

		return {
			getFeedPurchases: this.getFeedPurchases,
			businessId,
		};
	}
}
