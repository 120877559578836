import { helper } from '@ember/component/helper';
import { generateFullName as generateName } from 'vault-client/utils/general';

export default helper(function generateFullName(
	positional: [string | null | undefined, string | null | undefined] | [string, string, unknown]
) {
	const [firstName, lastName, defaultReturn] = positional;

	return generateName(firstName, lastName) ?? defaultReturn;
});
