import type Service from '@ember/service';

export enum FeedIngredientBase {
	'Corn' = 'Corn',
	'SoybeanMeal' = 'SoybeanMeal',
	'Other' = 'Other',
}

export interface SearchResult {
	id?: string;
	name?: string;
	type?: string;
}

export enum InternalChartType {
	HistoricalPercentile = 'historical-percentile',
	HistoricalFuturePrices = 'historical-future-prices',
}

export type MediaService = Service & {
	isMobile: boolean;
	isTablet: boolean;
	isLaptop: boolean;
	isDesktop: boolean;
	isJumpbo: boolean;
};
