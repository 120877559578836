import Controller from '@ember/controller';
import { action } from '@ember/object';
import { DateTime } from 'luxon';
import { tracked } from 'tracked-built-ins';
import MarketsProductSeasonality from 'vault-client/routes/markets/product/seasonality';
import { Future, Product } from 'vault-client/types/graphql-types';
import { ModelFrom } from 'vault-client/utils/type-utils';

export default class MarketsProductSeasonalityController extends Controller {
	declare model: ModelFrom<MarketsProductSeasonality>;
	@tracked numberOfFuturesToLoad = 0;
	@tracked loading = false;
	increment: number = 10;

	get futures(): Future[] {
		return this.product.CurrentFutures.slice(0, this.numberOfFuturesToLoad);
	}

	get product(): Product {
		return this.model;
	}

	@action
	seasonalityChartId(futureDate: string) {
		const monthAndYear = this.cardTitleMonthAndYear(futureDate);
		return `${this.product.name.toLowerCase()}-seasonality-chart-${monthAndYear}`;
	}

	@action
	cardTitleMonthAndYear(futureDate: string) {
		const dateTime = DateTime.fromISO(futureDate);
		return dateTime.toLocaleString({ month: 'long', year: 'numeric' });
	}

	@action
	loadMoreItems(increment: number) {
		this.numberOfFuturesToLoad += increment;
	}

	@action
	activateLoadingSpinner() {
		this.loading = true;
	}

	@action
	stopLoadingSpinner() {
		this.loading = false;
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'markets/product/seasonality': MarketsProductSeasonalityController;
	}
}
