import ReportsIndexController, {
	ButterSbmReport,
	ButterSimReport,
	CattleHistoricalPercentilesReport,
	CheeseBlockSbmReport,
	CheeseBlockSimReport,
	CheeseSbmReport,
	ClassIiiSbmReport,
	ClassIvSbmReport,
	DairyHistoricalPercentilesReport,
	DairyMarginReport,
	DrpPerformancebyAipReport,
	DrpPerformanceByPlanYearReport,
	DrpPerformanceBySalesEffectiveDateMonthReport,
	DryWheySbmReport,
	DryWheySimReport,
	LrpPaymentCalculatorReport,
	NonfatDryMilkSbmReport,
	NonfatDryMilkSimReport,
	Report,
	UnmappedAccountsReport,
	UnmappedInsurancePoliciesReport,
} from 'vault-client/controllers/reports';
import { inject as service } from '@ember/service';

export default class OrganizationsOrganizationReports extends ReportsIndexController {
	@service permissions: any;

	get rows() {
		const permissionedReports: Report[] = [
			UnmappedAccountsReport,
			UnmappedInsurancePoliciesReport,
			DairyMarginReport,
			DairyHistoricalPercentilesReport,
			CattleHistoricalPercentilesReport,
			LrpPaymentCalculatorReport,
			NonfatDryMilkSimReport,
		];

		if (this.permissions.isInternal) {
			permissionedReports.push(
				ButterSimReport,
				ButterSbmReport,
				NonfatDryMilkSbmReport,
				ClassIiiSbmReport,
				ClassIvSbmReport,
				CheeseSbmReport,
				DryWheySbmReport,
				DryWheySimReport,
				DrpPerformancebyAipReport,
				DrpPerformanceByPlanYearReport,
				DrpPerformanceBySalesEffectiveDateMonthReport,
				CheeseBlockSbmReport,
				CheeseBlockSimReport
			);
		}

		const _rows = [...this.availableRows, ...permissionedReports].sortBy('name');

		const reports = _rows.filter((row) => {
			return row.access != 'Internal Only' || this.permissions.isInternal;
		});
		return reports.map((row) => {
			row.routePath = 'organizations.organization.' + row.routePathRoot;
			return row;
		});
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'organizations/organization/reports': OrganizationsOrganizationReports;
	}
}
