import Controller from '@ember/controller';
import { saveAs } from 'file-saver';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { CellComponents, SortObj, TableColumn } from 'vault-client/types/vault-table';
import { isEmpty } from '@ember/utils';
import { ModelFrom } from 'vault-client/utils/type-utils';
import OrganizationsOrganizationCropPlanRoute from 'vault-client/routes/organizations/organization/crop-plan';
import BusinessesBusinessCropPlanRoute from 'vault-client/routes/businesses/business/crop-plan';
import PermissionsService from 'vault-client/services/permissions';
import CropPlan from 'vault-client/models/vgs/crop-plan';
import Target from 'vault-client/models/vgs/target';
import { generateFullName } from 'vault-client/utils/general';
import { getGrainOrderStatusDisplayValue, getSalesTypeDisplayValue } from 'vault-client/utils/vgs-utils';

export default class CropPlansShowController extends Controller {
	declare model: ModelFrom<OrganizationsOrganizationCropPlanRoute> | ModelFrom<BusinessesBusinessCropPlanRoute>;

	queryParams = ['showClosedPositions'];
	cropPlansRoutePath = 'crop-plans';
	transactionsRoutePath = '';
	transactionRoutePath = '';
	accountRoutePath = '';

	@service declare permissions: PermissionsService;

	@tracked optionPositionsSorts: SortObj[] = [{ valuePath: 'Instrument.strike', isAscending: true }];
	@tracked optionTransactionsSorts: SortObj[] = [{ valuePath: 'tradeDate', isAscending: false }];
	@tracked showClosedPositions: boolean = false;
	@tracked isPrinting = false;

	get openTargetsColumns(): TableColumn[] {
		const baseColumns: TableColumn[] = [
			{
				id: '24a9951e-4740-404d-9367-ba59cda13a39',
				name: 'Updated At',
				valuePath: 'StatusUpdates.updatedAt',
				width: 160,
				cellComponent: CellComponents.IntlDateTimeFormat,
				componentArgs: {
					day: 'numeric',
					month: 'numeric',
					year: 'numeric',
					hour: 'numeric',
					minute: 'numeric',
				},
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '12d7bcda-85ea-45e2-b69c-54e5da7446e6',
				name: 'Status',
				valuePath: 'status',
				width: 100,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.CustomFormat,
				componentArgs: {
					formatter: getGrainOrderStatusDisplayValue,
				},
				isFixed: '',
				isVisible: true,
			},
			{
				id: '4595c7af-dbaf-4241-827c-db9222da4bc3',
				name: 'Expires At',
				valuePath: 'expirationDate',
				width: 100,
				cellComponent: CellComponents.IntlDateTimeFormat,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'ae18c053-f087-4462-8577-82e0f203a63a',
				name: 'Bushels',
				valuePath: 'bushels',
				width: 110,
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '4082fff9-a324-4bb9-93c0-fe37a05cc449',
				name: 'Futures Month',
				valuePath: 'futuresMonth',
				width: 140,
				cellComponent: CellComponents.MonthFormat,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '525f27d4-95fd-4b7a-9802-4c87c1288d0b',
				name: 'Delivery Start Date',
				valuePath: 'deliveryStartDate',
				width: 180,
				cellComponent: CellComponents.IntlDateTimeFormat,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '5ad59a7e-f415-495d-afa3-9341bffa2746',
				name: 'Delivery End Date',
				valuePath: 'deliveryEndDate',
				width: 180,
				cellComponent: CellComponents.IntlDateTimeFormat,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'e9771fc7-9586-4deb-b5f9-7ecebb26b682',
				name: 'Sales Type',
				valuePath: 'salesType',
				width: 100,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.CustomFormat,
				componentArgs: {
					formatter: getSalesTypeDisplayValue,
				},
				isFixed: '',
				isVisible: true,
			},
			{
				id: '638c81de-9f80-4c9e-b837-71a73bdaae9e',
				name: 'Futures Price',
				valuePath: 'futurePrice',
				width: 140,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'ad43235b-11af-4f37-9f5e-23cdf479a58c',
				name: 'Fees',
				valuePath: 'fees',
				width: 140,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'f662e1dc-255f-4e37-83b2-4b556f0dfa05',
				name: 'Spread',
				valuePath: 'spread',
				width: 140,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'af95be47-11b0-4f10-9497-6f5384545f1c',
				name: 'Basis',
				valuePath: 'basis',
				width: 140,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '425ed254-1d15-4bc3-bf75-e17a89468f86',
				name: 'Buyer',
				valuePath: 'Buyer.name',
				width: 180,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '97e5796d-c1e3-4730-8f8e-339c57d4c62f',
				name: 'Location',
				valuePath: 'Location.name',
				width: 180,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'b03601c2-1bc1-4a8a-a45c-125202f1686f',
				name: 'Delivery',
				valuePath: 'DeliveryLocation.name',
				width: 180,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '170b9163-67f9-4537-ad9b-c41ed0846fc4',
				name: 'Contract Number',
				valuePath: 'contractNumber',
				width: 200,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
		];
		return baseColumns;
	}

	get fills(): TableColumn[] {
		const baseColumns: TableColumn[] = [
			{
				id: 'c6cad7e6-9578-488b-861e-93cb0cf0e353',
				name: 'Bushels',
				valuePath: 'bushels',
				width: 110,
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '345c5d1d-79db-4701-bf00-c28577c35b02',
				name: 'Futures Month',
				valuePath: 'futuresMonth',
				width: 150,
				cellComponent: CellComponents.MonthFormat,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '84bc4bc0-51bb-4c64-ad11-9346383d6153',
				name: 'Delivery Start Date',
				valuePath: 'deliveryStartDate',
				width: 200,
				cellComponent: CellComponents.IntlDateTimeFormat,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'abb6ea90-add2-42c4-851f-36a717b57e49',
				name: 'Delivery End Date',
				valuePath: 'deliveryEndDate',
				width: 200,
				cellComponent: CellComponents.IntlDateTimeFormat,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '5cc488ac-cd03-442a-9011-a2daa9817b69',
				name: 'Sales Type',
				valuePath: 'salesType',
				width: 100,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.CustomFormat,
				componentArgs: {
					formatter: getSalesTypeDisplayValue,
				},
				isFixed: '',
				isVisible: true,
			},
			{
				id: '6d666b27-2e02-4e99-83d5-f85393fb763b',
				name: 'Futures Price',
				valuePath: 'futurePrice',
				width: 140,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'e62bc02a-59af-4395-b7b0-5a6abe4d656d',
				name: 'Fees',
				valuePath: 'fees',
				width: 140,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '9c9690a7-4cac-4158-8b1d-d15152b7a7a3',
				name: 'Spread',
				valuePath: 'spread',
				width: 140,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '2aed83b6-228e-4f8e-abe9-d7da687c0719',
				name: 'Basis',
				valuePath: 'basis',
				width: 140,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'e0208628-0f66-4a29-9b2d-fe926bcc4d91',
				name: 'Buyer',
				valuePath: 'Buyer.name',
				width: 180,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '40a5dff8-42dd-43b1-b130-1e69ca3d1784',
				name: 'Location',
				valuePath: 'Location.name',
				width: 180,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'c331946e-6bdf-45e1-aa5d-79c3da657d32',
				name: 'Delivery',
				valuePath: 'DeliveryLocation.name',
				width: 180,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '1fe9f75f-f7d1-4808-962b-9f9bfa3e95e9',
				name: 'Contract Number',
				valuePath: 'contractNumber',
				width: 200,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
		];
		return baseColumns;
	}

	get optionPositions(): TableColumn[] {
		return [
			{
				id: 'b84d7aae-4602-4eb2-821f-e596e7fd691c',
				name: '',
				valuePath: 'id',
				width: 80,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.Details,
				isFixed: '',
				isVisible: true,
				linkRoute: this.transactionsRoutePath,
				linkQuery: {
					accountId: {
						rowKey: 'Account.id',
					},
					instrumentId: {
						rowKey: 'Instrument.id',
					},
				},
			},
			{
				id: '68a27142-f6ff-4142-8fbc-bf61651b7695',
				name: 'Account',
				valuePath: 'Account.accountNumber',
				minWidth: 100,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				linkRoute: this.accountRoutePath,
				linkModelPath: 'Account.id',
			},
			{
				id: 'cdc51139-3bee-468e-925f-4f4956654973',
				name: 'Name',
				valuePath: 'Instrument.name',
				minWidth: 220,
				textAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'dad38677-6878-41dd-a1fb-2f2d01e34c9c',
				name: 'Strike',
				valuePath: 'Instrument.strike',
				minWidth: 100,
				cellComponent: CellComponents.PriceFormat,
				componentArgs: {
					fractionDigitsPath: 'Instrument.SymbolGroup.fractionDigits',
					displayFactorPath: 'Instrument.SymbolGroup.displayFactor',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '37c21149-5741-4987-b107-154668eb6ab8',
				name: 'Side',
				valuePath: 'side',
				minWidth: 120,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '97619c5e-2bf4-4fc5-a8d4-77e07381b4b8',
				name: 'Quantity',
				valuePath: 'quantityInContracts',
				minWidth: 100,
				textAlign: 'right',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: false,
			},
			{
				id: '1ac503cf-6eb6-40ba-9ac9-90bcd9c71fbc',
				name: 'Long Qty',
				valuePath: 'longQty',
				minWidth: 100,
				textAlign: 'right',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '25a682a4-4786-4b8e-8067-7e5d65067c3a',
				name: 'Short Qty',
				valuePath: 'shortQty',
				minWidth: 100,
				textAlign: 'right',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'a542ae9c-71a1-4857-8c61-573fd0b3207a',
				name: 'Option Type',
				valuePath: 'Instrument.instrumentType',
				minWidth: 130,
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: false,
			},
			{
				id: 'ce1a0eae-0bc3-4502-bae7-1e9ad186753f',
				name: 'Symbol',
				valuePath: 'Instrument.exchangeSymbol',
				minWidth: 120,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: false,
			},
			{
				id: '7fd07c63-b88f-44ec-8627-d1e693d7e767',
				name: 'Gross P/L',
				valuePath: 'grossPnl',
				minWidth: 120,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
				isTotaled: true,
			},
		];
	}

	get optionTransactions(): TableColumn[] {
		return [
			{
				id: '605cfdf9-d8c2-4dcb-914c-45bf150f8f90',
				name: 'Trade Day',
				valuePath: 'tradeDate',
				minWidth: 120,
				textAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				linkRoute: this.transactionRoutePath,
				linkModelPath: 'id',
			},
			{
				id: '7d4603aa-2282-4b10-b618-d5b60fce9323',
				name: 'Account',
				valuePath: 'Account.accountNumber',
				minWidth: 100,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				linkRoute: this.accountRoutePath,
				linkModelPath: 'Account.id',
			},
			{
				id: '06c2d698-9a32-4c35-b297-c572c8ce256a',
				name: 'Name',
				valuePath: 'Instrument.name',
				minWidth: 220,
				textAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '31bc9400-f7ef-43b9-bb1d-13f578726064',
				name: 'Type',
				valuePath: 'Instrument.instrumentType',
				minWidth: 80,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: false,
			},
			{
				id: '85ef909b-ad61-47ba-8992-7ca6d5e08f87',
				name: 'Symbol',
				valuePath: 'Instrument.exchangeSymbol',
				minWidth: 120,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: false,
			},
			{
				id: '3297282a-28ab-44b9-865f-e2caf6109dbb',
				name: 'Strike',
				valuePath: 'Instrument.strike',
				minWidth: 80,
				cellComponent: CellComponents.PriceFormat,
				componentArgs: {
					fractionDigitsPath: 'Instrument.SymbolGroup.fractionDigits',
					displayFactorPath: 'Instrument.SymbolGroup.displayFactor',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '8aacfa8a-1595-4800-931b-7f4542f26fb4',
				name: 'B / S',
				valuePath: 'side',
				minWidth: 80,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'c307f874-97c6-4b6e-b92a-126fb86613a5',
				name: 'Quantity',
				valuePath: 'quantityInContracts',
				minWidth: 110,
				textAlign: 'right',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: false,
			},
			{
				id: '48f1fdbf-ab5a-475c-9573-c5396157028f',
				name: 'Long Qty',
				valuePath: 'longQty',
				minWidth: 100,
				textAlign: 'right',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '4b5977ee-fcb8-4658-bf30-906c3e4de0ae',
				name: 'Short Qty',
				valuePath: 'shortQty',
				minWidth: 100,
				textAlign: 'right',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '53e623e5-3c80-4e8b-a809-3b66a7e84836',
				name: 'Trade Price',
				valuePath: 'price',
				minWidth: 130,
				cellComponent: CellComponents.PriceFormat,
				componentArgs: {
					fractionDigitsPath: 'Instrument.SymbolGroup.fractionDigits',
					displayFactorPath: 'Instrument.SymbolGroup.displayFactor',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '767e1139-d7f2-451f-9720-b12e0e9390ef',
				name: 'Net P/L',
				valuePath: 'netPl',
				minWidth: 120,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
		];
	}

	get openTargets() {
		return this.cropPlan?.openTargets.map((target) => new Target(target, this.cropPlan?.acres ?? 0, this.cropPlan?.aph ?? 0)) ?? [];
	}

	get cropPlanName() {
		return `${this.cropPlan?.CropYear.year} ${this.cropPlan?.Crop.name}`;
	}

	get cropPlan() {
		return this.model.getGrainCropPlan.data?.GrainCropPlan ? new CropPlan(this.model.getGrainCropPlan.data.GrainCropPlan) : null;
	}

	get optionPositionsColumnTotals(): { [key: string]: number | string }[] | null {
		if (isEmpty(this.model.CurrentPositions)) return null;

		const totals: { [key: string]: number | string } = {};
		this.model.CurrentPositions.map((position) => {
			totals.grossPnl =
				totals.grossPnl && typeof totals.grossPnl === 'number' ? (totals.grossPnl += position.grossPnl ?? 0) : position.grossPnl ?? 0;
		});
		return [totals];
	}

	@action
	async print() {
		this.isPrinting = true;
		const productionObject = {
			id: this.cropPlan?.id,
			acres: this.cropPlan?.acres,
			aph: this.cropPlan?.aph,
			breakEven: this.cropPlan?.breakEven,
			goal: this.cropPlan?.goal,
			enrolledPercent: this.cropPlan?.enrolledPercent,
			harvestBu: this.cropPlan?.harvestBu,
			storageBu: this.cropPlan?.storageBu,
			Crop: this.cropPlan?.Crop,
			CropYear: this.cropPlan?.CropYear,
			Customer: this.cropPlan?.Customer,
			Advisor: {
				name:
					generateFullName(this.cropPlan?.Customer?.RelationshipOwner?.firstName, this.cropPlan?.Customer?.RelationshipOwner?.lastName) ??
					'',
			},
			production: this.cropPlan?.production,
			averageFuturePriceSold: this.cropPlan?.averageFuturePriceSold,
			averageFlatPriceSold: this.cropPlan?.averageFlatPriceSold,
			percentSold: this.cropPlan?.percentSold,
			bushelsFilled: this.cropPlan?.bushelsFilled,
			sumGrossRevenue: this.cropPlan?.sumGrossRevenue,
			Fills:
				this.cropPlan?.Fills.map((fill) => {
					return {
						basis: fill.basis,
						bushels: fill.bushels,
						contractNumber: fill.contractNumber,
						deliveryMonth: fill.delivery_month,
						deliveryStartDate: fill.deliveryStartDate,
						deliveryEndDate: fill.deliveryEndDate,
						fees: fill.fees,
						flatPrice: fill.flatPrice,
						futurePrice: fill.futurePrice,
						futuresMonth: fill.futuresMonth,
						salesType: fill.salesType,
						spread: fill.spread,
						Buyer: fill.Buyer,
						Location: fill.Location,
						Delivery: fill.DeliveryLocation,
						netPrice: fill.netPrice,
						grossRevenue: fill.grossRevenue,
						percentProduction: fill.bushels && this.cropPlan?.production ? fill.bushels / this.cropPlan?.production : 0,
					};
				}).sort((a, b) => {
					// Sort nulls at end
					if (!a.deliveryStartDate) return 1;
					if (!b.deliveryStartDate) return -1;
					return a.deliveryStartDate > b.deliveryStartDate ? 1 : -1;
				}) ?? [],
			Targets: this.openTargets.map((target) => {
				return {
					basis: target.basis,
					bushels: target.bushels,
					contractNumber: target.contractNumber,
					deliveryMonth: target.deliveryMonth,
					deliveryStartDate: target.deliveryStartDate,
					deliveryEndDate: target.deliveryEndDate,
					fees: target.fees,
					flatPrice: target.flatPrice,
					futurePrice: target.futurePrice,
					futuresMonth: target.futuresMonth,
					spread: target.spread,
					salesType: target.salesType,
					Buyer: target.Buyer,
					Location: target.Location,
					Delivery: target.DeliveryLocation,
					netPrice: target.netPrice,
					grossRevenue: target.grossRevenue,
					percentProduction: target.bushels && this.cropPlan?.production ? target.bushels / this.cropPlan.production : 0,
				};
			}),
		};

		const response = await fetch('/.netlify/functions/generate_crop_plan', {
			method: 'POST',
			body: JSON.stringify(productionObject),
		});

		const responseBlob = await response.blob();
		const fileName = `${this.cropPlan?.CropYear?.year} ${this.cropPlan?.Crop?.name} Crop Plan - ${this.cropPlan?.Customer?.name}.pdf`;
		await saveAs(responseBlob, fileName);
		this.isPrinting = false;
	}
}
