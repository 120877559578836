import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div class='relative' id='{{this.chartId}}-container' ...attributes>\n\t<Chart\n\t\t@type='line'\n\t\t@chartData={{this.chartData}}\n\t\t@options={{this.chartOptions}}\n\t\t@plugins={{this.plugins}}\n\t\t@data={{@data}}\n\t\t@id={{this.chartId}}\n\t\t@updateChart={{this.updateChart}}\n\t/>\n</div>", {"contents":"<div class='relative' id='{{this.chartId}}-container' ...attributes>\n\t<Chart\n\t\t@type='line'\n\t\t@chartData={{this.chartData}}\n\t\t@options={{this.chartOptions}}\n\t\t@plugins={{this.plugins}}\n\t\t@data={{@data}}\n\t\t@id={{this.chartId}}\n\t\t@updateChart={{this.updateChart}}\n\t/>\n</div>","moduleName":"vault-client/components/single-line-chart.hbs","parseOptions":{"srcName":"vault-client/components/single-line-chart.hbs"}});
import Component from '@glimmer/component';
import { Chart, ChartData, ChartOptions, ScriptableTooltipContext } from 'chart.js';
import { guidFor } from '@ember/object/internals';
import { action } from '@ember/object';
import getCSSVariable from 'vault-client/utils/get-css-variable';

interface SingleLineChartArgs {
	id: string;
	data: number[];
	labels: string[];
	xScaleType?: 'linear' | 'logarithmic' | 'category' | undefined;
	xScaleOptions?: Record<string, any>;
	yScaleType?: 'linear' | 'logarithmic' | 'category' | undefined;
	yScaleOptions?: Record<string, any>;
	color?: string;
	selectedLabel?: string | number;
	legendSubheading?: string | number;
	tooltipSubheading?: string | number;
	// eslint-disable-next-line no-unused-vars
	tooltipTitleCallback?: (title: string) => string;
	// eslint-disable-next-line no-unused-vars
	tooltipBodyTextCallback?: (bodyText: string | number) => string;
}

const DEFAULT_CHART_COLOR = getCSSVariable('--brand-interactive-blue-70');

export default class SingleLineChart extends Component<SingleLineChartArgs> {
	constructor(owner: any, args: SingleLineChartArgs) {
		super(owner, args);
	}

	get color(): string {
		return this.args.color ?? DEFAULT_CHART_COLOR;
	}

	get chartId(): string {
		return this.args.id ?? `single-line-chart-${guidFor(this)}`;
	}

	get labels() {
		return this.args.labels;
	}

	get chartData(): ChartData {
		return {
			labels: this.labels,
			datasets: [
				{
					tension: 0.3,
					pointRadius: 4,
					pointBackgroundColor: '#E3E3E3',
					backgroundColor: this.color,
					borderColor: this.color,
					data: this.args.data,
				},
			],
		};
	}

	get chartOptions(): ChartOptions {
		const getOrCreateTooltip = (chart: Chart) => {
			let tooltipEl: HTMLDivElement | null | undefined = chart.canvas.parentNode?.querySelector('div');
			if (!tooltipEl) {
				tooltipEl = document.createElement('div');
				tooltipEl.classList.add(
					'line-chart-tooltip',
					'line-chart-tooltip-bottom',
					'border',
					'border-brand-blue-80',
					'bg-white',
					'text-brand-gray-90',
					'absolute',
					'pointer-events-none',
					'transform',
					'-translate-x-2/4',
					'transition-all',
					'ease-linear',
					'duration-50',
					'opacity-1',
					'z-10',
					'text-left',
					'p-2',
					'rounded-sm'
				);

				const tooltipUL = document.createElement('ul');
				tooltipUL.classList.add('tooltipul');

				tooltipEl.append(tooltipUL);
				chart.canvas.parentNode?.append(tooltipEl);
			}
			return tooltipEl;
		};

		const customTooltip = (context: ScriptableTooltipContext<'line'>) => {
			const { chart, tooltip } = context;
			const tooltipEl = getOrCreateTooltip(chart);
			const tooltipUL = tooltipEl.querySelector('ul')!;

			if (tooltip.opacity === 0) {
				tooltipEl.classList.replace('opacity-1', 'opacity-0');
				return;
			}

			if (tooltip.body) {
				const titleLines = tooltip.title || [];
				const dataPoints = tooltip.dataPoints;
				const tooltipLI = document.createElement('li');
				tooltipLI.classList.add('whitespace-nowrap');

				// Title Loop
				titleLines.forEach((title: string) => {
					const formattedTitle = this.args.tooltipTitleCallback ? this.args.tooltipTitleCallback(title) : title;
					tooltipUL.appendChild(tooltipLI);

					const tooltipSpan = document.createElement('span');
					tooltipSpan.classList.add('text-sm', 'font-semibold', 'text-brand-blue-80', 'block');
					tooltipLI.appendChild(tooltipSpan);

					const tooltipTitle = document.createTextNode(formattedTitle);
					tooltipSpan.appendChild(tooltipTitle);

					if (this.args.tooltipSubheading) {
						const tooltipSubHeadingSpan = document.createElement('span');
						tooltipSubHeadingSpan.classList.add('text-brand-gray-60', 'text-xs', 'block');

						const tooltipSubHeading = document.createTextNode(this.args.tooltipSubheading.toString());

						tooltipSubHeadingSpan.appendChild(tooltipSubHeading);
						tooltipLI.appendChild(tooltipSubHeadingSpan);
					}
				});

				// Body Loop
				const tooltipBodyP = document.createElement('p');
				dataPoints.forEach((dataPoint) => {
					const displayBlockSpan = document.createElement('span');
					displayBlockSpan.classList.add('block', 'text-xs');

					const formattedBodyText = this.args.tooltipBodyTextCallback
						? this.args.tooltipBodyTextCallback(dataPoint.raw as string | number)
						: dataPoint.formattedValue;

					const valueTextNode = document.createTextNode(formattedBodyText);

					// Append body text
					displayBlockSpan.appendChild(valueTextNode);
					tooltipBodyP.appendChild(displayBlockSpan);
				});

				// Remove old children
				while (tooltipUL.firstChild) {
					tooltipUL.firstChild.remove();
				}

				// Add new children
				tooltipUL.appendChild(tooltipLI);
				tooltipLI.appendChild(tooltipBodyP);
				tooltipEl.classList.replace('opacity-0', 'opacity-1');

				// Position tooltip

				const position = context.chart.canvas.getBoundingClientRect();
				const { width: tooltipElWidth, height: tooltipElHeight } = tooltipEl.getBoundingClientRect();

				let offsetX = 0;
				let offsetY = 0;

				if (tooltip.caretX < tooltipElWidth / 2) {
					offsetX = tooltip.caretX + tooltipElWidth / 2 + 12;
					offsetY = tooltip.caretY - tooltipElHeight / 2;

					tooltipEl.classList.remove('line-chart-tooltip-bottom', 'line-chart-tooltip-right');
					tooltipEl.classList.add('line-chart-tooltip-left');
				} else if (position.width - tooltip.caretX < tooltipElWidth / 2) {
					offsetX = tooltip.caretX - tooltipElWidth / 2 - 12;
					offsetY = tooltip.caretY - tooltipElHeight / 2;

					tooltipEl.classList.remove('line-chart-tooltip-bottom', 'line-chart-tooltip-left');
					tooltipEl.classList.add('line-chart-tooltip-right');
				} else {
					offsetX = tooltip.caretX;
					offsetY = tooltip.caretY - tooltipElHeight - 12;
					tooltipEl.classList.remove('line-chart-tooltip-right', 'line-chart-tooltip-left');
					tooltipEl.classList.add('line-chart-tooltip-bottom');
					offsetY = tooltip.caretY - tooltipElHeight - 12;
					tooltipEl.classList.remove('line-chart-tooltip-right', 'line-chart-tooltip-left');
					tooltipEl.classList.add('line-chart-tooltip-bottom');
				}

				tooltipEl.style.left = offsetX + 'px';
				tooltipEl.style.top = offsetY + 'px';
			}
		};

		const options: ChartOptions<'line'> = {
			maintainAspectRatio: false,
			spanGaps: true,
			responsive: true,
			interaction: {
				intersect: false,
				mode: 'index',
			},
			scales: {
				y: {
					ticks: {
						autoSkip: true,
						font: {
							size: 12,
						},
						color: getComputedStyle(document.documentElement).getPropertyValue('--brand-gray-60'),
					},
				},
				x: {
					ticks: {
						autoSkip: true,
						color: getComputedStyle(document.documentElement).getPropertyValue('--brand-gray-60'),
						font: {
							size: 12,
						},
					},
					grid: {
						display: false,
					},
				},
			},
			plugins: {
				legend: {
					display: false,
				},
				tooltip: {
					enabled: false,
					external: customTooltip,
				},
			},
		};

		if (this.args.xScaleType) {
			options.scales!.x!.type = this.args.xScaleType;
		}

		if (this.args.xScaleOptions) {
			options.scales!.x = { ...options.scales!.x, ...this.args.xScaleOptions };
		}

		if (this.args.yScaleType) {
			options.scales!.y!.type = this.args.yScaleType;
		}

		if (this.args.yScaleOptions) {
			options.scales!.y = { ...options.scales!.y, ...this.args.yScaleOptions };
		}

		return options;
	}

	@action
	updateChart(chart: Chart, data: number[]) {
		chart.data.datasets.forEach((dataset) => {
			dataset.data = data;
		});
	}
}
