import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { QueryResource, gql, useMutation } from 'glimmer-apollo';
import { Mutation, Mutation_deleteGrainTargetOrderArgs } from 'vault-client/types/graphql-types';

interface RemoveGrainTargetModalArgs {
	targetId: string;
	closeModalFn: () => void;
	model: {
		getFeedPlan?: QueryResource<any, any>;
		getCropPlan?: QueryResource<any, any>;
	};
}

const DELETE_GRAIN_TARGET = gql`
	mutation DeleteGrainTargetOrder($id: String!) {
		deleteGrainTargetOrder(id: $id) {
			id
		}
	}
`;
export default class RemoveGrainTargetModal extends Component<RemoveGrainTargetModalArgs> {
	@tracked errorMessage: string | null = null;
	fsdf = this.args.model?.getFeedPlan;

	deleteGrainTarget = useMutation<{ deleteGrainTargetOrder: Mutation['deleteGrainTargetOrder'] }, Mutation_deleteGrainTargetOrderArgs>(
		this,
		() => [
			DELETE_GRAIN_TARGET,
			{
				onComplete: () => {
					this.args.model.getFeedPlan ? this.args.model.getFeedPlan.refetch() : this.args.model.getCropPlan?.refetch();
				},
				onError: (error): void => {
					this.errorMessage = 'There was an error. Target not deleted.';
					console.error('Error while attempting to delete grain target', error.message);
				},
			},
		]
	);

	@action
	async removeTarget() {
		await this.deleteGrainTarget.mutate({
			id: this.args.targetId,
		});

		this.closeConfirmation();
	}

	@action
	closeConfirmation() {
		this.args.closeModalFn();
	}
}
