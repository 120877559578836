import Route from '@ember/routing/route';
import { tracked } from '@glimmer/tracking';
import { gql, useQuery } from 'glimmer-apollo';
import {
	LocationEntityFilterDTO,
	LocationEntitySortByDTO,
	BusinessEntityRole,
	CustomerEntity,
	Query_LocationsArgs,
	LocationEntity,
	Count,
} from 'vault-client/types/graphql-types';

type SortObj = {
	valuePath: string;
	isAscending: boolean;
};

interface QueryParams {
	sorts: SortObj[];
	id: string;
}

const GET_LOCATIONS_QUERY = gql`
	query Locations($limit: Float = 50, $offset: Float, $orderBy: LocationEntitySortByDTO, $where: LocationEntityFilterDTO) {
		Locations(limit: $limit, offset: $offset, orderBy: $orderBy, where: $where) {
			id
			name
			County {
				id
				name
				State {
					id
					name
				}
			}
		}
		LocationCount(where: $where) {
			count
		}
	}
`;

type GetLocations = {
	__typename?: 'Query';

	LocationCount: Count;
	Locations: Array<LocationEntity>;
};

export default class BusinessesBusinessLocationsRoute extends Route {
	@tracked variables: Query_LocationsArgs = {};

	getLocations = useQuery<GetLocations, Query_LocationsArgs>(this, () => [GET_LOCATIONS_QUERY, { variables: this.variables }]);

	queryParams = {
		sorts: { refreshModel: true },
	};

	generateOrderBy(sorts: SortObj[]): LocationEntitySortByDTO {
		const orderBy: {
			[key: string]: any;
		} = {};

		sorts?.forEach((sort: any) => {
			const value = sort.isAscending ? 'Asc' : 'Desc';
			orderBy[sort.valuePath] = value;
		});

		return orderBy;
	}

	generateWhere(customerId: undefined | null | string): LocationEntityFilterDTO {
		const where: LocationEntityFilterDTO = {};

		if (customerId) {
			where.customerId = { equals: customerId };
		}

		return where;
	}

	async model(params: QueryParams) {
		const businessModel = this.modelFor('businesses.business') as { businessRoles: Array<BusinessEntityRole>; Customer: CustomerEntity };
		this.variables = {
			orderBy: this.generateOrderBy(params.sorts),
			where: this.generateWhere(businessModel.Customer.id),
		};
		this.getLocations.promise;
		if (!this.getLocations.loading) {
			await this.getLocations.refetch();
		} else {
			await this.getLocations.promise;
		}
		return {
			locations: this.getLocations,
			customerId: businessModel.Customer.id,
			businessRoles: businessModel.Customer.businessRoles,
		};
	}
}
