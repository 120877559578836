import { DateTime, Interval } from 'luxon';
import { ForecastedMilkProductionByMonth, ActualBlendedMilkPrice, TypeOfEntity, LocationEntity } from 'vault-client/types/graphql-types';

export type MonthlyForecastedProductionByLocation = {
	[date: string]:
		| {
				[locationId: string]:
					| { gross: number; classI: number; classII: number; classIII: number; classIV: number; blended: number }
					| undefined;
		  }
		| undefined;
};

export type MonthlyProjectedAmountsForAllLocations = { [month: string]: { blendedPrice: number } };

function calculateMonthlyWeightedPricesAndBasisValues(
	startDate: string | null,
	endDate: string | null,
	forecastedMilkProductionByMonths: ForecastedMilkProductionByMonth[],
	actualBlendedMilkPrices?: ActualBlendedMilkPrice[]
) {
	const monthObjs: {
		[date: string]: {
			actualBlendedPrices: number[];
			actualBlendedPriceRevenue: number;
			classIActualPrices: number[];
			grossClassIActualRevenue: number;
			classIIActualPrices: number[];
			grossClassIIActualRevenue: number;
			classIIIActualPrices: number[];
			grossClassIIIActualRevenue: number;
			classIVActualPrices: number[];
			grossClassIVActualRevenue: number;
			locationCurrentBasisValues: number[];
			locationCurrentBasisRevenue: number;
			grossClassIProductionForecasted: number;
			grossClassIIProductionForecasted: number;
			grossClassIIIProductionForecasted: number;
			grossClassIVProductionForecasted: number;
			grossProductionForecasted: number;
			finalClassIActualPrice: number | null;
			finalClassIIActualPrice: number | null;
			finalClassIIIActualPrice: number | null;
			finalClassIVActualPrice: number | null;
			finalBlendedPrice: number | null;
		};
	} = {};

	const monthRange =
		startDate && endDate
			? Interval.fromDateTimes(DateTime.fromISO(startDate).startOf('month'), DateTime.fromISO(endDate).endOf('month'))
					.splitBy({ month: 1 })
					.map((d) => d.start.toISODate())
			: [];

	// Setup the months
	monthRange.forEach((month) => {
		monthObjs[month] = {
			actualBlendedPrices: [],
			actualBlendedPriceRevenue: 0,
			classIActualPrices: [],
			grossClassIActualRevenue: 0,
			classIIActualPrices: [],
			grossClassIIActualRevenue: 0,
			classIIIActualPrices: [],
			grossClassIIIActualRevenue: 0,
			classIVActualPrices: [],
			locationCurrentBasisValues: [],
			locationCurrentBasisRevenue: 0,
			grossClassIVActualRevenue: 0,
			grossClassIProductionForecasted: 0,
			grossClassIIProductionForecasted: 0,
			grossClassIIIProductionForecasted: 0,
			grossClassIVProductionForecasted: 0,
			grossProductionForecasted: 0,
			finalClassIActualPrice: 0,
			finalClassIIActualPrice: 0,
			finalClassIIIActualPrice: 0,
			finalClassIVActualPrice: 0,
			finalBlendedPrice: 0,
		};
	});

	const monthlyForecastedProductionByLocation = createMonthlyProductionByLocationObject(forecastedMilkProductionByMonths);

	forecastedMilkProductionByMonths.forEach((month) => {
		let location = null;
		if (month?.Entity?.type === TypeOfEntity.Location) {
			location = month.Entity as LocationEntity;
		}

		const date = month.date;
		const grossProductionForecasted = month.grossProduction ?? 0;
		const grossClassIProduction = month.grossClassiProduction ?? 0;
		const grossClassIIProduction = month.grossClassiiProduction ?? 0;
		const grossClassIIIProduction = month.grossClassiiiProduction ?? 0;
		const grossClassIVProduction = month.grossClassivProduction ?? 0;
		const classIActualPrice = month.UsdaActualMilkPrice?.classiPrice ?? null;
		const classIIActualPrice = month.UsdaActualMilkPrice?.classiiPrice ?? null;
		const classIIIActualPrice = month.UsdaActualMilkPrice?.classiiiPrice ?? null;
		const classIVActualPrice = month.UsdaActualMilkPrice?.classivPrice ?? null;

		const monthObject = monthObjs[date];
		if (!monthObject) return;

		monthObject.grossClassIProductionForecasted += grossClassIProduction;
		monthObject.grossClassIIProductionForecasted += grossClassIIProduction;
		monthObject.grossClassIIIProductionForecasted += grossClassIIIProduction;
		monthObject.grossClassIVProductionForecasted += grossClassIVProduction;
		monthObject.grossProductionForecasted += grossProductionForecasted;

		if (classIActualPrice) {
			// Using forecasted production for actual revenues until Actual production is fully introduced
			monthObject.grossClassIActualRevenue += classIActualPrice * (grossClassIProduction / 100);
			monthObject.classIActualPrices.push(classIActualPrice);
		}

		if (classIIActualPrice) {
			monthObject.grossClassIIActualRevenue += classIIActualPrice * (grossClassIIProduction / 100);
			monthObject.classIIActualPrices.push(classIIActualPrice);
		}

		if (classIIIActualPrice) {
			monthObject.grossClassIIIActualRevenue += classIIIActualPrice * (grossClassIIIProduction / 100);
			monthObject.classIIIActualPrices.push(classIIIActualPrice);
		}

		if (classIVActualPrice) {
			monthObject.grossClassIVActualRevenue += classIVActualPrice * (grossClassIVProduction / 100);
			monthObject.classIVActualPrices.push(classIVActualPrice);
		}

		if (location && location.currentBasis) {
			monthObject.locationCurrentBasisValues.push(location.currentBasis);
			monthObject.locationCurrentBasisRevenue += location.currentBasis * (grossProductionForecasted / 100);
		}
	});

	actualBlendedMilkPrices?.forEach((actualBlendedPrice) => {
		if (!actualBlendedPrice.date) {
			console.error('Date not provided for ActualBlendedMilkPrice');
			return;
		}

		if (actualBlendedPrice.Entity.type !== TypeOfEntity.Location) {
			return;
		}

		const monthObject = monthObjs[actualBlendedPrice.date];

		if (!monthObject) return;

		const locationId = actualBlendedPrice.Entity.id;
		// Using forecasted production for now
		const locationGrossProduction = monthlyForecastedProductionByLocation[actualBlendedPrice.date]?.[locationId]?.gross;
		const actualPrice = actualBlendedPrice.price;

		if (actualPrice) {
			monthObject.actualBlendedPrices.push(actualPrice);

			if (locationGrossProduction) {
				monthObject.actualBlendedPriceRevenue += actualPrice * (locationGrossProduction / 100);
			}
		}
	});

	const months: {
		date: string;
		finalBlendedPrice: number | null;
		classIPrice: number | null;
		classIIPrice: number | null;
		classIIIPrice: number | null;
		classIVPrice: number | null;
		locationCurrentBasis: number;
		grossProduction: number;
		grossClassIProduction: number;
		grossClassIIProduction: number;
		grossClassIIIProduction: number;
		grossClassIVProduction: number;
	}[] = [];

	Object.keys(monthObjs).forEach((date) => {
		const monthObj = monthObjs[date];

		// Averages will be used as a fallback in cases where there is no forecasted production
		const avgClassIActualPrice =
			monthObj?.classIActualPrices && monthObj.classIActualPrices.length
				? monthObj.classIActualPrices.reduce((a, b) => a + b) / monthObj.classIActualPrices.length
				: null;
		const avgClassIIActualPrice =
			monthObj?.classIIActualPrices && monthObj.classIIActualPrices.length
				? monthObj.classIIActualPrices.reduce((a, b) => a + b) / monthObj.classIIActualPrices.length
				: null;
		const avgClassIIIActualPrice =
			monthObj?.classIIIActualPrices && monthObj.classIIIActualPrices.length
				? monthObj.classIIIActualPrices.reduce((a, b) => a + b) / monthObj.classIIIActualPrices.length
				: null;
		const avgClassIVActualPrice =
			monthObj?.classIVActualPrices && monthObj.classIVActualPrices.length
				? monthObj.classIVActualPrices.reduce((a, b) => a + b, 0) / monthObj.classIVActualPrices.length
				: null;
		const avgActualBlendedPrice =
			monthObj?.actualBlendedPrices && monthObj.actualBlendedPrices.length
				? monthObj.actualBlendedPrices.reduce((a, b) => a + b) / monthObj.actualBlendedPrices.length
				: null;
		const avgLocationCurrentBasis =
			monthObj.locationCurrentBasisValues && monthObj.locationCurrentBasisValues.length
				? monthObj.locationCurrentBasisValues.reduce((a, b) => a + b, 0) / monthObj.locationCurrentBasisValues.length
				: null;
		// Preferred price logic
		const weightedClassIActualPrice =
			monthObj.grossClassIProductionForecasted && monthObj?.grossClassIActualRevenue
				? monthObj.grossClassIActualRevenue / (monthObj.grossClassIProductionForecasted / 100)
				: null;
		const weightedClassIIActualPrice =
			monthObj.grossClassIIProductionForecasted && monthObj?.grossClassIIActualRevenue
				? monthObj.grossClassIIActualRevenue / (monthObj.grossClassIIProductionForecasted / 100)
				: null;
		const weightedClassIIIActualPrice =
			monthObj.grossClassIIIProductionForecasted && monthObj?.grossClassIIIActualRevenue
				? monthObj.grossClassIIIActualRevenue / (monthObj.grossClassIIIProductionForecasted / 100)
				: null;
		const weightedClassIVActualPrice =
			monthObj.grossClassIVProductionForecasted && monthObj?.grossClassIVActualRevenue
				? monthObj.grossClassIVActualRevenue / (monthObj.grossClassIVProductionForecasted / 100)
				: null;
		const weightedActualBlendedPrice =
			monthObj.grossProductionForecasted && monthObj.actualBlendedPriceRevenue
				? monthObj.actualBlendedPriceRevenue / (monthObj.grossProductionForecasted / 100)
				: null;
		const weightedLocationCurrentBasis =
			monthObj.grossProductionForecasted && monthObj.locationCurrentBasisRevenue
				? monthObj.locationCurrentBasisRevenue / (monthObj.grossProductionForecasted / 100)
				: null;
		monthObj.finalClassIActualPrice = weightedClassIActualPrice ?? avgClassIActualPrice ?? null;
		monthObj.finalClassIIActualPrice = weightedClassIIActualPrice ?? avgClassIIActualPrice ?? null;
		monthObj.finalClassIIIActualPrice = weightedClassIIIActualPrice ?? avgClassIIIActualPrice ?? null;
		monthObj.finalClassIVActualPrice = weightedClassIVActualPrice ?? avgClassIVActualPrice ?? null;
		monthObj.finalBlendedPrice = weightedActualBlendedPrice ?? avgActualBlendedPrice ?? null;
		const finalLocationCurrentBasis = weightedLocationCurrentBasis ?? avgLocationCurrentBasis ?? 0;

		months.push({
			date,
			finalBlendedPrice: monthObj.finalBlendedPrice,
			classIPrice: monthObj.finalClassIActualPrice,
			classIIPrice: monthObj.finalClassIIActualPrice,
			classIIIPrice: monthObj.finalClassIIIActualPrice,
			classIVPrice: monthObj.finalClassIVActualPrice,
			locationCurrentBasis: finalLocationCurrentBasis,
			grossProduction: monthObj.grossProductionForecasted,
			grossClassIProduction: monthObj.grossClassIProductionForecasted,
			grossClassIIProduction: monthObj.grossClassIIProductionForecasted,
			grossClassIIIProduction: monthObj.grossClassIIIProductionForecasted,
			grossClassIVProduction: monthObj.grossClassIVProductionForecasted,
		});
	});

	return months;
}

function createMonthlyProductionByLocationObject(forecastedMilkProductionByMonths: ForecastedMilkProductionByMonth[]) {
	return (
		forecastedMilkProductionByMonths?.reduce<MonthlyForecastedProductionByLocation>((acc, productionByMonth) => {
			if (productionByMonth?.Entity?.type !== TypeOfEntity.Location) return acc;

			const month = productionByMonth.date;
			const locationId = productionByMonth?.Entity?.id;
			const production = {
				gross: productionByMonth.grossProduction,
				classI: productionByMonth.grossClassiProduction,
				classII: productionByMonth.grossClassiiProduction,
				classIII: productionByMonth.grossClassiiiProduction,
				classIV: productionByMonth.grossClassivProduction,
				blended: productionByMonth.forecastedBlendedMilkPrice ?? 0,
			};

			if (!acc[month]) {
				acc[month] = { [locationId]: production };
			} else {
				acc[month]![locationId] = production;
			}

			return acc;
		}, {}) ?? {}
	);
}

function calculateUtilizationValuesFromForecastedProduction(forecastedMilkProductionByMonths: ForecastedMilkProductionByMonth[]) {
	const monthObjs: {
		[date: string]: {
			classIUtilizations: number[];
			classIIUtilizations: number[];
			classIIIUtilizations: number[];
			classIVUtilizations: number[];
			otherSolidsPercentages: number[];
			proteinPercentages: number[];
			butterfatPercentages: number[];
			grossClassIProduction: number;
			grossClassIIProduction: number;
			grossClassIIIProduction: number;
			grossClassIVProduction: number;
			grossOtherSolidsProduction: number;
			grossProteinProduction: number;
			grossButterfatProduction: number;
			grossProduction: number;
			classIRevenue: number;
			classIDifferentials: number[];
		};
	} = {};

	forecastedMilkProductionByMonths.forEach((production) => {
		const date = production.date;
		if (!date) return;

		let location: LocationEntity | null = null;
		if (production.Entity.type === TypeOfEntity.Location) {
			location = production.Entity as LocationEntity;
		}

		if (!monthObjs[date]) {
			monthObjs[date] = {
				classIUtilizations: [],
				classIIUtilizations: [],
				classIIIUtilizations: [],
				classIVUtilizations: [],
				otherSolidsPercentages: [],
				proteinPercentages: [],
				butterfatPercentages: [],
				grossClassIProduction: 0,
				grossClassIIProduction: 0,
				grossClassIIIProduction: 0,
				grossClassIVProduction: 0,
				grossOtherSolidsProduction: 0,
				grossProteinProduction: 0,
				grossButterfatProduction: 0,
				grossProduction: 0,
				classIRevenue: 0,
				classIDifferentials: [],
			};
		}

		const monthObj = monthObjs[date];

		monthObj.classIUtilizations.push(production.classiUtilization ?? 0);
		monthObj.classIIUtilizations.push(production.classiiUtilization ?? 0);
		monthObj.classIIIUtilizations.push(production.classiiiUtilization ?? 0);
		monthObj.classIVUtilizations.push(production.classivUtilization ?? 0);

		monthObj.otherSolidsPercentages.push(production.otherSolidsPercent ?? 0);
		monthObj.butterfatPercentages.push(production.butterfatPercent ?? 0);
		monthObj.proteinPercentages.push(production.proteinPercent ?? 0);

		monthObj.grossClassIProduction += production.grossClassiProduction ?? 0;
		monthObj.grossClassIIProduction += production.grossClassiiProduction ?? 0;
		monthObj.grossClassIIIProduction += production.grossClassiiiProduction ?? 0;
		monthObj.grossClassIVProduction += production.grossClassivProduction ?? 0;
		monthObj.grossButterfatProduction += production.grossButterfatProduction ?? 0;
		monthObj.grossOtherSolidsProduction += production.grossOtherSolidsProduction ?? 0;
		monthObj.grossProteinProduction += production.grossProteinProduction ?? 0;
		monthObj.grossProduction += production.grossProduction ?? 0;

		if (location && location.County.classIDifferential) {
			monthObj.classIDifferentials.push(location.County.classIDifferential);
			monthObj.classIRevenue += location.County.classIDifferential * ((production.grossClassiProduction ?? 0) / 100);
		}
	});

	const utilizationMonths: {
		date: string;
		classIUtilization: number;
		classIIUtilization: number;
		classIIIUtilization: number;
		classIVUtilization: number;
		proteinPercentage: number;
		otherSolidsPercentage: number;
		butterfatPercentage: number;
		classIDifferential: number;
		grossClassIProduction: number;
		grossClassIIProduction: number;
		grossClassIIIProduction: number;
		grossClassIVProduction: number;
	}[] = [];

	Object.keys(monthObjs).forEach((date) => {
		const month = monthObjs[date];

		const avgClassIUtilization = month.classIUtilizations.length
			? month.classIUtilizations.reduce((a, b) => a + b, 0) / month.classIUtilizations.length
			: null;
		const avgClassIIUtilization = month.classIIUtilizations.length
			? month.classIIUtilizations.reduce((a, b) => a + b, 0) / month.classIIUtilizations.length
			: null;
		const avgClassIIIUtilization = month.classIIIUtilizations.length
			? month.classIIIUtilizations.reduce((a, b) => a + b, 0) / month.classIIIUtilizations.length
			: null;
		const avgClassIVUtilization = month.classIVUtilizations.length
			? month.classIVUtilizations.reduce((a, b) => a + b, 0) / month.classIVUtilizations.length
			: null;
		const avgProteinPercentage = month.proteinPercentages.length
			? month.proteinPercentages.reduce((a, b) => a + b, 0) / month.proteinPercentages.length
			: null;
		const avgOtherSolidsPercentage = month.otherSolidsPercentages.length
			? month.otherSolidsPercentages.reduce((a, b) => a + b, 0) / month.otherSolidsPercentages.length
			: null;
		const avgButterfatPercentages = month.butterfatPercentages.length
			? month.butterfatPercentages.reduce((a, b) => a + b, 0) / month.butterfatPercentages.length
			: null;

		const weightedClassIUtilization = month.grossClassIProduction / month.grossProduction;
		const weightedClassIIUtilization = month.grossClassIIProduction / month.grossProduction;
		const weightedClassIIIUtilization = month.grossClassIIIProduction / month.grossProduction;
		const weightedClassIVUtilization = month.grossClassIVProduction / month.grossProduction;
		const weightedProteinPercentage = month.grossProteinProduction / month.grossProduction;
		const weightedOtherSolidsPercentage = month.grossOtherSolidsProduction / month.grossProduction;
		const weightedButterfatPercentage = month.grossButterfatProduction / month.grossProduction;

		let otherSolidsPercentage = weightedOtherSolidsPercentage;
		let proteinPercentage = weightedProteinPercentage;
		let butterfatPercentage = weightedButterfatPercentage;

		if (!otherSolidsPercentage && !proteinPercentage && !butterfatPercentage) {
			otherSolidsPercentage = avgOtherSolidsPercentage ?? 0;
			proteinPercentage = avgProteinPercentage ?? 0;
			butterfatPercentage = avgButterfatPercentages ?? 0;
		}

		const avgClassIDifferential = month.classIDifferentials.length
			? month.classIDifferentials.reduce((a, b) => a + b, 0) / month.classIDifferentials.length
			: null;
		const weightedClassIDifferential = month.grossClassIProduction ? month.classIRevenue / (month.grossClassIProduction / 100) : null;

		let classIUtilization = weightedClassIUtilization;
		let classIIUtilization = weightedClassIIUtilization;
		let classIIIUtilization = weightedClassIIIUtilization;
		let classIVUtilization = weightedClassIVUtilization;

		// If all weighted utilizations are zero, use the averages (implies 0 production, so we will fallback to the default utilization values)
		if (!classIUtilization && !classIIUtilization && !classIIIUtilization && !classIVUtilization) {
			classIUtilization = avgClassIUtilization ?? 0;
			classIIUtilization = avgClassIIUtilization ?? 0;
			classIIIUtilization = avgClassIIIUtilization ?? 0;
			classIVUtilization = avgClassIVUtilization ?? 0;
		}

		utilizationMonths.push({
			date,
			classIUtilization,
			classIIUtilization,
			classIIIUtilization,
			classIVUtilization,
			classIDifferential: (weightedClassIDifferential || avgClassIDifferential) ?? 0,
			otherSolidsPercentage,
			proteinPercentage,
			butterfatPercentage,
			grossClassIProduction: month.grossClassIProduction,
			grossClassIIProduction: month.grossClassIIProduction,
			grossClassIIIProduction: month.grossClassIIIProduction,
			grossClassIVProduction: month.grossClassIVProduction,
		});
	});

	return utilizationMonths;
}

function calculateMonthlyWeightedProjectedValues(forecastedMilkProductionByMonths: ForecastedMilkProductionByMonth[]) {
	const monthlyProductionByLocation = createMonthlyProductionByLocationObject(forecastedMilkProductionByMonths);
	const response: MonthlyProjectedAmountsForAllLocations = {};
	Object.keys(monthlyProductionByLocation).forEach((month) => {
		const monthValue = monthlyProductionByLocation[month];
		let blendedSum = 0;
		let totalGross = 0;

		for (const property in monthValue) {
			const locationMonth = monthValue[property];
			const blendedAmt = locationMonth?.blended ?? 0;
			const grossAmt = locationMonth?.gross ?? 0;
			totalGross += grossAmt;
			blendedSum += blendedAmt * grossAmt;
		}

		const blended = blendedSum / totalGross;
		response[month] = { blendedPrice: isNaN(blended) ? 0 : blended };
	});
	return response;
}

export {
	calculateMonthlyWeightedProjectedValues,
	calculateMonthlyWeightedPricesAndBasisValues,
	createMonthlyProductionByLocationObject,
	calculateUtilizationValuesFromForecastedProduction,
};
