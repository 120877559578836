import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Vault::UiButton @style='destructive' @size={{@size}} {{on 'click' this.openConfirmation}}>{{yield}}</Vault::UiButton>\n{{#if this.showConfirmation}}\n\t<Vault::UiConfirmation as |confirmation|>\n\t\t<confirmation.title>Delete Confirmation</confirmation.title>\n\t\t<confirmation.body>Are you sure you want to delete this policy?\n\t\t\t<br />This action is irreversible.\n\t\t</confirmation.body>\n\t\t<confirmation.cancel @style='plain' {{on 'click' this.closeConfirmation}}>Cancel</confirmation.cancel>\n\t\t<confirmation.action @style='destructive' {{on 'click' this.deletePolicy}}>Delete Policy</confirmation.action>\n\t</Vault::UiConfirmation>\n{{/if}}", {"contents":"<Vault::UiButton @style='destructive' @size={{@size}} {{on 'click' this.openConfirmation}}>{{yield}}</Vault::UiButton>\n{{#if this.showConfirmation}}\n\t<Vault::UiConfirmation as |confirmation|>\n\t\t<confirmation.title>Delete Confirmation</confirmation.title>\n\t\t<confirmation.body>Are you sure you want to delete this policy?\n\t\t\t<br />This action is irreversible.\n\t\t</confirmation.body>\n\t\t<confirmation.cancel @style='plain' {{on 'click' this.closeConfirmation}}>Cancel</confirmation.cancel>\n\t\t<confirmation.action @style='destructive' {{on 'click' this.deletePolicy}}>Delete Policy</confirmation.action>\n\t</Vault::UiConfirmation>\n{{/if}}","moduleName":"vault-client/components/delete-insurance-policy-button.hbs","parseOptions":{"srcName":"vault-client/components/delete-insurance-policy-button.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import mutation from 'vault-client/graphql/mutations/insurance-policy-delete.graphql';
import { queryManager } from 'ember-apollo-client';
import { inject as service } from '@ember/service';

interface DeleteInsurancePolicyButtonArgs {
	insurancePolicyId: string;
	insurancePoliciesRoutePath: string;
}

export default class DeleteInsurancePolicyButton extends Component<DeleteInsurancePolicyButtonArgs> {
	@queryManager apollo: any;
	@service router: any;
	@tracked showConfirmation = false;

	@action
	openConfirmation() {
		this.showConfirmation = true;
	}

	@action
	closeConfirmation() {
		this.showConfirmation = false;
	}

	@action
	async deletePolicy() {
		try {
			await this.apollo.mutate({
				mutation,
				variables: {
					id: this.args.insurancePolicyId,
				},
			});
		} catch (e) {
			this.closeConfirmation();
			return;
		}

		if (this.args.insurancePoliciesRoutePath) {
			this.router.transitionTo(this.args.insurancePoliciesRoutePath);
		}
	}
}
