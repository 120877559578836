import Component from '@glimmer/component';
interface UiButtonArgs {
	size: Size;
	style: Style;
	type: Type;
	width: Width;
	disabled: boolean;
	leftIconHref?: string;
	leftIconClass?: string;
	leftIconWidth?: string;
	leftIconHeight?: string;
	leftIconViewBox?: string;
	rightIconHref?: string;
	rightIconClass?: string;
	rightIconWidth?: string;
	rightIconHeight?: string;
	rightIconViewBox?: string;
	iconOnlyButton?: boolean;
	disableFn: (datum: any) => boolean;
	datum: any;
}

type Size = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'icon-nav' | 'icon-default' | 'icon-small' | 'icon-tiny';
type Style = 'primary' | 'outline' | 'utility' | 'plain' | 'plain-subdued' | 'destructive' | 'destructive-plain' | 'plain-contrast';
type Type = 'button' | 'submit' | 'reset';
type Width = 'standard' | 'full';

export default class UiButton extends Component<UiButtonArgs> {
	defaultSize = 'md';
	defaultStyle = 'primary';
	defaultType = 'button';
	defaultWidth = 'standard';
	defaultDisabled = false;

	get size() {
		return this.args.size ?? this.defaultSize;
	}

	get style() {
		return this.args.style ?? this.defaultStyle;
	}

	get type() {
		return this.args.type ?? this.defaultType;
	}

	get width() {
		return this.args.width ?? this.defaultWidth;
	}

	get defaultIconWidth() {
		return this.size == 'xs' || this.size == 'sm' ? '16' : '24';
	}

	get leftIconWidth() {
		return this.args.leftIconWidth ?? this.defaultIconWidth;
	}

	get leftIconHeight() {
		return this.args.leftIconHeight ?? this.defaultIconWidth;
	}

	get rightIconWidth() {
		return this.args.rightIconWidth ?? this.defaultIconWidth;
	}

	get rightIconHeight() {
		return this.args.rightIconHeight ?? this.defaultIconWidth;
	}

	get leftIconViewBox() {
		return this.args.leftIconViewBox ?? '0 0 24 24';
	}

	get rightIconViewBox() {
		return this.args.rightIconViewBox ?? '0 0 24 24';
	}

	get disabled() {
		return this.args.disableFn ? this.args.disableFn(this.args.datum) : this.args.disabled || this.defaultDisabled;
	}
}
