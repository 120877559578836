import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { LocationEntityUpdateDTO, Mutation_updateLocationArgs } from 'vault-client/types/graphql-types';
import { gql, useMutation } from 'glimmer-apollo';

interface SetEveragBasisButtonArgs {
	locationId: string;
	initialRollingBasisMonths: number | null;
	initialCustomBasis: number | null;
}

const SET_EVERAG_BASIS_ON_LOCATION = gql`
	mutation UpdateOperation($id: String!, $data: LocationEntityUpdateDTO!) {
		updateLocation(id: $id, data: $data) {
			id
		}
	}
`;

export default class SetEveragBasisButton extends Component<SetEveragBasisButtonArgs> {
	@tracked showModal = false;
	@tracked rollingBasisMonths: number | null = this.args.initialRollingBasisMonths ?? 12;
	@tracked _customBasis: number | null = this.args.initialCustomBasis;
	@tracked errorMessage: string | null = null;
	@tracked twelveMonths: boolean = false;
	@tracked twentyFourMonths: boolean = false;
	@tracked thirtySixMonths: boolean = false;

	setEverAgBasisOnLocation = useMutation<LocationEntityUpdateDTO, Mutation_updateLocationArgs>(this, () => [
		SET_EVERAG_BASIS_ON_LOCATION,
		{
			onError: (error): void => {
				this.errorMessage = 'Error setting Ever.Ag Basis.';
				console.error('Error while set Ever.Ag Basis.', error.message);
			},
			update: (cache) => {
				cache.evict({ fieldName: 'Locations' });
				cache.evict({ fieldName: 'Location' });
				cache.gc();
			},
		},
	]);

	get customBasis() {
		return this._customBasis;
	}

	set customBasis(basisCWT) {
		this._customBasis = basisCWT;
	}
	@action
	active(event: MouseEvent) {
		const target = event.target as HTMLButtonElement;
		switch (target?.id) {
			case 'twelveMonth':
				this.twelveMonths = true;
				this.twentyFourMonths = false;
				this.thirtySixMonths = false;
				break;
			case 'twentyFourMonth':
				this.twelveMonths = false;
				this.twentyFourMonths = true;
				this.thirtySixMonths = false;
				break;
			case 'thirtySixMonth':
				this.twelveMonths = false;
				this.twentyFourMonths = false;
				this.thirtySixMonths = true;
				break;
			case 'customValue':
				this.twelveMonths = false;
				this.twentyFourMonths = false;
				this.thirtySixMonths = false;
				break;
			default:
				this.twelveMonths = true;
				this.twentyFourMonths = false;
				this.thirtySixMonths = false;
		}
	}

	@action
	updateRollingBasisMonths(months: number) {
		this.customBasis = null;
		this.rollingBasisMonths = +months;
	}

	@action
	openModal() {
		if (this.rollingBasisMonths == 12) this.twelveMonths = true;
		if (this.rollingBasisMonths == 24) this.twentyFourMonths = true;
		if (this.rollingBasisMonths == 36) this.thirtySixMonths = true;
		this.showModal = true;
	}

	@action
	closeModal() {
		this.errorMessage = null;
		this.rollingBasisMonths = 12;
		this._customBasis = null;
		this.showModal = false;
		this.twelveMonths = true;
		this.twentyFourMonths = false;
		this.thirtySixMonths = false;
	}

	@action
	async submit() {
		const data = {
			rollingBasisNumberOfMonths: this.rollingBasisMonths,
			customBasis: this.customBasis ? +this.customBasis : null,
		};

		const mutation = await this.setEverAgBasisOnLocation.mutate({
			data: data,
			id: this.args.locationId,
		});
		await mutation;
		this.showModal = false;
	}
}
