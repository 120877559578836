import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<span {{did-insert this.fetchAvailableCropYears}}>\n  <Vault::UiDateFilter\n  @dateRangeOptions={{this.makeDateOptions}}\n  @currentValue={{this.formatSelectedDate}}\n  @onChange={{this.setYearDate}}\n  @ariaLabel='Filter targets by Crop Year'\n  @uid={{@uid}}\n  @label='Crop Year'\n  class='sm:mr-3 mt-2'\n/>\n</span>", {"contents":"<span {{did-insert this.fetchAvailableCropYears}}>\n  <Vault::UiDateFilter\n  @dateRangeOptions={{this.makeDateOptions}}\n  @currentValue={{this.formatSelectedDate}}\n  @onChange={{this.setYearDate}}\n  @ariaLabel='Filter targets by Crop Year'\n  @uid={{@uid}}\n  @label='Crop Year'\n  class='sm:mr-3 mt-2'\n/>\n</span>","moduleName":"vault-client/components/crop-year-filter.hbs","parseOptions":{"srcName":"vault-client/components/crop-year-filter.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { DateTime } from 'luxon';
import { gql, useQuery } from 'glimmer-apollo';
import { Query } from 'vault-client/types/graphql-types';

interface cropyearSelectArgs {
	fetchAvailableCropYears?: Function;
	cropYearEndDate: number;
	cropYearStartDate: number;
	setYearStartDate: Function;
	setYearEndDate: Function;
}

type DateFilterOptions = {
	displayName?: string | undefined;
	startDate?: string | undefined;
	endDate?: string | undefined;
};

const GET_CROP_YEARS = gql`
	query GrainCropYears {
		GrainCropYears(orderBy: { year: Asc }) {
			id
			year
		}
	}
`;

export default class cropyearSelect extends Component<cropyearSelectArgs> {
	@tracked cropYear = '';
	@tracked availableCropYears: number[] | null = null;

	get formatSelectedDate() {
		return {
			startDate: DateTime.fromFormat(`${this.args.cropYearStartDate}`, 'yyyy').startOf('year').toISODate(),
			endDate: DateTime.fromFormat(`${this.args.cropYearEndDate}`, 'yyyy').endOf('year').toISODate(),
		};
	}

	get makeDateOptions() {
		const options: DateFilterOptions[] = [
			{
				displayName: 'All Years',
				startDate: '1900-01-01',
				endDate: '2999-12-31',
			},
		];

		this.availableCropYears
			? this.availableCropYears.forEach((year) => {
					const optionsObj: DateFilterOptions = {};
					optionsObj.displayName = `${year}`;
					optionsObj.startDate = DateTime.fromFormat(`${year}`, 'yyyy').startOf('year').toISODate();
					optionsObj.endDate = DateTime.fromFormat(`${year}`, 'yyyy').endOf('year').toISODate();
					options.push(optionsObj);
			  })
			: null;

		return options;
	}

	getCropYears = useQuery<{ GrainCropYears: Query['GrainCropYears'] }>(this, () => [
		GET_CROP_YEARS,
		{
			onComplete: (data) => {
				this.availableCropYears = data?.GrainCropYears?.map((cropYear) => cropYear.year) ?? [];
			},
		},
	]);

	@action
	async fetchAvailableCropYears() {
		await this.getCropYears;
	}

	@action
	setYearDate(dateObj: { endDate: string; startDate: string }) {
		const { startDate, endDate } = dateObj;
		this.args.setYearStartDate(DateTime.fromFormat(startDate, 'yyyy-MM-dd').year);
		this.args.setYearEndDate(DateTime.fromFormat(endDate, 'yyyy-MM-dd').year);
	}
}
