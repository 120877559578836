import ReportsDryWheyStrategicBuyingModel from 'vault-client/controllers/reports/dry-whey-strategic-buying-model';

export default class OrganizationsOrganizationDryWheyStrategicBuyingModel extends ReportsDryWheyStrategicBuyingModel {
	reportsRoute: string = 'organizations.organization.reports';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'organizations/organization/dry-whey-strategic-buying-model': OrganizationsOrganizationDryWheyStrategicBuyingModel;
	}
}
