import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<span {{did-insert this.fetchCrops}}>\n\t<PowerSelect\n\t\t@selected={{@selected}}\n\t\t@options={{this.crops}}\n\t\t@searchEnabled='true'\n\t\t@renderInPlace={{true}}\n\t\t@searchField='name'\n\t\t@placeholder='Select a crop'\n\t\t@onChange={{@onChange}}\n\t\tas |crop|\n\t>\n\t\t{{crop.name}}\n\t</PowerSelect>\n</span>", {"contents":"<span {{did-insert this.fetchCrops}}>\n\t<PowerSelect\n\t\t@selected={{@selected}}\n\t\t@options={{this.crops}}\n\t\t@searchEnabled='true'\n\t\t@renderInPlace={{true}}\n\t\t@searchField='name'\n\t\t@placeholder='Select a crop'\n\t\t@onChange={{@onChange}}\n\t\tas |crop|\n\t>\n\t\t{{crop.name}}\n\t</PowerSelect>\n</span>","moduleName":"vault-client/components/crop-select.hbs","parseOptions":{"srcName":"vault-client/components/crop-select.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { gql, useQuery } from 'glimmer-apollo';
import { CropCategory, Query, Query_CropCategoriesArgs } from 'vault-client/types/graphql-types';

const GET_CROPS = gql`
	query CropCategories {
		CropCategories(orderBy: { name: Asc }) {
			id
			name
		}
	}
`;

interface CropSelectArgs {
	onChange: (crop: CropCategory) => void;
	selected: CropCategory;
}

export default class CropSelect extends Component<CropSelectArgs> {
	@tracked crops: CropCategory[] = [];

	@action
	async fetchCrops() {
		await useQuery<{ CropCategories: Query['CropCategories'] }, Query_CropCategoriesArgs>(this, () => [
			GET_CROPS,
			{
				onComplete: (data) => {
					if (data?.CropCategories) {
						this.crops = data.CropCategories;
					} else {
						this.crops = [];
					}
				},
				onError: (error) => {
					console.error('Error on Crop select', error.message);
				},
			},
		]).promise;
	}
}
