import { Future, Product } from 'vault-client/types/graphql-types';
import { setOwner } from '@ember/application';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { percentile, percentRank } from 'vault-client/utils/percentile';
import { Settlement } from 'vault-client/services/market-data';
import { DateTime } from 'luxon';

export default class DairyMargin {
	@service marketData: any;

	@tracked registeredSymbols: string[] = [];

	classIIIFuture: Future;
	cornFuture: Future;
	soybeanMealFuture: Future;
	classIIIProduct: Product;
	cornProduct: Product;
	soybeanMealProduct: Product;
	numberOfYears: number;

	constructor(
		owner: any,
		classIIIFuture: Future,
		cornFuture: Future,
		soybeanMealFuture: Future,
		classIIIProduct: Product,
		cornProduct: Product,
		soybeanMealProduct: Product,
		numberOfYears: number
	) {
		setOwner(this, owner);
		this.classIIIFuture = classIIIFuture;
		this.cornFuture = cornFuture;
		this.soybeanMealFuture = soybeanMealFuture;
		this.classIIIProduct = classIIIProduct;
		this.cornProduct = cornProduct;
		this.soybeanMealProduct = soybeanMealProduct;
		this.numberOfYears = numberOfYears;
		if (this.classIIIFuture.barchartSymbol) {
			this.registerMonthSymbols(this.classIIIFuture.barchartSymbol);
			this.marketData.registerHistoricalRange(this.classIIIFuture.barchartSymbol);
		}
		if (this.cornFuture.barchartSymbol) {
			this.registerMonthSymbols(this.cornFuture.barchartSymbol);
			this.marketData.registerHistoricalRange(this.cornFuture.barchartSymbol);
		}
		if (this.soybeanMealFuture.barchartSymbol) {
			this.registerMonthSymbols(this.soybeanMealFuture.barchartSymbol);
			this.marketData.registerHistoricalRange(this.soybeanMealFuture.barchartSymbol);
		}
	}

	get classIIIPrice() {
		const classIIIMarketDatum = this.marketData.getMarketDatum(this.classIIIFuture?.barchartSymbol);
		return classIIIMarketDatum?.lastPrice;
	}

	get cornPrice() {
		const cornMarketDatum = this.marketData.getMarketDatum(this.cornFuture?.barchartSymbol);

		return cornMarketDatum?.lastPrice;
	}

	get soybeanMealPrice() {
		const soybeanMealMarketDatum = this.marketData.getMarketDatum(this.soybeanMealFuture?.barchartSymbol);
		return soybeanMealMarketDatum?.lastPrice;
	}

	get margin() {
		return (
			this.classIIIPrice * this.classIIIDisplayFactor -
			this.cornPrice * this.cornDisplayFactor * 1.0728 -
			this.soybeanMealPrice * this.soybeanMealDisplayFactor * 0.00735
		);
	}

	get classIIIDisplayFactor() {
		return this.classIIIProduct.CurrentFutures[0].SymbolGroup.displayFactor;
	}

	get cornDisplayFactor() {
		return this.cornProduct.CurrentFutures[0].SymbolGroup.displayFactor;
	}

	get soybeanMealDisplayFactor() {
		return this.soybeanMealProduct.CurrentFutures[0].SymbolGroup.displayFactor;
	}

	get classIIIFractionDigits() {
		return this.classIIIProduct.CurrentFutures[0].SymbolGroup.fractionDigits;
	}

	get cornFractionDigits() {
		return this.cornProduct.CurrentFutures[0].SymbolGroup.fractionDigits;
	}

	get soybeanMealFractionDigits() {
		return this.soybeanMealProduct.CurrentFutures[0].SymbolGroup.fractionDigits;
	}

	get historicMargin() {
		// Get the historical range data for each product - dmw
		const classIIIHistoricalData = this.marketData.getMarketDataHistoricalRange(this.classIIIFuture?.barchartSymbol);
		const cornHistoricalData = this.marketData.getMarketDataHistoricalRange(this.cornFuture?.barchartSymbol);
		const soybeanMealHistoricalData = this.marketData.getMarketDataHistoricalRange(this.soybeanMealFuture?.barchartSymbol);

		if (
			classIIIHistoricalData?.get('settlements') &&
			cornHistoricalData?.get('settlements') &&
			soybeanMealHistoricalData?.get('settlements')
		) {
			const _historicMargin: Array<number> = [];

			// We need each settlement for each product going back the number of years the user specified - dmw
			const classIIISettlementsDatums = classIIIHistoricalData
				.get('settlements')
				.slice(0, this.numberOfYears)
				.sort(
					(firstSettlement: Settlement, secondSettlement: Settlement) =>
						DateTime.fromISO(firstSettlement.expirationDate).toMillis() - DateTime.fromISO(secondSettlement.expirationDate).toMillis()
				);

			const cornSettlementsDatums = cornHistoricalData
				.get('settlements')
				.slice(0, this.numberOfYears)
				.sort(
					(firstSettlement: Settlement, secondSettlement: Settlement) =>
						DateTime.fromISO(firstSettlement.expirationDate).toMillis() - DateTime.fromISO(secondSettlement.expirationDate).toMillis()
				);

			const soybeanMealSettlementsDatums = soybeanMealHistoricalData
				.get('settlements')
				.slice(0, this.numberOfYears)
				.sort(
					(firstSettlement: Settlement, secondSettlement: Settlement) =>
						DateTime.fromISO(firstSettlement.expirationDate).toMillis() - DateTime.fromISO(secondSettlement.expirationDate).toMillis()
				);

			// create an array of historic prices for each product - dmw
			const classIIISettlements: number[] = classIIISettlementsDatums.map((settlement: { close: string; lastPrice: number }) => {
				return settlement.close ?? settlement.lastPrice;
			});

			const cornSettlements: number[] = cornSettlementsDatums.map((settlement: { close: string; lastPrice: number }) => {
				return settlement.close ?? settlement.lastPrice;
			});

			const soybeanMealSettlements: number[] = soybeanMealSettlementsDatums.map((settlement: { close: string; lastPrice: number }) => {
				return settlement.close ?? settlement.lastPrice;
			});

			// For each historic year we need find the margin and push the margin into an array so now we have an array of historic margins that can be used to calculate the historical percentile - dmw
			for (let i = 0; i < this.numberOfYears; i++) {
				const historicMarginYear =
					classIIISettlements[i] * this.classIIIDisplayFactor -
					cornSettlements[i] * this.cornDisplayFactor * 1.0728 -
					soybeanMealSettlements[i] * this.soybeanMealDisplayFactor * 0.00735;

				_historicMargin.push(historicMarginYear);
			}

			return _historicMargin;
		} else {
			return null;
		}
	}

	get twentyFifthPercentileHistoricalMargin() {
		return this.historicMargin ? percentile(this.historicMargin, 0.25) : null;
	}

	get fiftiethPercentileHistoricalMargin() {
		return this.historicMargin ? percentile(this.historicMargin, 0.5) : null;
	}

	get seventyFifthPercentileHistoricalMargin() {
		return this.historicMargin ? percentile(this.historicMargin, 0.75) : null;
	}

	get ninetiethPercentileHistoricalMargin() {
		return this.historicMargin ? percentile(this.historicMargin, 0.9) : null;
	}

	get marginPercentRank() {
		return this.historicMargin && this.margin ? percentRank(this.historicMargin, this.margin) : null;
	}

	registerMonthSymbols(symbol: string) {
		if (!this.registeredSymbols.includes(symbol)) {
			this.marketData.register(symbol);
			this.registeredSymbols.push(symbol);
		}
	}

	willDestroy() {
		this.registeredSymbols.forEach((symbol) => {
			this.marketData.unregister(symbol);
		});
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
//@ts-ignore
declare module 'ember-data/types/registries/model' {
	export default interface ModelRegistry {
		'strategic-inventory-model': DairyMargin;
	}
}
