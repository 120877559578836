import Route from '@ember/routing/route';
import { tracked } from '@glimmer/tracking';
import { gql, useQuery } from 'glimmer-apollo';
import {
	Query,
	LrpInsuranceEndorsementSortByDTO,
	LrpInsuranceEndorsementFilterDTO,
	InsuranceEndorsementAllocationRatioFilterDTO,
	InsuranceEndorsementAllocationRatioSortByDTO,
	SortByDirection,
	Swap,
	Future,
	Option,
	Swaption,
	TypeOfInstrument,
} from 'vault-client/types/graphql-types';
import { DateTime } from 'luxon';
import { ModelFrom } from 'vault-client/utils/type-utils';
import { service } from '@ember/service';
import MarketDataService from 'vault-client/services/market-data';
import PermissionsService from 'vault-client/services/permissions';
import Transition from '@ember/routing/transition';
import { InsuranceProducts } from 'vault-client/utils/exposure-utils';

const HEDGE_MONTH_DETAIL = gql`
	query HedgeMonthDetail($scopeId: String!, $slug: String!, $startDate: String!, $endDate: String!) {
		SwineRevenue: AggregateSwineLivestockPopulationChanges(
			scopeId: $scopeId
			calc: { sum: { totalValue: true, quantity: true } }
			groupBy: {}
			where: { reasonType: { equals: Sale }, date: { gte: $startDate, lte: $endDate }, valueType: { equals: Forecasted } }
		) {
			sum {
				totalValue
				quantity
			}
		}
		ClassIIIFutures: Futures(
			where: {
				isStandardContractSize: { equals: true }
				Product: { slug: { equals: "us-dairy-class-iii" } }
				displayExpiresAt: { gte: $startDate, lte: $endDate }
			}
			orderBy: { displayExpiresAt: Asc }
		) {
			id
			displayExpiresAt
			barchartSymbol
		}
		ClassIVFutures: Futures(
			where: {
				isStandardContractSize: { equals: true }
				Product: { slug: { equals: "us-dairy-class-iv" } }
				displayExpiresAt: { gte: $startDate, lte: $endDate }
			}
			orderBy: { displayExpiresAt: Asc }
		) {
			id
			displayExpiresAt
			barchartSymbol
		}
		ForecastedMilkProductionByMonths(
			where: { date: { gte: $startDate, lte: $endDate } }
			orderBy: { date: Asc }
			scopeId: $scopeId
			limit: 2000
		) {
			id
			numberOfCows
			date
			grossProduction
			grossButterfatProduction
			grossProteinProduction
			grossOtherSolidsProduction
			grossClassiProduction
			grossClassiiProduction
			grossClassiiiProduction
			grossClassivProduction
			UsdaActualMilkPrice {
				id
				classiPrice
				classiiPrice
				classiiiPrice
				classivPrice
			}
		}
		ExposureProducts: Products(where: { Instruments: { type: { equals: Future } } }, orderBy: { name: Asc }) {
			id
			name
			slug
		}
		AggregateEntityAllocatedExposureRatios(
			calc: {
				sum: {
					brokerageVolumeHedged: true
					drpVolumeHedged: true
					productionExposure: true
					lgmVolumeHedged: true
					lrpVolumeHedged: true
					totalVolumeHedged: true
					physicalVolumeHedged: true
				}
			}
			groupBy: {}
			where: { startDate: $startDate, endDate: $endDate, Product: { slug: { equals: $slug } }, entityId: { equals: $scopeId } }
		) {
			sum {
				brokerageVolumeHedged
				drpVolumeHedged
				lgmVolumeHedged
				lrpVolumeHedged
				physicalVolumeHedged
				productionExposure
			}
		}
		Products(where: { slug: { equals: $slug } }) {
			id
			name
			slug
			CashInstrument {
				id
				name
				type
				barchartSymbol
				SymbolGroup {
					id
					fractionDigits
					displayFactor
				}
			}
			StandardProductLotSpecification {
				tickValue
				pointValue
				lotSize
			}
		}
		PricingFuture: Futures(
			orderBy: { displayExpiresAt: Asc }
			where: { Product: { slug: { equals: $slug } }, displayExpiresAt: { gte: $startDate } }
			limit: 1
		) {
			id
			name
			type
			displayExpiresAt
			barchartSymbol
			SymbolGroup {
				id
				fractionDigits
				displayFactor
			}
		}
		CurrentAllocationPositions(
			scopeId: $scopeId
			where: { effectiveHedgeDate: { gte: $startDate, lte: $endDate }, Product: { slug: { equals: $slug } } }
		) {
			id
			effectiveHedgeDate
			instrumentType
			contractQuantity
			unitQuantity
			lifetimeWeightedAveragePrice
			openSideWeightedAveragePrice
			positionId
			positionSide
			optionType
			grossPnl
			effectiveHedgeDate
			Account {
				accountNumber
			}
			PositionComponentAllocations {
				id
				price
				netContractQuantity
				Instrument {
					id
					type
					SymbolGroup {
						id
						fractionDigits
						displayFactor
					}
					... on Option {
						exchangeSymbol
						strike
						barchartSymbol
						optionType
					}
					... on Future {
						exchangeSymbol
						barchartSymbol
					}
					... on Swap {
						PriceInstrument {
							id
							... on Future {
								id
								barchartSymbol
							}
						}
					}

					... on Swaption {
						optionType
						strike
						PriceInstrument {
							id
							... on Option {
								barchartSymbol
							}
						}
					}
				}
			}
			Instrument {
				type

				Product {
					id
					slug
					MostCurrentFuture {
						name
						id
						barchartSymbol
					}
					CurrentFutures {
						id
						barchartSymbol
					}
				}
				SymbolGroup {
					id
					fractionDigits
					displayFactor
				}
				... on Option {
					id
					exchangeSymbol
					strike
					barchartSymbol
					optionType
					displayExpiresAt
				}
				... on Future {
					id
					exchangeSymbol
					barchartSymbol
					displayExpiresAt
				}
				... on Swap {
					id
					displayExpiresAt
					PriceInstrument {
						id
						... on Future {
							id
							barchartSymbol
						}
					}
				}

				... on Swaption {
					id
					optionType
					displayExpiresAt
					strike
					PriceInstrument {
						id
						... on Option {
							id
							barchartSymbol
						}
					}
				}
			}
			Product {
				id
				slug
				name
			}
		}
	}
`;

const GET_INSURANCE_ENDORSEMENT_ALLOCATION_RATIOS = gql`
	query InsuranceEndorsementAllocationRatios(
		$where: InsuranceEndorsementAllocationRatioFilterDTO
		$orderBy: InsuranceEndorsementAllocationRatioSortByDTO
		$scopeId: String!
	) {
		InsuranceEndorsementAllocationRatios(scopeId: $scopeId, orderBy: $orderBy, where: $where, limit: 2000) {
			id
			insuranceEndorsementId
			allocationRatio
			createdAt
			effectiveHedgeDate
			hasWriteAccess
			insurancePolicyId
			updatedAt
			RatioAdjustedDerivedDrpInsuranceEndorsement {
				id
				indemnity
				pnl
				effectiveCoveredMilkProduction
				classPriceWeightingFactor
				componentPriceWeightingFactor
				producerPremiumAmount
				cheeseGrossProtection
				butterGrossProtection
				expectedClassIiiPrice
				expectedClassIvPrice
				coverageLevelPercent
			}
			RatioAdjustedInsuranceEndorsement {
				id
				InsurancePolicy {
					id
					producerName
				}

				... on LgmInsuranceEndorsement {
					RmaType {
						id
						typeName
						typeCode
					}
					id
					perMonthData
					type
					coverageMonths
					grossMarginGuarantee
					totalTarget
					totalPremiumAmount
					totalExpectedGrossMargin
					pnl
					producerPremiumAmount
					deductibleAmount
					salesEffectiveDate
					revenueHedgeProductId
					indemnity
				}
				... on LrpInsuranceEndorsement {
					InsurancePolicy {
						id
						producerName
					}
					RmaCommodity {
						id
						commodityName
					}
					coverageEndDate
					coveragePrice
					salesEffectiveDate
					type
					totalPremiumAmount
					pnl
					commodityPrice
					insuredValue
					headCount
					revenueHedgeProductId
					targetWeightCwt
					producerPremiumAmount
					targetWeightCwt
					indemnity
				}
				... on DrpInsuranceEndorsement {
					pnl
					salesEffectiveDate
					producerPremiumAmount
					type
					updatedAt
					indemnity
					DerivedEndorsement {
						declaredCoveredMilkProduction
						effectiveCoveredMilkProduction
						quarterStartDate
						classIiiGrossProtection
						classIvGrossProtection
						cheeseGrossProtection
						butterGrossProtection
					}
				}
			}
		}
	}
`;

const GET_FEED_TRANSACTIONS = gql`
	query FeedTransactions($scopeId: String!, $startDate: String!, $endDate: String!, $slug: String!) {
		FeedTransactions(
			scopeId: $scopeId
			where: {
				OR: [{ deliveryStartDate: { gte: $startDate, lte: $endDate } }, { deliveryEndDate: { gte: $startDate, lte: $endDate } }]
				FeedIngredient: { FeedCategory: { HedgeProduct: { slug: { equals: $slug } } } }
			}
		) {
			id
			tons
			seller
			location
			flatPrice
			deliveryStartDate
			deliveryEndDate
			contractIdentifier
			FeedIngredient {
				id
				name
			}
			Business {
				id
				name
			}
		}
	}
`;

const GET_DAIRY_RELATED_DATA = gql`
	query DairyRelatedData($scopeId: String!, $startDate: String!, $endDate: String!, $slug: String!) {
		ClassIIIExposure: AggregateEntityAllocatedExposureRatios(
			calc: { sum: { productionExposure: true, brokerageVolumeHedged: true, drpVolumeHedged: true, totalVolumeHedged: true } }
			groupBy: {}
			where: {
				startDate: $startDate
				endDate: $endDate
				Product: { slug: { equals: "us-dairy-class-iii" } }
				entityId: { equals: $scopeId }
			}
		) {
			sum {
				productionExposure
				brokerageVolumeHedged
				drpVolumeHedged
				totalVolumeHedged
			}
		}
		ClassIVExposure: AggregateEntityAllocatedExposureRatios(
			calc: { sum: { productionExposure: true, brokerageVolumeHedged: true, drpVolumeHedged: true, totalVolumeHedged: true } }
			groupBy: {}
			where: {
				startDate: $startDate
				endDate: $endDate
				Product: { slug: { equals: "us-dairy-class-iv" } }
				entityId: { equals: $scopeId }
			}
		) {
			sum {
				productionExposure
				brokerageVolumeHedged
				drpVolumeHedged
				totalVolumeHedged
			}
		}
		SelectedProductExposure: AggregateEntityAllocatedExposureRatios(
			calc: { sum: { productionExposure: true, brokerageVolumeHedged: true, drpVolumeHedged: true, totalVolumeHedged: true } }
			groupBy: {}
			where: { startDate: $startDate, endDate: $endDate, Product: { slug: { equals: $slug } }, entityId: { equals: $scopeId } }
		) {
			sum {
				productionExposure
				brokerageVolumeHedged
				drpVolumeHedged
				totalVolumeHedged
			}
		}
		AggregateDairyBrokerageHedgedValues: AggregateAllocatedForecastedHedgedAndCappedVolumes(
			where: {
				entityId: { equals: $scopeId }
				hedgeType: { equals: Brokerage }
				Product: { slug: { in: ["us-dairy-class-iii", "us-dairy-class-iv"] } }
				date: { gte: $startDate, lte: $endDate }
			}
			groupBy: { Product: { slug: true } }
			calc: { sum: { naturallyLongHedged: true } }
		) {
			Product {
				slug
			}
			sum {
				naturallyLongHedged
			}
		}
	}
`;

const GET_FORECASTED_FEED_USAGE = gql`
	query ForecastedFeedUsage($entityId: String!, $slug: String!, $startDate: String!, $endDate: String!) {
		DairyFeedUsage: AggregateFeedIngredientForecastedUsages(
			scopeId: $entityId
			calc: { sum: { dmiUsageInTons: true } }
			groupBy: {}
			where: {
				FeedIngredient: {
					FeedCategory: { HedgeProduct: { slug: { equals: $slug } } }
					FeedIngredientVersion: { isCurrent: { equals: true } }
				}
				date: { gte: $startDate, lte: $endDate }
			}
		) {
			sum {
				dmiUsageInTons
			}
		}
		SwineFeedUsage: AggregateForecastedSwineLivestockFeedUsages(
			scopeId: $entityId
			where: {
				date: { gt: $startDate, lt: $endDate }
				LivestockGroupFeedUsage: { Version: { isCurrent: { equals: true } } }
				Ingredient: { FeedCategory: { HedgeProduct: { slug: { equals: $slug } } } }
			}
			calc: { sum: { quantityInTons: true } }
			groupBy: { Ingredient: { dryMatterPercent: true } }
		) {
			sum {
				quantityInTons
			}
			Ingredient {
				dryMatterPercent
			}
		}
	}
`;

interface ModelParams {
	date: string;
	slug: string;
	scopeId: string;
	exposureMonthStartDate: string;
	exposureMonthEndDate: string;
}

interface HedgeMonthDetailQueryArgs {
	scopeId: string;
	slug: string;
	startDate: string;
	endDate: string;
}

interface HedgeMonthDetailQuery {
	AggregateEntityAllocatedExposureRatios: Query['AggregateEntityAllocatedExposureRatios'];
	Products: Query['Products'];
	ExposureProducts: Query['Products'];
	SwineRevenue: Query['AggregateSwineLivestockPopulationChanges'];
	ForecastedMilkProductionByMonths: Query['ForecastedMilkProductionByMonths'];
	ActualBlendedMilkPrices: Query['ActualBlendedMilkPrices'];
	ClassIIIFutures: Query['Futures'];
	ClassIVFutures: Query['Futures'];
	CurrentAllocationPositions: Query['CurrentAllocationPositions'];
	FeedTransactions: Query['FeedTransactions'];
	PricingFuture: Query['Futures'];
}

interface GetLgmEndorsementAllocationRatios {
	InsuranceEndorsementAllocationRatios: Query['InsuranceEndorsementAllocationRatios'];
}

interface getLrpEndorsementAllocationRatios {
	InsuranceEndorsementAllocationRatios: Query['InsuranceEndorsementAllocationRatios'];
}

interface getDrpEndorsementAllocationRatios {
	InsuranceEndorsementAllocationRatios: Query['InsuranceEndorsementAllocationRatios'];
}

interface GetForecastedFeedUsage {
	DairyFeedUsage: Query['AggregateFeedIngredientForecastedUsages'];
	SwineFeedUsage: Query['AggregateForecastedSwineLivestockFeedUsages'];
}

interface getDairyRelatedData {
	ClassIIIExposure: Query['AggregateEntityAllocatedExposureRatios'];
	ClassIVExposure: Query['AggregateEntityAllocatedExposureRatios'];
	SelectedProductExposure: Query['AggregateEntityAllocatedExposureRatios'];
	AggregateDairyBrokerageHedgedValues: Query['AggregateAllocatedForecastedHedgedAndCappedVolumes'];
}

interface LgmQueryArgs {
	where: InsuranceEndorsementAllocationRatioFilterDTO;
	orderBy: InsuranceEndorsementAllocationRatioSortByDTO;
}

interface LrpQueryArgs {
	where: LrpInsuranceEndorsementFilterDTO;
	lrpOrderBy: LrpInsuranceEndorsementSortByDTO;
	SwineRevenue: Query['AggregateSwineLivestockPopulationChanges'];
}

interface DrpQueryArgs {
	where: InsuranceEndorsementAllocationRatioFilterDTO;
	orderBy: InsuranceEndorsementAllocationRatioSortByDTO;
	scopeId: string;
}

interface DairyRelatedDataQueryArgs {
	scopeId: string;
	startDate: string;
	endDate: string;
	slug: string;
}

interface FeedTransactionQueryArgs {
	scopeId: string;
	slug: string;
	startDate: string;
	endDate: string;
}

interface GetForecastedFeedUsageArgs {
	entityId: string;
	slug: string;
	startDate: string;
	endDate: string;
}

export default class ExposureHedgeMonthDetailRoute extends Route {
	queryParams = {
		scopeId: {
			refreshModel: true,
		},
		exposureMonthStartDate: {
			refreshModel: true,
		},
		exposureMonthEndDate: {
			refreshModel: true,
		},
		sorts: { refreshModel: true },
	};

	templateName = 'exposure/exposure-hedge-month-detail';

	@service marketData!: MarketDataService;
	@service declare permissions: PermissionsService;

	@tracked exposureMonthStartDate: string = DateTime.local().startOf('month').toISODate();
	@tracked exposureMonthEndDate: string = DateTime.local().endOf('month').toISODate();
	@tracked scopeId: string = '';
	@tracked variables: HedgeMonthDetailQueryArgs = {
		scopeId: '',
		slug: '',
		startDate: '',
		endDate: '',
	};

	@tracked drpVariables: DrpQueryArgs = {
		where: {},
		orderBy: {},
		scopeId: '',
	};
	@tracked lgmVariables: any = {};
	@tracked lrpVariables: any = {};
	@tracked brokerageVariables: any = {};
	@tracked feedTransactionVariables: FeedTransactionQueryArgs = {
		scopeId: '',
		slug: '',
		startDate: '',
		endDate: '',
	};
	@tracked forecastedFeedUsageVariables: GetForecastedFeedUsageArgs = {
		entityId: '',
		slug: '',
		startDate: '',
		endDate: '',
	};

	@tracked dairyRelatedDataVariables: DairyRelatedDataQueryArgs = {
		scopeId: '',
		startDate: '',
		endDate: '',
		slug: '',
	};

	registeredFutures: string[] = [];

	insuranceProducts = InsuranceProducts;

	physicalProducts = ['grain-soybean-meal', 'grain-corn'];

	feedProducts = ['grain-soybean-meal', 'grain-corn'];

	dairyProducts = [
		'us-dairy-class-iii',
		'us-dairy-class-iv',
		'us-dairy-cheese',
		'us-dairy-butter',
		'us-dairy-nonfat-milk',
		'us-dairy-dry-whey',
	];

	getExposureHedgeMonthDetail = useQuery<HedgeMonthDetailQuery, HedgeMonthDetailQueryArgs>(this, () => [
		HEDGE_MONTH_DETAIL,
		{ variables: this.variables },
	]);

	getLgmEndorsementAllocationRatios = useQuery<GetLgmEndorsementAllocationRatios, LgmQueryArgs>(this, () => [
		GET_INSURANCE_ENDORSEMENT_ALLOCATION_RATIOS,
		{
			variables: this.lgmVariables,
			fetchPolicy: 'no-cache',
		},
	]);

	getLrpEndorsementAllocationRatios = useQuery<getLrpEndorsementAllocationRatios, LrpQueryArgs>(this, () => [
		GET_INSURANCE_ENDORSEMENT_ALLOCATION_RATIOS,
		{
			variables: this.lrpVariables,
			fetchPolicy: 'no-cache',
		},
	]);

	getDrpEndorsementAllocationRatios = useQuery<getDrpEndorsementAllocationRatios, DrpQueryArgs>(this, () => [
		GET_INSURANCE_ENDORSEMENT_ALLOCATION_RATIOS,
		{
			variables: this.drpVariables,
			fetchPolicy: 'no-cache',
		},
	]);

	getDairyRelatedData = useQuery<getDairyRelatedData, DairyRelatedDataQueryArgs>(this, () => [
		GET_DAIRY_RELATED_DATA,
		{
			variables: this.dairyRelatedDataVariables,
		},
	]);

	getFeedTransactions = useQuery<{ FeedTransactions: Query['FeedTransactions'] }, FeedTransactionQueryArgs>(this, () => [
		GET_FEED_TRANSACTIONS,
		{ variables: this.feedTransactionVariables },
	]);

	getForecastedFeedUsage = useQuery<GetForecastedFeedUsage, GetForecastedFeedUsageArgs>(this, () => [
		GET_FORECASTED_FEED_USAGE,
		{ variables: this.forecastedFeedUsageVariables },
	]);

	get foundInsuranceProduct() {
		return this.insuranceProducts.find((s) => s.slug === this.variables.slug);
	}

	get isLgm(): boolean {
		return this.insuranceProducts.some((e) => e.type.includes('lgm') && e.slug === this.foundInsuranceProduct?.slug);
	}

	get isDrp(): boolean {
		return this.insuranceProducts.some((e) => e.type.includes('drp') && e.slug === this.foundInsuranceProduct?.slug);
	}

	get isLrp() {
		return this.insuranceProducts.some((e) => e.type.includes('lrp') && e.slug === this.foundInsuranceProduct?.slug);
	}

	get isDairyProduct() {
		return this.dairyProducts.includes(this.variables.slug);
	}

	get isPhysical() {
		return this.physicalProducts.includes(this.variables.slug);
	}

	get isFeed() {
		return this.feedProducts.includes(this.variables.slug);
	}

	beforeModel(_transition: Transition<unknown>): void {
		// Unregister any futures that were previously registered
		if (this.registeredFutures.length) {
			this.registeredFutures.forEach((symbol) => this.marketData.unregister(symbol));
		}

		this.registeredFutures = [];
	}

	// Remove for Production
	// Define a function to generate a random float within a specified range
	getRandomFloat(min: number, max: number, precision: number): number {
		const random = Math.random() * (max - min) + min;
		return parseFloat(random.toFixed(precision));
	}

	// Remove for Production
	// Define a function to generate a range of numbers with specified increments
	generateRange(min: number, max: number, increment: number): number[] {
		const range: number[] = [];
		for (let i = min; i <= max; i += increment) {
			range.push(i);
		}
		return range;
	}

	// Remove for Production
	get stubNetHedgeData() {
		// Generate an array of 10 objects
		const arrayOfObjects: { [key: string]: number }[] = [];
		const historicalPrices: number[] = this.generateRange(30, 90, 5);

		for (let i = 0; i < 10; i++) {
			const obj: { [key: string]: number } = {};

			const currentMarketPrice = this.getRandomFloat(40.0, 90.0, 2);
			const netHedgeCwt = this.getRandomFloat(40.0, 90.0, 2);
			const futurePl = this.getRandomFloat(10000.0, 15000.0, 2);
			const drpPl = this.getRandomFloat(10000.0, 15000.0, 2);
			const lrpPl = this.getRandomFloat(10000.0, 15000.0, 2);
			const lgmPl = this.getRandomFloat(10000.0, 15000.0, 2);
			const netHedgedPl = this.getRandomFloat(10000.0, 15000.0, 2);

			obj['currentMarketPrice'] = currentMarketPrice;
			obj['netHedgeCwt'] = netHedgeCwt;
			obj['historicalPrice'] = historicalPrices[i];
			obj['futurePl'] = futurePl;
			obj['drpPl'] = drpPl;
			obj['lrpPl'] = lrpPl;
			obj['lgmPl'] = lgmPl;
			obj['netHedgedPl'] = netHedgedPl;

			arrayOfObjects.push(obj);
		}

		return arrayOfObjects;
	}

	async model(params: ModelParams) {
		const slug = params.slug;
		const scopeId = this.scopeId;
		const startDate = params.exposureMonthStartDate;
		const endDate = params.exposureMonthEndDate;
		const promises: Promise<unknown>[] = [];

		this.variables = {
			scopeId,
			slug,
			startDate,
			endDate,
		};

		promises.push(this.getExposureHedgeMonthDetail.promise);

		if (this.isLgm) {
			this.lgmVariables = {
				scopeId: this.scopeId,
				orderBy: {
					InsuranceEndorsement: {
						salesEffectiveDate: SortByDirection.Asc,
					},
				},
				where: {
					InsuranceEndorsement: {
						AsLgmInsuranceEndorsement: {
							id: {
								not: null,
							},
							Product: {
								slug: {
									equals: slug,
								},
							},
						},
					},
					effectiveHedgeDate: {
						gte: startDate,
						lte: endDate,
					},
				},
			};

			promises.push(this.getLgmEndorsementAllocationRatios.promise);
		}

		if (this.isLrp) {
			this.lrpVariables = {
				scopeId: this.scopeId,
				orderBy: {
					InsuranceEndorsement: {
						salesEffectiveDate: SortByDirection.Asc,
					},
				},
				where: {
					InsuranceEndorsement: {
						commodityCode: {
							equals: this.foundInsuranceProduct?.code,
						},
						AsLrpInsuranceEndorsement: {
							id: {
								not: null,
							},
						},
					},

					effectiveHedgeDate: {
						gte: startDate,
						lte: endDate,
					},
				},
			};

			promises.push(this.getLrpEndorsementAllocationRatios.promise);
		}

		if (this.isDrp) {
			const key: string = this.foundInsuranceProduct?.endorsementFilterField || '';
			if (key) {
				this.drpVariables = {
					scopeId: this.scopeId,
					orderBy: {
						InsuranceEndorsement: {
							salesEffectiveDate: SortByDirection.Asc,
						},
					},
					where: {
						InsuranceEndorsement: {
							AsDrpInsuranceEndorsement: {
								id: {
									not: null,
								},
								DerivedDrpEndorsement: {
									[key]: {
										gt: 0,
									},
								},
							},
						},
						effectiveHedgeDate: {
							gte: startDate,
							lte: endDate,
						},
					},
				};
			}

			promises.push(this.getDrpEndorsementAllocationRatios.promise);
		}

		if (this.isDairyProduct) {
			this.dairyRelatedDataVariables = {
				scopeId: this.scopeId,
				startDate: startDate,
				endDate: endDate,
				slug: slug,
			};

			promises.push(this.getDairyRelatedData.promise);
		}

		if (this.isPhysical) {
			this.feedTransactionVariables = {
				scopeId: this.scopeId,
				slug: slug,
				startDate: startDate,
				endDate: endDate,
			};

			promises.push(this.getFeedTransactions.promise);
		}

		if (this.isFeed) {
			this.forecastedFeedUsageVariables = {
				entityId: this.scopeId,
				slug: slug,
				startDate: startDate,
				endDate: endDate,
			};

			promises.push(this.getForecastedFeedUsage.promise);
		}

		this.brokerageVariables = {
			where: {
				Instrument: {
					Product: {
						slug: {
							equals: slug,
						},
					},
				},
				effectiveHedgeDate: {
					gte: startDate,
					lte: endDate,
				},
			},
		};

		await Promise.all(promises);

		return {
			slug,
			scopeId,
			isLgm: this.isLgm,
			isLrp: this.isLrp,
			isDrp: this.isDrp,
			isDairyProduct: this.isDairyProduct,
			isPhysical: this.isPhysical,
			isFeed: this.isFeed,
			getExposureHedgeMonthDetail: this.getExposureHedgeMonthDetail,
			getLrpEndorsementAllocationRatios: this.isLrp ? this.getLrpEndorsementAllocationRatios : null,
			getLgmEndorsementAllocationRatios: this.isLgm ? this.getLgmEndorsementAllocationRatios : null,
			getDrpEndorsementAllocationRatios: this.isDrp ? this.getDrpEndorsementAllocationRatios : null,
			getDairyRelatedData: this.isDairyProduct ? this.getDairyRelatedData : null,
			getFeedTransactions: this.isPhysical ? this.getFeedTransactions : null,
			getForecastedFeedUsage: this.isPhysical ? this.getForecastedFeedUsage : null,
			lastUpdatedAt: DateTime.now().toISO(),
			netHedgeData: this.stubNetHedgeData,
		};
	}

	afterModel(model: ModelFrom<ExposureHedgeMonthDetailRoute>): void {
		// Register our futures with the marketData service
		model.getExposureHedgeMonthDetail.data?.ClassIIIFutures?.forEach((future) => {
			const barchartSymbol = future?.barchartSymbol;
			if (!barchartSymbol) return;
			this.registeredFutures.push(barchartSymbol);
			this.marketData.register(barchartSymbol);
		});

		model.getExposureHedgeMonthDetail.data?.ClassIVFutures?.forEach((future) => {
			const barchartSymbol = future?.barchartSymbol;
			if (!barchartSymbol) return;
			this.registeredFutures.push(barchartSymbol);
			this.marketData.register(barchartSymbol);
		});

		model.getExposureHedgeMonthDetail.data?.CurrentAllocationPositions?.forEach((position) => {
			let barchartSymbol: string | null = null;
			let instrument: Future | Option | Swap | Swaption | null = null;
			let priceInstrument: Future | Option | null = null;

			if (position.Instrument.type === TypeOfInstrument.Swap || position.Instrument.type === TypeOfInstrument.Swaption) {
				instrument = position.Instrument as Swap | Swaption;
				priceInstrument = instrument.PriceInstrument as Future | Option;
				barchartSymbol = priceInstrument?.barchartSymbol ?? null;
			} else {
				instrument = position.Instrument as Future | Option;
				barchartSymbol = instrument?.barchartSymbol ?? null;
			}

			if (!barchartSymbol) return;

			this.registeredFutures.push(barchartSymbol);
			this.marketData.register(barchartSymbol);
		});
	}
}
