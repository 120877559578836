import Route from '@ember/routing/route';
import query from 'vault-client/graphql/queries/markets/all-products.graphql';
import { queryManager } from 'ember-apollo-client';

export default class MarketsIndexRoute extends Route {
	@queryManager apollo: any;

	async model() {
		return this.apollo.watchQuery({ query });
	}
}
