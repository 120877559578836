import { getOwner } from '@ember/application';
import Service from '@ember/service';
import ApplicationController from 'vault-client/controllers/application';

export type Scope = 'organization' | 'business' | 'location' | null;

export default class ApplicationSpinner extends Service {
	get applicationController() {
		return getOwner(this).lookup('controller:application') as ApplicationController;
	}

	get isLoading() {
		return this.applicationController.isLoading;
	}

	start() {
		this.applicationController.isLoading = true;
	}

	stop() {
		this.applicationController.isLoading = false;
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'application-spinner': ApplicationSpinner;
	}
}
