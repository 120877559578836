import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import BusinessesBusinessTransaction from 'vault-client/routes/businesses/business/transaction';
import OrganizationsOrganizationTransaction from 'vault-client/routes/organizations/organization/transaction';
import { ModelFrom } from 'vault-client/utils/type-utils';

export default class TransactionsShowController extends Controller {
	@service permissions: any;
	transactionsRoutePath: string = '';

	declare model: ModelFrom<BusinessesBusinessTransaction> | ModelFrom<OrganizationsOrganizationTransaction>;

	get showModificationButtons() {
		return this.model.data?.Transaction?.inputSource === 'User' && this.permissions.isInternal;
		//The isInternal above needs to be replaced with the line below once the user can choose the owner of transactions to be business or organization
		//this.model.data?.Transaction?.Account?.Owner?.CurrentUserPermissions?.canWriteSwap
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'transactions-show-controller': TransactionsShowController;
	}
}
