import { gql } from 'glimmer-apollo';
import SearchFilterComponent from 'vault-client/components/vault/ui-search-filter';

const GET_LOCATION = gql`
	query GetLocation($id: String!) {
		Location(id: $id) {
			id
			name
		}
	}
`;

export default class LocationIdFilterComponent extends SearchFilterComponent {
	query = GET_LOCATION;
	valuePath = 'Location.name';
}
