import Route from '@ember/routing/route';
import query from 'vault-client/graphql/queries/insurance-overview/index.graphql';
import { queryManager } from 'ember-apollo-client';
import { inject as service } from '@ember/service';
import { DateTime } from 'luxon';
import { DerivedDrpEndorsementFilterDTO, ForecastedMilkProductionByMonthFilterDTO } from 'vault-client/types/graphql-types';

export default class InsuranceOverview extends Route {
	@service applicationScope: any;
	@queryManager apollo: any;

	templateName = 'insurance-overview/index';

	queryParams = {
		agent: {
			refreshModel: true,
		},
		aipId: {
			refreshModel: true,
		},
		stateId: {
			refreshModel: true,
		},
		quarterStartDate: {
			refreshModel: true,
		},
		quarterEndDate: {
			refreshModel: true,
		},
	};

	generateProductionWhere(
		agent: string | null | undefined,
		aipId: string | null | undefined,
		stateId: string | null | undefined,
		quarterStartDate: string | null,
		quarterEndDate: string | null
	): ForecastedMilkProductionByMonthFilterDTO {
		const productionWhere: ForecastedMilkProductionByMonthFilterDTO = {};

		if (quarterStartDate && quarterEndDate) {
			productionWhere.date = {
				gte: DateTime.fromISO(quarterStartDate).startOf('quarter').toISODate(),
				lte: DateTime.fromISO(quarterEndDate).endOf('quarter').toISODate(),
			};
		} else if (quarterStartDate) {
			productionWhere.date = {
				gte: DateTime.fromISO(quarterStartDate).startOf('quarter').toISODate(),
			};
		} else if (quarterEndDate) {
			productionWhere.date = {
				lte: DateTime.fromISO(quarterEndDate).endOf('quarter').toISODate(),
			};
		}

		if (agent) {
			const nameArr = agent.split(' ');
			if (!productionWhere.Entity) productionWhere.Entity = {};
			if (!productionWhere.Entity.AsLocationEntity) productionWhere.Entity.AsLocationEntity = {};
			if (!productionWhere.Entity.AsLocationEntity.Customer) productionWhere.Entity.AsLocationEntity.Customer = {};
			if (!productionWhere.Entity.AsLocationEntity.Customer.InsurancePolicies)
				productionWhere.Entity.AsLocationEntity.Customer.InsurancePolicies = {};

			productionWhere.Entity.AsLocationEntity.Customer.InsurancePolicies.agentFirstName = { equals: nameArr[0] };
			productionWhere.Entity.AsLocationEntity.Customer.InsurancePolicies.agentLastName = { equals: nameArr[1] };
		}

		// TODO: Figure out a better way to do this.  Right now, it isn't reliable due to possible AIP overlap.  This is why it wasn't added for milkUtilizations.
		if (aipId) {
			if (!productionWhere.Entity) productionWhere.Entity = {};
			if (!productionWhere.Entity.AsLocationEntity) productionWhere.Entity.AsLocationEntity = {};
			if (!productionWhere.Entity.AsLocationEntity.Customer) productionWhere.Entity.AsLocationEntity.Customer = {};
			if (!productionWhere.Entity.AsLocationEntity.Customer.InsurancePolicies)
				productionWhere.Entity.AsLocationEntity.Customer.InsurancePolicies = {};

			productionWhere.Entity.AsLocationEntity.Customer.InsurancePolicies.aipId = { equals: aipId };
		}

		if (stateId) {
			if (!productionWhere.Entity) productionWhere.Entity = {};
			if (!productionWhere.Entity.AsLocationEntity) productionWhere.Entity.AsLocationEntity = {};
			if (!productionWhere.Entity.AsLocationEntity.County) productionWhere.Entity.AsLocationEntity.County = {};

			productionWhere.Entity.AsLocationEntity.County.stateId = { equals: stateId };
		}

		return productionWhere;
	}

	// Separate Class Production call used to avoid filtering class production by AIP when filter is selected (desired functionality).
	generateClassProductionWhere(
		agent: string | null | undefined,
		stateId: string | null | undefined,
		quarterStartDate: string | null,
		quarterEndDate: string | null
	): ForecastedMilkProductionByMonthFilterDTO {
		const productionWhere: ForecastedMilkProductionByMonthFilterDTO = {};

		if (quarterStartDate && quarterEndDate) {
			productionWhere.date = {
				gte: DateTime.fromISO(quarterStartDate).startOf('quarter').toISODate(),
				lte: DateTime.fromISO(quarterEndDate).endOf('quarter').toISODate(),
			};
		} else if (quarterStartDate) {
			productionWhere.date = {
				gte: DateTime.fromISO(quarterStartDate).startOf('quarter').toISODate(),
			};
		} else if (quarterEndDate) {
			productionWhere.date = {
				lte: DateTime.fromISO(quarterEndDate).endOf('quarter').toISODate(),
			};
		}

		if (agent) {
			const nameArr = agent.split(' ');
			if (!productionWhere.Entity) productionWhere.Entity = {};
			if (!productionWhere.Entity.AsLocationEntity) productionWhere.Entity.AsLocationEntity = {};
			if (!productionWhere.Entity.AsLocationEntity.Customer) productionWhere.Entity.AsLocationEntity.Customer = {};
			if (!productionWhere.Entity.AsLocationEntity.Customer.InsurancePolicies)
				productionWhere.Entity.AsLocationEntity.Customer.InsurancePolicies = {};

			productionWhere.Entity.AsLocationEntity.Customer.InsurancePolicies.agentFirstName = { equals: nameArr[0] };
			productionWhere.Entity.AsLocationEntity.Customer.InsurancePolicies.agentLastName = { equals: nameArr[1] };
		}

		if (stateId) {
			if (!productionWhere.Entity) productionWhere.Entity = {};
			if (!productionWhere.Entity.AsLocationEntity) productionWhere.Entity.AsLocationEntity = {};
			if (!productionWhere.Entity.AsLocationEntity.County) productionWhere.Entity.AsLocationEntity.County = {};

			productionWhere.Entity.AsLocationEntity.County.stateId = { equals: stateId };
		}

		return productionWhere;
	}

	generateEndorsementWhere(
		agent: string | null | undefined,
		aipId: string | null | undefined,
		stateId: string | null | undefined,
		quarterStartDate: string | null,
		quarterEndDate: string | null
	): DerivedDrpEndorsementFilterDTO {
		const endorsementWhere: DerivedDrpEndorsementFilterDTO = {};

		if (quarterStartDate && quarterEndDate) {
			endorsementWhere.quarterEndDate = {
				gte: quarterStartDate,
				lte: DateTime.fromISO(quarterEndDate).endOf('quarter').toISODate(),
			};
		} else if (quarterStartDate) {
			endorsementWhere.quarterEndDate = { gte: quarterStartDate };
		} else if (quarterEndDate) {
			endorsementWhere.quarterEndDate = { lte: quarterEndDate };
		}

		if (agent) {
			const nameArr = agent.split(' ');

			if (!endorsementWhere.AND) {
				endorsementWhere.AND = [];
			}
			endorsementWhere.AND.push({
				InsurancePolicy: { agentFirstName: { equals: nameArr[0] } },
			});
			endorsementWhere.AND.push({
				InsurancePolicy: { agentLastName: { equals: nameArr[1] } },
			});
		}

		// TODO: Figure out a better way to do this.  Right now, it isn't reliable due to possible AIP overlap.  This is why it wasn't added for milkUtilizations.
		if (aipId) {
			if (endorsementWhere.InsurancePolicy) {
				endorsementWhere.InsurancePolicy.aipId = { equals: aipId };
			} else {
				endorsementWhere.InsurancePolicy = {
					aipId: {
						equals: aipId,
					},
				};
			}
		}

		if (stateId) {
			if (endorsementWhere.InsurancePolicy) {
				endorsementWhere.InsurancePolicy.stateId = { equals: stateId };
			} else {
				endorsementWhere.InsurancePolicy = {
					stateId: {
						equals: stateId,
					},
				};
			}
		}

		return endorsementWhere;
	}

	query = query;

	// Required due to Ember.js bug: https://github.com/emberjs/ember.js/issues/19582
	//@ts-ignore
	activate(transition: any) {
		transition.queryParamsOnly = false;
	}
}
