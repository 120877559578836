import Route from '@ember/routing/route';
import { gql, useQuery } from 'glimmer-apollo';
import { tracked } from '@glimmer/tracking';
import {
	CustomerEntity,
	LedgerExpenseCategory,
	LedgerMilkCheckCategory,
	LedgerRevenueCategory,
	Query_CustomerArgs,
} from 'vault-client/types/graphql-types';

const GET_BUSINESS = gql`
	query Customer($id: String!, $scopeId: String!) {
		Customer(id: $id) {
			id
			name
			createdAt
			updatedAt
			isVgs
			vgsCustomerId
			organizationId
			businessRoles
			hasWriteAccess
			averageFinishAgeInWeeks
			averageFinishWeightInLbs
			CreatedByUser {
				id
				firstName
				lastName
			}
			LastUpdatedByUser {
				id
				firstName
				lastName
			}
			Members {
				id
				permission
				canReadBrokerage
				canWriteBrokerage
				canReadInsurance
				canWriteInsurance
				canReadFeedAndCropPlan
				canWriteFeedAndCropPlan
				canReadOperations
				canWriteOperations
				canReadSwap
				canWriteSwap
				canReadOrganization
				canWriteOrganization
				canReadBusiness
				canWriteBusiness
				canReadEntityGroup
				canWriteEntityGroup
				User {
					id
					firstName
					lastName
					email
				}
				userEmail
				createdAt
			}
			GroupEntries {
				id
				Group {
					id
					name
				}
			}
			CurrentUserPermissions {
				canReadBrokerage
				canWriteBrokerage
				canReadInsurance
				canWriteInsurance
				canReadFeedAndCropPlan
				canWriteFeedAndCropPlan
				canReadOperations
				canWriteOperations
				canReadSwap
				canWriteSwap
				canReadOrganization
				canWriteOrganization
				canReadBusiness
				canWriteBusiness
				canReadEntityGroup
				canWriteEntityGroup
			}
		}
		LedgerRevenueCategories(scopeId: $scopeId) {
			id
			description
			calculationType
			name
			type
		}
		LedgerExpenseCategories(scopeId: $scopeId) {
			id
			description
			calculationType
			name
			type
		}
		LedgerMilkCheckCategories(scopeId: $scopeId) {
			id
			name
			description
			calculationType
			type
		}
	}
`;

type GetBusinessQuery = {
	__typename?: 'Query';
	Customer: CustomerEntity;
	LedgerRevenueCategories: LedgerRevenueCategory[];
	LedgerExpenseCategories: LedgerExpenseCategory[];
	LedgerMilkCheckCategories: LedgerMilkCheckCategory[];
};

export default class BusinessesBusinessBusinessSettingsRoute extends Route {
	@tracked variables: Query_CustomerArgs = { id: '' };

	getBusiness = useQuery<GetBusinessQuery, Query_CustomerArgs>(this, () => [GET_BUSINESS, { variables: this.variables }]);

	async model() {
		const businessParams = this.paramsFor('businesses.business') as { business_id: string };
		this.variables = {
			id: businessParams.business_id,
			scopeId: businessParams.business_id,
		};

		await this.getBusiness.promise;
		return this.getBusiness;
	}
}
