import { DateTime } from 'luxon';
import Instrument from './instrument';
import { Future as FutureInterface } from 'vault-client/types/graphql-types';
import { tracked } from '@glimmer/tracking';

export default class Future extends Instrument {
	@tracked instrumentType = 'Future';
	@tracked expiresAt: string;
	@tracked displayExpiresAt: string;
	@tracked unitsPerContract: number | null | undefined;
	@tracked barchartSymbol: string | null | undefined;
	@tracked cqgSymbol: string | null | undefined;
	@tracked exchangeSymbol: string | null | undefined;
	@tracked lastTradeAt: DateTime;

	constructor(instrument: FutureInterface) {
		super(instrument);

		this.expiresAt = instrument.expiresAt;
		this.displayExpiresAt = instrument.displayExpiresAt;
		this.unitsPerContract = instrument.unitsPerContract;
		this.barchartSymbol = instrument.barchartSymbol;
		this.cqgSymbol = instrument.cqgSymbol;
		this.exchangeSymbol = instrument.exchangeSymbol;
		this.lastTradeAt = instrument.lastTradeAt;
	}

	get finalPrice() {
		return 0;
	}

	get hasExpired() {
		// This should be > 0, but we don't have exact times for expirations.
		return DateTime.fromISO(this.expiresAt).diffNow().valueOf() < -86400000;
	}
}
