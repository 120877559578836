import Route from '@ember/routing/route';
import {
	ForecastedMilkUtilization,
	ForecastedMilkUtilizationFilterDTO,
	Future,
	LedgerEntryFilterDTO,
	AggregateLedgerForecastedEntryDTO,
	TypeOfLedgerCategory,
	AggregateForecastedMilkProductionByMonthDTO,
	LocationEntity,
} from 'vault-client/types/graphql-types';
import { DateTime } from 'luxon';
import { useQuery, gql } from 'glimmer-apollo';
import { tracked } from '@glimmer/tracking';

interface modelParams {
	id: string;
	productionMonthStartDate: string;
	productionMonthEndDate: string;
}

const GET_MILK_UTILIZATIONS = gql`
	query ForecastedMilkUtilizations(
		$where: ForecastedMilkUtilizationFilterDTO
		$locationId: String!
		$startDate: String!
		$endDate: String!
	) {
		Location(id: $locationId) {
			id
			name
			currentBasis
			rollingBasisNumberOfMonths
			customBasis
			County {
				id
				classIDifferential
				name
				State {
					id
					name
				}
			}
		}
		ForecastedMilkUtilizations(where: $where, orderBy: { year: Asc, month: Asc }) {
			date
			classiUtilization
			classiiUtilization
			classiiiUtilization
			classivUtilization
		}
		ClassIIIFutures: Futures(
			where: {
				AND: [
					{ displayExpiresAt: { gte: $startDate } }
					{ displayExpiresAt: { lte: $endDate } }
					{ isStandardContractSize: { equals: true } }
				]
				Product: { slug: { equals: "us-dairy-class-iii" } }
			}
		) {
			id
			displayExpiresAt
			barchartSymbol
			Product {
				id
			}
			SymbolGroup {
				displayFactor
			}
		}
		ClassIVFutures: Futures(
			where: {
				AND: [
					{ displayExpiresAt: { gte: $startDate } }
					{ displayExpiresAt: { lte: $endDate } }
					{ isStandardContractSize: { equals: true } }
				]
				Product: { slug: { equals: "us-dairy-class-iv" } }
			}
		) {
			id
			displayExpiresAt
			barchartSymbol
			Product {
				id
			}
			SymbolGroup {
				displayFactor
			}
		}
		ButterFutures: Futures(
			where: {
				AND: [
					{ displayExpiresAt: { gte: $startDate } }
					{ displayExpiresAt: { lte: $endDate } }
					{ isStandardContractSize: { equals: true } }
				]
				Product: { slug: { equals: "us-dairy-butter" } }
			}
		) {
			id
			displayExpiresAt
			barchartSymbol
			Product {
				id
			}
			SymbolGroup {
				displayFactor
			}
		}
		NonfatDryMilkFutures: Futures(
			where: {
				AND: [
					{ displayExpiresAt: { gte: $startDate } }
					{ displayExpiresAt: { lte: $endDate } }
					{ isStandardContractSize: { equals: true } }
				]
				Product: { slug: { equals: "us-dairy-nonfat-milk" } }
			}
		) {
			id
			displayExpiresAt
			barchartSymbol
			Product {
				id
			}
			SymbolGroup {
				displayFactor
			}
		}
		CheeseFutures: Futures(
			where: {
				AND: [
					{ displayExpiresAt: { gte: $startDate } }
					{ displayExpiresAt: { lte: $endDate } }
					{ isStandardContractSize: { equals: true } }
				]
				Product: { slug: { equals: "us-dairy-cheese" } }
			}
		) {
			id
			displayExpiresAt
			barchartSymbol
			Product {
				id
			}
			SymbolGroup {
				displayFactor
			}
		}
		DryWheyFutures: Futures(
			where: {
				AND: [
					{ displayExpiresAt: { gte: $startDate } }
					{ displayExpiresAt: { lte: $endDate } }
					{ isStandardContractSize: { equals: true } }
				]
				Product: { slug: { equals: "us-dairy-dry-whey" } }
			}
		) {
			id
			displayExpiresAt
			barchartSymbol
			Product {
				id
			}
			SymbolGroup {
				displayFactor
			}
		}
	}
`;

const GET_MILK_LEDGER_ENTRIES = gql`
	query LedgerEntries($ledgerEntriesWhere: LedgerForecastedEntryFilterDTO, $locationId: String!) {
		AggregateLedgerForecastedEntries(
			where: $ledgerEntriesWhere
			calc: { sum: { calculatedAmount: true } }
			groupBy: { month: true, year: true }
			scopeId: $locationId
		) {
			sum {
				calculatedAmount
			}
			month
			year
		}
		AggregateForecastedMilkProductionByMonths(
			calc: {
				sum: { grossProduction: true, grossProteinProduction: true, grossOtherSolidsProduction: true, grossButterfatProduction: true }
			}
			groupBy: { month: true, year: true }
			scopeId: $locationId
		) {
			sum {
				grossProduction
				grossOtherSolidsProduction
				grossProteinProduction
				grossButterfatProduction
			}
			month
			year
		}
	}
`;

export type GetMilkUtilizationsQuery = {
	__typename?: 'Query';
	Location: LocationEntity;
	ForecastedMilkUtilizations: ForecastedMilkUtilization[];
	ClassIIIFutures: Future[];
	ClassIVFutures: Future[];
	ButterFutures: Future[];
	NonfatDryMilkFutures: Future[];
	CheeseFutures: Future[];
	DryWheyFutures: Future[];
	AggregateLedgerForecastedEntries?: AggregateLedgerForecastedEntryDTO[];
	AggregateForecastedMilkProductionByMonths?: AggregateForecastedMilkProductionByMonthDTO[];
};

type GetMilkUtilizationsQueryVariables = {
	where?: ForecastedMilkUtilizationFilterDTO;
	locationId: string;
	startDate: string;
	endDate: string;
};

type GetLedgerEntriesQueryVariables = {
	ledgerEntriesWhere?: LedgerEntryFilterDTO;
	locationId?: string;
};

export default class LocationsLocationMilkCheckRoute extends Route {
	@tracked utilizationVariables: GetMilkUtilizationsQueryVariables = { locationId: '', startDate: '', endDate: '' };
	@tracked ledgerEntriesVariables: GetLedgerEntriesQueryVariables = {};

	queryParams = {
		productionMonthStartDate: {
			refreshModel: true,
		},
		productionMonthEndDate: {
			refreshModel: true,
		},
	};

	getMilkUtilizations = useQuery<GetMilkUtilizationsQuery, GetMilkUtilizationsQueryVariables>(this, () => [
		GET_MILK_UTILIZATIONS,
		{ variables: this.utilizationVariables },
	]);

	getLedgerEntries = useQuery<GetMilkUtilizationsQuery, GetLedgerEntriesQueryVariables>(this, () => [
		GET_MILK_LEDGER_ENTRIES,
		{ variables: this.ledgerEntriesVariables },
	]);

	async model(params: modelParams) {
		const locationParams = this.paramsFor('locations.location') as { id: string };
		const where: ForecastedMilkUtilizationFilterDTO = {
			locationId: {
				equals: locationParams.id,
			},
			AND: [
				{
					date: {
						gte: DateTime.fromISO(params.productionMonthStartDate).minus({ month: 1 }).toISODate(),
					},
				},
				{
					date: {
						lte: DateTime.fromISO(params.productionMonthEndDate).plus({ month: 1 }).toISODate(),
					},
				},
			],
		};

		this.utilizationVariables = {
			where,
			locationId: locationParams.id,
			startDate: DateTime.fromISO(params.productionMonthStartDate).minus({ month: 1 }).toISODate(),
			endDate: params.productionMonthEndDate,
		};

		this.ledgerEntriesVariables = {
			ledgerEntriesWhere: {
				LedgerCategory: {
					type: {
						equals: TypeOfLedgerCategory.MilkCheck,
					},
				},
			},
			locationId: locationParams.id,
		};

		await this.getMilkUtilizations.promise;
		await this.getLedgerEntries.promise;

		return {
			getMilkCheckData: this.getMilkUtilizations,
			getLedgerEntries: this.getLedgerEntries,
		};
	}
}
