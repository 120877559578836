import Controller from '@ember/controller';
import { service } from '@ember/service';
import { UiSecondaryNavItemArgs } from 'vault-client/components/ui-secondary-nav-item';
import NewFillModificationRequestMonitorService from 'vault-client/services/new-fill-modification-request-monitor';
import NewTargetMonitor from 'vault-client/services/new-target-monitor';
import PendingCancellationModificationRequestMonitor from 'vault-client/services/pending-cancellation-modification-request-monitor';
import PendingCancellationTargetMonitor from 'vault-client/services/pending-cancellation-target-monitor';
import PermissionsService from 'vault-client/services/permissions';

export default class Vgs extends Controller {
	@service('new-fill-modification-request-monitor') declare newFillModificationMonitor: NewFillModificationRequestMonitorService;
	@service declare newTargetMonitor: NewTargetMonitor;
	@service('pending-cancellation-modification-request-monitor')
	declare pendingCancellationFillMonitor: PendingCancellationModificationRequestMonitor;
	@service declare pendingCancellationTargetMonitor: PendingCancellationTargetMonitor;
	@service declare permissions: PermissionsService;

	current: { label: string; route: string; query?: string; model?: string } = {
		label: 'VGS',
		route: 'vgs.customers',
	};

	get feedMonitorCount() {
		const count = (this.newTargetMonitor?.feedCount ?? 0) + (this.pendingCancellationTargetMonitor.feedCount ?? 0);
		return count;
	}

	get grainMonitorCount() {
		const count =
			(this.newTargetMonitor?.grainCount ?? 0) +
			(this.newFillModificationMonitor?.grainCount ?? 0) +
			(this.pendingCancellationFillMonitor.grainCount ?? 0) +
			(this.pendingCancellationTargetMonitor.grainCount ?? 0);
		return count;
	}

	get secondaryNavItems(): Omit<UiSecondaryNavItemArgs, 'uid'>[] {
		return [
			{
				label: 'Customers',
				link: 'vgs.customers.index',
			},
			{
				label: 'Grain Services',
				badges: [
					{
						badgeText: this.grainMonitorCount || undefined,
						badgeStyle: 'positive',
					},
				],
				children: [
					{
						label: 'Targets',
						link: `vgs.grain-services.targets`,
						badges: [
							{
								badgeText: this.pendingCancellationTargetMonitor.grainCount || undefined,
								badgeStyle: 'vgs-pending-cancellation',
							},
							{
								badgeText: this.newTargetMonitor.grainCount || undefined,
								badgeStyle: 'vgs-new',
							},
						],
					},
					{
						label: 'Fills',
						link: `vgs.grain-services.fills`,
					},
					{
						label: 'Crop Plans',
						link: `vgs.grain-services.crop-plans`,
					},
					{
						label: 'Requests',
						link: `vgs.grain-services.modification-requests`,
						badges: [
							{
								badgeText: this.pendingCancellationFillMonitor.grainCount || undefined,
								badgeStyle: 'vgs-pending-cancellation',
							},
							{
								badgeText: this.newFillModificationMonitor.grainCount || undefined,
								badgeStyle: 'vgs-new',
							},
						],
					},
					{
						label: 'Grain Prices',
						link: 'https://grain-prices.vaultag.com/',
						isExternal: true,
					},
				],
			},
			{
				label: 'Feed Services',
				badges: [{ badgeText: this.feedMonitorCount || undefined, badgeStyle: 'positive' }],
				children: [
					{
						label: 'Targets',
						link: `vgs.feed-services.targets`,
						badges: [
							{
								badgeText: this.pendingCancellationTargetMonitor.feedCount || undefined,
								badgeStyle: 'vgs-pending-cancellation',
							},
							{
								badgeText: this.newTargetMonitor.feedCount || undefined,
								badgeStyle: 'vgs-new',
							},
						],
					},
					{
						label: 'Fills',
						link: `vgs.feed-services.fills`,
					},
					{
						label: 'Feed Plans',
						link: `vgs.feed-services.feed-plans`,
					},
				],
			},
			{
				label: 'Settings',
				hidden: this.permissions.isBuyerRelations ? false : true,
				children: [
					{
						label: 'Advisors',
						link: `vgs.settings.advisors.index`,
					},
					{
						label: 'Advisor',
						link: `vgs.settings.advisors.show`,
						hidden: true,
					},
					{
						label: 'Buyers',
						link: `vgs.settings.buyers`,
					},
					{
						label: 'Sellers',
						link: `vgs.settings.sellers`,
					},
					{
						label: 'Crops',
						link: `vgs.settings.crops`,
					},
					{
						label: 'Ingredients',
						link: `vgs.settings.ingredients`,
					},
					{
						label: 'Locations',
						link: `vgs.settings.locations`,
					},
					{
						label: 'Permissions',
						link: `vgs.settings.permissions`,
					},
				],
			},
		];
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	interface Registry {
		vgs: Vgs;
	}
}
