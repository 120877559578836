import Route from '@ember/routing/route';
import { queryManager } from 'ember-apollo-client';
import { Product } from 'vault-client/types/graphql-types';

export default class MarketsProductFuturesRoute extends Route {
	@queryManager apollo: any;

	async model() {
		return this.modelFor('markets.product') as { Product: Product };
	}
}
