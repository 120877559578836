import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import { inject as service } from '@ember/service';
import AuthService from 'vault-client/services/auth';

interface ModelParams {
	code: string;
	state: string;
}
export default class CallbackRoute extends Route {
	@service declare auth: AuthService;
	@service declare router: RouterService;

	async model(params: ModelParams) {
		if (params.code && params.state) {
			await this.auth.handleAuthentication();
		}

		await this.auth.requireAuthentication();
		this.router.transitionTo('index');
	}
}
