import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { CellComponents, TableColumn } from 'vault-client/types/vault-table';
import { ModelFrom } from 'vault-client/utils/type-utils';
import route from 'vault-client/routes/vgs/settings/advisors';
import PermissionsService from 'vault-client/services/permissions';
export default class VgsSettingsAdvisors extends Controller {
	declare model: ModelFrom<route>;

	@service declare permissions: PermissionsService;
	@tracked sorts = [{ valuePath: 'name', isAscending: true }];

	get columns() {
		const baseColumns: TableColumn[] = [
			{
				id: 'a87d995b-1e53-4c03-89c0-9461c4a48abd',
				name: 'ID',
				valuePath: 'id',
				minWidth: 80,
				textAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				// Link to advisors.show if user has buyerRelations permission
				...(this.permissions.isBuyerRelations && { linkRoute: 'vgs.settings.advisors.show', linkModelPath: 'id' }),
			},
			{
				id: '5b44862d-8177-4870-af96-2a35df96a05f',
				name: 'Name',
				valuePath: 'name',
				minWidth: 120,
				textAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'e084d231-54df-4408-845e-f7206a6c4325',
				name: 'Email',
				valuePath: 'email',
				minWidth: 120,
				textAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
		];
		return baseColumns;
	}

	get rows() {
		return this.model.getAdvisors.data?.GrainAdvisors ?? [];
	}

	get organizationId(): string | null {
		return this.model.getAdvisors.data?.Organizations.length === 1 ? this.model.getAdvisors.data?.Organizations[0].id : null;
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	interface Registry {
		'vgs/settings/advisors': VgsSettingsAdvisors;
	}
}
