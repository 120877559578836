import Component from '@glimmer/component';
interface UiDropdownActionArgs {
	idx: string;
	icon: string;
	disabled: boolean;
	darkMode: boolean;
	selected: boolean;
	secondary?: boolean;
	showCheck?: boolean;
}

export default class UiDropdownAction extends Component<UiDropdownActionArgs> {
	get showCheck(): boolean {
		return this.args.showCheck === undefined ? true : this.args.showCheck;
	}
	get buttonClasses(): string {
		if (this.args.darkMode && this.args.selected) {
			return 'bg-brand-white border border-transparent focus:border-brand-interactive-blue-70';
		} else if (this.args.darkMode) {
			return 'bg-brand-light border border-transparent hover:bg-brand-gray-10 hover:border-brand-gray-20 focus:border-brand-interactive-blue-70';
		} else if (this.args.secondary) {
			return 'hover:bg-brand-gray-10 hover:border-brand-gray-20 hover:text-brand-blue-70 focus-visible:outline-none focus-visible:bg-brand-light focus-visible:text-brand-gray-70 active:bg-brand-interactive-blue-10 active:text-brand-interactive-blue-70 focus-visible:ring-1 focus-visible:ring-brand-interactive-blue-70 focus-visible:ring-inset';
		} else {
			return 'hover:bg-brand-gray-10 hover:ring-1 hover:ring-brand-gray-20 active:bg-brand-gray-20 focus-visible:ring-1 focus-visible:ring-brand-interactive-blue-70';
		}
	}

	get checkClasses(): string {
		if (this.args.darkMode && this.args.selected) {
			return 'text-brand-gray-10';
		} else if (this.args.darkMode) {
			return 'text-brand-gray-90 group-hover:text-brand-gray-10';
		} else {
			return ''; //removed styles as they don't affect checkmark
		}
	}

	get iconClasses(): string {
		if (this.args.darkMode && this.args.selected) {
			return 'text-brand-blue-70';
		} else if (this.args.darkMode) {
			return 'text-brand-blue-70 group-hover:text-brand-blue-70';
		} else {
			return 'text-brand-blue-70 group-hover:text-brand-blue-70 group-focus:text-brand-blue-70';
		}
	}

	get textClasses(): string {
		if (this.args.darkMode && this.args.selected) {
			return 'text-brand-blue-70';
		} else if (this.args.darkMode) {
			return 'text-brand-blue-70 group-hover:text-brand-blue-70';
		} else if (this.args.secondary) {
			return '';
		} else {
			return 'text-brand-gray-90';
		}
	}
}
