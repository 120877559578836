import InsuranceOverviewByBusiness from 'vault-client/routes/insurance-overview-by-business';
import { Query } from 'vault-client/types/graphql-types';

interface ModelParams {
	agent: string | null;
	aipId: string | null;
	stateId: string | null;
	customerId: string | null;
	quarterStartDate: string | null;
	quarterEndDate: string | null;
	sorts: SortObj[];
	page: number;
	size: number;
}

type SortObj = {
	valuePath: string;
	isAscending: boolean;
};

export default class OrganizationsOrganizationInsuranceOverviewByBusiness extends InsuranceOverviewByBusiness {
	async model(params: ModelParams) {
		const organizationParams = this.paramsFor('organizations.organization') as { id: string };
		const variables = {
			where: this.generateWhere(
				organizationParams.id,
				params.customerId,
				params.quarterStartDate,
				params.quarterEndDate,
				params.agent,
				params.aipId,
				params.stateId
			),
			orderBy: this.generateOrderBy(params.sorts),
			offset: params.page * params.size,
			limit: params.size,
		};

		const results = (await this.apollo.watchQuery({ query: this.query, variables })) as {
			ForecastedMilkProductionCoverage: Query['ForecastedMilkProductionCoverage'];
			AggregateForecastedMilkProductionCoverage: Query['AggregateForecastedMilkProductionCoverage'];
		};

		return {
			...results,
			variables,
		};
	}
}
