import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

interface VaultUiDropdownArgs {
	ariaLabel: string;
	dropdownOriginRight?: boolean;
	disabled?: boolean;
	hideChevron?: boolean;
	uid: string;
}

export default class VaultUiDropdown extends Component<VaultUiDropdownArgs> {
	@tracked dropdownOpen = false;

	get dropdownOriginRight(): boolean {
		return this.args.dropdownOriginRight ?? true;
	}

	@action
	toggleDropdown(): void {
		this.dropdownOpen = !this.dropdownOpen;
	}

	@action
	closeDropDown(): void {
		this.dropdownOpen = false;
	}

	@action
	handleDropdownKeyDown(event: KeyboardEvent): void {
		const eventTarget = event.currentTarget as Element;
		const elements = [...eventTarget.getElementsByClassName('ui-dropdown-item')] as HTMLElement[];
		const length = elements.length - 1;
		let activeItem = elements.findIndex((el) => el.matches(':focus'));

		if (event.key === 'Down' || event.key === 'ArrowDown') {
			event.preventDefault();
			activeItem = activeItem === length ? 0 : ++activeItem;
			elements[activeItem].focus({ preventScroll: false });
		} else if ((event.key === 'Up' || event.key === 'ArrowUp') && activeItem > 0) {
			event.preventDefault();
			elements[--activeItem].focus({ preventScroll: false });
		} else if (event.key === 'Esc' || event.key === 'Escape') {
			event.preventDefault();
			const eventTarget = event.target as HTMLElement;
			eventTarget.blur();
			this.closeDropDown();
		} else if (event.shiftKey && event.key === 'Tab') {
			if (event.shiftKey && activeItem === 0) {
				this.closeDropDown();
			}
		} else if (event.key === 'Tab') {
			if (activeItem === length) {
				this.closeDropDown();
			}
		}
	}
}
