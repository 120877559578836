import InsuranceIndexController from 'vault-client/controllers/insurance';

export default class OrganizationsOrganizationInsuranceController extends InsuranceIndexController {
	insuranceOverviewRoutePath = 'organizations.organization.insurance-overview';
	policiesRoutePath = 'organizations.organization.insurance-policies';
	drpEndorsementsRoutePath = 'organizations.organization.drp-insurance-endorsements';
	lrpEndorsementsRoutePath = 'organizations.organization.lrp-insurance-endorsements';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'organizations/organization/insurance': OrganizationsOrganizationInsuranceController;
	}
}
