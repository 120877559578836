import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<span {{did-insert this.fetchLocations}}>\n\t<PowerSelect\n\t\t@selected={{@selected}}\n\t\t@options={{this.locations}}\n\t\t@searchEnabled={{true}}\n\t\t@searchField='name'\n\t\t@onChange={{@onChange}}\n\t\t@disabled={{this.loadingLocations}}\n\t\t@placeholder={{if this.loadingLocations 'Loading...'}}\n\t\t@renderInPlace={{@renderInPlace}}\n\t\tas |location|\n\t>\n\t\t<b>{{location.Business.name}}</b>\n\t\t({{location.name}})\n\t</PowerSelect>\n</span>", {"contents":"<span {{did-insert this.fetchLocations}}>\n\t<PowerSelect\n\t\t@selected={{@selected}}\n\t\t@options={{this.locations}}\n\t\t@searchEnabled={{true}}\n\t\t@searchField='name'\n\t\t@onChange={{@onChange}}\n\t\t@disabled={{this.loadingLocations}}\n\t\t@placeholder={{if this.loadingLocations 'Loading...'}}\n\t\t@renderInPlace={{@renderInPlace}}\n\t\tas |location|\n\t>\n\t\t<b>{{location.Business.name}}</b>\n\t\t({{location.name}})\n\t</PowerSelect>\n</span>","moduleName":"vault-client/components/location-select.hbs","parseOptions":{"srcName":"vault-client/components/location-select.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { queryManager } from 'ember-apollo-client';
import query from 'vault-client/graphql/queries/location-select.graphql';

export default class LocationSelectComponent extends Component {
	@queryManager apollo: any;

	@tracked loadingLocations = false;
	@tracked locations = [];

	@action
	async fetchLocations() {
		this.loadingLocations = true;
		const locations = (await this.apollo.watchQuery({ query })).Locations;
		this.locations = locations.sortBy('Business.name');
		this.loadingLocations = false;
	}
}
