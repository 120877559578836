import Controller from '@ember/controller';
import { DateTime } from 'luxon';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { queryManager } from 'ember-apollo-client';
import getFilterDisplayProperty from 'vault-client/utils/get-filter-display-property';
import searchQuery from 'vault-client/graphql/queries/search.graphql';
import agentFuzzyQuery from 'vault-client/graphql/queries/agent-fuzzy-match.graphql';
import stateFuzzyQuery from 'vault-client/graphql/queries/state-fuzzy-match.graphql';
import isTouchDevice from 'vault-client/utils/is-touch-device';
import resetVaultTableScroll from 'vault-client/utils/reset-vault-table-scroll';
import { TableColumn, CellComponents } from 'vault-client/types/vault-table';
import {
	AggregateForecastedMilkProductionCoverageDTO,
	ForecastedMilkProductionCoverage,
	NumericAggregateForecastedMilkProductionCoverageDTO,
} from 'vault-client/types/graphql-types';
import { ModelFrom } from 'vault-client/utils/type-utils';
import OrganizationsOrganizationInsuranceOverviewByBusiness from 'vault-client/routes/organizations/organization/insurance-overview-by-business';
import query from 'vault-client/graphql/queries/insurance-overview-by-business.graphql';

interface SearchFilterIdentifiers {
	agent: string | null;
	aipId: string | null;
	stateId: string | null;
}

type SearchFilterIdentifier = keyof SearchFilterIdentifiers;

function isSearchFilterIdentifier(key: unknown): key is SearchFilterIdentifier {
	const searchFilterIdentifiers = ['agent', 'aipId', 'stateId'];
	if (typeof key === 'string' && searchFilterIdentifiers.includes(key)) {
		return true;
	}
	return false;
}

interface searchResult {
	id: string;
	name: string;
	type: string;
}

type Quarter = (ForecastedMilkProductionCoverage | NumericAggregateForecastedMilkProductionCoverageDTO) & {
	classIiiPercentInsured?: number | null;
	classIvPercentInsured?: number | null;
	forecastedPnlCwt?: number | null;
	indemnityCwt?: number | null;
	producerPremiumAmountCwt?: number | null;
};

export default class InsuranceOverviewByBusiness extends Controller implements SearchFilterIdentifiers {
	@queryManager apollo: any;
	declare model: ModelFrom<OrganizationsOrganizationInsuranceOverviewByBusiness>;
	query = query;

	queryParams = ['agent', 'aipId', 'stateId', 'quarterStartDate', 'quarterEndDate', 'page', 'sorts'];

	quarterEndDateRangeOptions = [
		{
			displayName: 'All Quarters',
			startDate: '2019-01-01',
			endDate: DateTime.local().plus({ quarter: 6 }).endOf('quarter').toISODate(),
		},
		{
			displayName: 'Open Quarters',
			startDate: DateTime.local().startOf('quarter').toISODate(),
			endDate: DateTime.local()
				.plus({ quarter: 6 })
				.endOf('quarter')

				.toISODate(),
		},
		// Previous Quarter
		{
			displayName: DateTime.local()
				.minus({ quarter: 1 })
				.startOf('quarter')

				.toFormat('yyyy Qq'),
			startDate: DateTime.local()
				.minus({ quarter: 1 })
				.startOf('quarter')

				.toISODate(),
			endDate: DateTime.local()
				.minus({ quarter: 1 })
				.endOf('quarter')

				.toISODate(),
		},
		// Current Quarter
		{
			displayName: DateTime.local().startOf('quarter').toFormat('yyyy Qq'),
			startDate: DateTime.local().startOf('quarter').toISODate(),
			endDate: DateTime.local().endOf('quarter').toISODate(),
		},

		// +1 Quarter
		{
			displayName: DateTime.local()
				.plus({ quarter: 1 })
				.startOf('quarter')

				.toFormat('yyyy Qq'),
			startDate: DateTime.local()
				.plus({ quarter: 1 })
				.startOf('quarter')

				.toISODate(),
			endDate: DateTime.local()
				.plus({ quarter: 1 })
				.endOf('quarter')

				.toISODate(),
		},
		// +2 Quarter
		{
			displayName: DateTime.local()
				.plus({ quarter: 2 })
				.startOf('quarter')

				.toFormat('yyyy Qq'),
			startDate: DateTime.local()
				.plus({ quarter: 2 })
				.startOf('quarter')

				.toISODate(),
			endDate: DateTime.local()
				.plus({ quarter: 2 })
				.endOf('quarter')

				.toISODate(),
		},
		// +3 Quarter
		{
			displayName: DateTime.local()
				.plus({ quarter: 3 })
				.startOf('quarter')

				.toFormat('yyyy Qq'),
			startDate: DateTime.local()
				.plus({ quarter: 3 })
				.startOf('quarter')

				.toISODate(),
			endDate: DateTime.local()
				.plus({ quarter: 3 })
				.endOf('quarter')

				.toISODate(),
		},
		// +4 Quarter
		{
			displayName: DateTime.local()
				.plus({ quarter: 4 })
				.startOf('quarter')

				.toFormat('yyyy Qq'),
			startDate: DateTime.local()
				.plus({ quarter: 4 })
				.startOf('quarter')

				.toISODate(),
			endDate: DateTime.local()
				.plus({ quarter: 4 })
				.endOf('quarter')

				.toISODate(),
		},
		// +5 Quarter
		{
			displayName: DateTime.local()
				.plus({ quarter: 5 })
				.startOf('quarter')

				.toFormat('yyyy Qq'),
			startDate: DateTime.local()
				.plus({ quarter: 5 })
				.startOf('quarter')

				.toISODate(),
			endDate: DateTime.local()
				.plus({ quarter: 5 })
				.endOf('quarter')

				.toISODate(),
		},
		// +6 Quarter
		{
			displayName: DateTime.local()
				.plus({ quarter: 6 })
				.startOf('quarter')

				.toFormat('yyyy Qq'),
			startDate: DateTime.local()
				.plus({ quarter: 6 })
				.startOf('quarter')

				.toISODate(),
			endDate: DateTime.local().plus({ quarter: 6 }).endOf('quarter').toISODate(),
		},
		{
			displayName: `Calendar Year (${DateTime.local().year}) `,
			startDate: DateTime.local().startOf('year').toISODate(),
			endDate: DateTime.local().endOf('year').toISODate(),
		},
	];

	@tracked organizationId: any;
	@tracked globalCustomerId: any;
	@tracked locationId: any;
	@tracked agent: string | null = null;
	@tracked aipId: string | null = null;
	@tracked stateId: string | null = null;
	@tracked page = 0;
	@tracked sorts = [{ valuePath: 'percentInsured', isAscending: false }];
	size = 100;
	@tracked quarterStartDate?: string = DateTime.local().startOf('quarter').toISODate();
	@tracked quarterEndDate?: string = DateTime.local().plus({ quarter: 6 }).endOf('quarter').toISODate();
	@tracked currentScope: string | null = '';

	itemsFn = (coverages: ForecastedMilkProductionCoverage[]) => {
		return coverages.map((row: ForecastedMilkProductionCoverage) => {
			const updatedRow: Quarter = { ...row };

			const classIiiEffectiveProduction = (row.grossClassIProduction ?? 0) / 2 + (row.grossClassIiiProduction ?? 0);
			updatedRow.classIiiPercentInsured = classIiiEffectiveProduction ? (row.classIiiPounds ?? 0) / classIiiEffectiveProduction : null;

			const classIvEffectiveProduction =
				(row.grossClassIProduction ?? 0) / 2 + (row.grossClassIiProduction ?? 0) + (row.grossClassIvProduction ?? 0);
			updatedRow.classIvPercentInsured = classIvEffectiveProduction ? (row.classIvPounds ?? 0) / classIvEffectiveProduction : null;

			updatedRow.forecastedPnlCwt = updatedRow.effectiveCoveredMilkProduction
				? (updatedRow.forecastedPnl ?? 0) / (updatedRow.effectiveCoveredMilkProduction / 100)
				: null;

			updatedRow.indemnityCwt = updatedRow.effectiveCoveredMilkProduction
				? (updatedRow.forecastedIndemnity ?? 0) / (updatedRow.effectiveCoveredMilkProduction / 100)
				: null;

			updatedRow.producerPremiumAmountCwt = updatedRow.effectiveCoveredMilkProduction
				? (updatedRow.producerPremiumAmount ?? 0) / (updatedRow.effectiveCoveredMilkProduction / 100)
				: null;

			return updatedRow;
		});
	};

	get columns(): TableColumn[] {
		const baseColumns: TableColumn[] = [
			{
				id: '47735899-6c6b-4ecc-9906-8a22c1e327d4',
				name: 'Business',
				valuePath: 'CustomerEntity.name',
				minWidth: 200,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: !isTouchDevice() ? 'left' : '',
				isVisible: true,
				linkRoute: 'businesses.business.insurance-overview',
				linkModelPath: 'CustomerEntity.id',
			},
			{
				id: 'c02e4e0b-9e0e-41b1-8b25-9f8a0f812fa7',
				name: 'Quarter',
				valuePath: 'quarterStartDate',
				width: 125,
				minWidth: 125, // need minWidth === width re bug on resizing first fixed column
				cellComponent: CellComponents.QuarterFormat,
				textAlign: 'left',
				isSortable: true,
				isFixed: !isTouchDevice() ? 'left' : '',
				isVisible: true,
			},
			{
				id: '89a8cbac-f683-4fb4-871f-5f2fee14c37a',
				name: 'Production',
				valuePath: 'grossProduction',
				minWidth: 150,
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
				isTotaled: true,
			},
			{
				id: '03ba6588-21a2-4f42-9538-a373c542d7c4',
				name: 'Insured Milk',
				valuePath: 'effectiveCoveredMilkProduction',
				minWidth: 150,
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
				isTotaled: true,
			},
			{
				id: '1df2e69a-e2b7-4d5a-8695-e5e770aa0040',
				name: 'Insured %',
				minWidth: 150,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'percent',
				},
				subcolumns: [
					{
						id: 'b0327471-6e13-4ff2-b78b-2cbc5649844e',
						name: 'III',
						valuePath: 'classIiiPercentInsured',
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'percent',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
						isTotaled: true,
					},
					{
						id: '6fb78b38-05ad-444a-b416-e68d5fbc4007',
						name: 'IV',
						valuePath: 'classIvPercentInsured',
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'percent',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
						isTotaled: true,
					},
					{
						id: '586f4c16-efaa-49ff-ada3-73edb9afde1e',
						name: 'Total',
						valuePath: 'percentInsured',
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'percent',
						},
						textAlign: 'right',
						isSortable: true,
						isFixed: '',
						isVisible: true,
						isTotaled: true,
					},
				],
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '11e3ebaf-513b-40fc-a676-9a58aacc872b',
				name: 'Producer Premium',
				cellComponent: CellComponents.String,
				textAlign: 'center',
				isSortable: false,
				isFixed: '',
				isVisible: true,
				isTotaled: true,
				subcolumns: [
					{
						id: '6abb8bef-4d7c-4dd2-9220-96687ffa15fa',
						name: 'Total',
						valuePath: 'producerPremiumAmount',
						minWidth: 130,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							currencySign: 'accounting',
						},
						textAlign: 'right',
						isSortable: true,
						isFixed: '',
						isVisible: true,
						isTotaled: true,
					},
					{
						id: '2965f79f-5ad8-48df-bd47-839ef15002c0',
						name: 'CWT',
						valuePath: 'producerPremiumAmountCwt',
						minWidth: 70,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							currencySign: 'accounting',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
						isTotaled: true,
					},
				],
			},
			{
				id: '6f83249d-cbca-4558-ba60-4ae9897e7561',
				name: 'Indemnity',
				cellComponent: CellComponents.String,
				textAlign: 'center',
				isSortable: false,
				isFixed: '',
				isVisible: true,
				isTotaled: true,
				subcolumns: [
					{
						id: '49f8fb98-f9ff-4ce0-ba60-bf36702420dd',
						name: 'Total',
						valuePath: 'forecastedIndemnity',
						minWidth: 130,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							currencySign: 'accounting',
						},
						textAlign: 'right',
						isSortable: true,
						isFixed: '',
						isVisible: true,
						isTotaled: true,
					},
					{
						id: '915ec843-f714-4cca-985b-2b19d620a7de',
						name: 'CWT',
						valuePath: 'indemnityCwt',
						minWidth: 70,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							currencySign: 'accounting',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
						isTotaled: true,
					},
				],
			},
			{
				id: '9727bce7-34fc-4e8c-ab48-28ab4ef8d81b',
				name: 'Estimated P/L',
				cellComponent: CellComponents.String,
				textAlign: 'center',
				isSortable: false,
				isFixed: '',
				isVisible: true,
				isTotaled: true,
				subcolumns: [
					{
						id: '5d76ff56-3a5b-409f-aac0-ba198297260d',
						name: 'Total',
						valuePath: 'forecastedPnl',
						minWidth: 130,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							currencySign: 'accounting',
						},
						textAlign: 'right',
						isSortable: true,
						isFixed: '',
						isVisible: true,
						isTotaled: true,
					},
					{
						id: 'a2aba0be-0b74-4527-bc32-2c1ff4d91867',
						name: 'CWT',
						valuePath: 'forecastedPnlCwt',
						minWidth: 70,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							currencySign: 'accounting',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
						isTotaled: true,
					},
				],
			},
		];
		return baseColumns;
	}

	get currentPage() {
		return this.page;
	}

	set currentPage(page) {
		this.page = page;
	}

	get searchFilterQueryParams() {
		const obj: {
			[param: string]: { [filterProperty: string]: string };
		} = {};

		const searchQueryParams = ['agent', 'aipId', 'stateId'];
		searchQueryParams.forEach((param) => {
			const value = this[param as keyof InsuranceOverviewByBusiness];

			if (value) {
				obj[param] = {
					filterRule: 'equals',
					filterValue: value,
				};

				// set filterComponent property to specify component for custom display extended from search-filter
				const filterDisplayObj = getFilterDisplayProperty(param);
				if (filterDisplayObj && filterDisplayObj.customComponent) {
					obj[param].filterComponent = filterDisplayObj.customComponent;
				}
				// set filterLabel property to specify custom label for filter - default would be filterIdentifier (matches queryParam)
				if (filterDisplayObj && filterDisplayObj.label) {
					obj[param].filterLabel = filterDisplayObj.label;
				}
			}
		});

		return obj;
	}

	get searchPlaceholder() {
		return 'Filter by agent name, AIP, state...';
	}

	get searchPrompt() {
		return 'Type a search term to filter data by agent name, AIP, or state.';
	}

	get quarterEndDateQueryParam() {
		return {
			startDate: this.quarterStartDate,
			endDate: this.quarterEndDate,
		};
	}

	get quarters() {
		return this.itemsFn(this.model.ForecastedMilkProductionCoverage);
	}

	get totalNum() {
		return this.model.AggregateForecastedMilkProductionCoverage[0].count;
	}

	get colTotals(): Quarter[] {
		if (this.model.ForecastedMilkProductionCoverage.length === 0) return [];

		return this.model.AggregateForecastedMilkProductionCoverage.map((row: AggregateForecastedMilkProductionCoverageDTO) => {
			const updatedRow: Quarter = { ...row.sum };

			const classIiiEffectiveProduction = (updatedRow.grossClassIProduction ?? 0) / 2 + (updatedRow.grossClassIiiProduction ?? 0);
			updatedRow.classIiiPercentInsured = classIiiEffectiveProduction
				? (updatedRow.classIiiPounds ?? 0) / classIiiEffectiveProduction
				: null;

			const classIvEffectiveProduction =
				(updatedRow.grossClassIProduction ?? 0) / 2 + (updatedRow.grossClassIiProduction ?? 0) + (updatedRow.grossClassIvProduction ?? 0);

			updatedRow.classIvPercentInsured = classIvEffectiveProduction ? (updatedRow.classIvPounds ?? 0) / classIvEffectiveProduction : null;

			updatedRow.percentInsured = updatedRow.grossProduction
				? (updatedRow.effectiveCoveredMilkProduction ?? 0) / updatedRow.grossProduction
				: null;

			updatedRow.forecastedPnlCwt = updatedRow.effectiveCoveredMilkProduction
				? (updatedRow.forecastedPnl ?? 0) / (updatedRow.effectiveCoveredMilkProduction / 100)
				: null;

			updatedRow.indemnityCwt = updatedRow.effectiveCoveredMilkProduction
				? (updatedRow.forecastedIndemnity ?? 0) / (updatedRow.effectiveCoveredMilkProduction / 100)
				: null;

			updatedRow.producerPremiumAmountCwt = updatedRow.effectiveCoveredMilkProduction
				? (updatedRow.producerPremiumAmount ?? 0) / (updatedRow.effectiveCoveredMilkProduction / 100)
				: null;

			return updatedRow;
		});
	}

	@action
	async fetchSearchResults(searchText: string) {
		let searchApiResults = [];
		const agentResults = [];
		const stateResults: any[] = [];

		// get fuzzy match agents
		const agentVariables = {
			where: {
				OR: [
					{ agentFirstName: { contains: searchText } },
					{ agentLastName: { contains: searchText } },
					{ agentFullName: { contains: searchText } },
				],
			},
		};
		const agentsArr = (
			await this.apollo.watchQuery({
				query: agentFuzzyQuery,
				variables: agentVariables,
			})
		).InsurancePolicies.map((agent: any) => `${agent.agentFirstName} ${agent.agentLastName}`);

		const agentSet = new Set(agentsArr);

		for (const agent of agentSet) {
			const obj = {
				type: 'Agent',
				name: agent,
			};
			agentResults.push(obj);
		}

		// get fuzzy match state
		const stateVariables = {
			where: {
				OR: [{ abbreviation: { contains: searchText } }, { name: { contains: searchText } }, { id: { contains: searchText } }],
			},
		};
		const states = (
			await this.apollo.watchQuery({
				query: stateFuzzyQuery,
				variables: stateVariables,
			})
		).States;
		states.forEach((state: any) => {
			const obj = {
				type: 'State',
				id: state.id,
				name: state.name,
			};
			stateResults.push(obj);
		});

		// get search API matches
		const variables = {
			query: searchText,
			typesToInclude: ['Aip'],
		};

		searchApiResults = (await this.apollo.watchQuery({ query: searchQuery, variables })).Search;

		// return combined set
		return [...agentResults, ...searchApiResults, ...stateResults];
	}

	@action
	structureSearchResults(searchResults: searchResult[]) {
		const map = new Map();

		searchResults.forEach((item) => {
			if (map.has(item.type)) {
				map.get(item.type).push({ id: item.id, name: item.name, type: item.type });
			} else {
				map.set(item.type, [{ id: item.id, name: item.name, type: item.type }]);
			}
		});

		return map;
	}

	@action
	setSearchFilterQueryParam(searchResult: searchResult) {
		const mappedSearchFilter = this.mapSearchResult(searchResult);
		this.page = 0;
		if (mappedSearchFilter) {
			this[mappedSearchFilter.filterIdentifier] = mappedSearchFilter.filterValue;
			this.setTablePageState();
		}
	}

	mapSearchResult(searchResult: searchResult): { filterIdentifier: SearchFilterIdentifier; filterValue: string } | null {
		let filterIdentifier: SearchFilterIdentifier;

		switch (searchResult.type) {
			case 'Aip':
				filterIdentifier = 'aipId';
				break;
			case 'Agent':
				filterIdentifier = 'agent';
				break;
			case 'State':
				filterIdentifier = 'stateId';
				break;
			default:
				return null;
		}

		return {
			filterIdentifier,
			filterValue: searchResult.id ?? searchResult.name,
		};
	}

	@action
	clearSearchFilterQueryParam(filterIdentifier: string) {
		if (isSearchFilterIdentifier(filterIdentifier)) {
			this[filterIdentifier] = null;
			this.setTablePageState();
		}
	}

	@action
	setQuarterEndDateQueryParam(value: { startDate?: string; endDate?: string }) {
		this.quarterStartDate = value.startDate;
		this.quarterEndDate = value.endDate;
		this.setTablePageState();
	}

	@action
	setTablePageState(newPageVal?: number) {
		this.currentPage = newPageVal ?? 0;
		resetVaultTableScroll('insurance-overview-table');
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'insurance-overview-by-business': InsuranceOverviewByBusiness;
	}
}
