import CropTargetsRoute from 'vault-client/routes/crop-targets';

type SortObj = {
	valuePath: string;
	isAscending: boolean;
};

interface QueryParams {
	agent?: string | null;
	aipId?: string | null;
	customerId?: string | null;
	quarterStartDate: string;
	quarterEndDate: string;
	salesEffectiveStartDate: string;
	salesEffectiveEndDate: string;
	stateId?: string | null;
	type?: string | null;
	page: number;
	size: number;
	sorts: SortObj[];
	id: string;
}

export default class BusinessesCropTargets extends CropTargetsRoute {
	async model(params: QueryParams) {
		const businessParams = this.paramsFor('businesses.business') as { business_id: string };
		this.variables = {
			where: this.generateWhere(null, businessParams.business_id),
			orderBy: this.generateOrderBy(params.sorts),
			offset: params.page * params.size,
			limit: params.size,
		};

		await this.getTargets.promise;

		return {
			getTargets: this.getTargets,
			variables: this.variables,
		};
	}
}
