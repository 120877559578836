import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { DateTime } from 'luxon';
import query from 'vault-client/graphql/queries/markets/product/futures/future.graphql';
import { Future, FutureFilterDTO, Product, TypeOfInstrumentSymbolGroup } from 'vault-client/types/graphql-types';
import { CMEMonthCodes } from 'vault-client/utils/cme-month-codes';
import ENV from 'vault-client/config/environment';

interface ModelParams {
	future_id: string;
	showAllOptions: boolean;
	expirationDate: string | null;
}

interface BarchartAmericanFutureOption {
	symbol: string;
	root: string;
	contract: string;
	contractName: string;
	contractMonth: string;
	exchange: string;
	type: string;
	strike: number;
	expirationDate: string;
	date: string;
	impliedVolatility: number;
	open: number;
	high: number;
	low: number;
	last: number;
	previousClose: number;
	change: number;
	percentChange: number;
	volume: string;
	delta?: number;
	gamma?: number;
	theta?: number;
	vega?: number;
}

interface BarchartSpecialFutureOption {
	symbol: string;
	root: string;
	contract: string;
	contractName: string;
	contractMonth: string;
	exchange: string;
	type: string;
	strike: number;
	expirationDate: string;
	date: string;
	impliedVolatility: number;
	last: number;
	delta?: number;
	gamma?: number;
}

interface StrikeGroupOptions {
	Call?: BarchartFutureOption;
	Put?: BarchartFutureOption;
}

export interface OptionChains {
	[OptionType: string]: {
		expirationDate: string;
		optionsChain: {
			strike: number;
			options: StrikeGroupOptions;
			displayFactor: number;
			fractionDigits: number;
		}[];
	}[];
}

type BarchartFutureOption = BarchartAmericanFutureOption | BarchartSpecialFutureOption;

export default class ProductsShowFuturesOptions extends Route {
	queryParams = {
		showAllOptions: {
			refreshModel: true,
		},
	};

	@service apollo: any;

	async model(params: ModelParams) {
		const { Product } = this.modelFor('markets.product') as { Product: Product };

		const where: FutureFilterDTO = {
			Product: {
				slug: {
					equals: Product.slug,
				},
			},
			isStandardContractSize: { equals: true },
		};

		const orderBy = {
			displayExpiresAt: 'Asc',
		};

		const { Futures } = (await this.apollo.watchQuery({
			query,
			variables: { where, orderBy },
		})) as { Futures: Future[] };

		const currentFutureIdx = Futures.findIndex((future) => future.id === params.future_id);
		const currentFuture = Futures[currentFutureIdx];
		const prevFutureIdx = currentFutureIdx - 1;

		const futureSymbols: string[] = [];

		if (prevFutureIdx >= 0) {
			const prevFutureExpDate = DateTime.fromISO(Futures[prevFutureIdx].displayExpiresAt);

			const currentFutureExpDate = DateTime.fromISO(currentFuture.displayExpiresAt);

			let expDate = currentFutureExpDate;

			while (expDate.month !== prevFutureExpDate.month || expDate.year !== prevFutureExpDate.year) {
				const monthCode = CMEMonthCodes[expDate.month];

				if (Product.barchartRootSymbol && monthCode) {
					futureSymbols.push(Product.barchartRootSymbol + monthCode + expDate.toFormat('yy'));
				}

				expDate = expDate.minus({ month: 1 });
			}
		}

		const fetchAmericanOptionsRequests: Promise<BarchartAmericanFutureOption[]>[] = [];
		const fetchSpecialOptionsRequests: Promise<BarchartSpecialFutureOption[]>[] = [];

    const apiKey: string = ENV.barchartApiToken;
		for (const symbol of futureSymbols) {
			const optionParams = new URLSearchParams({
				apikey: apiKey,
				contract: symbol,
				fields: 'last,impliedVolatility,gamma',
			});

			fetchAmericanOptionsRequests.push(
				fetch('https://ondemand.websol.barchart.com/getFuturesOptions.json?' + optionParams.toString())
					.then((res) => res.json())
					.then((json: { results: BarchartAmericanFutureOption[] }) => json.results)
			);

			fetchSpecialOptionsRequests.push(
				fetch('https://ondemand.websol.barchart.com/getSpecialOptions.json?' + optionParams.toString())
					.then((res) => res.json())
					.then((json: { results: BarchartSpecialFutureOption[] }) => json.results)
			);
		}

		const [americanOptionsResponse, specialOptionsResponse] = await Promise.all([
			Promise.all(fetchAmericanOptionsRequests),
			Promise.all(fetchSpecialOptionsRequests),
		]);

		const americanOptions = americanOptionsResponse.filter((n) => n).flat();
		const specialOptions = specialOptionsResponse.filter((n) => n).flat();
		const americanOptionsMap = new Map<string, any>();
		const specialOptionsMap = new Map<string, any>();

		americanOptions.forEach((option) => {
			if (americanOptionsMap.has(option.expirationDate)) {
				if (!americanOptionsMap.get(option.expirationDate)[option.strike]) {
					americanOptionsMap.get(option.expirationDate)[option.strike] = {};
				}
				americanOptionsMap.get(option.expirationDate)[option.strike][option.type] = option;
			} else {
				americanOptionsMap.set(option.expirationDate, {});
				americanOptionsMap.get(option.expirationDate)[option.strike] = {};
				americanOptionsMap.get(option.expirationDate)[option.strike][option.type] = option;
			}
		});

		specialOptions.forEach((option) => {
			if (specialOptionsMap.has(option.contractName)) {
				if (!specialOptionsMap.get(option.contractName)[option.expirationDate]) {
					specialOptionsMap.get(option.contractName)[option.expirationDate] = {};
				}

				if (!specialOptionsMap.get(option.contractName)[option.expirationDate][option.strike]) {
					specialOptionsMap.get(option.contractName)[option.expirationDate][option.strike] = {};
				}

				specialOptionsMap.get(option.contractName)[option.expirationDate][option.strike][option.type] = option;
			} else {
				specialOptionsMap.set(option.contractName, {});
				specialOptionsMap.get(option.contractName)[option.expirationDate] = {};
				specialOptionsMap.get(option.contractName)[option.expirationDate][option.strike] = {};
				specialOptionsMap.get(option.contractName)[option.expirationDate][option.strike][option.type] = option;
			}
		});

		const optionSymbolGroup = Product.InstrumentSymbolGroups.find((symbolGroup) => symbolGroup.type === TypeOfInstrumentSymbolGroup.Option);

		const americanOptionChainsArray = [
			{
				['American Options' as string]: Array.from(americanOptionsMap, ([expirationDate, strikes]) => {
					const americanOptionsChainArray = Object.keys(strikes)
						.map((key) => {
							return {
								strike: +key,
								options: strikes[key] as StrikeGroupOptions,
								displayFactor: optionSymbolGroup?.displayFactor,
								fractionDigits: optionSymbolGroup?.fractionDigits,
							};
						})
						.sort((a, b) => (a.strike < b.strike ? -1 : 1));

					return {
						expirationDate,
						optionsChain: americanOptionsChainArray,
					};
				}),
			},
		];

		const specialOptionChainsArray = Array.from(specialOptionsMap, ([optionName, expirationDates]) => {
			const specialOptionExpirationDatesArray = Object.keys(expirationDates).map((expirationDate) => {
				const specialOptionChainArray = Object.keys(expirationDates[expirationDate])
					.map((strike) => {
						return {
							strike: +strike,
							options: expirationDates[expirationDate][strike] as StrikeGroupOptions,
							displayFactor: optionSymbolGroup?.displayFactor as number,
							fractionDigits: optionSymbolGroup?.fractionDigits as number,
						};
					})
					.sort((a, b) => (a.strike < b.strike ? -1 : 1));
				return {
					expirationDate: expirationDate,
					optionsChain: specialOptionChainArray,
				};
			});
			return {
				[optionName as string]: specialOptionExpirationDatesArray,
			};
		});

		const optionChainsArray = americanOptionChainsArray.concat(specialOptionChainsArray);

		const optionChains: OptionChains = {};

		for (const obj of optionChainsArray) {
			const key = Object.keys(obj)[0];
			Object.assign(optionChains, { [key]: obj[key] });
		}

		for (const key of Object.keys(optionChains)) {
			optionChains[key].sort((a, b) => {
				if (a.expirationDate < b.expirationDate) {
					return -1;
				}
				if (a.expirationDate > b.expirationDate) {
					return 1;
				}
				return 0;
			});
		}

		if (params.showAllOptions) {
			return { optionChains, Future: currentFuture, Product };
		} else {
			const filteredOptionChains = optionChains;
      const apiKey: string = ENV.barchartApiToken;
			const currentFutureBarchartSymbol = currentFuture.barchartSymbol;
			const currentFuturePrice = currentFutureBarchartSymbol
				? await fetch(
						`https://ondemand.websol.barchart.com/getQuote.json?apikey=${apiKey}&symbols=${currentFutureBarchartSymbol}`,
						{
							method: 'GET',
							headers: {},
						}
				  )
						.then((res) => res.json())
						.then((json: { results: { lastPrice: number }[] }) => (json.results?.length > 0 ? json.results[0].lastPrice : null))
				: null;

			if (currentFuturePrice) {
				for (const optionType of Object.keys(filteredOptionChains)) {
					filteredOptionChains[optionType].forEach((obj) => {
						const atmStrikeIndex = obj.optionsChain.findIndex((strikeGroup) => {
							return strikeGroup.strike > currentFuturePrice;
						});

						const startIndex = atmStrikeIndex - 10 > 0 ? atmStrikeIndex - 10 : 0;
						const endIndex = atmStrikeIndex + 10;

						obj.optionsChain = obj.optionsChain.slice(startIndex, endIndex + 1);
					});
				}
			}

			return { optionChains: filteredOptionChains, Future: currentFuture, Product };
		}
	}

	setupController(controller: any, model: any, transition: any): void {
		super.setupController(controller, model, transition);

		if (!controller.optionName) {
			if (model.optionChains['American Options'].length) {
				controller.optionName = 'American Options';
			} else {
				controller.optionName = Object.keys(model.optionChains)?.[0] ?? null;
			}
		}

		controller.expirationDate = model.optionChains[controller.optionName].map((obj: any) => obj.expirationDate).sort()?.[0] ?? null;
	}
}
