import Route from '@ember/routing/route';
import { queryManager } from 'ember-apollo-client';
import { inject as service } from '@ember/service';
import query from 'vault-client/graphql/queries/reports/pl-by-calendar-month.graphql';
import { DateTime } from 'luxon';
import { HistoricalPnlFilterDTO } from 'vault-client/types/graphql-types';

interface RouteParams {
	accountId?: string;
	customerId?: string;
	calendarMonthStartDate: string;
	calendarMonthEndDate: string;
}

interface QueryParams {
	where: HistoricalPnlFilterDTO;
}

export default class ReportsPlByCalendarMonthIndexRoute extends Route {
	@service marketData: any;
	@queryManager apollo: any;

	queryParams = {
		calendarMonthStartDate: { refreshModel: true },
		calendarMonthEndDate: { refreshModel: true },
		accountId: { refreshModel: true },
	};

	async model(params: RouteParams) {
		const businessParams = this.paramsFor('businesses.business') as { business_id: string };

		const datesSet: Set<string> = new Set();
		let currentDate = DateTime.fromISO(params.calendarMonthStartDate).startOf('month');
		let currentDateIso;
		const endDate = DateTime.fromISO(params.calendarMonthEndDate);
		let endDateIso = params.calendarMonthEndDate;

		while ((endDate.diff(currentDate).toObject().milliseconds ?? 0) > 0) {
			currentDateIso = currentDate.toISODate();
			datesSet.add(currentDateIso);
			currentDate = currentDate.plus({ month: 1 });
		}

		//
		//	Add the final date, so we can calculate the change for the last month.
		//
		if ((endDate.diff(DateTime.local()).toObject().milliseconds ?? 0) > 0) {
			// If the end date is after the current date, use the current date for month-to-date values.
			datesSet.add(DateTime.local().toISODate());
		} else {
			// Otherwise, get the ending value for the last month.
			endDateIso = endDate.plus({ month: 1 }).startOf('month').toISODate();
			datesSet.add(endDateIso);
		}

		const dates = Array.from(datesSet.values());

		const variables: QueryParams = {
			where: {
				startDate: params.calendarMonthStartDate,
				endDate: endDateIso,
				date: {
					in: dates,
				},
				transactions: {
					Account: {
						customerId: {
							equals: businessParams.business_id,
						},
					},
				},
			},
		};

		if (params.accountId && variables.where.transactions?.Account) {
			variables.where.transactions.Account.id = {
				equals: params.accountId,
			};
		}

		const response = await this.apollo.watchQuery({ query, variables });

		return response;
	}
}
