import Component from '@glimmer/component';
import { htmlSafe } from '@ember/template';

interface VaultTableCellInputArgs {
	datum: { [key: string]: any };
	conditionalStyleFn?: (datum: { [key: string]: any }, ...args: any[]) => string;
	style?: string;
	type?: string;
	valuePath: string;
}

export default class VaultTableCellInput extends Component<VaultTableCellInputArgs> {
	get style(): any {
		return htmlSafe((this.args.conditionalStyleFn ? this.args.conditionalStyleFn(this.args.datum) : '') + ' ' + (this.args.style ?? ''));
	}
}
