/**
 * Creates an array of historical symbols derived from the barchartSymbol and the numberOfYears. The array will be the length of the numberOfYears provided.
 * @param barchartSymbol
 * @param numberOfYears
 * @returns
 */

export default function buildHistoricalSymbolsArray(barchartSymbol: string, numberOfYears: number) {
	const symbolPrefix: string = barchartSymbol.slice(0, -2);
	const symbolPostfix: number = parseInt(barchartSymbol.slice(-2));
	const contractSymbols: Array<string> = [];
	for (let i = 1; i <= numberOfYears; i++) {
		`${symbolPrefix}${Intl.NumberFormat('us-en', {
			minimumIntegerDigits: 2,
		}).format(symbolPostfix - i)}`;

		contractSymbols.push(
			`${symbolPrefix}${Intl.NumberFormat('us-en', {
				minimumIntegerDigits: 2,
			}).format(symbolPostfix - i)}`
		);
	}

	return contractSymbols;
}
