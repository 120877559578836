import Controller from '@ember/controller';
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { Chart, ChartData, ChartDataset, ChartOptions, ScaleOptions } from 'chart.js';
import { Instance } from 'flatpickr/dist/types/instance';
import { DateTime } from 'luxon';
import { tracked } from 'tracked-built-ins';
import SwineMarketingWeek from 'vault-client/models/swine-marketing-week';
import BusinessesBusinessWeanedPigsRoute from 'vault-client/routes/businesses/business/weaned-pigs';
import { CellComponents, TableColumn } from 'vault-client/types/vault-table';
import { ModelFrom } from 'vault-client/utils/type-utils';
import { TypeOfLivestockPopulationChangeReason } from 'vault-client/types/graphql-types';
import { getCustomLegend, getCustomTooltip } from 'vault-client/utils/chart-utils';
import { add, formatISO, getWeek, getYear, parseISO, setDay, startOfWeek } from 'date-fns';
import checkStorageAvailable from 'vault-client/utils/check-storage-available';
import ENV from 'vault-client/config/environment';

enum ChartDisplayData {
	WeanedPigs = 'Weaned Pigs',
	TotalCost = 'Total Cost',
	CostPerHead = 'Cost Per Head',
}

export default class BusinessesBusinessWeanedPigsController extends Controller {
	@tracked activeChartDisplayData = ChartDisplayData.WeanedPigs;
	@tracked startDate: string = formatISO(startOfWeek(add(new Date(), { weeks: 1 }), { weekStartsOn: 0 }), { representation: 'date' });
	@tracked endDate: string = formatISO(startOfWeek(add(new Date(), { weeks: 52 }), { weekStartsOn: 0 }), { representation: 'date' });
	@tracked selectedRows: SwineMarketingWeek[] = [];
	@tracked showModal: boolean = false;

	queryParams: string[] = ['startDate', 'endDate'];

	declare model: ModelFrom<BusinessesBusinessWeanedPigsRoute>;

	id = guidFor(this);

	birthWeekRangeOptions = [];

	get weanedPigsChartId() {
		return `weaned-pigs-chart-${this.id}`;
	}

	get costPerHeadChartId() {
		return `cost-per-head-chart-${this.id}`;
	}

	get totalCostChartId() {
		return `total-cost-chart-${this.id}`;
	}

	get legendId() {
		return `charts-legend-${this.id}`;
	}

	get tableId() {
		return `weaned-pigs-table-${this.id}`;
	}

	get vaultColumnControlId() {
		return `vault-column-control-${this.id}`;
	}

	get activeChartId() {
		if (this.activeChartDisplayData === ChartDisplayData.WeanedPigs) {
			return this.weanedPigsChartId;
		} else if (this.activeChartDisplayData === ChartDisplayData.TotalCost) {
			return this.totalCostChartId;
		} else {
			return this.costPerHeadChartId;
		}
	}

	get legendSubheader() {
		switch (this.activeChartDisplayData) {
			case ChartDisplayData.WeanedPigs:
				return 'Volume by Wean Week';
			case ChartDisplayData.TotalCost:
				return 'Total Cost by Wean Week';
			case ChartDisplayData.CostPerHead:
				return 'Cost Per Head By Wean Week';
			default:
				throw new Error('activeChartDisplayData was not valid when getting legendSubheader');
		}
	}

	get chartDisplayDataOptions(): ChartDisplayData[] {
		return [ChartDisplayData.WeanedPigs, ChartDisplayData.TotalCost, ChartDisplayData.CostPerHead];
	}

	get lastUpdatedAtString() {
		const lastUpdatedAt = DateTime.fromISO(this.model.lastUpdatedAt);

		return `Last updated: ${lastUpdatedAt.toLocaleString(DateTime.DATE_SHORT)} at ${lastUpdatedAt.toLocaleString(DateTime.TIME_SIMPLE)}`;
	}

	get weeksUntilMarket() {
		return this.model.getWeanedPigs.data?.Customer?.averageFinishAgeInWeeks ?? (ENV.APP.DEFAULT_AVG_SWINE_FINISH_AGE_IN_WEEKS as number);
	}

	get columns(): TableColumn[] {
		return [
			{
				id: '321d2075-2d44-45a2-9b8d-d076437c4a4f',
				name: 'Wean Week',
				valuePath: 'readableBirthWeek',
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isFixed: '',
				isVisible: true,
				isSortable: false,
			},
			{
				id: '36758563-63fa-40c2-8d4d-0a24dbbdcd49',
				name: 'Marketing Week',
				valuePath: 'readableMarketingWeek',
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isFixed: '',
				isVisible: true,
				isSortable: false,
			},
			{
				id: '6c9a067e-af4c-4be5-b255-14bca4def979',
				name: 'Weaned Produced',
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: 'a914eda8-31a6-40b8-8e01-f186ffbb2557',
						name: 'Head',
						valuePath: 'produced',
						textAlign: 'right',
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							maximumFractionDigits: 0,
							minimumFractionDigits: 0,
						},
						isFixed: '',
						isVisible: true,
					},
					{
						id: '98fbbb1b-0c77-4e53-8103-6f5f883f517f',
						name: 'Cost/Head',
						valuePath: 'producedCostPerHead',
						textAlign: 'right',
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							maximumFractionDigits: 2,
							minimumFractionDigits: 2,
						},
						isFixed: '',
						isVisible: true,
					},
					{
						id: '32bbff6a-471a-4cc6-8f9b-7ee846a625ae',
						name: 'Total Cost',
						valuePath: 'producedTotalCost',
						textAlign: 'right',
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							maximumFractionDigits: 2,
							minimumFractionDigits: 2,
						},
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: '6ad96e36-ee97-4e64-94fc-1ec702401866',
				name: 'Weaned Purchased',
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: '472ddaef-db24-49d4-b2db-8b5e0321da31',
						name: 'Head',
						valuePath: 'purchased',
						textAlign: 'right',
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							maximumFractionDigits: 0,
							minimumFractionDigits: 0,
						},
						isFixed: '',
						isVisible: true,
					},
					{
						id: '51efab5a-37b1-476c-b0dd-898d16bf91f3',
						name: 'Cost/Head',
						valuePath: 'purchasedCostPerHead',
						textAlign: 'right',
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							maximumFractionDigits: 2,
							minimumFractionDigits: 2,
						},
						isFixed: '',
						isVisible: true,
					},
					{
						id: '227e81eb-bd51-4215-b9f4-8d99d79c3a14',
						name: 'Total Cost',
						valuePath: 'purchasedTotalCost',
						textAlign: 'right',
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							maximumFractionDigits: 2,
							minimumFractionDigits: 2,
						},
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: '003d9edc-546b-4fdb-b794-b114bbf2ed3f',
				name: 'Purchased and Produced',
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: '9fd1a146-be2f-4cb7-8d09-e9dfdd962e4e',
						name: 'Head',
						valuePath: 'purchasedAndProduced',
						textAlign: 'right',
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							maximumFractionDigits: 0,
							minimumFractionDigits: 0,
						},
						isFixed: '',
						isVisible: true,
					},
					{
						id: '38a234db-66f5-4130-9741-2ffbb6de9a0d',
						name: 'Cost/Head',
						textAlign: 'right',
						valuePath: 'purchasedAndProducedCostPerHead',
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							maximumFractionDigits: 2,
							minimumFractionDigits: 2,
						},
						isFixed: '',
						isVisible: true,
					},
					{
						id: 'b69006fe-5da8-482f-92ab-ba96c9c4fffe',
						name: 'Total Cost',
						textAlign: 'right',
						valuePath: 'purchasedAndProducedTotalCost',
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							maximumFractionDigits: 2,
							minimumFractionDigits: 2,
						},
						isFixed: '',
						isVisible: true,
					},
				],
			},
		];
	}

	get marketingWeeks() {
		const swineMarketingWeeksMap = new Map<string, SwineMarketingWeek>();

		const getSwineMarketingWeek = (birthDate: string) => {
			const marketingWeek = swineMarketingWeeksMap.get(birthDate);
			return marketingWeek;
		};

		let currDate = startOfWeek(parseISO(this.startDate), { weekStartsOn: 0 });
		const endDate = startOfWeek(parseISO(this.endDate), { weekStartsOn: 0 });
		const avgWeeksUntilMarket = this.weeksUntilMarket;

		while (currDate <= endDate) {
			const date = formatISO(currDate, { representation: 'date' });
			swineMarketingWeeksMap.set(date, new SwineMarketingWeek(date, avgWeeksUntilMarket));
			currDate = add(currDate, { weeks: 1 });
		}

		this.model.getWeanedPigs.data?.AggregateSwineLivestockPopulationChanges.forEach((populationChange) => {
			const dob = populationChange.dob;

			if (!dob) {
				throw new Error('Date of Birth not found for population change. Please ensure it was requested');
			}

			const birthWeek = formatISO(startOfWeek(parseISO(dob), { weekStartsOn: 0 }), { representation: 'date' });
			const swineMarketingWeek = getSwineMarketingWeek(birthWeek);
			if (!swineMarketingWeek) return;

			if (populationChange.reasonType === TypeOfLivestockPopulationChangeReason.Birth) {
				swineMarketingWeek.produced = populationChange.sum.quantity ?? null;
				swineMarketingWeek.producedTotalCost = populationChange.sum.totalValue ?? null;
			} else if (populationChange.reasonType === TypeOfLivestockPopulationChangeReason.Purchase) {
				swineMarketingWeek.purchased = populationChange.sum.quantity ?? null;
				swineMarketingWeek.purchasedTotalCost = populationChange.sum.totalValue ?? null;
			}
		});

		return Array.from(swineMarketingWeeksMap.values()).sortBy('birthWeek');
	}

	get chartLabels() {
		return this.marketingWeeks.map((week) => week.birthWeek);
	}

	get weanedPigsRawData() {
		return {
			Produced: this.marketingWeeks.map((week) => week.produced ?? 0),
			Purchased: this.marketingWeeks.map((week) => week.purchased ?? 0),
		};
	}

	get weanedPigsChartData(): ChartData {
		const datasets: ChartDataset<'bar'>[] = [
			{
				label: 'Produced',
				data: this.weanedPigsRawData['Produced'],
				backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-blue-60'),
			},
			{
				label: 'Purchased',
				data: this.weanedPigsRawData['Purchased'],
				backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-orange-40'),
			},
		];

		return {
			labels: this.chartLabels,
			datasets,
		};
	}

	get totalCostRawData() {
		return {
			Produced: this.marketingWeeks.map((week) => week.producedTotalCost ?? 0),
			Purchased: this.marketingWeeks.map((week) => week.purchasedTotalCost ?? 0),
		};
	}

	get totalCostChartData(): ChartData {
		const datasets: ChartDataset<'bar'>[] = [
			{
				label: 'Produced',
				data: this.totalCostRawData['Produced'],
				backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-blue-60'),
			},
			{
				label: 'Purchased',
				data: this.totalCostRawData['Purchased'],
				backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-orange-40'),
			},
		];

		return {
			labels: this.chartLabels,
			datasets,
		};
	}

	get costPerHeadRawData() {
		return {
			'Produced Average': this.marketingWeeks.map((week) => week.producedCostPerHead),
			'Purchased Average': this.marketingWeeks.map((week) => week.purchasedCostPerHead),
			'Purchased and Produced Average': this.marketingWeeks.map((week) => week.purchasedAndProducedCostPerHead),
		};
	}

	get costPerHeadChartData(): ChartData {
		const datasets: ChartDataset<'line'>[] = [
			{
				label: 'Produced Average',
				data: this.costPerHeadRawData['Produced Average'],
				backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-blue-60'),
			},
			{
				label: 'Purchased Average',
				data: this.costPerHeadRawData['Purchased Average'],
				backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-orange-40'),
			},
			{
				label: 'Purchased and Produced Average',
				data: this.costPerHeadRawData['Purchased and Produced Average'],
				backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-lime-40'),
			},
		];

		return {
			labels: this.chartLabels,
			datasets,
		};
	}

	get costPerHeadYScaleOptions(): ScaleOptions<'linear'> {
		return {
			ticks: {
				autoSkip: true,
				font: {
					size: 12,
				},
				callback: (val) => {
					if (typeof val === 'string') {
						return val;
					} else {
						return new Intl.NumberFormat('en-US', {
							notation: 'compact',
							style: 'currency',
							currency: 'USD',
						}).format(val);
					}
				},
				color: getComputedStyle(document.documentElement).getPropertyValue('brand-gray-70'),
			},
		};
	}

	get costPerHeadXScaleOptions(): ScaleOptions<'linear'> {
		return {
			ticks: {
				autoSkip: true,
				font: {
					size: 12,
				},
				callback: function (_tickValue, index) {
					const previousDate = index === 0 ? null : parseISO(this.getLabelForValue(index - 1));
					const currentDate = parseISO(this.getLabelForValue(index));
					const previousYear = previousDate ? getYear(setDay(previousDate, 4, { weekStartsOn: 0 })) : null;
					const currentYear = getYear(setDay(currentDate, 4, { weekStartsOn: 0 }));

					if (previousDate === null || previousYear !== currentYear) {
						return [getWeek(currentDate, { weekStartsOn: 0, firstWeekContainsDate: 4 }), currentYear];
					} else {
						return getWeek(currentDate, { weekStartsOn: 0, firstWeekContainsDate: 4 });
					}
				},
				color: getComputedStyle(document.documentElement).getPropertyValue('brand-gray-70'),
			},
		};
	}

	get weanedPigsChartOptions(): ChartOptions<'bar'> {
		return {
			maintainAspectRatio: false,
			responsive: true,
			interaction: {
				intersect: false,
				mode: 'index',
			},
			layout: {
				padding: {
					top: 12,
					left: 12,
					right: 12,
				},
			},
			datasets: {
				bar: {
					borderRadius: 2,
					categoryPercentage: 0.8,
				},
			},
			scales: {
				y: {
					grid: {
						display: true,
					},
					stacked: true,
					ticks: {
						autoSkip: true,
						font: {
							size: 12,
						},
						callback: (val) => {
							if (typeof val === 'string') {
								return val;
							} else {
								return new Intl.NumberFormat('en-US', {
									notation: 'compact',
								}).format(val);
							}
						},
						color: getComputedStyle(document.documentElement).getPropertyValue('brand-gray-70'),
					},
				},
				x: {
					stacked: true,
					ticks: {
						callback: function (_tickValue, index) {
							const previousDate = index === 0 ? null : parseISO(this.getLabelForValue(index - 1));
							const currentDate = parseISO(this.getLabelForValue(index));
							const previousYear = previousDate ? getYear(setDay(previousDate, 4, { weekStartsOn: 0 })) : null;
							const currentYear = getYear(setDay(currentDate, 4, { weekStartsOn: 0 }));

							if (previousDate === null || previousYear !== currentYear) {
								return [getWeek(currentDate, { weekStartsOn: 0, firstWeekContainsDate: 4 }), currentYear];
							} else {
								return getWeek(currentDate, { weekStartsOn: 0, firstWeekContainsDate: 4 });
							}
						},
					},
					grid: {
						display: false,
					},
				},
			},
			plugins: {
				legend: {
					display: false,
					labels: {
						usePointStyle: true,
						boxWidth: 2,
					},
				},
				tooltip: {
					enabled: false,
					external: this.customTooltip,
				},
			},
		};
	}

	get totalCostChartOptions(): ChartOptions<'bar'> {
		return {
			maintainAspectRatio: false,
			responsive: true,
			interaction: {
				intersect: false,
				mode: 'index',
			},
			layout: {
				padding: {
					top: 12,
					left: 12,
					right: 12,
				},
			},
			datasets: {
				bar: {
					borderRadius: 2,
					categoryPercentage: 0.8,
				},
			},
			scales: {
				y: {
					grid: {
						display: true,
					},
					stacked: true,
					ticks: {
						autoSkip: true,
						font: {
							size: 12,
						},
						callback: (val) => {
							if (typeof val === 'string') {
								return val;
							} else {
								return new Intl.NumberFormat('en-US', {
									notation: 'compact',
									style: 'currency',
									currency: 'USD',
								}).format(val);
							}
						},
						color: getComputedStyle(document.documentElement).getPropertyValue('brand-gray-70'),
					},
				},
				x: {
					stacked: true,
					ticks: {
						callback: function (_tickValue, index) {
							const previousDate = index === 0 ? null : parseISO(this.getLabelForValue(index - 1));
							const currentDate = parseISO(this.getLabelForValue(index));
							const previousYear = previousDate ? getYear(setDay(previousDate, 4, { weekStartsOn: 0 })) : null;
							const currentYear = getYear(setDay(currentDate, 4, { weekStartsOn: 0 }));

							if (previousDate === null || previousYear !== currentYear) {
								return [getWeek(currentDate, { weekStartsOn: 0, firstWeekContainsDate: 4 }), currentYear];
							} else {
								return getWeek(currentDate, { weekStartsOn: 0, firstWeekContainsDate: 4 });
							}
						},
					},
					grid: {
						display: false,
					},
				},
			},
			plugins: {
				legend: {
					display: false,
					labels: {
						usePointStyle: true,
						boxWidth: 2,
					},
				},
				tooltip: {
					enabled: false,
					external: this.customTooltip,
				},
			},
		};
	}

	get weanedPigsChartPlugins() {
		const chartId = this.weanedPigsChartId;
		const legendId = this.legendId;
		return [
			{
				afterUpdate: getCustomLegend(chartId, legendId),
			},
		];
	}

	get totalCostsChartPlugins() {
		const chartId = this.totalCostChartId;
		const legendId = this.legendId;
		return [
			{
				afterUpdate: getCustomLegend(chartId, legendId),
			},
		];
	}

	customTooltip = getCustomTooltip({
		titleFormatter: (title: string) => this.getWeekAndYear(title),
		valueFormatter: (dataPoint: number) =>
			Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0 }).format(Math.round(dataPoint)),
	});

	@action
	dateRangeGetWeek(dateObj: Date) {
		return getWeek(dateObj, { weekStartsOn: 0, firstWeekContainsDate: 4 });
	}

	@action
	dateRangeOnReady(_selectedDates: Date[], _dateStr: string, _instance: Instance) {
		const [startDate, endDate] = _selectedDates;
		if (!startDate || !endDate) return;
		this.setFormattedDatePickerString(startDate, endDate, _instance);
	}

	@action
	dateRangeOnChange(_selectedDates: Date[], _dateStr: string, _instance: Instance): void {
		const [startDate, endDate] = _selectedDates;
		if (!startDate || !endDate) return;
		const formattedStartDate = formatISO(startDate, { representation: 'date' });
		const formattedEndDate = formatISO(endDate, { representation: 'date' });
		this.startDate = formattedStartDate;
		this.endDate = formattedEndDate;
		this.setStoredDateRange(formattedStartDate, formattedEndDate);

		this.setFormattedDatePickerString(startDate, endDate, _instance);
	}

	setFormattedDatePickerString(startDate: Date, endDate: Date, instance: Instance) {
		const adjustedStartDate = startOfWeek(startDate, { weekStartsOn: 0 });
		const adjustedEndDate = startOfWeek(endDate, { weekStartsOn: 0 });
		const startYear = getYear(setDay(adjustedStartDate, 4, { weekStartsOn: 0 }));
		const endYear = getYear(setDay(adjustedEndDate, 4, { weekStartsOn: 0 }));

		const yearString = startYear === endYear ? startYear : `${startYear} - ${endYear}`;

		const dateString = `Wean Weeks ${getWeek(adjustedStartDate, { weekStartsOn: 0, firstWeekContainsDate: 4 })} - ${getWeek(
			adjustedEndDate,
			{ weekStartsOn: 0, firstWeekContainsDate: 4 },
		)}, ${yearString}`;

		(instance.element as HTMLInputElement).value = dateString;
	}

	@action
	onEditModalCancel() {
		this.showModal = false;
		this.selectedRows = [];
	}

	@action
	onEditModalSubmit() {
		this.showModal = false;
		this.selectedRows = [];
	}

	@action
	getWeekAndYear(date: string) {
		const parsedDate = parseISO(date);
		return `w${getWeek(parsedDate, { weekStartsOn: 0 })} ${getYear(setDay(parsedDate, 4, { weekStartsOn: 0 }))}`;
	}

	@action
	formatCurrency(val: number) {
		return new Intl.NumberFormat('en-US', {
			notation: 'compact',
			style: 'currency',
			currency: 'USD',
			minimumFractionDigits: 2,
			maximumFractionDigits: 2,
		}).format(val);
	}

	@action
	updateChart(chart: Chart) {
		if (this.activeChartDisplayData === ChartDisplayData.TotalCost) {
			chart.data = this.totalCostChartData;
		} else if (this.activeChartDisplayData === ChartDisplayData.WeanedPigs) {
			chart.data = this.weanedPigsChartData;
		}

		chart.update('none');
	}

	setStoredDateRange(startDate: string | undefined, endDate: string | undefined) {
		const businessId = this.model.customerId;
		if (checkStorageAvailable('localStorage') && businessId) {
			const datesToStore: { startDate?: string; endDate?: string } = {};

			if (startDate) {
				datesToStore.startDate = startDate;
			}

			if (endDate) {
				datesToStore.endDate = endDate;
			}

			if (Object.keys(datesToStore).length) {
				const stringifiedDatesToStore = JSON.stringify(datesToStore);
				window.localStorage.setItem(`${businessId}.weaned-pigs.dateRange`, stringifiedDatesToStore);
			}
		}
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'businesses/business/weaned-pigs': BusinessesBusinessWeanedPigsController;
	}
}
