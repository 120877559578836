import Controller from '@ember/controller';
import { UiSecondaryNavItemArgs } from 'vault-client/components/ui-secondary-nav-item';
import { Product } from 'vault-client/types/graphql-types';

export default class MarketsProduct extends Controller {
	model = this.model as { Product: Product };

	get secondaryNavItems(): Omit<UiSecondaryNavItemArgs, 'uid'>[] {
		const items: Omit<UiSecondaryNavItemArgs, 'uid'>[] = [
			{
				label: 'Overview',
				link: 'markets.product.overview',
				linkModel: this.model.Product.id,
			},
		];

		if (this.model.Product.MostCurrentFuture) {
			items.push({
				label: 'Futures',
				link: 'markets.product.futures.index',
				linkModel: this.model.Product.id,
				children: [
					{
						label: 'Future Detail',
						link: 'markets.product.futures.future',
						hidden: true,
					},
				],
			});
		}

		if (this.model.Product.CurrentFutures.length > 0) {
			items.push({
				label: 'Seasonality',
				link: 'markets.product.seasonality',
				linkModel: this.model.Product.id,
			});
		}

		if (this.model.Product.CashInstrument) {
			items.push({
				label: 'CME Spot',
				link: 'markets.product.cme-spot',
				linkModel: this.model.Product.id,
			});
		}

		return items;
	}

	get previous(): { label: string; route: string; query?: string; model?: string } | null {
		return {
			label: 'Markets',
			route: 'markets',
		};
	}

	get current(): { label: string; route: string; model: string } | null {
		return {
			label: this.model.Product.name,
			route: 'markets.product',
			model: this.model.Product.id,
		};
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'markets/product': MarketsProduct;
	}
}
