import { helper } from '@ember/component/helper';
import { TypeOfGrainOrderSales } from 'vault-client/types/graphql-types';
import { getSalesTypeDisplayValue } from 'vault-client/utils/vgs-utils';

export default helper(function formatSalesType(positional: [unknown]) {
	const salesType = positional[0];
	if (!salesType || typeof salesType !== 'string') return '';

	if (!(Object.values(TypeOfGrainOrderSales) as string[]).includes(salesType)) return '';

	return getSalesTypeDisplayValue(salesType as TypeOfGrainOrderSales);
});
