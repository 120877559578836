import Controller from '@ember/controller';
import { AggregateDerivedDrpEndorsementDTO, BusinessEntityRole, LgmInsuranceEndorsement } from 'vault-client/types/graphql-types';
import { DateTime, Interval } from 'luxon';
import InsuranceQuarter from 'vault-client/models/insurance-quarter';
import { CellComponents, TableColumn } from 'vault-client/types/vault-table';
import DerivedInsuranceEndorsement from 'vault-client/models/derived-insurance-endorsement';

import { ModelFrom } from 'vault-client/utils/type-utils';
import BusinessesBusinessInsuranceOverviewRoute from 'vault-client/routes/businesses/business/insurance-overview';
import isTouchDevice from 'vault-client/utils/is-touch-device';
import { intervalFromDateTime } from 'vault-client/utils/interval-from-date-time';
import { LgmInsuranceEndorsementByCoverageMonth, PerMonthData } from 'vault-client/controllers/exposure/exposure-hedge-month-detail';
import LgmEndorsementModel from 'vault-client/models/lgm-endorsement';
import { getOwner } from '@ember/application';
import intlDateTimeFormat from 'vault-client/utils/intl-date-time-format';

interface LGMMonthRow {
	date: string;
	grossProduction: number;
	effectiveCoveredLeanHogsLbs: number;
	effectiveCoveredMilkProduction: number;
	leanHogsPercentHedged: number;
	classIIIPercentHedged: number;
	producerPremiumAmount: number;
	producerPremiumAmountCwt: number | null;
	indemnity: number;
	indemnityCwt: number | null;
	pnl: number;
	pnlCwt: number | null;
}

export default class BusinessesBusinessInsuranceOverview extends Controller {
	insuranceRoutePath = 'businesses.business.insurance';
	lgmInsuranceEndorsementRoutePath = 'businesses.business.lgm-insurance-endorsement';

	declare model: ModelFrom<BusinessesBusinessInsuranceOverviewRoute>;

	get quarterColumns(): TableColumn[] {
		const baseColumns: TableColumn[] = [
			{
				id: 'c02e4e0b-9e0e-41b1-8b25-9f8a0f812fa7',
				name: 'Quarter',
				valuePath: 'startDate',
				width: 125,
				minWidth: 125, // need minWidth === width re bug on resizing first fixed column
				cellComponent: CellComponents.QuarterFormat,
				textAlign: 'left',
				isSortable: false,
				isFixed: !isTouchDevice() ? 'left' : '',
				isVisible: true,
				linkRoute: 'businesses.business.insurance-overview-show',
				linkModelPath: 'startDate',
			},
			{
				id: '89a8cbac-f683-4fb4-871f-5f2fee14c37a',
				name: 'Production',
				valuePath: 'grossProduction',
				minWidth: 150,
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
				isTotaled: true,
			},
			{
				id: '03ba6588-21a2-4f42-9538-a373c542d7c4',
				name: 'Insured Milk',
				valuePath: 'effectiveCoveredMilkProduction',
				minWidth: 150,
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
				isTotaled: true,
			},
			{
				id: '0ea509bb-cc8b-417e-9182-199fc8c3b08d',
				name: 'Insured %',
				cellComponent: CellComponents.String,
				subcolumns: [
					{
						id: 'b0327471-6e13-4ff2-b78b-2cbc5649844e',
						name: 'III',
						valuePath: 'classIiiPercentInsured',
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'percent',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
						isTotaled: true,
					},
					{
						id: '6fb78b38-05ad-444a-b416-e68d5fbc4007',
						name: 'IV',
						valuePath: 'classIvPercentInsured',
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'percent',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
						isTotaled: true,
					},
					{
						id: '586f4c16-efaa-49ff-ada3-73edb9afde1e',
						name: 'Total',
						valuePath: 'percentInsured',
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'percent',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
						isTotaled: true,
					},
				],
				textAlign: 'center',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '11e3ebaf-513b-40fc-a676-9a58aacc872b',
				name: 'Producer Premium',
				cellComponent: CellComponents.String,
				textAlign: 'center',
				isSortable: false,
				isFixed: '',
				isVisible: true,
				isTotaled: true,
				subcolumns: [
					{
						id: '7611a991-011a-427b-af8c-f85f3d7bbab4',
						name: 'Total',
						valuePath: 'producerPremiumAmount',
						minWidth: 130,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							currencySign: 'accounting',
							maximumFractionDigits: 0,
						},
						textAlign: 'right',
						isSortable: true,
						isFixed: '',
						isVisible: true,
						isTotaled: true,
					},
					{
						id: '497e86ee-71f1-4d39-b757-c408d4d7bf2e',
						name: 'CWT',
						valuePath: 'producerPremiumAmountCwt',
						minWidth: 70,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							currencySign: 'accounting',
						},
						textAlign: 'right',
						isSortable: true,
						isFixed: '',
						isVisible: true,
						isTotaled: true,
					},
				],
			},
			{
				id: '6f83249d-cbca-4558-ba60-4ae9897e7561',
				name: 'Indemnity',
				cellComponent: CellComponents.String,
				textAlign: 'center',
				isSortable: false,
				isFixed: '',
				isVisible: true,
				isTotaled: true,
				subcolumns: [
					{
						id: '829c4c5f-b783-48d9-897e-ac37aeb541e7',
						name: 'Total',
						valuePath: 'indemnity',
						minWidth: 130,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							currencySign: 'accounting',
							maximumFractionDigits: 0,
						},
						textAlign: 'right',
						isSortable: true,
						isFixed: '',
						isVisible: true,
						isTotaled: true,
					},
					{
						id: '4d8bd6f7-4e51-49fe-9727-cf566c837e77',
						name: 'CWT',
						valuePath: 'indemnityCwt',
						minWidth: 70,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							currencySign: 'accounting',
						},
						textAlign: 'right',
						isSortable: true,
						isFixed: '',
						isVisible: true,
						isTotaled: true,
					},
				],
			},
			{
				id: '9727bce7-34fc-4e8c-ab48-28ab4ef8d81b',
				name: 'Profit/Loss',
				cellComponent: CellComponents.String,
				textAlign: 'center',
				isSortable: false,
				isFixed: '',
				isVisible: true,
				isTotaled: true,
				subcolumns: [
					{
						id: 'faf5fb15-72b9-40e3-b6ff-bc1f57f10c4f',
						name: 'Total',
						valuePath: 'pnl',
						minWidth: 130,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							currencySign: 'accounting',
							maximumFractionDigits: 0,
						},
						textAlign: 'right',
						isSortable: true,
						isFixed: '',
						isVisible: true,
						isTotaled: true,
					},
					{
						id: 'b9a539d0-d26e-49cb-a6ba-a14fb02d88af',
						name: 'CWT',
						valuePath: 'pnlCwt',
						minWidth: 70,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							currencySign: 'accounting',
						},
						textAlign: 'right',
						isSortable: true,
						isFixed: '',
						isVisible: true,
						isTotaled: true,
					},
				],
			},
		];

		return baseColumns;
	}

	get drpEndorsementColumns() {
		const baseColumns = [
			{
				id: 'efaccab7-ac1c-4c46-8d89-508d3b80268d',
				name: 'Producer',
				valuePath: 'InsurancePolicy.producerName',
				width: 225,
				minWidth: 225, // need minWidth === width re bug on resizing first fixed column
				textAlign: 'left',
				isSortable: false,
				cellComponent: 'String',
				isFixed: !isTouchDevice() ? 'left' : '',
				isVisible: true,
			},
			{
				id: '937966fa-00a3-4900-bed6-88422e3a7e6e',
				name: 'Sales Date',
				valuePath: 'salesEffectiveDate',
				width: 120,
				cellComponent: 'IntlDateTimeFormat',
				componentArgs: { month: 'numeric', day: 'numeric', year: 'numeric' },
				textAlign: 'left',
				isSortable: false,
				isFixed: !isTouchDevice() ? 'left' : '',
				isVisible: true,
				linkRoute: 'businesses.business.drp-insurance-endorsement',
				linkModelPath: 'id',
			},
			{
				id: 'dec87246-681d-46ff-940c-f3eb7c846639',
				name: 'Quarter',
				valuePath: 'quarterStartDate',
				width: 105,
				cellComponent: 'QuarterFormat',
				isSortable: false,
				isFixed: '',
				isVisible: true,
				textAlign: 'left',
			},
			{
				id: '313ad000-e4e5-4f3b-9a0e-603650ebaca7',
				name: 'Policy Number',
				valuePath: 'InsurancePolicy.policyNumber',
				minWidth: 140,
				cellComponent: 'String',
				isSortable: false,
				isFixed: '',
				isVisible: false,
				textAlign: 'right',
			},
			{
				id: '52c01d7d-9b55-4fd1-8584-065b7d4b0d82',
				name: 'Milk Production',
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: 'f0666e8d-70e4-4521-b6ff-7ea7ff4bddbc',
						name: 'Effective',
						valuePath: 'effectiveCoveredMilkProduction',
						width: 110,
						cellComponent: 'IntlNumberFormat',
						textAlign: 'right',
						isSortable: false,
						isVisible: true,
						isFixed: '',
					},
					{
						id: 'de94e3c7-7ac6-4bb3-bc20-763322d66f0d',
						name: 'Declared',
						valuePath: 'declaredCoveredMilkProduction',
						width: 110,
						cellComponent: 'IntlNumberFormat',
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: '2bf9d0f4-ee28-41a4-9844-cb8623d809da',
				name: 'Milk Class',
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: '4df371e7-df9a-4b37-9a02-785e1e61a3d8',
						name: 'III',
						valuePath: 'classIiiPercentage',
						width: 65,
						cellComponent: 'IntlNumberFormat',
						isFixed: '',
						isVisible: true,
						componentArgs: {
							style: 'percent',
						},
						textAlign: 'right',
						isSortable: false,
					},
					{
						id: '6e96708f-a353-4dc5-a5d6-f34354481563',
						name: 'IV',
						valuePath: 'classIvPercentage',
						width: 65,
						cellComponent: 'IntlNumberFormat',
						componentArgs: {
							style: 'percent',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: '90b43b2e-b2e7-465d-a8fe-29b224b28679',
				name: 'Components',
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: 'c62a68de-e674-4628-8ada-eb9bc6cf698f',
						name: 'B',
						valuePath: 'butterfatPercentage',
						width: 75,
						cellComponent: 'IntlNumberFormat',
						componentArgs: {
							style: 'percent',
							minimumFractionDigits: '2',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: '52ddbc9f-1414-4498-84d6-93793cb2b3c3',
						name: 'PT',
						valuePath: 'proteinPercentage',
						width: 75,
						cellComponent: 'IntlNumberFormat',
						componentArgs: {
							style: 'percent',
							minimumFractionDigits: '2',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: 'a62ed077-0781-4996-932f-feae9a9286d1',
				name: 'Revenue Guarantee',
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: '9fe7d20d-e7c8-43a4-a9b4-63bbac1834ad',
						name: 'Total',
						valuePath: 'effectiveExpectedRevenueGuarantee',
						width: 100,
						cellComponent: 'IntlNumberFormat',
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							minimumFractionDigits: '0',
							maximumFractionDigits: '0',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: '0bf15d5b-76b7-4c1f-8bed-152ab8382fee',
						name: 'CWT',
						valuePath: 'effectiveExpectedRevenueGuaranteeCwt',
						width: 75,
						cellComponent: 'IntlNumberFormat',
						isFixed: '',
						isVisible: true,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							minimumFractionDigits: '2',
							maximumFractionDigits: '2',
						},
						textAlign: 'right',
						isSortable: false,
					},
				],
			},
			{
				id: '14b1225b-5ad0-4f3a-9de6-ccf028c4d1e2',
				name: 'Producer Premium',
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: 'd25788a1-af50-4cad-9266-4e588b1c60cf',
						name: 'Total',
						valuePath: 'producerPremiumAmount',
						width: 90,
						cellComponent: 'IntlNumberFormat',
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							minimumFractionDigits: '0',
							maximumFractionDigits: '0',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: '3fc84a8f-8158-4ef6-8840-2d3c5cd977ef',
						name: 'CWT',
						valuePath: 'producerPremiumAmountCwt',
						width: 75,
						cellComponent: 'IntlNumberFormat',
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							minimumFractionDigits: '2',
							maximumFractionDigits: '2',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: 'a0114043-e896-4a78-a6b3-7d388d2f057a',
				name: 'Indemnity',
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: '7fd627fa-65b2-4249-a666-bfd9b320f9bc',
						name: 'Total',
						valuePath: 'indemnity',
						width: 90,
						cellComponent: 'IntlNumberFormat',
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							minimumFractionDigits: '0',
							maximumFractionDigits: '0',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: '49664dc6-5e9d-4cee-a4b5-4c6f2b52b57c',
						name: 'CWT',
						valuePath: 'indemnityCwt',
						width: 75,
						cellComponent: 'IntlNumberFormat',
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							minimumFractionDigits: '2',
							maximumFractionDigits: '2',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: '678fb5b7-c18b-42b3-b015-3f6f60fe6016',
				name: 'Profit/Loss',
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: '5e8f80c8-1709-4cf9-8f5f-9ac04eb7209e',
						name: 'Total',
						valuePath: 'pnl',
						width: 90,
						cellComponent: 'IntlNumberFormat',
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							minimumFractionDigits: '0',
							maximumFractionDigits: '0',
							currencySign: 'accounting',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: 'e084e498-d214-48c5-a7ba-d3b180e9d1e2',
						name: 'CWT',
						valuePath: 'pnlCwt',
						width: 85,
						cellComponent: 'IntlNumberFormat',
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							minimumFractionDigits: '2',
							maximumFractionDigits: '2',
							currencySign: 'accounting',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: '9ecaeff7-2291-4c44-8837-99545b579558',
				name: 'Total Premium',
				isFixed: '',
				isVisible: false,
				subcolumns: [
					{
						id: 'e4fb3d44-65d5-4c3d-8538-2d97c5c777da',
						name: 'Total',
						valuePath: 'totalPremiumAmount',
						width: 90,
						cellComponent: 'IntlNumberFormat',
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							minimumFractionDigits: '0',
							maximumFractionDigits: '0',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: '21f1c217-07b2-4361-aac8-eb7bbd18b100',
						name: 'CWT',
						valuePath: 'totalPremiumAmountCwt',
						width: 75,
						cellComponent: 'IntlNumberFormat',
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							minimumFractionDigits: '2',
							maximumFractionDigits: '2',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
				],
			},
		];
		return baseColumns;
	}

	get openLGMMonthsColumns(): TableColumn[] {
		return [
			{
				id: 'a72ade05-c18d-457d-ab42-c95f7ca96534',
				name: 'Month',
				valuePath: 'date',
				minWidth: 100,
				cellComponent: CellComponents.MonthFormat,
				isVisible: true,
				isSortable: false,
				isFixed: 'left',
			},
			{
				id: '7c59fb99-332a-40d8-b05a-2c7aeaac4805',
				name: 'Production',
				valuePath: 'grossProduction',
				minWidth: 130,
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
				isTotaled: true,
			},
			...(this.isHogProducer
				? [
						{
							id: '965a7141-3a34-4d31-ae19-ddd4be576849',
							name: 'Insured LBS',
							valuePath: 'effectiveCoveredLeanHogsLbs',
							minWidth: 130,
							cellComponent: CellComponents.IntlNumberFormat,
							textAlign: 'right',
							isSortable: false,
							isFixed: '',
							isVisible: true,
							isTotaled: true,
						},
				  ]
				: [
						{
							id: '160ebee2-fa02-4944-9659-2326882415b2',
							name: 'Insured Milk',
							valuePath: 'effectiveCoveredMilkProduction',
							minWidth: 130,
							cellComponent: CellComponents.IntlNumberFormat,
							textAlign: 'right',
							isSortable: false,
							isFixed: '',
							isVisible: true,
							isTotaled: true,
						},
				  ]),
			{
				id: 'e14f96e3-aaae-4c84-80c4-328bb8290a65',
				name: 'Insured %',
				cellComponent: CellComponents.String,
				textAlign: 'center',
				isSortable: false,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					...(this.isHogProducer
						? [
								{
									id: '85a3df9a-6fce-4f51-ac6a-1dce9fe26338',
									name: 'Lean Hogs',
									valuePath: 'leanHogsPercentHedged',
									minWidth: 100,
									cellComponent: CellComponents.IntlNumberFormat,
									componentArgs: {
										style: 'percent',
									},
									textAlign: 'right',
									isSortable: false,
									isFixed: '',
									isVisible: true,
									isTotaled: true,
								},
						  ]
						: [
								{
									id: '85a3df9a-6fce-4f51-ac6a-1dce9fe26338',
									name: 'Class III',
									valuePath: 'classIIIPercentHedged',
									minWidth: 100,
									cellComponent: CellComponents.IntlNumberFormat,
									componentArgs: {
										style: 'percent',
									},
									textAlign: 'right',
									isSortable: false,
									isFixed: '',
									isVisible: true,
									isTotaled: true,
								},
						  ]),
				],
			},
			{
				id: 'ac4c9aac-f331-4314-926d-0b3115050453',
				name: 'Producer Premium',
				cellComponent: CellComponents.String,
				textAlign: 'center',
				isSortable: false,
				isFixed: '',
				isVisible: true,
				isTotaled: true,
				subcolumns: [
					{
						id: '1600a99a-40a8-4b17-a35a-e20120192fcd',
						name: 'Total',
						valuePath: 'producerPremiumAmount',
						minWidth: 130,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							currencySign: 'accounting',
							maximumFractionDigits: 0,
						},
						textAlign: 'right',
						isSortable: true,
						isFixed: '',
						isVisible: true,
						isTotaled: true,
					},
					{
						id: '39b15179-9402-4158-b865-280149bfbd88',
						name: 'CWT',
						valuePath: 'producerPremiumAmountCwt',
						minWidth: 70,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							currencySign: 'accounting',
						},
						textAlign: 'right',
						isSortable: true,
						isFixed: '',
						isVisible: true,
						isTotaled: true,
					},
				],
			},
			{
				id: '05227890-9ce1-4b1b-831a-d9fb8747346c',
				name: 'Indemnity',
				cellComponent: CellComponents.String,
				textAlign: 'center',
				isSortable: false,
				isFixed: '',
				isVisible: true,
				isTotaled: true,
				subcolumns: [
					{
						id: '86643e48-908f-426e-82fa-fde1ef872e63',
						name: 'Total',
						valuePath: 'indemnity',
						minWidth: 130,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							currencySign: 'accounting',
							maximumFractionDigits: 0,
						},
						textAlign: 'right',
						isSortable: true,
						isFixed: '',
						isVisible: true,
						isTotaled: true,
					},
					{
						id: '222a7c83-f64b-4e6e-9d20-5815a7845484',
						name: 'CWT',
						valuePath: 'indemnityCwt',
						minWidth: 70,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							currencySign: 'accounting',
						},
						textAlign: 'right',
						isSortable: true,
						isFixed: '',
						isVisible: true,
						isTotaled: true,
					},
				],
			},
			{
				id: '197314d2-83b0-4ff5-83e1-44d492f1e77f',
				name: 'Profit/Loss',
				cellComponent: CellComponents.String,
				textAlign: 'center',
				isSortable: false,
				isFixed: '',
				isVisible: true,
				isTotaled: true,
				subcolumns: [
					{
						id: '003030d9-f92d-40c3-a175-175fe8815a0e',
						name: 'Total',
						valuePath: 'pnl',
						minWidth: 130,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							currencySign: 'accounting',
							maximumFractionDigits: 0,
						},
						textAlign: 'right',
						isSortable: true,
						isFixed: '',
						isVisible: true,
						isTotaled: true,
					},
					{
						id: 'dad4b711-d611-4aa0-b9f7-bb9a60212c63',
						name: 'CWT',
						valuePath: 'pnlCwt',
						minWidth: 70,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							currencySign: 'accounting',
						},
						textAlign: 'right',
						isSortable: true,
						isFixed: '',
						isVisible: true,
						isTotaled: true,
					},
				],
			},
		];
	}

	get lgmColumns(): TableColumn[] {
		const baseColumns = [
			{
				id: 'a58dbe0e-2618-428e-8a2f-09375af179cb',
				name: 'Sales Date',
				valuePath: 'salesEffectiveDate',
				cellComponent: CellComponents.IntlDateTimeFormat,
				componentArgs: { month: 'numeric', day: 'numeric', year: 'numeric' },
				width: 115,
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
				linkRoute: this.lgmInsuranceEndorsementRoutePath,
				linkModelPath: 'insuranceEndorsementId',
			},
			{
				id: '126e89c5-8ced-4ff3-ae33-58f0fec20b24',
				name: 'Coverage Period',
				valuePath: 'coverageMonth',
				cellComponent: CellComponents.String,
				width: 155,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '8315798c-e295-41a4-86ad-22ed0f1532e1',
				name: 'Target',
				valuePath: 'target',
				width: 80,
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'e4b5dfe4-867f-419b-86ed-5adb9ef853b5',
				name: 'RMA Type',
				valuePath: 'rmaTypeName',
				width: 125,
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '1311e143-ee47-4909-91eb-83b9dee7582c',
				name: 'Delta Quantity',
				valuePath: 'endorsement.delta',
				width: 120,
				cellComponent: CellComponents.NumericFormat,
				componentArgs: {
					maximumFractionDigits: '1',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '53fb4711-85cd-40fb-9d48-56eef03c0da7',
				name: 'Producer Premium',
				valuePath: 'producerPremiumAmount',
				width: 160,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'abaf6af9-5a80-45a8-9a76-e740e86e2bc2',
				name: 'Gross Margin Guarantee',
				valuePath: 'grossMarginGuarantee',
				width: 165,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'e78c01d1-deff-4c3d-a429-c24fcf7eb8cd',
				name: 'Est. Indemnity',
				valuePath: 'indemnity',
				width: 165,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '2307f43e-4fef-4c1a-ad69-21637db86acc',
				name: 'P/L (EOD)',
				valuePath: 'pnl',
				width: 110,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
				isTotaled: true,
			},
			{
				id: '99a1d0f2-9c2b-4f13-acd2-f94c2ea5aa4f',
				name: 'Producer',
				valuePath: 'producerName',
				cellComponent: CellComponents.String,
				width: 125,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: '2fff730d-d755-488d-a900-2727ffc6e17f',
				name: 'Total Gross Margin',
				valuePath: 'totalExpectedGrossMargin',
				width: 165,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: false,
			},
		];

		return baseColumns;
	}

	get dairyLgmEndorsements() {
		return this.model.InsuranceEndorsementAllocationRatios.filter((ratio) => {
			const slug = (ratio.RatioAdjustedInsuranceEndorsement as LgmInsuranceEndorsement).RevenueHedgeProduct.slug;

			return ['livestock-feeder-cattle', 'livestock-live-cattle', 'us-dairy-class-iii'].includes(slug);
		});
	}

	get swineLgmEndorsements() {
		return this.model.InsuranceEndorsementAllocationRatios.filter((ratio) => {
			const slug = (ratio.RatioAdjustedInsuranceEndorsement as LgmInsuranceEndorsement).RevenueHedgeProduct.slug;

			return slug === 'livestock-lean-hogs';
		});
	}

	get lgmMonths() {
		const isHogProducer = this.isHogProducer;
		const months = intervalFromDateTime(DateTime.fromISO(this.model.lgmStartDate), DateTime.fromISO(this.model.lgmEndDate), {
			month: 1,
		}).reduce<Record<string, LGMMonthRow>>((acc, dateTime) => {
			const date = dateTime.toISODate();
			acc[date] = {
				date,
				grossProduction: 0,
				effectiveCoveredLeanHogsLbs: 0,
				effectiveCoveredMilkProduction: 0,
				leanHogsPercentHedged: 0,
				classIIIPercentHedged: 0,
				producerPremiumAmount: 0,
				producerPremiumAmountCwt: null,
				indemnity: 0,
				indemnityCwt: null,
				pnl: 0,
				pnlCwt: null,
			};
			return acc;
		}, {});

		// Add policy data to months
		(isHogProducer ? this.swineLgmEndorsements : this.dairyLgmEndorsements).forEach((ratio) => {
			const lgmInsuranceEndorsement = ratio.RatioAdjustedInsuranceEndorsement as LgmInsuranceEndorsement;
			const date = ratio.effectiveHedgeDate;
			const effectiveCoveredLeanHogsLbs = isHogProducer
				? lgmInsuranceEndorsement.totalTarget * this.model.Customer.averageFinishWeightInLbs
				: 0;
			// Convert totalTarget from cwt to lbs for effectiveCoveredMilkProduction
			const effectiveCoveredMilkProduction = !isHogProducer ? lgmInsuranceEndorsement.totalTarget * 100 : 0;
			const producerPremiumAmount = lgmInsuranceEndorsement.producerPremiumAmount;
			const indemnity = lgmInsuranceEndorsement.indemnity ?? 0;
			const pnl = lgmInsuranceEndorsement.pnl ?? 0;

			const month = months[date];
			if (month) {
				month.date = date;
				month.effectiveCoveredLeanHogsLbs += effectiveCoveredLeanHogsLbs;
				month.effectiveCoveredMilkProduction += effectiveCoveredMilkProduction;
				month.producerPremiumAmount += producerPremiumAmount;
				month.indemnity += indemnity;
				month.pnl += pnl;
			}
		});

		// Add production data to months
		if (isHogProducer) {
			this.model.SwinePurchasesAndProduced.forEach((swinePopulation) => {
				const dob = swinePopulation.dob;
				if (!dob) return;

				const expectedMarketingDate = DateTime.fromISO(dob).plus({
					weeks: this.model.Customer.averageFinishAgeInWeeks,
				});

				const numSwine = swinePopulation.sum.quantity ?? 0;
				const month = months[expectedMarketingDate.toISODate()];
				if (month) {
					month.grossProduction += numSwine;
				}
			});
		} else {
			this.model.AggregateForecastedMilkProductionByMonths.forEach((milkProduction) => {
				const date = milkProduction.date ?? '';
				const grossProduction = milkProduction.sum.grossProduction ?? 0;
				const month = months[date];
				if (month) {
					month.grossProduction += grossProduction;
				}
			});
		}

		// Calculate percentages
		Object.values(months).forEach((month) => {
			const grossProduction = month.grossProduction;
			const grossProductionLbs = this.isHogProducer ? grossProduction * this.model.Customer.averageFinishWeightInLbs : grossProduction;

			if (!grossProduction) return;

			month.leanHogsPercentHedged = month.effectiveCoveredLeanHogsLbs / grossProductionLbs;
			month.classIIIPercentHedged = month.effectiveCoveredMilkProduction / grossProductionLbs;

			const effectiveCoveredProductionCwt = this.isHogProducer
				? month.effectiveCoveredLeanHogsLbs / 100
				: month.effectiveCoveredMilkProduction / 100;

			month.pnlCwt = effectiveCoveredProductionCwt ? month.pnl / effectiveCoveredProductionCwt : null;
			month.producerPremiumAmountCwt = effectiveCoveredProductionCwt ? month.producerPremiumAmount / effectiveCoveredProductionCwt : null;
			month.indemnityCwt = effectiveCoveredProductionCwt ? month.indemnity / effectiveCoveredProductionCwt : null;
		});

		return Object.values(months).sortBy('date');
	}

	get lgmMonthTotals(): [Omit<LGMMonthRow, 'date'>] {
		let producerPremiumAmountCwtCount = 0;
		let indemnityCwtCount = 0;
		let pnlCwtCount = 0;
		let leanHogsPercentHedgedCount = 0;
		let classIIIPercentHedgedCount = 0;

		let producerPremiumAmountCwtTotal: number | null = null;
		let indemityAmountCwtTotal: number | null = null;
		let pnlCwtTotal: number | null = null;
		let leanHogsPercentHedgedTotal = 0;
		let classIIIPercentHedgedTotal = 0;

		const totals = this.lgmMonths.reduce<Omit<LGMMonthRow, 'date'>>(
			(acc, month) => {
				if (month.producerPremiumAmountCwt) {
					producerPremiumAmountCwtTotal = (producerPremiumAmountCwtTotal || 0) + month.producerPremiumAmountCwt;
					producerPremiumAmountCwtCount++;
				}

				if (month.indemnityCwt) {
					indemityAmountCwtTotal = (indemityAmountCwtTotal || 0) + month.indemnityCwt;
					indemnityCwtCount++;
				}

				if (month.pnlCwt) {
					pnlCwtTotal = (pnlCwtTotal || 0) + month.pnlCwt;
					pnlCwtCount++;
				}

				if (month.leanHogsPercentHedged) {
					leanHogsPercentHedgedTotal += month.leanHogsPercentHedged;
					leanHogsPercentHedgedCount++;
				}

				if (month.classIIIPercentHedged) {
					classIIIPercentHedgedTotal += month.classIIIPercentHedged;
					classIIIPercentHedgedCount++;
				}

				acc.grossProduction += month.grossProduction;
				acc.effectiveCoveredLeanHogsLbs += month.effectiveCoveredLeanHogsLbs;
				acc.effectiveCoveredMilkProduction += month.effectiveCoveredMilkProduction;
				acc.producerPremiumAmount += month.producerPremiumAmount;
				acc.indemnity += month.indemnity;
				acc.pnl += month.pnl;

				return acc;
			},
			{
				grossProduction: 0,
				effectiveCoveredLeanHogsLbs: 0,
				effectiveCoveredMilkProduction: 0,
				producerPremiumAmount: 0,
				producerPremiumAmountCwt: null,
				indemnity: 0,
				indemnityCwt: null,
				pnl: 0,
				pnlCwt: null,
				leanHogsPercentHedged: 0,
				classIIIPercentHedged: 0,
			},
		);

		const producerPremiumAmountCwtAvg =
			producerPremiumAmountCwtTotal && producerPremiumAmountCwtCount ? producerPremiumAmountCwtTotal / producerPremiumAmountCwtCount : null;
		const indemityAmountCwtAvg = indemityAmountCwtTotal && indemnityCwtCount ? indemityAmountCwtTotal / indemnityCwtCount : null;
		const pnlCwtAvg = pnlCwtTotal && pnlCwtCount ? pnlCwtTotal / pnlCwtCount : null;
		const leanHogsPercentHedgedAvg =
			leanHogsPercentHedgedTotal && leanHogsPercentHedgedCount ? leanHogsPercentHedgedTotal / leanHogsPercentHedgedCount : 0;
		const classIIIPercentHedgedAvg =
			classIIIPercentHedgedTotal && classIIIPercentHedgedCount ? classIIIPercentHedgedTotal / classIIIPercentHedgedCount : 0;

		totals.producerPremiumAmountCwt = producerPremiumAmountCwtAvg;
		totals.indemnityCwt = indemityAmountCwtAvg;
		totals.pnlCwt = pnlCwtAvg;
		totals.leanHogsPercentHedged = leanHogsPercentHedgedAvg;
		totals.classIIIPercentHedged = classIIIPercentHedgedAvg;

		return [totals];
	}

	get lgmEndorsementRows() {
		const data = (this.isHogProducer
			? this.swineLgmEndorsements
			: this.dairyLgmEndorsements) as unknown as LgmInsuranceEndorsementByCoverageMonth[];
		if (!data) {
			return null;
		}
		const endorsements: any[] = [];

		data.map((endorsement) => {
			const { RatioAdjustedInsuranceEndorsement, perMonthData, ...endorsementObj } = endorsement;
			//breaking endorsement up into separate row items based on coverage month and `perMonthData`. This is because the users expects to see the endorsement broken up by coverage month and there can be multiple months on a single LGM endorsement.

			const endorsementModel = new LgmEndorsementModel(
				getOwner(this),
				RatioAdjustedInsuranceEndorsement as LgmInsuranceEndorsement,
				undefined,
			);
			const coverageMonthCount = endorsement.RatioAdjustedInsuranceEndorsement?.coverageMonths?.length;

			const firstCoverageMonth =
				RatioAdjustedInsuranceEndorsement &&
				Array.isArray(RatioAdjustedInsuranceEndorsement?.coverageMonths) &&
				RatioAdjustedInsuranceEndorsement.coverageMonths?.length > 0
					? RatioAdjustedInsuranceEndorsement.coverageMonths[0]
					: null;

			const lastCoverageMonth =
				RatioAdjustedInsuranceEndorsement &&
				Array.isArray(RatioAdjustedInsuranceEndorsement?.coverageMonths) &&
				RatioAdjustedInsuranceEndorsement.coverageMonths?.length > 1
					? RatioAdjustedInsuranceEndorsement.coverageMonths[RatioAdjustedInsuranceEndorsement.coverageMonths.length - 1]
					: null;

			const targetSum = RatioAdjustedInsuranceEndorsement?.perMonthData?.reduce((acc, { target }) => acc + (target ?? 0), 0);

			const cornSum = RatioAdjustedInsuranceEndorsement?.perMonthData?.reduce((acc, { corn }) => acc + (corn ?? 0), 0);

			const sbmSum = RatioAdjustedInsuranceEndorsement?.perMonthData?.reduce((acc, { sbm }) => acc + (sbm ?? 0), 0);

			const expectedFeedCostSum = RatioAdjustedInsuranceEndorsement?.perMonthData?.reduce(
				(acc, { expectedFeedCost }) => acc + (expectedFeedCost ?? 0),
				0,
			);

			const expectedGrossMarginSum = RatioAdjustedInsuranceEndorsement?.perMonthData?.reduce(
				(acc, { expectedGrossMargin }) => acc + (expectedGrossMargin ?? 0),
				0,
			);

			const pnl = endorsement?.RatioAdjustedInsuranceEndorsement?.pnl;

			const newEndorsementObj = {
				...endorsementObj,
				isCollapsed: true,
				target: targetSum,
				corn: cornSum,
				sbm: sbmSum,
				expectedFeedCost: expectedFeedCostSum,
				grossMarginGuarantee: RatioAdjustedInsuranceEndorsement?.grossMarginGuarantee,
				indemnity: RatioAdjustedInsuranceEndorsement?.indemnity,
				expectedGrossMargin: expectedGrossMarginSum,
				rmaTypeName: RatioAdjustedInsuranceEndorsement?.RmaType?.typeName,
				producerPremiumAmount: RatioAdjustedInsuranceEndorsement?.producerPremiumAmount,
				totalExpectedGrossMargin: RatioAdjustedInsuranceEndorsement?.totalExpectedGrossMargin,
				pnl,
				producerName: RatioAdjustedInsuranceEndorsement?.InsurancePolicy.producerName,
				salesEffectiveDate: RatioAdjustedInsuranceEndorsement?.salesEffectiveDate,
				coverageMonth: `${
					firstCoverageMonth
						? intlDateTimeFormat({
								value: firstCoverageMonth,
								month: 'short',
								year: 'numeric',
						  })
						: '-'
				} thru ${
					lastCoverageMonth
						? intlDateTimeFormat({
								value: lastCoverageMonth,
								month: 'short',
								year: 'numeric',
						  })
						: '-'
				}`,
				endorsement: endorsementModel,
			};
			const childrenArray: PerMonthData[] = [];

			if (coverageMonthCount) {
				for (let i = 0; i < coverageMonthCount; i++) {
					const child: PerMonthData | null | undefined = {};
					const { coverageMonths, perMonthData } = RatioAdjustedInsuranceEndorsement
						? RatioAdjustedInsuranceEndorsement
						: { coverageMonths: [], perMonthData: [] };

					child.coverageMonth = coverageMonths?.length
						? intlDateTimeFormat({
								value: coverageMonths[i],
								month: 'short',
								year: 'numeric',
						  })
						: null;
					child.monthIndex = perMonthData?.length ? perMonthData[i].monthIndex : null;
					child.target = perMonthData?.length ? perMonthData[i].target : null;
					child.corn = perMonthData?.length ? perMonthData[i].corn : null;
					child.expectedFeedCost = perMonthData?.length ? perMonthData[i].expectedFeedCost : null;
					child.expectedGrossMargin = perMonthData?.length ? perMonthData[i].expectedGrossMargin : null;
					child.sbm = perMonthData?.length ? perMonthData[i].sbm : null;

					childrenArray.push(child);
				}
			}

			newEndorsementObj.children = childrenArray;
			endorsements.push(newEndorsementObj);
		});
		return endorsements;
	}

	get quarters() {
		const endorsementData = this.model.AggregateDerivedDrpEndorsements;
		const productionData = this.model.AggregateForecastedMilkProductionByMonths;

		const quarters: Record<string, InsuranceQuarter> = {};

		//
		//  Add quarters to object.
		//

		const firstQuarterDate = endorsementData[0]?.quarterStartDate
			? endorsementData[0].quarterStartDate
			: DateTime.local().startOf('quarter').toISODate();

		let lastQuarterDate =
			endorsementData.length > 0
				? endorsementData[endorsementData.length - 1].quarterStartDate
				: DateTime.local().startOf('quarter').plus({ quarters: 6 }).toISODate();

		const dateInterval = Interval.fromISO(firstQuarterDate + '/' + lastQuarterDate);

		if (dateInterval.length('quarters') <= 4) {
			lastQuarterDate = DateTime.fromISO(firstQuarterDate).plus({ quarters: 4 }).toISODate();
		}

		let currentQuarterDate = firstQuarterDate;
		if (currentQuarterDate && lastQuarterDate) {
			while (currentQuarterDate <= lastQuarterDate) {
				quarters[currentQuarterDate] = new InsuranceQuarter(currentQuarterDate);
				currentQuarterDate = DateTime.fromISO(currentQuarterDate).plus({ quarter: 1 }).toISODate();
			}
		} else {
			throw new Error('Missing quarterStartDate from Endorsement data.');
		}

		//
		//  Add endorsement data to quarter
		//

		endorsementData.forEach((data: AggregateDerivedDrpEndorsementDTO) => {
			if (data.quarterStartDate) {
				quarters[data.quarterStartDate].addEndorsementData(data);
			} else {
				throw new Error('Missing quarter start date for AggregateDerivedDrpEndorsementDto');
			}
		});

		//
		//  Add production data to quarter
		//

		productionData.forEach((data) => {
			if (data.date) {
				const startOfQuarter = DateTime.fromISO(data.date).startOf('quarter').toISODate();
				quarters[startOfQuarter]?.addProductionData(data);
			} else {
				throw new Error('Missing date from AggregateForecastedMilkProductionByMonthDTO');
			}
		});

		return Object.values(quarters);
	}

	get quartersColTotals() {
		// percentInsured = sum of effectiveCoveredMilkProduction / sum of grossProduction if grossProduction
		type Quarter = {
			effectiveCoveredMilkProduction: number;
			pnl: number;
			pnlCwt?: number | null;
			grossButterfat: number;
			grossOtherSolids: number;
			grossProduction: number;
			grossProtein: number;
			indemnity: number;
			indemnityCwt?: number | null;
			producerPremiumAmount: number;
			producerPremiumAmountCwt?: number | null;
			percentInsured?: number;
			classIiiEffectiveCoveredMilkProduction: number;
			classIiiEffectiveUtilization: number;
			classIvEffectiveCoveredMilkProduction: number;
			classIvEffectiveUtilization: number;
			classIiiPercentInsured?: number;
			classIvPercentInsured?: number;
		};
		const columnSums = this.quarters.reduce(
			(acc, quarter) => {
				acc.effectiveCoveredMilkProduction += quarter.effectiveCoveredMilkProduction;
				acc.pnl += quarter.pnl;
				acc.grossButterfat += quarter.grossButterfat;
				acc.grossOtherSolids += quarter.grossOtherSolids;
				acc.grossProduction += quarter.grossProduction;
				acc.grossProtein += quarter.grossProtein;
				acc.indemnity += quarter.indemnity;
				acc.producerPremiumAmount += quarter.producerPremiumAmount;
				acc.classIiiEffectiveCoveredMilkProduction += quarter.classIiiEffectiveCoveredMilkProduction;
				acc.classIiiEffectiveUtilization += quarter.classIiiEffectiveUtilization;
				acc.classIvEffectiveCoveredMilkProduction += quarter.classIvEffectiveCoveredMilkProduction;
				acc.classIvEffectiveUtilization += quarter.classIvEffectiveUtilization;
				return acc;
			},
			{
				effectiveCoveredMilkProduction: 0,
				pnl: 0,
				grossButterfat: 0,
				grossOtherSolids: 0,
				grossProduction: 0,
				grossProtein: 0,
				indemnity: 0,
				producerPremiumAmount: 0,
				classIiiEffectiveCoveredMilkProduction: 0,
				classIiiEffectiveUtilization: 0,
				classIvEffectiveCoveredMilkProduction: 0,
				classIvEffectiveUtilization: 0,
			} as Quarter,
		);
		if (columnSums.grossProduction) {
			columnSums.percentInsured = columnSums.effectiveCoveredMilkProduction / columnSums.grossProduction;
		}

		if (columnSums.classIiiEffectiveUtilization) {
			columnSums.classIiiPercentInsured = columnSums.classIiiEffectiveCoveredMilkProduction / columnSums.classIiiEffectiveUtilization;
		}

		if (columnSums.classIvEffectiveUtilization) {
			columnSums.classIvPercentInsured = columnSums.classIvEffectiveCoveredMilkProduction / columnSums.classIvEffectiveUtilization;
		}

		columnSums.pnlCwt = columnSums.effectiveCoveredMilkProduction
			? (columnSums.pnl ?? 0) / (columnSums.effectiveCoveredMilkProduction / 100)
			: null;

		columnSums.indemnityCwt = columnSums.effectiveCoveredMilkProduction
			? (columnSums.indemnity ?? 0) / (columnSums.effectiveCoveredMilkProduction / 100)
			: null;

		columnSums.producerPremiumAmountCwt = columnSums.effectiveCoveredMilkProduction
			? (columnSums.producerPremiumAmount ?? 0) / (columnSums.effectiveCoveredMilkProduction / 100)
			: null;

		return [columnSums];
	}

	itemsFn = (rows: any) => {
		return rows
			? rows.map((row: any) => {
					return new DerivedInsuranceEndorsement(row);
			  })
			: null;
	};

	get drpEndorsementColumnTotals() {
		const totals: {
			[key: string]: any;
		} = {};
		Object.assign(totals, this.model.AggregateDerivedDrpEndorsements[0].sum);
		totals['effectiveExpectedRevenueGuaranteeCwt'] =
			(totals.effectiveExpectedRevenueGuarantee / totals.effectiveCoveredMilkProduction) * 100;
		totals['producerPremiumAmountCwt'] = (totals.producerPremiumAmount / totals.effectiveCoveredMilkProduction) * 100;
		totals['totalPremiumAmountCwt'] = (totals.totalPremiumAmount / totals.effectiveCoveredMilkProduction) * 100;
		totals['indemnityCwt'] = (totals.indemnity / totals.effectiveCoveredMilkProduction) * 100;
		totals['pnlCwt'] = (totals.pnl / totals.effectiveCoveredMilkProduction) * 100;
		return [totals];
	}

	get drpEndorsementData() {
		return this.itemsFn(this.model.DerivedDrpEndorsements);
	}

	get lgmEndorsementData() {
		return this.model.InsuranceEndorsementAllocationRatios;
	}

	get isDairyProducer() {
		const businessRoles = this.model.Customer?.businessRoles ?? [];
		const isDairyProducer = businessRoles.some((role) => role === BusinessEntityRole.DairyProducer);
		return isDairyProducer;
	}

	get isHogProducer() {
		const businessRoles = this.model.Customer?.businessRoles ?? [];
		const isHogProducer = businessRoles.some((role) => role === BusinessEntityRole.HogProducer);
		return isHogProducer;
	}

	get showDRPOverview() {
		return this.isDairyProducer;
	}

	get showDRPEndorsements() {
		const hasDrpInsurance = this.drpEndorsementData.length > 0;
		return this.isDairyProducer && hasDrpInsurance;
	}

	get showLGMOverview() {
		return this.isHogProducer || this.isDairyProducer;
	}

	get showLGMEndorsements() {
		const hasLgmInsurance = this.lgmEndorsementData.length > 0;

		return (this.isHogProducer || this.isDairyProducer) && hasLgmInsurance;
	}

	get showLGMFirst() {
		// Used to trigger flex reverse order if a dairy producer has no DRP Endorsements
		return this.showDRPOverview && !this.showDRPEndorsements;
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'businesses/business/insurance-overview': BusinessesBusinessInsuranceOverview;
	}
}
