import TransactionsIndexController from 'vault-client/controllers/transactions';

export default class OrganizationsOrganizationTransactions extends TransactionsIndexController {
	transactionShowRoute: string = 'organizations.organization.transaction';
	accountShowRoute: string = 'organizations.organization.account';
	brokerageRoutePath: string = 'organizations.organization.brokerage';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'organizations/organization/transactions': OrganizationsOrganizationTransactions;
	}
}
