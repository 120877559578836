import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{this.formattedValue}}", {"contents":"{{this.formattedValue}}","moduleName":"vault-client/components/historical-price-range-format.hbs","parseOptions":{"srcName":"vault-client/components/historical-price-range-format.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { percentRank } from 'vault-client/utils/percentile';

interface HistoricalPriceRangeFormatArgs {
	rowData: any;
	valuePath: string;
}

export default class HistoricalPriceRangeFormat extends Component<HistoricalPriceRangeFormatArgs> {
	@service marketData: any;

	registeredSymbols: string[] = [];

	registerSymbol(symbol: string) {
		if (!this.registeredSymbols.includes(symbol)) {
			this.marketData.register(symbol);
			this.registeredSymbols.push(symbol);
		}
	}

	getByCompositeKey(obj: any, key: string) {
		if (!key) {
			return null;
		}

		return key.split('.').reduce((prev, itm) => (prev ? prev[itm] : null), obj) || null;
	}

	get numberOfYears() {
		return 5;
	}

	get formattedValue() {
		const symbol = this.getByCompositeKey(this.args.rowData, `${this.args.valuePath}.barchartSymbol`);
		this.registerSymbol(symbol);
		this.marketData.registerHistoricalRange(symbol);

		const marketDatum = this.marketData.getMarketDatum(symbol);

		const historicalData = this.marketData.getMarketDataHistoricalRange(symbol);

		if (historicalData?.get('settlements') && marketDatum?.lastPrice) {
			const settlementsDatums = historicalData.get('settlements').slice(0, this.numberOfYears);

			const settlements = settlementsDatums.map((settlement: any) => {
				return settlement.close ?? settlement.lastPrice;
			});

			const rank = percentRank(settlements, marketDatum.lastPrice);

			if (rank) {
				return new Intl.NumberFormat(undefined, {
					style: 'percent',
				}).format(rank);
			} else {
				return '-';
			}
		} else {
			return '-';
		}
	}

	willDestroy() {
		super.willDestroy();
		this.registeredSymbols.forEach((symbol) => {
			this.marketData.unregister(symbol);
		});
	}
}
