import { set } from '@ember/object';
import Instrument from './instrument';
import Future from './future';
import { tracked } from '@glimmer/tracking';
import { Option as OptionInterface, TypeOfOption } from 'vault-client/types/graphql-types';
import { DateTime } from 'luxon';

export default class Option extends Instrument {
	@tracked optionType: TypeOfOption | undefined;
	@tracked strike: number;
	@tracked UnderlyingInstrument: Future | undefined;
	@tracked expiresAt: string;
	@tracked displayExpiresAt: string;
	@tracked barchartSymbol: string | null | undefined;
	@tracked cqgSymbol: string | null | undefined;
	@tracked exchangeSymbol: string | null | undefined;
	@tracked lastTradeAt: DateTime | null = null;

	constructor(instrument: OptionInterface) {
		super(instrument);

		set(this, 'optionType', instrument.optionType);
		this.strike = instrument.strike;
		this.expiresAt = instrument.expiresAt;
		this.displayExpiresAt = instrument.displayExpiresAt;
		this.barchartSymbol = instrument.barchartSymbol;
		this.cqgSymbol = instrument.cqgSymbol;
		this.exchangeSymbol = instrument.exchangeSymbol;
		this.lastTradeAt = instrument.lastTradeAt;

		if (instrument.UnderlyingInstrument) {
			this.UnderlyingInstrument = new Future(instrument.UnderlyingInstrument);
		}
	}

	get finalPrice() {
		return 0;
	}

	get instrumentType() {
		return this.optionType;
	}

	get isCall() {
		return this.optionType == 'Call';
	}

	get isPut() {
		return this.optionType == 'Put';
	}

	get hasExpired() {
		// This should be > 0, but we don't have exact times for expirations.
		return DateTime.fromISO(this.expiresAt).diffNow().valueOf() < -86400000;
	}
}
