import LgmInsuranceEndorsementsIndexController from 'vault-client/controllers/lgm-insurance-endorsements/index';

export default class BusinessesBusinessLgmInsuranceEndorsements extends LgmInsuranceEndorsementsIndexController {
	lgmInsuranceEndorsementRoute: string = 'businesses.business.lgm-insurance-endorsement';
	insuranceRoutePath: string = 'businesses.business.insurance';
	insurancePolicyRoutePath: string = 'businesses.business.insurance-policy';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	interface Registry {
		'businesses/business/lgm-insurance-endorsements': BusinessesBusinessLgmInsuranceEndorsements;
	}
}
