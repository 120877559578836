import { tracked } from '@glimmer/tracking';
import Future from './future';
import TransactionClass from './transaction';
//@ts-ignore
import Big from 'big.js';
import { Account, Instrument, Transaction } from 'vault-client/types/graphql-types';
import { next } from '@ember/runloop';

export default class FutureTransaction extends TransactionClass {
	@tracked Instrument: Future;

	constructor(owner: any, transaction: Transaction, instrument: Instrument | null, account: Account | null, useEODPrice = false) {
		super(owner, transaction, account, useEODPrice);

		const _instrument = instrument ?? transaction.Instrument;
		this.Instrument = new Future(_instrument as any);

		// DGC: For some reason, I had to push this to the next runloop to prevent
		// a Glimmer error.
		const barchartSymbol = this.Instrument.barchartSymbol;
		const obj = { barchartSymbol, marketData: this.marketData };
		next(obj, function () {
			if (obj.barchartSymbol) obj.marketData.register(obj.barchartSymbol);
		});
	}

	get plPerContract() {
		const marketPrice = this.Instrument.barchartSymbol ? this.getMarketPrice(this.Instrument.barchartSymbol) : null;
		let pointValue = this.Instrument.Product?.StandardProductLotSpecification?.pointValue || 1;
		if (typeof pointValue === 'string') {
			pointValue = pointValue.replace(/[^0-9.]/g, '');
		}

		if (marketPrice) {
			const v = new Big(marketPrice).minus(this.price).times(pointValue);
			return this.quantity > 0 ? v : v.times(-1);
		} else {
			return new Big(0);
		}
	}

	get grossPl() {
		return parseFloat(this.plPerContract?.times(new Big(this.quantityInContracts).abs()).toString());
	}

	get netPl() {
		return this.grossPl + this.commission + this.nonCommissionFees;
	}
}
