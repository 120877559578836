import Controller from '@ember/controller';
import VgsGrainServicesTargetsShowRoute from 'vault-client/routes/vgs/grain-services/targets/show';
import { ModelFrom } from 'vault-client/utils/type-utils';
import Target from 'vault-client/models/vgs/target';
import { inject as service } from '@ember/service';
import { informationWasUpdated } from 'vault-client/utils/vgs-utils';
export default class VgsGrainServicesTargetsShow extends Controller {
	@service permissions: any;
	declare model: ModelFrom<VgsGrainServicesTargetsShowRoute>;

	get targetModel() {
		const targetData = this.model.target.data?.GrainTargetOrder;
		return targetData ? new Target(targetData, null, null) : null;
	}

	get filteredActivities() {
		return this.targetModel?.orderActivities?.filter((activity) => informationWasUpdated(activity)) ?? [];
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	interface Registry {
		'vgs/grain-services/targets/show': VgsGrainServicesTargetsShow;
	}
}
