import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{this.formattedValue}}", {"contents":"{{this.formattedValue}}","moduleName":"vault-client/components/price-format.hbs","parseOptions":{"srcName":"vault-client/components/price-format.hbs"}});
import Component from '@glimmer/component';

interface PriceFormatArgs {
	displayFactorPath: string;
	fractionDigitsPath: string;
	rowData: any;
	valuePath?: string;
	value: number | null;
}

export default class PriceFormat extends Component<PriceFormatArgs> {
	get formattedValue() {
		const displayFactor = this.getByCompositeKey(this.args.rowData, this.args.displayFactorPath);
		const fractionDigits = this.getByCompositeKey(this.args.rowData, this.args.fractionDigitsPath);

		const value = this.args.valuePath ? this.getByCompositeKey(this.args.rowData, this.args.valuePath) : this.args.value;

		if (value) {
			const price = displayFactor ? displayFactor * value : value;

			return new Intl.NumberFormat(undefined, {
				minimumFractionDigits: fractionDigits,
				maximumFractionDigits: fractionDigits,
			}).format(price);
		} else {
			return '-';
		}
	}

	getByCompositeKey(obj: any, key: string) {
		// turn this into utility function?
		if (!key) {
			return null;
		}

		return key.split('.').reduce((prev: any, itm: string) => (prev ? prev[itm] : null), obj) || null;
	}
}
