import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<span {{did-insert this.fetchItems}}>\n\t<PowerSelect\n\t\t@selected={{this.selectedItem}}\n\t\t@options={{this.items}}\n\t\t@searchEnabled={{true}}\n\t\t@allowClear={{false}}\n\t\t@searchField='name'\n\t\t@onChange={{this.setItem}}\n\t\t@renderInPlace={{@renderInPlace}}\n\t\t@required='true'\n\t\tas |item|\n\t>\n\t\t{{item.name}}\n\t</PowerSelect>\n</span>", {"contents":"<span {{did-insert this.fetchItems}}>\n\t<PowerSelect\n\t\t@selected={{this.selectedItem}}\n\t\t@options={{this.items}}\n\t\t@searchEnabled={{true}}\n\t\t@allowClear={{false}}\n\t\t@searchField='name'\n\t\t@onChange={{this.setItem}}\n\t\t@renderInPlace={{@renderInPlace}}\n\t\t@required='true'\n\t\tas |item|\n\t>\n\t\t{{item.name}}\n\t</PowerSelect>\n</span>","moduleName":"vault-client/components/swap-dealer-select.hbs","parseOptions":{"srcName":"vault-client/components/swap-dealer-select.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { queryManager } from 'ember-apollo-client';
import query from 'vault-client/graphql/queries/swap-dealer-select.graphql';

interface Args {
	selected: any;
	onChange: any;
}

export default class SwapDealerSelectComponent extends Component<Args> {
	@queryManager apollo: any;

	@tracked items = [];
	@tracked _selectedItem: any;

	get selectedItem() {
		if (this._selectedItem == null) {
			return this.items.find(({ name }) => name == this.args.selected);
		} else {
			return this._selectedItem;
		}
	}

	set selectedItem(value) {
		this._selectedItem = value;
	}

	@action
	async fetchItems() {
		const items = (await this.apollo.watchQuery({ query })).SwapDealers;
		this.items = items.sortBy('name');
	}

	@action
	async setItem(item: any) {
		this.selectedItem = item;
		this.args.onChange(item);
	}
}
