import DRPInsuranceEndorsementsIndexController from 'vault-client/controllers/drp-insurance-endorsements';

export default class OrganizationsOrganizationDrpInsuranceEndorsements extends DRPInsuranceEndorsementsIndexController {
	drpInsuranceEndorsementRoute: string = 'organizations.organization.drp-insurance-endorsement';
	insuranceRoutePath: string = 'organizations.organization.insurance';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'organizations/organization/drp-insurance-endorsements': OrganizationsOrganizationDrpInsuranceEndorsements;
	}
}
