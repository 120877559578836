import Controller from '@ember/controller';
import StrategicInventoryModel from '../../models/strategic-inventory-model';
import { getOwner } from '@ember/application';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { Product } from 'vault-client/types/graphql-types';
import isTouchDevice from 'vault-client/utils/is-touch-device';
import { TableColumn, CellComponents } from 'vault-client/types/vault-table';
import ReportsCheeseBlockStrategicInventoryModelRoutes from 'vault-client/routes/reports/cheese-block-strategic-inventory-model';
import { ModelFrom } from 'vault-client/utils/type-utils';

interface HtmlElement {
	target: HTMLInputElement;
}

export default class ReportsCheeseBlockStrategicInventoryModel extends Controller {
	@tracked carryCost: number = 0.01;
	@tracked currentPriceInput: string = 'Front Future Price';
	@tracked numberOfYears: number = 5;
	reportsRoute: string = '';
	declare model: ModelFrom<ReportsCheeseBlockStrategicInventoryModelRoutes>;
	get columns(): TableColumn[] {
		const baseColumns: TableColumn[] = [
			{
				id: 'a461b359-9722-42c1-b793-40d60d474bb9',
				name: 'Month',
				valuePath: 'displayExpiresAt',
				minWidth: 90,
				cellComponent: CellComponents.MonthFormat,
				textAlign: 'left',
				isSortable: false,
				isFixed: !isTouchDevice() ? 'left' : '',
				isVisible: true,
			},
			{
				id: '877c71ee-830a-4494-b5ab-5ca06ec8f1c0',
				name: 'Futures',
				valuePath: 'currentFuturePrice',
				minWidth: 110,
				cellComponent: CellComponents.PriceFormat,
				componentArgs: {
					fractionDigitsPath: 'product.MostCurrentFuture.SymbolGroup.fractionDigits',
					displayFactorPath: 'noDisplayFactor',
				},
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '86c6339c-75d3-4a47-afc1-e4fff2d2215e',
				name: 'Futures - Carry',
				valuePath: 'futuresMinusCarryCost',
				minWidth: 150,
				cellComponent: CellComponents.PriceFormat,
				componentArgs: {
					fractionDigitsPath: 'product.MostCurrentFuture.SymbolGroup.fractionDigits',
					displayFactorPath: 'noDisplayFactor',
				},
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '223604be-e7eb-4ca4-bd5b-2ae9f063767a',
				name: 'Current',
				valuePath: 'currentPrice',
				minWidth: 120,
				cellComponent: CellComponents.PriceFormat,
				componentArgs: {
					fractionDigitsPath: 'product.MostCurrentFuture.SymbolGroup.fractionDigits',
					displayFactorPath: 'noDisplayFactor',
				},
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'b79e7254-8ce1-4d0b-9667-d62fac4cc083',
				name: '1 Mo Carry Incentive',
				valuePath: 'oneMonthCarryIncentive',
				minWidth: 200,
				cellComponent: CellComponents.PriceFormat,
				componentArgs: {
					fractionDigitsPath: 'product.MostCurrentFuture.SymbolGroup.fractionDigits',
					displayFactorPath: 'noDisplayFactor',
				},
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '5410443c-b1c5-4458-87b2-0c5cb95cc08c',
				name: 'Strategy',
				valuePath: 'riskManagementStrategy',
				minWidth: 120,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
		];
		return baseColumns;
	}

	prevSims: {
		oneMonthCarryIncentive: number | null;
		displayExpiresAt: string;
		symbol: string;
	}[] = [];

	get spotProduct() {
		const products: Product[] = this.model.Products;
		const cashProduct = products?.find((product: Product) => product?.slug === 'us-dairy-cheese-block-spot');
		return cashProduct ?? null;
	}

	get futuresProduct() {
		const products: Product[] = this.model.Products;
		return products?.find((product) => product.slug === 'us-dairy-cheese-block');
	}

	get frontFuture() {
		return this.futuresProduct?.CurrentFutures[0];
	}

	get months() {
		const owner = getOwner(this);
		const product = this.futuresProduct;
		return product?.CurrentFutures?.reduce((acc: any, future: any) => {
			const previousSims = acc.slice(-2);
			const currentSim = new StrategicInventoryModel(
				owner,
				product,
				future,
				this.carryCost,
				previousSims,
				this.spotProduct,
				this.currentPriceInput,
				this.numberOfYears
			);

			acc.push(currentSim);
			return acc;
		}, []);
	}

	@action
	currentPriceRadioInput(e: HtmlElement) {
		return (this.currentPriceInput = e.target.value);
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'reports/cheese-block-strategic-inventory-model': ReportsCheeseBlockStrategicInventoryModel;
	}
}
