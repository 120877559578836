/* eslint-disable ember/no-controller-access-in-routes */
import Route from '@ember/routing/route';
import { gql, useQuery } from 'glimmer-apollo';
import { Account, CurrentPosition, Query_AccountArgs, Transaction } from 'vault-client/types/graphql-types';
import { tracked } from 'tracked-built-ins';

const GET_ACCOUNT = gql`
	query Account($id: String!, $startDate: DateTime!) {
		Account(id: $id) {
			id
			name
			accountNumber
			type
			updatedAt
			hasWriteAccess
			Customer {
				id
				name
			}
			... on BrokerageAccount {
				marginExcessDeficit
				liquidatingValue
				currentBalance
				purchasingPower
				parentAccountId
				FCM {
					id
					name
					portalUrl
				}
			}
			... on SwapAccount {
				SwapDealer {
					id
					name
				}
			}
		}
		ChildAccounts: Accounts(where: { parentAccountId: { equals: $id } }) {
			id
			name
			accountNumber
			type
		}
		Transactions(where: { Account: { id: { equals: $id } } }, limit: 10, orderBy: { tradeDate: Desc }) {
			id
			contractQuantity
			unitQuantity
			quantity
			price
			tradeDate
			feeTotal
			commissionTotal
			commissionAndFeeTotal
			Account {
				id
				accountNumber
				name
				... on BrokerageAccount {
					FCM {
						id
						name
					}
				}
				... on SwapAccount {
					SwapDealer {
						id
						name
					}
				}
			}
			Instrument {
				id
				name
				SymbolGroup {
					fractionDigits
					displayFactor
				}
				Product {
					id
					name
					optionsUnitValue
					pointValue
					StandardProductLotSpecification {
						pointValue
					}
					velaRootSymbol
				}
				... on Option {
					optionType
					strike
					expiresAt
					displayExpiresAt
					barchartSymbol
					exchangeSymbol
					UnderlyingInstrument {
						id
						barchartSymbol
					}
				}
				... on Future {
					expiresAt
					displayExpiresAt
					barchartSymbol
					exchangeSymbol
				}
				... on Swap {
					expiresAt
					displayExpiresAt
					PriceInstrument {
						id
						... on Future {
							barchartSymbol
						}
					}
				}
				... on Swaption {
					expiresAt
					displayExpiresAt
					strike
					optionType
					PriceInstrument {
						id
						... on Future {
							barchartSymbol
						}
					}
				}
			}
		}
		CurrentPositions(
			orderBy: { displayExpiresAt: Asc, productId: Asc }
			where: { accountId: { equals: $id }, expiresAt: { gte: $startDate }, quantity: { not: { equals: 0 } } }
		) {
			id
			displayExpiresAt
			quantity
			feeTotal
			commissionTotal
			commissionAndFeeTotal
			currentWeightedAveragePrice
			unrealizedPnl
			realizedPnl
			grossPnl
			Transactions {
				id
				contractQuantity
				unitQuantity
				price
				tradeDate
			}
			Instrument {
				id
				name
				type
				SymbolGroup {
					fractionDigits
					displayFactor
				}
				Product {
					id
					name
					optionsUnitValue
					pointValue
					StandardProductLotSpecification {
						pointValue
					}
					velaRootSymbol
				}
				... on Option {
					optionType
					strike
					expiresAt
					displayExpiresAt
					barchartSymbol
					exchangeSymbol
					UnderlyingInstrument {
						id
						barchartSymbol
					}
				}
				... on Future {
					expiresAt
					displayExpiresAt
					barchartSymbol
					exchangeSymbol
				}
				... on Swap {
					expiresAt
					displayExpiresAt
					PriceInstrument {
						id
						... on Future {
							barchartSymbol
						}
					}
				}
				... on Swaption {
					expiresAt
					displayExpiresAt
					strike
					optionType
					PriceInstrument {
						id
						... on Future {
							barchartSymbol
						}
					}
				}
			}
			CurrentPositionComponents {
				id
				quantity
				positionId
				price
				updatedAt
				unrealizedPnl
				Account {
					accountNumber
				}
			}
		}
	}
`;

type GetAccount = {
	__typename?: 'Query';

	Account: Account;
	CurrentPositions: Array<CurrentPosition>;
	Transactions: Array<Transaction>;
	ChildAccounts: Array<Account>;
};

export default class AccountsShowRoute extends Route {
	@tracked variables = { id: '', startDate: '' };
	getAccount = useQuery<GetAccount, Query_AccountArgs>(this, () => [GET_ACCOUNT, { variables: this.variables }]);

	templateName: string = 'accounts/show';
}
