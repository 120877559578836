import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { ModelFrom } from 'vault-client/utils/type-utils';
import route, { GET_TARGETS } from 'vault-client/routes/vgs/feed-services/targets/index';
import { CellComponents, TableColumn } from 'vault-client/types/vault-table';
import resetVaultTableScroll from 'vault-client/utils/reset-vault-table-scroll';
import { action } from '@ember/object';
import { gql, useQuery } from 'glimmer-apollo';
import getFilterDisplayProperty from 'vault-client/utils/get-filter-display-property';
import { UiDateFilterOption } from 'vault-client/components/vault/ui-date-filter';
import { DateTime } from 'luxon';
import { inject as service } from '@ember/service';
import PermissionsService from 'vault-client/services/permissions';
import {
	GrainTargetOrder,
	Query,
	Query_GrainAdvisorsArgs,
	Query_GrainLocationsArgs,
	Query_SearchArgs,
	Query_SellersArgs,
	TypeOfSearchItem,
	Query_GrainTargetOrdersArgs,
	TypeOfGrainOrderStatus,
	TypeOfGrainOrderSales,
} from 'vault-client/types/graphql-types';
import { SearchResult } from 'vault-client/types/vault-client';
import Target from 'vault-client/models/vgs/target';
import { getGrainOrderStatusDisplayValue, getSalesTypeDisplayValue } from 'vault-client/utils/vgs-utils';
import { generateFullName } from 'vault-client/utils/general';
const GET_SEARCH_QUERY = gql`
	query Search($query: String!, $typesToInclude: [TypeOfSearchItem!]) {
		Search(query: $query, typesToInclude: $typesToInclude) {
			id
			name
			type
			attributes
		}
	}
`;

const GET_GRAIN_ORDER_BR_OWNER = gql`
	query GrainTargetBrOwner($where: GrainTargetOrderFilterDTO) {
		GrainTargetOrders(where: $where) {
			id
			BuyerRelationsOwner {
				id
				firstName
				lastName
			}
		}
	}
`;

const GET_GRAIN_ADVISOR = gql`
	query GrainAdvisor($where: GrainAdvisorFilterDTO) {
		GrainAdvisors(where: $where) {
			id
			name
		}
	}
`;

const GET_GRAIN_LOCATION = gql`
	query GrainLocation($where: GrainLocationFilterDTO) {
		GrainLocations(where: $where) {
			id
			name
		}
	}
`;

const GET_SELLER = gql`
	query Seller($where: SellerFilterDTO) {
		Sellers(where: $where) {
			id
			name
		}
	}
`;

type GetAdvisor = {
	GrainAdvisors: Query['GrainAdvisors'];
};

type GetLocation = {
	GrainLocations: Query['GrainLocations'];
};

type GetTarget = {
	GrainTargetOrders: Query['GrainTargetOrders'];
};

type GetSeller = {
	Sellers: Query['Sellers'];
};
export default class VgsFeedServicesTargetsIndex extends Controller {
	@service declare permissions: PermissionsService;

	@tracked page = 0;
	@tracked size = 100;
	@tracked sorts = [{ valuePath: 'expirationDate', isAscending: true }];
	@tracked brOwnerId: string | null = null;
	@tracked grainAdvisorId: string | null = null;
	@tracked customerId: string | null = null;
	@tracked selectedFeedCategories: string[] = [];
	@tracked status: TypeOfGrainOrderStatus | null = null;
	@tracked futuresMonthStart: string = '1900-01-01';
	@tracked futuresMonthEnd: string = '2999-12-31';
	@tracked deliveryStartDateStart: string = '1900-01-01';
	@tracked deliveryStartDateEnd: string = '2999-12-31';
	@tracked deliveryEndDateStart: string = '1900-01-01';
	@tracked deliveryEndDateEnd: string = '2999-12-31';
	@tracked updatedAtDateStart: string = '1900-01-01';
	@tracked updatedAtDateEnd: string = '2999-12-31';
	@tracked selectedSalesTypes: TypeOfGrainOrderSales[] = [];
	@tracked sellerId: string | null = null;
	@tracked grainLocationId: string | null = null;
	@tracked targetIdToDelete: GrainTargetOrder | null = null;
	@tracked targetToClaim: GrainTargetOrder | null = null;

	declare model: ModelFrom<route>;

	queryParams = [
		'page',
		'size',
		'sorts',
		'brOwnerId',
		'grainAdvisorId',
		'customerId',
		'selectedFeedCategories',
		'status',
		'futuresMonthStart',
		'futuresMonthEnd',
		'deliveryStartDateStart',
		'deliveryStartDateEnd',
		'deliveryEndDateStart',
		'deliveryEndDateEnd',
		'updatedAtDateStart',
		'updatedAtDateEnd',
		'selectedSalesTypes',
		'sellerId',
		'grainLocationId',
	];

	deliveryDateRangeFilterOptions: UiDateFilterOption[] = [
		{
			displayName: 'All Dates',
			startDate: '1900-01-01',
			endDate: '2999-12-31',
		},
		{
			displayName: 'Current Month',
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().endOf('month').toISODate(),
		},
		{
			displayName: 'Next 3 Months',
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().plus({ months: 3 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Next 6 Months',
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().plus({ months: 6 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Next 12 Months',
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().plus({ months: 12 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Next 24 Months',
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().plus({ months: 24 }).endOf('month').toISODate(),
		},
	];

	updatedAtDateRangeFilterOptions: UiDateFilterOption[] = [
		{
			displayName: 'All Dates',
			startDate: '1900-01-01',
			endDate: '2999-12-31',
		},
		{
			displayName: 'Today',
			startDate: DateTime.local().toISODate(),
			endDate: DateTime.local().toISODate(),
		},
		{
			displayName: 'Yesterday',
			startDate: DateTime.local().minus({ day: 1 }).toISODate(),
			endDate: DateTime.local().minus({ day: 1 }).toISODate(),
		},
		{
			displayName: 'Last 7 Days',
			startDate: DateTime.local().minus({ day: 7 }).toISODate(),
			endDate: DateTime.local().toISODate(),
		},
		{
			displayName: 'Last 30 Days',
			startDate: DateTime.local().minus({ day: 30 }).toISODate(),
			endDate: DateTime.local().toISODate(),
		},
	];

	futuresMonthRangeFilterOptions: UiDateFilterOption[] = [
		{
			displayName: 'All Months',
			startDate: '1900-01-01',
			endDate: '2999-12-31',
		},
		{
			displayName: 'Current Month',
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().endOf('month').toISODate(),
		},
		{
			displayName: 'Next 3 Months',
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().plus({ months: 3 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Next 6 Months',
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().plus({ months: 6 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Next 12 Months',
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().plus({ months: 12 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Next 24 Months',
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().plus({ months: 24 }).endOf('month').toISODate(),
		},
	];

	get searchPlaceholder() {
		return 'Filter by BR Owner, Advisor, Customer, Location, or Seller.';
	}

	get searchPrompt() {
		return 'Type a search term to find targets by BR Owner, Advisor, Customer, Location, or Seller.';
	}

	get query() {
		return GET_TARGETS;
	}

	get columns() {
		const baseColumns: TableColumn[] = [
			{
				id: '9a80a08e-e64e-44bb-a42a-29576112a259',
				name: '',
				width: 100,
				textAlign: 'center',
				isSortable: false,
				cellComponent: CellComponents.Button,
				componentArgs: {
					size: 'xs',
					style: 'outline',
					text: 'Details',
					fn: () => {},
				},
				isFixed: '',
				isVisible: true,
				linkRoute: 'vgs.feed-services.targets.show',
				linkModelPath: 'id',
			},
			{
				id: '291f06ef-4135-4fa4-842e-eb9e58a30d9b',
				name: 'BR Owner',
				valuePath: 'BuyerRelationsOwner.id',
				minWidth: 100,
				width: 115,
				textAlign: 'center',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: '803497e7-be88-4cdb-bd74-b18374d8dd6a',
						name: 'First Name',
						valuePath: 'BuyerRelationsOwner.firstName',
						minWidth: 115,
						width: 100,
						textAlign: 'left',
						isSortable: true,
						cellComponent: CellComponents.String,
						isFixed: '',
						isVisible: true,
					},
					{
						id: '01f7cce0-52df-40ea-b044-31b4193a14c5',
						name: 'Last Name',
						valuePath: 'BuyerRelationsOwner.lastName',
						minWidth: 115,
						width: 100,
						textAlign: 'left',
						isSortable: true,
						cellComponent: CellComponents.String,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: '3bfd1d14-f05f-433f-bf8b-66e4f6401583',
				name: 'Advisor',
				valuePath: 'Plan.Customer.RelationshipOwner.id',
				minWidth: 115,
				width: 115,
				cellComponent: CellComponents.String,
				textAlign: 'center',
				isSortable: true,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: 'fc6c53e8-4824-442a-a1f7-f007eaf88393',
						name: 'First Name',
						// valuePath: 'Plan.AsGrainFeedPlan.Customer.RelationshipOwner.firstName',
						valuePath: 'Plan.Customer.RelationshipOwner.firstName',
						minWidth: 115,
						width: 100,
						textAlign: 'left',
						isSortable: true,
						cellComponent: CellComponents.String,
						isFixed: '',
						isVisible: true,
					},
					{
						id: 'af9584d5-6b18-4cdd-a307-2afabefb57a0',
						name: 'Last Name',
						// valuePath: 'Plan.AsGrainFeedPlan.Customer.RelationshipOwner.lastName',
						valuePath: 'Plan.Customer.RelationshipOwner.lastName',
						minWidth: 115,
						width: 100,
						textAlign: 'left',
						isSortable: true,
						cellComponent: CellComponents.String,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: '0baf95ca-b352-452b-adf6-a42d93735f33',
				name: 'Customer',
				// valuePath: 'Plan.AsGrainFeedPlan.Customer.name',
				valuePath: 'Plan.Customer.name',
				minWidth: 125,
				width: 125,
				cellComponent: CellComponents.String,
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '87c5b9a3-f289-4587-a142-4e03da08d264',
				name: 'Ingredient',
				valuePath: 'Plan.FeedCategory.name',
				minWidth: 115,
				width: 115,
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '81dc7c7b-c62e-4def-a0ea-2ec6776ba445',
				name: 'Status',
				valuePath: 'status',
				width: 140,
				cellComponent: CellComponents.CustomFormat,
				componentArgs: {
					formatter: getGrainOrderStatusDisplayValue,
				},
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'bf4b426a-1bc6-40b5-bcfa-1a26026b21f8',
				name: 'Updated At',
				valuePath: 'StatusUpdates.updatedAt',
				width: 120,
				cellComponent: CellComponents.IntlDateTimeFormat,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '996bc2f1-94d5-4899-a86c-8f1432bf5715',
				name: 'Tons',
				valuePath: 'bushels',
				minWidth: 105,
				width: 105,
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'f5901bf5-5f00-4d5c-ba6c-01c01011d490',
				name: 'Futures Month',
				valuePath: 'futuresMonth',
				minWidth: 140,
				width: 140,
				cellComponent: CellComponents.MonthFormat,
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '57ac599a-60d7-46e2-84cd-aae43ef24c9f',
				name: 'Delivery Start Date',
				valuePath: 'deliveryStartDate',
				minWidth: 165,
				width: 165,
				cellComponent: CellComponents.IntlDateTimeFormat,
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '2691d51a-6219-43f8-ad24-2640e3a596c6',
				name: 'Delivery End Date',
				valuePath: 'deliveryEndDate',
				minWidth: 160,
				width: 160,
				cellComponent: CellComponents.IntlDateTimeFormat,
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '29391897-4968-4005-b6b1-e146bf462e18',
				name: 'Order Type',
				valuePath: 'salesType',
				minWidth: 115,
				width: 115,
				cellComponent: CellComponents.CustomFormat,
				componentArgs: {
					formatter: getSalesTypeDisplayValue,
				},
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'efbcce8b-4879-4ca4-b38d-523bc066300a',
				name: 'Futures Price',
				valuePath: 'futurePrice',
				minWidth: 130,
				width: 130,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'e06083c8-03f4-44b5-a79d-d3773ab62566',
				name: 'Basis',
				valuePath: 'basis',
				minWidth: 80,
				width: 80,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '30639fd6-306e-48de-b2ef-faef9e730872',
				name: 'Flat Price',
				valuePath: 'feedPrice',
				minWidth: 80,
				width: 80,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '2f08b146-2247-4230-8857-254cccb3528c',
				name: 'Seller',
				valuePath: 'Seller.name',
				minWidth: 100,
				width: 100,
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '31fd1b31-e834-4b92-a4b6-a9c211b7deab',
				name: 'Location',
				valuePath: 'Location.name',
				minWidth: 110,
				width: 110,
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'a264c0b2-fe93-4c7a-bc7a-583b7e088373',
				name: 'Delivery Terms',
				valuePath: 'deliveryTerms',
				minWidth: 140,
				width: 140,
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '69A30E79-1C9E-453B-8789-1292D765C18A',
				name: 'Contract Number',
				valuePath: 'contractNumber',
				minWidth: 155,
				width: 155,
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'fce6ee54-93e8-4842-bb75-031145c74a44',
				name: 'Expires At',
				valuePath: 'expirationDate',
				minWidth: 110,
				width: 110,
				cellComponent: CellComponents.IntlDateTimeFormat,
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'c2cf7b7d-b95f-4394-8b73-862ae42feafb',
				name: 'Target Note',
				valuePath: 'targetNote',
				minWidth: 120,
				width: 120,
				cellComponent: CellComponents.String,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
		];
		if (this.permissions.isBuyerRelations) {
			const brOnlyButtonColumns = [
				{
					id: 'cad25196-d640-4797-8583-8b7477b22058',
					name: '',
					valuePath: '',
					minWidth: 120,
					textAlign: 'left',
					isSortable: false,
					cellComponent: CellComponents.Button,
					componentArgs: {
						size: 'xs',
						style: 'outline',
						text: 'Remove',
						fn: this.setTargetToDelete,
						disableFn: (datum: GrainTargetOrder) => {
							return !datum.Plan?.hasWriteAccess ? true : false;
						},
					},
					isFixed: '',
					isVisible: true,
				},
				{
					id: '912259a5-8c38-43f2-bc35-7548dfed0682',
					name: '',
					valuePath: '',
					minWidth: 120,
					textAlign: 'left',
					isSortable: false,
					cellComponent: CellComponents.Button,
					componentArgs: {
						size: 'xs',
						style: 'outline',
						text: 'Claim',
						fn: this.setTargetToClaim,
						disableFn: (datum: GrainTargetOrder) => {
							return !datum.Plan?.hasWriteAccess ? true : false;
						},
					},
					isFixed: '',
					isVisible: true,
				},
			];

			baseColumns.push(...brOnlyButtonColumns);
		}
		return baseColumns;
	}

	get currentPage() {
		return this.page;
	}

	set currentPage(page) {
		this.page = page;
	}

	itemsFn = (targets: GrainTargetOrder[] | undefined) => {
		return targets?.map((target) => {
			return new Target(target, null, null);
		});
	};

	get rows() {
		return this.itemsFn(this.model.getTargets.data?.GrainTargetOrders);
	}

	get totalNumTargets() {
		return this.model.getTargets.data?.GrainTargetOrderCount?.count;
	}

	get feedCategories() {
		return this.model.getTargets.data?.FeedCategories ?? [];
	}

	get salesTypes() {
		return [
			TypeOfGrainOrderSales.BasisOnly,
			TypeOfGrainOrderSales.FuturesOnly,
			TypeOfGrainOrderSales.SetBasis,
			TypeOfGrainOrderSales.SetFutures,
			TypeOfGrainOrderSales.Flat,
		];
	}

	get searchFilterQueryParams() {
		const obj: {
			[key: string]: any;
		} = {};
		const searchQueryParams = ['brOwnerId', 'grainAdvisorId', 'customerId', 'sellerId', 'grainLocationId'] as const;
		searchQueryParams.forEach((param) => {
			const value = this[param];

			if (value) {
				obj[param] = {
					filterRule: 'equals',
					filterValue: value,
				};

				// set filterComponent property to specify component for custom display extended from search-filter
				const filterDisplayObj = getFilterDisplayProperty(param);

				if (filterDisplayObj && filterDisplayObj.customComponent) {
					obj[param].filterComponent = filterDisplayObj.customComponent;
				}
				// set filterLabel property to specify custom label for filter - default would be filterIdentifier (matches queryParam)
				if (filterDisplayObj && filterDisplayObj.label) {
					obj[param].filterLabel = filterDisplayObj.label;
				}
			}
		});

		return obj;
	}

	get selectedFeedCategoriesString() {
		if (this.selectedFeedCategories.length === 0) {
			return 'All';
		}

		if (this.selectedFeedCategories.length === 1) {
			const feedCategory = this.feedCategories.find((feedCategory) => feedCategory.id === this.selectedFeedCategories[0]);
			return feedCategory?.name;
		}

		return `${this.selectedFeedCategories.length} Selected`;
	}

	get selectedSalesTypesString() {
		if (this.selectedSalesTypes.length === 0) {
			return 'All';
		}

		if (this.selectedSalesTypes.length === 1) {
			return getSalesTypeDisplayValue(this.selectedSalesTypes[0]);
		}

		return `${this.selectedSalesTypes.length} Selected`;
	}

	@action
	addSelectedFeedCategory(feedCategory: string | null) {
		if (feedCategory === null) {
			this.selectedFeedCategories = [];
		} else if (this.selectedFeedCategories.includes(feedCategory)) {
			this.selectedFeedCategories = this.selectedFeedCategories.filter((v) => v !== feedCategory);
		} else {
			this.selectedFeedCategories = [...this.selectedFeedCategories, feedCategory];
		}

		const selectedFeedCategoriesSet = new Set(this.selectedFeedCategories);
		const feedCategoriesSet = new Set(this.feedCategories.map((a) => a.name));

		// If all ingredients are selected, clear the selected array so that 'All' is used
		if (
			selectedFeedCategoriesSet.size === feedCategoriesSet.size &&
			[...selectedFeedCategoriesSet].every((value) => feedCategoriesSet.has(value))
		) {
			this.selectedFeedCategories = [];
		}
		this.setTablePageState();
	}

	@action
	addSelectedSalesType(salesType: TypeOfGrainOrderSales | null) {
		if (salesType === null) {
			this.selectedSalesTypes = [];
		} else if (this.selectedSalesTypes.includes(salesType)) {
			this.selectedSalesTypes = this.selectedSalesTypes.filter((v) => v !== salesType);
		} else {
			this.selectedSalesTypes = [...this.selectedSalesTypes, salesType];
		}

		// If all sales types are selected, clear the selected array so that 'All' is used
		if (this.selectedSalesTypes.length === this.salesTypes.length) {
			this.selectedSalesTypes = [];
		}
		this.setTablePageState();
	}

	@action
	setDateRangeQueryParam(
		field: 'futuresMonth' | 'deliveryStartDate' | 'deliveryEndDate' | 'updatedAtDate',
		value: { startDate: string; endDate: string }
	) {
		this[`${field}Start`] = value.startDate;
		this[`${field}End`] = value.endDate;
		this.setTablePageState();
	}

	@action
	structureSearchResults(searchResults: SearchResult[]) {
		const map = new Map();

		searchResults.forEach((item) => {
			if (map.has(item.type)) {
				map.get(item.type).push({ name: item.name, type: item.type, id: item.id });
			} else {
				map.set(item.type, [{ name: item.name, type: item.type, id: item.id }]);
			}
		});

		return map;
	}

	mapSearchResult(searchResult: SearchResult) {
		let filterIdentifier: keyof VgsFeedServicesTargetsIndex | null | undefined = null;

		switch (searchResult.type) {
			case 'brOwner':
				filterIdentifier = 'brOwnerId';
				break;
			case 'advisor':
				filterIdentifier = 'grainAdvisorId';
				break;
			case 'customer':
				filterIdentifier = 'customerId';
				break;
			case 'location':
				filterIdentifier = 'grainLocationId';
				break;
			case 'seller':
				filterIdentifier = 'sellerId';
				break;
		}

		return {
			filterIdentifier,
			filterValue: searchResult.id,
		};
	}

	@action
	async fetchSearchResults(searchText: string) {
		const advisorResults: SearchResult[] = [];
		const sellerResults: SearchResult[] = [];
		const grainLocationResults: SearchResult[] = [];
		const searchQueryResults: SearchResult[] = [];
		const brOwnerResults: SearchResult[] = [];

		const searchQuery = useQuery<{ Search: Query['Search'] }, Query_SearchArgs>(this, () => [
			GET_SEARCH_QUERY,
			{
				variables: {
					query: searchText,
					typesToInclude: [TypeOfSearchItem.Customer],
				},
				onComplete: (data): void => {
					searchQueryResults.push(
						...(data?.Search.filter((item) => {
							// Filter out non-VGS customers
							if (item.type === TypeOfSearchItem.Customer && !item.attributes?.isVgs) {
								return false;
							}

							return true;
						}).map((item) => {
							switch (item.type) {
								case TypeOfSearchItem.Customer:
									return {
										type: 'customer',
										name: item.name,
										id: item.id,
									};
								default:
									return { type: '', name: '', id: '' };
							}
						}) ?? [])
					);
				},
			},
		]);

		const brOwnerQuery = useQuery<GetTarget, Query_GrainTargetOrdersArgs>(this, () => [
			GET_GRAIN_ORDER_BR_OWNER,
			{
				variables: {
					where: {
						BuyerRelationsOwner: {
							OR: [{ firstName: { contains: searchText } }, { lastName: { contains: searchText } }],
						},
					},
				},
				onComplete: (data) => {
					brOwnerResults.push(
						...(data?.GrainTargetOrders.reduce<SearchResult[]>((acc, order) => {
							if (!acc.find((searchResult) => searchResult?.id === order.BuyerRelationsOwner?.id)) {
								acc.push({
									id: order.BuyerRelationsOwner?.id,
									name: generateFullName(order.BuyerRelationsOwner?.firstName, order.BuyerRelationsOwner?.lastName) ?? '',
									type: 'brOwner',
								});
							}
							return acc;
						}, []) ?? [])
					);
				},
			},
		]);

		const advisorQuery = useQuery<GetAdvisor, Query_GrainAdvisorsArgs>(this, () => [
			GET_GRAIN_ADVISOR,
			{
				variables: {
					where: {
						name: { contains: searchText },
					},
				},
				onComplete: (data): void => {
					advisorResults.push(
						...(data?.GrainAdvisors.map((advisor) => ({
							type: 'advisor',
							name: advisor.name,
							id: advisor.id,
						})) ?? [])
					);
				},
			},
		]);

		const grainLocationQuery = useQuery<GetLocation, Query_GrainLocationsArgs>(this, () => [
			GET_GRAIN_LOCATION,
			{
				variables: {
					where: {
						name: { contains: searchText },
					},
				},
				onComplete: (data): void => {
					grainLocationResults.push(
						...(data?.GrainLocations.map((location) => ({
							type: 'location',
							name: location.name,
							id: location.id,
						})) ?? [])
					);
				},
			},
		]);

		const sellerQuery = useQuery<GetSeller, Query_SellersArgs>(this, () => [
			GET_SELLER,
			{
				variables: {
					where: {
						name: { contains: searchText },
					},
				},
				onComplete: (data): void => {
					sellerResults.push(
						...(data?.Sellers.map((seller) => ({
							type: 'seller',
							name: seller.name,
							id: seller.id,
						})) ?? [])
					);
				},
			},
		]);

		const promises = [advisorQuery.promise, grainLocationQuery.promise, searchQuery.promise, sellerQuery.promise, brOwnerQuery.promise];

		await Promise.all(promises);

		return [...advisorResults, ...searchQueryResults, ...grainLocationResults, ...sellerResults, ...brOwnerResults];
	}

	@action
	setSearchFilterQueryParam(searchResult: SearchResult) {
		const mappedSearchFilter = this.mapSearchResult(searchResult);

		if (mappedSearchFilter.filterIdentifier) {
			//@ts-ignore
			this[mappedSearchFilter.filterIdentifier] = mappedSearchFilter.filterValue;
			this.setTablePageState();
		}
	}

	@action
	clearSearchFilterQueryParam(filterIdentifier: 'brOwnerId' | 'grainAdvisorId' | 'customerId' | 'sellerId' | 'grainLocationId') {
		this[`${filterIdentifier}`] = null;
		this.setTablePageState();
	}

	@action
	setTablePageState(newPageVal: number | null = null) {
		this.currentPage = newPageVal ?? 0;
		resetVaultTableScroll('feed-services-targets-index-table');
	}

	@action
	setTargetToDelete(target: GrainTargetOrder) {
		this.targetIdToDelete = target ?? null;
	}

	@action
	setTargetToClaim(target: GrainTargetOrder) {
		this.targetToClaim = target ?? null;
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	interface Registry {
		'vgs/feed-services/targets/index': VgsFeedServicesTargetsIndex;
	}
}
