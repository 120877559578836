import Route from '@ember/routing/route';
import { tracked } from '@glimmer/tracking';
import { gql, useQuery } from 'glimmer-apollo';
import { Query, Query_EntityGroupsArgs, SortByDirection } from 'vault-client/types/graphql-types';
import { SortObj } from 'vault-client/types/vault-table';

export const GET_PERMISSION_GROUPS = gql`
	query PermissionGroups($orderBy: EntityGroupSortByDTO) {
		Organizations(orderBy: { name: Asc }) {
			id
			name
		}
		EntityGroups(orderBy: $orderBy, where: { isVgs: { equals: true } }) {
			id
			name
		}
	}
`;

type getPermissionGroups = {
	__typename?: 'Query';

	Organizations: Query['Organizations'];
	EntityGroups: Query['EntityGroups'];
};

interface QueryParams {
	sorts: SortObj[];
}

export default class VgsSettingsPermissions extends Route {
	@tracked variables: any = {};

	getPermissionGroups = useQuery<getPermissionGroups, Query_EntityGroupsArgs>(this, () => [
		GET_PERMISSION_GROUPS,
		{ variables: this.variables },
	]);

	queryParams = {
		sorts: { refreshModel: true },
	};

	generateOrderBy(sorts: SortObj[]) {
		const orderBy: {
			[key: string]: any;
		} = {};

		sorts.forEach((sort: any) => {
			const parts = sort.valuePath.split('.');
			const firstPart = parts.shift();

			let value: any = sort.isAscending ? SortByDirection.Asc : SortByDirection.Desc;

			while (parts.length > 0) {
				const part = parts.pop();
				const obj: {
					[key: string]: any;
				} = {};
				obj[part] = value;
				value = obj;
			}

			orderBy[firstPart] = value;
		});

		return orderBy;
	}

	async model(params: QueryParams) {
		this.variables = {
			orderBy: this.generateOrderBy(params.sorts),
		};

		await this.getPermissionGroups.promise;

		return { getPermissionGroups: this.getPermissionGroups, variables: this.variables };
	}
}
