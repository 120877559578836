import LRPInsuranceEndorsementsIndexController from 'vault-client/controllers/lrp-insurance-endorsements/index';

export default class OrganizationsOrganizationLrpInsuranceEndorsements extends LRPInsuranceEndorsementsIndexController {
	lrpInsuranceEndorsementRoute: string = 'organizations.organization.lrp-insurance-endorsement';
	insuranceRoutePath: string = 'organizations.organization.insurance';
	insurancePolicyRoutePath: string = 'organizations.organization.insurance-policy';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'organizations/organization/lrp-insurance-endorsements': OrganizationsOrganizationLrpInsuranceEndorsements;
	}
}
