import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { queryManager } from 'ember-apollo-client';
import agentFuzzyQuery from 'vault-client/graphql/queries/agent-fuzzy-match.graphql';
import stateFuzzyQuery from 'vault-client/graphql/queries/state-fuzzy-match.graphql';
import getFilterDisplayProperty from 'vault-client/utils/get-filter-display-property';
import { A } from '@ember/array';
import { DateTime } from 'luxon';

interface searchResult {
	id: string;
	name: string;
	type: string;
}

export default class ReportsDrpPerformanceByAIPController extends Controller {
	@queryManager apollo: any;

	queryParams = ['agent', 'stateId', 'reinsuranceYear', 'quarterStartDate', 'quarterEndDate'];
	reportsRoute: string = '';
	quarterEndDateRangeOptions = [
		{
			displayName: 'All Quarters',
			startDate: '1900-01-01',
			endDate: '2999-12-31',
		},
		{
			displayName: 'Open Quarters',
			startDate: DateTime.local().minus({ days: 60 }).startOf('quarter').toISODate(),
			endDate: '2999-12-31',
		},
		// Previous Quarter
		{
			displayName: DateTime.local().startOf('quarter').minus({ quarter: 1 }).toFormat('yyyy Qq'),
			startDate: DateTime.local().startOf('quarter').minus({ quarter: 1 }).toISODate(),
			endDate: DateTime.local().endOf('quarter').minus({ quarter: 1 }).toISODate(),
		},
		// Current Quarter
		{
			displayName: DateTime.local().startOf('quarter').toFormat('yyyy Qq'),
			startDate: DateTime.local().startOf('quarter').toISODate(),
			endDate: DateTime.local().endOf('quarter').toISODate(),
		},

		// +1 Quarter
		{
			displayName: DateTime.local().startOf('quarter').plus({ quarter: 1 }).toFormat('yyyy Qq'),
			startDate: DateTime.local().startOf('quarter').plus({ quarter: 1 }).toISODate(),
			endDate: DateTime.local().endOf('quarter').plus({ quarter: 1 }).toISODate(),
		},
		// +2 Quarter
		{
			displayName: DateTime.local().startOf('quarter').plus({ quarter: 2 }).toFormat('yyyy Qq'),
			startDate: DateTime.local().startOf('quarter').plus({ quarter: 2 }).toISODate(),
			endDate: DateTime.local().endOf('quarter').plus({ quarter: 2 }).toISODate(),
		},
		// +3 Quarter
		{
			displayName: DateTime.local().startOf('quarter').plus({ quarter: 3 }).toFormat('yyyy Qq'),
			startDate: DateTime.local().startOf('quarter').plus({ quarter: 3 }).toISODate(),
			endDate: DateTime.local().endOf('quarter').plus({ quarter: 3 }).toISODate(),
		},
		// +4 Quarter
		{
			displayName: DateTime.local().startOf('quarter').plus({ quarter: 4 }).toFormat('yyyy Qq'),
			startDate: DateTime.local().startOf('quarter').plus({ quarter: 4 }).toISODate(),
			endDate: DateTime.local().endOf('quarter').plus({ quarter: 4 }).toISODate(),
		},
		// +5 Quarter
		{
			displayName: DateTime.local().startOf('quarter').plus({ quarter: 5 }).toFormat('yyyy Qq'),
			startDate: DateTime.local().startOf('quarter').plus({ quarter: 5 }).toISODate(),
			endDate: DateTime.local().endOf('quarter').plus({ quarter: 5 }).toISODate(),
		},
		// +6 Quarter
		{
			displayName: DateTime.local().startOf('quarter').plus({ quarter: 6 }).toFormat('yyyy Qq'),
			startDate: DateTime.local().startOf('quarter').plus({ quarter: 6 }).toISODate(),
			endDate: DateTime.local().endOf('quarter').plus({ quarter: 6 }).toISODate(),
		},
		{
			displayName: `Calendar Year (${DateTime.local().year}) `,
			startDate: DateTime.local().startOf('year').toISODate(),
			endDate: DateTime.local().endOf('year').toISODate(),
		},
	];

	@tracked organizationId = '';
	@tracked globalCustomerId = '';
	@tracked customerId = '';
	@tracked locationId = '';
	@tracked agent: string | null = null;
	@tracked stateId: string | null = null;
	@tracked reinsuranceYear: string | null = null;
	@tracked quarterStartDate?: string = '1900-01-01';
	@tracked quarterEndDate?: string = '2999-12-31';
	@tracked currentScope: string | null = '';
	@tracked columns = A([
		{
			id: 'bd08e827-bfe9-4ca1-bebc-a59c02aabec3',
			name: 'AIP',
			valuePath: 'InsurancePolicy.Aip.name',
			minWidth: 80,
			cellComponent: 'String',
			textAlign: 'left',
			isSortable: false,
			isFixed: '',
			isVisible: true,
		},
		{
			id: '76cf5fc7-9751-4a97-987a-1a854cc38d86',
			name: 'Covered Milk (lbs)',
			valuePath: 'sum.effectiveCoveredMilkProduction',
			minWidth: 170,
			cellComponent: 'IntlNumberFormat',
			textAlign: 'right',
			isSortable: false,
			isFixed: '',
			isVisible: true,
		},
		{
			id: 'a484ad1b-38da-40c6-a32d-0bf5d9092a14',
			name: 'Producer Premium',
			valuePath: 'sum.producerPremiumAmount',
			minWidth: 170,
			cellComponent: 'IntlNumberFormat',
			componentArgs: {
				style: 'currency',
				currency: 'USD',
				currencySign: 'accounting',
			},
			textAlign: 'right',
			isSortable: false,
			isFixed: '',
			isVisible: true,
		},
		{
			id: 'f2154808-9691-4e18-be01-e1c5df94acca',
			name: 'Total Premium',
			valuePath: 'sum.totalPremiumAmount',
			minWidth: 160,
			cellComponent: 'IntlNumberFormat',
			componentArgs: {
				style: 'currency',
				currency: 'USD',
				currencySign: 'accounting',
			},
			textAlign: 'right',
			isSortable: false,
			isFixed: '',
			isVisible: true,
		},
		{
			id: '3af3bab1-af57-48a2-8cb1-a71ed2d20643',
			name: 'Indemnity',
			valuePath: 'sum.indemnity',
			minWidth: 130,
			cellComponent: 'IntlNumberFormat',
			componentArgs: {
				style: 'currency',
				currency: 'USD',
				currencySign: 'accounting',
			},
			textAlign: 'right',
			isSortable: false,
			isFixed: '',
			isVisible: true,
		},
		{
			id: 'fcf92e71-fefe-493d-97a8-14d6450f6bd4',
			name: 'Profit/Loss',
			valuePath: 'sum.pnl',
			minWidth: 150,
			cellComponent: 'IntlNumberFormat',
			componentArgs: {
				style: 'currency',
				currency: 'USD',
				currencySign: 'accounting',
			},
			textAlign: 'right',
			isSortable: false,
			isFixed: '',
			isVisible: true,
		},
	]);

	get searchFilterQueryParams() {
		const obj: {
			[key: string]: any;
		} = {};
		const searchQueryParams = ['agent', 'stateId'];
		searchQueryParams.forEach((param) => {
			//@ts-ignore
			if (this[param]) {
				obj[param] = {
					filterRule: 'equals',
					//@ts-ignore
					filterValue: this[param],
				};

				// set filterComponent property to specify component for custom display extended from search-filter
				const filterDisplayObj = getFilterDisplayProperty(param);
				if (filterDisplayObj && filterDisplayObj.customComponent) {
					obj[param].filterComponent = filterDisplayObj.customComponent;
				}
				// set filterLabel property to specify custom label for filter - default would be filterIdentifier (matches queryParam)
				if (filterDisplayObj && filterDisplayObj.label) {
					obj[param].filterLabel = filterDisplayObj.label;
				}
			}
		});

		return obj;
	}

	get searchPlaceholder() {
		return 'Filter by agent name or state';
	}

	get searchPrompt() {
		return 'Type a search term to examine performance by business.';
	}

	get quarterEndDateQueryParam() {
		return {
			startDate: this.quarterStartDate,
			endDate: this.quarterEndDate,
		};
	}

	get reinsuranceYearRangeOptions() {
		const reinsuranceYearRangeOptions = [
			// All Years
			{
				displayName: 'All Years',
				startDate: '1900-01-01',
				endDate: '2999-12-31',
			},
			// -2 years
			{
				displayName: DateTime.local().minus({ years: 2 }).year,
				startDate: DateTime.local().startOf('year').minus({ years: 2 }).toISODate(),
				endDate: DateTime.local().endOf('year').minus({ years: 2 }).toISODate(),
			},
			// -1 year
			{
				displayName: DateTime.local().minus({ years: 1 }).year,
				startDate: DateTime.local().startOf('year').minus({ years: 1 }).toISODate(),
				endDate: DateTime.local().endOf('year').minus({ years: 1 }).toISODate(),
			},
			// Current Year
			{
				displayName: DateTime.local().year,
				startDate: DateTime.local().startOf('year').toISODate(),
				endDate: DateTime.local().endOf('year').toISODate(),
			},
		];

		const currentYear = DateTime.local().year;

		// +1 year if after July 1st
		if (DateTime.local() > DateTime.local(currentYear, 7, 1)) {
			reinsuranceYearRangeOptions.push({
				displayName: DateTime.local().plus({ years: 1 }).year,
				startDate: DateTime.local().startOf('year').plus({ years: 1 }).toISODate(),
				endDate: DateTime.local().endOf('year').plus({ years: 1 }).toISODate(),
			});
		}

		return reinsuranceYearRangeOptions;
	}

	get reinsuranceYearQueryParam() {
		let startDate = '1900-01-01';
		let endDate = '2999-12-31';

		if (this.reinsuranceYear) {
			startDate = DateTime.fromFormat(this.reinsuranceYear, 'yyyy').startOf('year').toISODate();
			endDate = DateTime.fromFormat(this.reinsuranceYear, 'yyyy').endOf('year').toISODate();
		}

		return {
			startDate,
			endDate,
		};
	}

	@action
	async fetchSearchResults(searchText: string) {
		const agentResults = [];
		const stateResults: any[] = [];

		// get fuzzy match agents
		const agentVariables = {
			where: {
				OR: [
					{ agentFirstName: { contains: searchText } },
					{ agentLastName: { contains: searchText } },
					{ agentFullName: { contains: searchText } },
				],
			},
		};
		const agentsArr = (
			await this.apollo.watchQuery({
				query: agentFuzzyQuery,
				variables: agentVariables,
			})
		).InsurancePolicies.map((agent: any) => `${agent.agentFirstName} ${agent.agentLastName}`);

		const agentSet = new Set(agentsArr);

		for (const agent of agentSet) {
			const obj: {
				[key: string]: any;
			} = {};
			obj.type = 'Agent';
			obj.name = agent;
			agentResults.push(obj);
		}

		// get fuzzy match state
		const stateVariables = {
			where: {
				OR: [{ abbreviation: { contains: searchText } }, { name: { contains: searchText } }, { id: { contains: searchText } }],
			},
		};

		const states = (
			await this.apollo.watchQuery({
				query: stateFuzzyQuery,
				variables: stateVariables,
			})
		).States;

		states.forEach((state: any) => {
			const obj: {
				[key: string]: any;
			} = {};
			obj.type = 'State';
			obj.id = state.id;
			obj.name = state.name;
			stateResults.push(obj);
		});

		// return combined set
		return [...agentResults, ...stateResults];
	}

	@action
	structureSearchResults(searchResults: any) {
		const map = new Map();

		searchResults.forEach((item: any) => {
			if (map.has(item.type)) {
				map.get(item.type).push({ id: item.id, name: item.name, type: item.type });
			} else {
				map.set(item.type, [{ id: item.id, name: item.name, type: item.type }]);
			}
		});

		return map;
	}

	@action
	setQuarterEndDateQueryParam(value: { startDate?: string; endDate?: string }) {
		this.quarterStartDate = value.startDate;
		this.quarterEndDate = value.endDate;
	}

	@action
	setReinsuranceYearQueryParam(value: { startDate: string; endDate: string }) {
		if (value.startDate === '1900-01-01') {
			this.reinsuranceYear = null;
		} else {
			this.reinsuranceYear = DateTime.fromISO(value.startDate).year.toString();
		}
	}

	@action
	setSearchFilterQueryParam(searchResult: searchResult) {
		const mappedSearchFilter = this.mapSearchResult(searchResult);
		if (mappedSearchFilter) {
			//@ts-ignore
			this[mappedSearchFilter.filterIdentifier] = mappedSearchFilter.filterValue;
		}
	}

	mapSearchResult(searchResult: searchResult) {
		let filterIdentifier;

		switch (searchResult.type) {
			case 'Agent':
				filterIdentifier = 'agent';
				break;
			case 'State':
				filterIdentifier = 'stateId';
				break;
			default:
				return null;
		}

		return {
			filterIdentifier,
			filterValue: searchResult.id ?? searchResult.name,
		};
	}

	@action
	clearSearchFilterQueryParam(filterIdentifier: any) {
		// @ts-ignore
		this[`${filterIdentifier}`] = null;
	}
}
