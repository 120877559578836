import Controller from '@ember/controller';
import { DateTime, Interval } from 'luxon';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { queryManager } from 'ember-apollo-client';
import getFilterDisplayProperty from 'vault-client/utils/get-filter-display-property';
import searchQuery from 'vault-client/graphql/queries/search.graphql';
import agentFuzzyQuery from 'vault-client/graphql/queries/agent-fuzzy-match.graphql';
import stateFuzzyQuery from 'vault-client/graphql/queries/state-fuzzy-match.graphql';
import { TableColumn, CellComponents } from 'vault-client/types/vault-table';
import { AggregateDerivedDrpEndorsementDTO } from 'vault-client/types/graphql-types';
import InsuranceQuarter from 'vault-client/models/insurance-quarter';
import { ModelFrom } from 'vault-client/utils/type-utils';
import OrganizationsInsuranceOverview from 'vault-client/routes/organizations/organization/insurance-overview';
import isTouchDevice from 'vault-client/utils/is-touch-device';

interface SearchFilterIdentifiers {
	agent: string | null;
	aipId: string | null;
	stateId: string | null;
}

type SearchFilterIdentifier = keyof SearchFilterIdentifiers;

function isSearchFilterIdentifier(key: unknown): key is SearchFilterIdentifier {
	const searchFilterIdentifiers = ['agent', 'aipId', 'stateId'];
	if (typeof key === 'string' && searchFilterIdentifiers.includes(key)) {
		return true;
	}
	return false;
}

interface searchResult {
	id: string;
	name: string;
	type: string;
}

export default class InsuranceOverview extends Controller implements SearchFilterIdentifiers {
	@queryManager apollo: any;
	declare model: ModelFrom<OrganizationsInsuranceOverview>;

	insuranceRoutePath = '';
	insuranceQuarterRoutePath = '';
	pageTitle = 'Insurance Overview';

	queryParams = ['agent', 'aipId', 'stateId', 'quarterStartDate', 'quarterEndDate'];

	quarterEndDateRangeOptions = [
		{
			displayName: 'All Quarters',
			startDate: '1900-01-01',
			endDate: '2999-12-31',
		},
		{
			displayName: 'Open Quarters',
			startDate: DateTime.local().minus({ days: 60 }).startOf('quarter').toISODate(),
			endDate: '2999-12-31',
		},
		{
			displayName: `Calendar Year (${DateTime.local().year}) `,
			startDate: DateTime.local().startOf('year').toISODate(),
			endDate: DateTime.local().endOf('year').toISODate(),
		},
		{
			displayName: `Calendar Year (${DateTime.local().plus({ year: 1 }).year}) `,
			startDate: DateTime.local().plus({ year: 1 }).startOf('year').toISODate(),
			endDate: DateTime.local().plus({ year: 1 }).endOf('year').toISODate(),
		},
	];

	@tracked agent: string | null = null;
	@tracked aipId: string | null = null;
	@tracked stateId: string | null = null;
	@tracked quarterStartDate?: string = DateTime.local().minus({ days: 60 }).startOf('quarter').toISODate();
	@tracked quarterEndDate?: string = '2999-12-31';
	@tracked currentScope: string | null = '';

	get columns(): TableColumn[] {
		const baseColumns: TableColumn[] = [
			{
				id: 'c02e4e0b-9e0e-41b1-8b25-9f8a0f812fa7',
				name: 'Quarter',
				valuePath: 'startDate',
				width: 125,
				minWidth: 125, // need minWidth === width re bug on resizing first fixed column
				cellComponent: CellComponents.QuarterFormat,
				textAlign: 'left',
				isSortable: false,
				isFixed: !isTouchDevice() ? 'left' : '',
				isVisible: true,
				linkRoute: this.insuranceQuarterRoutePath,
				linkModelPath: this.insuranceQuarterRoutePath == 'businesses.business.insurance-overview-show' ? 'startDate' : undefined,
				linkQuery: {
					quarterStartDate: {
						rowKey: 'startDate',
					},
					quarterEndDate: {
						rowKey: 'endDate',
					},
					agent: {
						staticValue: this.agent,
					},
					aipId: {
						staticValue: this.aipId,
					},
					stateId: {
						staticValue: this.stateId,
					},
				},
			},
			{
				id: '89a8cbac-f683-4fb4-871f-5f2fee14c37a',
				name: 'Production',
				valuePath: 'grossProduction',
				minWidth: 150,
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
				isTotaled: true,
			},
			{
				id: '03ba6588-21a2-4f42-9538-a373c542d7c4',
				name: 'Insured Milk',
				valuePath: 'effectiveCoveredMilkProduction',
				minWidth: 150,
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
				isTotaled: true,
			},
			{
				id: '0ea509bb-cc8b-417e-9182-199fc8c3b08d',
				name: 'Insured %',
				cellComponent: CellComponents.String,
				subcolumns: [
					{
						id: 'b0327471-6e13-4ff2-b78b-2cbc5649844e',
						name: 'III',
						valuePath: 'classIiiPercentInsured',
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'percent',
						},
						textAlign: 'right',
						isSortable: true,
						isFixed: '',
						isVisible: true,
						isTotaled: true,
					},
					{
						id: '6fb78b38-05ad-444a-b416-e68d5fbc4007',
						name: 'IV',
						valuePath: 'classIvPercentInsured',
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'percent',
						},
						textAlign: 'right',
						isSortable: true,
						isFixed: '',
						isVisible: true,
						isTotaled: true,
					},
					{
						id: '586f4c16-efaa-49ff-ada3-73edb9afde1e',
						name: 'Total',
						valuePath: 'percentInsured',
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'percent',
						},
						textAlign: 'right',
						isSortable: true,
						isFixed: '',
						isVisible: true,
						isTotaled: true,
					},
				],
				textAlign: 'center',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '11e3ebaf-513b-40fc-a676-9a58aacc872b',
				name: 'Producer Premium',
				cellComponent: CellComponents.String,
				textAlign: 'center',
				isSortable: false,
				isFixed: '',
				isVisible: true,
				isTotaled: true,
				subcolumns: [
					{
						id: '5e0343b8-a9d8-41b1-b250-018b93d7c2f8',
						name: 'Total',
						valuePath: 'producerPremiumAmount',
						minWidth: 130,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							currencySign: 'accounting',
							maximumFractionDigits: 0,
						},
						textAlign: 'right',
						isSortable: true,
						isFixed: '',
						isVisible: true,
						isTotaled: true,
					},
					{
						id: '47d211bb-40c2-403c-a1dd-74448d14970a',
						name: 'CWT',
						valuePath: 'producerPremiumAmountCwt',
						minWidth: 70,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							currencySign: 'accounting',
						},
						textAlign: 'right',
						isSortable: true,
						isFixed: '',
						isVisible: true,
						isTotaled: true,
					},
				],
			},
			{
				id: '6f83249d-cbca-4558-ba60-4ae9897e7561',
				name: 'Indemnity',
				cellComponent: CellComponents.String,
				textAlign: 'center',
				isSortable: false,
				isFixed: '',
				isVisible: true,
				isTotaled: true,
				subcolumns: [
					{
						id: 'ec3372d1-d9aa-4f7f-94fd-5016fc36a04d',
						name: 'Total',
						valuePath: 'indemnity',
						minWidth: 130,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							currencySign: 'accounting',
							maximumFractionDigits: 0,
						},
						textAlign: 'right',
						isSortable: true,
						isFixed: '',
						isVisible: true,
						isTotaled: true,
					},
					{
						id: '2353f363-6472-4b58-9dbb-d0820c98a8b0',
						name: 'CWT',
						valuePath: 'indemnityCwt',
						minWidth: 70,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							currencySign: 'accounting',
						},
						textAlign: 'right',
						isSortable: true,
						isFixed: '',
						isVisible: true,
						isTotaled: true,
					},
				],
			},
			{
				id: '9727bce7-34fc-4e8c-ab48-28ab4ef8d81b',
				name: 'Profit/Loss',
				cellComponent: CellComponents.String,
				textAlign: 'center',
				isSortable: false,
				isFixed: '',
				isVisible: true,
				isTotaled: true,
				subcolumns: [
					{
						id: 'e76c7336-33b8-4c92-8ae2-00ead4fe7c7a',
						name: 'Total',
						valuePath: 'pnl',
						minWidth: 130,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							currencySign: 'accounting',
							maximumFractionDigits: 0,
						},
						textAlign: 'right',
						isSortable: true,
						isFixed: '',
						isVisible: true,
						isTotaled: true,
					},
					{
						id: '2efa0d19-b5e0-4caf-851c-3633e3486119',
						name: 'CWT',
						valuePath: 'pnlCwt',
						minWidth: 70,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							currencySign: 'accounting',
						},
						textAlign: 'right',
						isSortable: true,
						isFixed: '',
						isVisible: true,
						isTotaled: true,
					},
				],
			},
		];

		return baseColumns;
	}

	get searchFilterQueryParams() {
		const obj: {
			[param: string]: { [filterProperty: string]: string };
		} = {};

		const searchQueryParams = ['agent', 'aipId', 'stateId'];
		searchQueryParams.forEach((param) => {
			const value = this[param as keyof InsuranceOverview];

			if (value) {
				obj[param] = {
					filterRule: 'equals',
					filterValue: value,
				};

				// set filterComponent property to specify component for custom display extended from search-filter
				const filterDisplayObj = getFilterDisplayProperty(param);
				if (filterDisplayObj && filterDisplayObj.customComponent) {
					obj[param].filterComponent = filterDisplayObj.customComponent;
				}
				// set filterLabel property to specify custom label for filter - default would be filterIdentifier (matches queryParam)
				if (filterDisplayObj && filterDisplayObj.label) {
					obj[param].filterLabel = filterDisplayObj.label;
				}
			}
		});

		return obj;
	}

	get searchPlaceholder() {
		return 'Filter by agent name, AIP, state...';
	}

	get searchPrompt() {
		return 'Type a search term to filter data by agent name, AIP, or state.';
	}

	get quarterEndDateQueryParam() {
		return {
			startDate: this.quarterStartDate,
			endDate: this.quarterEndDate,
		};
	}

	get firstQuarterDate() {
		if (this.model.AggregateDerivedDrpEndorsements[0]?.quarterStartDate) {
			return this.model.AggregateDerivedDrpEndorsements[0].quarterStartDate;
		}

		if (this.model.AggregateForecastedMilkProductionByMonths[0]) {
			const { date } = this.model.AggregateForecastedMilkProductionByMonths[0];
			if (date) {
				return date;
			}
		}

		return DateTime.local().startOf('quarter').toISODate();
	}

	get quarters() {
		const endorsementData = this.model.AggregateDerivedDrpEndorsements;
		const productionData = this.model.AggregateForecastedMilkProductionByMonths;
		const classProductionData = this.model.ClassProduction;

		const quarters: any = {};

		//
		//  Add quarters to object.
		//

		const firstQuarterDate = this.firstQuarterDate;

		let lastQuarterDate =
			endorsementData.length > 0
				? endorsementData[endorsementData.length - 1].quarterStartDate
				: DateTime.local().startOf('quarter').plus({ quarters: 6 }).toISODate();

		const openQuartersOption = this.quarterEndDateRangeOptions.find((option) => option.displayName === 'Open Quarters');
		const dateInterval = Interval.fromISO(firstQuarterDate + '/' + lastQuarterDate);

		if (
			openQuartersOption &&
			this.quarterStartDate === openQuartersOption.startDate &&
			this.quarterEndDate === openQuartersOption.endDate &&
			dateInterval.length('quarters') <= 4
		) {
			lastQuarterDate = DateTime.fromISO(firstQuarterDate).plus({ quarters: 4 }).toISODate();
		}

		let currentQuarterDate = firstQuarterDate;
		if (currentQuarterDate && lastQuarterDate) {
			while (currentQuarterDate <= lastQuarterDate) {
				quarters[currentQuarterDate] = new InsuranceQuarter(currentQuarterDate);
				currentQuarterDate = DateTime.fromISO(currentQuarterDate).plus({ quarter: 1 }).toISODate();
			}
		} else {
			throw new Error('Missing quarterStartDate from Endorsement data.');
		}

		//
		//  Add endorsement data to quarter
		//

		endorsementData.forEach((data: AggregateDerivedDrpEndorsementDTO) => {
			if (data.quarterStartDate) {
				quarters[data.quarterStartDate].addEndorsementData(data);
			} else {
				throw new Error('Missing quarter start date for AggrregateDerivedDrpEndorsementDto');
			}
		});

		//
		//  Add production data to quarter
		//

		productionData.forEach((data) => {
			if (data.date) {
				const startOfQuarter = DateTime.fromISO(data.date).startOf('quarter').toISODate();
				quarters[startOfQuarter]?.addProductionData(data);
			} else {
				throw new Error('Missing date from AggregateForecastedMilkProductionDto');
			}
		});

		classProductionData.forEach((data) => {
			if (data.date) {
				const startOfQuarter = DateTime.fromISO(data.date).startOf('quarter').toISODate();
				quarters[startOfQuarter]?.addProductionData(data);
			} else {
				throw new Error('Missing date from Class Production');
			}
		});

		return Object.values(quarters);
	}

	get quartersColTotals() {
		// percentInsured = sum of effectiveCoveredMilkProduction / sum of grossProduction if grossProduction
		type Quarter = {
			effectiveCoveredMilkProduction: number;
			pnl: number;
			pnlCwt?: number | null;
			grossButterfat: number;
			grossOtherSolids: number;
			grossProduction: number;
			grossProtein: number;
			indemnity: number;
			indemnityCwt?: number | null;
			producerPremiumAmount: number;
			producerPremiumAmountCwt?: number | null;
			percentInsured?: number;
			classIiiEffectiveCoveredMilkProduction: number;
			classIiiEffectiveUtilization: number;
			classIvEffectiveCoveredMilkProduction: number;
			classIvEffectiveUtilization: number;
			classIiiPercentInsured?: number;
			classIvPercentInsured?: number;
		};
		const columnSums = this.quarters.reduce<Quarter>(
			(acc: Quarter, quarter: Quarter) => {
				acc.effectiveCoveredMilkProduction += quarter.effectiveCoveredMilkProduction;
				acc.pnl += quarter.pnl;
				acc.grossButterfat += quarter.grossButterfat;
				acc.grossOtherSolids += quarter.grossOtherSolids;
				acc.grossProduction += quarter.grossProduction;
				acc.grossProtein += quarter.grossProtein;
				acc.indemnity += quarter.indemnity;
				acc.producerPremiumAmount += quarter.producerPremiumAmount;
				acc.classIiiEffectiveCoveredMilkProduction += quarter.classIiiEffectiveCoveredMilkProduction;
				acc.classIiiEffectiveUtilization += quarter.classIiiEffectiveUtilization;
				acc.classIvEffectiveCoveredMilkProduction += quarter.classIvEffectiveCoveredMilkProduction;
				acc.classIvEffectiveUtilization += quarter.classIvEffectiveUtilization;
				return acc;
			},
			{
				effectiveCoveredMilkProduction: 0,
				pnl: 0,
				grossButterfat: 0,
				grossOtherSolids: 0,
				grossProduction: 0,
				grossProtein: 0,
				indemnity: 0,
				producerPremiumAmount: 0,
				classIiiEffectiveCoveredMilkProduction: 0,
				classIiiEffectiveUtilization: 0,
				classIvEffectiveCoveredMilkProduction: 0,
				classIvEffectiveUtilization: 0,
			}
		);
		if (columnSums.grossProduction) {
			columnSums.percentInsured = columnSums.effectiveCoveredMilkProduction / columnSums.grossProduction;
		}

		if (columnSums.classIiiEffectiveUtilization) {
			columnSums.classIiiPercentInsured = columnSums.classIiiEffectiveCoveredMilkProduction / columnSums.classIiiEffectiveUtilization;
		}

		if (columnSums.classIvEffectiveUtilization) {
			columnSums.classIvPercentInsured = columnSums.classIvEffectiveCoveredMilkProduction / columnSums.classIvEffectiveUtilization;
		}

		columnSums.pnlCwt = columnSums.effectiveCoveredMilkProduction
			? (columnSums.pnl ?? 0) / (columnSums.effectiveCoveredMilkProduction / 100)
			: null;

		columnSums.indemnityCwt = columnSums.effectiveCoveredMilkProduction
			? (columnSums.indemnity ?? 0) / (columnSums.effectiveCoveredMilkProduction / 100)
			: null;

		columnSums.producerPremiumAmountCwt = columnSums.effectiveCoveredMilkProduction
			? (columnSums.producerPremiumAmount ?? 0) / (columnSums.effectiveCoveredMilkProduction / 100)
			: null;

		return [columnSums];
	}

	@action
	async fetchSearchResults(searchText: string) {
		let searchApiResults = [];
		const agentResults = [];
		const stateResults: any[] = [];

		// get fuzzy match agents
		const agentVariables = {
			where: {
				OR: [
					{ agentFirstName: { contains: searchText } },
					{ agentLastName: { contains: searchText } },
					{ agentFullName: { contains: searchText } },
				],
			},
		};
		const agentsArr = (
			await this.apollo.watchQuery({
				query: agentFuzzyQuery,
				variables: agentVariables,
			})
		).InsurancePolicies.map((agent: any) => `${agent.agentFirstName} ${agent.agentLastName}`);

		const agentSet = new Set(agentsArr);

		for (const agent of agentSet) {
			const obj = {
				type: 'Agent',
				name: agent,
			};
			agentResults.push(obj);
		}

		// get fuzzy match state
		const stateVariables = {
			where: {
				OR: [{ abbreviation: { contains: searchText } }, { name: { contains: searchText } }, { id: { contains: searchText } }],
			},
		};
		const states = (
			await this.apollo.watchQuery({
				query: stateFuzzyQuery,
				variables: stateVariables,
			})
		).States;
		states.forEach((state: any) => {
			const obj = {
				type: 'State',
				id: state.id,
				name: state.name,
			};
			stateResults.push(obj);
		});

		// get search API matches
		const variables = {
			query: searchText,
			typesToInclude: ['Aip'],
		};

		searchApiResults = (await this.apollo.watchQuery({ query: searchQuery, variables })).Search;

		// return combined set
		return [...agentResults, ...searchApiResults, ...stateResults];
	}

	@action
	structureSearchResults(searchResults: searchResult[]) {
		const map = new Map();

		searchResults.forEach((item) => {
			if (map.has(item.type)) {
				map.get(item.type).push({ id: item.id, name: item.name, type: item.type });
			} else {
				map.set(item.type, [{ id: item.id, name: item.name, type: item.type }]);
			}
		});

		return map;
	}

	@action
	setSearchFilterQueryParam(searchResult: searchResult) {
		const mappedSearchFilter = this.mapSearchResult(searchResult);
		if (mappedSearchFilter) {
			this[mappedSearchFilter.filterIdentifier] = mappedSearchFilter.filterValue;
		}
	}

	mapSearchResult(searchResult: searchResult): { filterIdentifier: SearchFilterIdentifier; filterValue: string } | null {
		let filterIdentifier: SearchFilterIdentifier;

		switch (searchResult.type) {
			case 'Aip':
				filterIdentifier = 'aipId';
				break;
			case 'Agent':
				filterIdentifier = 'agent';
				break;
			case 'State':
				filterIdentifier = 'stateId';
				break;
			default:
				return null;
		}

		return {
			filterIdentifier,
			filterValue: searchResult.id ?? searchResult.name,
		};
	}

	@action
	clearSearchFilterQueryParam(filterIdentifier: string) {
		if (isSearchFilterIdentifier(filterIdentifier)) {
			this[filterIdentifier] = null;
		}
	}

	@action
	setQuarterEndDateQueryParam(value: { startDate?: string; endDate?: string }) {
		this.quarterStartDate = value.startDate;
		this.quarterEndDate = value.endDate;
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'insurance-overview': InsuranceOverview;
	}
}
