import ToolsSwinePriceScenariosController from 'vault-client/controllers/tools/swine-price-scenarios';

export default class BusinessesBusinessToolsSwinePriceScenariosController extends ToolsSwinePriceScenariosController {
	toolsRoutePath = 'businesses.business.tools';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'businesses/business/tools/swine-price-scenarios': BusinessesBusinessToolsSwinePriceScenariosController;
	}
}
