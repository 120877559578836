import TransactionClass from './transaction';
import Big from 'big.js';
import Swap from './swap';
import { Account, Instrument, Transaction } from 'vault-client/types/graphql-types';
import { tracked } from '@glimmer/tracking';
import { next } from '@ember/runloop';

export default class SwapTransaction extends TransactionClass {
	@tracked Instrument: Swap;

	constructor(owner: any, transaction: Transaction, instrument: Instrument | null, account: Account | null, useEODPrice = false) {
		super(owner, transaction, account, useEODPrice);

		const _instrument = instrument ?? transaction.Instrument;
		this.Instrument = new Swap(_instrument as any);

		// DGC: For some reason, I had to push this to the next runloop to prevent
		// a Glimmer error.
		const barchartSymbol = this.Instrument.priceInstrument?.barchartSymbol;
		const obj = { barchartSymbol, marketData: this.marketData };
		next(obj, function () {
			if (obj.barchartSymbol) obj.marketData.register(obj.barchartSymbol);
		});
	}

	get plPerContract() {
		const lookAlikeBarchartSymbol = this.Instrument.priceInstrument?.barchartSymbol;

		if (lookAlikeBarchartSymbol) {
			const marketPrice = this.getMarketPrice(lookAlikeBarchartSymbol);
			let pointValue = this.Instrument.Product?.StandardProductLotSpecification?.pointValue || 1;
			if (typeof pointValue === 'string') {
				pointValue = pointValue.replace(/[^0-9.]/g, '');
			}

			if (marketPrice) {
				const v = new Big(marketPrice).minus(this.price).times(pointValue);
				return this.quantity > 0 ? v : v.times(-1);
			}
		}

		return new Big(0);
	}

	get grossPl() {
		return parseFloat(this.plPerContract.times(new Big(this.quantityInContracts).abs()).toString());
	}

	get netPl() {
		return this.grossPl + this.commission + this.nonCommissionFees;
	}
}
