import Route from '@ember/routing/route';
import { tracked } from '@glimmer/tracking';
import BusinessesBusinessRoute from 'vault-client/routes/businesses/business';
import { ModelFrom } from 'vault-client/utils/type-utils';
import { Query, Query_FieldsArgs, FieldSortByDTO } from 'vault-client/types/graphql-types';
import { gql, useQuery } from 'glimmer-apollo';
import { SortObj } from 'vault-client/types/vault-table';
export const GET_FIELDS = gql`
	query Fields($orderBy: FieldSortByDTO, $where: FieldFilterDTO, $offset: Float, $limit: Float) {
		Fields(orderBy: $orderBy, where: $where, offset: $offset, limit: $limit) {
			id
			name
			acres
		}
		FieldCount(where: $where) {
			count
		}
	}
`;

export type FieldsQuery = {
	Fields: Query['Fields'];
	FieldCount: Query['FieldCount'];
};

interface QueryParams {
	name: string;
	acres: number;
	sorts: SortObj[];
	page: number;
	size: number;
}
export default class BusinessesBusinessFieldsIndexRoute extends Route {
	@tracked variables = {};

	queryParams = {
		name: { refreshModel: true },
		acres: { refreshModel: true },
		sorts: { refreshModel: true },
		page: { refreshModel: true },
		size: { refreshModel: true },
	};

	generateOrderBy(sorts: SortObj[]): FieldSortByDTO {
		const orderBy: {
			[key: string]: any;
		} = {};

		sorts.forEach((sort: any) => {
			const value = sort.isAscending ? 'Asc' : 'Desc';
			orderBy[sort.valuePath] = value;
		});

		return orderBy;
	}

	getFields = useQuery<FieldsQuery, Query_FieldsArgs>(this, () => [GET_FIELDS, { variables: this.variables }]);
	async model(params: QueryParams) {
		const { businessId } = this.modelFor('businesses.business') as ModelFrom<BusinessesBusinessRoute>;
		this.variables = {
			where: { businessId: { equals: businessId } },
			orderBy: this.generateOrderBy(params.sorts),
			offset: params.page * params.size,
			limit: params.size,
		};
		await this.getFields.promise;
		return { businessId: businessId, fields: this.getFields, variables: this.variables };
	}
}
