import Route from '@ember/routing/route';
import { tracked } from '@glimmer/tracking';
import { gql, useQuery } from 'glimmer-apollo';
import { Query, Query_GrainFillOrderArgs } from 'vault-client/types/graphql-types';

const GET_FEED_FILL = gql`
	query GrainFillOrder($id: String!) {
		GrainFillOrder(id: $id) {
			id
			deliveryTerms
			bushels
			futuresMonth
			deliveryStartDate
			deliveryEndDate
			deliveryMonth
			salesType
			futurePrice
			htaFee
			rollFee
			flexFee
			basis
			flatPrice
			contractNumber
			spread
			targetId
			Seller {
				id
				name
			}
			Location {
				id
				name
			}
			Activity(orderBy: { performedAt: Desc }) {
				id
				type
				data
				performedAt
				GrainPlan {
					id
					type
				}
				User {
					id
					firstName
					lastName
				}
			}
			Plan {
				... on GrainFeedPlan {
					id
					type
					startDate
					hasWriteAccess
					feedYear
					FeedCategory {
						id
						name
					}
					Customer {
						id
						name
						RelationshipOwner {
							id
							firstName
							lastName
						}
					}
				}
			}
			Notes(orderBy: { createdAt: Desc }) {
				orderId
				id
				note
				writtenBy
				createdAt
				Order {
					id
					type
				}
			}
			GrainTargetOrder {
				id
				Notes(orderBy: { createdAt: Desc }) {
					orderId
					id
					note
					writtenBy
					createdAt
					Order {
						id
						type
					}
				}
				Activity(orderBy: { performedAt: Desc }) {
					id
					data
					type
					operationType
					performedAt
					performedAt
					GrainPlan {
						id
						type
					}
					User {
						firstName
						lastName
					}
				}
			}
		}
	}
`;

type GetFill = {
	__typename?: 'Query';

	GrainFillOrder: Query['GrainFillOrder'];
};

type ModelParams = {
	id: string;
};

export default class VgsFeedServicesFillsShow extends Route {
	@tracked variables: Query_GrainFillOrderArgs = { id: '' };

	getFeedFill = useQuery<GetFill, Query_GrainFillOrderArgs>(this, () => [GET_FEED_FILL, { variables: this.variables }]);

	async model(params: ModelParams) {
		this.variables = {
			id: params.id,
		};

		await this.getFeedFill.promise;

		return { getFeedFill: this.getFeedFill };
	}
}
