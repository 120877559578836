import Service from '@ember/service';
import { later, debounce, next } from '@ember/runloop';
import { TrackedMap } from 'tracked-maps-and-sets';
import { tracked } from '@glimmer/tracking';
import { DateTime } from 'luxon';
import * as Sentry from '@sentry/browser';
import ENV from 'vault-client/config/environment';

export type Settlement = {
	close: number;
	dayCode: string;
	dollarVolume: number;
	expirationDate: string;
	flag: string;
	high: number;
	lastPrice: number;
	low: number;
	mode: string;
	name: string;
	netChange: number;
	numTrades: number;
	open: number;
	percentChange: number;
	previousVolume: number;
	serverTimestamp: string;
	symbol: string;
	tradeTimestamp: string;
	unitCode: string;
	volume: number;
};

export default class MarketDataService extends Service {
	subscriptions = new TrackedMap();
	@tracked historicalSubscriptions = new TrackedMap<string, TrackedMap<string, any[]>>();
	pollFrequency = 5000;
	@tracked scheduledPoll: any = null;
	@tracked marketDataUpdated = true;
	@tracked lastPolledAt: DateTime | null = null;
	tradeTimestampMin: string = '1970-01-01T0:00:00-00:00';

	constructor(...args: any) {
		super(...args);

		// Initial poll should be immediately after this has been constructed.
		later(this, this.poll.bind(this), 1);
	}

	register(symbol: string) {
		next(() => {
			if (this.subscriptions.has(symbol)) {
				// @ts-ignore
				const previousQuantity = this.subscriptions.get(symbol).get('quantity');
				// @ts-ignore
				this.subscriptions.get(symbol).set('quantity', previousQuantity + 1);
			} else {
				const newMap = new TrackedMap();
				newMap.set('quantity', 1);
				newMap.set('price', null);

				this.subscriptions.set(symbol, newMap);

				debounce(this, this.updateMarketData, 100);
			}
		});
	}

	unregister(symbol: any) {
		if (this.subscriptions.has(symbol)) {
			// @ts-ignore
			const previousQuantity = this.subscriptions.get(symbol).get('quantity');
			// @ts-ignore
			this.subscriptions.get(symbol).set('quantity', previousQuantity - 1);
		} else {
			throw Error('Unregistering a non-existent subscription.');
		}
	}

	getMarketDatum(symbol: string) {
		if (this.subscriptions.has(symbol)) {
			// @ts-ignore
			const price = this.subscriptions.get(symbol).get('price');

			return price?.tradeTimestamp >= this.tradeTimestampMin ? price : null;
		}
	}

	getEODPrice(symbol: string) {
		// Get the previous day's settlement/close price for the instrument, factoring in flags.
		// Return Previous Settlement if 's' flag is found
		// Return preciousClose otherwise

		const datum = this.getMarketDatum(symbol);

		if (!datum || datum.tradeTimestamp < this.tradeTimestampMin) return null;

		if (datum.flag === 's' && datum.previousSettlement) {
			return datum.previousSettlement;
		}

		return datum.previousSettlement ?? null;
	}

	getLatestPrice(symbol: string): number | null {
		// Get latest price of instrument, factoring in flags.
		// Return settlement if 's' flag is found
		// Return lastPrice or close otherwise

		const datum = this.getMarketDatum(symbol);

		if (!datum || datum.tradeTimestamp < this.tradeTimestampMin) return null;

		if (datum.flag === 's' && datum.settlement) {
			return datum.settlement;
		}

		return datum.lastPrice ?? datum.close ?? null;
	}

	async updateMarketData() {
		const subscriptionEntries = [...this.subscriptions.entries()];
		const activeSubscriptions = subscriptionEntries.filter((entry) => {
			// @ts-ignore
			return entry[1].get('quantity') > 0;
		});

		const symbols = activeSubscriptions.map((subscription) => {
			return subscription[0];
		});

		if (symbols.length > 0) {
			const length = symbols.length;

			let current = 0;
			const groups = [];
			let end;

			while (current < length) {
				end = length < current + 50 ? length : current + 50;
				groups.push(symbols.slice(current, end));
				current += 50;
			}

			const results = [];
      const apiKey: string = ENV.barchartApiToken;
			for (let i = 0; i < groups.length; i++) {
				const symbols = groups[i];
				try {
					const res = await fetch(
						`https://ondemand.websol.barchart.com/getQuote.json?apikey=${apiKey}&symbols=${symbols.join(
							','
						)}&fields=settlement,previousSettlement,previousClose,openInterest,fiftyTwoWkHigh,fiftyTwoWkLow`,
						{
							method: 'GET',
							headers: {},
						}
					);

					results.push(res);
				} catch (error) {
					Sentry.captureException(error);
				}
			}

			const prices = [];
			for (let i = 0; i < results.length; i++) {
				const result = results[i];
				const contentType = result.headers.get('content-type');

				// Verify that contentType is JSON and that the status code represents success
				if (contentType && contentType.includes('application/json') && result.ok) {
					const r = await result.json();

					if (r.results) {
						prices.push(...r.results);
					}
				}
			}

			prices.forEach((price) => {
				if (this.subscriptions.has(price.symbol) && price.tradeTimestamp >= this.tradeTimestampMin) {
					// @ts-ignore
					this.subscriptions.get(price.symbol).set('price', price);
				}
			});

			this.marketDataUpdated = true;
		}
	}

	async poll() {
		await this.updateMarketData();
		const currentTime = DateTime.now();
		this.scheduledPoll = later(this, this.poll.bind(this), this.pollFrequency);
		this.lastPolledAt = currentTime;
	}

	async registerHistoricalRange(symbol: string) {
		next(async () => {
			if (symbol === null) {
				return;
			}
			const symbolPrefix = symbol.slice(0, -2);
			if (!this.historicalSubscriptions.has(symbolPrefix)) {
				this.historicalSubscriptions.set(symbolPrefix, new TrackedMap());

				const settlementPrices = await this.getHistoricalSettlements(symbolPrefix);

				this.historicalSubscriptions.get(symbolPrefix)?.set('settlements', settlementPrices);
				this.marketDataUpdated = true;
			}
		});
	}

	getMarketDataHistoricalRange(symbol: any) {
		if (symbol) {
			const symbolPrefix = symbol.slice(0, -2);
			return this.historicalSubscriptions.get(symbolPrefix);
		} else {
			return null;
		}
	}

	async getHistoricalSettlements(symbolPrefix: any) {
		const currentYear = DateTime.local().year - 2000;
		const symbols = [];
		for (let i = 0; i < 15; i++) {
			symbols.push(symbolPrefix + Intl.NumberFormat('us-en', { minimumIntegerDigits: 2 }).format(currentYear - i));
		}

    const apiKey: string = ENV.barchartApiToken;
		const res = await fetch(
			`https://ondemand.websol.barchart.com/getQuote.json?apikey=${apiKey}&symbols=${symbols.join(
				','
			)}&fields=expirationDate`,
			{
				method: 'GET',
				headers: {},
			}
		);

		const historicalSettlements = (await res.json())?.results ?? [];
		const filteredHistoricalSettlements = historicalSettlements.filter((settlement: any) => {
			return DateTime.fromISO(settlement.expirationDate).diffNow('seconds').seconds < 0;
		});

		const orderedHistoricalSettlements = filteredHistoricalSettlements.sortBy('expirationDate').reverse();
		return orderedHistoricalSettlements;
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'market-data-service': MarketDataService;
	}
}
