import CropPlansShowController from 'vault-client/controllers/crop-plans/show';

export default class OrganizationsOrganizationCropPlan extends CropPlansShowController {
	cropPlansRoutePath: string = 'organizations.organization.crop-plans';
	transactionsRoutePath: string = 'organizations.organization.transactions';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'organizations/organization/crop-plan': OrganizationsOrganizationCropPlan;
	}
}
