import UnmappedInsurancePoliciesController from 'vault-client/controllers/reports/unmapped-insurance-policies';

export default class OrganizationsOrganizationUnmappedInsurancePoliciesController extends UnmappedInsurancePoliciesController {
	reportsRoutePath: string = 'organizations.organization.reports';
	insurancePolicyRoutePath: string = 'organizations.organization.insurance-policy';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'organizations/organization/unmapped-insurance-policies': OrganizationsOrganizationUnmappedInsurancePoliciesController;
	}
}
