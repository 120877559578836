import Route from '@ember/routing/route';
import { tracked } from '@glimmer/tracking';
import { gql, useQuery } from 'glimmer-apollo';
import {
	AllocatedForecastedHedgedAndCappedVolumeFilterDTO,
	ForecastedSwineLivestockFeedUsageFilterDTO,
	ProductFilterDTO,
	Query,
	TypeOfAllocatedHedge,
	TypeOfInstrument,
	TypeOfOption,
} from 'vault-client/types/graphql-types';
import { FeedIngredientBase } from 'vault-client/types/vault-client';

const FEED_OVERVIEW_DETAILS_QUERY = gql`
	query LivestockAggregateFeedIngredientForecastedUsages(
		$livestockFeedIngredientsWhere: ForecastedSwineLivestockFeedUsageFilterDTO
		$forecastedHedgedAndCappedVolumeWhere: AllocatedForecastedHedgedAndCappedVolumeFilterDTO
		$productsWhere: ProductFilterDTO
	) {
		AggregateForecastedSwineLivestockFeedUsages(
			where: $livestockFeedIngredientsWhere
			calc: { sum: { quantityInLbs: true } }
			groupBy: {
				firstDateOfMonth: true
				Ingredient: { id: true, dryMatterPercent: true, FeedCategory: { id: true, HedgeProduct: { id: true, slug: true } } }
			}
		) {
			firstDateOfMonth
			sum {
				quantityInLbs
			}
			Ingredient {
				id
				dryMatterPercent
				FeedCategory {
					id
					HedgeProduct {
						id
						slug
					}
				}
			}
		}
		AggregateAllocatedForecastedHedgedAndCappedVolumes(
			calc: { sum: { naturallyShortHedged: true } }
			groupBy: { instrumentType: true, date: true, optionType: true, Product: { id: true, slug: true } }
			where: $forecastedHedgedAndCappedVolumeWhere
		) {
			optionType
			instrumentType
			date
			Product {
				id
				slug
			}
			sum {
				naturallyShortHedged
			}
		}
		Products(where: $productsWhere) {
			id
			slug
		}
	}
`;

type feedOverviewDetailsQuery = {
	__typename?: 'Query';
	AggregateForecastedSwineLivestockFeedUsages: Query['AggregateForecastedSwineLivestockFeedUsages'];
	AggregateAllocatedForecastedHedgedAndCappedVolumes: Query['AggregateAllocatedForecastedHedgedAndCappedVolumes'];
	Products: Query['Products'];
};

type FeedOverviewDetailsQuery_Args = {
	livestockFeedIngredientsWhere?: ForecastedSwineLivestockFeedUsageFilterDTO;
	forecastedHedgedAndCappedVolumeWhere?: AllocatedForecastedHedgedAndCappedVolumeFilterDTO;
	productsWhere?: ProductFilterDTO;
};

export default class LivestockFeedOverviewDetailRoute extends Route {
	queryParams = {
		startDate: { refreshModel: true },
		endDate: { refreshModel: true },
	};

	@tracked variables: FeedOverviewDetailsQuery_Args = {};

	// See `update-feed-usage-button` for comment on fetchPolicy
	getFeedDetails = useQuery<feedOverviewDetailsQuery, FeedOverviewDetailsQuery_Args>(this, () => [
		FEED_OVERVIEW_DETAILS_QUERY,
		{
			variables: this.variables,
			fetchPolicy: 'network-only',
		},
	]);

	templateName = 'livestock-feed/overview/detail';

	generateProductsWhere(ingredientBase: FeedIngredientBase): ProductFilterDTO {
		let productSlug: string | null = null;

		if (ingredientBase === FeedIngredientBase.Corn) {
			productSlug = 'grain-corn';
		} else if (ingredientBase === FeedIngredientBase.SoybeanMeal) {
			productSlug = 'grain-soybean-meal';
		}

		return {
			slug: {
				equals: productSlug,
			},
		};
	}

	generateForecastedLivestockFeedIngredientsWhere(
		ingredientBase: FeedIngredientBase,
		businessId: string,
		startDate: string,
		endDate: string
	): ForecastedSwineLivestockFeedUsageFilterDTO {
		const where: ForecastedSwineLivestockFeedUsageFilterDTO = {};
		where.date = { gt: startDate, lt: endDate };
		where.businessId = { equals: businessId };
		switch (ingredientBase) {
			case FeedIngredientBase.Corn:
				where.Ingredient = {
					FeedCategory: {
						HedgeProduct: {
							slug: {
								equals: 'grain-corn',
							},
						},
					},
				};
				break;

			case FeedIngredientBase.SoybeanMeal:
				where.Ingredient = {
					FeedCategory: {
						HedgeProduct: {
							slug: {
								equals: 'grain-soybean-meal',
							},
						},
					},
				};
				break;

			default:
				where.Ingredient = {
					FeedCategory: {
						HedgeProduct: {
							AND: [
								{
									slug: {
										not: {
											equals: 'grain-corn',
										},
									},
								},
								{
									slug: {
										not: {
											equals: 'grain-soybean-meal',
										},
									},
								},
							],
						},
					},
				};
		}

		return where;
	}

	generateForecastedHedgedAndCappedVolumeWhere(
		ingredientBase: FeedIngredientBase,
		businessId: string,
		startDate: string,
		endDate: string
	): AllocatedForecastedHedgedAndCappedVolumeFilterDTO {
		let productSlug: string | null = null;

		if (ingredientBase === FeedIngredientBase.Corn) {
			productSlug = 'grain-corn';
		} else if (ingredientBase === FeedIngredientBase.SoybeanMeal) {
			productSlug = 'grain-soybean-meal';
		}

		const forecastedHedgedAndCappedVolumeWhere: AllocatedForecastedHedgedAndCappedVolumeFilterDTO = {
			entityId: {
				equals: businessId,
			},
			hedgeType: {
				equals: TypeOfAllocatedHedge.Brokerage,
			},
			instrumentType: {
				in: [TypeOfInstrument.Future, TypeOfInstrument.Option],
			},
			OR: [
				{
					optionType: {
						equals: null,
					},
				},
				{
					optionType: {
						equals: TypeOfOption.Call,
					},
				},
			],
			date: {
				gte: startDate,
				lte: endDate,
			},
		};

		if (productSlug) {
			forecastedHedgedAndCappedVolumeWhere.Product = {
				slug: {
					equals: productSlug,
				},
			};
		} else {
			forecastedHedgedAndCappedVolumeWhere.productId = { equals: null };
		}

		return forecastedHedgedAndCappedVolumeWhere;
	}
}
