/** Returns true if the two arrays contain the same elements, in the same order. Returns false otherwise */
export default function arraysEqual(a: unknown[], b: unknown[]) {
	if (a === b) return true;
	if (a == null || b == null) return false;
	if (a.length !== b.length) return false;

	for (let i = 0; i < a.length; ++i) {
		if (a[i] !== b[i]) return false;
	}
	return true;
}
