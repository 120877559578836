import Route from '@ember/routing/route';
import query from 'vault-client/graphql/queries/insurance-policies/show.graphql';
import { queryManager } from 'ember-apollo-client';
export default class InsurancePoliciesShowRoute extends Route {
	@queryManager apollo: any;

	templateName: string = 'insurance-policies/show';

	async model(params: { policy_id: string }) {
		const variables = {
			id: params.policy_id,
		};

		return this.apollo.watchQuery({ query, variables });
	}
}
