import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<PowerSelect\n\t@selected={{this.selectedAccount}}\n\t@disabled={{@disabled}}\n\t@options={{this.accounts}}\n\t@searchEnabled={{true}}\n\t@allowClear={{true}}\n\t@searchField='name'\n\t@placeholder={{this.placeholder}}\n\t@onChange={{@onChange}}\n\t@renderInPlace={{@renderInPlace}}\n\tas |account|\n>\n\t{{account.name}}\n\t{{#if @showAccountNumber}}\n\t\t({{account.accountNumber}})\n\t{{/if}}\n</PowerSelect>", {"contents":"<PowerSelect\n\t@selected={{this.selectedAccount}}\n\t@disabled={{@disabled}}\n\t@options={{this.accounts}}\n\t@searchEnabled={{true}}\n\t@allowClear={{true}}\n\t@searchField='name'\n\t@placeholder={{this.placeholder}}\n\t@onChange={{@onChange}}\n\t@renderInPlace={{@renderInPlace}}\n\tas |account|\n>\n\t{{account.name}}\n\t{{#if @showAccountNumber}}\n\t\t({{account.accountNumber}})\n\t{{/if}}\n</PowerSelect>","moduleName":"vault-client/components/account-select.hbs","parseOptions":{"srcName":"vault-client/components/account-select.hbs"}});
import Component from '@glimmer/component';
import { gql, useQuery } from 'glimmer-apollo';
import { Account, Query, Query_AccountsArgs, TypeOfAccount } from 'vault-client/types/graphql-types';

const GET_ACCOUNTS = gql`
	query Accounts($where: AccountFilterDTO) {
		Accounts(orderBy: { name: Asc }, where: $where) {
			id
			name
			accountNumber
		}
	}
`;

interface AccountSelectArgs {
	onChange: (account: Account) => void;
	selected: Account | string;
	accountTypesToInclude?: TypeOfAccount[];
	customerId?: string;
	placeholder?: string;
	showAccountNumber?: boolean;
	accountIdsToExclude?: string[];
}

export default class AccountSelect extends Component<AccountSelectArgs> {
	getAccounts = useQuery<{ Accounts: Query['Accounts'] }, Query_AccountsArgs>(this, () => [
		GET_ACCOUNTS,
		{
			variables: this.variables,
			onError: (error) => {
				console.error('Error on Account select', error.message);
			},
		},
	]);

	get placeholder() {
		return this.args.placeholder ?? 'Select an account';
	}

	get variables(): Query_AccountsArgs {
		return {
			where: this.where,
		};
	}

	get where(): Query_AccountsArgs['where'] {
		return {
			id: {
				notIn: this.excludedAccountIds,
			},
			...(this.args.accountTypesToInclude && {
				type: {
					in: this.args.accountTypesToInclude,
				},
			}),
			...(this.args.customerId && {
				customerId: {
					equals: this.args.customerId,
				},
			}),
		};
	}

	get excludedAccountIds() {
		return this.args.accountIdsToExclude || [];
	}

	get accounts() {
		return this.getAccounts.data?.Accounts || [];
	}

	get selectedAccount() {
		if (typeof this.args.selected === 'string') {
			return this.accounts.find((account) => account.id === this.args.selected) ?? null;
		} else {
			return this.args.selected;
		}
	}
}
