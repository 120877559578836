import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { queryManager } from 'ember-apollo-client';
import query from 'vault-client/graphql/queries/location-get.graphql';
import { LocationEntity } from 'vault-client/types/graphql-types';

export default class LocationsLocation extends Route {
	@service applicationScope: any;
	@queryManager apollo: any;

	async model(params: { id: string }): Promise<{ Location: LocationEntity }> {
		const response: { Location: LocationEntity } = await this.apollo.watchQuery({ query, variables: { id: params.id } });

		this.applicationScope.organizationId = response.Location.Customer?.organizationId;
		this.applicationScope.globalCustomerId = null;
		this.applicationScope.locationId = params.id;

		return response;
	}
}
