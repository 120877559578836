import Route from '@ember/routing/route';
import { tracked } from '@glimmer/tracking';
import { gql, useQuery } from 'glimmer-apollo';
import { DateTime } from 'luxon';
import { Maybe } from 'vault-client/types/graphql-types';

import {
	GrainTargetOrder,
	GrainTargetOrderSortByDTO,
	GrainTargetOrderFilterDTO,
	SortByDirection,
	TypeOfGrainOrderSales,
	TypeOfGrainOrderStatus,
	TypeOfGrainPlan,
	Count,
} from 'vault-client/types/graphql-types';

type SortObj = {
	valuePath: string;
	isAscending: boolean;
};

export const GET_TARGETS = gql`
	query Targets($limit: Float, $offset: Float, $orderBy: GrainTargetOrderSortByDTO, $where: GrainTargetOrderFilterDTO) {
		GrainTargetOrders(orderBy: $orderBy, where: $where, limit: $limit, offset: $offset) {
			id
			bushels
			futuresMonth
			deliveryStartDate
			deliveryEndDate
			deliveryMonth
			salesType
			futurePrice
			fees
			spread
			basis
			cropFlatPrice
			expirationDate
			contractNumber
			planId
			status
			updatedAt
			createdAt
			shortNote
			BuyerRelationsOwner {
				email
				firstName
				id
				lastName
				roles
			}
			StatusUpdates {
				id
				updatedAt
			}

			Activity {
				id
				type
				performedAt
				data
				GrainPlan {
					id
					type
				}
			}
			Buyer {
				id
				name
			}
			Seller {
				id
				name
			}
			Location {
				id
				name
			}
			Plan {
				type
				... on GrainCropPlan {
					id
					cropId
					customerId
					hasWriteAccess
					Customer {
						id
						name
						RelationshipOwner {
							id
							firstName
							lastName
						}
					}
					Crop {
						id
						name
					}
					CropYear {
						id
						year
					}
				}
			}
		}
		GrainTargetOrderCount(where: $where) {
			count
		}
	}
`;

export type GetTargets = {
	__typename?: 'Query';
	GrainTargetOrderCount: Count;
	GrainTargetOrders: Array<GrainTargetOrder>;
};

interface QueryParams {
	page: number;
	size: number;
	sorts: SortObj[];
	grainAdvisorId: string;
	brOwnerId: string;
	customerId: string;
	cropCategoryId: string;
	buyerId: string;
	grainLocationId: string;
	status: TypeOfGrainOrderStatus;
	selectedSalesTypes: TypeOfGrainOrderSales[];
	cropYearStart: number;
	cropYearEnd: number;
	updatedAtStart: string;
	updatedAtEnd: string;
	futuresMonthStart: string;
	futuresMonthEnd: string;
	deliveryStartDateStart: string;
	deliveryStartDateEnd: string;
	deliveryEndDateStart: string;
	deliveryEndDateEnd: string;
}

export type Query_TargetsArgs = {
	limit?: Maybe<number>;
	offset?: Maybe<number>;
	orderBy?: Maybe<[GrainTargetOrderSortByDTO]>;
};

export default class VgsGrainServicesTargetsIndex extends Route {
	@tracked variables: any = {};

	queryParams = {
		page: { refreshModel: true },
		sorts: { refreshModel: true },
		size: { refreshModel: true },
		brOwnerId: { refreshModel: true },
		grainAdvisorId: { refreshModel: true },
		customerId: { refreshModel: true },
		cropCategoryId: { refreshModel: true },
		buyerId: { refreshModel: true },
		grainLocationId: { refreshModel: true },
		status: { refreshModel: true },
		selectedSalesTypes: { refreshModel: true },
		cropYearStart: { refreshModel: true },
		cropYearEnd: { refreshModel: true },
		updatedAtStart: { refreshModel: true },
		updatedAtEnd: { refreshModel: true },
		futuresMonthStart: { refreshModel: true },
		futuresMonthEnd: { refreshModel: true },
		deliveryStartDateStart: { refreshModel: true },
		deliveryStartDateEnd: { refreshModel: true },
		deliveryEndDateStart: { refreshModel: true },
		deliveryEndDateEnd: { refreshModel: true },
	};

	getTargets = useQuery<GetTargets, Query_TargetsArgs>(this, () => [GET_TARGETS, { variables: this.variables, pollInterval: 10000 }]);

	generateOrderBy(sorts: SortObj[]) {
		const orderBy: {
			[key: string]: any;
		} = {};

		sorts.forEach((sort: any) => {
			const parts = sort.valuePath.split('.');
			const firstPart = parts.shift();

			let value: any = sort.isAscending ? SortByDirection.Asc : SortByDirection.Desc;

			while (parts.length > 0) {
				const part = parts.pop();
				const formattedPart = ((part: string) => {
					if (part === 'Crop') {
						return 'CropCategory';
					} else {
						return part;
					}
				})(part);

				const obj: {
					[key: string]: any;
				} = {};
				obj[formattedPart] = value;
				value = obj;
			}

			if (firstPart === 'CropPlan') {
				orderBy.Plan = { AsGrainCropPlan: { ...value } };
			} else {
				orderBy[firstPart] = value;
			}
		});

		return orderBy;
	}

	generateWhere(params: QueryParams) {
		// Convert date ranges to null if they use the default values.
		const futuresMonthStart = params.futuresMonthStart !== '1900-01-01' ? params.futuresMonthStart : null;
		const futuresMonthEnd = params.futuresMonthEnd !== '2999-12-31' ? params.futuresMonthEnd : null;
		const deliveryStartDateStart = params.deliveryStartDateStart !== '1900-01-01' ? params.deliveryStartDateStart : null;
		const deliveryStartDateEnd = params.deliveryStartDateEnd !== '2999-12-31' ? params.deliveryStartDateEnd : null;
		const deliveryEndDateStart = params.deliveryEndDateStart !== '1900-01-01' ? params.deliveryEndDateStart : null;
		const deliveryEndDateEnd = params.deliveryEndDateEnd !== '2999-12-31' ? params.deliveryEndDateEnd : null;
		const updatedAtStart = params.updatedAtStart !== '1900-01-01' ? DateTime.fromISO(params.updatedAtStart).startOf('day').toISO() : null;
		const updatedAtEnd = params.updatedAtEnd !== '2999-12-31' ? DateTime.fromISO(params.updatedAtEnd).endOf('day').toISO() : null;
		const where: GrainTargetOrderFilterDTO = {};
		const cropYearStart = params.cropYearStart !== 1900 ? params.cropYearStart : null;
		const cropYearEnd = params.cropYearEnd !== 2999 ? params.cropYearEnd : null;

		if (params.brOwnerId) {
			where.BuyerRelationsOwner = {
				id: { equals: params.brOwnerId },
			};
		}

		if (params.buyerId) {
			where.Buyer = {
				id: {
					equals: params.buyerId,
				},
			};
		}

		if (params.grainLocationId) {
			where.Location = {
				id: {
					equals: params.grainLocationId,
				},
			};
		}

		if (params.status) {
			where.status = {
				equals: params.status,
			};
		}
		if (params.selectedSalesTypes?.length) {
			where.salesType = {
				in: params.selectedSalesTypes,
			};
		}

		if (updatedAtStart && updatedAtEnd) {
			where.OR = [
				...(where.OR || []),
				{
					createdAt: {
						...(updatedAtStart && { gte: updatedAtStart }),
						...(updatedAtEnd && { lte: updatedAtEnd }),
					},
				},
				{
					StatusUpdates: {
						updatedAt: {
							...(updatedAtStart && { gte: updatedAtStart }),
							...(updatedAtEnd && { lte: updatedAtEnd }),
						},
					},
				},
			];
		}

		if (futuresMonthStart && futuresMonthEnd) {
			where.futuresMonth = {
				...(futuresMonthStart && { gte: futuresMonthStart }),
				...(futuresMonthEnd && { lte: futuresMonthEnd }),
			};
		}

		if (deliveryStartDateStart && deliveryStartDateEnd) {
			where.deliveryStartDate = {
				...(deliveryStartDateStart && { gte: deliveryStartDateStart }),
				...(deliveryStartDateEnd && { lte: deliveryStartDateEnd }),
			};
		}

		if (deliveryEndDateStart && deliveryEndDateEnd) {
			where.deliveryEndDate = {
				...(deliveryEndDateStart && { gte: deliveryEndDateStart }),
				...(deliveryEndDateEnd && { lte: deliveryEndDateEnd }),
			};
		}

		if (params.grainAdvisorId || params.customerId || params.cropCategoryId || (cropYearStart && cropYearEnd)) {
			where.Plan = {
				...((params.grainAdvisorId || params.customerId) && {
					Customer: {
						...(params.grainAdvisorId && { relationshipOwnerId: { equals: params.grainAdvisorId } }),
						...(params.customerId && { id: { equals: params.customerId } }),
					},
				}),

				...(params.cropCategoryId && { cropId: { equals: params.cropCategoryId } }),

				...(cropYearStart &&
					cropYearEnd && {
						AsGrainCropPlan: {
							CropYear: {
								year: {
									...(cropYearStart && { gte: cropYearStart }),
									...(cropYearEnd && { lte: cropYearEnd }),
								},
							},
						},
					}),
			};
		}
		where.Plan = { ...where.Plan, type: { equals: TypeOfGrainPlan.Crop } };

		return where;
	}

	async model(params: QueryParams) {
		this.variables = {
			orderBy: this.generateOrderBy(params.sorts),
			offset: params.page * params.size,
			limit: params.size,
			where: this.generateWhere(params),
		};

		await this.getTargets.promise;
		return { getTargets: this.getTargets, variables: this.variables };
	}
}
