/**
 * Array of rgb colors
 * @param colorsArray
 * @param return
 */
const colorsArray = [
	'rgb(70,130,180)',
	'rgb(75,0,130)',
	'rgb(112,128,144)',
	'rgb(0,0,128',
	'rgb(255,228,196)',
	'rgb(244,164,96)',
	'rgb(255,105,180)',
	'rgb(30,144,255)',
	'rgb(255,140,0',
	'rgb(0,139,139)',
	'rgb(255,215,0)',
	'rgb(240,255,240)',
	'rgb(139,69,19)',
	'rgb(64,224,208)',
	'rgb(188,143,143)',
	'rgb(230,230,250)',
	'rgb(147,112,219)',
	'rgb(205,133,63)',
	'rgb(255,235,205)',
	'rgb(189,183,107)',
];

export { colorsArray };
