import Controller from '@ember/controller';
import { DateTime, Interval } from 'luxon';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import resetVaultTableScroll from 'vault-client/utils/reset-vault-table-scroll';
import { TableColumn, CellComponents } from 'vault-client/types/vault-table';
import { v5 as uuid } from 'uuid';
import { camelize, classify } from '@ember/string';
import {
	AggregateForecastedMilkProductionByMonthDTO,
	LedgerMilkCheckCategory,
	LedgerMilkCheckCategoryCreateDTO,
	Mutation_createLedgerMilkCheckCategoryArgs,
	NumericAggregateLedgerEntryDTO,
	TypeOfLedgerCalculation,
	TypeOfLedgerEntry,
	AggregateActualMilkProductionDTO,
} from 'vault-client/types/graphql-types';
import { gql, useMutation } from 'glimmer-apollo';
import BusinessesBusinessMilkPremiumsRoute from 'vault-client/routes/businesses/business/milk-premiums';
import { ModelFrom } from 'vault-client/utils/type-utils';
type AggregateLedgerRow = {
	month: string | number;
	// Procedurally generated keys based on ledger category name
	[key: string]: string | number;
};

interface DateFilterOption {
	displayName: string;
	startDate: string | null;
	endDate: string | null;
}

interface Entry {
	name: string;
	year: number;
	LedgerCategory: LedgerMilkCheckCategory;
	sum: NumericAggregateLedgerEntryDTO;
	month: number;
	type: string;
	avg: {
		amount: number;
	};
}

function* months(interval: Interval) {
	let cursor = interval.start.startOf('month');
	while (cursor < interval.end) {
		yield cursor;
		cursor = cursor.plus({ month: 1 });
	}
}

const CREATE_LEDGER_MILK_CHECK_CATEGORY = gql`
	mutation CreateLedgerMilkCheckCategory($data: LedgerMilkCheckCategoryCreateDTO!) {
		createLedgerMilkCheckCategory(data: $data) {
			id
			name
		}
	}
`;

type CreateLedgerMilkCheckCategoryMutation = {
	__typename?: 'Mutation';

	createLedgerMilkCheckCategory?: {
		data: LedgerMilkCheckCategoryCreateDTO;
	} | null;
};
export default class BusinessesBusinessMilkPremiums extends Controller {
	declare model: ModelFrom<BusinessesBusinessMilkPremiumsRoute>;

	@tracked startDate: string | null = DateTime.local().startOf('year').toISODate();
	@tracked endDate: string | null = DateTime.local().endOf('year').toISODate();
	@tracked selectedRows: any[] = [];
	@tracked categoryName: string = '';
	@tracked dynamicUnit: string | null = null;

	selectableUnits = ['Static', 'Per CWT'];
	uuidNamespace = 'b8520383-3afc-4498-aa3b-eb5cbd533dc6';
	operationsRoutePath = 'businesses.business.operations';

	get showUpdateValuesButton() {
		return this.selectedRows.length > 0;
	}

	get columns(): TableColumn[] {
		const milkCheckEntries: TableColumn[] = (
			this.model.getMilkPremiumsAndDeductions.data?.LedgerMilkCheckCategories as LedgerMilkCheckCategory[]
		)
			.map((LedgerMilkCheckCategory) => {
				const isPerCWT = LedgerMilkCheckCategory?.calculationType === 'DairyCwt';

				const subcolumns = isPerCWT
					? [
							{
								id: uuid(LedgerMilkCheckCategory.id, this.uuidNamespace),
								name: 'Forecasted (CWT)',
								textAlign: 'right',
								width: 140,
								valuePath: camelize(LedgerMilkCheckCategory.id + ' Forecasted Cwt'),
								cellComponent: CellComponents.IntlNumberFormat,
								componentArgs: {
									style: 'currency',
									currency: 'USD',
									currencySign: 'accounting',
							},							
								isSortable: false,
								isFixed: '',
								isVisible: true,
								csvMaxFractionDigits: 30
							},
							{
								id: uuid(LedgerMilkCheckCategory.id, this.uuidNamespace),
								name: 'Forecasted (Total)',
								textAlign: 'right',
								width: 160,
								valuePath: camelize(LedgerMilkCheckCategory.id + ' Forecasted Total'),
								cellComponent: CellComponents.IntlNumberFormat,
								componentArgs: {
									style: 'currency',
									currency: 'USD',
									currencySign: 'accounting',
								},
								isSortable: false,
								isTotaled: true,
								isFixed: '',
								isVisible: true,
								csvMaxFractionDigits: 30
							},
							{
								id: uuid(LedgerMilkCheckCategory.id, this.uuidNamespace),
								name: 'Actual (CWT)',
								textAlign: 'right',
								width: 130,
								valuePath: camelize(LedgerMilkCheckCategory.id + ' Actual Cwt'),
								cellComponent: CellComponents.IntlNumberFormat,
								componentArgs: {
									style: 'currency',
									currency: 'USD',
									currencySign: 'accounting',
								},
								isSortable: false,
								isTotaled: true,
								isFixed: '',
								isVisible: true,
								csvMaxFractionDigits: 30
							},
							{
								id: uuid(LedgerMilkCheckCategory.id, this.uuidNamespace),
								name: 'Actual (Total)',
								textAlign: 'right',
								width: 130,
								valuePath: camelize(LedgerMilkCheckCategory.id + ' Actual Total'),
								cellComponent: CellComponents.IntlNumberFormat,
								componentArgs: {
									style: 'currency',
									currency: 'USD',
									currencySign: 'accounting',
								},
								isSortable: false,
								isTotaled: true,
								isFixed: '',
								isVisible: true,
								csvMaxFractionDigits: 30
							},
					  ]
					: [
							{
								id: uuid(LedgerMilkCheckCategory.id, this.uuidNamespace),
								name: 'Forecasted',
								textAlign: 'right',
								width: 110,
								valuePath: camelize(LedgerMilkCheckCategory.id + ' Forecasted'),
								cellComponent: CellComponents.IntlNumberFormat,
								componentArgs: {
									style: 'currency',
									currency: 'USD',
									currencySign: 'accounting',
								},
								isSortable: false,
								isTotaled: true,
								isFixed: '',
								isVisible: true,
								csvMaxFractionDigits: 30
							},
							{
								id: uuid(LedgerMilkCheckCategory.id, this.uuidNamespace),
								name: 'Actual',
								textAlign: 'right',
								width: 110,
								valuePath: camelize(LedgerMilkCheckCategory.id + ' Actual'),
								cellComponent: CellComponents.IntlNumberFormat,
								componentArgs: {
									style: 'currency',
									currency: 'USD',
									currencySign: 'accounting',
								},
								isSortable: false,
								isTotaled: true,
								isFixed: '',
								isVisible: true,
								csvMaxFractionDigits: 30
							},
					  ];
				return {
					id: uuid(LedgerMilkCheckCategory.id, this.uuidNamespace),
					name: LedgerMilkCheckCategory.name,
					cellComponent: CellComponents.String,
					minWidth: 110,
					textAlign: 'center',
					isSortable: false,
					componentArgs: {
						style: 'currency',
						currency: 'USD',
						currencySign: 'accounting',
					},
					isFixed: '',
					isVisible: true,
					isTotaled: true,
					subcolumns,
				};
			})
			.sortBy('name');

		return [
			{
				id: 'f0bbbe46-6233-4159-b13d-7a1f7635a0b9',
				name: 'Month',
				valuePath: 'month',
				minWidth: 150,
				textAlign: 'left',
				isSortable: true,
				isReorderable: false,
				cellComponent: CellComponents.MonthFormat,
				isFixed: 'left',
				isVisible: true,
			},
			...milkCheckEntries,
			{
				id: '0226df7d-4d87-4b9f-a383-82435f09cb64',
				name: 'Total',
				minWidth: 150,
				textAlign: 'center',
				isSortable: false,
				isReorderable: false,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				isFixed: 'right',
				isVisible: true,
				isTotaled: true,
				csvMaxFractionDigits: 30,
				subcolumns: [
					{
						id: '1848b039-fc35-4d98-af2c-900f0c0d1e85',
						name: 'Forecasted',
						valuePath: 'forecastedTotal',
						textAlign: 'right',
						width: 130,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							currencySign: 'accounting',
						},
						isSortable: false,
						isTotaled: true,
						isFixed: '',
						isVisible: true,
						csvMaxFractionDigits: 30
					},
					{
						id: 'd321be24-410e-4985-8469-d052438e2537',
						name: 'Forecasted (CWT)',
						valuePath: 'forecastedTotalCwt',
						textAlign: 'right',
						width: 140,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							currencySign: 'accounting',
						},
						isSortable: false,
						isTotaled: true,
						isFixed: '',
						isVisible: true,
						csvMaxFractionDigits: 30
					},
					{
						id: 'e70b1ba1-910e-44d3-a923-c28a42c4c045',
						name: 'Actual',
						valuePath: 'actualTotal',
						textAlign: 'right',
						width: 130,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							currencySign: 'accounting',
						},
						isFixed: '',
						isSortable: false,
						isTotaled: true,
						isVisible: true,
						csvMaxFractionDigits: 30
					},
					{
						id: '125fd791-aad1-4a16-bc3f-0e4abb526c3b',
						name: 'Actual (CWT)',
						valuePath: 'actualTotalCwt',
						textAlign: 'right',
						width: 140,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							currencySign: 'accounting',
						},
						isSortable: false,
						isTotaled: true,
						isFixed: '',
						isVisible: true,
						csvMaxFractionDigits: 30
					},
				],
			},
		];
	}

	queryParams = ['startDate', 'endDate'];

	initializeRows = (entries: Entry[] | undefined, map: Map<string, AggregateLedgerRow>): void => {
		entries &&
			entries.forEach((entry) => {
				const date = DateTime.local(entry.year, entry.month).toISODate();
				if (!map.get(date)) {
					map.set(date, { month: date, id: date });
				}
			});
	};

	populateRows = (
		entries: Entry[] | undefined,
		monthlyProductionForecasted: AggregateForecastedMilkProductionByMonthDTO[],
		monthlyProductionActual: AggregateActualMilkProductionDTO[],
		map: Map<string, AggregateLedgerRow>
	): void => {
		if (!entries) {
			return;
		}

		entries.forEach((entry) => {
			const date = DateTime.local(entry.year, entry.month).toISODate();
			let row = map.get(date);

			if (!row) {
				row = { month: date, id: date };
				map.set(date, row);
			}

			const entryTotalAmount = entry.sum.calculatedAmount;
			const cwtAmount = entry.avg.amount;

			if (entry.type === 'Actual') {
				const actualValuePath = `${camelize(entry.LedgerCategory?.id + ' Actual')}`;
				const actualCwtValuePath = `${camelize(entry.LedgerCategory?.id + ' Actual Cwt')}`;
				const actualCwtTotalValuePath = camelize(entry.LedgerCategory?.id + ' Actual Total');
				const actualTotalValuePath = 'actualTotal';
				const actualTotalCwtValuePath = 'actualTotalCwt';
				const production = monthlyProductionActual.find((v) => v.firstDateOfMonth === date)?.sum.grossProduction ?? 0;

				if (typeof entryTotalAmount !== 'number') return;
				if (entry.LedgerCategory?.calculationType === 'DairyCwt') {
					row[actualCwtValuePath] = cwtAmount;
					row[actualCwtTotalValuePath] = entryTotalAmount;
				} else {
					row[actualValuePath] = entryTotalAmount;
				}

				if (typeof row[actualTotalValuePath] === 'number') {
					row[actualTotalValuePath] += entryTotalAmount;
				} else {
					row[actualTotalValuePath] = entryTotalAmount;
				}

				row[actualTotalCwtValuePath] = production && row[actualTotalValuePath] ? row[actualTotalValuePath] / (production / 100) : 0;
			} else {
				const forecastedValuePath = `${camelize(entry.LedgerCategory?.id + ' Forecasted')}`;
				const forecastedCwtValuePath = `${camelize(entry.LedgerCategory?.id + ' Forecasted Cwt')}`;
				const forecastedCwtTotalValuePath = camelize(entry.LedgerCategory?.id + ' Forecasted Total');
				const forecastedTotalValuePath = 'forecastedTotal';
				const forecastedTotalCwtValuePath = 'forecastedTotalCwt';
				const production = monthlyProductionForecasted.find((v) => v.date === date)?.sum.grossProduction ?? 0;

				if (typeof entryTotalAmount === 'number') {
					if (entry.LedgerCategory?.calculationType === 'DairyCwt') {
						row[forecastedCwtValuePath] = cwtAmount;
						row[forecastedCwtTotalValuePath] = entryTotalAmount;
					} else {
						row[forecastedValuePath] = entryTotalAmount;
					}

					if (typeof row[forecastedTotalValuePath] === 'number') {
						row[forecastedTotalValuePath] += entryTotalAmount;
					} else {
						row[forecastedTotalValuePath] = entryTotalAmount;
					}

					row[forecastedTotalCwtValuePath] =
						production && row[forecastedTotalValuePath] ? row[forecastedTotalValuePath] / (production / 100) : 0;
				}
			}
		});
	};

	fillMissingMonths = (startDate: string | null, endDate: string | null, map: Map<string, AggregateLedgerRow>): void => {
		const interval = Interval.fromISO(`${startDate}/${endDate}`);
		const currentMonths: { [key: string]: number | undefined } = {};

		for (const currentMonth of map.keys()) {
			const date = DateTime.fromISO(currentMonth).startOf('month').toISODate();
			currentMonths[date] = 1;
		}

		for (const date of months(interval)) {
			if (!currentMonths[date.toISODate()]) {
				map.set(date.toISODate(), { month: date.toISODate(), id: date.toISODate() });
			}
		}
	};

	get data(): AggregateLedgerRow[] {
		const map = new Map<string, AggregateLedgerRow>();
		const entries: Entry[] | undefined = this.model.getMilkPremiumsAndDeductions?.data?.MilkPremiumAndDeductionEntries;
		const monthlyProductionForecasted = this.model.getMilkPremiumsAndDeductions.data?.AggregateForecastedMilkProductionByMonths ?? [];
		const monthlyProductionActual = this.model.getMilkPremiumsAndDeductions.data?.AggregateActualMilkProduction ?? [];

		this.initializeRows(entries, map);
		this.populateRows(entries, monthlyProductionForecasted, monthlyProductionActual, map);
		this.fillMissingMonths(this.startDate, this.endDate, map);

		return Array.from(map.values()).sortBy('month');
	}

	get monthlyProductionForecasted() {
		return this.model.getMilkPremiumsAndDeductions.data?.AggregateForecastedMilkProductionByMonths ?? [];
	}

	get monthlyProductionActual() {
		return this.model.getMilkPremiumsAndDeductions.data?.AggregateActualMilkProduction ?? [];
	}

	get columnTotals() {
		const totals: { [key: string]: number } = {};

		const actualTotalValuePath = 'actualTotal';
		const actualTotalCwtValuePath = 'actualTotalCwt';
		const forecastedTotalValuePath = 'forecastedTotal';
		const forecastedTotalCwtValuePath = 'forecastedTotalCwt';

		const categoryCwtValues: { [key: string]: number[] } = {};
		const totalRowTotalValues: {
			[actualTotalValuePath]: number | null;
			[actualTotalCwtValuePath]: number[];
			[forecastedTotalValuePath]: number | null;
			[forecastedTotalCwtValuePath]: number[];
		} = {
			[actualTotalValuePath]: null,
			[actualTotalCwtValuePath]: [],
			[forecastedTotalValuePath]: null,
			[forecastedTotalCwtValuePath]: [],
		};

		this.data.forEach((v) => {
			if (typeof v[actualTotalValuePath] === 'number') {
				if (!totalRowTotalValues[actualTotalValuePath]) {
					totalRowTotalValues[actualTotalValuePath] = v[actualTotalValuePath];
				} else {
					totalRowTotalValues[actualTotalValuePath] += v[actualTotalValuePath];
				}
			}

			if (typeof v[forecastedTotalValuePath] === 'number') {
				if (!totalRowTotalValues[forecastedTotalValuePath]) {
					totalRowTotalValues[forecastedTotalValuePath] = v[forecastedTotalValuePath];
				} else {
					totalRowTotalValues[forecastedTotalValuePath] += v[forecastedTotalValuePath];
				}
			}

			if (typeof v[actualTotalCwtValuePath] === 'number' && v[actualTotalCwtValuePath] != null) {
				totalRowTotalValues[actualTotalCwtValuePath].push(v[actualTotalCwtValuePath]);
			}

			if (typeof v[forecastedTotalCwtValuePath] === 'number' && v[forecastedTotalCwtValuePath] != null) {
				totalRowTotalValues[forecastedTotalCwtValuePath].push(v[forecastedTotalCwtValuePath]);
			}
		});

		this.model.getMilkPremiumsAndDeductions.data?.MilkPremiumAndDeductionEntryTotals?.forEach((ledgerEntryTotal) => {
			const date = ledgerEntryTotal.date;
			if (!date) return;

			const typePath = ledgerEntryTotal.type === TypeOfLedgerEntry.Actual ? ' Actual Total' : ' Forecasted Total';
			const typePathCwt = ledgerEntryTotal.type === TypeOfLedgerEntry.Actual ? ' Actual Cwt' : ' Forecasted Cwt';

			if (ledgerEntryTotal?.LedgerCategory?.calculationType === 'DairyCwt') {
				// logic for column totals
				const cwtTotalSumValuePathByType = `${camelize(ledgerEntryTotal.LedgerCategory?.id + typePath)}`;
				const cwtSumValuePathByType = `${camelize(ledgerEntryTotal.LedgerCategory?.id + typePathCwt)}`;

				totals[cwtTotalSumValuePathByType] = (totals[cwtTotalSumValuePathByType] || 0) + (ledgerEntryTotal.sum.calculatedAmount ?? 0);

				if (ledgerEntryTotal.sum.amount != null) {
					if (categoryCwtValues[cwtSumValuePathByType]) {
						categoryCwtValues[cwtSumValuePathByType].push(ledgerEntryTotal.sum.amount);
					} else {
						categoryCwtValues[cwtSumValuePathByType] = [ledgerEntryTotal.sum.amount];
					}
				}
			} else {
				const totalValuePath = `${camelize(ledgerEntryTotal?.LedgerCategory?.id + ' ' + ledgerEntryTotal.type)}`;

				totals[totalValuePath] = (totals[totalValuePath] || 0) + (ledgerEntryTotal.sum.amount ?? 0);
			}
		});

		Object.keys(categoryCwtValues).forEach((key) => {
			totals[key] = categoryCwtValues[key].reduce((a, b) => a + b, 0) / categoryCwtValues[key].length;
		});

		totals[actualTotalCwtValuePath] =
			totalRowTotalValues[actualTotalCwtValuePath].reduce((a, b) => a + b, 0) / totalRowTotalValues[actualTotalCwtValuePath].length;
		totals[forecastedTotalCwtValuePath] =
			totalRowTotalValues[forecastedTotalCwtValuePath].reduce((a, b) => a + b, 0) / totalRowTotalValues[forecastedTotalCwtValuePath].length;
		totals[actualTotalValuePath] = totalRowTotalValues[actualTotalValuePath] ?? 0;
		totals[forecastedTotalValuePath] = totalRowTotalValues[forecastedTotalValuePath] ?? 0;

		return [totals];
	}

	get csvFileName() {
		return this.model.getMilkPremiumsAndDeductions?.data?.Customer?.name
			? `expense-${classify(this.model.getMilkPremiumsAndDeductions?.data?.Customer?.name)}.csv`
			: null;
	}

	get dateRangeOptions(): DateFilterOption[] {
		return [
			{
				displayName: 'Previous Year',
				startDate: DateTime.local().minus({ year: 1 }).startOf('year').toISODate(),
				endDate: DateTime.local().minus({ year: 1 }).endOf('year').toISODate(),
			},
			{
				displayName: `Current Year`,
				startDate: DateTime.local().startOf('year').toISODate(),
				endDate: DateTime.local().endOf('year').toISODate(),
			},
			{
				displayName: `Calendar Year (${DateTime.local().plus({ year: 1 }).year.toString()})`,
				startDate: DateTime.local().plus({ year: 1 }).startOf('year').toISODate(),
				endDate: DateTime.local().plus({ year: 1 }).endOf('year').toISODate(),
			},
			{
				displayName: `Calendar Year (${DateTime.local().plus({ year: 2 }).year.toString()})`,
				startDate: DateTime.local().plus({ year: 2 }).startOf('year').toISODate(),
				endDate: DateTime.local().plus({ year: 2 }).endOf('year').toISODate(),
			},
		];
	}

	get dateRangeQueryParam() {
		return {
			startDate: this.startDate,
			endDate: this.endDate,
		};
	}

	@action
	setDateRangeQueryParam(value: { startDate?: string; endDate?: string }) {
		this.startDate = value.startDate || null;
		this.endDate = value.endDate || null;
	}

	@action
	setTablePageState() {
		resetVaultTableScroll('milk-premiums-and-deductions-table');
	}

	@action
	clear() {
		this.categoryName = '';
	}

	@action
	async submit() {
		const categoryNameTrimmed = this.categoryName.trim();

		if (!categoryNameTrimmed) {
			console.warn('Category name must be supplied');
			return;
		}

		const createLedgerMilkCheckCategory = useMutation<CreateLedgerMilkCheckCategoryMutation, Mutation_createLedgerMilkCheckCategoryArgs>(
			this,
			() => [CREATE_LEDGER_MILK_CHECK_CATEGORY]
		);

		//If left null, all entries are assumed to be "static" values.
		let ledgerType: 'DairyCwt' | null = null;

		switch (this.dynamicUnit) {
			case 'Per CWT':
				ledgerType = 'DairyCwt';
				break;
			default:
				ledgerType = null;
		}

		await createLedgerMilkCheckCategory.mutate({
			data: {
				name: categoryNameTrimmed,
				customerId: this.model.entityId,
				...(ledgerType && { calculationType: ledgerType as TypeOfLedgerCalculation }),
			},
		});

		this.clear();

		await this.model.getMilkPremiumsAndDeductions.refetch();
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'businesses/business/milk-premiums': BusinessesBusinessMilkPremiums;
	}
}
