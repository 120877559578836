import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @contractId}}\n\t<Vault::UiConfirmation as |confirmation|>\n\t\t<confirmation.title>Currently Under Development</confirmation.title>\n\t\t<confirmation.body>This Feature is currently under development and will be delivered shortly\n\t\t\t<br />\n\t\t</confirmation.body>\n\t\t<confirmation.cancel @style='plain' {{on 'click' this.closeConfirmation}}>Cancel</confirmation.cancel>\n\t\t<confirmation.action @style='primary' {{on 'click' this.closeConfirmation}}>Thanks</confirmation.action>\n\t\t{{! change style of confirm to danger and add and implement submit function}}\n\t</Vault::UiConfirmation>\n{{/if}}", {"contents":"{{#if @contractId}}\n\t<Vault::UiConfirmation as |confirmation|>\n\t\t<confirmation.title>Currently Under Development</confirmation.title>\n\t\t<confirmation.body>This Feature is currently under development and will be delivered shortly\n\t\t\t<br />\n\t\t</confirmation.body>\n\t\t<confirmation.cancel @style='plain' {{on 'click' this.closeConfirmation}}>Cancel</confirmation.cancel>\n\t\t<confirmation.action @style='primary' {{on 'click' this.closeConfirmation}}>Thanks</confirmation.action>\n\t\t{{! change style of confirm to danger and add and implement submit function}}\n\t</Vault::UiConfirmation>\n{{/if}}","moduleName":"vault-client/components/upload-production-contract-pdf-modal.hbs","parseOptions":{"srcName":"vault-client/components/upload-production-contract-pdf-modal.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';

interface UploadProductionContractPdfModalArgs {
	contractId: string;
	closeModalFn: Function;
}

export default class UploadProductionContractPdfModal extends Component<UploadProductionContractPdfModalArgs> {
	@action
	closeConfirmation() {
		this.args.closeModalFn();
	}
}
