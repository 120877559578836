import InsuranceDocumentsIndex from 'vault-client/controllers/insurance-documents';

export default class OrganizationsOrganizationInsuranceDocumentsController extends InsuranceDocumentsIndex {
	insuranceRoutePath = 'organizations.organization.insurance';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'organizations/organization/insurance-documents': OrganizationsOrganizationInsuranceDocumentsController;
	}
}
