import Route from '@ember/routing/route';
import { Product } from 'vault-client/types/graphql-types';
import MarketsProductOverviewController from 'vault-client/controllers/markets/product/overview';
export default class MarketsProductOverviewRoute extends Route {
	async model() {
		return this.modelFor('markets.product') as { Product: Product };
	}

	resetController(controller: MarketsProductOverviewController, isExiting: boolean, transition: any) {
		if (isExiting && transition.targetName !== 'error') {
			controller.set('activeChart', null);
		}
	}
}
