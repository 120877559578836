import ReportsNFDMStrategicInventoryModel from 'vault-client/controllers/reports/nfdm-strategic-inventory-model';

export default class BusinessesBusinessNfdmStrategicInventoryModel extends ReportsNFDMStrategicInventoryModel {
	reportsRoute: string = 'businesses.business.reports';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'businesses/business/nfdm-strategic-inventory-model': BusinessesBusinessNfdmStrategicInventoryModel;
	}
}
