import BrokerageIndexRoute from 'vault-client/routes/brokerage';

export default class BusinessesBusinessBrokerageRoute extends BrokerageIndexRoute {
	async model() {
		const businessParams = this.paramsFor('businesses.business') as { business_id: string };
		const variables = this.generateWhere(null, businessParams.business_id);

		const response = await this.apollo.watchQuery({ query: this.query, variables });

		return {
			...response,
			variables,
		};
	}
}
