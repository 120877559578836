import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import { run } from '@ember/runloop';
import { inject as service } from '@ember/service';
import PermissionsService from 'vault-client/services/permissions';
import AuthService from 'vault-client/services/auth';
import checkStorageAvailable from 'vault-client/utils/check-storage-available';

export default class IndexRoute extends Route {
	@service declare permissions: PermissionsService;
	@service applicationScope: any;
	@service declare auth: AuthService;
	@service declare router: RouterService;

	async beforeModel() {
		if (this.auth.isAuthenticated) {
			// We need to get access before we check permissions.
			await this.permissions.fetchAccess();
			if (this.permissions.showOrganizations || this.permissions.showOrganization) {
				let organizationId = '';
				if (this.applicationScope.organizationId) {
					// if navigating to index route from within app
					// after organizationId set and saved in local storage
					// bypass checking and retrieving from local storage
					organizationId = this.applicationScope.organizationId;
				} else {
					organizationId = this.permissions.firstMemberOrganization;
					if (checkStorageAvailable('localStorage')) {
						const storedOrganiztionId = window.localStorage.getItem('organizationId');
						if (storedOrganiztionId && this.permissions.canAccessOrganization(storedOrganiztionId)) {
							organizationId = storedOrganiztionId;
						}
					}
					this.applicationScope.organizationId = organizationId;
				}
				if (this.permissions.showInsurance) {
					// isInternal (and has 1 org access) OR external 1 organization only
					run(this, () => this.router.transitionTo('organizations.organization.insurance-overview', organizationId));
				} else {
					// isInternal (and has 1 org access) OR external 1 organization only
					run(this, () => this.router.transitionTo('organizations.organization.details-and-members', organizationId));
				}
			} else if (this.permissions.showCustomer) {
				// external 1 customer only{
				run(this, () => this.router.transitionTo('businesses.business.dashboard', this.permissions.firstMemberCustomer));
			} else if (this.permissions.showCustomers) {
				// external +1 customers only
				const organizationId = this.permissions.firstMemberCustomerOrganization;
				this.applicationScope.organizationId = organizationId;
				run(this, () => this.router.transitionTo('organizations.organization.businesses', organizationId));
			} else {
				// not admin, no organization(s), no business(es) access
				run(this, () => this.router.transitionTo('markets.index'));
			}
		}
	}
}
