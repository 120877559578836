import Route from '@ember/routing/route';
import { gql, useQuery } from 'glimmer-apollo';
import { tracked } from '@glimmer/tracking';
import {
	TransactionFilterDTO,
	Query_HedgeStrategyArgs,
	Maybe,
	Scalars,
	Query_TransactionsArgs,
	TypeOfInstrument,
	TypeOfOption,
	TransactionSortByDTO,
} from 'vault-client/types/graphql-types';
import { DateTime } from 'luxon';
import { ModelFrom } from 'vault-client/utils/type-utils';
import BusinessesBusinessRoute from 'vault-client/routes/businesses/business';
import { TransactionQuery } from 'vault-client/controllers/businesses/business/hedge-strategies/hedge-strategy';
import { GetTransactionsQuery } from 'vault-client/routes/transactions';

type SortObj = {
	valuePath: string;
	isAscending: boolean;
};

const GET_HEDGE_STRATEGY = gql`
	query HedgeStrategy($id: String!) {
		HedgeStrategy(id: $id) {
			productId
			name
			Transactions {
				id
				tradeDate
				price
				contractQuantity
				unitQuantity
				feeTotal
				commissionTotal
				commissionAndFeeTotal
				hedgeStrategyId
				Account {
					id
					name
					accountNumber
					... on BrokerageAccount {
						id
						salesCode
					}
				}
				Instrument {
					type
					Product {
						id
						name
						displayFactor
						fractionDigits
						optionsUnitValue
						pointValue
						StandardProductLotSpecification {
							pointValue
						}
						velaRootSymbol
						MostCurrentFuture {
							id
							name
							barchartSymbol
						}
					}
					SymbolGroup {
						id
						fractionDigits
						displayFactor
					}
					... on Option {
						id
						exchangeSymbol
						strike
						barchartSymbol
						displayExpiresAt
						optionType
						exchangeSymbol
						UnderlyingInstrument {
							id
							barchartSymbol
						}
					}
					... on Future {
						id
						exchangeSymbol
						barchartSymbol
						displayExpiresAt
					}
					... on Swap {
						id
						displayExpiresAt
						PriceInstrument {
							id
							... on Future {
								id
								barchartSymbol
							}
						}
					}
					... on Swaption {
						id
						strike
						displayExpiresAt
						optionType
						PriceInstrument {
							id
							... on Option {
								id
								barchartSymbol
							}
						}
					}
				}
			}
		}
	}
`;

const GET_BROKERAGE_TRANSACTIONS = gql`
	query Transactions($limit: Float = 50, $offset: Float = 0, $where: TransactionFilterDTO, $orderBy: TransactionSortByDTO) {
		Transactions(where: $where, limit: $limit, offset: $offset, orderBy: $orderBy) {
			id
			tradeDate
			price
			contractQuantity
			unitQuantity
			feeTotal
			commissionTotal
			commissionAndFeeTotal
			hedgeStrategyId
			Account {
				id
				name
				accountNumber
				... on BrokerageAccount {
					id
					salesCode
				}
			}
			Instrument {
				type
				Product {
					id
					name
					displayFactor
					fractionDigits
					optionsUnitValue
					pointValue
					StandardProductLotSpecification {
						pointValue
					}
					velaRootSymbol
					MostCurrentFuture {
						id
						name
						barchartSymbol
					}
				}
				SymbolGroup {
					id
					fractionDigits
					displayFactor
				}
				... on Option {
					id
					exchangeSymbol
					strike
					barchartSymbol
					displayExpiresAt
					optionType
					exchangeSymbol
					UnderlyingInstrument {
						id
						barchartSymbol
					}
				}
				... on Future {
					id
					exchangeSymbol
					barchartSymbol
					displayExpiresAt
				}
				... on Swap {
					id
					displayExpiresAt
					PriceInstrument {
						id
						... on Future {
							id
							barchartSymbol
						}
					}
				}
				... on Swaption {
					id
					strike
					displayExpiresAt
					optionType
					PriceInstrument {
						id
						... on Option {
							id
							barchartSymbol
						}
					}
				}
			}
		}
		TransactionCount(where: $where) {
			count
		}
	}
`;

type HedgeStrategyQueryResult = {
	__typename?: 'Query';
	data: {
		HedgeStrategy?: {
			__typename?: 'HedgeStrategy';
			productId?: Maybe<Scalars['String']>;
			name?: Maybe<Scalars['String']>;
			id?: Scalars['String'];
			Transactions: TransactionQuery[];
		};
	};
};

interface QueryParams {
	strategy_id?: string | null | undefined;
	accountId?: string | null;
	instrumentId?: string | null;
	customerId?: string | null;
	productId?: string | null | undefined;
	instrumentType?: TypeOfInstrument | null;
	optionType?: TypeOfOption | null;
	side?: string | null | undefined;
	tradeDateStartDate: string;
	tradeDateEndDate: string;
	expMonthStartDate: string;
	expMonthEndDate: string;
	page: number;
	size: number;
	sorts: SortObj[];
	id: string;
}

export default class BusinessesBusinessHedgeStrategiesEditBrokerageTransactionsIndexRoute extends Route {
	@tracked variables: Query_TransactionsArgs = {};
	@tracked strategyId: string | null | undefined = null;

	queryParams = {
		productId: { refreshModel: true },
		instrumentId: { refreshModel: true },
		searchFilterProductId: { refreshModel: true },
		accountId: { refreshModel: true },
		instrumentType: { refreshModel: true },
		optionType: { refreshModel: true },
		tradeDateStartDate: { refreshModel: true },
		tradeDateEndDate: { refreshModel: true },
		expMonthStartDate: { refreshModel: true },
		expMonthEndDate: { refreshModel: true },
		side: { refreshModel: true },
		page: { refreshModel: true },
		size: { refreshModel: true },
		sorts: { refreshModel: true },
	};

	getBrokerageTransactions = useQuery<GetTransactionsQuery, Query_TransactionsArgs>(this, () => [
		GET_BROKERAGE_TRANSACTIONS,
		{
			variables: this.variables,
		},
	]);

	getHedgeStrategy = useQuery<HedgeStrategyQueryResult, Query_HedgeStrategyArgs>(this, () => [
		GET_HEDGE_STRATEGY,
		{
			variables: {
				id: this.strategyId ?? '',
			},
		},
	]);

	generateBrokerageTransactionsWhere(
		currentStrategyId: string,
		hedgeStrategyProductId: string | null,
		productId: string | null | undefined,
		customerId: string | null,
		accountId: string | null | undefined,
		instrumentId: string | null | undefined,
		instrumentType: string | null | undefined,
		optionType: string | null | undefined,
		tradeDateStartDate: string | null,
		tradeDateEndDate: string | null,
		expMonthStartDate: string | null,
		expMonthEndDate: string | null,
		side: string | null | undefined
	): TransactionFilterDTO {
		/**
		 * This method creates a filter object that is used to query available transactions to be added to a strategy based on two main conditions:
		 * 1. Hedge Strategy ID: The transaction must either be linked to the hedge strategy currently being edited (identified by 'currentStrategyId'),
		 *    or not be linked to any hedge strategy at all. This ensures that each transaction is associated with
		 *    only one hedge strategy and also enables the selection of unassigned transactions.
		 *
		 * 2. Product ID: If a 'productId' is provided, it indicates that the current hedge strategy is already tied to a specific product.
		 *    In this case, the filter will also include a condition to only fetch transactions related to this product. If no 'productId' is provided,
		 *    the filter omits the product condition, allowing transactions of any product to be fetched.
		 */

		const where: TransactionFilterDTO = {
			OR: [
				{
					hedgeStrategyId: {
						equals: currentStrategyId,
					},
				},
				{
					hedgeStrategyId: {
						equals: null,
					},
				},
			],
		};
		if (customerId) {
			where.Account = {
				customerId: { equals: customerId },
			};
		}

		if (accountId) {
			where.accountId = { equals: accountId };
		}

		if (instrumentId) {
			where.instrumentId = { equals: instrumentId };
		}

		if (instrumentType) {
			if (!where.CurrentPosition) where.CurrentPosition = {};
			where.CurrentPosition.instrumentType = { equals: instrumentType as TypeOfInstrument };
		}

		if (optionType) {
			if (!where.CurrentPosition) where.CurrentPosition = {};
			where.CurrentPosition.optionType = { equals: optionType as TypeOfOption };
		}

		if (tradeDateStartDate && tradeDateEndDate) {
			where.tradeDate = { gte: tradeDateStartDate, lte: tradeDateEndDate };
		} else if (tradeDateStartDate) {
			where.tradeDate = { gte: tradeDateStartDate };
		} else if (tradeDateEndDate) {
			where.tradeDate = { lte: tradeDateEndDate };
		}

		if (expMonthStartDate && expMonthEndDate) {
			where.Instrument = { displayExpiresAt: { gte: expMonthStartDate, lte: expMonthEndDate } };
		} else if (expMonthStartDate) {
			where.Instrument = { displayExpiresAt: { gte: expMonthStartDate } };
		} else if (expMonthEndDate) {
			where.Instrument = { displayExpiresAt: { lte: expMonthEndDate } };
		}

		if (hedgeStrategyProductId) {
			where.Instrument = {
				...where.Instrument,
				productId: {
					equals: hedgeStrategyProductId,
				},
			};
		} else if (productId) {
			where.Instrument = {
				...where.Instrument,
				productId: {
					equals: productId,
				},
			};
		}

		if (side) {
			where.quantity = side === 'Long' ? { gt: 0 } : { lt: 0 };
		}

		return where;
	}

	generateOrderBy(sorts: SortObj[]): TransactionSortByDTO {
		const orderBy: {
			[key: string]: any;
		} = {};

		sorts.forEach((sort: any) => {
			const value = sort.isAscending ? 'Asc' : 'Desc';
			orderBy[sort.valuePath] = value;
		});

		return orderBy;
	}

	async model(params: QueryParams) {
		const { strategy_id } = params;
		this.strategyId = strategy_id;

		await this.getHedgeStrategy.promise;

		const hedgeStrategyResult = this.getHedgeStrategy as HedgeStrategyQueryResult;
		const hedgeStrategyProductId = hedgeStrategyResult?.data?.HedgeStrategy?.productId ?? null;
		const hedgeStrategyName = hedgeStrategyResult?.data?.HedgeStrategy?.name ?? null;
		const hedgeStrategyData = {
			name: hedgeStrategyName,
			id: strategy_id,
		};

		const business_id = (this.modelFor('businesses.business') as ModelFrom<BusinessesBusinessRoute> | undefined)?.businessId ?? '';
		if (!business_id || !strategy_id) {
			throw new Error('No business ID or strategy ID found');
		}
		this.variables = {
			where: this.generateBrokerageTransactionsWhere(
				strategy_id,
				hedgeStrategyProductId,
				params.productId,
				business_id,
				params.accountId,
				params.instrumentId,
				params.instrumentType,
				params.optionType,
				params.tradeDateStartDate,
				params.tradeDateEndDate,
				params.expMonthStartDate,
				params.expMonthEndDate,
				params.side
			),
			offset: params.page * params.size,
			limit: params.size,
			orderBy: this.generateOrderBy(params.sorts),
		};

		await this.getBrokerageTransactions.promise;

		return {
			getBrokerageTransactions: this.getBrokerageTransactions,
			hedgeStrategy: hedgeStrategyData,
			selectedBrokerage: hedgeStrategyResult,
			lastUpdatedAt: DateTime.now().toISO(),
		};
	}
}
