import InsurancePoliciesIndexRoute from 'vault-client/routes/insurance-policies';

type SortObj = {
	valuePath: string;
	isAscending: boolean;
};

interface QueryParams {
	agent?: string | null;
	aipId?: string | null;
	policyNumber?: string | null;
	stateId?: string | null;
	sorts: SortObj[];
	page: number;
	id: string;
	customerId?: string;
	size: number;
}

export default class OrganizationsInsurancePoliciesRoute extends InsurancePoliciesIndexRoute {
	async model(params: QueryParams) {
		const organizationParams = this.paramsFor('organizations.organization') as { id: string };
		const variables = {
			where: this.generateWhere(organizationParams.id, params.customerId, params.aipId, params.policyNumber, params.agent, params.stateId),
			orderBy: this.generateOrderBy(params.sorts),
			offset: params.page * params.size,
			limit: params.size,
			entityId: organizationParams.id,
		};

		const response = await this.apollo.watchQuery({
			query: this.query,
			variables,
			fetchPolicy: 'cache-and-network',
		});

		return {
			...response,
			variables,
		};
	}
}
