import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import {
	BusinessEntityRole,
	LedgerExpenseCategoryCreateDTO,
	Mutation_createLedgerExpenseCategoryArgs,
	TypeOfLedgerCalculation,
} from 'vault-client/types/graphql-types';
import { gql, useMutation } from 'glimmer-apollo';
import { ModelFrom } from 'vault-client/utils/type-utils';
import BusinessesBusinessBusinessSettingsRoute from 'vault-client/routes/businesses/business/business-settings';
import BusinessesBusinessExpensesRoute from 'vault-client/routes/businesses/business/expenses';

interface AddLedgerExpenseCategoryButtonArgs {
	businessId: string;
	model: ModelFrom<BusinessesBusinessBusinessSettingsRoute> | ModelFrom<BusinessesBusinessExpensesRoute>['getExpenses'];
}

const CREATE_LEDGER_EXPENSE_CATEGORY = gql`
	mutation createLedgerExpenseCategory($data: LedgerExpenseCategoryCreateDTO!) {
		createLedgerExpenseCategory(data: $data) {
			id
			name
		}
	}
`;

type CreateLedgerExpenseCategoryMutation = {
	__typename?: 'Mutation';

	createLedgerExpenseCategory?: {
		data: LedgerExpenseCategoryCreateDTO;
	} | null;
};

export default class AddLedgerExpenseCategoryButton extends Component<AddLedgerExpenseCategoryButtonArgs> {
	@tracked showModal = false;
	@tracked categoryName: string = '';
	@tracked dynamicUnit: string | null = null;
	selectableUnits = this.businessRole === BusinessEntityRole.HogProducer ? ['Static', 'Per Head'] : ['Static', 'Per Head', 'Per CWT'];

	createLedgerExpenseCategory = useMutation<CreateLedgerExpenseCategoryMutation, Mutation_createLedgerExpenseCategoryArgs>(this, () => [
		CREATE_LEDGER_EXPENSE_CATEGORY,
		{
			/* options */
			update: (cache) => {
				cache.evict({ fieldName: 'AggregateLedgerEntries' });
				cache.evict({ fieldName: 'LedgerEntry' });
				cache.evict({ fieldName: 'LedgerEntries' });
				cache.evict({ fieldName: 'LedgerEntryCount' });

				cache.evict({ fieldName: 'LedgerCategory' });
				cache.evict({ fieldName: 'LedgerCategories' });
				cache.evict({ fieldName: 'LedgerCategoryCount' });

				cache.evict({ fieldName: 'LedgerExpenseCategory' });
				cache.evict({ fieldName: 'LedgerExpenseCategories' });
				cache.evict({ fieldName: 'LedgerExpenseCategoryCount' });
				cache.gc();
			},
		},
	]);

	get businessRole() {
		return (
			this.args.model.data?.Customer?.businessRoles?.find(
				(role) => role === BusinessEntityRole.HogProducer || role === BusinessEntityRole.DairyProducer
			) ?? null
		);
	}

	@action
	openModal() {
		this.showModal = true;
	}

	@action
	closeModal() {
		this.showModal = false;
		this.categoryName = '';
		this.dynamicUnit = null;
	}

	get hasValidInput() {
		return this.categoryName.trim();
	}

	@action
	async submit() {
		//If left null, all entries are assumed to be "static" values.
		let ledgerType: TypeOfLedgerCalculation | null = null;

		switch (this.dynamicUnit) {
			case 'Per Head':
				ledgerType =
					this.businessRole === BusinessEntityRole.HogProducer ? TypeOfLedgerCalculation.SwineHead : TypeOfLedgerCalculation.DairyHead;
				break;
			case 'Per CWT':
				ledgerType = TypeOfLedgerCalculation.DairyCwt;
				break;
			default:
				ledgerType = null;
		}
		await this.createLedgerExpenseCategory.mutate({
			data: {
				name: this.categoryName,
				customerId: this.args.businessId,
				calculationType: ledgerType,
			},
		});

		await this.args.model.refetch();

		this.closeModal();
	}
}
