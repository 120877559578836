import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{this.formattedValue}}", {"contents":"{{this.formattedValue}}","moduleName":"vault-client/components/month-format.hbs","parseOptions":{"srcName":"vault-client/components/month-format.hbs"}});
import Component from '@glimmer/component';
import { DateTime } from 'luxon';

interface MonthFormatArgs {
	value: string | null;
}

export default class MonthFormat extends Component<MonthFormatArgs> {
	get formattedValue() {
		if (this.args.value) {
			const luxonDate = DateTime.fromISO(this.args.value);
			return `${luxonDate.monthShort} ${luxonDate.year}`;
		} else {
			return '-';
		}
	}
}
