import ReportsDrpPerformanceByAIPRoute from 'vault-client/routes/reports/drp-performance-by-aip';
interface QueryParams {
	agent: string | null;
	stateId: string | null;
	reinsuranceYear: number | null;
	quarterStartDate: string | null;
	quarterEndDate: string | null;
}
export default class BusinessesBusinessDrpPerformanceByAip extends ReportsDrpPerformanceByAIPRoute {
	async model(params: QueryParams) {
		const businessParams = this.paramsFor('businesses.business') as { business_id: string };
		const variables = {
			endorsementWhere: this.generateWhere(
				null,
				businessParams.business_id,
				params.quarterStartDate,
				params.quarterEndDate,
				params.agent,
				params.stateId,
				params.reinsuranceYear
			),
		};
		return this.apollo.watchQuery({ query: this.query, variables });
	}
}
