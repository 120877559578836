import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { DateTime, Interval } from 'luxon';
import { CellComponents, TableColumn } from 'vault-client/types/vault-table';
import { ModelFrom } from 'vault-client/utils/type-utils';
import BusinessesBusinessPigCohortRoute from 'vault-client/routes/businesses/business/pig-cohort';
import { SwineLivestockPopulationForecastByWeek } from 'vault-client/types/graphql-types';
import { ChartOptions } from 'chart.js';
import { getCustomTooltip, CustomTooltipOptions, getCustomLegend, calculateAutoLabelYOffset } from 'vault-client/utils/chart-utils';

type RowObject = {
	ageInWeeks: string;
	date: string;
	forecastedTotal: number;
	produced: number;
	purchased: number;
	lost: number;
};
export default class BusinessesBusinessPigCohort extends Controller {
	declare model: ModelFrom<BusinessesBusinessPigCohortRoute>;

	@tracked dob: string | null = null;

	@tracked columns: TableColumn[] = [
		{
			id: '6f4c1c60-7c2f-42f5-866c-1e467e98d4f4',
			name: 'Date',
			valuePath: 'date',
			minWidth: 80,
			cellComponent: CellComponents.IntlDateTimeFormat,
			textAlign: 'left',
			isSortable: true,
			isFixed: '',
			isVisible: true,
		},
		{
			id: 'ee12c44a-d89c-4a6d-bf80-59e3a0727d48',
			name: 'Age of Cohort',
			valuePath: 'ageInWeeks',
			textAlign: 'left',
			width: 80,
			cellComponent: CellComponents.String,
			isVisible: true,
			isSortable: false,
			isFixed: '',
		},
		{
			id: 'd2c68d6b-54a3-4965-ae05-61f596e322f3',
			name: 'Produced',
			valuePath: 'produced',
			minWidth: 80,
			cellComponent: CellComponents.IntlNumberFormat,
			textAlign: 'right',
			isSortable: false,
			isFixed: '',
			isVisible: true,
		},
		{
			id: '3bd56221-b690-478a-bbe3-c2ec0b2d2bee',
			name: 'Purchased',
			valuePath: 'purchased',
			minWidth: 80,
			cellComponent: CellComponents.IntlNumberFormat,
			textAlign: 'right',
			isSortable: false,
			isFixed: '',
			isVisible: true,
		},
		{
			id: 'fcc32357-118a-44fe-95e9-8afcd4d901da',
			name: 'Lost',
			valuePath: 'lost',
			minWidth: 80,
			cellComponent: CellComponents.IntlNumberFormat,
			textAlign: 'right',
			isSortable: false,
			isFixed: '',
			isVisible: true,
		},
		{
			id: '03ac9ec9-aeec-44ea-b3af-963b7b7d7729',
			name: 'Forecasted Total',
			valuePath: 'forecastedTotal',
			minWidth: 80,
			cellComponent: CellComponents.IntlNumberFormat,
			textAlign: 'right',
			isSortable: false,
			isFixed: '',
			isVisible: true,
		},
	];

	get rows() {
		const weeks: Array<any> = [];

		this.model.pigCohort.data?.SwineLivestockPopulationForecastsByWeek.forEach((week: any) => {
			const rowObj = {} as RowObject;

			rowObj.date = week.date;
			rowObj.ageInWeeks = `Week ${week.ageInWeeks}`;
			rowObj.forecastedTotal = week.endQuantity;

			// aggregate population changes
			let producedQuantity = 0;
			let purchasedQuantity = 0;
			let lostQuantity = 0;
			week.PopulationChanges.forEach((change: any) => {
				producedQuantity += change.reasonType === 'Birth' ? change.quantity : 0;
				purchasedQuantity += change.reasonType === 'Purchase' ? change.quantity : 0;
				lostQuantity += change.reasonType === 'Death' || change.reasonType === 'Sale' ? change.quantity : 0;
			});
			rowObj.produced = producedQuantity;
			rowObj.purchased = purchasedQuantity;
			rowObj.lost = lostQuantity;
			weeks.push(rowObj);
		});

		return weeks;
	}

	get formattedWeekAgeAndDate() {
		const dob = this.model.pigCohort.data?.SwineLivestockPopulationForecastsByWeek[0]?.dob;
		const formattedDate = dob ? DateTime.fromISO(dob).toFormat('MMMM dd, yyyy') : null;
		return `Pigs weaned week of ${formattedDate}`;
	}

	get plugins() {
		return [
			{
				afterUpdate: getCustomLegend(this.chartId, this.legendId),
			},
		];
	}

	get chartId() {
		const date = this.model.pigCohort.data?.SwineLivestockPopulationForecastsByWeek[0].dob;
		return `weaned-on-${date}-pig-cohort`;
	}

	get legendId() {
		return `${this.chartId}-legend`;
	}

	get tooltipId() {
		return `${this.chartId}-tooltip`;
	}

	get annotationIndex() {
		let minOffset = Infinity;
		let minOffsetIndex = 0;
		const now = DateTime.now();
		this.rows.forEach((date, index) => {
			const diff = Interval.fromDateTimes(DateTime.fromFormat(date.date, 'yyyy-MM-dd'), now);
			const diffDays = diff.length('days');
			if (diffDays <= minOffset) {
				minOffset = diffDays;
				minOffsetIndex = index;
			}
		});

		return minOffsetIndex;
	}

	get chartOptions() {
		const tooltipOptions: CustomTooltipOptions = {
			titleFormatter: (val: string | number) => {
				return `${val}`;
			},
			valueFormatter: (val: number) => {
				return new Intl.NumberFormat('en-US').format(val);
			},
		};

		const options: ChartOptions<'line' | 'bar'> = {
			maintainAspectRatio: false,
			responsive: true,
			interaction: {
				intersect: false,
				mode: 'index',
			},
			layout: {
				padding: {
					top: 35,
				},
			},
			scales: {
				x: {
					stacked: true,
					ticks: {
						callback: function (value: number) {
							const labelValue = this.getLabelForValue(value);
							return labelValue;
						},
						font: {
							size: 12,
						},
						color: getComputedStyle(document.documentElement).getPropertyValue('--brand-gray-60'),
					},
					grid: {
						display: false,
					},
				},

				y: {
					stacked: true,
					ticks: {
						font: {
							size: 12,
						},
						color: getComputedStyle(document.documentElement).getPropertyValue('--brand-gray-60'),
						callback: (value: number) => {
							return new Intl.NumberFormat(undefined, {
								notation: 'compact',
							}).format(value);
						},
					},
				},
			},
			plugins: {
				legend: {
					display: false,
				},
				tooltip: {
					external: getCustomTooltip(tooltipOptions),
					enabled: false,
					callbacks: {
						title: (tooltipItem: any) => {
							return `${tooltipItem[0].label}`;
						},
					},
				},
				annotation: {
					clip: false,
					annotations: {
						line1: {
							display: true,
							type: 'line',
							adjustScaleRange: true,
							xMin: this.annotationIndex,
							xMax: this.annotationIndex,
							borderColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-lemon-50'),
							borderDash: [5],
							label: {
								content: 'Today',
								display: true,
								position: 'end',
								color: getComputedStyle(document.documentElement).getPropertyValue('--brand-gray-90'),
								borderColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-blue-80'),
								borderWidth: 1,
								backgroundColor: 'white',
								yAdjust: (ctx) => {
									return calculateAutoLabelYOffset(ctx, this.annotationIndex);
								},
								font: {
									size: 12,
								},
							},
						},
					},
				},
			},
		};
		return options;
	}

	get chartData() {
		const labels = this.rows?.map((cohort: SwineLivestockPopulationForecastByWeek) => cohort.ageInWeeks);
		const datasets = [
			{
				backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-blue-60'),
				borderColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-blue-60'),
				pointStyle: 'circle',
				fill: false,
				type: 'bar',
				label: 'Produced',
				afterLabel: true,
				borderRadius: 2,
				order: 2,
				data: this.rows?.map((week) => week.produced),
			},
			{
				backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-orange-40'),
				borderColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-orange-40'),
				pointStyle: 'circle',
				fill: false,
				type: 'bar',
				label: 'Purchased',
				afterLabel: true,
				borderRadius: 2,
				order: 4,
				data: this.rows?.map((week) => week.purchased),
			},
			{
				backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-lime-40'),
				borderColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-lime-40'),
				pointStyle: 'circle',
				fill: false,
				type: 'bar',
				label: 'Lost',
				afterLabel: true,
				borderRadius: 2,
				order: 3,
				data: this.rows?.map((week) => week.lost),
			},
			{
				pointBackgroundColor: '#E3E3E3',
				backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-lemon-40'),
				borderColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-lemon-40'),
				borderWidth: 2,
				type: 'line',
				label: 'Forecasted Total',
				pointRadius: 4,
				order: 1,
				data: this.rows?.map((week) => week.forecastedTotal),
			},
		];
		return {
			labels,
			datasets,
		};
	}
	get lastUpdatedAtString() {
		const lastUpdatedAt = DateTime.fromISO(this.model.lastUpdatedAt);

		return `Last updated: ${lastUpdatedAt.toLocaleString(DateTime.DATE_SHORT)} at ${lastUpdatedAt.toLocaleString(DateTime.TIME_SIMPLE)}`;
	}

	@action
	previousWeek() {
		const currentStartOfWeekDate = this.model.birth_week_start_date;
		if (currentStartOfWeekDate) {
			const dt = DateTime.fromFormat(currentStartOfWeekDate, 'yyyy-MM-dd');
			// dob date should always be on a Sunday but just in case
			if (dt.weekday === 7) {
				this.dob = dt.minus({ days: 7 }).toFormat(`yyyy'-'MM'-'dd`);
			} else {
				// Luxon start of the week is on Monday. Pig Cohort start of the week in on Sunday. Getting the start of the current Luxon week and subtracting by 8 days will put us on Sunday of previous week
				this.dob = dt.startOf('week').minus({ days: 8 }).toFormat(`yyyy'-'MM'-'dd`);
			}
		}
	}

	@action
	nextWeek() {
		const currentStartOfWeekDate = this.model.birth_week_start_date;

		if (currentStartOfWeekDate) {
			const dt = DateTime.fromFormat(currentStartOfWeekDate, 'yyyy-MM-dd');
			// dob date should always be on a Sunday but just in case
			if (dt.weekday === 7) {
				this.dob = dt.plus({ days: 7 }).toFormat(`yyyy'-'MM'-'dd`);
			} else {
				// Luxon start of the week is on Monday. Pig Cohort start of the week in on Sunday. Getting the start of the current Luxon week and adding 6 days will put us on the Sunday the first day of the next Pig Cohort week.
				this.dob = dt.startOf('week').plus({ days: 6 }).toFormat(`yyyy'-'MM'-'dd`);
			}
		}
	}

	@action
	updateChart(chart: any, _data: unknown) {
		chart.options = this.chartOptions;
		chart.data = this.chartData;
		chart.update('none');
	}
}
// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	interface Registry {
		'businesses/business/pig-cohort': BusinessesBusinessPigCohort;
	}
}
