import ReportsLrpCalculator from 'vault-client/controllers/reports/lrp-calculator';

export default class BusinessesBusinessLrpCalculator extends ReportsLrpCalculator {
	reportsRoute: string = 'businesses.business.reports';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'businesses/business/lrp-calculator': BusinessesBusinessLrpCalculator;
	}
}
