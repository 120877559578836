import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { ModelFrom } from 'vault-client/utils/type-utils';
import VgsCustomersRoute, { GET_CUSTOMERS } from 'vault-client/routes/vgs/customers';
import { CellComponents, TableColumn } from 'vault-client/types/vault-table';
import resetVaultTableScroll from 'vault-client/utils/reset-vault-table-scroll';
import { action } from '@ember/object';
import { service } from '@ember/service';
import PermissionsService from 'vault-client/services/permissions';
import getFilterDisplayProperty from 'vault-client/utils/get-filter-display-property';
import { gql, useQuery } from 'glimmer-apollo';
import { CustomerEntity, Query, Query_BusinessContactsArgs, Query_CustomersArgs } from 'vault-client/types/graphql-types';
import { generateFullName } from 'vault-client/utils/general';

interface SearchResult {
	id: string;
	name: string;
	type: string;
}

const SEARCH_CUSTOMERS = gql`
	query Customers($where: CustomerEntityFilterDTO) {
		Customers(where: $where, orderBy: { name: Asc }) {
			id
			name
		}
	}
`;

const GET_BUSINESS_CONTACT = gql`
	query BusinessContacts($where: BusinessContactFilterDTO) {
		BusinessContacts(where: $where) {
			id
			firstName
			lastName
		}
	}
`;

export default class VgsCustomersIndex extends Controller {
	@tracked page = 0;
	@tracked size = 100;
	@tracked sorts = [{ valuePath: 'name', isAscending: true }];
	@service declare permissions: PermissionsService;
	@tracked customerToEdit: CustomerEntity | null = null;
	@tracked selectedAdvisorIds: string[] = [];
	@tracked customerId: string | null = null;
	@tracked businessContactId: string | null = null;

	queryParams = ['page', 'size', 'sorts', 'customer', 'contactName', 'selectedAdvisorIds'];

	declare model: ModelFrom<VgsCustomersRoute>;

	query = GET_CUSTOMERS;

	itemsFn = (customers: CustomerEntity[]) => {
		return customers.map((customer) => {
			const businessContact = customer.BusinessContacts[0];

			return {
				advisorName: generateFullName(customer.RelationshipOwner?.firstName, customer.RelationshipOwner?.lastName),
				contactName: generateFullName(businessContact?.firstName, businessContact?.lastName),
				contactEmail: customer.BusinessContacts[0]?.email,
				contactPhoneNumber: customer.BusinessContacts[0]?.phone,
				...customer,
			};
		});
	};

	get columns() {
		const baseColumns: TableColumn[] = [
			{
				id: '7029028A-DA1B-4025-96A4-2664B5B29776',
				name: 'Customer Name',
				valuePath: 'name',
				minWidth: 120,
				textAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				linkRoute: 'vgs.customers.show',
				linkModelPath: 'id',
			},
			{
				id: 'e4d5372d-b2a0-4c76-ab1f-6c99b1a2b3a7',
				name: 'Id Number',
				valuePath: 'identificationNumber',
				minWidth: 100,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			// Show edit customer button for users with buyer relations role
			...(this.permissions.isBuyerRelations
				? [
						{
							id: '9edc3ac7-62cb-4756-b9f1-22883cfb092a',
							name: '',
							valuePath: '',
							minWidth: 80,
							width: 80,
							maxWidth: 80,
							textAlign: 'center',
							isSortable: false,
							cellComponent: CellComponents.Button,
							isFixed: '',
							isVisible: true,
							componentArgs: {
								size: 'xs',
								style: 'utility',
								text: 'Edit',
								fn: this.showEditCustomerModal,
							},
						},
				  ]
				: []),
			{
				id: '9EBFF468-0865-49F2-975A-0CE5D6986846',
				name: 'Advisor',
				valuePath: 'advisorName',
				minWidth: 120,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '8F281546-862B-4A8B-ACBB-06B415F54DDA',
				name: 'Contact Name',
				valuePath: 'contactName',
				minWidth: 160,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '5899D9B7-42D2-454A-9864-0E6F8DFB4B18',
				name: 'Contact Email',
				valuePath: 'contactEmail',
				minWidth: 160,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '6E2D338F-E813-47C8-96DB-F99AA2686E23',
				name: 'Contact Phone Number',
				valuePath: 'contactPhoneNumber',
				minWidth: 120,
				cellComponent: CellComponents.String,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
		];
		return baseColumns;
	}

	get currentPage() {
		return this.page;
	}

	set currentPage(page) {
		this.page = page;
	}

	get rows() {
		return this.itemsFn(this.model.getCustomers.data?.Customers ?? []);
	}

	get advisors() {
		return this.model.getCustomers.data?.GrainAdvisors ?? [];
	}

	get totalNumCustomers() {
		return this.model.getCustomers.data?.CustomerCount?.count;
	}

	@action
	showEditCustomerModal(customer: CustomerEntity) {
		this.customerToEdit = customer;
	}

	@action
	closeEditCustomerModal() {
		this.customerToEdit = null;
	}

	get selectedAdvisorsString() {
		if (this.selectedAdvisorIds.length === 0) {
			return 'All';
		}

		if (this.selectedAdvisorIds.length === 1) {
			return this.advisors.find((advisor) => advisor.id === this.selectedAdvisorIds[0])?.name ?? '';
		}

		return `${this.selectedAdvisorIds.length} Selected`;
	}

	get searchFilterQueryParams() {
		const obj: {
			[key: string]: any;
		} = {};
		const searchQueryParams = ['customerId', 'businessContactId'] as const;
		searchQueryParams.forEach((param) => {
			const value = this[param];

			if (value) {
				obj[param] = {
					filterRule: 'equals',
					filterValue: value,
				};

				// set filterComponent property to specify component for custom display extended from search-filter
				const filterDisplayObj = getFilterDisplayProperty(param);

				if (filterDisplayObj && filterDisplayObj.customComponent) {
					obj[param].filterComponent = filterDisplayObj.customComponent;
				}
				// set filterLabel property to specify custom label for filter - default would be filterIdentifier (matches queryParam)
				if (filterDisplayObj && filterDisplayObj.label) {
					obj[param].filterLabel = filterDisplayObj.label;
				}
			}
		});

		return obj;
	}

	@action
	addSelectedAdvisorId(advisor: string | null) {
		if (advisor === null) {
			this.selectedAdvisorIds = [];
		} else if (this.selectedAdvisorIds.includes(advisor)) {
			this.selectedAdvisorIds = this.selectedAdvisorIds.filter((v) => v !== advisor);
		} else {
			this.selectedAdvisorIds = [...this.selectedAdvisorIds, advisor];
		}

		// If all advisors are selected, clear the selected array so that 'All' is used
		if (this.selectedAdvisorIds.length === this.advisors.length) {
			this.selectedAdvisorIds = [];
		}
	}

	@action
	structureSearchResults(searchResults: SearchResult[]) {
		const map = new Map();

		searchResults.forEach((item) => {
			if (map.has(item.type)) {
				map.get(item.type).push({ id: item.id, name: item.name, type: item.type });
			} else {
				map.set(item.type, [{ id: item.id, name: item.name, type: item.type }]);
			}
		});

		return map;
	}

	@action
	async fetchSearchResults(searchText: string) {
		const customerResults: SearchResult[] = [];
		const contactNameResults: SearchResult[] = [];

		const customerQuery = useQuery<{ Customers: Query['Customers'] }, Query_CustomersArgs>(this, () => [
			SEARCH_CUSTOMERS,
			{
				variables: {
					where: {
						name: {
							contains: searchText,
						},
						isVgs: {
							equals: true,
						},
					},
				},
				onComplete: (data): void => {
					customerResults.push(
						...(data?.Customers.map((customer) => ({
							id: customer.id,
							type: 'customer',
							name: customer.name,
						})) ?? [])
					);
				},
			},
		]);

		const contactNameQuery = useQuery<{ BusinessContacts: Query['BusinessContacts'] }, Query_BusinessContactsArgs>(this, () => [
			GET_BUSINESS_CONTACT,
			{
				variables: {
					where: {
						OR: [
							{
								firstName: { contains: searchText },
							},
							{
								lastName: { contains: searchText },
							},
						],
					},
				},
				onComplete: (data): void => {
					customerResults.push(
						...(data?.BusinessContacts.map((contact) => ({
							id: contact.id,
							name: generateFullName(contact.firstName, contact.lastName) ?? '',
							type: 'businessContact',
						})) ?? [])
					);
				},
			},
		]);

		const promises = [customerQuery.promise, contactNameQuery.promise];

		await Promise.all(promises);

		return [...customerResults, ...contactNameResults];
	}

	mapSearchResult(searchResult: SearchResult) {
		let filterIdentifier: keyof VgsCustomersIndex | null = null;

		switch (searchResult.type) {
			case 'customer':
				filterIdentifier = 'customerId';
				break;
			case 'businessContact':
				filterIdentifier = 'businessContactId';
				break;
		}

		return {
			filterIdentifier,
			filterValue: searchResult.id ?? searchResult.name,
		};
	}

	@action
	setSearchFilterQueryParam(searchResult: SearchResult) {
		const mappedSearchFilter = this.mapSearchResult(searchResult);

		if (mappedSearchFilter.filterIdentifier) {
			this[mappedSearchFilter.filterIdentifier] = mappedSearchFilter.filterValue;
			this.setTablePageState(0);
		}
	}

	@action
	clearSearchFilterQueryParam(filterIdentifier: 'customerId' | 'businessContactId') {
		this[`${filterIdentifier}`] = null;
		this.setTablePageState(0);
	}

	@action
	setTablePageState(newPageVal: number) {
		this.currentPage = newPageVal ?? 0;
		resetVaultTableScroll('customers-table');
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	interface Registry {
		'vgs/customers/index': VgsCustomersIndex;
	}
}
