import { action } from '@ember/object';
import Component from '@glimmer/component';
import { TableColumn } from 'vault-client/types/vault-table';
import { getByCompositeKey } from 'vault-client/utils/general';

interface VaultTableCellArgs {
	cell: string | number | null;
	row: any;
	column: TableColumn;
	footerRow: boolean;
	rowIndex: number;
}

export default class VaultTableCell extends Component<VaultTableCellArgs> {
	get linkChildColumn() {
		const newColumn = { ...this.args.column };
		delete newColumn.linkRoute;
		delete newColumn.linkRoutePath;
		delete newColumn.linkModelPath;
		delete newColumn.linkQuery;
		return newColumn;
	}

	get linkQuery() {
		if (!this.args.column.linkQuery) {
			return {};
		}

		const queryObject = { ...this.args.column.linkQuery };

		Object.keys(queryObject).forEach((key) => {
			const value = queryObject[key];
			if (value?.rowKey) {
				queryObject[key] = value.rowKey
					.split('.')
					.reduce((prev: { [key: string]: any } | null, item: string) => (prev ? prev[item] : null), this.args.row);
			} else if (value?.staticValue) {
				queryObject[key] = value.staticValue;
			} else {
				queryObject[key] = null;
			}
		});

		return queryObject;
	}

	@action
	getValuesByPaths(paths: string[], object: Record<PropertyKey, unknown>) {
		return paths.map((path) => getByCompositeKey(object, path));
	}
}
