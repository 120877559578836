import { DateTime } from 'luxon';

const PRODUCT_SLUGS = ['grain-corn', 'grain-soybeans', 'grain-soybean-meal'] as const;
type ProductSlug = (typeof PRODUCT_SLUGS)[number];

function isProductSlug(slug: string): slug is ProductSlug {
	return PRODUCT_SLUGS.includes(slug as ProductSlug);
}

const futureMonthToMonthSubtrahends: { [key in ProductSlug]: (number[] | undefined)[] } = {
	'grain-corn': [
		undefined,
		undefined,
		undefined,
		[2, 1, 0], // Mar
		undefined,
		[1, 0], // May
		undefined,
		[1, 0], // Jul
		undefined,
		[1, 0], // Sep
		undefined,
		undefined,
		[2, 1, 0], // Dec
	],
	'grain-soybeans': [
		undefined,
		[1, 0], // Jan
		undefined,
		[1, 0], // Mar
		undefined,
		[1, 0], // May
		undefined,
		[1, 0], // Jul
		[0], // Aug
		[0], // Sep
		undefined,
		[1, 0], // Nov
		undefined,
	],
	'grain-soybean-meal': [
		undefined,
		[0], // Jan
		undefined,
		[1, 0], // Mar
		undefined,
		[1, 0], // May
		undefined,
		[1, 0], // Jul
		[0], // Aug
		[0], // Sept
		[0], // Oct
		undefined,
		[1, 0], // Dec
	],
};

// Pass in a future's display expiration date
// Return the months for which this future is the underlying
export function cmeFutureMonthToRelevantMonths(date: string, productSlug: string) {
	if (!isProductSlug(productSlug)) {
		throw new Error('Product information not found. Please add it to monthToFutureMonthAddends');
	}
	const dateTime = DateTime.fromISO(date);
	return (
		futureMonthToMonthSubtrahends[productSlug][dateTime.month]?.map((subtrahend) =>
			dateTime.minus({ months: subtrahend }).startOf('month').toISODate()
		) ?? []
	);
}
