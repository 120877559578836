import Route from '@ember/routing/route';
import { gql, useQuery } from 'glimmer-apollo';
import { tracked } from '@glimmer/tracking';
import {
	LgmInsuranceEndorsementFilterDTO,
	Count,
	LgmInsuranceEndorsement,
	Query_LgmInsuranceEndorsementsArgs,
	Query,
	Query_HedgeStrategyArgs,
} from 'vault-client/types/graphql-types';
import { DateTime } from 'luxon';

type SortObj = {
	valuePath: string;
	isAscending: boolean;
};

export const GET_HEDGE_STRATEGY = gql`
	query HedgeStrategy($id: String!) {
		HedgeStrategy(id: $id) {
			id
			businessId
			productId
			name
			InsuranceEndorsements {
				id
				pnl
				producerPremiumAmount
				type
				hedgeStrategyId
				InsurancePolicy {
					producerName
					State {
						id
						abbreviation
					}
					AIP {
						id
						name
					}
				}
				RmaType {
					id
					typeName
					typeCode
					RmaCommodity {
						commodityYear
						commodityName
					}
				}
				... on LgmInsuranceEndorsement {
					id
					perMonthData
					type
					coverageMonths
					grossMarginGuarantee
					totalTarget
					totalPremiumAmount
					totalExpectedGrossMargin
					pnl
					producerPremiumAmount
					salesEffectiveDate
					forecastedIndemnity
					deductibleAmount
					subsidyAmount
					hedgeStrategyId
					revenueHedgeProductId
					InsurancePolicy {
						id
						agentFullName
						State {
							abbreviation
						}
					}
				}
			}
		}
	}
`;

export const GET_LGM_INSURANCE_ENDORSEMENTS = gql`
	query LgmInsuranceEndorsements(
		$limit: Float
		$offset: Float
		$orderBy: LgmInsuranceEndorsementSortByDTO
		$where: LgmInsuranceEndorsementFilterDTO
	) {
		LgmInsuranceEndorsements(orderBy: $orderBy, limit: $limit, offset: $offset, where: $where) {
			id
			updatedAt
			type
			totalExpectedGrossMargin
			subsidyAmount
			stateId
			startDate
			salesEffectiveDate
			rmaTypeId
			rmaStatus
			reinsuranceYear
			producerPremiumAmount
			policyId
			perMonthData
			liability
			isShort
			isLong
			integrationInstallationId
			insurancePracticeId
			insurancePolicyId
			inputSource
			hasWriteAccess
			grossMarginGuarantee
			endDate
			effectiveHedgeMonth
			deductibleAmount
			createdAt
			coverageMonths
			ccSubsidyReductionPercent
			ccSubsidyReductionAmount
			bfrVfrSubsidyAmount
			baseSubsidyAmount
			aoExpenseSubsidyAmount
			agentId
			forecastedIndemnity
			pnl
			hedgeStrategyId
			revenueHedgeProductId
			InsurancePractice {
				id
				startDate
				reinsuranceYear
				programType
				practiceCode
				practiceAbbreviation
				endDate
				commodityCode
				InsuranceEndorsements {
					id
				}
			}
			InsurancePolicy {
				id
				agentFullName
				producerName
				aipId
				agencyName
				AIP {
					name
					id
				}
				State {
					name
					abbreviation
					rmaStateCode
				}
			}
			InsuranceAgent {
				id
				fullName
			}
			RmaType {
				typeName
				commodityCode
				typeCode
				RmaCommodity {
					commodityYear
					commodityName
				}
			}
		}
		LgmInsuranceEndorsementCount(where: $where) {
			count
		}
	}
`;

type GetLgmInsuranceEndorsements = {
	__typename?: 'Query';
	customers: Array<LgmInsuranceEndorsement>;
	LgmInsuranceEndorsementCount: Count;
	LgmInsuranceEndorsements?: LgmInsuranceEndorsement[];
};

type HedgeStrategyQuery = Query['HedgeStrategy'] & {
	HedgeStrategy: Query['HedgeStrategy'];
};
export default class BusinessesBusinessHedgeStrategiesEditLgmEndorsementsIndexRoute extends Route {
	@tracked variables = {};
	@tracked hedgeStrategyVariables = { id: '' };
	@tracked errorMessage: string | null = null;
	@tracked productId: string | null | undefined = null;

	queryParams = {
		page: { refreshModel: true },
		sorts: { refreshModel: true },
		size: { refreshModel: true },
		salesEffectiveStartDate: { refreshModel: true },
		salesEffectiveEndDate: { refreshModel: true },
		agent: { refreshModel: true },
		aipId: { refreshModel: true },
		stateId: { refreshModel: true },
		customerId: { refreshModel: true },
	};

	getHedgeStrategy = useQuery<HedgeStrategyQuery, Query_HedgeStrategyArgs>(this, () => [
		GET_HEDGE_STRATEGY,
		{ variables: this.hedgeStrategyVariables },
	]);

	getLgmEndorsements = useQuery<GetLgmInsuranceEndorsements, Query_LgmInsuranceEndorsementsArgs>(this, () => [
		GET_LGM_INSURANCE_ENDORSEMENTS,
		{
			variables: this.variables,
			onComplete: () => {
				this.errorMessage = null;
			},
			onError: (error) => {
				this.errorMessage = 'There was an error. LGM insurance policies were not retrieved.';
				console.error('Error while attempting to retrieve LGM insurance policies.', error.message);
			},
		},
	]);

	generateWhere(
		strategyId: string | null,
		customerId: string | null | undefined,
		productId: string | null | undefined,
		aipId: string | null | undefined,
		agent: string | null | undefined,
		stateId: string | null | undefined,
		salesEffectiveStartDate: string | null | undefined,
		salesEffectiveEndDate: string | null | undefined
	): LgmInsuranceEndorsementFilterDTO {
		const where: LgmInsuranceEndorsementFilterDTO = {
			// Only show endorsements that either do not have a Hedge Strategy or match this Hedge Strategy.
			OR: [
				{
					hedgeStrategyId: {
						equals: strategyId,
					},
				},
				{
					hedgeStrategyId: {
						equals: null,
					},
				},
			],
		};

		if (customerId) {
			if (!where.Policy) {
				where.Policy = {};
			}
			where.Policy.customerId = {
				equals: customerId,
			};
		}

		// if the hedge strategy already has been associated with a Product/Commodity only show endorsements respective to the associated Product/Commodity
		if (productId) {
			where.revenueHedgeProductId = {
				equals: productId,
			};
		}

		if (aipId) {
			if (!where.Policy) {
				where.Policy = {};
			}
			where.Policy.aipId = {
				equals: aipId,
			};
		}

		if (agent) {
			if (!where.Policy) {
				where.Policy = {};
			}
			where.Policy.agentFullName = {
				equals: agent,
			};
		}

		if (stateId) {
			if (!where.Policy) {
				where.Policy = {};
			}
			where.Policy.stateId = {
				equals: stateId,
			};
		}

		if (salesEffectiveStartDate && salesEffectiveEndDate) {
			if (!where.AND) {
				where.AND = [];
			}
			where.AND.push({
				salesEffectiveDate: { gte: salesEffectiveStartDate },
			});
			where.AND.push({
				salesEffectiveDate: { lte: salesEffectiveEndDate },
			});
		} else if (salesEffectiveStartDate) {
			where.salesEffectiveDate = {
				gte: salesEffectiveStartDate,
			};
		} else if (salesEffectiveEndDate) {
			where.salesEffectiveDate = {
				lte: salesEffectiveEndDate,
			};
		}

		return where;
	}

	generateOrderBy(sorts: SortObj[]) {
		const orderBy: {
			[key: string]: any;
		} = {};

		sorts.forEach((sort: any) => {
			const parts = sort.valuePath.split('.');
			const firstPart = parts.shift();

			let value: unknown = sort.isAscending ? 'Asc' : 'Desc';

			while (parts.length > 0) {
				const part = parts.pop();
				const obj: {
					[key: string]: any;
				} = {};
				obj[part] = value;
				value = obj;
			}

			orderBy[firstPart] = value;
		});

		return orderBy;
	}

	async model(params: any) {
		this.hedgeStrategyVariables = { id: params.strategy_id };

		await this.getHedgeStrategy.promise;

		this.productId = this.getHedgeStrategy?.data?.HedgeStrategy?.productId;

		this.variables = {
			where: this.generateWhere(
				params.strategy_id,
				this.getHedgeStrategy?.data?.HedgeStrategy?.businessId,
				this.getHedgeStrategy?.data?.HedgeStrategy?.productId,
				params.aipId,
				params.agent,
				params.stateId,
				params.salesEffectiveStartDate,
				params.salesEffectiveEndDate
			),
			orderBy: this.generateOrderBy(params.sorts),
			offset: params.page * params.size,
			limit: params.size,
		};

		await this.getLgmEndorsements.promise;

		return {
			LgmInsuranceEndorsements: this.getLgmEndorsements,
			HedgeStrategy: this.getHedgeStrategy,
			lastUpdatedAt: DateTime.now().toISO(),
		};
	}
}
