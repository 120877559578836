import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { queryManager } from 'ember-apollo-client';
import query from 'vault-client/graphql/queries/insurance-policies/index.graphql';
import searchQuery from 'vault-client/graphql/queries/search.graphql';
import agentFuzzyQuery from 'vault-client/graphql/queries/agent-fuzzy-match.graphql';
import stateFuzzyQuery from 'vault-client/graphql/queries/state-fuzzy-match.graphql';
import InsurancePolicy from 'vault-client/models/insurance-policy';
import getFilterDisplayProperty from 'vault-client/utils/get-filter-display-property';

import { inject as service } from '@ember/service';
import resetVaultTableScroll from 'vault-client/utils/reset-vault-table-scroll';
import BusinessesPolicies from 'vault-client/routes/businesses/business/insurance-policies';
import OrganizationsPolicies from 'vault-client/routes/organizations/organization/insurance-policies';
import { ModelFrom } from 'vault-client/utils/type-utils';
import { CellComponents, TableColumn } from 'vault-client/types/vault-table';
export default class InsurancePoliciesIndexController extends Controller {
	@queryManager apollo: any;
	@service applicationScope: any;

	declare model: ModelFrom<OrganizationsPolicies> | ModelFrom<BusinessesPolicies>;

	queryParams = ['agent', 'aipId', 'stateId', 'page', 'sorts', 'size'];

	itemsFn(rows: any) {
		return rows.map((row: any) => {
			return new InsurancePolicy(row);
		});
	}
	@tracked agent: string | undefined;
	@tracked aipId: string | undefined;
	@tracked policyNumber: string | undefined;
	@tracked stateId: string | undefined;
	@tracked page = 0;
	@tracked size = 100;
	@tracked sorts = [{ valuePath: 'producerName', isAscending: true }];
	screenWidth = window.self.innerWidth;

	insurancePolicyRoute: string = '';
	insuranceRoutePath: string = '';

	get columns() {
		const baseColumns: TableColumn[] = [
			{
				id: '2bdde237-4ce6-4399-9a21-2d1788adcc92',
				name: 'Policy Number',
				valuePath: 'policyNumber',
				minWidth: 150,
				textAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				linkRoute: this.insurancePolicyRoute,
				linkModelPath: 'id',
			},
			{
				id: 'e9c0b07b-324e-4023-b8af-83ded4935f48',
				name: 'Producer',
				valuePath: 'producerName',
				minWidth: 180,
				textAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'cc6d7c01-7c67-4834-9205-b0f7bd9b6313',
				name: 'Type',
				valuePath: 'programType',
				minWidth: 50,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '033fd353-f3fb-4843-9b77-37d23d361a65',
				name: 'State',
				valuePath: 'State.abbreviation',
				minWidth: 80,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '965cd106-0d4c-493a-a894-15345096e988',
				name: 'AIP',
				valuePath: 'AIP.name',
				minWidth: 120,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'f1332755-43a1-40ad-8dfa-cf287b7e26c0',
				name: 'Agency',
				valuePath: 'agencyName',
				width: 220,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: false,
			},
			{
				id: 'd04957b9-0926-4f52-8089-01a586a4decd',
				name: 'Agent',
				valuePath: '',
				minWidth: 105,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,

				subcolumns: [
					{
						id: '4e5c4095-da4a-4365-99ee-2fdf5ab90cc2',
						name: 'First Name',
						valuePath: 'agentFirstName',
						minWidth: 105,
						textAlign: 'left',
						isSortable: true,
						cellComponent: CellComponents.String,
						isFixed: '',
						isVisible: true,
					},
					{
						id: '774a88ea-a1c3-403b-9f24-15f3c22dcc6a',
						name: 'Last Name',
						valuePath: 'agentLastName',
						minWidth: 105,
						textAlign: 'left',
						isSortable: true,
						cellComponent: CellComponents.String,
						isFixed: '',
						isVisible: true,
					},
				],
			},
		];
		return baseColumns;
	}

	get sortParams() {
		return this.sorts;
	}

	set sortParams(sorts) {
		this.sorts = sorts;
	}

	get currentPage() {
		return this.page;
	}

	set currentPage(page) {
		this.page = page;
	}

	get insurancePolicyData() {
		return this.itemsFn(this.model.InsurancePolicies);
	}

	get totalNumInsurancePolicies() {
		return this.model.InsurancePolicyCount.count;
	}

	get policiesHasWriteAccess() {
		return this.model.Entity?.CurrentUserPermissions?.canWriteInsurance;
	}

	get searchFilterQueryParams() {
		const obj: {
			[key: string]: any;
		} = {};
		const searchQueryParams = ['agent', 'aipId', 'policyNumber', 'stateId'];
		searchQueryParams.forEach((param) => {
			//@ts-ignore
			const value = this[param];

			if (value) {
				obj[param] = {
					filterRule: 'equals',
					filterValue: value,
				};

				// set filterComponent property to specify component for custom display extended from search-filter
				const filterDisplayObj = getFilterDisplayProperty(param);
				if (filterDisplayObj && filterDisplayObj.customComponent) {
					obj[param].filterComponent = filterDisplayObj.customComponent;
				}
				// set filterLabel property to specify custom label for filter - default would be filterIdentifier (matches queryParam)
				if (filterDisplayObj && filterDisplayObj.label) {
					obj[param].filterLabel = filterDisplayObj.label;
				}
			}
		});

		return obj;
	}

	get searchPlaceholder() {
		return 'Filter by AIP, agent, policy number, state...';
	}

	get searchPrompt() {
		return 'Type a search term to find policies by AIP, policy number, agent name, or state.';
	}

	// pass gridFilters to ui-grid - gets queryParams and formats for graphql query
	get gridFilters() {
		const obj: { where: { AND: unknown[]; OR: unknown[] } } = { where: { AND: [], OR: [] } };
		const searchParams = this.searchFilterQueryParams;

		// setting filters from search-filters
		for (const param in searchParams) {
			const filterRule = searchParams[param].filterRule;
			const filterValue = searchParams[param].filterValue;

			if (param === 'agent') {
				const nameArr = filterValue.split(' ');
				//@ts-ignore
				obj.where.AND.push({ agentFirstName: { equals: nameArr[0] } });
				//@ts-ignore
				obj.where.AND.push({ agentLastName: { equals: nameArr[1] } });
			} else {
				//@ts-ignore
				obj.where[param] = {};
				//@ts-ignore
				obj.where[param][filterRule] = filterValue;
			}
		}

		// setting filter for organizationId for organization scope
		if (this.applicationScope.organizationId) {
			obj.where.OR.push({
				Customer: {
					organizationId: {
						//@ts-ignore
						equals: this.applicationScope.organizationId,
					},
				},
			});
			obj.where.OR.push({
				ownerId: {
					//@ts-ignore
					equals: this.applicationScope.organizationId,
				},
			});
		}

		// setting filter for globalCustomerId for customer scope
		if (this.applicationScope.globalCustomerId) {
			//@ts-ignore
			obj.where.customerId = { equals: this.applicationScope.globalCustomerId };
		}

		return obj;
	}

	get query() {
		return query;
	}

	@action
	async fetchSearchResults(searchText: any) {
		let searchApiResults = [];
		const agentResults: any[] = [];
		const stateResults: any[] = [];
		const insurancePolicyResults: any[] = [];

		// get fuzzy match agents
		const agentVariables = {
			where: {
				OR: [
					{ agentFirstName: { contains: searchText } },
					{ agentLastName: { contains: searchText } },
					{ agentFullName: { contains: searchText } },
				],
			},
		};
		const agentsArr = (
			await this.apollo.watchQuery({
				query: agentFuzzyQuery,
				variables: agentVariables,
			})
		).InsurancePolicies.map((agent: any) => `${agent.agentFirstName} ${agent.agentLastName}`);

		const agentSet = new Set(agentsArr);

		for (const agent of agentSet) {
			const obj: {
				[key: string]: any;
			} = {};
			obj.type = 'Agent';
			obj.name = agent;
			agentResults.push(obj);
		}

		// get fuzzy match state
		const stateVariables = {
			where: {
				OR: [{ abbreviation: { contains: searchText } }, { name: { contains: searchText } }, { id: { contains: searchText } }],
			},
		};

		const states = (
			await this.apollo.watchQuery({
				query: stateFuzzyQuery,
				variables: stateVariables,
			})
		).States;

		states.forEach((state: any) => {
			const obj: {
				[key: string]: any;
			} = {};
			obj.type = 'State';
			obj.id = state.id;
			obj.name = state.name;
			stateResults.push(obj);
		});

		// Get fuzzy match policy numbers
		const insurancePolicyVariables = {
			limit: 5,
			...this.model.variables,
		};

		insurancePolicyVariables.where.policyNumber = { contains: searchText };

		let insurancePolicies = (await this.apollo.watchQuery({ query, variables: insurancePolicyVariables })).InsurancePolicies;

		// Remove Insurance Policies with duplicate policy numbers
		insurancePolicies = insurancePolicies.filter(
			(value: any, idx: any, arr: any) => arr.findIndex((p: any) => p.policyNumber === value.policyNumber) === idx
		);

		insurancePolicies.forEach((policy: any) => {
			const obj = {
				type: 'InsurancePolicy',
				id: policy.policyNumber,
				name: policy.policyNumber,
			};

			insurancePolicyResults.push(obj);
		});

		// get search API matches
		const variables = {
			query: searchText,
			limit: 10,
			typesToInclude: ['Aip'],
		};
		searchApiResults = (await this.apollo.watchQuery({ query: searchQuery, variables })).Search;

		// return combined set
		return [...agentResults, ...searchApiResults, ...insurancePolicyResults, ...stateResults];
	}

	@action
	structureSearchResults(searchResults: any) {
		const map = new Map();

		searchResults.forEach((item: any) => {
			if (map.has(item.type)) {
				map.get(item.type).push({ id: item.id, name: item.name, type: item.type });
			} else {
				map.set(item.type, [{ id: item.id, name: item.name, type: item.type }]);
			}
		});

		return map;
	}

	@action
	setSearchFilterQueryParam(searchResult: any) {
		const mappedSearchFilter = this.mapSearchResult(searchResult);
		//@ts-ignore
		this[mappedSearchFilter.filterIdentifier] = mappedSearchFilter.filterValue;
		this.setTablePageState();
	}

	mapSearchResult(searchResult: any) {
		let filterIdentifier;

		switch (searchResult.type) {
			case 'Agent':
				filterIdentifier = 'agent';
				break;
			case 'Aip':
				filterIdentifier = 'aipId';
				break;
			case 'State':
				filterIdentifier = 'stateId';
				break;
			case 'InsurancePolicy':
				filterIdentifier = 'policyNumber';
				break;
		}

		return {
			filterIdentifier,
			filterValue: searchResult.id ?? searchResult.name,
		};
	}

	@action
	clearSearchFilterQueryParam(filterIdentifier: any) {
		//@ts-ignore
		this[`${filterIdentifier}`] = null;
		this.setTablePageState();
	}

	@action
	setTablePageState(newPageVal = 0) {
		this.currentPage = newPageVal;
		resetVaultTableScroll('insurance-policy-table');
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'insurance-policies-index-controller': InsurancePoliciesIndexController;
	}
}
