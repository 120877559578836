import resetElementScroll from '../utils/reset-element-scroll';

interface ResetVaultTableScroll {
	tableWrapperScrollPosition?: number;
	emberTableScrollPosition?: number;
}

export default function resetVaultTableScroll(tableId: string): ResetVaultTableScroll {
	const scrollableOuterDiv = document?.getElementById(`wrapper-${tableId}`);
	const emberTableScrollableEl = document?.getElementById(tableId)?.querySelector('.ember-table-overflow');
	const tableWrapperScrollPosition = resetElementScroll(scrollableOuterDiv);
	const emberTableScrollPosition = resetElementScroll(emberTableScrollableEl);
	return { tableWrapperScrollPosition, emberTableScrollPosition };
}
