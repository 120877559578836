import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<span class='tool-tip-reveal' aria-describedby='location-name-hint' {{did-update this.fetchName @locationId}}>\n\t{{#if this.name}}\n\t\t{{this.name}}\n\t\t<Vault::UiToolTip @id='location-name-hint' aria-hidden='true' class='mt-2' @text={{this.name}} />\n\t{{else}}\n\t\t...\n\t{{/if}}\n</span>", {"contents":"<span class='tool-tip-reveal' aria-describedby='location-name-hint' {{did-update this.fetchName @locationId}}>\n\t{{#if this.name}}\n\t\t{{this.name}}\n\t\t<Vault::UiToolTip @id='location-name-hint' aria-hidden='true' class='mt-2' @text={{this.name}} />\n\t{{else}}\n\t\t...\n\t{{/if}}\n</span>","moduleName":"vault-client/components/location-name.hbs","parseOptions":{"srcName":"vault-client/components/location-name.hbs"}});
import Component from '@glimmer/component';
import { queryManager } from 'ember-apollo-client';
import query from 'vault-client/graphql/queries/location-get.graphql';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

interface LocationNameArgs {
	locationId: String;
}

export default class LocationName extends Component<LocationNameArgs> {
	@queryManager apollo: any;

	@tracked name = '';

	constructor(owner: any, args: any) {
		super(owner, args);
		this.fetchName();
	}

	@action
	async fetchName() {
		if (this.args.locationId) {
			this.name = (
				await this.apollo.watchQuery({
					query,
					variables: { id: this.args.locationId },
				})
			).Location?.name;
		} else {
			this.name = 'N/A';
		}
	}
}
