import Controller from '@ember/controller';
import StrategicBuyingModel from '../../models/strategic-buying-model';
import { getOwner } from '@ember/application';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { Product } from 'vault-client/types/graphql-types';
import isTouchDevice from 'vault-client/utils/is-touch-device';
import { TableColumn, CellComponents } from 'vault-client/types/vault-table';
import { ModelFrom } from 'vault-client/utils/type-utils';
import ReportsButterStrategicBuyingModelRoute from 'vault-client/routes/reports/butter-strategic-buying-model';

interface HtmlElement {
	target: HTMLInputElement;
}

export default class ReportsButterStrategicBuyingModel extends Controller {
	@tracked carryCost: number = 0.01;
	@tracked currentPriceInput: string = 'Front Future Price';
	@tracked canBuySpot: boolean = false;
	@tracked numberOfYears: number = 5;
	declare model: ModelFrom<ReportsButterStrategicBuyingModelRoute>;

	reportsRoute: string = '';

	get columns(): TableColumn[] {
		const baseColumns: TableColumn[] = [
			{
				id: 'd5ab4581-c091-4bc9-ba01-111ad00923f0',
				name: 'Month',
				valuePath: 'displayExpiresAt',
				minWidth: 120,
				cellComponent: CellComponents.MonthFormat,
				textAlign: 'left',
				isSortable: false,
				isFixed: !isTouchDevice() ? 'left' : '',
				isVisible: true,
			},
			{
				id: 'e66a979b-1165-409e-a250-3a514cb6affd',
				name: 'Futures',
				valuePath: 'currentFuturePrice',
				minWidth: 120,
				cellComponent: CellComponents.PriceFormat,
				componentArgs: {
					fractionDigitsPath: 'product.MostCurrentFuture.SymbolGroup.fractionDigits',
					displayFactorPath: 'noDisplayFactor',
				},
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'ae820e32-648c-4199-a8e3-6a0ff5fd9e64',
				name: 'Futures - Carry',
				valuePath: 'futuresMinusCarryCost',
				minWidth: 120,
				cellComponent: CellComponents.PriceFormat,
				componentArgs: {
					fractionDigitsPath: 'product.MostCurrentFuture.SymbolGroup.fractionDigits',
					displayFactorPath: 'noDisplayFactor',
				},
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: this.canBuySpot,
			},
			{
				id: '8902b849-f45a-4434-95e6-750c2004ce9c',
				name: 'Current',
				valuePath: 'currentPrice',
				minWidth: 120,
				cellComponent: CellComponents.PriceFormat,
				componentArgs: {
					fractionDigitsPath: 'product.MostCurrentFuture.SymbolGroup.fractionDigits',
					displayFactorPath: 'noDisplayFactor',
				},
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: this.canBuySpot,
			},
			{
				id: '6765e5e6-18bd-4120-8f8f-4306126233e2',
				name: '1 Mo Carry Incentive',
				valuePath: 'oneMonthCarryIncentive',
				minWidth: 120,
				cellComponent: CellComponents.PriceFormat,
				componentArgs: {
					fractionDigitsPath: 'product.MostCurrentFuture.SymbolGroup.fractionDigits',
					displayFactorPath: 'noDisplayFactor',
				},
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: this.canBuySpot,
			},
			{
				id: '7ad796f8-60d5-4860-998f-3f39a4b4c8b7',
				name: 'Strategy',
				valuePath: 'riskManagementStrategy',
				minWidth: 120,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
		];

		return baseColumns.filter((column) => column.isVisible);
	}

	prevSims: {
		oneMonthCarryIncentive: number | null;
		displayExpiresAt: string;
		symbol: string;
	}[] = [];

	get spotProduct() {
		const products: Product[] = this.model.Products;
		const cashProduct = products?.find((product: Product) => product?.slug === 'us-dairy-butter-grade-aa');
		return cashProduct ?? null;
	}

	get futuresProduct() {
		const products: Product[] = this.model.Products;
		return products?.find((product) => product.slug === 'us-dairy-butter');
	}

	get frontFuture() {
		return this.futuresProduct?.CurrentFutures[0];
	}

	get months() {
		const owner = getOwner(this);
		const product = this.futuresProduct;
		return product?.CurrentFutures?.reduce((acc: any, future: any) => {
			const previousSims = acc.slice(-2);
			const currentSim = new StrategicBuyingModel(
				owner,
				product,
				future,
				this.carryCost,
				previousSims,
				this.spotProduct,
				this.currentPriceInput,
				this.canBuySpot,
				this.numberOfYears
			);

			acc.push(currentSim);
			return acc;
		}, []);
	}

	@action
	currentPriceRadioInput(e: HtmlElement) {
		return (this.currentPriceInput = e.target.value);
	}

	@action
	buySpotCheck(e: HtmlElement) {
		// update the 'Front Future Price' radio button to be selected when 'Can Buy Spot' is unchecked
		if (!e.target.checked) {
			this.currentPriceInput = 'Front Future Price';
			const element = document.getElementById('frontFuturePriceInput') as HTMLInputElement;
			element!.checked = true;
		}
		return (this.canBuySpot = e.target.checked);
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'reports/butter-strategic-buying-model': ReportsButterStrategicBuyingModel;
	}
}
