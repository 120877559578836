import Component from '@glimmer/component';

interface ResultArgs {
	query: any;
}

export default class Result extends Component<ResultArgs> {
	// workaround for: @query must be and object -- https://github.com/emberjs/ember.js/issues/19693 -dmw
	get query() {
		return this.args.query ?? {};
	}
}
