import Controller from '@ember/controller';
import { DateTime } from 'luxon';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import query from 'vault-client/graphql/queries/locations/forecasted-milk-utilizations.graphql';
import {
	ForecastedMilkUtilization,
	Future,
	AggregateLedgerForecastedEntryDTO,
	AggregateForecastedMilkProductionByMonthDTO,
} from 'vault-client/types/graphql-types';
import { getOwner } from '@ember/application';
import { CellComponents, TableColumn } from 'vault-client/types/vault-table';
import MilkCheck from 'vault-client/models/milk-check';
import LocationsLocationMilkCheckRoute from 'vault-client/routes/locations/location/milk-check';
import { ModelFrom } from 'vault-client/utils/type-utils';
export interface IMonthObject {
	date: string;
	utilizations?: ForecastedMilkUtilization;
	classiiiFuture: Future | null;
	classivFuture: Future | null;
	butterFuture: Future | null;
	advancedNonfatDryMilkFuture: Future | null;
	advancedButterFuture: Future | null;
	advancedCheeseFuture: Future | null;
	advancedDryWheyFuture: Future | null;
	monthlyCalculatedAmount?: number | null | undefined;
}

interface IMonthObjects {
	[key: string]: IMonthObject;
}

export default class LocationsMilkCheck extends Controller {
	declare model: ModelFrom<LocationsLocationMilkCheckRoute>;

	@tracked organizationId: string | null = null;
	@tracked globalCustomerId: string | null = null;
	@tracked locationId: string | null = null;
	@tracked productionMonthStartDate = DateTime.local().startOf('month').toISODate();
	@tracked productionMonthEndDate = DateTime.local().plus({ months: 23 }).endOf('month').toISODate();
	@tracked selectedRows: MilkCheck[] = [];

	@tracked columns: TableColumn[] = [
		{
			id: '9f504d3b-9d4a-46a7-a87d-69e43ca9e7b2',
			name: 'Month',
			valuePath: 'date',
			minWidth: 80,
			cellComponent: CellComponents.MonthFormat,
			textAlign: 'left',
			isSortable: false,
			isFixed: '',
			isVisible: true,
		},
		{
			id: '6f170819-3067-45a8-a718-16d254252cd0',
			name: 'Class Utilizations',
			cellComponent: CellComponents.String,
			isVisible: true,
			isFixed: '',
			subcolumns: [
				{
					id: 'b1679cf1-6a5b-4fb9-b4d7-628b80fb8056',
					name: 'I',
					valuePath: 'milkUtilizations.classiUtilization',
					cellComponent: CellComponents.IntlNumberFormat,
					width: 40,
					componentArgs: {
						style: 'percent',
					},
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
				{
					id: 'c1c88fc3-2a0a-4010-b1da-8e2aaf5de318',
					name: 'II',
					valuePath: 'milkUtilizations.classiiUtilization',
					cellComponent: CellComponents.IntlNumberFormat,
					width: 40,
					componentArgs: {
						style: 'percent',
					},
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
				{
					id: 'fa902e88-4455-41a6-8608-e66f31f15826',
					name: 'III',
					valuePath: 'milkUtilizations.classiiiUtilization',
					cellComponent: CellComponents.IntlNumberFormat,
					width: 40,
					componentArgs: {
						style: 'percent',
					},
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
				{
					id: '86fbab14-0c1d-40f3-a497-e6861df2476b',
					name: 'IV',
					valuePath: 'milkUtilizations.classivUtilization',
					cellComponent: CellComponents.IntlNumberFormat,
					width: 40,
					componentArgs: {
						style: 'percent',
					},
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
			],
		},
		{
			id: '79a58287-c786-4a6a-b5d1-2f03b88b1fa9',
			name: 'Class Prices',
			cellComponent: CellComponents.String,
			isFixed: '',
			isVisible: true,
			subcolumns: [
				{
					id: '2a67d67d-330f-4917-a31f-96ef3b0e5dd5',
					name: 'I',
					valuePath: 'classiPrice',
					cellComponent: CellComponents.IntlNumberFormat,
					width: 40,
					componentArgs: {
						style: 'currency',
						currency: 'USD',
					},
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
				{
					id: 'bd3da4ac-b3ad-4d6d-8d00-e30100fa8bae',
					name: 'II',
					valuePath: 'classiiPrice',
					cellComponent: CellComponents.IntlNumberFormat,
					width: 40,
					componentArgs: {
						style: 'currency',
						currency: 'USD',
					},
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
				{
					id: '00c36c6a-023d-44ea-9fee-e990c29bdeaa',
					name: 'III',
					valuePath: 'classiiiPrice',
					cellComponent: CellComponents.IntlNumberFormat,
					width: 40,
					componentArgs: {
						style: 'currency',
						currency: 'USD',
					},
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
				{
					id: '495e21f9-3733-4249-b032-6833025fee6e',
					name: 'IV',
					valuePath: 'classivPrice',
					cellComponent: CellComponents.IntlNumberFormat,
					width: 40,
					componentArgs: {
						style: 'currency',
						currency: 'USD',
					},
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
			],
		},
		{
			id: '5496eabe-3b20-413d-8ace-ebb018f370f3',
			name: 'Projected Prices',
			cellComponent: CellComponents.String,
			isFixed: '',
			isVisible: true,
			subcolumns: [
				{
					id: '6f9f1c43-1348-4831-88fc-b49ddb5853a5',
					name: 'Blended',
					valuePath: 'blendedPrice',
					width: 100,
					cellComponent: CellComponents.IntlNumberFormat,
					componentArgs: {
						style: 'currency',
						currency: 'USD',
					},
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
				{
					id: '0f897dd9-af8e-40a5-850c-e19f5be4e233',
					name: 'Forecasted Basis',
					valuePath: 'everAgBasis',
					minWidth: 140,
					cellComponent: CellComponents.IntlNumberFormat,
					componentArgs: {
						style: 'currency',
						currency: 'USD',
					},
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
				{
					id: 'b9587719-5186-45fa-856d-c25f6c866963',
					name: 'Prem / Ded',
					valuePath: 'monthlyCalculatedAmount',
					width: 140,
					cellComponent: CellComponents.IntlNumberFormat,
					componentArgs: {
						style: 'currency',
						currency: 'USD',
					},
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
				{
					id: '016c2c88-e885-4ad9-8e3a-068c995f4a51',
					name: 'Mailbox Price',
					valuePath: 'mailboxPrice',
					width: 140,
					cellComponent: CellComponents.IntlNumberFormat,
					componentArgs: {
						style: 'currency',
						currency: 'USD',
					},
					textAlign: 'right',
					isSortable: false,
					isFixed: '',
					isVisible: true,
				},
			],
		},
	];

	queryParams = ['productionMonthStartDate', 'productionMonthEndDate'];

	productionMonthRangeOptions = [
		{
			displayName: 'Next 24 Months',
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().plus({ months: 23 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Previous 24 Months',
			startDate: DateTime.local().minus({ months: 24 }).startOf('month').toISODate(),
			endDate: DateTime.local().endOf('month').toISODate(),
		},
		{
			displayName: 'Next 12 Months',
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().plus({ months: 11 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Previous 12 Months',
			startDate: DateTime.local().minus({ months: 12 }).startOf('month').toISODate(),
			endDate: DateTime.local().endOf('month').toISODate(),
		},
		{
			displayName: `Calendar Year (${DateTime.local().year}) `,
			startDate: DateTime.local().startOf('year').toISODate(),
			endDate: DateTime.local().endOf('year').toISODate(),
		},
	];

	get showUpdateValuesButton() {
		return this.selectedRows.length > 0;
	}

	get currentDateRange() {
		return {
			startDate: DateTime.fromISO(this.productionMonthStartDate).toISODate(),
			endDate: DateTime.fromISO(this.productionMonthEndDate).toISODate(),
		};
	}

	get locationBasis() {
		return this.model.getMilkCheckData.data?.Location.currentBasis;
	}

	get initialRollingBasisMonths() {
		return this.model.getMilkCheckData.data?.Location.rollingBasisNumberOfMonths;
	}

	get initialCustomBasis() {
		return this.model.getMilkCheckData.data?.Location.customBasis;
	}

	get milkChecks() {
		const monthObjects: IMonthObjects = {};

		this.model.getMilkCheckData.data?.ForecastedMilkUtilizations.forEach((utilizations: ForecastedMilkUtilization) => {
			let monthObject = monthObjects[utilizations.date];

			if (monthObject === undefined) {
				monthObject = {
					date: utilizations.date,
					classiiiFuture: null,
					classivFuture: null,
					butterFuture: null,
					advancedNonfatDryMilkFuture: null,
					advancedButterFuture: null,
					advancedCheeseFuture: null,
					advancedDryWheyFuture: null,
				};
				monthObjects[utilizations.date] = monthObject;
			} else {
				console.error('Duplicate utilizations returned from server.');
			}

			monthObject.utilizations = utilizations;
		});

		this.model.getMilkCheckData.data?.ClassIIIFutures?.forEach((future: Future) => {
			let monthObject = monthObjects[future.displayExpiresAt];

			if (monthObject === undefined) {
				console.error(`Utilizations <-> Class III Futures mismatch. ${future.displayExpiresAt}`);
				monthObject = {
					date: future.displayExpiresAt,
					classiiiFuture: null,
					classivFuture: null,
					butterFuture: null,
					advancedButterFuture: null,
					advancedCheeseFuture: null,
					advancedDryWheyFuture: null,
					advancedNonfatDryMilkFuture: null,
				};
				monthObjects[future.displayExpiresAt] = monthObject;
			}

			monthObject.classiiiFuture = future;
		});

		this.model.getMilkCheckData.data?.ClassIVFutures?.forEach((future: Future) => {
			let monthObject = monthObjects[future.displayExpiresAt];

			if (monthObject === undefined) {
				console.error(`Utilizations <-> Class IV Futures mismatch. ${future.displayExpiresAt}`);
				monthObject = {
					date: future.displayExpiresAt,
					classiiiFuture: null,
					classivFuture: null,
					butterFuture: null,
					advancedNonfatDryMilkFuture: null,
					advancedButterFuture: null,
					advancedCheeseFuture: null,
					advancedDryWheyFuture: null,
				};
				monthObjects[future.displayExpiresAt] = monthObject;
			}

			monthObject.classivFuture = future;
		});

		this.model.getMilkCheckData.data?.ButterFutures?.forEach((future: Future) => {
			let monthObject = monthObjects[future.displayExpiresAt];

			if (monthObject === undefined) {
				console.error(`Utilizations <-> Butter Futures mismatch. ${future.displayExpiresAt}`);
				monthObject = {
					date: future.displayExpiresAt,
					classiiiFuture: null,
					classivFuture: null,
					butterFuture: null,
					advancedNonfatDryMilkFuture: null,
					advancedButterFuture: null,
					advancedCheeseFuture: null,
					advancedDryWheyFuture: null,
				};
				monthObjects[future.displayExpiresAt] = monthObject;
			}

			const advancedDate = DateTime.fromISO(future.displayExpiresAt).plus({ month: 1 }).toISODate();

			let advancedMonthObject = monthObjects[advancedDate];

			if (advancedMonthObject === undefined) {
				console.error(`Utilizations <-> Butter Futures mismatch. ${future.displayExpiresAt}`);
				advancedMonthObject = {
					date: future.displayExpiresAt,
					classiiiFuture: null,
					classivFuture: null,
					butterFuture: null,
					advancedNonfatDryMilkFuture: null,
					advancedButterFuture: null,
					advancedCheeseFuture: null,
					advancedDryWheyFuture: null,
				};
				monthObjects[advancedDate] = advancedMonthObject;
			}

			monthObject.butterFuture = future;
			advancedMonthObject.advancedButterFuture = future;
		});

		this.model.getMilkCheckData.data?.NonfatDryMilkFutures?.forEach((future: Future) => {
			const advancedDate = DateTime.fromISO(future.displayExpiresAt).plus({ month: 1 }).toISODate();
			let monthObject = monthObjects[advancedDate];

			if (monthObject === undefined) {
				console.error(`Utilizations <-> Butter Futures mismatch. ${advancedDate}`);
				monthObject = {
					date: advancedDate,
					classiiiFuture: null,
					classivFuture: null,
					butterFuture: null,
					advancedNonfatDryMilkFuture: null,
					advancedButterFuture: null,
					advancedCheeseFuture: null,
					advancedDryWheyFuture: null,
				};
				monthObjects[advancedDate] = monthObject;
			}

			monthObject.advancedNonfatDryMilkFuture = future;
		});

		this.model.getMilkCheckData.data?.CheeseFutures?.forEach((future: Future) => {
			const advancedDate = DateTime.fromISO(future.displayExpiresAt).plus({ month: 1 }).toISODate();
			let monthObject = monthObjects[advancedDate];

			if (monthObject === undefined) {
				console.error(`Utilizations <-> Cheese Futures mismatch. ${advancedDate}`);
				monthObject = {
					date: advancedDate,
					classiiiFuture: null,
					classivFuture: null,
					butterFuture: null,
					advancedNonfatDryMilkFuture: null,
					advancedButterFuture: null,
					advancedCheeseFuture: null,
					advancedDryWheyFuture: null,
				};
				monthObjects[advancedDate] = monthObject;
			}

			monthObject.advancedCheeseFuture = future;
		});

		this.model.getMilkCheckData.data?.DryWheyFutures?.forEach((future: Future) => {
			const advancedDate = DateTime.fromISO(future.displayExpiresAt).plus({ month: 1 }).toISODate();
			let monthObject = monthObjects[advancedDate];

			if (monthObject === undefined) {
				console.error(`Utilizations <-> Dry Whey Futures mismatch. ${advancedDate}`);
				monthObject = {
					date: advancedDate,
					classiiiFuture: null,
					classivFuture: null,
					butterFuture: null,
					advancedNonfatDryMilkFuture: null,
					advancedButterFuture: null,
					advancedCheeseFuture: null,
					advancedDryWheyFuture: null,
				};
				monthObjects[advancedDate] = monthObject;
			}

			monthObject.advancedDryWheyFuture = future;
		});

		this.model.getLedgerEntries.data?.AggregateLedgerForecastedEntries?.forEach((entry: AggregateLedgerForecastedEntryDTO) => {
			let dateObject = {};

			if (entry.year && entry.month) {
				dateObject = { year: entry.year, month: entry.month, day: 1 };
			}

			const formattedDate = DateTime.fromObject(dateObject).toISODate();

			let monthObject = monthObjects[formattedDate];

			if (monthObject === undefined) {
				monthObject = {
					date: formattedDate,
					classiiiFuture: null,
					classivFuture: null,
					butterFuture: null,
					advancedNonfatDryMilkFuture: null,
					advancedButterFuture: null,
					advancedCheeseFuture: null,
					advancedDryWheyFuture: null,
				};
				monthObjects[formattedDate] = monthObject;
			}

			monthObject.monthlyCalculatedAmount = entry.sum.calculatedAmount;
		});

		let results = Object.entries(monthObjects).map(([date, monthObject]) => {
			const productionObj = this.model.getLedgerEntries.data?.AggregateForecastedMilkProductionByMonths?.find(
				(production: AggregateForecastedMilkProductionByMonthDTO) => {
					let dateObject = {};

					if (production.year && production.month) {
						dateObject = { year: production.year, month: production.month, day: 1 };
					}

					const formattedDate = DateTime.fromObject(dateObject).toISODate();
					return formattedDate == date;
				}
			);

			const grossProduction = productionObj?.sum.grossProduction;
			const otherSolidsPercentage =
				productionObj?.sum.grossOtherSolidsProduction != null && grossProduction
					? productionObj.sum.grossOtherSolidsProduction / grossProduction
					: null;
			const butterfatPercentage =
				productionObj?.sum.grossButterfatProduction != null && grossProduction
					? productionObj.sum.grossButterfatProduction / grossProduction
					: null;
			const proteinPercentage =
				productionObj?.sum.grossProteinProduction != null && grossProduction
					? productionObj.sum.grossProteinProduction / grossProduction
					: null;

			const production = productionObj ? productionObj : null;

			if (monthObject.utilizations && this.model.getMilkCheckData.data?.Location.County) {
				return new MilkCheck(
					getOwner(this),
					date,
					this.locationBasis ?? null,
					monthObject.utilizations,
					monthObject.classiiiFuture,
					monthObject.classivFuture,
					monthObject.butterFuture,
					monthObject.advancedNonfatDryMilkFuture,
					monthObject.advancedButterFuture,
					monthObject.advancedCheeseFuture,
					monthObject.advancedDryWheyFuture,
					this.model.getMilkCheckData.data?.Location.County.classIDifferential
						? this.model.getMilkCheckData.data?.Location.County.classIDifferential
						: undefined,
					monthObject.monthlyCalculatedAmount,
					production,
					otherSolidsPercentage,
					butterfatPercentage,
					proteinPercentage
				);
			}

			// This should never happen.
			console.error("We're missing some utilizations.");
			return;
		});

		results = results.filter((v) => v).slice(1, results.length - 1);

		return results;
	}

	get query() {
		return query;
	}

	@action
	setProductionMonthQueryParam(value: { startDate: string; endDate: string }) {
		this.productionMonthStartDate = value.startDate;
		this.productionMonthEndDate = value.endDate;
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'locations/milk-check': LocationsMilkCheck;
	}
}
