import Service from '@ember/service';
import { gql, useQuery } from 'glimmer-apollo';
import { Query } from 'vault-client/types/graphql-types';

export const GET_FEED_YEARS = gql`
	query GrainFeedPlans {
		GrainFeedPlans(orderBy: { feedYear: Asc }, distinctOn: { feedYear: true }) {
			id
			feedYear
		}
	}
`;

type GetFeedYears = {
	__typename?: 'Query';
	GrainFeedPlans: Query['GrainFeedPlans'];
};

export default class GetAvailableFeedYearsService extends Service {
	getFeedYears = useQuery<GetFeedYears>(this, () => [GET_FEED_YEARS]);

	get feedYears() {
		const availableFeedYears = new Set<number>();
		this.getFeedYears.data?.GrainFeedPlans.forEach((plan) => {
			plan.feedYear ? availableFeedYears.add(plan.feedYear) : null;
		});
		return Array.from(availableFeedYears);
	}
}

// Don't remove this declaration: this is what enables TypeScript to resolve
// this service using `Owner.lookup('service:get-available-feed-years')`, as well
// as to check when you pass the service name as an argument to the decorator,
// like `@service('get-available-feed-years') declare altName: GetAvailableFeedYearsService;`.
declare module '@ember/service' {
	interface Registry {
		'get-available-feed-years': GetAvailableFeedYearsService;
	}
}
