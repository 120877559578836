import { getOwner } from '@ember/application';
import { debounce } from '@ember/runloop';
import Service from '@ember/service';
import { TrackedMap, TrackedObject } from 'tracked-built-ins';
import checkStorageAvailable from 'vault-client/utils/check-storage-available';
import PermissionsService from './permissions';
import ENV from 'vault-client/config/environment';

type PriceType = 'market' | 'scenario';

type GetQuoteResponse = {
	symbol: string;
	tradeDate: string;
	lastPrice?: number;
	close?: number;
	settlement?: number;
	previousSettlement?: number;
};

type LocallyStoredPrices = Record<string, number | null>;

export default class ScenarioPricesService extends Service {
	priceStore = new TrackedMap<string, Record<PriceType, number | null>>();
	locallyStoredPrices: LocallyStoredPrices;
	symbolsToUpdateInLocalStorage: string[] = [];

	constructor(owner: any) {
		super(owner);
		this.locallyStoredPrices = this.getLocallyStoredPrices();
	}

	get permissions() {
		return getOwner(this).lookup('service:permissions') as PermissionsService;
	}

	get isPopulated() {
		return this.priceStore.size > 0;
	}

	get userId() {
		return this.permissions.userId ?? null;
	}

	get localStorageString(): string | null {
		return this.userId ? `scenario-prices.${this.userId}` : null;
	}

	// TODO: Remove after all users have migrated to the new storage key (added Mar 28, 2024)
	// - Preston Cobb
	get deprecatedLocalStorageString(): string | null {
		return this.userId ? `scenario-prices.${this.permissions.auth0UserId}` : null;
	}

	async addInstrument(barchartSymbol: string) {
		if (this.getMarketPrice(barchartSymbol)) return;

    const apiKey: string = ENV.barchartApiToken
		return fetch(
			`https://ondemand.websol.barchart.com/getQuote.json?apikey=${apiKey}&symbols=${barchartSymbol}&fields=settlement%2CpreviousSettlement`,
			{
				method: 'GET',
				headers: {},
			}
		)
			.then((res) => res.json())
			.then((json: { results: GetQuoteResponse[] }) => {
				const result = json.results[0];
				const marketPrice = result.settlement ?? result?.close ?? result?.lastPrice ?? null;
				const scenarioPrice = this.locallyStoredPrices[barchartSymbol] || marketPrice;

				this.setMarketPrice(barchartSymbol, marketPrice);
				this.setScenarioPrice(barchartSymbol, scenarioPrice);

				return Promise.resolve();
			});
	}

	async addInstruments(barchartSymbols: string[]) {
		const newSymbols = barchartSymbols.filter((symbol) => !this.getMarketPrice(symbol));
		const symbolChunks: string[][] = [];

		const chunkSize = 50;
		for (let i = 0; i < newSymbols.length; i += chunkSize) {
			symbolChunks.push(newSymbols.slice(i, i + chunkSize));
		}

    const apiKey: string = ENV.barchartApiToken
		return Promise.all(
			symbolChunks.map((symbols) => {
				return fetch(
					`https://ondemand.websol.barchart.com/getQuote.json?apikey=${apiKey}&symbols=${symbols.join(
						'%2C'
					)}&fields=settlement%2CpreviousSettlement`,
					{
						method: 'GET',
						headers: {},
					}
				)
					.then((res) => res.json())
					.then((json: { results: GetQuoteResponse[] }) => {
						json.results.forEach((result) => {
							const barchartSymbol = result.symbol;
							const marketPrice = result.settlement ?? result?.close ?? result?.lastPrice ?? null;
							const scenarioPrice = this.locallyStoredPrices[barchartSymbol] || marketPrice;

							this.setMarketPrice(barchartSymbol, marketPrice);
							this.setScenarioPrice(barchartSymbol, scenarioPrice);
						});

						return Promise.resolve();
					});
			})
		);
	}

	getMarketPrice(barchartSymbol: string) {
		return this.priceStore.get(barchartSymbol)?.market ?? null;
	}

	getScenarioPrice(barchartSymbol: string) {
		return this.priceStore.get(barchartSymbol)?.scenario ?? null;
	}

	setMarketPrice(barchartSymbol: string, marketPrice: number | null) {
		const entry = this.priceStore.get(barchartSymbol);

		if (entry) {
			entry.market = marketPrice;
		} else {
			const entry = new TrackedObject<Record<PriceType, number | null>>();
			entry.market = marketPrice;
			this.priceStore.set(barchartSymbol, entry);
		}
	}

	setScenarioPrice(barchartSymbol: string, scenarioPrice: number | null) {
		const entry = this.priceStore.get(barchartSymbol);

		if (entry) {
			entry.scenario = scenarioPrice;
		} else {
			const entry = new TrackedObject<Record<PriceType, number | null>>();
			entry.scenario = scenarioPrice;
			this.priceStore.set(barchartSymbol, entry);
		}

		const productCode = barchartSymbol.slice(0, -3);

		if (['DG', 'BD', 'BJ'].includes(productCode)) {
			const monthCode = barchartSymbol.slice(-3, -2);
			const yearCode = barchartSymbol.slice(-2);

			this.updateScenarioClassIIIPrice(monthCode, yearCode);
		}

		if (['BD', 'DF'].includes(productCode)) {
			const monthCode = barchartSymbol.slice(-3, -2);
			const yearCode = barchartSymbol.slice(-2);

			this.updateScenarioClassIVPrice(monthCode, yearCode);
		}

		this.updateLocallyStoredPrices(barchartSymbol);
	}

	setScenarioPrices(barchartSymbols: string[], scenarioPrice: number | null) {
		barchartSymbols.forEach((symbol) => this.setScenarioPrice(symbol, scenarioPrice));
	}

	setScenarioPricesToMarket(barchartSymbols: string[]) {
		barchartSymbols.forEach((symbol) => {
			const marketPrice = this.getMarketPrice(symbol);
			this.setScenarioPrice(symbol, marketPrice);
		});
	}

	resetAllScenarioPricesToMarket() {
		const barchartSymbols = Array.from(this.priceStore.keys());
		this.setScenarioPricesToMarket(barchartSymbols);
	}

	setMarketAndScenarioPrice(barchartSymbol: string, price: number | null) {
		this.setMarketPrice(barchartSymbol, price);
		this.setScenarioPrice(barchartSymbol, price);
	}

	updateScenarioClassIIIPrice(monthCode: string, yearCode: string) {
		const classIIIFutureSymbol = 'DL' + monthCode + yearCode;
		const dryWheyFutureSymbol = 'DG' + monthCode + yearCode;
		const butterFutureSymbol = 'BD' + monthCode + yearCode;
		const cheeseFutureSymbol = 'BJ' + monthCode + yearCode;

		const dryWheyPrice = this.getScenarioPrice(dryWheyFutureSymbol);
		const butterPrice = this.getScenarioPrice(butterFutureSymbol);
		const cheesePrice = this.getScenarioPrice(cheeseFutureSymbol);

		const classIIIPrice = this.calculateClassIIIPrice(
			dryWheyPrice ? dryWheyPrice * 0.01 : null,
			butterPrice ? butterPrice * 0.01 : null,
			cheesePrice
		);

		this.setScenarioPrice(classIIIFutureSymbol, classIIIPrice);
	}

	updateScenarioClassIVPrice(monthCode: string, yearCode: string) {
		const classIVFutureSymbol = 'DK' + monthCode + yearCode;
		const nonfatDryMilkFutureSymbol = 'DF' + monthCode + yearCode;
		const butterFutureSymbol = 'BD' + monthCode + yearCode;

		const nonfatDryMilkPrice = this.getScenarioPrice(nonfatDryMilkFutureSymbol);
		const butterPrice = this.getScenarioPrice(butterFutureSymbol);

		const classIVPrice = this.calculateClassIVPrice(
			butterPrice ? butterPrice * 0.01 : null,
			nonfatDryMilkPrice ? nonfatDryMilkPrice * 0.01 : null
		);

		this.setScenarioPrice(classIVFutureSymbol, classIVPrice);
	}

	calculateClassIIIPrice(dryWheyPrice: number | null, butterPrice: number | null, cheesePrice: number | null) {
		if (dryWheyPrice == null || butterPrice == null || cheesePrice == null) return null;

		const otherSolidsPrice = (dryWheyPrice - 0.1991) * 1.03;
		const butterFatPricingFactor = (butterPrice - 0.1715) * 1.211;
		const proteinPrice = (cheesePrice - 0.2003) * 1.383 + ((cheesePrice - 0.2003) * 1.572 - butterFatPricingFactor * 0.9) * 1.17;
		const classiiiSkimMilkPricingFactor = proteinPrice * 3.1 + otherSolidsPrice * 5.9;

		return classiiiSkimMilkPricingFactor * 0.965 + butterFatPricingFactor * 3.5;
	}

	calculateClassIVPrice(butterPrice: number | null, nonfatDryMilkPrice: number | null) {
		if (butterPrice == null || nonfatDryMilkPrice == null) return null;

		const butterFatPricingFactor = (butterPrice - 0.1715) * 1.211;
		const nonFatSolidsPricingFactor = (nonfatDryMilkPrice - 0.1678) * 0.99;
		const classivSkimMilkPricingFactor = nonFatSolidsPricingFactor * 9;

		return classivSkimMilkPricingFactor * 0.965 + butterFatPricingFactor * 3.5;
	}

	updateLocallyStoredPrices(barchartSymbol: string) {
		// Do not run if the scenario and stored prices for the future are equal
		if (this.locallyStoredPrices[barchartSymbol] === this.getScenarioPrice(barchartSymbol)) return;
		this.symbolsToUpdateInLocalStorage.push(barchartSymbol);
		// Debounce setting local storage to prevent overwhelming the browser
		debounce(this, this.setLocallyStoredPrices, 750);
	}

	setLocallyStoredPrices() {
		if (!checkStorageAvailable('localStorage') || !this.localStorageString) return;

		this.symbolsToUpdateInLocalStorage.forEach((symbol) => {
			this.locallyStoredPrices[symbol] = this.getScenarioPrice(symbol);
		});

		const stringifiedLocallyStoredPrices = JSON.stringify(this.locallyStoredPrices);

		window.localStorage.setItem(this.localStorageString, stringifiedLocallyStoredPrices);
		this.symbolsToUpdateInLocalStorage.clear();
	}

	getLocallyStoredPrices(): LocallyStoredPrices {
		if (!checkStorageAvailable('localStorage') || !this.localStorageString) return {};

		let stringifiedLocallyStoredPrices = window.localStorage.getItem(this.localStorageString);

		if (!stringifiedLocallyStoredPrices) {
			// Temporary logic to migrate old storage key to new storage key
			if (this.deprecatedLocalStorageString) {
				const deprecatedStringifiedLocallyStoredPrices = window.localStorage.getItem(this.deprecatedLocalStorageString);
				if (deprecatedStringifiedLocallyStoredPrices) {
					window.localStorage.setItem(this.localStorageString, deprecatedStringifiedLocallyStoredPrices);
					window.localStorage.removeItem(this.deprecatedLocalStorageString);
					stringifiedLocallyStoredPrices = deprecatedStringifiedLocallyStoredPrices;
				}
			}
		}

		if (!stringifiedLocallyStoredPrices) return {};

		try {
			const locallyStoredPrices = JSON.parse(stringifiedLocallyStoredPrices) as LocallyStoredPrices;
			return locallyStoredPrices;
		} catch (e) {
			return {};
		}
	}
}
