import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Vault::UiButton @type='button' @style='destructive' @disabled={{@disabled}} {{on 'click' this.openModal}}>{{yield}}</Vault::UiButton>\n{{#if this.showModal}}\n\t<Vault::UiModal as |modal|>\n\t\t<modal.header @closeFn={{this.closeModal}}>\n\t\t\t<span class='text-xl'>\n\t\t\t\tDelete Fill\n\t\t\t</span>\n\t\t</modal.header>\n\t\t<modal.body>\n\t\t\tClick \"Confirm\" to delete the fill.\n\t\t\t{{#if this.errorMessage}}\n\t\t\t\t<div class='w-full py-3'>\n\t\t\t\t\t<p class='justify-center text-brand-error-50 w-full'>{{this.errorMessage}}</p>\n\t\t\t\t</div>\n\t\t\t{{/if}}\n\t\t</modal.body>\n\t\t<modal.footer>\n\t\t\t<Vault::UiButton @type='submit' class='mr-2' {{on 'click' this.submit}}>Confirm</Vault::UiButton>\n\t\t\t<Vault::UiButton @type='button' @style='outline' class='mr-2' {{on 'click' this.closeModal}}>Cancel</Vault::UiButton>\n\t\t</modal.footer>\n\t</Vault::UiModal>\n{{/if}}", {"contents":"<Vault::UiButton @type='button' @style='destructive' @disabled={{@disabled}} {{on 'click' this.openModal}}>{{yield}}</Vault::UiButton>\n{{#if this.showModal}}\n\t<Vault::UiModal as |modal|>\n\t\t<modal.header @closeFn={{this.closeModal}}>\n\t\t\t<span class='text-xl'>\n\t\t\t\tDelete Fill\n\t\t\t</span>\n\t\t</modal.header>\n\t\t<modal.body>\n\t\t\tClick \"Confirm\" to delete the fill.\n\t\t\t{{#if this.errorMessage}}\n\t\t\t\t<div class='w-full py-3'>\n\t\t\t\t\t<p class='justify-center text-brand-error-50 w-full'>{{this.errorMessage}}</p>\n\t\t\t\t</div>\n\t\t\t{{/if}}\n\t\t</modal.body>\n\t\t<modal.footer>\n\t\t\t<Vault::UiButton @type='submit' class='mr-2' {{on 'click' this.submit}}>Confirm</Vault::UiButton>\n\t\t\t<Vault::UiButton @type='button' @style='outline' class='mr-2' {{on 'click' this.closeModal}}>Cancel</Vault::UiButton>\n\t\t</modal.footer>\n\t</Vault::UiModal>\n{{/if}}","moduleName":"vault-client/components/vgs-delete-fill-button.hbs","parseOptions":{"srcName":"vault-client/components/vgs-delete-fill-button.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import RouterService from '@ember/routing/router-service';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { gql, useMutation } from 'glimmer-apollo';
import { ApolloCache } from '@apollo/client/cache';
import { GrainFillOrder, Mutation, Mutation_deleteGrainFillOrderArgs } from 'vault-client/types/graphql-types';

interface DeleteFillButtonArgs {
	fill: GrainFillOrder;
	closeModalFn: () => void;
	target: TargetsArgs;
	postSubmitTransition: (planId: string) => void;
}

type TargetsArgs = {
	id: number;
	username: string;
};

const DELETE_GRAIN_FILL_ORDER = gql`
	mutation DeleteGrainFillOrder($id: String!) {
		deleteGrainFillOrder(id: $id) {
			id
		}
	}
`;

export default class VgsDeleteFillButtonComponent extends Component<DeleteFillButtonArgs> {
	@service router: RouterService | undefined;
	@tracked showModal = false;
	@tracked errorMessage: string | null = null;

	deleteFill = useMutation<{ deleteGrainFillOrder: Mutation['deleteGrainFillOrder'] }, Mutation_deleteGrainFillOrderArgs>(this, () => [
		DELETE_GRAIN_FILL_ORDER,
		{
			update: (cache) => {
				this.fieldsToEvict(cache);
				cache.gc();
			},
			onError: (error): void => {
				this.errorMessage = 'There was an error. Fill not deleted.';
				console.error('Error while attempting to delete fill', error.message);
			},
		},
	]);

	fieldsToEvict(cache: ApolloCache<any>) {
		const fields = [
			'GrainFillOrder',
			'GrainFillOrders',
			'AggregateGrainFillOrders',
			'GrainFeedPlans',
			'GrainFeedPlan',
			'GrainCropPlans',
			'GrainCropPlan',
			'GrainTargetOrders',
			'GrainTargetOrder',
			'AggregateGrainTargetOrders',
		];

		fields.forEach((field) => {
			return cache.evict({ fieldName: field });
		});
	}

	@action
	openModal() {
		this.showModal = true;
	}

	@action
	closeModal() {
		this.showModal = false;
	}

	@action
	async submit() {
		const { id } = this.args.fill ?? {};

		if (!id) {
			this.errorMessage = "Could not find fill's ID";
			return;
		}
		const deletedFill = this.deleteFill.mutate({
			id,
		});

		await deletedFill;

		this.closeModal();
	}
}
