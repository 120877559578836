import InsuranceOverview from 'vault-client/controllers/insurance-overview';

export default class BusinessesBusinessInsuranceQuartersController extends InsuranceOverview {
	insuranceRoutePath = 'businesses.business.insurance';
	insuranceQuarterRoutePath = 'businesses.business.insurance-overview-show';
	pageTitle = 'Insurance Quarters';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'organizations/organization/insurance-quarters': BusinessesBusinessInsuranceQuartersController;
	}
}
