import Route from '@ember/routing/route';
import { RouteQueryParam } from '@ember/routing/types';
import { tracked } from '@glimmer/tracking';
import { gql, useQuery } from 'glimmer-apollo';
import { GrainTargetOrderFilterDTO, Query, TypeOfGrainOrderStatus, TypeOfGrainPlan } from 'vault-client/types/graphql-types';

const GET_FEED_TARGETS = gql`
	query FeedTargets($scopeId: String!, $where: GrainTargetOrderFilterDTO!) {
		GrainTargetOrders(scopeId: $scopeId, orderBy: { deliveryEndDate: Asc }, where: $where) {
			id
			deliveryTerms
			bushels
			futuresMonth
			deliveryStartDate
			deliveryEndDate
			deliveryMonth
			salesType
			futurePrice
			basis
			feedPrice
			expirationDate
			contractNumber
			status
			updatedAt
			shortNote
			Seller {
				id
				name
			}
			Location {
				id
				name
			}
			Plan {
				id
				type
				... on GrainFeedPlan {
					customerId
					feedCategoryId
					startDate
					hasWriteAccess
					feedYear
					Customer {
						id
						name
						RelationshipOwner {
							id
							firstName
							lastName
						}
					}
					FeedCategory {
						id
						name
					}
				}
			}
		}
		GrainTargetOrderCount(scopeId: $scopeId, where: $where) {
			count
		}
	}
`;

interface FeedTargetQueryArgs {
	scopeId: string;
	where?: GrainTargetOrderFilterDTO;
}

export type GetFeedTargetsQuery = {
	__typename?: 'Query';
	GrainTargetOrders: Query['GrainTargetOrders'];
	GrainTargetOrderCount: Query['GrainTargetOrderCount'];
};

interface ModelParams {
	startDate: string;
	endDate: string;
}

export default class FeedTargetsRoute extends Route {
	templateName = 'feed/targets/index';

	@tracked scopeId = '';
	@tracked feedTargetVariables: FeedTargetQueryArgs = { scopeId: '' };

	queryParams: { [key: string]: RouteQueryParam } = {
		startDate: { refreshModel: true },
		endDate: { refreshModel: true },
	};

	getFeedTargets = useQuery<GetFeedTargetsQuery, FeedTargetQueryArgs>(this, () => [
		GET_FEED_TARGETS,
		{
			variables: this.feedTargetVariables,
		},
	]);

	async model(params: ModelParams) {
		this.feedTargetVariables = {
			scopeId: this.scopeId,
			where: this.generateWhere(params.startDate, params.endDate),
		};

		await this.getFeedTargets.promise;

		return {
			getFeedTargets: this.getFeedTargets,
			scopeId: this.scopeId,
			query: GET_FEED_TARGETS,
			variables: this.feedTargetVariables,
		};
	}

	generateWhere(startDate: undefined | null | string, endDate: undefined | null | string): GrainTargetOrderFilterDTO {
		const where: GrainTargetOrderFilterDTO = {
			Plan: {
				type: {
					equals: TypeOfGrainPlan.Feed,
				},
			},
			status: {
				not: TypeOfGrainOrderStatus.Cancelled,
			},
			OR: [{ deliveryStartDate: { gte: startDate, lte: endDate } }, { deliveryEndDate: { gte: startDate, lte: endDate } }],
		};

		return where;
	}
}
