import { DateTime } from 'luxon';

const scrollToFirstErrorMessage = (invalidElements: NodeListOf<HTMLFormElement>) =>
	invalidElements.length > 0 && invalidElements[0].scrollIntoView({ behavior: 'smooth', block: 'center' });

const isValidDate = (date: string): boolean => {
	if (date === null || date === undefined) {
		throw new Error('Date cannot be null or undefined.');
	}

	const dateString = DateTime.fromFormat(date, 'yyyy-MM-dd');

	if (date === '') {
		return true;
	}

	return dateString.isValid;
};

const getDatesAndValidate = (dates: NodeListOf<HTMLFormElement>): boolean => {
	let isValid = true;
	document.querySelectorAll('.date-error').forEach((e) => e.remove());
	[...dates].forEach((e) => {
		const date = e.value;
		const inputDate = date;
		// not only check if the date if valid but also check if the input is required and if the date is within the min and max range if set.
		if (
			!isValidDate(inputDate) ||
			(e.required && inputDate === '') ||
			(inputDate !== '' && e.min && inputDate < e.min) ||
			(inputDate !== '' && e.max && inputDate > e.max)
		) {
			const createSpan = document.createElement('span');
			const getNode = document.getElementById(e.id);
			if (createSpan && getNode?.parentElement) {
				const newChildDiv = getNode.parentElement.appendChild(createSpan);

				newChildDiv.className = 'date-error justify-center text-brand-error-50 w-full';

				newChildDiv.innerText = 'Please enter a valid date.';
				isValid = false;
			}
		}
	});
	return isValid;
};

const getRequiredAndValidate = (inputs: NodeListOf<HTMLFormElement>): boolean => {
	let isValid = true;
	inputs.forEach((e) => {
		if (e.dataset.required && !e.dataset.value && !e.value) {
			const createSpan = document.createElement('span');
			const getNode = document.getElementById(e.id);
			if (createSpan) {
				const newChildSpan = getNode?.insertAdjacentElement('afterend', createSpan) as HTMLElement;

				if (newChildSpan) {
					newChildSpan.className = 'required-error justify-center text-brand-error-50 w-full';
					newChildSpan.innerText = e.dataset.message || 'An entry is required.';
				}
			}
			isValid = false;
		}
	});

	return isValid;
};

const getAvailableBushelAmountAndValidate = (bushelInput: NodeListOf<HTMLFormElement>) => {
	let isValid = true;

	bushelInput.forEach((e) => {
		if (
			e.dataset.availableBushels &&
			e.dataset.enteredBushels &&
			(+e.dataset.availableBushels < +e.dataset.enteredBushels || +e.dataset.enteredBushels == 0)
		) {
			const createSpan = document.createElement('span');
			const getNode = document.getElementById(e.id);
			if (createSpan) {
				const newChildSpan = getNode?.insertAdjacentElement('afterend', createSpan) as HTMLElement;

				if (newChildSpan) {
					newChildSpan.className = 'required-error justify-center text-brand-error-50 w-full';
					newChildSpan.innerText = e.dataset.message || 'An entry is required.';
				}
			}
			isValid = false;
		}
	});

	return isValid;
};

/**
 * @description This function checks if the form is valid by checking if all required fields are filled out and if the dates are valid.
 * @param {Document} document - The document object.
 */
const isFormValid = (document: Document): boolean => {
	// clear all error classes to prevent duplicate error messages
	document.querySelectorAll('.required-error').forEach((e) => e.remove());
	document.querySelectorAll('.date-error').forEach((e) => e.remove());
	const inputs = document.querySelectorAll('[data-required]') as NodeListOf<HTMLFormElement>;
	const dates = document.querySelectorAll('input[type="date"]') as NodeListOf<HTMLFormElement>;
	const bushelInput = document.querySelectorAll('[data-available-bushels]') as NodeListOf<HTMLFormElement>;
	// const enteredBushels = document.querySelectorAll('[data-entered-bushels]') as NodeListOf<HTMLFormElement>;
	const required = getRequiredAndValidate(inputs);
	const date = getDatesAndValidate(dates);
	const bushelAmountValid = getAvailableBushelAmountAndValidate(bushelInput);
	return required && date && bushelAmountValid;
};

/**
 * @description This function scrolls to the first error message in the form.
 * @param {Document} document - The document object.
 */
const getInvalidElements = (document: Document): void => {
	const invalidDateElements = document.querySelectorAll('.date-error') as NodeListOf<HTMLFormElement>;
	const invalidRequired = document.querySelectorAll('.required-error') as NodeListOf<HTMLFormElement>;

	scrollToFirstErrorMessage(invalidDateElements);
	scrollToFirstErrorMessage(invalidRequired);
};

export {
	getDatesAndValidate,
	getRequiredAndValidate,
	scrollToFirstErrorMessage,
	isFormValid,
	getInvalidElements,
	getAvailableBushelAmountAndValidate,
};
