import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<span\n\tclass='{{this.colorClass}}\n\t\t{{this.sizeClass}}\n\t\t{{this.textSizeClass}}\n\t\trounded-xl w-auto px-3 py-0.5 text-brand-gray-90 inline-flex items-center whitespace-nowrap'\n\t...attributes\n>\n\t{{#if (has-block)}}\n\t\t{{yield}}\n\t{{else}}\n\t\t{{capitalize @text}}\n\t{{/if}}\n</span>", {"contents":"<span\n\tclass='{{this.colorClass}}\n\t\t{{this.sizeClass}}\n\t\t{{this.textSizeClass}}\n\t\trounded-xl w-auto px-3 py-0.5 text-brand-gray-90 inline-flex items-center whitespace-nowrap'\n\t...attributes\n>\n\t{{#if (has-block)}}\n\t\t{{yield}}\n\t{{else}}\n\t\t{{capitalize @text}}\n\t{{/if}}\n</span>","moduleName":"vault-client/components/ui-tag.hbs","parseOptions":{"srcName":"vault-client/components/ui-tag.hbs"}});
import Component from '@glimmer/component';

export type UiTagStatus = 'neutral' | 'positive' | 'warning' | 'pending' | 'negative';
export type UiTagColor = 'blue' | 'teal' | 'orange' | 'lemon' | 'lime' | 'purple' | 'interactive';
export type UiTagSize = 'default' | 'small' | 'micro';

interface UiTagComponentArgs {
	text?: string;
	status?: UiTagStatus | ((text: string | undefined) => UiTagStatus);
	color?: UiTagColor;
	size?: UiTagSize;
}

export default class UiTagComponent extends Component<UiTagComponentArgs> {
	constructor(owner: any, args: UiTagComponentArgs) {
		super(owner, args);
		if (!this.args.color && !this.args.status) {
			throw new Error('TagComponent: Either status or color must be provided to TagComponent');
		}
	}

	get colorClass() {
		if (this.args.status) {
			const status = typeof this.args.status === 'function' ? this.args.status(this.args.text) : this.args.status;

			switch (status) {
				case 'neutral':
					return 'bg-brand-gray-20';
				case 'positive':
					return 'bg-brand-success-30';
				case 'negative':
					return 'bg-brand-error-30';
				case 'pending':
					return 'bg-brand-warning-30';
				case 'warning':
					return 'bg-brand-warning-30';
				default:
					throw new Error('TagComponent: Invalid status provided');
			}
		}

		if (this.args.color) {
			switch (this.args.color) {
				case 'blue':
					return 'bg-brand-blue-30';
				case 'teal':
					return 'bg-brand-teal-30';
				case 'orange':
					return 'bg-brand-orange-30';
				case 'lemon':
					return 'bg-brand-lemon-30';
				case 'lime':
					return 'bg-brand-lime-30';
				case 'purple':
					return 'bg-brand-purple-30';
				case 'interactive':
					return 'bg-brand-interactive-blue-30';
				default:
					throw new Error('TagComponent: Invalid color provided');
			}
		}

		// Default to neutral
		return 'bg-brand-gray-20';
	}

	get sizeClass() {
		switch (this.args.size) {
			case 'micro':
				return 'h-4';
			case 'small':
				return 'h-5';
			default:
				return 'h-6';
		}
	}

	get textSizeClass() {
		switch (this.args.size) {
			case 'micro':
				return 'text-xs';
			case 'small':
				return 'text-xs';
			default:
				return 'text-sm';
		}
	}
}
