import Controller from '@ember/controller';
import { action } from '@ember/object';
import { queryManager } from 'ember-apollo-client';
import searchQuery from 'vault-client/graphql/queries/search.graphql';
import getFilterDisplayProperty from 'vault-client/utils/get-filter-display-property';

export default class ReportsDrpPerformanceByPlanYearController extends Controller {
	@queryManager apollo: any;

	queryParams = ['customerId'];
	reportsRoute: string = '';
	get columns() {
		const baseColumns = [
			{
				id: 'c7e9bc37-1048-4f9c-8e68-a1e635a33a12',
				name: 'Year',
				valuePath: 'reinsuranceYear',
				minWidth: 80,
				textAlign: 'left',
				isSortable: false,
				cellComponent: 'String',
				isFixed: '',
				isVisible: true,
			},
			{
				id: '611ed290-f192-40ad-95d7-5f74a869f4d5',
				name: 'Covered Milk (lbs)',
				valuePath: 'sum.effectiveCoveredMilkProduction',
				minWidth: 170,
				cellComponent: 'IntlNumberFormat',
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'a52f5628-7424-4ad0-8af0-ce1c9b6b0036',
				name: 'Producer Premium',
				valuePath: 'sum.producerPremiumAmount',
				minWidth: 170,
				cellComponent: 'IntlNumberFormat',
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '7856bd65-d6de-4db9-b188-8639c3b16340',
				name: 'Total Premium',
				valuePath: 'sum.totalPremiumAmount',
				minWidth: 160,
				cellComponent: 'IntlNumberFormat',
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '6f835643-64db-461f-9f5f-4848111d9fc2',
				name: 'Indemnity',
				valuePath: 'sum.indemnity',
				minWidth: 130,
				cellComponent: 'IntlNumberFormat',
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'bf96520a-8b81-4810-b9d8-3d21cfabb43d',
				name: 'Profit/Loss',
				valuePath: 'sum.pnl',
				minWidth: 150,
				cellComponent: 'IntlNumberFormat',
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
		];
		return baseColumns;
	}

	get searchFilterQueryParams() {
		const obj: {
			[key: string]: any;
		} = {};
		const searchQueryParams = ['customerId'];
		searchQueryParams.forEach((param) => {
			// @ts-ignore
			if (this[param]) {
				obj[param] = {
					filterRule: 'equals',
					// @ts-ignore
					filterValue: this[param],
				};

				// set filterComponent property to specify component for custom display extended from search-filter
				const filterDisplayObj = getFilterDisplayProperty(param);
				if (filterDisplayObj && filterDisplayObj.customComponent) {
					obj[param].filterComponent = filterDisplayObj.customComponent;
				}
				// set filterLabel property to specify custom label for filter - default would be filterIdentifier (matches queryParam)
				if (filterDisplayObj && filterDisplayObj.label) {
					obj[param].filterLabel = filterDisplayObj.label;
				}
			}
		});

		return obj;
	}

	get searchPlaceholder() {
		return 'Filter by business...';
	}

	get searchPrompt() {
		return 'Type a search term to examine performance by business.';
	}

	@action
	async fetchSearchResults(searchText: any) {
		const variables = {
			query: searchText,
			limit: 10,
			typesToInclude: ['Customer'],
		};
		return (await this.apollo.watchQuery({ query: searchQuery, variables })).Search;
	}

	@action
	structureSearchResults(searchResults: any) {
		const map = new Map();

		searchResults.forEach((item: any) => {
			if (map.has(item.type)) {
				map.get(item.type).push({ id: item.id, name: item.name, type: item.type });
			} else {
				map.set(item.type, [{ id: item.id, name: item.name, type: item.type }]);
			}
		});

		return map;
	}

	@action
	setSearchFilterQueryParam(searchResult: any) {
		const mappedSearchFilter = this.mapSearchResult(searchResult);

		//@ts-ignore
		this[mappedSearchFilter.filterIdentifier] = mappedSearchFilter.filterValue;
	}

	mapSearchResult(searchResult: any) {
		let filterIdentifier;

		switch (searchResult.type) {
			case 'Customer':
				filterIdentifier = 'customerId';
				break;
		}

		return {
			filterIdentifier,
			filterValue: searchResult.id,
		};
	}

	@action
	clearSearchFilterQueryParam(filterIdentifier: any) {
		//@ts-ignore
		this[`${filterIdentifier}`] = null;
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'reports-drp-performance-by-plan-year-controller': ReportsDrpPerformanceByPlanYearController;
	}
}
