import Component from '@glimmer/component';
import { TypeOfGrainOrderSales } from 'vault-client/types/graphql-types';

type GrainOrFeed = 'grain' | 'feed';
interface GrainSalesTypeSelectArgs {
	selected: TypeOfGrainOrderSales;
	optionsFilterFlag: GrainOrFeed;
	onChangeFn: (type: TypeOfGrainOrderSales) => void;
}

export default class GrainSalesTypeSelect extends Component<GrainSalesTypeSelectArgs> {
	feedTypes: TypeOfGrainOrderSales[] = [
		TypeOfGrainOrderSales.BasisOnly,
		TypeOfGrainOrderSales.FuturesOnly,
		TypeOfGrainOrderSales.SetBasis,
		TypeOfGrainOrderSales.SetFutures,
		TypeOfGrainOrderSales.Flat,
	];
	grainTypes: TypeOfGrainOrderSales[] = [
		TypeOfGrainOrderSales.Basis,
		TypeOfGrainOrderSales.Cbot,
		TypeOfGrainOrderSales.Flat,
		TypeOfGrainOrderSales.Hta,
		TypeOfGrainOrderSales.MarketFlat,
		TypeOfGrainOrderSales.MarketHta,
		TypeOfGrainOrderSales.Npe,
	];

	get types() {
		return this.args.optionsFilterFlag === 'grain' ? this.grainTypes : this.feedTypes;
	}
}
