import Route from '@ember/routing/route';
import lrpPrices from 'vault-client/graphql/queries/reports/lrp-calculator.graphql';
import { queryManager } from 'ember-apollo-client';

interface LrpDailyPrice {
	id: string;
	commodityCode: string;
	commodityTypeCode: string;
	typeCode: string;
	stateCode: string;
	endDate: string;
	salesEffectiveDate: string;
	insurancePlanCode: string;
	intervalCode: string;
	endorsementLengthCount: number;
	expectedEndingValueAmount: number;
	coveragePrice: number;
	livestockCoverageLevelPercent: number;
	livestockRate: number;
	costPerCwtAmount: number;
}

interface ModelParams {
	_commodityCode: string;
	_commodityTypeCode: string;
	stateCode: string;
}

export default class ReportsLrpCalculatorRoute extends Route {
	@queryManager apollo: any;

	queryParams = {
		_commodityCode: { refreshModel: true },
		_commodityTypeCode: { refreshModel: true },
		stateCode: { refreshModel: true },
	};

	templateName: string = 'reports/lrp-calculator';

	async model(params: ModelParams) {
		const variables = {
			where: {
				stateCode: {
					equals: params.stateCode,
				},
				commodityCode: {
					equals: params._commodityCode,
				},
				typeCode: {
					equals: params._commodityTypeCode,
				},
			},
		};

		return await this.apollo.watchQuery({ query: lrpPrices, variables });
	}

	setupController(controller: any, model: any, transition: any) {
		super.setupController(controller, model, transition);

		let endDate = null;
		let coveragePrice = null;

		if ((model.MostRecentLrpDailyPrices as LrpDailyPrice[]).length) {
			({ endDate, coveragePrice } = (model.MostRecentLrpDailyPrices as LrpDailyPrice[]).sortBy('endDate', 'coveragePrice')[0]);
		}

		const properties = {
			coverageEndDate: endDate,
			coveragePrice: coveragePrice,
		};

		controller.setProperties(properties);
	}
}
