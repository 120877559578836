import Route from '@ember/routing/route';
import { queryManager } from 'ember-apollo-client';
import query from 'vault-client/graphql/queries/insurance-policies/index.graphql';
import { inject as service } from '@ember/service';

import { ProducerAndPolicyFilterDTO, ProducerAndPolicySortByDTO } from 'vault-client/types/graphql-types';

type SortObj = {
	valuePath: string;
	isAscending: boolean;
};

export default class InsurancePoliciesIndexRoute extends Route {
	@service applicationScope: any;
	@queryManager apollo: any;

	templateName = 'insurance-policies/index';

	query = query;

	generateOrderBy(sorts: SortObj[]): ProducerAndPolicySortByDTO {
		const orderBy: {
			[key: string]: any;
		} = {};

		sorts.forEach((sort: any) => {
			const value = sort.isAscending ? 'Asc' : 'Desc';
			orderBy[sort.valuePath] = value;
		});

		return orderBy;
	}

	generateWhere(
		organizationId: null | string,
		customerId: undefined | null | string,
		aipId: string | null | undefined,
		policyNumber: string | null | undefined,
		agent: string | null | undefined,
		stateId: string | null | undefined
	): ProducerAndPolicyFilterDTO {
		const where: ProducerAndPolicyFilterDTO = {};

		if (customerId) {
			where.customerId = { equals: customerId };
		} else if (organizationId) {
			if (!where.OR) {
				where.OR = [];
			}
			where.OR.push({
				Customer: {
					organizationId: {
						equals: organizationId,
					},
				},
			});
			where.OR.push({
				ownerId: {
					equals: organizationId,
				},
			});
		}

		if (aipId) {
			where.aipId = {
				equals: aipId,
			};
		}

		if (policyNumber) {
			where.policyNumber = {
				equals: policyNumber,
			};
		}

		if (agent) {
			const nameArr = agent.split(' ');
			if (!where.AND) {
				where.AND = [];
			}
			where.AND.push({ agentFirstName: { equals: nameArr[0] } });
			where.AND.push({ agentLastName: { equals: nameArr[1] } });
		}

		if (stateId) {
			where.stateId = {
				equals: stateId,
			};
		}

		return where;
	}

	queryParams = {
		agent: { refreshModel: true },
		aipId: { refreshModel: true },
		policyNumber: { refreshModel: true },
		stateId: { refreshModel: true },
		page: { refreshModel: true },
		sorts: { refreshModel: true },
		size: { refreshModel: true },
	};
}
