import Route from '@ember/routing/route';
import query from 'vault-client/graphql/queries/reports/drp-performance-by-plan-year.graphql';
import { queryManager } from 'ember-apollo-client';
import { inject as service } from '@ember/service';
import { DerivedDrpEndorsementFilterDTO } from 'vault-client/types/graphql-types';

export default class ReportsDrpPerformanceByPlanYearRoute extends Route {
	@service applicationScope: any;

	@queryManager apollo: any;

	queryParams = {
		customerId: {
			refreshModel: true,
		},
	};

	generateWhere(organizationId: null | string, customerId: undefined | null | string): DerivedDrpEndorsementFilterDTO {
		const where: DerivedDrpEndorsementFilterDTO = {};

		if (customerId) {
			where.InsurancePolicy = {
				customerId: { equals: customerId },
			};
		} else if (organizationId) {
			where.InsurancePolicy = {
				Owner: {
					id: { equals: organizationId },
				},
			};
		}

		return where;
	}

	templateName: string = 'reports/drp-performance-by-plan-year';

	async model(params: any) {
		const variables = {
			where: this.generateWhere(params.id, params.customerId),
		};
		return this.apollo.watchQuery({ query, variables });
	}
}
