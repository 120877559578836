import { action } from '@ember/object';
import { gql, useQuery } from 'glimmer-apollo';
import SearchFilterComponent from 'vault-client/components/vault/ui-search-filter';
import { Query, Query_BusinessContactArgs } from 'vault-client/types/graphql-types';
import { generateFullName } from 'vault-client/utils/general';

const GET_BUSINESS_CONTACT = gql`
	query GetBusinessContact($id: String!) {
		BusinessContact(id: $id) {
			id
			firstName
			lastName
		}
	}
`;

export default class BusinessContactIdFilterComponent extends SearchFilterComponent {
	query = GET_BUSINESS_CONTACT;
	valuePath = 'BusinessContact.name';

	@action
	async fetchItem() {
		let value;
		if (!this.args.filterValues.filterValue) {
			this._value = 'N/A';
			return;
		}

		const query = this.query;

		if (query) {
			const GetBusinessContact = useQuery<{ BusinessContact: Query['BusinessContact'] }, Query_BusinessContactArgs>(this, () => [
				query,
				{
					variables: { id: this.args.filterValues.filterValue },
				},
			]);
			await GetBusinessContact.promise;

			const businessContact = GetBusinessContact.data?.BusinessContact ?? null;

			const name = generateFullName(businessContact?.firstName, businessContact?.lastName) ?? '';

			value = name;
			this._value = value ?? this.args.filterValues.filterValue;
		} else {
			this._value = this.args.filterValues.filterValue;
		}
	}
}
