import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<PowerSelect\n\t@selected={{@selected}}\n\t@options={{this.deliveryTerms}}\n\t@onChange={{@onChange}}\n\t@allowClear={{true}}\n\t@renderInPlace={{true}}\n\t@placeholder='N/A'\n\tas |term|\n>\n\t{{term}}\n</PowerSelect>", {"contents":"<PowerSelect\n\t@selected={{@selected}}\n\t@options={{this.deliveryTerms}}\n\t@onChange={{@onChange}}\n\t@allowClear={{true}}\n\t@renderInPlace={{true}}\n\t@placeholder='N/A'\n\tas |term|\n>\n\t{{term}}\n</PowerSelect>","moduleName":"vault-client/components/delivery-terms-select.hbs","parseOptions":{"srcName":"vault-client/components/delivery-terms-select.hbs"}});
import Component from '@glimmer/component';
import { GrainOrderDeliveryTerms } from 'vault-client/types/graphql-types';

interface DeliveryTermsSelectComponentArgs {
	selected: GrainOrderDeliveryTerms | null;
	onChange: (selected: GrainOrderDeliveryTerms | null) => void;
}

export default class DeliveryTermsSelectComponent extends Component<DeliveryTermsSelectComponentArgs> {
	get deliveryTerms() {
		return [GrainOrderDeliveryTerms.DAP, GrainOrderDeliveryTerms.FOB];
	}
}
