import { TypeOfGrainFillOrderModification, TypeOfGrainOrderSales, TypeOfGrainOrderStatus } from 'vault-client/types/graphql-types';
import { ActivityUtil } from 'vault-client/utils/activity-message';
import { objectCompareStringify } from 'vault-client/utils/general';

const SALES_TYPE_DISPLAY_VALUES: { [key: string]: string } = {
	[TypeOfGrainOrderSales.Basis]: 'Basis',
	BASIS: 'Basis',
	[TypeOfGrainOrderSales.BasisOnly]: 'Basis Only',
	BASIS_ONLY: 'Basis Only',
	[TypeOfGrainOrderSales.SetBasis]: 'Set Basis',
	SET_BASIS: 'Set Basis',
	[TypeOfGrainOrderSales.Cbot]: 'CBOT',
	CBOT: 'CBOT',
	[TypeOfGrainOrderSales.Flat]: 'Flat',
	FLAT: 'Flat',
	[TypeOfGrainOrderSales.Hta]: 'HTA',
	HTA: 'HTA',
	[TypeOfGrainOrderSales.MarketFlat]: 'Market Flat',
	MARKET_FLAT: 'Market Flat',
	[TypeOfGrainOrderSales.MarketHta]: 'Market HTA',
	MARKET_HTA: 'Market HTA',
	[TypeOfGrainOrderSales.Npe]: 'NPE',
	NPE: 'NPE',
	[TypeOfGrainOrderSales.FuturesOnly]: 'Futures Only',
	FUTURES_ONLY: 'Futures Only',
	[TypeOfGrainOrderSales.SetFutures]: 'Set Futures',
	SET_FUTURES: 'Set Futures',
};

const GRAIN_ORDER_STATUS_DISPLAY_VALUES: { [key: string]: string } = {
	[TypeOfGrainOrderStatus.New]: 'New',
	NEW: 'New',
	[TypeOfGrainOrderStatus.Pending]: 'Pending',
	PENDING: 'Pending',
	[TypeOfGrainOrderStatus.PendingCancellation]: 'Pending Cancellation',
	PENDING_CANCELLATION: 'Pending Cancellation',
	[TypeOfGrainOrderStatus.Submitted]: 'Submitted',
	SUBMITTED: 'Submitted',
	[TypeOfGrainOrderStatus.Cancelled]: 'Cancelled',
	CANCELLED: 'Cancelled',
	[TypeOfGrainOrderStatus.Filled]: 'Filled',
	FILLED: 'Filled',
	[TypeOfGrainOrderStatus.Working]: 'Working',
	WORKING: 'Working',
};

const GRAIN_FILL_ORDER_MODIFICATION_TYPE_DISPLAY_VALUES = {
	[TypeOfGrainFillOrderModification.Cancel]: 'Cancel',
	[TypeOfGrainFillOrderModification.ExchangeCash]: 'Exchange Cash',
	[TypeOfGrainFillOrderModification.ExchangeHta]: 'Exchange HTA',
	[TypeOfGrainFillOrderModification.LiftAndSellCash]: 'Lift & Sell Cash',
	[TypeOfGrainFillOrderModification.LiftAndSellHta]: 'Lift & Sell HTA',
	[TypeOfGrainFillOrderModification.Roll]: 'Roll',
	[TypeOfGrainFillOrderModification.RollMarket]: 'Roll Market',
	[TypeOfGrainFillOrderModification.RollMarketAndSetBasis]: 'Roll Market & Set Basis',
	[TypeOfGrainFillOrderModification.RollMarketAndSetFuturesMarket]: 'Roll Market & Set Futures Market',
	[TypeOfGrainFillOrderModification.RollMarketAndSetFuturesOffer]: 'Roll Market & Set Futures Offer',
	[TypeOfGrainFillOrderModification.RollOffer]: 'Roll Offer',
	[TypeOfGrainFillOrderModification.SetBasis]: 'Set Basis',
	[TypeOfGrainFillOrderModification.SetCashMarket]: 'Set Cash Market',
	[TypeOfGrainFillOrderModification.SetCashOffer]: 'Set Cash Offer',
	[TypeOfGrainFillOrderModification.SetFuturesMarket]: 'Set Futures Market',
	[TypeOfGrainFillOrderModification.SetFuturesOffer]: 'Set Futures Offer',
};

function informationWasUpdated(activity: ActivityUtil) {
	// Utility for checking if the information of a target / fill was updated within an activity
	const fieldsToIgnore = {
		status_updated_at: null,
		last_updated_by_id: null,
		last_updated_by: null,
		updated_at: null,
	};

	const newObj = { ...activity.data.new, ...fieldsToIgnore };
	const oldObj = { ...activity.data.old, ...fieldsToIgnore };

	return !objectCompareStringify(newObj, oldObj);
}

function getSalesTypeDisplayValue(salesType: string) {
	return SALES_TYPE_DISPLAY_VALUES[salesType] ?? salesType;
}

function getGrainOrderStatusDisplayValue(status: string) {
	return GRAIN_ORDER_STATUS_DISPLAY_VALUES[status] ?? status;
}

function getGrainFillOrderModificationTypeDisplayValue(type: TypeOfGrainFillOrderModification) {
	return GRAIN_FILL_ORDER_MODIFICATION_TYPE_DISPLAY_VALUES[type];
}

export { informationWasUpdated, getSalesTypeDisplayValue, getGrainOrderStatusDisplayValue, getGrainFillOrderModificationTypeDisplayValue };
