import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';

//@ts-ignore
import { Product } from 'vault-client/types/graphql-types.d';

interface DairyHistoricalPercentilesReportsModel {
	Products: Product[];
}

export default class ReportsDairyHistoricalPercentilesFiveYear extends Controller {
	model: DairyHistoricalPercentilesReportsModel = this.model;

	@tracked numberOfYears: number = 10;
	reportsRoute: string = '';

	get validNumberOfYears() {
		return this.numberOfYears > 0 && this.numberOfYears <= 15;
	}
	get butter() {
		return this.model.Products.find((product: Product) => product.slug === 'us-dairy-butter');
	}

	get nonfatMilk() {
		return this.model.Products.find((product: Product) => product.slug === 'us-dairy-nonfat-milk');
	}

	get corn() {
		return this.model.Products.find((product: Product) => product.slug === 'grain-corn');
	}

	get soybean() {
		return this.model.Products.find((product: Product) => product.slug === 'grain-soybean-meal');
	}

	get classIII() {
		return this.model.Products.find((product: Product) => product.slug === 'us-dairy-class-iii');
	}

	get dryWhey() {
		return this.model.Products.find((product: Product) => product.slug === 'us-dairy-dry-whey');
	}

	get classIV() {
		return this.model.Products.find((product: Product) => product.slug === 'us-dairy-class-iv');
	}

	get cheese() {
		return this.model.Products.find((product: Product) => product.slug === 'us-dairy-cheese');
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'reports/dairy-historical-percentiles-five-year': ReportsDairyHistoricalPercentilesFiveYear;
	}
}
