import { DateTime } from 'luxon';

export default class FeedBoughtHedgedMonth {
	// Short ton (2,000 lbs) used
	LBS_PER_TON = 2000;

	tonsPerContract: number;
	date: string;
	usage: number = 0;
	bought: number = 0;
	basisLocked: number = 0;
	futureLocked: number = 0;
	futuresHedged: number = 0;
	callsHedged: number = 0;
	lbsPerBushel;

	constructor(date: string, lbsPerBushel: number = 56, tonsPerContract: number = 0) {
		this.date = date;
		this.lbsPerBushel = lbsPerBushel;
		this.tonsPerContract = tonsPerContract;
	}

	get monthStartDate() {
		return DateTime.fromISO(this.date).startOf('month');
	}

	get monthEndDate() {
		return DateTime.fromISO(this.date).endOf('month');
	}

	get boughtPercent() {
		return this.usage ? this.bought / this.usage : 0;
	}

	get basisLockedPercent() {
		return this.usage ? this.basisLocked / this.usage : 0;
	}

	get futureLockedPercent() {
		return this.usage ? this.futureLocked / this.usage : 0;
	}

	get futuresHedgedPercent() {
		return this.usage ? this.futuresHedged / this.usage : 0;
	}

	get callsHedgedPercent() {
		return this.usage ? this.callsHedged / this.usage : 0;
	}

	get hedgedPercent() {
		return this.usage ? this.totalHedged / this.usage : 0;
	}

	get totalHedged() {
		return this.bought + this.basisLocked + this.futureLocked + this.futuresHedged + this.callsHedged;
	}

	get unhedged() {
		if (this.totalHedged > this.usage) return 0;
		return this.usage - this.totalHedged;
	}

	get unhedgedPercent() {
		if (!this.usage || this.unhedged < 0) return 0;

		return this.unhedged / this.usage;
	}

	// Tons
	get usageInTons() {
		return this.usage / this.LBS_PER_TON;
	}

	get unhedgedInTons() {
		return this.unhedged / this.LBS_PER_TON;
	}

	get boughtInTons() {
		return this.bought / this.LBS_PER_TON;
	}

	get basisLockedInTons() {
		return this.basisLocked / this.LBS_PER_TON;
	}

	get futureLockedInTons() {
		return this.futureLocked / this.LBS_PER_TON;
	}

	get futuresHedgedInTons() {
		return this.futuresHedged / this.LBS_PER_TON;
	}

	get callsHedgedInTons() {
		return this.callsHedged / this.LBS_PER_TON;
	}

	// Bushels
	get usageInBushels() {
		return this.usage / this.lbsPerBushel;
	}

	get unhedgedInBushels() {
		return this.unhedged / this.lbsPerBushel;
	}

	get boughtInBushels() {
		return this.bought / this.lbsPerBushel;
	}

	get basisLockedInBushels() {
		return this.basisLocked / this.lbsPerBushel;
	}

	get futureLockedInBushels() {
		return this.futureLocked / this.lbsPerBushel;
	}

	get futuresHedgedInBushels() {
		return this.futuresHedged / this.lbsPerBushel;
	}

	get callsHedgedInBushels() {
		return this.callsHedged / this.lbsPerBushel;
	}

	// Contracts
	get usageInContracts() {
		if (!this.tonsPerContract) return 0;
		return this.usageInTons / this.tonsPerContract;
	}

	get unhedgedInContracts() {
		if (!this.tonsPerContract) return 0;
		return this.unhedgedInTons / this.tonsPerContract;
	}

	get boughtInContracts() {
		if (!this.tonsPerContract) return 0;
		return this.boughtInTons / this.tonsPerContract;
	}

	get basisLockedInContracts() {
		if (!this.tonsPerContract) return 0;
		return this.basisLockedInTons / this.tonsPerContract;
	}

	get futureLockedInContracts() {
		if (!this.tonsPerContract) return 0;
		return this.futureLockedInTons / this.tonsPerContract;
	}

	get futuresHedgedInContracts() {
		if (!this.tonsPerContract) return 0;
		return this.futuresHedgedInTons / this.tonsPerContract;
	}

	get callsHedgedInContracts() {
		if (!this.tonsPerContract) return 0;
		return this.callsHedgedInTons / this.tonsPerContract;
	}
}
