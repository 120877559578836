import BrokerageIndexController from 'vault-client/controllers/brokerage/index';

export default class BusinessesBusinessBrokerage extends BrokerageIndexController {
	accountsRoutePath: string = 'businesses.business.accounts';
	positionsRoutePath: string = 'businesses.business.positions';
	transactionsRoutePath: string = 'businesses.business.transactions';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'businesses/business/brokerage': BusinessesBusinessBrokerage;
	}
}
