export default class InsurancePolicyFile {
	createdAt: Date;
	fileName: string;
	id: string;
	lastModifiedAt: Date;
	reinsuranceYear: Date;
	updatedAt: Date;
	url: string;
	description: string;

	constructor(file: any) {
		this.createdAt = file.createdAt;
		this.fileName = file.fileName;
		this.id = file.id;
		this.lastModifiedAt = file.lastModifiedAt;
		this.reinsuranceYear = file.reinsuranceYear;
		this.description = file.description;
		this.updatedAt = file.updatedAt;
		this.url = file.url;
	}
}
