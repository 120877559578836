import Controller from '@ember/controller';
import { A } from '@ember/array';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import resetVaultTableScroll from 'vault-client/utils/reset-vault-table-scroll';
import { CellComponents, SortObj, TableColumn } from 'vault-client/types/vault-table';
import { Product, TypeOfInstrumentSymbolGroup } from 'vault-client/types/graphql-types';
import { guidFor } from '@ember/object/internals';

export default class MarketsProductFuturesController extends Controller {
	@tracked numberOfYears: number = 5;
	@tracked model: any;
	@tracked sorts: SortObj[] = [
		{
			valuePath: 'displayExpiresAt',
			isAscending: true,
		},
	];
	@tracked columns: TableColumn[] = A([
		{
			id: '96bf2f77-9c37-4fbc-af42-c83dd93ea908',
			name: 'Exp Month',
			valuePath: 'displayExpiresAt',
			cellComponent: CellComponents.MonthFormat,
			minWidth: 130,
			textAlign: 'left',
			isSortable: true,
			isFixed: '',
			isVisible: true,
			linkRoute: 'markets.product.futures.future',
			linkModelPath: 'id',
		},
		{
			id: '8b627f6e-d423-4b2d-bf9b-23b1d9555a87',
			name: 'Symbol',
			valuePath: 'exchangeSymbol',
			minWidth: 110,
			cellComponent: CellComponents.String,
			textAlign: 'left',
			isSortable: true,
			isFixed: '',
			isVisible: true,
		},
		{
			id: '3c698c24-62a1-4ad5-9bf1-3e099a737d4b',
			name: 'Last Price',
			valuePath: 'self',
			minWidth: 110,
			cellComponent: CellComponents.MarketPriceFormat,
			componentArgs: {
				fractionDigitsPath: 'SymbolGroup.fractionDigits',
				displayFactorPath: 'SymbolGroup.displayFactor',
				side: 'last',
			},
			textAlign: 'right',
			isSortable: false,
			isFixed: '',
			isVisible: true,
		},
		{
			id: 'cba75f99-de90-4bea-9931-c96871ccf7be',
			name: 'Low',
			valuePath: 'self',
			minWidth: 110,
			cellComponent: CellComponents.MarketPriceFormat,
			componentArgs: {
				fractionDigitsPath: 'SymbolGroup.fractionDigits',
				displayFactorPath: 'SymbolGroup.displayFactor',
				side: 'low',
			},
			textAlign: 'right',
			isSortable: false,
			isFixed: '',
			isVisible: true,
		},
		{
			id: '310e6c80-820d-428e-ab4d-f4bb8d4816e3',
			name: 'High',
			valuePath: 'self',
			minWidth: 110,
			cellComponent: CellComponents.MarketPriceFormat,
			componentArgs: {
				fractionDigitsPath: 'SymbolGroup.fractionDigits',
				displayFactorPath: 'SymbolGroup.displayFactor',
				side: 'high',
			},
			textAlign: 'right',
			isSortable: false,
			isFixed: '',
			isVisible: true,
		},
		{
			id: '96cad23e-5e4d-4b66-bb85-60b98c99d8a2',
			name: 'Session Change',
			valuePath: 'self',
			minWidth: 150,
			cellComponent: CellComponents.SessionChangeFormat,
			componentArgs: {
				fractionDigitsPath: 'SymbolGroup.fractionDigits',
				displayFactorPath: 'SymbolGroup.displayFactor',
			},
			textAlign: 'right',
			isSortable: false,
			isFixed: '',
			isVisible: true,
		},
		{
			id: '1537e7da-2f64-4a30-ae47-553debce98b9',
			name: 'Previous Settlement',
			valuePath: 'self',
			minWidth: 170,
			cellComponent: CellComponents.MarketPriceFormat,
			componentArgs: {
				fractionDigitsPath: 'SymbolGroup.fractionDigits',
				displayFactorPath: 'SymbolGroup.displayFactor',
				side: 'previousSettlement',
			},
			textAlign: 'right',
			isSortable: false,
			isFixed: '',
			isVisible: true,
		},
		{
			id: '9872c04a-3b60-483a-9fc9-bbb9b3b4838f',
			name: 'Settlement',
			valuePath: 'self',
			minWidth: 110,
			cellComponent: CellComponents.MarketPriceFormat,
			componentArgs: {
				fractionDigitsPath: 'SymbolGroup.fractionDigits',
				displayFactorPath: 'SymbolGroup.displayFactor',
				side: 'settlement',
			},
			textAlign: 'right',
			isSortable: false,
			isFixed: '',
			isVisible: true,
		},
		{
			id: '1da7a8e0-054a-4472-b969-5bce9a975840',
			name: 'Volume',
			valuePath: 'self',
			minWidth: 110,
			cellComponent: CellComponents.MarketVolume,
			componentArgs: {
				side: 'volume',
			},
			textAlign: 'right',
			isSortable: false,
			isFixed: '',
			isVisible: true,
		},
		{
			id: '1e21a7d3-fb2c-4f38-9f01-e2ea5d914807',
			name: 'Open Interest',
			valuePath: 'self',
			minWidth: 130,
			cellComponent: CellComponents.MarketOpenInterest,
			componentArgs: {
				side: 'openInterest',
			},
			textAlign: 'right',
			isSortable: false,
			isFixed: '',
			isVisible: true,
		},
	]);

	id = guidFor(this);

	get chartId() {
		return `${this.product.name.toLowerCase()}-historical-percentile-chart`;
	}

	get product() {
		return this.model.Product as Product;
	}

	get futureSymbolGroup() {
		return this.product.InstrumentSymbolGroups.find((SymbolGroup) => SymbolGroup.type === TypeOfInstrumentSymbolGroup.Future);
	}

	@action
	updateSorts(sorts: SortObj[]) {
		this.sorts = sorts;
		resetVaultTableScroll(`markets-${this.product.slug}-futures-table`);
	}

	@action
	inputValidator(value: string) {
		return Number.isInteger(+value) && +value >= 1 && +value <= 30;
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'markets/product/futures': MarketsProductFuturesController;
	}
}
