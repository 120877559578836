import Option from './option';
import TransactionClass from './transaction';
//@ts-ignore
import Big from 'big.js';
import { next } from '@ember/runloop';
import { Transaction, Account, Instrument } from 'vault-client/types/graphql-types';
import { tracked } from '@glimmer/tracking';

export default class OptionTransaction extends TransactionClass {
	@tracked Instrument: Option;

	constructor(owner: any, transaction: Transaction, instrument: Instrument | null, account: Account | null, useEODPrice = false) {
		super(owner, transaction, account, useEODPrice);

		const _instrument = instrument ?? transaction.Instrument;
		this.Instrument = new Option(_instrument as any);

		// DGC: For some reason, I had to push this to the next runloop to prevent
		// a Glimmer error.
		// adding option instrument
		const barchartSymbol = this.Instrument.barchartSymbol;
		const obj = { barchartSymbol, marketData: this.marketData };
		next(obj, function () {
			if (obj.barchartSymbol) obj.marketData.register(obj.barchartSymbol);
		});

		// adding option underlying instrument
		if (this.Instrument.hasExpired) {
			const obj = {
				barchartSymbol: this.Instrument.UnderlyingInstrument?.barchartSymbol,
				marketData: this.marketData,
			};
			next(obj, function () {
				if (obj.barchartSymbol) obj.marketData.register(obj.barchartSymbol);
			});
		}
	}

	get plPerContract() {
		let pointValue = this.Instrument.Product?.StandardProductLotSpecification?.pointValue || 1;
		if (typeof pointValue === 'string') {
			pointValue = pointValue.replace(/[^0-9.]/g, '');
		}

		const premium = this.quantity > 0 ? new Big(this.price).times(pointValue).times(-1) : new Big(this.price).times(pointValue);
		let strikePl;

		if (this.Instrument.hasExpired) {
			const underlyingMarketPrice = this.Instrument.UnderlyingInstrument?.barchartSymbol
				? this.getMarketPrice(this.Instrument.UnderlyingInstrument.barchartSymbol)
				: null;
			if (!underlyingMarketPrice) {
				return premium;
			}

			if (this.Instrument.isCall && underlyingMarketPrice > this.Instrument.strike) {
				strikePl = new Big(underlyingMarketPrice).minus(this.Instrument.strike).times(pointValue);
				return this.quantity >= 0 ? premium.plus(strikePl) : premium.minus(strikePl);
			}

			if (this.Instrument.isPut && underlyingMarketPrice < this.Instrument.strike) {
				strikePl = new Big(this.Instrument.strike).minus(underlyingMarketPrice).times(pointValue);
				return this.quantity >= 0 ? premium.plus(strikePl) : premium.minus(strikePl);
			}

			return premium;
		} else {
			const marketPrice = this.Instrument.barchartSymbol ? this.getMarketPrice(this.Instrument.barchartSymbol) : null;

			if (marketPrice) {
				strikePl = new Big(marketPrice).minus(this.price).times(pointValue);
				return this.quantity >= 0 ? strikePl : strikePl.times(-1);
			} else {
				return premium;
			}
		}
	}

	get premium() {
		let pointValue = this.Instrument?.Product?.StandardProductLotSpecification.pointValue || 1;
		if (typeof pointValue === 'string') {
			pointValue = pointValue.replace(/[^0-9.]/g, '');
		}

		return new Big(this.quantity).times(this.price).times(-1).times(pointValue);
	}

	get grossPl() {
		return parseFloat(this.plPerContract?.times(new Big(this.quantityInContracts).abs()).toString());
	}

	get netPl() {
		return this.grossPl + this.commission + this.nonCommissionFees;
	}
}
