import UnmappedAccountsController from 'vault-client/controllers/reports/unmapped-accounts';

export default class OrganizationsOrganizationUnmappedAccountsController extends UnmappedAccountsController {
	accountRoutePath: string = 'organizations.organization.account';
	reportsRoutePath = 'organizations.organization.reports';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'organizations/organization/unmapped-accounts': OrganizationsOrganizationUnmappedAccountsController;
	}
}
