import Route from '@ember/routing/route';
import { tracked } from '@glimmer/tracking';
import { gql, useQuery } from 'glimmer-apollo';
import { Query, Query_CropCategoriesArgs, SortByDirection } from 'vault-client/types/graphql-types';
import { SortObj } from 'vault-client/types/vault-table';

export const GET_CROP_CATEGORIES = gql`
	query CropCategories($orderBy: CropCategorySortByDTO) {
		CropCategories(orderBy: $orderBy) {
			id
			name
		}
	}
`;

type getCrops = {
	__typename?: 'Query';

	CropCategories: Query['CropCategories'];
};

interface QueryParams {
	sorts: SortObj[];
}
``;

export default class VgsSettingsCrops extends Route {
	@tracked variables: Query_CropCategoriesArgs = {};

	getCropCategories = useQuery<getCrops, Query_CropCategoriesArgs>(this, () => [GET_CROP_CATEGORIES, { variables: this.variables }]);

	queryParams = {
		sorts: { refreshModel: true },
	};

	generateOrderBy(sorts: SortObj[]) {
		const orderBy: {
			[key: string]: any;
		} = {};

		sorts.forEach((sort: any) => {
			const parts = sort.valuePath.split('.');
			const firstPart = parts.shift();

			let value: any = sort.isAscending ? SortByDirection.Asc : SortByDirection.Desc;

			while (parts.length > 0) {
				const part = parts.pop();
				const obj: {
					[key: string]: any;
				} = {};
				obj[part] = value;
				value = obj;
			}

			orderBy[firstPart] = value;
		});

		return orderBy;
	}

	async model(params: QueryParams) {
		this.variables = {
			orderBy: this.generateOrderBy(params.sorts),
		};

		await this.getCropCategories.promise;

		return { getCropCategories: this.getCropCategories, variables: this.variables };
	}
}
