import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.id}}\n\t<Vault::UiConfirmation as |confirmation|>\n\t\t<confirmation.title>{{this.titleText}}</confirmation.title>\n\t\t<confirmation.body>{{this.bodyText}}\n\t\t\t{{#if this.label}}\n\t\t\t\t<br />\n\t\t\t\t<span class='font-sans-semibold text-base leading-9'>{{this.label}}</span>\n\t\t\t{{/if}}\n\t\t\t<br />This action is irreversible.\n\t\t</confirmation.body>\n\t\t<confirmation.cancel\n\t\t\t@style='plain'\n\t\t\t@disabled={{this.disableButtons}}\n\t\t\t{{on 'click' this.closeConfirmation}}\n\t\t>{{this.cancelButtonText}}</confirmation.cancel>\n\t\t<confirmation.action\n\t\t\t@style='destructive'\n\t\t\t@disabled={{this.disableButtons}}\n\t\t\t{{on 'click' (perform this.deleteItem)}}\n\t\t>{{this.deleteButtonText}}</confirmation.action>\n\t</Vault::UiConfirmation>\n{{/if}}", {"contents":"{{#if this.id}}\n\t<Vault::UiConfirmation as |confirmation|>\n\t\t<confirmation.title>{{this.titleText}}</confirmation.title>\n\t\t<confirmation.body>{{this.bodyText}}\n\t\t\t{{#if this.label}}\n\t\t\t\t<br />\n\t\t\t\t<span class='font-sans-semibold text-base leading-9'>{{this.label}}</span>\n\t\t\t{{/if}}\n\t\t\t<br />This action is irreversible.\n\t\t</confirmation.body>\n\t\t<confirmation.cancel\n\t\t\t@style='plain'\n\t\t\t@disabled={{this.disableButtons}}\n\t\t\t{{on 'click' this.closeConfirmation}}\n\t\t>{{this.cancelButtonText}}</confirmation.cancel>\n\t\t<confirmation.action\n\t\t\t@style='destructive'\n\t\t\t@disabled={{this.disableButtons}}\n\t\t\t{{on 'click' (perform this.deleteItem)}}\n\t\t>{{this.deleteButtonText}}</confirmation.action>\n\t</Vault::UiConfirmation>\n{{/if}}","moduleName":"vault-client/components/base-delete-modal.hbs","parseOptions":{"srcName":"vault-client/components/base-delete-modal.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { useMutation } from 'glimmer-apollo';
import { DocumentNode } from 'graphql';
import RouterService from '@ember/routing/router-service';
import { tracked } from '@glimmer/tracking';
import { task } from 'ember-concurrency';

interface BaseDeleteModalComponentArgs {
	closeConfirmation: () => void;
	itemToDelete?: { id: string } & unknown;
	redirectRoutePath?: string;
	redirectModel?: string;
	redirectQueryParams?: Record<string, string>;
}

export default class BaseDeleteModalComponent extends Component<BaseDeleteModalComponentArgs> {
	@service declare router: RouterService;

	@tracked errorMessage: string | null = null;

	mutation: DocumentNode | null = null;
	evictionFields: string[] = [];

	get id() {
		return this.args.itemToDelete?.id;
	}

	get titleText() {
		return 'Delete Item';
	}

	get bodyText() {
		return 'Are you sure you want to delete this item?';
	}

	get cancelButtonText() {
		return 'Cancel';
	}

	get deleteButtonText() {
		return 'Delete Item';
	}

	get label(): string {
		return '';
	}

	get disableButtons() {
		return this.deleteItem.isRunning;
	}

	deleteItem = task({ drop: true }, async () => {
		const mutation = this.mutation;
		if (!mutation) {
			const message = 'No mutation provided to Base Delete Button Component';
			console.warn(message);
			this.errorMessage = message;
			return;
		}

		const id = this.id;
		if (!id) {
			const message = 'No id provided to Base Delete Button Component';
			console.warn(message);
			this.errorMessage = message;
			return;
		}

		await useMutation<{ [key: string]: unknown }, unknown>(this, () => [
			mutation,
			{
				update: (cache) => {
					this.evictionFields.forEach((fieldName) => {
						cache.evict({ fieldName });
					});
					cache.gc();
				},
				onError: (error) => {
					console.warn('Error deleting item: ', error.message);
					this.errorMessage = error.message;
				},
				onComplete: () => {
					this.closeConfirmation();
					if (this.args.redirectRoutePath) {
						this.redirect();
					}
				},
			},
		]).mutate({ id });
	});

	@action
	closeConfirmation() {
		this.errorMessage = null;
		this.args.closeConfirmation();
	}

	@action
	redirect() {
		const routePath = this.args.redirectRoutePath;
		const model = this.args.redirectModel;
		const queryParams = this.args.redirectQueryParams;

		if (routePath && model && queryParams) {
			this.router.transitionTo(routePath, model, { queryParams });
		} else if (routePath && model) {
			this.router.transitionTo(routePath, model);
		} else if (routePath && queryParams) {
			this.router.transitionTo(routePath, { queryParams });
		} else if (routePath) {
			this.router.transitionTo(routePath);
		}
	}
}
