import AccountsShowRoute from 'vault-client/routes/accounts/show';
import { DateTime } from 'luxon';

export default class BusinessesBusinessAccount extends AccountsShowRoute {
	async model(params: any) {
		const dateToday: any = DateTime.now().toISODate();
		this.variables = {
			id: params.account_id,
			startDate: dateToday,
		};

		await this.getAccount.promise;
		return this.getAccount;
	}
}
