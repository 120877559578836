import FoundationPlan from './foundation-plan';

export default class FoundationCropPlan extends FoundationPlan {
	constructor(production: any) {
		super(production);
	}

	get cropPlanDisplayId() {
		if (this.CropYear && this.Customer) {
			return `${this.Customer.name} ${this.Crop.name} ${this.CropYear.year}`;
		} else {
			return this.id;
		}
	}

	get cropPlanOpenTargets() {
		return this.Targets.filter(
			(t: any) =>
				t.TargetStatus.status === 'New' ||
				t.TargetStatus.status === 'Pending' ||
				t.TargetStatus.status === 'Submitted' ||
				t.TargetStatus.status === 'Working'
		);
	}
}
