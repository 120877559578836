import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { TypeOfGrainOrderStatus } from 'vault-client/types/graphql-types';
import { getGrainOrderStatusDisplayValue } from 'vault-client/utils/vgs-utils';
interface ModificationRequestStatusSelectArgs {
	modificationStatus: TypeOfGrainOrderStatus | null;
	setModStatus: (status: TypeOfGrainOrderStatus | null) => void;
}

type ModificationStatusListItem = { display: string; value: TypeOfGrainOrderStatus | null };
export default class ModificationRequestStatusSelect extends Component<ModificationRequestStatusSelectArgs> {
	modificationStatusList: ModificationStatusListItem[] = [
		{
			display: 'All',
			value: null,
		},
		{
			display: getGrainOrderStatusDisplayValue(TypeOfGrainOrderStatus.New),
			value: TypeOfGrainOrderStatus.New,
		},
		{
			display: getGrainOrderStatusDisplayValue(TypeOfGrainOrderStatus.Pending),
			value: TypeOfGrainOrderStatus.Pending,
		},
		{
			display: getGrainOrderStatusDisplayValue(TypeOfGrainOrderStatus.Working),
			value: TypeOfGrainOrderStatus.Working,
		},
		{
			display: getGrainOrderStatusDisplayValue(TypeOfGrainOrderStatus.PendingCancellation),
			value: TypeOfGrainOrderStatus.PendingCancellation,
		},
		{
			display: getGrainOrderStatusDisplayValue(TypeOfGrainOrderStatus.Cancelled),
			value: TypeOfGrainOrderStatus.Cancelled,
		},
		{
			display: getGrainOrderStatusDisplayValue(TypeOfGrainOrderStatus.Filled),
			value: TypeOfGrainOrderStatus.Filled,
		},
		{
			display: getGrainOrderStatusDisplayValue(TypeOfGrainOrderStatus.Submitted),
			value: TypeOfGrainOrderStatus.Submitted,
		},
	];

	@tracked modificationStatus = this.args.modificationStatus ? TypeOfGrainOrderStatus.New : null;

	@action
	setModStatus(status: ModificationStatusListItem) {
		this.args.setModStatus(status.value);
	}

	get selected() {
		return this.modificationStatusList.find((status) => status.value === this.args.modificationStatus) ?? null;
	}
}
