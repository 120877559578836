import Controller from '@ember/controller';
import intlNumberFormat from 'vault-client/utils/intl-number-format';
import intlDateTimeFormat from 'vault-client/utils/intl-date-time-format';
import DerivedInsuranceEndorsement from 'vault-client/models/derived-insurance-endorsement';
import { tracked } from '@glimmer/tracking';
import isTouchDevice from 'vault-client/utils/is-touch-device';
import { TableColumn, CellComponents } from 'vault-client/types/vault-table';
import { ModelFrom } from 'vault-client/utils/type-utils';
import DRPInsuranceEndorsementsShowRoute from 'vault-client/routes/drp-insurance-endorsements/show';

export default class DRPInsuranceEndorsementsShowController extends Controller {
	declare model: ModelFrom<DRPInsuranceEndorsementsShowRoute>;
	@tracked organizationId: string | null = null;
	@tracked globalCustomerId: string | null = null;
	@tracked locationId: string | null = null;

	drpInsuranceEndorsementsRoute: string = '';

	get exchangePricing(): TableColumn[] {
		const baseColumns: TableColumn[] = [
			{
				id: 'c33a982e-aa89-402c-84d4-16beec20c942',
				name: '',
				valuePath: 'rowHeader',
				minWidth: 120,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: !isTouchDevice() ? 'left' : '',
				isVisible: true,
			},
			{
				id: '0896d517-cec5-4bfb-b2b1-699a3cc3fcd5',
				name: 'Effective Pounds',
				valuePath: 'effectivePounds',
				minWidth: 150,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					minimumFractionDigits: '0',
					maximumFractionDigits: '0',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'ea621118-c2d7-4e8a-be74-f56fe4a304b8',
				name: 'Equivalent CME Contracts',
				valuePath: 'cmeContracts',
				minWidth: 210,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					minimumFractionDigits: '2',
					maximumFractionDigits: '2',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'ce1ec965-3a2e-4a6d-b0a1-06d99c9cf7d5',
				name: 'Insured Price',
				valuePath: 'insuredPrice',
				minWidth: 140,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					minimumFractionDigits: '4',
					maximumFractionDigits: '4',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '162bc87b-8606-453e-9152-e2d5b37ac920',
				name: 'Indemnity Effect',
				valuePath: 'indemnityEffect',
				minWidth: 160,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					minimumFractionDigits: '2',
					maximumFractionDigits: '2',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
		];
		return baseColumns;
	}

	get componentPricing(): TableColumn[] {
		const baseColumns: TableColumn[] = [
			{
				id: '001fea28-f2a9-4d04-b07e-956ef6ad0301',
				name: '',
				valuePath: 'rowHeader',
				minWidth: 120,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: !isTouchDevice() ? 'left' : '',
				isVisible: true,
			},
			{
				id: 'bf5a21b9-262a-4c7d-a411-5335a5e28a51',
				name: 'Effective Pounds',
				valuePath: 'effectivePounds',
				minWidth: 150,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					minimumFractionDigits: '0',
					maximumFractionDigits: '0',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'a4ce252a-c075-42ed-b357-827b320799d7',
				name: 'Insured Price',
				valuePath: 'insuredPrice',
				minWidth: 140,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					minimumFractionDigits: '4',
					maximumFractionDigits: '4',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'a6822d47-2e0a-4545-90a1-94e51225090b',
				name: 'Indemnity Effect',
				valuePath: 'indemnityEffect',
				minWidth: 160,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					minimumFractionDigits: '2',
					maximumFractionDigits: '2',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
		];
		return baseColumns;
	}

	get contractSpecifications(): TableColumn[] {
		const baseColumns = [
			{
				id: '9a1ce19a-e7ff-46ed-a577-5b6ce1afc1d0',
				name: '',
				valuePath: 'rowHeader',
				minWidth: 260,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: !isTouchDevice() ? 'left' : '',
				isVisible: true,
			},
			{
				id: '091af6ab-5238-4fee-9a65-879e511e5a17',
				name: '',
				valuePath: 'value',
				minWidth: 160,
				textAlign: 'right',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
		];
		return baseColumns;
	}
	get yieldAdjustment(): TableColumn[] {
		const baseColumns = [
			{
				id: 'ae78a442-90b2-4094-8e86-2604e2ff723e',
				name: '',
				valuePath: 'rowHeader',
				minWidth: 80,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: !isTouchDevice() ? 'left' : '',
				isVisible: true,
			},
			{
				id: 'e2aca76f-6345-411b-bb9f-6d7021365097',
				name: '',
				valuePath: 'value',
				minWidth: 240,
				textAlign: 'right',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
		];
		return baseColumns;
	}

	get endorsement() {
		return new DerivedInsuranceEndorsement(this.model.data?.DerivedDrpEndorsement ?? null);
	}

	get exchangePricingData() {
		const endorsement = this.endorsement;

		return [
			{
				rowHeader: 'Class III',
				effectivePounds: endorsement.classIiiPounds,
				cmeContracts: endorsement.classIiiContracts,
				insuredPrice: endorsement.insuredClassIiiPrice,
				indemnityEffect: endorsement.indemnityEffectClassIii,
			},
			{
				rowHeader: 'Class IV',
				effectivePounds: endorsement.classIvPounds,
				cmeContracts: endorsement.classIvContracts,
				insuredPrice: endorsement.insuredClassIvPrice,
				indemnityEffect: endorsement.indemnityEffectClassIv,
			},
			{
				rowHeader: 'Cheese',
				effectivePounds: endorsement.excessCheesePounds,
				cmeContracts: endorsement.cheeseContracts,
				insuredPrice: endorsement.insuredCheesePrice,
				indemnityEffect: endorsement.indemnityEffectCheese,
			},
			{
				rowHeader: 'Butter',
				effectivePounds: endorsement.excessButterPounds,
				cmeContracts: endorsement.butterContracts,
				insuredPrice: endorsement.insuredButterPrice,
				indemnityEffect: endorsement.indemnityEffectButter,
			},
		];
	}

	get componentPricingData() {
		const endorsement = this.endorsement;

		return [
			{
				rowHeader: 'Butterfat',
				effectivePounds: endorsement.butterfatPounds,
				insuredPrice: endorsement.insuredButterfatPrice,
				indemnityEffect: endorsement.indemnityEffectButterfat,
			},
			{
				rowHeader: 'Protein',
				effectivePounds: endorsement.proteinPounds,
				insuredPrice: endorsement.insuredProteinPrice,
				indemnityEffect: endorsement.indemnityEffectProtein,
			},
			{
				rowHeader: 'Other Solids',
				effectivePounds: endorsement.otherSolidsPounds,
				insuredPrice: endorsement.insuredOtherSolidsPrice,
				indemnityEffect: endorsement.indemnityEffectOtherSolids,
			},
			{
				rowHeader: 'Nonfat Solids',
				effectivePounds: endorsement.nonfatSolidsPounds,
				insuredPrice: endorsement.insuredNonfatSolidsPrice,
				indemnityEffect: endorsement.indemnityEffectNonfatSolids,
			},
		];
	}

	get contractSpecificationsData() {
		const endorsement = this.endorsement;

		return [
			{
				rowHeader: 'Sales Effective Date',
				value: intlDateTimeFormat({
					value: endorsement.salesEffectiveDate,
					day: 'numeric',
					month: 'long',
					year: 'numeric',
					nullSkipFormat: true,
				}),
			},
			{
				rowHeader: 'Declared Share',
				value: intlNumberFormat({
					value: endorsement.declaredShare,
					style: 'percent',
					nullSkipFormat: true,
				}),
			},
			{
				rowHeader: 'Coverage Level %',
				value: intlNumberFormat({
					value: endorsement.coverageLevelPercent,
					style: 'percent',
					nullSkipFormat: true,
				}),
			},
			{
				rowHeader: 'Declared Covered Milk Production',
				value: intlNumberFormat({
					value: endorsement.declaredCoveredMilkProduction,
					maximumFractionDigits: '0',
					minimumFractionDigits: '0',
					nullSkipFormat: true,
				}),
			},
			{
				rowHeader: 'Protection Factor',
				value: endorsement.protectionFactor
					? intlNumberFormat({
							value: endorsement.protectionFactor,
							maximumFractionDigits: '2',
							minimumFractionDigits: '1',
							nullSkipFormat: true,
					  }).concat('x')
					: '–',
			},
			{
				rowHeader: 'Declared Butterfat',
				value: intlNumberFormat({
					value: endorsement.declaredButterfatTest,
					maximumFractionDigits: '2',
					minimumFractionDigits: '2',
					style: 'percent',
					nullSkipFormat: true,
				}),
			},
			{
				rowHeader: 'Declared Protein',
				value: intlNumberFormat({
					value: endorsement.declaredProteinTest,
					maximumFractionDigits: '2',
					minimumFractionDigits: '2',
					style: 'percent',
					nullSkipFormat: true,
				}),
			},
			{
				rowHeader: 'Class III %',
				value: intlNumberFormat({
					value: endorsement.classIiiPercentage,
					maximumFractionDigits: '0',
					minimumFractionDigits: '0',
					style: 'percent',
					nullSkipFormat: true,
				}),
			},
			{
				rowHeader: 'Class IV %',
				value: intlNumberFormat({
					value: endorsement.classIvPercentage,
					maximumFractionDigits: '0',
					minimumFractionDigits: '0',
					style: 'percent',
					nullSkipFormat: true,
				}),
			},
			{
				rowHeader: 'Total Premium',
				value: intlNumberFormat({
					value: endorsement.totalPremiumAmount,
					maximumFractionDigits: '0',
					minimumFractionDigits: '0',
					style: 'currency',
					currency: 'USD',
					nullSkipFormat: true,
				}),
			},
			// {
			// 	rowHeader: 'Subsidy',
			// 	value: intlNumberFormat({
			// 		value: endorsement.subsidyAmount,
			// 		maximumFractionDigits: '0',
			// 		minimumFractionDigits: '0',
			// 		style: 'currency',
			// 		currency: 'USD',
			// 		nullSkipFormat: true,
			// 	}),
			// },
			{
				rowHeader: 'Producer Premium',
				value: intlNumberFormat({
					value: endorsement.producerPremiumAmount,
					maximumFractionDigits: '0',
					minimumFractionDigits: '0',
					style: 'currency',
					currency: 'USD',
					nullSkipFormat: true,
				}),
			},
			{
				rowHeader: 'Revenue Guarantee',
				value: intlNumberFormat({
					value: endorsement.expectedRevenueGuarantee,
					maximumFractionDigits: '0',
					minimumFractionDigits: '0',
					style: 'currency',
					currency: 'USD',
					nullSkipFormat: true,
				}),
			},
		];
	}

	get yieldAdjustmentData() {
		const endorsement = this.endorsement;

		return [
			{
				rowHeader: 'Insured Yield',
				value: (
					intlNumberFormat({
						value: endorsement.insuredYield,
						maximumFractionDigits: '0',
						minimumFractionDigits: '0',
						nullSkipFormat: true,
					}) ?? '–'
				).concat(
					` (${
						intlDateTimeFormat({
							value: endorsement.salesEffectiveDate,
							day: 'numeric',
							month: 'long',
							year: 'numeric',
							nullSkipFormat: true,
						}) ?? '–'
					})`
				),
			},
			{
				rowHeader: 'Actual Yield',
				value: intlNumberFormat({
					value: endorsement.actualYield,
					maximumFractionDigits: '0',
					minimumFractionDigits: '0',
					nullSkipFormat: true,
				}),
			},
			{
				rowHeader: 'Last Yield',
				value: intlNumberFormat({
					value: endorsement.lastYield,
					maximumFractionDigits: '0',
					minimumFractionDigits: '0',
					nullSkipFormat: true,
				}) /*.concat(
					` (${intlDateTimeFormat({
						value: endorsement.lastYieldReleasedDate,
						day: 'numeric',
						month: 'long',
						year: 'numeric',
						nullSkipFormat: true,
					})})`
				)*/,
			},
			{
				rowHeader: 'Yield Change',
				value: intlNumberFormat({
					value: endorsement.yieldChange,
					maximumFractionDigits: '2',
					minimumFractionDigits: '2',
					style: 'percent',
					nullSkipFormat: true,
				}),
			},
		];
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'drp-insurance-endorsement-show-controller': DRPInsuranceEndorsementsShowController;
	}
}
