import InsurancePoliciesShowController from 'vault-client/controllers/insurance-policies/show';

export default class OrganizationsOrganizationInsurancePolicy extends InsurancePoliciesShowController {
	insurancePoliciesRoute: string = 'organizations.organization.insurance-policies';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'organizations/organization/insurance-policy': OrganizationsOrganizationInsurancePolicy;
	}
}
