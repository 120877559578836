import ReportsDrpPerformanceBySalesEffectiveDateMonth from 'vault-client/routes/reports/drp-performance-by-sales-effective-date-month';
import { SortObj } from 'vault-client/types/vault-table';

interface QueryParams {
	agent: string | null;
	aipId: string | null;
	quarterStartDate: string | null;
	quarterEndDate: string | null;
	salesEffectiveStartDate: string | null;
	salesEffectiveEndDate: string | null;
	sorts: SortObj[];
	stateId: string | null;
	type: string | null;
}
export default class BusinessesBusinessDrpPerformanceBySalesEffectiveDateMonthRoute extends ReportsDrpPerformanceBySalesEffectiveDateMonth {
	async model(params: QueryParams) {
		const businessParams = this.paramsFor('businesses.business') as { business_id: string };
		const variables = {
			where: this.generateWhere(
				null,
				businessParams.business_id,
				params.quarterStartDate,
				params.quarterEndDate,
				params.agent,
				params.stateId,
				params.aipId,
				params.type,
				params.salesEffectiveStartDate,
				params.salesEffectiveEndDate
			),
			orderBy: this.generateOrderBy(params.sorts),
		};

		return this.apollo.watchQuery({ query: this.query, variables });
	}
}
