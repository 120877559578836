import Route from '@ember/routing/route';
import { tracked } from '@glimmer/tracking';
import { gql, useQuery } from 'glimmer-apollo';
import { Query, Query_SellersArgs, SortByDirection } from 'vault-client/types/graphql-types';
import { SortObj } from 'vault-client/types/vault-table';

export const GET_SELLERS = gql`
	query Sellers($orderBy: SellerSortByDTO) {
		Sellers(orderBy: $orderBy) {
			id
			name
		}
	}
`;

type getSellers = {
	__typename?: 'Query';

	Sellers: Query['Sellers'];
};

interface QueryParams {
	sorts: SortObj[];
}
export default class VgsSettingsSellers extends Route {
	@tracked variables: Query_SellersArgs = {};

	getSellers = useQuery<getSellers, Query_SellersArgs>(this, () => [GET_SELLERS, { variables: this.variables }]);

	queryParams = {
		sorts: { refreshModel: true },
	};

	generateOrderBy(sorts: SortObj[]) {
		const orderBy: {
			[key: string]: any;
		} = {};

		sorts.forEach((sort: any) => {
			const parts = sort.valuePath.split('.');
			const firstPart = parts.shift();

			let value: any = sort.isAscending ? SortByDirection.Asc : SortByDirection.Desc;

			while (parts.length > 0) {
				const part = parts.pop();
				const obj: {
					[key: string]: any;
				} = {};
				obj[part] = value;
				value = obj;
			}

			orderBy[firstPart] = value;
		});

		return orderBy;
	}

	async model(params: QueryParams) {
		this.variables = {
			orderBy: this.generateOrderBy(params.sorts),
		};

		await this.getSellers.promise;

		return { getSellers: this.getSellers, variables: this.variables };
	}
}
