import Route from '@ember/routing/route';
import query from 'vault-client/graphql/queries/insurance-overview-by-business.graphql';
import { queryManager } from 'ember-apollo-client';
import { ForecastedMilkProductionCoverageFilterDTO, TypeOfInsuranceProgram } from 'vault-client/types/graphql-types';
import { SortObj } from 'vault-client/types/vault-table';

export default class InsuranceOverviewByBusiness extends Route {
	@queryManager apollo: any;

	query = query;

	templateName = 'insurance-overview-by-business/index';
	controllerName = 'insurance-overview-by-business/index';

	queryParams = {
		agent: {
			refreshModel: true,
		},
		aipId: {
			refreshModel: true,
		},
		stateId: {
			refreshModel: true,
		},
		quarterStartDate: {
			refreshModel: true,
		},
		quarterEndDate: {
			refreshModel: true,
		},
		page: {
			refreshModel: true,
		},
		size: {
			refreshModel: true,
		},
		sorts: {
			refreshModel: true,
		},
	};

	generateWhere(
		organizationId: string | null,
		customerId: string | null,
		startDate: string | null,
		endDate: string | null,
		agent: string | null,
		aipId: string | null,
		stateId: string | null
	) {
		const where: ForecastedMilkProductionCoverageFilterDTO = {
			startDate: '',
			endDate: '',
			InsurancePolicies: {},
		};

		if (startDate) {
			where.startDate = startDate;
		}

		if (endDate) {
			where.endDate = endDate;
		}

		if (customerId) {
			where.InsurancePolicies ??= {};
			where.InsurancePolicies.Customer ??= {};
			where.InsurancePolicies.Customer = {
				id: { equals: customerId },
			};
		} else if (organizationId) {
			where.InsurancePolicies ??= {};
			where.InsurancePolicies.Customer ??= {};
			where.InsurancePolicies.Customer = {
				organizationId: { equals: organizationId },
			};
		}

		// This is a hack, suggested by Evan, to return true for an Insurance Policy if *any* policy that the customer has meets the critera.
		if (agent) {
			where.InsurancePolicies ??= {};
			where.InsurancePolicies.Customer ??= {};
			where.InsurancePolicies.Customer.InsurancePolicies ??= {};
			where.InsurancePolicies.Customer.InsurancePolicies.agentFullName = { equals: agent };
			where.InsurancePolicies.Customer.InsurancePolicies.programType = { equals: TypeOfInsuranceProgram.Drp };
		}

		if (aipId) {
			(where.InsurancePolicies ??= {}).aipId = { equals: aipId };
		}

		if (stateId) {
			(where.InsurancePolicies ??= {}).stateId = { equals: stateId };
		}

		return where;
	}

	generateOrderBy(sorts: SortObj[]) {
		const orderBy: {
			[key: string]: any;
		} = {};

		sorts.forEach((sort) => {
			let value;

			if (sort.valuePath === 'quarterStartDate') {
				value = sort.isAscending ? 'Asc' : 'Desc';
			} else {
				value = sort.isAscending ? 'AscNullsFirst' : 'DescNullsLast';
			}

			orderBy[sort.valuePath] = value;
		});

		return orderBy;
	}
}
