import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

interface UiCustomSelectComponentArgs {
	label: string;
	dropdownOriginRight?: boolean;
	currentValue?: string;
	defaultValue?: any;
	firstOption?: string;
}

export default class UiCustomSelectComponent extends Component<UiCustomSelectComponentArgs> {
	@tracked dropdownOpen = false;

	get dropdownOriginRight() {
		return this.args.dropdownOriginRight ?? true;
	}

	get currentValue() {
		return this.args.currentValue ?? this.args.defaultValue ?? `Select ${this.args.label}`;
	}

	@action
	toggleDropdown() {
		this.dropdownOpen = !this.dropdownOpen;
	}

	@action
	closeDropDown() {
		this.dropdownOpen = false;
	}

	@action
	handleDropdownKeyDown(event: KeyboardEvent) {
		const elements = event.currentTarget ? [...(event.currentTarget as HTMLElement).getElementsByClassName('ui-dropdown-item')] : [];
		const length = elements.length - 1;
		let activeItem = elements.findIndex((el) => el.matches(':focus'));

		if (event.key === 'Down' || event.key === 'ArrowDown') {
			event.preventDefault();
			activeItem = activeItem === length ? 0 : ++activeItem;
			(elements[activeItem] as HTMLElement).focus({ preventScroll: false });
		} else if ((event.key === 'Up' || event.key === 'ArrowUp') && activeItem > 0) {
			event.preventDefault();
			(elements[--activeItem] as HTMLElement).focus({ preventScroll: false });
		} else if (event.key === 'Esc' || event.key === 'Escape') {
			event.preventDefault();
			(event?.srcElement as HTMLElement).blur();
			this.closeDropDown();
		} else if (event.shiftKey && event.key === 'Tab') {
			if (event.shiftKey && activeItem === 0) {
				this.closeDropDown();
			}
		} else if (event.key === 'Tab') {
			if (activeItem === length) {
				this.closeDropDown();
			}
		}
	}

	@action
	focusOnFirstOption() {
		if (this.args.firstOption) {
			document.getElementById(this.args.firstOption)?.focus();
		}
	}
}
