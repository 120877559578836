import Controller from '@ember/controller';
import intlNumberFormat from 'vault-client/utils/intl-number-format';
import intlDateTimeFormat from 'vault-client/utils/intl-date-time-format';
import { inject as service } from '@ember/service';
import { TableColumn, CellComponents } from 'vault-client/types/vault-table';
import { LrpEndorsement } from 'vault-client/types/graphql-types';
import ApplicationScope from 'vault-client/services/application-scope';
import { ModelFrom } from 'vault-client/utils/type-utils';
import LRPInsuranceEndorsementsShowRoute from 'vault-client/routes/lrp-insurance-endorsements/show';

export default class LRPInsuranceEndorsementsShowController extends Controller {
	@service permissions: any;
	@service declare applicationScope: ApplicationScope;
	declare model: ModelFrom<LRPInsuranceEndorsementsShowRoute>;

	lrpInsuranceEndorsementsRoute: string = '';

	get contractSpecifications(): TableColumn[] {
		return [
			{
				id: '9a1ce19a-e7ff-46ed-a577-5b6ce1afc1d0',
				name: '',
				valuePath: 'rowHeader',
				minWidth: 260,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isVisible: true,
				isFixed: '',
			},
			{
				id: '091af6ab-5238-4fee-9a65-879e511e5a17',
				name: '',
				valuePath: 'value',
				minWidth: 160,
				textAlign: 'right',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
		];
	}

	get endorsement(): LrpEndorsement {
		return this.model.data?.LrpEndorsement as LrpEndorsement;
	}

	get contractSpecificationsData() {
		const endorsement = this.endorsement;

		return [
			{
				rowHeader: 'Sales Effective Date',
				value: intlDateTimeFormat({
					value: endorsement.salesEffectiveDate,
					day: 'numeric',
					month: 'long',
					year: 'numeric',
					nullSkipFormat: true,
				}),
			},
			{
				rowHeader: 'Coverage End Date',
				value: intlDateTimeFormat({
					value: endorsement.coverageEndDate,
					day: 'numeric',
					month: 'long',
					year: 'numeric',
					nullSkipFormat: true,
				}),
			},
			{
				rowHeader: 'Insured Value',
				value: intlNumberFormat({
					value: endorsement.insuredValue,
					maximumFractionDigits: '2',
					minimumFractionDigits: '2',
					style: 'currency',
					currency: 'USD',
					nullSkipFormat: true,
				}),
			},
			{
				rowHeader: 'Length (Weeks)',
				value: intlNumberFormat({
					value: endorsement.lengthInWeeks,
					maximumFractionDigits: '0',
					minimumFractionDigits: '0',
					style: 'decimal',
					nullSkipFormat: true,
				}),
			},
			{
				rowHeader: 'Head Count',
				value: intlNumberFormat({
					value: endorsement.headCount,
					maximumFractionDigits: '0',
					minimumFractionDigits: '0',
					style: 'decimal',
					nullSkipFormat: true,
				}),
			},
			{
				rowHeader: 'Target Weight (CWT)',
				value: intlNumberFormat({
					value: endorsement.targetWeightCwt,
					maximumFractionDigits: '2',
					minimumFractionDigits: '2',
					style: 'decimal',
					nullSkipFormat: true,
				}),
			},
			{
				rowHeader: 'Coverage Price',
				value: intlNumberFormat({
					value: endorsement.coveragePrice,
					maximumFractionDigits: '2',
					minimumFractionDigits: '2',
					style: 'currency',
					currency: 'USD',
					nullSkipFormat: true,
				}),
			},
			{
				rowHeader: 'Producer Premium',
				value: intlNumberFormat({
					value: endorsement.producerPremiumAmount,
					maximumFractionDigits: '0',
					minimumFractionDigits: '0',
					style: 'currency',
					currency: 'USD',
					nullSkipFormat: true,
				}),
			},
			{
				rowHeader: 'Subsidy',
				value: intlNumberFormat({
					value: endorsement.subsidyAmount,
					maximumFractionDigits: '2',
					minimumFractionDigits: '2',
					style: 'currency',
					currency: 'USD',
					nullSkipFormat: true,
				}),
			},
			{
				rowHeader: 'Total Premium',
				value: intlNumberFormat({
					value: endorsement.totalPremiumAmount,
					maximumFractionDigits: '2',
					minimumFractionDigits: '2',
					style: 'currency',
					currency: 'USD',
					nullSkipFormat: true,
				}),
			},
		];
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'lrp-insurance-endorsement-show-controller': LRPInsuranceEndorsementsShowController;
	}
}
