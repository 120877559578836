import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { ProductionRow } from 'vault-client/controllers/locations/location/production';
import {
	Mutation_setAverageActualMilkProductionArgs,
	ActualMilkProduction,
	ActualMilkProductionSetDTO,
} from 'vault-client/types/graphql-types';
import { gql, useMutation } from 'glimmer-apollo';

interface SetActualProductionButtonArgs {
	locationId: string;
	selectedMonths: ProductionRow[];
	updateSelectedMonths: (values: ProductionRow[]) => void;
}

type setAverageActualMilkProduction = {
	__typename?: 'Mutation';

	actualMilkProduction: ActualMilkProduction;
};

const SET_AVERAGE_ACTUAL_MILK_PRODUCTION_MUTATION = gql`
	mutation setAverageActualMilkProduction($startDate: String!, $endDate: String!, $grossProduction: ActualMilkProductionSetDTO) {
		setAverageActualMilkProduction(startDate: $startDate, endDate: $endDate, grossProduction: $grossProduction) {
			id
			numberOfCows
			grossProduction
		}
	}
`;

export default class SetActualProductionButton extends Component<SetActualProductionButtonArgs> {
	setAverageActualMilkProductionMutation = useMutation<setAverageActualMilkProduction, Mutation_setAverageActualMilkProductionArgs>(
		this,
		() => [
			SET_AVERAGE_ACTUAL_MILK_PRODUCTION_MUTATION,
			{
				onError: (error): void => {
					this.errorMessage = 'Error updating actual dairy production.';
					console.error('Error while attempting to update actual dairy production. ', error.message);
				},
				update: (cache) => {
					cache.evict({ fieldName: 'ActualMilkProduction' });
					cache.evict({ fieldName: 'AggregateActualMilkProduction' });
					cache.gc();
				},
			},
		]
	);

	@tracked showModal = false;
	@tracked production: null | string = null;
	@tracked errorMessage: null | string | undefined = null;
	@tracked uiWaitingUpdate: boolean = true;

	get isLoading() {
		return this.setAverageActualMilkProductionMutation.loading;
	}

	get monthsToUpdate() {
		const orderedSelectedMonths = this.args.selectedMonths.sortBy('dateIso');
		const firstMonth = orderedSelectedMonths[0];
		const daysInFirstMonth = firstMonth.date.daysInMonth;

		const months = this.args.selectedMonths.map((month) => {
			// Always use 1 for number of cows when setting actual production. Value is not utilized right now.
			const numberOfCows = 1;

			const production = (() => {
				// Actual production is set using daily values, based on the first month selected
				if (this.production == null || this.production === '') {
					return month.grossProductionActual / daysInFirstMonth;
				}

				const production = parseFloat(this.production);

				return production / daysInFirstMonth;
			})();

			return {
				startDate: month.date.toISODate(),
				endDate: month.date.endOf('month').toISODate(),
				numberOfCows,
				production,
			};
		});

		return months;
	}

	@action
	openModal() {
		this.showModal = true;
	}

	@action
	closeModal() {
		this.showModal = false;
		this.production = null;
		this.errorMessage = null;
	}

	@action
	async submit() {
		const updatePromises = this.monthsToUpdate.map((month) => {
			const grossProduction: ActualMilkProductionSetDTO = {
				locationId: this.args.locationId,
				numberOfCows: month.numberOfCows,
				production: month.production,
			};

			return this.setAverageActualMilkProductionMutation.mutate({
				grossProduction: grossProduction,
				startDate: month.startDate,
				endDate: month.endDate,
			});
		});

		await Promise.all(updatePromises);

		if (!this.errorMessage) {
			this.args.updateSelectedMonths([]);
			this.closeModal();
		}
	}
}
