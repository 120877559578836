import { tracked } from '@glimmer/tracking';

import { setOwner } from '@ember/application';
import { percentRank } from 'vault-client/utils/percentile';
import { inject as service } from '@ember/service';
import { Future, Product } from 'vault-client/types/graphql-types';
import Big from 'big.js';

export default class HistoricalPercentilesByProductMonth {
	product: Product;
	instrument: Future;
	@tracked _hypotheticalPrice: Big | null = null;
	@service marketData: any;

	constructor(owner: any, product: Product, instrument: Future) {
		setOwner(this, owner);
		this.product = product;
		this.instrument = instrument;
		this.marketData.registerHistoricalRange(this.instrument.barchartSymbol);
	}

	get displayFactor() {
		return this.instrument.SymbolGroup.displayFactor ?? 1;
	}

	get fractionDigits() {
		return this.instrument.SymbolGroup.fractionDigits ?? 2;
	}

	get marketPrice(): Big | null {
		const marketDatum = this.marketData.getMarketDatum(this.instrument.barchartSymbol) as
			| { lastPrice: unknown; close: unknown }
			| undefined;

		if (typeof marketDatum?.lastPrice === 'number' && isFinite(marketDatum.lastPrice)) {
			return Big(marketDatum.lastPrice);
		}
		if (typeof marketDatum?.close === 'number' && isFinite(marketDatum.close)) {
			return Big(marketDatum.close);
		}
		return null;
	}

	get historicalRange() {
		return this.marketData
			.getMarketDataHistoricalRange(this.instrument.barchartSymbol)
			?.get('settlements')
			?.map((settlement: any) => {
				return settlement.close ?? settlement.lastPrice;
			});
	}

	get threeYearPercentile() {
		const range = this.historicalRange?.slice(0, 3);

		if (this.hypotheticalPrice != null && range && range.length >= 3) {
			return percentRank(range, this.hypotheticalPrice.toNumber());
		} else {
			return null;
		}
	}

	get fiveYearPercentile() {
		const range = this.historicalRange?.slice(0, 5);

		if (this.hypotheticalPrice != null && range && range.length >= 5) {
			return percentRank(range, this.hypotheticalPrice.toNumber());
		} else {
			return null;
		}
	}

	get tenYearPercentile() {
		const range = this.historicalRange?.slice(0, 10);

		if (this.hypotheticalPrice != null && range && range.length >= 10) {
			return percentRank(range, this.hypotheticalPrice.toNumber());
		} else {
			return null;
		}
	}

	get displayHypotheticalPrice() {
		if (this._hypotheticalPrice !== null && this.hypotheticalPrice) {
			return this.hypotheticalPrice.times(this.displayFactor).toString();
		} else if (this.hypotheticalPrice) {
			// Format decimals if the user hasn't entered a hypothetical price.
			return new Intl.NumberFormat(undefined, {
				minimumFractionDigits: this.fractionDigits,
				maximumFractionDigits: this.fractionDigits,
				useGrouping: false,
			}).format(this.hypotheticalPrice.times(this.displayFactor).toNumber());
		} else {
			return null;
		}
	}

	set displayHypotheticalPrice(price: string | null) {
		if (price === '' || price === null) {
			// If the user clears the field, set to zero.
			this.hypotheticalPrice = Big(0);
		} else {
			const val = Big(price).div(this.displayFactor);
			this.hypotheticalPrice = val;
		}
	}

	get hypotheticalPrice(): Big | null {
		return this._hypotheticalPrice ?? this.marketPrice;
	}

	set hypotheticalPrice(price: Big | number | null) {
		this._hypotheticalPrice = typeof price === 'number' ? Big(price) : price;
	}

	get isHypotheticalPriceSameAsMarketPrice() {
		return this.hypotheticalPrice && this.marketPrice ? this.hypotheticalPrice.eq(this.marketPrice) : false;
	}
}
