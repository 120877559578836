export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
	ID: string;
	String: string;
	Boolean: boolean;
	Int: number;
	Float: number;
	date: any;
	jsonb: any;
	numeric: any;
	operation_type: any;
	smallint: any;
	timestamptz: any;
	uuid: any;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
	_eq?: InputMaybe<Scalars['Boolean']>;
	_gt?: InputMaybe<Scalars['Boolean']>;
	_gte?: InputMaybe<Scalars['Boolean']>;
	_in?: InputMaybe<Array<Scalars['Boolean']>>;
	_is_null?: InputMaybe<Scalars['Boolean']>;
	_lt?: InputMaybe<Scalars['Boolean']>;
	_lte?: InputMaybe<Scalars['Boolean']>;
	_neq?: InputMaybe<Scalars['Boolean']>;
	_nin?: InputMaybe<Array<Scalars['Boolean']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
	_eq?: InputMaybe<Scalars['Int']>;
	_gt?: InputMaybe<Scalars['Int']>;
	_gte?: InputMaybe<Scalars['Int']>;
	_in?: InputMaybe<Array<Scalars['Int']>>;
	_is_null?: InputMaybe<Scalars['Boolean']>;
	_lt?: InputMaybe<Scalars['Int']>;
	_lte?: InputMaybe<Scalars['Int']>;
	_neq?: InputMaybe<Scalars['Int']>;
	_nin?: InputMaybe<Array<Scalars['Int']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
	_eq?: InputMaybe<Scalars['String']>;
	_gt?: InputMaybe<Scalars['String']>;
	_gte?: InputMaybe<Scalars['String']>;
	/** does the column match the given case-insensitive pattern */
	_ilike?: InputMaybe<Scalars['String']>;
	_in?: InputMaybe<Array<Scalars['String']>>;
	/** does the column match the given POSIX regular expression, case insensitive */
	_iregex?: InputMaybe<Scalars['String']>;
	_is_null?: InputMaybe<Scalars['Boolean']>;
	/** does the column match the given pattern */
	_like?: InputMaybe<Scalars['String']>;
	_lt?: InputMaybe<Scalars['String']>;
	_lte?: InputMaybe<Scalars['String']>;
	_neq?: InputMaybe<Scalars['String']>;
	/** does the column NOT match the given case-insensitive pattern */
	_nilike?: InputMaybe<Scalars['String']>;
	_nin?: InputMaybe<Array<Scalars['String']>>;
	/** does the column NOT match the given POSIX regular expression, case insensitive */
	_niregex?: InputMaybe<Scalars['String']>;
	/** does the column NOT match the given pattern */
	_nlike?: InputMaybe<Scalars['String']>;
	/** does the column NOT match the given POSIX regular expression, case sensitive */
	_nregex?: InputMaybe<Scalars['String']>;
	/** does the column NOT match the given SQL regular expression */
	_nsimilar?: InputMaybe<Scalars['String']>;
	/** does the column match the given POSIX regular expression, case sensitive */
	_regex?: InputMaybe<Scalars['String']>;
	/** does the column match the given SQL regular expression */
	_similar?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "advisors" */
export type Advisors = {
	__typename?: 'advisors';
	email?: Maybe<Scalars['String']>;
	id: Scalars['Int'];
	name?: Maybe<Scalars['String']>;
	/** An array relationship */
	permission_group_memberships: Array<Permission_Group_Membership>;
};

/** columns and relationships of "advisors" */
export type AdvisorsPermission_Group_MembershipsArgs = {
	distinct_on?: InputMaybe<Array<Permission_Group_Membership_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Permission_Group_Membership_Order_By>>;
	where?: InputMaybe<Permission_Group_Membership_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "advisors". All fields are combined with a logical 'AND'. */
export type Advisors_Bool_Exp = {
	_and?: InputMaybe<Array<Advisors_Bool_Exp>>;
	_not?: InputMaybe<Advisors_Bool_Exp>;
	_or?: InputMaybe<Array<Advisors_Bool_Exp>>;
	email?: InputMaybe<String_Comparison_Exp>;
	id?: InputMaybe<Int_Comparison_Exp>;
	name?: InputMaybe<String_Comparison_Exp>;
	permission_group_memberships?: InputMaybe<Permission_Group_Membership_Bool_Exp>;
};

/** unique or primary key constraints on table "advisors" */
export enum Advisors_Constraint {
	/** unique or primary key constraint on columns "id" */
	AdvisorsPkey = 'advisors_pkey',
}

/** input type for inserting data into table "advisors" */
export type Advisors_Insert_Input = {
	email?: InputMaybe<Scalars['String']>;
	name?: InputMaybe<Scalars['String']>;
	permission_group_memberships?: InputMaybe<Permission_Group_Membership_Arr_Rel_Insert_Input>;
};

/** response of any mutation on the table "advisors" */
export type Advisors_Mutation_Response = {
	__typename?: 'advisors_mutation_response';
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Advisors>;
};

/** input type for inserting object relation for remote table "advisors" */
export type Advisors_Obj_Rel_Insert_Input = {
	data: Advisors_Insert_Input;
	/** upsert condition */
	on_conflict?: InputMaybe<Advisors_On_Conflict>;
};

/** on_conflict condition type for table "advisors" */
export type Advisors_On_Conflict = {
	constraint: Advisors_Constraint;
	update_columns?: Array<Advisors_Update_Column>;
	where?: InputMaybe<Advisors_Bool_Exp>;
};

/** Ordering options when selecting data from "advisors". */
export type Advisors_Order_By = {
	email?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	name?: InputMaybe<Order_By>;
	permission_group_memberships_aggregate?: InputMaybe<Permission_Group_Membership_Aggregate_Order_By>;
};

/** primary key columns input for table: advisors */
export type Advisors_Pk_Columns_Input = {
	id: Scalars['Int'];
};

/** select columns of table "advisors" */
export enum Advisors_Select_Column {
	/** column name */
	Email = 'email',
	/** column name */
	Id = 'id',
	/** column name */
	Name = 'name',
}

/** input type for updating data in table "advisors" */
export type Advisors_Set_Input = {
	email?: InputMaybe<Scalars['String']>;
	name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "advisors" */
export type Advisors_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Advisors_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Advisors_Stream_Cursor_Value_Input = {
	email?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['Int']>;
	name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "advisors" */
export enum Advisors_Update_Column {
	/** column name */
	Email = 'email',
	/** column name */
	Name = 'name',
}

export type Advisors_Updates = {
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Advisors_Set_Input>;
	/** filter the rows which have to be updated */
	where: Advisors_Bool_Exp;
};

/** columns and relationships of "buyers" */
export type Buyers = {
	__typename?: 'buyers';
	id: Scalars['Int'];
	name?: Maybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "buyers". All fields are combined with a logical 'AND'. */
export type Buyers_Bool_Exp = {
	_and?: InputMaybe<Array<Buyers_Bool_Exp>>;
	_not?: InputMaybe<Buyers_Bool_Exp>;
	_or?: InputMaybe<Array<Buyers_Bool_Exp>>;
	id?: InputMaybe<Int_Comparison_Exp>;
	name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "buyers" */
export enum Buyers_Constraint {
	/** unique or primary key constraint on columns "id" */
	BuyersPkey = 'buyers_pkey',
}

/** input type for inserting data into table "buyers" */
export type Buyers_Insert_Input = {
	name?: InputMaybe<Scalars['String']>;
};

/** response of any mutation on the table "buyers" */
export type Buyers_Mutation_Response = {
	__typename?: 'buyers_mutation_response';
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Buyers>;
};

/** input type for inserting object relation for remote table "buyers" */
export type Buyers_Obj_Rel_Insert_Input = {
	data: Buyers_Insert_Input;
	/** upsert condition */
	on_conflict?: InputMaybe<Buyers_On_Conflict>;
};

/** on_conflict condition type for table "buyers" */
export type Buyers_On_Conflict = {
	constraint: Buyers_Constraint;
	update_columns?: Array<Buyers_Update_Column>;
	where?: InputMaybe<Buyers_Bool_Exp>;
};

/** Ordering options when selecting data from "buyers". */
export type Buyers_Order_By = {
	id?: InputMaybe<Order_By>;
	name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: buyers */
export type Buyers_Pk_Columns_Input = {
	id: Scalars['Int'];
};

/** select columns of table "buyers" */
export enum Buyers_Select_Column {
	/** column name */
	Id = 'id',
	/** column name */
	Name = 'name',
}

/** input type for updating data in table "buyers" */
export type Buyers_Set_Input = {
	name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "buyers" */
export type Buyers_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Buyers_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Buyers_Stream_Cursor_Value_Input = {
	id?: InputMaybe<Scalars['Int']>;
	name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "buyers" */
export enum Buyers_Update_Column {
	/** column name */
	Name = 'name',
}

export type Buyers_Updates = {
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Buyers_Set_Input>;
	/** filter the rows which have to be updated */
	where: Buyers_Bool_Exp;
};

/** columns and relationships of "crop_years" */
export type Crop_Years = {
	__typename?: 'crop_years';
	id: Scalars['Int'];
	year: Scalars['smallint'];
};

/** Boolean expression to filter rows from the table "crop_years". All fields are combined with a logical 'AND'. */
export type Crop_Years_Bool_Exp = {
	_and?: InputMaybe<Array<Crop_Years_Bool_Exp>>;
	_not?: InputMaybe<Crop_Years_Bool_Exp>;
	_or?: InputMaybe<Array<Crop_Years_Bool_Exp>>;
	id?: InputMaybe<Int_Comparison_Exp>;
	year?: InputMaybe<Smallint_Comparison_Exp>;
};

/** Ordering options when selecting data from "crop_years". */
export type Crop_Years_Order_By = {
	id?: InputMaybe<Order_By>;
	year?: InputMaybe<Order_By>;
};

/** select columns of table "crop_years" */
export enum Crop_Years_Select_Column {
	/** column name */
	Id = 'id',
	/** column name */
	Year = 'year',
}

/** Streaming cursor of the table "crop_years" */
export type Crop_Years_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Crop_Years_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Crop_Years_Stream_Cursor_Value_Input = {
	id?: InputMaybe<Scalars['Int']>;
	year?: InputMaybe<Scalars['smallint']>;
};

/** columns and relationships of "crops" */
export type Crops = {
	__typename?: 'crops';
	id: Scalars['Int'];
	name?: Maybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "crops". All fields are combined with a logical 'AND'. */
export type Crops_Bool_Exp = {
	_and?: InputMaybe<Array<Crops_Bool_Exp>>;
	_not?: InputMaybe<Crops_Bool_Exp>;
	_or?: InputMaybe<Array<Crops_Bool_Exp>>;
	id?: InputMaybe<Int_Comparison_Exp>;
	name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "crops" */
export enum Crops_Constraint {
	/** unique or primary key constraint on columns "id" */
	CropsPkey = 'crops_pkey',
}

/** input type for inserting data into table "crops" */
export type Crops_Insert_Input = {
	name?: InputMaybe<Scalars['String']>;
};

/** response of any mutation on the table "crops" */
export type Crops_Mutation_Response = {
	__typename?: 'crops_mutation_response';
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Crops>;
};

/** input type for inserting object relation for remote table "crops" */
export type Crops_Obj_Rel_Insert_Input = {
	data: Crops_Insert_Input;
	/** upsert condition */
	on_conflict?: InputMaybe<Crops_On_Conflict>;
};

/** on_conflict condition type for table "crops" */
export type Crops_On_Conflict = {
	constraint: Crops_Constraint;
	update_columns?: Array<Crops_Update_Column>;
	where?: InputMaybe<Crops_Bool_Exp>;
};

/** Ordering options when selecting data from "crops". */
export type Crops_Order_By = {
	id?: InputMaybe<Order_By>;
	name?: InputMaybe<Order_By>;
};

/** select columns of table "crops" */
export enum Crops_Select_Column {
	/** column name */
	Id = 'id',
	/** column name */
	Name = 'name',
}

/** Streaming cursor of the table "crops" */
export type Crops_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Crops_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Crops_Stream_Cursor_Value_Input = {
	id?: InputMaybe<Scalars['Int']>;
	name?: InputMaybe<Scalars['String']>;
};

/** placeholder for update columns of table "crops" (current role has no relevant permissions) */
export enum Crops_Update_Column {
	/** placeholder (do not use) */
	Placeholder = '_PLACEHOLDER',
}

/** ordering argument of a cursor */
export enum Cursor_Ordering {
	/** ascending ordering of the cursor */
	Asc = 'ASC',
	/** descending ordering of the cursor */
	Desc = 'DESC',
}

/** columns and relationships of "customers" */
export type Customers = {
	__typename?: 'customers';
	/** An object relationship */
	Advisor?: Maybe<Advisors>;
	/** An array relationship */
	FeedPlans: Array<Feed_Plans>;
	/** An aggregate relationship */
	FeedPlans_aggregate: Feed_Plans_Aggregate;
	/** An array relationship */
	Production: Array<Production>;
	/** An aggregate relationship */
	Production_aggregate: Production_Aggregate;
	advisor_id?: Maybe<Scalars['Int']>;
	contact_email?: Maybe<Scalars['String']>;
	contact_name?: Maybe<Scalars['String']>;
	contact_phone_number?: Maybe<Scalars['String']>;
	id: Scalars['Int'];
	name?: Maybe<Scalars['String']>;
	/** An object relationship */
	permission_group?: Maybe<Permission_Groups>;
};

/** columns and relationships of "customers" */
export type CustomersFeedPlansArgs = {
	distinct_on?: InputMaybe<Array<Feed_Plans_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Feed_Plans_Order_By>>;
	where?: InputMaybe<Feed_Plans_Bool_Exp>;
};

/** columns and relationships of "customers" */
export type CustomersFeedPlans_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Feed_Plans_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Feed_Plans_Order_By>>;
	where?: InputMaybe<Feed_Plans_Bool_Exp>;
};

/** columns and relationships of "customers" */
export type CustomersProductionArgs = {
	distinct_on?: InputMaybe<Array<Production_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Production_Order_By>>;
	where?: InputMaybe<Production_Bool_Exp>;
};

/** columns and relationships of "customers" */
export type CustomersProduction_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Production_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Production_Order_By>>;
	where?: InputMaybe<Production_Bool_Exp>;
};

/** aggregated selection of "customers" */
export type Customers_Aggregate = {
	__typename?: 'customers_aggregate';
	aggregate?: Maybe<Customers_Aggregate_Fields>;
	nodes: Array<Customers>;
};

export type Customers_Aggregate_Bool_Exp = {
	count?: InputMaybe<Customers_Aggregate_Bool_Exp_Count>;
};

export type Customers_Aggregate_Bool_Exp_Count = {
	arguments?: InputMaybe<Array<Customers_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Customers_Bool_Exp>;
	predicate: Int_Comparison_Exp;
};

/** aggregate fields of "customers" */
export type Customers_Aggregate_Fields = {
	__typename?: 'customers_aggregate_fields';
	avg?: Maybe<Customers_Avg_Fields>;
	count: Scalars['Int'];
	max?: Maybe<Customers_Max_Fields>;
	min?: Maybe<Customers_Min_Fields>;
	stddev?: Maybe<Customers_Stddev_Fields>;
	stddev_pop?: Maybe<Customers_Stddev_Pop_Fields>;
	stddev_samp?: Maybe<Customers_Stddev_Samp_Fields>;
	sum?: Maybe<Customers_Sum_Fields>;
	var_pop?: Maybe<Customers_Var_Pop_Fields>;
	var_samp?: Maybe<Customers_Var_Samp_Fields>;
	variance?: Maybe<Customers_Variance_Fields>;
};

/** aggregate fields of "customers" */
export type Customers_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Customers_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "customers" */
export type Customers_Aggregate_Order_By = {
	avg?: InputMaybe<Customers_Avg_Order_By>;
	count?: InputMaybe<Order_By>;
	max?: InputMaybe<Customers_Max_Order_By>;
	min?: InputMaybe<Customers_Min_Order_By>;
	stddev?: InputMaybe<Customers_Stddev_Order_By>;
	stddev_pop?: InputMaybe<Customers_Stddev_Pop_Order_By>;
	stddev_samp?: InputMaybe<Customers_Stddev_Samp_Order_By>;
	sum?: InputMaybe<Customers_Sum_Order_By>;
	var_pop?: InputMaybe<Customers_Var_Pop_Order_By>;
	var_samp?: InputMaybe<Customers_Var_Samp_Order_By>;
	variance?: InputMaybe<Customers_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "customers" */
export type Customers_Arr_Rel_Insert_Input = {
	data: Array<Customers_Insert_Input>;
	/** upsert condition */
	on_conflict?: InputMaybe<Customers_On_Conflict>;
};

/** aggregate avg on columns */
export type Customers_Avg_Fields = {
	__typename?: 'customers_avg_fields';
	advisor_id?: Maybe<Scalars['Float']>;
	id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "customers" */
export type Customers_Avg_Order_By = {
	advisor_id?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "customers". All fields are combined with a logical 'AND'. */
export type Customers_Bool_Exp = {
	Advisor?: InputMaybe<Advisors_Bool_Exp>;
	FeedPlans?: InputMaybe<Feed_Plans_Bool_Exp>;
	FeedPlans_aggregate?: InputMaybe<Feed_Plans_Aggregate_Bool_Exp>;
	Production?: InputMaybe<Production_Bool_Exp>;
	Production_aggregate?: InputMaybe<Production_Aggregate_Bool_Exp>;
	_and?: InputMaybe<Array<Customers_Bool_Exp>>;
	_not?: InputMaybe<Customers_Bool_Exp>;
	_or?: InputMaybe<Array<Customers_Bool_Exp>>;
	advisor_id?: InputMaybe<Int_Comparison_Exp>;
	contact_email?: InputMaybe<String_Comparison_Exp>;
	contact_name?: InputMaybe<String_Comparison_Exp>;
	contact_phone_number?: InputMaybe<String_Comparison_Exp>;
	id?: InputMaybe<Int_Comparison_Exp>;
	name?: InputMaybe<String_Comparison_Exp>;
	permission_group?: InputMaybe<Permission_Groups_Bool_Exp>;
};

/** unique or primary key constraints on table "customers" */
export enum Customers_Constraint {
	/** unique or primary key constraint on columns "id" */
	CustomersPkey = 'customers_pkey',
}

/** input type for incrementing numeric columns in table "customers" */
export type Customers_Inc_Input = {
	advisor_id?: InputMaybe<Scalars['Int']>;
};

/** input type for inserting data into table "customers" */
export type Customers_Insert_Input = {
	Advisor?: InputMaybe<Advisors_Obj_Rel_Insert_Input>;
	FeedPlans?: InputMaybe<Feed_Plans_Arr_Rel_Insert_Input>;
	Production?: InputMaybe<Production_Arr_Rel_Insert_Input>;
	advisor_id?: InputMaybe<Scalars['Int']>;
	contact_email?: InputMaybe<Scalars['String']>;
	contact_name?: InputMaybe<Scalars['String']>;
	contact_phone_number?: InputMaybe<Scalars['String']>;
	name?: InputMaybe<Scalars['String']>;
	permission_group?: InputMaybe<Permission_Groups_Obj_Rel_Insert_Input>;
	permission_group_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate max on columns */
export type Customers_Max_Fields = {
	__typename?: 'customers_max_fields';
	advisor_id?: Maybe<Scalars['Int']>;
	contact_email?: Maybe<Scalars['String']>;
	contact_name?: Maybe<Scalars['String']>;
	contact_phone_number?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['Int']>;
	name?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "customers" */
export type Customers_Max_Order_By = {
	advisor_id?: InputMaybe<Order_By>;
	contact_email?: InputMaybe<Order_By>;
	contact_name?: InputMaybe<Order_By>;
	contact_phone_number?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	name?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Customers_Min_Fields = {
	__typename?: 'customers_min_fields';
	advisor_id?: Maybe<Scalars['Int']>;
	contact_email?: Maybe<Scalars['String']>;
	contact_name?: Maybe<Scalars['String']>;
	contact_phone_number?: Maybe<Scalars['String']>;
	id?: Maybe<Scalars['Int']>;
	name?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "customers" */
export type Customers_Min_Order_By = {
	advisor_id?: InputMaybe<Order_By>;
	contact_email?: InputMaybe<Order_By>;
	contact_name?: InputMaybe<Order_By>;
	contact_phone_number?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	name?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "customers" */
export type Customers_Mutation_Response = {
	__typename?: 'customers_mutation_response';
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Customers>;
};

/** input type for inserting object relation for remote table "customers" */
export type Customers_Obj_Rel_Insert_Input = {
	data: Customers_Insert_Input;
	/** upsert condition */
	on_conflict?: InputMaybe<Customers_On_Conflict>;
};

/** on_conflict condition type for table "customers" */
export type Customers_On_Conflict = {
	constraint: Customers_Constraint;
	update_columns?: Array<Customers_Update_Column>;
	where?: InputMaybe<Customers_Bool_Exp>;
};

/** Ordering options when selecting data from "customers". */
export type Customers_Order_By = {
	Advisor?: InputMaybe<Advisors_Order_By>;
	FeedPlans_aggregate?: InputMaybe<Feed_Plans_Aggregate_Order_By>;
	Production_aggregate?: InputMaybe<Production_Aggregate_Order_By>;
	advisor_id?: InputMaybe<Order_By>;
	contact_email?: InputMaybe<Order_By>;
	contact_name?: InputMaybe<Order_By>;
	contact_phone_number?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	name?: InputMaybe<Order_By>;
	permission_group?: InputMaybe<Permission_Groups_Order_By>;
};

/** primary key columns input for table: customers */
export type Customers_Pk_Columns_Input = {
	id: Scalars['Int'];
};

/** select columns of table "customers" */
export enum Customers_Select_Column {
	/** column name */
	AdvisorId = 'advisor_id',
	/** column name */
	ContactEmail = 'contact_email',
	/** column name */
	ContactName = 'contact_name',
	/** column name */
	ContactPhoneNumber = 'contact_phone_number',
	/** column name */
	Id = 'id',
	/** column name */
	Name = 'name',
}

/** input type for updating data in table "customers" */
export type Customers_Set_Input = {
	advisor_id?: InputMaybe<Scalars['Int']>;
	contact_email?: InputMaybe<Scalars['String']>;
	contact_name?: InputMaybe<Scalars['String']>;
	contact_phone_number?: InputMaybe<Scalars['String']>;
	name?: InputMaybe<Scalars['String']>;
	permission_group_id?: InputMaybe<Scalars['uuid']>;
};

/** aggregate stddev on columns */
export type Customers_Stddev_Fields = {
	__typename?: 'customers_stddev_fields';
	advisor_id?: Maybe<Scalars['Float']>;
	id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "customers" */
export type Customers_Stddev_Order_By = {
	advisor_id?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Customers_Stddev_Pop_Fields = {
	__typename?: 'customers_stddev_pop_fields';
	advisor_id?: Maybe<Scalars['Float']>;
	id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "customers" */
export type Customers_Stddev_Pop_Order_By = {
	advisor_id?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Customers_Stddev_Samp_Fields = {
	__typename?: 'customers_stddev_samp_fields';
	advisor_id?: Maybe<Scalars['Float']>;
	id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "customers" */
export type Customers_Stddev_Samp_Order_By = {
	advisor_id?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "customers" */
export type Customers_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Customers_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Customers_Stream_Cursor_Value_Input = {
	advisor_id?: InputMaybe<Scalars['Int']>;
	contact_email?: InputMaybe<Scalars['String']>;
	contact_name?: InputMaybe<Scalars['String']>;
	contact_phone_number?: InputMaybe<Scalars['String']>;
	id?: InputMaybe<Scalars['Int']>;
	name?: InputMaybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type Customers_Sum_Fields = {
	__typename?: 'customers_sum_fields';
	advisor_id?: Maybe<Scalars['Int']>;
	id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "customers" */
export type Customers_Sum_Order_By = {
	advisor_id?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
};

/** update columns of table "customers" */
export enum Customers_Update_Column {
	/** column name */
	AdvisorId = 'advisor_id',
	/** column name */
	ContactEmail = 'contact_email',
	/** column name */
	ContactName = 'contact_name',
	/** column name */
	ContactPhoneNumber = 'contact_phone_number',
	/** column name */
	Name = 'name',
	/** column name */
	PermissionGroupId = 'permission_group_id',
}

export type Customers_Updates = {
	/** increments the numeric columns with given value of the filtered values */
	_inc?: InputMaybe<Customers_Inc_Input>;
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Customers_Set_Input>;
	/** filter the rows which have to be updated */
	where: Customers_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Customers_Var_Pop_Fields = {
	__typename?: 'customers_var_pop_fields';
	advisor_id?: Maybe<Scalars['Float']>;
	id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "customers" */
export type Customers_Var_Pop_Order_By = {
	advisor_id?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Customers_Var_Samp_Fields = {
	__typename?: 'customers_var_samp_fields';
	advisor_id?: Maybe<Scalars['Float']>;
	id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "customers" */
export type Customers_Var_Samp_Order_By = {
	advisor_id?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Customers_Variance_Fields = {
	__typename?: 'customers_variance_fields';
	advisor_id?: Maybe<Scalars['Float']>;
	id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "customers" */
export type Customers_Variance_Order_By = {
	advisor_id?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
	_eq?: InputMaybe<Scalars['date']>;
	_gt?: InputMaybe<Scalars['date']>;
	_gte?: InputMaybe<Scalars['date']>;
	_in?: InputMaybe<Array<Scalars['date']>>;
	_is_null?: InputMaybe<Scalars['Boolean']>;
	_lt?: InputMaybe<Scalars['date']>;
	_lte?: InputMaybe<Scalars['date']>;
	_neq?: InputMaybe<Scalars['date']>;
	_nin?: InputMaybe<Array<Scalars['date']>>;
};

/** columns and relationships of "feed_plans" */
export type Feed_Plans = {
	__typename?: 'feed_plans';
	/** An object relationship */
	Customer?: Maybe<Customers>;
	/** An array relationship */
	Fills: Array<Fills>;
	/** An aggregate relationship */
	Fills_aggregate: Fills_Aggregate;
	/** An object relationship */
	Ingredient?: Maybe<Ingredients>;
	/** An array relationship */
	Targets: Array<Targets>;
	/** An aggregate relationship */
	Targets_aggregate: Targets_Aggregate;
	breakeven?: Maybe<Scalars['numeric']>;
	customer_id?: Maybe<Scalars['Int']>;
	goal?: Maybe<Scalars['numeric']>;
	id: Scalars['uuid'];
	ingredient_id?: Maybe<Scalars['uuid']>;
	start_date: Scalars['date'];
	start_date_month: Scalars['Int'];
	tons: Scalars['numeric'];
};

/** columns and relationships of "feed_plans" */
export type Feed_PlansFillsArgs = {
	distinct_on?: InputMaybe<Array<Fills_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Fills_Order_By>>;
	where?: InputMaybe<Fills_Bool_Exp>;
};

/** columns and relationships of "feed_plans" */
export type Feed_PlansFills_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Fills_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Fills_Order_By>>;
	where?: InputMaybe<Fills_Bool_Exp>;
};

/** columns and relationships of "feed_plans" */
export type Feed_PlansTargetsArgs = {
	distinct_on?: InputMaybe<Array<Targets_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Targets_Order_By>>;
	where?: InputMaybe<Targets_Bool_Exp>;
};

/** columns and relationships of "feed_plans" */
export type Feed_PlansTargets_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Targets_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Targets_Order_By>>;
	where?: InputMaybe<Targets_Bool_Exp>;
};

/** aggregated selection of "feed_plans" */
export type Feed_Plans_Aggregate = {
	__typename?: 'feed_plans_aggregate';
	aggregate?: Maybe<Feed_Plans_Aggregate_Fields>;
	nodes: Array<Feed_Plans>;
};

export type Feed_Plans_Aggregate_Bool_Exp = {
	count?: InputMaybe<Feed_Plans_Aggregate_Bool_Exp_Count>;
};

export type Feed_Plans_Aggregate_Bool_Exp_Count = {
	arguments?: InputMaybe<Array<Feed_Plans_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Feed_Plans_Bool_Exp>;
	predicate: Int_Comparison_Exp;
};

/** aggregate fields of "feed_plans" */
export type Feed_Plans_Aggregate_Fields = {
	__typename?: 'feed_plans_aggregate_fields';
	avg?: Maybe<Feed_Plans_Avg_Fields>;
	count: Scalars['Int'];
	max?: Maybe<Feed_Plans_Max_Fields>;
	min?: Maybe<Feed_Plans_Min_Fields>;
	stddev?: Maybe<Feed_Plans_Stddev_Fields>;
	stddev_pop?: Maybe<Feed_Plans_Stddev_Pop_Fields>;
	stddev_samp?: Maybe<Feed_Plans_Stddev_Samp_Fields>;
	sum?: Maybe<Feed_Plans_Sum_Fields>;
	var_pop?: Maybe<Feed_Plans_Var_Pop_Fields>;
	var_samp?: Maybe<Feed_Plans_Var_Samp_Fields>;
	variance?: Maybe<Feed_Plans_Variance_Fields>;
};

/** aggregate fields of "feed_plans" */
export type Feed_Plans_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Feed_Plans_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "feed_plans" */
export type Feed_Plans_Aggregate_Order_By = {
	avg?: InputMaybe<Feed_Plans_Avg_Order_By>;
	count?: InputMaybe<Order_By>;
	max?: InputMaybe<Feed_Plans_Max_Order_By>;
	min?: InputMaybe<Feed_Plans_Min_Order_By>;
	stddev?: InputMaybe<Feed_Plans_Stddev_Order_By>;
	stddev_pop?: InputMaybe<Feed_Plans_Stddev_Pop_Order_By>;
	stddev_samp?: InputMaybe<Feed_Plans_Stddev_Samp_Order_By>;
	sum?: InputMaybe<Feed_Plans_Sum_Order_By>;
	var_pop?: InputMaybe<Feed_Plans_Var_Pop_Order_By>;
	var_samp?: InputMaybe<Feed_Plans_Var_Samp_Order_By>;
	variance?: InputMaybe<Feed_Plans_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "feed_plans" */
export type Feed_Plans_Arr_Rel_Insert_Input = {
	data: Array<Feed_Plans_Insert_Input>;
	/** upsert condition */
	on_conflict?: InputMaybe<Feed_Plans_On_Conflict>;
};

/** aggregate avg on columns */
export type Feed_Plans_Avg_Fields = {
	__typename?: 'feed_plans_avg_fields';
	breakeven?: Maybe<Scalars['Float']>;
	customer_id?: Maybe<Scalars['Float']>;
	goal?: Maybe<Scalars['Float']>;
	start_date_month?: Maybe<Scalars['Float']>;
	tons?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "feed_plans" */
export type Feed_Plans_Avg_Order_By = {
	breakeven?: InputMaybe<Order_By>;
	customer_id?: InputMaybe<Order_By>;
	goal?: InputMaybe<Order_By>;
	start_date_month?: InputMaybe<Order_By>;
	tons?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "feed_plans". All fields are combined with a logical 'AND'. */
export type Feed_Plans_Bool_Exp = {
	Customer?: InputMaybe<Customers_Bool_Exp>;
	Fills?: InputMaybe<Fills_Bool_Exp>;
	Fills_aggregate?: InputMaybe<Fills_Aggregate_Bool_Exp>;
	Ingredient?: InputMaybe<Ingredients_Bool_Exp>;
	Targets?: InputMaybe<Targets_Bool_Exp>;
	Targets_aggregate?: InputMaybe<Targets_Aggregate_Bool_Exp>;
	_and?: InputMaybe<Array<Feed_Plans_Bool_Exp>>;
	_not?: InputMaybe<Feed_Plans_Bool_Exp>;
	_or?: InputMaybe<Array<Feed_Plans_Bool_Exp>>;
	breakeven?: InputMaybe<Numeric_Comparison_Exp>;
	customer_id?: InputMaybe<Int_Comparison_Exp>;
	goal?: InputMaybe<Numeric_Comparison_Exp>;
	id?: InputMaybe<Uuid_Comparison_Exp>;
	ingredient_id?: InputMaybe<Uuid_Comparison_Exp>;
	start_date?: InputMaybe<Date_Comparison_Exp>;
	start_date_month?: InputMaybe<Int_Comparison_Exp>;
	tons?: InputMaybe<Numeric_Comparison_Exp>;
};

/** unique or primary key constraints on table "feed_plans" */
export enum Feed_Plans_Constraint {
	/** unique or primary key constraint on columns "id" */
	FeedPlansPkey = 'feed_plans_pkey',
}

/** input type for incrementing numeric columns in table "feed_plans" */
export type Feed_Plans_Inc_Input = {
	breakeven?: InputMaybe<Scalars['numeric']>;
	goal?: InputMaybe<Scalars['numeric']>;
	tons?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "feed_plans" */
export type Feed_Plans_Insert_Input = {
	Customer?: InputMaybe<Customers_Obj_Rel_Insert_Input>;
	Fills?: InputMaybe<Fills_Arr_Rel_Insert_Input>;
	Ingredient?: InputMaybe<Ingredients_Obj_Rel_Insert_Input>;
	Targets?: InputMaybe<Targets_Arr_Rel_Insert_Input>;
	breakeven?: InputMaybe<Scalars['numeric']>;
	customer_id?: InputMaybe<Scalars['Int']>;
	goal?: InputMaybe<Scalars['numeric']>;
	ingredient_id?: InputMaybe<Scalars['uuid']>;
	start_date?: InputMaybe<Scalars['date']>;
	tons?: InputMaybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Feed_Plans_Max_Fields = {
	__typename?: 'feed_plans_max_fields';
	breakeven?: Maybe<Scalars['numeric']>;
	customer_id?: Maybe<Scalars['Int']>;
	goal?: Maybe<Scalars['numeric']>;
	id?: Maybe<Scalars['uuid']>;
	ingredient_id?: Maybe<Scalars['uuid']>;
	start_date?: Maybe<Scalars['date']>;
	start_date_month?: Maybe<Scalars['Int']>;
	tons?: Maybe<Scalars['numeric']>;
};

/** order by max() on columns of table "feed_plans" */
export type Feed_Plans_Max_Order_By = {
	breakeven?: InputMaybe<Order_By>;
	customer_id?: InputMaybe<Order_By>;
	goal?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	ingredient_id?: InputMaybe<Order_By>;
	start_date?: InputMaybe<Order_By>;
	start_date_month?: InputMaybe<Order_By>;
	tons?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Feed_Plans_Min_Fields = {
	__typename?: 'feed_plans_min_fields';
	breakeven?: Maybe<Scalars['numeric']>;
	customer_id?: Maybe<Scalars['Int']>;
	goal?: Maybe<Scalars['numeric']>;
	id?: Maybe<Scalars['uuid']>;
	ingredient_id?: Maybe<Scalars['uuid']>;
	start_date?: Maybe<Scalars['date']>;
	start_date_month?: Maybe<Scalars['Int']>;
	tons?: Maybe<Scalars['numeric']>;
};

/** order by min() on columns of table "feed_plans" */
export type Feed_Plans_Min_Order_By = {
	breakeven?: InputMaybe<Order_By>;
	customer_id?: InputMaybe<Order_By>;
	goal?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	ingredient_id?: InputMaybe<Order_By>;
	start_date?: InputMaybe<Order_By>;
	start_date_month?: InputMaybe<Order_By>;
	tons?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "feed_plans" */
export type Feed_Plans_Mutation_Response = {
	__typename?: 'feed_plans_mutation_response';
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Feed_Plans>;
};

/** input type for inserting object relation for remote table "feed_plans" */
export type Feed_Plans_Obj_Rel_Insert_Input = {
	data: Feed_Plans_Insert_Input;
	/** upsert condition */
	on_conflict?: InputMaybe<Feed_Plans_On_Conflict>;
};

/** on_conflict condition type for table "feed_plans" */
export type Feed_Plans_On_Conflict = {
	constraint: Feed_Plans_Constraint;
	update_columns?: Array<Feed_Plans_Update_Column>;
	where?: InputMaybe<Feed_Plans_Bool_Exp>;
};

/** Ordering options when selecting data from "feed_plans". */
export type Feed_Plans_Order_By = {
	Customer?: InputMaybe<Customers_Order_By>;
	Fills_aggregate?: InputMaybe<Fills_Aggregate_Order_By>;
	Ingredient?: InputMaybe<Ingredients_Order_By>;
	Targets_aggregate?: InputMaybe<Targets_Aggregate_Order_By>;
	breakeven?: InputMaybe<Order_By>;
	customer_id?: InputMaybe<Order_By>;
	goal?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	ingredient_id?: InputMaybe<Order_By>;
	start_date?: InputMaybe<Order_By>;
	start_date_month?: InputMaybe<Order_By>;
	tons?: InputMaybe<Order_By>;
};

/** primary key columns input for table: feed_plans */
export type Feed_Plans_Pk_Columns_Input = {
	id: Scalars['uuid'];
};

/** select columns of table "feed_plans" */
export enum Feed_Plans_Select_Column {
	/** column name */
	Breakeven = 'breakeven',
	/** column name */
	CustomerId = 'customer_id',
	/** column name */
	Goal = 'goal',
	/** column name */
	Id = 'id',
	/** column name */
	IngredientId = 'ingredient_id',
	/** column name */
	StartDate = 'start_date',
	/** column name */
	StartDateMonth = 'start_date_month',
	/** column name */
	Tons = 'tons',
}

/** input type for updating data in table "feed_plans" */
export type Feed_Plans_Set_Input = {
	breakeven?: InputMaybe<Scalars['numeric']>;
	goal?: InputMaybe<Scalars['numeric']>;
	tons?: InputMaybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Feed_Plans_Stddev_Fields = {
	__typename?: 'feed_plans_stddev_fields';
	breakeven?: Maybe<Scalars['Float']>;
	customer_id?: Maybe<Scalars['Float']>;
	goal?: Maybe<Scalars['Float']>;
	start_date_month?: Maybe<Scalars['Float']>;
	tons?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "feed_plans" */
export type Feed_Plans_Stddev_Order_By = {
	breakeven?: InputMaybe<Order_By>;
	customer_id?: InputMaybe<Order_By>;
	goal?: InputMaybe<Order_By>;
	start_date_month?: InputMaybe<Order_By>;
	tons?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Feed_Plans_Stddev_Pop_Fields = {
	__typename?: 'feed_plans_stddev_pop_fields';
	breakeven?: Maybe<Scalars['Float']>;
	customer_id?: Maybe<Scalars['Float']>;
	goal?: Maybe<Scalars['Float']>;
	start_date_month?: Maybe<Scalars['Float']>;
	tons?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "feed_plans" */
export type Feed_Plans_Stddev_Pop_Order_By = {
	breakeven?: InputMaybe<Order_By>;
	customer_id?: InputMaybe<Order_By>;
	goal?: InputMaybe<Order_By>;
	start_date_month?: InputMaybe<Order_By>;
	tons?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Feed_Plans_Stddev_Samp_Fields = {
	__typename?: 'feed_plans_stddev_samp_fields';
	breakeven?: Maybe<Scalars['Float']>;
	customer_id?: Maybe<Scalars['Float']>;
	goal?: Maybe<Scalars['Float']>;
	start_date_month?: Maybe<Scalars['Float']>;
	tons?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "feed_plans" */
export type Feed_Plans_Stddev_Samp_Order_By = {
	breakeven?: InputMaybe<Order_By>;
	customer_id?: InputMaybe<Order_By>;
	goal?: InputMaybe<Order_By>;
	start_date_month?: InputMaybe<Order_By>;
	tons?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "feed_plans" */
export type Feed_Plans_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Feed_Plans_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Feed_Plans_Stream_Cursor_Value_Input = {
	breakeven?: InputMaybe<Scalars['numeric']>;
	customer_id?: InputMaybe<Scalars['Int']>;
	goal?: InputMaybe<Scalars['numeric']>;
	id?: InputMaybe<Scalars['uuid']>;
	ingredient_id?: InputMaybe<Scalars['uuid']>;
	start_date?: InputMaybe<Scalars['date']>;
	start_date_month?: InputMaybe<Scalars['Int']>;
	tons?: InputMaybe<Scalars['numeric']>;
};

/** aggregate sum on columns */
export type Feed_Plans_Sum_Fields = {
	__typename?: 'feed_plans_sum_fields';
	breakeven?: Maybe<Scalars['numeric']>;
	customer_id?: Maybe<Scalars['Int']>;
	goal?: Maybe<Scalars['numeric']>;
	start_date_month?: Maybe<Scalars['Int']>;
	tons?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "feed_plans" */
export type Feed_Plans_Sum_Order_By = {
	breakeven?: InputMaybe<Order_By>;
	customer_id?: InputMaybe<Order_By>;
	goal?: InputMaybe<Order_By>;
	start_date_month?: InputMaybe<Order_By>;
	tons?: InputMaybe<Order_By>;
};

/** update columns of table "feed_plans" */
export enum Feed_Plans_Update_Column {
	/** column name */
	Breakeven = 'breakeven',
	/** column name */
	Goal = 'goal',
	/** column name */
	Tons = 'tons',
}

export type Feed_Plans_Updates = {
	/** increments the numeric columns with given value of the filtered values */
	_inc?: InputMaybe<Feed_Plans_Inc_Input>;
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Feed_Plans_Set_Input>;
	/** filter the rows which have to be updated */
	where: Feed_Plans_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Feed_Plans_Var_Pop_Fields = {
	__typename?: 'feed_plans_var_pop_fields';
	breakeven?: Maybe<Scalars['Float']>;
	customer_id?: Maybe<Scalars['Float']>;
	goal?: Maybe<Scalars['Float']>;
	start_date_month?: Maybe<Scalars['Float']>;
	tons?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "feed_plans" */
export type Feed_Plans_Var_Pop_Order_By = {
	breakeven?: InputMaybe<Order_By>;
	customer_id?: InputMaybe<Order_By>;
	goal?: InputMaybe<Order_By>;
	start_date_month?: InputMaybe<Order_By>;
	tons?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Feed_Plans_Var_Samp_Fields = {
	__typename?: 'feed_plans_var_samp_fields';
	breakeven?: Maybe<Scalars['Float']>;
	customer_id?: Maybe<Scalars['Float']>;
	goal?: Maybe<Scalars['Float']>;
	start_date_month?: Maybe<Scalars['Float']>;
	tons?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "feed_plans" */
export type Feed_Plans_Var_Samp_Order_By = {
	breakeven?: InputMaybe<Order_By>;
	customer_id?: InputMaybe<Order_By>;
	goal?: InputMaybe<Order_By>;
	start_date_month?: InputMaybe<Order_By>;
	tons?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Feed_Plans_Variance_Fields = {
	__typename?: 'feed_plans_variance_fields';
	breakeven?: Maybe<Scalars['Float']>;
	customer_id?: Maybe<Scalars['Float']>;
	goal?: Maybe<Scalars['Float']>;
	start_date_month?: Maybe<Scalars['Float']>;
	tons?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "feed_plans" */
export type Feed_Plans_Variance_Order_By = {
	breakeven?: InputMaybe<Order_By>;
	customer_id?: InputMaybe<Order_By>;
	goal?: InputMaybe<Order_By>;
	start_date_month?: InputMaybe<Order_By>;
	tons?: InputMaybe<Order_By>;
};

/** columns and relationships of "fill_activity" */
export type Fill_Activity = {
	__typename?: 'fill_activity';
	action?: Maybe<Scalars['String']>;
	action_time?: Maybe<Scalars['timestamptz']>;
	actor?: Maybe<Scalars['String']>;
	/** An object relationship */
	fill?: Maybe<Fills>;
	fill_id?: Maybe<Scalars['Int']>;
};

/** order by aggregate values of table "fill_activity" */
export type Fill_Activity_Aggregate_Order_By = {
	avg?: InputMaybe<Fill_Activity_Avg_Order_By>;
	count?: InputMaybe<Order_By>;
	max?: InputMaybe<Fill_Activity_Max_Order_By>;
	min?: InputMaybe<Fill_Activity_Min_Order_By>;
	stddev?: InputMaybe<Fill_Activity_Stddev_Order_By>;
	stddev_pop?: InputMaybe<Fill_Activity_Stddev_Pop_Order_By>;
	stddev_samp?: InputMaybe<Fill_Activity_Stddev_Samp_Order_By>;
	sum?: InputMaybe<Fill_Activity_Sum_Order_By>;
	var_pop?: InputMaybe<Fill_Activity_Var_Pop_Order_By>;
	var_samp?: InputMaybe<Fill_Activity_Var_Samp_Order_By>;
	variance?: InputMaybe<Fill_Activity_Variance_Order_By>;
};

/** order by avg() on columns of table "fill_activity" */
export type Fill_Activity_Avg_Order_By = {
	fill_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "fill_activity". All fields are combined with a logical 'AND'. */
export type Fill_Activity_Bool_Exp = {
	_and?: InputMaybe<Array<Fill_Activity_Bool_Exp>>;
	_not?: InputMaybe<Fill_Activity_Bool_Exp>;
	_or?: InputMaybe<Array<Fill_Activity_Bool_Exp>>;
	action?: InputMaybe<String_Comparison_Exp>;
	action_time?: InputMaybe<Timestamptz_Comparison_Exp>;
	actor?: InputMaybe<String_Comparison_Exp>;
	fill?: InputMaybe<Fills_Bool_Exp>;
	fill_id?: InputMaybe<Int_Comparison_Exp>;
};

/** order by max() on columns of table "fill_activity" */
export type Fill_Activity_Max_Order_By = {
	action?: InputMaybe<Order_By>;
	action_time?: InputMaybe<Order_By>;
	actor?: InputMaybe<Order_By>;
	fill_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "fill_activity" */
export type Fill_Activity_Min_Order_By = {
	action?: InputMaybe<Order_By>;
	action_time?: InputMaybe<Order_By>;
	actor?: InputMaybe<Order_By>;
	fill_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "fill_activity". */
export type Fill_Activity_Order_By = {
	action?: InputMaybe<Order_By>;
	action_time?: InputMaybe<Order_By>;
	actor?: InputMaybe<Order_By>;
	fill?: InputMaybe<Fills_Order_By>;
	fill_id?: InputMaybe<Order_By>;
};

/** select columns of table "fill_activity" */
export enum Fill_Activity_Select_Column {
	/** column name */
	Action = 'action',
	/** column name */
	ActionTime = 'action_time',
	/** column name */
	Actor = 'actor',
	/** column name */
	FillId = 'fill_id',
}

/** order by stddev() on columns of table "fill_activity" */
export type Fill_Activity_Stddev_Order_By = {
	fill_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "fill_activity" */
export type Fill_Activity_Stddev_Pop_Order_By = {
	fill_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "fill_activity" */
export type Fill_Activity_Stddev_Samp_Order_By = {
	fill_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "fill_activity" */
export type Fill_Activity_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Fill_Activity_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Fill_Activity_Stream_Cursor_Value_Input = {
	action?: InputMaybe<Scalars['String']>;
	action_time?: InputMaybe<Scalars['timestamptz']>;
	actor?: InputMaybe<Scalars['String']>;
	fill_id?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "fill_activity" */
export type Fill_Activity_Sum_Order_By = {
	fill_id?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "fill_activity" */
export type Fill_Activity_Var_Pop_Order_By = {
	fill_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "fill_activity" */
export type Fill_Activity_Var_Samp_Order_By = {
	fill_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "fill_activity" */
export type Fill_Activity_Variance_Order_By = {
	fill_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "fill_modification_requests" */
export type Fill_Modification_Requests = {
	__typename?: 'fill_modification_requests';
	basis?: Maybe<Scalars['numeric']>;
	br_owner?: Maybe<Scalars['String']>;
	bushels?: Maybe<Scalars['Int']>;
	/** An object relationship */
	buyer?: Maybe<Buyers>;
	buyer_id?: Maybe<Scalars['Int']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	delivery_end_date?: Maybe<Scalars['date']>;
	delivery_id?: Maybe<Scalars['Int']>;
	delivery_start_date?: Maybe<Scalars['date']>;
	expires_at?: Maybe<Scalars['date']>;
	/** An object relationship */
	fill: Fills;
	flex_fee?: Maybe<Scalars['numeric']>;
	future_price?: Maybe<Scalars['numeric']>;
	futures_month?: Maybe<Scalars['date']>;
	hta_fee?: Maybe<Scalars['numeric']>;
	id: Scalars['uuid'];
	is_fob?: Maybe<Scalars['Boolean']>;
	/** An object relationship */
	location?: Maybe<Locations>;
	/** An object relationship */
	locationByDeliveryId?: Maybe<Locations>;
	location_id?: Maybe<Scalars['Int']>;
	/** Exchange, Set Basis, Set Futures, Roll, Reduce Contract */
	modification_type?: Maybe<Scalars['String']>;
	/** The fill that is being modified. */
	original_fill_id: Scalars['Int'];
	/** The user who requested the modification. */
	requested_by?: Maybe<Scalars['String']>;
	roll_fee?: Maybe<Scalars['numeric']>;
	sales_type?: Maybe<Scalars['String']>;
	/** An object relationship */
	seller?: Maybe<Sellers>;
	seller_id?: Maybe<Scalars['uuid']>;
	spread_gain?: Maybe<Scalars['numeric']>;
	/** Open, Fulfilled, or Rejected */
	status?: Maybe<Scalars['String']>;
};

/** order by aggregate values of table "fill_modification_requests" */
export type Fill_Modification_Requests_Aggregate_Order_By = {
	avg?: InputMaybe<Fill_Modification_Requests_Avg_Order_By>;
	count?: InputMaybe<Order_By>;
	max?: InputMaybe<Fill_Modification_Requests_Max_Order_By>;
	min?: InputMaybe<Fill_Modification_Requests_Min_Order_By>;
	stddev?: InputMaybe<Fill_Modification_Requests_Stddev_Order_By>;
	stddev_pop?: InputMaybe<Fill_Modification_Requests_Stddev_Pop_Order_By>;
	stddev_samp?: InputMaybe<Fill_Modification_Requests_Stddev_Samp_Order_By>;
	sum?: InputMaybe<Fill_Modification_Requests_Sum_Order_By>;
	var_pop?: InputMaybe<Fill_Modification_Requests_Var_Pop_Order_By>;
	var_samp?: InputMaybe<Fill_Modification_Requests_Var_Samp_Order_By>;
	variance?: InputMaybe<Fill_Modification_Requests_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "fill_modification_requests" */
export type Fill_Modification_Requests_Arr_Rel_Insert_Input = {
	data: Array<Fill_Modification_Requests_Insert_Input>;
	/** upsert condition */
	on_conflict?: InputMaybe<Fill_Modification_Requests_On_Conflict>;
};

/** order by avg() on columns of table "fill_modification_requests" */
export type Fill_Modification_Requests_Avg_Order_By = {
	basis?: InputMaybe<Order_By>;
	bushels?: InputMaybe<Order_By>;
	buyer_id?: InputMaybe<Order_By>;
	delivery_id?: InputMaybe<Order_By>;
	flex_fee?: InputMaybe<Order_By>;
	future_price?: InputMaybe<Order_By>;
	hta_fee?: InputMaybe<Order_By>;
	location_id?: InputMaybe<Order_By>;
	/** The fill that is being modified. */
	original_fill_id?: InputMaybe<Order_By>;
	roll_fee?: InputMaybe<Order_By>;
	spread_gain?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "fill_modification_requests". All fields are combined with a logical 'AND'. */
export type Fill_Modification_Requests_Bool_Exp = {
	_and?: InputMaybe<Array<Fill_Modification_Requests_Bool_Exp>>;
	_not?: InputMaybe<Fill_Modification_Requests_Bool_Exp>;
	_or?: InputMaybe<Array<Fill_Modification_Requests_Bool_Exp>>;
	basis?: InputMaybe<Numeric_Comparison_Exp>;
	br_owner?: InputMaybe<String_Comparison_Exp>;
	bushels?: InputMaybe<Int_Comparison_Exp>;
	buyer?: InputMaybe<Buyers_Bool_Exp>;
	buyer_id?: InputMaybe<Int_Comparison_Exp>;
	created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	delivery_end_date?: InputMaybe<Date_Comparison_Exp>;
	delivery_id?: InputMaybe<Int_Comparison_Exp>;
	delivery_start_date?: InputMaybe<Date_Comparison_Exp>;
	expires_at?: InputMaybe<Date_Comparison_Exp>;
	fill?: InputMaybe<Fills_Bool_Exp>;
	flex_fee?: InputMaybe<Numeric_Comparison_Exp>;
	future_price?: InputMaybe<Numeric_Comparison_Exp>;
	futures_month?: InputMaybe<Date_Comparison_Exp>;
	hta_fee?: InputMaybe<Numeric_Comparison_Exp>;
	id?: InputMaybe<Uuid_Comparison_Exp>;
	is_fob?: InputMaybe<Boolean_Comparison_Exp>;
	location?: InputMaybe<Locations_Bool_Exp>;
	locationByDeliveryId?: InputMaybe<Locations_Bool_Exp>;
	location_id?: InputMaybe<Int_Comparison_Exp>;
	modification_type?: InputMaybe<String_Comparison_Exp>;
	original_fill_id?: InputMaybe<Int_Comparison_Exp>;
	requested_by?: InputMaybe<String_Comparison_Exp>;
	roll_fee?: InputMaybe<Numeric_Comparison_Exp>;
	sales_type?: InputMaybe<String_Comparison_Exp>;
	seller?: InputMaybe<Sellers_Bool_Exp>;
	seller_id?: InputMaybe<Uuid_Comparison_Exp>;
	spread_gain?: InputMaybe<Numeric_Comparison_Exp>;
	status?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "fill_modification_requests" */
export enum Fill_Modification_Requests_Constraint {
	/** unique or primary key constraint on columns "id" */
	TargetModificationRequestsPkey = 'target_modification_requests_pkey',
}

/** input type for incrementing numeric columns in table "fill_modification_requests" */
export type Fill_Modification_Requests_Inc_Input = {
	basis?: InputMaybe<Scalars['numeric']>;
	bushels?: InputMaybe<Scalars['Int']>;
	buyer_id?: InputMaybe<Scalars['Int']>;
	delivery_id?: InputMaybe<Scalars['Int']>;
	flex_fee?: InputMaybe<Scalars['numeric']>;
	future_price?: InputMaybe<Scalars['numeric']>;
	hta_fee?: InputMaybe<Scalars['numeric']>;
	location_id?: InputMaybe<Scalars['Int']>;
	roll_fee?: InputMaybe<Scalars['numeric']>;
	spread_gain?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "fill_modification_requests" */
export type Fill_Modification_Requests_Insert_Input = {
	basis?: InputMaybe<Scalars['numeric']>;
	br_owner?: InputMaybe<Scalars['String']>;
	bushels?: InputMaybe<Scalars['Int']>;
	buyer?: InputMaybe<Buyers_Obj_Rel_Insert_Input>;
	buyer_id?: InputMaybe<Scalars['Int']>;
	delivery_end_date?: InputMaybe<Scalars['date']>;
	delivery_id?: InputMaybe<Scalars['Int']>;
	delivery_start_date?: InputMaybe<Scalars['date']>;
	expires_at?: InputMaybe<Scalars['date']>;
	fill?: InputMaybe<Fills_Obj_Rel_Insert_Input>;
	flex_fee?: InputMaybe<Scalars['numeric']>;
	future_price?: InputMaybe<Scalars['numeric']>;
	futures_month?: InputMaybe<Scalars['date']>;
	hta_fee?: InputMaybe<Scalars['numeric']>;
	is_fob?: InputMaybe<Scalars['Boolean']>;
	location?: InputMaybe<Locations_Obj_Rel_Insert_Input>;
	locationByDeliveryId?: InputMaybe<Locations_Obj_Rel_Insert_Input>;
	location_id?: InputMaybe<Scalars['Int']>;
	/** Exchange, Set Basis, Set Futures, Roll, Reduce Contract */
	modification_type?: InputMaybe<Scalars['String']>;
	/** The fill that is being modified. */
	original_fill_id?: InputMaybe<Scalars['Int']>;
	/** The user who requested the modification. */
	requested_by?: InputMaybe<Scalars['String']>;
	roll_fee?: InputMaybe<Scalars['numeric']>;
	sales_type?: InputMaybe<Scalars['String']>;
	seller?: InputMaybe<Sellers_Obj_Rel_Insert_Input>;
	seller_id?: InputMaybe<Scalars['uuid']>;
	spread_gain?: InputMaybe<Scalars['numeric']>;
	/** Open, Fulfilled, or Rejected */
	status?: InputMaybe<Scalars['String']>;
};

/** order by max() on columns of table "fill_modification_requests" */
export type Fill_Modification_Requests_Max_Order_By = {
	basis?: InputMaybe<Order_By>;
	br_owner?: InputMaybe<Order_By>;
	bushels?: InputMaybe<Order_By>;
	buyer_id?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	delivery_end_date?: InputMaybe<Order_By>;
	delivery_id?: InputMaybe<Order_By>;
	delivery_start_date?: InputMaybe<Order_By>;
	expires_at?: InputMaybe<Order_By>;
	flex_fee?: InputMaybe<Order_By>;
	future_price?: InputMaybe<Order_By>;
	futures_month?: InputMaybe<Order_By>;
	hta_fee?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	location_id?: InputMaybe<Order_By>;
	/** Exchange, Set Basis, Set Futures, Roll, Reduce Contract */
	modification_type?: InputMaybe<Order_By>;
	/** The fill that is being modified. */
	original_fill_id?: InputMaybe<Order_By>;
	/** The user who requested the modification. */
	requested_by?: InputMaybe<Order_By>;
	roll_fee?: InputMaybe<Order_By>;
	sales_type?: InputMaybe<Order_By>;
	seller_id?: InputMaybe<Order_By>;
	spread_gain?: InputMaybe<Order_By>;
	/** Open, Fulfilled, or Rejected */
	status?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "fill_modification_requests" */
export type Fill_Modification_Requests_Min_Order_By = {
	basis?: InputMaybe<Order_By>;
	br_owner?: InputMaybe<Order_By>;
	bushels?: InputMaybe<Order_By>;
	buyer_id?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	delivery_end_date?: InputMaybe<Order_By>;
	delivery_id?: InputMaybe<Order_By>;
	delivery_start_date?: InputMaybe<Order_By>;
	expires_at?: InputMaybe<Order_By>;
	flex_fee?: InputMaybe<Order_By>;
	future_price?: InputMaybe<Order_By>;
	futures_month?: InputMaybe<Order_By>;
	hta_fee?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	location_id?: InputMaybe<Order_By>;
	/** Exchange, Set Basis, Set Futures, Roll, Reduce Contract */
	modification_type?: InputMaybe<Order_By>;
	/** The fill that is being modified. */
	original_fill_id?: InputMaybe<Order_By>;
	/** The user who requested the modification. */
	requested_by?: InputMaybe<Order_By>;
	roll_fee?: InputMaybe<Order_By>;
	sales_type?: InputMaybe<Order_By>;
	seller_id?: InputMaybe<Order_By>;
	spread_gain?: InputMaybe<Order_By>;
	/** Open, Fulfilled, or Rejected */
	status?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "fill_modification_requests" */
export type Fill_Modification_Requests_Mutation_Response = {
	__typename?: 'fill_modification_requests_mutation_response';
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Fill_Modification_Requests>;
};

/** on_conflict condition type for table "fill_modification_requests" */
export type Fill_Modification_Requests_On_Conflict = {
	constraint: Fill_Modification_Requests_Constraint;
	update_columns?: Array<Fill_Modification_Requests_Update_Column>;
	where?: InputMaybe<Fill_Modification_Requests_Bool_Exp>;
};

/** Ordering options when selecting data from "fill_modification_requests". */
export type Fill_Modification_Requests_Order_By = {
	basis?: InputMaybe<Order_By>;
	br_owner?: InputMaybe<Order_By>;
	bushels?: InputMaybe<Order_By>;
	buyer?: InputMaybe<Buyers_Order_By>;
	buyer_id?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	delivery_end_date?: InputMaybe<Order_By>;
	delivery_id?: InputMaybe<Order_By>;
	delivery_start_date?: InputMaybe<Order_By>;
	expires_at?: InputMaybe<Order_By>;
	fill?: InputMaybe<Fills_Order_By>;
	flex_fee?: InputMaybe<Order_By>;
	future_price?: InputMaybe<Order_By>;
	futures_month?: InputMaybe<Order_By>;
	hta_fee?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	is_fob?: InputMaybe<Order_By>;
	location?: InputMaybe<Locations_Order_By>;
	locationByDeliveryId?: InputMaybe<Locations_Order_By>;
	location_id?: InputMaybe<Order_By>;
	modification_type?: InputMaybe<Order_By>;
	original_fill_id?: InputMaybe<Order_By>;
	requested_by?: InputMaybe<Order_By>;
	roll_fee?: InputMaybe<Order_By>;
	sales_type?: InputMaybe<Order_By>;
	seller?: InputMaybe<Sellers_Order_By>;
	seller_id?: InputMaybe<Order_By>;
	spread_gain?: InputMaybe<Order_By>;
	status?: InputMaybe<Order_By>;
};

/** primary key columns input for table: fill_modification_requests */
export type Fill_Modification_Requests_Pk_Columns_Input = {
	id: Scalars['uuid'];
};

/** select columns of table "fill_modification_requests" */
export enum Fill_Modification_Requests_Select_Column {
	/** column name */
	Basis = 'basis',
	/** column name */
	BrOwner = 'br_owner',
	/** column name */
	Bushels = 'bushels',
	/** column name */
	BuyerId = 'buyer_id',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	DeliveryEndDate = 'delivery_end_date',
	/** column name */
	DeliveryId = 'delivery_id',
	/** column name */
	DeliveryStartDate = 'delivery_start_date',
	/** column name */
	ExpiresAt = 'expires_at',
	/** column name */
	FlexFee = 'flex_fee',
	/** column name */
	FuturePrice = 'future_price',
	/** column name */
	FuturesMonth = 'futures_month',
	/** column name */
	HtaFee = 'hta_fee',
	/** column name */
	Id = 'id',
	/** column name */
	IsFob = 'is_fob',
	/** column name */
	LocationId = 'location_id',
	/** column name */
	ModificationType = 'modification_type',
	/** column name */
	OriginalFillId = 'original_fill_id',
	/** column name */
	RequestedBy = 'requested_by',
	/** column name */
	RollFee = 'roll_fee',
	/** column name */
	SalesType = 'sales_type',
	/** column name */
	SellerId = 'seller_id',
	/** column name */
	SpreadGain = 'spread_gain',
	/** column name */
	Status = 'status',
}

/** input type for updating data in table "fill_modification_requests" */
export type Fill_Modification_Requests_Set_Input = {
	basis?: InputMaybe<Scalars['numeric']>;
	br_owner?: InputMaybe<Scalars['String']>;
	bushels?: InputMaybe<Scalars['Int']>;
	buyer_id?: InputMaybe<Scalars['Int']>;
	delivery_end_date?: InputMaybe<Scalars['date']>;
	delivery_id?: InputMaybe<Scalars['Int']>;
	delivery_start_date?: InputMaybe<Scalars['date']>;
	expires_at?: InputMaybe<Scalars['date']>;
	flex_fee?: InputMaybe<Scalars['numeric']>;
	future_price?: InputMaybe<Scalars['numeric']>;
	futures_month?: InputMaybe<Scalars['date']>;
	hta_fee?: InputMaybe<Scalars['numeric']>;
	is_fob?: InputMaybe<Scalars['Boolean']>;
	location_id?: InputMaybe<Scalars['Int']>;
	/** Exchange, Set Basis, Set Futures, Roll, Reduce Contract */
	modification_type?: InputMaybe<Scalars['String']>;
	roll_fee?: InputMaybe<Scalars['numeric']>;
	sales_type?: InputMaybe<Scalars['String']>;
	seller_id?: InputMaybe<Scalars['uuid']>;
	spread_gain?: InputMaybe<Scalars['numeric']>;
	/** Open, Fulfilled, or Rejected */
	status?: InputMaybe<Scalars['String']>;
};

/** order by stddev() on columns of table "fill_modification_requests" */
export type Fill_Modification_Requests_Stddev_Order_By = {
	basis?: InputMaybe<Order_By>;
	bushels?: InputMaybe<Order_By>;
	buyer_id?: InputMaybe<Order_By>;
	delivery_id?: InputMaybe<Order_By>;
	flex_fee?: InputMaybe<Order_By>;
	future_price?: InputMaybe<Order_By>;
	hta_fee?: InputMaybe<Order_By>;
	location_id?: InputMaybe<Order_By>;
	/** The fill that is being modified. */
	original_fill_id?: InputMaybe<Order_By>;
	roll_fee?: InputMaybe<Order_By>;
	spread_gain?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "fill_modification_requests" */
export type Fill_Modification_Requests_Stddev_Pop_Order_By = {
	basis?: InputMaybe<Order_By>;
	bushels?: InputMaybe<Order_By>;
	buyer_id?: InputMaybe<Order_By>;
	delivery_id?: InputMaybe<Order_By>;
	flex_fee?: InputMaybe<Order_By>;
	future_price?: InputMaybe<Order_By>;
	hta_fee?: InputMaybe<Order_By>;
	location_id?: InputMaybe<Order_By>;
	/** The fill that is being modified. */
	original_fill_id?: InputMaybe<Order_By>;
	roll_fee?: InputMaybe<Order_By>;
	spread_gain?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "fill_modification_requests" */
export type Fill_Modification_Requests_Stddev_Samp_Order_By = {
	basis?: InputMaybe<Order_By>;
	bushels?: InputMaybe<Order_By>;
	buyer_id?: InputMaybe<Order_By>;
	delivery_id?: InputMaybe<Order_By>;
	flex_fee?: InputMaybe<Order_By>;
	future_price?: InputMaybe<Order_By>;
	hta_fee?: InputMaybe<Order_By>;
	location_id?: InputMaybe<Order_By>;
	/** The fill that is being modified. */
	original_fill_id?: InputMaybe<Order_By>;
	roll_fee?: InputMaybe<Order_By>;
	spread_gain?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "fill_modification_requests" */
export type Fill_Modification_Requests_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Fill_Modification_Requests_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Fill_Modification_Requests_Stream_Cursor_Value_Input = {
	basis?: InputMaybe<Scalars['numeric']>;
	br_owner?: InputMaybe<Scalars['String']>;
	bushels?: InputMaybe<Scalars['Int']>;
	buyer_id?: InputMaybe<Scalars['Int']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	delivery_end_date?: InputMaybe<Scalars['date']>;
	delivery_id?: InputMaybe<Scalars['Int']>;
	delivery_start_date?: InputMaybe<Scalars['date']>;
	expires_at?: InputMaybe<Scalars['date']>;
	flex_fee?: InputMaybe<Scalars['numeric']>;
	future_price?: InputMaybe<Scalars['numeric']>;
	futures_month?: InputMaybe<Scalars['date']>;
	hta_fee?: InputMaybe<Scalars['numeric']>;
	id?: InputMaybe<Scalars['uuid']>;
	is_fob?: InputMaybe<Scalars['Boolean']>;
	location_id?: InputMaybe<Scalars['Int']>;
	/** Exchange, Set Basis, Set Futures, Roll, Reduce Contract */
	modification_type?: InputMaybe<Scalars['String']>;
	/** The fill that is being modified. */
	original_fill_id?: InputMaybe<Scalars['Int']>;
	/** The user who requested the modification. */
	requested_by?: InputMaybe<Scalars['String']>;
	roll_fee?: InputMaybe<Scalars['numeric']>;
	sales_type?: InputMaybe<Scalars['String']>;
	seller_id?: InputMaybe<Scalars['uuid']>;
	spread_gain?: InputMaybe<Scalars['numeric']>;
	/** Open, Fulfilled, or Rejected */
	status?: InputMaybe<Scalars['String']>;
};

/** order by sum() on columns of table "fill_modification_requests" */
export type Fill_Modification_Requests_Sum_Order_By = {
	basis?: InputMaybe<Order_By>;
	bushels?: InputMaybe<Order_By>;
	buyer_id?: InputMaybe<Order_By>;
	delivery_id?: InputMaybe<Order_By>;
	flex_fee?: InputMaybe<Order_By>;
	future_price?: InputMaybe<Order_By>;
	hta_fee?: InputMaybe<Order_By>;
	location_id?: InputMaybe<Order_By>;
	/** The fill that is being modified. */
	original_fill_id?: InputMaybe<Order_By>;
	roll_fee?: InputMaybe<Order_By>;
	spread_gain?: InputMaybe<Order_By>;
};

/** update columns of table "fill_modification_requests" */
export enum Fill_Modification_Requests_Update_Column {
	/** column name */
	Basis = 'basis',
	/** column name */
	BrOwner = 'br_owner',
	/** column name */
	Bushels = 'bushels',
	/** column name */
	BuyerId = 'buyer_id',
	/** column name */
	DeliveryEndDate = 'delivery_end_date',
	/** column name */
	DeliveryId = 'delivery_id',
	/** column name */
	DeliveryStartDate = 'delivery_start_date',
	/** column name */
	ExpiresAt = 'expires_at',
	/** column name */
	FlexFee = 'flex_fee',
	/** column name */
	FuturePrice = 'future_price',
	/** column name */
	FuturesMonth = 'futures_month',
	/** column name */
	HtaFee = 'hta_fee',
	/** column name */
	IsFob = 'is_fob',
	/** column name */
	LocationId = 'location_id',
	/** column name */
	ModificationType = 'modification_type',
	/** column name */
	RollFee = 'roll_fee',
	/** column name */
	SalesType = 'sales_type',
	/** column name */
	SellerId = 'seller_id',
	/** column name */
	SpreadGain = 'spread_gain',
	/** column name */
	Status = 'status',
}

export type Fill_Modification_Requests_Updates = {
	/** increments the numeric columns with given value of the filtered values */
	_inc?: InputMaybe<Fill_Modification_Requests_Inc_Input>;
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Fill_Modification_Requests_Set_Input>;
	/** filter the rows which have to be updated */
	where: Fill_Modification_Requests_Bool_Exp;
};

/** order by var_pop() on columns of table "fill_modification_requests" */
export type Fill_Modification_Requests_Var_Pop_Order_By = {
	basis?: InputMaybe<Order_By>;
	bushels?: InputMaybe<Order_By>;
	buyer_id?: InputMaybe<Order_By>;
	delivery_id?: InputMaybe<Order_By>;
	flex_fee?: InputMaybe<Order_By>;
	future_price?: InputMaybe<Order_By>;
	hta_fee?: InputMaybe<Order_By>;
	location_id?: InputMaybe<Order_By>;
	/** The fill that is being modified. */
	original_fill_id?: InputMaybe<Order_By>;
	roll_fee?: InputMaybe<Order_By>;
	spread_gain?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "fill_modification_requests" */
export type Fill_Modification_Requests_Var_Samp_Order_By = {
	basis?: InputMaybe<Order_By>;
	bushels?: InputMaybe<Order_By>;
	buyer_id?: InputMaybe<Order_By>;
	delivery_id?: InputMaybe<Order_By>;
	flex_fee?: InputMaybe<Order_By>;
	future_price?: InputMaybe<Order_By>;
	hta_fee?: InputMaybe<Order_By>;
	location_id?: InputMaybe<Order_By>;
	/** The fill that is being modified. */
	original_fill_id?: InputMaybe<Order_By>;
	roll_fee?: InputMaybe<Order_By>;
	spread_gain?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "fill_modification_requests" */
export type Fill_Modification_Requests_Variance_Order_By = {
	basis?: InputMaybe<Order_By>;
	bushels?: InputMaybe<Order_By>;
	buyer_id?: InputMaybe<Order_By>;
	delivery_id?: InputMaybe<Order_By>;
	flex_fee?: InputMaybe<Order_By>;
	future_price?: InputMaybe<Order_By>;
	hta_fee?: InputMaybe<Order_By>;
	location_id?: InputMaybe<Order_By>;
	/** The fill that is being modified. */
	original_fill_id?: InputMaybe<Order_By>;
	roll_fee?: InputMaybe<Order_By>;
	spread_gain?: InputMaybe<Order_By>;
};

/** columns and relationships of "fill_notes" */
export type Fill_Notes = {
	__typename?: 'fill_notes';
	/** An object relationship */
	fill?: Maybe<Fills>;
	fill_id?: Maybe<Scalars['Int']>;
	id: Scalars['Int'];
	note?: Maybe<Scalars['String']>;
	written_at?: Maybe<Scalars['timestamptz']>;
	written_by?: Maybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "fill_notes". All fields are combined with a logical 'AND'. */
export type Fill_Notes_Bool_Exp = {
	_and?: InputMaybe<Array<Fill_Notes_Bool_Exp>>;
	_not?: InputMaybe<Fill_Notes_Bool_Exp>;
	_or?: InputMaybe<Array<Fill_Notes_Bool_Exp>>;
	fill?: InputMaybe<Fills_Bool_Exp>;
	fill_id?: InputMaybe<Int_Comparison_Exp>;
	id?: InputMaybe<Int_Comparison_Exp>;
	note?: InputMaybe<String_Comparison_Exp>;
	written_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	written_by?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "fill_notes" */
export enum Fill_Notes_Constraint {
	/** unique or primary key constraint on columns "id" */
	FillNotesPkey = 'fill_notes_pkey',
}

/** input type for inserting data into table "fill_notes" */
export type Fill_Notes_Insert_Input = {
	fill?: InputMaybe<Fills_Obj_Rel_Insert_Input>;
	fill_id?: InputMaybe<Scalars['Int']>;
	note?: InputMaybe<Scalars['String']>;
	written_by?: InputMaybe<Scalars['String']>;
};

/** response of any mutation on the table "fill_notes" */
export type Fill_Notes_Mutation_Response = {
	__typename?: 'fill_notes_mutation_response';
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Fill_Notes>;
};

/** on_conflict condition type for table "fill_notes" */
export type Fill_Notes_On_Conflict = {
	constraint: Fill_Notes_Constraint;
	update_columns?: Array<Fill_Notes_Update_Column>;
	where?: InputMaybe<Fill_Notes_Bool_Exp>;
};

/** Ordering options when selecting data from "fill_notes". */
export type Fill_Notes_Order_By = {
	fill?: InputMaybe<Fills_Order_By>;
	fill_id?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	note?: InputMaybe<Order_By>;
	written_at?: InputMaybe<Order_By>;
	written_by?: InputMaybe<Order_By>;
};

/** select columns of table "fill_notes" */
export enum Fill_Notes_Select_Column {
	/** column name */
	FillId = 'fill_id',
	/** column name */
	Id = 'id',
	/** column name */
	Note = 'note',
	/** column name */
	WrittenAt = 'written_at',
	/** column name */
	WrittenBy = 'written_by',
}

/** Streaming cursor of the table "fill_notes" */
export type Fill_Notes_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Fill_Notes_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Fill_Notes_Stream_Cursor_Value_Input = {
	fill_id?: InputMaybe<Scalars['Int']>;
	id?: InputMaybe<Scalars['Int']>;
	note?: InputMaybe<Scalars['String']>;
	written_at?: InputMaybe<Scalars['timestamptz']>;
	written_by?: InputMaybe<Scalars['String']>;
};

/** placeholder for update columns of table "fill_notes" (current role has no relevant permissions) */
export enum Fill_Notes_Update_Column {
	/** placeholder (do not use) */
	Placeholder = '_PLACEHOLDER',
}

/** columns and relationships of "fills" */
export type Fills = {
	__typename?: 'fills';
	/** An object relationship */
	Buyer?: Maybe<Buyers>;
	/** An object relationship */
	Delivery?: Maybe<Locations>;
	/** An object relationship */
	FeedPlan?: Maybe<Feed_Plans>;
	/** An array relationship */
	FillActivity: Array<Fill_Activity>;
	/** An array relationship */
	FillModificationRequests: Array<Fill_Modification_Requests>;
	/** An object relationship */
	Location?: Maybe<Locations>;
	/** An object relationship */
	Production?: Maybe<Production>;
	/** An object relationship */
	Seller?: Maybe<Sellers>;
	/** An object relationship */
	Target?: Maybe<Targets>;
	basis?: Maybe<Scalars['numeric']>;
	bushels?: Maybe<Scalars['Int']>;
	buyer_id?: Maybe<Scalars['Int']>;
	contract_number?: Maybe<Scalars['String']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	created_by?: Maybe<Scalars['String']>;
	delivery_end_date?: Maybe<Scalars['date']>;
	delivery_id?: Maybe<Scalars['Int']>;
	delivery_month?: Maybe<Scalars['date']>;
	delivery_start_date?: Maybe<Scalars['date']>;
	feed_plan_id?: Maybe<Scalars['uuid']>;
	fees?: Maybe<Scalars['numeric']>;
	flat_price?: Maybe<Scalars['numeric']>;
	flex_fee?: Maybe<Scalars['numeric']>;
	future_price?: Maybe<Scalars['numeric']>;
	futures_month?: Maybe<Scalars['date']>;
	hta_fee?: Maybe<Scalars['numeric']>;
	id: Scalars['Int'];
	is_fob?: Maybe<Scalars['Boolean']>;
	location_id?: Maybe<Scalars['Int']>;
	/** An array relationship */
	order_activity: Array<Order_Activity>;
	production_id?: Maybe<Scalars['Int']>;
	roll_fee?: Maybe<Scalars['numeric']>;
	sales_type?: Maybe<Scalars['String']>;
	seller_id?: Maybe<Scalars['uuid']>;
	spread_gain?: Maybe<Scalars['numeric']>;
	target_id: Scalars['Int'];
};

/** columns and relationships of "fills" */
export type FillsFillActivityArgs = {
	distinct_on?: InputMaybe<Array<Fill_Activity_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Fill_Activity_Order_By>>;
	where?: InputMaybe<Fill_Activity_Bool_Exp>;
};

/** columns and relationships of "fills" */
export type FillsFillModificationRequestsArgs = {
	distinct_on?: InputMaybe<Array<Fill_Modification_Requests_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Fill_Modification_Requests_Order_By>>;
	where?: InputMaybe<Fill_Modification_Requests_Bool_Exp>;
};

/** columns and relationships of "fills" */
export type FillsOrder_ActivityArgs = {
	distinct_on?: InputMaybe<Array<Order_Activity_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Order_Activity_Order_By>>;
	where?: InputMaybe<Order_Activity_Bool_Exp>;
};

/** aggregated selection of "fills" */
export type Fills_Aggregate = {
	__typename?: 'fills_aggregate';
	aggregate?: Maybe<Fills_Aggregate_Fields>;
	nodes: Array<Fills>;
};

export type Fills_Aggregate_Bool_Exp = {
	bool_and?: InputMaybe<Fills_Aggregate_Bool_Exp_Bool_And>;
	bool_or?: InputMaybe<Fills_Aggregate_Bool_Exp_Bool_Or>;
	count?: InputMaybe<Fills_Aggregate_Bool_Exp_Count>;
};

export type Fills_Aggregate_Bool_Exp_Bool_And = {
	arguments: Fills_Select_Column_Fills_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Fills_Bool_Exp>;
	predicate: Boolean_Comparison_Exp;
};

export type Fills_Aggregate_Bool_Exp_Bool_Or = {
	arguments: Fills_Select_Column_Fills_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Fills_Bool_Exp>;
	predicate: Boolean_Comparison_Exp;
};

export type Fills_Aggregate_Bool_Exp_Count = {
	arguments?: InputMaybe<Array<Fills_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Fills_Bool_Exp>;
	predicate: Int_Comparison_Exp;
};

/** aggregate fields of "fills" */
export type Fills_Aggregate_Fields = {
	__typename?: 'fills_aggregate_fields';
	avg?: Maybe<Fills_Avg_Fields>;
	count: Scalars['Int'];
	max?: Maybe<Fills_Max_Fields>;
	min?: Maybe<Fills_Min_Fields>;
	stddev?: Maybe<Fills_Stddev_Fields>;
	stddev_pop?: Maybe<Fills_Stddev_Pop_Fields>;
	stddev_samp?: Maybe<Fills_Stddev_Samp_Fields>;
	sum?: Maybe<Fills_Sum_Fields>;
	var_pop?: Maybe<Fills_Var_Pop_Fields>;
	var_samp?: Maybe<Fills_Var_Samp_Fields>;
	variance?: Maybe<Fills_Variance_Fields>;
};

/** aggregate fields of "fills" */
export type Fills_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Fills_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "fills" */
export type Fills_Aggregate_Order_By = {
	avg?: InputMaybe<Fills_Avg_Order_By>;
	count?: InputMaybe<Order_By>;
	max?: InputMaybe<Fills_Max_Order_By>;
	min?: InputMaybe<Fills_Min_Order_By>;
	stddev?: InputMaybe<Fills_Stddev_Order_By>;
	stddev_pop?: InputMaybe<Fills_Stddev_Pop_Order_By>;
	stddev_samp?: InputMaybe<Fills_Stddev_Samp_Order_By>;
	sum?: InputMaybe<Fills_Sum_Order_By>;
	var_pop?: InputMaybe<Fills_Var_Pop_Order_By>;
	var_samp?: InputMaybe<Fills_Var_Samp_Order_By>;
	variance?: InputMaybe<Fills_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "fills" */
export type Fills_Arr_Rel_Insert_Input = {
	data: Array<Fills_Insert_Input>;
	/** upsert condition */
	on_conflict?: InputMaybe<Fills_On_Conflict>;
};

/** aggregate avg on columns */
export type Fills_Avg_Fields = {
	__typename?: 'fills_avg_fields';
	basis?: Maybe<Scalars['Float']>;
	bushels?: Maybe<Scalars['Float']>;
	buyer_id?: Maybe<Scalars['Float']>;
	delivery_id?: Maybe<Scalars['Float']>;
	fees?: Maybe<Scalars['Float']>;
	flat_price?: Maybe<Scalars['Float']>;
	flex_fee?: Maybe<Scalars['Float']>;
	future_price?: Maybe<Scalars['Float']>;
	hta_fee?: Maybe<Scalars['Float']>;
	id?: Maybe<Scalars['Float']>;
	location_id?: Maybe<Scalars['Float']>;
	production_id?: Maybe<Scalars['Float']>;
	roll_fee?: Maybe<Scalars['Float']>;
	spread_gain?: Maybe<Scalars['Float']>;
	target_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "fills" */
export type Fills_Avg_Order_By = {
	basis?: InputMaybe<Order_By>;
	bushels?: InputMaybe<Order_By>;
	buyer_id?: InputMaybe<Order_By>;
	delivery_id?: InputMaybe<Order_By>;
	fees?: InputMaybe<Order_By>;
	flat_price?: InputMaybe<Order_By>;
	flex_fee?: InputMaybe<Order_By>;
	future_price?: InputMaybe<Order_By>;
	hta_fee?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	location_id?: InputMaybe<Order_By>;
	production_id?: InputMaybe<Order_By>;
	roll_fee?: InputMaybe<Order_By>;
	spread_gain?: InputMaybe<Order_By>;
	target_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "fills". All fields are combined with a logical 'AND'. */
export type Fills_Bool_Exp = {
	Buyer?: InputMaybe<Buyers_Bool_Exp>;
	Delivery?: InputMaybe<Locations_Bool_Exp>;
	FeedPlan?: InputMaybe<Feed_Plans_Bool_Exp>;
	FillActivity?: InputMaybe<Fill_Activity_Bool_Exp>;
	FillModificationRequests?: InputMaybe<Fill_Modification_Requests_Bool_Exp>;
	Location?: InputMaybe<Locations_Bool_Exp>;
	Production?: InputMaybe<Production_Bool_Exp>;
	Seller?: InputMaybe<Sellers_Bool_Exp>;
	Target?: InputMaybe<Targets_Bool_Exp>;
	_and?: InputMaybe<Array<Fills_Bool_Exp>>;
	_not?: InputMaybe<Fills_Bool_Exp>;
	_or?: InputMaybe<Array<Fills_Bool_Exp>>;
	basis?: InputMaybe<Numeric_Comparison_Exp>;
	bushels?: InputMaybe<Int_Comparison_Exp>;
	buyer_id?: InputMaybe<Int_Comparison_Exp>;
	contract_number?: InputMaybe<String_Comparison_Exp>;
	created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	created_by?: InputMaybe<String_Comparison_Exp>;
	delivery_end_date?: InputMaybe<Date_Comparison_Exp>;
	delivery_id?: InputMaybe<Int_Comparison_Exp>;
	delivery_month?: InputMaybe<Date_Comparison_Exp>;
	delivery_start_date?: InputMaybe<Date_Comparison_Exp>;
	feed_plan_id?: InputMaybe<Uuid_Comparison_Exp>;
	fees?: InputMaybe<Numeric_Comparison_Exp>;
	flat_price?: InputMaybe<Numeric_Comparison_Exp>;
	flex_fee?: InputMaybe<Numeric_Comparison_Exp>;
	future_price?: InputMaybe<Numeric_Comparison_Exp>;
	futures_month?: InputMaybe<Date_Comparison_Exp>;
	hta_fee?: InputMaybe<Numeric_Comparison_Exp>;
	id?: InputMaybe<Int_Comparison_Exp>;
	is_fob?: InputMaybe<Boolean_Comparison_Exp>;
	location_id?: InputMaybe<Int_Comparison_Exp>;
	order_activity?: InputMaybe<Order_Activity_Bool_Exp>;
	production_id?: InputMaybe<Int_Comparison_Exp>;
	roll_fee?: InputMaybe<Numeric_Comparison_Exp>;
	sales_type?: InputMaybe<String_Comparison_Exp>;
	seller_id?: InputMaybe<Uuid_Comparison_Exp>;
	spread_gain?: InputMaybe<Numeric_Comparison_Exp>;
	target_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "fills" */
export enum Fills_Constraint {
	/** unique or primary key constraint on columns "id" */
	FillsPkey = 'fills_pkey',
}

/** input type for incrementing numeric columns in table "fills" */
export type Fills_Inc_Input = {
	basis?: InputMaybe<Scalars['numeric']>;
	bushels?: InputMaybe<Scalars['Int']>;
	buyer_id?: InputMaybe<Scalars['Int']>;
	delivery_id?: InputMaybe<Scalars['Int']>;
	flex_fee?: InputMaybe<Scalars['numeric']>;
	future_price?: InputMaybe<Scalars['numeric']>;
	hta_fee?: InputMaybe<Scalars['numeric']>;
	location_id?: InputMaybe<Scalars['Int']>;
	roll_fee?: InputMaybe<Scalars['numeric']>;
	spread_gain?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "fills" */
export type Fills_Insert_Input = {
	Buyer?: InputMaybe<Buyers_Obj_Rel_Insert_Input>;
	Delivery?: InputMaybe<Locations_Obj_Rel_Insert_Input>;
	FeedPlan?: InputMaybe<Feed_Plans_Obj_Rel_Insert_Input>;
	FillModificationRequests?: InputMaybe<Fill_Modification_Requests_Arr_Rel_Insert_Input>;
	Location?: InputMaybe<Locations_Obj_Rel_Insert_Input>;
	Production?: InputMaybe<Production_Obj_Rel_Insert_Input>;
	Seller?: InputMaybe<Sellers_Obj_Rel_Insert_Input>;
	Target?: InputMaybe<Targets_Obj_Rel_Insert_Input>;
	basis?: InputMaybe<Scalars['numeric']>;
	bushels?: InputMaybe<Scalars['Int']>;
	buyer_id?: InputMaybe<Scalars['Int']>;
	contract_number?: InputMaybe<Scalars['String']>;
	created_by?: InputMaybe<Scalars['String']>;
	delivery_end_date?: InputMaybe<Scalars['date']>;
	delivery_id?: InputMaybe<Scalars['Int']>;
	delivery_month?: InputMaybe<Scalars['date']>;
	delivery_start_date?: InputMaybe<Scalars['date']>;
	feed_plan_id?: InputMaybe<Scalars['uuid']>;
	flex_fee?: InputMaybe<Scalars['numeric']>;
	future_price?: InputMaybe<Scalars['numeric']>;
	futures_month?: InputMaybe<Scalars['date']>;
	hta_fee?: InputMaybe<Scalars['numeric']>;
	is_fob?: InputMaybe<Scalars['Boolean']>;
	location_id?: InputMaybe<Scalars['Int']>;
	production_id?: InputMaybe<Scalars['Int']>;
	roll_fee?: InputMaybe<Scalars['numeric']>;
	sales_type?: InputMaybe<Scalars['String']>;
	seller_id?: InputMaybe<Scalars['uuid']>;
	spread_gain?: InputMaybe<Scalars['numeric']>;
	target_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Fills_Max_Fields = {
	__typename?: 'fills_max_fields';
	basis?: Maybe<Scalars['numeric']>;
	bushels?: Maybe<Scalars['Int']>;
	buyer_id?: Maybe<Scalars['Int']>;
	contract_number?: Maybe<Scalars['String']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	created_by?: Maybe<Scalars['String']>;
	delivery_end_date?: Maybe<Scalars['date']>;
	delivery_id?: Maybe<Scalars['Int']>;
	delivery_month?: Maybe<Scalars['date']>;
	delivery_start_date?: Maybe<Scalars['date']>;
	feed_plan_id?: Maybe<Scalars['uuid']>;
	fees?: Maybe<Scalars['numeric']>;
	flat_price?: Maybe<Scalars['numeric']>;
	flex_fee?: Maybe<Scalars['numeric']>;
	future_price?: Maybe<Scalars['numeric']>;
	futures_month?: Maybe<Scalars['date']>;
	hta_fee?: Maybe<Scalars['numeric']>;
	id?: Maybe<Scalars['Int']>;
	location_id?: Maybe<Scalars['Int']>;
	production_id?: Maybe<Scalars['Int']>;
	roll_fee?: Maybe<Scalars['numeric']>;
	sales_type?: Maybe<Scalars['String']>;
	seller_id?: Maybe<Scalars['uuid']>;
	spread_gain?: Maybe<Scalars['numeric']>;
	target_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "fills" */
export type Fills_Max_Order_By = {
	basis?: InputMaybe<Order_By>;
	bushels?: InputMaybe<Order_By>;
	buyer_id?: InputMaybe<Order_By>;
	contract_number?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	created_by?: InputMaybe<Order_By>;
	delivery_end_date?: InputMaybe<Order_By>;
	delivery_id?: InputMaybe<Order_By>;
	delivery_month?: InputMaybe<Order_By>;
	delivery_start_date?: InputMaybe<Order_By>;
	feed_plan_id?: InputMaybe<Order_By>;
	fees?: InputMaybe<Order_By>;
	flat_price?: InputMaybe<Order_By>;
	flex_fee?: InputMaybe<Order_By>;
	future_price?: InputMaybe<Order_By>;
	futures_month?: InputMaybe<Order_By>;
	hta_fee?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	location_id?: InputMaybe<Order_By>;
	production_id?: InputMaybe<Order_By>;
	roll_fee?: InputMaybe<Order_By>;
	sales_type?: InputMaybe<Order_By>;
	seller_id?: InputMaybe<Order_By>;
	spread_gain?: InputMaybe<Order_By>;
	target_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Fills_Min_Fields = {
	__typename?: 'fills_min_fields';
	basis?: Maybe<Scalars['numeric']>;
	bushels?: Maybe<Scalars['Int']>;
	buyer_id?: Maybe<Scalars['Int']>;
	contract_number?: Maybe<Scalars['String']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	created_by?: Maybe<Scalars['String']>;
	delivery_end_date?: Maybe<Scalars['date']>;
	delivery_id?: Maybe<Scalars['Int']>;
	delivery_month?: Maybe<Scalars['date']>;
	delivery_start_date?: Maybe<Scalars['date']>;
	feed_plan_id?: Maybe<Scalars['uuid']>;
	fees?: Maybe<Scalars['numeric']>;
	flat_price?: Maybe<Scalars['numeric']>;
	flex_fee?: Maybe<Scalars['numeric']>;
	future_price?: Maybe<Scalars['numeric']>;
	futures_month?: Maybe<Scalars['date']>;
	hta_fee?: Maybe<Scalars['numeric']>;
	id?: Maybe<Scalars['Int']>;
	location_id?: Maybe<Scalars['Int']>;
	production_id?: Maybe<Scalars['Int']>;
	roll_fee?: Maybe<Scalars['numeric']>;
	sales_type?: Maybe<Scalars['String']>;
	seller_id?: Maybe<Scalars['uuid']>;
	spread_gain?: Maybe<Scalars['numeric']>;
	target_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "fills" */
export type Fills_Min_Order_By = {
	basis?: InputMaybe<Order_By>;
	bushels?: InputMaybe<Order_By>;
	buyer_id?: InputMaybe<Order_By>;
	contract_number?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	created_by?: InputMaybe<Order_By>;
	delivery_end_date?: InputMaybe<Order_By>;
	delivery_id?: InputMaybe<Order_By>;
	delivery_month?: InputMaybe<Order_By>;
	delivery_start_date?: InputMaybe<Order_By>;
	feed_plan_id?: InputMaybe<Order_By>;
	fees?: InputMaybe<Order_By>;
	flat_price?: InputMaybe<Order_By>;
	flex_fee?: InputMaybe<Order_By>;
	future_price?: InputMaybe<Order_By>;
	futures_month?: InputMaybe<Order_By>;
	hta_fee?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	location_id?: InputMaybe<Order_By>;
	production_id?: InputMaybe<Order_By>;
	roll_fee?: InputMaybe<Order_By>;
	sales_type?: InputMaybe<Order_By>;
	seller_id?: InputMaybe<Order_By>;
	spread_gain?: InputMaybe<Order_By>;
	target_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "fills" */
export type Fills_Mutation_Response = {
	__typename?: 'fills_mutation_response';
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Fills>;
};

/** input type for inserting object relation for remote table "fills" */
export type Fills_Obj_Rel_Insert_Input = {
	data: Fills_Insert_Input;
	/** upsert condition */
	on_conflict?: InputMaybe<Fills_On_Conflict>;
};

/** on_conflict condition type for table "fills" */
export type Fills_On_Conflict = {
	constraint: Fills_Constraint;
	update_columns?: Array<Fills_Update_Column>;
	where?: InputMaybe<Fills_Bool_Exp>;
};

/** Ordering options when selecting data from "fills". */
export type Fills_Order_By = {
	Buyer?: InputMaybe<Buyers_Order_By>;
	Delivery?: InputMaybe<Locations_Order_By>;
	FeedPlan?: InputMaybe<Feed_Plans_Order_By>;
	FillActivity_aggregate?: InputMaybe<Fill_Activity_Aggregate_Order_By>;
	FillModificationRequests_aggregate?: InputMaybe<Fill_Modification_Requests_Aggregate_Order_By>;
	Location?: InputMaybe<Locations_Order_By>;
	Production?: InputMaybe<Production_Order_By>;
	Seller?: InputMaybe<Sellers_Order_By>;
	Target?: InputMaybe<Targets_Order_By>;
	basis?: InputMaybe<Order_By>;
	bushels?: InputMaybe<Order_By>;
	buyer_id?: InputMaybe<Order_By>;
	contract_number?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	created_by?: InputMaybe<Order_By>;
	delivery_end_date?: InputMaybe<Order_By>;
	delivery_id?: InputMaybe<Order_By>;
	delivery_month?: InputMaybe<Order_By>;
	delivery_start_date?: InputMaybe<Order_By>;
	feed_plan_id?: InputMaybe<Order_By>;
	fees?: InputMaybe<Order_By>;
	flat_price?: InputMaybe<Order_By>;
	flex_fee?: InputMaybe<Order_By>;
	future_price?: InputMaybe<Order_By>;
	futures_month?: InputMaybe<Order_By>;
	hta_fee?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	is_fob?: InputMaybe<Order_By>;
	location_id?: InputMaybe<Order_By>;
	order_activity_aggregate?: InputMaybe<Order_Activity_Aggregate_Order_By>;
	production_id?: InputMaybe<Order_By>;
	roll_fee?: InputMaybe<Order_By>;
	sales_type?: InputMaybe<Order_By>;
	seller_id?: InputMaybe<Order_By>;
	spread_gain?: InputMaybe<Order_By>;
	target_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: fills */
export type Fills_Pk_Columns_Input = {
	id: Scalars['Int'];
};

/** select columns of table "fills" */
export enum Fills_Select_Column {
	/** column name */
	Basis = 'basis',
	/** column name */
	Bushels = 'bushels',
	/** column name */
	BuyerId = 'buyer_id',
	/** column name */
	ContractNumber = 'contract_number',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	CreatedBy = 'created_by',
	/** column name */
	DeliveryEndDate = 'delivery_end_date',
	/** column name */
	DeliveryId = 'delivery_id',
	/** column name */
	DeliveryMonth = 'delivery_month',
	/** column name */
	DeliveryStartDate = 'delivery_start_date',
	/** column name */
	FeedPlanId = 'feed_plan_id',
	/** column name */
	Fees = 'fees',
	/** column name */
	FlatPrice = 'flat_price',
	/** column name */
	FlexFee = 'flex_fee',
	/** column name */
	FuturePrice = 'future_price',
	/** column name */
	FuturesMonth = 'futures_month',
	/** column name */
	HtaFee = 'hta_fee',
	/** column name */
	Id = 'id',
	/** column name */
	IsFob = 'is_fob',
	/** column name */
	LocationId = 'location_id',
	/** column name */
	ProductionId = 'production_id',
	/** column name */
	RollFee = 'roll_fee',
	/** column name */
	SalesType = 'sales_type',
	/** column name */
	SellerId = 'seller_id',
	/** column name */
	SpreadGain = 'spread_gain',
	/** column name */
	TargetId = 'target_id',
}

/** select "fills_aggregate_bool_exp_bool_and_arguments_columns" columns of table "fills" */
export enum Fills_Select_Column_Fills_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
	/** column name */
	IsFob = 'is_fob',
}

/** select "fills_aggregate_bool_exp_bool_or_arguments_columns" columns of table "fills" */
export enum Fills_Select_Column_Fills_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
	/** column name */
	IsFob = 'is_fob',
}

/** input type for updating data in table "fills" */
export type Fills_Set_Input = {
	basis?: InputMaybe<Scalars['numeric']>;
	bushels?: InputMaybe<Scalars['Int']>;
	buyer_id?: InputMaybe<Scalars['Int']>;
	contract_number?: InputMaybe<Scalars['String']>;
	delivery_end_date?: InputMaybe<Scalars['date']>;
	delivery_id?: InputMaybe<Scalars['Int']>;
	delivery_month?: InputMaybe<Scalars['date']>;
	delivery_start_date?: InputMaybe<Scalars['date']>;
	feed_plan_id?: InputMaybe<Scalars['uuid']>;
	flex_fee?: InputMaybe<Scalars['numeric']>;
	future_price?: InputMaybe<Scalars['numeric']>;
	futures_month?: InputMaybe<Scalars['date']>;
	hta_fee?: InputMaybe<Scalars['numeric']>;
	is_fob?: InputMaybe<Scalars['Boolean']>;
	location_id?: InputMaybe<Scalars['Int']>;
	roll_fee?: InputMaybe<Scalars['numeric']>;
	sales_type?: InputMaybe<Scalars['String']>;
	seller_id?: InputMaybe<Scalars['uuid']>;
	spread_gain?: InputMaybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Fills_Stddev_Fields = {
	__typename?: 'fills_stddev_fields';
	basis?: Maybe<Scalars['Float']>;
	bushels?: Maybe<Scalars['Float']>;
	buyer_id?: Maybe<Scalars['Float']>;
	delivery_id?: Maybe<Scalars['Float']>;
	fees?: Maybe<Scalars['Float']>;
	flat_price?: Maybe<Scalars['Float']>;
	flex_fee?: Maybe<Scalars['Float']>;
	future_price?: Maybe<Scalars['Float']>;
	hta_fee?: Maybe<Scalars['Float']>;
	id?: Maybe<Scalars['Float']>;
	location_id?: Maybe<Scalars['Float']>;
	production_id?: Maybe<Scalars['Float']>;
	roll_fee?: Maybe<Scalars['Float']>;
	spread_gain?: Maybe<Scalars['Float']>;
	target_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "fills" */
export type Fills_Stddev_Order_By = {
	basis?: InputMaybe<Order_By>;
	bushels?: InputMaybe<Order_By>;
	buyer_id?: InputMaybe<Order_By>;
	delivery_id?: InputMaybe<Order_By>;
	fees?: InputMaybe<Order_By>;
	flat_price?: InputMaybe<Order_By>;
	flex_fee?: InputMaybe<Order_By>;
	future_price?: InputMaybe<Order_By>;
	hta_fee?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	location_id?: InputMaybe<Order_By>;
	production_id?: InputMaybe<Order_By>;
	roll_fee?: InputMaybe<Order_By>;
	spread_gain?: InputMaybe<Order_By>;
	target_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Fills_Stddev_Pop_Fields = {
	__typename?: 'fills_stddev_pop_fields';
	basis?: Maybe<Scalars['Float']>;
	bushels?: Maybe<Scalars['Float']>;
	buyer_id?: Maybe<Scalars['Float']>;
	delivery_id?: Maybe<Scalars['Float']>;
	fees?: Maybe<Scalars['Float']>;
	flat_price?: Maybe<Scalars['Float']>;
	flex_fee?: Maybe<Scalars['Float']>;
	future_price?: Maybe<Scalars['Float']>;
	hta_fee?: Maybe<Scalars['Float']>;
	id?: Maybe<Scalars['Float']>;
	location_id?: Maybe<Scalars['Float']>;
	production_id?: Maybe<Scalars['Float']>;
	roll_fee?: Maybe<Scalars['Float']>;
	spread_gain?: Maybe<Scalars['Float']>;
	target_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "fills" */
export type Fills_Stddev_Pop_Order_By = {
	basis?: InputMaybe<Order_By>;
	bushels?: InputMaybe<Order_By>;
	buyer_id?: InputMaybe<Order_By>;
	delivery_id?: InputMaybe<Order_By>;
	fees?: InputMaybe<Order_By>;
	flat_price?: InputMaybe<Order_By>;
	flex_fee?: InputMaybe<Order_By>;
	future_price?: InputMaybe<Order_By>;
	hta_fee?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	location_id?: InputMaybe<Order_By>;
	production_id?: InputMaybe<Order_By>;
	roll_fee?: InputMaybe<Order_By>;
	spread_gain?: InputMaybe<Order_By>;
	target_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Fills_Stddev_Samp_Fields = {
	__typename?: 'fills_stddev_samp_fields';
	basis?: Maybe<Scalars['Float']>;
	bushels?: Maybe<Scalars['Float']>;
	buyer_id?: Maybe<Scalars['Float']>;
	delivery_id?: Maybe<Scalars['Float']>;
	fees?: Maybe<Scalars['Float']>;
	flat_price?: Maybe<Scalars['Float']>;
	flex_fee?: Maybe<Scalars['Float']>;
	future_price?: Maybe<Scalars['Float']>;
	hta_fee?: Maybe<Scalars['Float']>;
	id?: Maybe<Scalars['Float']>;
	location_id?: Maybe<Scalars['Float']>;
	production_id?: Maybe<Scalars['Float']>;
	roll_fee?: Maybe<Scalars['Float']>;
	spread_gain?: Maybe<Scalars['Float']>;
	target_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "fills" */
export type Fills_Stddev_Samp_Order_By = {
	basis?: InputMaybe<Order_By>;
	bushels?: InputMaybe<Order_By>;
	buyer_id?: InputMaybe<Order_By>;
	delivery_id?: InputMaybe<Order_By>;
	fees?: InputMaybe<Order_By>;
	flat_price?: InputMaybe<Order_By>;
	flex_fee?: InputMaybe<Order_By>;
	future_price?: InputMaybe<Order_By>;
	hta_fee?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	location_id?: InputMaybe<Order_By>;
	production_id?: InputMaybe<Order_By>;
	roll_fee?: InputMaybe<Order_By>;
	spread_gain?: InputMaybe<Order_By>;
	target_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "fills" */
export type Fills_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Fills_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Fills_Stream_Cursor_Value_Input = {
	basis?: InputMaybe<Scalars['numeric']>;
	bushels?: InputMaybe<Scalars['Int']>;
	buyer_id?: InputMaybe<Scalars['Int']>;
	contract_number?: InputMaybe<Scalars['String']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	created_by?: InputMaybe<Scalars['String']>;
	delivery_end_date?: InputMaybe<Scalars['date']>;
	delivery_id?: InputMaybe<Scalars['Int']>;
	delivery_month?: InputMaybe<Scalars['date']>;
	delivery_start_date?: InputMaybe<Scalars['date']>;
	feed_plan_id?: InputMaybe<Scalars['uuid']>;
	fees?: InputMaybe<Scalars['numeric']>;
	flat_price?: InputMaybe<Scalars['numeric']>;
	flex_fee?: InputMaybe<Scalars['numeric']>;
	future_price?: InputMaybe<Scalars['numeric']>;
	futures_month?: InputMaybe<Scalars['date']>;
	hta_fee?: InputMaybe<Scalars['numeric']>;
	id?: InputMaybe<Scalars['Int']>;
	is_fob?: InputMaybe<Scalars['Boolean']>;
	location_id?: InputMaybe<Scalars['Int']>;
	production_id?: InputMaybe<Scalars['Int']>;
	roll_fee?: InputMaybe<Scalars['numeric']>;
	sales_type?: InputMaybe<Scalars['String']>;
	seller_id?: InputMaybe<Scalars['uuid']>;
	spread_gain?: InputMaybe<Scalars['numeric']>;
	target_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Fills_Sum_Fields = {
	__typename?: 'fills_sum_fields';
	basis?: Maybe<Scalars['numeric']>;
	bushels?: Maybe<Scalars['Int']>;
	buyer_id?: Maybe<Scalars['Int']>;
	delivery_id?: Maybe<Scalars['Int']>;
	fees?: Maybe<Scalars['numeric']>;
	flat_price?: Maybe<Scalars['numeric']>;
	flex_fee?: Maybe<Scalars['numeric']>;
	future_price?: Maybe<Scalars['numeric']>;
	hta_fee?: Maybe<Scalars['numeric']>;
	id?: Maybe<Scalars['Int']>;
	location_id?: Maybe<Scalars['Int']>;
	production_id?: Maybe<Scalars['Int']>;
	roll_fee?: Maybe<Scalars['numeric']>;
	spread_gain?: Maybe<Scalars['numeric']>;
	target_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "fills" */
export type Fills_Sum_Order_By = {
	basis?: InputMaybe<Order_By>;
	bushels?: InputMaybe<Order_By>;
	buyer_id?: InputMaybe<Order_By>;
	delivery_id?: InputMaybe<Order_By>;
	fees?: InputMaybe<Order_By>;
	flat_price?: InputMaybe<Order_By>;
	flex_fee?: InputMaybe<Order_By>;
	future_price?: InputMaybe<Order_By>;
	hta_fee?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	location_id?: InputMaybe<Order_By>;
	production_id?: InputMaybe<Order_By>;
	roll_fee?: InputMaybe<Order_By>;
	spread_gain?: InputMaybe<Order_By>;
	target_id?: InputMaybe<Order_By>;
};

/** update columns of table "fills" */
export enum Fills_Update_Column {
	/** column name */
	Basis = 'basis',
	/** column name */
	Bushels = 'bushels',
	/** column name */
	BuyerId = 'buyer_id',
	/** column name */
	ContractNumber = 'contract_number',
	/** column name */
	DeliveryEndDate = 'delivery_end_date',
	/** column name */
	DeliveryId = 'delivery_id',
	/** column name */
	DeliveryMonth = 'delivery_month',
	/** column name */
	DeliveryStartDate = 'delivery_start_date',
	/** column name */
	FeedPlanId = 'feed_plan_id',
	/** column name */
	FlexFee = 'flex_fee',
	/** column name */
	FuturePrice = 'future_price',
	/** column name */
	FuturesMonth = 'futures_month',
	/** column name */
	HtaFee = 'hta_fee',
	/** column name */
	IsFob = 'is_fob',
	/** column name */
	LocationId = 'location_id',
	/** column name */
	RollFee = 'roll_fee',
	/** column name */
	SalesType = 'sales_type',
	/** column name */
	SellerId = 'seller_id',
	/** column name */
	SpreadGain = 'spread_gain',
}

export type Fills_Updates = {
	/** increments the numeric columns with given value of the filtered values */
	_inc?: InputMaybe<Fills_Inc_Input>;
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Fills_Set_Input>;
	/** filter the rows which have to be updated */
	where: Fills_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Fills_Var_Pop_Fields = {
	__typename?: 'fills_var_pop_fields';
	basis?: Maybe<Scalars['Float']>;
	bushels?: Maybe<Scalars['Float']>;
	buyer_id?: Maybe<Scalars['Float']>;
	delivery_id?: Maybe<Scalars['Float']>;
	fees?: Maybe<Scalars['Float']>;
	flat_price?: Maybe<Scalars['Float']>;
	flex_fee?: Maybe<Scalars['Float']>;
	future_price?: Maybe<Scalars['Float']>;
	hta_fee?: Maybe<Scalars['Float']>;
	id?: Maybe<Scalars['Float']>;
	location_id?: Maybe<Scalars['Float']>;
	production_id?: Maybe<Scalars['Float']>;
	roll_fee?: Maybe<Scalars['Float']>;
	spread_gain?: Maybe<Scalars['Float']>;
	target_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "fills" */
export type Fills_Var_Pop_Order_By = {
	basis?: InputMaybe<Order_By>;
	bushels?: InputMaybe<Order_By>;
	buyer_id?: InputMaybe<Order_By>;
	delivery_id?: InputMaybe<Order_By>;
	fees?: InputMaybe<Order_By>;
	flat_price?: InputMaybe<Order_By>;
	flex_fee?: InputMaybe<Order_By>;
	future_price?: InputMaybe<Order_By>;
	hta_fee?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	location_id?: InputMaybe<Order_By>;
	production_id?: InputMaybe<Order_By>;
	roll_fee?: InputMaybe<Order_By>;
	spread_gain?: InputMaybe<Order_By>;
	target_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Fills_Var_Samp_Fields = {
	__typename?: 'fills_var_samp_fields';
	basis?: Maybe<Scalars['Float']>;
	bushels?: Maybe<Scalars['Float']>;
	buyer_id?: Maybe<Scalars['Float']>;
	delivery_id?: Maybe<Scalars['Float']>;
	fees?: Maybe<Scalars['Float']>;
	flat_price?: Maybe<Scalars['Float']>;
	flex_fee?: Maybe<Scalars['Float']>;
	future_price?: Maybe<Scalars['Float']>;
	hta_fee?: Maybe<Scalars['Float']>;
	id?: Maybe<Scalars['Float']>;
	location_id?: Maybe<Scalars['Float']>;
	production_id?: Maybe<Scalars['Float']>;
	roll_fee?: Maybe<Scalars['Float']>;
	spread_gain?: Maybe<Scalars['Float']>;
	target_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "fills" */
export type Fills_Var_Samp_Order_By = {
	basis?: InputMaybe<Order_By>;
	bushels?: InputMaybe<Order_By>;
	buyer_id?: InputMaybe<Order_By>;
	delivery_id?: InputMaybe<Order_By>;
	fees?: InputMaybe<Order_By>;
	flat_price?: InputMaybe<Order_By>;
	flex_fee?: InputMaybe<Order_By>;
	future_price?: InputMaybe<Order_By>;
	hta_fee?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	location_id?: InputMaybe<Order_By>;
	production_id?: InputMaybe<Order_By>;
	roll_fee?: InputMaybe<Order_By>;
	spread_gain?: InputMaybe<Order_By>;
	target_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Fills_Variance_Fields = {
	__typename?: 'fills_variance_fields';
	basis?: Maybe<Scalars['Float']>;
	bushels?: Maybe<Scalars['Float']>;
	buyer_id?: Maybe<Scalars['Float']>;
	delivery_id?: Maybe<Scalars['Float']>;
	fees?: Maybe<Scalars['Float']>;
	flat_price?: Maybe<Scalars['Float']>;
	flex_fee?: Maybe<Scalars['Float']>;
	future_price?: Maybe<Scalars['Float']>;
	hta_fee?: Maybe<Scalars['Float']>;
	id?: Maybe<Scalars['Float']>;
	location_id?: Maybe<Scalars['Float']>;
	production_id?: Maybe<Scalars['Float']>;
	roll_fee?: Maybe<Scalars['Float']>;
	spread_gain?: Maybe<Scalars['Float']>;
	target_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "fills" */
export type Fills_Variance_Order_By = {
	basis?: InputMaybe<Order_By>;
	bushels?: InputMaybe<Order_By>;
	buyer_id?: InputMaybe<Order_By>;
	delivery_id?: InputMaybe<Order_By>;
	fees?: InputMaybe<Order_By>;
	flat_price?: InputMaybe<Order_By>;
	flex_fee?: InputMaybe<Order_By>;
	future_price?: InputMaybe<Order_By>;
	hta_fee?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	location_id?: InputMaybe<Order_By>;
	production_id?: InputMaybe<Order_By>;
	roll_fee?: InputMaybe<Order_By>;
	spread_gain?: InputMaybe<Order_By>;
	target_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "ingredients" */
export type Ingredients = {
	__typename?: 'ingredients';
	id: Scalars['uuid'];
	name?: Maybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "ingredients". All fields are combined with a logical 'AND'. */
export type Ingredients_Bool_Exp = {
	_and?: InputMaybe<Array<Ingredients_Bool_Exp>>;
	_not?: InputMaybe<Ingredients_Bool_Exp>;
	_or?: InputMaybe<Array<Ingredients_Bool_Exp>>;
	id?: InputMaybe<Uuid_Comparison_Exp>;
	name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "ingredients" */
export enum Ingredients_Constraint {
	/** unique or primary key constraint on columns "id" */
	IngredientsPkey = 'ingredients_pkey',
}

/** input type for inserting data into table "ingredients" */
export type Ingredients_Insert_Input = {
	name?: InputMaybe<Scalars['String']>;
};

/** response of any mutation on the table "ingredients" */
export type Ingredients_Mutation_Response = {
	__typename?: 'ingredients_mutation_response';
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Ingredients>;
};

/** input type for inserting object relation for remote table "ingredients" */
export type Ingredients_Obj_Rel_Insert_Input = {
	data: Ingredients_Insert_Input;
	/** upsert condition */
	on_conflict?: InputMaybe<Ingredients_On_Conflict>;
};

/** on_conflict condition type for table "ingredients" */
export type Ingredients_On_Conflict = {
	constraint: Ingredients_Constraint;
	update_columns?: Array<Ingredients_Update_Column>;
	where?: InputMaybe<Ingredients_Bool_Exp>;
};

/** Ordering options when selecting data from "ingredients". */
export type Ingredients_Order_By = {
	id?: InputMaybe<Order_By>;
	name?: InputMaybe<Order_By>;
};

/** select columns of table "ingredients" */
export enum Ingredients_Select_Column {
	/** column name */
	Id = 'id',
	/** column name */
	Name = 'name',
}

/** Streaming cursor of the table "ingredients" */
export type Ingredients_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Ingredients_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Ingredients_Stream_Cursor_Value_Input = {
	id?: InputMaybe<Scalars['uuid']>;
	name?: InputMaybe<Scalars['String']>;
};

/** placeholder for update columns of table "ingredients" (current role has no relevant permissions) */
export enum Ingredients_Update_Column {
	/** placeholder (do not use) */
	Placeholder = '_PLACEHOLDER',
}

export type Jsonb_Cast_Exp = {
	String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
	_cast?: InputMaybe<Jsonb_Cast_Exp>;
	/** is the column contained in the given json value */
	_contained_in?: InputMaybe<Scalars['jsonb']>;
	/** does the column contain the given json value at the top level */
	_contains?: InputMaybe<Scalars['jsonb']>;
	_eq?: InputMaybe<Scalars['jsonb']>;
	_gt?: InputMaybe<Scalars['jsonb']>;
	_gte?: InputMaybe<Scalars['jsonb']>;
	/** does the string exist as a top-level key in the column */
	_has_key?: InputMaybe<Scalars['String']>;
	/** do all of these strings exist as top-level keys in the column */
	_has_keys_all?: InputMaybe<Array<Scalars['String']>>;
	/** do any of these strings exist as top-level keys in the column */
	_has_keys_any?: InputMaybe<Array<Scalars['String']>>;
	_in?: InputMaybe<Array<Scalars['jsonb']>>;
	_is_null?: InputMaybe<Scalars['Boolean']>;
	_lt?: InputMaybe<Scalars['jsonb']>;
	_lte?: InputMaybe<Scalars['jsonb']>;
	_neq?: InputMaybe<Scalars['jsonb']>;
	_nin?: InputMaybe<Array<Scalars['jsonb']>>;
};

/** columns and relationships of "locations" */
export type Locations = {
	__typename?: 'locations';
	id: Scalars['Int'];
	name?: Maybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "locations". All fields are combined with a logical 'AND'. */
export type Locations_Bool_Exp = {
	_and?: InputMaybe<Array<Locations_Bool_Exp>>;
	_not?: InputMaybe<Locations_Bool_Exp>;
	_or?: InputMaybe<Array<Locations_Bool_Exp>>;
	id?: InputMaybe<Int_Comparison_Exp>;
	name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "locations" */
export enum Locations_Constraint {
	/** unique or primary key constraint on columns "id" */
	LocationsPkey = 'locations_pkey',
}

/** input type for inserting data into table "locations" */
export type Locations_Insert_Input = {
	name?: InputMaybe<Scalars['String']>;
};

/** response of any mutation on the table "locations" */
export type Locations_Mutation_Response = {
	__typename?: 'locations_mutation_response';
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Locations>;
};

/** input type for inserting object relation for remote table "locations" */
export type Locations_Obj_Rel_Insert_Input = {
	data: Locations_Insert_Input;
	/** upsert condition */
	on_conflict?: InputMaybe<Locations_On_Conflict>;
};

/** on_conflict condition type for table "locations" */
export type Locations_On_Conflict = {
	constraint: Locations_Constraint;
	update_columns?: Array<Locations_Update_Column>;
	where?: InputMaybe<Locations_Bool_Exp>;
};

/** Ordering options when selecting data from "locations". */
export type Locations_Order_By = {
	id?: InputMaybe<Order_By>;
	name?: InputMaybe<Order_By>;
};

/** select columns of table "locations" */
export enum Locations_Select_Column {
	/** column name */
	Id = 'id',
	/** column name */
	Name = 'name',
}

/** Streaming cursor of the table "locations" */
export type Locations_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Locations_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Locations_Stream_Cursor_Value_Input = {
	id?: InputMaybe<Scalars['Int']>;
	name?: InputMaybe<Scalars['String']>;
};

/** placeholder for update columns of table "locations" (current role has no relevant permissions) */
export enum Locations_Update_Column {
	/** placeholder (do not use) */
	Placeholder = '_PLACEHOLDER',
}

/** mutation root */
export type Mutation_Root = {
	__typename?: 'mutation_root';
	/** delete data from the table: "advisors" */
	delete_advisors?: Maybe<Advisors_Mutation_Response>;
	/** delete single row from the table: "advisors" */
	delete_advisors_by_pk?: Maybe<Advisors>;
	/** delete data from the table: "buyers" */
	delete_buyers?: Maybe<Buyers_Mutation_Response>;
	/** delete single row from the table: "buyers" */
	delete_buyers_by_pk?: Maybe<Buyers>;
	/** delete data from the table: "feed_plans" */
	delete_feed_plans?: Maybe<Feed_Plans_Mutation_Response>;
	/** delete single row from the table: "feed_plans" */
	delete_feed_plans_by_pk?: Maybe<Feed_Plans>;
	/** delete data from the table: "fills" */
	delete_fills?: Maybe<Fills_Mutation_Response>;
	/** delete single row from the table: "fills" */
	delete_fills_by_pk?: Maybe<Fills>;
	/** delete data from the table: "permission_group_membership" */
	delete_permission_group_membership?: Maybe<Permission_Group_Membership_Mutation_Response>;
	/** delete single row from the table: "permission_group_membership" */
	delete_permission_group_membership_by_pk?: Maybe<Permission_Group_Membership>;
	/** delete data from the table: "permission_groups" */
	delete_permission_groups?: Maybe<Permission_Groups_Mutation_Response>;
	/** delete single row from the table: "permission_groups" */
	delete_permission_groups_by_pk?: Maybe<Permission_Groups>;
	/** delete data from the table: "production" */
	delete_production?: Maybe<Production_Mutation_Response>;
	/** delete single row from the table: "production" */
	delete_production_by_pk?: Maybe<Production>;
	/** delete data from the table: "sellers" */
	delete_sellers?: Maybe<Sellers_Mutation_Response>;
	/** delete single row from the table: "sellers" */
	delete_sellers_by_pk?: Maybe<Sellers>;
	/** delete data from the table: "targets" */
	delete_targets?: Maybe<Targets_Mutation_Response>;
	/** delete single row from the table: "targets" */
	delete_targets_by_pk?: Maybe<Targets>;
	/** insert data into the table: "advisors" */
	insert_advisors?: Maybe<Advisors_Mutation_Response>;
	/** insert a single row into the table: "advisors" */
	insert_advisors_one?: Maybe<Advisors>;
	/** insert data into the table: "buyers" */
	insert_buyers?: Maybe<Buyers_Mutation_Response>;
	/** insert a single row into the table: "buyers" */
	insert_buyers_one?: Maybe<Buyers>;
	/** insert data into the table: "crops" */
	insert_crops?: Maybe<Crops_Mutation_Response>;
	/** insert a single row into the table: "crops" */
	insert_crops_one?: Maybe<Crops>;
	/** insert data into the table: "customers" */
	insert_customers?: Maybe<Customers_Mutation_Response>;
	/** insert a single row into the table: "customers" */
	insert_customers_one?: Maybe<Customers>;
	/** insert data into the table: "feed_plans" */
	insert_feed_plans?: Maybe<Feed_Plans_Mutation_Response>;
	/** insert a single row into the table: "feed_plans" */
	insert_feed_plans_one?: Maybe<Feed_Plans>;
	/** insert data into the table: "fill_modification_requests" */
	insert_fill_modification_requests?: Maybe<Fill_Modification_Requests_Mutation_Response>;
	/** insert a single row into the table: "fill_modification_requests" */
	insert_fill_modification_requests_one?: Maybe<Fill_Modification_Requests>;
	/** insert data into the table: "fill_notes" */
	insert_fill_notes?: Maybe<Fill_Notes_Mutation_Response>;
	/** insert a single row into the table: "fill_notes" */
	insert_fill_notes_one?: Maybe<Fill_Notes>;
	/** insert data into the table: "fills" */
	insert_fills?: Maybe<Fills_Mutation_Response>;
	/** insert a single row into the table: "fills" */
	insert_fills_one?: Maybe<Fills>;
	/** insert data into the table: "ingredients" */
	insert_ingredients?: Maybe<Ingredients_Mutation_Response>;
	/** insert a single row into the table: "ingredients" */
	insert_ingredients_one?: Maybe<Ingredients>;
	/** insert data into the table: "locations" */
	insert_locations?: Maybe<Locations_Mutation_Response>;
	/** insert a single row into the table: "locations" */
	insert_locations_one?: Maybe<Locations>;
	/** insert data into the table: "permission_group_membership" */
	insert_permission_group_membership?: Maybe<Permission_Group_Membership_Mutation_Response>;
	/** insert a single row into the table: "permission_group_membership" */
	insert_permission_group_membership_one?: Maybe<Permission_Group_Membership>;
	/** insert data into the table: "permission_groups" */
	insert_permission_groups?: Maybe<Permission_Groups_Mutation_Response>;
	/** insert a single row into the table: "permission_groups" */
	insert_permission_groups_one?: Maybe<Permission_Groups>;
	/** insert data into the table: "production" */
	insert_production?: Maybe<Production_Mutation_Response>;
	/** insert a single row into the table: "production" */
	insert_production_one?: Maybe<Production>;
	/** insert data into the table: "sellers" */
	insert_sellers?: Maybe<Sellers_Mutation_Response>;
	/** insert a single row into the table: "sellers" */
	insert_sellers_one?: Maybe<Sellers>;
	/** insert data into the table: "target_notes" */
	insert_target_notes?: Maybe<Target_Notes_Mutation_Response>;
	/** insert a single row into the table: "target_notes" */
	insert_target_notes_one?: Maybe<Target_Notes>;
	/** insert data into the table: "target_status_updates" */
	insert_target_status_updates?: Maybe<Target_Status_Updates_Mutation_Response>;
	/** insert a single row into the table: "target_status_updates" */
	insert_target_status_updates_one?: Maybe<Target_Status_Updates>;
	/** insert data into the table: "targets" */
	insert_targets?: Maybe<Targets_Mutation_Response>;
	/** insert a single row into the table: "targets" */
	insert_targets_one?: Maybe<Targets>;
	/** update data of the table: "advisors" */
	update_advisors?: Maybe<Advisors_Mutation_Response>;
	/** update single row of the table: "advisors" */
	update_advisors_by_pk?: Maybe<Advisors>;
	/** update multiples rows of table: "advisors" */
	update_advisors_many?: Maybe<Array<Maybe<Advisors_Mutation_Response>>>;
	/** update data of the table: "buyers" */
	update_buyers?: Maybe<Buyers_Mutation_Response>;
	/** update single row of the table: "buyers" */
	update_buyers_by_pk?: Maybe<Buyers>;
	/** update multiples rows of table: "buyers" */
	update_buyers_many?: Maybe<Array<Maybe<Buyers_Mutation_Response>>>;
	/** update data of the table: "customers" */
	update_customers?: Maybe<Customers_Mutation_Response>;
	/** update single row of the table: "customers" */
	update_customers_by_pk?: Maybe<Customers>;
	/** update multiples rows of table: "customers" */
	update_customers_many?: Maybe<Array<Maybe<Customers_Mutation_Response>>>;
	/** update data of the table: "feed_plans" */
	update_feed_plans?: Maybe<Feed_Plans_Mutation_Response>;
	/** update single row of the table: "feed_plans" */
	update_feed_plans_by_pk?: Maybe<Feed_Plans>;
	/** update multiples rows of table: "feed_plans" */
	update_feed_plans_many?: Maybe<Array<Maybe<Feed_Plans_Mutation_Response>>>;
	/** update data of the table: "fill_modification_requests" */
	update_fill_modification_requests?: Maybe<Fill_Modification_Requests_Mutation_Response>;
	/** update single row of the table: "fill_modification_requests" */
	update_fill_modification_requests_by_pk?: Maybe<Fill_Modification_Requests>;
	/** update multiples rows of table: "fill_modification_requests" */
	update_fill_modification_requests_many?: Maybe<Array<Maybe<Fill_Modification_Requests_Mutation_Response>>>;
	/** update data of the table: "fills" */
	update_fills?: Maybe<Fills_Mutation_Response>;
	/** update single row of the table: "fills" */
	update_fills_by_pk?: Maybe<Fills>;
	/** update multiples rows of table: "fills" */
	update_fills_many?: Maybe<Array<Maybe<Fills_Mutation_Response>>>;
	/** update data of the table: "permission_groups" */
	update_permission_groups?: Maybe<Permission_Groups_Mutation_Response>;
	/** update single row of the table: "permission_groups" */
	update_permission_groups_by_pk?: Maybe<Permission_Groups>;
	/** update multiples rows of table: "permission_groups" */
	update_permission_groups_many?: Maybe<Array<Maybe<Permission_Groups_Mutation_Response>>>;
	/** update data of the table: "production" */
	update_production?: Maybe<Production_Mutation_Response>;
	/** update single row of the table: "production" */
	update_production_by_pk?: Maybe<Production>;
	/** update multiples rows of table: "production" */
	update_production_many?: Maybe<Array<Maybe<Production_Mutation_Response>>>;
	/** update data of the table: "sellers" */
	update_sellers?: Maybe<Sellers_Mutation_Response>;
	/** update single row of the table: "sellers" */
	update_sellers_by_pk?: Maybe<Sellers>;
	/** update multiples rows of table: "sellers" */
	update_sellers_many?: Maybe<Array<Maybe<Sellers_Mutation_Response>>>;
	/** update data of the table: "targets" */
	update_targets?: Maybe<Targets_Mutation_Response>;
	/** update single row of the table: "targets" */
	update_targets_by_pk?: Maybe<Targets>;
	/** update multiples rows of table: "targets" */
	update_targets_many?: Maybe<Array<Maybe<Targets_Mutation_Response>>>;
};

/** mutation root */
export type Mutation_RootDelete_AdvisorsArgs = {
	where: Advisors_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Advisors_By_PkArgs = {
	id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_BuyersArgs = {
	where: Buyers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Buyers_By_PkArgs = {
	id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Feed_PlansArgs = {
	where: Feed_Plans_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Feed_Plans_By_PkArgs = {
	id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_FillsArgs = {
	where: Fills_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Fills_By_PkArgs = {
	id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_Permission_Group_MembershipArgs = {
	where: Permission_Group_Membership_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Permission_Group_Membership_By_PkArgs = {
	id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_Permission_GroupsArgs = {
	where: Permission_Groups_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Permission_Groups_By_PkArgs = {
	id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_ProductionArgs = {
	where: Production_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Production_By_PkArgs = {
	id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootDelete_SellersArgs = {
	where: Sellers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Sellers_By_PkArgs = {
	id: Scalars['uuid'];
};

/** mutation root */
export type Mutation_RootDelete_TargetsArgs = {
	where: Targets_Bool_Exp;
};

/** mutation root */
export type Mutation_RootDelete_Targets_By_PkArgs = {
	id: Scalars['Int'];
};

/** mutation root */
export type Mutation_RootInsert_AdvisorsArgs = {
	objects: Array<Advisors_Insert_Input>;
	on_conflict?: InputMaybe<Advisors_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Advisors_OneArgs = {
	object: Advisors_Insert_Input;
	on_conflict?: InputMaybe<Advisors_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_BuyersArgs = {
	objects: Array<Buyers_Insert_Input>;
	on_conflict?: InputMaybe<Buyers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Buyers_OneArgs = {
	object: Buyers_Insert_Input;
	on_conflict?: InputMaybe<Buyers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_CropsArgs = {
	objects: Array<Crops_Insert_Input>;
	on_conflict?: InputMaybe<Crops_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Crops_OneArgs = {
	object: Crops_Insert_Input;
	on_conflict?: InputMaybe<Crops_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_CustomersArgs = {
	objects: Array<Customers_Insert_Input>;
	on_conflict?: InputMaybe<Customers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Customers_OneArgs = {
	object: Customers_Insert_Input;
	on_conflict?: InputMaybe<Customers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Feed_PlansArgs = {
	objects: Array<Feed_Plans_Insert_Input>;
	on_conflict?: InputMaybe<Feed_Plans_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Feed_Plans_OneArgs = {
	object: Feed_Plans_Insert_Input;
	on_conflict?: InputMaybe<Feed_Plans_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Fill_Modification_RequestsArgs = {
	objects: Array<Fill_Modification_Requests_Insert_Input>;
	on_conflict?: InputMaybe<Fill_Modification_Requests_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Fill_Modification_Requests_OneArgs = {
	object: Fill_Modification_Requests_Insert_Input;
	on_conflict?: InputMaybe<Fill_Modification_Requests_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Fill_NotesArgs = {
	objects: Array<Fill_Notes_Insert_Input>;
	on_conflict?: InputMaybe<Fill_Notes_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Fill_Notes_OneArgs = {
	object: Fill_Notes_Insert_Input;
	on_conflict?: InputMaybe<Fill_Notes_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_FillsArgs = {
	objects: Array<Fills_Insert_Input>;
	on_conflict?: InputMaybe<Fills_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Fills_OneArgs = {
	object: Fills_Insert_Input;
	on_conflict?: InputMaybe<Fills_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_IngredientsArgs = {
	objects: Array<Ingredients_Insert_Input>;
	on_conflict?: InputMaybe<Ingredients_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Ingredients_OneArgs = {
	object: Ingredients_Insert_Input;
	on_conflict?: InputMaybe<Ingredients_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_LocationsArgs = {
	objects: Array<Locations_Insert_Input>;
	on_conflict?: InputMaybe<Locations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Locations_OneArgs = {
	object: Locations_Insert_Input;
	on_conflict?: InputMaybe<Locations_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Permission_Group_MembershipArgs = {
	objects: Array<Permission_Group_Membership_Insert_Input>;
	on_conflict?: InputMaybe<Permission_Group_Membership_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Permission_Group_Membership_OneArgs = {
	object: Permission_Group_Membership_Insert_Input;
	on_conflict?: InputMaybe<Permission_Group_Membership_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Permission_GroupsArgs = {
	objects: Array<Permission_Groups_Insert_Input>;
	on_conflict?: InputMaybe<Permission_Groups_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Permission_Groups_OneArgs = {
	object: Permission_Groups_Insert_Input;
	on_conflict?: InputMaybe<Permission_Groups_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_ProductionArgs = {
	objects: Array<Production_Insert_Input>;
	on_conflict?: InputMaybe<Production_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Production_OneArgs = {
	object: Production_Insert_Input;
	on_conflict?: InputMaybe<Production_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_SellersArgs = {
	objects: Array<Sellers_Insert_Input>;
	on_conflict?: InputMaybe<Sellers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Sellers_OneArgs = {
	object: Sellers_Insert_Input;
	on_conflict?: InputMaybe<Sellers_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Target_NotesArgs = {
	objects: Array<Target_Notes_Insert_Input>;
	on_conflict?: InputMaybe<Target_Notes_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Target_Notes_OneArgs = {
	object: Target_Notes_Insert_Input;
	on_conflict?: InputMaybe<Target_Notes_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Target_Status_UpdatesArgs = {
	objects: Array<Target_Status_Updates_Insert_Input>;
	on_conflict?: InputMaybe<Target_Status_Updates_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Target_Status_Updates_OneArgs = {
	object: Target_Status_Updates_Insert_Input;
	on_conflict?: InputMaybe<Target_Status_Updates_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_TargetsArgs = {
	objects: Array<Targets_Insert_Input>;
	on_conflict?: InputMaybe<Targets_On_Conflict>;
};

/** mutation root */
export type Mutation_RootInsert_Targets_OneArgs = {
	object: Targets_Insert_Input;
	on_conflict?: InputMaybe<Targets_On_Conflict>;
};

/** mutation root */
export type Mutation_RootUpdate_AdvisorsArgs = {
	_set?: InputMaybe<Advisors_Set_Input>;
	where: Advisors_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Advisors_By_PkArgs = {
	_set?: InputMaybe<Advisors_Set_Input>;
	pk_columns: Advisors_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Advisors_ManyArgs = {
	updates: Array<Advisors_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_BuyersArgs = {
	_set?: InputMaybe<Buyers_Set_Input>;
	where: Buyers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Buyers_By_PkArgs = {
	_set?: InputMaybe<Buyers_Set_Input>;
	pk_columns: Buyers_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Buyers_ManyArgs = {
	updates: Array<Buyers_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_CustomersArgs = {
	_inc?: InputMaybe<Customers_Inc_Input>;
	_set?: InputMaybe<Customers_Set_Input>;
	where: Customers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Customers_By_PkArgs = {
	_inc?: InputMaybe<Customers_Inc_Input>;
	_set?: InputMaybe<Customers_Set_Input>;
	pk_columns: Customers_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Customers_ManyArgs = {
	updates: Array<Customers_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Feed_PlansArgs = {
	_inc?: InputMaybe<Feed_Plans_Inc_Input>;
	_set?: InputMaybe<Feed_Plans_Set_Input>;
	where: Feed_Plans_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Feed_Plans_By_PkArgs = {
	_inc?: InputMaybe<Feed_Plans_Inc_Input>;
	_set?: InputMaybe<Feed_Plans_Set_Input>;
	pk_columns: Feed_Plans_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Feed_Plans_ManyArgs = {
	updates: Array<Feed_Plans_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Fill_Modification_RequestsArgs = {
	_inc?: InputMaybe<Fill_Modification_Requests_Inc_Input>;
	_set?: InputMaybe<Fill_Modification_Requests_Set_Input>;
	where: Fill_Modification_Requests_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Fill_Modification_Requests_By_PkArgs = {
	_inc?: InputMaybe<Fill_Modification_Requests_Inc_Input>;
	_set?: InputMaybe<Fill_Modification_Requests_Set_Input>;
	pk_columns: Fill_Modification_Requests_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Fill_Modification_Requests_ManyArgs = {
	updates: Array<Fill_Modification_Requests_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_FillsArgs = {
	_inc?: InputMaybe<Fills_Inc_Input>;
	_set?: InputMaybe<Fills_Set_Input>;
	where: Fills_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Fills_By_PkArgs = {
	_inc?: InputMaybe<Fills_Inc_Input>;
	_set?: InputMaybe<Fills_Set_Input>;
	pk_columns: Fills_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Fills_ManyArgs = {
	updates: Array<Fills_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_Permission_GroupsArgs = {
	_set?: InputMaybe<Permission_Groups_Set_Input>;
	where: Permission_Groups_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Permission_Groups_By_PkArgs = {
	_set?: InputMaybe<Permission_Groups_Set_Input>;
	pk_columns: Permission_Groups_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Permission_Groups_ManyArgs = {
	updates: Array<Permission_Groups_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_ProductionArgs = {
	_inc?: InputMaybe<Production_Inc_Input>;
	_set?: InputMaybe<Production_Set_Input>;
	where: Production_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Production_By_PkArgs = {
	_inc?: InputMaybe<Production_Inc_Input>;
	_set?: InputMaybe<Production_Set_Input>;
	pk_columns: Production_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Production_ManyArgs = {
	updates: Array<Production_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_SellersArgs = {
	_set?: InputMaybe<Sellers_Set_Input>;
	where: Sellers_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Sellers_By_PkArgs = {
	_set?: InputMaybe<Sellers_Set_Input>;
	pk_columns: Sellers_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Sellers_ManyArgs = {
	updates: Array<Sellers_Updates>;
};

/** mutation root */
export type Mutation_RootUpdate_TargetsArgs = {
	_inc?: InputMaybe<Targets_Inc_Input>;
	_set?: InputMaybe<Targets_Set_Input>;
	where: Targets_Bool_Exp;
};

/** mutation root */
export type Mutation_RootUpdate_Targets_By_PkArgs = {
	_inc?: InputMaybe<Targets_Inc_Input>;
	_set?: InputMaybe<Targets_Set_Input>;
	pk_columns: Targets_Pk_Columns_Input;
};

/** mutation root */
export type Mutation_RootUpdate_Targets_ManyArgs = {
	updates: Array<Targets_Updates>;
};

/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type Numeric_Comparison_Exp = {
	_eq?: InputMaybe<Scalars['numeric']>;
	_gt?: InputMaybe<Scalars['numeric']>;
	_gte?: InputMaybe<Scalars['numeric']>;
	_in?: InputMaybe<Array<Scalars['numeric']>>;
	_is_null?: InputMaybe<Scalars['Boolean']>;
	_lt?: InputMaybe<Scalars['numeric']>;
	_lte?: InputMaybe<Scalars['numeric']>;
	_neq?: InputMaybe<Scalars['numeric']>;
	_nin?: InputMaybe<Array<Scalars['numeric']>>;
};

/** Boolean expression to compare columns of type "operation_type". All fields are combined with logical 'AND'. */
export type Operation_Type_Comparison_Exp = {
	_eq?: InputMaybe<Scalars['operation_type']>;
	_gt?: InputMaybe<Scalars['operation_type']>;
	_gte?: InputMaybe<Scalars['operation_type']>;
	_in?: InputMaybe<Array<Scalars['operation_type']>>;
	_is_null?: InputMaybe<Scalars['Boolean']>;
	_lt?: InputMaybe<Scalars['operation_type']>;
	_lte?: InputMaybe<Scalars['operation_type']>;
	_neq?: InputMaybe<Scalars['operation_type']>;
	_nin?: InputMaybe<Array<Scalars['operation_type']>>;
};

/** columns and relationships of "order_activity" */
export type Order_Activity = {
	__typename?: 'order_activity';
	data: Scalars['jsonb'];
	/** An object relationship */
	fill?: Maybe<Fills>;
	fill_id?: Maybe<Scalars['Int']>;
	id: Scalars['uuid'];
	operation: Scalars['operation_type'];
	performed_at: Scalars['timestamptz'];
	performed_by?: Maybe<Scalars['String']>;
	performed_by_id?: Maybe<Scalars['uuid']>;
	source_table: Scalars['String'];
	/** An object relationship */
	target?: Maybe<Targets>;
	target_id?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "order_activity" */
export type Order_ActivityDataArgs = {
	path?: InputMaybe<Scalars['String']>;
};

/** order by aggregate values of table "order_activity" */
export type Order_Activity_Aggregate_Order_By = {
	avg?: InputMaybe<Order_Activity_Avg_Order_By>;
	count?: InputMaybe<Order_By>;
	max?: InputMaybe<Order_Activity_Max_Order_By>;
	min?: InputMaybe<Order_Activity_Min_Order_By>;
	stddev?: InputMaybe<Order_Activity_Stddev_Order_By>;
	stddev_pop?: InputMaybe<Order_Activity_Stddev_Pop_Order_By>;
	stddev_samp?: InputMaybe<Order_Activity_Stddev_Samp_Order_By>;
	sum?: InputMaybe<Order_Activity_Sum_Order_By>;
	var_pop?: InputMaybe<Order_Activity_Var_Pop_Order_By>;
	var_samp?: InputMaybe<Order_Activity_Var_Samp_Order_By>;
	variance?: InputMaybe<Order_Activity_Variance_Order_By>;
};

/** order by avg() on columns of table "order_activity" */
export type Order_Activity_Avg_Order_By = {
	fill_id?: InputMaybe<Order_By>;
	target_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "order_activity". All fields are combined with a logical 'AND'. */
export type Order_Activity_Bool_Exp = {
	_and?: InputMaybe<Array<Order_Activity_Bool_Exp>>;
	_not?: InputMaybe<Order_Activity_Bool_Exp>;
	_or?: InputMaybe<Array<Order_Activity_Bool_Exp>>;
	data?: InputMaybe<Jsonb_Comparison_Exp>;
	fill?: InputMaybe<Fills_Bool_Exp>;
	fill_id?: InputMaybe<Int_Comparison_Exp>;
	id?: InputMaybe<Uuid_Comparison_Exp>;
	operation?: InputMaybe<Operation_Type_Comparison_Exp>;
	performed_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	performed_by?: InputMaybe<String_Comparison_Exp>;
	performed_by_id?: InputMaybe<Uuid_Comparison_Exp>;
	source_table?: InputMaybe<String_Comparison_Exp>;
	target?: InputMaybe<Targets_Bool_Exp>;
	target_id?: InputMaybe<Int_Comparison_Exp>;
};

/** order by max() on columns of table "order_activity" */
export type Order_Activity_Max_Order_By = {
	fill_id?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	operation?: InputMaybe<Order_By>;
	performed_at?: InputMaybe<Order_By>;
	performed_by?: InputMaybe<Order_By>;
	performed_by_id?: InputMaybe<Order_By>;
	source_table?: InputMaybe<Order_By>;
	target_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "order_activity" */
export type Order_Activity_Min_Order_By = {
	fill_id?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	operation?: InputMaybe<Order_By>;
	performed_at?: InputMaybe<Order_By>;
	performed_by?: InputMaybe<Order_By>;
	performed_by_id?: InputMaybe<Order_By>;
	source_table?: InputMaybe<Order_By>;
	target_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "order_activity". */
export type Order_Activity_Order_By = {
	data?: InputMaybe<Order_By>;
	fill?: InputMaybe<Fills_Order_By>;
	fill_id?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	operation?: InputMaybe<Order_By>;
	performed_at?: InputMaybe<Order_By>;
	performed_by?: InputMaybe<Order_By>;
	performed_by_id?: InputMaybe<Order_By>;
	source_table?: InputMaybe<Order_By>;
	target?: InputMaybe<Targets_Order_By>;
	target_id?: InputMaybe<Order_By>;
};

/** select columns of table "order_activity" */
export enum Order_Activity_Select_Column {
	/** column name */
	Data = 'data',
	/** column name */
	FillId = 'fill_id',
	/** column name */
	Id = 'id',
	/** column name */
	Operation = 'operation',
	/** column name */
	PerformedAt = 'performed_at',
	/** column name */
	PerformedBy = 'performed_by',
	/** column name */
	PerformedById = 'performed_by_id',
	/** column name */
	SourceTable = 'source_table',
	/** column name */
	TargetId = 'target_id',
}

/** order by stddev() on columns of table "order_activity" */
export type Order_Activity_Stddev_Order_By = {
	fill_id?: InputMaybe<Order_By>;
	target_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "order_activity" */
export type Order_Activity_Stddev_Pop_Order_By = {
	fill_id?: InputMaybe<Order_By>;
	target_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "order_activity" */
export type Order_Activity_Stddev_Samp_Order_By = {
	fill_id?: InputMaybe<Order_By>;
	target_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "order_activity" */
export type Order_Activity_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Order_Activity_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Order_Activity_Stream_Cursor_Value_Input = {
	data?: InputMaybe<Scalars['jsonb']>;
	fill_id?: InputMaybe<Scalars['Int']>;
	id?: InputMaybe<Scalars['uuid']>;
	operation?: InputMaybe<Scalars['operation_type']>;
	performed_at?: InputMaybe<Scalars['timestamptz']>;
	performed_by?: InputMaybe<Scalars['String']>;
	performed_by_id?: InputMaybe<Scalars['uuid']>;
	source_table?: InputMaybe<Scalars['String']>;
	target_id?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "order_activity" */
export type Order_Activity_Sum_Order_By = {
	fill_id?: InputMaybe<Order_By>;
	target_id?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "order_activity" */
export type Order_Activity_Var_Pop_Order_By = {
	fill_id?: InputMaybe<Order_By>;
	target_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "order_activity" */
export type Order_Activity_Var_Samp_Order_By = {
	fill_id?: InputMaybe<Order_By>;
	target_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "order_activity" */
export type Order_Activity_Variance_Order_By = {
	fill_id?: InputMaybe<Order_By>;
	target_id?: InputMaybe<Order_By>;
};

/** column ordering options */
export enum Order_By {
	/** in ascending order, nulls last */
	Asc = 'asc',
	/** in ascending order, nulls first */
	AscNullsFirst = 'asc_nulls_first',
	/** in ascending order, nulls last */
	AscNullsLast = 'asc_nulls_last',
	/** in descending order, nulls first */
	Desc = 'desc',
	/** in descending order, nulls first */
	DescNullsFirst = 'desc_nulls_first',
	/** in descending order, nulls last */
	DescNullsLast = 'desc_nulls_last',
}

/** columns and relationships of "permission_group_membership" */
export type Permission_Group_Membership = {
	__typename?: 'permission_group_membership';
	/** An object relationship */
	advisor?: Maybe<Advisors>;
	advisor_id?: Maybe<Scalars['Int']>;
	id: Scalars['uuid'];
	/** An object relationship */
	permission_group?: Maybe<Permission_Groups>;
	permission_group_id?: Maybe<Scalars['uuid']>;
};

/** order by aggregate values of table "permission_group_membership" */
export type Permission_Group_Membership_Aggregate_Order_By = {
	avg?: InputMaybe<Permission_Group_Membership_Avg_Order_By>;
	count?: InputMaybe<Order_By>;
	max?: InputMaybe<Permission_Group_Membership_Max_Order_By>;
	min?: InputMaybe<Permission_Group_Membership_Min_Order_By>;
	stddev?: InputMaybe<Permission_Group_Membership_Stddev_Order_By>;
	stddev_pop?: InputMaybe<Permission_Group_Membership_Stddev_Pop_Order_By>;
	stddev_samp?: InputMaybe<Permission_Group_Membership_Stddev_Samp_Order_By>;
	sum?: InputMaybe<Permission_Group_Membership_Sum_Order_By>;
	var_pop?: InputMaybe<Permission_Group_Membership_Var_Pop_Order_By>;
	var_samp?: InputMaybe<Permission_Group_Membership_Var_Samp_Order_By>;
	variance?: InputMaybe<Permission_Group_Membership_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "permission_group_membership" */
export type Permission_Group_Membership_Arr_Rel_Insert_Input = {
	data: Array<Permission_Group_Membership_Insert_Input>;
	/** upsert condition */
	on_conflict?: InputMaybe<Permission_Group_Membership_On_Conflict>;
};

/** order by avg() on columns of table "permission_group_membership" */
export type Permission_Group_Membership_Avg_Order_By = {
	advisor_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "permission_group_membership". All fields are combined with a logical 'AND'. */
export type Permission_Group_Membership_Bool_Exp = {
	_and?: InputMaybe<Array<Permission_Group_Membership_Bool_Exp>>;
	_not?: InputMaybe<Permission_Group_Membership_Bool_Exp>;
	_or?: InputMaybe<Array<Permission_Group_Membership_Bool_Exp>>;
	advisor?: InputMaybe<Advisors_Bool_Exp>;
	advisor_id?: InputMaybe<Int_Comparison_Exp>;
	id?: InputMaybe<Uuid_Comparison_Exp>;
	permission_group?: InputMaybe<Permission_Groups_Bool_Exp>;
	permission_group_id?: InputMaybe<Uuid_Comparison_Exp>;
};

/** unique or primary key constraints on table "permission_group_membership" */
export enum Permission_Group_Membership_Constraint {
	/** unique or primary key constraint on columns "id" */
	PermissionGroupMembershipPkey = 'permission_group_membership_pkey',
}

/** input type for inserting data into table "permission_group_membership" */
export type Permission_Group_Membership_Insert_Input = {
	advisor?: InputMaybe<Advisors_Obj_Rel_Insert_Input>;
	advisor_id?: InputMaybe<Scalars['Int']>;
	id?: InputMaybe<Scalars['uuid']>;
	permission_group?: InputMaybe<Permission_Groups_Obj_Rel_Insert_Input>;
	permission_group_id?: InputMaybe<Scalars['uuid']>;
};

/** order by max() on columns of table "permission_group_membership" */
export type Permission_Group_Membership_Max_Order_By = {
	advisor_id?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	permission_group_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "permission_group_membership" */
export type Permission_Group_Membership_Min_Order_By = {
	advisor_id?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	permission_group_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "permission_group_membership" */
export type Permission_Group_Membership_Mutation_Response = {
	__typename?: 'permission_group_membership_mutation_response';
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Permission_Group_Membership>;
};

/** on_conflict condition type for table "permission_group_membership" */
export type Permission_Group_Membership_On_Conflict = {
	constraint: Permission_Group_Membership_Constraint;
	update_columns?: Array<Permission_Group_Membership_Update_Column>;
	where?: InputMaybe<Permission_Group_Membership_Bool_Exp>;
};

/** Ordering options when selecting data from "permission_group_membership". */
export type Permission_Group_Membership_Order_By = {
	advisor?: InputMaybe<Advisors_Order_By>;
	advisor_id?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	permission_group?: InputMaybe<Permission_Groups_Order_By>;
	permission_group_id?: InputMaybe<Order_By>;
};

/** select columns of table "permission_group_membership" */
export enum Permission_Group_Membership_Select_Column {
	/** column name */
	AdvisorId = 'advisor_id',
	/** column name */
	Id = 'id',
	/** column name */
	PermissionGroupId = 'permission_group_id',
}

/** order by stddev() on columns of table "permission_group_membership" */
export type Permission_Group_Membership_Stddev_Order_By = {
	advisor_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "permission_group_membership" */
export type Permission_Group_Membership_Stddev_Pop_Order_By = {
	advisor_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "permission_group_membership" */
export type Permission_Group_Membership_Stddev_Samp_Order_By = {
	advisor_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "permission_group_membership" */
export type Permission_Group_Membership_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Permission_Group_Membership_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Permission_Group_Membership_Stream_Cursor_Value_Input = {
	advisor_id?: InputMaybe<Scalars['Int']>;
	id?: InputMaybe<Scalars['uuid']>;
	permission_group_id?: InputMaybe<Scalars['uuid']>;
};

/** order by sum() on columns of table "permission_group_membership" */
export type Permission_Group_Membership_Sum_Order_By = {
	advisor_id?: InputMaybe<Order_By>;
};

/** placeholder for update columns of table "permission_group_membership" (current role has no relevant permissions) */
export enum Permission_Group_Membership_Update_Column {
	/** placeholder (do not use) */
	Placeholder = '_PLACEHOLDER',
}

/** order by var_pop() on columns of table "permission_group_membership" */
export type Permission_Group_Membership_Var_Pop_Order_By = {
	advisor_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "permission_group_membership" */
export type Permission_Group_Membership_Var_Samp_Order_By = {
	advisor_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "permission_group_membership" */
export type Permission_Group_Membership_Variance_Order_By = {
	advisor_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "permission_groups" */
export type Permission_Groups = {
	__typename?: 'permission_groups';
	/** An array relationship */
	customers: Array<Customers>;
	/** An aggregate relationship */
	customers_aggregate: Customers_Aggregate;
	id: Scalars['uuid'];
	name?: Maybe<Scalars['String']>;
	/** An array relationship */
	permission_group_memberships: Array<Permission_Group_Membership>;
};

/** columns and relationships of "permission_groups" */
export type Permission_GroupsCustomersArgs = {
	distinct_on?: InputMaybe<Array<Customers_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Customers_Order_By>>;
	where?: InputMaybe<Customers_Bool_Exp>;
};

/** columns and relationships of "permission_groups" */
export type Permission_GroupsCustomers_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Customers_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Customers_Order_By>>;
	where?: InputMaybe<Customers_Bool_Exp>;
};

/** columns and relationships of "permission_groups" */
export type Permission_GroupsPermission_Group_MembershipsArgs = {
	distinct_on?: InputMaybe<Array<Permission_Group_Membership_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Permission_Group_Membership_Order_By>>;
	where?: InputMaybe<Permission_Group_Membership_Bool_Exp>;
};

/** Boolean expression to filter rows from the table "permission_groups". All fields are combined with a logical 'AND'. */
export type Permission_Groups_Bool_Exp = {
	_and?: InputMaybe<Array<Permission_Groups_Bool_Exp>>;
	_not?: InputMaybe<Permission_Groups_Bool_Exp>;
	_or?: InputMaybe<Array<Permission_Groups_Bool_Exp>>;
	customers?: InputMaybe<Customers_Bool_Exp>;
	customers_aggregate?: InputMaybe<Customers_Aggregate_Bool_Exp>;
	id?: InputMaybe<Uuid_Comparison_Exp>;
	name?: InputMaybe<String_Comparison_Exp>;
	permission_group_memberships?: InputMaybe<Permission_Group_Membership_Bool_Exp>;
};

/** unique or primary key constraints on table "permission_groups" */
export enum Permission_Groups_Constraint {
	/** unique or primary key constraint on columns "id" */
	PermissionGroupsPkey = 'permission_groups_pkey',
}

/** input type for inserting data into table "permission_groups" */
export type Permission_Groups_Insert_Input = {
	customers?: InputMaybe<Customers_Arr_Rel_Insert_Input>;
	id?: InputMaybe<Scalars['uuid']>;
	name?: InputMaybe<Scalars['String']>;
	permission_group_memberships?: InputMaybe<Permission_Group_Membership_Arr_Rel_Insert_Input>;
};

/** response of any mutation on the table "permission_groups" */
export type Permission_Groups_Mutation_Response = {
	__typename?: 'permission_groups_mutation_response';
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Permission_Groups>;
};

/** input type for inserting object relation for remote table "permission_groups" */
export type Permission_Groups_Obj_Rel_Insert_Input = {
	data: Permission_Groups_Insert_Input;
	/** upsert condition */
	on_conflict?: InputMaybe<Permission_Groups_On_Conflict>;
};

/** on_conflict condition type for table "permission_groups" */
export type Permission_Groups_On_Conflict = {
	constraint: Permission_Groups_Constraint;
	update_columns?: Array<Permission_Groups_Update_Column>;
	where?: InputMaybe<Permission_Groups_Bool_Exp>;
};

/** Ordering options when selecting data from "permission_groups". */
export type Permission_Groups_Order_By = {
	customers_aggregate?: InputMaybe<Customers_Aggregate_Order_By>;
	id?: InputMaybe<Order_By>;
	name?: InputMaybe<Order_By>;
	permission_group_memberships_aggregate?: InputMaybe<Permission_Group_Membership_Aggregate_Order_By>;
};

/** primary key columns input for table: permission_groups */
export type Permission_Groups_Pk_Columns_Input = {
	id: Scalars['uuid'];
};

/** select columns of table "permission_groups" */
export enum Permission_Groups_Select_Column {
	/** column name */
	Id = 'id',
	/** column name */
	Name = 'name',
}

/** input type for updating data in table "permission_groups" */
export type Permission_Groups_Set_Input = {
	name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "permission_groups" */
export type Permission_Groups_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Permission_Groups_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Permission_Groups_Stream_Cursor_Value_Input = {
	id?: InputMaybe<Scalars['uuid']>;
	name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "permission_groups" */
export enum Permission_Groups_Update_Column {
	/** column name */
	Name = 'name',
}

export type Permission_Groups_Updates = {
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Permission_Groups_Set_Input>;
	/** filter the rows which have to be updated */
	where: Permission_Groups_Bool_Exp;
};

/** columns and relationships of "production" */
export type Production = {
	__typename?: 'production';
	/** An object relationship */
	Crop?: Maybe<Crops>;
	/** An object relationship */
	CropYear?: Maybe<Crop_Years>;
	/** An object relationship */
	Customer?: Maybe<Customers>;
	/** An array relationship */
	Fills: Array<Fills>;
	/** An aggregate relationship */
	Fills_aggregate: Fills_Aggregate;
	/** An array relationship */
	Targets: Array<Targets>;
	/** An aggregate relationship */
	Targets_aggregate: Targets_Aggregate;
	acres?: Maybe<Scalars['numeric']>;
	aph?: Maybe<Scalars['numeric']>;
	breakeven?: Maybe<Scalars['numeric']>;
	crop_id?: Maybe<Scalars['Int']>;
	customer_id?: Maybe<Scalars['Int']>;
	enrolled_percent?: Maybe<Scalars['numeric']>;
	goal?: Maybe<Scalars['numeric']>;
	harvest_bu?: Maybe<Scalars['numeric']>;
	id: Scalars['Int'];
	storage_bu?: Maybe<Scalars['numeric']>;
	year_id?: Maybe<Scalars['Int']>;
};

/** columns and relationships of "production" */
export type ProductionFillsArgs = {
	distinct_on?: InputMaybe<Array<Fills_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Fills_Order_By>>;
	where?: InputMaybe<Fills_Bool_Exp>;
};

/** columns and relationships of "production" */
export type ProductionFills_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Fills_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Fills_Order_By>>;
	where?: InputMaybe<Fills_Bool_Exp>;
};

/** columns and relationships of "production" */
export type ProductionTargetsArgs = {
	distinct_on?: InputMaybe<Array<Targets_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Targets_Order_By>>;
	where?: InputMaybe<Targets_Bool_Exp>;
};

/** columns and relationships of "production" */
export type ProductionTargets_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Targets_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Targets_Order_By>>;
	where?: InputMaybe<Targets_Bool_Exp>;
};

/** aggregated selection of "production" */
export type Production_Aggregate = {
	__typename?: 'production_aggregate';
	aggregate?: Maybe<Production_Aggregate_Fields>;
	nodes: Array<Production>;
};

export type Production_Aggregate_Bool_Exp = {
	count?: InputMaybe<Production_Aggregate_Bool_Exp_Count>;
};

export type Production_Aggregate_Bool_Exp_Count = {
	arguments?: InputMaybe<Array<Production_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Production_Bool_Exp>;
	predicate: Int_Comparison_Exp;
};

/** aggregate fields of "production" */
export type Production_Aggregate_Fields = {
	__typename?: 'production_aggregate_fields';
	avg?: Maybe<Production_Avg_Fields>;
	count: Scalars['Int'];
	max?: Maybe<Production_Max_Fields>;
	min?: Maybe<Production_Min_Fields>;
	stddev?: Maybe<Production_Stddev_Fields>;
	stddev_pop?: Maybe<Production_Stddev_Pop_Fields>;
	stddev_samp?: Maybe<Production_Stddev_Samp_Fields>;
	sum?: Maybe<Production_Sum_Fields>;
	var_pop?: Maybe<Production_Var_Pop_Fields>;
	var_samp?: Maybe<Production_Var_Samp_Fields>;
	variance?: Maybe<Production_Variance_Fields>;
};

/** aggregate fields of "production" */
export type Production_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Production_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "production" */
export type Production_Aggregate_Order_By = {
	avg?: InputMaybe<Production_Avg_Order_By>;
	count?: InputMaybe<Order_By>;
	max?: InputMaybe<Production_Max_Order_By>;
	min?: InputMaybe<Production_Min_Order_By>;
	stddev?: InputMaybe<Production_Stddev_Order_By>;
	stddev_pop?: InputMaybe<Production_Stddev_Pop_Order_By>;
	stddev_samp?: InputMaybe<Production_Stddev_Samp_Order_By>;
	sum?: InputMaybe<Production_Sum_Order_By>;
	var_pop?: InputMaybe<Production_Var_Pop_Order_By>;
	var_samp?: InputMaybe<Production_Var_Samp_Order_By>;
	variance?: InputMaybe<Production_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "production" */
export type Production_Arr_Rel_Insert_Input = {
	data: Array<Production_Insert_Input>;
	/** upsert condition */
	on_conflict?: InputMaybe<Production_On_Conflict>;
};

/** aggregate avg on columns */
export type Production_Avg_Fields = {
	__typename?: 'production_avg_fields';
	acres?: Maybe<Scalars['Float']>;
	aph?: Maybe<Scalars['Float']>;
	breakeven?: Maybe<Scalars['Float']>;
	crop_id?: Maybe<Scalars['Float']>;
	customer_id?: Maybe<Scalars['Float']>;
	enrolled_percent?: Maybe<Scalars['Float']>;
	goal?: Maybe<Scalars['Float']>;
	harvest_bu?: Maybe<Scalars['Float']>;
	id?: Maybe<Scalars['Float']>;
	storage_bu?: Maybe<Scalars['Float']>;
	year_id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "production" */
export type Production_Avg_Order_By = {
	acres?: InputMaybe<Order_By>;
	aph?: InputMaybe<Order_By>;
	breakeven?: InputMaybe<Order_By>;
	crop_id?: InputMaybe<Order_By>;
	customer_id?: InputMaybe<Order_By>;
	enrolled_percent?: InputMaybe<Order_By>;
	goal?: InputMaybe<Order_By>;
	harvest_bu?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	storage_bu?: InputMaybe<Order_By>;
	year_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "production". All fields are combined with a logical 'AND'. */
export type Production_Bool_Exp = {
	Crop?: InputMaybe<Crops_Bool_Exp>;
	CropYear?: InputMaybe<Crop_Years_Bool_Exp>;
	Customer?: InputMaybe<Customers_Bool_Exp>;
	Fills?: InputMaybe<Fills_Bool_Exp>;
	Fills_aggregate?: InputMaybe<Fills_Aggregate_Bool_Exp>;
	Targets?: InputMaybe<Targets_Bool_Exp>;
	Targets_aggregate?: InputMaybe<Targets_Aggregate_Bool_Exp>;
	_and?: InputMaybe<Array<Production_Bool_Exp>>;
	_not?: InputMaybe<Production_Bool_Exp>;
	_or?: InputMaybe<Array<Production_Bool_Exp>>;
	acres?: InputMaybe<Numeric_Comparison_Exp>;
	aph?: InputMaybe<Numeric_Comparison_Exp>;
	breakeven?: InputMaybe<Numeric_Comparison_Exp>;
	crop_id?: InputMaybe<Int_Comparison_Exp>;
	customer_id?: InputMaybe<Int_Comparison_Exp>;
	enrolled_percent?: InputMaybe<Numeric_Comparison_Exp>;
	goal?: InputMaybe<Numeric_Comparison_Exp>;
	harvest_bu?: InputMaybe<Numeric_Comparison_Exp>;
	id?: InputMaybe<Int_Comparison_Exp>;
	storage_bu?: InputMaybe<Numeric_Comparison_Exp>;
	year_id?: InputMaybe<Int_Comparison_Exp>;
};

/** unique or primary key constraints on table "production" */
export enum Production_Constraint {
	/** unique or primary key constraint on columns "crop_id", "customer_id", "year_id" */
	ProductionCustomerIdCropIdYearIdIdx = 'production_customer_id_crop_id_year_id_idx',
	/** unique or primary key constraint on columns "id" */
	ProductionPkey = 'production_pkey',
}

/** input type for incrementing numeric columns in table "production" */
export type Production_Inc_Input = {
	acres?: InputMaybe<Scalars['numeric']>;
	aph?: InputMaybe<Scalars['numeric']>;
	breakeven?: InputMaybe<Scalars['numeric']>;
	enrolled_percent?: InputMaybe<Scalars['numeric']>;
	goal?: InputMaybe<Scalars['numeric']>;
	harvest_bu?: InputMaybe<Scalars['numeric']>;
	storage_bu?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "production" */
export type Production_Insert_Input = {
	Crop?: InputMaybe<Crops_Obj_Rel_Insert_Input>;
	Customer?: InputMaybe<Customers_Obj_Rel_Insert_Input>;
	Fills?: InputMaybe<Fills_Arr_Rel_Insert_Input>;
	Targets?: InputMaybe<Targets_Arr_Rel_Insert_Input>;
	acres?: InputMaybe<Scalars['numeric']>;
	aph?: InputMaybe<Scalars['numeric']>;
	breakeven?: InputMaybe<Scalars['numeric']>;
	crop_id?: InputMaybe<Scalars['Int']>;
	customer_id?: InputMaybe<Scalars['Int']>;
	enrolled_percent?: InputMaybe<Scalars['numeric']>;
	goal?: InputMaybe<Scalars['numeric']>;
	harvest_bu?: InputMaybe<Scalars['numeric']>;
	storage_bu?: InputMaybe<Scalars['numeric']>;
	year_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate max on columns */
export type Production_Max_Fields = {
	__typename?: 'production_max_fields';
	acres?: Maybe<Scalars['numeric']>;
	aph?: Maybe<Scalars['numeric']>;
	breakeven?: Maybe<Scalars['numeric']>;
	crop_id?: Maybe<Scalars['Int']>;
	customer_id?: Maybe<Scalars['Int']>;
	enrolled_percent?: Maybe<Scalars['numeric']>;
	goal?: Maybe<Scalars['numeric']>;
	harvest_bu?: Maybe<Scalars['numeric']>;
	id?: Maybe<Scalars['Int']>;
	storage_bu?: Maybe<Scalars['numeric']>;
	year_id?: Maybe<Scalars['Int']>;
};

/** order by max() on columns of table "production" */
export type Production_Max_Order_By = {
	acres?: InputMaybe<Order_By>;
	aph?: InputMaybe<Order_By>;
	breakeven?: InputMaybe<Order_By>;
	crop_id?: InputMaybe<Order_By>;
	customer_id?: InputMaybe<Order_By>;
	enrolled_percent?: InputMaybe<Order_By>;
	goal?: InputMaybe<Order_By>;
	harvest_bu?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	storage_bu?: InputMaybe<Order_By>;
	year_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Production_Min_Fields = {
	__typename?: 'production_min_fields';
	acres?: Maybe<Scalars['numeric']>;
	aph?: Maybe<Scalars['numeric']>;
	breakeven?: Maybe<Scalars['numeric']>;
	crop_id?: Maybe<Scalars['Int']>;
	customer_id?: Maybe<Scalars['Int']>;
	enrolled_percent?: Maybe<Scalars['numeric']>;
	goal?: Maybe<Scalars['numeric']>;
	harvest_bu?: Maybe<Scalars['numeric']>;
	id?: Maybe<Scalars['Int']>;
	storage_bu?: Maybe<Scalars['numeric']>;
	year_id?: Maybe<Scalars['Int']>;
};

/** order by min() on columns of table "production" */
export type Production_Min_Order_By = {
	acres?: InputMaybe<Order_By>;
	aph?: InputMaybe<Order_By>;
	breakeven?: InputMaybe<Order_By>;
	crop_id?: InputMaybe<Order_By>;
	customer_id?: InputMaybe<Order_By>;
	enrolled_percent?: InputMaybe<Order_By>;
	goal?: InputMaybe<Order_By>;
	harvest_bu?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	storage_bu?: InputMaybe<Order_By>;
	year_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "production" */
export type Production_Mutation_Response = {
	__typename?: 'production_mutation_response';
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Production>;
};

/** input type for inserting object relation for remote table "production" */
export type Production_Obj_Rel_Insert_Input = {
	data: Production_Insert_Input;
	/** upsert condition */
	on_conflict?: InputMaybe<Production_On_Conflict>;
};

/** on_conflict condition type for table "production" */
export type Production_On_Conflict = {
	constraint: Production_Constraint;
	update_columns?: Array<Production_Update_Column>;
	where?: InputMaybe<Production_Bool_Exp>;
};

/** Ordering options when selecting data from "production". */
export type Production_Order_By = {
	Crop?: InputMaybe<Crops_Order_By>;
	CropYear?: InputMaybe<Crop_Years_Order_By>;
	Customer?: InputMaybe<Customers_Order_By>;
	Fills_aggregate?: InputMaybe<Fills_Aggregate_Order_By>;
	Targets_aggregate?: InputMaybe<Targets_Aggregate_Order_By>;
	acres?: InputMaybe<Order_By>;
	aph?: InputMaybe<Order_By>;
	breakeven?: InputMaybe<Order_By>;
	crop_id?: InputMaybe<Order_By>;
	customer_id?: InputMaybe<Order_By>;
	enrolled_percent?: InputMaybe<Order_By>;
	goal?: InputMaybe<Order_By>;
	harvest_bu?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	storage_bu?: InputMaybe<Order_By>;
	year_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: production */
export type Production_Pk_Columns_Input = {
	id: Scalars['Int'];
};

/** select columns of table "production" */
export enum Production_Select_Column {
	/** column name */
	Acres = 'acres',
	/** column name */
	Aph = 'aph',
	/** column name */
	Breakeven = 'breakeven',
	/** column name */
	CropId = 'crop_id',
	/** column name */
	CustomerId = 'customer_id',
	/** column name */
	EnrolledPercent = 'enrolled_percent',
	/** column name */
	Goal = 'goal',
	/** column name */
	HarvestBu = 'harvest_bu',
	/** column name */
	Id = 'id',
	/** column name */
	StorageBu = 'storage_bu',
	/** column name */
	YearId = 'year_id',
}

/** input type for updating data in table "production" */
export type Production_Set_Input = {
	acres?: InputMaybe<Scalars['numeric']>;
	aph?: InputMaybe<Scalars['numeric']>;
	breakeven?: InputMaybe<Scalars['numeric']>;
	enrolled_percent?: InputMaybe<Scalars['numeric']>;
	goal?: InputMaybe<Scalars['numeric']>;
	harvest_bu?: InputMaybe<Scalars['numeric']>;
	storage_bu?: InputMaybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Production_Stddev_Fields = {
	__typename?: 'production_stddev_fields';
	acres?: Maybe<Scalars['Float']>;
	aph?: Maybe<Scalars['Float']>;
	breakeven?: Maybe<Scalars['Float']>;
	crop_id?: Maybe<Scalars['Float']>;
	customer_id?: Maybe<Scalars['Float']>;
	enrolled_percent?: Maybe<Scalars['Float']>;
	goal?: Maybe<Scalars['Float']>;
	harvest_bu?: Maybe<Scalars['Float']>;
	id?: Maybe<Scalars['Float']>;
	storage_bu?: Maybe<Scalars['Float']>;
	year_id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "production" */
export type Production_Stddev_Order_By = {
	acres?: InputMaybe<Order_By>;
	aph?: InputMaybe<Order_By>;
	breakeven?: InputMaybe<Order_By>;
	crop_id?: InputMaybe<Order_By>;
	customer_id?: InputMaybe<Order_By>;
	enrolled_percent?: InputMaybe<Order_By>;
	goal?: InputMaybe<Order_By>;
	harvest_bu?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	storage_bu?: InputMaybe<Order_By>;
	year_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Production_Stddev_Pop_Fields = {
	__typename?: 'production_stddev_pop_fields';
	acres?: Maybe<Scalars['Float']>;
	aph?: Maybe<Scalars['Float']>;
	breakeven?: Maybe<Scalars['Float']>;
	crop_id?: Maybe<Scalars['Float']>;
	customer_id?: Maybe<Scalars['Float']>;
	enrolled_percent?: Maybe<Scalars['Float']>;
	goal?: Maybe<Scalars['Float']>;
	harvest_bu?: Maybe<Scalars['Float']>;
	id?: Maybe<Scalars['Float']>;
	storage_bu?: Maybe<Scalars['Float']>;
	year_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "production" */
export type Production_Stddev_Pop_Order_By = {
	acres?: InputMaybe<Order_By>;
	aph?: InputMaybe<Order_By>;
	breakeven?: InputMaybe<Order_By>;
	crop_id?: InputMaybe<Order_By>;
	customer_id?: InputMaybe<Order_By>;
	enrolled_percent?: InputMaybe<Order_By>;
	goal?: InputMaybe<Order_By>;
	harvest_bu?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	storage_bu?: InputMaybe<Order_By>;
	year_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Production_Stddev_Samp_Fields = {
	__typename?: 'production_stddev_samp_fields';
	acres?: Maybe<Scalars['Float']>;
	aph?: Maybe<Scalars['Float']>;
	breakeven?: Maybe<Scalars['Float']>;
	crop_id?: Maybe<Scalars['Float']>;
	customer_id?: Maybe<Scalars['Float']>;
	enrolled_percent?: Maybe<Scalars['Float']>;
	goal?: Maybe<Scalars['Float']>;
	harvest_bu?: Maybe<Scalars['Float']>;
	id?: Maybe<Scalars['Float']>;
	storage_bu?: Maybe<Scalars['Float']>;
	year_id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "production" */
export type Production_Stddev_Samp_Order_By = {
	acres?: InputMaybe<Order_By>;
	aph?: InputMaybe<Order_By>;
	breakeven?: InputMaybe<Order_By>;
	crop_id?: InputMaybe<Order_By>;
	customer_id?: InputMaybe<Order_By>;
	enrolled_percent?: InputMaybe<Order_By>;
	goal?: InputMaybe<Order_By>;
	harvest_bu?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	storage_bu?: InputMaybe<Order_By>;
	year_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "production" */
export type Production_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Production_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Production_Stream_Cursor_Value_Input = {
	acres?: InputMaybe<Scalars['numeric']>;
	aph?: InputMaybe<Scalars['numeric']>;
	breakeven?: InputMaybe<Scalars['numeric']>;
	crop_id?: InputMaybe<Scalars['Int']>;
	customer_id?: InputMaybe<Scalars['Int']>;
	enrolled_percent?: InputMaybe<Scalars['numeric']>;
	goal?: InputMaybe<Scalars['numeric']>;
	harvest_bu?: InputMaybe<Scalars['numeric']>;
	id?: InputMaybe<Scalars['Int']>;
	storage_bu?: InputMaybe<Scalars['numeric']>;
	year_id?: InputMaybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type Production_Sum_Fields = {
	__typename?: 'production_sum_fields';
	acres?: Maybe<Scalars['numeric']>;
	aph?: Maybe<Scalars['numeric']>;
	breakeven?: Maybe<Scalars['numeric']>;
	crop_id?: Maybe<Scalars['Int']>;
	customer_id?: Maybe<Scalars['Int']>;
	enrolled_percent?: Maybe<Scalars['numeric']>;
	goal?: Maybe<Scalars['numeric']>;
	harvest_bu?: Maybe<Scalars['numeric']>;
	id?: Maybe<Scalars['Int']>;
	storage_bu?: Maybe<Scalars['numeric']>;
	year_id?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "production" */
export type Production_Sum_Order_By = {
	acres?: InputMaybe<Order_By>;
	aph?: InputMaybe<Order_By>;
	breakeven?: InputMaybe<Order_By>;
	crop_id?: InputMaybe<Order_By>;
	customer_id?: InputMaybe<Order_By>;
	enrolled_percent?: InputMaybe<Order_By>;
	goal?: InputMaybe<Order_By>;
	harvest_bu?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	storage_bu?: InputMaybe<Order_By>;
	year_id?: InputMaybe<Order_By>;
};

/** update columns of table "production" */
export enum Production_Update_Column {
	/** column name */
	Acres = 'acres',
	/** column name */
	Aph = 'aph',
	/** column name */
	Breakeven = 'breakeven',
	/** column name */
	EnrolledPercent = 'enrolled_percent',
	/** column name */
	Goal = 'goal',
	/** column name */
	HarvestBu = 'harvest_bu',
	/** column name */
	StorageBu = 'storage_bu',
}

export type Production_Updates = {
	/** increments the numeric columns with given value of the filtered values */
	_inc?: InputMaybe<Production_Inc_Input>;
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Production_Set_Input>;
	/** filter the rows which have to be updated */
	where: Production_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Production_Var_Pop_Fields = {
	__typename?: 'production_var_pop_fields';
	acres?: Maybe<Scalars['Float']>;
	aph?: Maybe<Scalars['Float']>;
	breakeven?: Maybe<Scalars['Float']>;
	crop_id?: Maybe<Scalars['Float']>;
	customer_id?: Maybe<Scalars['Float']>;
	enrolled_percent?: Maybe<Scalars['Float']>;
	goal?: Maybe<Scalars['Float']>;
	harvest_bu?: Maybe<Scalars['Float']>;
	id?: Maybe<Scalars['Float']>;
	storage_bu?: Maybe<Scalars['Float']>;
	year_id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "production" */
export type Production_Var_Pop_Order_By = {
	acres?: InputMaybe<Order_By>;
	aph?: InputMaybe<Order_By>;
	breakeven?: InputMaybe<Order_By>;
	crop_id?: InputMaybe<Order_By>;
	customer_id?: InputMaybe<Order_By>;
	enrolled_percent?: InputMaybe<Order_By>;
	goal?: InputMaybe<Order_By>;
	harvest_bu?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	storage_bu?: InputMaybe<Order_By>;
	year_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Production_Var_Samp_Fields = {
	__typename?: 'production_var_samp_fields';
	acres?: Maybe<Scalars['Float']>;
	aph?: Maybe<Scalars['Float']>;
	breakeven?: Maybe<Scalars['Float']>;
	crop_id?: Maybe<Scalars['Float']>;
	customer_id?: Maybe<Scalars['Float']>;
	enrolled_percent?: Maybe<Scalars['Float']>;
	goal?: Maybe<Scalars['Float']>;
	harvest_bu?: Maybe<Scalars['Float']>;
	id?: Maybe<Scalars['Float']>;
	storage_bu?: Maybe<Scalars['Float']>;
	year_id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "production" */
export type Production_Var_Samp_Order_By = {
	acres?: InputMaybe<Order_By>;
	aph?: InputMaybe<Order_By>;
	breakeven?: InputMaybe<Order_By>;
	crop_id?: InputMaybe<Order_By>;
	customer_id?: InputMaybe<Order_By>;
	enrolled_percent?: InputMaybe<Order_By>;
	goal?: InputMaybe<Order_By>;
	harvest_bu?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	storage_bu?: InputMaybe<Order_By>;
	year_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Production_Variance_Fields = {
	__typename?: 'production_variance_fields';
	acres?: Maybe<Scalars['Float']>;
	aph?: Maybe<Scalars['Float']>;
	breakeven?: Maybe<Scalars['Float']>;
	crop_id?: Maybe<Scalars['Float']>;
	customer_id?: Maybe<Scalars['Float']>;
	enrolled_percent?: Maybe<Scalars['Float']>;
	goal?: Maybe<Scalars['Float']>;
	harvest_bu?: Maybe<Scalars['Float']>;
	id?: Maybe<Scalars['Float']>;
	storage_bu?: Maybe<Scalars['Float']>;
	year_id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "production" */
export type Production_Variance_Order_By = {
	acres?: InputMaybe<Order_By>;
	aph?: InputMaybe<Order_By>;
	breakeven?: InputMaybe<Order_By>;
	crop_id?: InputMaybe<Order_By>;
	customer_id?: InputMaybe<Order_By>;
	enrolled_percent?: InputMaybe<Order_By>;
	goal?: InputMaybe<Order_By>;
	harvest_bu?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	storage_bu?: InputMaybe<Order_By>;
	year_id?: InputMaybe<Order_By>;
};

export type Query_Root = {
	__typename?: 'query_root';
	/** fetch data from the table: "advisors" */
	advisors: Array<Advisors>;
	/** fetch data from the table: "advisors" using primary key columns */
	advisors_by_pk?: Maybe<Advisors>;
	/** fetch data from the table: "buyers" */
	buyers: Array<Buyers>;
	/** fetch data from the table: "buyers" using primary key columns */
	buyers_by_pk?: Maybe<Buyers>;
	/** fetch data from the table: "crop_years" */
	crop_years: Array<Crop_Years>;
	/** fetch data from the table: "crop_years" using primary key columns */
	crop_years_by_pk?: Maybe<Crop_Years>;
	/** fetch data from the table: "crops" */
	crops: Array<Crops>;
	/** fetch data from the table: "crops" using primary key columns */
	crops_by_pk?: Maybe<Crops>;
	/** An array relationship */
	customers: Array<Customers>;
	/** An aggregate relationship */
	customers_aggregate: Customers_Aggregate;
	/** fetch data from the table: "customers" using primary key columns */
	customers_by_pk?: Maybe<Customers>;
	/** fetch data from the table: "feed_plans" */
	feed_plans: Array<Feed_Plans>;
	/** fetch aggregated fields from the table: "feed_plans" */
	feed_plans_aggregate: Feed_Plans_Aggregate;
	/** fetch data from the table: "feed_plans" using primary key columns */
	feed_plans_by_pk?: Maybe<Feed_Plans>;
	/** fetch data from the table: "fill_activity" */
	fill_activity: Array<Fill_Activity>;
	/** fetch data from the table: "fill_modification_requests" */
	fill_modification_requests: Array<Fill_Modification_Requests>;
	/** fetch data from the table: "fill_modification_requests" using primary key columns */
	fill_modification_requests_by_pk?: Maybe<Fill_Modification_Requests>;
	/** fetch data from the table: "fill_notes" */
	fill_notes: Array<Fill_Notes>;
	/** fetch data from the table: "fill_notes" using primary key columns */
	fill_notes_by_pk?: Maybe<Fill_Notes>;
	/** fetch data from the table: "fills" */
	fills: Array<Fills>;
	/** fetch aggregated fields from the table: "fills" */
	fills_aggregate: Fills_Aggregate;
	/** fetch data from the table: "fills" using primary key columns */
	fills_by_pk?: Maybe<Fills>;
	/** fetch data from the table: "ingredients" */
	ingredients: Array<Ingredients>;
	/** fetch data from the table: "ingredients" using primary key columns */
	ingredients_by_pk?: Maybe<Ingredients>;
	/** fetch data from the table: "locations" */
	locations: Array<Locations>;
	/** fetch data from the table: "locations" using primary key columns */
	locations_by_pk?: Maybe<Locations>;
	/** An array relationship */
	order_activity: Array<Order_Activity>;
	/** fetch data from the table: "order_activity" using primary key columns */
	order_activity_by_pk?: Maybe<Order_Activity>;
	/** fetch data from the table: "permission_group_membership" */
	permission_group_membership: Array<Permission_Group_Membership>;
	/** fetch data from the table: "permission_group_membership" using primary key columns */
	permission_group_membership_by_pk?: Maybe<Permission_Group_Membership>;
	/** fetch data from the table: "permission_groups" */
	permission_groups: Array<Permission_Groups>;
	/** fetch data from the table: "permission_groups" using primary key columns */
	permission_groups_by_pk?: Maybe<Permission_Groups>;
	/** fetch data from the table: "production" */
	production: Array<Production>;
	/** fetch aggregated fields from the table: "production" */
	production_aggregate: Production_Aggregate;
	/** fetch data from the table: "production" using primary key columns */
	production_by_pk?: Maybe<Production>;
	/** fetch data from the table: "sellers" */
	sellers: Array<Sellers>;
	/** fetch data from the table: "sellers" using primary key columns */
	sellers_by_pk?: Maybe<Sellers>;
	/** fetch data from the table: "target_activity" */
	target_activity: Array<Target_Activity>;
	/** fetch data from the table: "target_notes" */
	target_notes: Array<Target_Notes>;
	/** fetch data from the table: "target_notes" using primary key columns */
	target_notes_by_pk?: Maybe<Target_Notes>;
	/** fetch data from the table: "target_status" */
	target_status: Array<Target_Status>;
	/** fetch data from the table: "target_status_updates" */
	target_status_updates: Array<Target_Status_Updates>;
	/** fetch data from the table: "target_status_updates" using primary key columns */
	target_status_updates_by_pk?: Maybe<Target_Status_Updates>;
	/** fetch data from the table: "targets" */
	targets: Array<Targets>;
	/** fetch aggregated fields from the table: "targets" */
	targets_aggregate: Targets_Aggregate;
	/** fetch data from the table: "targets" using primary key columns */
	targets_by_pk?: Maybe<Targets>;
};

export type Query_RootAdvisorsArgs = {
	distinct_on?: InputMaybe<Array<Advisors_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Advisors_Order_By>>;
	where?: InputMaybe<Advisors_Bool_Exp>;
};

export type Query_RootAdvisors_By_PkArgs = {
	id: Scalars['Int'];
};

export type Query_RootBuyersArgs = {
	distinct_on?: InputMaybe<Array<Buyers_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Buyers_Order_By>>;
	where?: InputMaybe<Buyers_Bool_Exp>;
};

export type Query_RootBuyers_By_PkArgs = {
	id: Scalars['Int'];
};

export type Query_RootCrop_YearsArgs = {
	distinct_on?: InputMaybe<Array<Crop_Years_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Crop_Years_Order_By>>;
	where?: InputMaybe<Crop_Years_Bool_Exp>;
};

export type Query_RootCrop_Years_By_PkArgs = {
	id: Scalars['Int'];
};

export type Query_RootCropsArgs = {
	distinct_on?: InputMaybe<Array<Crops_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Crops_Order_By>>;
	where?: InputMaybe<Crops_Bool_Exp>;
};

export type Query_RootCrops_By_PkArgs = {
	id: Scalars['Int'];
};

export type Query_RootCustomersArgs = {
	distinct_on?: InputMaybe<Array<Customers_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Customers_Order_By>>;
	where?: InputMaybe<Customers_Bool_Exp>;
};

export type Query_RootCustomers_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Customers_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Customers_Order_By>>;
	where?: InputMaybe<Customers_Bool_Exp>;
};

export type Query_RootCustomers_By_PkArgs = {
	id: Scalars['Int'];
};

export type Query_RootFeed_PlansArgs = {
	distinct_on?: InputMaybe<Array<Feed_Plans_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Feed_Plans_Order_By>>;
	where?: InputMaybe<Feed_Plans_Bool_Exp>;
};

export type Query_RootFeed_Plans_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Feed_Plans_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Feed_Plans_Order_By>>;
	where?: InputMaybe<Feed_Plans_Bool_Exp>;
};

export type Query_RootFeed_Plans_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Query_RootFill_ActivityArgs = {
	distinct_on?: InputMaybe<Array<Fill_Activity_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Fill_Activity_Order_By>>;
	where?: InputMaybe<Fill_Activity_Bool_Exp>;
};

export type Query_RootFill_Modification_RequestsArgs = {
	distinct_on?: InputMaybe<Array<Fill_Modification_Requests_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Fill_Modification_Requests_Order_By>>;
	where?: InputMaybe<Fill_Modification_Requests_Bool_Exp>;
};

export type Query_RootFill_Modification_Requests_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Query_RootFill_NotesArgs = {
	distinct_on?: InputMaybe<Array<Fill_Notes_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Fill_Notes_Order_By>>;
	where?: InputMaybe<Fill_Notes_Bool_Exp>;
};

export type Query_RootFill_Notes_By_PkArgs = {
	id: Scalars['Int'];
};

export type Query_RootFillsArgs = {
	distinct_on?: InputMaybe<Array<Fills_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Fills_Order_By>>;
	where?: InputMaybe<Fills_Bool_Exp>;
};

export type Query_RootFills_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Fills_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Fills_Order_By>>;
	where?: InputMaybe<Fills_Bool_Exp>;
};

export type Query_RootFills_By_PkArgs = {
	id: Scalars['Int'];
};

export type Query_RootIngredientsArgs = {
	distinct_on?: InputMaybe<Array<Ingredients_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Ingredients_Order_By>>;
	where?: InputMaybe<Ingredients_Bool_Exp>;
};

export type Query_RootIngredients_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Query_RootLocationsArgs = {
	distinct_on?: InputMaybe<Array<Locations_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Locations_Order_By>>;
	where?: InputMaybe<Locations_Bool_Exp>;
};

export type Query_RootLocations_By_PkArgs = {
	id: Scalars['Int'];
};

export type Query_RootOrder_ActivityArgs = {
	distinct_on?: InputMaybe<Array<Order_Activity_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Order_Activity_Order_By>>;
	where?: InputMaybe<Order_Activity_Bool_Exp>;
};

export type Query_RootOrder_Activity_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Query_RootPermission_Group_MembershipArgs = {
	distinct_on?: InputMaybe<Array<Permission_Group_Membership_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Permission_Group_Membership_Order_By>>;
	where?: InputMaybe<Permission_Group_Membership_Bool_Exp>;
};

export type Query_RootPermission_Group_Membership_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Query_RootPermission_GroupsArgs = {
	distinct_on?: InputMaybe<Array<Permission_Groups_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Permission_Groups_Order_By>>;
	where?: InputMaybe<Permission_Groups_Bool_Exp>;
};

export type Query_RootPermission_Groups_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Query_RootProductionArgs = {
	distinct_on?: InputMaybe<Array<Production_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Production_Order_By>>;
	where?: InputMaybe<Production_Bool_Exp>;
};

export type Query_RootProduction_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Production_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Production_Order_By>>;
	where?: InputMaybe<Production_Bool_Exp>;
};

export type Query_RootProduction_By_PkArgs = {
	id: Scalars['Int'];
};

export type Query_RootSellersArgs = {
	distinct_on?: InputMaybe<Array<Sellers_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Sellers_Order_By>>;
	where?: InputMaybe<Sellers_Bool_Exp>;
};

export type Query_RootSellers_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Query_RootTarget_ActivityArgs = {
	distinct_on?: InputMaybe<Array<Target_Activity_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Target_Activity_Order_By>>;
	where?: InputMaybe<Target_Activity_Bool_Exp>;
};

export type Query_RootTarget_NotesArgs = {
	distinct_on?: InputMaybe<Array<Target_Notes_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Target_Notes_Order_By>>;
	where?: InputMaybe<Target_Notes_Bool_Exp>;
};

export type Query_RootTarget_Notes_By_PkArgs = {
	id: Scalars['Int'];
};

export type Query_RootTarget_StatusArgs = {
	distinct_on?: InputMaybe<Array<Target_Status_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Target_Status_Order_By>>;
	where?: InputMaybe<Target_Status_Bool_Exp>;
};

export type Query_RootTarget_Status_UpdatesArgs = {
	distinct_on?: InputMaybe<Array<Target_Status_Updates_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Target_Status_Updates_Order_By>>;
	where?: InputMaybe<Target_Status_Updates_Bool_Exp>;
};

export type Query_RootTarget_Status_Updates_By_PkArgs = {
	id: Scalars['Int'];
};

export type Query_RootTargetsArgs = {
	distinct_on?: InputMaybe<Array<Targets_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Targets_Order_By>>;
	where?: InputMaybe<Targets_Bool_Exp>;
};

export type Query_RootTargets_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Targets_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Targets_Order_By>>;
	where?: InputMaybe<Targets_Bool_Exp>;
};

export type Query_RootTargets_By_PkArgs = {
	id: Scalars['Int'];
};

/** columns and relationships of "sellers" */
export type Sellers = {
	__typename?: 'sellers';
	id: Scalars['uuid'];
	name?: Maybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "sellers". All fields are combined with a logical 'AND'. */
export type Sellers_Bool_Exp = {
	_and?: InputMaybe<Array<Sellers_Bool_Exp>>;
	_not?: InputMaybe<Sellers_Bool_Exp>;
	_or?: InputMaybe<Array<Sellers_Bool_Exp>>;
	id?: InputMaybe<Uuid_Comparison_Exp>;
	name?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "sellers" */
export enum Sellers_Constraint {
	/** unique or primary key constraint on columns "id" */
	SellersPkey = 'sellers_pkey',
}

/** input type for inserting data into table "sellers" */
export type Sellers_Insert_Input = {
	name?: InputMaybe<Scalars['String']>;
};

/** response of any mutation on the table "sellers" */
export type Sellers_Mutation_Response = {
	__typename?: 'sellers_mutation_response';
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Sellers>;
};

/** input type for inserting object relation for remote table "sellers" */
export type Sellers_Obj_Rel_Insert_Input = {
	data: Sellers_Insert_Input;
	/** upsert condition */
	on_conflict?: InputMaybe<Sellers_On_Conflict>;
};

/** on_conflict condition type for table "sellers" */
export type Sellers_On_Conflict = {
	constraint: Sellers_Constraint;
	update_columns?: Array<Sellers_Update_Column>;
	where?: InputMaybe<Sellers_Bool_Exp>;
};

/** Ordering options when selecting data from "sellers". */
export type Sellers_Order_By = {
	id?: InputMaybe<Order_By>;
	name?: InputMaybe<Order_By>;
};

/** primary key columns input for table: sellers */
export type Sellers_Pk_Columns_Input = {
	id: Scalars['uuid'];
};

/** select columns of table "sellers" */
export enum Sellers_Select_Column {
	/** column name */
	Id = 'id',
	/** column name */
	Name = 'name',
}

/** input type for updating data in table "sellers" */
export type Sellers_Set_Input = {
	name?: InputMaybe<Scalars['String']>;
};

/** Streaming cursor of the table "sellers" */
export type Sellers_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Sellers_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Sellers_Stream_Cursor_Value_Input = {
	id?: InputMaybe<Scalars['uuid']>;
	name?: InputMaybe<Scalars['String']>;
};

/** update columns of table "sellers" */
export enum Sellers_Update_Column {
	/** column name */
	Name = 'name',
}

export type Sellers_Updates = {
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Sellers_Set_Input>;
	/** filter the rows which have to be updated */
	where: Sellers_Bool_Exp;
};

/** Boolean expression to compare columns of type "smallint". All fields are combined with logical 'AND'. */
export type Smallint_Comparison_Exp = {
	_eq?: InputMaybe<Scalars['smallint']>;
	_gt?: InputMaybe<Scalars['smallint']>;
	_gte?: InputMaybe<Scalars['smallint']>;
	_in?: InputMaybe<Array<Scalars['smallint']>>;
	_is_null?: InputMaybe<Scalars['Boolean']>;
	_lt?: InputMaybe<Scalars['smallint']>;
	_lte?: InputMaybe<Scalars['smallint']>;
	_neq?: InputMaybe<Scalars['smallint']>;
	_nin?: InputMaybe<Array<Scalars['smallint']>>;
};

export type Subscription_Root = {
	__typename?: 'subscription_root';
	/** fetch data from the table: "advisors" */
	advisors: Array<Advisors>;
	/** fetch data from the table: "advisors" using primary key columns */
	advisors_by_pk?: Maybe<Advisors>;
	/** fetch data from the table in a streaming manner: "advisors" */
	advisors_stream: Array<Advisors>;
	/** fetch data from the table: "buyers" */
	buyers: Array<Buyers>;
	/** fetch data from the table: "buyers" using primary key columns */
	buyers_by_pk?: Maybe<Buyers>;
	/** fetch data from the table in a streaming manner: "buyers" */
	buyers_stream: Array<Buyers>;
	/** fetch data from the table: "crop_years" */
	crop_years: Array<Crop_Years>;
	/** fetch data from the table: "crop_years" using primary key columns */
	crop_years_by_pk?: Maybe<Crop_Years>;
	/** fetch data from the table in a streaming manner: "crop_years" */
	crop_years_stream: Array<Crop_Years>;
	/** fetch data from the table: "crops" */
	crops: Array<Crops>;
	/** fetch data from the table: "crops" using primary key columns */
	crops_by_pk?: Maybe<Crops>;
	/** fetch data from the table in a streaming manner: "crops" */
	crops_stream: Array<Crops>;
	/** An array relationship */
	customers: Array<Customers>;
	/** An aggregate relationship */
	customers_aggregate: Customers_Aggregate;
	/** fetch data from the table: "customers" using primary key columns */
	customers_by_pk?: Maybe<Customers>;
	/** fetch data from the table in a streaming manner: "customers" */
	customers_stream: Array<Customers>;
	/** fetch data from the table: "feed_plans" */
	feed_plans: Array<Feed_Plans>;
	/** fetch aggregated fields from the table: "feed_plans" */
	feed_plans_aggregate: Feed_Plans_Aggregate;
	/** fetch data from the table: "feed_plans" using primary key columns */
	feed_plans_by_pk?: Maybe<Feed_Plans>;
	/** fetch data from the table in a streaming manner: "feed_plans" */
	feed_plans_stream: Array<Feed_Plans>;
	/** fetch data from the table: "fill_activity" */
	fill_activity: Array<Fill_Activity>;
	/** fetch data from the table in a streaming manner: "fill_activity" */
	fill_activity_stream: Array<Fill_Activity>;
	/** fetch data from the table: "fill_modification_requests" */
	fill_modification_requests: Array<Fill_Modification_Requests>;
	/** fetch data from the table: "fill_modification_requests" using primary key columns */
	fill_modification_requests_by_pk?: Maybe<Fill_Modification_Requests>;
	/** fetch data from the table in a streaming manner: "fill_modification_requests" */
	fill_modification_requests_stream: Array<Fill_Modification_Requests>;
	/** fetch data from the table: "fill_notes" */
	fill_notes: Array<Fill_Notes>;
	/** fetch data from the table: "fill_notes" using primary key columns */
	fill_notes_by_pk?: Maybe<Fill_Notes>;
	/** fetch data from the table in a streaming manner: "fill_notes" */
	fill_notes_stream: Array<Fill_Notes>;
	/** fetch data from the table: "fills" */
	fills: Array<Fills>;
	/** fetch aggregated fields from the table: "fills" */
	fills_aggregate: Fills_Aggregate;
	/** fetch data from the table: "fills" using primary key columns */
	fills_by_pk?: Maybe<Fills>;
	/** fetch data from the table in a streaming manner: "fills" */
	fills_stream: Array<Fills>;
	/** fetch data from the table: "ingredients" */
	ingredients: Array<Ingredients>;
	/** fetch data from the table: "ingredients" using primary key columns */
	ingredients_by_pk?: Maybe<Ingredients>;
	/** fetch data from the table in a streaming manner: "ingredients" */
	ingredients_stream: Array<Ingredients>;
	/** fetch data from the table: "locations" */
	locations: Array<Locations>;
	/** fetch data from the table: "locations" using primary key columns */
	locations_by_pk?: Maybe<Locations>;
	/** fetch data from the table in a streaming manner: "locations" */
	locations_stream: Array<Locations>;
	/** An array relationship */
	order_activity: Array<Order_Activity>;
	/** fetch data from the table: "order_activity" using primary key columns */
	order_activity_by_pk?: Maybe<Order_Activity>;
	/** fetch data from the table in a streaming manner: "order_activity" */
	order_activity_stream: Array<Order_Activity>;
	/** fetch data from the table: "permission_group_membership" */
	permission_group_membership: Array<Permission_Group_Membership>;
	/** fetch data from the table: "permission_group_membership" using primary key columns */
	permission_group_membership_by_pk?: Maybe<Permission_Group_Membership>;
	/** fetch data from the table in a streaming manner: "permission_group_membership" */
	permission_group_membership_stream: Array<Permission_Group_Membership>;
	/** fetch data from the table: "permission_groups" */
	permission_groups: Array<Permission_Groups>;
	/** fetch data from the table: "permission_groups" using primary key columns */
	permission_groups_by_pk?: Maybe<Permission_Groups>;
	/** fetch data from the table in a streaming manner: "permission_groups" */
	permission_groups_stream: Array<Permission_Groups>;
	/** fetch data from the table: "production" */
	production: Array<Production>;
	/** fetch aggregated fields from the table: "production" */
	production_aggregate: Production_Aggregate;
	/** fetch data from the table: "production" using primary key columns */
	production_by_pk?: Maybe<Production>;
	/** fetch data from the table in a streaming manner: "production" */
	production_stream: Array<Production>;
	/** fetch data from the table: "sellers" */
	sellers: Array<Sellers>;
	/** fetch data from the table: "sellers" using primary key columns */
	sellers_by_pk?: Maybe<Sellers>;
	/** fetch data from the table in a streaming manner: "sellers" */
	sellers_stream: Array<Sellers>;
	/** fetch data from the table: "target_activity" */
	target_activity: Array<Target_Activity>;
	/** fetch data from the table in a streaming manner: "target_activity" */
	target_activity_stream: Array<Target_Activity>;
	/** fetch data from the table: "target_notes" */
	target_notes: Array<Target_Notes>;
	/** fetch data from the table: "target_notes" using primary key columns */
	target_notes_by_pk?: Maybe<Target_Notes>;
	/** fetch data from the table in a streaming manner: "target_notes" */
	target_notes_stream: Array<Target_Notes>;
	/** fetch data from the table: "target_status" */
	target_status: Array<Target_Status>;
	/** fetch data from the table in a streaming manner: "target_status" */
	target_status_stream: Array<Target_Status>;
	/** fetch data from the table: "target_status_updates" */
	target_status_updates: Array<Target_Status_Updates>;
	/** fetch data from the table: "target_status_updates" using primary key columns */
	target_status_updates_by_pk?: Maybe<Target_Status_Updates>;
	/** fetch data from the table in a streaming manner: "target_status_updates" */
	target_status_updates_stream: Array<Target_Status_Updates>;
	/** fetch data from the table: "targets" */
	targets: Array<Targets>;
	/** fetch aggregated fields from the table: "targets" */
	targets_aggregate: Targets_Aggregate;
	/** fetch data from the table: "targets" using primary key columns */
	targets_by_pk?: Maybe<Targets>;
	/** fetch data from the table in a streaming manner: "targets" */
	targets_stream: Array<Targets>;
};

export type Subscription_RootAdvisorsArgs = {
	distinct_on?: InputMaybe<Array<Advisors_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Advisors_Order_By>>;
	where?: InputMaybe<Advisors_Bool_Exp>;
};

export type Subscription_RootAdvisors_By_PkArgs = {
	id: Scalars['Int'];
};

export type Subscription_RootAdvisors_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Advisors_Stream_Cursor_Input>>;
	where?: InputMaybe<Advisors_Bool_Exp>;
};

export type Subscription_RootBuyersArgs = {
	distinct_on?: InputMaybe<Array<Buyers_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Buyers_Order_By>>;
	where?: InputMaybe<Buyers_Bool_Exp>;
};

export type Subscription_RootBuyers_By_PkArgs = {
	id: Scalars['Int'];
};

export type Subscription_RootBuyers_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Buyers_Stream_Cursor_Input>>;
	where?: InputMaybe<Buyers_Bool_Exp>;
};

export type Subscription_RootCrop_YearsArgs = {
	distinct_on?: InputMaybe<Array<Crop_Years_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Crop_Years_Order_By>>;
	where?: InputMaybe<Crop_Years_Bool_Exp>;
};

export type Subscription_RootCrop_Years_By_PkArgs = {
	id: Scalars['Int'];
};

export type Subscription_RootCrop_Years_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Crop_Years_Stream_Cursor_Input>>;
	where?: InputMaybe<Crop_Years_Bool_Exp>;
};

export type Subscription_RootCropsArgs = {
	distinct_on?: InputMaybe<Array<Crops_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Crops_Order_By>>;
	where?: InputMaybe<Crops_Bool_Exp>;
};

export type Subscription_RootCrops_By_PkArgs = {
	id: Scalars['Int'];
};

export type Subscription_RootCrops_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Crops_Stream_Cursor_Input>>;
	where?: InputMaybe<Crops_Bool_Exp>;
};

export type Subscription_RootCustomersArgs = {
	distinct_on?: InputMaybe<Array<Customers_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Customers_Order_By>>;
	where?: InputMaybe<Customers_Bool_Exp>;
};

export type Subscription_RootCustomers_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Customers_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Customers_Order_By>>;
	where?: InputMaybe<Customers_Bool_Exp>;
};

export type Subscription_RootCustomers_By_PkArgs = {
	id: Scalars['Int'];
};

export type Subscription_RootCustomers_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Customers_Stream_Cursor_Input>>;
	where?: InputMaybe<Customers_Bool_Exp>;
};

export type Subscription_RootFeed_PlansArgs = {
	distinct_on?: InputMaybe<Array<Feed_Plans_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Feed_Plans_Order_By>>;
	where?: InputMaybe<Feed_Plans_Bool_Exp>;
};

export type Subscription_RootFeed_Plans_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Feed_Plans_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Feed_Plans_Order_By>>;
	where?: InputMaybe<Feed_Plans_Bool_Exp>;
};

export type Subscription_RootFeed_Plans_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Subscription_RootFeed_Plans_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Feed_Plans_Stream_Cursor_Input>>;
	where?: InputMaybe<Feed_Plans_Bool_Exp>;
};

export type Subscription_RootFill_ActivityArgs = {
	distinct_on?: InputMaybe<Array<Fill_Activity_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Fill_Activity_Order_By>>;
	where?: InputMaybe<Fill_Activity_Bool_Exp>;
};

export type Subscription_RootFill_Activity_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Fill_Activity_Stream_Cursor_Input>>;
	where?: InputMaybe<Fill_Activity_Bool_Exp>;
};

export type Subscription_RootFill_Modification_RequestsArgs = {
	distinct_on?: InputMaybe<Array<Fill_Modification_Requests_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Fill_Modification_Requests_Order_By>>;
	where?: InputMaybe<Fill_Modification_Requests_Bool_Exp>;
};

export type Subscription_RootFill_Modification_Requests_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Subscription_RootFill_Modification_Requests_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Fill_Modification_Requests_Stream_Cursor_Input>>;
	where?: InputMaybe<Fill_Modification_Requests_Bool_Exp>;
};

export type Subscription_RootFill_NotesArgs = {
	distinct_on?: InputMaybe<Array<Fill_Notes_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Fill_Notes_Order_By>>;
	where?: InputMaybe<Fill_Notes_Bool_Exp>;
};

export type Subscription_RootFill_Notes_By_PkArgs = {
	id: Scalars['Int'];
};

export type Subscription_RootFill_Notes_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Fill_Notes_Stream_Cursor_Input>>;
	where?: InputMaybe<Fill_Notes_Bool_Exp>;
};

export type Subscription_RootFillsArgs = {
	distinct_on?: InputMaybe<Array<Fills_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Fills_Order_By>>;
	where?: InputMaybe<Fills_Bool_Exp>;
};

export type Subscription_RootFills_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Fills_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Fills_Order_By>>;
	where?: InputMaybe<Fills_Bool_Exp>;
};

export type Subscription_RootFills_By_PkArgs = {
	id: Scalars['Int'];
};

export type Subscription_RootFills_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Fills_Stream_Cursor_Input>>;
	where?: InputMaybe<Fills_Bool_Exp>;
};

export type Subscription_RootIngredientsArgs = {
	distinct_on?: InputMaybe<Array<Ingredients_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Ingredients_Order_By>>;
	where?: InputMaybe<Ingredients_Bool_Exp>;
};

export type Subscription_RootIngredients_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Subscription_RootIngredients_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Ingredients_Stream_Cursor_Input>>;
	where?: InputMaybe<Ingredients_Bool_Exp>;
};

export type Subscription_RootLocationsArgs = {
	distinct_on?: InputMaybe<Array<Locations_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Locations_Order_By>>;
	where?: InputMaybe<Locations_Bool_Exp>;
};

export type Subscription_RootLocations_By_PkArgs = {
	id: Scalars['Int'];
};

export type Subscription_RootLocations_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Locations_Stream_Cursor_Input>>;
	where?: InputMaybe<Locations_Bool_Exp>;
};

export type Subscription_RootOrder_ActivityArgs = {
	distinct_on?: InputMaybe<Array<Order_Activity_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Order_Activity_Order_By>>;
	where?: InputMaybe<Order_Activity_Bool_Exp>;
};

export type Subscription_RootOrder_Activity_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Subscription_RootOrder_Activity_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Order_Activity_Stream_Cursor_Input>>;
	where?: InputMaybe<Order_Activity_Bool_Exp>;
};

export type Subscription_RootPermission_Group_MembershipArgs = {
	distinct_on?: InputMaybe<Array<Permission_Group_Membership_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Permission_Group_Membership_Order_By>>;
	where?: InputMaybe<Permission_Group_Membership_Bool_Exp>;
};

export type Subscription_RootPermission_Group_Membership_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Subscription_RootPermission_Group_Membership_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Permission_Group_Membership_Stream_Cursor_Input>>;
	where?: InputMaybe<Permission_Group_Membership_Bool_Exp>;
};

export type Subscription_RootPermission_GroupsArgs = {
	distinct_on?: InputMaybe<Array<Permission_Groups_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Permission_Groups_Order_By>>;
	where?: InputMaybe<Permission_Groups_Bool_Exp>;
};

export type Subscription_RootPermission_Groups_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Subscription_RootPermission_Groups_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Permission_Groups_Stream_Cursor_Input>>;
	where?: InputMaybe<Permission_Groups_Bool_Exp>;
};

export type Subscription_RootProductionArgs = {
	distinct_on?: InputMaybe<Array<Production_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Production_Order_By>>;
	where?: InputMaybe<Production_Bool_Exp>;
};

export type Subscription_RootProduction_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Production_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Production_Order_By>>;
	where?: InputMaybe<Production_Bool_Exp>;
};

export type Subscription_RootProduction_By_PkArgs = {
	id: Scalars['Int'];
};

export type Subscription_RootProduction_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Production_Stream_Cursor_Input>>;
	where?: InputMaybe<Production_Bool_Exp>;
};

export type Subscription_RootSellersArgs = {
	distinct_on?: InputMaybe<Array<Sellers_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Sellers_Order_By>>;
	where?: InputMaybe<Sellers_Bool_Exp>;
};

export type Subscription_RootSellers_By_PkArgs = {
	id: Scalars['uuid'];
};

export type Subscription_RootSellers_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Sellers_Stream_Cursor_Input>>;
	where?: InputMaybe<Sellers_Bool_Exp>;
};

export type Subscription_RootTarget_ActivityArgs = {
	distinct_on?: InputMaybe<Array<Target_Activity_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Target_Activity_Order_By>>;
	where?: InputMaybe<Target_Activity_Bool_Exp>;
};

export type Subscription_RootTarget_Activity_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Target_Activity_Stream_Cursor_Input>>;
	where?: InputMaybe<Target_Activity_Bool_Exp>;
};

export type Subscription_RootTarget_NotesArgs = {
	distinct_on?: InputMaybe<Array<Target_Notes_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Target_Notes_Order_By>>;
	where?: InputMaybe<Target_Notes_Bool_Exp>;
};

export type Subscription_RootTarget_Notes_By_PkArgs = {
	id: Scalars['Int'];
};

export type Subscription_RootTarget_Notes_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Target_Notes_Stream_Cursor_Input>>;
	where?: InputMaybe<Target_Notes_Bool_Exp>;
};

export type Subscription_RootTarget_StatusArgs = {
	distinct_on?: InputMaybe<Array<Target_Status_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Target_Status_Order_By>>;
	where?: InputMaybe<Target_Status_Bool_Exp>;
};

export type Subscription_RootTarget_Status_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Target_Status_Stream_Cursor_Input>>;
	where?: InputMaybe<Target_Status_Bool_Exp>;
};

export type Subscription_RootTarget_Status_UpdatesArgs = {
	distinct_on?: InputMaybe<Array<Target_Status_Updates_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Target_Status_Updates_Order_By>>;
	where?: InputMaybe<Target_Status_Updates_Bool_Exp>;
};

export type Subscription_RootTarget_Status_Updates_By_PkArgs = {
	id: Scalars['Int'];
};

export type Subscription_RootTarget_Status_Updates_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Target_Status_Updates_Stream_Cursor_Input>>;
	where?: InputMaybe<Target_Status_Updates_Bool_Exp>;
};

export type Subscription_RootTargetsArgs = {
	distinct_on?: InputMaybe<Array<Targets_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Targets_Order_By>>;
	where?: InputMaybe<Targets_Bool_Exp>;
};

export type Subscription_RootTargets_AggregateArgs = {
	distinct_on?: InputMaybe<Array<Targets_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Targets_Order_By>>;
	where?: InputMaybe<Targets_Bool_Exp>;
};

export type Subscription_RootTargets_By_PkArgs = {
	id: Scalars['Int'];
};

export type Subscription_RootTargets_StreamArgs = {
	batch_size: Scalars['Int'];
	cursor: Array<InputMaybe<Targets_Stream_Cursor_Input>>;
	where?: InputMaybe<Targets_Bool_Exp>;
};

/** columns and relationships of "target_activity" */
export type Target_Activity = {
	__typename?: 'target_activity';
	/** An object relationship */
	Target?: Maybe<Targets>;
	action?: Maybe<Scalars['String']>;
	action_time?: Maybe<Scalars['timestamptz']>;
	actor?: Maybe<Scalars['String']>;
	target_id?: Maybe<Scalars['Int']>;
};

/** order by aggregate values of table "target_activity" */
export type Target_Activity_Aggregate_Order_By = {
	avg?: InputMaybe<Target_Activity_Avg_Order_By>;
	count?: InputMaybe<Order_By>;
	max?: InputMaybe<Target_Activity_Max_Order_By>;
	min?: InputMaybe<Target_Activity_Min_Order_By>;
	stddev?: InputMaybe<Target_Activity_Stddev_Order_By>;
	stddev_pop?: InputMaybe<Target_Activity_Stddev_Pop_Order_By>;
	stddev_samp?: InputMaybe<Target_Activity_Stddev_Samp_Order_By>;
	sum?: InputMaybe<Target_Activity_Sum_Order_By>;
	var_pop?: InputMaybe<Target_Activity_Var_Pop_Order_By>;
	var_samp?: InputMaybe<Target_Activity_Var_Samp_Order_By>;
	variance?: InputMaybe<Target_Activity_Variance_Order_By>;
};

/** order by avg() on columns of table "target_activity" */
export type Target_Activity_Avg_Order_By = {
	target_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "target_activity". All fields are combined with a logical 'AND'. */
export type Target_Activity_Bool_Exp = {
	Target?: InputMaybe<Targets_Bool_Exp>;
	_and?: InputMaybe<Array<Target_Activity_Bool_Exp>>;
	_not?: InputMaybe<Target_Activity_Bool_Exp>;
	_or?: InputMaybe<Array<Target_Activity_Bool_Exp>>;
	action?: InputMaybe<String_Comparison_Exp>;
	action_time?: InputMaybe<Timestamptz_Comparison_Exp>;
	actor?: InputMaybe<String_Comparison_Exp>;
	target_id?: InputMaybe<Int_Comparison_Exp>;
};

/** order by max() on columns of table "target_activity" */
export type Target_Activity_Max_Order_By = {
	action?: InputMaybe<Order_By>;
	action_time?: InputMaybe<Order_By>;
	actor?: InputMaybe<Order_By>;
	target_id?: InputMaybe<Order_By>;
};

/** order by min() on columns of table "target_activity" */
export type Target_Activity_Min_Order_By = {
	action?: InputMaybe<Order_By>;
	action_time?: InputMaybe<Order_By>;
	actor?: InputMaybe<Order_By>;
	target_id?: InputMaybe<Order_By>;
};

/** Ordering options when selecting data from "target_activity". */
export type Target_Activity_Order_By = {
	Target?: InputMaybe<Targets_Order_By>;
	action?: InputMaybe<Order_By>;
	action_time?: InputMaybe<Order_By>;
	actor?: InputMaybe<Order_By>;
	target_id?: InputMaybe<Order_By>;
};

/** select columns of table "target_activity" */
export enum Target_Activity_Select_Column {
	/** column name */
	Action = 'action',
	/** column name */
	ActionTime = 'action_time',
	/** column name */
	Actor = 'actor',
	/** column name */
	TargetId = 'target_id',
}

/** order by stddev() on columns of table "target_activity" */
export type Target_Activity_Stddev_Order_By = {
	target_id?: InputMaybe<Order_By>;
};

/** order by stddev_pop() on columns of table "target_activity" */
export type Target_Activity_Stddev_Pop_Order_By = {
	target_id?: InputMaybe<Order_By>;
};

/** order by stddev_samp() on columns of table "target_activity" */
export type Target_Activity_Stddev_Samp_Order_By = {
	target_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "target_activity" */
export type Target_Activity_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Target_Activity_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Target_Activity_Stream_Cursor_Value_Input = {
	action?: InputMaybe<Scalars['String']>;
	action_time?: InputMaybe<Scalars['timestamptz']>;
	actor?: InputMaybe<Scalars['String']>;
	target_id?: InputMaybe<Scalars['Int']>;
};

/** order by sum() on columns of table "target_activity" */
export type Target_Activity_Sum_Order_By = {
	target_id?: InputMaybe<Order_By>;
};

/** order by var_pop() on columns of table "target_activity" */
export type Target_Activity_Var_Pop_Order_By = {
	target_id?: InputMaybe<Order_By>;
};

/** order by var_samp() on columns of table "target_activity" */
export type Target_Activity_Var_Samp_Order_By = {
	target_id?: InputMaybe<Order_By>;
};

/** order by variance() on columns of table "target_activity" */
export type Target_Activity_Variance_Order_By = {
	target_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "target_notes" */
export type Target_Notes = {
	__typename?: 'target_notes';
	/** An object relationship */
	Target?: Maybe<Targets>;
	id: Scalars['Int'];
	note?: Maybe<Scalars['String']>;
	target_id?: Maybe<Scalars['Int']>;
	written_at?: Maybe<Scalars['timestamptz']>;
	written_by?: Maybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "target_notes". All fields are combined with a logical 'AND'. */
export type Target_Notes_Bool_Exp = {
	Target?: InputMaybe<Targets_Bool_Exp>;
	_and?: InputMaybe<Array<Target_Notes_Bool_Exp>>;
	_not?: InputMaybe<Target_Notes_Bool_Exp>;
	_or?: InputMaybe<Array<Target_Notes_Bool_Exp>>;
	id?: InputMaybe<Int_Comparison_Exp>;
	note?: InputMaybe<String_Comparison_Exp>;
	target_id?: InputMaybe<Int_Comparison_Exp>;
	written_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	written_by?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "target_notes" */
export enum Target_Notes_Constraint {
	/** unique or primary key constraint on columns "id" */
	TargetNotesPkey = 'target_notes_pkey',
}

/** input type for inserting data into table "target_notes" */
export type Target_Notes_Insert_Input = {
	Target?: InputMaybe<Targets_Obj_Rel_Insert_Input>;
	note?: InputMaybe<Scalars['String']>;
	target_id?: InputMaybe<Scalars['Int']>;
	written_by?: InputMaybe<Scalars['String']>;
};

/** response of any mutation on the table "target_notes" */
export type Target_Notes_Mutation_Response = {
	__typename?: 'target_notes_mutation_response';
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Target_Notes>;
};

/** on_conflict condition type for table "target_notes" */
export type Target_Notes_On_Conflict = {
	constraint: Target_Notes_Constraint;
	update_columns?: Array<Target_Notes_Update_Column>;
	where?: InputMaybe<Target_Notes_Bool_Exp>;
};

/** Ordering options when selecting data from "target_notes". */
export type Target_Notes_Order_By = {
	Target?: InputMaybe<Targets_Order_By>;
	id?: InputMaybe<Order_By>;
	note?: InputMaybe<Order_By>;
	target_id?: InputMaybe<Order_By>;
	written_at?: InputMaybe<Order_By>;
	written_by?: InputMaybe<Order_By>;
};

/** select columns of table "target_notes" */
export enum Target_Notes_Select_Column {
	/** column name */
	Id = 'id',
	/** column name */
	Note = 'note',
	/** column name */
	TargetId = 'target_id',
	/** column name */
	WrittenAt = 'written_at',
	/** column name */
	WrittenBy = 'written_by',
}

/** Streaming cursor of the table "target_notes" */
export type Target_Notes_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Target_Notes_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Target_Notes_Stream_Cursor_Value_Input = {
	id?: InputMaybe<Scalars['Int']>;
	note?: InputMaybe<Scalars['String']>;
	target_id?: InputMaybe<Scalars['Int']>;
	written_at?: InputMaybe<Scalars['timestamptz']>;
	written_by?: InputMaybe<Scalars['String']>;
};

/** placeholder for update columns of table "target_notes" (current role has no relevant permissions) */
export enum Target_Notes_Update_Column {
	/** placeholder (do not use) */
	Placeholder = '_PLACEHOLDER',
}

/** columns and relationships of "target_status" */
export type Target_Status = {
	__typename?: 'target_status';
	/** An object relationship */
	Target?: Maybe<Targets>;
	status?: Maybe<Scalars['String']>;
	target_id?: Maybe<Scalars['Int']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
	updated_by?: Maybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "target_status". All fields are combined with a logical 'AND'. */
export type Target_Status_Bool_Exp = {
	Target?: InputMaybe<Targets_Bool_Exp>;
	_and?: InputMaybe<Array<Target_Status_Bool_Exp>>;
	_not?: InputMaybe<Target_Status_Bool_Exp>;
	_or?: InputMaybe<Array<Target_Status_Bool_Exp>>;
	status?: InputMaybe<String_Comparison_Exp>;
	target_id?: InputMaybe<Int_Comparison_Exp>;
	updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	updated_by?: InputMaybe<String_Comparison_Exp>;
};

/** Ordering options when selecting data from "target_status". */
export type Target_Status_Order_By = {
	Target?: InputMaybe<Targets_Order_By>;
	status?: InputMaybe<Order_By>;
	target_id?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	updated_by?: InputMaybe<Order_By>;
};

/** select columns of table "target_status" */
export enum Target_Status_Select_Column {
	/** column name */
	Status = 'status',
	/** column name */
	TargetId = 'target_id',
	/** column name */
	UpdatedAt = 'updated_at',
	/** column name */
	UpdatedBy = 'updated_by',
}

/** Streaming cursor of the table "target_status" */
export type Target_Status_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Target_Status_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Target_Status_Stream_Cursor_Value_Input = {
	status?: InputMaybe<Scalars['String']>;
	target_id?: InputMaybe<Scalars['Int']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	updated_by?: InputMaybe<Scalars['String']>;
};

/** columns and relationships of "target_status_updates" */
export type Target_Status_Updates = {
	__typename?: 'target_status_updates';
	/** An object relationship */
	Target?: Maybe<Targets>;
	id: Scalars['Int'];
	new_status?: Maybe<Scalars['String']>;
	target_id?: Maybe<Scalars['Int']>;
	updated_at?: Maybe<Scalars['timestamptz']>;
	updated_by?: Maybe<Scalars['String']>;
};

/** Boolean expression to filter rows from the table "target_status_updates". All fields are combined with a logical 'AND'. */
export type Target_Status_Updates_Bool_Exp = {
	Target?: InputMaybe<Targets_Bool_Exp>;
	_and?: InputMaybe<Array<Target_Status_Updates_Bool_Exp>>;
	_not?: InputMaybe<Target_Status_Updates_Bool_Exp>;
	_or?: InputMaybe<Array<Target_Status_Updates_Bool_Exp>>;
	id?: InputMaybe<Int_Comparison_Exp>;
	new_status?: InputMaybe<String_Comparison_Exp>;
	target_id?: InputMaybe<Int_Comparison_Exp>;
	updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	updated_by?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "target_status_updates" */
export enum Target_Status_Updates_Constraint {
	/** unique or primary key constraint on columns "id" */
	TargetStatusUpdatesPkey = 'target_status_updates_pkey',
}

/** input type for inserting data into table "target_status_updates" */
export type Target_Status_Updates_Insert_Input = {
	Target?: InputMaybe<Targets_Obj_Rel_Insert_Input>;
	new_status?: InputMaybe<Scalars['String']>;
	target_id?: InputMaybe<Scalars['Int']>;
	updated_by?: InputMaybe<Scalars['String']>;
};

/** response of any mutation on the table "target_status_updates" */
export type Target_Status_Updates_Mutation_Response = {
	__typename?: 'target_status_updates_mutation_response';
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Target_Status_Updates>;
};

/** on_conflict condition type for table "target_status_updates" */
export type Target_Status_Updates_On_Conflict = {
	constraint: Target_Status_Updates_Constraint;
	update_columns?: Array<Target_Status_Updates_Update_Column>;
	where?: InputMaybe<Target_Status_Updates_Bool_Exp>;
};

/** Ordering options when selecting data from "target_status_updates". */
export type Target_Status_Updates_Order_By = {
	Target?: InputMaybe<Targets_Order_By>;
	id?: InputMaybe<Order_By>;
	new_status?: InputMaybe<Order_By>;
	target_id?: InputMaybe<Order_By>;
	updated_at?: InputMaybe<Order_By>;
	updated_by?: InputMaybe<Order_By>;
};

/** select columns of table "target_status_updates" */
export enum Target_Status_Updates_Select_Column {
	/** column name */
	Id = 'id',
	/** column name */
	NewStatus = 'new_status',
	/** column name */
	TargetId = 'target_id',
	/** column name */
	UpdatedAt = 'updated_at',
	/** column name */
	UpdatedBy = 'updated_by',
}

/** Streaming cursor of the table "target_status_updates" */
export type Target_Status_Updates_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Target_Status_Updates_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Target_Status_Updates_Stream_Cursor_Value_Input = {
	id?: InputMaybe<Scalars['Int']>;
	new_status?: InputMaybe<Scalars['String']>;
	target_id?: InputMaybe<Scalars['Int']>;
	updated_at?: InputMaybe<Scalars['timestamptz']>;
	updated_by?: InputMaybe<Scalars['String']>;
};

/** placeholder for update columns of table "target_status_updates" (current role has no relevant permissions) */
export enum Target_Status_Updates_Update_Column {
	/** placeholder (do not use) */
	Placeholder = '_PLACEHOLDER',
}

/** columns and relationships of "targets" */
export type Targets = {
	__typename?: 'targets';
	/** An object relationship */
	Buyer?: Maybe<Buyers>;
	/** An object relationship */
	Delivery?: Maybe<Locations>;
	/** An object relationship */
	FeedPlan?: Maybe<Feed_Plans>;
	/** An object relationship */
	Location?: Maybe<Locations>;
	/** An object relationship */
	Production?: Maybe<Production>;
	/** An object relationship */
	Seller?: Maybe<Sellers>;
	/** An array relationship */
	TargetActivity: Array<Target_Activity>;
	/** An object relationship */
	TargetStatus?: Maybe<Target_Status>;
	basis?: Maybe<Scalars['numeric']>;
	br_owner?: Maybe<Scalars['String']>;
	bushels?: Maybe<Scalars['Int']>;
	buyer_id?: Maybe<Scalars['Int']>;
	contract_number?: Maybe<Scalars['String']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	created_by?: Maybe<Scalars['String']>;
	delivery_end_date?: Maybe<Scalars['date']>;
	delivery_id?: Maybe<Scalars['Int']>;
	delivery_month?: Maybe<Scalars['date']>;
	delivery_start_date?: Maybe<Scalars['date']>;
	expires_at?: Maybe<Scalars['date']>;
	feed_plan_id?: Maybe<Scalars['uuid']>;
	fees?: Maybe<Scalars['numeric']>;
	flex_fee?: Maybe<Scalars['numeric']>;
	future_price?: Maybe<Scalars['numeric']>;
	futures_month?: Maybe<Scalars['date']>;
	hta_fee?: Maybe<Scalars['numeric']>;
	id: Scalars['Int'];
	is_fob?: Maybe<Scalars['Boolean']>;
	location_id?: Maybe<Scalars['Int']>;
	/** An array relationship */
	order_activity: Array<Order_Activity>;
	production_id?: Maybe<Scalars['Int']>;
	roll_fee?: Maybe<Scalars['numeric']>;
	sales_type?: Maybe<Scalars['String']>;
	seller_id?: Maybe<Scalars['uuid']>;
	spread_gain?: Maybe<Scalars['numeric']>;
	status: Scalars['String'];
	status_updated_at: Scalars['timestamptz'];
};

/** columns and relationships of "targets" */
export type TargetsTargetActivityArgs = {
	distinct_on?: InputMaybe<Array<Target_Activity_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Target_Activity_Order_By>>;
	where?: InputMaybe<Target_Activity_Bool_Exp>;
};

/** columns and relationships of "targets" */
export type TargetsOrder_ActivityArgs = {
	distinct_on?: InputMaybe<Array<Order_Activity_Select_Column>>;
	limit?: InputMaybe<Scalars['Int']>;
	offset?: InputMaybe<Scalars['Int']>;
	order_by?: InputMaybe<Array<Order_Activity_Order_By>>;
	where?: InputMaybe<Order_Activity_Bool_Exp>;
};

/** aggregated selection of "targets" */
export type Targets_Aggregate = {
	__typename?: 'targets_aggregate';
	aggregate?: Maybe<Targets_Aggregate_Fields>;
	nodes: Array<Targets>;
};

export type Targets_Aggregate_Bool_Exp = {
	bool_and?: InputMaybe<Targets_Aggregate_Bool_Exp_Bool_And>;
	bool_or?: InputMaybe<Targets_Aggregate_Bool_Exp_Bool_Or>;
	count?: InputMaybe<Targets_Aggregate_Bool_Exp_Count>;
};

export type Targets_Aggregate_Bool_Exp_Bool_And = {
	arguments: Targets_Select_Column_Targets_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Targets_Bool_Exp>;
	predicate: Boolean_Comparison_Exp;
};

export type Targets_Aggregate_Bool_Exp_Bool_Or = {
	arguments: Targets_Select_Column_Targets_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Targets_Bool_Exp>;
	predicate: Boolean_Comparison_Exp;
};

export type Targets_Aggregate_Bool_Exp_Count = {
	arguments?: InputMaybe<Array<Targets_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
	filter?: InputMaybe<Targets_Bool_Exp>;
	predicate: Int_Comparison_Exp;
};

/** aggregate fields of "targets" */
export type Targets_Aggregate_Fields = {
	__typename?: 'targets_aggregate_fields';
	avg?: Maybe<Targets_Avg_Fields>;
	count: Scalars['Int'];
	max?: Maybe<Targets_Max_Fields>;
	min?: Maybe<Targets_Min_Fields>;
	stddev?: Maybe<Targets_Stddev_Fields>;
	stddev_pop?: Maybe<Targets_Stddev_Pop_Fields>;
	stddev_samp?: Maybe<Targets_Stddev_Samp_Fields>;
	sum?: Maybe<Targets_Sum_Fields>;
	var_pop?: Maybe<Targets_Var_Pop_Fields>;
	var_samp?: Maybe<Targets_Var_Samp_Fields>;
	variance?: Maybe<Targets_Variance_Fields>;
};

/** aggregate fields of "targets" */
export type Targets_Aggregate_FieldsCountArgs = {
	columns?: InputMaybe<Array<Targets_Select_Column>>;
	distinct?: InputMaybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "targets" */
export type Targets_Aggregate_Order_By = {
	avg?: InputMaybe<Targets_Avg_Order_By>;
	count?: InputMaybe<Order_By>;
	max?: InputMaybe<Targets_Max_Order_By>;
	min?: InputMaybe<Targets_Min_Order_By>;
	stddev?: InputMaybe<Targets_Stddev_Order_By>;
	stddev_pop?: InputMaybe<Targets_Stddev_Pop_Order_By>;
	stddev_samp?: InputMaybe<Targets_Stddev_Samp_Order_By>;
	sum?: InputMaybe<Targets_Sum_Order_By>;
	var_pop?: InputMaybe<Targets_Var_Pop_Order_By>;
	var_samp?: InputMaybe<Targets_Var_Samp_Order_By>;
	variance?: InputMaybe<Targets_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "targets" */
export type Targets_Arr_Rel_Insert_Input = {
	data: Array<Targets_Insert_Input>;
	/** upsert condition */
	on_conflict?: InputMaybe<Targets_On_Conflict>;
};

/** aggregate avg on columns */
export type Targets_Avg_Fields = {
	__typename?: 'targets_avg_fields';
	basis?: Maybe<Scalars['Float']>;
	bushels?: Maybe<Scalars['Float']>;
	buyer_id?: Maybe<Scalars['Float']>;
	delivery_id?: Maybe<Scalars['Float']>;
	fees?: Maybe<Scalars['Float']>;
	flex_fee?: Maybe<Scalars['Float']>;
	future_price?: Maybe<Scalars['Float']>;
	hta_fee?: Maybe<Scalars['Float']>;
	id?: Maybe<Scalars['Float']>;
	location_id?: Maybe<Scalars['Float']>;
	production_id?: Maybe<Scalars['Float']>;
	roll_fee?: Maybe<Scalars['Float']>;
	spread_gain?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "targets" */
export type Targets_Avg_Order_By = {
	basis?: InputMaybe<Order_By>;
	bushels?: InputMaybe<Order_By>;
	buyer_id?: InputMaybe<Order_By>;
	delivery_id?: InputMaybe<Order_By>;
	fees?: InputMaybe<Order_By>;
	flex_fee?: InputMaybe<Order_By>;
	future_price?: InputMaybe<Order_By>;
	hta_fee?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	location_id?: InputMaybe<Order_By>;
	production_id?: InputMaybe<Order_By>;
	roll_fee?: InputMaybe<Order_By>;
	spread_gain?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "targets". All fields are combined with a logical 'AND'. */
export type Targets_Bool_Exp = {
	Buyer?: InputMaybe<Buyers_Bool_Exp>;
	Delivery?: InputMaybe<Locations_Bool_Exp>;
	FeedPlan?: InputMaybe<Feed_Plans_Bool_Exp>;
	Location?: InputMaybe<Locations_Bool_Exp>;
	Production?: InputMaybe<Production_Bool_Exp>;
	Seller?: InputMaybe<Sellers_Bool_Exp>;
	TargetActivity?: InputMaybe<Target_Activity_Bool_Exp>;
	TargetStatus?: InputMaybe<Target_Status_Bool_Exp>;
	_and?: InputMaybe<Array<Targets_Bool_Exp>>;
	_not?: InputMaybe<Targets_Bool_Exp>;
	_or?: InputMaybe<Array<Targets_Bool_Exp>>;
	basis?: InputMaybe<Numeric_Comparison_Exp>;
	br_owner?: InputMaybe<String_Comparison_Exp>;
	bushels?: InputMaybe<Int_Comparison_Exp>;
	buyer_id?: InputMaybe<Int_Comparison_Exp>;
	contract_number?: InputMaybe<String_Comparison_Exp>;
	created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
	created_by?: InputMaybe<String_Comparison_Exp>;
	delivery_end_date?: InputMaybe<Date_Comparison_Exp>;
	delivery_id?: InputMaybe<Int_Comparison_Exp>;
	delivery_month?: InputMaybe<Date_Comparison_Exp>;
	delivery_start_date?: InputMaybe<Date_Comparison_Exp>;
	expires_at?: InputMaybe<Date_Comparison_Exp>;
	feed_plan_id?: InputMaybe<Uuid_Comparison_Exp>;
	fees?: InputMaybe<Numeric_Comparison_Exp>;
	flex_fee?: InputMaybe<Numeric_Comparison_Exp>;
	future_price?: InputMaybe<Numeric_Comparison_Exp>;
	futures_month?: InputMaybe<Date_Comparison_Exp>;
	hta_fee?: InputMaybe<Numeric_Comparison_Exp>;
	id?: InputMaybe<Int_Comparison_Exp>;
	is_fob?: InputMaybe<Boolean_Comparison_Exp>;
	location_id?: InputMaybe<Int_Comparison_Exp>;
	order_activity?: InputMaybe<Order_Activity_Bool_Exp>;
	production_id?: InputMaybe<Int_Comparison_Exp>;
	roll_fee?: InputMaybe<Numeric_Comparison_Exp>;
	sales_type?: InputMaybe<String_Comparison_Exp>;
	seller_id?: InputMaybe<Uuid_Comparison_Exp>;
	spread_gain?: InputMaybe<Numeric_Comparison_Exp>;
	status?: InputMaybe<String_Comparison_Exp>;
	status_updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "targets" */
export enum Targets_Constraint {
	/** unique or primary key constraint on columns "id" */
	TargetsPkey = 'targets_pkey',
}

/** input type for incrementing numeric columns in table "targets" */
export type Targets_Inc_Input = {
	basis?: InputMaybe<Scalars['numeric']>;
	bushels?: InputMaybe<Scalars['Int']>;
	buyer_id?: InputMaybe<Scalars['Int']>;
	delivery_id?: InputMaybe<Scalars['Int']>;
	flex_fee?: InputMaybe<Scalars['numeric']>;
	future_price?: InputMaybe<Scalars['numeric']>;
	hta_fee?: InputMaybe<Scalars['numeric']>;
	location_id?: InputMaybe<Scalars['Int']>;
	roll_fee?: InputMaybe<Scalars['numeric']>;
	spread_gain?: InputMaybe<Scalars['numeric']>;
};

/** input type for inserting data into table "targets" */
export type Targets_Insert_Input = {
	Buyer?: InputMaybe<Buyers_Obj_Rel_Insert_Input>;
	Delivery?: InputMaybe<Locations_Obj_Rel_Insert_Input>;
	FeedPlan?: InputMaybe<Feed_Plans_Obj_Rel_Insert_Input>;
	Location?: InputMaybe<Locations_Obj_Rel_Insert_Input>;
	Production?: InputMaybe<Production_Obj_Rel_Insert_Input>;
	Seller?: InputMaybe<Sellers_Obj_Rel_Insert_Input>;
	basis?: InputMaybe<Scalars['numeric']>;
	br_owner?: InputMaybe<Scalars['String']>;
	bushels?: InputMaybe<Scalars['Int']>;
	buyer_id?: InputMaybe<Scalars['Int']>;
	contract_number?: InputMaybe<Scalars['String']>;
	created_by?: InputMaybe<Scalars['String']>;
	delivery_end_date?: InputMaybe<Scalars['date']>;
	delivery_id?: InputMaybe<Scalars['Int']>;
	delivery_month?: InputMaybe<Scalars['date']>;
	delivery_start_date?: InputMaybe<Scalars['date']>;
	expires_at?: InputMaybe<Scalars['date']>;
	feed_plan_id?: InputMaybe<Scalars['uuid']>;
	flex_fee?: InputMaybe<Scalars['numeric']>;
	future_price?: InputMaybe<Scalars['numeric']>;
	futures_month?: InputMaybe<Scalars['date']>;
	hta_fee?: InputMaybe<Scalars['numeric']>;
	is_fob?: InputMaybe<Scalars['Boolean']>;
	location_id?: InputMaybe<Scalars['Int']>;
	production_id?: InputMaybe<Scalars['Int']>;
	roll_fee?: InputMaybe<Scalars['numeric']>;
	sales_type?: InputMaybe<Scalars['String']>;
	seller_id?: InputMaybe<Scalars['uuid']>;
	spread_gain?: InputMaybe<Scalars['numeric']>;
};

/** aggregate max on columns */
export type Targets_Max_Fields = {
	__typename?: 'targets_max_fields';
	basis?: Maybe<Scalars['numeric']>;
	br_owner?: Maybe<Scalars['String']>;
	bushels?: Maybe<Scalars['Int']>;
	buyer_id?: Maybe<Scalars['Int']>;
	contract_number?: Maybe<Scalars['String']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	created_by?: Maybe<Scalars['String']>;
	delivery_end_date?: Maybe<Scalars['date']>;
	delivery_id?: Maybe<Scalars['Int']>;
	delivery_month?: Maybe<Scalars['date']>;
	delivery_start_date?: Maybe<Scalars['date']>;
	expires_at?: Maybe<Scalars['date']>;
	feed_plan_id?: Maybe<Scalars['uuid']>;
	fees?: Maybe<Scalars['numeric']>;
	flex_fee?: Maybe<Scalars['numeric']>;
	future_price?: Maybe<Scalars['numeric']>;
	futures_month?: Maybe<Scalars['date']>;
	hta_fee?: Maybe<Scalars['numeric']>;
	id?: Maybe<Scalars['Int']>;
	location_id?: Maybe<Scalars['Int']>;
	production_id?: Maybe<Scalars['Int']>;
	roll_fee?: Maybe<Scalars['numeric']>;
	sales_type?: Maybe<Scalars['String']>;
	seller_id?: Maybe<Scalars['uuid']>;
	spread_gain?: Maybe<Scalars['numeric']>;
	status?: Maybe<Scalars['String']>;
	status_updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "targets" */
export type Targets_Max_Order_By = {
	basis?: InputMaybe<Order_By>;
	br_owner?: InputMaybe<Order_By>;
	bushels?: InputMaybe<Order_By>;
	buyer_id?: InputMaybe<Order_By>;
	contract_number?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	created_by?: InputMaybe<Order_By>;
	delivery_end_date?: InputMaybe<Order_By>;
	delivery_id?: InputMaybe<Order_By>;
	delivery_month?: InputMaybe<Order_By>;
	delivery_start_date?: InputMaybe<Order_By>;
	expires_at?: InputMaybe<Order_By>;
	feed_plan_id?: InputMaybe<Order_By>;
	fees?: InputMaybe<Order_By>;
	flex_fee?: InputMaybe<Order_By>;
	future_price?: InputMaybe<Order_By>;
	futures_month?: InputMaybe<Order_By>;
	hta_fee?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	location_id?: InputMaybe<Order_By>;
	production_id?: InputMaybe<Order_By>;
	roll_fee?: InputMaybe<Order_By>;
	sales_type?: InputMaybe<Order_By>;
	seller_id?: InputMaybe<Order_By>;
	spread_gain?: InputMaybe<Order_By>;
	status?: InputMaybe<Order_By>;
	status_updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Targets_Min_Fields = {
	__typename?: 'targets_min_fields';
	basis?: Maybe<Scalars['numeric']>;
	br_owner?: Maybe<Scalars['String']>;
	bushels?: Maybe<Scalars['Int']>;
	buyer_id?: Maybe<Scalars['Int']>;
	contract_number?: Maybe<Scalars['String']>;
	created_at?: Maybe<Scalars['timestamptz']>;
	created_by?: Maybe<Scalars['String']>;
	delivery_end_date?: Maybe<Scalars['date']>;
	delivery_id?: Maybe<Scalars['Int']>;
	delivery_month?: Maybe<Scalars['date']>;
	delivery_start_date?: Maybe<Scalars['date']>;
	expires_at?: Maybe<Scalars['date']>;
	feed_plan_id?: Maybe<Scalars['uuid']>;
	fees?: Maybe<Scalars['numeric']>;
	flex_fee?: Maybe<Scalars['numeric']>;
	future_price?: Maybe<Scalars['numeric']>;
	futures_month?: Maybe<Scalars['date']>;
	hta_fee?: Maybe<Scalars['numeric']>;
	id?: Maybe<Scalars['Int']>;
	location_id?: Maybe<Scalars['Int']>;
	production_id?: Maybe<Scalars['Int']>;
	roll_fee?: Maybe<Scalars['numeric']>;
	sales_type?: Maybe<Scalars['String']>;
	seller_id?: Maybe<Scalars['uuid']>;
	spread_gain?: Maybe<Scalars['numeric']>;
	status?: Maybe<Scalars['String']>;
	status_updated_at?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "targets" */
export type Targets_Min_Order_By = {
	basis?: InputMaybe<Order_By>;
	br_owner?: InputMaybe<Order_By>;
	bushels?: InputMaybe<Order_By>;
	buyer_id?: InputMaybe<Order_By>;
	contract_number?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	created_by?: InputMaybe<Order_By>;
	delivery_end_date?: InputMaybe<Order_By>;
	delivery_id?: InputMaybe<Order_By>;
	delivery_month?: InputMaybe<Order_By>;
	delivery_start_date?: InputMaybe<Order_By>;
	expires_at?: InputMaybe<Order_By>;
	feed_plan_id?: InputMaybe<Order_By>;
	fees?: InputMaybe<Order_By>;
	flex_fee?: InputMaybe<Order_By>;
	future_price?: InputMaybe<Order_By>;
	futures_month?: InputMaybe<Order_By>;
	hta_fee?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	location_id?: InputMaybe<Order_By>;
	production_id?: InputMaybe<Order_By>;
	roll_fee?: InputMaybe<Order_By>;
	sales_type?: InputMaybe<Order_By>;
	seller_id?: InputMaybe<Order_By>;
	spread_gain?: InputMaybe<Order_By>;
	status?: InputMaybe<Order_By>;
	status_updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "targets" */
export type Targets_Mutation_Response = {
	__typename?: 'targets_mutation_response';
	/** number of rows affected by the mutation */
	affected_rows: Scalars['Int'];
	/** data from the rows affected by the mutation */
	returning: Array<Targets>;
};

/** input type for inserting object relation for remote table "targets" */
export type Targets_Obj_Rel_Insert_Input = {
	data: Targets_Insert_Input;
	/** upsert condition */
	on_conflict?: InputMaybe<Targets_On_Conflict>;
};

/** on_conflict condition type for table "targets" */
export type Targets_On_Conflict = {
	constraint: Targets_Constraint;
	update_columns?: Array<Targets_Update_Column>;
	where?: InputMaybe<Targets_Bool_Exp>;
};

/** Ordering options when selecting data from "targets". */
export type Targets_Order_By = {
	Buyer?: InputMaybe<Buyers_Order_By>;
	Delivery?: InputMaybe<Locations_Order_By>;
	FeedPlan?: InputMaybe<Feed_Plans_Order_By>;
	Location?: InputMaybe<Locations_Order_By>;
	Production?: InputMaybe<Production_Order_By>;
	Seller?: InputMaybe<Sellers_Order_By>;
	TargetActivity_aggregate?: InputMaybe<Target_Activity_Aggregate_Order_By>;
	TargetStatus?: InputMaybe<Target_Status_Order_By>;
	basis?: InputMaybe<Order_By>;
	br_owner?: InputMaybe<Order_By>;
	bushels?: InputMaybe<Order_By>;
	buyer_id?: InputMaybe<Order_By>;
	contract_number?: InputMaybe<Order_By>;
	created_at?: InputMaybe<Order_By>;
	created_by?: InputMaybe<Order_By>;
	delivery_end_date?: InputMaybe<Order_By>;
	delivery_id?: InputMaybe<Order_By>;
	delivery_month?: InputMaybe<Order_By>;
	delivery_start_date?: InputMaybe<Order_By>;
	expires_at?: InputMaybe<Order_By>;
	feed_plan_id?: InputMaybe<Order_By>;
	fees?: InputMaybe<Order_By>;
	flex_fee?: InputMaybe<Order_By>;
	future_price?: InputMaybe<Order_By>;
	futures_month?: InputMaybe<Order_By>;
	hta_fee?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	is_fob?: InputMaybe<Order_By>;
	location_id?: InputMaybe<Order_By>;
	order_activity_aggregate?: InputMaybe<Order_Activity_Aggregate_Order_By>;
	production_id?: InputMaybe<Order_By>;
	roll_fee?: InputMaybe<Order_By>;
	sales_type?: InputMaybe<Order_By>;
	seller_id?: InputMaybe<Order_By>;
	spread_gain?: InputMaybe<Order_By>;
	status?: InputMaybe<Order_By>;
	status_updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: targets */
export type Targets_Pk_Columns_Input = {
	id: Scalars['Int'];
};

/** select columns of table "targets" */
export enum Targets_Select_Column {
	/** column name */
	Basis = 'basis',
	/** column name */
	BrOwner = 'br_owner',
	/** column name */
	Bushels = 'bushels',
	/** column name */
	BuyerId = 'buyer_id',
	/** column name */
	ContractNumber = 'contract_number',
	/** column name */
	CreatedAt = 'created_at',
	/** column name */
	CreatedBy = 'created_by',
	/** column name */
	DeliveryEndDate = 'delivery_end_date',
	/** column name */
	DeliveryId = 'delivery_id',
	/** column name */
	DeliveryMonth = 'delivery_month',
	/** column name */
	DeliveryStartDate = 'delivery_start_date',
	/** column name */
	ExpiresAt = 'expires_at',
	/** column name */
	FeedPlanId = 'feed_plan_id',
	/** column name */
	Fees = 'fees',
	/** column name */
	FlexFee = 'flex_fee',
	/** column name */
	FuturePrice = 'future_price',
	/** column name */
	FuturesMonth = 'futures_month',
	/** column name */
	HtaFee = 'hta_fee',
	/** column name */
	Id = 'id',
	/** column name */
	IsFob = 'is_fob',
	/** column name */
	LocationId = 'location_id',
	/** column name */
	ProductionId = 'production_id',
	/** column name */
	RollFee = 'roll_fee',
	/** column name */
	SalesType = 'sales_type',
	/** column name */
	SellerId = 'seller_id',
	/** column name */
	SpreadGain = 'spread_gain',
	/** column name */
	Status = 'status',
	/** column name */
	StatusUpdatedAt = 'status_updated_at',
}

/** select "targets_aggregate_bool_exp_bool_and_arguments_columns" columns of table "targets" */
export enum Targets_Select_Column_Targets_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
	/** column name */
	IsFob = 'is_fob',
}

/** select "targets_aggregate_bool_exp_bool_or_arguments_columns" columns of table "targets" */
export enum Targets_Select_Column_Targets_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
	/** column name */
	IsFob = 'is_fob',
}

/** input type for updating data in table "targets" */
export type Targets_Set_Input = {
	basis?: InputMaybe<Scalars['numeric']>;
	br_owner?: InputMaybe<Scalars['String']>;
	bushels?: InputMaybe<Scalars['Int']>;
	buyer_id?: InputMaybe<Scalars['Int']>;
	contract_number?: InputMaybe<Scalars['String']>;
	delivery_end_date?: InputMaybe<Scalars['date']>;
	delivery_id?: InputMaybe<Scalars['Int']>;
	delivery_month?: InputMaybe<Scalars['date']>;
	delivery_start_date?: InputMaybe<Scalars['date']>;
	expires_at?: InputMaybe<Scalars['date']>;
	flex_fee?: InputMaybe<Scalars['numeric']>;
	future_price?: InputMaybe<Scalars['numeric']>;
	futures_month?: InputMaybe<Scalars['date']>;
	hta_fee?: InputMaybe<Scalars['numeric']>;
	is_fob?: InputMaybe<Scalars['Boolean']>;
	location_id?: InputMaybe<Scalars['Int']>;
	roll_fee?: InputMaybe<Scalars['numeric']>;
	sales_type?: InputMaybe<Scalars['String']>;
	seller_id?: InputMaybe<Scalars['uuid']>;
	spread_gain?: InputMaybe<Scalars['numeric']>;
};

/** aggregate stddev on columns */
export type Targets_Stddev_Fields = {
	__typename?: 'targets_stddev_fields';
	basis?: Maybe<Scalars['Float']>;
	bushels?: Maybe<Scalars['Float']>;
	buyer_id?: Maybe<Scalars['Float']>;
	delivery_id?: Maybe<Scalars['Float']>;
	fees?: Maybe<Scalars['Float']>;
	flex_fee?: Maybe<Scalars['Float']>;
	future_price?: Maybe<Scalars['Float']>;
	hta_fee?: Maybe<Scalars['Float']>;
	id?: Maybe<Scalars['Float']>;
	location_id?: Maybe<Scalars['Float']>;
	production_id?: Maybe<Scalars['Float']>;
	roll_fee?: Maybe<Scalars['Float']>;
	spread_gain?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "targets" */
export type Targets_Stddev_Order_By = {
	basis?: InputMaybe<Order_By>;
	bushels?: InputMaybe<Order_By>;
	buyer_id?: InputMaybe<Order_By>;
	delivery_id?: InputMaybe<Order_By>;
	fees?: InputMaybe<Order_By>;
	flex_fee?: InputMaybe<Order_By>;
	future_price?: InputMaybe<Order_By>;
	hta_fee?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	location_id?: InputMaybe<Order_By>;
	production_id?: InputMaybe<Order_By>;
	roll_fee?: InputMaybe<Order_By>;
	spread_gain?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Targets_Stddev_Pop_Fields = {
	__typename?: 'targets_stddev_pop_fields';
	basis?: Maybe<Scalars['Float']>;
	bushels?: Maybe<Scalars['Float']>;
	buyer_id?: Maybe<Scalars['Float']>;
	delivery_id?: Maybe<Scalars['Float']>;
	fees?: Maybe<Scalars['Float']>;
	flex_fee?: Maybe<Scalars['Float']>;
	future_price?: Maybe<Scalars['Float']>;
	hta_fee?: Maybe<Scalars['Float']>;
	id?: Maybe<Scalars['Float']>;
	location_id?: Maybe<Scalars['Float']>;
	production_id?: Maybe<Scalars['Float']>;
	roll_fee?: Maybe<Scalars['Float']>;
	spread_gain?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "targets" */
export type Targets_Stddev_Pop_Order_By = {
	basis?: InputMaybe<Order_By>;
	bushels?: InputMaybe<Order_By>;
	buyer_id?: InputMaybe<Order_By>;
	delivery_id?: InputMaybe<Order_By>;
	fees?: InputMaybe<Order_By>;
	flex_fee?: InputMaybe<Order_By>;
	future_price?: InputMaybe<Order_By>;
	hta_fee?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	location_id?: InputMaybe<Order_By>;
	production_id?: InputMaybe<Order_By>;
	roll_fee?: InputMaybe<Order_By>;
	spread_gain?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Targets_Stddev_Samp_Fields = {
	__typename?: 'targets_stddev_samp_fields';
	basis?: Maybe<Scalars['Float']>;
	bushels?: Maybe<Scalars['Float']>;
	buyer_id?: Maybe<Scalars['Float']>;
	delivery_id?: Maybe<Scalars['Float']>;
	fees?: Maybe<Scalars['Float']>;
	flex_fee?: Maybe<Scalars['Float']>;
	future_price?: Maybe<Scalars['Float']>;
	hta_fee?: Maybe<Scalars['Float']>;
	id?: Maybe<Scalars['Float']>;
	location_id?: Maybe<Scalars['Float']>;
	production_id?: Maybe<Scalars['Float']>;
	roll_fee?: Maybe<Scalars['Float']>;
	spread_gain?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "targets" */
export type Targets_Stddev_Samp_Order_By = {
	basis?: InputMaybe<Order_By>;
	bushels?: InputMaybe<Order_By>;
	buyer_id?: InputMaybe<Order_By>;
	delivery_id?: InputMaybe<Order_By>;
	fees?: InputMaybe<Order_By>;
	flex_fee?: InputMaybe<Order_By>;
	future_price?: InputMaybe<Order_By>;
	hta_fee?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	location_id?: InputMaybe<Order_By>;
	production_id?: InputMaybe<Order_By>;
	roll_fee?: InputMaybe<Order_By>;
	spread_gain?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "targets" */
export type Targets_Stream_Cursor_Input = {
	/** Stream column input with initial value */
	initial_value: Targets_Stream_Cursor_Value_Input;
	/** cursor ordering */
	ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Targets_Stream_Cursor_Value_Input = {
	basis?: InputMaybe<Scalars['numeric']>;
	br_owner?: InputMaybe<Scalars['String']>;
	bushels?: InputMaybe<Scalars['Int']>;
	buyer_id?: InputMaybe<Scalars['Int']>;
	contract_number?: InputMaybe<Scalars['String']>;
	created_at?: InputMaybe<Scalars['timestamptz']>;
	created_by?: InputMaybe<Scalars['String']>;
	delivery_end_date?: InputMaybe<Scalars['date']>;
	delivery_id?: InputMaybe<Scalars['Int']>;
	delivery_month?: InputMaybe<Scalars['date']>;
	delivery_start_date?: InputMaybe<Scalars['date']>;
	expires_at?: InputMaybe<Scalars['date']>;
	feed_plan_id?: InputMaybe<Scalars['uuid']>;
	fees?: InputMaybe<Scalars['numeric']>;
	flex_fee?: InputMaybe<Scalars['numeric']>;
	future_price?: InputMaybe<Scalars['numeric']>;
	futures_month?: InputMaybe<Scalars['date']>;
	hta_fee?: InputMaybe<Scalars['numeric']>;
	id?: InputMaybe<Scalars['Int']>;
	is_fob?: InputMaybe<Scalars['Boolean']>;
	location_id?: InputMaybe<Scalars['Int']>;
	production_id?: InputMaybe<Scalars['Int']>;
	roll_fee?: InputMaybe<Scalars['numeric']>;
	sales_type?: InputMaybe<Scalars['String']>;
	seller_id?: InputMaybe<Scalars['uuid']>;
	spread_gain?: InputMaybe<Scalars['numeric']>;
	status?: InputMaybe<Scalars['String']>;
	status_updated_at?: InputMaybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type Targets_Sum_Fields = {
	__typename?: 'targets_sum_fields';
	basis?: Maybe<Scalars['numeric']>;
	bushels?: Maybe<Scalars['Int']>;
	buyer_id?: Maybe<Scalars['Int']>;
	delivery_id?: Maybe<Scalars['Int']>;
	fees?: Maybe<Scalars['numeric']>;
	flex_fee?: Maybe<Scalars['numeric']>;
	future_price?: Maybe<Scalars['numeric']>;
	hta_fee?: Maybe<Scalars['numeric']>;
	id?: Maybe<Scalars['Int']>;
	location_id?: Maybe<Scalars['Int']>;
	production_id?: Maybe<Scalars['Int']>;
	roll_fee?: Maybe<Scalars['numeric']>;
	spread_gain?: Maybe<Scalars['numeric']>;
};

/** order by sum() on columns of table "targets" */
export type Targets_Sum_Order_By = {
	basis?: InputMaybe<Order_By>;
	bushels?: InputMaybe<Order_By>;
	buyer_id?: InputMaybe<Order_By>;
	delivery_id?: InputMaybe<Order_By>;
	fees?: InputMaybe<Order_By>;
	flex_fee?: InputMaybe<Order_By>;
	future_price?: InputMaybe<Order_By>;
	hta_fee?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	location_id?: InputMaybe<Order_By>;
	production_id?: InputMaybe<Order_By>;
	roll_fee?: InputMaybe<Order_By>;
	spread_gain?: InputMaybe<Order_By>;
};

/** update columns of table "targets" */
export enum Targets_Update_Column {
	/** column name */
	Basis = 'basis',
	/** column name */
	BrOwner = 'br_owner',
	/** column name */
	Bushels = 'bushels',
	/** column name */
	BuyerId = 'buyer_id',
	/** column name */
	ContractNumber = 'contract_number',
	/** column name */
	DeliveryEndDate = 'delivery_end_date',
	/** column name */
	DeliveryId = 'delivery_id',
	/** column name */
	DeliveryMonth = 'delivery_month',
	/** column name */
	DeliveryStartDate = 'delivery_start_date',
	/** column name */
	ExpiresAt = 'expires_at',
	/** column name */
	FlexFee = 'flex_fee',
	/** column name */
	FuturePrice = 'future_price',
	/** column name */
	FuturesMonth = 'futures_month',
	/** column name */
	HtaFee = 'hta_fee',
	/** column name */
	IsFob = 'is_fob',
	/** column name */
	LocationId = 'location_id',
	/** column name */
	RollFee = 'roll_fee',
	/** column name */
	SalesType = 'sales_type',
	/** column name */
	SellerId = 'seller_id',
	/** column name */
	SpreadGain = 'spread_gain',
}

export type Targets_Updates = {
	/** increments the numeric columns with given value of the filtered values */
	_inc?: InputMaybe<Targets_Inc_Input>;
	/** sets the columns of the filtered rows to the given values */
	_set?: InputMaybe<Targets_Set_Input>;
	/** filter the rows which have to be updated */
	where: Targets_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Targets_Var_Pop_Fields = {
	__typename?: 'targets_var_pop_fields';
	basis?: Maybe<Scalars['Float']>;
	bushels?: Maybe<Scalars['Float']>;
	buyer_id?: Maybe<Scalars['Float']>;
	delivery_id?: Maybe<Scalars['Float']>;
	fees?: Maybe<Scalars['Float']>;
	flex_fee?: Maybe<Scalars['Float']>;
	future_price?: Maybe<Scalars['Float']>;
	hta_fee?: Maybe<Scalars['Float']>;
	id?: Maybe<Scalars['Float']>;
	location_id?: Maybe<Scalars['Float']>;
	production_id?: Maybe<Scalars['Float']>;
	roll_fee?: Maybe<Scalars['Float']>;
	spread_gain?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "targets" */
export type Targets_Var_Pop_Order_By = {
	basis?: InputMaybe<Order_By>;
	bushels?: InputMaybe<Order_By>;
	buyer_id?: InputMaybe<Order_By>;
	delivery_id?: InputMaybe<Order_By>;
	fees?: InputMaybe<Order_By>;
	flex_fee?: InputMaybe<Order_By>;
	future_price?: InputMaybe<Order_By>;
	hta_fee?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	location_id?: InputMaybe<Order_By>;
	production_id?: InputMaybe<Order_By>;
	roll_fee?: InputMaybe<Order_By>;
	spread_gain?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Targets_Var_Samp_Fields = {
	__typename?: 'targets_var_samp_fields';
	basis?: Maybe<Scalars['Float']>;
	bushels?: Maybe<Scalars['Float']>;
	buyer_id?: Maybe<Scalars['Float']>;
	delivery_id?: Maybe<Scalars['Float']>;
	fees?: Maybe<Scalars['Float']>;
	flex_fee?: Maybe<Scalars['Float']>;
	future_price?: Maybe<Scalars['Float']>;
	hta_fee?: Maybe<Scalars['Float']>;
	id?: Maybe<Scalars['Float']>;
	location_id?: Maybe<Scalars['Float']>;
	production_id?: Maybe<Scalars['Float']>;
	roll_fee?: Maybe<Scalars['Float']>;
	spread_gain?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "targets" */
export type Targets_Var_Samp_Order_By = {
	basis?: InputMaybe<Order_By>;
	bushels?: InputMaybe<Order_By>;
	buyer_id?: InputMaybe<Order_By>;
	delivery_id?: InputMaybe<Order_By>;
	fees?: InputMaybe<Order_By>;
	flex_fee?: InputMaybe<Order_By>;
	future_price?: InputMaybe<Order_By>;
	hta_fee?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	location_id?: InputMaybe<Order_By>;
	production_id?: InputMaybe<Order_By>;
	roll_fee?: InputMaybe<Order_By>;
	spread_gain?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Targets_Variance_Fields = {
	__typename?: 'targets_variance_fields';
	basis?: Maybe<Scalars['Float']>;
	bushels?: Maybe<Scalars['Float']>;
	buyer_id?: Maybe<Scalars['Float']>;
	delivery_id?: Maybe<Scalars['Float']>;
	fees?: Maybe<Scalars['Float']>;
	flex_fee?: Maybe<Scalars['Float']>;
	future_price?: Maybe<Scalars['Float']>;
	hta_fee?: Maybe<Scalars['Float']>;
	id?: Maybe<Scalars['Float']>;
	location_id?: Maybe<Scalars['Float']>;
	production_id?: Maybe<Scalars['Float']>;
	roll_fee?: Maybe<Scalars['Float']>;
	spread_gain?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "targets" */
export type Targets_Variance_Order_By = {
	basis?: InputMaybe<Order_By>;
	bushels?: InputMaybe<Order_By>;
	buyer_id?: InputMaybe<Order_By>;
	delivery_id?: InputMaybe<Order_By>;
	fees?: InputMaybe<Order_By>;
	flex_fee?: InputMaybe<Order_By>;
	future_price?: InputMaybe<Order_By>;
	hta_fee?: InputMaybe<Order_By>;
	id?: InputMaybe<Order_By>;
	location_id?: InputMaybe<Order_By>;
	production_id?: InputMaybe<Order_By>;
	roll_fee?: InputMaybe<Order_By>;
	spread_gain?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
	_eq?: InputMaybe<Scalars['timestamptz']>;
	_gt?: InputMaybe<Scalars['timestamptz']>;
	_gte?: InputMaybe<Scalars['timestamptz']>;
	_in?: InputMaybe<Array<Scalars['timestamptz']>>;
	_is_null?: InputMaybe<Scalars['Boolean']>;
	_lt?: InputMaybe<Scalars['timestamptz']>;
	_lte?: InputMaybe<Scalars['timestamptz']>;
	_neq?: InputMaybe<Scalars['timestamptz']>;
	_nin?: InputMaybe<Array<Scalars['timestamptz']>>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
	_eq?: InputMaybe<Scalars['uuid']>;
	_gt?: InputMaybe<Scalars['uuid']>;
	_gte?: InputMaybe<Scalars['uuid']>;
	_in?: InputMaybe<Array<Scalars['uuid']>>;
	_is_null?: InputMaybe<Scalars['Boolean']>;
	_lt?: InputMaybe<Scalars['uuid']>;
	_lte?: InputMaybe<Scalars['uuid']>;
	_neq?: InputMaybe<Scalars['uuid']>;
	_nin?: InputMaybe<Array<Scalars['uuid']>>;
};
