import Component from '@glimmer/component';

interface UiConfirmationArgs {
	modalContainerId: string;
}

export default class UiConfirmation extends Component<UiConfirmationArgs> {
	_modalContainerId = 'modal_root';

	get modalContainer(): HTMLElement | null {
		const element = this.args.modalContainerId
			? document.getElementById(this.args.modalContainerId)
			: document.getElementById(this._modalContainerId);
		return element;
	}

	// handling for if do not find HTMLElement to pass to in-element
	get renderOut() {
		return this.modalContainer ? true : false;
	}
}
