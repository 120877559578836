import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { gql, useQuery } from 'glimmer-apollo';
import { GrainLocation } from 'vault-client/types/graphql-types';

const GET_GRAIN_LOCATIONS = gql`
	query GrainLocations {
		GrainLocations(orderBy: { name: Asc }) {
			id
			name
		}
	}
`;

interface GrainLocationSelectArgs {
	stateAbbreviation: string;
	selected: string;
	selectedId: string;
}

type LocationsQuery = {
	__typename?: 'Query';
	GrainLocations: GrainLocation[];
};

export default class GrainLocationSelect extends Component<GrainLocationSelectArgs> {
	@tracked locations: GrainLocation[] | null = [];
	@tracked selected: string | GrainLocation | null | undefined = this.args.selected;

	get currentlySelected() {
		if (this.args.selected) {
			return this.args.selected;
		}

		if (this.args.selectedId) {
			return this.locations?.find((location) => location.id == this.args.selectedId);
		}

		return null;
	}
	set currentlySelected(value) {
		this.selected = value;
	}

	@action
	async fetchLocations() {
		await useQuery<LocationsQuery>(this, () => [
			GET_GRAIN_LOCATIONS,
			{
				onComplete: (data) => {
					if (data?.GrainLocations) {
						this.locations = data.GrainLocations;
					} else {
						this.locations = [];
					}
				},
				onError: (error) => {
					console.error('Error on Location select', error.message);
				},
			},
		]).promise;
	}
}
