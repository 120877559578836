import { gql } from 'glimmer-apollo';
import SearchFilterComponent from 'vault-client/components/vault/ui-search-filter';

const GET_SELLER = gql`
	query GetSeller($id: String!) {
		Seller(id: $id) {
			id
			name
		}
	}
`;

export default class SellerIdFilterComponent extends SearchFilterComponent {
	query = GET_SELLER;
	valuePath = 'Seller.name';
}
