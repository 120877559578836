import Route from '@ember/routing/route';
import { gql, useQuery } from 'glimmer-apollo';
import { EntityGroup, Query_EntityGroupArgs } from 'vault-client/types/graphql-types';
import { tracked } from '@glimmer/tracking';

const GET_ENTITY_GROUP_QUERY = gql`
	query EntityGroup($id: String!) {
		EntityGroup(id: $id) {
			id
			name
			isVgs
			createdAt
			updatedAt
			organizationId
			hasWriteAccess
			Members {
				id
				permission
				userEmail
				canReadBrokerage
				canWriteBrokerage
				canReadFeedAndCropPlan
				canWriteFeedAndCropPlan
				canReadInsurance
				canWriteInsurance
				canReadOperations
				canWriteOperations
				canReadSwap
				canWriteSwap
				canReadOrganization
				canWriteOrganization
				canReadBusiness
				canWriteBusiness
				canReadEntityGroup
				canWriteEntityGroup
				User {
					id
					firstName
					lastName
					email
				}
			}
			CurrentUserPermissions {
				canReadBrokerage
				canWriteBrokerage
				canReadInsurance
				canWriteInsurance
				canReadFeedAndCropPlan
				canWriteFeedAndCropPlan
				canReadOperations
				canWriteOperations
				canReadSwap
				canWriteSwap
				canReadOrganization
				canWriteOrganization
				canReadBusiness
				canWriteBusiness
				canReadEntityGroup
				canWriteEntityGroup
			}
		}
	}
`;

type GetEntityGroupQuery = {
	__typename?: 'Query';

	EntityGroup: EntityGroup;
};
export default class PermissionGroupsShowRoute extends Route {
	@tracked variables: Query_EntityGroupArgs = { id: '' };

	templateName = 'permission-groups/show';

	getEntityGroup = useQuery<GetEntityGroupQuery, Query_EntityGroupArgs>(this, () => [
		GET_ENTITY_GROUP_QUERY,
		{
			variables: this.variables,
		},
	]);

	async model(params: { entity_group_id: string }) {
		this.variables = { id: params.entity_group_id };
		await this.getEntityGroup.promise;

		return this.getEntityGroup;
	}
}
