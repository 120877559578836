import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import {
	GrainFillOrderModificationRequest,
	TypeOfGrainFillOrderModification,
	TypeOfGrainPlan,
	TypeOfGrainOrderSales,
} from 'vault-client/types/graphql-types';
interface OrderModificationItemTableArgs {
	modification: GrainFillOrderModificationRequest;
}

type ModificationRequestObj = {
	name: string;
	current: string | number | undefined | null;
	requested: string | number | undefined | null;
	format?: string | number | null;
	minimumFractionDigits?: number;
	maximumFractionDigits?: number;
};

export default class OrderModificationItemTable extends Component<OrderModificationItemTableArgs> {
	@tracked modificationRequest = this.args.modification;

	get planType() {
		return this.modificationRequest.GrainFillOrder.Plan?.type;
	}

	get orderModificationRequests() {
		const modificationType = this.modificationRequest.type;
		const salesType = this.modificationRequest.GrainFillOrder.salesType;

		// Always show Bushels/Tons column whether there was a request to modify or not.
		const orderModificationRequestObjectsArray: ModificationRequestObj[] = [
			{
				name: 'Bushels',
				current: this.modificationRequest.GrainFillOrder.bushels,
				requested: this.modificationRequest.data.bushels || this.modificationRequest.GrainFillOrder.bushels,
				format: 'number',
			},
			{
				name: `Buyer`,
				current: this.modificationRequest.GrainFillOrder.Buyer?.name,
				requested: this.modificationRequest.Buyer?.name || this.modificationRequest.GrainFillOrder.Buyer?.name,
				format: 'string',
			},
			{
				name: `Location`,
				current: this.modificationRequest.GrainFillOrder.Location?.name,
				requested: this.modificationRequest.Location?.name || this.modificationRequest.GrainFillOrder.Location?.name,
				format: 'string',
			},
		];

		// arrays of applicable modification types for each fill property to display in table
		const futureMonthsTypes = [
			TypeOfGrainFillOrderModification.Roll,
			TypeOfGrainFillOrderModification.RollOffer,
			TypeOfGrainFillOrderModification.RollMarket,
			TypeOfGrainFillOrderModification.LiftAndSellCash,
			TypeOfGrainFillOrderModification.LiftAndSellHta,
			TypeOfGrainFillOrderModification.SetFuturesOffer,
			TypeOfGrainFillOrderModification.RollMarketAndSetBasis,
			TypeOfGrainFillOrderModification.SetFuturesMarket,
			TypeOfGrainFillOrderModification.RollMarketAndSetFuturesMarket,
			TypeOfGrainFillOrderModification.RollMarketAndSetFuturesOffer,
			TypeOfGrainFillOrderModification.SetCashOffer,
		];
		const deliveryStartTypes = [
			TypeOfGrainFillOrderModification.Roll,
			TypeOfGrainFillOrderModification.LiftAndSellCash,
			TypeOfGrainFillOrderModification.SetBasis,
			TypeOfGrainFillOrderModification.ExchangeCash,
			TypeOfGrainFillOrderModification.RollMarketAndSetBasis,
			TypeOfGrainFillOrderModification.RollMarketAndSetFuturesMarket,
			TypeOfGrainFillOrderModification.RollMarketAndSetFuturesOffer,
			TypeOfGrainFillOrderModification.SetCashMarket,
			TypeOfGrainFillOrderModification.SetCashOffer,
		];

		const deliveryEndTypes = [
			TypeOfGrainFillOrderModification.Roll,
			TypeOfGrainFillOrderModification.LiftAndSellCash,
			TypeOfGrainFillOrderModification.SetBasis,
			TypeOfGrainFillOrderModification.ExchangeCash,
			TypeOfGrainFillOrderModification.RollMarketAndSetBasis,
			TypeOfGrainFillOrderModification.RollMarketAndSetFuturesMarket,
			TypeOfGrainFillOrderModification.RollMarketAndSetFuturesOffer,
			TypeOfGrainFillOrderModification.SetCashMarket,
			TypeOfGrainFillOrderModification.SetCashOffer,
		];

		const spreadGainTypes = [TypeOfGrainFillOrderModification.Roll, TypeOfGrainFillOrderModification.RollOffer];
		const basisTypes = [
			TypeOfGrainFillOrderModification.SetBasis,
			TypeOfGrainFillOrderModification.LiftAndSellCash,
			TypeOfGrainFillOrderModification.ExchangeCash,
			TypeOfGrainFillOrderModification.RollMarketAndSetBasis,
			TypeOfGrainFillOrderModification.SetCashOffer,
		];
		const futurePriceTypes = [
			TypeOfGrainFillOrderModification.SetFuturesOffer,
			TypeOfGrainFillOrderModification.RollMarketAndSetFuturesOffer,
			TypeOfGrainFillOrderModification.SetCashOffer,
		];

		// Include delivery start and end date for Roll Market but only when sales type is Basis
		if (salesType === TypeOfGrainOrderSales.Basis) {
			deliveryStartTypes.push(TypeOfGrainFillOrderModification.RollMarket);
			deliveryEndTypes.push(TypeOfGrainFillOrderModification.RollMarket);
		}

		// Include delivery start and end date for feed Set Future order modifications
		if (this.planType === TypeOfGrainPlan.Feed) {
			deliveryStartTypes.push(TypeOfGrainFillOrderModification.SetFuturesOffer);
			deliveryEndTypes.push(TypeOfGrainFillOrderModification.SetFuturesOffer);
		}

		if (futureMonthsTypes.find((type) => type === modificationType)) {
			orderModificationRequestObjectsArray.push({
				name: `Future Months`,
				current: this.modificationRequest.GrainFillOrder.futuresMonth,
				requested: this.modificationRequest.data.futuresMonth || this.modificationRequest.GrainFillOrder.futuresMonth,
				format: 'month',
			});
		}

		if (deliveryStartTypes.find((type) => type === modificationType)) {
			orderModificationRequestObjectsArray.push({
				name: `Delivery Start`,
				current: this.modificationRequest.GrainFillOrder.deliveryStartDate,
				requested: this.modificationRequest.data.deliveryStartDate || this.modificationRequest.GrainFillOrder.deliveryStartDate,
				format: 'date',
			});
		}

		if (deliveryEndTypes.find((type) => type === modificationType)) {
			orderModificationRequestObjectsArray.push({
				name: `Delivery End`,
				current: this.modificationRequest.GrainFillOrder.deliveryEndDate,
				requested: this.modificationRequest.data.deliveryEndDate || this.modificationRequest.GrainFillOrder.deliveryEndDate,
				format: 'date',
			});
		}

		if (spreadGainTypes.find((type) => type === modificationType && this.planType === TypeOfGrainPlan.Crop)) {
			orderModificationRequestObjectsArray.push({
				name: `Spread Gain`,
				current: this.modificationRequest.GrainFillOrder.spread,
				requested: this.modificationRequest.data.spread ?? this.modificationRequest.GrainFillOrder.spread,
				format: 'currency',
			});
		}

		if (basisTypes.find((type) => type === modificationType)) {
			orderModificationRequestObjectsArray.push({
				name: `Basis`,
				current: this.modificationRequest.GrainFillOrder.basis,
				requested: this.modificationRequest.data.basis ?? this.modificationRequest.GrainFillOrder.basis,
				format: 'currency',
			});
		}

		if (futurePriceTypes.find((type) => type === modificationType)) {
			orderModificationRequestObjectsArray.push({
				name: `Future Price`,
				current: this.modificationRequest.GrainFillOrder.futurePrice,
				requested: this.modificationRequest.data.futurePrice || this.modificationRequest.GrainFillOrder.futurePrice,
				format: 'currency',
			});
		}

		return orderModificationRequestObjectsArray;
	}
}
