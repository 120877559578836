/**
 * Remove the first set of barchart results that use a default price by removing the zero volume results until the first result with volume is available.
 * @param fetchResults
 * @returns
 */

interface historicFuture {
	symbol: string;
	close: number;
	tradingDay: string;
	volume: number;
}

export default function removeResultsWithDefaultPrices(fetchResults: Array<historicFuture>) {
	let hadVolume = false;
	const priceResults = [];
	let price;
	for (let i = 0; i < fetchResults.length; i++) {
		price = fetchResults[i];
		if (hadVolume || price.volume > 0) {
			priceResults.push(price);
		}

		if (price.volume > 0) {
			hadVolume = true;
		}
	}
	return priceResults;
}
