import UnmappedInsurancePoliciesRoute from 'vault-client/routes/reports/unmapped-insurance-policies';
import { SortObj } from 'vault-client/types/vault-table';
import { inject as service } from '@ember/service';
import ApplicationScope from 'vault-client/services/application-scope';

interface ModelParams {
	aipId?: string | null;
	agent?: string | null;
	organizationId: string | null;
	page: number;
	size: number;
	policyNumber?: string | null;
	sorts: SortObj[];
	stateId?: string | null;
}

export default class BusinessesBusinessUnmappedInsurancePoliciesRoute extends UnmappedInsurancePoliciesRoute {
	@service declare applicationScope: ApplicationScope;

	async model(params: ModelParams) {
		const variables = {
			where: this.generateWhere(this.applicationScope.organizationId, params.agent, params.aipId, params.policyNumber, params.stateId),
			orderBy: this.generateOrderBy(params.sorts),
			offset: params.page * params.size,
			limit: params.size,
		};

		const results = await this.apollo.watchQuery({ query: this.query, variables });

		return {
			...results,
			variables,
		};
	}
}
