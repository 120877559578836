import { modifier } from 'ember-modifier';
import { getFocusableElementsInContainer } from 'vault-client/utils/general';

export default modifier(function autofocus(element: HTMLElement, [selector = 'input:not([disabled])']) {
	if (typeof selector === 'string') {
		const childElement = element.querySelector(selector) as HTMLElement;

		if (childElement) {
			childElement.focus();
		} else {
			// fallback to first focusable element in container
			getFocusableElementsInContainer(element)?.[0]?.focus();
		}
	}
});
