import Route from '@ember/routing/route';
import { queryManager } from 'ember-apollo-client';
import query from 'vault-client/graphql/queries/reports/inventory-hedges-by-product.graphql';
import { DateTime } from 'luxon';
import {
	EntityAllocatedExposureRatioFilterDTO,
	InventoryLevelFilterDTO,
	ProductFilterDTO,
	Query,
	Query_ProductsArgs,
} from 'vault-client/types/graphql-types';
import { gql, useQuery } from 'glimmer-apollo';

const GET_CURRENT_FUTURES = gql`
	query MostCurrentFuture($where: ProductFilterDTO) {
		Products(where: $where) {
			CurrentFutures {
				id
				displayExpiresAt
			}
		}
	}
`;

// add in change in scope logic just in case
export default class ReportsInventoryHedgesByProductRoute extends Route {
	@queryManager apollo: any;

	query = query;

	templateName: string = 'reports/inventory-hedges-by-product';

	productsVariables: Query_ProductsArgs = {};

	getCurrentFutures = useQuery<{ Products: Query['Products'] }, Query_ProductsArgs>(this, () => [
		GET_CURRENT_FUTURES,
		{
			variables: this.productsVariables,
		},
	]);

	generateProductsWhere(): ProductFilterDTO {
		return {
			slug: {
				equals: 'us-dairy-butter',
			},
		};
	}

	generateExposureWhere(
		organizationId: null | string,
		customerId: undefined | null | string,
		locationId: string | null | undefined,
		startDate: string
	): EntityAllocatedExposureRatioFilterDTO {
		const currentEntityId = locationId ?? customerId ?? organizationId;
		const where: EntityAllocatedExposureRatioFilterDTO = {
			startDate,
			endDate: DateTime.fromISO(startDate).startOf('month').plus({ months: 23 }).endOf('month').toISODate().toString(),
			entityId: currentEntityId ? { equals: currentEntityId } : {},
			Product: {
				slug: { equals: 'us-dairy-butter' },
			},
		};

		return where;
	}

	generateInventoryWhere(
		organizationId: null | string,
		customerId: undefined | null | string,
		locationId: string | null | undefined
	): InventoryLevelFilterDTO {
		const where: InventoryLevelFilterDTO = {
			Product: {
				slug: { equals: 'us-dairy-butter' },
			},
		};

		if (locationId) {
			where.locationId = { equals: locationId };
			return where;
		}

		if (customerId) {
			where.Location = {
				customerId: {
					equals: customerId,
				},
			};

			return where;
		}

		if (organizationId) {
			where.Location = {
				Customer: {
					organizationId: {
						equals: organizationId,
					},
				},
			};
			return where;
		}

		return where;
	}
}
