import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<canvas id={{@id}} {{did-insert this.createChart}} {{did-update this.updateChart @data}} ...attributes></canvas>", {"contents":"<canvas id={{@id}} {{did-insert this.createChart}} {{did-update this.updateChart @data}} ...attributes></canvas>","moduleName":"vault-client/components/chart.hbs","parseOptions":{"srcName":"vault-client/components/chart.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { ChartTypeRegistry, ChartData, ChartOptions, Chart, ChartConfiguration } from 'chart.js';

interface ChartComponentArgs {
	type: keyof ChartTypeRegistry;
	chartData: ChartData;
	options: ChartOptions;
	plugins: any[];
	data: unknown;
	id?: string;
	updateChart: (_chart: Chart, _data: unknown) => {};
}

export default class ChartComponent extends Component<ChartComponentArgs> {
	chart!: Chart;

	@action
	createChart(element: HTMLCanvasElement) {
		const { type, chartData, options, plugins } = this.args;
		const config: ChartConfiguration = {
			type,
			data: chartData,
			options: options,
			plugins,
		};
		const chart = new Chart(element, config);
		this.chart = chart;
	}

	@action
	updateChart() {
		const { updateChart, data } = this.args;

		const updateFunction = updateChart ?? this.defaultUpdateChartFunction;

		updateFunction(this.chart, data as any);
		this.chart.update();
	}

	defaultUpdateChartFunction = (chart: Chart, data: Record<string, number[]>) => {
		chart.data.datasets.forEach((dataset) => {
			dataset.data = data[dataset.label as string];
		});
	};
}
