import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.isLoadingHistoricalData}}\n\t<Vault::UiLoadingSpinner />\n{{else}}\n\t<LineWithMarkersChart\n\t\t...attributes\n\t\t@id={{@id}}\n\t\t@data={{this.data}}\n\t\t@labels={{this.labels}}\n\t\t@xScaleOptions={{(hash ticks=(hash autoSkip=true))}}\n\t\t@yScaleOptions={{this.yScaleOptions}}\n\t\t@tooltipValueFormatCallback={{this.tooltipValueFormatCallback}}\n\t\t@fullUpdate={{false}}\n\t/>\n{{/if}}", {"contents":"{{#if this.isLoadingHistoricalData}}\n\t<Vault::UiLoadingSpinner />\n{{else}}\n\t<LineWithMarkersChart\n\t\t...attributes\n\t\t@id={{@id}}\n\t\t@data={{this.data}}\n\t\t@labels={{this.labels}}\n\t\t@xScaleOptions={{(hash ticks=(hash autoSkip=true))}}\n\t\t@yScaleOptions={{this.yScaleOptions}}\n\t\t@tooltipValueFormatCallback={{this.tooltipValueFormatCallback}}\n\t\t@fullUpdate={{false}}\n\t/>\n{{/if}}","moduleName":"vault-client/components/current-futures-chart.hbs","parseOptions":{"srcName":"vault-client/components/current-futures-chart.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { Future } from 'vault-client/types/graphql-types';
import { DateTime } from 'luxon';
import { task } from 'ember-concurrency';
import { action } from '@ember/object';
import { CMEMonthCodes } from 'vault-client/utils/cme-month-codes';
import ENV from 'vault-client/config/environment';

interface CurrentFuturesChartArgs {
	fractionDigits: number;
	displayFactor: number;
	futures: Future[];
}

interface GetClosePriceItem {
	symbol: string;
	closePrice: number | null;
	date: string | null;
}

enum HistoricalFuturesDataLabels {
	CurrentFutures = 'Current Futures',
	LastWeek = 'Last Week',
	LastMonth = 'Last Month',
	PriorYearFuturesCurve = 'Prior Year Futures Curve',
}

export default class CurrentFuturesChart extends Component<CurrentFuturesChartArgs> {
	@service marketData: any;

	historicalFuturesData: { [k: string]: (number | null)[] } = {};

	constructor(owner: any, args: CurrentFuturesChartArgs) {
		super(owner, args);
		this.registerFutures();
		this.fetchHistoricalfuturesDataTask.perform();
	}

	get futures() {
		return this.args.futures.filter((future) => !!future.barchartSymbol);
	}

	get previousYearFuturesSymbols() {
		return this.futures.map((future) => {
			const displayExpiresAt = DateTime.fromISO(future.displayExpiresAt);
			const root = future.SymbolGroup.barchartRootSymbol;
			const month = displayExpiresAt.month;
			const year = displayExpiresAt.year - 1;

			if (!root || !year || !month) return null;

			return root + CMEMonthCodes[month] + (year % 100);
		});
	}

	get isLoadingHistoricalData() {
		return this.fetchHistoricalfuturesDataTask.isRunning;
	}

	get data(): { [key: string]: (number | null)[] | null } {
		return {
			[HistoricalFuturesDataLabels.CurrentFutures]: this.futures.map((future: Future) => {
				const marketDatum = this.marketData.getMarketDatum(future.barchartSymbol);
				if (marketDatum?.lastPrice == null) return null;

				const price = this.args.displayFactor ? marketDatum.lastPrice * this.args.displayFactor : marketDatum.lastPrice;
				return price as number;
			}),
			[HistoricalFuturesDataLabels.LastWeek]: this.historicalFuturesData[HistoricalFuturesDataLabels.LastWeek],
			[HistoricalFuturesDataLabels.LastMonth]: this.historicalFuturesData[HistoricalFuturesDataLabels.LastMonth],
			[HistoricalFuturesDataLabels.PriorYearFuturesCurve]: this.historicalFuturesData[HistoricalFuturesDataLabels.PriorYearFuturesCurve],
		};
	}

	get labels() {
		return this.futures.map((future: Future) => {
			const luxonDate = DateTime.fromISO(future.displayExpiresAt);
			const date = luxonDate.toJSDate();

			const formattedDate = new Intl.DateTimeFormat('en-US', {
				month: 'short',
				year: 'numeric',
			}).format(date);
			return formattedDate;
		});
	}

	yScaleOptions = {
		ticks: {
			callback: (value: number) => {
				return value.toFixed(this.args.fractionDigits);
			},
		},
	};

	@action
	tooltipValueFormatCallback(value: number) {
		return new Intl.NumberFormat(undefined, {
			minimumFractionDigits: this.args.fractionDigits,
			maximumFractionDigits: this.args.fractionDigits,
		}).format(value);
	}

	fetchHistoricalfuturesDataTask = task(this, async () => {
		const historicalDataTargets = [
			{
				label: HistoricalFuturesDataLabels.LastWeek,
				date: DateTime.now().minus({ week: 1 }).toISODate(),
			},
			{
				label: HistoricalFuturesDataLabels.LastMonth,
				date: DateTime.now().minus({ month: 1 }).toISODate(),
			},
			{
				label: HistoricalFuturesDataLabels.PriorYearFuturesCurve,
				date: DateTime.now().minus({ year: 1 }).toISODate(),
			},
		];

		const historicalDataTargetPromises = historicalDataTargets.map((target) => {
			const symbols =
				target.label === HistoricalFuturesDataLabels.PriorYearFuturesCurve
					? this.previousYearFuturesSymbols
					: this.futures.map((future) => future.barchartSymbol!);

      const apiKey: string = ENV.barchartApiToken
			return fetch(
				`https://ondemand.websol.barchart.com/getClosePrice.json?` +
					new URLSearchParams({
						apikey: apiKey,
						symbols: symbols.toString(),
						date: target.date,
					}),
				{
					method: 'GET',
					headers: {},
				}
			)
				.then(async (response) => {
					const contentType = response.headers.get('content-type');
					if (!response.ok || !contentType || !contentType.includes('application/json')) {
						throw new Error();
					}

					await response
						.json()
						.then((response) => response.results)
						.then((data: GetClosePriceItem[]) => {
							if (!data) return;

							this.historicalFuturesData[target.label] = symbols.map((barchartSymbol) => {
								const closePrice = data.find((item) => item.symbol === barchartSymbol)?.closePrice;
								return closePrice ? this.args.displayFactor * closePrice : null;
							});
						});
				})
				.catch((error) => {
					console.error(`Failed to retrieve ${target.label} Historical Futures. \n` + error);
					this.historicalFuturesData[target.label] = [];
				});
		});

		await Promise.all(historicalDataTargetPromises);
	});

	registerFutures() {
		this.args.futures.forEach((future) => {
			if (future.barchartSymbol) {
				this.marketData.register(future.barchartSymbol);
			}
		});
	}

	willDestroy() {
		super.willDestroy();
		this.args.futures.forEach((future) => {
			if (future.barchartSymbol) {
				this.marketData.unregister(future.barchartSymbol);
			}
		});
	}
}
