import PositionsShowController from 'vault-client/controllers/positions/show';

export default class BusinessesBusinessPositionController extends PositionsShowController {
	positionsRoutePath: string = 'businesses.business.positions';
	exposureHedgeMonthDetailRoute: string = 'businesses.business.exposure-hedge-month-detail';
	transactionShowRoute: string = 'businesses.business.transaction';
	accountShowRoute: string = 'businesses.business.account';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'businesses/business/position': BusinessesBusinessPositionController;
	}
}
