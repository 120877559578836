import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { ModelFrom } from 'vault-client/utils/type-utils';
import route, { GET_FILLS } from 'vault-client/routes/vgs/grain-services/fills';
import { CellComponents, TableColumn } from 'vault-client/types/vault-table';
import resetVaultTableScroll from 'vault-client/utils/reset-vault-table-scroll';
import { action } from '@ember/object';
import getFilterDisplayProperty from 'vault-client/utils/get-filter-display-property';
import { gql, useQuery } from 'glimmer-apollo';
import { DateTime } from 'luxon';
import {
	Query,
	Query_BuyersArgs,
	Query_CropsArgs,
	Query_GrainLocationsArgs,
	Query_SearchArgs,
	TypeOfGrainOrderSales,
	TypeOfSearchItem,
} from 'vault-client/types/graphql-types';
import { SearchResult } from 'vault-client/types/vault-client';
import { getSalesTypeDisplayValue } from 'vault-client/utils/vgs-utils';

const GET_CROP = gql`
	query CropCategories($where: CropCategoryFilterDTO) {
		CropCategories(where: $where) {
			id
			name
		}
	}
`;

const GET_BUYER = gql`
	query Buyers($where: BuyerFilterDTO) {
		Buyers(where: $where) {
			id
			name
		}
	}
`;

const GET_SEARCH_QUERY = gql`
	query Search($query: String!, $typesToInclude: [TypeOfSearchItem!]) {
		Search(query: $query, typesToInclude: $typesToInclude) {
			id
			name
			type
			attributes
		}
	}
`;

const GET_GRAIN_LOCATION = gql`
	query GrainLocation($where: GrainLocationFilterDTO) {
		GrainLocations(where: $where) {
			id
			name
		}
	}
`;

type DateFilterOptions = {
	displayName?: string | undefined;
	startDate?: string | undefined;
	endDate?: string | undefined;
};

export default class VgsGrainServicesFillsIndex extends Controller {
	@tracked page = 0;
	@tracked size = 100;
	@tracked sorts = [
		{ valuePath: 'deliveryStartDate', isAscending: true },
		{ valuePath: 'futuresMonth', isAscending: true },
	];
	// @tracked sorts = [{ valuePath: 'expires_at' }];
	@tracked selectedGrainAdvisorIds: string[] = [];
	@tracked customerId: string | null = null;
	@tracked cropCategoryId: string | null = null;
	@tracked buyerId: string | null = null;
	@tracked grainLocationId: string | null = null;
	@tracked selectedSalesTypes: TypeOfGrainOrderSales[] = [];
	@tracked cropYearStart: number | null = 1900;
	@tracked cropYearEnd: number | null = 2999;
	@tracked futuresMonthStart: string | null = '1900-01-01';
	@tracked futuresMonthEnd: string | null = '2999-12-31';
	@tracked deliveryStartDateStart: string | null = '1900-01-01';
	@tracked deliveryStartDateEnd: string | null = '2999-12-31';
	@tracked deliveryEndDateStart: string | null = '1900-01-01';
	@tracked deliveryEndDateEnd: string | null = '2999-12-31';

	futuresMonthFilterOptions: DateFilterOptions[] = [
		{
			displayName: 'All Months',
			startDate: '1900-01-01',
			endDate: '2999-12-31',
		},
		{
			displayName: 'Current Month',
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().endOf('month').toISODate(),
		},
		{
			displayName: 'Next Month',
			startDate: DateTime.local().plus({ months: 1 }).startOf('month').toISODate(),
			endDate: DateTime.local().plus({ months: 1 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Next 12 Months',
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().plus({ months: 12 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Next 24 Months',
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().plus({ months: 24 }).endOf('month').toISODate(),
		},
	];

	deliveryStartDateFilterOptions: DateFilterOptions[] = [
		{
			displayName: 'All Dates',
			startDate: '1900-01-01',
			endDate: '2999-12-31',
		},
		{
			displayName: 'Current Month',
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().endOf('month').toISODate(),
		},
		{
			displayName: 'Next 3 Months',
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().plus({ months: 3 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Next 6 Months',
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().plus({ months: 6 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Next 12 Months',
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().plus({ months: 12 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Next 24 Months',
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().plus({ months: 24 }).endOf('month').toISODate(),
		},
	];

	deliveryEndDateFilterOptions: DateFilterOptions[] = [
		{
			displayName: 'All Dates',
			startDate: '1900-01-01',
			endDate: '2999-12-31',
		},
		{
			displayName: 'Current Month',
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().endOf('month').toISODate(),
		},
		{
			displayName: 'Next 3 Months',
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().plus({ months: 3 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Next 6 Months',
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().plus({ months: 6 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Next 12 Months',
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().plus({ months: 12 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Next 24 Months',
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().plus({ months: 24 }).endOf('month').toISODate(),
		},
	];

	declare model: ModelFrom<route>;

	queryParams = [
		'selectedGrainAdvisorIds',
		'customer',
		'cropCategoryId',
		'grainBuyerId',
		'grainLocationId',
		'selectedSalesTypes',
		'cropYearStart',
		'cropYearEnd',
		'futuresMonthStart',
		'futuresMonthEnd',
		'deliveryStartStart',
		'deliveryStartEnd',
		'deliveryEndStart',
		'deliveryEndEnd',
	];

	query = GET_FILLS;

	get columns() {
		const baseColumns: TableColumn[] = [
			{
				id: '10671593-bd8d-4c7c-b41a-7f17915c64a1',
				name: '',
				width: 100,
				textAlign: 'center',
				isSortable: false,
				cellComponent: CellComponents.Button,
				componentArgs: {
					size: 'xs',
					style: 'outline',
					text: 'Details',
					fn: () => {},
				},
				isFixed: '',
				isVisible: true,
				linkRoute: 'vgs.grain-services.fills.show',
				linkModelPath: 'id',
			},
			{
				id: '5e31dfa8-081f-448f-a967-77ba4f22d4c5',
				name: 'Advisor',
				valuePath: '',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: '60bc18db-c042-44e9-af25-fae31d0bd5b3',
						name: 'First Name',
						valuePath: 'Plan.Customer.RelationshipOwner.firstName',
						minWidth: 115,
						width: 115,
						textAlign: 'left',
						isSortable: true,
						cellComponent: CellComponents.String,
						isFixed: '',
						isVisible: true,
					},
					{
						id: '19764cf2-59cb-4e4f-b0a1-036d5fe154da',
						name: 'Last Name',
						valuePath: 'Plan.Customer.RelationshipOwner.lastName',
						minWidth: 120,
						width: 120,
						textAlign: 'left',
						isSortable: true,
						cellComponent: CellComponents.String,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: 'e5bf0f7a-1fbd-4880-a045-24129517176f',
				name: ' Customer',
				valuePath: 'Plan.Customer.name',
				minWidth: 100,
				width: 110,
				textAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'f79f97c1-0883-4652-af91-3cc771f36177',
				name: 'Crop',
				valuePath: 'Plan.Crop.name',
				minWidth: 80,
				width: 80,
				textAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'cf7287b8-bdd9-4bd4-b9ba-7a756f9dc77f',
				name: 'Crop Year',
				valuePath: 'Plan.CropYear.year',
				minWidth: 80,
				width: 110,
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '03817d2a-42a8-42b8-9cea-5a84dc794b8b',
				name: 'Bushels',
				valuePath: 'bushels',
				minWidth: 120,
				cellComponent: CellComponents.String,
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '38235e44-24df-40bc-a206-bb99b01b0990',
				name: 'Futures Month',
				valuePath: 'futuresMonth',
				minWidth: 120,
				width: 140,
				cellComponent: CellComponents.MonthFormat,
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'f8a3264f-0a5b-490c-98f6-9940c8444f77',
				name: 'Delivery Start Date',
				valuePath: 'deliveryStartDate',
				minWidth: 120,
				width: 170,
				cellComponent: CellComponents.IntlDateTimeFormat,
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '184cbe7c-2ec9-4dbd-b05f-35a507310fa4',
				name: 'Delivery End Date',
				valuePath: 'deliveryEndDate',
				minWidth: 120,
				width: 170,
				cellComponent: CellComponents.IntlDateTimeFormat,
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '54b1ee4a-df03-4c18-96e5-3ab62230e712',
				name: 'Sales Type',
				valuePath: 'salesType',
				minWidth: 80,
				width: 115,
				cellComponent: CellComponents.CustomFormat,
				componentArgs: {
					formatter: getSalesTypeDisplayValue,
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'e2ced2d2-13d1-4438-a2b1-e23404022cd6',
				name: 'Futures Price',
				valuePath: 'futurePrice',
				minWidth: 140,
				width: 130,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
					minimumFractionDigits: 2,
					maximumFractionDigits: 5,
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '709a39aa-d36a-40e8-bd1c-9174be005fc3',
				name: 'Fees',
				valuePath: 'fees',
				minWidth: 120,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '128ecf25-b665-4ae8-b036-ff1c44752e9b',
				name: 'Spread Gain',
				valuePath: 'spread',
				minWidth: 100,
				width: 125,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					maximumFractionDigits: 5,
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '04c337c2-468c-48c6-b83a-4397473ac4f9',
				name: 'Basis',
				valuePath: 'basis',
				minWidth: 120,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'c5d743ac-5d9c-4dc8-92aa-1ae99b6eaf82',
				name: 'Buyer',
				valuePath: 'Buyer.name',
				minWidth: 120,
				cellComponent: CellComponents.String,
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'c19b6afe-e27a-4816-9163-bac84dd84504',
				name: 'Location',
				valuePath: 'Location.name',
				minWidth: 120,
				cellComponent: CellComponents.String,
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '8b5fe438-093d-408b-bd14-3e522400aeb2',
				name: 'Contract Number',
				valuePath: 'contractNumber',
				minWidth: 120,
				width: 160,
				cellComponent: CellComponents.String,
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
		];
		return baseColumns;
	}

	get currentPage() {
		return this.page;
	}

	set currentPage(page) {
		this.page = page;
	}

	get rows() {
		return this.model.getFills.data?.GrainFillOrders ?? [];
	}

	get totalNumFills() {
		return this.model.getFills.data?.GrainFillOrderCount.count ?? 0;
	}

	get searchFilterQueryParams() {
		const obj: {
			[key: string]: any;
		} = {};
		const searchQueryParams = ['customerId', 'cropCategoryId', 'grainLocationId', 'buyerId'] as const;
		searchQueryParams.forEach((param) => {
			const value = this[param];

			if (value) {
				obj[param] = {
					filterRule: 'equals',
					filterValue: value,
				};

				// set filterComponent property to specify component for custom display extended from search-filter
				const filterDisplayObj = getFilterDisplayProperty(param);

				if (filterDisplayObj && filterDisplayObj.customComponent) {
					obj[param].filterComponent = filterDisplayObj.customComponent;
				}
				// set filterLabel property to specify custom label for filter - default would be filterIdentifier (matches queryParam)
				if (filterDisplayObj && filterDisplayObj.label) {
					obj[param].filterLabel = filterDisplayObj.label;
				}
			}
		});

		return obj;
	}

	get searchPlaceholder() {
		return 'Filter by Customer, Crop, Buyer, Location';
	}

	get searchPrompt() {
		return 'Type a search term to find fills by Customer, Crop, Buyer or Location.';
	}

	get salesTypes(): TypeOfGrainOrderSales[] {
		return [
			TypeOfGrainOrderSales.Cbot,
			TypeOfGrainOrderSales.Basis,
			TypeOfGrainOrderSales.Flat,
			TypeOfGrainOrderSales.Hta,
			TypeOfGrainOrderSales.Npe,
			TypeOfGrainOrderSales.MarketFlat,
			TypeOfGrainOrderSales.MarketHta,
		];
	}

	get advisors() {
		return this.model.getFills.data?.GrainAdvisors ?? [];
	}

	get futuresMonthSelectedDate() {
		return {
			startDate: this.futuresMonthStart,
			endDate: this.futuresMonthEnd,
		};
	}

	get deliveryStartDateSelectedDate() {
		return {
			startDate: this.deliveryStartDateStart,
			endDate: this.deliveryStartDateEnd,
		};
	}

	get deliveryEndDateSelectedDate() {
		return {
			startDate: this.deliveryEndDateStart,
			endDate: this.deliveryEndDateEnd,
		};
	}

	get selectedSalesTypesString() {
		if (this.selectedSalesTypes.length === 0) {
			return 'All';
		}

		if (this.selectedSalesTypes.length === 1) {
			return getSalesTypeDisplayValue(this.selectedSalesTypes[0]);
		}

		return `${this.selectedSalesTypes.length} Selected`;
	}

	get selectedAdvisorString() {
		if (this.selectedGrainAdvisorIds.length === 0) {
			return 'All';
		}

		if (this.selectedGrainAdvisorIds.length === 1) {
			return this.advisors.find((advisor) => advisor.id === this.selectedGrainAdvisorIds[0])?.name ?? '';
		}

		return `${this.selectedGrainAdvisorIds.length} Selected`;
	}

	@action
	addSelectedAdvisorId(advisorId: string | null) {
		if (advisorId === null) {
			this.selectedGrainAdvisorIds = [];
		} else if (this.selectedGrainAdvisorIds.includes(advisorId)) {
			this.selectedGrainAdvisorIds = this.selectedGrainAdvisorIds.filter((v) => v !== advisorId);
		} else {
			this.selectedGrainAdvisorIds = [...this.selectedGrainAdvisorIds, advisorId];
		}

		if (this.selectedGrainAdvisorIds.length === this.advisors.length && this.advisors.length > 1) {
			this.selectedGrainAdvisorIds = [];
		}
		this.setTablePageState();
	}

	@action
	addSelectedSalesType(salesType: TypeOfGrainOrderSales | null) {
		if (salesType === null) {
			this.selectedSalesTypes = [];
		} else if (this.selectedSalesTypes.includes(salesType)) {
			this.selectedSalesTypes = this.selectedSalesTypes.filter((v) => v !== salesType);
		} else {
			this.selectedSalesTypes = [...this.selectedSalesTypes, salesType];
		}

		// If all sales types are selected, clear the selected array so that 'All' is used
		if (this.selectedSalesTypes.length === this.salesTypes.length) {
			this.selectedSalesTypes = [];
		}
		this.setTablePageState();
	}

	@action
	async fetchSearchResults(searchText: any) {
		const searchResults: SearchResult[] = [];

		const searchQuery = useQuery<{ Search: Query['Search'] }, Query_SearchArgs>(this, () => [
			GET_SEARCH_QUERY,
			{
				variables: {
					query: searchText,
					typesToInclude: [TypeOfSearchItem.Customer],
				},
				onComplete: (data): void => {
					searchResults.push(
						...(data?.Search.filter((item) => {
							// Filter out non-VGS customers
							if (item.type === TypeOfSearchItem.Customer && !item.attributes?.isVgs) {
								return false;
							}

							return true;
						}).map((result) => {
							return {
								id: result.id,
								name: result.name,
								type: result.type as TypeOfSearchItem,
							};
						}) ?? [])
					);
				},
			},
		]);

		const cropQuery = useQuery<{ CropCategories: Query['CropCategories'] }, Query_CropsArgs>(this, () => [
			GET_CROP,
			{
				variables: {
					where: {
						name: {
							contains: searchText,
						},
					},
				},
				onComplete: (data): void => {
					data?.CropCategories.forEach((cropCategory) => {
						searchResults.push({
							id: cropCategory.id,
							name: cropCategory.name ?? '',
							type: 'Crop',
						});
					});
				},
			},
		]);

		const buyerQuery = useQuery<{ Buyers: Query['Buyers'] }, Query_BuyersArgs>(this, () => [
			GET_BUYER,
			{
				variables: {
					where: {
						name: {
							contains: searchText,
						},
					},
				},
				onComplete: (data): void => {
					data?.Buyers.forEach((buyer) => {
						searchResults.push({
							id: buyer.id,
							name: buyer.name ?? '',
							type: 'Buyer',
						});
					});
				},
			},
		]);

		const grainLocationsQuery = useQuery<{ GrainLocations: Query['GrainLocations'] }, Query_GrainLocationsArgs>(this, () => [
			GET_GRAIN_LOCATION,
			{
				variables: {
					where: {
						name: { contains: searchText },
					},
				},
				onComplete: (data): void => {
					searchResults.push(
						...(data?.GrainLocations.map((location) => ({
							type: 'Location',
							name: location.name,
							id: location.id,
						})) ?? [])
					);
				},
			},
		]);

		await Promise.all([searchQuery.promise, cropQuery.promise, buyerQuery.promise, grainLocationsQuery.promise]);

		return searchResults;
	}

	@action
	structureSearchResults(searchResults: any) {
		const map = new Map();

		searchResults.forEach((item: any) => {
			if (map.has(item.type)) {
				map.get(item.type).push({ id: item.id, name: item.name, type: item.type });
			} else {
				map.set(item.type, [{ id: item.id, name: item.name, type: item.type }]);
			}
		});

		return map;
	}

	@action
	setTablePageState(newPageVal: number | null = null) {
		this.currentPage = newPageVal ?? 0;
		resetVaultTableScroll('fills-table');
	}

	@action
	setSearchFilterQueryParam(searchResult: any) {
		const mappedSearchFilter = this.mapSearchResult(searchResult);
		//@ts-ignore
		this[mappedSearchFilter.filterIdentifier] = mappedSearchFilter.filterValue;
		this.setTablePageState();
	}

	mapSearchResult(searchResult: SearchResult) {
		let filterIdentifier;

		switch (searchResult.type) {
			case TypeOfSearchItem.Customer:
				filterIdentifier = 'customerId';
				break;
			case 'Location':
				filterIdentifier = 'grainLocationId';
				break;
			case 'Crop':
				filterIdentifier = 'cropCategoryId';
				break;
			case 'Buyer':
				filterIdentifier = 'buyerId';
				break;
		}

		return {
			filterIdentifier,
			filterValue: searchResult.id ?? searchResult.name,
		};
	}

	@action
	clearSearchFilterQueryParam(filterIdentifier: any) {
		//@ts-ignore
		this[`${filterIdentifier}`] = null;
		this.setTablePageState();
	}

	@action
	setCropYearStartDate(value: number) {
		this.cropYearStart = value;
		this.setTablePageState();
	}

	@action
	setCropYearEndDate(value: number) {
		this.cropYearEnd = value;
		this.setTablePageState();
	}

	@action
	setFuturesMonthDate(dateObj: { endDate: string; startDate: string }) {
		const { startDate, endDate } = dateObj;
		this.futuresMonthStart = startDate;
		this.futuresMonthEnd = endDate;
		this.setTablePageState();
	}

	@action
	setDeliveryStartDate(dateObj: { endDate: string; startDate: string }) {
		const { startDate, endDate } = dateObj;
		this.deliveryStartDateStart = startDate;
		this.deliveryStartDateEnd = endDate;
		this.setTablePageState();
	}

	@action
	setDeliveryEndDate(dateObj: { endDate: string; startDate: string }) {
		const { startDate, endDate } = dateObj;
		this.deliveryEndDateStart = startDate;
		this.deliveryEndDateEnd = endDate;
		this.setTablePageState();
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	interface Registry {
		'vgs/grain-services/fills/index': VgsGrainServicesFillsIndex;
	}
}
