import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Vault::UiButton @size={{@size}} @style={{this.buttonStyle}} {{on 'click' this.downloadCSV}} ...attributes>\n\t{{#if this.isLoading}}\n\t\t<span class='mr-2'>Generating...</span>\n\t\t<FaIcon @icon='spinner' @spin={{true}} />\n\t{{else}}{{yield}}{{/if}}\n</Vault::UiButton>", {"contents":"<Vault::UiButton @size={{@size}} @style={{this.buttonStyle}} {{on 'click' this.downloadCSV}} ...attributes>\n\t{{#if this.isLoading}}\n\t\t<span class='mr-2'>Generating...</span>\n\t\t<FaIcon @icon='spinner' @spin={{true}} />\n\t{{else}}{{yield}}{{/if}}\n</Vault::UiButton>","moduleName":"vault-client/components/download-table-in-csv-button.hbs","parseOptions":{"srcName":"vault-client/components/download-table-in-csv-button.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { queryManager } from 'ember-apollo-client';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { DateTime } from 'luxon';
import { percentRank } from 'vault-client/utils/percentile';
import { TableColumn, CellComponents } from 'vault-client/types/vault-table';
import { useQuery } from 'glimmer-apollo';
import { DocumentNode } from 'graphql';
import checkStorageAvailable from 'vault-client/utils/check-storage-available';
import { getCurrentColumns } from 'vault-client/utils/vault-table-utils';
import { analyticsCustomTracking } from 'vault-client/utils/analytics-tracking';
interface DownloadTableInCsvButtonArgs {
	items?: any[];
	queryParams?: any;
	query?: DocumentNode;
	itemsFn?: Function;
	queryPath?: string;
	countQueryPath?: string;
	columns: TableColumn[];
	fileName: string;
	style?: string;
	apolloClientId?: string;
	tableRoute?: string;
}

export default class DownloadTableInCsvButton extends Component<DownloadTableInCsvButtonArgs> {
	@service marketData: any;
	@queryManager apollo: any;

	@tracked isLoading = false;

	get buttonStyle() {
		return this.args.style ?? 'primary';
	}

	@action
	async downloadCSV() {
		this.isLoading = true;

		//
		// request data
		//
		let page = 0;
		const pageSize = 2000;
		let results = [];
		let possiblyMoreResults = true;

		if (this.args.items) {
			results = this.args.items;
			possiblyMoreResults = false;
		}

		let response;
		let responseResults;

		const initialVariables = {
			offset: page * pageSize,
			limit: pageSize,
		};

		const queryParams = this.args.queryParams ?? {};

		const variables = { ...initialVariables, ...queryParams };
		const query = this.args.query;
		const apolloClientId = this.args.apolloClientId;

		while (possiblyMoreResults) {
			variables.offset = page * pageSize;
			variables.limit = pageSize;

			if (apolloClientId && query) {
				const apolloQuery = useQuery(this, () => [
					query,
					{
						clientId: apolloClientId,
						variables,
					},
				]);

				await apolloQuery.promise;

				response = apolloQuery.data ?? {};
			} else {
				response = await this.apollo.watchQuery({
					query: this.args.query,
					variables,
					fetchPolicy: 'network-only',
				});
			}

			//	Wrap the results in a class, if applicable.
			if (this.args.itemsFn) {
				responseResults = this.args.queryPath ? this.args.itemsFn(response[this.args.queryPath]) : this.args.itemsFn(response);
			} else {
				responseResults = this.args.queryPath ? response[this.args.queryPath] : response;
			}
			results.push(...responseResults);

			// check if total results still less than total count, if available, or if equal to pageSize
			if (
				(this.args.countQueryPath && results.length < (this.getByCompositeKey(response, this.args.countQueryPath)?.count ?? 0)) ||
				responseResults.length == pageSize
			) {
				page++;
			} else {
				possiblyMoreResults = false;
			}
		}

		//
		// Force a market data update, to make sure we have current data for all applicable symbols.
		//
		await this.marketData.updateMarketData();

		//
		// create rowGenKey
		//
		interface Column {
			name: string;
			isVisible: boolean;
			valuePath?: string;
			subcolumns?: Column[];
			cellComponent?: string;
			componentArgs?: any;
			side?: string;
			[key: string]: any;
		}

		const rowGenKey: Column[][] = [];
		let moreSubColumns = true;
		let level: number;
		const addColSpan = function (subCols: Column[], parentRowCol: Column, childArr?: Column[]) {
			subCols.forEach((subCol) => {
				// Do not add hidden subColumns
				if (!subCol.isVisible) {
					return;
				}
				if (subCol.subcolumns && subCol.subcolumns.length > 0) {
					addColSpan(subCol.subcolumns, parentRowCol);
				} else {
					if (parentRowCol.span) {
						parentRowCol.span += 1;
					} else {
						parentRowCol.span = 1;
					}
				}
				if (childArr) {
					childArr.push(subCol); // only push for first level - do not recursively push
				}
			});
		};

		// Sort columns by their order in localstorage if available
		// Fall back to default order if no localstorage
		const savedColumState = this.fetchStoredTableState(this.args.columns, this.args.tableRoute ?? '');
		while (moreSubColumns) {
			level = rowGenKey.length;
			// Only add visible visible columns for first level
			const prevRowArr =
				level > 0
					? rowGenKey[level - 1]
					: savedColumState
							.filter((col) => this.includeColumnInCSV(col))
							.map((a) => {
								return { ...a };
							});
			const rowArr: Column[] = [];
			prevRowArr.forEach((col: Column, idx: number) => {
				if (level === 0) {
					rowArr.push(col);
				} else if (col.subcolumns && col.subcolumns.length > 0) {
					addColSpan(col.subcolumns, prevRowArr[idx], rowArr);
				} else {
					const newCol = { ...col };
					newCol.name = '';
					rowArr.push(newCol);
				}
			});

			rowGenKey.push(rowArr);

			if (rowArr.findIndex((col) => col.subcolumns && col.subcolumns.length > 0) === -1) {
				moreSubColumns = false;
			}
		}

		//
		// array of csvRows for blob
		//
		const csvRows: string[] = [];

		//
		// create headers
		//
		let headerRow: string[] = [];
		rowGenKey.forEach((row) => {
			headerRow = [];
			row.forEach((col: Column) => {
				if (col.span) {
					let count = 0;
					while (count < col.span) {
						headerRow.push(col.name);
						count += 1;
					}
				} else {
					headerRow.push(col.name);
				}
			});
			csvRows.push(headerRow.join(','));
		});
		//
		// create escaped rows for csv format
		//
		const columns = rowGenKey[rowGenKey.length - 1];

		const mappedResults = results.map((row) => {
			if (row.children) {
				const childrenArray = [];
				childrenArray.push(row);
				for (const element of row.children) {
					element.isChild = true;
					childrenArray.push(element);
				}
				return childrenArray;
			} else {
				return [row];
			}
		});

		for (const row of mappedResults.flat()) {
			const values = columns.map((col: Column) => {
				switch (col.cellComponent) {
					case CellComponents.Button:
						return '';
					case CellComponents.Check:
						return ''; // could do something with this...
					case CellComponents.Currency:
						return this.numberColumnContent(row, col.valuePath || '', col.componentArgs);
					case CellComponents.HistoricalPriceRangeFormat:
						return this.historcalPriceRangeColumnContent(row, col.valuePath || '');
					case CellComponents.IntlDateTimeFormat:
						return this.dateColumnContent(row, col.valuePath || '', col.componentArgs);
					case CellComponents.IntlNumberFormat:
						if (col.csvMaxFractionDigits != null) {
							col.componentArgs = { maximumFractionDigits: col.csvMaxFractionDigits };
						}
						return this.numberColumnContent(row, col.valuePath || '', col.componentArgs);
					case CellComponents.MarketPriceFormat:
						return this.marketPriceColumnContent(
							row,
							col.valuePath || '',
							col.componentArgs.fractionDigitsPath,
							col.componentArgs.displayFactorPath
						);
					case CellComponents.MonthFormat:
						return this.dateColumnContent(row, col.valuePath || '', {
							month: 'short',
							year: 'numeric',
						});
					case CellComponents.PriceFormat:
						return this.priceColumnContent(
							row,
							col.valuePath || '',
							col.componentArgs.fractionDigitsPath,
							col.componentArgs.displayFactorPath
						);
					case CellComponents.SessionChangeFormat:
						return this.sessionChangeColumnContent(
							row,
							col.valuePath || '',
							col.componentArgs.fractionDigitsPath,
							col.componentArgs.displayFactorPath
						);
					case CellComponents.QuarterFormat:
						return this.quarterColumnContent(row, col.valuePath || '', row.displayMonthName || false);
					case CellComponents.MarketVolume:
						return this.marketVolumeColumnContent(row, col.valuePath || '', col.side || undefined);
					case CellComponents.MarketOpenInterest:
						return this.marketOpenInterestColumnContent(row, col.valuePath || '', col.side || undefined);
					default:
						return this.textColumnContent(row, col.valuePath || '');
				}
			});

			csvRows.push(values.join(','));
		}

		//
		//create blob
		//
		const formattedCSVData = csvRows.join('\n');

		const blob = new Blob([formattedCSVData], { type: 'text/csv' });
		const url = window.URL.createObjectURL(blob);
		const a = document.createElement('a');
		a.setAttribute('hidden', '');
		a.setAttribute('href', url);
		a.setAttribute('download', this.args.fileName);
		document.body.appendChild(a);
		a.click();
		document.body.removeChild(a);

		this.isLoading = false;

		// tracks when CSV Download button is clicked
		analyticsCustomTracking('CSV Downloaded', {});

		return;
	}

	@action
	getByCompositeKey(obj: any, key: string) {
		if (!key) {
			return null;
		}

		return key.split('.').reduce((prev: any, itm: string) => (prev ? prev[itm] : null), obj) || null;
	}

	@action
	textColumnContent(datum: any, path: string) {
		const value = this.getByCompositeKey(datum, path);
		if (value) {
			const escaped = ('' + value).replace(/"/g, '\\"');
			return `"${escaped}"`;
		}

		return value;
	}

	@action
	numberColumnContent(datum: any, path: string, args: any) {
		const value = this.getByCompositeKey(datum, path);

		return `"${new Intl.NumberFormat(undefined, args).format(value)}"`;
	}

	@action
	dateColumnContent(datum: any, path: string, args: any) {
		const value = this.getByCompositeKey(datum, path);

		if (value) {
			const luxonDate = DateTime.fromISO(value);
			const date = luxonDate.toJSDate();
			try {
				return `"${new Intl.DateTimeFormat('en-US', {
					...args,
				}).format(date)}"`;
			} catch (e) {
				console.error(e.name, '\n', e.message, '\n', e.stack);
				return '';
			}
		} else {
			return null;
		}
	}

	@action
	quarterColumnContent(datum: any, path: string, displayMonthName: boolean) {
		const value = this.getByCompositeKey(datum, path);
		if (value) {
			const luxonDate = DateTime.fromISO(value);
			if (displayMonthName) {
				return `${luxonDate.toLocaleString({ month: 'long' })}`;
			} else {
				return `${luxonDate.year} Q${luxonDate.quarter}`;
			}
		} else {
			return null;
		}
	}

	@action
	priceColumnContent(datum: any, path: string, fractionDigitsPath: string, displayFactorPath: string) {
		const value = this.getByCompositeKey(datum, path);

		if (value) {
			const fractionDigits = this.getByCompositeKey(datum, fractionDigitsPath);
			const displayFactor = displayFactorPath ? this.getByCompositeKey(datum, displayFactorPath) : null;

			const price = displayFactor ? displayFactor * value : value;

			return `"${new Intl.NumberFormat(undefined, {
				minimumFractionDigits: fractionDigits,
				maximumFractionDigits: fractionDigits,
			}).format(price)}"`;
		} else {
			return null;
		}
	}

	@action
	historcalPriceRangeColumnContent(datum: any, path: string) {
		const symbol = this.getByCompositeKey(datum, `${path}.barchartSymbol`);
		const marketDatum = this.marketData.getMarketDatum(symbol);

		const historicalRange = this.marketData.getMarketDataHistoricalRange(symbol);
		const numberOfYears = 5;

		if (historicalRange?.get('settlements') && marketDatum?.lastPrice) {
			const settlementsDatums = historicalRange.get('settlements').slice(0, numberOfYears);

			const settlements = settlementsDatums.map((settlement: any) => {
				return settlement.close ?? settlement.lastPrice;
			});

			const rank = percentRank(settlements, marketDatum.lastPrice);

			if (rank) {
				return `"${new Intl.NumberFormat(undefined, {
					style: 'percent',
				}).format(rank)}"`;
			} else {
				return null;
			}
		} else {
			return null;
		}
	}

	@action
	marketPriceColumnContent(datum: any, path: string, fractionDigitsPath: string, displayFactorPath: string, side?: string) {
		const symbol = this.getByCompositeKey(datum, `${path}.barchartSymbol`);

		const marketDatum = this.marketData.getMarketDatum(symbol);
		let value;

		// This is hacky and should be cleaned up.
		const showValue =
			this.getByCompositeKey(datum, `${path}.instrumentType`) == 'Future' || !this.getByCompositeKey(datum, `${path}.hasExpired`);

		if (marketDatum) {
			switch (side) {
				case 'last':
					value = marketDatum.lastPrice;
					break;
				case 'high':
					value = marketDatum.high;
					break;
				case 'low':
					value = marketDatum.low;
					break;
				case 'open':
					value = marketDatum.open;
					break;
				case 'close':
					value = marketDatum.close;
					break;
				default:
					value = marketDatum.lastPrice;
			}
		}

		if (value && showValue) {
			const fractionDigits = this.getByCompositeKey(datum, fractionDigitsPath);
			const displayFactor = displayFactorPath ? this.getByCompositeKey(datum, displayFactorPath) : null;

			const price = displayFactor ? displayFactor * value : value;

			return `"${new Intl.NumberFormat(undefined, {
				minimumFractionDigits: fractionDigits,
				maximumFractionDigits: fractionDigits,
			}).format(price)}"`;
		} else {
			return null;
		}
	}

	@action
	sessionChangeColumnContent(datum: any, path: string, fractionDigitsPath: string, displayFactorPath: string) {
		const symbol = this.getByCompositeKey(datum, `${path}.barchartSymbol`);

		const marketDatum = this.marketData.getMarketDatum(symbol);
		const value = marketDatum?.netChange ?? null;

		// This is hacky and should be cleaned up.
		const showValue =
			this.getByCompositeKey(datum, `${path}.instrumentType`) == 'Future' || !this.getByCompositeKey(datum, `${path}.hasExpired`);

		if (value && showValue) {
			const fractionDigits = this.getByCompositeKey(datum, fractionDigitsPath);
			const displayFactor = displayFactorPath ? this.getByCompositeKey(datum, displayFactorPath) : null;

			const price = displayFactor ? displayFactor * value : value;

			return `"${new Intl.NumberFormat(undefined, {
				minimumFractionDigits: fractionDigits,
				maximumFractionDigits: fractionDigits,
			}).format(price)}"`;
		} else {
			return null;
		}
	}

	@action
	marketVolumeColumnContent(datum: any, path: string, side?: string) {
		const symbol = this.getByCompositeKey(datum, `${path}.barchartSymbol`);

		const marketDatum = this.marketData.getMarketDatum(symbol);
		let value;

		// This is hacky and should be cleaned up.
		const showValue =
			this.getByCompositeKey(datum, `${path}.instrumentType`) == 'Future' || !this.getByCompositeKey(datum, `${path}.hasExpired`);

		if (marketDatum) {
			switch (side) {
				case 'volume':
					value = marketDatum.volume;
					break;
				case 'previousVolume':
					value = marketDatum.previousVolume;
					break;
				default:
					value = marketDatum.volume;
			}
		}

		if (value && showValue) {
			return value;
		} else {
			return null;
		}
	}

	@action
	marketOpenInterestColumnContent(datum: any, path: string, side?: string) {
		const symbol = this.getByCompositeKey(datum, `${path}.barchartSymbol`);

		const marketDatum = this.marketData.getMarketDatum(symbol);
		let value;

		// This is hacky and should be cleaned up.
		const showValue =
			this.getByCompositeKey(datum, `${path}.instrumentType`) == 'Future' || !this.getByCompositeKey(datum, `${path}.hasExpired`);

		if (marketDatum) {
			switch (side) {
				case 'openInterest':
					value = marketDatum.openInterest;
					break;
				case 'previousOpenInterest':
					value = marketDatum.previousOpenInterest;
					break;
				default:
					value = marketDatum.openInterest;
			}
		}

		if (value && showValue) {
			return value;
		} else {
			return null;
		}
	}

	fetchStoredTableState(existingColumns: TableColumn[], tableRoute: string): TableColumn[] {
		const availableColumns = existingColumns;

		if (!this.args.tableRoute || !checkStorageAvailable('localStorage')) {
			return availableColumns;
		}

		// Fetch saved columns from local storage
		const savedState = window.localStorage.getItem(tableRoute);
		if (savedState == null) {
			return availableColumns;
		}

		// Parse saved columns
		const storedTableState = JSON.parse(savedState);

		// Sort and Filter available columns based on saved table state
		const currentColumns = getCurrentColumns(storedTableState, availableColumns);
		return currentColumns;
	}

	includeColumnInCSV(col: TableColumn) {
		if (!col.isVisible || col.name === 'Session Change' || col.name === 'Last Price' || col.cellComponent === CellComponents.Button) {
			return false;
		}

		return true;
	}
}
