import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{this.formattedValue}}", {"contents":"{{this.formattedValue}}","moduleName":"vault-client/components/market-volume.hbs","parseOptions":{"srcName":"vault-client/components/market-volume.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';

interface MarketVolumeArgs {
	rowData: any;
	valuePath: string;
	symbol?: string;
	side?: string;
}

export default class MarketVolume extends Component<MarketVolumeArgs> {
	@service marketData: any;

	registeredSymbols: string[] = [];

	registerSymbol(symbol: string) {
		if (!this.registeredSymbols.includes(symbol)) {
			this.marketData.register(symbol);
			this.registeredSymbols.push(symbol);
		}
	}

	getByCompositeKey(obj: any, key: string) {
		if (!key) {
			return null;
		}

		return key.split('.').reduce((prev, itm) => (prev ? prev[itm] : null), obj) || null;
	}

	get formattedValue() {
		const symbol =
			this.args.symbol ??
			this.getByCompositeKey(this.args.rowData, `${this.args.valuePath}.barchartSymbol`) ??
			this.args.rowData.barchartSymbol;
		this.registerSymbol(symbol);

		const marketDatum = this.marketData.getMarketDatum(symbol);
		let value;

		// This is hacky and should be cleaned up.
		const showValue =
			this.getByCompositeKey(this.args.rowData, `${this.args.valuePath}.instrumentType`) == 'Future' ||
			!this.getByCompositeKey(this.args.rowData, `${this.args.valuePath}.hasExpired`);

		if (marketDatum) {
			switch (this.args.side) {
				case 'volume':
					value = marketDatum.volume;
					break;
				case 'previousVolume':
					value = marketDatum.previousVolume;
					break;
				default:
					value = marketDatum.volume;
			}
		}

		if (value && showValue) {
			return Intl.NumberFormat().format(value);
		} else {
			return '-';
		}
	}

	willDestroy() {
		super.willDestroy();
		this.registeredSymbols.forEach((symbol) => {
			this.marketData.unregister(symbol);
		});
	}
}
