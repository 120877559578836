import InsuranceOverview from 'vault-client/routes/insurance-overview';
import { Query } from 'vault-client/types/graphql-types';

interface QueryParams {
	agent: string | null;
	aipId: string | null;
	stateId: string | null;
	quarterStartDate: string | null;
	quarterEndDate: string | null;
	customerId: string | null;
	id: string | null;
}

type InsuranceOverviewQuery = {
	__typename?: 'Query';
	ClassProduction: Query['AggregateForecastedMilkProductionByMonths'];
	AggregateForecastedMilkProductionByMonths: Query['AggregateForecastedMilkProductionByMonths'];
	AggregateDerivedDrpEndorsements: Query['AggregateDerivedDrpEndorsements'];
};

export default class OrganizationsInsuranceOverview extends InsuranceOverview {
	async model(params: QueryParams) {
		const organizationParams = this.paramsFor('organizations.organization') as { id: string };
		const variables = {
			productionWhere: this.generateProductionWhere(
				params.agent,
				params.aipId,
				params.stateId,
				params.quarterStartDate,
				params.quarterEndDate
			),
			classProductionWhere: this.generateClassProductionWhere(params.agent, params.stateId, params.quarterStartDate, params.quarterEndDate),
			endorsementWhere: this.generateEndorsementWhere(
				params.agent,
				params.aipId,
				params.stateId,
				params.quarterStartDate,
				params.quarterEndDate
			),
			scopeId: organizationParams.id,
		};

		return this.apollo.watchQuery({ query: this.query, variables }) as InsuranceOverviewQuery;
	}
}
