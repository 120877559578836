import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{this.formattedValue}}", {"contents":"{{this.formattedValue}}","moduleName":"vault-client/components/session-change-format.hbs","parseOptions":{"srcName":"vault-client/components/session-change-format.hbs"}});
import Component from '@glimmer/component';
import { inject as service } from '@ember/service';
import { htmlSafe } from '@ember/template';

interface SessionChangeFormatArgs {
	rowData: any;
	valuePath: string;
	fractionDigitsPath: string;
	displayFactorPath: string;
}

export default class SessionChangeFormat extends Component<SessionChangeFormatArgs> {
	@service marketData: any;

	registeredSymbols: string[] = [];

	registerSymbol(symbol: string) {
		if (!this.registeredSymbols.includes(symbol)) {
			this.marketData.register(symbol);
			this.registeredSymbols.push(symbol);
		}
	}

	getByCompositeKey(obj: any, key: string) {
		if (!key) {
			return null;
		}

		return key.split('.').reduce((prev, itm) => (prev ? prev[itm] : null), obj) || null;
	}

	get formattedValue() {
		const symbol = this.getByCompositeKey(this.args.rowData, `${this.args.valuePath}.barchartSymbol`) ?? this.args.rowData.barchartSymbol;
		this.registerSymbol(symbol);

		const marketDatum = this.marketData.getMarketDatum(symbol);
		const value = marketDatum?.netChange ?? null;
		const percentChange = marketDatum?.percentChange ? marketDatum.percentChange / 100 : null;

		// This is hacky and should be cleaned up.
		const showValue =
			(this.getByCompositeKey(this.args.rowData, `${this.args.valuePath}.instrumentType`) == 'Future' ??
				this.args.rowData.instrumentType) ||
			!(this.getByCompositeKey(this.args.rowData, `${this.args.valuePath}.hasExpired`) ?? this.args.rowData.hasExpired);

		if (value && showValue) {
			const fractionDigits = this.getByCompositeKey(this.args.rowData, this.args.fractionDigitsPath);
			const displayFactor = this.args.displayFactorPath ? this.getByCompositeKey(this.args.rowData, this.args.displayFactorPath) : null;

			const price = displayFactor ? displayFactor * value : value;

			const span = document.createElement('span');
			if (price > 0) {
				span.classList.add('text-brand-success-50');
			}
			if (price < 0) {
				span.classList.add('text-brand-error-50');
			}

			const priceString = new Intl.NumberFormat(undefined, {
				minimumFractionDigits: fractionDigits,
				maximumFractionDigits: fractionDigits,
			}).format(price);

			let percentChangeString = '';

			if (percentChange) {
				const formattedPercentChange = new Intl.NumberFormat(undefined, {
					style: 'percent',
					minimumFractionDigits: 2,
				}).format(percentChange);

				percentChangeString = ` (${formattedPercentChange})`;
			}

			span.innerHTML = `${priceString}${percentChangeString}`;

			return htmlSafe(span.outerHTML);
		} else {
			return '-';
		}
	}

	willDestroy() {
		super.willDestroy();
		this.registeredSymbols.forEach((symbol) => {
			this.marketData.unregister(symbol);
		});
	}
}
