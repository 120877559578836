import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{this.formattedValue}}", {"contents":"{{this.formattedValue}}","moduleName":"vault-client/components/intl-date-time-format.hbs","parseOptions":{"srcName":"vault-client/components/intl-date-time-format.hbs"}});
import Component from '@glimmer/component';
import { DateTime } from 'luxon';

interface Args {
	locale: string;
	value: any;
	weekday: any;
	year: any;
	month: any;
	day: any;
	hour: any;
	minute: any;
	second: any;
}

export default class IntlDateTimeFormatComponent extends Component<Args> {
	constructor(owner: any, args: Args) {
		super(owner, args);
	}

	get formattedValue() {
		const locale = this.args.locale ? this.args.locale : 'en-US';

		if (this.args.value) {
			const luxonDate = DateTime.fromISO(this.args.value);
			if (luxonDate.isValid) {
				const date = luxonDate.toJSDate();

				const options: {
					[key: string]: any;
				} = {};

				if (this.args.weekday) {
					options['weekday'] = this.args.weekday;
				}

				options['year'] = this.args.year;
				options['month'] = this.args.month;
				options['day'] = this.args.day;
				options['hour'] = this.args.hour;
				options['minute'] = this.args.minute;

				if (this.args.second) {
					options['second'] = this.args.second;
				}

				return new Intl.DateTimeFormat(locale, options).format(date);
			}
		}
		return '-';
	}
}
