import Controller from '@ember/controller';
import { UiSecondaryNavItemArgs } from 'vault-client/components/ui-secondary-nav-item';
import { BusinessEntityRole } from 'vault-client/types/graphql-types';
import LocationsLocation from 'vault-client/routes/locations/location';
import { ModelFrom } from 'vault-client/utils/type-utils';

export default class LocationsLocationController extends Controller {
	accountRoutePath: string = 'locations.location.account';
	exposureRoute = 'locations.location.exposure';

	declare model: ModelFrom<LocationsLocation>;

	get secondaryNavItems(): Omit<UiSecondaryNavItemArgs, 'uid'>[] {
		const businessRoles = this.model.Location.Customer?.businessRoles;

		if (businessRoles?.find((role: BusinessEntityRole) => role === BusinessEntityRole.DairyProducer)) {
			return [
				{
					label: 'Production',
					link: 'locations.location.production',
				},
				{
					label: 'Milk Check',
					link: 'locations.location.milk-check',
				},
				{
					label: 'Milk Check Reconciliation',
					link: 'locations.location.milk-check-reconciliation',
				},
				{
					label: 'Location Settings',
					link: `locations.location.settings`,
				},
			];
		}

		if (businessRoles?.find((role: BusinessEntityRole) => role === BusinessEntityRole.DairyProcessor)) {
			return [
				{
					label: 'Inventory',
					link: 'locations.location.inventory.index',
				},
				{
					label: 'Location Settings',
					link: `locations.location.settings`,
				},
			];
		}

		return [
			{
				label: 'Location Settings',
				link: `locations.location.settings`,
			},
		];
	}

	get previous(): { label: string; route: string; model: string } | null {
		if (this.model.Location.Customer)
			return {
				label: this.model.Location.Customer.name,
				route: 'businesses.business.dashboard',
				model: this.model.Location.Customer.id,
			};
		return null;
	}

	get current(): { label: string; route: string; model: string } {
		return {
			label: this.model.Location.name,
			route: 'locations.location.settings',
			model: this.model.Location.id,
		};
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'locations/location': LocationsLocationController;
	}
}
