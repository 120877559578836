import TransactionsIndexController from 'vault-client/controllers/transactions';

export default class BusinessesBusinessTransactions extends TransactionsIndexController {
	transactionShowRoute: string = 'businesses.business.transaction';
	accountShowRoute: string = 'businesses.business.account';
	brokerageRoutePath: string = 'businesses.business.brokerage';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'businesses/business/transactions': BusinessesBusinessTransactions;
	}
}
