import FeedPurchasesRoute from 'vault-client/routes/feed/purchases';

export default class BusinessesBusinessFeedPurchasesRoute extends FeedPurchasesRoute {
	async model() {
		const { business_id: businessId } = this.paramsFor('businesses.business') as { business_id: string };
		this.variables = {
			feedIngredientsWhere: this.generateFeedIngredientsWhere(businessId),
		};

		await this.getFeedPurchases.promise;

		return {
			getFeedPurchases: this.getFeedPurchases,
			businessId,
		};
	}
}
