import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<span\n\tclass='{{this.colorClass}}\n\t\t{{this.sizeClass}}\n\t\t{{this.textColor}}\n\t\ttext-xs rounded-xl w-auto px-2 py-0.5 inline-flex items-center whitespace-nowrap'\n\t...attributes\n>\n\t{{#if (has-block)}}\n\t\t{{yield}}\n\t{{else}}\n\t\t{{@text}}\n\t{{/if}}\n</span>", {"contents":"<span\n\tclass='{{this.colorClass}}\n\t\t{{this.sizeClass}}\n\t\t{{this.textColor}}\n\t\ttext-xs rounded-xl w-auto px-2 py-0.5 inline-flex items-center whitespace-nowrap'\n\t...attributes\n>\n\t{{#if (has-block)}}\n\t\t{{yield}}\n\t{{else}}\n\t\t{{@text}}\n\t{{/if}}\n</span>","moduleName":"vault-client/components/ui-badge.hbs","parseOptions":{"srcName":"vault-client/components/ui-badge.hbs"}});
import Component from '@glimmer/component';

export type UiBadgeStyle =
	| 'bright'
	| 'primary'
	| 'white'
	| 'lemon'
	| 'positive'
	| 'interactive-blue'
	| 'vgs-new'
	| 'vgs-submitted'
	| 'vgs-pending'
	| 'vgs-working'
	| 'vgs-cancelled'
	| 'vgs-pending-cancellation'
	| 'vgs-filled';
export type UiBadgeSize = 'small' | 'micro';

interface UiBadgeComponentArgs {
	text?: string;
	style?: UiBadgeStyle;
	size?: UiBadgeSize;
}

export default class UiBadgeComponent extends Component<UiBadgeComponentArgs> {
	get colorClass() {
		switch (this.args.style) {
			case 'bright':
				return 'bg-brand-error-50';
			case 'primary':
				return 'bg-brand-interactive-blue-70';
			case 'white':
				return 'bg-brand-white';
			case 'lemon':
				return 'bg-brand-lemon-30';
			case 'interactive-blue':
				return 'bg-brand-interactive-blue-20';
			case 'positive':
				return 'bg-brand-success-30';
			case 'vgs-new':
				return 'bg-brand-blue-20';
			case 'vgs-pending':
				return 'bg-brand-teal-20';
			case 'vgs-working':
				return 'bg-brand-lime-10';
			case 'vgs-submitted':
				return 'bg-brand-lemon-20';
			case 'vgs-cancelled':
				return 'bg-brand-error-10';
			case 'vgs-filled':
				return 'bg-brand-gray-20';
			case 'vgs-pending-cancellation':
				return 'bg-brand-warning-40';
			default:
				return 'bg-brand-interactive-blue-70';
		}
	}

	get sizeClass() {
		switch (this.args.size) {
			case 'micro':
				return 'h-4';
			case 'small':
				return 'h-5';
			default:
				return 'h-5';
		}
	}

	get textColor() {
		switch (this.args.style) {
			case 'white':
				return 'text-brand-gray-90';
			case 'positive':
				return 'text-brand-gray-90';
			case 'interactive-blue':
				return 'text-brand-gray-90';
			case 'lemon':
				return 'text-brand-gray-90';
			case 'vgs-new':
				return 'text-brand-blue-80';
			case 'vgs-pending':
				return ' text-brand-teal-80';
			case 'vgs-working':
				return 'text-brand-lime-60';
			case 'vgs-submitted':
				return 'text-brand-lemon-70';
			case 'vgs-cancelled':
				return 'text-brand-error-70';
			case 'vgs-filled':
				return 'text-brand-gray-80';
			case 'vgs-pending-cancellation':
				return 'text-brand-error-60';
			default:
				return 'text-brand-white';
		}
	}
}
