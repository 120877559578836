import Controller from '@ember/controller';
import { CellComponents, TableColumn } from 'vault-client/types/vault-table';

export default class CropsIndexController extends Controller {
	cropPlansRoute = '';
  cropTargetsRoute = '';
  cropFillsRoute = '';
  fieldsRoute = '';

	get columns(): TableColumn[] {
		return [
			{
				id: 'b1c53153-81c6-48d6-86ec-22635584a9d9',
				name: 'Crops',
				valuePath: 'name',
				cellComponent: CellComponents.String,
				linkRoutePath: 'route',
				isSortable: false,
				isFixed: '',
				isVisible: true,
				textAlign: 'left',
			},
		];
	}

	get rows() {
		return [
			{
				name: 'Crop Plans',
				route: this.cropPlansRoute,
			},
			{
				name: 'Targets',
				route: this.cropTargetsRoute,
			},
			{
				name: 'Fills',
				route: this.cropFillsRoute,
			},
      {
				name: 'Fields',
				route: this.fieldsRoute,
			},
		];
	}
}
