import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<PowerSelect\n\t@selected={{this.selectedMilkOrder}}\n\t@options={{this.milkOrders}}\n\t@allowClear={{@allowClear}}\n\t@onChange={{@onChange}}\n\t@renderInPlace={{@renderInPlace}}\n\t@placeholder={{@placeholder}}\n\t...attributes\n\tas |item|\n>\n\t{{item.name}}\n</PowerSelect>", {"contents":"<PowerSelect\n\t@selected={{this.selectedMilkOrder}}\n\t@options={{this.milkOrders}}\n\t@allowClear={{@allowClear}}\n\t@onChange={{@onChange}}\n\t@renderInPlace={{@renderInPlace}}\n\t@placeholder={{@placeholder}}\n\t...attributes\n\tas |item|\n>\n\t{{item.name}}\n</PowerSelect>","moduleName":"vault-client/components/milk-order-select.hbs","parseOptions":{"srcName":"vault-client/components/milk-order-select.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { MilkOrder, Query } from 'vault-client/types/graphql-types';
import { gql, useQuery } from 'glimmer-apollo';
import { tracked } from '@glimmer/tracking';

interface MilkOrderSelectArgs {
	selected: MilkOrder;
	onChange: (_milkOrder: MilkOrder) => {};
}

const GET_MILK_ORDERS = gql`
	query MilkOrders {
		MilkOrders(orderBy: { federalOrderNumber: Asc }) {
			id
			name
		}
	}
`;

export default class MilkOrderSelect extends Component<MilkOrderSelectArgs> {
	@tracked milkOrders: MilkOrder[] = [];
	getMilkOrders = useQuery<{ MilkOrders: Query['MilkOrders'] }>(this, () => [
		GET_MILK_ORDERS,
		{
			onComplete: (data) => {
				this.milkOrders = data?.MilkOrders ?? [];
			},
		},
	]);

	constructor(owner: unknown, args: MilkOrderSelectArgs) {
		super(owner, args);
		this.fetchMilkOrders();
	}

	get selectedMilkOrder() {
		return this.args.selected ? this.args.selected : null;
	}

	@action
	async fetchMilkOrders() {
		await this.getMilkOrders.promise;
	}
}
