import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<PowerSelect\n\t@selected={{this.selected}}\n\t@options={{this.permissionGroups}}\n\t@searchEnabled='true'\n\t@renderInPlace={{@renderInPlace}}\n\t@searchField='name'\n\t@placeholder='Select a Permission Group'\n\t@onChange={{@onChange}}\n\t@allowClear={{@allowClear}}\n\t...attributes\n\tas |permissionGroup|\n>\n\t{{permissionGroup.name}}\n</PowerSelect>", {"contents":"<PowerSelect\n\t@selected={{this.selected}}\n\t@options={{this.permissionGroups}}\n\t@searchEnabled='true'\n\t@renderInPlace={{@renderInPlace}}\n\t@searchField='name'\n\t@placeholder='Select a Permission Group'\n\t@onChange={{@onChange}}\n\t@allowClear={{@allowClear}}\n\t...attributes\n\tas |permissionGroup|\n>\n\t{{permissionGroup.name}}\n</PowerSelect>","moduleName":"vault-client/components/vgs-permission-group-select.hbs","parseOptions":{"srcName":"vault-client/components/vgs-permission-group-select.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { gql, useQuery } from 'glimmer-apollo';
import { tracked } from 'tracked-built-ins';
import { EntityGroup, Query } from 'vault-client/types/graphql-types';

const GET_PERMISSION_GROUPS = gql`
	query EntityGroups {
		EntityGroups(orderBy: { name: Desc }, where: { isVgs: { equals: true } }) {
			id
			name
		}
	}
`;

interface VgsPermissionGroupSelectArgs {
	selected: EntityGroup | null;
	selectedId: string | null;
	onChange: (advisor: EntityGroup | null) => void;
}

export default class VgsPermissionGroupSelect extends Component<VgsPermissionGroupSelectArgs> {
	@tracked permissionGroups: EntityGroup[] | null = [];

	constructor(owner: unknown, args: VgsPermissionGroupSelectArgs) {
		super(owner, args);
		this.fetchPermissionGroups();
	}

	get selected() {
		if (this.args.selected) {
			return this.args.selected;
		}

		const selectedId = this.args.selectedId;
		if (selectedId) {
			return this.permissionGroups?.find((permissionGroup) => permissionGroup.id == selectedId) ?? null;
		}

		return null;
	}

	@action
	async fetchPermissionGroups() {
		await useQuery<{ EntityGroups: Query['EntityGroups'] }>(this, () => [
			GET_PERMISSION_GROUPS,
			{
				onComplete: (data) => {
					if (data?.EntityGroups) {
						this.permissionGroups = data.EntityGroups ?? [];
					} else {
						this.permissionGroups = [];
					}
				},
				onError: (error) => {
					console.error('Error on advisor select', error.message);
				},
			},
		]).promise;
	}
}
