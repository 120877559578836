import PositionsShowController from 'vault-client/controllers/positions/show';

export default class OrganizationsOrganizationPositionController extends PositionsShowController {
	positionsRoutePath: string = 'organizations.organization.positions';
	transactionShowRoute: string = 'organizations.organization.transaction';
	accountShowRoute: string = 'organizations.organization.account';

	// Disables the exposure hedge month detail route links in the header columns
	exposureHedgeMonthDetailRoute = null;
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'organizations/organization/position': OrganizationsOrganizationPositionController;
	}
}
