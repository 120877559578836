const doc = {
  "kind": "Document",
  "definitions": [
    {
      "kind": "OperationDefinition",
      "operation": "query",
      "name": {
        "kind": "Name",
        "value": "DRPPerformance"
      },
      "variableDefinitions": [
        {
          "kind": "VariableDefinition",
          "variable": {
            "kind": "Variable",
            "name": {
              "kind": "Name",
              "value": "where"
            }
          },
          "type": {
            "kind": "NamedType",
            "name": {
              "kind": "Name",
              "value": "DerivedDrpEndorsementFilterDTO"
            }
          },
          "directives": []
        }
      ],
      "directives": [],
      "selectionSet": {
        "kind": "SelectionSet",
        "selections": [
          {
            "kind": "Field",
            "name": {
              "kind": "Name",
              "value": "AggregateDerivedDrpEndorsements"
            },
            "arguments": [
              {
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "calc"
                },
                "value": {
                  "kind": "ObjectValue",
                  "fields": [
                    {
                      "kind": "ObjectField",
                      "name": {
                        "kind": "Name",
                        "value": "sum"
                      },
                      "value": {
                        "kind": "ObjectValue",
                        "fields": [
                          {
                            "kind": "ObjectField",
                            "name": {
                              "kind": "Name",
                              "value": "effectiveCoveredMilkProduction"
                            },
                            "value": {
                              "kind": "BooleanValue",
                              "value": true
                            }
                          },
                          {
                            "kind": "ObjectField",
                            "name": {
                              "kind": "Name",
                              "value": "pnl"
                            },
                            "value": {
                              "kind": "BooleanValue",
                              "value": true
                            }
                          },
                          {
                            "kind": "ObjectField",
                            "name": {
                              "kind": "Name",
                              "value": "indemnity"
                            },
                            "value": {
                              "kind": "BooleanValue",
                              "value": true
                            }
                          },
                          {
                            "kind": "ObjectField",
                            "name": {
                              "kind": "Name",
                              "value": "producerPremiumAmount"
                            },
                            "value": {
                              "kind": "BooleanValue",
                              "value": true
                            }
                          },
                          {
                            "kind": "ObjectField",
                            "name": {
                              "kind": "Name",
                              "value": "totalPremiumAmount"
                            },
                            "value": {
                              "kind": "BooleanValue",
                              "value": true
                            }
                          }
                        ]
                      }
                    }
                  ]
                }
              },
              {
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "groupBy"
                },
                "value": {
                  "kind": "ObjectValue",
                  "fields": [
                    {
                      "kind": "ObjectField",
                      "name": {
                        "kind": "Name",
                        "value": "quarterStartDate"
                      },
                      "value": {
                        "kind": "BooleanValue",
                        "value": true
                      }
                    },
                    {
                      "kind": "ObjectField",
                      "name": {
                        "kind": "Name",
                        "value": "quarterEndDate"
                      },
                      "value": {
                        "kind": "BooleanValue",
                        "value": true
                      }
                    }
                  ]
                }
              },
              {
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "where"
                },
                "value": {
                  "kind": "Variable",
                  "name": {
                    "kind": "Name",
                    "value": "where"
                  }
                }
              },
              {
                "kind": "Argument",
                "name": {
                  "kind": "Name",
                  "value": "orderBy"
                },
                "value": {
                  "kind": "ObjectValue",
                  "fields": [
                    {
                      "kind": "ObjectField",
                      "name": {
                        "kind": "Name",
                        "value": "quarterStartDate"
                      },
                      "value": {
                        "kind": "EnumValue",
                        "value": "Asc"
                      }
                    }
                  ]
                }
              }
            ],
            "directives": [],
            "selectionSet": {
              "kind": "SelectionSet",
              "selections": [
                {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "quarterStartDate"
                  },
                  "arguments": [],
                  "directives": []
                },
                {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "quarterEndDate"
                  },
                  "arguments": [],
                  "directives": []
                },
                {
                  "kind": "Field",
                  "name": {
                    "kind": "Name",
                    "value": "sum"
                  },
                  "arguments": [],
                  "directives": [],
                  "selectionSet": {
                    "kind": "SelectionSet",
                    "selections": [
                      {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "effectiveCoveredMilkProduction"
                        },
                        "arguments": [],
                        "directives": []
                      },
                      {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "pnl"
                        },
                        "arguments": [],
                        "directives": []
                      },
                      {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "indemnity"
                        },
                        "arguments": [],
                        "directives": []
                      },
                      {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "producerPremiumAmount"
                        },
                        "arguments": [],
                        "directives": []
                      },
                      {
                        "kind": "Field",
                        "name": {
                          "kind": "Name",
                          "value": "totalPremiumAmount"
                        },
                        "arguments": [],
                        "directives": []
                      }
                    ]
                  }
                }
              ]
            }
          }
        ]
      }
    }
  ],
  "loc": {
    "start": 0,
    "end": 515
  }
};
export default doc;
