import DrpPerformanceBySalesEffectiveDateMonth from 'vault-client/controllers/reports/drp-performance-by-sales-effective-date-month';

export default class OrganizationsOrganizationDrpPerformanceBySalesEffectiveDateMonth extends DrpPerformanceBySalesEffectiveDateMonth {
	reportsRoute: string = 'organizations.organization.reports';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'organizations/organization/drp-performance-by-sales-effective-date-month': OrganizationsOrganizationDrpPerformanceBySalesEffectiveDateMonth;
	}
}
