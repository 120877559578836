import { gql, useMutation } from 'glimmer-apollo';
import { Mutation, Mutation_createFieldArgs } from 'vault-client/types/graphql-types';

export type CreateFieldData = { error: string; name: string; acres: string; businessId: string };
const CREATE_GRAIN_FIELD = gql`
	mutation CreateField($data: FieldCreateDTO!) {
		createField(data: $data) {
			id
			acres
			name
		}
	}
`;

async function createField(context: object, variables: Mutation_createFieldArgs) {
	const mutation = useMutation<{ createField: Mutation['createField'] }, Mutation_createFieldArgs>(context, () => [
		CREATE_GRAIN_FIELD,
		{
			onError: (error): void => {
				console.error(error);
				throw new Error(`Failed to create field: ${variables}`);
			},

			update: (cache) => {
				cache.evict({ fieldName: 'Field' });
				cache.evict({ fieldName: 'Fields' });
				cache.gc();
			},
		},
	]);

	await mutation.mutate(variables);
	return mutation.data;
}

function parseFieldData(data: CreateFieldData) {
	return {
		name: data.name.trim(),
		acres: typeof data.acres == 'string' ? parseInt(data.acres.trim()) : data.acres,
		businessId: data.businessId,
	};
}

export { createField, parseFieldData };
