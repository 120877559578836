import Route from '@ember/routing/route';
import { tracked } from '@glimmer/tracking';
import { gql, useQuery } from 'glimmer-apollo';
import { Query, Query_BuyersArgs, SortByDirection } from 'vault-client/types/graphql-types';
import { SortObj } from 'vault-client/types/vault-table';

export const GET_BUYERS = gql`
	query Buyers($orderBy: BuyerSortByDTO) {
		Buyers(orderBy: $orderBy) {
			id
			name
		}
	}
`;

type getBuyers = {
	__typename?: 'Query';

	Buyers: Query['Buyers'];
};

interface QueryParams {
	sorts: SortObj[];
}

export default class VgsSettingsBuyers extends Route {
	@tracked variables: Query_BuyersArgs = {};

	getBuyers = useQuery<getBuyers, Query_BuyersArgs>(this, () => [GET_BUYERS, { variables: this.variables }]);

	queryParams = {
		sorts: { refreshModel: true },
	};

	generateOrderBy(sorts: SortObj[]) {
		const orderBy: {
			[key: string]: any;
		} = {};

		sorts.forEach((sort: any) => {
			const parts = sort.valuePath.split('.');
			const firstPart = parts.shift();

			let value: any = sort.isAscending ? SortByDirection.Asc : SortByDirection.Desc;

			while (parts.length > 0) {
				const part = parts.pop();
				const obj: {
					[key: string]: any;
				} = {};
				obj[part] = value;
				value = obj;
			}

			orderBy[firstPart] = value;
		});

		return orderBy;
	}

	async model(params: QueryParams) {
		this.variables = {
			orderBy: this.generateOrderBy(params.sorts),
		};

		await this.getBuyers.promise;

		return { getBuyers: this.getBuyers, variables: this.variables };
	}
}
