import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<span class='tool-tip-reveal' aria-describedby='customer-name-hint' {{did-update this.fetchName @customerId}}>\n\t{{#if this.name}}\n\t\t{{this.name}}\n\t\t<Vault::UiToolTip @id='customer-name-hint' aria-hidden='true' class='mt-2' @text={{this.name}} />\n\t{{else}}\n\t\t...\n\t{{/if}}\n</span>", {"contents":"<span class='tool-tip-reveal' aria-describedby='customer-name-hint' {{did-update this.fetchName @customerId}}>\n\t{{#if this.name}}\n\t\t{{this.name}}\n\t\t<Vault::UiToolTip @id='customer-name-hint' aria-hidden='true' class='mt-2' @text={{this.name}} />\n\t{{else}}\n\t\t...\n\t{{/if}}\n</span>","moduleName":"vault-client/components/customer-name.hbs","parseOptions":{"srcName":"vault-client/components/customer-name.hbs"}});
import Component from '@glimmer/component';
import { queryManager } from 'ember-apollo-client';
import query from 'vault-client/graphql/queries/customer-get.graphql';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

interface Args {
	customerId: string;
}

export default class CustomerNameComponent extends Component<Args> {
	@queryManager apollo: any;

	@tracked _name: any;

	constructor(owner: any, args: Args) {
		super(owner, args);
		this.fetchName();
	}

	get name() {
		return this._name;
	}

	@action
	async fetchName() {
		if (this.args.customerId) {
			this._name = (
				await this.apollo.watchQuery({
					query,
					variables: { id: this.args.customerId },
				})
			).Customer.name;
		} else {
			this._name = 'N/A';
		}
	}
}
