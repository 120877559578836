import { modifier } from 'ember-modifier';

export default modifier((element: HTMLElement, [disableRowSelectCondition, row]: [(row: unknown) => boolean | undefined, unknown]) => {
	if (!disableRowSelectCondition || disableRowSelectCondition(row) === false) return;

	const checkboxElement = element.getElementsByTagName('input').item(0);

	checkboxElement?.setAttribute('disabled', '');

	return () => {
		checkboxElement?.removeAttribute('disabled');
	};
});
