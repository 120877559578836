import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<span {{did-insert this.fetchEntityGroups}}>\n\n\t<PowerSelect\n\t\t@selected={{this.selectedEntityGroup}}\n\t\t@options={{this.filteredEntityGroups}}\n\t\t@searchEnabled={{false}}\n\t\t@allowClear={{true}}\n\t\t@searchField='name'\n\t\t@placeholder='All Entity Groups'\n\t\t@onChange={{this.onChange}}\n\t\t@renderInPlace={{@renderInPlace}}\n\t\tas |item|\n\t>\n\t\t{{item.name}}\n\t</PowerSelect>\n\n</span>", {"contents":"<span {{did-insert this.fetchEntityGroups}}>\n\n\t<PowerSelect\n\t\t@selected={{this.selectedEntityGroup}}\n\t\t@options={{this.filteredEntityGroups}}\n\t\t@searchEnabled={{false}}\n\t\t@allowClear={{true}}\n\t\t@searchField='name'\n\t\t@placeholder='All Entity Groups'\n\t\t@onChange={{this.onChange}}\n\t\t@renderInPlace={{@renderInPlace}}\n\t\tas |item|\n\t>\n\t\t{{item.name}}\n\t</PowerSelect>\n\n</span>","moduleName":"vault-client/components/entity-group-select.hbs","parseOptions":{"srcName":"vault-client/components/entity-group-select.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { queryManager } from 'ember-apollo-client';
import query from 'vault-client/graphql/queries/entity-group-select.graphql';
import { EntityGroup, EntityGroupEntry } from 'vault-client/types/graphql-types';

interface EntityGroupSelectArgs {
	organizationId: String;
	selected: EntityGroup;
	onChange: Function;
	businessExistingEntityGroups?: EntityGroupEntry[];
}

export default class EntityGroupSelect extends Component<EntityGroupSelectArgs> {
	@queryManager apollo: any;

	@tracked filteredEntityGroups: EntityGroup[] = [];
	@tracked _selectedEntityGroup: EntityGroup | null = null;

	get selectedEntityGroup() {
		return this._selectedEntityGroup ?? this.args.selected;
	}

	set selectedEntityGroup(value) {
		this._selectedEntityGroup = value;
	}

	@action
	async fetchEntityGroups() {
		const entityGroups = (
			await this.apollo.watchQuery({
				query,
				variables: {
					where: {
						organizationId: {
							equals: this.args.organizationId,
						},
					},
				},
			})
		).EntityGroups;

		this.filteredEntityGroups = entityGroups
			.filter(
				(group: EntityGroup) =>
					!this.args.businessExistingEntityGroups?.some((businessGroups: EntityGroupEntry) => group.id === businessGroups.Group.id)
			)
			.sortBy('name');
	}

	@action
	onChange(selected: any) {
		const onChange = this.args.onChange;

		onChange(selected);
	}
}
