import Controller from '@ember/controller';
import { A } from '@ember/array';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { Future, Product } from 'vault-client/types/graphql-types';
import { DateTime } from 'luxon';
import resetVaultTableScroll from 'vault-client/utils/reset-vault-table-scroll';
import { CellComponents, SortObj, TableColumn } from 'vault-client/types/vault-table';
import { guidFor } from '@ember/object/internals';

enum Charts {
	Futures = 'Futures',
	Cash = 'Cash',
}

export default class MarketsProductOverviewController extends Controller {
	_activeChart: Charts | null = null;

	@tracked model: any;
	@tracked startDate = DateTime.now().minus({ month: 1 }).toISODate();
	@tracked endDate = DateTime.now().toISODate();
	@tracked sorts: SortObj[] = [
		{
			valuePath: 'displayExpiresAt',
			isAscending: true,
		},
	];
	@tracked columns: TableColumn[] = A([
		{
			id: '84aecf08-e97c-4c8e-b24d-2395044073b2',
			name: 'Exp Month Symbol',
			valuePath: 'displayExpiresAt',
			cellComponent: CellComponents.MonthFormat,
			minWidth: 190,
			textAlign: 'left',
			isSortable: true,
			isFixed: '',
			isVisible: true,
			linkRoute: 'markets.product.futures.future',
			linkModelPath: 'id',
		},
		{
			id: '0f843423-0e7c-4445-a5fe-4390c8cdbda9',
			name: 'Last Price',
			valuePath: 'self',
			minWidth: 105,
			cellComponent: CellComponents.MarketPriceFormat,
			componentArgs: {
				fractionDigitsPath: 'SymbolGroup.fractionDigits',
				displayFactorPath: 'SymbolGroup.displayFactor',
				side: 'last',
			},
			textAlign: 'right',
			isSortable: false,
			isFixed: '',
			isVisible: true,
		},
		{
			id: '0c2c9b51-9003-4a5d-88a3-d71ddc8a3e65',
			name: 'Change',
			valuePath: 'self',
			minWidth: 115,
			cellComponent: CellComponents.SessionChangeFormat,
			componentArgs: {
				fractionDigitsPath: 'SymbolGroup.fractionDigits',
				displayFactorPath: 'SymbolGroup.displayFactor',
			},
			textAlign: 'right',
			isSortable: false,
			isFixed: '',
			isVisible: true,
		},
		{
			id: 'cac05243-ef07-41b6-8f57-ae8431e44153',
			name: 'Previous Settlement',
			valuePath: 'self',
			minWidth: 170,
			cellComponent: CellComponents.MarketPriceFormat,
			componentArgs: {
				fractionDigitsPath: 'SymbolGroup.fractionDigits',
				displayFactorPath: 'SymbolGroup.displayFactor',
				side: 'previousSettlement',
			},
			textAlign: 'right',
			isSortable: false,
			isFixed: '',
			isVisible: true,
		},
		{
			id: 'c6696658-af90-46b0-9f7c-0ae5646dce48',
			name: 'Volume',
			valuePath: 'self',
			minWidth: 95,
			cellComponent: CellComponents.MarketVolume,
			componentArgs: {
				side: 'volume',
			},
			textAlign: 'right',
			isSortable: false,
			isFixed: '',
			isVisible: true,
		},
		{
			id: 'dc2dfec0-7d68-451d-a29d-6965ef799440',
			name: 'Open Interest',
			valuePath: 'right',
			minWidth: 125,
			cellComponent: CellComponents.MarketOpenInterest,
			componentArgs: {
				side: 'openInterest',
			},
			textAlign: 'center',
			isSortable: false,
			isFixed: '',
			isVisible: true,
		},
		{
			id: 'f466b156-6d64-4509-8790-fb1d953d296c',
			name: 'Open',
			valuePath: 'self',
			minWidth: 95,
			cellComponent: CellComponents.MarketPriceFormat,
			componentArgs: {
				fractionDigitsPath: 'SymbolGroup.fractionDigits',
				displayFactorPath: 'SymbolGroup.displayFactor',
				side: 'open',
			},
			textAlign: 'right',
			isSortable: false,
			isFixed: '',
			isVisible: true,
		},
		{
			id: 'd4b08be7-a5c7-46f4-b27b-f7c70caa56ff',
			name: 'High',
			valuePath: 'self',
			minWidth: 95,
			cellComponent: CellComponents.MarketPriceFormat,
			componentArgs: {
				fractionDigitsPath: 'SymbolGroup.fractionDigits',
				displayFactorPath: 'SymbolGroup.displayFactor',
				side: 'high',
			},
			textAlign: 'right',
			isSortable: false,
			isFixed: '',
			isVisible: true,
		},
		{
			id: '161eab9f-c5e2-422c-9442-6c2605f29a91',
			name: 'Low',
			valuePath: 'self',
			minWidth: 95,
			cellComponent: CellComponents.MarketPriceFormat,
			componentArgs: {
				fractionDigitsPath: 'SymbolGroup.fractionDigits',
				displayFactorPath: 'SymbolGroup.displayFactor',
				side: 'low',
			},
			textAlign: 'right',
			isSortable: false,
			isFixed: '',
			isVisible: true,
		},
		{
			id: '161eab9f-c5e2-422c-9442-6c2605f29a91',
			name: 'Number of Trades',
			valuePath: 'self',
			minWidth: 140,
			cellComponent: CellComponents.MarketDataProperty,
			componentArgs: {
				property: 'numTrades',
			},
			textAlign: 'right',
			isSortable: false,
			isFixed: '',
			isVisible: true,
		},
		{
			id: '64cd3c35-8fae-4eeb-b08f-c9c8d74e7ba1',
			name: 'Last Updated',
			valuePath: 'updatedAt',
			minWidth: 240,
			cellComponent: CellComponents.MarketDataProperty,
			componentArgs: {
				property: 'serverTimestamp',
				formatter: (value: string) => {
					return DateTime.fromISO(value).toLocaleString(DateTime.DATETIME_FULL);
				},
			},
			textAlign: 'right',
			isSortable: false,
			isFixed: '',
			isVisible: true,
		},
	]);

	id = guidFor(this);

	get activeChart() {
		return this._activeChart ?? this.activeChartOptions[0];
	}

	set activeChart(value: Charts | null) {
		this._activeChart = value;
	}

	get currentFuturesChartId() {
		return `${this.product.name.toLowerCase()}-current-futures-chart`;
	}

	get cashPriceChartId() {
		return `${this.product.name.toLowerCase()}-cash-price-chart`;
	}

	get futures(): Future[] {
		return this.product.CurrentFutures;
	}

	get excludedProps() {
		const excludedProps = [];

		if (this.isCashInstrument && !this.isFutureInstrument) {
			excludedProps.push('monthsTraded', 'symbol', 'exchangeMargin');
		}

		return excludedProps;
	}

	get product(): Product {
		return this.model.Product as Product;
	}

	get isCashInstrument() {
		return !!this.product.CashInstrument;
	}

	get isFutureInstrument() {
		return !!this.product.MostCurrentFuture;
	}

	@action
	updateSorts(sorts: SortObj[]) {
		this.sorts = sorts;
		resetVaultTableScroll(`products-show-${this.product.slug}-table`);
	}

	get activeChartOptions() {
		const options = [];
		if (this.isFutureInstrument) options.push(Charts.Futures);
		if (this.isCashInstrument) options.push(Charts.Cash);
		return options;
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'markets/product/overview': MarketsProductOverviewController;
	}
}
