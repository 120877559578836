import Route from '@ember/routing/route';
import { gql, useQuery } from 'glimmer-apollo';
import { tracked } from '@glimmer/tracking';
import {
	Query,
	Query_HedgeStrategiesArgs,
	LgmInsuranceEndorsement,
	LrpInsuranceEndorsement,
	HedgeStrategyStatus,
} from 'vault-client/types/graphql-types';
import { ModelFrom } from 'vault-client/utils/type-utils';
import BusinessesBusinessRoute from 'vault-client/routes/businesses/business';
export const GET_HEDGE_STRATEGIES = gql`
	query HedgeStrategies($where: HedgeStrategyFilterDTO, $countWhere: HedgeStrategyFilterDTO) {
		HedgeStrategies(where: $where) {
			id
			name
			businessId
			description
			endDate
			createdAt
			startDate
			status
			netPnl
			productId
			Product {
				name
			}
		}
		HedgeStrategyCount(where: $countWhere) {
			count
		}
	}
`;

type AsInsuranceEndorsement = LgmInsuranceEndorsement & LrpInsuranceEndorsement;

type HedgeStrategyQuery = {
	productId: string;
	Transactions?: Array<{ Instrument: { Product: { name: string; id: string } } }>;
	productName: string | null | undefined;
	name: string;
	createdAt: string;
	startDate: string;
	endDate: string;
	netPnl?: number;
	description?: string;
	status?: HedgeStrategyStatus;
	InsuranceEndorsements?: AsInsuranceEndorsement[];
	HedgeStrategy: Query['HedgeStrategies'];
	HedgeStrategyCount: Query['HedgeStrategyCount'];
	Product: Query['Product'];
};

type HedgeStrategies = {
	HedgeStrategies: HedgeStrategyQuery[];
	HedgeStrategyCount: Query['HedgeStrategyCount'];
};

type Params = {
	showInactive: boolean;
};

export default class BusinessesBusinessHedgeStrategiesIndexRoute extends Route {
	@tracked variables = {};

	queryParams = {
		showInactive: {
			refreshModel: true,
		},
	};

	getHedgeStrategies = useQuery<HedgeStrategies, Query_HedgeStrategiesArgs>(this, () => [
		GET_HEDGE_STRATEGIES,
		{ variables: this.variables },
	]);

	async model(params: Params) {
		const { businessId } = this.modelFor('businesses.business') as ModelFrom<BusinessesBusinessRoute>;

		this.variables = {
			countWhere: {
				businessId: {
					equals: businessId,
				},
			},
			where: {
				businessId: {
					equals: businessId,
				},
				...(params.showInactive ? {} : { status: { equals: HedgeStrategyStatus.Active } }),
			},
		};

		await this.getHedgeStrategies.promise;

		return { businessId: businessId, hedgeStrategies: this.getHedgeStrategies };
	}
}
