import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import query from 'vault-client/graphql/queries/businesses/index.graphql';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import resetVaultTableScroll from 'vault-client/utils/reset-vault-table-scroll';
import { ModelFrom } from 'vault-client/utils/type-utils';
import CustomersIndexRoute from 'vault-client/routes/organizations/organization/businesses';
import { CustomerEntityFilterDTO, SortByDirection } from 'vault-client/types/graphql-types';

export default class CustomersIndexController extends Controller {
	@service permissions: any;
	@service applicationScope: any;
	queryParams = ['page', 'sorts', 'size'];
	declare model: ModelFrom<CustomersIndexRoute>;

	@tracked size = 100;

	@tracked page = 0;
	@tracked sorts = [{ valuePath: 'name', isAscending: true }];
	get columns() {
		const baseColumns = [
			{
				id: 'b8b645c0-50ab-4c06-b4fc-747bc53c9811',
				name: 'Name',
				valuePath: 'name',
				minWidth: 300,
				textAlign: 'left',
				headerTextAlign: 'left',
				isSortable: true,
				cellComponent: 'String',
				isFixed: '',
				isVisible: true,
				linkRoute: 'businesses.business.dashboard',
				linkModelPath: 'id',
			},
			{
				id: 'c033916a-4238-4d8f-8868-24cb982f69be',
				name: 'Business Roles',
				valuePath: 'businessRoles',
				textAlign: 'left',
				headerTextAlign: 'left',
				isSortable: false,
				cellComponent: 'FormatArrayOfStrings',
				isFixed: '',
				isVisible: true,
			},
		];
		return baseColumns;
	}

	get sortParams() {
		return this.sorts;
	}

	set sortParams(sorts) {
		this.sorts = sorts;
	}

	get currentPage() {
		return this.page;
	}

	set currentPage(page) {
		this.page = page;
	}

	get customerData() {
		return this.model.Customers.data?.Customers;
	}

	get totalNumCustomers() {
		return this.model.Customers.data?.CustomerCount.count;
	}

	get query() {
		return query;
	}

	get formattedQueryParams() {
		const orderBy: { [key: string]: SortByDirection } = {};
		this.sorts.forEach((sort) => {
			const value = sort.isAscending ? SortByDirection.Asc : SortByDirection.Desc;
			orderBy[sort.valuePath] = value;
		});

		const where: CustomerEntityFilterDTO = {};
		if (this.applicationScope.organizationId) {
			where.organizationId = { equals: this.applicationScope.organizationId };
		}

		const variables = {
			where,
			offset: this.page * this.size,
			limit: this.size,
			orderBy,
		};

		return variables;
	}

	@action
	resetTableScrollPosition() {
		const scrollableDiv = document.getElementById('customers-index-table')?.querySelector('.ember-table-overflow');
		if (scrollableDiv) scrollableDiv.scrollTop = 0;
	}

	@action
	setTablePageState(newPageVal: number) {
		this.currentPage = newPageVal ?? 0;
		resetVaultTableScroll('customers-index-table');
	}
}
