import { setOwner } from '@ember/application';
import { service } from '@ember/service';
import ScenarioPricesService from 'vault-client/services/scenario-prices';
import { ForecastedMilkUtilization, Future, AggregateLedgerEntryDTO } from 'vault-client/types/graphql-types';

export default class ScenarioMilkCheck {
	date: string;
	id: string;
	classiDifferential: number;
	milkUtilizations: ForecastedMilkUtilization;
	basis: number | null = null;

	butterFuture: Future | null = null;
	advancedButterFuture: Future | null = null;
	nonfatDryMilkFuture: Future | null = null;
	advancedNonfatDryMilkFuture: Future | null = null;
	cheeseFuture: Future | null = null;
	advancedCheeseFuture: Future | null = null;
	dryWheyFuture: Future | null = null;
	advancedDryWheyFuture: Future | null = null;
	monthlyPremiumsAndDeductions: AggregateLedgerEntryDTO | null = null;
	grossProduction: number | null = null;
	otherSolidsPercent: number | null = null;
	butterfatPercent: number | null = null;
	proteinPercent: number | null = null;
	@service declare scenarioPrices: ScenarioPricesService;

	constructor(
		owner: any,
		date: string,
		milkUtilizations: ForecastedMilkUtilization,
		grossProduction: number,
		classIDifferential: number = 0,
		basis: number | null,
		butterFuture: Future | null,
		advancedButterFuture: Future | null,
		nonfatDryMilkFuture: Future | null,
		advancedNonfatDryMilkFuture: Future | null,
		cheeseFuture: Future | null,
		advancedCheeseFuture: Future | null,
		dryWheyFuture: Future | null,
		advancedDryWheyFuture: Future | null,
		grossOtherSolidsPercent: number | null,
		grossButterFatPercent: number | null,
		grossProteinPercent: number | null
	) {
		setOwner(this, owner);
		this.id = date;
		this.date = date;
		this.milkUtilizations = milkUtilizations;
		this.grossProduction = grossProduction;
		this.classiDifferential = classIDifferential;
		this.basis = basis;
		this.butterFuture = butterFuture;
		this.advancedButterFuture = advancedButterFuture;
		this.nonfatDryMilkFuture = nonfatDryMilkFuture;
		this.advancedNonfatDryMilkFuture = advancedNonfatDryMilkFuture;
		this.cheeseFuture = cheeseFuture;
		this.advancedCheeseFuture = advancedCheeseFuture;
		this.dryWheyFuture = dryWheyFuture;
		this.advancedDryWheyFuture = advancedDryWheyFuture;
		this.otherSolidsPercent = grossOtherSolidsPercent;
		this.butterfatPercent = grossButterFatPercent;
		this.proteinPercent = grossProteinPercent;
	}

	get marketButterPrice() {
		return this.getMarketPrice(this.butterFuture);
	}

	get scenarioButterPrice() {
		return this.getScenarioPrice(this.butterFuture);
	}

	get marketNonfatDryMilkPrice() {
		return this.getMarketPrice(this.nonfatDryMilkFuture);
	}

	get scenarioNonfatDryMilkPrice() {
		return this.getScenarioPrice(this.nonfatDryMilkFuture);
	}

	get marketAdvancedNonfatDryMilkPrice() {
		return this.getMarketPrice(this.advancedNonfatDryMilkFuture);
	}

	get scenarioAdvancedNonfatDryMilkPrice() {
		return this.getScenarioPrice(this.advancedNonfatDryMilkFuture);
	}

	get marketAdvancedButterPrice() {
		return this.getMarketPrice(this.advancedButterFuture);
	}

	get scenarioAdvancedButterPrice() {
		return this.getScenarioPrice(this.advancedButterFuture);
	}

	get marketCheesePrice() {
		return this.getMarketPrice(this.cheeseFuture);
	}

	get scenarioCheesePrice() {
		return this.getScenarioPrice(this.cheeseFuture);
	}

	get marketAdvancedCheesePrice() {
		return this.getMarketPrice(this.advancedCheeseFuture);
	}

	get scenarioAdvancedCheesePrice() {
		return this.getScenarioPrice(this.advancedCheeseFuture);
	}

	get marketDryWheyPrice() {
		return this.getMarketPrice(this.dryWheyFuture);
	}

	get scenarioDryWheyPrice() {
		return this.getScenarioPrice(this.dryWheyFuture);
	}

	get marketAdvancedDryWheyPrice() {
		return this.getMarketPrice(this.advancedDryWheyFuture);
	}

	get scenarioAdvancedDryWheyPrice() {
		return this.getScenarioPrice(this.advancedDryWheyFuture);
	}

	get marketClassIIIPrice() {
		return this.calculateClassIIIPrice(this.marketDryWheyPrice, this.marketButterPrice, this.marketCheesePrice);
	}

	get marketClassIVPrice() {
		return this.calculateClassIVPrice(this.marketButterPrice, this.marketNonfatDryMilkPrice);
	}

	get scenarioClassIIIPrice() {
		return this.calculateClassIIIPrice(this.scenarioDryWheyPrice, this.scenarioButterPrice, this.scenarioCheesePrice);
	}

	get scenarioClassIVPrice() {
		return this.calculateClassIVPrice(this.scenarioButterPrice, this.scenarioNonfatDryMilkPrice);
	}

	get marketAdvancedButterfatPrice() {
		return this.calculateButterfatPrice(this.marketAdvancedButterPrice);
	}
	get scenarioAdvancedButterfatPrice() {
		return this.calculateButterfatPrice(this.scenarioAdvancedButterPrice);
	}

	get marketAdvancedOtherSolidsPrice() {
		return this.calculateOtherSolidsPrice(this.marketAdvancedDryWheyPrice);
	}
	get scenarioAdvancedOtherSolidsPrice() {
		return this.calculateOtherSolidsPrice(this.scenarioAdvancedDryWheyPrice);
	}

	get marketAdvancedProteinPrice() {
		return this.calculateProteinPrice(this.marketAdvancedCheesePrice, this.marketAdvancedDryWheyPrice);
	}
	get scenarioAdvancedProteinPrice() {
		return this.calculateProteinPrice(this.scenarioAdvancedCheesePrice, this.scenarioAdvancedDryWheyPrice);
	}

	get marketBlendedPrice() {
		const advancedClassivSkimMilkPricingFactor = this.calculateAdvancedClassivSkimMilkPricingFactor(this.marketAdvancedNonfatDryMilkPrice);
		const classiUtilization = this.milkUtilizations.classiUtilization ?? 0;
		const classiiUtilization = this.milkUtilizations.classiiUtilization ?? 0;
		const classiiiUtilization = this.milkUtilizations.classiiiUtilization ?? 0;
		const classivUtilization = this.milkUtilizations.classivUtilization ?? 0;
		const classIPrice = this.calculateClassIPrice(
			this.marketAdvancedButterPrice,
			this.marketAdvancedCheesePrice,
			this.marketAdvancedDryWheyPrice,
			advancedClassivSkimMilkPricingFactor,
			this.classiDifferential
		);
		const classIIPrice = this.calculateClassIIPrice(this.marketButterPrice, advancedClassivSkimMilkPricingFactor);

		let blendedPrice = this.calculateBlendedPrice(
			classiUtilization,
			classiiUtilization,
			classiiiUtilization,
			classivUtilization,
			classIPrice,
			classIIPrice,
			this.marketClassIIIPrice,
			this.marketClassIVPrice
		);
		if (
			blendedPrice == null ||
			this.marketAdvancedOtherSolidsPrice == null ||
			this.marketAdvancedButterfatPrice == null ||
			this.marketAdvancedProteinPrice == null ||
			this.otherSolidsPercent == null ||
			this.butterfatPercent == null ||
			this.proteinPercent == null
		)
			return null;
		const componentAdjustedBlendedPrice = (blendedPrice += this.componentDifferential(
			this.marketAdvancedOtherSolidsPrice,
			this.marketAdvancedButterfatPrice,
			this.marketAdvancedProteinPrice,
			this.otherSolidsPercent,
			this.butterfatPercent,
			this.proteinPercent
		));
		return componentAdjustedBlendedPrice + (this.basis ?? 0);
	}

	get forecastedPremiumsAndDeductionsCWT() {
		return this.monthlyPremiumsAndDeductions?.calculatedAmount && this.grossProduction
			? this.monthlyPremiumsAndDeductions.calculatedAmount / (this.grossProduction / 100)
			: 0;
	}

	get marketMailboxPrice() {
		return (this.marketBlendedPrice ?? 0) + (this.forecastedPremiumsAndDeductionsCWT ?? 0);
	}

	get scenarioMailboxPrice() {
		return (this.scenarioBlendedPrice ?? 0) + (this.forecastedPremiumsAndDeductionsCWT ?? 0);
	}

	get scenarioBlendedPrice() {
		const advancedClassivSkimMilkPricingFactor = this.calculateAdvancedClassivSkimMilkPricingFactor(
			this.scenarioAdvancedNonfatDryMilkPrice
		);
		const classiUtilization = this.milkUtilizations.classiUtilization ?? 0;
		const classiiUtilization = this.milkUtilizations.classiiUtilization ?? 0;
		const classiiiUtilization = this.milkUtilizations.classiiiUtilization ?? 0;
		const classivUtilization = this.milkUtilizations.classivUtilization ?? 0;
		const classIPrice = this.calculateClassIPrice(
			this.scenarioAdvancedButterPrice,
			this.scenarioAdvancedCheesePrice,
			this.scenarioAdvancedDryWheyPrice,
			advancedClassivSkimMilkPricingFactor,
			this.classiDifferential
		);
		const classIIPrice = this.calculateClassIIPrice(this.scenarioButterPrice, advancedClassivSkimMilkPricingFactor);

		let blendedPrice = this.calculateBlendedPrice(
			classiUtilization,
			classiiUtilization,
			classiiiUtilization,
			classivUtilization,
			classIPrice,
			classIIPrice,
			this.scenarioClassIIIPrice,
			this.scenarioClassIVPrice
		);
		if (
			blendedPrice == null ||
			this.scenarioAdvancedOtherSolidsPrice == null ||
			this.scenarioAdvancedButterfatPrice == null ||
			this.scenarioAdvancedProteinPrice == null ||
			this.otherSolidsPercent == null ||
			this.butterfatPercent == null ||
			this.proteinPercent == null
		)
			return null;
		const componentAdjustedBlendedPrice = (blendedPrice += this.componentDifferential(
			this.scenarioAdvancedOtherSolidsPrice,
			this.scenarioAdvancedButterfatPrice,
			this.scenarioAdvancedProteinPrice,
			this.otherSolidsPercent,
			this.butterfatPercent,
			this.proteinPercent
		));
		return componentAdjustedBlendedPrice + (this.basis ?? 0);
	}

	componentDifferential(
		otherSolidsPrice: number,
		butterfatPrice: number,
		proteinPrice: number,
		otherSolidsPercent: number,
		butterfatPercent: number,
		proteinPercent: number
	) {
		const standardOtherSolidsPercentage = 0.0569;
		const standardButterFatPercentage = 0.035;
		const standardProteinPercentage = 0.0299;
		const farm = otherSolidsPrice * otherSolidsPercent + butterfatPrice * butterfatPercent + proteinPrice * proteinPercent;
		const standard =
			otherSolidsPrice * standardOtherSolidsPercentage +
			butterfatPrice * standardButterFatPercentage +
			proteinPrice * standardProteinPercentage;

		return (farm - standard) * 100;
	}

	getMarketPrice(future: Future | null) {
		const barchartSymbol = future?.barchartSymbol;
		const displayFactor = future?.SymbolGroup.displayFactor ?? 1;
		if (barchartSymbol == null) return null;

		const price = this.scenarioPrices.getMarketPrice(barchartSymbol);
		return price ? price * displayFactor : null;
	}

	getScenarioPrice(future: Future | null) {
		const barchartSymbol = future?.barchartSymbol;
		const displayFactor = future?.SymbolGroup.displayFactor ?? 1;
		if (barchartSymbol == null) return null;

		const price = this.scenarioPrices.getScenarioPrice(barchartSymbol);
		return price ? price * displayFactor : null;
	}

	calculateBlendedPrice(
		classiUtilization: number,
		classiiUtilization: number,
		classiiiUtilization: number,
		classivUtilization: number,
		classIPrice: number | null,
		classIIPrice: number | null,
		classIIIPrice: number | null,
		classIVPrice: number | null
	) {
		if (
			classiUtilization != null &&
			classiiUtilization != null &&
			classiiiUtilization != null &&
			classivUtilization != null &&
			classIPrice != null &&
			classIIPrice != null &&
			classIIIPrice != null &&
			classIVPrice != null
		) {
			return (
				classIPrice * classiUtilization +
				classIIPrice * classiiUtilization +
				classIIIPrice * classiiiUtilization +
				classIVPrice * classivUtilization
			);
		} else {
			return null;
		}
	}

	/* The NDPSR provides current wholesale market prices for basic dairy commodities. These commodity prices are used in the following formulas to calculate the USDA’s Class and Component Prices used in the Federal Milk Marketing Order Program. */

	// SOURCE: https://www.ams.usda.gov/sites/default/files/media/PriceFormula2019.pdf
	/*
  • Commodity Prices (Butter, Nonfat Dry Milk, Cheddar Cheese, and Dry Whey)
◦ Commodity prices are calculated on the NDPSR ¹.
◦ The prices are weighted averages for the previous four or five weeks (depending on date of last
publication), weighted by sales volume in pounds.
◦ Cheddar cheese in 500-pound barrels is adjusted to 38 percent moisture content.
◦ For more information, see the Price Formulas publication ².
• Butterfat Price = (Butter Price – 0.1715) x 1.211
• Nonfat Solids Price = (NFDM Price – 0.1678) x 0.99
• Protein Price = ((Cheese Price – 0.2003) x 1.383) + ((((Cheese Price – 0.2003) x 1.572) – Butterfat Price x 0.9)
x 1.17)
• Other Solids Price = (Dry Whey Price-0.1991) x 1.03
• Somatic Cell Adjustment Rate = Cheese Price x 0.0005
• Class II Price = (Class II Skim Milk Price x 0.965)+(Class II Butterfat Price x 3.5)
◦ Class II Skim Milk Price = See the Announcement of Advanced Prices and Pricing Factors for the
applicable month.
◦ Class II Butterfat Price = Butterfat Price+0.007
• Class III Price = (Class III Skim Milk Price x 0.965)+(Butterfat Price x 3.5)
◦ Class III Skim Milk Price = (Protein Price x 3.1)+(Other Solids Price x 5.9)
• Class IV Price = (Class IV Skim Milk Price x 0.965)+(Butterfat Price x 3.5)
◦ Class IV Skim Milk Price = Nonfat Solids Price x 9  */

	calculateAdvancedClassivSkimMilkPricingFactor(advancedNonfatDryMilkPrice: number | null) {
		if (advancedNonfatDryMilkPrice != null) {
			const advancedNonfatSolidsPrice = (advancedNonfatDryMilkPrice - 0.1678) * 0.99;
			return advancedNonfatSolidsPrice * 9;
		} else {
			return null;
		}
	}

	calculateClassIPrice(
		advancedButterPrice: number | null,
		advancedCheesePrice: number | null,
		advancedDryWheyPrice: number | null,
		advancedClassivSkimMilkPricingFactor: number | null,
		classIDifferential: number | null
	) {
		if (
			advancedButterPrice == null ||
			advancedCheesePrice == null ||
			advancedDryWheyPrice == null ||
			advancedClassivSkimMilkPricingFactor == null ||
			classIDifferential == null
		)
			return null;
		const advancedButterfatPricingFactor = (advancedButterPrice - 0.1715) * 1.211;

		const advancedProteinPrice =
			(advancedCheesePrice - 0.2003) * 1.383 + ((advancedCheesePrice - 0.2003) * 1.572 - advancedButterfatPricingFactor * 0.9) * 1.17;

		const advancedOtherSolidsPrice = (advancedDryWheyPrice - 0.1991) * 1.03;

		const advancedClassiiiSkimMilkPricingFactor = advancedProteinPrice * 3.1 + advancedOtherSolidsPrice * 5.9;

		const baseSkimMilkPriceForClassi = (advancedClassiiiSkimMilkPricingFactor + advancedClassivSkimMilkPricingFactor) / 2 + 0.74;

		const baseClassIPrice = baseSkimMilkPriceForClassi * 0.965 + advancedButterfatPricingFactor * 3.5;

		return classIDifferential + baseClassIPrice;
	}

	calculateClassIIPrice(butterPrice: number | null, advancedClassivSkimMilkPricingFactor: number | null) {
		if (butterPrice == null || advancedClassivSkimMilkPricingFactor == null) return null;

		const classiiSkimMilkPrice = advancedClassivSkimMilkPricingFactor + 0.7;
		const butterfatPrice = (butterPrice - 0.1715) * 1.211;
		const classiiButterfatPrice = butterfatPrice + 0.007;

		return classiiSkimMilkPrice * 0.965 + classiiButterfatPrice * 3.5;
	}

	calculateClassIIIPrice(dryWheyPrice: number | null, butterPrice: number | null, cheesePrice: number | null) {
		if (dryWheyPrice == null || butterPrice == null || cheesePrice == null) return null;

		const otherSolidsPrice = (dryWheyPrice - 0.1991) * 1.03;
		const butterFatPricingFactor = (butterPrice - 0.1715) * 1.211;
		const proteinPrice = (cheesePrice - 0.2003) * 1.383 + ((cheesePrice - 0.2003) * 1.572 - butterFatPricingFactor * 0.9) * 1.17;
		const classiiiSkimMilkPricingFactor = proteinPrice * 3.1 + otherSolidsPrice * 5.9;

		return classiiiSkimMilkPricingFactor * 0.965 + butterFatPricingFactor * 3.5;
	}

	calculateClassIVPrice(butterPrice: number | null, nonfatDryMilkPrice: number | null) {
		if (butterPrice == null || nonfatDryMilkPrice == null) return null;

		const butterFatPricingFactor = (butterPrice - 0.1715) * 1.211;
		const nonFatSolidsPricingFactor = (nonfatDryMilkPrice - 0.1678) * 0.99;
		const classivSkimMilkPricingFactor = nonFatSolidsPricingFactor * 9;

		return classivSkimMilkPricingFactor * 0.965 + butterFatPricingFactor * 3.5;
	}

	calculateButterfatPrice(butterPrice: number | null) {
		if (butterPrice == null) return null;
		return (butterPrice - 0.1715) * 1.211;
	}

	calculateOtherSolidsPrice(dryWheyPrice: number | null) {
		if (dryWheyPrice == null) return null;
		return (dryWheyPrice - 0.1991) * 1.03;
	}

	calculateProteinPrice(cheesePrice: number | null, butterfatPrice: number | null) {
		if (butterfatPrice == null || cheesePrice == null) return null;
		return (cheesePrice - 0.2003) * 1.383 + ((cheesePrice - 0.2003) * 1.572 - butterfatPrice * 0.9) * 1.17;
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
//@ts-ignore
declare module 'ember-data/types/registries/model' {
	export default interface ModelRegistry {
		'scenario-milk-check': ScenarioMilkCheck;
	}
}
