import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import resetVaultTableScroll from 'vault-client/utils/reset-vault-table-scroll';
import { CellComponents, TableColumn } from 'vault-client/types/vault-table';

export default class BusinessesIndexController extends Controller {
	@service permissions: any;

	@tracked sorts = [{ valuePath: 'name', isAscending: true }];
	get columns() {
		const baseColumns: TableColumn[] = [
			{
				id: '5f03cf11-23c7-4c98-a885-3ca39e9d8fc3',
				name: 'Name',
				valuePath: 'name',
				isSortable: true,
				isFixed: '',
				isVisible: true,
				cellComponent: CellComponents.String,
				textAlign: 'left',
				headerTextAlign: 'left',
				linkRoute: 'businesses.business.dashboard',
				linkModelPath: 'id',
				linkQuery: {
					customerId: {
						rowKey: 'id',
					},
				},
			},
		];
		return baseColumns;
	}

	get sortParams() {
		return this.sorts;
	}

	set sortParams(sorts) {
		this.sorts = sorts;
	}

	@action
	setTablePageState() {
		resetVaultTableScroll('businesses-index-table');
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'businesses-index-controller': BusinessesIndexController;
	}
}
