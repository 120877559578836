import { DateTime } from 'luxon';
import ReportsInventoryHedgesByProduct from 'vault-client/routes/reports/inventory-hedges-by-product';
import { Query } from 'vault-client/types/graphql-types';

export default class BusinessesBusinessInventoryHedgesByProduct extends ReportsInventoryHedgesByProduct {
	async model() {
		const businessParams = this.paramsFor('businesses.business') as { business_id: string };
		this.productsVariables = {
			where: this.generateProductsWhere(),
		};

		await this.getCurrentFutures.promise;

		const mostCurrentFuture = this.getCurrentFutures.data?.Products?.firstObject?.CurrentFutures.firstObject ?? null;
		const startDate = mostCurrentFuture?.displayExpiresAt || DateTime.local().startOf('month').toISODate();

		const variables = {
			currentInventoryLevelswhere: this.generateInventoryWhere(null, businessParams.business_id, null),
			entityExposureRatiosWhere: this.generateExposureWhere(null, businessParams.business_id, null, startDate),
		};

		return (await this.apollo.watchQuery({ query: this.query, variables })) as {
			CurrentInventoryLevels: Query['CurrentInventoryLevels'];
			EntityAllocatedExposureRatios: Query['EntityAllocatedExposureRatios'];
		};
	}
}
