import Route from '@ember/routing/route';
import { queryManager } from 'ember-apollo-client';
import query from 'vault-client/graphql/queries/locations/inventory/index.graphql';
import { InventoryLevelFilterDTO } from 'vault-client/types/graphql-types';

export default class LocationsInventoryIndexRoute extends Route {
	@queryManager apollo: any;

	generateWhere(locationId: string): InventoryLevelFilterDTO {
		const where: InventoryLevelFilterDTO = {};

		where.locationId = { equals: locationId };

		return where;
	}

	async model() {
		const locationParams = this.paramsFor('locations.location') as { id: string };
		const variables = {
			locationId: locationParams.id,
			where: this.generateWhere(locationParams.id),
		};
		return this.apollo.watchQuery({ query, variables });
	}
}
