import Route from '@ember/routing/route';
import query from 'vault-client/graphql/queries/reports/strategic-inventory-model.graphql';
import { queryManager } from 'ember-apollo-client';

export default class ReportsDryWheyStrategicBuyingModelRoute extends Route {
	@queryManager apollo: any;

	templateName: string = 'reports/dry-whey-strategic-buying-model';

	async model(params: Array<string>) {
		const variables = {
			params: params,
			where: {
				slug: {
					equals: 'us-dairy-dry-whey',
				},
			},
		};

		return this.apollo.watchQuery({ query, variables });
	}
}
