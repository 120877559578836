import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { ModelFrom } from 'vault-client/utils/type-utils';
import route from 'vault-client/routes/vgs/customers/show';
import { CellComponents, TableColumn } from 'vault-client/types/vault-table';
import CropPlan from 'vault-client/models/vgs/crop-plan';
import FeedPlan from 'vault-client/models/vgs/feed-plan';
import PermissionsService from 'vault-client/services/permissions';
import { service } from '@ember/service';
import luxon from 'luxon';
import GetAvailableFeedYearsService from 'vault-client/services/get-available-feed-years';
import GetAvailableCropYearsService from 'vault-client/services/get-available-crop-years';
export default class VgsCustomersShowController extends Controller {
	declare model: ModelFrom<route>;
	@service('get-available-feed-years') declare getAvailableFeedYears: GetAvailableFeedYearsService;
	@service('get-available-crop-years') declare getAvailableCropYears: GetAvailableCropYearsService;
	@service declare permissions: PermissionsService;
	@tracked sorts = [{ valuePath: 'id', isAscending: true }];
	@tracked month: string | null = null;
	@tracked cropYear: string | null = null;
	@tracked feedYear: string | null = null;
	@tracked selectedFeedCategoryIds: string[] = [];
	@tracked selectedCropIds: string[] = [];

	queryParams = ['selectedCropIds', 'selectedFeedCategoryIds', 'month', 'year'];

	months = luxon.Info.months().map((month, idx) => ({ id: (idx + 1).toString(), name: month }));

	get cropPlanColumns(): TableColumn[] {
		const baseColumns: TableColumn[] = [
			{
				id: '561e6892-7183-4415-bec8-2c7154d4cc13',
				name: '',
				width: 100,
				textAlign: 'center',
				isSortable: false,
				cellComponent: CellComponents.Button,
				componentArgs: {
					size: 'xs',
					style: 'outline',
					text: 'Details',
					fn: () => {},
				},
				isFixed: '',
				isVisible: true,
				linkRoute: 'vgs.grain-services.crop-plans.show',
				linkModelPath: 'id',
			},
			{
				id: 'abcefb68-a616-4bd6-b491-c4ea3dea30c2',
				name: 'Year',
				valuePath: 'CropYear.year',
				minWidth: 80,
				textAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '273408af-f271-4406-9102-bf7553ee1d89',
				name: 'crop',
				valuePath: 'Crop.name',
				minWidth: 100,
				textAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '625bfd2e-fe5a-4063-907a-c0fcf68023af',
				name: 'Acres',
				valuePath: 'acres',
				minWidth: 125,
				textAlign: 'right',
				isSortable: true,
				cellComponent: CellComponents.IntlNumberFormat,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '01674072-16d3-4c11-a732-bed676ab9eba',
				name: 'APH',
				valuePath: 'aph',
				minWidth: 80,
				textAlign: 'right',
				isSortable: true,
				cellComponent: CellComponents.IntlNumberFormat,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'fa8cc881-a366-403f-95ae-864d07239e1b',
				name: 'Production',
				valuePath: 'production',
				minWidth: 100,
				width: 120,
				textAlign: 'right',
				isSortable: true,
				cellComponent: CellComponents.IntlNumberFormat,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'b3b5e71a-3834-4855-be0a-161837136d92',
				name: 'RP%',
				valuePath: 'enrolledPercent',
				minWidth: 100,
				textAlign: 'right',
				isSortable: true,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'percent',
				},
				isFixed: '',
				isVisible: true,
			},
			{
				id: '77ec3863-f023-44d9-8bde-f2ccb4597cb3',
				name: 'RP Production',
				valuePath: 'enrolledProduction',
				minWidth: 100,
				width: 140,
				textAlign: 'right',
				isSortable: true,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'decimal',
				},
				isFixed: '',
				isVisible: true,
			},
			{
				id: '0da21e62-2222-4bbe-b0cf-8064e194b21e',
				name: 'Filled',
				valuePath: 'bushelsFilled',
				minWidth: 100,
				textAlign: 'right',
				isSortable: true,
				cellComponent: CellComponents.IntlNumberFormat,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '9c687d98-97dd-41a3-806e-37d49b295f1d',
				name: '% Sold',
				valuePath: 'percentSold',
				minWidth: 100,
				textAlign: 'right',
				isSortable: true,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'percent',
				},
				isFixed: '',
				isVisible: true,
			},
			{
				id: '5798b866-1e1d-4128-aedd-1d8418c63453',
				name: 'Harvest Bu.',
				valuePath: 'harvestBu',
				minWidth: 100,
				width: 120,
				textAlign: 'right',
				isSortable: true,
				cellComponent: CellComponents.IntlNumberFormat,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '18ce3b39-183b-42e4-80cc-1efb2a29c2c6',
				name: 'Storage Bu.',
				valuePath: 'storageBu',
				minWidth: 100,
				width: 120,
				textAlign: 'right',
				isSortable: true,
				cellComponent: CellComponents.IntlNumberFormat,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'ef811796-83c7-41e5-ad62-5c3850081343',
				name: 'Breakeven',
				valuePath: 'breakEven',
				minWidth: 100,
				width: 120,
				textAlign: 'right',
				isSortable: true,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				isFixed: '',
				isVisible: true,
			},
			{
				id: '8618e7ef-159b-4d49-bbf6-b858840b7401',
				name: 'Goal',
				valuePath: 'goal',
				minWidth: 100,
				textAlign: 'right',
				isSortable: true,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'f4935525-5ef4-49cf-b7d5-bff42df44e1d',
				name: 'Avg. Future Price',
				valuePath: 'averageFuturePriceSold',
				minWidth: 100,
				width: 160,
				textAlign: 'right',
				isSortable: true,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
					minimumFractionDigits: 2,
					maximumFractionDigits: 5,
				},
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'b8fed44c-7552-4848-a7c1-c5531f0b53f9',
				name: 'Avg. Flat Price',
				valuePath: 'averageFlatPriceSold',
				minWidth: 100,
				width: 150,
				textAlign: 'right',
				isSortable: true,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
					minimumFractionDigits: 2,
					maximumFractionDigits: 5,
				},
				isFixed: '',
				isVisible: true,
			},
		];
		return baseColumns;
	}

	get feedPlanColumns(): TableColumn[] {
		const baseColumns: TableColumn[] = [
			{
				id: 'c5179722-ffe7-46f9-94be-4a430103ca6e',
				name: 'id',
				valuePath: 'feedPlanDisplayId',
				minWidth: 80,
				textAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				linkRoute: 'vgs.feed-services.feed-plans.show',
				linkModelPath: 'id',
			},
			{
				id: 'c0560dbb-1e34-4d97-8b54-1134abfebc9b',
				name: 'Ingredients',
				valuePath: 'FeedCategory.name',
				minWidth: 100,
				textAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'e60ac8b1-84c4-4a9c-b98a-aa3af7e0f048',
				name: 'Purchased',
				valuePath: 'tonsFilled',
				minWidth: 100,
				textAlign: 'right',
				isSortable: true,
				cellComponent: CellComponents.IntlNumberFormat,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'f93e57ab-7156-4128-a0a8-2746f6dd775a',
				name: 'Goal',
				valuePath: 'goal',
				minWidth: 100,
				textAlign: 'right',
				isSortable: true,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				isFixed: '',
				isVisible: true,
			},
			{
				id: '3bf91f4d-d4fa-45b8-b2a5-16f9792d90cc',
				name: 'Total Est. Feed Usage',
				valuePath: 'tons',
				width: 165,
				minWidth: 165,
				textAlign: 'right',
				isSortable: false,
				cellComponent: CellComponents.IntlNumberFormat,
				isFixed: '',
				isVisible: true,
			},

			{
				id: 'c0c7bcf2-bfd8-44e2-b6fd-1422661753c2',
				name: 'Total Tons Flat Priced',
				valuePath: 'totalTonsFlatPriced',
				width: 165,
				minWidth: 165,
				textAlign: 'right',
				isSortable: false,
				cellComponent: CellComponents.IntlNumberFormat,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'bc6d337d-33a5-4e1a-b507-8ab7bfa529b5',
				name: 'Basis Only Purchased',
				valuePath: 'basisOnlyPurchased',
				width: 165,
				minWidth: 165,
				textAlign: 'right',
				isSortable: false,
				cellComponent: CellComponents.IntlNumberFormat,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '9644a40f-6f5c-4adb-91e3-a96d8b50b1c8',
				name: 'Basis Exposure',
				valuePath: 'basisExposure',
				width: 140,
				minWidth: 140,
				textAlign: 'right',
				isSortable: false,
				cellComponent: CellComponents.IntlNumberFormat,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'ba600262-8dfe-40c1-81eb-198311d53e8d',
				name: 'Futures Only Purchased',
				valuePath: 'futuresOnlyPurchased',
				width: 180,
				minWidth: 180,
				textAlign: 'right',
				isSortable: false,
				cellComponent: CellComponents.IntlNumberFormat,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '8f421598-6a04-4cf5-9ac3-3d5edb9df5b0',
				name: 'Futures Exposure',
				valuePath: 'futuresExposure',
				width: 150,
				minWidth: 150,
				textAlign: 'right',
				isSortable: false,
				cellComponent: CellComponents.IntlNumberFormat,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '8b3174ca-6d34-4bb2-8bb7-164e9603d693',
				name: 'Avg. Future Price',
				valuePath: 'averageFuturePriceSold',
				minWidth: 100,
				width: 140,
				textAlign: 'right',
				isSortable: true,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
					minimumFractionDigits: 2,
					maximumFractionDigits: 5,
				},
				isFixed: '',
				isVisible: true,
			},
			{
				id: '549dfa70-cf81-4aef-a297-e479c274bf90',
				name: 'Avg. Flat Price',
				valuePath: 'averageFlatPriceSold',
				minWidth: 100,
				width: 140,
				textAlign: 'right',
				isSortable: true,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
					minimumFractionDigits: 2,
					maximumFractionDigits: 5,
				},
				isFixed: '',
				isVisible: true,
			},
		];
		return baseColumns;
	}

	get customer() {
		return this.model.getCustomers?.data?.Customer ?? null;
	}

	get cropPlan() {
		if (this.model.getCustomers.data) {
			const plans = this.model.getCustomers.data.GrainCropPlans.map((plan) => {
				return new CropPlan(plan);
			});
			return plans;
		} else {
			return null;
		}
	}

	get feedPlan() {
		if (this.model.getCustomers.data) {
			const plans = this.model.getCustomers.data.GrainFeedPlans.map((plan) => {
				return new FeedPlan(plan);
			});
			return plans;
		} else {
			return null;
		}
	}

	get selectedMonth() {
		const val = this.months.find((month) => month.id === this.month) ?? null;

		return val;
	}

	get feedCategories() {
		return this.model.getCustomers?.data?.FeedCategories ?? [];
	}
	get crops() {
		return this.model.getCustomers?.data?.CropCategories ?? [];
	}

	get selectedFeedCategoriesString() {
		if (this.selectedFeedCategoryIds.length === 0) {
			return 'All';
		}

		if (this.selectedFeedCategoryIds.length === 1) {
			return this.feedCategories.find((feedCategory) => feedCategory.id === this.selectedFeedCategoryIds[0])?.name ?? '';
		}

		return `${this.selectedFeedCategoryIds.length} Selected`;
	}

	get selectedCropsString() {
		if (this.selectedCropIds.length === 0) {
			return 'All';
		}

		if (this.selectedCropIds.length === 1) {
			return this.crops.find((crop) => crop.id === this.selectedCropIds[0])?.name ?? '';
		}

		return `${this.selectedCropIds.length} Selected`;
	}

	@action
	addSelectedFeedCategoryId(feedCategory: string | null) {
		if (feedCategory === null) {
			this.selectedFeedCategoryIds = [];
		} else if (this.selectedFeedCategoryIds.includes(feedCategory)) {
			this.selectedFeedCategoryIds = this.selectedFeedCategoryIds.filter((v) => v !== feedCategory);
		} else {
			this.selectedFeedCategoryIds = [...this.selectedFeedCategoryIds, feedCategory];
		}

		if (this.selectedFeedCategoryIds.length === this.feedCategories.length) {
			this.selectedFeedCategoryIds = [];
		}
	}

	@action
	addSelectedCropId(crop: string | null) {
		if (crop === null) {
			this.selectedCropIds = [];
		} else if (this.selectedCropIds.includes(crop)) {
			this.selectedCropIds = this.selectedCropIds.filter((v) => v !== crop);
		} else {
			this.selectedCropIds = [...this.selectedCropIds, crop];
		}

		if (this.selectedCropIds.length === this.crops.length) {
			this.selectedCropIds = [];
		}
	}
}
