import Controller from '@ember/controller';
import DerivedInsuranceEndorsement from 'vault-client/models/derived-insurance-endorsement';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { queryManager } from 'ember-apollo-client';
import searchQuery from 'vault-client/graphql/queries/search.graphql';
import agentFuzzyQuery from 'vault-client/graphql/queries/agent-fuzzy-match.graphql';
import stateFuzzyQuery from 'vault-client/graphql/queries/state-fuzzy-match.graphql';
import { DateTime } from 'luxon';
import getFilterDisplayProperty from 'vault-client/utils/get-filter-display-property';
import query from 'vault-client/graphql/queries/drp-insurance-endorsements/index.graphql';
import isTouchDevice from 'vault-client/utils/is-touch-device';
import resetVaultTableScroll from 'vault-client/utils/reset-vault-table-scroll';
import { inject as service } from '@ember/service';
import { CellComponents, SortObj, TableColumn } from 'vault-client/types/vault-table';
import { DerivedDrpEndorsementFilterDTO, DerivedDrpEndorsementSortByDTO } from 'vault-client/types/graphql-types';
import DRPInsuranceEndorsementsIndexRoute from 'vault-client/routes/drp-insurance-endorsements';
import { ModelFrom } from 'vault-client/utils/type-utils';
import OrganizationsDrpInsuranceEndorsements from 'vault-client/routes/organizations/organization/drp-insurance-endorsements';
import BusinessesDrpInsuranceEndorsements from 'vault-client/routes/businesses/business/drp-insurance-endorsements';
export default class DRPInsuranceEndorsementsIndexController extends Controller {
	@queryManager apollo: any;
	@service applicationScope: any;

	declare model: ModelFrom<OrganizationsDrpInsuranceEndorsements> | ModelFrom<BusinessesDrpInsuranceEndorsements>;
	drpInsuranceEndorsementRoute: string = '';
	insuranceRoutePath: string = '';

	queryParams = [
		'agent',
		'aipId',
		'quarterStartDate',
		'quarterEndDate',
		'salesEffectiveStartDate',
		'salesEffectiveEndDate',
		'stateId',
		'page',
		'sorts',
		'type',
		'size',
	];

	@tracked size = 100;
	quarterEndDateRangeOptions = [
		{
			displayName: 'All Quarters',
			startDate: '1900-01-01',
			endDate: '2999-12-31',
		},
		{
			displayName: 'Open Quarters',
			startDate: DateTime.local().startOf('quarter').toISODate(),
			endDate: '2999-12-31',
		},
		// Previous Quarter
		{
			displayName: DateTime.local().startOf('quarter').minus({ quarter: 1 }).toFormat('yyyy Qq'),
			startDate: DateTime.local().startOf('quarter').minus({ quarter: 1 }).toISODate(),
			endDate: DateTime.local().endOf('quarter').minus({ quarter: 1 }).toISODate(),
		},
		// Current Quarter
		{
			displayName: DateTime.local().startOf('quarter').toFormat('yyyy Qq'),
			startDate: DateTime.local().startOf('quarter').toISODate(),
			endDate: DateTime.local().endOf('quarter').toISODate(),
		},

		// +1 Quarter
		{
			displayName: DateTime.local().startOf('quarter').plus({ quarter: 1 }).toFormat('yyyy Qq'),
			startDate: DateTime.local().startOf('quarter').plus({ quarter: 1 }).toISODate(),
			endDate: DateTime.local().endOf('quarter').plus({ quarter: 1 }).toISODate(),
		},
		// +2 Quarter
		{
			displayName: DateTime.local().startOf('quarter').plus({ quarter: 2 }).toFormat('yyyy Qq'),
			startDate: DateTime.local().startOf('quarter').plus({ quarter: 2 }).toISODate(),
			endDate: DateTime.local().endOf('quarter').plus({ quarter: 2 }).toISODate(),
		},
		// +3 Quarter
		{
			displayName: DateTime.local().startOf('quarter').plus({ quarter: 3 }).toFormat('yyyy Qq'),
			startDate: DateTime.local().startOf('quarter').plus({ quarter: 3 }).toISODate(),
			endDate: DateTime.local().endOf('quarter').plus({ quarter: 3 }).toISODate(),
		},
		// +4 Quarter
		{
			displayName: DateTime.local().startOf('quarter').plus({ quarter: 4 }).toFormat('yyyy Qq'),
			startDate: DateTime.local().startOf('quarter').plus({ quarter: 4 }).toISODate(),
			endDate: DateTime.local().endOf('quarter').plus({ quarter: 4 }).toISODate(),
		},
		// +5 Quarter
		{
			displayName: DateTime.local().startOf('quarter').plus({ quarter: 5 }).toFormat('yyyy Qq'),
			startDate: DateTime.local().startOf('quarter').plus({ quarter: 5 }).toISODate(),
			endDate: DateTime.local().endOf('quarter').plus({ quarter: 5 }).toISODate(),
		},
		// +6 Quarter
		{
			displayName: DateTime.local().startOf('quarter').plus({ quarter: 6 }).toFormat('yyyy Qq'),
			startDate: DateTime.local().startOf('quarter').plus({ quarter: 6 }).toISODate(),
			endDate: DateTime.local().endOf('quarter').plus({ quarter: 6 }).toISODate(),
		},
		{
			displayName: `Calendar Year (${DateTime.local().year}) `,
			startDate: DateTime.local().startOf('year').toISODate(),
			endDate: DateTime.local().endOf('year').toISODate(),
		},
	];

	salesEffectiveDateRangeOptions = [
		{
			displayName: 'All Dates',
			startDate: '1900-01-01',
			endDate: '2999-12-31',
		},
		{
			displayName: 'Previous 7 Days',
			startDate: DateTime.local().minus({ days: 7 }).toISODate(),
			endDate: DateTime.local().toISODate(),
		},
		{
			displayName: 'Previous 30 Days',
			startDate: DateTime.local().minus({ days: 30 }).toISODate(),
			endDate: DateTime.local().toISODate(),
		},
		{
			displayName: 'Previous 90 Days',
			startDate: DateTime.local().minus({ days: 90 }).toISODate(),
			endDate: DateTime.local().toISODate(),
		},
		{
			displayName: 'Previous 12 Months',
			startDate: DateTime.local().minus({ months: 12 }).startOf('month').toISODate(),
			endDate: DateTime.local().toISODate(),
		},
		{
			displayName: 'Previous 24 Months',
			startDate: DateTime.local().minus({ months: 24 }).startOf('month').toISODate(),
			endDate: DateTime.local().toISODate(),
		},
	];

	itemsFn = (rows: any) => {
		return rows
			? rows.map((row: any) => {
					return new DerivedInsuranceEndorsement(row);
				})
			: null;
	};

	@tracked agent: string | null | undefined = null;
	@tracked aipId: string | null | undefined = null;
	@tracked quarterStartDate = DateTime.local().startOf('quarter').toISODate();
	@tracked quarterEndDate = '2999-12-31';
	@tracked salesEffectiveStartDate = '1900-01-01';
	@tracked salesEffectiveEndDate = '2999-12-31';
	@tracked stateId: string | undefined;
	@tracked type = null;
	@tracked currentScope = '';
	@tracked page = 0;
	@tracked sorts = [{ valuePath: 'quarterStartDate', isAscending: true }];
	searchPlaceholder = 'Filter by AIP, agent, state...';
	searchPrompt = 'Type a search term to filter endorsements by AIP, agent name, or state.';

	get columns() {
		const baseColumns: TableColumn[] = [
			{
				id: 'efaccab7-ac1c-4c46-8d89-508d3b80268d',
				name: 'Producer',
				valuePath: 'InsurancePolicy.producerName',
				width: 225,
				minWidth: 225, // need minWidth === width re bug on resizing first fixed column
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: !isTouchDevice() ? 'left' : '',
				isVisible: true,
			},
			{
				id: '937966fa-00a3-4900-bed6-88422e3a7e6e',
				name: 'Sales Date',
				valuePath: 'salesEffectiveDate',
				width: 120,
				cellComponent: CellComponents.IntlDateTimeFormat,
				componentArgs: { month: 'numeric', day: 'numeric', year: 'numeric' },
				textAlign: 'left',
				isSortable: true,
				isFixed: !isTouchDevice() ? 'left' : '',
				isVisible: true,
				linkRoute: this.drpInsuranceEndorsementRoute,
				linkModelPath: 'id',
			},
			{
				id: '5bd07025-ae5c-480a-8611-e40856195d56',
				name: 'AIP',
				valuePath: 'InsurancePolicy.AIP.name',
				width: 120,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: false,
			},
			{
				id: '5dd83784-0ddf-4813-b144-424bf808306f',
				name: 'Agent',
				valuePath: 'InsurancePolicy.agentFullName',
				width: 140,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: false,
			},
			{
				id: 'f752026d-d525-4c9a-8bda-02b75374a7ea',
				name: 'Agency',
				valuePath: 'InsurancePolicy.agencyName',
				width: 200,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: false,
			},
			{
				id: 'dec87246-681d-46ff-940c-f3eb7c846639',
				name: 'Quarter',
				valuePath: 'quarterStartDate',
				width: 105,
				cellComponent: CellComponents.QuarterFormat,
				isSortable: true,
				isFixed: '',
				isVisible: true,
				textAlign: 'left',
			},
			{
				id: '313ad000-e4e5-4f3b-9a0e-603650ebaca7',
				name: 'Policy Number',
				valuePath: 'InsurancePolicy.policyNumber',
				minWidth: 140,
				cellComponent: CellComponents.String,
				isSortable: false,
				isFixed: '',
				isVisible: false,
				textAlign: 'left',
			},
			{
				id: '52c01d7d-9b55-4fd1-8584-065b7d4b0d82',
				name: 'Milk Production',
				isFixed: '',
				isVisible: true,
				cellComponent: CellComponents.String,
				subcolumns: [
					{
						id: 'f0666e8d-70e4-4521-b6ff-7ea7ff4bddbc',
						name: 'Effective',
						valuePath: 'effectiveCoveredMilkProduction',
						width: 110,
						cellComponent: CellComponents.IntlNumberFormat,
						textAlign: 'right',
						isSortable: true,
						isVisible: true,
						isFixed: '',
						isTotaled: true,
					},
					{
						id: 'de94e3c7-7ac6-4bb3-bc20-763322d66f0d',
						name: 'Declared',
						valuePath: 'declaredCoveredMilkProduction',
						width: 110,
						cellComponent: CellComponents.IntlNumberFormat,
						textAlign: 'right',
						isSortable: true,
						isFixed: '',
						isVisible: true,
						isTotaled: true,
					},
				],
			},
			{
				id: '2bf9d0f4-ee28-41a4-9844-cb8623d809da',
				name: 'Milk Class',
				isFixed: '',
				isVisible: true,
				cellComponent: CellComponents.String,
				subcolumns: [
					{
						id: '4df371e7-df9a-4b37-9a02-785e1e61a3d8',
						name: 'III',
						valuePath: 'classIiiPercentage',
						width: 65,
						cellComponent: CellComponents.IntlNumberFormat,
						isFixed: '',
						isVisible: true,
						componentArgs: {
							style: 'percent',
						},
						textAlign: 'right',
						isSortable: false,
					},
					{
						id: '6e96708f-a353-4dc5-a5d6-f34354481563',
						name: 'IV',
						valuePath: 'classIvPercentage',
						width: 65,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'percent',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: '90b43b2e-b2e7-465d-a8fe-29b224b28679',
				name: 'Components',
				isFixed: '',
				isVisible: true,
				cellComponent: CellComponents.String,
				subcolumns: [
					{
						id: 'c62a68de-e674-4628-8ada-eb9bc6cf698f',
						name: 'B',
						valuePath: 'butterfatPercentage',
						width: 75,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'percent',
							minimumFractionDigits: '2',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: '52ddbc9f-1414-4498-84d6-93793cb2b3c3',
						name: 'PT',
						valuePath: 'proteinPercentage',
						width: 75,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'percent',
							minimumFractionDigits: '2',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: 'a62ed077-0781-4996-932f-feae9a9286d1',
				name: 'Revenue Guarantee',
				isFixed: '',
				isVisible: true,
				cellComponent: CellComponents.String,
				subcolumns: [
					{
						id: '9fe7d20d-e7c8-43a4-a9b4-63bbac1834ad',
						name: 'Total',
						valuePath: 'effectiveExpectedRevenueGuarantee',
						width: 100,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							minimumFractionDigits: '0',
							maximumFractionDigits: '0',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
						isTotaled: true,
					},
					{
						id: '0bf15d5b-76b7-4c1f-8bed-152ab8382fee',
						name: 'CWT',
						valuePath: 'effectiveExpectedRevenueGuaranteeCwt',
						width: 75,
						cellComponent: CellComponents.IntlNumberFormat,
						isFixed: '',
						isVisible: true,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							minimumFractionDigits: '2',
							maximumFractionDigits: '2',
						},
						textAlign: 'right',
						isSortable: false,
						isTotaled: true,
					},
				],
			},
			{
				id: '14b1225b-5ad0-4f3a-9de6-ccf028c4d1e2',
				name: 'Producer Premium',
				isFixed: '',
				isVisible: true,
				cellComponent: CellComponents.String,
				subcolumns: [
					{
						id: 'd25788a1-af50-4cad-9266-4e588b1c60cf',
						name: 'Total',
						valuePath: 'producerPremiumAmount',
						width: 90,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							minimumFractionDigits: '0',
							maximumFractionDigits: '0',
						},
						textAlign: 'right',
						isSortable: true,
						isFixed: '',
						isVisible: true,
						isTotaled: true,
					},
					{
						id: '3fc84a8f-8158-4ef6-8840-2d3c5cd977ef',
						name: 'CWT',
						valuePath: 'producerPremiumAmountCwt',
						width: 75,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							minimumFractionDigits: '2',
							maximumFractionDigits: '2',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
						isTotaled: true,
					},
				],
			},
			{
				id: 'a0114043-e896-4a78-a6b3-7d388d2f057a',
				name: 'Indemnity',
				isFixed: '',
				isVisible: true,
				cellComponent: CellComponents.String,
				subcolumns: [
					{
						id: '7fd627fa-65b2-4249-a666-bfd9b320f9bc',
						name: 'Total',
						valuePath: 'indemnity',
						width: 90,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							minimumFractionDigits: '0',
							maximumFractionDigits: '0',
						},
						textAlign: 'right',
						isSortable: true,
						isFixed: '',
						isVisible: true,
						isTotaled: true,
					},
					{
						id: '49664dc6-5e9d-4cee-a4b5-4c6f2b52b57c',
						name: 'CWT',
						valuePath: 'indemnityCwt',
						width: 75,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							minimumFractionDigits: '2',
							maximumFractionDigits: '2',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
						isTotaled: true,
					},
				],
			},
			{
				id: '678fb5b7-c18b-42b3-b015-3f6f60fe6016',
				name: 'Profit/Loss',
				isFixed: '',
				isVisible: true,
				cellComponent: CellComponents.String,
				subcolumns: [
					{
						id: '5e8f80c8-1709-4cf9-8f5f-9ac04eb7209e',
						name: 'Total',
						valuePath: 'pnl',
						width: 90,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							minimumFractionDigits: '0',
							maximumFractionDigits: '0',
							currencySign: 'accounting',
						},
						textAlign: 'right',
						isSortable: true,
						isFixed: '',
						isVisible: true,
						isTotaled: true,
					},
					{
						id: 'e084e498-d214-48c5-a7ba-d3b180e9d1e2',
						name: 'CWT',
						valuePath: 'pnlCwt',
						width: 85,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							minimumFractionDigits: '2',
							maximumFractionDigits: '2',
							currencySign: 'accounting',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
						isTotaled: true,
					},
				],
			},
			{
				id: '9ecaeff7-2291-4c44-8837-99545b579558',
				name: 'Total Premium',
				isFixed: '',
				isVisible: false,
				cellComponent: CellComponents.String,
				subcolumns: [
					{
						id: 'e4fb3d44-65d5-4c3d-8538-2d97c5c777da',
						name: 'Total',
						valuePath: 'totalPremiumAmount',
						width: 90,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							minimumFractionDigits: '0',
							maximumFractionDigits: '0',
						},
						textAlign: 'right',
						isSortable: true,
						isFixed: '',
						isVisible: true,
						isTotaled: true,
					},
					{
						id: '21f1c217-07b2-4361-aac8-eb7bbd18b100',
						name: 'CWT',
						valuePath: 'totalPremiumAmountCwt',
						width: 75,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							minimumFractionDigits: '2',
							maximumFractionDigits: '2',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
						isTotaled: true,
					},
				],
			},
		];
		return baseColumns;
	}

	get currentPage() {
		return this.page;
	}

	set currentPage(page) {
		this.page = page;
	}

	get endorsementData() {
		return this.itemsFn(this.model.getInsuranceEndorsements.data?.DerivedDrpEndorsements);
	}

	get totalNumEndorsements() {
		return this.model.getInsuranceEndorsements.data?.DerivedDrpEndorsementCount.count;
	}

	get searchFilterQueryParams() {
		const obj: {
			[key: string]: any;
		} = {};
		const searchQueryParams = ['agent', 'aipId', 'stateId'];
		searchQueryParams.forEach((param) => {
			// @ts-ignore
			const value = this[param];

			if (value) {
				obj[param] = {
					filterRule: 'equals',
					filterValue: value,
				};

				// set filterComponent property to specify component for custom display extended from search-filter
				const filterDisplayObj = getFilterDisplayProperty(param);
				if (filterDisplayObj && filterDisplayObj.customComponent) {
					obj[param].filterComponent = filterDisplayObj.customComponent;
				}
				// set filterLabel property to specify custom label for filter - default would be filterIdentifier (matches queryParam)
				if (filterDisplayObj && filterDisplayObj.label) {
					obj[param].filterLabel = filterDisplayObj.label;
				}
			}
		});

		return obj;
	}

	get scopedSearchFilterQueryParams() {
		return this.searchFilterQueryParams;
	}

	get quarterEndDateQueryParam() {
		return {
			startDate: this.quarterStartDate,
			endDate: this.quarterEndDate,
		};
	}

	get salesEffectiveDateQueryParam() {
		return {
			startDate: this.salesEffectiveStartDate,
			endDate: this.salesEffectiveEndDate,
		};
	}

	get columnTotals() {
		if (this.model.getInsuranceEndorsements.data?.DerivedDrpEndorsementCount.count === 0) return [];

		const totals: {
			[key: string]: any;
		} = {};
		Object.assign(totals, this.model.getInsuranceEndorsements.data?.AggregateDerivedDrpEndorsements[0].sum);
		totals['effectiveExpectedRevenueGuaranteeCwt'] =
			(totals.effectiveExpectedRevenueGuarantee / totals.effectiveCoveredMilkProduction) * 100;
		totals['producerPremiumAmountCwt'] = (totals.producerPremiumAmount / totals.effectiveCoveredMilkProduction) * 100;
		totals['totalPremiumAmountCwt'] = (totals.totalPremiumAmount / totals.effectiveCoveredMilkProduction) * 100;
		totals['indemnityCwt'] = (totals.indemnity / totals.effectiveCoveredMilkProduction) * 100;
		totals['pnlCwt'] = (totals.pnl / totals.effectiveCoveredMilkProduction) * 100;
		return [totals];
	}

	get query() {
		return query;
	}

	get formattedQueryParams(): { orderBy: DerivedDrpEndorsementSortByDTO; where: DerivedDrpEndorsementFilterDTO } {
		const route = new DRPInsuranceEndorsementsIndexRoute();

		return {
			orderBy: route.generateOrderBy(this.sorts),
			where: route.generateWhere(
				this.applicationScope.organizationId,
				this.applicationScope.globalCustomerId,
				this.aipId,
				this.type,
				this.agent,
				this.stateId,
				this.salesEffectiveStartDate,
				this.salesEffectiveEndDate,
				this.quarterStartDate,
				this.quarterEndDate,
			),
		};
	}

	@action
	setSorts(sorts: SortObj[]) {
		this.sorts = sorts;
	}

	@action
	async fetchSearchResults(searchText: any) {
		let searchApiResults = [];
		const agentResults = [];
		const stateResults: any[] = [];

		// get fuzzy match agents
		const agentVariables = {
			where: {
				OR: [
					{ agentFirstName: { contains: searchText } },
					{ agentLastName: { contains: searchText } },
					{ agentFullName: { contains: searchText } },
				],
			},
		};
		const agentsArr = (
			await this.apollo.watchQuery({
				query: agentFuzzyQuery,
				variables: agentVariables,
			})
		).InsurancePolicies.map((agent: any) => `${agent.agentFirstName} ${agent.agentLastName}`);

		const agentSet = new Set(agentsArr);

		for (const agent of agentSet) {
			const obj: {
				[key: string]: any;
			} = {};
			obj.type = 'Agent';
			obj.name = agent;
			agentResults.push(obj);
		}

		// get fuzzy match state
		const stateVariables = {
			where: {
				OR: [{ abbreviation: { contains: searchText } }, { name: { contains: searchText } }, { id: { contains: searchText } }],
			},
		};

		const states = (
			await this.apollo.watchQuery({
				query: stateFuzzyQuery,
				variables: stateVariables,
			})
		).States;

		states.forEach((state: any) => {
			const obj: {
				[key: string]: any;
			} = {};
			obj.type = 'State';
			obj.id = state.id;
			obj.name = state.name;
			stateResults.push(obj);
		});

		// get search API matches
		const variables = {
			query: searchText,
			typesToInclude: ['Aip'],
		};

		searchApiResults = (await this.apollo.watchQuery({ query: searchQuery, variables })).Search;

		// return combined set
		return [...agentResults, ...searchApiResults, ...stateResults];
	}

	@action
	structureSearchResults(searchResults: any) {
		const map = new Map();

		searchResults.forEach((item: any) => {
			if (map.has(item.type)) {
				map.get(item.type).push({ id: item.id, name: item.name, type: item.type });
			} else {
				map.set(item.type, [{ id: item.id, name: item.name, type: item.type }]);
			}
		});

		return map;
	}

	@action
	setSearchFilterQueryParam(searchResult: any) {
		const mappedSearchFilter = this.mapSearchResult(searchResult);
		//@ts-ignore
		this[mappedSearchFilter.filterIdentifier] = mappedSearchFilter.filterValue;
		this.setTablePageState();
	}

	mapSearchResult(searchResult: any) {
		let filterIdentifier;

		switch (searchResult.type) {
			case 'Agent':
				filterIdentifier = 'agent';
				break;
			case 'Aip':
				filterIdentifier = 'aipId';
				break;
			case 'State':
				filterIdentifier = 'stateId';
				break;
		}

		return {
			filterIdentifier,
			filterValue: searchResult.id ?? searchResult.name,
		};
	}

	@action
	clearSearchFilterQueryParam(filterIdentifier: any) {
		//@ts-ignore
		this[`${filterIdentifier}`] = null;
		this.setTablePageState();
	}

	@action
	setTypeQueryParam(value: any, callback: any) {
		callback();
		this.type = value;
		this.setTablePageState();
	}

	@action
	setDateFilterQueryParam(queryParam: string, value: { startDate: string; endDate: string }) {
		//@ts-ignore
		this[`${queryParam}StartDate`] = value.startDate;
		//@ts-ignore
		this[`${queryParam}EndDate`] = value.endDate;
		this.setTablePageState();
	}

	@action
	setTablePageState(newPageVal = 0) {
		this.currentPage = newPageVal;
		resetVaultTableScroll('endorsement-table');
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'drp-insurance-endorsements-index-controller': DRPInsuranceEndorsementsIndexController;
	}
}
