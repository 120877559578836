import Controller from '@ember/controller';
import { action } from '@ember/object';
import VgsFeedServicesFillsShowRoute from 'vault-client/routes/vgs/feed-services/fills/show';
import { ModelFrom } from 'vault-client/utils/type-utils';
import Fill from 'vault-client/models/vgs/fill';
import { inject as service } from '@ember/service';
import PermissionsService from 'vault-client/services/permissions';
import RouterService from '@ember/routing/router-service';
import { informationWasUpdated } from 'vault-client/utils/vgs-utils';
import { TypeOfGrainOrder } from 'vault-client/types/graphql-types';

export default class VgsFeedServicesFillsShow extends Controller {
	@service declare permissions: PermissionsService;
	@service declare router: RouterService;
	declare model: ModelFrom<VgsFeedServicesFillsShowRoute>;

	get fillModel() {
		const fillData = this.model.getFeedFill.data?.GrainFillOrder;
		const fillNotesData =
			this.model.getFeedFill.data?.GrainFillOrder?.Notes.filter((note) => note.Order.type === TypeOfGrainOrder.Fill) ?? null;
		const targetNotesData =
			this.model.getFeedFill.data?.GrainFillOrder?.GrainTargetOrder.Notes.filter((note) => note.Order.type === TypeOfGrainOrder.Target) ??
			null;
		return fillData ? new Fill(fillData, null, null, fillNotesData, targetNotesData, null, null) : null;
	}

	get filteredActivities() {
		return this.fillModel?.orderActivities?.filter((activity) => informationWasUpdated(activity)) ?? [];
	}

	@action
	transitionTo(id: number) {
		this.router.transitionTo('vgs.feed-services.feed-plans.show', id);
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	interface Registry {
		'vgs/feed-services/fills/show': VgsFeedServicesFillsShow;
	}
}
