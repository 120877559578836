import ExposureIndexController from 'vault-client/controllers/exposure/index';

export default class BusinessesBusinessExposuresController extends ExposureIndexController {
	exposureRoutePath = 'businesses.business.exposure';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'businesses/business/exposures': BusinessesBusinessExposuresController;
	}
}
