import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<PowerSelect\n\t{{did-insert this.fetchFeedCategories}}\n\t@selected={{this.currentlySelected}}\n\t@searchEnabled={{true}}\n\t@allowClear={{false}}\n\t@options={{this.feedCategories}}\n\t@searchField='name'\n\t@searchPlaceholder='Find feed category...'\n\t@onChange={{@onChange}}\n\t@renderInPlace={{@renderInPlace}}\n\t@placeholder={{@placeholder}}\n\t...attributes\n\tas |item|\n>\n\t{{item.name}}\n</PowerSelect>", {"contents":"<PowerSelect\n\t{{did-insert this.fetchFeedCategories}}\n\t@selected={{this.currentlySelected}}\n\t@searchEnabled={{true}}\n\t@allowClear={{false}}\n\t@options={{this.feedCategories}}\n\t@searchField='name'\n\t@searchPlaceholder='Find feed category...'\n\t@onChange={{@onChange}}\n\t@renderInPlace={{@renderInPlace}}\n\t@placeholder={{@placeholder}}\n\t...attributes\n\tas |item|\n>\n\t{{item.name}}\n</PowerSelect>","moduleName":"vault-client/components/feed-category-select.hbs","parseOptions":{"srcName":"vault-client/components/feed-category-select.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { gql, useQuery } from 'glimmer-apollo';
import { FeedCategory, Query } from 'vault-client/types/graphql-types';

const GET_FEED_CATEGORIES = gql`
	query FeedCategories {
		FeedCategories(orderBy: { name: Asc }) {
			id
			name
		}
	}
`;

interface FeedCategorySelectArgs {
	selected: FeedCategory;
	selectedId: string;
}

export default class FeedCategorySelect extends Component<FeedCategorySelectArgs> {
	@tracked feedCategories: FeedCategory[] | null = [];
	@tracked selected: FeedCategory | null = this.args.selected;

	get currentlySelected() {
		if (this.args.selected) {
			return this.args.selected;
		}

		if (this.args.selectedId) {
			return this.feedCategories?.find((feedCategory) => feedCategory.id == this.args.selectedId) ?? null;
		}

		return null;
	}
	set currentlySelected(value) {
		this.selected = value;
	}

	@action
	async fetchFeedCategories() {
		await useQuery<{ FeedCategories: Query['FeedCategories'] }>(this, () => [
			GET_FEED_CATEGORIES,
			{
				onComplete: (data) => {
					if (data?.FeedCategories) {
						this.feedCategories = data.FeedCategories;
					} else {
						this.feedCategories = [];
					}
				},
				onError: (error) => {
					console.error('Error on Feed Category select', error.message);
				},
			},
		]).promise;
	}
}
