import InsuranceIndexRoute, { InsuranceIndexRouteQuery } from 'vault-client/routes/insurance';

export default class OrganizationsOrganizationInsuranceRoute extends InsuranceIndexRoute {
	async model() {
		const organizationParams = this.paramsFor('organizations.organization') as { id: string };
		const variables = {
			endorsementWhere: this.generateEndorsementWhere(),
			productionWhere: this.generateProductionWhere(),
			entityId: organizationParams.id,
		};

		const response = (await this.apollo.watchQuery({ query: this.query, variables })) as InsuranceIndexRouteQuery;

		return {
			...response,
			variables,
		};
	}
}
