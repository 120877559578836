import Route from '@ember/routing/route';
import { tracked } from '@glimmer/tracking';
import { gql, useQuery } from 'glimmer-apollo';
import {
	GrainFillOrderFilterDTO,
	GrainFillOrderSortByDTO,
	Query,
	Query_GrainFillOrdersArgs,
	SortByDirection,
	TypeOfGrainPlan,
} from 'vault-client/types/graphql-types';

export const GET_CROP_FILLS = gql`
	query GrainGrainFillOrders($where: GrainFillOrderFilterDTO, $limit: Float, $orderBy: GrainFillOrderSortByDTO, $offset: Float) {
		GrainFillOrders(where: $where, limit: $limit, orderBy: $orderBy, offset: $offset) {
			id
			bushels
			futuresMonth
			deliveryStartDate
			deliveryEndDate
			deliveryMonth
			salesType
			futurePrice
			flatPrice
			fees
			spread
			basis
			planId
			contractNumber
			targetId
			Buyer {
				id
				name
			}
			Seller {
				id
				name
			}
			Location {
				id
				name
			}
			DeliveryLocation {
				id
				name
			}
			GrainFillOrderActivity(orderBy: { performedAt: Desc }) {
				id
				data
				type
			}
			Plan {
				... on GrainCropPlan {
					acres
					aph
					id
					Crop {
						id
						name
					}
					CropYear {
						id
						year
					}
					Customer {
						id
						name
					}
				}
			}
		}
		GrainFillOrderCount(where: $where) {
			count
		}
	}
`;
type SortObj = {
	valuePath: string;
	isAscending: boolean;
};

export type GetFills = {
	__typename?: 'Query';
	GrainFillOrders: Query['GrainFillOrders'];
	GrainFillOrderCount: Query['GrainFillOrderCount'];
};

export default class CropFillsRoute extends Route {
	templateName = 'crop-fills/index';

	queryParams = {
		page: { refreshModel: true },
		sorts: { refreshModel: true },
		size: { refreshModel: true },
	};

	@tracked variables: Query_GrainFillOrdersArgs = {};

	getGrainFills = useQuery<GetFills, Query_GrainFillOrdersArgs>(this, () => [GET_CROP_FILLS, { variables: this.variables }]);

	generateOrderBy(sorts: SortObj[]): GrainFillOrderSortByDTO {
		const orderBy: {
			[key: string]: any;
		} = {};

		sorts.forEach((sort: any) => {
			const value = sort.isAscending ? SortByDirection.Asc : SortByDirection.Desc;
			orderBy[sort.valuePath] = value;
		});

		return orderBy;
	}

	generateWhere(organizationId: null | string, customerId: undefined | null | string): GrainFillOrderFilterDTO {
		const where: GrainFillOrderFilterDTO = {
			Plan: {
				type: {
					equals: TypeOfGrainPlan.Crop,
				},
				...(customerId && {
					customerId: {
						equals: customerId,
					},
				}),
				...(organizationId && {
					organizationId: {
						equals: organizationId,
					},
				}),
			},
		};

		return where;
	}
}
