import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Vault::UiButton @style='destructive' @size={{@size}} {{on 'click' this.openConfirmation}}>{{yield}}</Vault::UiButton>\n{{#if this.showConfirmation}}\n\t<Vault::UiConfirmation as |confirmation|>\n\t\t<confirmation.title>Delete Confirmation</confirmation.title>\n\t\t<confirmation.body>Are you sure you want to delete this business?\n\t\t\t<br />This action is irreversible.\n\t\t\t{{#if this.errorMessage}}\n\t\t\t\t<div class='w-full py-3'>\n\t\t\t\t\t<p class='justify-center text-brand-error-50 w-full'>{{this.errorMessage}}</p>\n\t\t\t\t</div>\n\t\t\t{{/if}}\n\t\t</confirmation.body>\n\t\t<confirmation.cancel @style='plain' {{on 'click' this.closeConfirmation}}>Cancel</confirmation.cancel>\n\t\t<confirmation.action @style='destructive' {{on 'click' this.deleteBusiness}}>Delete Business</confirmation.action>\n\t</Vault::UiConfirmation>\n{{/if}}", {"contents":"<Vault::UiButton @style='destructive' @size={{@size}} {{on 'click' this.openConfirmation}}>{{yield}}</Vault::UiButton>\n{{#if this.showConfirmation}}\n\t<Vault::UiConfirmation as |confirmation|>\n\t\t<confirmation.title>Delete Confirmation</confirmation.title>\n\t\t<confirmation.body>Are you sure you want to delete this business?\n\t\t\t<br />This action is irreversible.\n\t\t\t{{#if this.errorMessage}}\n\t\t\t\t<div class='w-full py-3'>\n\t\t\t\t\t<p class='justify-center text-brand-error-50 w-full'>{{this.errorMessage}}</p>\n\t\t\t\t</div>\n\t\t\t{{/if}}\n\t\t</confirmation.body>\n\t\t<confirmation.cancel @style='plain' {{on 'click' this.closeConfirmation}}>Cancel</confirmation.cancel>\n\t\t<confirmation.action @style='destructive' {{on 'click' this.deleteBusiness}}>Delete Business</confirmation.action>\n\t</Vault::UiConfirmation>\n{{/if}}","moduleName":"vault-client/components/delete-business-button.hbs","parseOptions":{"srcName":"vault-client/components/delete-business-button.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { gql, useMutation } from 'glimmer-apollo';
import { Mutation_deleteCustomerArgs } from 'vault-client/types/graphql-types';

interface DeleteBusinessButtonArgs {
	businessId: string;
	organizationId: string;
}

const DELETE_BUSINESS = gql`
	mutation deleteCustomer($id: String!) {
		deleteCustomer(id: $id) {
			id
		}
	}
`;

type DeleteCustomerMutation = {
	__typename?: 'Mutation';

	deleteBusiness?: {
		id: String;
	} | null;
};

export default class DeleteBusinessButton extends Component<DeleteBusinessButtonArgs> {
	@service router: any;
	@tracked showConfirmation = false;
	@tracked errorMessage: string | null = null;

	deleteBusinessMutation = useMutation<DeleteCustomerMutation, Mutation_deleteCustomerArgs>(this, () => [
		DELETE_BUSINESS,
		{
			onComplete: (): void => {
				this.router.transitionTo('organizations.organization.businesses', this.args.organizationId);
				this.closeConfirmation();
			},
			onError: (error): void => {
				this.errorMessage = 'There was an error. Business was not deleted.';
				console.error('Error while attempting to delete business', error.message);
			},
		},
	]);

	@action
	openConfirmation() {
		this.showConfirmation = true;
	}

	@action
	closeConfirmation() {
		this.showConfirmation = false;
	}

	@action
	async deleteBusiness() {
		await this.deleteBusinessMutation.mutate({
			id: this.args.businessId,
		});
	}
}
