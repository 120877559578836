import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{this.formattedValue}}", {"contents":"{{this.formattedValue}}","moduleName":"vault-client/components/week-format.hbs","parseOptions":{"srcName":"vault-client/components/week-format.hbs"}});
import Component from '@glimmer/component';
import { DateTime } from 'luxon';

interface Args {
	value: string;
}

export default class WeekFormatComponent extends Component<Args> {
	constructor(owner: any, args: Args) {
		super(owner, args);
	}

	get formattedValue() {
		if (this.args.value) {
			const luxonDate = DateTime.fromISO(this.args.value);
			if (luxonDate.isValid) {
				const weekString = `w${luxonDate.weekNumber} ${luxonDate.year}`;
				return weekString;
			}
		}
		return null;
	}
}
