import LRPInsuranceEndorsementsIndexController from 'vault-client/controllers/lrp-insurance-endorsements/index';

export default class BusinessesBusinessLrpInsuranceEndorsements extends LRPInsuranceEndorsementsIndexController {
	lrpInsuranceEndorsementRoute: string = 'businesses.business.lrp-insurance-endorsement';
	insuranceRoutePath: string = 'businesses.business.insurance';
	insurancePolicyRoutePath: string = 'businesses.business.insurance-policy';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'businesses/business/lrp-insurance-endorsements': BusinessesBusinessLrpInsuranceEndorsements;
	}
}
