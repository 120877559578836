export default function getFilterDisplayProperty(filterName) {
	return filterDisplayDict.get(filterName);
}

const filterDisplayDict = new Map([
	['accountId', { customComponent: 'account-id-filter', label: 'Account' }],
	['agent', { label: 'Agent' }],
	['aipId', { customComponent: 'aip-id-filter', label: 'AIP' }],
	[
		'instrumentId',
		{
			customComponent: 'instrument-id-filter',
			label: 'Instrument',
		},
	],
	['br_owner', { label: 'BR Owner' }],
	['brOwnerId', { customComponent: 'br-owner-id-filter', label: 'BR Owner' }],
	['businessContactId', { customComponent: 'business-contact-id-filter', label: 'Business Contact' }],
	['advisor', { label: 'Advisor' }],
	['grainAdvisorId', { customComponent: 'grain-advisor-id-filter', label: 'Advisor' }],
	['customer', { label: 'Customer' }],
	['contactName', { label: 'Contact Name' }],
	['crop', { label: 'Crop' }],
	['cropCategoryId', { customComponent: 'crop-category-id-filter', label: 'Crop' }],
	['buyer', { label: 'Buyer' }],
	['buyerId', { customComponent: 'buyer-id-filter', label: 'Buyer' }],
	['location', { label: 'Location' }],
	['grainLocationId', { customComponent: 'grain-location-id-filter', label: 'Location' }],
	['selectedLocationId', { customComponent: 'location-id-filter', label: 'Location' }],
	['salesCode', { label: 'Sales Code' }],
	['seller', { label: 'Seller' }],
	['sellerId', { customComponent: 'seller-id-filter', label: 'Seller' }],
	['origin', { label: 'Origin' }],
	['customerId', { customComponent: 'customer-id-filter', label: 'Business' }],
	['stateId', { customComponent: 'state-id-filter', label: 'State' }],
	['policyNumber', { label: 'Policy Number' }],
	['fcmId', { customComponent: 'fcm-id-filter', label: 'FCM' }],
	['productId', { customComponent: 'product-id-filter', label: 'Product' }],
]);
