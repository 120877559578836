import Controller from '@ember/controller';
import StrategicInventoryModel from '../../models/strategic-inventory-model';
import { getOwner } from '@ember/application';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { Product } from 'vault-client/types/graphql-types';
import isTouchDevice from 'vault-client/utils/is-touch-device';
import { TableColumn, CellComponents } from 'vault-client/types/vault-table';
import { ModelFrom } from 'vault-client/utils/type-utils';
import ReportsCheeseStrategicInventoryModelRoute from 'vault-client/routes/reports/cheese-strategic-inventory-model';

interface HtmlElement {
	target: HTMLInputElement;
}

export default class ReportsCheeseStrategicInventoryModel extends Controller {
	@tracked carryCost: number = 0.01;
	@tracked currentPriceInput: string = 'Front Future Price';
	@tracked numberOfYears: number = 5;
	reportsRoute: string = '';
	declare model: ModelFrom<ReportsCheeseStrategicInventoryModelRoute>;
	get columns(): TableColumn[] {
		const baseColumns: TableColumn[] = [
			{
				id: '3919d2ff-c4c4-44e9-a932-57aa1523d971',
				name: 'Month',
				valuePath: 'displayExpiresAt',
				minWidth: 90,
				cellComponent: CellComponents.MonthFormat,
				textAlign: 'left',
				isSortable: false,
				isFixed: !isTouchDevice() ? 'left' : '',
				isVisible: true,
			},
			{
				id: '7e394cd9-1e8d-44c0-855e-26a070b86585',
				name: 'Futures',
				valuePath: 'currentFuturePrice',
				minWidth: 110,
				cellComponent: CellComponents.PriceFormat,
				componentArgs: {
					fractionDigitsPath: 'product.MostCurrentFuture.SymbolGroup.fractionDigits',
					displayFactorPath: 'noDisplayFactor',
				},
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'c7063d2f-a2b7-43f2-b54a-08551de20645',
				name: 'Futures - Carry',
				valuePath: 'futuresMinusCarryCost',
				minWidth: 150,
				cellComponent: CellComponents.PriceFormat,
				componentArgs: {
					fractionDigitsPath: 'product.MostCurrentFuture.SymbolGroup.fractionDigits',
					displayFactorPath: 'noDisplayFactor',
				},
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '2126abe9-aae7-4e2f-80f0-ef3f2cd951a4',
				name: 'Current',
				valuePath: 'currentPrice',
				minWidth: 120,
				cellComponent: CellComponents.PriceFormat,
				componentArgs: {
					fractionDigitsPath: 'product.MostCurrentFuture.SymbolGroup.fractionDigits',
					displayFactorPath: 'noDisplayFactor',
				},
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '1a013d8d-d07b-4588-b84c-460dcc43e736',
				name: '1 Mo Carry Incentive',
				valuePath: 'oneMonthCarryIncentive',
				minWidth: 200,
				cellComponent: CellComponents.PriceFormat,
				componentArgs: {
					fractionDigitsPath: 'product.MostCurrentFuture.SymbolGroup.fractionDigits',
					displayFactorPath: 'noDisplayFactor',
				},
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '45fad1fd-9cf7-43d6-9f50-2e49e08e0ebd',
				name: 'Strategy',
				valuePath: 'riskManagementStrategy',
				minWidth: 120,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
		];
		return baseColumns;
	}

	prevSims: {
		oneMonthCarryIncentive: number | null;
		displayExpiresAt: string;
		symbol: string;
	}[] = [];
	// Cash settled prices for Cheese can either be "Front Future", "Cheese Barrel", or "Cheese Block" depending on what the user chooses to use. Defaulting to "Front Future".
	get spotProduct() {
		if (this.currentPriceInput == 'Spot Cheese Barrel') {
			return this.spotBarrelProduct;
		}

		if (this.currentPriceInput == 'Spot Cheese Block') {
			return this.spotBlockProduct;
		}

		return this.frontFuture;
	}

	get spotBlockProduct() {
		return this.model.Products?.find((product: Product) => product?.slug === 'us-dairy-cheese-block-spot');
	}

	get spotBarrelProduct() {
		return this.model.Products?.find((product: Product) => product?.slug === 'us-dairy-cheese-barrel');
	}

	get futuresProduct() {
		const products: Product[] = this.model.Products;
		return products?.find((product) => product.slug === 'us-dairy-cheese');
	}

	get frontFuture() {
		return this.futuresProduct?.CurrentFutures[0];
	}

	get months() {
		const owner = getOwner(this);
		const product = this.futuresProduct;
		return product?.CurrentFutures?.reduce((acc: any, future: any) => {
			const previousSims = acc.slice(-2);
			const currentSim = new StrategicInventoryModel(
				owner,
				product,
				future,
				this.carryCost,
				previousSims,
				this.spotProduct,
				this.currentPriceInput,
				this.numberOfYears
			);

			acc.push(currentSim);
			return acc;
		}, []);
	}

	@action
	currentPriceRadioInput(e: HtmlElement) {
		return (this.currentPriceInput = e.target.value);
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'reports/cheese-strategic-inventory-model': ReportsCheeseStrategicInventoryModel;
	}
}
