import Route from '@ember/routing/route';
import { DerivedDrpEndorsementFilterDTO, ForecastedMilkProductionByMonthFilterDTO, Query } from 'vault-client/types/graphql-types';
import { queryManager } from 'ember-apollo-client';
import query from 'vault-client/graphql/queries/insurance/index.graphql';
import { DateTime } from 'luxon';

export type InsuranceIndexRouteQuery = {
	AggregateDerivedDrpEndorsements: Query['AggregateDerivedDrpEndorsements'];
	AggregateForecastedMilkProductionByMonths: Query['AggregateForecastedMilkProductionByMonths'];
	InsurancePolicies: Query['InsurancePolicies'];
	DerivedDrpEndorsements: Query['DerivedDrpEndorsements'];
	LrpInsuranceEndorsements: Query['LrpInsuranceEndorsements'];
};
export default class InsuranceIndexRoute extends Route {
	@queryManager apollo: any;

	templateName = 'insurance/index';

	query = query;

	generateProductionWhere(): ForecastedMilkProductionByMonthFilterDTO {
		const productionWhere: ForecastedMilkProductionByMonthFilterDTO = {};

		const quarterEndDate = DateTime.local().startOf('quarter').toISODate();
		const quarterStartDate = DateTime.local().startOf('quarter').minus({ quarters: 4 }).toISODate();
		productionWhere.date = {
			gte: DateTime.fromISO(quarterStartDate).startOf('quarter').toISODate(),
			lte: DateTime.fromISO(quarterEndDate).endOf('quarter').toISODate(),
		};

		return productionWhere;
	}

	generateEndorsementWhere(): DerivedDrpEndorsementFilterDTO {
		const endorsementWhere: DerivedDrpEndorsementFilterDTO = {};

		const quarterEndDate = DateTime.local().startOf('quarter').toISODate();
		const quarterStartDate = DateTime.local().startOf('quarter').minus({ quarters: 4 }).toISODate();

		endorsementWhere.quarterEndDate = {
			gte: quarterStartDate,
			lte: DateTime.fromISO(quarterEndDate).endOf('quarter').toISODate(),
		};

		return endorsementWhere;
	}
}
