import { Observable } from '@apollo/client/utilities';

// On Error does not support promises and async await, so this util was added for compatibility
// Source: https://github.com/apollographql/apollo-link/issues/646#issuecomment-423279220
const promiseToObservable = (promise: Promise<any>) =>
	new Observable((subscriber: any) => {
		promise.then(
			(value) => {
				if (subscriber.closed) return;
				subscriber.next(value);
				subscriber.complete();
			},
			(err) => subscriber.error(err)
		);
	});

export { promiseToObservable };
