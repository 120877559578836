import Controller from '@ember/controller';
import { DateTime } from 'luxon';
import { tracked } from '@glimmer/tracking';
import DerivedInsuranceEndorsement from 'vault-client/models/derived-insurance-endorsement';
import isTouchDevice from 'vault-client/utils/is-touch-device';
import { SortObj, TableColumn, CellComponents } from 'vault-client/types/vault-table';
import { ModelFrom } from 'vault-client/utils/type-utils';
import InsuranceQuarterRoute from 'vault-client/routes/insurance-overview/show';

export default class InsuranceQuarterController extends Controller {
	declare model: ModelFrom<InsuranceQuarterRoute>;
	@tracked quarterStartDate: string = '';

	@tracked organizationId: any;
	@tracked globalCustomerId: any;
	@tracked locationId: any;
	@tracked recentEndorsementsSorts: SortObj[] = [{ valuePath: 'salesEffectiveDate', isAscending: false }];

	get recentEndorsementSortParams(): SortObj[] {
		return this.recentEndorsementsSorts;
	}

	set recentEndorsementSortParams(sorts: SortObj[]) {
		this.recentEndorsementsSorts = sorts;
	}

	get recentEndorsements(): TableColumn[] {
		const baseColumns: TableColumn[] = [
			{
				id: '78332926-ad25-4493-a0d9-76316f440a80',
				name: 'Sales Date',
				valuePath: 'salesEffectiveDate',
				width: 120,
				cellComponent: CellComponents.IntlDateTimeFormat,
				componentArgs: { month: 'numeric', day: 'numeric', year: 'numeric' },
				textAlign: 'left',
				isSortable: true,
				isFixed: !isTouchDevice() ? 'left' : '',
				isVisible: true,
				linkRoute: 'drp-insurance-endorsements.show',
				linkModelPath: 'id',
			},
			{
				id: '08fd74ea-ec1d-4956-b7ee-288aa755146c',
				name: 'Milk Production',
				isFixed: '',
				isVisible: true,
				cellComponent: CellComponents.String,
				subcolumns: [
					{
						id: 'a63b0f44-ba80-482e-bca3-2a1eb115d4ac',
						name: 'Effective',
						valuePath: 'effectiveCoveredMilkProduction',
						width: 110,
						cellComponent: CellComponents.IntlNumberFormat,
						textAlign: 'right',
						isSortable: true,
						isFixed: '',
						isVisible: true,
					},
					{
						id: '4c9d97dd-ca81-4fd9-b664-d01cdbf7c1c0',
						name: 'Declared',
						valuePath: 'declaredCoveredMilkProduction',
						width: 110,
						cellComponent: CellComponents.IntlNumberFormat,
						textAlign: 'right',
						isSortable: true,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: '557ebb06-1221-4a6c-a4b5-d06d350d287e',
				name: 'Milk Class',
				isFixed: '',
				isVisible: true,
				cellComponent: CellComponents.String,
				subcolumns: [
					{
						id: '30b8e76c-ee46-400e-90bd-72497a9aef5c',
						name: 'III',
						valuePath: 'classIiiPercentage',
						width: 65,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'percent',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: 'c7c6742f-7eb9-4e74-9473-667c393df229',
						name: 'IV',
						valuePath: 'classIvPercentage',
						width: 65,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'percent',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: '11eb371f-8248-4a6d-8902-b5e0575a28e4',
				name: 'Components',
				isFixed: '',
				isVisible: true,
				cellComponent: CellComponents.String,
				subcolumns: [
					{
						id: 'd746fb11-895c-4427-b655-cf08fba92a19',
						name: 'B',
						valuePath: 'butterfatPercentage',
						width: 75,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'percent',
							minimumFractionDigits: '2',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: '57f8aca9-b753-44bb-9748-61799e075315',
						name: 'PT',
						valuePath: 'proteinPercentage',
						width: 75,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'percent',
							minimumFractionDigits: '2',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: '2f5eb1a6-727b-47ef-87b3-b8179e891926',
				name: 'Revenue Guarantee',
				isFixed: '',
				isVisible: true,
				cellComponent: CellComponents.String,
				subcolumns: [
					{
						id: '9dda3162-ae95-47c1-b5e0-8320f90ee396',
						name: 'Total',
						valuePath: 'effectiveExpectedRevenueGuarantee',
						width: 100,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							minimumFractionDigits: '0',
							maximumFractionDigits: '0',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: 'ab321e5f-d68f-4a94-be2f-40c1d094d555',
						name: 'CWT',
						valuePath: 'effectiveExpectedRevenueGuaranteeCwt',
						width: 75,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							minimumFractionDigits: '2',
							maximumFractionDigits: '2',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: 'f2f44907-cdcf-44e3-b298-85925db49a04',
				name: 'Producer Premium',
				isFixed: '',
				isVisible: true,
				cellComponent: CellComponents.String,
				subcolumns: [
					{
						id: '1eddf0a5-bd68-4fbd-bee4-4227d254dc90',
						name: 'Total',
						valuePath: 'producerPremiumAmount',
						width: 90,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							minimumFractionDigits: '0',
							maximumFractionDigits: '0',
						},
						textAlign: 'right',
						isSortable: true,
						isFixed: '',
						isVisible: true,
					},
					{
						id: '9db83b80-e746-4f65-93e7-90d2937bcbda',
						name: 'CWT',
						valuePath: 'producerPremiumAmountCwt',
						width: 75,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							minimumFractionDigits: '2',
							maximumFractionDigits: '2',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: '57363fa8-95ee-4e12-bafb-ef2c513deab3',
				name: 'Indemnity',
				isFixed: '',
				isVisible: true,
				cellComponent: CellComponents.String,
				subcolumns: [
					{
						id: '2d9a8873-5c54-4538-b242-b3228254ff76',
						name: 'Total',
						valuePath: 'indemnity',
						width: 90,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							minimumFractionDigits: '0',
							maximumFractionDigits: '0',
						},
						textAlign: 'right',
						isSortable: true,
						isFixed: '',
						isVisible: true,
					},
					{
						id: '3816abab-98ac-40dc-bb52-f7ddf034fcce',
						name: 'CWT',
						valuePath: 'indemnityCwt',
						width: 75,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							minimumFractionDigits: '2',
							maximumFractionDigits: '2',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: '5af3612a-076f-43b3-a565-28a9480d4bae',
				name: 'Profit/Loss',
				isFixed: '',
				isVisible: true,
				cellComponent: CellComponents.String,
				subcolumns: [
					{
						id: '295fb63e-c2af-4ef0-bb0d-c6a3f7d203ce',
						name: 'Total',
						valuePath: 'pnl',
						width: 90,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							minimumFractionDigits: '0',
							maximumFractionDigits: '0',
							currencySign: 'accounting',
						},
						textAlign: 'right',
						isSortable: true,
						isFixed: '',
						isVisible: true,
					},
					{
						id: 'be3595c9-cd8c-43f0-b1d2-04dd442a2381',
						name: 'CWT',
						valuePath: 'pnlCwt',
						width: 85,
						cellComponent: CellComponents.IntlNumberFormat,
						componentArgs: {
							style: 'currency',
							currency: 'USD',
							minimumFractionDigits: '2',
							maximumFractionDigits: '2',
							currencySign: 'accounting',
						},
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
				],
			},
		];

		return baseColumns;
	}

	get quarterlyTotals(): TableColumn[] {
		const baseColumns: TableColumn[] = [
			{
				id: '73f484ec-6095-4264-b500-1f1657b0e4f3',
				name: 'Milk Production',
				isFixed: '',
				isVisible: true,
				cellComponent: CellComponents.String,
				subcolumns: [
					{
						id: 'd2a38d80-3ef2-48ef-8ca6-116977bba16b',
						name: 'Effective',
						valuePath: 'sum.effectiveCoveredMilkProduction',
						width: 110,
						cellComponent: CellComponents.IntlNumberFormat,
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: 'fa8aa466-1977-41fd-bda0-d2f56d2e27a0',
						name: 'Declared',
						valuePath: 'sum.declaredCoveredMilkProduction',
						width: 110,
						cellComponent: CellComponents.IntlNumberFormat,
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: '749b6ecd-26ef-4976-9f92-297e0120a24e',
				name: 'Revenue Guarantee',
				valuePath: 'sum.effectiveExpectedRevenueGuarantee',
				width: 120,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '5d337349-d062-452a-ae92-1cbc237ccbde',
				name: 'Producer Premium',
				valuePath: 'sum.producerPremiumAmount',
				width: 120,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '474850b4-d341-439b-8ef5-eb797b63316c',
				name: 'Indemnity',
				valuePath: 'sum.indemnity',
				width: 120,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '49ba922f-8293-4e5f-bb92-8fcea12b595a',
				name: 'P/L',
				valuePath: 'sum.pnl',
				width: 120,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
		];
		return baseColumns;
	}

	get quarterlyExchangeEquivalents(): TableColumn[] {
		const baseColumns: TableColumn[] = [
			{
				id: 'f35a606a-73bf-4359-ae48-b30f87099a98',
				name: '',
				valuePath: 'name',
				// width: 120,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: !isTouchDevice() ? 'left' : '',
				isVisible: true,
			},
			{
				id: '7226976b-3f1f-4865-bb35-2a324371f916',
				name: 'Pounds',
				valuePath: 'lbs',
				// width: 120,
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '25fb53fe-f2cc-42af-8cbb-aed234ed56cf',
				name: 'CME Contracts',
				valuePath: 'contracts',
				// width: 120,
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
		];
		return baseColumns;
	}

	get quarterlyComponentEquivalents(): TableColumn[] {
		const baseColumns: TableColumn[] = [
			{
				id: 'fe94bc84-e82a-4fa7-a283-e187719b233a',
				name: '',
				valuePath: 'name',
				width: 120,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: !isTouchDevice() ? 'left' : '',
				isVisible: true,
			},
			{
				id: '937b0f8c-dda3-4cdf-b338-a7d096e557ca',
				name: 'Effective Pounds',
				valuePath: 'lbs',
				width: 120,
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
		];
		return baseColumns;
	}

	get quarterEndDate() {
		return this.quarterStartDate == '' ? undefined : DateTime.fromISO(this.quarterStartDate).endOf('quarter').toISODate();
	}

	get quarterEndorsements() {
		return this.model?.DerivedDrpEndorsements.map((row: any) => {
			return new DerivedInsuranceEndorsement(row);
		});
	}

	get displayQuarter() {
		const luxonDate = DateTime.fromISO(this.quarterStartDate);

		return `${luxonDate.year} Q${luxonDate.quarter}`;
	}

	get exchangeEquivalents() {
		const aggregateEndorsementData = this.model.AggregateDerivedDrpEndorsements[0];

		if (!aggregateEndorsementData) {
			return [];
		}

		return [
			{
				name: 'Class III',
				lbs: aggregateEndorsementData.sum.classIiiPounds,
				contracts: aggregateEndorsementData.sum.classIiiPounds / 200000,
			},
			{
				name: 'Class IV',
				lbs: aggregateEndorsementData.sum.classIvPounds,
				contracts: aggregateEndorsementData.sum.classIvPounds / 200000,
			},
			{
				name: 'Cheese',
				lbs: aggregateEndorsementData.sum.excessCheesePounds,
				contracts: aggregateEndorsementData.sum.excessCheesePounds / 20000,
			},
			{
				name: 'Butter',
				lbs: aggregateEndorsementData.sum.excessButterPounds,
				contracts: aggregateEndorsementData.sum.excessButterPounds / 20000,
			},
			{
				name: 'Excess Protein',
				lbs: aggregateEndorsementData.sum.excessProteinPoundsAfterCheese,
				contracts: null,
			},
		];
	}

	get componentEquivalents() {
		const aggregateEndorsementData = this.model.AggregateDerivedDrpEndorsements[0];

		if (!aggregateEndorsementData) {
			return [];
		}

		return [
			{
				name: 'Butterfat',
				lbs: aggregateEndorsementData.sum.butterfatPounds,
			},
			{
				name: 'Protein',
				lbs: aggregateEndorsementData.sum.proteinPounds,
			},
			{
				name: 'Other Solids',
				lbs: aggregateEndorsementData.sum.otherSolidsPounds,
			},
			{
				name: 'Nonfat Solids',
				lbs: aggregateEndorsementData.sum.nonfatSolidsPounds,
			},
		];
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'insurance-quarter': InsuranceQuarterController;
	}
}
