import ReportsPriceScenariosRoute from 'vault-client/routes/reports/price-scenarios';

type ModelParams = {
	startDate: string;
	endDate: string;
};
export default class BusinessesBusinessPriceScenariosRoute extends ReportsPriceScenariosRoute {
	async model(params: ModelParams) {
		const startDate = params.startDate;
		const endDate = params.endDate;
		const businessParams = this.paramsFor('businesses.business') as { business_id: string };

		this.variables = {
			forecastedMilkProductionByMonths: this.generateForecastedMilkProductionByMonthWhere(startDate, endDate),
			futuresWhere: this.generateFuturesWhere(startDate, endDate),
			revenuesAndExpensesWhere: this.generateRevenuesAndExpensesWhere(startDate, endDate),
			premiumsAndDeductionsWhere: this.generatePremiumsAndDeductionsWhere(startDate, endDate),
			allocatedFuturePositionsWhere: this.generateAllocatedFuturePositionsWhere(startDate, endDate, businessParams.business_id),
			allocatedOptionPositionsWhere: this.generateAllocatedOptionPositionsWhere(startDate, endDate, businessParams.business_id),
			allocatedSwapPositionsWhere: this.generateAllocatedSwapPositionsWhere(startDate, endDate, businessParams.business_id),
			allocatedSwaptionPositionsWhere: this.generateAllocatedSwaptionPositionsWhere(startDate, endDate, businessParams.business_id),
			allocatedDrpEndorsementsWhere: this.generateAllocatedDrpEndorsementsWhere(startDate, endDate),
			scopeId: businessParams.business_id,
		};

		await this.getPriceScenariosData.promise;

		return {
			getPriceScenariosData: this.getPriceScenariosData,
			entityId: businessParams.business_id,
		};
	}
}
