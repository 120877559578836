import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Vault::UiButton @style='destructive' @size={{@size}} {{on 'click' this.openConfirmation}}>{{yield}}</Vault::UiButton>\n{{#if this.showConfirmation}}\n\t<Vault::UiConfirmation as |confirmation|>\n\t\t<confirmation.title>Delete Confirmation</confirmation.title>\n\t\t<confirmation.body>Are you sure you want to delete the endorsement?\n\t\t\t<br />This action is irreversible.\n\t\t</confirmation.body>\n\t\t<confirmation.cancel @style='plain' {{on 'click' this.closeConfirmation}}>Cancel</confirmation.cancel>\n\t\t<confirmation.action @style='destructive' {{on 'click' this.deleteEndorsement}}>Delete Endorsement</confirmation.action>\n\t\t{{#if this.errorMessage}}\n\t\t\t<div class='w-full py-3'>\n\t\t\t\t<p class='justify-center text-brand-error-50 w-full'>{{this.errorMessage}}</p>\n\t\t\t</div>\n\t\t{{/if}}\n\t</Vault::UiConfirmation>\n{{/if}}", {"contents":"<Vault::UiButton @style='destructive' @size={{@size}} {{on 'click' this.openConfirmation}}>{{yield}}</Vault::UiButton>\n{{#if this.showConfirmation}}\n\t<Vault::UiConfirmation as |confirmation|>\n\t\t<confirmation.title>Delete Confirmation</confirmation.title>\n\t\t<confirmation.body>Are you sure you want to delete the endorsement?\n\t\t\t<br />This action is irreversible.\n\t\t</confirmation.body>\n\t\t<confirmation.cancel @style='plain' {{on 'click' this.closeConfirmation}}>Cancel</confirmation.cancel>\n\t\t<confirmation.action @style='destructive' {{on 'click' this.deleteEndorsement}}>Delete Endorsement</confirmation.action>\n\t\t{{#if this.errorMessage}}\n\t\t\t<div class='w-full py-3'>\n\t\t\t\t<p class='justify-center text-brand-error-50 w-full'>{{this.errorMessage}}</p>\n\t\t\t</div>\n\t\t{{/if}}\n\t</Vault::UiConfirmation>\n{{/if}}","moduleName":"vault-client/components/delete-insurance-endorsement-button.hbs","parseOptions":{"srcName":"vault-client/components/delete-insurance-endorsement-button.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { DrpInsuranceEndorsement, LrpInsuranceEndorsement } from 'vault-client/types/graphql-types';
import { gql, useMutation } from 'glimmer-apollo';
import { inject as service } from '@ember/service';

interface DeleteInsuranceEndorsementButtonArgs {
	insuranceEndorsementId: string;
	transitionToRoutePath: string;
}

type Mutation_deleteDrpInsuranceEndorsementArgs = {
	deleteDrpInsuranceEndorsementId: string;
};

type Mutation_deleteLrpEndorsementArgs = {
	deleteLrpInsuranceEndorsementId: string;
};

type DeleteDrpInsuranceEndorsementMutation = {
	__typename?: 'Mutation';

	DrpInsuranceEndorsement: DrpInsuranceEndorsement;
};

type DeleteLrpInsuranceEndorsementMutation = {
	__typename?: 'Mutation';

	LrpInsuranceEndorsement: LrpInsuranceEndorsement;
};

const DELETE_LRP_INSURANCE_ENDORSEMENT = gql`
	mutation DeleteLrpInsuranceEndorsement($deleteLrpInsuranceEndorsementId: String!) {
		deleteLrpInsuranceEndorsement(id: $deleteLrpInsuranceEndorsementId) {
			id
		}
	}
`;

const DELETE_DRP_INSURANCE_ENDORSEMENT = gql`
	mutation DeleteDrpInsuranceEndorsement($deleteDrpInsuranceEndorsementId: String!) {
		deleteDrpInsuranceEndorsement(id: $deleteDrpInsuranceEndorsementId) {
			id
		}
	}
`;

const getInsuranceTarget = (transitionToRoutePath: string) => {
	const path = transitionToRoutePath;
	const insuranceTarget = path.split('.', 3)[2];
	return insuranceTarget;
};

export default class DeleteInsuranceEndorsementButton extends Component<DeleteInsuranceEndorsementButtonArgs> {
	@service router: any;
	@service apollo: any;
	@tracked showConfirmation = false;
	@tracked errorMessage: string | null = null;

	deleteLrpInsuranceEndorsement = useMutation<DeleteLrpInsuranceEndorsementMutation, Mutation_deleteLrpEndorsementArgs>(this, () => [
		DELETE_LRP_INSURANCE_ENDORSEMENT,
		{
			update: (cache) => {
				// Individual Endorsement queries left out to avoid re-triggering update on deleted endorsements
				cache.evict({ fieldName: 'LrpInsuranceEndorsements' });
				cache.evict({ fieldName: 'AggregateLrpInsuranceEndorsements' });
				cache.evict({ fieldName: 'LrpInsuranceEndorsementCount' });
				cache.evict({ fieldName: 'LrpEndorsementCount' });
				cache.evict({ fieldName: 'LrpEndorsements' });
				cache.gc();
			},
			onComplete: (): void => {
				this.router.transitionTo(this.args.transitionToRoutePath);
				this.errorMessage = null;
				this.closeConfirmation();
			},
			onError: (error): void => {
				this.errorMessage = 'There was an error. Insurance endorsement was not deleted.';
				console.error('Error while attempting to delete insurance endorsement.', error.message);
			},
		},
	]);

	deleteDrpInsuranceEndorsement = useMutation<DeleteDrpInsuranceEndorsementMutation, Mutation_deleteDrpInsuranceEndorsementArgs>(
		this,
		() => [
			DELETE_DRP_INSURANCE_ENDORSEMENT,
			{
				update: (cache) => {
					// Individual Endorsement queries left out to avoid re-triggering update on deleted endorsements
					cache.evict({ fieldName: 'AggregateDerivedDrpEndorsements' });
					cache.evict({ fieldName: 'AggregateDrpEndorsements' });
					cache.evict({ fieldName: 'AggregateDrpInsuranceEndorsements' });
					cache.evict({ fieldName: 'DrpInsuranceEndorsements' });
					cache.evict({ fieldName: 'DerivedDrpEndorsements' });
					cache.evict({ fieldName: 'DerivedDrpEndorsementCount' });
					cache.evict({ fieldName: 'DrpEndorsements' });
					cache.gc();
				},
				onComplete: (): void => {
					this.router.transitionTo(this.args.transitionToRoutePath);
					this.errorMessage = null;
					this.closeConfirmation();
				},
				onError: (error): void => {
					this.errorMessage = 'There was an error. Insurance endorsement was not deleted.';
					console.error('Error while attempting to delete insurance endorsement.', error.message);
				},
			},
		]
	);

	@action
	openConfirmation() {
		this.showConfirmation = true;
	}

	@action
	closeConfirmation() {
		this.showConfirmation = false;
	}

	@action
	async deleteEndorsement() {
		const insuranceTarget = getInsuranceTarget(this.args.transitionToRoutePath);

		if (insuranceTarget === 'lrp-insurance-endorsements') {
			await this.deleteLrpInsuranceEndorsement.mutate({
				deleteLrpInsuranceEndorsementId: this.args.insuranceEndorsementId,
			});
		} else if (insuranceTarget === 'drp-insurance-endorsements') {
			await this.deleteDrpInsuranceEndorsement.mutate({
				deleteDrpInsuranceEndorsementId: this.args.insuranceEndorsementId,
			});
		}
	}
}
