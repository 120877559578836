import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { queryManager } from 'ember-apollo-client';
import { inject as service } from '@ember/service';
import { ModelFrom } from 'vault-client/utils/type-utils';
import ApplicationScope from 'vault-client/services/application-scope';
import BusinessesBusinessHedgeStrategiesEditLgmEndorsementsIndexRoute from 'vault-client/routes/businesses/business/hedge-strategies/edit-lgm-endorsements/index';
import { DateTime } from 'luxon';
import { CellComponents, SortObj, TableColumn } from 'vault-client/types/vault-table';
import intlDateTimeFormat from 'vault-client/utils/intl-date-time-format';
import {
	LgmInsuranceEndorsementByCoverageMonth,
	PerMonthData,
} from 'vault-client/controllers/businesses/business/hedge-strategies/hedge-strategy';
import getFilterDisplayProperty from 'vault-client/utils/get-filter-display-property';
import searchQuery from 'vault-client/graphql/queries/search.graphql';
import agentFuzzyQuery from 'vault-client/graphql/queries/agent-fuzzy-match.graphql';
import stateFuzzyQuery from 'vault-client/graphql/queries/state-fuzzy-match.graphql';
import resetVaultTableScroll from 'vault-client/utils/reset-vault-table-scroll';
import { State } from 'vault-client/types/graphql-types';
import LgmInsuranceEndorsementModel from 'vault-client/models/lgm-endorsement';
import { getOwner } from '@ember/application';

const queryParams = [
	'agent',
	'aipId',
	'customerId',
	'page',
	'sorts',
	'size',
	'salesEffectiveStartDate',
	'salesEffectiveEndDate',
	'stateId',
];

interface DateFilterQueryParamPrefixes {
	salesEffective: string | null;
}

type DateFilterQueryParamPrefix = keyof DateFilterQueryParamPrefixes;
interface SearchFilterIdentifiers {
	agent: string | null;
	aipId: string | null;
	customerId: string | null;
	stateId: string | null;
}

type SearchFilterIdentifier = keyof SearchFilterIdentifiers;

function isSearchFilterIdentifier(key: unknown): key is SearchFilterIdentifier {
	const searchFilterIdentifiers = ['agent', 'aipId', 'customerId', 'stateId'];
	if (typeof key === 'string' && searchFilterIdentifiers.includes(key)) {
		return true;
	}
	return false;
}

interface searchResult {
	id?: string;
	name?: string;
	type: string;
}

interface Agent {
	agentFirstName: string;
	agentLastName: string;
}

export default class BusinessesBusinessHedgeStrategiesEditLgmEndorsementsIndexController extends Controller {
	@queryManager apollo: any;
	@service declare applicationScope: ApplicationScope;

	declare model: ModelFrom<BusinessesBusinessHedgeStrategiesEditLgmEndorsementsIndexRoute>;

	@tracked lgmInsuranceEndorsementRoute: string = '';
	@tracked agent: string | null = null;
	@tracked aipId: string | null = null;
	@tracked stateId: string | null = null;
	@tracked customerId: string | null = null;
	@tracked page = 0;
	@tracked sorts = [{ valuePath: 'salesEffectiveDate', isAscending: true }];
	@tracked size = 100;
	@tracked salesEffectiveEndDate: string | null = '2999-12-31';
	@tracked salesEffectiveStartDate: string | null = '1900-01-01';
	searchPlaceholder = 'Filter by AIP, agent, state...';
	searchPrompt = 'Type a search term to filter endorsements by AIP, agent name, or state.';

	queryParams = queryParams;

	salesEffectiveDateRangeOptions = [
		{
			displayName: 'All Dates',
			startDate: '1900-01-01',
			endDate: '2999-12-31',
		},
		{
			displayName: 'Previous 7 Days',
			startDate: DateTime.local().minus({ days: 7 }).toISODate(),
			endDate: DateTime.local().toISODate(),
		},
		{
			displayName: 'Previous 30 Days',
			startDate: DateTime.local().minus({ days: 30 }).toISODate(),
			endDate: DateTime.local().toISODate(),
		},
		{
			displayName: 'Previous 90 Days',
			startDate: DateTime.local().minus({ days: 90 }).toISODate(),
			endDate: DateTime.local().toISODate(),
		},
		{
			displayName: 'Previous 12 Months',
			startDate: DateTime.local().minus({ months: 12 }).startOf('month').toISODate(),
			endDate: DateTime.local().toISODate(),
		},
		{
			displayName: 'Previous 24 Months',
			startDate: DateTime.local().minus({ months: 24 }).startOf('month').toISODate(),
			endDate: DateTime.local().toISODate(),
		},
	];

	getLgmColumns(tableType: 'selected' | 'available'): TableColumn[] {
		const baseColumns = [
			{
				id: guidFor(`${tableType}-salesEffectiveDate`),
				name: 'Sales Date',
				valuePath: 'salesEffectiveDate',
				cellComponent: CellComponents.IntlDateTimeFormat,
				componentArgs: { month: 'numeric', day: 'numeric', year: 'numeric' },
				width: 115,
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
				linkRoute: 'businesses.business.lgm-insurance-endorsement',
				linkModelPath: 'insuranceEndorsementId',
			},
			{
				id: guidFor(`${tableType}-coverageMonth`),
				name: 'Coverage Period',
				valuePath: 'coverageMonth',
				cellComponent: CellComponents.String,
				width: 155,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: guidFor(`${tableType}-target`),
				name: 'Target',
				valuePath: 'target',
				width: 80,
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: guidFor(`${tableType}-delta-quantity`),
				name: 'Delta Quantity',
				valuePath: 'lgmModelData.delta',
				width: 120,
				cellComponent: CellComponents.NumericFormat,
				componentArgs: {
					maximumFractionDigits: '1',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: guidFor(`${tableType}-producerPremiumAmount`),
				name: 'Producer Premium',
				valuePath: 'producerPremiumAmount',
				width: 160,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: guidFor(`${tableType}-grossMarginGuarantee`),
				name: 'Gross Margin Guarantee',
				valuePath: 'grossMarginGuarantee',
				width: 200,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: guidFor(`${tableType}-forecastedIndemnity`),
				name: 'Est. Indemnity',
				valuePath: 'forecastedIndemnity',
				width: 140,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
				isTotaled: true,
			},
			{
				id: guidFor(`${tableType}-pnl`),
				name: 'Net P/L',
				valuePath: 'pnl',
				width: 110,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
				isTotaled: true,
			},
			{
				id: guidFor(`${tableType}-deductibleAmount`),
				name: 'Deductible',
				valuePath: 'deductibleAmount',
				width: 120,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: false,
			},
			{
				id: guidFor(`${tableType}-commodityName`),
				name: 'Commodity',
				valuePath: 'RmaType.RmaCommodity.commodityName',
				width: 110,
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: guidFor(`${tableType}-expectedFeedCost`),
				name: 'Expected Feed Cost',
				valuePath: 'expectedFeedCost',
				width: 180,
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: guidFor(`${tableType}-expectedGrossMargin`),
				name: 'Expected Gross Margin',
				valuePath: 'expectedGrossMargin',
				width: 180,
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: guidFor(`${tableType}-sbm`),
				name: 'SBM',
				valuePath: 'sbm',
				width: 100,
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: guidFor(`${tableType}-state`),
				name: 'State',
				valuePath: 'InsurancePolicy.State.abbreviation',
				cellComponent: CellComponents.String,
				width: 70,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: guidFor(`${tableType}-agent`),
				name: 'Agent',
				valuePath: 'agentFullName',
				width: 120,
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: guidFor(`${tableType}-aip`),
				name: 'AIP',
				valuePath: 'InsurancePolicy.AIP.name',
				width: 120,
				textAlign: '',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: false,
			},
			{
				id: guidFor(`${tableType}-agency`),
				name: 'Agency',
				valuePath: 'InsurancePolicy.agencyName',
				width: 200,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: false,
			},
			{
				id: guidFor(`${tableType}-subsidyAmount`),
				name: 'Subsidy',
				valuePath: 'subsidyAmount',
				width: 180,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: false,
			},
			{
				id: guidFor(`${tableType}-producerName`),
				name: 'Producer',
				valuePath: 'producerName',
				cellComponent: CellComponents.String,
				width: 125,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: guidFor(`${tableType}-totalExpectedGrossMargin`),
				name: 'Total Gross Margin',
				valuePath: 'totalExpectedGrossMargin',
				width: 165,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: false,
			},
			{
				id: guidFor(`${tableType}-rmaTypeName`),
				name: 'RMA Type',
				valuePath: 'rmaTypeName',
				width: 125,
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: false,
			},
			{
				id: 'b6522b5c-66a4-49bf-8771-cf3a144b655d',
				name: '',
				valuePath: 'isAdded',
				minWidth: 125,
				maxWidth: 125,
				width: 125,
				cellComponent: CellComponents.UpdateHedgeStrategyItemsButton,
				componentArgs: {
					hedgeStrategyId: this.hedgeStrategyId,
					updateLgmEndorsements: this.updateLgmEndorsements,
					tableType,
				},
				textAlign: 'center',
				isSortable: false,
				isFixed: 'right',
				isVisible: true,
			},
		];
		return baseColumns;
	}

	get selectedLgmColumns(): TableColumn[] {
		return this.getLgmColumns('selected');
	}

	get availableLgmColumns(): TableColumn[] {
		return this.getLgmColumns('available');
	}

	get hedgeStrategy() {
		return this.model.HedgeStrategy.data?.HedgeStrategy;
	}
	get endorsementData() {
		return this.model.LgmInsuranceEndorsements.data?.LgmInsuranceEndorsements;
	}

	get totalNumEndorsements() {
		return this.model.LgmInsuranceEndorsements.data?.LgmInsuranceEndorsementCount.count;
	}

	get selectedLgmRows() {
		const data = this.model.HedgeStrategy?.data?.HedgeStrategy?.InsuranceEndorsements.filter(
			(endorsement) => endorsement.type === 'Lgm',
		) as LgmInsuranceEndorsementByCoverageMonth[];

		if (!data) return null;
		return this.buildLgmRows(data);
	}

	get availableLgmData() {
		const data = this.model.LgmInsuranceEndorsements?.data?.LgmInsuranceEndorsements?.filter((endorsement) => endorsement.type === 'Lgm');
		if (!data) return null;
		return this.buildLgmRows(data);
	}

	get modelLastUpdatedAt() {
		try {
			return DateTime.fromISO(this.model.lastUpdatedAt).toLocaleString(DateTime.DATETIME_FULL);
		} catch {
			return '';
		}
	}

	buildLgmRows(data: LgmInsuranceEndorsementByCoverageMonth[]) {
		const endorsements: Array<LgmInsuranceEndorsementByCoverageMonth> = [];

		data.map((endorsement: LgmInsuranceEndorsementByCoverageMonth) => {
			let lgmModelData: LgmInsuranceEndorsementByCoverageMonth | LgmInsuranceEndorsementModel | null = null;

			lgmModelData = new LgmInsuranceEndorsementModel(getOwner(this), endorsement, undefined);

			const { perMonthData, ...endorsementObj } = endorsement;
			//breaking endorsement up into separate row items based on coverage month and `perMonthData`. This is because the users expects to see the endorsement broken up by coverage month and there can be multiple months on a single LGM endorsement.

			const coverageMonthCount = endorsement?.coverageMonths?.length;

			const firstCoverageMonth =
				Array.isArray(endorsement.coverageMonths) && endorsement.coverageMonths?.length > 0 ? endorsement.coverageMonths[0] : null;

			const lastCoverageMonth =
				Array.isArray(endorsement?.coverageMonths) && endorsement.coverageMonths?.length > 1
					? endorsement.coverageMonths[endorsement.coverageMonths.length - 1]
					: null;

			const targetSum = endorsement?.perMonthData?.reduce((acc, { target }) => acc + (target ?? 0), 0);

			const cornSum = endorsement?.perMonthData?.reduce((acc, { corn }) => acc + (corn ?? 0), 0);

			const sbmSum = endorsement?.perMonthData?.reduce((acc, { sbm }) => acc + (sbm ?? 0), 0);

			const expectedFeedCostSum = endorsement?.perMonthData?.reduce((acc, { expectedFeedCost }) => acc + (expectedFeedCost ?? 0), 0);

			const expectedGrossMarginSum = endorsement?.perMonthData?.reduce(
				(acc, { expectedGrossMargin }) => acc + (expectedGrossMargin ?? 0),
				0,
			);

			const pnl = endorsement?.pnl;

			const isAdded = endorsementObj?.hedgeStrategyId === this.hedgeStrategyId;
			const newEndorsementObj = {
				...endorsementObj,
				isCollapsed: true,
				insuranceEndorsementId: endorsement?.id,
				target: targetSum,
				corn: cornSum,
				sbm: sbmSum,
				expectedFeedCost: expectedFeedCostSum,
				expectedGrossMargin: expectedGrossMarginSum,
				rmaTypeName: endorsementObj?.RmaType?.typeName,
				producerPremiumAmount: endorsement?.producerPremiumAmount,
				totalExpectedGrossMargin: endorsement?.totalExpectedGrossMargin,
				pnl,
				producerName: endorsement?.InsurancePolicy?.producerName,
				salesEffectiveDate: endorsement?.salesEffectiveDate,
				forecastedIndemnity: endorsement?.forecastedIndemnity,
				grossMarginGuarantee: endorsement?.grossMarginGuarantee,
				deductibleAmount: endorsement?.deductibleAmount,
				agentFullName: endorsement?.InsurancePolicy?.agentFullName,
				perMonthData: endorsement?.perMonthData,
				coverageMonth: `${
					firstCoverageMonth
						? intlDateTimeFormat({
								value: firstCoverageMonth,
								month: 'short',
								year: 'numeric',
							})
						: '-'
				} thru ${
					lastCoverageMonth
						? intlDateTimeFormat({
								value: lastCoverageMonth,
								month: 'short',
								year: 'numeric',
							})
						: '-'
				}`,
				lgmModelData: lgmModelData,
				isAdded,
			};
			const childrenArray: PerMonthData[] = [];
			if (coverageMonthCount) {
				for (let i = 0; i < coverageMonthCount; i++) {
					const child: PerMonthData | null | undefined = {};
					const { coverageMonths, perMonthData } = endorsement ? endorsement : { coverageMonths: [], perMonthData: [] };

					child.coverageMonth = coverageMonths?.length
						? intlDateTimeFormat({
								value: coverageMonths[i],
								month: 'short',
								year: 'numeric',
							})
						: null;
					child.monthIndex = perMonthData?.length ? perMonthData[i].monthIndex : null;
					child.target = perMonthData?.length ? perMonthData[i].target : null;
					child.corn = perMonthData?.length ? perMonthData[i].corn : null;
					child.expectedFeedCost = perMonthData?.length ? perMonthData[i].expectedFeedCost : null;
					child.expectedGrossMargin = perMonthData?.length ? perMonthData[i].expectedGrossMargin : null;
					child.sbm = perMonthData?.length ? perMonthData[i].sbm : null;

					childrenArray.push(child);
				}
			}

			newEndorsementObj.children = childrenArray;
			endorsements.push(newEndorsementObj);
		});
		return endorsements;
	}

	get salesEffectiveDateQueryParam() {
		return {
			startDate: this.salesEffectiveStartDate,
			endDate: this.salesEffectiveEndDate,
		};
	}

	get hedgeStrategyId() {
		return this.model.HedgeStrategy.data?.HedgeStrategy?.id;
	}

	get currentPage() {
		return this.page;
	}

	set currentPage(page) {
		this.page = page;
	}
	get searchFilterQueryParams() {
		const obj: {
			[param: string]: { [filterProperty: string]: string };
		} = {};
		const searchQueryParams: SearchFilterIdentifier[] = ['agent', 'aipId', 'customerId', 'stateId'];

		searchQueryParams.forEach((param) => {
			// if applicable setting filter for customerId for customer scope
			const value = param === 'customerId' && this.applicationScope.globalCustomerId ? this.applicationScope.globalCustomerId : this[param];

			if (value) {
				obj[param] = {
					filterRule: 'equals',
					filterValue: value,
				};

				// set filterComponent property to specify component for custom display extended from search-filter
				const filterDisplayObj = getFilterDisplayProperty(param);
				if (filterDisplayObj && filterDisplayObj.customComponent) {
					obj[param].filterComponent = filterDisplayObj.customComponent;
				}
				// set filterLabel property to specify custom label for filter - default would be filterIdentifier (matches queryParam)
				if (filterDisplayObj && filterDisplayObj.label) {
					obj[param].filterLabel = filterDisplayObj.label;
				}
			}
		});

		return obj;
	}

	@action
	setDateFilterQueryParam(queryParam: DateFilterQueryParamPrefix, value: { startDate: string; endDate: string }) {
		this[`${queryParam}StartDate`] = value.startDate;
		this[`${queryParam}EndDate`] = value.endDate;

		this.setTablePageState();
	}

	@action
	setTablePageState(newPageVal?: number) {
		this.currentPage = newPageVal ?? 0;
		resetVaultTableScroll('lgm-endorsement-table');
	}

	@action
	setSorts(sorts: SortObj[]) {
		this.sorts = sorts;
	}

	@action
	async fetchSearchResults(searchText: string) {
		let searchApiResults = [];
		const agentResults: searchResult[] = [];
		const stateResults: searchResult[] = [];

		// get fuzzy match agents
		const agentVariables = {
			where: {
				OR: [
					{ agentFirstName: { contains: searchText } },
					{ agentLastName: { contains: searchText } },
					{ agentFullName: { contains: searchText } },
				],
			},
		};
		const agentsArr = (
			(
				await this.apollo.watchQuery({
					query: agentFuzzyQuery,
					variables: agentVariables,
				})
			).InsurancePolicies as Agent[]
		).map((agent) => `${agent.agentFirstName} ${agent.agentLastName}`);

		const agentSet = new Set(agentsArr);

		for (const agent of agentSet) {
			const obj = {
				type: 'Agent',
				name: agent,
			};

			agentResults.push(obj);
		}

		// get fuzzy match state
		const stateVariables = {
			where: {
				OR: [{ abbreviation: { contains: searchText } }, { name: { contains: searchText } }, { id: { contains: searchText } }],
			},
		};

		const states = (
			await this.apollo.watchQuery({
				query: stateFuzzyQuery,
				variables: stateVariables,
			})
		).States as State[];

		states.forEach((state) => {
			const obj = {
				type: 'State',
				id: state.id,
				name: state.name,
			};
			stateResults.push(obj);
		});

		// get search API matches
		const variables = {
			query: searchText,
			typesToInclude: ['Aip'],
		};

		searchApiResults = (await this.apollo.watchQuery({ query: searchQuery, variables })).Search;

		// return combined set
		return [...agentResults, ...searchApiResults, ...stateResults];
	}

	@action
	structureSearchResults(searchResults: searchResult[]) {
		const map = new Map();

		searchResults.forEach((item) => {
			if (map.has(item.type)) {
				map.get(item.type).push({ id: item.id, name: item.name, type: item.type });
			} else {
				map.set(item.type, [{ id: item.id, name: item.name, type: item.type }]);
			}
		});

		return map;
	}

	@action
	setSearchFilterQueryParam(searchResult: searchResult) {
		const mappedSearchFilter = this.mapSearchResult(searchResult);
		if (mappedSearchFilter) {
			this[mappedSearchFilter.filterIdentifier] = mappedSearchFilter.filterValue;
		}
		this.setTablePageState();
	}

	mapSearchResult(searchResult: searchResult): { filterIdentifier: SearchFilterIdentifier; filterValue: string } | null {
		let filterIdentifier: SearchFilterIdentifier;

		switch (searchResult.type) {
			case 'Agent':
				filterIdentifier = 'agent';
				break;
			case 'Aip':
				filterIdentifier = 'aipId';
				break;
			case 'Customer':
				filterIdentifier = 'customerId';
				break;
			case 'State':
				filterIdentifier = 'stateId';
				break;
			default:
				return null;
		}

		if (searchResult.id || searchResult.name) {
			return {
				filterIdentifier,
				filterValue: (searchResult.id || searchResult.name) as string,
			};
		}

		return null;
	}
	get scopedSearchFilterQueryParams() {
		if (this.applicationScope.globalCustomerId) {
			const { customerId, ...rest } = this.searchFilterQueryParams;
			return rest;
		} else {
			return this.searchFilterQueryParams;
		}
	}

	@action
	clearSearchFilterQueryParam(filterIdentifier: string) {
		if (isSearchFilterIdentifier(filterIdentifier)) {
			this[filterIdentifier] = null;
			this.setTablePageState();
		}
	}

	@action
	async updateLgmEndorsements() {
		this.model.LgmInsuranceEndorsements.refetch();
	}
}
