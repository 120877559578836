import PowerSelect from 'ember-power-select/components/power-select';
import { addObserver, removeObserver } from '@ember/object/observers';
import { isNone } from '@ember/utils';
import { action } from '@ember/object';

const isPromiseLike = (thing) => {
	return typeof thing.then === 'function';
};

const isCancellablePromise = (thing) => {
	return typeof thing.cancel === 'function';
};

const isPromiseProxyLike = (thing) => {
	return isPromiseLike(thing) && Object.hasOwn(thing, 'content');
};

export default class EmberPowerSelectComponent extends PowerSelect {
	@action
	_performSearch() {
		let term = this.storedAPI.searchText;

		if (!this.args.search) return;

		this._searchResult = []; // clear existing values in _searchResult

		if (term === '') {
			this.loading = false;
			this.lastSearchedText = term;
			if (this._lastSearchPromise !== undefined) {
				if (isCancellablePromise(this._lastSearchPromise)) {
					this._lastSearchPromise.cancel(); // Cancel ember-concurrency tasks
				}
				this._lastSearchPromise = undefined;
			}
			return;
		}
		let searchResult = this.args.search(term, this.storedAPI);
		if (searchResult && isPromiseLike(searchResult)) {
			this.loading = true;
			if (this._lastSearchPromise !== undefined && isCancellablePromise(this._lastSearchPromise)) {
				this._lastSearchPromise.cancel(); // Cancel ember-concurrency tasks
			}
			this._lastSearchPromise = searchResult;
			searchResult
				.then((results) => {
					if (this._lastSearchPromise === searchResult) {
						this._searchResult = results;
						this.loading = false;
						this.lastSearchedText = term;
						this._resetHighlighted();
					}
				})
				.catch(() => {
					if (this._lastSearchPromise === searchResult) {
						this.loading = false;
						this.lastSearchedText = term;
					}
				});
		} else {
			this.lastSearchedText = term;
			this._searchResult = searchResult;
			this._resetHighlighted();
		}
	}

	@action
	__updateSelected() {
		// Overriding this method as there is an issue with ember-power-select not clearing the selection properly when the selection is a promise.
		// You can find more details about this issue here: https://github.com/cibernox/ember-power-select/issues/1467
		// The below adopted solution of overriding the _updateSelected method is proposed here: https://github.com/cibernox/ember-power-select/issues/1423
		// The hope is for this to be removed once ember-power-select adopts a solution to this issue
		if (isNone(this.args.selected)) {
			this._resolvedSelected = undefined;
			this._highlight(this.args.selected);
		} else if (typeof this.args.selected.then === 'function') {
			if (this._lastSelectedPromise === this.args.selected) return; // promise is still the same
			if (this._lastSelectedPromise && isPromiseProxyLike(this._lastSelectedPromise)) {
				removeObserver(this._lastSelectedPromise, 'content', this, this._selectedObserverCallback);
			}

			const currentSelectedPromise = this.args.selected;
			currentSelectedPromise.then(() => {
				if (this.isDestroyed || this.isDestroying) return;
				if (isPromiseProxyLike(currentSelectedPromise)) {
					// eslint-disable-next-line ember/no-observers
					addObserver(currentSelectedPromise, 'content', this, this._selectedObserverCallback);
				}
			});

			this._lastSelectedPromise = currentSelectedPromise;
			this._lastSelectedPromise.then((resolvedSelected) => {
				if (this._lastSelectedPromise === currentSelectedPromise) {
					this._resolvedSelected = resolvedSelected;
					this._highlight(resolvedSelected);
				}
			});
		} else {
			this._resolvedSelected = undefined;
			// Don't highlight args.selected array on multi-select
			if (!Array.isArray(this.args.selected)) {
				this._highlight(this.args.selected);
			}
		}
	}
}
