import { DateTime } from 'luxon';

export default function cropYearToFutureExpirationDates(slug: string, year: string): { startDate: string | null; endDate: string | null } {
	const cropYearStartDate = DateTime.fromISO(year).startOf('year');
	switch (slug) {
		case 'grain-corn':
			return {
				startDate: cropYearStartDate.plus({ months: 10 }).toISODate(),
				endDate: cropYearStartDate.plus({ year: 1, month: 8 }).endOf('month').toISODate(),
			};
		case 'grain-soybeans':
			return {
				startDate: cropYearStartDate.plus({ months: 10 }).toISODate(),
				endDate: cropYearStartDate.plus({ year: 1, month: 8 }).endOf('month').toISODate(),
			};
		case 'grain-chicago-soft-red-winter-wheat':
			return {
				startDate: cropYearStartDate.plus({ months: 6 }).toISODate(),
				endDate: cropYearStartDate.plus({ year: 1, month: 5 }).endOf('month').toISODate(),
			};
		case 'grain-hard-red-spring-wheat':
			return {
				startDate: cropYearStartDate.plus({ months: 8 }).toISODate(),
				endDate: cropYearStartDate.plus({ year: 1, month: 7 }).endOf('month').toISODate(),
			};
		default:
			return {
				startDate: null,
				endDate: null,
			};
	}
}
