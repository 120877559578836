import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<PowerSelect\n\t@selected={{this.selectedItem}}\n\t@searchEnabled={{true}}\n\t@search={{this.fetchItems}}\n\t@allowClear={{false}}\n\t@searchField='name'\n\t@searchPlaceholder='Find county...'\n\t@onChange={{this.setItem}}\n\t@renderInPlace={{@renderInPlace}}\n\t@placeholder={{@placeholder}}\n\t...attributes\n\tas |item|\n>\n\t{{item.name}}\n\t{{#if item.State}}({{item.State.name}}){{/if}}\n</PowerSelect>", {"contents":"<PowerSelect\n\t@selected={{this.selectedItem}}\n\t@searchEnabled={{true}}\n\t@search={{this.fetchItems}}\n\t@allowClear={{false}}\n\t@searchField='name'\n\t@searchPlaceholder='Find county...'\n\t@onChange={{this.setItem}}\n\t@renderInPlace={{@renderInPlace}}\n\t@placeholder={{@placeholder}}\n\t...attributes\n\tas |item|\n>\n\t{{item.name}}\n\t{{#if item.State}}({{item.State.name}}){{/if}}\n</PowerSelect>","moduleName":"vault-client/components/county-select.hbs","parseOptions":{"srcName":"vault-client/components/county-select.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { queryManager } from 'ember-apollo-client';
import query from 'vault-client/graphql/queries/search.graphql';
import { County } from 'vault-client/types/graphql-types';

interface CountySelectArgs {
	selected: County;
	// eslint-disable-next-line no-unused-vars
	onChange: (item: County) => {};
}

export default class CountySelect extends Component<CountySelectArgs> {
	@queryManager apollo: any;

	get selectedItem() {
		return this.args.selected ? this.args.selected : null;
	}

	@action
	async fetchItems(searchText: string) {
		const variables = {
			query: searchText,
			limit: 20,
			typesToInclude: ['County'],
		};
		return (await this.apollo.watchQuery({ query, variables })).Search;
	}

	@action
	setItem(county: County) {
		this.args.onChange(county);
	}
}
