import Route from '@ember/routing/route';
import { gql, useQuery } from 'glimmer-apollo';
import { tracked } from '@glimmer/tracking';
import { GrainCropPlanFilterDTO, GrainFeedPlanFilterDTO, FeedCategory, Query } from 'vault-client/types/graphql-types';

const GET_CUSTOMER_VGS = gql`
	query customerVGS($id: String!, $cropWhere: GrainCropPlanFilterDTO, $feedWhere: GrainFeedPlanFilterDTO) {
		Customer(id: $id) {
			id
			name
			organizationId
			hasWriteAccess
		}
		GrainCropPlans(scopeId: $id, orderBy: { CropYear: { year: Asc } }, where: $cropWhere) {
			id
			type
			acres
			aph
			enrolledPercent
			harvestBu
			storageBu
			breakEven
			goal
			Crop {
				id
				name
			}
			CropYear {
				id
				year
			}
			Orders {
				id
				type
				... on GrainFillOrder {
					bushels
					salesType
					futurePrice
					fees
					spread
					flatPrice
					basis
				}
			}
		}
		GrainFeedPlans(scopeId: $id, orderBy: { startDate: Asc }, where: $feedWhere) {
			id
			totalTonsFlatPriced
			basisOnlyPurchased
			basisExposure
			futuresOnlyPurchased
			futuresExposure
			breakEven
			type
			goal
			feedCategoryId
			startDate
			tons
			feedYear
			Customer {
				id
				name
				RelationshipOwner {
					id
					email
					firstName
					lastName
				}
			}
			FeedCategory {
				id
				name
			}
			Orders {
				id
				type
				... on GrainFillOrder {
					bushels
					salesType
					futurePrice
					fees
					spread
					flatPrice
					basis
				}
			}
		}
		FeedCategories(orderBy: { name: Asc }) {
			id
			name
		}
		CropCategories(orderBy: { name: Asc }) {
			id
			name
		}
	}
`;

interface QueryParams {
	id: string;
}

interface CustomerQuery {
	Customer: Query['Customer'];
	GrainCropPlans: Query['GrainCropPlans'];
	GrainFeedPlans: Query['GrainFeedPlans'];
	FeedCategories: FeedCategory[];
	CropCategories: Query['CropCategories'];
}

interface CustomersQuery_Args {
	id: String;
	cropWhere: GrainCropPlanFilterDTO;
	feedWhere: GrainFeedPlanFilterDTO;
}

interface QueryParams {
	selectedFeedCategoryIds: string[];
	selectedCropIds: string[];
	month: string | null;
	cropYear: string | null;
	feedYear: string | null;
}

export default class VgsCustomersShowRoute extends Route {
	@tracked variables: CustomersQuery_Args = {
		id: '',
		cropWhere: {},
		feedWhere: {},
	};

	queryParams = {
		month: { refreshModel: true },
		cropYear: { refreshModel: true },
		feedYear: { refreshModel: true },
		selectedFeedCategoryIds: { refreshModel: true },
		selectedCropIds: { refreshModel: true },
	};

	getCustomerProduction = useQuery<CustomerQuery, CustomersQuery_Args>(this, () => [
		GET_CUSTOMER_VGS,
		{ variables: this.variables, pollInterval: 10000 },
	]);

	generateCropWhere(params: QueryParams, customerId: string | null): GrainCropPlanFilterDTO {
		const where: GrainCropPlanFilterDTO = {
			customerId: {
				equals: customerId,
			},
		};

		if (params.cropYear) {
			where.CropYear = {
				year: {
					equals: parseInt(params.cropYear),
				},
			};
		}
		if (params.selectedCropIds?.length) {
			where.CropCategory = {
				id: {
					in: params.selectedCropIds,
				},
			};
		}

		return where;
	}

	generateFeedWhere(params: QueryParams, customerId: string | null): GrainFeedPlanFilterDTO {
		const where: GrainFeedPlanFilterDTO = {
			customerId: {
				equals: customerId,
			},
		};

		if (params.selectedFeedCategoryIds?.length) {
			where.FeedCategory = {
				id: {
					in: params.selectedFeedCategoryIds,
				},
			};
		}

		if (params.feedYear) {
			where.feedYear = {
				equals: parseInt(params.feedYear),
			};
		}

		if (params.month) {
			where.startDateMonth = {
				equals: parseInt(params.month),
			};
		}

		return where;
	}

	async model(params: QueryParams) {
		this.variables = {
			id: params.id,
			feedWhere: this.generateFeedWhere(params, params.id),
			cropWhere: this.generateCropWhere(params, params.id),
		};

		await this.getCustomerProduction.promise;

		return { getCustomers: this.getCustomerProduction, variables: this.variables };
	}
}
