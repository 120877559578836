import { getOwner } from '@ember/application';
import Route from '@ember/routing/route';
import Transition from '@ember/routing/transition';
import { service } from '@ember/service';
import { gql, useQuery } from 'glimmer-apollo';
import { DateTime } from 'luxon';
import { tracked, TrackedArray } from 'tracked-built-ins';
import ReportsPriceScenariosController from 'vault-client/controllers/reports/price-scenarios';
import HypotheticalPosition, { HypotheticalPositionJsonFormat } from 'vault-client/models/hypothetical-position';
import ScenarioPricesService from 'vault-client/services/scenario-prices';
import {
	Query,
	TypeOfInstrument,
	FutureFilterDTO,
	DerivedDrpEndorsementFilterDTO,
	LedgerEntryFilterDTO,
	Swap,
	Future,
	Swaption,
	TypeOfLedgerCategory,
	TypeOfLedgerEntry,
	ForecastedMilkProductionByMonthFilterDTO,
	CurrentAllocationPositionFilterDTO,
	CurrentAllocationPosition,
	Option,
	InsuranceEndorsementAllocationRatioFilterDTO,
} from 'vault-client/types/graphql-types';
import checkStorageAvailable from 'vault-client/utils/check-storage-available';
import { ModelFrom } from 'vault-client/utils/type-utils';
import BusinessesBusinessPriceScenarios from '../businesses/business/price-scenarios';
import OrganizationsOrganizationPriceScenarios from '../organizations/organization/price-scenarios';
import { calculateRealizedPnlAndUnrealizedComponents } from 'vault-client/utils/price-scenarios-utils';

const GET_PRICE_SCENARIOS_DATA = gql`
	query PriceScenarios(
		$forecastedMilkProductionByMonths: ForecastedMilkProductionByMonthFilterDTO
		$futuresWhere: FutureFilterDTO
		$revenuesAndExpensesWhere: LedgerEntryFilterDTO
		$premiumsAndDeductionsWhere: LedgerEntryFilterDTO
		$allocatedFuturePositionsWhere: CurrentAllocationPositionFilterDTO
		$allocatedOptionPositionsWhere: CurrentAllocationPositionFilterDTO
		$allocatedSwapPositionsWhere: CurrentAllocationPositionFilterDTO
		$allocatedSwaptionPositionsWhere: CurrentAllocationPositionFilterDTO
		$allocatedDrpEndorsementsWhere: InsuranceEndorsementAllocationRatioFilterDTO
		$scopeId: String
	) {
		ForecastedMilkProductionByMonths(where: $forecastedMilkProductionByMonths, scopeId: $scopeId, orderBy: { date: Asc }) {
			id
			date
			grossProduction
			grossOtherSolidsProduction
			grossButterfatProduction
			grossProteinProduction
			grossOtherSolidsProduction
			grossClassiProduction
			grossClassiiProduction
			grossClassiiiProduction
			grossClassivProduction
			classiUtilization
			classiiUtilization
			classiiiUtilization
			classivUtilization
			Entity {
				id
				type
				... on LocationEntity {
					currentBasis
					County {
						id
						classIDifferential
					}
				}
			}
		}
		Futures(where: $futuresWhere, orderBy: { displayExpiresAt: Asc }) {
			id
			displayExpiresAt
			barchartSymbol
			SymbolGroup {
				id
				fractionDigits
				displayFactor
			}
			Product {
				id
				name
				slug
				optionsUnitValue
				StandardProductLotSpecification {
					id
					lotSize
					pointValue
				}
			}
		}
		AllocatedFuturePositions: CurrentAllocationPositions(
			where: $allocatedFuturePositionsWhere
			limit: 2000
			orderBy: { effectiveHedgeDate: Asc }
		) {
			id
			effectiveHedgeDate
			contractQuantity
			instrumentType
			Product {
				id
				slug
				optionsUnitValue
				ProductLotSpecifications {
					id
					lotSize
					pointValue
				}
			}
			Instrument {
				... on Future {
					id
					displayExpiresAt
					barchartSymbol
					productLotSpecificationId
				}
			}
			PositionComponentAllocations {
				id
				netContractQuantity
				longContractQuantity
				shortContractQuantity
				price
				effectiveHedgeDate
			}
		}
		AllocatedOptionPositions: CurrentAllocationPositions(
			where: $allocatedOptionPositionsWhere
			limit: 2000
			orderBy: { effectiveHedgeDate: Asc }
		) {
			id
			effectiveHedgeDate
			contractQuantity
			instrumentType
			Product {
				id
				slug
				ProductLotSpecifications {
					id
					lotSize
					pointValue
				}
			}
			Instrument {
				... on Option {
					id
					productLotSpecificationId
					optionType
					strike
					UnderlyingInstrument {
						id
						displayExpiresAt
						barchartSymbol
					}
				}
			}
			PositionComponentAllocations {
				id
				netContractQuantity
				longContractQuantity
				shortContractQuantity
				price
				effectiveHedgeDate
			}
		}
		AllocatedSwapPositions: CurrentAllocationPositions(
			where: $allocatedSwapPositionsWhere
			limit: 2000
			orderBy: { effectiveHedgeDate: Asc }
		) {
			id
			effectiveHedgeDate
			contractQuantity
			instrumentType
			Product {
				id
				slug
				ProductLotSpecifications {
					id
					lotSize
					pointValue
				}
			}
			Instrument {
				... on Swap {
					id
					productLotSpecificationId
					PriceInstrument {
						... on Future {
							id
							displayExpiresAt
							barchartSymbol
						}
					}
				}
			}
			PositionComponentAllocations {
				id
				netContractQuantity
				longContractQuantity
				shortContractQuantity
				price
				effectiveHedgeDate
			}
		}
		AllocatedSwaptionPositions: CurrentAllocationPositions(
			where: $allocatedSwaptionPositionsWhere
			limit: 2000
			orderBy: { effectiveHedgeDate: Asc }
		) {
			id
			effectiveHedgeDate
			contractQuantity
			instrumentType
			Product {
				id
				slug
				ProductLotSpecifications {
					id
					lotSize
					pointValue
				}
			}
			Instrument {
				... on Swaption {
					id
					optionType
					strike
					productLotSpecificationId
					PriceInstrument {
						... on Future {
							id
							displayExpiresAt
							barchartSymbol
						}
					}
				}
			}
			PositionComponentAllocations {
				id
				netContractQuantity
				longContractQuantity
				shortContractQuantity
				price
				effectiveHedgeDate
			}
		}
		AllocatedDrpEndorsements: InsuranceEndorsementAllocationRatios(
			scopeId: $scopeId
			orderBy: { effectiveHedgeDate: Asc }
			where: $allocatedDrpEndorsementsWhere
			limit: 2000
		) {
			id
			insuranceEndorsementId
			allocationRatio
			createdAt
			effectiveHedgeDate
			hasWriteAccess
			insurancePolicyId
			updatedAt
			RatioAdjustedDerivedDrpInsuranceEndorsement {
				id
				quarterStartDate
				quarterEndDate
				expectedRevenueGuarantee
				effectiveCoveredMilkProduction
				declaredCoveredMilkProduction
				yieldAdjustmentFactor
				isLong
				classPriceWeightingFactor
				componentPriceWeightingFactor
				declaredButterfatTest
				declaredProteinTest
				producerPremiumAmount
				totalPremiumAmount
				protectionFactor
				indemnity
				pnl
			}
		}
		MonthlyRevenuesAndExpenses: AggregateLedgerEntries(
			calc: { sum: { calculatedAmount: true } }
			groupBy: { month: true, year: true }
			where: $revenuesAndExpensesWhere
			scopeId: $scopeId
		) {
			sum {
				calculatedAmount
			}
			month
			year
		}
		MonthlyPremiumsAndDeductions: AggregateLedgerEntries(
			calc: { sum: { calculatedAmount: true } }
			groupBy: { month: true, year: true }
			where: $premiumsAndDeductionsWhere
			scopeId: $scopeId
		) {
			sum {
				calculatedAmount
			}
			month
			year
		}
	}
`;

type GetPriceScenariosDataQuery = {
	__typename?: 'Query';
	ForecastedMilkProductionByMonths: Query['ForecastedMilkProductionByMonths'];
	Futures: Query['Futures'];
	AllocatedFuturePositions: Query['CurrentAllocationPositions'];
	AllocatedOptionPositions: Query['CurrentAllocationPositions'];
	AllocatedSwapPositions: Query['CurrentAllocationPositions'];
	AllocatedSwaptionPositions: Query['CurrentAllocationPositions'];
	CurrentDRPEndorsementsPnl: Query['AggregateDerivedDrpEndorsements'];
	AggregateDerivedDrpEndorsements: Query['AggregateDerivedDrpEndorsements'];
	MonthlyRevenuesAndExpenses: Query['AggregateLedgerEntries'];
	MonthlyPremiumsAndDeductions: Query['AggregateLedgerEntries'];
	AllocatedDrpEndorsements: Query['InsuranceEndorsementAllocationRatios'];
};

type GetPriceScenariosDataQueryVariables = {
	forecastedMilkProductionByMonths?: ForecastedMilkProductionByMonthFilterDTO;
	futuresWhere?: FutureFilterDTO;
	endorsementsWhere?: DerivedDrpEndorsementFilterDTO;
	revenuesAndExpensesWhere?: LedgerEntryFilterDTO;
	premiumsAndDeductionsWhere?: LedgerEntryFilterDTO;
	allocatedFuturePositionsWhere?: CurrentAllocationPositionFilterDTO;
	allocatedOptionPositionsWhere?: CurrentAllocationPositionFilterDTO;
	allocatedSwapPositionsWhere?: CurrentAllocationPositionFilterDTO;
	allocatedSwaptionPositionsWhere?: CurrentAllocationPositionFilterDTO;
	allocatedDrpEndorsementsWhere?: InsuranceEndorsementAllocationRatioFilterDTO;
	scopeId?: string;
};

export default class ReportsPriceScenariosRoute extends Route {
	@service declare scenarioPrices: ScenarioPricesService;
	@tracked variables: GetPriceScenariosDataQueryVariables = {};

	getPriceScenariosData = useQuery<GetPriceScenariosDataQuery, GetPriceScenariosDataQueryVariables>(this, () => [
		GET_PRICE_SCENARIOS_DATA,
		{ variables: this.variables },
	]);

	queryParams = {};

	templateName = 'reports/price-scenarios';

	async setupController(
		controller: ReportsPriceScenariosController,
		model: ModelFrom<OrganizationsOrganizationPriceScenarios> | ModelFrom<BusinessesBusinessPriceScenarios>,
		transition: Transition
	) {
		super.setupController(controller, model, transition);

		const barchartSymbols = model.getPriceScenariosData.data?.Futures.map((future) => future.barchartSymbol ?? null) ?? [];
		const filteredBarchartSymbols = barchartSymbols.filter((symbol): symbol is string => typeof symbol === 'string');

		const currentPositions = this.createCurrentPositions(
			model.getPriceScenariosData.data?.AllocatedFuturePositions ?? [],
			model.getPriceScenariosData.data?.AllocatedOptionPositions ?? [],
			model.getPriceScenariosData.data?.AllocatedSwapPositions ?? [],
			model.getPriceScenariosData.data?.AllocatedSwaptionPositions ?? []
		);
		controller._currentPositions = currentPositions;

		// With allocations, it is possible for us to base pricing on a future that is outside of the date range of our scenario.
		// To ensure that we have all the necessary data, we will fetch the prices for all the symbols in our current positions.
		const currentPositionsBarchartSymbols = currentPositions
			.map((position) => position.barchartSymbol)
			.filter((symbol): symbol is string => typeof symbol === 'string');

		const uniqueBarchartSymbols = [...new Set([...filteredBarchartSymbols, ...currentPositionsBarchartSymbols])];
		await this.scenarioPrices.addInstruments(uniqueBarchartSymbols);

		// TODO: This is a temporary migration to ensure that lot sizes are set for all hypothetical positions. Can be removed after a while
		// - Preston Cobb, May 6 2024
		const lotSizes =
			model.getPriceScenariosData.data?.Futures?.reduce((acc, future) => {
				const slug = future.Product.slug;

				if (!acc[slug]) {
					const lotSize = future.Product.StandardProductLotSpecification?.lotSize ?? 0;
					acc[slug] = lotSize;
				}

				return acc;
			}, {} as Record<string, number>) ?? {};

		this.retrieveAddedHypotheticalPositionsFromStorage(model.entityId, controller._addedHypotheticalPositions, lotSizes);
	}

	createCurrentPositions(
		allocatedFuturePositions: CurrentAllocationPosition[],
		allocatedOptionPositions: CurrentAllocationPosition[],
		allocatedSwapPositions: CurrentAllocationPosition[],
		allocatedSwaptionPositions: CurrentAllocationPosition[]
	) {
		const currentPositions: HypotheticalPosition[] = new TrackedArray([]);

		const addPosition = (obj: HypotheticalPositionJsonFormat) => {
			currentPositions.push(HypotheticalPosition.fromJson(obj, getOwner(this)));
		};

		allocatedFuturePositions.forEach((position) => {
			const date = position.effectiveHedgeDate;
			const instrument = position.Instrument as Future;
			const components = position.PositionComponentAllocations;
			const productLotSpecificationId = instrument.productLotSpecificationId;
			const ProductLotSpecification = position.Product.ProductLotSpecifications.find((spec) => spec.id === productLotSpecificationId);
			const pointValue = ProductLotSpecification?.pointValue ?? 0;
			const lotSize = ProductLotSpecification?.lotSize ?? 0;
			const instrumentType = TypeOfInstrument.Future;
			const optionType = null;
			const strike = null;
			const futureDisplayExpiresAt = instrument?.displayExpiresAt;
			const barchartSymbol = instrument?.barchartSymbol;
			const productSlug = position.Product.slug;
			const { realizedPl, unrealizedAvgPrice, unrealizedTotalContracts } = calculateRealizedPnlAndUnrealizedComponents(
				components,
				pointValue
			);

			if (!date || !futureDisplayExpiresAt || !productSlug || !barchartSymbol) {
				console.error(`Not all data could be retrieved for: ` + JSON.stringify(position));
				return;
			}

			addPosition({
				date,
				futureDisplayExpiresAt,
				productSlug,
				instrumentType,
				barchartSymbol,
				pointValue,
				lotSize,
				quantity: unrealizedTotalContracts,
				price: unrealizedAvgPrice,
				commissionAndFeeTotal: 0,
				realizedPnl: realizedPl,
				optionType,
				strike,
			});
		});

		allocatedOptionPositions.forEach((position) => {
			const date = position.effectiveHedgeDate;
			const instrument = position.Instrument as Option;
			const components = position.PositionComponentAllocations;
			const productLotSpecificationId = instrument.productLotSpecificationId;
			const ProductLotSpecification = position.Product.ProductLotSpecifications.find((spec) => spec.id === productLotSpecificationId);
			const pointValue = ProductLotSpecification?.pointValue ?? 0;
			const lotSize = ProductLotSpecification?.lotSize ?? 0;
			const instrumentType = TypeOfInstrument.Option;
			const optionType = instrument.optionType;
			const strike = instrument.strike;
			const futureDisplayExpiresAt = instrument?.UnderlyingInstrument?.displayExpiresAt;
			const barchartSymbol = instrument?.UnderlyingInstrument?.barchartSymbol;
			const productSlug = position.Product.slug;

			const { realizedPl, unrealizedAvgPrice, unrealizedTotalContracts } = calculateRealizedPnlAndUnrealizedComponents(
				components,
				pointValue
			);

			if (!date || !futureDisplayExpiresAt || !productSlug || !barchartSymbol) {
				console.error(`Not all data could be retrieved for: ` + JSON.stringify(position));
				return;
			}
			addPosition({
				date,
				futureDisplayExpiresAt,
				productSlug,
				instrumentType,
				barchartSymbol,
				pointValue,
				lotSize,
				quantity: unrealizedTotalContracts,
				price: unrealizedAvgPrice,
				commissionAndFeeTotal: 0,
				realizedPnl: realizedPl,
				optionType,
				strike,
			});
		});

		allocatedSwapPositions.forEach((position) => {
			const instrumentType = TypeOfInstrument.Swap;
			const instrument = position.Instrument as Swap;
			const components = position.PositionComponentAllocations;
			const optionType = null;
			const strike = null;
			const date = position.effectiveHedgeDate;
			const futureDisplayExpiresAt = (instrument.PriceInstrument as Future | null)?.displayExpiresAt;
			const productSlug = position.Product.slug;
			const productLotSpecificationId = instrument.productLotSpecificationId;
			const ProductLotSpecification = position.Product.ProductLotSpecifications.find((spec) => spec.id === productLotSpecificationId);
			const pointValue = ProductLotSpecification?.pointValue ?? 0;
			const lotSize = ProductLotSpecification?.lotSize ?? 0;
			const barchartSymbol = ((position.Instrument as Swap)?.PriceInstrument as Future | null)?.barchartSymbol;

			if (!pointValue || !date || !futureDisplayExpiresAt || !productSlug || !barchartSymbol) {
				console.error(`Not all data could be retrieved for Swap: ` + JSON.stringify(position));
				return;
			}

			const { realizedPl, unrealizedAvgPrice, unrealizedTotalContracts } = calculateRealizedPnlAndUnrealizedComponents(
				components,
				pointValue
			);

			addPosition({
				date,
				futureDisplayExpiresAt,
				productSlug,
				instrumentType,
				barchartSymbol,
				pointValue,
				lotSize,
				quantity: unrealizedTotalContracts,
				price: unrealizedAvgPrice,
				commissionAndFeeTotal: 0,
				realizedPnl: realizedPl,
				optionType,
				strike,
			});
		});

		allocatedSwaptionPositions.forEach((position) => {
			const instrumentType = position.instrumentType;
			const instrument = position.Instrument as Swaption;
			const components = position.PositionComponentAllocations;
			const optionType = position.optionType;
			const strike = (position.Instrument as Swaption).strike;
			const date = position.effectiveHedgeDate;
			const futureDisplayExpiresAt = (instrument.PriceInstrument as Future | null)?.displayExpiresAt;
			const productSlug = position.Product.slug;
			const productLotSpecificationId = instrument.productLotSpecificationId;
			const ProductLotSpecification = position.Product.ProductLotSpecifications.find((spec) => spec.id === productLotSpecificationId);
			const pointValue = ProductLotSpecification?.pointValue ?? 0;
			const lotSize = ProductLotSpecification?.lotSize ?? 0;
			const barchartSymbol = ((position.Instrument as Swaption)?.PriceInstrument as Future | null)?.barchartSymbol;

			if (!date || !optionType || !strike || !futureDisplayExpiresAt || !productSlug || !barchartSymbol) {
				console.error(`Not all data could be retrieved for Swaption: ` + JSON.stringify(position));
				return;
			}

			const { realizedPl, unrealizedAvgPrice, unrealizedTotalContracts } = calculateRealizedPnlAndUnrealizedComponents(
				components,
				pointValue
			);

			addPosition({
				date,
				futureDisplayExpiresAt,
				productSlug,
				instrumentType,
				barchartSymbol,
				pointValue,
				lotSize,
				quantity: unrealizedTotalContracts,
				price: unrealizedAvgPrice,
				commissionAndFeeTotal: 0,
				realizedPnl: realizedPl,
				optionType,
				strike,
			});
		});

		return currentPositions;
	}

	retrieveAddedHypotheticalPositionsFromStorage(
		entityId: string | null,
		hypotheticalPositions: HypotheticalPosition[],
		lotSizes?: Record<string, number>
	): HypotheticalPosition[] {
		if (!entityId || !checkStorageAvailable('localStorage')) return hypotheticalPositions;

		const localStorageString = `price-scenarios-hypothetical-positions.${entityId}`;

		const temp = window.localStorage.getItem(localStorageString);
		if (!temp) return hypotheticalPositions;

		let migrated = false;

		const hypotheticalPositionObjects = JSON.parse(temp) as HypotheticalPositionJsonFormat[];

		hypotheticalPositionObjects.forEach((obj: HypotheticalPositionJsonFormat) => {
			if (obj) {
				if (lotSizes && obj.lotSize == null) {
					// TODO: This is a temporary migration to ensure that lot sizes are set for all hypothetical positions. Can be removed after a while
					// - Preston Cobb, May 6 2024
					obj.lotSize = lotSizes[obj.productSlug];
					migrated = true;
				}
				hypotheticalPositions.push(HypotheticalPosition.fromJson(obj, getOwner(this)));
			}
		});

		if (migrated) {
			// TODO: Migration logic, can be removed after a while
			window.localStorage.setItem(localStorageString, JSON.stringify(hypotheticalPositions.map((pos) => pos.toJson())));
		}

		return hypotheticalPositions;
	}

	generateFuturesWhere(startDate: string, endDate: string): FutureFilterDTO {
		// Fetch extended futures to ensure the underlying for all options and advanced price instruments are retrieved
		const futuresStartEndDate = DateTime.fromISO(startDate).minus({ month: 1 }).startOf('month').toISODate();
		const futuresEndDate = DateTime.fromISO(endDate).plus({ months: 13 }).startOf('month').toISODate();
		return {
			AND: [
				{ displayExpiresAt: { gte: futuresStartEndDate } },
				{ displayExpiresAt: { lte: futuresEndDate } },
				{ isStandardContractSize: { equals: true } },
			],
			Product: {
				slug: {
					in: [
						'us-dairy-butter',
						'us-dairy-nonfat-milk',
						'us-dairy-cheese',
						'us-dairy-dry-whey',
						'us-dairy-class-iii',
						'us-dairy-class-iv',
					],
				},
			},
			isStandardContractSize: { equals: true },
		};
	}

	generateForecastedMilkProductionByMonthWhere(startDate: string, endDate: string): ForecastedMilkProductionByMonthFilterDTO {
		const where: ForecastedMilkProductionByMonthFilterDTO = {};
		where.AND = [
			{
				date: {
					gte: startDate,
				},
			},
			{
				date: {
					lte: endDate,
				},
			},
		];

		return where;
	}

	generateAllocatedFuturePositionsWhere(
		startDate: string | null,
		endDate: string | null,
		entityId: string
	): CurrentAllocationPositionFilterDTO {
		const where = this.generateAllocatedPositionsWhere(startDate, endDate, entityId);
		where.instrumentType = { equals: TypeOfInstrument.Future };
		return where;
	}

	generateAllocatedOptionPositionsWhere(
		startDate: string | null,
		endDate: string | null,
		entityId: string
	): CurrentAllocationPositionFilterDTO {
		const where = this.generateAllocatedPositionsWhere(startDate, endDate, entityId);
		where.instrumentType = { equals: TypeOfInstrument.Option };
		return where;
	}

	generateAllocatedSwapPositionsWhere(
		startDate: string | null,
		endDate: string | null,
		entityId: string
	): CurrentAllocationPositionFilterDTO {
		const where = this.generateAllocatedPositionsWhere(startDate, endDate, entityId);
		where.instrumentType = { equals: TypeOfInstrument.Swap };
		return where;
	}

	generateAllocatedSwaptionPositionsWhere(
		startDate: string | null,
		endDate: string | null,
		entityId: string
	): CurrentAllocationPositionFilterDTO {
		const where = this.generateAllocatedPositionsWhere(startDate, endDate, entityId);
		where.instrumentType = { equals: TypeOfInstrument.Swaption };
		return where;
	}

	generateAllocatedPositionsWhere(startDate: string | null, endDate: string | null, entityId: string): CurrentAllocationPositionFilterDTO {
		const where: CurrentAllocationPositionFilterDTO = {
			entityId: {
				equals: entityId,
			},
			effectiveHedgeDate: {
				gte: startDate,
				lte: endDate,
			},
			Product: {
				slug: {
					in: [
						'us-dairy-butter',
						'us-dairy-nonfat-milk',
						'us-dairy-cheese',
						'us-dairy-dry-whey',
						'us-dairy-class-iii',
						'us-dairy-class-iv',
					],
				},
			},
		};

		return where;
	}

	generateEndorsementsWhere(startDate: string, endDate: string): DerivedDrpEndorsementFilterDTO {
		const where: DerivedDrpEndorsementFilterDTO = {};

		where.OR = [
			{
				// Endorsement starts within range
				quarterStartDate: {
					gte: startDate,
					lte: endDate,
				},
			},
			{
				// Endorsement ends within range
				quarterEndDate: {
					gte: startDate,
					lte: endDate,
				},
			},
			{
				// Endorsement spans range
				quarterStartDate: {
					lte: startDate,
				},
				quarterEndDate: {
					gte: endDate,
				},
			},
		];

		return where;
	}

	generateAllocatedDrpEndorsementsWhere(startDate: string | null, endDate: string | null): InsuranceEndorsementAllocationRatioFilterDTO {
		return {
			InsuranceEndorsement: {
				// Only return DRP endorsements
				AsDrpInsuranceEndorsement: {},
			},
			effectiveHedgeDate: {
				gte: startDate,
				lte: endDate,
			},
		};
	}

	generateRevenuesAndExpensesWhere(startDate: string | null, endDate: string | null): LedgerEntryFilterDTO {
		const where: LedgerEntryFilterDTO = {
			type: {
				equals: TypeOfLedgerEntry.Forecasted,
			},
			LedgerCategory: {
				type: {
					in: [TypeOfLedgerCategory.Revenue, TypeOfLedgerCategory.Expense],
				},
			},
		};

		if (startDate) {
			if (!where.AND) where.AND = [];
			where.AND.push({
				date: {
					gte: startDate,
				},
			});
		}

		if (endDate) {
			if (!where.AND) where.AND = [];
			where.AND.push({
				date: {
					lte: endDate,
				},
			});
		}

		return where;
	}

	generatePremiumsAndDeductionsWhere(startDate: string | null, endDate: string | null): LedgerEntryFilterDTO {
		const where: LedgerEntryFilterDTO = {
			type: {
				equals: TypeOfLedgerEntry.Forecasted,
			},
			LedgerCategory: {
				type: {
					equals: TypeOfLedgerCategory.MilkCheck,
				},
			},
		};

		if (startDate) {
			if (!where.AND) where.AND = [];
			where.AND.push({
				date: {
					gte: startDate,
				},
			});
		}

		if (endDate) {
			if (!where.AND) where.AND = [];
			where.AND.push({
				date: {
					lte: endDate,
				},
			});
		}

		return where;
	}
}
