import Route from '@ember/routing/route';
import { tracked } from '@glimmer/tracking';
import { DateTime } from 'luxon';
import { useQuery, gql } from 'glimmer-apollo';
import {
	SwineLivestockPopulationForecastByWeekFilterDTO,
	SwineLivestockPopulationForecastByWeekSortByDTO,
	SwineLivestockPopulationForecastByWeek,
} from 'vault-client/types/graphql-types';

const GET_PIG_COHORT = gql`
	query SwineLivestockPopulationForecastsByWeek($where: SwineLivestockPopulationForecastByWeekFilterDTO) {
		SwineLivestockPopulationForecastsByWeek(where: $where, orderBy: { ageInWeeks: Asc }) {
			updatedAt
			startQuantity
			readableWeek
			livestockGroupId
			id
			exactStartQuantity
			exactEndQuantity
			endQuantity
			dob
			date
			businessId
			ageInWeeks
			PopulationChanges {
				week
				valueType
				valuePerHead
				updatedAt
				type
				totalValue
				reasonType
				reason
				readableWeek
				readableDobWeek
				quantity
				marketingWeekStartDate
				id
				exactTotalValue
				exactQuantity
				dobWeek
				dob
				date
				businessId
			}
		}
	}
`;

type GetSwineLivestockPopulationForecastByWeekQueryVariables = {
	where?: SwineLivestockPopulationForecastByWeekFilterDTO;
	sortBy?: SwineLivestockPopulationForecastByWeekSortByDTO;
	businessId?: string;
};

type SwineLivestockPopulationForecastsByWeek = {
	SwineLivestockPopulationForecastsByWeek: Array<SwineLivestockPopulationForecastByWeek>;
};

interface modelParams {
	birth_week_start_date: string;
}

export default class BusinessesBusinessPigCohort extends Route {
	@tracked pigCohortVariables: GetSwineLivestockPopulationForecastByWeekQueryVariables = { businessId: '' };

	getPigCohort = useQuery<SwineLivestockPopulationForecastsByWeek, GetSwineLivestockPopulationForecastByWeekQueryVariables>(this, () => [
		GET_PIG_COHORT,
		{ variables: this.pigCohortVariables },
	]);

	async model(params: modelParams) {
		const { business_id } = this.paramsFor('businesses.business') as { business_id: string };
		this.pigCohortVariables = {
			where: {
				dob: {
					equals: params.birth_week_start_date,
				},
				businessId: {
					equals: business_id,
				},
			},
		};

		await this.getPigCohort.promise;

		const result = {
			pigCohort: this.getPigCohort,
			lastUpdatedAt: DateTime.now().toISO(),
			birth_week_start_date: params.birth_week_start_date,
		};

		return result;
	}
}
