import Route from '@ember/routing/route';
import { tracked } from '@glimmer/tracking';
import {
	CustomerEntity,
	LedgerEntryAggregateDTO,
	LedgerEntryFilterDTO,
	LedgerEntryGroupByDTO,
	LedgerMilkCheckCategory,
	TypeOfLedgerCategory,
	NumericAggregateLedgerEntryDTO,
	LedgerActualEntryAggregateDTO,
	LedgerForecastedEntryAggregateDTO,
	LedgerActualEntryGroupByDTO,
	LedgerForecastedEntryGroupByDTO,
	LedgerForecastedEntry,
	LedgerActualEntry,
	LedgerForecastedEntryFilterDTO,
	LedgerActualEntryFilterDTO,
	ForecastedMilkProductionByMonthFilterDTO,
	Query,
	ActualMilkProductionFilterDTO,
} from 'vault-client/types/graphql-types';
import { useQuery, gql } from 'glimmer-apollo';
interface modelParams {
	id: string;
	startDate: string;
	endDate: string;
}

const GET_MILK_PREMIUMS_AND_DEDUCTIONS = gql`
	query AggregateLedgerEntries(
		$calc: LedgerEntryAggregateDTO!
		$calcActualTotal: LedgerActualEntryAggregateDTO!
		$calcForecastedTotal: LedgerForecastedEntryAggregateDTO!
		$groupBy: LedgerEntryGroupByDTO!
		$totalsGroupBy: LedgerEntryGroupByDTO!
		$forecastedGroupBy: LedgerForecastedEntryGroupByDTO!
		$actualGroupBy: LedgerActualEntryGroupByDTO!
		$where: LedgerEntryFilterDTO
		$whereForecasted: LedgerForecastedEntryFilterDTO
		$whereActual: LedgerActualEntryFilterDTO
		$forecastedProductionWhere: ForecastedMilkProductionByMonthFilterDTO
		$actualProductionWhere: ActualMilkProductionFilterDTO
		$customerId: String!
		$scopeId: String!
	) {
		MilkPremiumAndDeductionEntries: AggregateLedgerEntries(
			calc: $calc
			groupBy: $groupBy
			where: $where
			orderBy: { month: Asc, year: Asc }
			scopeId: $scopeId
			limit: 2000
		) {
			sum {
				amount
				calculatedAmount
			}
			avg {
				amount
				calculatedAmount
			}
			type
			month
			year
			LedgerCategory {
				id
				name
				type
				calculationType
			}
		}
		MilkPremiumAndDeductionEntryTotals: AggregateLedgerEntries(calc: $calc, groupBy: $totalsGroupBy, where: $where, scopeId: $scopeId) {
			date
			sum {
				amount
				calculatedAmount
			}
			avg {
				amount
			}
			type
			LedgerCategory {
				name
				id
				calculationType
			}
		}
		LedgerMilkCheckCategories(scopeId: $scopeId) {
			id
			name
			type
			calculationType
		}
		Customer(id: $customerId) {
			id
			name
			businessRoles
			Locations {
				id
				name
			}
		}
		AggregateLedgerForecastedEntries(calc: $calcForecastedTotal, groupBy: $forecastedGroupBy, scopeId: $scopeId, where: $whereForecasted) {
			LedgerCategory {
				id
				calculationType
				name
			}
			sum {
				calculatedAmount
				amount
			}
			avg {
				amount
				calculatedAmount
			}
		}
		AggregateLedgerActualEntries(calc: $calcActualTotal, groupBy: $actualGroupBy, scopeId: $scopeId, where: $whereActual) {
			LedgerCategory {
				id
				calculationType
				name
			}
			sum {
				calculatedAmount
				amount
			}
			avg {
				amount
				calculatedAmount
			}
		}
		AggregateForecastedMilkProductionByMonths(
			calc: { sum: { grossProduction: true } }
			where: $forecastedProductionWhere
			groupBy: { date: true }
			scopeId: $customerId
		) {
			date
			sum {
				grossProduction
			}
		}
		AggregateActualMilkProduction(
			calc: { sum: { grossProduction: true } }
			where: $actualProductionWhere
			groupBy: { firstDateOfMonth: true }
			scopeId: $customerId
		) {
			firstDateOfMonth
			sum {
				grossProduction
			}
		}
	}
`;

interface Entry {
	name: string;
	year: number;
	LedgerCategory: LedgerMilkCheckCategory;
	sum: NumericAggregateLedgerEntryDTO;
	month: number;
	type: string;
	avg: {
		amount: number;
	};
}

export type GetMilkPremiumsAndDeductionsQuery = {
	__typename?: 'Query';
	MilkPremiumAndDeductionEntries: Entry[];
	MilkCheckEntries: {
		__typename?: 'MilkCheckEntries';
		year: number;
		month: number;
		avg: {
			numberOfCows: number;
			productionPerCow: number;
		};
		sum: {
			grossProduction: number;
			grossOtherSolids: number;
			grossButterfat: number;
			grossProtein: number;
		};
	}[];
	MilkCheckEntryTotals: {
		sum: {
			amount: number;
		};
		avg: {
			amount: true;
		};
		type: string;
		LedgerCategory: {
			name: string;
			id: string;
		};
	}[];
	LedgerMilkCheckCategories: LedgerMilkCheckCategory[];
	Customer: CustomerEntity;
	scopeId: string;
	MilkPremiumAndDeductionEntryTotals: Query['AggregateLedgerEntries'];
	AggregateLedgerForecastedEntries: LedgerForecastedEntry[];
	AggregateLedgerActualEntries: LedgerActualEntry[];
	AggregateForecastedMilkProductionByMonths: Query['AggregateForecastedMilkProductionByMonths'];
	AggregateActualMilkProduction: Query['AggregateActualMilkProduction'];
};

type GetMilkPremiumsAndDeductionsQueryVariables = {
	calc: LedgerEntryAggregateDTO;
	calcActualTotal: LedgerActualEntryAggregateDTO;
	calcForecastedTotal: LedgerForecastedEntryAggregateDTO;
	groupBy: LedgerEntryGroupByDTO;
	totalsGroupBy: LedgerEntryGroupByDTO;
	actualGroupBy: LedgerActualEntryGroupByDTO;
	forecastedGroupBy: LedgerForecastedEntryGroupByDTO;
	where: LedgerEntryFilterDTO;
	whereForecasted: LedgerForecastedEntryFilterDTO;
	whereActual: LedgerActualEntryFilterDTO;
	forecastedProductionWhere: ForecastedMilkProductionByMonthFilterDTO;
	actualProductionWhere: ActualMilkProductionFilterDTO;
	customerId: string;
	scopeId: string;
};
export default class BusinessesBusinessMilkPremiums extends Route {
	groupBy: LedgerEntryGroupByDTO = {
		categoryId: true,
		type: true,
		LedgerCategory: {
			name: true,
			id: true,
			type: true,
			calculationType: true,
		},
		month: true,
		year: true,
	};

	totalsGroupBy: LedgerEntryGroupByDTO = {
		categoryId: true,
		type: true,
		date: true,
		LedgerCategory: {
			name: true,
			id: true,
			calculationType: true,
		},
	};

	actualGroupBy: LedgerActualEntryGroupByDTO = {
		categoryId: true,
		type: true,
		LedgerCategory: {
			name: true,
			id: true,
			calculationType: true,
		},
	};

	forecastedGroupBy: LedgerForecastedEntryGroupByDTO = {
		categoryId: true,
		type: true,
		LedgerCategory: {
			name: true,
			id: true,
			calculationType: true,
		},
	};

	calc: LedgerEntryAggregateDTO = {
		sum: {
			amount: true,
			calculatedAmount: true,
		},
		avg: {
			amount: true,
			calculatedAmount: true,
		},
	};

	calcActualTotal: LedgerActualEntryAggregateDTO = {
		sum: {
			calculatedAmount: true,
		},
	};

	calcForecastedTotal: LedgerForecastedEntryAggregateDTO = {
		sum: {
			calculatedAmount: true,
			amount: true,
		},
		avg: {
			amount: true,
		},
	};

	@tracked variables: GetMilkPremiumsAndDeductionsQueryVariables = {
		calc: this.calc,
		calcActualTotal: this.calcActualTotal,
		calcForecastedTotal: this.calcForecastedTotal,
		groupBy: this.groupBy,
		totalsGroupBy: this.totalsGroupBy,
		actualGroupBy: this.actualGroupBy,
		forecastedGroupBy: this.forecastedGroupBy,
		customerId: '',
		scopeId: '',
		where: this.generateWhere(null, null),
		whereForecasted: this.generateForecastedWhere(null, null),
		whereActual: this.generateActualWhere(null, null),
		forecastedProductionWhere: this.generateForecastedProductionWhere(null, null),
		actualProductionWhere: this.generateActualProductionWhere(null, null),
	};

	getMilkPremiumsAndDeductions = useQuery<GetMilkPremiumsAndDeductionsQuery, GetMilkPremiumsAndDeductionsQueryVariables>(this, () => [
		GET_MILK_PREMIUMS_AND_DEDUCTIONS,
		{
			variables: this.variables,
		},
	]);

	generateForecastedProductionWhere(startDate: string | null, endDate: string | null): ForecastedMilkProductionByMonthFilterDTO {
		return {
			date: {
				gte: startDate,
				lte: endDate,
			},
		};
	}

	generateActualProductionWhere(startDate: string | null, endDate: string | null): ActualMilkProductionFilterDTO {
		return {
			date: {
				gte: startDate,
				lte: endDate,
			},
		};
	}

	generateWhere(startDate: string | null, endDate: string | null): LedgerEntryFilterDTO {
		const where: LedgerEntryFilterDTO = {
			LedgerCategory: {
				type: {
					equals: TypeOfLedgerCategory.MilkCheck,
				},
				AND: [],
			},
		};

		if (startDate) {
			if (!where.AND) where.AND = [];
			where.AND.push({
				date: {
					gte: startDate,
				},
			});
		}

		if (endDate) {
			if (!where.AND) where.AND = [];
			where.AND.push({
				date: {
					lte: endDate,
				},
			});
		}

		return where;
	}

	generateForecastedWhere(startDate: string | null, endDate: string | null): LedgerForecastedEntryFilterDTO {
		const where: LedgerForecastedEntryFilterDTO = {
			LedgerCategory: {
				type: {
					equals: TypeOfLedgerCategory.MilkCheck,
				},
				AND: [],
			},
		};

		if (startDate) {
			if (!where.AND) where.AND = [];
			where.AND.push({
				date: {
					gte: startDate,
				},
			});
		}

		if (endDate) {
			if (!where.AND) where.AND = [];
			where.AND.push({
				date: {
					lte: endDate,
				},
			});
		}

		return where;
	}

	generateActualWhere(startDate: string | null, endDate: string | null): LedgerActualEntryFilterDTO {
		const where: LedgerActualEntryFilterDTO = {
			LedgerCategory: {
				type: {
					equals: TypeOfLedgerCategory.MilkCheck,
				},
				AND: [],
			},
		};

		if (startDate) {
			if (!where.AND) where.AND = [];
			where.AND.push({
				date: {
					gte: startDate,
				},
			});
		}

		if (endDate) {
			if (!where.AND) where.AND = [];
			where.AND.push({
				date: {
					lte: endDate,
				},
			});
		}

		return where;
	}

	queryParams = {
		startDate: {
			refreshModel: true,
		},
		endDate: {
			refreshModel: true,
		},
	};

	async model(params: modelParams) {
		const { business_id } = this.paramsFor('businesses.business') as { business_id: string };

		this.variables = {
			calc: this.calc,
			calcActualTotal: this.calcActualTotal,
			calcForecastedTotal: this.calcForecastedTotal,
			groupBy: this.groupBy,
			totalsGroupBy: this.totalsGroupBy,
			actualGroupBy: this.actualGroupBy,
			forecastedGroupBy: this.forecastedGroupBy,
			where: this.generateWhere(params.startDate, params.endDate),
			whereForecasted: this.generateForecastedWhere(params.startDate, params.endDate),
			whereActual: this.generateActualWhere(params.startDate, params.endDate),
			forecastedProductionWhere: this.generateForecastedProductionWhere(params.startDate, params.endDate),
			actualProductionWhere: this.generateActualProductionWhere(params.startDate, params.endDate),
			customerId: business_id,
			scopeId: business_id,
		};
		await this.getMilkPremiumsAndDeductions.promise;
		return {
			entityId: business_id,
			getMilkPremiumsAndDeductions: this.getMilkPremiumsAndDeductions,
		};
	}
}
