import CropPlansShowController from 'vault-client/controllers/crop-plans/show';

export default class BusinessesBusinessCropPlan extends CropPlansShowController {
	cropPlansRoutePath: string = 'businesses.business.crop-plans';
	transactionsRoutePath: string = 'businesses.business.transactions';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'businesses/business/crop-plan': BusinessesBusinessCropPlan;
	}
}
