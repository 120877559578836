import Route from '@ember/routing/route';
import { tracked } from '@glimmer/tracking';
import { gql, useQuery } from 'glimmer-apollo';
import {
	GrainFillOrderModificationRequestFilterDTO,
	GrainFillOrderModificationStatus,
	GrainOrderFilterDTO,
	Query,
	TypeOfGrainOrderStatus,
} from 'vault-client/types/graphql-types';

export const GET_CROP_PLAN = gql`
	query production($id: String!, $ordersWhere: GrainOrderFilterDTO, $modReqWhere: GrainFillOrderModificationRequestFilterDTO) {
		GrainCropPlan(id: $id) {
			id
			acres
			aph
			enrolledPercent
			harvestBu
			storageBu
			breakEven
			goal
			hasWriteAccess
			type
			Orders(where: $ordersWhere, orderBy: { createdAt: Asc }) {
				id
				updatedAt
				createdAt
				bushels
				type
				futuresMonth
				deliveryStartDate
				deliveryEndDate
				deliveryMonth
				salesType
				futurePrice
				fees
				spread
				basis
				contractNumber
				flatPrice
				shortNote
				Location {
					id
					name
				}
				Buyer {
					id
					name
				}
				... on GrainTargetOrder {
					status
					expirationDate
					StatusUpdates(orderBy: { updatedAt: Desc }) {
						id
						updatedAt
					}
					Plan {
						id
						type
						... on GrainCropPlan {
							Crop {
								id
								name
							}
							Customer {
								id
								name
							}
						}
					}
				}
				... on GrainFillOrder {
					GrainFillOrderModificationRequests(where: $modReqWhere) {
						id
						OwnedByUser {
							id
							firstName
							lastName
						}
						RequestedByUser {
							id
							firstName
							lastName
						}
						createdAt
						fillId
						status
						type
						shortNote
						Buyer {
							id
							name
						}
						GrainFillOrder {
							id
							basis
							bushels
							buyerId
							contractNumber
							createdAt
							createdBy
							deliveryEndDate
							deliveryMonth
							deliveryStartDate
							planId
							fees
							flatPrice
							flexFee
							futurePrice
							futuresMonth
							htaFee
							deliveryTerms
							locationId
							rollFee
							salesType
							spread
							targetId
							Location {
								id
								name
							}
							DeliveryLocation {
								id
								name
							}
							Buyer {
								id
								name
							}
							Plan {
								... on GrainCropPlan {
									hasWriteAccess
									type
									Crop {
										id
										name
									}
									CropYear {
										id
										year
									}
									Customer {
										id
										name
									}
								}
							}
						}
						DeliveryLocation {
							id
							name
						}
						Location {
							id
							name
						}
						Buyer {
							id
							name
						}
						OwnedByUser {
							id
							firstName
							lastName
						}
						data {
							basis
							bushels
							buyerId
							deliveryStartDate
							deliveryEndDate
							deliveryId
							flexFee
							futurePrice
							futuresMonth
							htaFee
							deliveryTerms
							rollFee
							locationId
							spread
						}
					}
				}
			}
			Crop {
				id
				name
			}
			CropYear {
				id
				year
			}
			Customer {
				id
				name
				RelationshipOwner {
					id
					firstName
					lastName
				}
				BusinessContacts(orderBy: { isPrimary: Desc }) {
					id
					firstName
					lastName
					email
					phone
					isPrimary
				}
			}
		}
	}
`;

type GetCropPlan = {
	__typename?: 'Query';
	GrainCropPlan: Query['GrainCropPlan'];
};

type Query_CropPlansArgs = {
	id?: string;
	ordersWhere?: GrainOrderFilterDTO;
	modReqWhere?: GrainFillOrderModificationRequestFilterDTO;
};

interface QueryParams {
	id: string;
	showClosed: boolean;
}

export default class VgsGrainServicesCropPlansShow extends Route {
	@tracked variables: Query_CropPlansArgs = {};

	queryParams = {
		showClosed: {
			refreshModel: true,
		},
	};

	getCropPlan = useQuery<GetCropPlan, Query_CropPlansArgs>(this, () => [GET_CROP_PLAN, { variables: this.variables, pollInterval: 10000 }]);

	async model(params: QueryParams) {
		const ordersWhere: GrainOrderFilterDTO = {
			OR: [
				{
					AsGrainTargetOrder: {
						...(!params.showClosed && {
							status: {
								notIn: [TypeOfGrainOrderStatus.Filled, TypeOfGrainOrderStatus.Cancelled],
							},
						}),
					},
				},
				{
					AsGrainFillOrder: {},
				},
			],
		};

		const modReqWhere: GrainFillOrderModificationRequestFilterDTO = {
			status: {
				notIn: [
					GrainFillOrderModificationStatus.Rejected,
					GrainFillOrderModificationStatus.Cancelled,
					GrainFillOrderModificationStatus.Filled,
				],
			},
		};

		this.variables = {
			id: params.id,
			modReqWhere,
			ordersWhere,
		};

		await this.getCropPlan.promise;

		return { getCropPlan: this.getCropPlan };
	}
}
