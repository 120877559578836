import Component from '@glimmer/component';
import { action } from '@ember/object';
import {
	LedgerCategory,
	TypeOfLedgerCategory,
	Mutation_updateLedgerRevenueCategoryArgs,
	Mutation_updateLedgerExpenseCategoryArgs,
	Mutation_updateLedgerMilkCheckCategoryArgs,
} from 'vault-client/types/graphql-types';
import { tracked } from '@glimmer/tracking';
import { gql, useMutation } from 'glimmer-apollo';

interface EditLedgerCategoryModalArgs {
	ledgerCategory: LedgerCategory;
	closeModalFn: Function;
	model: any;
}

const UPDATE_LEDGER_REVENUE_CATEGORY = gql`
	mutation UpdateLedgerRevenueCategory($data: LedgerRevenueCategoryUpdateDTO!, $id: String!) {
		updateLedgerRevenueCategory(data: $data, id: $id) {
			id
			description
			name
			type
		}
	}
`;

const UPDATE_LEDGER_EXPENSE_CATEGORY = gql`
	mutation UpdateLedgerExpenseCategory($data: LedgerExpenseCategoryUpdateDTO!, $id: String!) {
		updateLedgerExpenseCategory(data: $data, id: $id) {
			id
			description
			name
			type
		}
	}
`;

const UPDATE_LEDGER_MILK_PREMIUMS_AND_DEDUCTIONS_CATEGORY = gql`
	mutation UpdateLedgerMilkCheckCategory($data: LedgerMilkCheckCategoryUpdateDTO!, $id: String!) {
		updateLedgerMilkCheckCategory(data: $data, id: $id) {
			id
			description
			name
			type
		}
	}
`;

type UpdateLedgerRevenueCategoryMutation = {
	__typename?: 'Mutation';
	updateLedgerRevenueCategory: {
		id: string;
		__typename: 'UpdatedResource';
	};
};

type UpdateLedgerExpenseCategoryMutation = {
	__typename?: 'Mutation';
	updateLedgerExpenseCategory: {
		id: string;
		__typename: 'UpdatedResource';
	};
};

type UpdateLedgerMilkPremiumsAndDeductionsCategoryMutation = {
	__typename?: 'Mutation';
	updateLedgerMilkCheckCategory: {
		id: string;
		__typename: 'UpdatedResource';
	};
};
export default class EditLedgerCategoryModal extends Component<EditLedgerCategoryModalArgs> {
	updateLedgerRevenueCategory = useMutation<UpdateLedgerRevenueCategoryMutation, Mutation_updateLedgerRevenueCategoryArgs>(this, () => [
		UPDATE_LEDGER_REVENUE_CATEGORY,
		{
			/* options */
			update: (cache) => {
				cache.evict({ fieldName: 'AggregateLedgerEntries' });
				cache.evict({ fieldName: 'LedgerEntry' });
				cache.evict({ fieldName: 'LedgerEntries' });
				cache.evict({ fieldName: 'LedgerEntryCount' });

				cache.evict({ fieldName: 'LedgerCategory' });
				cache.evict({ fieldName: 'LedgerCategories' });
				cache.evict({ fieldName: 'LedgerCategoryCount' });

				cache.evict({ fieldName: 'LedgerRevenueCategory' });
				cache.evict({ fieldName: 'LedgerRevenueCategories' });
				cache.evict({ fieldName: 'LedgerRevenueCategoryCount' });
				cache.gc();
			},
		},
	]);

	updateLedgerExpenseCategory = useMutation<UpdateLedgerExpenseCategoryMutation, Mutation_updateLedgerExpenseCategoryArgs>(this, () => [
		UPDATE_LEDGER_EXPENSE_CATEGORY,
		{
			/* options */
			update: (cache) => {
				cache.evict({ fieldName: 'AggregateLedgerEntries' });
				cache.evict({ fieldName: 'LedgerEntry' });
				cache.evict({ fieldName: 'LedgerEntries' });
				cache.evict({ fieldName: 'LedgerEntryCount' });

				cache.evict({ fieldName: 'LedgerCategory' });
				cache.evict({ fieldName: 'LedgerCategories' });
				cache.evict({ fieldName: 'LedgerCategoryCount' });

				cache.evict({ fieldName: 'LedgerExpenseCategory' });
				cache.evict({ fieldName: 'LedgerExpenseCategories' });
				cache.evict({ fieldName: 'LedgerExpenseCategoryCount' });
				cache.gc();
			},
		},
	]);

	updateLedgerMilkPremiumsAndDeductionsCategory = useMutation<
		UpdateLedgerMilkPremiumsAndDeductionsCategoryMutation,
		Mutation_updateLedgerMilkCheckCategoryArgs
	>(this, () => [
		UPDATE_LEDGER_MILK_PREMIUMS_AND_DEDUCTIONS_CATEGORY,
		{
			/* options */
			update: (cache) => {
				cache.evict({ fieldName: 'AggregateLedgerEntries' });
				cache.evict({ fieldName: 'LedgerEntry' });
				cache.evict({ fieldName: 'LedgerEntries' });
				cache.evict({ fieldName: 'LedgerEntryCount' });

				cache.evict({ fieldName: 'LedgerCategory' });
				cache.evict({ fieldName: 'LedgerCategories' });
				cache.evict({ fieldName: 'LedgerCategoryCount' });

				cache.evict({ fieldName: 'LedgerMilkCheckCategory' });
				cache.evict({ fieldName: 'LedgerMilkCheckCategories' });
				cache.evict({ fieldName: 'LedgerMilkCheckCategoryCount' });
				cache.gc();
			},
		},
	]);

	@tracked _categoryName: string | undefined = this.args.ledgerCategory.name;
	@tracked _categoryDescription: string | undefined | null = this.args.ledgerCategory.description;

	get categoryName() {
		return this._categoryName || this.args.ledgerCategory.name;
	}

	set categoryName(name) {
		this._categoryName = name;
	}

	get categoryDescription() {
		return this._categoryDescription ?? this.args.ledgerCategory.description;
	}

	set categoryDescription(description) {
		this._categoryDescription = description;
	}

	get hasValidInput() {
		return this.categoryName?.trim();
	}

	getUpdateLedgerCategoryMethod() {
		switch (this.args.ledgerCategory.type) {
			case TypeOfLedgerCategory.Revenue:
				return this.updateLedgerRevenueCategory;
			case TypeOfLedgerCategory.Expense:
				return this.updateLedgerExpenseCategory;
			case TypeOfLedgerCategory.MilkCheck:
				return this.updateLedgerMilkPremiumsAndDeductionsCategory;
			default:
				return null;
		}
	}

	@action
	async submit() {
		const updateLedgerCategoryMethod = this.getUpdateLedgerCategoryMethod();

		const data = {
			name: this.categoryName,
			description: this.categoryDescription,
		};

		if (updateLedgerCategoryMethod && updateLedgerCategoryMethod.mutate) {
			await updateLedgerCategoryMethod.mutate({
				id: this.args.ledgerCategory.id,
				data: data,
			});

			await this.args.model.refetch();
		}

		this.closeModal();
	}

	@action
	closeModal() {
		this.categoryName = '';
		this.categoryDescription = undefined;

		this.args.closeModalFn();
	}
}
