import Route from '@ember/routing/route';
import { tracked } from '@glimmer/tracking';
import { gql, useQuery } from 'glimmer-apollo';
import { Query, Query_GrainAdvisorsArgs, SortByDirection } from 'vault-client/types/graphql-types';
import { SortObj } from 'vault-client/types/vault-table';

export const GET_ADVISORS = gql`
	query GrainAdvisors($orderBy: GrainAdvisorSortByDTO) {
		GrainAdvisors(orderBy: $orderBy) {
			id
			name
			email
		}
		Organizations {
			id
		}
	}
`;

type getAdvisors = {
	__typename?: 'Query';

	GrainAdvisors: Query['GrainAdvisors'];
	Organizations: Query['Organizations'];
};

interface QueryParams {
	sorts: SortObj[];
}

export default class VgsSettingsAdvisors extends Route {
	@tracked variables: Query_GrainAdvisorsArgs = {};

	getAdvisors = useQuery<getAdvisors, Query_GrainAdvisorsArgs>(this, () => [GET_ADVISORS, { variables: this.variables }]);

	queryParams = {
		sorts: { refreshModel: true },
	};

	generateOrderBy(sorts: SortObj[]) {
		const orderBy: {
			[key: string]: any;
		} = {};

		sorts.forEach((sort: any) => {
			const parts = sort.valuePath.split('.');
			const firstPart = parts.shift();

			let value: any = sort.isAscending ? SortByDirection.Asc : SortByDirection.Desc;

			while (parts.length > 0) {
				const part = parts.pop();
				const obj: {
					[key: string]: any;
				} = {};
				obj[part] = value;
				value = obj;
			}

			orderBy[firstPart] = value;
		});

		return orderBy;
	}

	async model(params: QueryParams) {
		this.variables = {
			orderBy: this.generateOrderBy(params.sorts),
		};

		await this.getAdvisors.promise;

		return { getAdvisors: this.getAdvisors, variables: this.variables };
	}
}
