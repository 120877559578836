import Controller from '@ember/controller';
import { CellComponents } from 'vault-client/types/vault-table';

export default class OrganizationsOrganizationSettings extends Controller {
	get columns() {
		const baseColumns = [
			{
				id: '50a8fb9d-ecfd-495c-bf5c-ff8c4da06fac',
				name: 'Name',
				valuePath: 'name',
				minWidth: 225,
				textAlign: 'left',
				headerTextAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				linkRoutePath: 'linkRoute',
				linkModelPath: 'id',
			},
		];

		return baseColumns;
	}

	get rows() {
		return [
			{
				name: 'Details & Members',
				linkRoute: 'organizations.organization.details-and-members',
			},
			{
				name: 'Permission Groups',
				linkRoute: 'organizations.organization.permission-groups',
			},
		];
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'organizations/organization/settings': OrganizationsOrganizationSettings;
	}
}
