import ReportsHistoricalPercentilesByProduct from 'vault-client/controllers/reports/historical-percentiles-by-product';

export default class OrganizationsOrganizationHistoricalPercentilesByProduct extends ReportsHistoricalPercentilesByProduct {
	reportsRoute: string = 'organizations.organization.reports';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'organizations/organization/historical-percentiles-by-product': OrganizationsOrganizationHistoricalPercentilesByProduct;
	}
}
