import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { DateTime } from 'luxon';
import flatpickr from 'flatpickr';
import { Instance } from 'flatpickr/dist/types/instance';
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect';
import { Dropdown } from 'vault-client/types/ember-power-select';

interface Args {
	currentValue: { startDate: string; endDate: string };
	dateRangeOptions: any;
	onChange: any;
	uid: any;
	staticContainer?: boolean;
	monthOnly?: boolean;
}

export type UiDateFilterOption = {
	displayName?: string;
	startDate: string;
	endDate: string;
};

// Added to prevent users from accidentally selecting 1000+ year ranges (Causes performance issues)
const CUSTOM_MAX_YEARS_FROM_CURRENT_DATE = 100;

export default class UiDateFilterComponent extends Component<Args> {
	@tracked optionsDrawerOpen = false;
	@tracked showDateRangeOptions = true;
	@tracked showCustomDatePicker = false;
	@tracked showDrawerRight = true;
	@tracked startDateUnmasked: any;
	@tracked startDateMasked: any;
	@tracked endDateUnmasked: any;
	@tracked endDateMasked: any;

	// flatpickr instances
	startDateFlatpickr: Instance | null = null;
	endDateFlatpickr: Instance | null = null;

	constructor(owner: any, args: Args) {
		super(owner, args);
		window.addEventListener('click', this.closeIfClickOutside);
		const optionsDisplayNames = this.args.dateRangeOptions.map((option: any) => option.displayName);
		if (!optionsDisplayNames.includes(this.currentValue)) {
			this.startDateUnmasked = this.args.currentValue.startDate;
			this.endDateUnmasked = this.args.currentValue.endDate;
			this.startDateMasked = DateTime.fromFormat(this.startDateUnmasked, 'yyyy-MM-dd').toFormat('MM/dd/yyyy');
			this.endDateMasked = DateTime.fromFormat(this.endDateUnmasked, 'yyyy-MM-dd').toFormat('MM/dd/yyyy');
		}
	}

	willDestroy() {
		super.willDestroy();
		window.removeEventListener('click', this.closeIfClickOutside);
	}

	get drawerPositionClasses() {
		if (this.args.staticContainer) return '';
		if (this.showDrawerRight) return 'left-0';
		return 'right-0';
	}

	get showCustomDateWarning() {
		return this.endDateMasked && !this.isCustomDateRangeValid;
	}

	get currentValue() {
		if (this.args.currentValue && (this.args.currentValue.startDate || this.args.currentValue.endDate)) {
			const startDate = this.args.currentValue.startDate ?? null;
			const endDate = this.args.currentValue.endDate ?? null;
			let idx;

			if (startDate && endDate) {
				idx = this.dateRangeOptions.findIndex((option: any) => {
					return option.startDate === startDate && option.endDate === endDate;
				});
			} else if (startDate) {
				idx = this.dateRangeOptions.findIndex((option: any) => {
					return option.startDate === startDate;
				});
			} else if (endDate) {
				idx = this.dateRangeOptions.findIndex((option: any) => {
					return option.endDate === endDate;
				});
			}

			if (idx >= 0) {
				return this.dateRangeOptions[idx].displayName
					? this.dateRangeOptions[idx].displayName
					: this.generateDisplayName(this.args.currentValue);
			} else {
				return this.generateDisplayName(this.args.currentValue);
			}
		} else {
			return 'Select dates';
		}
	}

	get dateRangeOptions() {
		const rangeOptions = this.args.dateRangeOptions;

		return rangeOptions.map((option: any) => {
			if (!option.displayName) {
				option.displayName = this.generateDisplayName(option);
			}
			return option;
		});
	}

	get isCustomDateRangeValid() {
		// Check if inputs are populated with valid dates
		const bothInputsValid = this.validateDateInput(this.startDateMasked) && this.validateDateInput(this.endDateMasked);
		if (!bothInputsValid) return false;

		// Make sure startDate is before endDate
		const startDate = DateTime.fromFormat(this.startDateMasked, 'MM/dd/yyyy');
		const endDate = DateTime.fromFormat(this.endDateMasked, 'MM/dd/yyyy');
		if (endDate < startDate) return false;

		return true;
	}

	get isCustomDateRange() {
		return (
			0 >
			this.dateRangeOptions.findIndex((option: any) => {
				return option.displayName === this.currentValue;
			})
		);
	}

	get customDateRangeOptionIdx() {
		return this.dateRangeOptions.length;
	}

	get plugins() {
		const plugins = [];

		if (this.args.monthOnly) {
			plugins.push(
				monthSelectPlugin({
					shorthand: true,
					dateFormat: 'Y-m-d',
					altFormat: 'M Y',
					theme: 'light',
				})
			);
		}
		return plugins;
	}

	// component returns { startDate: YYYY-MM-DD, endDate: YYYY-MM-DD } via passed in callback for this.args.onChange
	@action
	setCurrentValue(startDate: any, endDate: any) {
		this.args.onChange({ startDate, endDate });
		this.optionsDrawerOpen = false;
		this.resetDateFilterState();
		this.startDateUnmasked = '';
		this.startDateMasked = '';
		this.endDateUnmasked = '';
		this.endDateMasked = '';
	}

	@action
	setCustomDateRange() {
		const startDate = this.startDateUnmasked;
		const endDate = this.endDateUnmasked;
		this.args.onChange({ startDate, endDate });
		this.optionsDrawerOpen = false;
		this.resetDateFilterState();
	}

	@action
	handleDateFilterToggleBtn() {
		if (!this.optionsDrawerOpen) {
			this.optionsDrawerOpen = true;
		} else if (this.optionsDrawerOpen) {
			this.optionsDrawerOpen = false;
			this.resetDateFilterState();
		}
	}

	@action
	handleCustomDateCntrlBtn() {
		this.showDateRangeOptions = false;
		this.showCustomDatePicker = true;
	}

	@action
	closeIfClickOutside(e: any) {
		const dateFilterWrapper = document.getElementById(`${this.args.uid}-ui-date-filter-wrapper`);
		const flatpickerStartDate = document.getElementById(`${this.args.uid}-flatpickr-startDate-wrapper`);
		const flatpickerEndDate = document.getElementById(`${this.args.uid}-flatpickr-endDate-wrapper`);
		const flatpickerStartDateActive = flatpickerStartDate ? flatpickerStartDate.contains(e.target) : false;
		const flatpickerEndDateActive = flatpickerEndDate ? flatpickerEndDate.contains(e.target) : false;

		if (!!this.optionsDrawerOpen && !dateFilterWrapper?.contains(e.target) && !flatpickerStartDateActive && !flatpickerEndDateActive) {
			this.optionsDrawerOpen = false;
			this.resetDateFilterState();
		}
	}

	@action
	setDropdownDirection() {
		const { left } = document.getElementById(`${this.args.uid}-ui-date-filter-toggle-btn`)?.getBoundingClientRect() ?? { left: 0 };
		const { width } = document.getElementById(`${this.args.uid}-ui-date-filter-custom-date-picker`)?.getBoundingClientRect() ?? {
			width: 0,
		};
		const intViewportWidth = window.innerWidth;
		if (intViewportWidth < left + width) {
			this.showDrawerRight = false;
		} else {
			this.showDrawerRight = true;
		}
	}

	@action
	handleDateFilterKeyDown(className: any, event: any) {
		const elements = [...event.currentTarget.getElementsByClassName(className)].filter((el) => !el.hasAttribute('disabled'));
		const length = elements.length - 1;
		let activeItem = elements.findIndex((el) => el.matches(':focus'));

		if (event.key === 'Down' || event.key === 'ArrowDown') {
			event.preventDefault();
			activeItem = activeItem === length ? 0 : ++activeItem;
			elements[activeItem].focus({ preventScroll: false });
		} else if ((event.key === 'Up' || event.key === 'ArrowUp') && activeItem > 0) {
			event.preventDefault();
			elements[--activeItem].focus({ preventScroll: false });
		} else if (event.key === 'Esc' || event.key === 'Escape') {
			event.preventDefault();
			event.srcElement.blur();
			this.optionsDrawerOpen = false;
		} else if (event.shiftKey && event.key === 'Tab') {
			if (event.shiftKey && activeItem === 0) {
				this.optionsDrawerOpen = false;
			}
		} else if (event.key === 'Tab') {
			if (activeItem === length) {
				this.optionsDrawerOpen = false;
			}
		}
	}

	@action
	focusOnFirstOption() {
		document.getElementById(`${this.args.uid}-ui-date-filter-option-0`)?.focus();
	}

	@action
	focusOnElement(e: any) {
		e.focus();
	}

	// actions for ember inputmask
	@action
	handleDateMaskInput(input: 'startDate' | 'endDate', dropdown: Dropdown, unmasked: any, masked: any) {
		// method for one-way-date-mask update hook
		this[`${input}Unmasked`] = unmasked;
		this[`${input}Masked`] = masked;

		if (this.validateDateInput(masked)) {
			dropdown.actions.close();
			document.getElementById(`${this.args.uid}-${input}-input`)?.focus();
		}
	}

	// actions for flatpickr
	@action
	setUpDateFlatpickrInstance(inputStr: 'startDate' | 'endDate', dropdown: Dropdown) {
		// create startDate and endDate flatpickr instances
		const element = document.getElementById(`${this.args.uid}-flatpickr-${inputStr}-picker`);
		const wrapper = document.getElementById(`${this.args.uid}-flatpickr-${inputStr}-wrapper`);

		if (!element || !wrapper) return;

		const onChangeFunc = (inputStr: 'startDate' | 'endDate', dropdown: Dropdown) => {
			return (_selectedDates: Date[], dateStr: string) => {
				// arguments made available via flatpickr for callback to onChange
				if (this.args.monthOnly) {
					// If using the monthOnly custom selector, startDate needs to be the first day of the selected start month
					// endDate needs to be the last day of the selected end month'
					const adjustedDate =
						inputStr === 'startDate' ? DateTime.fromISO(dateStr).startOf('month') : DateTime.fromISO(dateStr).endOf('month');
					this[`${inputStr}Unmasked`] = adjustedDate.toISODate();
					this[`${inputStr}Masked`] = adjustedDate.toFormat('MM/dd/yyyy');
				} else {
					this[`${inputStr}Unmasked`] = dateStr;
					this[`${inputStr}Masked`] = DateTime.fromFormat(dateStr, 'yyyy-MM-dd').toFormat('MM/dd/yyyy');
				}
				document.getElementById(`${this.args.uid}-${inputStr}-input`)?.focus();
				dropdown.actions.close();
			};
		};
		if (inputStr == 'endDate') {
			this.endDateFlatpickr = flatpickr(element, {
				dateFormat: 'Y-m-d',
				appendTo: wrapper,
				inline: true,
				onChange: onChangeFunc(inputStr, dropdown),
				static: false,
				minDate: this.startDateUnmasked,
				plugins: this.plugins,
			});
			if (this.endDateUnmasked) {
				this.endDateFlatpickr.setDate(this.endDateUnmasked, false);
			}
		} else {
			this.startDateFlatpickr = flatpickr(element, {
				dateFormat: 'Y-m-d',
				appendTo: wrapper,
				inline: true,
				onChange: onChangeFunc(inputStr, dropdown),
				static: false,
				plugins: this.plugins,
			});
			if (this.startDateUnmasked) {
				this.startDateFlatpickr.setDate(this.startDateUnmasked, false);
			}
		}
	}

	@action
	triggerFlatpickrOpen(dropdown: Dropdown) {
		dropdown.actions.open();
	}

	@action
	triggerFlatpickrClose(input: any, dropdown: Dropdown, e: any) {
		const flatpickrWrapper = document.getElementById(`${this.args.uid}-flatpickr-${input}-wrapper`);
		const flatpickrActive = flatpickrWrapper ? flatpickrWrapper.contains(e.relatedTarget) : false;
		if (!flatpickrActive) {
			dropdown.actions.close();
		}
	}

	resetDateFilterState() {
		this.showDateRangeOptions = true;
		this.showCustomDatePicker = false;
	}

	generateDisplayName(dateFilterObj: any) {
		if (dateFilterObj.startDate && dateFilterObj.endDate) {
			// both dates
			if (
				dateFilterObj.startDate === dateFilterObj.endDate ||
				(this.args.monthOnly && dateFilterObj.startDate === DateTime.fromISO(dateFilterObj.endDate).startOf('month').toISODate())
			) {
				return `${this.formatDateAsLocalString(dateFilterObj.startDate)}`;
			} else {
				return `${this.formatDateAsLocalString(dateFilterObj.startDate)} - ${this.formatDateAsLocalString(dateFilterObj.endDate)}`;
			}
		} else if (dateFilterObj.startDate && !dateFilterObj.endDate) {
			// start date only
			return `After ${this.formatDateAsLocalString(dateFilterObj.startDate)}`;
		} else if (!dateFilterObj.startDate && dateFilterObj.endDate) {
			// end date only
			return `Before ${this.formatDateAsLocalString(dateFilterObj.endDate)}`;
		} else {
			// no dates - this shouldn't happen
			return 'N/A';
		}
	}

	formatDateAsLocalString(dateStr: string) {
		const dateTime = DateTime.fromISO(dateStr);
		return this.args.monthOnly ? dateTime.toFormat('MMM yyyy') : DateTime.fromISO(dateStr).toLocaleString(DateTime.DATE_MED);
	}

	validateDateInput(dateString: any) {
		if (!dateString) return false;

		const dateTime = DateTime.fromFormat(dateString, 'MM/dd/yyyy');

		if (!dateTime.isValid) return false;

		const now = DateTime.now();
		const lowerBound = now.minus({ years: CUSTOM_MAX_YEARS_FROM_CURRENT_DATE });
		const upperBound = now.plus({ years: CUSTOM_MAX_YEARS_FROM_CURRENT_DATE });

		if (dateTime < lowerBound || dateTime > upperBound) return false;

		return true;
	}
}
