import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import { CellComponents, TableColumn } from 'vault-client/types/vault-table';
import { ModelFrom } from 'vault-client/utils/type-utils';
import FeedPlansShowRoute from 'vault-client/routes/vgs/feed-services/feed-plans/show';
import FeedPlanModel from 'vault-client/models/vgs/feed-plan';
import Target from 'vault-client/models/vgs/target';
import Fill from 'vault-client/models/vgs/fill';
import PermissionsService from 'vault-client/services/permissions';
import { action } from '@ember/object';
import { saveAs } from 'file-saver';
import { generateFullName } from 'vault-client/utils/general';
import { getGrainOrderStatusDisplayValue, getSalesTypeDisplayValue } from 'vault-client/utils/vgs-utils';

export default class VgsFeedServicesFeedPlansShow extends Controller {
	declare model: ModelFrom<FeedPlansShowRoute>;
	@service declare permissions: PermissionsService;

	queryParams = ['showClosed', 'showCancelledFillsClosed'];
	@tracked showClosed = false;
	@tracked showCancelledFillsClosed = false;
	@tracked fillNotes: string | null = null;
	@tracked targetIdToRemove: string | null = null;
	@tracked isPrinting = false;
	@tracked showDeleteConfirmation = false;

	get targets(): TableColumn[] {
		const brOnlyColumns: TableColumn[] = [
			{
				id: 'c3f43bf9-0b37-491c-aeb2-1ec7a009e4bb',
				name: '',
				minWidth: 130,
				textAlign: 'center',
				cellComponent: CellComponents.Button,
				componentArgs: {
					size: 'xs',
					style: 'secondary',
					text: 'Remove',
					fn: this.removeTarget,
				},
				isFixed: '',
				isVisible: true,
			},
		];
		const baseColumns: TableColumn[] = [
			{
				id: 'e8cb9a80-d1b1-48bc-ba87-1f06d7eb03a6',
				name: 'Status',
				valuePath: 'status',
				width: 140,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.CustomFormat,
				componentArgs: {
					formatter: getGrainOrderStatusDisplayValue,
				},
				isFixed: '',
				isVisible: true,
			},

			{
				id: '82e09639-2d27-400a-ad47-00c008b2653b',
				name: 'Expires At',
				valuePath: 'expirationDate',
				width: 100,
				cellComponent: CellComponents.IntlDateTimeFormat,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '7094ea1b-70c4-42fe-a22f-51f7359eb542',
				name: 'Tons',
				valuePath: 'bushels',
				width: 100,
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '95f66ed3-cfe8-4ff0-b9d2-e6a59cd9cd7f',
				name: 'Futures Month',
				valuePath: 'futuresMonth',
				width: 140,
				cellComponent: CellComponents.MonthFormat,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '0d7d2d90-806f-412f-bdb1-c7775747f5d1',
				name: 'Delivery Start Date',
				valuePath: 'deliveryStartDate',
				width: 180,
				cellComponent: CellComponents.IntlDateTimeFormat,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '39cc13bd-faf9-4aae-9e37-4faae5f2bea5',
				name: 'Delivery End Date',
				valuePath: 'deliveryEndDate',
				width: 180,
				cellComponent: CellComponents.IntlDateTimeFormat,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'e7dd5029-b9c1-405a-a7c8-a688b1808dfa',
				name: 'Purchase Type',
				valuePath: 'salesType',
				width: 140,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.CustomFormat,
				componentArgs: {
					formatter: getSalesTypeDisplayValue,
				},
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'd6be1bba-be42-48bf-b11d-f5a733511ea3',
				name: 'Futures Price',
				valuePath: 'futurePrice',
				width: 140,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
					minimumFractionDigits: 2,
					maximumFractionDigits: 5,
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'c78fcf06-12de-4276-83bc-116a4b39a7c4',
				name: 'Basis',
				valuePath: 'basis',
				width: 140,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'a05d5ad1-d756-4ecb-915a-d8fb7b2c8b27',
				name: 'Seller',
				valuePath: 'Seller.name',
				width: 180,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'a3555a1f-934f-457b-8c23-cc561230c3f0',
				name: 'Location',
				valuePath: 'Location.name',
				width: 180,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'c78fcf06-12de-4276-83bc-116a4b39a7c4',
				name: 'Delivery Terms',
				valuePath: 'deliveryTerms',
				width: 140,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '7c776295-7177-4a38-b1ec-8d22a511a162',
				name: 'Target Note',
				valuePath: 'targetNote',
				minWidth: 120,
				width: 120,
				cellComponent: CellComponents.String,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '3b6e194c-794d-404e-bbc4-02f2f56a49c0',
				name: '',
				minWidth: 130,
				textAlign: 'center',
				isSortable: false,
				cellComponent: CellComponents.Button,
				componentArgs: {
					size: 'xs',
					style: 'secondary',
					text: 'Details',
					fn: () => {},
				},
				isFixed: '',
				isVisible: true,
				linkRoute: 'vgs.feed-services.targets.show',
				linkModelPath: 'id',
			},
		];

		const baseAndWriteColumns = [...baseColumns, ...brOnlyColumns];

		return this.permissions.isBuyerRelations && this.feedPlan?.hasWriteAccess ? baseAndWriteColumns : baseColumns;
	}

	get fills(): TableColumn[] {
		const baseColumns: TableColumn[] = [
			{
				id: '8af3ba21-a974-4d1e-9da5-217d7801ea8f',
				name: 'Contract Number',
				valuePath: 'contractNumber',
				width: 200,
				textAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			// {
			// 	id: '4fe2fe66-bf2c-488d-9c2c-ed554b90ddc0',
			// 	name: 'Date Sold',
			// 	valuePath: 'dateSold',
			// 	width: 110,
			// 	cellComponent: CellComponents.IntlDateTimeFormat,
			// 	textAlign: 'left',
			// 	isSortable: true,
			// 	isFixed: '',
			// 	isVisible: false,
			// },
			{
				id: '64fe5a53-d64a-46h0-aacf-421327457c5f',
				name: '%',
				valuePath: 'feedPercent',
				width: 100,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'percent',
					minimumFractionDigits: '0',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '0860b384-f976-4a26-9fd0-e0c736412486',
				name: 'Tons',
				valuePath: 'bushels',
				width: 110,
				cellComponent: CellComponents.IntlNumberFormat,
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '7d0f67c5-a3bf-47a2-9dd7-6324dababa50',
				name: 'Futures Month',
				valuePath: 'futuresMonth',
				width: 150,
				cellComponent: CellComponents.MonthFormat,
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '49f9adca-96cb-466b-992f-c4f389464d72',
				name: 'Delivery Start Date',
				valuePath: 'deliveryStartDate',
				width: 200,
				cellComponent: CellComponents.IntlDateTimeFormat,
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'dc0cb460-7354-49f1-91dc-0761738c7122',
				name: 'Delivery End Date',
				valuePath: 'deliveryEndDate',
				width: 200,
				cellComponent: CellComponents.IntlDateTimeFormat,
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'c696c3d8-da2a-477f-87cb-21ec6293207e',
				name: 'Purchase Type',
				valuePath: 'salesType',
				width: 140,
				textAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.CustomFormat,
				componentArgs: {
					formatter: getSalesTypeDisplayValue,
				},
				isFixed: '',
				isVisible: true,
			},
			{
				id: '008eb0a9-5a6c-4ded-9d6b-a58ae9c61e0c',
				name: 'Futures Price',
				valuePath: 'futurePrice',
				width: 140,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
					minimumFractionDigits: 2,
					maximumFractionDigits: 5,
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'b133545d-0c40-469d-8ed8-18fba468895f',
				name: 'Basis',
				valuePath: 'basis',
				width: 140,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'd591c453-4089-4f00-84e4-35a33e583f53',
				name: 'Flat Price',
				valuePath: 'flatPrice',
				width: 140,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
					minimumFractionDigits: 2,
					maximumFractionDigits: 5,
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'e91380a5-28bd-4ee0-8292-5ff6f3af3f9d',
				name: 'Seller',
				valuePath: 'Seller.name',
				width: 180,
				textAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '50c0dfdb-07b1-4d77-a006-88a4fadef6d6',
				name: 'Delivery Terms',
				valuePath: 'deliveryTerms',
				width: 140,
				textAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '83578215-27ca-4866-b328-9b51fea72626',
				name: 'Location',
				valuePath: 'Location.name',
				width: 180,
				textAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '5db0d5bd-d00b-44b7-8cf0-695469dec74f',
				name: '',
				minWidth: 130,
				textAlign: 'center',
				isSortable: true,
				cellComponent: CellComponents.Button,
				componentArgs: {
					size: 'xs',
					style: 'secondary',
					text: 'Details',
					fn: () => {},
				},
				isFixed: '',
				isVisible: true,
				linkRoute: 'vgs.feed-services.fills.show',
				linkModelPath: 'id',
			},
		];
		return baseColumns;
	}

	get businessContact() {
		return (
			this.model.getFeedPlan.data?.GrainFeedPlan?.Customer?.BusinessContacts.find((contact) => contact.isPrimary) ??
			this.model.getFeedPlan.data?.GrainFeedPlan?.Customer?.BusinessContacts[0] ??
			null
		);
	}

	get businessContactName() {
		return generateFullName(this.businessContact?.firstName, this.businessContact?.lastName) ?? '';
	}

	get feedPlan() {
		const feedPlan = this.model.getFeedPlan.data?.GrainFeedPlan;
		return feedPlan ? new FeedPlanModel(feedPlan) : null;
	}

	get targetData() {
		return this.feedPlan?.Targets.map((target) => new Target(target, null, null));
	}

	get fillData() {
		if (this.model.getFeedPlan.data) {
			return this.feedPlan?.Fills.sort((a, b) => {
				if (!a.futuresMonth) return 1;
				if (!b.futuresMonth) return -1;
				if (new Date(a.futuresMonth).getTime() > new Date(b.futuresMonth).getTime()) return 1;

				return -1;
			}).sort((a, b) => {
				if (!a.deliveryStartDate) return 1;
				if (!b.deliveryStartDate) return -1;
				const aStartDate = new Date(a.deliveryStartDate).getTime();
				const bStartDate = new Date(b.deliveryStartDate).getTime();

				if (aStartDate === bStartDate) return 0;

				if (aStartDate > bStartDate) return 1;
				return -1;
			});
		} else {
			return null;
		}
	}

	get feedPlanName() {
		return this.feedPlan
			? `${this.feedPlan.feedMonth} ${this.feedPlan.feedYear}
    ${this.feedPlan.FeedCategory?.name}`
			: null;
	}

	@action
	async print() {
		this.isPrinting = true;
		await this.fillData;

		const feedPlansObject = {
			id: this.feedPlan?.id,
			breakeven: this.feedPlan?.breakEven,
			goal: this.feedPlan?.goal,
			feedYear: this.feedPlan?.feedYear,
			FeedCategory: this.feedPlan?.FeedCategory,
			feedMonth: this.feedPlan?.feedMonth,
			Customer: this.feedPlan?.Customer,
			averageFuturePriceSold: this.feedPlan?.averageFuturePriceSold,
			averageFlatPriceSold: this.feedPlan?.averageFlatPriceSold,
			bushelsFilled: this.feedPlan?.bushelsFilled,
			sumGrossRevenue: this.feedPlan?.sumGrossRevenue,
			totalEstFeedUsage: this.feedPlan?.tons,
			totalTonsFlatPriced: this.feedPlan?.totalTonsFlatPriced,
			percentPriced: this.feedPlan?.percentPriced,
			physicalTonsOpen: this.feedPlan?.openUsage,
			percentPhysicalTonsOpen: this.feedPlan?.percentOpen,
			basisOnlyPurchased: this.feedPlan?.basisOnlyPurchased,
			basisExposure: this.feedPlan?.basisExposure,
			percentbasisExposure: this.feedPlan?.percentBasisExposure,
			totalTonsOpen: this.feedPlan?.totalTonsOpen,
			percentWorkingPending: this.feedPlan?.percentTonsOpen,
			futuresOnlyPurchased: this.feedPlan?.futuresOnlyPurchased,
			futuresExposure: this.feedPlan?.futuresExposure,
			percentFuturesExposure: this.feedPlan?.percentFuturesExposure,
			averageBasis: this.feedPlan?.averageBasis,
			Fills: this.fillData
				?.map((fill: Fill) => {
					return {
						basis: fill.basis,
						bushels: fill.bushels,
						contractNumber: fill.contractNumber,
						futurePrice: fill.futurePrice,
						futuresMonth: fill.futuresMonth,
						deliveryStartDate: fill.deliveryStartDate,
						deliveryEndDate: fill.deliveryEndDate,
						salesType: fill.salesType,
						Seller: fill.Seller,
						Location: fill.Location,
						netPrice: fill.netPrice,
						grossRevenue: fill.grossRevenue,
						deliveryTerms: fill.deliveryTerms,
					};
				})
				.sort((a, b) => {
					if (!a.futuresMonth) return 1;
					if (!b.futuresMonth) return -1;
					if (new Date(a.futuresMonth).getTime() > new Date(b.futuresMonth).getTime()) return 1;

					return -1;
				})
				.sort((a, b) => {
					if (!a.deliveryStartDate) return 1;
					if (!b.deliveryStartDate) return -1;
					const aStartDate = new Date(a.deliveryStartDate).getTime();
					const bStartDate = new Date(b.deliveryStartDate).getTime();

					if (aStartDate === bStartDate) return 0;

					if (aStartDate > bStartDate) return 1;
					return -1;
				}),
			Targets: this.targetData?.map((target: Target) => {
				return {
					basis: target.basis,
					bushels: target.bushels,
					deliveryStartDate: target.deliveryStartDate,
					deliveryEndDate: target.deliveryEndDate,
					futurePrice: target.futurePrice,
					futuresMonth: target.futuresMonth,
					salesType: target.salesType,
					Seller: target.Seller,
					Location: target.Location,
					netPrice: target.netPrice,
					grossRevenue: target.grossRevenue,
					deliveryTerms: target.deliveryTerms,
				};
			}),
		};
		const response = await fetch('/.netlify/functions/generate_feed_plan', {
			method: 'POST',
			body: JSON.stringify(feedPlansObject),
		});

		const responseBlob = await response.blob();
		const fileName = `${this.feedPlan?.feedMonth} ${this.feedPlan?.feedYear} ${this.feedPlan?.FeedCategory?.name} Feed Plan - ${this.feedPlan?.Customer?.name}.pdf`;
		await saveAs(responseBlob, fileName);
		this.isPrinting = false;
	}

	@action
	removeTarget(row: { id: string }) {
		this.targetIdToRemove = row.id;
	}

	@action
	closeRemoveTargetModal() {
		this.targetIdToRemove = null;
	}

	@action
	deletePlan() {
		this.showDeleteConfirmation = true;
	}

	@action
	closeDeletePlanModalFn() {
		this.showDeleteConfirmation = false;
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	interface Registry {
		'vgs/feed-services/feed-plans/show': VgsFeedServicesFeedPlansShow;
	}
}
