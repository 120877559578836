import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if this.showAlreadyExists}}\n\t<div class='w-full text-center text-xs flex flex-col my-3'>\n\t\t<span class='text-brand-gray-50'>This ingredient already exists in your feed usage table.</span>\n\t\t<span class='mt-3'>The ingredient column may be hidden.</span>\n\t\t<span>Check column customization control.</span>\n\t\t<FaIcon @icon='cog' class='text-base mt-3' />\n\t</div>\n{{else if this.mustShowNoMessages}}\n\t<div class='text-sm text-brand-gray-50 w-full text-center flex flex-col my-8'>\n\t\t<span>Don't see your ingredient?</span>\n\t\t<span role='button' class='grid-link' {{on 'click' this.openCreateNewIngredientModal}}>Create new ingredient</span>\n\t</div>\n{{/if}}", {"contents":"{{#if this.showAlreadyExists}}\n\t<div class='w-full text-center text-xs flex flex-col my-3'>\n\t\t<span class='text-brand-gray-50'>This ingredient already exists in your feed usage table.</span>\n\t\t<span class='mt-3'>The ingredient column may be hidden.</span>\n\t\t<span>Check column customization control.</span>\n\t\t<FaIcon @icon='cog' class='text-base mt-3' />\n\t</div>\n{{else if this.mustShowNoMessages}}\n\t<div class='text-sm text-brand-gray-50 w-full text-center flex flex-col my-8'>\n\t\t<span>Don't see your ingredient?</span>\n\t\t<span role='button' class='grid-link' {{on 'click' this.openCreateNewIngredientModal}}>Create new ingredient</span>\n\t</div>\n{{/if}}","moduleName":"vault-client/components/add-feed-ingredient-button/after-options-component.hbs","parseOptions":{"srcName":"vault-client/components/add-feed-ingredient-button/after-options-component.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { Select } from 'vault-client/types/ember-power-select';
import { FeedIngredient } from 'vault-client/types/graphql-types';

interface AfterOptionsComponentArgs {
	select: Select;
	extra: {
		openCreateNewIngredientModal: () => void;
		activeIngredients: FeedIngredient[];
	};
}

export default class AfterOptionsComponent extends Component<AfterOptionsComponentArgs> {
	get mustShowNoMessages(): boolean {
		return (
			!this.args.select.loading &&
			this.args.select.resultsCount === 0 &&
			(!this.args.select.searchText || this.args.select.lastSearchedText.length > 0)
		);
	}

	get showAlreadyExists() {
		return this.args.extra.activeIngredients.find((v) => v.name.trim().toLowerCase() === this.args.select.searchText.trim().toLowerCase());
	}

	@action
	openCreateNewIngredientModal() {
		this.args.select.actions.close();
		this.args.extra.openCreateNewIngredientModal();
	}
}
