import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { debounce } from '@ember/runloop';
import { inject as service } from '@ember/service';

interface Args {
	isAuthenticated: any;
	searchPrompt: any;
	structureSearchResults: any;
	fetchSearchResults: any;
	handleSearchFocusIn: any;
	handleSearchFocusOut: any;
	isGlobalSearch: any;
	uid: any;
}

export default class UiSearchComponent extends Component<Args> {
	@tracked searchText: any;
	@tracked _searchResults = [];
	@tracked loading = false;
	@tracked dropDownOpen = false;
	@service media: any;

	textEditableElements = ['INPUT', 'TEXTAREA'];
	_isAuthenticated = false;
	_searchPrompt = 'Type a search term to find options.';

	get isAuthenticated() {
		return this.args.isAuthenticated ? this.args.isAuthenticated : this._isAuthenticated;
	}

	get searchPrompt() {
		return this.args.searchPrompt ? this.args.searchPrompt : this._searchPrompt;
	}

	get searchResults() {
		if (this.args.structureSearchResults) {
			return this.args.structureSearchResults(this._searchResults);
		} else {
			return this._searchResults;
		}
	}

	async fetchNewResults() {
		if (this.searchText) {
			if (this.args.fetchSearchResults) {
				this._searchResults = await this.args.fetchSearchResults(this.searchText);
			} else {
				this._searchResults = [];
			}
		} else {
			this._searchResults = [];
		}
	}

	clearSearchText() {
		this.searchText = '';
		this._searchResults = [];
	}

	@action
	async updatedSearchText() {
		this.loading = true;
		debounce(
			this,
			// eslint-disable-next-line ember/no-incorrect-calls-with-inline-anonymous-functions
			async function () {
				await this.fetchNewResults();
				this.loading = false;
			},
			500
		);
	}

	@action
	openSearchContent() {
		this.dropDownOpen = true;
	}

	closeSearchContent() {
		this.dropDownOpen = false;
	}

	@action
	handleSearchFocusIn() {
		if (this.args.handleSearchFocusIn) {
			this.args.handleSearchFocusIn();
		}
	}

	@action
	handleClickOutside() {
		if (!this.media.isMobile) {
			this.closeSearchContent();
			if (this.args.handleSearchFocusOut) {
				this.args.handleSearchFocusOut();
			}
		}
	}

	@action
	handleLinkClick() {
		this.clearSearchText();
		this.closeSearchContent();
		if (this.args.handleSearchFocusOut) {
			this.args.handleSearchFocusOut();
		}
	}

	@action
	handleCloseClick() {
		this.clearSearchText();
		this.closeSearchContent();
		if (this.args.handleSearchFocusOut) {
			this.args.handleSearchFocusOut();
		}
	}

	@action
	handleKeyDown(event: any) {
		const searchElements = [].slice.call(event.currentTarget.getElementsByClassName('search-element'));
		const length = searchElements.length - 1;
		let activeItem = searchElements.findIndex((el: any) => el.matches(':focus'));

		if (event.key === 'Down' || event.key === 'ArrowDown') {
			event.preventDefault();
			activeItem = activeItem === length ? 0 : ++activeItem;
			searchElements[activeItem].focus({ preventScroll: false });
		} else if ((event.key === 'Up' || event.key === 'ArrowUp') && activeItem > 0) {
			event.preventDefault();
			searchElements[--activeItem].focus({ preventScroll: false });
		} else if (event.key === 'Esc' || event.key === 'Escape') {
			event.preventDefault();
			event.srcElement.blur();
			this.closeSearchContent();
			this.clearSearchText();
			if (this.args.handleSearchFocusOut) {
				this.args.handleSearchFocusOut();
			}
		} else if (event.key === 'Tab') {
			if ((event.shiftKey && activeItem === 0) || activeItem === length) {
				this.closeSearchContent();
				if (this.args.handleSearchFocusOut) {
					this.args.handleSearchFocusOut();
				}
			}
		}
	}

	@action
	focusSearch(event: KeyboardEvent) {
		const target = event.target as HTMLElement | null;
		const editableElement = target ? this.textEditableElements.includes(target.tagName) : false;
		if (this.args.isGlobalSearch && !editableElement) {
			const el = document.getElementById(this.args.uid);

			if (el) {
				//@ts-ignore
				el.value = el.value || '';
				el.focus();
			} else {
				console.warn('No search in viewport.');
			}
		}
	}
}
