import ReportsCheeseStrategicInventoryModel from 'vault-client/controllers/reports/cheese-strategic-inventory-model';

export default class OrganizationsOrganizationCheeseStrategicInventoryModel extends ReportsCheeseStrategicInventoryModel {
	reportsRoute: string = 'organizations.organization.reports';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'organizations/organization/cheese-strategic-inventory-model': OrganizationsOrganizationCheeseStrategicInventoryModel;
	}
}
