import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import query from 'vault-client/graphql/queries/customer-get.graphql';
import { queryManager } from 'ember-apollo-client';
import { CustomerEntity, InsurancePolicy } from 'vault-client/types/graphql-types';

interface QueryParams {
	business_id: string;
}

interface PolicyHolderCustomerEntity extends CustomerEntity {
	DrpPolicies: InsurancePolicy[];
	LrpPolicies: InsurancePolicy[];
	LgmPolicies: InsurancePolicy[];
}

export default class BusinessesBusinessRoute extends Route {
	@service applicationScope: any;
	@queryManager apollo: any;

	async model(params: QueryParams) {
		const response: { Customer: PolicyHolderCustomerEntity } = await this.apollo.watchQuery({
			query,
			variables: { id: params.business_id },
		});
		const organizationId = response.Customer?.organizationId ?? null;

		this.applicationScope.globalCustomerId = params.business_id;

		if (organizationId) {
			this.applicationScope.organizationId = organizationId;
		}

		return { ...response, businessId: params.business_id };
	}
}
