import LgmInsuranceMonths from 'vault-client/routes/lgm-insurance-months';
import { ModelFrom } from 'vault-client/utils/type-utils';
import BusinessesBusinessRoute from 'vault-client/routes/businesses/business';

interface ModelParams {
	startDate: string | null;
	endDate: string | null;
}
export default class BusinessesBusinessLgmInsuranceMonths extends LgmInsuranceMonths {
	async model(params: ModelParams) {
		const { businessId, Customer } = this.modelFor('businesses.business') as ModelFrom<BusinessesBusinessRoute>;
		const scopeId = businessId;
		if (!scopeId) {
			throw new Error('businessId could not be found for LGM Insurance Months');
		}

		const { startDate, endDate } = params;
		const lgmEndorsementsWhere = this.generateLGMEndorsementWhere(startDate, endDate);
		const milkProductionWhere = this.generateMilkProductionWhere(startDate, endDate);
		const swinePurchasesAndProducedWhere = this.generateSwinePurchasesAndProducedWhere(scopeId, startDate, endDate, 16);

		this.variables = {
			scopeId,
			lgmEndorsementsWhere,
			milkProductionWhere,
			swinePurchasesAndProducedWhere,
		};

		await this.getLgmEndorsements.promise;

		return {
			scopeId,
			getLgmEndorsements: this.getLgmEndorsements,
			Customer,
		};
	}
}
