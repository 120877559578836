import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { gql, useMutation } from 'glimmer-apollo';
import { ModelFrom } from 'vault-client/utils/type-utils';
import route from 'vault-client/routes/vgs/settings/advisors';
import {
	EntityUserRole,
	Mutation,
	Mutation_setEntityUserArgs,
	OrganizationEntity,
	Query_UsersArgs,
} from 'vault-client/types/graphql-types';
import { getInvalidElements, isFormValid } from 'vault-client/utils/form-validation';
import { guidFor } from '@ember/object/internals';

const CREATE_ADVISOR = gql`
	mutation AddAdvisor($data: EntityUserSetInput!) {
		setEntityUser(data: $data) {
			id
		}
	}
`;

interface AddAdvisorButtonArgs {
	model: ModelFrom<route>;
	organizationId?: string;
}

export default class AddAdvisorButton extends Component<AddAdvisorButtonArgs> {
	@tracked showModal = false;
	@tracked email: string | null = null;
	@tracked organizationId: string | null = null;
	@tracked getUsersVariables: Query_UsersArgs = {};
	@tracked isLoading = false;

	componentId = guidFor(this);
	createAdvisor = useMutation<{ setEntityUser: Mutation['setEntityUser'] }, Mutation_setEntityUserArgs>(this, () => [
		CREATE_ADVISOR,
		{
			onComplete: (): void => {
				this.args.model.getAdvisors.refetch();
				this.closeModal();
			},
			onError: (error): void => {
				console.error('Error while attempting to add Advisor', error.message);
			},
		},
	]);

	@action
	openModal() {
		this.showModal = true;
	}

	@action
	closeModal() {
		this.showModal = false;
		this.email = null;
	}

	@action
	setOrganizationId(organization: OrganizationEntity) {
		this.organizationId = organization.id;
	}

	@action
	async submit() {
		this.isLoading = true;
		const email = this.email?.trim();
		const entityId = this.args.organizationId ?? this.organizationId;

		if (isFormValid(document) && email && entityId) {
			await this.createAdvisor.mutate({
				data: {
					email,
					entityId,
					roles: [EntityUserRole.GrainAdvisor],
				},
			});
		} else {
			getInvalidElements(document);
		}
		this.isLoading = false;
	}
}
