import Service from '@ember/service';
import { next, once } from '@ember/runloop';
import { TrackedMap } from 'tracked-maps-and-sets';
import { tracked } from '@glimmer/tracking';
import { DateTime } from 'luxon';
import * as Sentry from '@sentry/browser';
import ENV from 'vault-client/config/environment';

export default class DeltaService extends Service {
	subscriptions = new TrackedMap();
	lrpSubscriptions = new TrackedMap();
	@tracked lastPolledAt: DateTime | null = null;

	constructor(...args: any) {
		super(...args);
	}

	register(symbol: string) {
		next(() => {
			if (!this.subscriptions.has(symbol)) {
				const newMap = new TrackedMap();
				newMap.set('definition', null);
				newMap.set('quantity', 1);
				this.subscriptions.set(symbol, newMap);

				once(this, this.fetchDeltas);
			} else {
				// @ts-ignore
				const previousQuantity = this.subscriptions.get(symbol).get('quantity');
				// @ts-ignore
				this.subscriptions.get(symbol).set('quantity', previousQuantity + 1);
			}
		});
	}

	unregister(symbol: any) {
		if (this.subscriptions.has(symbol)) {
			// @ts-ignore
			const previousQuantity = this.subscriptions.get(symbol).get('quantity');
			// @ts-ignore
			this.subscriptions.get(symbol).set('quantity', previousQuantity - 1);
		} else {
			throw Error('Unregistering a non-existent subscription.');
		}
	}

	getDelta(symbol: string) {
		if (this.subscriptions.has(symbol)) {
			// @ts-ignore
			return this.subscriptions.get(symbol).get('definition')?.delta;
		}
	}

	async fetchDeltas() {
		const subscriptionEntries = [...this.subscriptions.entries()];
		const newSubscriptions = subscriptionEntries.filter((entry) => {
			// @ts-ignore
			return entry[1].get('definition') === null;
		});

		const symbols = newSubscriptions.map((subscription) => {
			return subscription[0];
		});

		if (symbols.length > 0) {
			const length = symbols.length;

			let current = 0;
			const groups = [];
			let end;

			while (current < length) {
				end = length < current + 50 ? length : current + 50;
				groups.push(symbols.slice(current, end));
				current += 50;
			}

			const results = [];
      const apiKey: string = ENV.barchartApiToken;
			for (let i = 0; i < groups.length; i++) {
				const symbols = groups[i];
				try {
					const res = await fetch(
						`https://ondemand.websol.barchart.com/getFuturesOptions.json?apikey=${apiKey}&symbols=${symbols.join(
							','
						)}&fields=delta,vega,theta,gamma`,
						{
							method: 'GET',
							headers: {},
						}
					);

					results.push(res);
				} catch (error) {
					Sentry.captureException(error);
				}
			}

			const definitions = [];
			for (let i = 0; i < results.length; i++) {
				const result = results[i];
				const contentType = result.headers.get('content-type');

				// Verify that contentType is JSON and that the status code represents success
				if (contentType && contentType.includes('application/json') && result.ok) {
					const r = await result.json();

					if (r.results) {
						definitions.push(...r.results);
					}
				}
			}

			definitions.forEach((definition) => {
				if (this.subscriptions.has(definition.symbol)) {
					// @ts-ignore
					this.subscriptions.get(definition.symbol).set('definition', definition);
				}
			});
		}
	}
}

// Don't remove this declaration: this is what enables TypeScript to resolve
// this service using `Owner.lookup('service:delta')`, as well
// as to check when you pass the service name as an argument to the decorator,
// like `@service('delta') declare altName: DeltaService;`.
declare module '@ember/service' {
	interface Registry {
		delta: DeltaService;
	}
}
