import Controller from '@ember/controller';
import { action, set } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { TrackedObject } from 'tracked-built-ins';
import { CreateFieldData, createField, parseFieldData } from 'vault-client/utils/grain-utils';
import { getInvalidElements, isFormValid } from 'vault-client/utils/form-validation';
import { task } from 'ember-concurrency';
import { ModelFrom } from 'vault-client/utils/type-utils';
import { CellComponents, SortObj } from 'vault-client/types/vault-table';
import { Field } from 'vault-client/types/graphql-types';
import BusinessesBusinessFieldsIndexRoute, { FieldsQuery, GET_FIELDS } from 'vault-client/routes/businesses/business/fields';
import resetVaultTableScroll from 'vault-client/utils/reset-vault-table-scroll';

export default class BusinessesBusinessFieldsIndexController extends Controller {
	declare model: ModelFrom<BusinessesBusinessFieldsIndexRoute>;

	@tracked isSidePanelOpen = false;
	@tracked createFieldFormData: CreateFieldData = new TrackedObject({
		name: '',
		acres: '',
		businessId: '',
		error: '',
	}) as CreateFieldData;
	@tracked sorts = [{ valuePath: 'name', isAscending: true }];
	@tracked fieldToRemove: FieldsQuery | null = null;
	@tracked page = 0;
	@tracked size = 100;

	queryParams = ['name', 'acres', 'sorts', 'page', 'size'];

	get isSubmitting(): boolean {
		return this.submitCreateFieldForm.isRunning;
	}

	get query() {
		return GET_FIELDS;
	}

	get disableSubmitButton(): boolean {
		return this.isSubmitting;
	}

	get fields() {
		return this.model.fields.data?.Fields;
	}

	get fieldsColumns() {
		const baseColumns = [
			{
				id: 'ee2fc067-d483-487a-8a2f-06efb39b68e9',
				name: 'Name',
				valuePath: 'name',
				width: 140,
				textAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '72f00a52-e907-4e9d-97c7-ba277430b7f8',
				name: 'Acres',
				valuePath: 'acres',
				cellComponent: CellComponents.String,
				width: 80,
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
				isTotaled: true,
			},
			{
				id: '540d3111-7268-49c5-a33a-c9006d4f27bb',
				name: 'Crop',
				valuePath: 'crop',
				width: 100,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'b8b3b08b-8331-490f-b919-7cc4c2b61069',
				name: 'Production/Ac',
				valuePath: 'productionPerAcre',
				cellComponent: CellComponents.String,
				width: 80,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '0a5a20a1-e71d-40ef-a118-348ba4633758',
				name: 'Total Production',
				valuePath: 'productionTotal',
				cellComponent: CellComponents.String,
				width: 140,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '08c30a47-44af-4e5b-82c9-05f1d5c864b3',
				name: 'Total Revenue',
				valuePath: 'revenueTotal',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				width: 140,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'dbfb396e-3338-480f-bebe-1f9f625d3fec',
				name: 'Total Expenses',
				valuePath: 'expensesTotal',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				width: 140,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '364a1e22-887a-4424-a248-20d708ffd40b',
				name: 'Net P/L',
				valuePath: 'netPL',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				width: 140,
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'c1d1e6d4-3d9e-4f7e-8e3d-0d5f7b5c4f5e',
				name: '',
				minWidth: 80,
				maxWidth: 80,
				width: 80,
				cellComponent: CellComponents.Button,
				componentArgs: {
					style: 'plain',
					iconOnlyButton: true,
					fn: this.removeField,
					centerIconHref: '/icons/Delete-Outline.svg#delete-outline',
					centerIconClass: 'icon-interactive-stroke',
				},
				isSortable: false,
				isVisible: true,
			},
		];

		return baseColumns;
	}

	get fieldsRows() {
		const data = this.model.fields.data?.Fields;
		if (!data) return [];
		return this.itemFn(data);
	}

	get columnTotals() {
		const data = this.model.fields.data?.Fields;
		if (!data) return [];
		let totalAcres = 0;
		data.forEach((field) => {
			totalAcres += field.acres;
		});
		return [{ acres: totalAcres }];
	}

	get totalNumFields() {
		return this.model.fields.data?.FieldCount?.count ?? 0;
	}

	@action
	openSidePanel() {
		this.isSidePanelOpen = true;
	}

	@action
	closeSidePanel() {
		this.isSidePanelOpen = false;
		this.createFieldFormData = {
			name: '',
			acres: '',
			businessId: '',
			error: '',
		};
	}

	itemFn = (fields: Field[]) => {
		return fields.map((field) => {
			return {
				id: field.id,
				name: field.name,
				acres: field.acres,
			};
		});
	};

	submitCreateFieldForm = task({ drop: true }, async () => {
		if (!isFormValid(document)) {
			getInvalidElements(document);
			return;
		}
		let validatedData;
		try {
			validatedData = parseFieldData(this.createFieldFormData);
			validatedData.businessId = this.model.businessId;
		} catch (error) {
			set(this.createFieldFormData, 'error', error.message);
			return;
		}

		// Call mutation to create feed ingredient, use 'call' to pass in 'this' context
		await createField(this, { data: validatedData });
		this.closeSidePanel();
	});

	@action
	updateCreateFieldFormData(key: keyof CreateFieldData, value: CreateFieldData[keyof CreateFieldData]) {
		set(this.createFieldFormData, key, value);
		return;
	}

	@action
	removeField(row: any) {
		this.fieldToRemove = row;
	}

	@action
	closeConfirmation() {
		this.fieldToRemove = null;
	}

	@action
	setSorts(sorts: SortObj[]) {
		this.sorts = sorts;
	}

	@action
	setTablePageState(newPageVal = 0) {
		this.page = newPageVal;
		resetVaultTableScroll('fields-table');
	}
}
