import Route from '@ember/routing/route';
import { tracked } from '@glimmer/tracking';
import { gql, useQuery } from 'glimmer-apollo';
import { GrainCropPlanFilterDTO, Query, Query_GrainCropPlansArgs, SortByDirection } from 'vault-client/types/graphql-types';
import { SortObj } from 'vault-client/types/vault-table';

export const GET_CROP_PLANS = gql`
	query GrainCropPlansIndex($where: GrainCropPlanFilterDTO, $limit: Float, $offset: Float, $orderBy: GrainCropPlanSortByDTO) {
		GrainCropPlans(where: $where, limit: $limit, offset: $offset, orderBy: $orderBy) {
			id
			acres
			aph
			enrolledPercent
			harvestBu
			storageBu
			breakEven
			goal
			hasWriteAccess
			type
			Orders {
				id
				bushels
				futuresMonth
				deliveryStartDate
				deliveryEndDate
				deliveryMonth
				salesType
				futurePrice
				fees
				spread
				basis
				contractNumber
				flatPrice
				type
				Location {
					id
					name
				}
				Buyer {
					id
					name
				}
				... on GrainTargetOrder {
					expirationDate
					Plan {
						... on GrainCropPlan {
							id
							Crop {
								id
								name
							}
							Customer {
								id
								name
							}
						}
					}
				}
			}
			Crop {
				id
				name
			}
			CropYear {
				id
				year
			}
			Customer {
				id
				name
				RelationshipOwner {
					id
					firstName
					lastName
					email
					phoneNumber
				}
			}
		}
		GrainCropPlanCount(where: $where) {
			count
		}
		GrainAdvisors(orderBy: { name: Asc }) {
			id
			name
		}
		GrainCropYears(orderBy: { year: Asc }) {
			id
			year
		}
	}
`;

type GetCropPlans = {
	__typename?: 'Query';
	GrainCropPlans: Query['GrainCropPlans'];
	GrainCropPlanCount: Query['GrainCropPlanCount'];
	GrainAdvisors: Query['GrainAdvisors'];
	GrainCropYears: Query['GrainCropYears'];
};

interface QueryParams {
	page: number;
	size: number;
	sorts: SortObj[];
	selectedAdvisorIds: string[];
	selectedCropYearIds: string[];
	customerId: string | null;
	cropCategoryId: string | null;
}

export default class VgsGrainServicesCropPlansIndex extends Route {
	@tracked variables: any = {};
	// Even with glimmer apollo need to keep refreshModel for csv download
	queryParams = {
		page: { refreshModel: true },
		sorts: { refreshModel: true },
		size: { refreshModel: true },
		selectedAdvisorIds: { refreshModel: true },
		selectedCropYearIds: { refreshModel: true },
		customerId: { refreshModel: true },
		cropCategoryId: { refreshModel: true },
	};

	getCropPlans = useQuery<GetCropPlans, Query_GrainCropPlansArgs>(this, () => [GET_CROP_PLANS, { variables: this.variables }]);

	generateOrderBy(sorts: SortObj[]) {
		const orderBy: {
			[key: string]: any;
		} = {};
		sorts.forEach((sort: any) => {
			const parts = sort.valuePath.split('.');
			const firstPart = parts.shift();
			let value: any = sort.isAscending ? SortByDirection.Asc : SortByDirection.Desc;

			while (parts.length > 0) {
				const part = parts.pop();
				const obj: {
					[key: string]: any;
				} = {};
				obj[part] = value;
				value = obj;
			}

			if (firstPart === 'Crop') {
				orderBy['CropCategory'] = value;
			} else {
				orderBy[firstPart] = value;
			}
		});

		return orderBy;
	}

	generateWhere(params: QueryParams): GrainCropPlanFilterDTO {
		const where: GrainCropPlanFilterDTO = {};

		if (params.selectedAdvisorIds?.length || params.customerId) {
			where.Customer = {
				...(params.selectedAdvisorIds?.length && { relationshipOwnerId: { in: params.selectedAdvisorIds } }),
				...(params.customerId && { id: { equals: params.customerId } }),
			};
		}

		if (params.selectedCropYearIds?.length) {
			where.CropYear = {
				...(params.selectedCropYearIds?.length && {
					id: {
						in: params.selectedCropYearIds,
					},
				}),
			};
		}

		if (params.cropCategoryId) {
			where.cropId = {
				equals: params.cropCategoryId,
			};
		}

		return where;
	}

	async model(params: QueryParams) {
		this.variables = {
			orderBy: this.generateOrderBy(params.sorts),
			where: this.generateWhere(params),
			offset: params.page * params.size,
			limit: params.size,
		};

		await this.getCropPlans.promise;

		return { getCropPlans: this.getCropPlans, variables: this.variables };
	}
}
