// Returns the "realizedPl" for a group of positionAllocationComponents, as well as the remaining open positions, express as an average price and total contracts.

import Big from 'big.js';
import { PositionComponentAllocation } from 'vault-client/types/graphql-types';
import { roundTo } from './round-to';

// PointValue shoud be the correct point value for the instrument (pointValue for futures, optionsUnitValue for options)
function calculateRealizedPnlAndUnrealizedComponents(positionAllocationComponents: PositionComponentAllocation[], pointValue: number) {
	// Separate long and short positions. We're going to determine which components are offsetting each other and what the pnl would be if they did.
	const longPositions = positionAllocationComponents
		.filter((position) => position.netContractQuantity > 0)
		.map((position) => ({
			price: position.price,
			netContractQuantity: position.netContractQuantity,
		}));
	const shortPositions = positionAllocationComponents
		.filter((position) => position.netContractQuantity < 0)
		.map((position) => ({
			price: position.price,
			netContractQuantity: position.netContractQuantity,
		}));

	const longPositionsToOffset = longPositions.slice();
	const shortPositionsToOffset = shortPositions.slice();

	let realizedPl = Big(0);

	while (longPositionsToOffset.length && shortPositionsToOffset.length) {
		const longPositionToOffset = longPositionsToOffset[0];
		const offsettingShortPosition = shortPositionsToOffset[0];

		const longPositionToOffsetQuantity = Big(longPositionToOffset.netContractQuantity);
		const offsettingShortPositionQuantityAbs = Big(offsettingShortPosition.netContractQuantity).abs();
		const longPositionToOffsetPrice = Big(longPositionToOffset.price);
		const offsettingShortPositionPrice = Big(offsettingShortPosition.price);

		const plPerContract = offsettingShortPositionPrice.minus(longPositionToOffsetPrice).times(pointValue);

		// Determine the number of contracts offset based on the smaller position
		const numberOfContractsOffset = longPositionToOffsetQuantity.lt(offsettingShortPositionQuantityAbs)
			? longPositionToOffsetQuantity
			: offsettingShortPositionQuantityAbs;

		// Negative means the short position is larger than the long position.
		const remainingContractsAfterOffset = longPositionToOffsetQuantity.minus(offsettingShortPositionQuantityAbs);

		realizedPl = realizedPl.plus(plPerContract.times(numberOfContractsOffset));

		if (remainingContractsAfterOffset.gt(0)) {
			// Use all of the short position to offset and pop it. Reduce the long positions quantity by the number of contracts offset.
			shortPositionsToOffset.shift();
			longPositionToOffset.netContractQuantity = remainingContractsAfterOffset.toNumber();
		} else if (remainingContractsAfterOffset.lt(0)) {
			// Use all of the long position to offset and pop it. Reduce the short positions quantity by the number of contracts offset.
			longPositionsToOffset.shift();
			offsettingShortPosition.netContractQuantity = remainingContractsAfterOffset.toNumber();
		} else {
			// All contracts on both sides were used. Remove both positions from the offsetting arrays.
			longPositionsToOffset.shift();
			shortPositionsToOffset.shift();
		}
	}

	if (longPositionsToOffset.length && shortPositionsToOffset.length) {
		throw new Error('Offsetting positions failed to clear all short or all long positions.');
	}

	if (longPositionsToOffset.length) {
		return {
			realizedPl: realizedPl.toNumber(),
			...calculateUnrealizedAveragePriceAndTotalContacts(longPositionsToOffset, false),
		};
	} else {
		return {
			realizedPl: realizedPl.toNumber(),
			...calculateUnrealizedAveragePriceAndTotalContacts(shortPositionsToOffset, true),
		};
	}
}

function calculateUnrealizedAveragePriceAndTotalContacts(
	positionAllocationComponents: {
		price: number;
		netContractQuantity: number;
	}[],
	isShort: boolean
) {
	// Use a weighted average to consolidate the "open" positions
	const totalContracts = positionAllocationComponents.reduce((total, position) => total + Math.abs(position.netContractQuantity), 0);
	const totalValue = positionAllocationComponents.reduce(
		(total, position) => total + Math.abs(position.netContractQuantity) * position.price,
		0
	);

	return {
		unrealizedAvgPrice: roundTo(totalContracts ? totalValue / totalContracts : 0, 5),
		unrealizedTotalContracts: isShort ? -totalContracts : totalContracts,
	};
}

export { calculateRealizedPnlAndUnrealizedComponents, calculateUnrealizedAveragePriceAndTotalContacts };
