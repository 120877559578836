import Service from '@ember/service';
import { tracked } from '@glimmer/tracking';

export default class AccessibilityService extends Service {
	@tracked isAccessibilityModeEnabled = false;

	constructor(args: Object) {
		super(args);
		this.initializeAccessibilityMode();
	}

	toggleAccessibilityMode() {
		this.isAccessibilityModeEnabled = !this.isAccessibilityModeEnabled;
		localStorage.setItem('accessibilityMode', JSON.stringify(this.isAccessibilityModeEnabled));
		this.updateBodyClass();
	}

	updateBodyClass() {
		if (this.isAccessibilityModeEnabled) {
			document.body.classList.add('larger-table-font-size');
		} else {
			document.body.classList.remove('larger-table-font-size');
		}
	}

	initializeAccessibilityMode() {
		const savedMode = localStorage.getItem('accessibilityMode');

		if (savedMode !== null) {
			this.isAccessibilityModeEnabled = JSON.parse(savedMode);
			this.updateBodyClass();
		}
	}
}

// Don't remove this declaration: this is what enables TypeScript to resolve
// this service using `Owner.lookup('service:accessibility')`, as well
// as to check when you pass the service name as an argument to the decorator,
// like `@service('accessibility') declare altName: AccessibilityService;`.
declare module '@ember/service' {
	interface Registry {
		accessibility: AccessibilityService;
	}
}
