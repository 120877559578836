import ReportsCheeseBlockStrategicBuyingModel from 'vault-client/controllers/reports/cheese-block-strategic-buying-model';

export default class BusinessesBusinessCheeseBlockStrategicBuyingModel extends ReportsCheeseBlockStrategicBuyingModel {
	reportsRoute: string = 'businesses.business.reports';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'businesses/business/cheese-block-strategic-buying-model': BusinessesBusinessCheeseBlockStrategicBuyingModel;
	}
}
