import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import { gql, useMutation } from 'glimmer-apollo';
import PermissionsService from 'vault-client/services/permissions';
import { GrainFillOrder, Mutation, GrainOrderNoteCreateDTO } from 'vault-client/types/graphql-types';

const ADD_FILL_NOTE_MUTATION = gql`
	mutation CreateGrainOrderNote($data: GrainOrderNoteCreateDTO!) {
		createGrainOrderNote(data: $data) {
			id
		}
	}
`;

interface AddFillNoteFormArgs {
	fill: GrainFillOrder;
}

interface CreateNoteMutationArgs {
	data: GrainOrderNoteCreateDTO;
}

export default class AddFillNoteForm extends Component<AddFillNoteFormArgs> {
	@service declare permissions: PermissionsService;

	@tracked note: string | null = null;
	@tracked errorMessage: string | null = null;

	addNoteMutation = useMutation<{ createGrainOrderNote: Mutation['createGrainOrderNote'] }, CreateNoteMutationArgs>(this, () => [
		ADD_FILL_NOTE_MUTATION,
		{
			onComplete: () => {
				this.errorMessage = null;
			},
			onError: (error): void => {
				this.errorMessage = 'There was an error. Note not added.';
				console.error('Error while attempting to add a note on fill.', error.message);
			},
			update: (cache) => {
				cache.evict({ fieldName: 'GrainOrderNote' });
				cache.evict({ fieldName: 'GrainOrderNotes' });
				cache.evict({ fieldName: 'GrainFillOrder' });
				cache.evict({ fieldName: 'GrainFillOrders' });
				cache.gc();
			},
		},
	]);

	get isLoading() {
		return this.addNoteMutation.loading;
	}

	get isDisabled() {
		return !this.note?.trim() || this.isLoading;
	}

	@action
	async submit() {
		const variables: CreateNoteMutationArgs = {
			data: {
				orderId: this.args.fill.id,
				note: this.note ?? '',
				writtenBy: this.permissions.userName ?? '',
			},
		};

		await this.addNoteMutation.mutate(variables);

		this.note = null;
	}
}
