import InsuranceDocumentsIndex from 'vault-client/routes/insurance-documents';

interface ModelParams {
	aipId: string | null;
	page: number;
	policyNumber: string | null;
	sorts: SortObj[];
	id: string;
	customerId?: string;
	size: number;
}

type SortObj = {
	valuePath: string;
	isAscending: boolean;
};

export default class BusinessesInsuranceDocumentsRoute extends InsuranceDocumentsIndex {
	async model(params: ModelParams) {
		const businessParams = this.paramsFor('businesses.business') as { business_id: string };
		const variables = {
			where: this.generateWhere(null, businessParams.business_id, params.aipId, params.policyNumber),
			orderBy: this.generateOrderBy(params.sorts),
			offset: params.page * params.size,
			limit: params.size,
		};

		const results = await this.apollo.watchQuery({ query: this.query, variables });

		return {
			...results,
			variables,
		};
	}
}
