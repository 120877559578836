import Controller from '@ember/controller';
import { action } from '@ember/object';
// eslint-disable-next-line ember/no-computed-properties-in-native-classes
import { alias } from '@ember/object/computed';
import { guidFor } from '@ember/object/internals';
import { tracked } from '@glimmer/tracking';
import { type Chart, ChartData, ChartOptions } from 'chart.js';
import BusinessesBusinessInventoryHedgesByProduct from 'vault-client/routes/businesses/business/inventory-hedges-by-product';
import OrganizationsOrganizationInventoryHedgesByProduct from 'vault-client/routes/organizations/organization/inventory-hedges-by-product';
import { Query } from 'vault-client/types/graphql-types';
import { TableColumn, CellComponents } from 'vault-client/types/vault-table';
import { getCustomLegend, getCustomTooltip, CustomTooltipOptions } from 'vault-client/utils/chart-utils';
import isTouchDevice from 'vault-client/utils/is-touch-device';
import { ModelFrom } from 'vault-client/utils/type-utils';

interface currentInventoryObj {
	name: string;
	timestamp: any;
	quantity: number;
}

interface entityExposureObj {
	date: string;
	volumeHedged: number;
}

export default class ReportsInventoryHedgesByProduct extends Controller {
	@tracked organizationId: string | null = null;
	@tracked globalCustomerId: string | null = null;
	@tracked locationId: string | null = null;
	declare model: ModelFrom<BusinessesBusinessInventoryHedgesByProduct> | ModelFrom<OrganizationsOrganizationInventoryHedgesByProduct>;
	reportsRoute: string = '';

	get chartId() {
		return `inventory-hedges-by-product-chart-${guidFor(this)}`;
	}

	get legendId() {
		return `inventory-hedges-by-product-chart-legend-${guidFor(this)}`;
	}

	get columns(): TableColumn[] {
		const baseColumns: TableColumn[] = [
			{
				id: '54cdfb2b-9fa2-45b3-8668-edbe3f45b332',
				name: 'Month',
				valuePath: 'date',
				minWidth: 200,
				maxWidth: 400,
				cellComponent: CellComponents.MonthFormat,
				textAlign: 'left',
				isSortable: false,
				isFixed: !isTouchDevice() ? 'left' : '',
				isVisible: true,
			},
			{
				id: 'ba499832-f8c4-4885-8f04-5b64f2ac3a66',
				name: 'Pounds Hedged per Month',
				valuePath: 'volumeHedged',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					maximumFractionDigits: '0',
				},
				minWidth: 212,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'b23ff58d-a9dd-4d12-8786-4277aaa46883',
				name: 'Percent Hedged per Month',
				valuePath: 'percentHedged',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'percent',
					minimumFractionDigits: '2',
				},
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
		];
		return baseColumns;
	}

	@alias('model.EntityAllocatedExposureRatios') declare exposureRatios: Query['EntityAllocatedExposureRatios'];

	get currentInventory() {
		const obj: currentInventoryObj = {
			quantity: this.model.CurrentInventoryLevels.reduce((acc: number, cur) => acc + cur.quantity, 0),
			name: this.model.CurrentInventoryLevels[0].Product.name,
			timestamp: this.model.CurrentInventoryLevels.map((item) => item.timestamp)
				.sort((a: string, b: string) => (a > b ? 1 : -1))
				.pop(),
		};
		return obj;
	}

	get entityExposures() {
		let exposureRatiosArr: entityExposureObj[] = [];

		if (!this.organizationId && !this.globalCustomerId) {
			// if user is in org level with org permission (user with multiple customer access)
			this.exposureRatios.forEach((item) => {
				const idx = exposureRatiosArr.findIndex((arrItem) => arrItem.date === item.date);
				if (idx >= 0) {
					exposureRatiosArr[idx].volumeHedged = exposureRatiosArr[idx].volumeHedged + (item.totalVolumeHedged ?? 0);
				} else {
					exposureRatiosArr.push({
						date: item.date,
						volumeHedged: item.totalVolumeHedged ?? 0,
					});
				}
			});
		} else {
			exposureRatiosArr = this.exposureRatios.map((item) => ({ date: item.date, volumeHedged: item.totalVolumeHedged ?? 0 }));
		}
		return exposureRatiosArr.map((item) => {
			return {
				date: item.date,
				volumeHedged: item.volumeHedged || 0,
				percentHedged: item.volumeHedged / this.currentInventory.quantity,
			};
		});
	}

	get totalPercentHedged() {
		const sumVolumeHedged = this.exposureRatios.reduce((acc: number, cur) => acc + (cur.totalVolumeHedged ?? 0), 0);
		return sumVolumeHedged / this.currentInventory.quantity;
	}

	get totalPercentOpen() {
		return 1 - this.totalPercentHedged;
	}

	get barData(): ChartData {
		return {
			labels: [''],
			datasets: [
				{
					label: 'Inventory Hedged',
					data: [this.totalPercentHedged],
					backgroundColor: '#efdf9a', // --brand-lemon-20
					borderColor: '#4f4100', // --brand-lemon-80
					borderWidth: 1,
					fill: false,
				},
				{
					label: 'Inventory Open',
					data: [this.totalPercentOpen],
					backgroundColor: '#d9e4e9', // --brand-blue-20,
					borderColor: '#274856', // --brand-blue-70
					borderWidth: 1,
					fill: false,
				},
			],
		};
	}

	get stackedBarOptions(): ChartOptions {
		const customTooltipOptions: CustomTooltipOptions = {
			valueFormatter: (val: number | string) => {
				if (val == null) return '-';
				if (typeof val === 'string') return val;
				return Intl.NumberFormat('en-US', {
					style: 'percent',
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
					currencySign: 'accounting',
				}).format(val / 1);
			},
		};

		return {
			// Horizontal Bar Chart
			indexAxis: 'y',
			maintainAspectRatio: false,
			responsive: true,
			interaction: {
				mode: 'index',
			},
			scales: {
				x: {
					min: 0,
					max: 1,
					ticks: {
						color: getComputedStyle(document.documentElement).getPropertyValue('--brand-gray-90'),
						font: {
							size: 12,
						},
						stepSize: 0.1,
						callback: function (value: number) {
							return Intl.NumberFormat('en-US', {
								style: 'percent',
								minimumFractionDigits: 0,
								maximumFractionDigits: 0,
								currencySign: 'accounting',
							}).format(value);
						},
					},
					grid: {
						color: getComputedStyle(document.documentElement).getPropertyValue('--brand-gray-20'),
					},
				},
			},
			plugins: {
				tooltip: {
					external: getCustomTooltip(customTooltipOptions),
					enabled: false,
				},
				legend: {
					display: false,
				},
			},
		};
	}

	get chartPlugins() {
		const chartId = this.chartId;
		const legendId = this.legendId;
		return [
			{
				afterUpdate: getCustomLegend(chartId, legendId),
			},
		];
	}

	@action
	updateChart(chart: Chart) {
		chart.data.datasets = this.barData.datasets;
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'reports/inventory-hedges-by-product': ReportsInventoryHedgesByProduct;
	}
}
