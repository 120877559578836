import AccountsIndexController from 'vault-client/controllers/accounts';

export default class BusinessesBusinessAccounts extends AccountsIndexController {
	accountRoutePath: string = 'businesses.business.account';
	brokerageRoutePath: string = 'businesses.business.brokerage';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'businesses/business/accounts': BusinessesBusinessAccounts;
	}
}
