import Route from '@ember/routing/route';
import {
	Query,
	LedgerEntryAggregateDTO,
	LedgerEntryFilterDTO,
	LedgerEntryGroupByDTO,
	LedgerExpenseCategory,
	LedgerExpenseCategoryFilterDTO,
	TypeOfLedgerCategory,
	ILedgerCategory,
} from 'vault-client/types/graphql-types';
import { useQuery, gql } from 'glimmer-apollo';
import { tracked } from '@glimmer/tracking';
interface modelParams {
	id: string;
	startDate: string;
	endDate: string;
}

const GET_EXPENSE = gql`
	query AggregateLedgerEntries(
		$calc: LedgerEntryAggregateDTO!
		$groupBy: LedgerEntryGroupByDTO!
		$totalsGroupBy: LedgerEntryGroupByDTO!
		$where: LedgerEntryFilterDTO
		$categoriesWhere: LedgerExpenseCategoryFilterDTO
		$customerId: String!
	) {
		ExpenseEntries: AggregateLedgerEntries(calc: $calc, groupBy: $groupBy, where: $where, orderBy: { month: Asc, year: Asc }) {
			sum {
				amount
				calculatedAmount
				calculationQuantity
			}
			avg {
				amount
			}
			type
			categoryId
			LedgerCategory {
				name
				description
				calculationType
				isStatic
			}
			month
			year
		}
		ExpenseEntryTotals: AggregateLedgerEntries(calc: $calc, groupBy: $totalsGroupBy, where: $where) {
			sum {
				amount
				calculatedAmount
				calculationQuantity
			}
			type
			categoryId
			LedgerCategory {
				name
				isStatic
			}
		}
		LedgerExpenseCategories(where: $categoriesWhere) {
			id
			name
			calculationType
			isStatic
		}
		Customer(id: $customerId) {
			id
			name
			businessRoles
			Locations {
				id
				name
			}
		}
	}
`;

export type GetExpenseQuery = {
	__typename?: 'Query';
	ExpenseEntries: Query['AggregateLedgerEntries'];
	ExpenseEntryTotals: {
		sum: {
			amount: number;
		};
		type: string;
		LedgerCategory: ILedgerCategory;
	}[];
	LedgerExpenseCategories: LedgerExpenseCategory[];
	Customer: Query['Customer'];
};

type GetExpenseQueryVariables = {
	calc: LedgerEntryAggregateDTO;
	groupBy: LedgerEntryGroupByDTO;
	totalsGroupBy: LedgerEntryGroupByDTO;
	where?: LedgerEntryFilterDTO;
	categoriesWhere?: LedgerExpenseCategoryFilterDTO;
	customerId: string;
};

export default class BusinessesBusinessExpensesRoute extends Route {
	groupBy: LedgerEntryGroupByDTO = {
		categoryId: true,
		type: true,
		LedgerCategory: {
			name: true,
			isStatic: true,
			description: true,
			calculationType: true,
		},
		month: true,
		year: true,
	};

	totalsGroupBy: LedgerEntryGroupByDTO = {
		categoryId: true,
		type: true,
		LedgerCategory: {
			name: true,
			isStatic: true,
		},
	};

	calc: LedgerEntryAggregateDTO = {
		sum: {
			amount: true,
			calculatedAmount: true,
			calculationQuantity: true,
		},
		avg: {
			amount: true,
		},
	};

	@tracked variables: GetExpenseQueryVariables = {
		calc: this.calc,
		groupBy: this.groupBy,
		totalsGroupBy: this.totalsGroupBy,
		customerId: (this.paramsFor('businesses.business') as { business_id: string }).business_id,
	};

	getExpenses = useQuery<GetExpenseQuery, GetExpenseQueryVariables>(this, () => [GET_EXPENSE, { variables: this.variables }]);

	generateWhere(businessId: string, startDate: string | null, endDate: string | null): LedgerEntryFilterDTO {
		const where: LedgerEntryFilterDTO = {
			LedgerCategory: {
				type: {
					equals: TypeOfLedgerCategory.Expense,
				},
			},
		};

		where.entityId = { equals: businessId };

		if (startDate) {
			if (!where.AND) where.AND = [];
			where.AND.push({
				date: {
					gte: startDate,
				},
			});
		}

		if (endDate) {
			if (!where.AND) where.AND = [];
			where.AND.push({
				date: {
					lte: endDate,
				},
			});
		}

		return where;
	}

	queryParams = {
		startDate: {
			refreshModel: true,
		},
		endDate: {
			refreshModel: true,
		},
	};

	async model(params: modelParams) {
		const businessParams = this.paramsFor('businesses.business') as { business_id: string };

		this.variables = {
			calc: this.calc,
			groupBy: this.groupBy,
			totalsGroupBy: this.totalsGroupBy,
			where: this.generateWhere(businessParams.business_id, params.startDate, params.endDate),
			categoriesWhere: {
				customerId: {
					equals: businessParams.business_id,
				},
			},
			customerId: businessParams.business_id,
		};

		await this.getExpenses.promise;

		return {
			businessId: businessParams.business_id,
			getExpenses: this.getExpenses,
		};
	}
}
