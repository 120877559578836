import Controller from '@ember/controller';
import StrategicInventoryModel from '../../models/strategic-inventory-model';
import { getOwner } from '@ember/application';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
//@ts-ignore
import { Product } from 'types/graphql-types';
import isTouchDevice from 'vault-client/utils/is-touch-device';
import { TableColumn, CellComponents } from 'vault-client/types/vault-table';
import ReportsButterStrategicInventoryModelRoute from 'vault-client/routes/reports/butter-strategic-inventory-model';
import { ModelFrom } from 'vault-client/utils/type-utils';

interface HtmlElement {
	target: HTMLInputElement;
}

export default class ReportsButterStrategicInventoryModel extends Controller {
	@tracked carryCost: number = 0.01;
	@tracked currentPriceInput: string = 'Front Future Price';
	@tracked numberOfYears: number = 5;
	reportsRoute: string = '';
	declare model: ModelFrom<ReportsButterStrategicInventoryModelRoute>;
	get columns(): TableColumn[] {
		const baseColumns: TableColumn[] = [
			{
				id: '4b5442b1-1903-4c07-92da-94a3c2eec332',
				name: 'Month',
				valuePath: 'displayExpiresAt',
				minWidth: 90,
				cellComponent: CellComponents.MonthFormat,
				textAlign: 'left',
				isSortable: false,
				isFixed: !isTouchDevice() ? 'left' : '',
				isVisible: true,
			},
			{
				id: '42fb466f-f183-4f0c-b683-5d7411dd4fea',
				name: 'Futures',
				valuePath: 'currentFuturePrice',
				minWidth: 110,
				cellComponent: CellComponents.PriceFormat,
				componentArgs: {
					fractionDigitsPath: 'product.MostCurrentFuture.SymbolGroup.fractionDigits',
					displayFactorPath: 'noDisplayFactor',
				},
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '561e6dc4-60c7-49cc-bc88-7b0d52bafd21',
				name: 'Futures - Carry',
				valuePath: 'futuresMinusCarryCost',
				minWidth: 150,
				cellComponent: CellComponents.PriceFormat,
				componentArgs: {
					fractionDigitsPath: 'product.MostCurrentFuture.SymbolGroup.fractionDigits',
					displayFactorPath: 'noDisplayFactor',
				},
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '08d6e15a-782e-47c1-84bd-40e5fb730b52',
				name: 'Current',
				valuePath: 'currentPrice',
				minWidth: 120,
				cellComponent: CellComponents.PriceFormat,
				componentArgs: {
					fractionDigitsPath: 'product.MostCurrentFuture.SymbolGroup.fractionDigits',
					displayFactorPath: 'noDisplayFactor',
				},
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'f70fe613-4d9c-49a0-9e67-cab94a76aa65',
				name: '1 Mo Carry Incentive',
				valuePath: 'oneMonthCarryIncentive',
				minWidth: 200,
				cellComponent: CellComponents.PriceFormat,
				componentArgs: {
					fractionDigitsPath: 'product.MostCurrentFuture.SymbolGroup.fractionDigits',
					displayFactorPath: 'noDisplayFactor',
				},
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'fd62d4a6-9517-4d98-b82c-80e6acc0fa3f',
				name: 'Strategy',
				valuePath: 'riskManagementStrategy',
				minWidth: 120,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
		];
		return baseColumns;
	}

	prevSims: {
		oneMonthCarryIncentive: number | null;
		displayExpiresAt: string;
		symbol: string;
	}[] = [];

	get spotProduct() {
		const products: Product[] = this.model.Products;
		const cashProduct = products?.find((product: Product) => product?.slug === 'us-dairy-butter-grade-aa');
		return cashProduct ?? null;
	}

	get futuresProduct() {
		const products: Product[] = this.model.Products;
		return products?.find((product) => product.slug === 'us-dairy-butter');
	}

	get frontFuture() {
		return this.futuresProduct?.CurrentFutures[0];
	}

	get months() {
		const owner = getOwner(this);
		const product = this.futuresProduct;
		return product?.CurrentFutures?.reduce((acc: any, future: any) => {
			const previousSims = acc.slice(-2);
			const currentSim = new StrategicInventoryModel(
				owner,
				product,
				future,
				this.carryCost,
				previousSims,
				this.spotProduct,
				this.currentPriceInput,
				this.numberOfYears
			);

			acc.push(currentSim);
			return acc;
		}, []);
	}

	@action
	currentPriceRadioInput(e: HtmlElement) {
		return (this.currentPriceInput = e.target.value);
	}
}
// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'reports/butter-strategic-inventory-model': ReportsButterStrategicInventoryModel;
	}
}
