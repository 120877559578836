import UnmappedAccountsController from 'vault-client/controllers/reports/unmapped-accounts';

export default class BusinessesBusinessUnmappedAccountsController extends UnmappedAccountsController {
	accountRoutePath: string = 'businesses.business.account';
	reportsRoutePath: string = 'businesses.business.reports';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'businesses/business/unmapped-accounts': BusinessesBusinessUnmappedAccountsController;
	}
}
