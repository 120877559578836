import Route from '@ember/routing/route';
import { tracked } from '@glimmer/tracking';
import { gql, useQuery } from 'glimmer-apollo';
import { GrainFeedPlanFilterDTO, FeedCategory, Query, Query_GrainFeedPlansArgs, SortByDirection } from 'vault-client/types/graphql-types';

type SortObj = {
	valuePath: string;
	isAscending: boolean;
};

export const GET_FEED_PLANS = gql`
	query VGSGrainFeedPlans($limit: Float, $offset: Float, $orderBy: GrainFeedPlanSortByDTO, $where: GrainFeedPlanFilterDTO) {
		GrainFeedPlans(limit: $limit, offset: $offset, orderBy: $orderBy, where: $where) {
			id
			tons
			totalTonsFlatPriced
			percentPriced
			basisOnlyPurchased
			basisExposure
			percentBasisExposure
			totalTonsOpen
			percentTonsOpen
			futuresOnlyPurchased
			futuresExposure
			percentFuturesExposure
			hasWriteAccess
			type
			feedYear
			Customer {
				id
				name
			}
			breakEven
			customerId
			goal
			feedCategoryId
			FeedCategory {
				id
				name
			}
			startDate
			tons
			Orders(orderBy: { createdAt: Desc }) {
				id
				type
				deliveryTerms
				bushels
				futuresMonth
				deliveryStartDate
				deliveryEndDate
				deliveryMonth
				salesType
				futurePrice
				flatPrice
				fees
				spread
				basis
				contractNumber
				Seller {
					id
					name
				}
				Location {
					id
					name
				}
				DeliveryLocation {
					id
					name
				}
				... on GrainTargetOrder {
					expirationDate
				}
			}
		}
		GrainFeedPlanCount(where: $where) {
			count
		}
		FeedCategories(orderBy: { name: Asc }) {
			id
			name
		}
	}
`;

type GetFeedPlansQuery = {
	__typename?: 'Query';
	GrainFeedPlans: Query['GrainFeedPlans'];
	GrainFeedPlanCount: Query['GrainFeedPlanCount'];
	FeedCategories: FeedCategory[];
};

interface QueryParams {
	page: number;
	size: number;
	sorts: SortObj[];
	customerId: string;
	selectedFeedCategoryIds: string[];
	startDateStart: string;
	startDateEnd: string;
	month: string | null;
	selectedFeedYears: number[];
}

export default class VgsFeedServicesFeedPlansIndex extends Route {
	@tracked variables: Query_GrainFeedPlansArgs = {};
	// Even with glimmer apollo need to keep refreshModel for csv download
	queryParams = {
		page: { refreshModel: true },
		sorts: { refreshModel: true },
		size: { refreshModel: true },
		customerId: { refreshModel: true },
		month: { refreshModel: true },
		selectedFeedYears: { refreshModel: true },
		selectedFeedCategoryIds: { refreshModel: true },
	};

	getFeedPlans = useQuery<GetFeedPlansQuery, Query_GrainFeedPlansArgs>(this, () => [GET_FEED_PLANS, { variables: this.variables }]);

	generateOrderBy(sorts: SortObj[]) {
		const orderBy: {
			[key: string]: any;
		} = {};
		sorts.forEach((sort: any) => {
			// allow sorting by 'id'
			sort.valuePath === 'feedPlanDisplayId' ? (sort.valuePath = 'id') : sort.valuePath;
			const parts = sort.valuePath.split('.');
			const firstPart = parts.shift();
			let value: any = sort.isAscending ? SortByDirection.Asc : SortByDirection.Desc;

			while (parts.length > 0) {
				const part = parts.pop();
				const obj: {
					[key: string]: any;
				} = {};
				obj[part] = value;
				value = obj;
			}

			orderBy[firstPart] = value;
		});

		return orderBy;
	}

	generateWhere(params: QueryParams): GrainFeedPlanFilterDTO {
		const where: GrainFeedPlanFilterDTO = {};

		if (params.customerId) {
			where.customerId = {
				equals: params.customerId,
			};
		}

		if (params.selectedFeedCategoryIds?.length) {
			where.feedCategoryId = {
				in: params.selectedFeedCategoryIds,
			};
		}

		if (params.month) {
			where.startDateMonth = {
				equals: parseInt(params.month),
			};
		}

		if (params.selectedFeedYears.length) {
			where.feedYear = {
				...(params.selectedFeedYears?.length && { in: params.selectedFeedYears }),
			};
		}

		return where;
	}

	async model(params: QueryParams) {
		this.variables = {
			orderBy: this.generateOrderBy(params.sorts),
			offset: params.page * params.size,
			limit: params.size,
			where: this.generateWhere(params),
		};

		await this.getFeedPlans.promise;

		return { getFeedPlans: this.getFeedPlans, variables: this.variables };
	}
}
