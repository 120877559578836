import Controller from '@ember/controller';
import VgsGrainServicesModificationRequestsRoute from 'vault-client/routes/vgs/grain-services/modification-requests';
import { ModelFrom } from 'vault-client/utils/type-utils';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import PermissionsService from 'vault-client/services/permissions';
import { GrainFillOrderModificationStatus } from 'vault-client/types/graphql-types';
export default class VgsGrainServicesModificationRequests extends Controller {
	@service declare permissions: PermissionsService;
	declare model: ModelFrom<VgsGrainServicesModificationRequestsRoute>;

	queryParams = ['status'];

	@tracked status: GrainFillOrderModificationStatus | null = GrainFillOrderModificationStatus.New;

	get modificationRequests() {
		return this.model?.grainRequests?.data?.GrainFillOrderModificationRequests ?? null;
	}

	@action
	setModStatus(status: GrainFillOrderModificationStatus | 'All') {
		this.status = status === 'All' ? null : status;
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	interface Registry {
		'vgs/grain-services/modification-requests': VgsGrainServicesModificationRequests;
	}
}
