import TransactionsShowController from 'vault-client/controllers/transactions/show';

export default class BusinessesBusinessTransaction extends TransactionsShowController {
	accountRoute: string = 'businesses.business.account';
	transactionsRoutePath: string = 'businesses.business.transactions';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'businesses/business/transaction': BusinessesBusinessTransaction;
	}
}
