import Controller from '@ember/controller';
import { action } from '@ember/object';
import { guidFor } from '@ember/object/internals';
import { Chart, ChartData, ChartDataset, ChartOptions } from 'chart.js';
import { DateTime } from 'luxon';
import { tracked } from 'tracked-built-ins';
import BusinessesBusinessPigMarketingRoute from 'vault-client/routes/businesses/business/pig-marketing';
import { CellComponents, TableColumn } from 'vault-client/types/vault-table';
import { ModelFrom } from 'vault-client/utils/type-utils';
import { getCustomTooltip, CustomTooltipOptions, getCustomLegend } from 'vault-client/utils/chart-utils';
import { intervalFromDateTime } from 'vault-client/utils/interval-from-date-time';
import { roundTo } from 'vault-client/utils/round-to';

enum DisplayUnits {
	Total = 'Total',
	PerHead = 'Per Head',
	PerCWT = 'Per CWT',
}

interface ProjectedRevenueRow {
	date: string;
	pigsSold: number | null;
	pigsSoldPerWeek: number | null;
	pricePerHead: number | null;
	totalRevenue: number | null;
	netPnl: number | null;
}

class ProjectedRevenueMonth {
	date: string;
	pigsSold: number | null = null;
	salesRevenue: number | null = null;
	hedgePnl: number | null = null;
	avgLbsPerPig: number | null = null;

	constructor(date: string, avgLbsPerPig: number | null) {
		this.date = date;
		this.avgLbsPerPig = avgLbsPerPig;
	}

	get numWeeks() {
		return DateTime.fromISO(this.date).endOf('month').diff(DateTime.fromISO(this.date), 'weeks').weeks;
	}

	get pigsSoldPerWeek() {
		if (this.pigsSold == null) return null;

		return this.pigsSold / this.numWeeks;
	}

	get totalLbs() {
		if (this.pigsSold == null || this.avgLbsPerPig == null) return null;
		return this.pigsSold * this.avgLbsPerPig;
	}

	get netPnl() {
		if (this.salesRevenue == null && this.hedgePnl == null) return null;
		return (this.salesRevenue ?? 0) + (this.hedgePnl ?? 0);
	}

	get netPnlPerHead() {
		return this.calculateValuePerHead(this.netPnl);
	}

	get netPnlCwt() {
		return this.calculateValuePerCwt(this.netPnl);
	}

	get salesRevenuePerHead() {
		return this.calculateValuePerHead(this.salesRevenue);
	}

	get salesRevenueCwt() {
		return this.calculateValuePerCwt(this.salesRevenue);
	}

	get hedgePnlPerHead() {
		return this.calculateValuePerHead(this.hedgePnl);
	}

	get hedgePnlCwt() {
		return this.calculateValuePerCwt(this.hedgePnl);
	}

	calculateValuePerHead(value: number | null) {
		if (value == null || this.pigsSold == null) return null;
		if (this.pigsSold === 0) return 0;

		return value / this.pigsSold;
	}

	calculateValuePerCwt(value: number | null) {
		if (value == null || this.totalLbs == null) return null;
		if (this.totalLbs === 0) return 0;

		return value / (this.totalLbs / 100);
	}
}

export default class BusinessesBusinessPigsMarketingController extends Controller {
	@tracked selectedDisplayUnit = DisplayUnits.Total;
	@tracked startDate = DateTime.local().minus({ days: 15 }).startOf('month').toISODate();
	@tracked endDate = DateTime.local().plus({ months: 12 }).endOf('month').toISODate();
	declare model: ModelFrom<BusinessesBusinessPigMarketingRoute>;
	id = guidFor(this);

	queryParams = ['startDate', 'endDate'];

	dateRangeOptions = [
		{
			displayName: 'Previous 24 Months',
			startDate: DateTime.local().minus({ months: 25 }).startOf('month').toISODate(),
			endDate: DateTime.local().endOf('month').toISODate(),
		},
		{
			displayName: 'Previous 12 Months',
			startDate: DateTime.local().minus({ months: 12 }).startOf('month').toISODate(),
			endDate: DateTime.local().endOf('month').toISODate(),
		},
		{
			displayName: 'Previous 3 Months',
			startDate: DateTime.local().minus({ months: 3 }).startOf('month').toISODate(),
			endDate: DateTime.local().endOf('month').toISODate(),
		},
		{
			displayName: 'Next 3 Months',
			startDate: DateTime.local().minus({ days: 15 }).startOf('month').toISODate(),
			endDate: DateTime.local().plus({ months: 3 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Next 12 Months',
			startDate: DateTime.local().minus({ days: 15 }).startOf('month').toISODate(),
			endDate: DateTime.local().plus({ months: 12 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Next 24 Months',
			startDate: DateTime.local().minus({ days: 15 }).startOf('month').toISODate(),
			endDate: DateTime.local().plus({ months: 24 }).endOf('month').toISODate(),
		},
	];

	get currentDateRange() {
		return {
			startDate: this.startDate,
			endDate: this.endDate,
		};
	}

	get currentDates() {
		const startDateTime = DateTime.fromISO(this.startDate);
		const endDateTime = DateTime.fromISO(this.endDate);
		return intervalFromDateTime(startDateTime, endDateTime, {
			month: 1,
		}).filter((dateTime) => dateTime <= endDateTime);
	}

	get lastUpdatedAtString() {
		const lastUpdatedAt = DateTime.fromISO(this.model.lastUpdatedAt);

		return `Last updated: ${lastUpdatedAt.toLocaleString(DateTime.DATE_SHORT)} at ${lastUpdatedAt.toLocaleString(DateTime.TIME_SIMPLE)}`;
	}

	get projectedRevenueChartId() {
		return `projected-revenue-chart-${this.id}`;
	}

	get projectedRevenueLegendId() {
		return `projected-revenue-chart-legend-${this.id}`;
	}

	get averageFinishWeight() {
		return this.model.getPigsMarketing.data?.Customer.averageFinishWeightInLbs;
	}

	get projectedRevenueMonths() {
		const projectedRevenueMonthsMap: Map<string, ProjectedRevenueMonth> = new Map();

		this.currentDates.forEach((date) => {
			const dateString = date.toISODate();

			projectedRevenueMonthsMap.set(dateString, new ProjectedRevenueMonth(dateString, this.averageFinishWeight ?? null));
		});

		this.model.getPigsMarketing.data?.AggregateCurrentAllocationPositions.forEach((position) => {
			const effectiveHedgeDate = position.effectiveHedgeDate;
			const grossPnl = position.sum.grossPnl ?? 0;
			if (!effectiveHedgeDate || grossPnl == null) return;

			const startOfMonth = DateTime.fromISO(effectiveHedgeDate).startOf('month').toISODate();

			const projectedRevenueMonth = projectedRevenueMonthsMap.get(startOfMonth);
			if (!projectedRevenueMonth) return;

			projectedRevenueMonth.hedgePnl = projectedRevenueMonth.hedgePnl ? projectedRevenueMonth.hedgePnl + grossPnl : grossPnl;
		});

		this.model.getPigsMarketing.data?.AggregateSwineLivestockPopulationChanges.forEach((populationChange) => {
			const date = populationChange.date;
			const pigsSold = populationChange.sum.quantity != null ? Math.abs(populationChange.sum.quantity) : null;
			const totalValue = populationChange.sum.totalValue != null ? Math.abs(populationChange.sum.totalValue) : null;
			if (!date || (pigsSold == null && totalValue == null)) return;

			const startOfMonth = DateTime.fromISO(date).startOf('month').toISODate();
			const projectedRevenueMonth = projectedRevenueMonthsMap.get(startOfMonth);
			if (!projectedRevenueMonth) return;

			projectedRevenueMonth.pigsSold = projectedRevenueMonth.pigsSold ? projectedRevenueMonth.pigsSold + (pigsSold ?? 0) : pigsSold ?? 0;
			projectedRevenueMonth.salesRevenue = projectedRevenueMonth.salesRevenue
				? projectedRevenueMonth.salesRevenue + (totalValue ?? 0)
				: totalValue ?? 0;
		});

		this.model.getPigsMarketing.data?.AggregateLrpInsuranceEndorsements.forEach((lrpEndorsement) => {
			const startDate = lrpEndorsement.salesEffectiveDate;
			const endDate = lrpEndorsement.coverageEndDate;
			const estimatedPnl = lrpEndorsement.sum.pnl;

			if (!startDate || !endDate || estimatedPnl == null) return;

			const startDateTime = DateTime.fromISO(startDate);
			const endDateTime = DateTime.fromISO(endDate);

			const relevantWeeksDateTime = intervalFromDateTime(startDateTime.startOf('week'), endDateTime.startOf('week'), { week: 1 });

			relevantWeeksDateTime.forEach((week) => {
				const startOfMonth = week.startOf('month').toISODate();
				const projectedRevenueMonth = projectedRevenueMonthsMap.get(startOfMonth);
				if (!projectedRevenueMonth) return;
				const pnl = estimatedPnl / relevantWeeksDateTime.length;
				projectedRevenueMonth.hedgePnl = projectedRevenueMonth.hedgePnl ? projectedRevenueMonth.hedgePnl + pnl : pnl;
			});
		});

		return Array.from(projectedRevenueMonthsMap.values()).sortBy('date');
	}

	get projectedRevenueRows() {
		let unit: 'total' | 'perHead' | 'perCwt' = 'total';

		if (this.selectedDisplayUnit === DisplayUnits.PerHead) unit = 'perHead';

		if (this.selectedDisplayUnit === DisplayUnits.PerCWT) unit = 'perCwt';

		return this.projectedRevenueMonths.map((month) => {
			const data: ProjectedRevenueRow = {
				date: month.date,
				pigsSold: null,
				pigsSoldPerWeek: null,
				pricePerHead: null,
				totalRevenue: null,
				netPnl: null,
			};

			if (unit === 'total') {
				data.pigsSold = month.pigsSold;
				data.pigsSoldPerWeek = month.pigsSoldPerWeek;
				data.pricePerHead = month.salesRevenuePerHead;
				data.totalRevenue = month.salesRevenue;
				data.netPnl = month.netPnl;
			} else if (unit === 'perHead') {
				data.pigsSold = month.pigsSold;
				data.pigsSoldPerWeek = month.pigsSoldPerWeek;
				data.pricePerHead = month.salesRevenuePerHead;
				data.totalRevenue = month.salesRevenuePerHead;
				data.netPnl = month.netPnlPerHead;
			} else if (unit === 'perCwt') {
				data.pigsSold = month.pigsSold;
				data.pigsSoldPerWeek = month.pigsSoldPerWeek;
				data.pricePerHead = month.salesRevenueCwt;
				data.totalRevenue = month.salesRevenueCwt;
				data.netPnl = month.netPnlCwt;
			}
			return data;
		});
	}

	get projectedRevenueMonthTotals(): [Omit<ProjectedRevenueRow, 'date'>] {
		const totals: Omit<ProjectedRevenueRow, 'date'> = {
			pigsSold: null,
			pigsSoldPerWeek: null,
			pricePerHead: null,
			totalRevenue: null,
			netPnl: null,
		};

		let validEntriesForPigsSoldPerWeek = 0;
		let validEntriesForPricePerHead = 0;
		let validEntriesForPigsSold = 0;
		let validEntriesForTotalRevenue = 0;
		let validEntriesForNetPnl = 0;

		const addToTotal = (key: keyof Omit<ProjectedRevenueRow, 'date'>, value: number | null) => {
			if (value == null) return;

			if (key === 'pigsSold') validEntriesForPigsSold++;
			if (key === 'pigsSoldPerWeek') validEntriesForPigsSoldPerWeek++;
			if (key === 'pricePerHead') validEntriesForPricePerHead++;
			if (key === 'totalRevenue') validEntriesForTotalRevenue++;
			if (key === 'netPnl') validEntriesForNetPnl++;

			totals[key] = (totals[key] ?? 0) + value;
		};

		this.projectedRevenueRows.forEach((row) => {
			addToTotal('pigsSold', row.pigsSold);
			addToTotal('pigsSoldPerWeek', row.pigsSoldPerWeek);
			addToTotal('pricePerHead', row.pricePerHead);
			addToTotal('totalRevenue', row.totalRevenue);
			addToTotal('netPnl', row.netPnl);
		});

		if (this.selectedDisplayUnit !== DisplayUnits.Total) {
			if (validEntriesForPigsSold > 0 && totals.pigsSold != null) {
				totals.pigsSold = totals.pigsSold / validEntriesForPigsSold;
			}

			if (validEntriesForPigsSoldPerWeek > 0 && totals.pigsSoldPerWeek != null) {
				totals.pigsSoldPerWeek = totals.pigsSoldPerWeek / validEntriesForPigsSoldPerWeek;
			}

			if (validEntriesForPricePerHead > 0 && totals.pricePerHead != null) {
				totals.pricePerHead = totals.pricePerHead / validEntriesForPricePerHead;
			}

			if (validEntriesForTotalRevenue > 0 && totals.totalRevenue != null) {
				totals.totalRevenue = totals.totalRevenue / validEntriesForTotalRevenue;
			}

			if (validEntriesForNetPnl > 0 && totals.netPnl != null) {
				totals.netPnl = totals.netPnl / validEntriesForNetPnl;
			}
		}

		return [totals];
	}

	get projectedRevenueChartOptions(): ChartOptions<'bar'> {
		const tooltipOptions: CustomTooltipOptions = {
			titleFormatter: (val: string) => {
				const date = DateTime.fromISO(val);
				return `${date.monthShort} ${date.year}`;
			},
			valueFormatter: (val: number) => {
				return new Intl.NumberFormat('en-US', {
					style: 'currency',
					currency: 'USD',
					minimumFractionDigits: 0,
					maximumFractionDigits: 0,
				}).format(val);
			},
		};
		return {
			maintainAspectRatio: false,
			responsive: true,
			interaction: {
				intersect: false,
				mode: 'index',
			},
			layout: {
				padding: {
					top: 12,
					left: 12,
					right: 12,
				},
			},
			datasets: {
				bar: {
					borderRadius: 2,
					categoryPercentage: 0.8,
				},
			},
			scales: {
				y: {
					grid: {
						display: true,
					},
					stacked: true,
					ticks: {
						autoSkip: true,
						font: {
							size: 12,
						},
						callback: (val) => {
							if (typeof val === 'string') {
								return val;
							} else {
								return new Intl.NumberFormat('en-US', {
									style: 'currency',
									currency: 'USD',
									notation: 'compact',
								}).format(val);
							}
						},
						color: getComputedStyle(document.documentElement).getPropertyValue('brand-gray-60'),
					},
				},
				x: {
					stacked: true,
					ticks: {
						callback: function (_tickValue, index) {
							const luxonDate = DateTime.fromISO(this.getLabelForValue(index));
							return `${luxonDate.monthShort} ${luxonDate.year}`;
						},
					},
					grid: {
						display: false,
					},
				},
			},
			plugins: {
				legend: {
					display: false,
				},
				tooltip: {
					enabled: false,
					external: getCustomTooltip(tooltipOptions),
				},
			},
		};
	}

	get projectedRevenueChartDataLabels() {
		return this.currentDates.map((date) => date.toISODate());
	}

	get projectedRevenueChartRawData() {
		const data: { 'Pig Sales': number[]; 'Swine Hedge': number[] } = {
			'Pig Sales': [],
			'Swine Hedge': [],
		};

		this.projectedRevenueMonths.forEach((month) => {
			if (this.selectedDisplayUnit === DisplayUnits.Total) {
				data['Pig Sales'].push(month.salesRevenue ?? 0);
				data['Swine Hedge'].push(month.hedgePnl ?? 0);
			} else if (this.selectedDisplayUnit === DisplayUnits.PerCWT) {
				data['Pig Sales'].push(month.salesRevenueCwt ?? 0);
				data['Swine Hedge'].push(month.hedgePnlCwt ?? 0);
			} else {
				data['Pig Sales'].push(month.salesRevenuePerHead ?? 0);
				data['Swine Hedge'].push(month.hedgePnlPerHead ?? 0);
			}
		});

		return data;
	}

	get projectedRevenueChartData(): ChartData<'bar'> {
		const datasets: ChartDataset<'bar'>[] = [
			{
				label: 'Pig Sales',
				data: this.projectedRevenueChartRawData['Pig Sales'],
				backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-blue-60'),
			},
			{
				label: 'Swine Hedge',
				data: this.projectedRevenueChartRawData['Swine Hedge'],
				backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-orange-40'),
			},
		];

		return {
			labels: this.projectedRevenueChartDataLabels,
			datasets,
		};
	}

	get projectedRevenueChartPlugins() {
		return [
			{
				afterUpdate: getCustomLegend(this.projectedRevenueChartId, this.projectedRevenueLegendId),
			},
		];
	}

	get percentHedgedColumns(): TableColumn[] {
		const columns = [
			{
				id: '89b7c45f-adc2-4b74-91e0-8192bd0edde7',
				name: 'Date',
				valuePath: 'date',
				cellComponent: CellComponents.MonthFormat,
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '00508b92-2eb1-4a7a-b76e-9dfa80bf2d51',
				name: 'Swine',
				valuePath: 'percentHedged',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'percent',
					minimumFractionDigits: 2,
					maximumFractionDigits: 2,
				},
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
		];

		return columns;
	}

	get projectedRevenueColumns(): TableColumn[] {
		return [
			{
				id: '14081202-b823-4a0a-a930-ec7e9a3ebaf5',
				name: 'Date',
				valuePath: 'date',
				cellComponent: CellComponents.MonthFormat,
				componentArgs: {
					footerContent: this.selectedDisplayUnit === DisplayUnits.Total ? 'Total' : 'Average',
				},
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'a317cf27-7fd0-4058-9082-bb350c602862',
				name: 'Pigs',
				valuePath: 'pigsSold',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					minimumFractionDigits: 0,
					maximumFractionDigits: 0,
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},

			{
				id: '37df321f-e648-4045-8c52-e392bb87f841',
				name: 'Pigs / Week',
				valuePath: 'pigsSoldPerWeek',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					minimumFractionDigits: 0,
					maximumFractionDigits: 0,
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '1285d1a3-d589-456e-a608-97407eced234',
				name: this.selectedDisplayUnit === DisplayUnits.PerCWT ? 'Price / CWT' : 'Price / Head',
				valuePath: 'pricePerHead',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'efd9a3c3-d983-4829-860b-aac242b2e411',
				name: 'Total Revenue',
				valuePath: 'totalRevenue',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'f2ca8063-4d46-4c8b-a2a7-a5f32387882d',
				name: 'Net P/L',
				valuePath: 'netPnl',
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
		];
	}

	get percentHedgedRows() {
		const map: {
			[date: string]: {
				date: string;
				percentHedged: number;
			};
		} = {};

		this.model.getPigsMarketing.data?.AggregateEntityAllocatedExposureRatiosByOwner.forEach((entityExposure) => {
			const percentHedged = entityExposure.sum.totalPercentVolumeHedged;

			if (entityExposure.date) {
				if (!map[entityExposure.date]) {
					map[entityExposure.date] = {
						date: entityExposure.date,
						percentHedged: 0,
					};
				}

				if (percentHedged) {
					map[entityExposure.date].percentHedged = percentHedged;
				}
			}
		});

		return Object.values(map).sortBy('date');
	}

	get percentHedgedAverageRow(): [
		{
			percentHedged: number | null;
		}
	] {
		let total: null | number = null;
		let count: null | number = null;
		this.percentHedgedRows.forEach((row) => {
			if (row.percentHedged != null) {
				total = total !== null ? total + row.percentHedged : row.percentHedged;
				count = count !== null ? count + 1 : 1;
			}
		});

		if (total == null || count == null) return [{ percentHedged: null }];

		if (count === 0) return [{ percentHedged: 0 }];

		return [{ percentHedged: roundTo(total / count, 2) }];
	}

	get percentHedgedChartId() {
		return `pig-marketing-hedged-chart-${this.id}`;
	}

	get percentHedgedChartData(): ChartData<'line'> {
		const [labels, data] = this.percentHedgedRows.reduce(
			([labels, data]: [string[], number[]], val) => {
				labels.push(val.date);
				data.push(val.percentHedged);
				return [labels, data];
			},
			[[], []]
		);
		const datasets: ChartDataset<'line', (number | null)[]>[] = [
			{
				label: 'Swine',
				data,
				borderColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-teal-60'),
				backgroundColor: getComputedStyle(document.documentElement).getPropertyValue('--brand-teal-60'),
				pointRadius: 0,
				tension: 0.3,
			},
		];

		return {
			labels,
			datasets,
		};
	}

	get percentHedgedChartOptions(): ChartOptions<'line'> {
		const tooltipOptions: CustomTooltipOptions = {
			titleFormatter: (val: string) => {
				const dateTime = DateTime.fromISO(val);
				return `${dateTime.monthShort} ${dateTime.year}`;
			},
			valueFormatter: (val: number) => {
				return new Intl.NumberFormat('en-US', {
					style: 'percent',
					maximumFractionDigits: 2,
					minimumFractionDigits: 2,
				}).format(val);
			},
		};

		return {
			maintainAspectRatio: false,
			spanGaps: true,
			responsive: true,
			interaction: {
				intersect: false,
				mode: 'index',
			},
			scales: {
				y: {
					min: 0,
					ticks: {
						autoSkip: false,
						font: {
							size: 12,
						},
						callback: (tickValue) => {
							if (typeof tickValue === 'string') {
								return tickValue;
							} else {
								return new Intl.NumberFormat('en-us', { style: 'percent', maximumFractionDigits: 0, minimumFractionDigits: 0 }).format(
									tickValue
								);
							}
						},
						color: getComputedStyle(document.documentElement).getPropertyValue('--brand-gray-80'),
					},
				},
				x: {
					ticks: {
						autoSkip: false,
						color: getComputedStyle(document.documentElement).getPropertyValue('--brand-gray-80'),
						font: {
							size: 12,
						},
						callback: function (_tickValue, index) {
							const luxonDate = DateTime.fromISO(this.getLabelForValue(index));
							return `${luxonDate.monthShort} ${luxonDate.year}`;
						},
					},
					grid: {
						display: false,
					},
				},
			},
			plugins: {
				legend: {
					display: false,
				},
				tooltip: {
					enabled: false,
					external: getCustomTooltip(tooltipOptions),
				},
			},
		};
	}

	@action
	updateProjectedRevenueChart(chart: Chart<'bar'>, _data: unknown) {
		chart.data = this.projectedRevenueChartData;
		chart.update('none');
	}

	@action
	updatePercentHedgedChart(chart: Chart<'line'>, _data: unknown) {
		chart.data = this.percentHedgedChartData;
		chart.update('none');
	}

	@action
	setDateRange(value: { startDate: string; endDate: string }) {
		this.startDate = value.startDate;
		this.endDate = value.endDate;
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'businesses/business/pigs-marketing': BusinessesBusinessPigsMarketingController;
	}
}
