import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div ...attributes>\n\t{{#unless @legendId}}\n\t\t<div class='mb-2' id={{this.legendId}}></div>\n\t{{/unless}}\n\t<div class='relative {{@containerClasses}}' id='{{this.chartId}}-container'>\n\t\t<BarChart\n\t\t\t@chartData={{this.chartData}}\n\t\t\t@options={{this.chartOptions}}\n\t\t\t@data={{@feedBoughtHedgedMonths}}\n\t\t\t@id={{this.chartId}}\n\t\t\t@plugins={{this.plugins}}\n\t\t\t@updateChart={{this.updateChart}}\n\t\t/>\n\t</div>\n</div>", {"contents":"<div ...attributes>\n\t{{#unless @legendId}}\n\t\t<div class='mb-2' id={{this.legendId}}></div>\n\t{{/unless}}\n\t<div class='relative {{@containerClasses}}' id='{{this.chartId}}-container'>\n\t\t<BarChart\n\t\t\t@chartData={{this.chartData}}\n\t\t\t@options={{this.chartOptions}}\n\t\t\t@data={{@feedBoughtHedgedMonths}}\n\t\t\t@id={{this.chartId}}\n\t\t\t@plugins={{this.plugins}}\n\t\t\t@updateChart={{this.updateChart}}\n\t\t/>\n\t</div>\n</div>","moduleName":"vault-client/components/bought-vs-hedged-chart.hbs","parseOptions":{"srcName":"vault-client/components/bought-vs-hedged-chart.hbs"}});
import { guidFor } from '@ember/object/internals';
import Component from '@glimmer/component';
import { Chart, ChartData, ChartOptions, ScriptableTooltipContext } from 'chart.js';
import { DateTime } from 'luxon';
import { FeedIngredientBase } from 'vault-client/types/vault-client';
import getCSSVariable from 'vault-client/utils/get-css-variable';
import { service } from '@ember/service';
import { action } from '@ember/object';
import FeedBoughtHedgedMonth from 'vault-client/models/feed-bought-hedged-month';
import { roundTo } from 'vault-client/utils/round-to';

interface BoughtVsHedgedChartArgs {
	ingredientBase: FeedIngredientBase;
	feedBoughtHedgedMonths: FeedBoughtHedgedMonth[];
	legendId?: string;
	containerClasses?: string;
	sharedLegend?: boolean;
	sharedLegendChartIds?: string[];
	chartId?: string;
}

const DEFAULT_CHART_COLORS = [
	getCSSVariable('--brand-interactive-blue-70'),
	getCSSVariable('--brand-orange-40'),
	getCSSVariable('--brand-lime-40'),
	getCSSVariable('--brand-lemon-40'),
	getCSSVariable('--brand-teal-60'),
	getCSSVariable('--brand-purple-50'),
];

enum DatasetLabels {
	Bought = 'Bought',
	OnlyBasisLocked = 'Only Basis Locked',
	OnlyFutureLocked = 'Only Future Locked',
	FuturesHedged = 'Futures Hedged',
	HedgedWithCalls = 'Hedged With Calls',
}

type DataValues = { [label in DatasetLabels]?: (number | null)[] };

export default class BoughtVsHedgedChart extends Component<BoughtVsHedgedChartArgs> {
	@service media: any;

	get data(): DataValues {
		const tempData = this.defaultValues;

		this.args.feedBoughtHedgedMonths.forEach((month, idx) => {
			if (tempData['Bought']) tempData['Bought'][idx] = roundTo(month.bought, 2);

			if (tempData['Futures Hedged']) tempData['Futures Hedged'][idx] = roundTo(month.futuresHedgedPercent, 2);

			if (tempData['Hedged With Calls']) tempData['Hedged With Calls'][idx] = roundTo(month.callsHedgedPercent, 2);
		});

		return tempData;
	}

	id = guidFor(this);

	get chartId() {
		return this.args.chartId ?? `bought-vs-hedged-chart-${this.id}`;
	}

	get tooltipId() {
		return `${this.chartId}-tooltip`;
	}

	get legendId() {
		return this.args.legendId ?? `${this.chartId}-legend`;
	}

	get labels() {
		return this.args.feedBoughtHedgedMonths.map((month) => DateTime.fromISO(month.date).toFormat('MMM yyyy'));
	}

	get defaultValues(): DataValues {
		const numMonths = this.args.feedBoughtHedgedMonths.length;
		if (this.args.ingredientBase === FeedIngredientBase.Corn || this.args.ingredientBase === FeedIngredientBase.SoybeanMeal) {
			return {
				// [DatasetLabels.Bought]: [],
				// [DatasetLabels.OnlyBasisLocked]: [],
				// [DatasetLabels.OnlyFutureLocked]: [],
				[DatasetLabels.FuturesHedged]: Array(numMonths).fill(0),
				[DatasetLabels.HedgedWithCalls]: Array(numMonths).fill(0),
			};
		} else {
			return {
				// [DatasetLabels.Bought]: Array(numMonths).fill(0),
			};
		}
	}

	get chartOptions(): ChartOptions<'bar'> {
		const getOrCreateTooltip = (chart: Chart) => {
			let tooltipEl: HTMLDivElement | null | undefined = chart.canvas.parentNode?.querySelector('div');
			if (!tooltipEl) {
				tooltipEl = document.createElement('div');
				tooltipEl.id = this.tooltipId;
				tooltipEl.classList.add(
					'line-chart-tooltip',
					'line-chart-tooltip-bottom',
					'border',
					'border-brand-blue-80',
					'bg-white',
					'text-brand-gray-90',
					'absolute',
					'pointer-events-none',
					'transform',
					'-translate-x-2/4',
					'transition-all',
					'ease-linear',
					'duration-50',
					'opacity-1',
					'z-10',
					'text-left',
					'p-2',
					'rounded-sm'
				);

				const tooltipUL = document.createElement('ul');
				tooltipUL.classList.add('tooltipul');

				tooltipEl.append(tooltipUL);
				chart.canvas.parentNode?.append(tooltipEl);
			}
			return tooltipEl;
		};

		const customTooltip = (context: ScriptableTooltipContext<'bar'>) => {
			const { chart, tooltip } = context;
			const tooltipEl = getOrCreateTooltip(chart);
			const tooltipUL = tooltipEl.querySelector('ul')!;

			if (tooltip.opacity === 0) {
				tooltipEl.classList.replace('opacity-1', 'opacity-0');
				return;
			}

			if (tooltip.body) {
				const titleLines = tooltip.title || [];
				const dataPoints = tooltip.dataPoints;
				const tooltipLI = document.createElement('li');
				tooltipLI.classList.add('whitespace-nowrap');

				// Title Loop
				titleLines.forEach((title: string) => {
					const formattedTitle = title;
					tooltipUL.appendChild(tooltipLI);

					const tooltipSpan = document.createElement('span');
					tooltipSpan.classList.add('text-sm', 'font-semibold', 'text-brand-blue-80', 'block');
					tooltipLI.appendChild(tooltipSpan);

					const tooltipTitle = document.createTextNode(formattedTitle);
					tooltipSpan.appendChild(tooltipTitle);
				});

				// Body Loop
				const tooltipBodyP = document.createElement('p');
				dataPoints.forEach((dataPoint, i: number) => {
					const displayBlockSpan = document.createElement('span');
					displayBlockSpan.classList.add('block', 'text-xs');
					const colors = tooltip.labelColors[i];
					const colorCircle = document.createElement('span');
					colorCircle.classList.add('rounded-full', 'w-2', 'h-2', 'inline-block', 'mr-2');
					colorCircle.style.background = colors.borderColor as string;
					colorCircle.style.border = colors.borderColor as string;

					const formattedValue = Intl.NumberFormat('en-US', {
						style: 'percent',
						minimumFractionDigits: 0,
						maximumFractionDigits: 0,
					}).format(dataPoint.raw as number);
					const value = document.createTextNode(formattedValue);
					const label = document.createElement('span');
					label.classList.add('ml-1', 'font-sans-semibold');
					label.textContent = dataPoint.dataset.label ?? null;

					// Append color label and text
					displayBlockSpan.appendChild(colorCircle);
					displayBlockSpan.appendChild(value);
					displayBlockSpan.append(label);
					tooltipBodyP.appendChild(displayBlockSpan);
				});

				// Remove old children
				while (tooltipUL.firstChild) {
					tooltipUL.firstChild.remove();
				}

				// Add new children
				tooltipUL.appendChild(tooltipLI);
				tooltipLI.appendChild(tooltipBodyP);
				tooltipEl.classList.replace('opacity-0', 'opacity-1');

				// Position tooltip
				const position = context.chart.canvas.getBoundingClientRect();
				const { width: tooltipElWidth } = tooltipEl.getBoundingClientRect();

				let offsetX = 0;

				if (tooltip.caretX < tooltipElWidth / 2) {
					offsetX = tooltip.caretX + tooltipElWidth / 2 + 12;
				} else if (position.width - tooltip.caretX < tooltipElWidth / 2) {
					offsetX = tooltip.caretX - tooltipElWidth / 2 - 12;
				} else {
					offsetX = context.chart.canvas.offsetLeft + tooltip.caretX;
				}

				tooltipEl.style.left = offsetX + 'px';
				tooltipEl.style.bottom = position.height - 24 + 'px';
			}
		};

		return {
			responsive: true,
			maintainAspectRatio: false,
			plugins: {
				datalabels: {
					display: () => {
						// Hide data labels from chart when they get illegible
						let showDatalabels = this.media.isLaptop || this.media.isDesktop || this.media.isJumbo;

						if (this.labels && this.labels.length <= 20) {
							showDatalabels = showDatalabels || this.media.isMedium;
						}

						return showDatalabels;
					},
					color: getCSSVariable('--brand-white'),
					formatter: (value) => {
						// Hide labels less than 6% as they don't have enough space to display properly
						if (!value || value < 6) return null;
						return new Intl.NumberFormat(undefined, {
							minimumFractionDigits: 0,
							maximumFractionDigits: 0,
							style: 'percent',
							signDisplay: 'auto',
						}).format(value);
					},
					font: {
						weight: 400,
						size: 12,
					},
				},
				tooltip: {
					mode: 'index',
					external: customTooltip,
					enabled: false,
					displayColors: false,
				},
				legend: {
					display: false,
				},
			},
			interaction: {
				intersect: false,
				mode: 'index',
			},
			layout: {
				// padding: 32,
			},
			scales: {
				x: {
					grid: {
						display: false,
					},
					stacked: true,
				},
				y: {
					min: 0,
					stacked: true,
					ticks: {
						callback: (tickValue) => {
							if (typeof tickValue === 'string') {
								return tickValue;
							} else {
								return Intl.NumberFormat('en-US', { minimumFractionDigits: 0, maximumFractionDigits: 0, style: 'percent' }).format(
									tickValue
								);
							}
						},
					},
				},
			},
		};
	}

	get chartData(): ChartData {
		const datasets = Object.keys(this.data ?? {}).map((label, i) => ({
			label: label,
			data: this.data[label as DatasetLabels] ?? [],
			borderColor: DEFAULT_CHART_COLORS[i],
			backgroundColor: DEFAULT_CHART_COLORS[i],
			fill: true,
		}));

		return {
			labels: this.labels,
			datasets,
		};
	}

	get plugins() {
		const chartId = this.chartId;
		const legendId = this.legendId;
		return [
			{
				afterUpdate: (chart: Chart) => {
					const toggleDataset = (event: PointerEvent): void => {
						const index = (event.currentTarget as HTMLElement).dataset.index;
						if (!index) return;

						if (this.args.sharedLegend && this.args.sharedLegendChartIds) {
							const charts = this.args.sharedLegendChartIds.map((chartId) => Chart.getChart(chartId)).filter((x) => !!x) as Chart[];
							charts.forEach((chart) => {
								const meta = chart.getDatasetMeta(+index);
								meta.hidden = !meta.hidden ? true : false;
								chart.update();
							});
						} else {
							const meta = chart.getDatasetMeta(+index);
							meta.hidden = !meta.hidden ? true : false;
							chart.update();
						}
					};

					// Make sure we're applying the legend to the right chart
					if (chart.canvas.id === chartId) {
						const legend = document.getElementById(legendId)!;
						const ul = legend?.querySelector('ul') ?? document.createElement('ul');
						ul.classList.add('flex', 'flex-wrap');

						// Remove old legend items
						while (ul.firstChild) {
							ul.firstChild.remove();
						}

						while (legend.firstChild) {
							legend.firstChild.remove();
						}

						let chartWithLargestDataset = null;

						if (this.args.sharedLegend && this.args.sharedLegendChartIds) {
							const chartInstances = this.args.sharedLegendChartIds.map((chartId) => Chart.getChart(chartId)).filter((x) => !!x) as Chart[];
							chartWithLargestDataset = chartInstances.sort((a, b) => {
								return a.data.datasets.length < b.data.datasets.length ? 1 : -1;
							}).firstObject;
						}

						((chart: Chart | undefined) => {
							chart?.data.datasets.forEach((dataset, index) => {
								const legendItem = document.createElement('li');
								legendItem.classList.add(
									'flex',
									'flex-col',
									'items-center',
									'mr-4',
									'text-brand-gray-60',
									'cursor-pointer',
									'whitespace-nowrap'
								);

								// Strike out legend item if hidden
								const meta = chart.getDatasetMeta(index);
								if (meta.hidden) {
									legendItem.classList.add('line-through');
								}

								legendItem.dataset.index = index.toString(10);
								legendItem.onclick = toggleDataset;

								legendItem.innerHTML += `
										<div class="text-sm flex items-center">
											<span style="background-color: ${dataset.borderColor}" class="w-2 h-2 rounded-full inline-block mr-1"></span>
											${dataset.label}
										<div/>
								`;

								ul.append(legendItem);
							});
						})(this.args.sharedLegend && chartWithLargestDataset ? chartWithLargestDataset : chart);

						return legend?.insertBefore(ul, legend.firstChild);
					}
					return;
				},
			},
		];
	}

	@action
	updateChart(chart: Chart) {
		chart.data = this.chartData;
		chart.update('none');
	}
}
