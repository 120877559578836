import { inject as service } from '@ember/service';
import { DateTime } from 'luxon';
import { setOwner } from '@ember/application';
import { tracked } from '@glimmer/tracking';
import { percentRank } from 'vault-client/utils/percentile';
import { Product, Future } from 'vault-client/types/graphql-types';

export default class StrategicInventoryModel {
	@service marketData: any;

	@tracked registeredSymbols = [];

	product: Product;
	prevMonthsIncentive: Array<number | null>;
	symbol: string | null | undefined;
	carryCost: number;
	frontFuture: Future;
	displayExpiresAt: string;
	expiresAt: string;
	spotProduct: Product | null;
	noDisplayFactor = 1;
	numberOfYears: number;

	currentPriceInput: string;

	constructor(
		owner: any,
		product: Product,
		future: Future,
		carryCost: number,
		lastTwoSims: { oneMonthCarryIncentive: number | null }[],
		spotProduct: Product | null,
		currentPriceInput: string,
		numberOfYears: number = 5
	) {
		setOwner(this, owner);

		this.product = product;
		this.frontFuture = product.CurrentFutures[0];
		this.carryCost = carryCost;
		this.symbol = future.barchartSymbol;
		this.displayExpiresAt = future.displayExpiresAt;
		this.expiresAt = future.expiresAt;
		this.prevMonthsIncentive = lastTwoSims.map((e) => e.oneMonthCarryIncentive);
		this.spotProduct = spotProduct;
		this.marketData.register(this.symbol);
		this.marketData.register(this.frontFuture.barchartSymbol);
		this.numberOfYears = numberOfYears;

		if (this.spotProduct?.CashInstrument) {
			this.marketData.register(this.spotProduct.CashInstrument.barchartSymbol);
		}

		this.marketData.registerHistoricalRange(this.symbol);

		this.currentPriceInput = currentPriceInput;
	}

	get currentCarryCost() {
		return this.carryCost;
	}

	get productDisplayFactor() {
		return this.product.MostCurrentFuture?.SymbolGroup.displayFactor ?? 1;
	}

	get spotProductDisplayFactor() {
		return this.spotProduct?.CashInstrument?.SymbolGroup.displayFactor ?? 1;
	}

	get spotPrice() {
		if (this.spotProduct?.CashInstrument) {
			const spotPriceMarketDatum = this.marketData.getMarketDatum(this.spotProduct?.CashInstrument?.barchartSymbol);
			return spotPriceMarketDatum?.lastPrice * this.spotProductDisplayFactor;
		} else {
			return null;
		}
	}

	get frontFuturePrice() {
		const mostCurrentFutureMarketData = this.marketData.getMarketDatum(this.frontFuture.barchartSymbol);
		return mostCurrentFutureMarketData?.lastPrice * this.productDisplayFactor;
	}

	get currentFuturePrice() {
		const marketDatum = this.marketData.getMarketDatum(this.symbol);
		return marketDatum?.lastPrice * this.productDisplayFactor;
	}

	get currentPrice() {
		let price: number | null = this.frontFuturePrice;
		switch (this.currentPriceInput) {
			case 'Front Future Price':
				price = this.frontFuturePrice;
				break;
			default:
				price = this.spotPrice;
		}
		return price;
	}

	get diffInMonths() {
		const now = DateTime.local().startOf('month');
		const futureMonth = DateTime.fromISO(this.displayExpiresAt);

		// get the difference from the current month from now
		return futureMonth.diff(now, 'months').months;
	}

	get futuresMinusCarryCost() {
		// next 2 const prevents negative values to render in columns
		const futureDiffInMonths = this.diffInMonths > 0 ? this.diffInMonths : 0;
		const _carryCost = this.diffInMonths > 0 ? this.currentCarryCost : 0;

		return this.currentFuturePrice - futureDiffInMonths * _carryCost;
	}

	get oneMonthCarryIncentive() {
		if (this.diffInMonths > 0 && this.currentPrice) {
			return this.futuresMinusCarryCost - this.currentPrice;
		} else {
			return 0;
		}
	}

	get riskManagementStrategy() {
		const marketDatum = this.marketData.getMarketDatum(this.symbol);

		const historicalData = this.marketData.getMarketDataHistoricalRange(this.symbol);

		const settlementsDatums = historicalData?.get('settlements');

		const settlements = settlementsDatums
			?.map((settlement: any) => {
				return settlement.close ?? settlement.lastPrice;
			})
			.slice(0, this.numberOfYears);

		if (!marketDatum || !settlements) {
			return '-';
		}

		const currentPercentile = percentRank(settlements, marketDatum.lastPrice);

		if (currentPercentile == null) {
			return '-';
		}

		if (this.oneMonthCarryIncentive < 0) {
			return 'Sell Spot';
		}

		if (currentPercentile < 0.25) {
			return 'Buy Put';
		}

		if (currentPercentile >= 0.25 && currentPercentile <= 0.75) {
			return 'Risk Reversal';
		}

		return 'Sell Futures';
	}

	willDestroy() {
		this.registeredSymbols.forEach((symbol) => {
			this.marketData.unregister(symbol);
		});
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your models.
//@ts-ignore
declare module 'ember-data/types/registries/model' {
	export default interface ModelRegistry {
		'strategic-inventory-model': StrategicInventoryModel;
	}
}
