import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{this.formattedValue}}", {"contents":"{{this.formattedValue}}","moduleName":"vault-client/components/numeric-format.hbs","parseOptions":{"srcName":"vault-client/components/numeric-format.hbs"}});
import Component from '@glimmer/component';

interface NumericFormatArgs {
	rowData: any;
	valuePath: any;
	maximumFractionDigits?: number;
	minimumFractionDigits?: number | null;
}

export default class NumericFormat extends Component<NumericFormatArgs> {
	get formattedValue() {
		const value = this.args.valuePath ? this.getByCompositeKey(this.args.rowData, this.args.valuePath) : '0';

		const formatter = new Intl.NumberFormat('en-US', {
			minimumFractionDigits: this.args.minimumFractionDigits ?? 0,
			maximumFractionDigits: this.args.maximumFractionDigits ?? 0,
		});

		if (Number(value) < 0) {
			const val = value.toString().substring(1);
			return `(${formatter.format(Number(val) ?? 0)})`;
		}
		return `${formatter.format(Number(value) ?? 0)}`;
	}

	getByCompositeKey(obj: any, key: string) {
		if (!key) {
			return null;
		}

		return key.split('.').reduce((prev: any, itm: string) => (prev ? prev[itm] : null), obj) || null;
	}
}
