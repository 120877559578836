import TransactionClass from './transaction';
import Big from 'big.js';
import Swaption from './swaption';
import { Account, Instrument, Transaction } from 'vault-client/types/graphql-types';
import { tracked } from '@glimmer/tracking';
import { next } from '@ember/runloop';

export default class SwaptionTransaction extends TransactionClass {
	@tracked Instrument: Swaption;

	constructor(owner: any, transaction: Transaction, instrument: Instrument | null, account: Account | null, useEODPrice = false) {
		super(owner, transaction, account, useEODPrice);

		const _instrument = instrument ?? transaction.Instrument;
		this.Instrument = new Swaption(_instrument as any);

		// DGC: For some reason, I had to push this to the next runloop to prevent
		// a Glimmer error.
		const barchartSymbol = this.Instrument.priceInstrument?.barchartSymbol;
		const obj = { barchartSymbol, marketData: this.marketData };
		next(obj, function () {
			if (obj.barchartSymbol) obj.marketData.register(obj.barchartSymbol);
		});
	}

	get absQuantityInContracts() {
		return new Big(this.quantityInContracts).abs();
	}

	/**
	 * Total Premium for the Transaction
	 * Negative if the swaption was purchased.
	 */
	get premium() {
		let pointValue = this.Instrument?.Product?.StandardProductLotSpecification?.pointValue || 1;
		if (typeof pointValue === 'string') {
			pointValue = pointValue.replace(/[^0-9.]/g, '');
		}
		// Times -1, since a negative quantity would be receiving premium.
		return new Big(this.quantityInContracts).times(-1).times(this.price).times(pointValue);
	}

	/**
	 * Premium per contract
	 * Negative if the swaption was purchased.
	 */
	get premiumPerContract() {
		return this.premium.div(this.absQuantityInContracts);
	}

	/**
	 * The P/L since transaction.
	 */
	get marketPl() {
		return this.marketPlPerContract.times(this.absQuantityInContracts);
	}

	/**
	 * The P/L since transaction per contract.
	 */
	get marketPlPerContract() {
		const lookAlikeBarchartSymbol = this.Instrument?.priceInstrument?.barchartSymbol;

		let pointValue = this.Instrument?.Product?.StandardProductLotSpecification?.pointValue || 1;
		if (typeof pointValue === 'string') {
			pointValue = pointValue.replace(/[^0-9.]/g, '');
		}

		let valueAtExpiration = new Big(0);

		// We can only value, if there is an instrument to use.
		if (lookAlikeBarchartSymbol) {
			const marketPrice = this.getMarketPrice(lookAlikeBarchartSymbol);

			// If we have a market price, figure the P/L
			if (marketPrice) {
				if (this.Instrument?.isCall && marketPrice > this.Instrument.strike) {
					valueAtExpiration = new Big(marketPrice).minus(this.Instrument.strike).times(pointValue);
					return this.quantity > 0 ? valueAtExpiration : valueAtExpiration.times(-1);
				}

				if (this.Instrument?.isPut && marketPrice < this.Instrument.strike) {
					valueAtExpiration = new Big(this.Instrument.strike).minus(marketPrice).times(pointValue);
					return this.quantity > 0 ? valueAtExpiration : valueAtExpiration.times(-1);
				}
			}
		}

		return valueAtExpiration;
	}

	/**
	 * The realized P/L, which will never change.
	 * @returns {Big}
	 */
	get realizedPl() {
		return this.Instrument?.hasExpired ? this.premium.plus(this.marketPl) : this.premium;
	}

	/**
	 * The unrealized P/L, which might still change.
	 * @returns {Big}
	 */
	get unrealizedPl() {
		return this.Instrument?.hasExpired ? new Big(0) : this.marketPl;
	}

	/**
	 * The unrealized P/L, per contract
	 * @returns {Big}
	 */
	get unrealizedPlPerContract() {
		return this.unrealizedPl.div(this.quantityInContracts);
	}

	/**
	 * Total p/l per contract
	 * @returns {Big}
	 */
	get plPerContract() {
		return this.realizedPl.plus(this.unrealizedPl).div(this.absQuantityInContracts);
	}

	get grossPl() {
		return parseFloat(this.plPerContract.times(new Big(this.quantityInContracts).abs()).toString());
	}

	get netPl() {
		return this.grossPl + this.commission + this.nonCommissionFees;
	}
}
