import Route from '@ember/routing/route';
import { tracked } from '@glimmer/tracking';
import { gql, useQuery } from 'glimmer-apollo';
import { Query, LedgerFeedCategoryFilterDTO } from 'vault-client/types/graphql-types';

const GET_FIXED_FEED_COSTS_CATEGORIES = gql`
	query FixedFeedCategories($getFixedFeedCostsCategoriesWhere: LedgerFeedCategoryFilterDTO) {
		LedgerFeedCategories(where: $getFixedFeedCostsCategoriesWhere) {
			id
			customerId
			calculationType
			description
			name
			type
		}
	}
`;

export type GetFixedFeedCostCategoriesQuery = {
	__typename?: 'Query';
	LedgerFeedCategories: Query['LedgerFeedCategory'][];
};

type GetFixedFeedCostQueryVariables = {
	getFixedFeedCostsCategoriesWhere?: LedgerFeedCategoryFilterDTO;
};

export default class FeedCostsRoute extends Route {
	templateName = 'feed/costs/index';

	@tracked fixedFeedCostCategoriesVars: GetFixedFeedCostQueryVariables = {};

	getFixedFeedCostsCategories = useQuery<GetFixedFeedCostCategoriesQuery, GetFixedFeedCostQueryVariables>(this, () => [
		GET_FIXED_FEED_COSTS_CATEGORIES,
		{ variables: this.fixedFeedCostCategoriesVars },
	]);

	generateFixedFeedCostsWhere(businessId: string | null) {
		const where: LedgerFeedCategoryFilterDTO = {};

		if (businessId) {
			where.customerId = { equals: businessId };
		}

		return where;
	}
}
