import Route from '@ember/routing/route';
import cropToUnderlyingProduct from 'vault-client/utils/crop-to-product-slug';
import cropYearToFutureExpirationDates from 'vault-client/utils/crop-year-to-future-expiration-dates';
import { DateTime } from 'luxon';
import {
	Transaction,
	TypeOfInstrument,
	Query,
	Query_GrainCropPlanArgs,
	TransactionFilterDTO,
	CurrentAllocationPosition,
	IInstrument,
	Account,
	CurrentAllocationPositionFilterDTO,
} from 'vault-client/types/graphql-types';
import { getOwner } from '@ember/application';
import OptionTransaction from 'vault-client/models/option-transaction';
import { gql, useQuery } from 'glimmer-apollo';
import { tracked } from '@glimmer/tracking';
interface OptionPositionRow {
	id: string;
	Account: Account;
	Instrument: IInstrument;
	side: string;
	quantityInContracts: number | null;
	longQty: number | null;
	shortQty: number | null;
	grossPnl: number | null;
}
interface optionTransactionRow {
	shortQty: number | null;
	longQty: number | null;
}

interface QueryParams {
	crop_plan_id: string;
	showClosedPositions: boolean;
}

interface GetGrainCropPlan {
	GrainCropPlan: Query['GrainCropPlan'];
}

const GET_GRAIN_CROP_PLAN_QUERY = gql`
	query GrainCropPlan($id: String!) {
		GrainCropPlan(id: $id) {
			id
			acres
			aph
			breakEven
			Crop {
				id
				name
			}
			CropYear {
				id
				year
			}
			Customer {
				id
				name
			}
			enrolledPercent
			goal
			harvestBu
			storageBu
			Orders {
				id
				type
				bushels
				futurePrice
				futuresMonth
				basis
				deliveryMonth
				deliveryStartDate
				deliveryEndDate
				futurePrice
				salesType
				fees
				spread
				basis
				contractNumber
				Buyer {
					id
					name
				}
				Seller {
					id
					name
				}
				Location {
					id
					name
				}
				DeliveryLocation {
					id
					name
				}
				... on GrainFillOrder {
					flatPrice
					locationId
				}
				... on GrainTargetOrder {
					status
					expirationDate
					StatusUpdates(orderBy: { updatedAt: Desc }) {
						id
						updatedAt
						updatedBy
					}
					Activity {
						id
						operationType
						type
						performedAt
						User {
							id
							firstName
							lastName
						}
						GrainPlan {
							id
							type
						}
					}
				}
			}
		}
	}
`;

interface GetOptionPositions {
	CurrentAllocationPositions: CurrentAllocationPosition[];
	Transactions: Transaction[];
}

interface GetOptionPositions_Args {
	positionsWhere?: CurrentAllocationPositionFilterDTO;
	transactionsWhere?: TransactionFilterDTO;
}

const GET_OPTION_POSITIONS_QUERY = gql`
	query Options($positionsWhere: CurrentAllocationPositionFilterDTO, $transactionsWhere: TransactionFilterDTO) {
		CurrentAllocationPositions(where: $positionsWhere) {
			id
			effectiveHedgeDate
			contractQuantity
			grossPnl
			Account {
				id
				accountNumber
				name
			}
			Instrument {
				id
				SymbolGroup {
					displayFactor
					fractionDigits
				}
				... on Option {
					name
					optionType
					strike
					expiresAt
					displayExpiresAt
					barchartSymbol
					exchangeSymbol
					Product {
						id
						pointValue
						ProductLotSpecifications {
							pointValue
						}
					}
					UnderlyingInstrument {
						id
						barchartSymbol
					}
				}
			}
		}

		Transactions(where: $transactionsWhere) {
			id
			price
			tradeDate
			feeTotal
			commissionTotal
			contractQuantity
			unitQuantity
			Account {
				id
				accountNumber
				name
			}
			Instrument {
				id
				SymbolGroup {
					displayFactor
					fractionDigits
				}
				... on Option {
					name
					optionType
					strike
					expiresAt
					displayExpiresAt
					barchartSymbol
					exchangeSymbol
					Product {
						id
						name
						pointValue
					}
					UnderlyingInstrument {
						id
						barchartSymbol
					}
				}
			}
		}
	}
`;

export default class CropPlansShowRoute extends Route {
	queryParams = {
		showClosedPositions: { refreshModel: true },
	};

	@tracked variables: Query_GrainCropPlanArgs = { id: '' };
	@tracked optionVariables: GetOptionPositions_Args = {};

	getGrainCropPlan = useQuery<GetGrainCropPlan, Query_GrainCropPlanArgs>(this, () => [
		GET_GRAIN_CROP_PLAN_QUERY,
		{
			variables: this.variables,
		},
	]);

	getOptionPositions = useQuery<GetOptionPositions, GetOptionPositions_Args>(this, () => [
		GET_OPTION_POSITIONS_QUERY,
		{
			variables: this.optionVariables,
		},
	]);

	templateName: string = 'crop-plans/show';

	itemsFn = (rows: CurrentAllocationPosition[] | Transaction[]) => {
		const owner = getOwner(this);

		return rows.map((row) => {
			if (row.__typename === 'CurrentAllocationPosition') {
				let side = 'Flat';

				if (row.contractQuantity > 0) {
					side = 'Long';
				} else if (row.contractQuantity < 0) {
					side = 'Short';
				}

				return {
					id: row.id,
					Account: row.Account,
					Instrument: row.Instrument,
					side: side,
					quantityInContracts: row.contractQuantity,
					longQty: row.contractQuantity > 0 ? row.contractQuantity : null,
					shortQty: row.contractQuantity < 0 ? row.contractQuantity : null,
					grossPnl: row.grossPnl,
				} as OptionPositionRow;
			} else if (row.__typename === 'Transaction') {
				const transaction = new OptionTransaction(owner, row, row.Instrument, row.Account) as OptionTransaction & optionTransactionRow;
				transaction.shortQty = transaction.quantityInContracts < 0 ? Math.abs(transaction.quantityInContracts) : null;
				transaction.longQty = transaction.quantityInContracts > 0 ? transaction.quantityInContracts : null;
				return transaction;
			} else {
				return row;
			}
		});
	};

	async model(params: QueryParams) {
		this.variables = {
			id: params.crop_plan_id,
		};

		await this.getGrainCropPlan.promise;

		// Allows model to return early if customer or underlying product don't exist, saving the long Positions call
		if (
			!this.getGrainCropPlan.data?.GrainCropPlan?.Customer?.id ||
			!cropToUnderlyingProduct[this.getGrainCropPlan.data?.GrainCropPlan?.Crop?.name]
		) {
			return {
				getGrainCropPlan: this.getGrainCropPlan,
				CurrentPositions: [],
				Transactions: [],
			};
		}

		// Utility used to map from crop to product. Should be replaced if a more robust method for mapping is available.
		const productSlug = cropToUnderlyingProduct[this.getGrainCropPlan.data.GrainCropPlan.Crop.name];
		const { startDate, endDate } = cropYearToFutureExpirationDates(
			productSlug as string,
			this.getGrainCropPlan.data.GrainCropPlan?.CropYear.year.toString()
		);
		const currentDate = DateTime.local().toISODate();

		const positionsWhere: CurrentAllocationPositionFilterDTO = {
			Instrument: {
				Product: {
					slug: {
						equals: productSlug,
					},
				},
			},
			instrumentType: {
				equals: TypeOfInstrument.Option,
			},
			businessId: {
				equals: this.getGrainCropPlan.data.GrainCropPlan.Customer.id,
			},
		};

		if (params.showClosedPositions) {
			positionsWhere.Instrument = {
				...positionsWhere.Instrument,
				AsOptionInstrument: {
					UnderlyingInstrument: {
						displayExpiresAt: {
							gte: startDate,
							lte: endDate,
						},
					},
				},
			};
		} else {
			positionsWhere.Instrument = {
				...positionsWhere.Instrument,
				AsOptionInstrument: {
					AND: [
						{
							UnderlyingInstrument: {
								displayExpiresAt: {
									gte: startDate,
									lte: endDate,
								},
							},
						},
						{
							displayExpiresAt: {
								gte: currentDate,
							},
						},
					],
				},
			};

			positionsWhere.contractQuantity = {
				not: {
					equals: 0,
				},
			};
		}

		const transactionsWhere = {
			Instrument: {
				AsOptionInstrument: {
					UnderlyingInstrument: {
						displayExpiresAt: {
							gte: startDate,
							lte: endDate,
						},
					},
				},
				type: {
					equals: TypeOfInstrument.Option,
				},
				Product: {
					slug: {
						equals: productSlug,
					},
				},
			},
			Account: {
				customerId: {
					equals: this.getGrainCropPlan.data.GrainCropPlan.Customer.id,
				},
			},
		};

		this.optionVariables = {
			positionsWhere,
			transactionsWhere,
		};

		await this.getOptionPositions.promise;

		return {
			getGrainCropPlan: this.getGrainCropPlan,
			CurrentPositions: this.itemsFn(this.getOptionPositions.data?.CurrentAllocationPositions ?? []) as (
				| CurrentAllocationPosition
				| OptionPositionRow
			)[],
			Transactions: this.itemsFn(this.getOptionPositions.data?.Transactions ?? []),
		};
	}
}
