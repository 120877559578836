import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import query from 'vault-client/graphql/queries/insurance-documents/index.graphql';
import { action } from '@ember/object';
import resetVaultTableScroll from 'vault-client/utils/reset-vault-table-scroll';
import { SortObj, TableColumn, CellComponents } from 'vault-client/types/vault-table';
import { queryManager } from 'ember-apollo-client';
import searchQuery from 'vault-client/graphql/queries/search.graphql';
import getFilterDisplayProperty from 'vault-client/utils/get-filter-display-property';
import { InsuranceFile, TypeOfSearchItem } from 'vault-client/types/graphql-types';
import { ModelFrom } from 'vault-client/utils/type-utils';
import OrganizationsInsuranceDocumentsRoute from 'vault-client/routes/organizations/organization/insurance-documents';
import BusinessesInsuranceDocumentsRoute from 'vault-client/routes/businesses/business/insurance-documents';

interface SearchFilterIdentifiers {
	aipId: string | null;
	policyNumber: string | null;
}

type SearchFilterIdentifier = keyof SearchFilterIdentifiers;

function isSearchFilterIdentifier(key: unknown): key is SearchFilterIdentifier {
	const searchFilterIdentifiers = ['aipId', 'policyNumber'];
	if (typeof key === 'string' && searchFilterIdentifiers.includes(key)) {
		return true;
	}
	return false;
}
interface searchResult {
	id: string;
	name: string;
	type: string;
}

export default class InsuranceDocumentsIndex extends Controller implements SearchFilterIdentifiers {
	@queryManager apollo: any;
	queryParams = ['aipId', 'page', 'policyNumber', 'sorts', 'size'];
	declare model: ModelFrom<OrganizationsInsuranceDocumentsRoute> | ModelFrom<BusinessesInsuranceDocumentsRoute>;

	@tracked aipId: string | null = null;
	@tracked page = 0;
	@tracked size = 100;
	@tracked policyNumber: string | null = null;
	@tracked sorts: SortObj[] = [{ valuePath: 'createdAt', isAscending: false }];
	@tracked currentScope = '';

	insuranceRoutePath: string = '';

	get columns(): TableColumn[] {
		const baseColumns: TableColumn[] = [
			{
				id: 'be4c5504-6e55-41b2-bb6b-b658f81d14f7',
				name: 'AIP',
				valuePath: 'InsurancePolicy.AIP.name',
				minWidth: 160,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '057f434d-fffc-46b6-b9a4-80ffd40d1186',
				name: 'Producer Name',
				valuePath: 'InsurancePolicy.producerName',
				minWidth: 160,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '143fbe05-e17c-4685-a674-ffb988792b36',
				name: 'Policy Number',
				valuePath: 'InsurancePolicy.policyNumber',
				minWidth: 160,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '2bb73ecb-f852-4c9b-8f66-dce6383380ee',
				name: 'Crop Year',
				valuePath: 'reinsuranceYear',
				minWidth: 160,
				textAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'a02ffcfd-7e77-4d5d-938e-ca38cc657764',
				name: 'Last Modified Date',
				valuePath: 'lastModifiedAt',
				minWidth: 160,
				cellComponent: CellComponents.IntlDateTimeFormat,
				componentArgs: { month: 'numeric', day: 'numeric', year: 'numeric' },
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '903524cb-3891-4d81-bddf-9e6b17889ef1',
				name: 'Type',
				valuePath: 'description',
				minWidth: 160,
				textAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'd85d2666-10f8-42ae-bdb4-556be39de68f',
				name: '',
				valuePath: 'url',
				minWidth: 160,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.Button,
				componentArgs: {
					size: 'xs',
					style: 'outline',
					text: 'Download',
					fn: this.downloadBtnTempAction,
				},
				isFixed: '',
				isVisible: true,
			},
		];
		return baseColumns;
	}

	get sortParams(): SortObj[] {
		return this.sorts;
	}

	set sortParams(sorts: SortObj[]) {
		this.sorts = sorts;
	}

	get currentPage(): number {
		return this.page;
	}

	set currentPage(page: number) {
		this.page = page;
	}

	get insuranceDocumentData() {
		return this.model.InsuranceFiles;
	}

	get totalNumInsuranceDocuments() {
		return this.model.InsuranceFileCount.count;
	}

	get query() {
		return query;
	}

	get searchFilterQueryParams() {
		const obj: {
			[param: string]: { [filterProperty: string]: string };
		} = {};

		const searchQueryParams = ['aipId', 'policyNumber'];
		searchQueryParams.forEach((param) => {
			const value = this[param as keyof InsuranceDocumentsIndex];

			if (value) {
				obj[param] = {
					filterRule: 'equals',
					filterValue: value,
				};

				// set filterComponent property to specify component for custom display extended from search-filter
				const filterDisplayObj = getFilterDisplayProperty(param);
				if (filterDisplayObj && filterDisplayObj.customComponent) {
					obj[param].filterComponent = filterDisplayObj.customComponent;
				}
				// set filterLabel property to specify custom label for filter - default would be filterIdentifier (matches queryParam)
				if (filterDisplayObj && filterDisplayObj.label) {
					obj[param].filterLabel = filterDisplayObj.label;
				}
			}
		});

		return obj;
	}

	@action
	async fetchSearchResults(searchText: string) {
		let searchApiResults = [];
		const insurancePolicyResults: searchResult[] = [];

		const insurancePolicyVariables = {
			limit: 5,
			...this.model.variables,
		};

		insurancePolicyVariables.where.InsurancePolicy = insurancePolicyVariables.where.InsurancePolicy || {};
		insurancePolicyVariables.where.InsurancePolicy.policyNumber = { contains: searchText };

		let insurancePolicies = (
			(await this.apollo.watchQuery({ query, variables: insurancePolicyVariables })).InsuranceFiles as InsuranceFile[]
		).map((file) => {
			return file.InsurancePolicy;
		});

		// Remove Insurance Policies with duplicate policy numbers
		insurancePolicies = insurancePolicies.filter((value, idx, arr) => arr.findIndex((p) => p.policyNumber === value.policyNumber) === idx);

		insurancePolicies.forEach((policy) => {
			const obj = {
				type: 'InsurancePolicy',
				id: policy.policyNumber,
				name: policy.policyNumber,
			};

			insurancePolicyResults.push(obj);
		});

		// get search API matches
		const variables = {
			query: searchText,
			typesToInclude: [TypeOfSearchItem.Aip],
		};

		searchApiResults = (await this.apollo.watchQuery({ query: searchQuery, variables })).Search;

		// return combined set
		return [...searchApiResults, ...insurancePolicyResults];
	}

	@action
	structureSearchResults(searchResults: searchResult[]) {
		const map = new Map();

		searchResults.forEach((item) => {
			if (map.has(item.type)) {
				map.get(item.type).push({ id: item.id, name: item.name, type: item.type });
			} else {
				map.set(item.type, [{ id: item.id, name: item.name, type: item.type }]);
			}
		});

		return map;
	}

	@action
	setSearchFilterQueryParam(searchResult: searchResult) {
		const mappedSearchFilter = this.mapSearchResult(searchResult);
		if (mappedSearchFilter) {
			this[mappedSearchFilter.filterIdentifier] = mappedSearchFilter.filterValue;
			this.setTablePageState();
		}
	}

	mapSearchResult(searchResult: searchResult): { filterIdentifier: SearchFilterIdentifier; filterValue: string } | null {
		let filterIdentifier: SearchFilterIdentifier;

		switch (searchResult.type) {
			case 'Aip':
				filterIdentifier = 'aipId';
				break;
			case 'InsurancePolicy':
				filterIdentifier = 'policyNumber';
				break;
			default:
				return null;
		}

		return {
			filterIdentifier,
			filterValue: searchResult.id ?? searchResult.name,
		};
	}

	@action
	clearSearchFilterQueryParam(filterIdentifier: string) {
		if (isSearchFilterIdentifier(filterIdentifier)) {
			this[filterIdentifier] = null;
			this.setTablePageState();
		}
	}

	@action
	async downloadBtnTempAction(rowData: any) {
		const aDownloadPdf = document.createElement('a');
		aDownloadPdf.href = rowData.url;
		aDownloadPdf.target = '_blank';
		aDownloadPdf.download = rowData.url.split('/').pop();
		document.body.appendChild(aDownloadPdf);
		aDownloadPdf.click();
		document.body.removeChild(aDownloadPdf);
	}

	@action
	setTablePageState(newPageVal?: number) {
		this.currentPage = newPageVal ?? 0;
		resetVaultTableScroll('insurance-documents-table');
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'insurance-documents/index': InsuranceDocumentsIndex;
	}
}
