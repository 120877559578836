import Controller from '@ember/controller';
import BusinessesBusinessLocations from 'vault-client/routes/locations/location/settings';
import { LocationEntity } from 'vault-client/types/graphql-types';
import { ModelFrom } from 'vault-client/utils/type-utils';

export default class LocationsShow extends Controller {
	declare model: ModelFrom<BusinessesBusinessLocations>;
	get location(): LocationEntity | undefined {
		return this.model.data?.Location;
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'locations/show': LocationsShow;
	}
}
