import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';

interface VaultUiDropdownRenderoutActionArgs {
	destinationElement: string;
}

export default class VaultUiDropdownRenderoutAction extends Component<VaultUiDropdownRenderoutActionArgs> {
	@tracked showContent = false;

	get destinationElement() {
		return document.getElementById(this.args.destinationElement);
	}

	@action
	openContent() {
		this.showContent = true;
	}

	@action
	closeContent() {
		this.showContent = false;
	}
}
