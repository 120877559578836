import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { queryManager } from 'ember-apollo-client';
import query from 'vault-client/graphql/queries/reports/unmapped-accounts.graphql';
import searchQuery from 'vault-client/graphql/queries/search.graphql';
import FCMFuzzyQuery from 'vault-client/graphql/queries/fcm-fuzzy-match.graphql';

import getFilterDisplayProperty from 'vault-client/utils/get-filter-display-property';
import { Fcm, FcmFilterDTO } from 'vault-client/types/graphql-types';
import { CellComponents, SortObj } from 'vault-client/types/vault-table';
import resetVaultTableScroll from 'vault-client/utils/reset-vault-table-scroll';
import unmappedAccountsReportQuery from 'vault-client/utils/unmapped-accounts-query';
import { ModelFrom } from 'vault-client/utils/type-utils';
import OrganizationsOrganizationUnmappedAccountsRoute from 'vault-client/routes/organizations/organization/unmapped-accounts';
import BusinessesBusinessUnmappedAccountsRoute from 'vault-client/routes/businesses/business/unmapped-accounts';
interface SearchFilterIdentifiers {
	accountId: string | null;
	fcmId: string | null;
}

type SearchFilterIdentifier = keyof SearchFilterIdentifiers;

function isSearchFilterIdentifier(key: unknown): key is SearchFilterIdentifier {
	const searchFilterIdentifiers = ['fcmId', 'accountId'];
	if (typeof key === 'string' && searchFilterIdentifiers.includes(key)) {
		return true;
	}
	return false;
}

interface searchResult {
	id?: string;
	name?: string;
	type?: string;
}

export default class UnmappedAccountsController extends Controller {
	queryParams = ['page', 'size', 'sorts', 'fcmId', 'accountId'];

	@queryManager apollo: any;

	declare model: ModelFrom<OrganizationsOrganizationUnmappedAccountsRoute> | ModelFrom<BusinessesBusinessUnmappedAccountsRoute>;

	@tracked organizationId: string | null = null;
	@tracked globalCustomerId: string | null = null;
	@tracked locationId: string | null = null;
	@tracked accountId: string | null = null;
	@tracked fcmId: string | null = null;
	@tracked page = 0;
	@tracked currentScope = '';
	@tracked sorts: SortObj[] = [{ valuePath: 'accountNumber', isAscending: true }];
	size = 100;
	reportsRoutePath = 'reports';
	accountRoutePath = 'accounts.show';

	get columns() {
		const baseColumns = [
			{
				id: '95905a37-5d7e-4061-8150-f3e5080a1769',
				name: 'Account Number',
				valuePath: 'accountNumber',
				minWidth: 180,
				textAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				linkRoute: this.accountRoutePath,
				linkModelPath: 'id',
			},
			{
				id: 'dd317216-9acf-4a6a-8ade-20f3a5d6d43c',
				name: 'FCM',
				valuePath: 'FCM.name',
				minWidth: 150,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '5694d3e2-9ccb-4ff8-9e7b-a3edb9868448',
				name: 'Account Name',
				valuePath: 'name',
				minWidth: 180,
				textAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
		];
		return baseColumns;
	}

	get currentPage(): number {
		return this.page;
	}

	set currentPage(page: number) {
		this.page = page;
	}

	get accountsData() {
		return this.model.Accounts;
	}

	get totalNumAccounts() {
		return this.model.AccountCount.count;
	}

	get searchFilterQueryParams() {
		const obj: {
			[param: string]: { [filterProperty: string]: string };
		} = {};
		const searchQueryParams = ['fcmId', 'accountId'];
		searchQueryParams.forEach((param) => {
			const value = this[param as keyof UnmappedAccountsController];

			if (value) {
				obj[param] = {
					filterRule: 'equals',
					filterValue: value,
				};

				// set filterComponent property to specify component for custom display extended from search-filter
				const filterDisplayObj = getFilterDisplayProperty(param);
				if (filterDisplayObj && filterDisplayObj.customComponent) {
					obj[param].filterComponent = filterDisplayObj.customComponent;
				}
				// set filterLabel property to specify custom label for filter - default would be filterIdentifier (matches queryParam)
				if (filterDisplayObj && filterDisplayObj.label) {
					obj[param].filterLabel = filterDisplayObj.label;
				}
			}
		});

		return obj;
	}

	get searchPlaceholder() {
		return 'Filter by FCM or account number...';
	}

	get searchPrompt() {
		return 'Type a search term to find accounts by FCM or account number.';
	}

	get query() {
		return query;
	}

	get formattedQueryParams() {
		const params = { sorts: this.sorts, accountId: this.accountId, fcmId: this.fcmId };
		return { ...unmappedAccountsReportQuery(params) };
	}

	@action
	updateSort(sorts: SortObj[]) {
		this.sorts = sorts;
	}

	@action
	async fetchSearchResults(searchText: string) {
		let searchApiResults: searchResult[] = [];
		const FCMResults: searchResult[] = [];

		const FCMvariables: { where: FcmFilterDTO } = {
			where: { name: { contains: searchText } },
		};

		const FCMs = (await this.apollo.watchQuery({ query: FCMFuzzyQuery, variables: FCMvariables })).FCMs as Fcm[];

		FCMs.forEach((FCM) => {
			const obj = {
				type: 'FCM',
				name: FCM.name,
				id: FCM.id,
			};

			FCMResults.push(obj);
		});

		const variables = {
			query: searchText,
			typesToInclude: ['BrokerageAccount'],
		};

		searchApiResults = (await this.apollo.watchQuery({ query: searchQuery, variables })).Search;

		return [...searchApiResults, ...FCMResults];
	}

	@action
	structureSearchResults(searchResults: searchResult[]) {
		const map = new Map();

		searchResults.forEach((item) => {
			if (map.has(item.type)) {
				map.get(item.type).push({
					id: item.id,
					name: item.name,
					type: item.type,
				});
			} else {
				map.set(item.type, [
					{
						id: item.id,
						name: item.name,
						type: item.type,
					},
				]);
			}
		});

		return map;
	}

	@action
	setSearchFilterQueryParam(searchResult: searchResult) {
		const mappedSearchFilter = this.mapSearchResult(searchResult);
		if (mappedSearchFilter) {
			this[mappedSearchFilter.filterIdentifier] = mappedSearchFilter.filterValue;
			this.setTablePageState();
		}
	}

	mapSearchResult(searchResult: searchResult): { filterIdentifier: SearchFilterIdentifier; filterValue: string } | null {
		let filterIdentifier: SearchFilterIdentifier;

		switch (searchResult.type) {
			case 'FCM':
				filterIdentifier = 'fcmId';
				break;
			case 'BrokerageAccount':
				filterIdentifier = 'accountId';
				break;
			default:
				return null;
		}

		if (searchResult.id || searchResult.name) {
			return {
				filterIdentifier,
				filterValue: (searchResult.id || searchResult.name) as string,
			};
		}

		return null;
	}

	@action
	clearSearchFilterQueryParam(filterIdentifier: string) {
		if (isSearchFilterIdentifier(filterIdentifier)) {
			this[filterIdentifier] = null;
			this.setTablePageState();
		}
	}

	@action
	setTablePageState(newPageVal?: number) {
		this.currentPage = newPageVal ?? 0;
		resetVaultTableScroll('unmapped-accounts-table');
	}
}
