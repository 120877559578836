import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div\n\tclass='inline-flex flex-wrap space-x-1 rounded-md px-2 py-1 text-white text-xs font-semibold min-w-100 {{this.backgroundColor}}'\n\t...attributes\n>\n\t<div class='flex-1'>\n\t\t<Price @price={{this.netChange}} @displayFactor={{@displayFactor}} @fractionDigits={{@fractionDigits}} />\n\t</div>\n\t<div class='flex-1'>\n\t\t&lpar;<IntlNumberFormat @value={{this.percentChange}} @style='percent' @minimumFractionDigits='2' @maximumFractionDigits='4' />&rpar;\n\t</div>\n</div>", {"contents":"<div\n\tclass='inline-flex flex-wrap space-x-1 rounded-md px-2 py-1 text-white text-xs font-semibold min-w-100 {{this.backgroundColor}}'\n\t...attributes\n>\n\t<div class='flex-1'>\n\t\t<Price @price={{this.netChange}} @displayFactor={{@displayFactor}} @fractionDigits={{@fractionDigits}} />\n\t</div>\n\t<div class='flex-1'>\n\t\t&lpar;<IntlNumberFormat @value={{this.percentChange}} @style='percent' @minimumFractionDigits='2' @maximumFractionDigits='4' />&rpar;\n\t</div>\n</div>","moduleName":"vault-client/components/session-change-badge.hbs","parseOptions":{"srcName":"vault-client/components/session-change-badge.hbs"}});
import SessionChangeComponent from 'vault-client/components/session-change';

export default class SessionChangeFormat extends SessionChangeComponent {
	get backgroundColor() {
		if (!this.hasChange) {
			return 'bg-brand-gray-60';
		}

		if (this.hasPositiveChange) {
			return 'bg-brand-lime-80';
		} else {
			return 'bg-red-700';
		}
	}
}
