import Controller from '@ember/controller';
import { inject as service } from '@ember/service';
import { queryManager } from 'ember-apollo-client';

export default class BrokerageIndexController extends Controller {
	@queryManager apollo: any;
	@service permissions: any;

	positionsRoute = '';
	transactionsRoute = '';
	accountsRoute = '';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'brokerage-index-controller': BrokerageIndexController;
	}
}
