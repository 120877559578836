import { action } from '@ember/object';
import Component from '@glimmer/component';
import { Chart, ChartTypeRegistry, ChartConfiguration, ChartData, ChartOptions } from 'chart.js';

interface BarChartArgs {
	id: string;
	type: keyof ChartTypeRegistry;
	chartData: ChartData;
	options: ChartOptions;
	plugins: any[];
	data: unknown;
	grouped: boolean;
	// eslint-disable-next-line no-unused-vars
	updateChart?: (chart: Chart, data: unknown) => {};
}

export default class BarChart extends Component<BarChartArgs> {
	chart!: Chart;

	@action
	createChart(element: HTMLCanvasElement) {
		const { chartData, options, plugins } = this.args;
		const appendedOptions = { ...options, grouped: this.args.grouped };
		const config: ChartConfiguration = {
			type: 'bar',
			data: chartData,
			options: appendedOptions,
			plugins,
		};

		const barChart = new Chart(element, config);
		this.chart = barChart;
	}

	@action
	// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
	updateChart(_element: HTMLCanvasElement) {
		const { updateChart, data } = this.args;

		const updateFunction = updateChart ?? this.defaultUpdateChartFunction;

		updateFunction(this.chart, data as any);
		this.chart.update();
	}

	defaultUpdateChartFunction = (chart: Chart, data: Record<string, number[]>) => {
		chart.data.datasets.forEach((dataset) => {
			dataset.data = data[dataset.label as string];
		});
	};
}
