import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<PowerSelect\n\t@selected={{this.selected}}\n\t@options={{this.advisors}}\n\t@searchEnabled='true'\n\t@renderInPlace={{true}}\n\t@searchField='name'\n\t@placeholder='Select an advisor'\n\t@onChange={{@onChange}}\n\t@allowClear={{@allowClear}}\n\t...attributes\n\tas |advisor|\n>\n\t{{advisor.name}}\n</PowerSelect>", {"contents":"<PowerSelect\n\t@selected={{this.selected}}\n\t@options={{this.advisors}}\n\t@searchEnabled='true'\n\t@renderInPlace={{true}}\n\t@searchField='name'\n\t@placeholder='Select an advisor'\n\t@onChange={{@onChange}}\n\t@allowClear={{@allowClear}}\n\t...attributes\n\tas |advisor|\n>\n\t{{advisor.name}}\n</PowerSelect>","moduleName":"vault-client/components/vgs-advisor-select.hbs","parseOptions":{"srcName":"vault-client/components/vgs-advisor-select.hbs"}});
import { action } from '@ember/object';
import Component from '@glimmer/component';
import { gql, useQuery } from 'glimmer-apollo';
import { tracked } from 'tracked-built-ins';
import { GrainAdvisor, Query } from 'vault-client/types/graphql-types';

const GET_GRAIN_ADVISORS = gql`
	query Advisors {
		GrainAdvisors(orderBy: { name: Asc }) {
			id
			name
			email
		}
	}
`;

interface VgsAdvisorSelectArgs {
	selected: GrainAdvisor | null;
	selectedId: string | null;
	onChange: (advisor: GrainAdvisor | null) => void;
}

export default class VgsAdvisorSelect extends Component<VgsAdvisorSelectArgs> {
	@tracked advisors: GrainAdvisor[] | null = [];

	constructor(owner: unknown, args: VgsAdvisorSelectArgs) {
		super(owner, args);
		this.fetchAdvisors();
	}

	get selected() {
		if (this.args.selected) {
			return this.args.selected;
		}

		const selectedId = this.args.selectedId;
		if (selectedId) {
			return this.advisors?.find((advisor) => advisor.id == selectedId) ?? null;
		}

		return null;
	}

	@action
	async fetchAdvisors() {
		await useQuery<{ GrainAdvisors: Query['GrainAdvisors'] }>(this, () => [
			GET_GRAIN_ADVISORS,
			{
				onComplete: (data) => {
					if (data?.GrainAdvisors) {
						this.advisors = data.GrainAdvisors;
					} else {
						this.advisors = [];
					}
				},
				onError: (error) => {
					console.error('Error on Grain Advisor select', error.message);
				},
			},
		]).promise;
	}
}
