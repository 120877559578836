import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import resetVaultTableScroll from 'vault-client/utils/reset-vault-table-scroll';
import { ModelFrom } from 'vault-client/utils/type-utils';
import BusinessesCropPlans from 'vault-client/routes/businesses/business/crop-plans';
import OrganizationsCropPlans from 'vault-client/routes/organizations/organization/crop-plans';
import { GET_CROP_PLANS_QUERY } from 'vault-client/routes/crop-plans';
import PermissionsService from 'vault-client/services/permissions';
import { GrainCropPlan } from 'vault-client/types/graphql-types';
import CropPlan from 'vault-client/models/vgs/crop-plan';

export default class CropPlansIndexController extends Controller {
	@service declare permissions: PermissionsService;

	declare model: ModelFrom<BusinessesCropPlans> | ModelFrom<OrganizationsCropPlans>;

	cropPlanRoutePath = 'crop-plan';
	queryParams = ['customerId', 'page', 'sorts'];

	itemsFn = (rows: GrainCropPlan[]) => {
		return rows.map((row) => {
			return new CropPlan(row);
		});
	};

	get columns() {
		const baseColumns = [
			{
				id: '5e80518f-b26d-4639-a5e8-48286f0914a7',
				name: '',
				valuePath: 'id',
				width: 80,
				textAlign: 'left',
				isSortable: false,
				cellComponent: 'Details',
				isFixed: '',
				isVisible: true,
				linkRoute: this.cropPlanRoutePath,
				linkModelPath: 'id',
			},
			{
				id: 'c42ecd9e-64ae-43eb-a413-2999b3154e9e',
				name: 'Business',
				valuePath: 'Customer.name',
				width: 200,
				textAlign: 'left',
				isSortable: false,
				cellComponent: 'String',
				isFixed: '',
				isVisible: true,
				linkRoute: 'businesses.business.dashboard',
				linkModelPath: 'Customer.id',
			},
			{
				id: '6aaa75d5-534d-41b3-aefb-386cf991c7a3',
				name: 'Year',
				valuePath: 'CropYear.year',
				width: 80,
				textAlign: 'left',
				isSortable: false,
				cellComponent: 'String',
				isFixed: '',
				isVisible: true,
			},
			{
				id: '79075ef6-a2b8-417f-b21d-ac693970d074',
				name: 'Crop',
				valuePath: 'Crop.name',
				width: 100,
				textAlign: 'left',
				isSortable: false,
				cellComponent: 'String',
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'cf6b6ce6-9212-4f52-a48c-462e0eab4b19',
				name: 'Acres',
				valuePath: 'acres',
				width: 90,
				cellComponent: 'IntlNumberFormat',
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '4d2b3b3b-b691-4429-b416-3631ffe1286b',
				name: 'APH',
				valuePath: 'aph',
				width: 90,
				cellComponent: 'IntlNumberFormat',
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '28c04fcc-5eb7-407e-a22d-6b2f1248fee1',
				name: 'Production',
				valuePath: 'production',
				width: 120,
				cellComponent: 'IntlNumberFormat',
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '8ea0f1b6-db44-49ee-83f5-868df356885d',
				name: 'RP %',
				valuePath: 'enrolledPercent',
				width: 80,
				cellComponent: 'IntlNumberFormat',
				componentArgs: {
					style: 'percent',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'd62206ad-a6d6-48a4-a560-53d47641bacb',
				name: 'RP Production',
				valuePath: 'enrolledProduction',
				width: 145,
				cellComponent: 'IntlNumberFormat',
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '845c403d-a07b-4b2a-a2d9-e32c0acea500',
				name: 'Filled',
				valuePath: 'bushelsFilled',
				width: 110,
				cellComponent: 'IntlNumberFormat',
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'eec49614-4753-47fe-955e-66dfd29a70b0',
				name: '% Sold',
				valuePath: 'percentSold',
				width: 100,
				cellComponent: 'IntlNumberFormat',
				componentArgs: {
					style: 'percent',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '68fc6798-ea86-499c-8084-7ea5d26827fb',
				name: 'Harvest Bu',
				valuePath: 'harvestBu',
				width: 110,
				cellComponent: 'IntlNumberFormat',
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '26108d31-9a51-4d3b-b075-8314030833b6',
				name: 'Storage Bu',
				valuePath: 'storageBu',
				width: 110,
				cellComponent: 'IntlNumberFormat',
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '1dc5d43a-e009-47d0-bf16-b26772fc964a',
				name: 'Breakeven',
				valuePath: 'breakEven',
				width: 140,
				cellComponent: 'IntlNumberFormat',
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '0db8dc20-dcb2-4211-9687-994135dbb78b',
				name: 'Goal',
				valuePath: 'goal',
				width: 140,
				cellComponent: 'IntlNumberFormat',
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '0e4125d1-b60e-4cb3-ad9a-0fddc48655e8',
				name: 'Avg. Future Price',
				valuePath: 'averageFuturePriceSold',
				width: 140,
				cellComponent: 'IntlNumberFormat',
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '1aab7da6-baa6-42f5-b045-0360adf5a4ee',
				name: 'Avg. Flat Price',
				valuePath: 'averageFlatPriceSold',
				width: 140,
				cellComponent: 'IntlNumberFormat',
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
		];
		return baseColumns;
	}

	@tracked currentScope = '';
	@tracked page = 0;
	@tracked size = 100;
	@tracked sorts = [];

	get currentPage() {
		return this.page;
	}

	set currentPage(page) {
		this.page = page;
	}

	get GrainCropPlansData() {
		return this.itemsFn(this.model.getCropPlans.data?.GrainCropPlans.sortBy('CropYear.year', 'Crop.name') ?? []);
	}

	get totalNumGrainCropPlans() {
		return this.model.getCropPlans.data?.GrainCropPlanCount?.count;
	}

	get query() {
		return GET_CROP_PLANS_QUERY;
	}

	@action
	setTablePageState(newPageVal = 0) {
		this.currentPage = newPageVal;
		resetVaultTableScroll('crop-plans-index-table');
	}
}
