import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { ModelFrom } from 'vault-client/utils/type-utils';
import route, { GET_FILLS } from 'vault-client/routes/vgs/feed-services/fills';
import { CellComponents, TableColumn } from 'vault-client/types/vault-table';
import resetVaultTableScroll from 'vault-client/utils/reset-vault-table-scroll';
import { action } from '@ember/object';
import { gql, useQuery } from 'glimmer-apollo';
import getFilterDisplayProperty from 'vault-client/utils/get-filter-display-property';
import { UiDateFilterOption } from 'vault-client/components/vault/ui-date-filter';
import { DateTime } from 'luxon';
import {
	GrainAdvisorFilterDTO,
	Query,
	Query_GrainLocationsArgs,
	Query_SearchArgs,
	Query_SellersArgs,
	TypeOfGrainOrderSales,
	TypeOfSearchItem,
} from 'vault-client/types/graphql-types';
import { SearchResult } from 'vault-client/types/vault-client';
import { getSalesTypeDisplayValue } from 'vault-client/utils/vgs-utils';
import GetAvailableFeedYearsService from 'vault-client/services/get-available-feed-years';
import { service } from '@ember/service';

const GET_SEARCH_QUERY = gql`
	query Search($query: String!, $typesToInclude: [TypeOfSearchItem!]) {
		Search(query: $query, typesToInclude: $typesToInclude) {
			id
			name
			type
			attributes
		}
	}
`;

const GET_GRAIN_ADVISORS = gql`
	query GrainAdvisors($where: GrainAdvisorFilterDTO) {
		GrainAdvisors(where: $where) {
			id
			name
		}
	}
`;

const GET_GRAIN_LOCATION = gql`
	query GrainLocation($where: GrainLocationFilterDTO) {
		GrainLocations(where: $where) {
			id
			name
		}
	}
`;

const GET_SELLER = gql`
	query Seller($where: SellerFilterDTO) {
		Sellers(where: $where) {
			id
			name
		}
	}
`;

type GetGrainAdvisor = {
	GrainAdvisors: Query['GrainAdvisors'];
};

type GetSeller = {
	Sellers: Query['Sellers'];
};

type GetLocation = {
	GrainLocations: Query['GrainLocations'];
};

export default class VgsFeedServicesFillsIndex extends Controller {
	@service('get-available-feed-years') declare getAvailableFeedYears: GetAvailableFeedYearsService;

	@tracked page = 0;
	@tracked size = 100;
	@tracked sorts = [{ valuePath: 'deliveryStartDate', isAscending: true }];
	@tracked grainAdvisorId: string | null = null;
	@tracked customerId: string | null = null;
	@tracked selectedFeedCategoryIds: string[] = [];
	@tracked futuresMonthStart: string = '1900-01-01';
	@tracked futuresMonthEnd: string = '2999-12-31';
	@tracked deliveryStartDateStart: string = '1900-01-01';
	@tracked deliveryStartDateEnd: string = '2999-12-31';
	@tracked deliveryEndDateStart: string = '1900-01-01';
	@tracked deliveryEndDateEnd: string = '2999-12-31';
	@tracked selectedSalesTypes: TypeOfGrainOrderSales[] = [];
	@tracked sellerId: string | null = null;
	@tracked grainLocationId: string | null = null;
	@tracked selectedFeedYears: number[] = [];

	declare model: ModelFrom<route>;
	queryParams = [
		'page',
		'size',
		'sorts',
		'grainAdvisorId',
		'customerId',
		'selectedFeedCategoryIds',
		'futuresMonthStart',
		'futuresMonthEnd',
		'deliveryStartDateStart',
		'deliveryStartDateEnd',
		'deliveryEndDateStart',
		'deliveryEndDateEnd',
		'selectedSalesTypes',
		'sellerId',
		'grainLocationId',
		'selectedFeedYears',
	];

	deliveryDateRangeFilterOptions: UiDateFilterOption[] = [
		{
			displayName: 'All Dates',
			startDate: '1900-01-01',
			endDate: '2999-12-31',
		},
		{
			displayName: 'Current Month',
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().endOf('month').toISODate(),
		},
		{
			displayName: 'Next 3 Months',
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().plus({ months: 3 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Next 6 Months',
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().plus({ months: 6 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Next 12 Months',
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().plus({ months: 12 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Next 24 Months',
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().plus({ months: 24 }).endOf('month').toISODate(),
		},
	];

	futuresMonthRangeFilterOptions: UiDateFilterOption[] = [
		{
			displayName: 'All Months',
			startDate: '1900-01-01',
			endDate: '2999-12-31',
		},
		{
			displayName: 'Current Month',
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().endOf('month').toISODate(),
		},
		{
			displayName: 'Next 3 Months',
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().plus({ months: 3 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Next 6 Months',
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().plus({ months: 6 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Next 12 Months',
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().plus({ months: 12 }).endOf('month').toISODate(),
		},
		{
			displayName: 'Next 24 Months',
			startDate: DateTime.local().startOf('month').toISODate(),
			endDate: DateTime.local().plus({ months: 24 }).endOf('month').toISODate(),
		},
	];

	get searchPlaceholder() {
		return 'Filter by Advisor, Customer, Location, or Seller.';
	}

	get searchPrompt() {
		return 'Type a search term to find fills by Advisor, Customer, Location, or Seller.';
	}

	get columns() {
		const baseColumns: TableColumn[] = [
			{
				id: 'c8d09269-9de6-4dab-bcf1-f21481720b45',
				name: '',
				width: 100,
				textAlign: 'center',
				isSortable: false,
				cellComponent: CellComponents.Button,
				componentArgs: {
					size: 'xs',
					style: 'outline',
					text: 'Details',
					fn: () => {},
				},
				isFixed: '',
				isVisible: true,
				linkRoute: 'vgs.feed-services.fills.show',
				linkModelPath: 'id',
			},
			{
				id: 'd4e4f62b-bc22-49cf-bc92-221fc6d5a763',
				name: 'Advisor',
				valuePath: '',
				minWidth: 115,
				width: 115,
				textAlign: 'center',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: 'c37652c3-a8ec-499a-97cc-4a8108f43ebc',
						name: 'First Name',
						valuePath: 'Plan.Customer.RelationshipOwner.firstName',
						minWidth: 115,
						width: 115,
						textAlign: 'left',
						isSortable: false,
						cellComponent: CellComponents.String,
						isFixed: '',
						isVisible: true,
					},
					{
						id: 'cef896ea-2af1-474f-b83c-db40c1ecacf8',
						name: 'Last Name',
						valuePath: 'Plan.Customer.RelationshipOwner.lastName',
						minWidth: 115,
						width: 115,
						textAlign: 'left',
						isSortable: false,
						cellComponent: CellComponents.String,
						isFixed: '',
						isVisible: true,
					},
				],
			},
			{
				id: 'e5bf0f7a-1fbd-4880-a045-24129517176f',
				name: ' Customer',
				valuePath: 'Plan.Customer.name',
				minWidth: 125,
				width: 125,
				textAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '988ec941-a06b-42f6-a4de-0453c4616085',
				name: 'Feed Year',
				valuePath: 'Plan.feedYear',
				minWidth: 110,
				width: 110,
				textAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'f2271aa7-aecc-4840-9c03-72cf038410fe',
				name: 'Ingredient',
				valuePath: 'Plan.FeedCategory.name',
				minWidth: 115,
				width: 115,
				textAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'c5b74f9f-a140-48ee-90bb-349ae9a0dfe9',
				name: 'Tons',
				valuePath: 'bushels',
				minWidth: 105,
				width: 105,
				cellComponent: CellComponents.String,
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '53cfeda7-21f6-4d5b-91c8-1f2a4a44da69',
				name: 'Futures Month',
				valuePath: 'futuresMonth',
				minWidth: 140,
				width: 140,
				cellComponent: CellComponents.MonthFormat,
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'f8a3264f-0a5b-490c-98f6-9940c8444f97',
				name: 'Delivery Start Date',
				valuePath: 'deliveryStartDate',
				minWidth: 165,
				width: 165,
				cellComponent: CellComponents.IntlDateTimeFormat,
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'b246c90f-5945-426b-9161-b41dc0852dea',
				name: 'Delivery End Date',
				valuePath: 'deliveryEndDate',
				minWidth: 165,
				width: 165,
				cellComponent: CellComponents.IntlDateTimeFormat,
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'ce676c70-2f50-4ac0-afda-17fb46645df9',
				name: 'Order Type',
				valuePath: 'salesType',
				minWidth: 115,
				width: 115,
				cellComponent: CellComponents.CustomFormat,
				componentArgs: {
					formatter: getSalesTypeDisplayValue,
				},
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '9794d858-e4e6-4a03-af4b-511e33420a94',
				name: 'Futures Price',
				valuePath: 'futurePrice',
				minWidth: 130,
				width: 130,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
					minimumFractionDigits: 2,
					maximumFractionDigits: 5,
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '6f771c5d-3ad0-4527-a945-1e567c3bc910',
				name: 'Basis',
				valuePath: 'basis',
				minWidth: 80,
				width: 80,
				cellComponent: CellComponents.IntlNumberFormat,
				componentArgs: {
					style: 'currency',
					currency: 'USD',
					currencySign: 'accounting',
				},
				textAlign: 'right',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'd741fb04-bdbd-4256-9402-805122fb865a',
				name: 'Seller',
				valuePath: 'Seller.name',
				minWidth: 100,
				width: 100,
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'c19b6afe-e27a-4816-9163-bac84dd84504',
				name: 'Location',
				valuePath: 'Location.name',
				minWidth: 110,
				width: 110,
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'c22b10dd-a7d3-4f2c-bfda-371d2eb48390',
				name: 'Delivery Terms',
				valuePath: 'deliveryTerms',
				minWidth: 140,
				width: 140,
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
			{
				id: '018a67d4-8cf7-48df-9c53-53be0b763703',
				name: 'Contract Number',
				valuePath: 'contractNumber',
				minWidth: 155,
				width: 155,
				cellComponent: CellComponents.String,
				textAlign: 'left',
				isSortable: true,
				isFixed: '',
				isVisible: true,
			},
		];
		return baseColumns;
	}

	get currentPage() {
		return this.page;
	}

	set currentPage(page) {
		this.page = page;
	}

	get rows() {
		return this.model.getFills.data?.GrainFillOrders ?? [];
	}
	get query() {
		return GET_FILLS;
	}

	get totalNumFills() {
		return this.model.getFills.data?.GrainFillOrderCount.count;
	}

	get feedCategories() {
		return this.model.getFills.data?.FeedCategories ?? [];
	}

	get salesTypes(): TypeOfGrainOrderSales[] {
		return [
			TypeOfGrainOrderSales.BasisOnly,
			TypeOfGrainOrderSales.FuturesOnly,
			TypeOfGrainOrderSales.SetBasis,
			TypeOfGrainOrderSales.SetFutures,
			TypeOfGrainOrderSales.Flat,
		];
	}

	get searchFilterQueryParams() {
		const obj: {
			[key: string]: any;
		} = {};
		const searchQueryParams = ['grainAdvisorId', 'customerId', 'sellerId', 'grainLocationId'] as const;
		searchQueryParams.forEach((param) => {
			const value = this[param];

			if (value) {
				obj[param] = {
					filterRule: 'equals',
					filterValue: value,
				};

				// set filterComponent property to specify component for custom display extended from search-filter
				const filterDisplayObj = getFilterDisplayProperty(param);

				if (filterDisplayObj && filterDisplayObj.customComponent) {
					obj[param].filterComponent = filterDisplayObj.customComponent;
				}
				// set filterLabel property to specify custom label for filter - default would be filterIdentifier (matches queryParam)
				if (filterDisplayObj && filterDisplayObj.label) {
					obj[param].filterLabel = filterDisplayObj.label;
				}
			}
		});

		return obj;
	}

	get selectedFeedCategoryIdsString() {
		if (this.selectedFeedCategoryIds.length === 0) {
			return 'All';
		}

		if (this.selectedFeedCategoryIds.length === 1) {
			return this.feedCategories.find((feedCategory) => feedCategory.id === this.selectedFeedCategoryIds[0])?.name ?? '';
		}

		return `${this.selectedFeedCategoryIds.length} Selected`;
	}

	get selectedSalesTypesString() {
		if (this.selectedSalesTypes.length === 0) {
			return 'All';
		}

		if (this.selectedSalesTypes.length === 1) {
			return getSalesTypeDisplayValue(this.selectedSalesTypes[0]);
		}

		return `${this.selectedSalesTypes.length} Selected`;
	}

	get selectedFeedYearsString() {
		if (this.selectedFeedYears.length === 0) {
			return 'All';
		}

		if (this.selectedFeedYears.length === 1) {
			return this.selectedFeedYears[0];
		}

		return `${this.selectedFeedYears.length} Selected`;
	}

	@action
	addSelectedFeedCategoryId(feedCategory: string | null) {
		if (feedCategory === null) {
			this.selectedFeedCategoryIds = [];
		} else if (this.selectedFeedCategoryIds.includes(feedCategory)) {
			this.selectedFeedCategoryIds = this.selectedFeedCategoryIds.filter((v) => v !== feedCategory);
		} else {
			this.selectedFeedCategoryIds = [...this.selectedFeedCategoryIds, feedCategory];
		}

		if (this.feedCategories.length === this.selectedFeedCategoryIds.length) {
			this.selectedFeedCategoryIds = [];
		}
		this.setTablePageState();
	}

	@action
	addSelectedSalesType(salesType: TypeOfGrainOrderSales | null) {
		if (salesType === null) {
			this.selectedSalesTypes = [];
		} else if (this.selectedSalesTypes.includes(salesType)) {
			this.selectedSalesTypes = this.selectedSalesTypes.filter((v) => v !== salesType);
		} else {
			this.selectedSalesTypes = [...this.selectedSalesTypes, salesType];
		}

		if (this.selectedSalesTypes.length === this.salesTypes.length) {
			this.selectedSalesTypes = [];
		}
		this.setTablePageState();
	}

	@action
	addSelectedFeedYears(feedYear: number | null) {
		if (feedYear === null) {
			this.selectedFeedYears = [];
		} else if (this.selectedFeedYears.includes(feedYear)) {
			this.selectedFeedYears = this.selectedFeedYears.filter((v) => v !== feedYear);
		} else {
			this.selectedFeedYears = [...this.selectedFeedYears, feedYear];
		}

		if (this.selectedFeedYears.length === this.getAvailableFeedYears.feedYears.length) {
			this.selectedFeedYears = [];
		}

		this.setTablePageState();
	}

	@action
	setTablePageState(newPageVal: number | null = null) {
		this.currentPage = newPageVal ?? 0;
		resetVaultTableScroll('fills-table');
	}

	@action
	async fetchSearchResults(searchText: string) {
		const advisorResults: SearchResult[] = [];
		const sellerResults: SearchResult[] = [];
		const searchQueryResults: SearchResult[] = [];
		const grainLocationResults: SearchResult[] = [];

		const searchQuery = useQuery<{ Search: Query['Search'] }, Query_SearchArgs>(this, () => [
			GET_SEARCH_QUERY,
			{
				variables: {
					query: searchText,
					typesToInclude: [TypeOfSearchItem.Customer],
				},
				onComplete: (data): void => {
					searchQueryResults.push(
						...(data?.Search.filter((item) => {
							// Filter out non-VGS customers
							if (item.type === TypeOfSearchItem.Customer && !item.attributes?.isVgs) {
								return false;
							}

							return true;
						}).map((item) => {
							switch (item.type) {
								case TypeOfSearchItem.Customer:
									return {
										type: 'customer',
										name: item.name,
										id: item.id,
									};
								default:
									return { type: '', name: '', id: '' };
							}
						}) ?? [])
					);
				},
			},
		]);

		const grainAdvisorQuery = useQuery<GetGrainAdvisor, { where: GrainAdvisorFilterDTO }>(this, () => [
			GET_GRAIN_ADVISORS,
			{
				variables: {
					where: {
						name: { contains: searchText },
					},
				},
				onComplete: (data): void => {
					advisorResults.push(
						...(data?.GrainAdvisors.map((advisor) => ({
							type: 'advisor',
							name: advisor.name,
							id: advisor.id,
						})) ?? [])
					);
				},
			},
		]);

		const grainLocationQuery = useQuery<GetLocation, Query_GrainLocationsArgs>(this, () => [
			GET_GRAIN_LOCATION,
			{
				variables: {
					where: {
						name: { contains: searchText },
					},
				},
				onComplete: (data): void => {
					grainLocationResults.push(
						...(data?.GrainLocations.map((location) => ({
							type: 'location',
							name: location.name,
							id: location.id,
						})) ?? [])
					);
				},
			},
		]);

		const sellerQuery = useQuery<GetSeller, Query_SellersArgs>(this, () => [
			GET_SELLER,
			{
				variables: {
					where: {
						name: { contains: searchText },
					},
				},
				onComplete: (data): void => {
					sellerResults.push(
						...(data?.Sellers.map((seller) => ({
							type: 'seller',
							name: seller.name,
							id: seller.id,
						})) ?? [])
					);
				},
			},
		]);

		const promises = [grainAdvisorQuery.promise, searchQuery.promise, sellerQuery.promise, grainLocationQuery.promise];

		await Promise.all(promises);

		return [...advisorResults, ...searchQueryResults, ...sellerResults, ...grainLocationResults];
	}

	@action
	structureSearchResults(searchResults: SearchResult[]) {
		const map = new Map();

		searchResults.forEach((item) => {
			if (map.has(item.type)) {
				map.get(item.type).push({ name: item.name, type: item.type, id: item.id });
			} else {
				map.set(item.type, [{ name: item.name, type: item.type, id: item.id }]);
			}
		});

		return map;
	}

	mapSearchResult(searchResult: SearchResult) {
		let filterIdentifier: keyof VgsFeedServicesFillsIndex | null = null;

		switch (searchResult.type) {
			case 'advisor':
				filterIdentifier = 'grainAdvisorId';
				break;
			case 'customer':
				filterIdentifier = 'customerId';
				break;
			case 'location':
				filterIdentifier = 'grainLocationId';
				break;
			case 'seller':
				filterIdentifier = 'sellerId';
				break;
		}

		return {
			filterIdentifier,
			filterValue: searchResult.id ?? searchResult.name ?? null,
		};
	}

	@action
	setDateRangeQueryParam(field: 'futuresMonth' | 'deliveryStartDate' | 'deliveryEndDate', value: { startDate: string; endDate: string }) {
		this[`${field}Start`] = value.startDate;
		this[`${field}End`] = value.endDate;
		this.setTablePageState();
	}

	@action
	setSearchFilterQueryParam(searchResult: SearchResult) {
		const mappedSearchFilter = this.mapSearchResult(searchResult);

		if (mappedSearchFilter.filterIdentifier) {
			this[mappedSearchFilter.filterIdentifier] = mappedSearchFilter.filterValue;
			this.setTablePageState();
		}
	}

	@action
	clearSearchFilterQueryParam(filterIdentifier: 'grainAdvisorId' | 'customerId' | 'sellerId' | 'grainLocationId') {
		this[`${filterIdentifier}`] = null;
		this.setTablePageState();
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	interface Registry {
		'vgs/feed-services/fills/index': VgsFeedServicesFillsIndex;
	}
}
