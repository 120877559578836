import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import isTouchDevice from 'vault-client/utils/is-touch-device';
import { Product } from 'vault-client/types/graphql-types';
import HistoricalPercentilesByProductMonth from 'vault-client/models/historical-percentiles-by-product-month';
import { TableColumn, CellComponents } from 'vault-client/types/vault-table';
import { ModelFrom } from 'vault-client/utils/type-utils';
import ReportsHistoricalPercentilesByProductRoute from 'vault-client/routes/reports/historical-percentiles-by-product';

export default class ReportsHistoricalPercentilesByProduct extends Controller {
	@tracked productId: string | null = null;
	declare model: ModelFrom<ReportsHistoricalPercentilesByProductRoute>;

	queryParams = ['productId'];
	@tracked months = [];

	reportsRoute: string = '';

	get product() {
		return this.model.Products[0];
	}

	get columns(): TableColumn[] {
		const baseColumns: TableColumn[] = [
			{
				id: 'd1d149d6-1f1e-4de7-af17-6d42d4caef0b',
				name: 'Month',
				valuePath: 'instrument.displayExpiresAt',
				minWidth: 90,
				cellComponent: CellComponents.MonthFormat,
				textAlign: 'left',
				isSortable: false,
				isFixed: !isTouchDevice() ? 'left' : '',
				isVisible: true,
			},
			{
				id: '2713fdae-051b-4484-b6ee-d63fd37108b3',
				name: 'Last Price',
				valuePath: 'instrument',
				minWidth: 105,
				cellComponent: CellComponents.MarketPriceFormat,
				componentArgs: {
					fractionDigitsPath: 'fractionDigits',
					displayFactorPath: 'displayFactor',
					side: 'last',
				},
				textAlign: 'right',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'c0b2d402-5c97-4d64-9ffc-b55c85e0b1b6',
				name: 'Hypothetical',
				valuePath: 'displayHypotheticalPrice',
				minWidth: 140,
				textAlign: 'center',
				isSortable: false,
				cellComponent: CellComponents.InputWithButton,
				componentArgs: {
					conditionalStyleFn: (datum: { [key: string]: any }, valuePath = '_hypotheticalPrice'): string => {
						if (datum[valuePath]) {
							return 'color: var(--brand-lemon-40)';
						} else {
							return 'color: var(--brand-gray-90)';
						}
					},
					inputType: 'number',
					buttonText: 'Reset',
					fn: this.resetThisMonth,
				},
				isFixed: '',
				isVisible: true,
			},
			{
				id: '1d61536a-16a5-41f0-a80e-684fbf8a177f',
				name: 'Percentiles',
				textAlign: 'center',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				subcolumns: [
					{
						id: '11810537-81dd-4279-ac2b-39dfe3b87c6a',
						name: '3 YR',
						valuePath: 'threeYearPercentile',
						width: 140,
						cellComponent: CellComponents.ColorizedPercent,
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: '75ee993f-c289-459d-9fd4-b66a8b81b4ef',
						name: '5 YR',
						valuePath: 'fiveYearPercentile',
						width: 140,
						cellComponent: CellComponents.ColorizedPercent,
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
					{
						id: '85abd4a0-78e7-4304-8ef8-4793ebb9c10a',
						name: '10 YR',
						valuePath: 'tenYearPercentile',
						width: 140,
						cellComponent: CellComponents.ColorizedPercent,
						textAlign: 'right',
						isSortable: false,
						isFixed: '',
						isVisible: true,
					},
				],
			},
		];
		return baseColumns;
	}

	get disableResetPrices() {
		return !this.months.some((month: HistoricalPercentilesByProductMonth) => {
			return !month.isHypotheticalPriceSameAsMarketPrice;
		});
	}

	@action
	setProductId(product: Product) {
		this.productId = product.id;
	}

	@action
	resetHypotheticalPrices() {
		this.months.forEach((month: HistoricalPercentilesByProductMonth) => {
			month.hypotheticalPrice = null;
		});
	}

	@action
	resetThisMonth(month: HistoricalPercentilesByProductMonth) {
		month.hypotheticalPrice = null;
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'reports/historical-percentiles-by-product': ReportsHistoricalPercentilesByProduct;
	}
}
