import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("{{#if @memberId}}\n\t<Vault::UiConfirmation as |confirmation|>\n\t\t<span {{did-insert this.fetchMember @memberId}}></span>\n\t\t<confirmation.title>Remove Confirmation</confirmation.title>\n\t\t<confirmation.body>Are you sure you want to remove this member? ({{#if\n\t\t\t\tthis.member.EntityMember.User\n\t\t\t}}{{this.member.EntityMember.User.email}}{{else}}{{this.member.EntityMember.userEmail}}\n\t\t\t{{/if}})<br />This action is irreversible.\n\t\t\t<br />\n\n\t\t</confirmation.body>\n\t\t<confirmation.cancel @style='plain' {{on 'click' this.closeConfirmation}}>Cancel</confirmation.cancel>\n\t\t<confirmation.action @style='destructive' {{on 'click' this.removeMember}}>Remove Member</confirmation.action>\n\t</Vault::UiConfirmation>\n{{/if}}", {"contents":"{{#if @memberId}}\n\t<Vault::UiConfirmation as |confirmation|>\n\t\t<span {{did-insert this.fetchMember @memberId}}></span>\n\t\t<confirmation.title>Remove Confirmation</confirmation.title>\n\t\t<confirmation.body>Are you sure you want to remove this member? ({{#if\n\t\t\t\tthis.member.EntityMember.User\n\t\t\t}}{{this.member.EntityMember.User.email}}{{else}}{{this.member.EntityMember.userEmail}}\n\t\t\t{{/if}})<br />This action is irreversible.\n\t\t\t<br />\n\n\t\t</confirmation.body>\n\t\t<confirmation.cancel @style='plain' {{on 'click' this.closeConfirmation}}>Cancel</confirmation.cancel>\n\t\t<confirmation.action @style='destructive' {{on 'click' this.removeMember}}>Remove Member</confirmation.action>\n\t</Vault::UiConfirmation>\n{{/if}}","moduleName":"vault-client/components/remove-member-modal.hbs","parseOptions":{"srcName":"vault-client/components/remove-member-modal.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import GET_MEMBER_QUERY from 'vault-client/graphql/queries/member-get.graphql';
import { tracked } from '@glimmer/tracking';
import { gql, useMutation, useQuery } from 'glimmer-apollo';
import { EntityMember, Mutation_deleteEntityMemberArgs, Query_EntityMemberArgs } from 'vault-client/types/graphql-types';

interface RemoveMemberModalArgs {
	memberId: string;
	closeModalFn: Function;
	model: any;
}

const DELETE_ENTITY_MEMBER = gql`
	mutation deleteEntityMember($id: String!) {
		deleteEntityMember(id: $id) {
			id
		}
	}
`;

type DeletEntityMemberMutation = {
	__typename?: 'Mutation';

	deleteEntityMember?: {
		id: string;
	} | null;
};

type GetMemberQuery = {
	__typename?: 'Query';

	entityMember?: EntityMember | null;
};

export default class RemoveMemberModal extends Component<RemoveMemberModalArgs> {
	@tracked member: any;

	deleteEntityMember = useMutation<DeletEntityMemberMutation, Mutation_deleteEntityMemberArgs>(this, () => [
		DELETE_ENTITY_MEMBER,
		{
			/* options */
		},
	]);

	getMember = useQuery<GetMemberQuery, Query_EntityMemberArgs>(this, () => [
		GET_MEMBER_QUERY,
		{
			variables: { id: this.args.memberId },
		},
	]);

	@action
	async fetchMember() {
		await this.getMember.promise;
		this.member = this.getMember.data;
	}

	@action
	async removeMember() {
		await this.deleteEntityMember.mutate({
			id: this.args.memberId,
		});
		await this.args.model.refetch();

		this.closeConfirmation();
	}

	@action
	closeConfirmation() {
		this.args.closeModalFn();
	}
}
