import { helper } from '@ember/component/helper';
import { TypeOfGrainFillOrderModification } from 'vault-client/types/graphql-types';
import { getGrainFillOrderModificationTypeDisplayValue } from 'vault-client/utils/vgs-utils';

export default helper(function formatOrderModificationType(positional: [unknown]) {
	const type = positional[0];
	if (!type || typeof type !== 'string') return '';

	if (!(Object.values(TypeOfGrainFillOrderModification) as string[]).includes(type)) return '';

	return getGrainFillOrderModificationTypeDisplayValue(type as TypeOfGrainFillOrderModification);
});
