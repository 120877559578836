import ReportsDryWheyStrategicInventoryModel from 'vault-client/controllers/reports/dry-whey-strategic-inventory-model';

export default class OrganizationsOrganizationDryWheyStrategicInventoryModel extends ReportsDryWheyStrategicInventoryModel {
	reportsRoute: string = 'organizations.organization.reports';
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'organizations/organization/dry-whey-strategic-inventory-model': OrganizationsOrganizationDryWheyStrategicInventoryModel;
	}
}
