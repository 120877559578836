function productSlugToContractSize(productSlug: string): number | null {
	switch (productSlug) {
		// Feed
		case 'grain-corn':
			return 5000; // Bushels
		case 'grain-soybean-meal':
			return 100; // Short Tons

		// Dairy
		case 'us-dairy-dry-whey':
			return 44000; // Pounds
		case 'us-dairy-nonfat-milk':
			return 44000; // Pounds
		case 'us-dairy-cheese':
			return 20000; // Pounds
		case 'us-dairy-butter':
			return 20000; // Pounds
		case 'us-dairy-class-iii':
			return 200000; // Pounds
		case 'us-dairy-class-iv':
			return 200000; // Pounds

		// Livestock
		case 'livestock-lean-hogs':
			return 40000; // Pounds
		case 'livestock-live-cattle':
			return 40000; // Pounds
		case 'livestock-feeder-cattle':
			return 50000; // Pounds
		default:
			console.error(`Unknown product slug in productSlugToContractSize: ${productSlug}`);
			return null;
	}
}

export { productSlugToContractSize };
