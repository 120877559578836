import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { gql, useMutation } from 'glimmer-apollo';
import { objectCompareStringify } from 'vault-client/utils/general';
import { isFormValid, getInvalidElements } from '../utils/form-validation';
import {
	Buyer,
	GrainFillOrder,
	GrainFillOrderUpdateDTO,
	GrainLocation,
	Mutation,
	Mutation_updateGrainFillOrderArgs,
	Seller,
	TypeOfGrainOrderSales,
	GrainOrderDeliveryTerms,
} from 'vault-client/types/graphql-types';
import { guidFor } from '@ember/object/internals';

const UPDATE_GRAIN_FILL_ORDER = gql`
	mutation UpdateGrainFillOrder($data: GrainFillOrderUpdateDTO!, $id: String!) {
		updateGrainFillOrder(data: $data, id: $id) {
			id
		}
	}
`;

interface EditFillButtonArgs {
	fill: GrainFillOrder;
}

export default class EditFillButton extends Component<EditFillButtonArgs> {
	@tracked showModal = false;
	@tracked showConfirmationModal = false;
	@tracked errorMessage: string | null = null;
	@tracked deliveryTerms: GrainOrderDeliveryTerms | null | undefined = this.args.fill.deliveryTerms;

	componentId = guidFor(this);

	updateGrainFillOrder = useMutation<Mutation['updateGrainFillOrder'], Mutation_updateGrainFillOrderArgs>(this, () => [
		UPDATE_GRAIN_FILL_ORDER,
		{
			onComplete: () => {
				this.closeModal();
			},
			onError: (error): void => {
				this.errorMessage = 'There was an error. Fill not updated.';
				console.error('Error while attempting to update fill.', error.message);
			},
			update: (cache) => {
				cache.evict({ fieldName: 'GrainFillOrder' });
				cache.evict({ fieldName: 'GrainFillOrders' });
				cache.evict({ fieldName: 'AggregateGrainFillOrders' });
				cache.evict({ fieldName: 'GrainCropPlans' });
				cache.evict({ fieldName: 'GrainCropPlan' });
				cache.evict({ fieldName: 'GrainFeedPlans' });
				cache.evict({ fieldName: 'GrainFeedPlan' });
				cache.gc();
			},
		},
	]);

	@tracked _bushels: string | null = null;
	get bushels() {
		return this._bushels ?? this.args.fill.bushels?.toString() ?? null;
	}
	set bushels(bushels) {
		this._bushels = bushels;
	}

	@tracked _futureMonth: string | null = null;
	get futureMonth() {
		return this._futureMonth ?? this.args.fill?.futuresMonth?.slice(0, -3) ?? null;
	}
	set futureMonth(futureMonth) {
		this._futureMonth = futureMonth;
	}

	@tracked _deliveryStartDate: string | null = null;
	get deliveryStartDate() {
		return this._deliveryStartDate ?? this.args.fill.deliveryStartDate ?? null;
	}
	set deliveryStartDate(deliveryStartDate) {
		this._deliveryStartDate = deliveryStartDate;
	}

	@tracked _deliveryEndDate: string | null = null;
	get deliveryEndDate() {
		return this._deliveryEndDate ?? this.args.fill.deliveryEndDate ?? null;
	}
	set deliveryEndDate(deliveryEndDate) {
		this._deliveryEndDate = deliveryEndDate;
	}

	@tracked _salesType: TypeOfGrainOrderSales | null = null;
	get salesType() {
		return this._salesType ?? this.args.fill.salesType ?? null;
	}
	set salesType(salesType) {
		this._salesType = salesType;
	}

	@tracked _futurePrice: string | null = null;
	get futurePrice() {
		return this._futurePrice ?? this.args.fill.futurePrice?.toString() ?? null;
	}
	set futurePrice(futurePrice) {
		this._futurePrice = futurePrice;
	}

	@tracked _spread: string | null = null;
	get spread() {
		return this._spread ?? this.args.fill.spread?.toString() ?? null;
	}
	set spread(spread) {
		this._spread = spread;
	}

	@tracked htaFee: string = this.args.fill.htaFee?.toString() || '0';

	@tracked rollFee: string = this.args.fill.rollFee?.toString() || '0';

	@tracked flexFee: string = this.args.fill.flexFee?.toString() || '0';

	@tracked _basis: string | null = null;
	get basis() {
		return this._basis ?? this.args.fill.basis?.toString() ?? null;
	}
	set basis(basis) {
		this._basis = basis;
	}

	@tracked _buyer: Buyer | null = null;
	get buyer() {
		return this._buyer ?? this.args.fill.Buyer ?? null;
	}
	set buyer(buyer) {
		this._buyer = buyer;
	}

	@tracked _seller: Seller | null = null;
	get seller() {
		return this._seller ?? this.args.fill.Seller ?? null;
	}
	set seller(seller) {
		this._seller = seller;
	}

	@tracked _location: GrainLocation | null = null;
	get location() {
		return this._location ?? this.args.fill.Location ?? null;
	}
	set location(location) {
		this._location = location;
	}

	@tracked _contractNumber: string | null = null;
	get contractNumber() {
		return this._contractNumber ?? this.args.fill.contractNumber ?? null;
	}
	set contractNumber(contractNumber) {
		this._contractNumber = contractNumber;
	}

	get disableConfirmAndSubmit(): boolean {
		return this.errorMessage != null || this.updateGrainFillOrder.loading;
	}

	get currentSet() {
		return {
			basis: this.args.fill.basis ?? null,
			bushels: this.args.fill.bushels ?? null,
			buyerId: this.args.fill.Buyer?.id ?? undefined,
			sellerId: this.args.fill.Seller?.id ?? undefined,
			flexFee: this.args.fill.flexFee || '0',
			futurePrice: this.args.fill.futurePrice,
			deliveryStartDate: this.args.fill.deliveryStartDate,
			deliveryEndDate: this.args.fill.deliveryEndDate,
			futuresMonth: this.args.fill?.futuresMonth ? this.args.fill.futuresMonth.slice(0, -3) + '-01' : null,
			htaFee: this.args.fill.htaFee || '0',
			locationId: this.args.fill.Location ? this.args.fill.Location.id : null,
			rollFee: this.args.fill.rollFee || '0',
			salesType: this.args.fill.salesType,
			spread: this.args.fill.spread ?? null,
			contractNumber: this.args.fill.contractNumber,
			deliveryTerms: this.args.fill.deliveryTerms,
		};
	}

	get updateSet(): GrainFillOrderUpdateDTO {
		return {
			basis: this.basis ? parseFloat(this.basis) : null,
			bushels: this.bushels ? parseFloat(this.bushels) : null,
			buyerId: this.buyer ? this.buyer.id : undefined,
			sellerId: this.seller ? this.seller.id : undefined,
			flexFee: this.flexFee ? parseFloat(this.flexFee) : 0,
			futurePrice: this.futurePrice ? parseFloat(this.futurePrice) : null,
			deliveryStartDate: this.deliveryStartDate ? this.deliveryStartDate : null,
			deliveryEndDate: this.deliveryEndDate ? this.deliveryEndDate : null,
			futuresMonth: this.futureMonth ? this.futureMonth + '-01' : null,
			htaFee: this.htaFee ? parseFloat(this.htaFee) : 0,
			locationId: this.location ? this.location.id : null,
			rollFee: this.rollFee ? parseFloat(this.rollFee) : 0,
			spread: this.spread ? parseFloat(this.spread) : null,
			...(this.salesType && { salesType: this.salesType }),
			contractNumber: this.contractNumber,
			deliveryTerms: this.deliveryTerms,
		};
	}

	@action
	openModal() {
		this.showModal = true;
	}

	@action
	closeModal() {
		this.showModal = false;
		this.errorMessage = null;
		this.showConfirmationModal = false;
	}

	@action
	openConfirmationModal() {
		if (isFormValid(document)) {
			if (objectCompareStringify(this.updateSet, this.currentSet)) {
				this.errorMessage = 'No changes were made to the Fill';
				return;
			}
			this.showConfirmationModal = true;
			this.showModal = false;
		} else {
			getInvalidElements(document);
		}
	}

	@action
	closeConfirmationModal() {
		this.showConfirmationModal = false;
		this.showModal = false;
		this.errorMessage = null;
	}

	@action
	confirm() {
		this.submitFill();
	}
	@action
	async submitFill() {
		if (this.args.fill) {
			const variables: Mutation_updateGrainFillOrderArgs = {
				id: this.args.fill.id,
				data: this.updateSet,
			};

			const _updateFillMutation = this.updateGrainFillOrder.mutate(variables);
			await _updateFillMutation;
		} else {
			throw new Error('No Fill available to update the Fill');
		}
	}
}
