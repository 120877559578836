import Controller from '@ember/controller';
import StrategicBuyingModel from '../../models/strategic-buying-model';
import { getOwner } from '@ember/application';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
//@ts-ignore
import { Product } from 'types/graphql-types';
import isTouchDevice from 'vault-client/utils/is-touch-device';
import { TableColumn, CellComponents } from 'vault-client/types/vault-table';
import ReportsCheeseStrategicBuyingModelRoute from 'vault-client/routes/reports/cheese-strategic-buying-model';
import { ModelFrom } from 'vault-client/utils/type-utils';

interface HtmlElement {
	target: HTMLInputElement;
}

export default class ReportsCheeseStrategicBuyingModel extends Controller {
	@tracked carryCost: number = 0.01;
	@tracked currentPriceInput: string = 'Front Future Price';
	@tracked canBuySpot: boolean = false;
	@tracked numberOfYears: number = 5;
	declare model: ModelFrom<ReportsCheeseStrategicBuyingModelRoute>;
	reportsRoute: string = '';
	get columns(): TableColumn[] {
		const baseColumns: TableColumn[] = [
			{
				id: '6557805f-730c-4135-834f-223011b86f78',
				name: 'Month',
				valuePath: 'displayExpiresAt',
				minWidth: 120,
				cellComponent: CellComponents.MonthFormat,
				textAlign: 'left',
				isSortable: false,
				isFixed: !isTouchDevice() ? 'left' : '',
				isVisible: true,
			},
			{
				id: '26b1845a-6932-496f-a689-7ad3a8c52895',
				name: 'Futures',
				valuePath: 'currentFuturePrice',
				minWidth: 120,
				cellComponent: CellComponents.PriceFormat,
				componentArgs: {
					fractionDigitsPath: 'product.MostCurrentFuture.SymbolGroup.fractionDigits',
					displayFactorPath: 'noDisplayFactor',
				},
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: true,
			},
			{
				id: 'b28d787e-d8c0-4acd-8285-b53db524d54f',
				name: 'Futures - Carry',
				valuePath: 'futuresMinusCarryCost',
				minWidth: 120,
				cellComponent: CellComponents.PriceFormat,
				componentArgs: {
					fractionDigitsPath: 'product.MostCurrentFuture.SymbolGroup.fractionDigits',
					displayFactorPath: 'noDisplayFactor',
				},
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: this.canBuySpot,
			},
			{
				id: 'e1bde6ce-ec5b-4fee-9f4c-2ee027fa2b56',
				name: 'Current',
				valuePath: 'currentPrice',
				minWidth: 120,
				cellComponent: CellComponents.PriceFormat,
				componentArgs: {
					fractionDigitsPath: 'product.MostCurrentFuture.SymbolGroup.fractionDigits',
					displayFactorPath: 'noDisplayFactor',
				},
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: this.canBuySpot,
			},
			{
				id: '4870affa-0157-415d-bce3-1993df6dcfd6',
				name: '1 Mo Carry Incentive',
				valuePath: 'oneMonthCarryIncentive',
				minWidth: 120,
				cellComponent: CellComponents.PriceFormat,
				componentArgs: {
					fractionDigitsPath: 'product.MostCurrentFuture.SymbolGroup.fractionDigits',
					displayFactorPath: 'noDisplayFactor',
				},
				textAlign: 'left',
				isSortable: false,
				isFixed: '',
				isVisible: this.canBuySpot,
			},
			{
				id: '21ebcc4d-3422-43a8-81d9-345fc0a1de08',
				name: 'Strategy',
				valuePath: 'riskManagementStrategy',
				minWidth: 120,
				textAlign: 'left',
				isSortable: false,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
			},
		];
		return baseColumns.filter((column) => column.isVisible);
	}

	prevSims: {
		oneMonthCarryIncentive: number | null;
		displayExpiresAt: string;
		symbol: string;
	}[] = [];

	// Cash settled prices for Cheese can either be "Front Future", "Cheese Barrel", or "Cheese Block" depending on what the user chooses to use. Defaulting to "Front Future".
	get spotProduct() {
		if (this.currentPriceInput == 'Spot Cheese Barrel') {
			return this.spotBarrelProduct;
		}

		if (this.currentPriceInput == 'Spot Cheese Block') {
			return this.spotBlockProduct;
		}

		return this.frontFuture;
	}

	get spotBlockProduct() {
		return this.model.Products?.find((product: Product) => product?.slug === 'us-dairy-cheese-block-spot');
	}

	get spotBarrelProduct() {
		return this.model.Products?.find((product: Product) => product?.slug === 'us-dairy-cheese-barrel');
	}

	get futuresProduct() {
		const products: Product[] = this.model.Products;
		return products?.find((product) => product.slug === 'us-dairy-cheese');
	}

	get frontFuture() {
		return this.futuresProduct?.CurrentFutures[0];
	}

	get months() {
		const owner = getOwner(this);
		const product = this.futuresProduct;
		return product?.CurrentFutures?.reduce((acc: any, future: any) => {
			const previousSims = acc.slice(-2);
			const currentSim = new StrategicBuyingModel(
				owner,
				product,
				future,
				this.carryCost,
				previousSims,
				this.spotProduct,
				this.currentPriceInput,
				this.canBuySpot,
				this.numberOfYears
			);

			acc.push(currentSim);
			return acc;
		}, []);
	}

	@action
	currentPriceRadioInput(e: HtmlElement) {
		return (this.currentPriceInput = e.target.value);
	}

	@action
	buySpotCheck(e: HtmlElement) {
		return (this.canBuySpot = e.target.checked);
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'reports/cheese-strategic-buying-model': ReportsCheeseStrategicBuyingModel;
	}
}
