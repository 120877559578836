import Route from '@ember/routing/route';
import { inject as service } from '@ember/service';
import { queryManager } from 'ember-apollo-client';

import query from 'vault-client/graphql/queries/organization-get.graphql';
import { OrganizationEntity } from 'vault-client/types/graphql-types';

export default class OrganizationsOrganizationRoute extends Route {
	@service applicationScope: any;
	@queryManager apollo: any;

	async model(params: { id: string }): Promise<{ Organization: OrganizationEntity }> {
		const response: { Organization: OrganizationEntity } = await this.apollo.watchQuery({
			query,
			variables: { organizationId: params.id },
		});

		this.applicationScope.organizationId = params.id;
		this.applicationScope.globalCustomerId = null;

		return response;
	}
}
