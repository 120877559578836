import Route from '@ember/routing/route';
import RouterService from '@ember/routing/router-service';
import { service } from '@ember/service';
import { gql, useQuery } from 'glimmer-apollo';
import { tracked } from 'tracked-built-ins';
import PermissionsService from 'vault-client/services/permissions';
import { Query, Query_GrainAdvisorArgs } from 'vault-client/types/graphql-types';

interface ModelParams {
	id: string;
}

type getAdvisor = {
	__typename?: 'Query';
	GrainAdvisor: Query['GrainAdvisor'];
	EntityMembers: Query['EntityMembers'];
	EntityUsers: Query['EntityUsers'];
};

const GET_ADVISOR = gql`
	query GrainAdvisor($id: String!) {
		GrainAdvisor(id: $id) {
			id
			name
			email
		}
		EntityMembers(where: { userId: { equals: $id }, Entity: { type: { equals: EntityGroup } } }) {
			id
			Entity {
				id
				name
			}
		}
		EntityUsers(where: { userId: { equals: $id } }) {
			id
			roles
		}
	}
`;

export default class VgsSettingsAdvisorsShow extends Route {
	@tracked variables: Query_GrainAdvisorArgs = { id: '' };
	getAdvisor = useQuery<getAdvisor, Query_GrainAdvisorArgs>(this, () => [GET_ADVISOR, { variables: this.variables }]);

	@service declare permissions: PermissionsService;
	@service declare router: RouterService;

	beforeModel() {
		if (!this.permissions.isBuyerRelations) {
			this.router.transitionTo('vgs.settings.advisors.index');
		}
	}

	async model(params: ModelParams) {
		const advisorId = params.id;

		this.variables = {
			id: advisorId,
		};

		await this.getAdvisor.promise;

		return {
			getAdvisor: this.getAdvisor,
		};
	}
}
