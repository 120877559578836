import AccountsIndexRoute from 'vault-client/routes/accounts';

type SortObj = {
	valuePath: string;
	isAscending: boolean;
};

interface QueryParams {
	customerId?: string | null;
	page: number;
	size: number;
	sorts: SortObj[];
	id: string;
}

export default class BusinessesAccounts extends AccountsIndexRoute {
	async model(params: QueryParams) {
		const businessParams = this.paramsFor('businesses.business') as { business_id: string };
		this.variables = {
			orderBy: this.generateOrderBy(params.sorts),
			where: this.generateWhere(null, businessParams.business_id),
			aggregateWhere: this.generateAggregateWhere(null, businessParams.business_id),
			offset: params.page * params.size,
			limit: params.size,
		};

		await this.getBrokerageAccounts.promise;

		// The businessId string is needed for the Add Account button functionality
		// This in the future can be replaced by a change in the API that makes user access clear on the accounts query. Such as the CurrentUserPermissions object
		return { getBrokerageAccounts: this.getBrokerageAccounts, variables: this.variables, businessId: businessParams.business_id };
	}
}
