import Component from '@glimmer/component';
import { action, set } from '@ember/object';
import { ColumnListMap, TableColumn } from 'vault-client/types/vault-table';
import updateColumnArray from 'vault-client/utils/update-column-array';
import isTouchDevice from 'vault-client/utils/is-touch-device';
import { SortableEvent } from 'sortablejs';

interface VaultColumnControlArgs {
	id: string;
	columns: TableColumn[];
	onChange: OnChangeFunc;
}

interface OnChangeFunc {
	// eslint-disable-next-line no-unused-vars
	(newColumnArr: TableColumn[]): void;
}

export default class VaultColumnControl extends Component<VaultColumnControlArgs> {
	// need to revisit mobile logic
	frozenLeftColumn = (col: TableColumn) => !isTouchDevice() && col.isFixed == 'left';
	bodyColumn = (col: TableColumn) => col.isFixed == '';
	frozenRightColumn = (col: TableColumn) => !isTouchDevice() && col.isFixed == 'right';
	filterColumnFunc = (func: Function) => {
		return this.args.columns.filter((col: TableColumn) => func(col));
	};

	get _fullColumnList(): ColumnListMap {
		const list = new Map([
			['frozenLeftColumns', [] as TableColumn[]],
			['bodyColumns', [] as TableColumn[]],
			['frozenRightColumns', [] as TableColumn[]],
		]);

		list.set('frozenLeftColumns', this.filterColumnFunc(this.frozenLeftColumn));
		list.set('bodyColumns', this.filterColumnFunc(this.bodyColumn));
		list.set('frozenRightColumns', this.filterColumnFunc(this.frozenRightColumn));

		return list;
	}

	get fullColumnList(): ColumnListMap {
		if (isTouchDevice()) {
			const touchDeviceColumnList = new Map(this._fullColumnList);
			touchDeviceColumnList.delete('frozenLeftColumns');
			touchDeviceColumnList.delete('frozenRightColumns');
			return touchDeviceColumnList;
		} else {
			return this._fullColumnList;
		}
	}

	get scrollElementId(): string {
		return `${this.args.id}-control-container`;
	}

	@action
	setColumnVisibility(parentList: string, parentItem: string, colId: string, e: Event) {
		const target = e.target as HTMLInputElement;
		const columnList = this.getColumnList(this._fullColumnList, parentList);

		if (parentItem) {
			const parentColumn = columnList?.find((col: TableColumn) => col.id == parentItem);

			if (parentColumn) {
				const subcolumn = parentColumn.subcolumns?.find((subcolumn: TableColumn) => subcolumn.id == colId);

				if (subcolumn && target) {
					set(subcolumn, 'isVisible', target.checked);
				}
			}
		} else {
			const column = columnList?.find((col: TableColumn) => col.id == colId);

			if (column && target) {
				set(column, 'isVisible', target.checked);
			}
		}

		this.args.onChange([...this._fullColumnList.values()].flat() as TableColumn[]);
	}

	@action
	updateOrder(event: SortableEvent) {
		this.args.onChange(updateColumnArray(this._fullColumnList, event));
	}

	getColumnList(columnList: ColumnListMap, listName: string): TableColumn[] | null | undefined {
		return columnList.has(listName) ? columnList.get(listName) : null;
	}
}
