import ReportsPriceScenariosRoute from 'vault-client/routes/reports/price-scenarios';

type ModelParams = {
	startDate: string;
	endDate: string;
};

export default class ReportsOrganizationsOrganizationPriceScenariosRoute extends ReportsPriceScenariosRoute {
	async model(params: ModelParams) {
		const startDate = params.startDate;
		const endDate = params.endDate;
		const organizationParams = this.paramsFor('organizations.organization') as { id: string };

		this.variables = {
			forecastedMilkProductionByMonths: this.generateForecastedMilkProductionByMonthWhere(startDate, endDate),
			futuresWhere: this.generateFuturesWhere(startDate, endDate),
			revenuesAndExpensesWhere: this.generateRevenuesAndExpensesWhere(startDate, endDate),
			premiumsAndDeductionsWhere: this.generatePremiumsAndDeductionsWhere(startDate, endDate),
			allocatedFuturePositionsWhere: this.generateAllocatedFuturePositionsWhere(startDate, endDate, organizationParams.id),
			allocatedOptionPositionsWhere: this.generateAllocatedOptionPositionsWhere(startDate, endDate, organizationParams.id),
			allocatedSwapPositionsWhere: this.generateAllocatedSwapPositionsWhere(startDate, endDate, organizationParams.id),
			allocatedSwaptionPositionsWhere: this.generateAllocatedSwaptionPositionsWhere(startDate, endDate, organizationParams.id),
			allocatedDrpEndorsementsWhere: this.generateAllocatedDrpEndorsementsWhere(startDate, endDate),
			scopeId: organizationParams.id,
		};

		await this.getPriceScenariosData.promise;

		return {
			getPriceScenariosData: this.getPriceScenariosData,
			entityId: organizationParams.id,
		};
	}
}
