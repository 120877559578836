import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<Vault::UiButton @style='destructive' @size={{@size}} {{on 'click' this.openConfirmation}}>{{yield}}</Vault::UiButton>\n{{#if this.showConfirmation}}\n\t<Vault::UiConfirmation as |confirmation|>\n\t\t<confirmation.title>Delete Confirmation</confirmation.title>\n\t\t<confirmation.body>Are you sure you want to delete this permission group?\n\t\t\t<br />This action is irreversible.\n\t\t</confirmation.body>\n\t\t<confirmation.cancel @style='plain' {{on 'click' this.closeConfirmation}}>Cancel</confirmation.cancel>\n\t\t<confirmation.action @style='destructive' {{on 'click' this.deleteEntityGroup}}>Delete Permission Group</confirmation.action>\n\t</Vault::UiConfirmation>\n{{/if}}", {"contents":"<Vault::UiButton @style='destructive' @size={{@size}} {{on 'click' this.openConfirmation}}>{{yield}}</Vault::UiButton>\n{{#if this.showConfirmation}}\n\t<Vault::UiConfirmation as |confirmation|>\n\t\t<confirmation.title>Delete Confirmation</confirmation.title>\n\t\t<confirmation.body>Are you sure you want to delete this permission group?\n\t\t\t<br />This action is irreversible.\n\t\t</confirmation.body>\n\t\t<confirmation.cancel @style='plain' {{on 'click' this.closeConfirmation}}>Cancel</confirmation.cancel>\n\t\t<confirmation.action @style='destructive' {{on 'click' this.deleteEntityGroup}}>Delete Permission Group</confirmation.action>\n\t</Vault::UiConfirmation>\n{{/if}}","moduleName":"vault-client/components/delete-entity-group-button.hbs","parseOptions":{"srcName":"vault-client/components/delete-entity-group-button.hbs"}});
import Component from '@glimmer/component';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { gql, useMutation } from 'glimmer-apollo';
import { Mutation_deleteEntityGroupArgs } from 'vault-client/types/graphql-types';
import RouterService from '@ember/routing/router-service';

interface DeleteEntityGroupButtonArgs {
	entityGroupId: string;
	organizationId: string;
}

const DELETE_ENTITY_GROUP_MUTATION = gql`
	mutation DeleteEntityGroup($id: String!) {
		deleteEntityGroup(id: $id) {
			id
		}
	}
`;

type DeleteEntityGroupMutation = {
	__typename?: 'Mutation';

	deletedEntityGroupId: String | null;
};

export default class DeleteEntityGroupButton extends Component<DeleteEntityGroupButtonArgs> {
	@service router: RouterService | undefined;
	@tracked showConfirmation = false;

	deleteEntityGroupMutation = useMutation<DeleteEntityGroupMutation, Mutation_deleteEntityGroupArgs>(this, () => [
		DELETE_ENTITY_GROUP_MUTATION,
		{
			variables: { id: this.args.entityGroupId },
		},
	]);

	@action
	openConfirmation() {
		this.showConfirmation = true;
	}

	@action
	closeConfirmation() {
		this.showConfirmation = false;
	}

	@action
	async deleteEntityGroup() {
		try {
			await this.deleteEntityGroupMutation.mutate();
		} catch (e) {
			this.closeConfirmation();
			return;
		}

		this.router?.transitionTo('organizations.organization.permission-groups', this.args.organizationId);
	}
}
