import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<PowerSelect\n\t@placeholder='Select a fill modification type'\n\t@selected={{@selected}}\n\t@options={{this.typesList}}\n\t@onChange={{@onChange}}\n\tas |type|\n>\n{{format-order-modification-type type}}\n</PowerSelect>", {"contents":"<PowerSelect\n\t@placeholder='Select a fill modification type'\n\t@selected={{@selected}}\n\t@options={{this.typesList}}\n\t@onChange={{@onChange}}\n\tas |type|\n>\n{{format-order-modification-type type}}\n</PowerSelect>","moduleName":"vault-client/components/modification-type-select.hbs","parseOptions":{"srcName":"vault-client/components/modification-type-select.hbs"}});
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { TypeOfGrainFillOrderModification, TypeOfGrainOrderSales } from 'vault-client/types/graphql-types';

interface ModificationTypeSelectComponentArgs {
	salesType: string | null;
	onChange: (salesType: string | null) => void;
}

export default class ModificationTypeSelectComponent extends Component<ModificationTypeSelectComponentArgs> {
	@tracked salesType: string | null = this.args.salesType;

	get typesList() {
		let types: string[] = [];

		if (this.salesType === TypeOfGrainOrderSales.Cbot) {
			types = [
				TypeOfGrainFillOrderModification.ExchangeHta,
				TypeOfGrainFillOrderModification.ExchangeCash,
				TypeOfGrainFillOrderModification.RollOffer,
				TypeOfGrainFillOrderModification.RollMarket,
				TypeOfGrainFillOrderModification.LiftAndSellCash,
				TypeOfGrainFillOrderModification.LiftAndSellHta,
				TypeOfGrainFillOrderModification.Cancel,
			];
		} else if (this.salesType === TypeOfGrainOrderSales.MarketHta || this.salesType === TypeOfGrainOrderSales.Hta) {
			types = [
				TypeOfGrainFillOrderModification.SetBasis,
				TypeOfGrainFillOrderModification.Cancel,
				TypeOfGrainFillOrderModification.RollOffer,
				TypeOfGrainFillOrderModification.RollMarket,
				TypeOfGrainFillOrderModification.RollMarketAndSetBasis,
			];
		} else if (this.salesType === TypeOfGrainOrderSales.Basis) {
			types = [
				TypeOfGrainFillOrderModification.SetFuturesOffer,
				TypeOfGrainFillOrderModification.RollOffer,
				TypeOfGrainFillOrderModification.SetFuturesMarket,
				TypeOfGrainFillOrderModification.RollMarket,
				TypeOfGrainFillOrderModification.RollMarketAndSetFuturesMarket,
				TypeOfGrainFillOrderModification.RollMarketAndSetFuturesOffer,
				TypeOfGrainFillOrderModification.Cancel,
			];
		} else if (this.salesType === TypeOfGrainOrderSales.Flat || this.salesType === TypeOfGrainOrderSales.MarketFlat) {
			types = [TypeOfGrainFillOrderModification.Roll, TypeOfGrainFillOrderModification.Cancel];
		} else if (this.salesType === TypeOfGrainOrderSales.Npe) {
			types = [
				TypeOfGrainFillOrderModification.Roll,
				TypeOfGrainFillOrderModification.SetBasis,
				TypeOfGrainFillOrderModification.Cancel,
				TypeOfGrainFillOrderModification.SetFuturesOffer,
				TypeOfGrainFillOrderModification.SetFuturesMarket,
				TypeOfGrainFillOrderModification.SetCashMarket,
				TypeOfGrainFillOrderModification.SetCashOffer,
			];
		}

		return types;
	}
}
