import Service from '@ember/service';
import { inject as service } from '@ember/service';
import jwtDecode from 'jwt-decode';
import query from 'vault-client/graphql/queries/permissions.graphql';
import { queryManager } from 'ember-apollo-client';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import {
	AccountFilterDTO,
	GrainCropPlanFilterDTO,
	OrganizationEntity,
	CurrentPositionFilterDTO,
	ProducerAndPolicyFilterDTO,
	User,
	UserRole,
	EntityUserRole,
} from 'vault-client/types/graphql-types';
import AuthService from 'vault-client/services/auth';
import { generateFullName } from 'vault-client/utils/general';
import ApplicationScope from './application-scope';

export default class PermissionsService extends Service {
	@service declare auth: AuthService;
	@service router: any;
	@service declare applicationScope: ApplicationScope;
	@queryManager apollo: any;

	@tracked showInsurance = false;
	@tracked showCropPlans = false;
	@tracked showBrokerage = false;
	@tracked showCustomers = false;
	@tracked showCustomer = false;
	@tracked showOrganizations = false;
	@tracked showOrganization = false;
	@tracked showLocations = false;
	@tracked showLocation = false;
	@tracked productSlugs: string[] = [];
	@tracked hasFetchedAccess = false;
	@tracked firstMemberOrganization: any;
	@tracked firstMemberCustomer: any;
	@tracked firstMemberCustomerOrganization: any;
	@tracked firstMemberLocation: any;
	@tracked organizationId: any;
	@tracked globalCustomerId: any;
	@tracked locationId: any;
	@tracked organizations: OrganizationEntity[] = [];
	@tracked _customers: Record<string, number> = {};
	@tracked me: User | null = null;

	@action
	async fetchAccess() {
		if (
			this.auth.isAuthenticated &&
			(!this.hasFetchedAccess ||
				this.organizationId !== this.applicationScope.organizationId ||
				this.globalCustomerId !== this.applicationScope.globalCustomerId ||
				this.locationId !== this.applicationScope.locationId)
		) {
			this.hasFetchedAccess = true;
			this.organizationId = this.applicationScope.organizationId;
			this.globalCustomerId = this.applicationScope.globalCustomerId;
			this.locationId = this.applicationScope.locationId;

			const insurancePolicyCountWhere: ProducerAndPolicyFilterDTO = {};

			if (this.applicationScope.globalCustomerId) {
				insurancePolicyCountWhere.OR = [
					{
						customerId: {
							equals: this.applicationScope.globalCustomerId,
						},
					},
					{
						ownerId: { equals: this.applicationScope.globalCustomerId },
					},
				];
			} else if (this.applicationScope.organizationId) {
				insurancePolicyCountWhere.OR = [
					{
						Customer: {
							organizationId: {
								equals: this.applicationScope.organizationId,
							},
						},
					},
					{
						ownerId: { equals: this.applicationScope.organizationId },
					},
				];
			}

			const grainCropPlanCountWhere: GrainCropPlanFilterDTO = {
				Customer:
					this.applicationScope.organizationId || this.applicationScope.globalCustomerId
						? {
								organizationId: { equals: this.applicationScope.organizationId },
								id: { equals: this.applicationScope.globalCustomerId },
						  }
						: {},
			};

			const aggregateCurrentPositionsWhere: CurrentPositionFilterDTO = {
				customerId: this.applicationScope.globalCustomerId ? { equals: this.applicationScope.globalCustomerId } : {},
			};
			if (this.applicationScope.organizationId) {
				aggregateCurrentPositionsWhere.Account = {
					OR: [
						{
							ownerId: { equals: this.applicationScope.organizationId },
						},
						{
							Customer: {
								organizationId: { equals: this.applicationScope.organizationId },
							},
						},
					],
				};
			}

			const accountCountWhere: AccountFilterDTO = {
				customerId: this.applicationScope.globalCustomerId ? { equals: this.applicationScope.globalCustomerId } : {},
			};
			if (this.applicationScope.organizationId) {
				accountCountWhere.OR = [
					{
						ownerId: { equals: this.applicationScope.organizationId },
					},
					{
						Customer: {
							organizationId: { equals: this.applicationScope.organizationId },
						},
					},
				];
			}

			const customersWhere = {
				organizationId: this.applicationScope.organizationId ? { equals: this.applicationScope.organizationId } : {},
			};

			const variables = {
				insurancePolicyCountWhere: insurancePolicyCountWhere,
				grainCropPlanCountWhere: grainCropPlanCountWhere,

				aggregateCurrentPositionsWhere: aggregateCurrentPositionsWhere,
				accountCountWhere: accountCountWhere,
				customersWhere: customersWhere,
			};

			try {
				const response = await this.apollo.watchQuery({ variables, query });
				this.me = response.Me;

				if (response.InsurancePolicyCount.count > 0) {
					this.showInsurance = true;
				} else {
					this.showInsurance = false;
				}

				if (response.GrainCropPlanCount.count > 0) {
					this.showCropPlans = true;
				} else {
					this.showCropPlans = false;
				}

				if (response.AccountCount.count > 0) {
					this.showBrokerage = true;
				} else {
					this.showBrokerage = false;
				}

				if (response.Customers.length > 1) {
					this.showCustomers = true;
					this.showCustomer = false;
				} else if (response.Customers.length === 1) {
					this.showCustomers = false;
					this.showCustomer = true;
				} else {
					this.showCustomers = false;
					this.showCustomer = false;
				}

				if (response.Organizations.length > 1) {
					this.showOrganizations = true;
					this.showOrganization = false;
				} else if (response.Organizations.length === 1) {
					this.showOrganizations = false;
					this.showOrganization = true;
				} else {
					this.showOrganizations = false;
					this.showOrganization = false;
				}

				if (response.Locations.length > 1) {
					this.showLocations = true;
					this.showLocation = false;
				} else if (response.Locations.length === 1) {
					this.showLocations = false;
					this.showLocation = true;
				} else {
					this.showLocations = false;
					this.showLocation = false;
				}

				const productSlugs = response.AggregateCurrentPositions.map((pos: any) => {
					return pos.Instrument.Product.slug;
				});
				this.productSlugs = productSlugs;

				this.firstMemberOrganization = response.Organizations?.length > 0 ? response.Organizations[0].id : null;
				this.firstMemberCustomer = response.Customers?.length > 0 ? response.Customers[0].id : null;
				this.firstMemberCustomerOrganization = response.Customers?.length > 0 ? response.Customers[0].organizationId : null;
				this.firstMemberLocation = response.Locations?.length > 0 ? response.Locations[0].id : null;
				this.organizations = response.Organizations;
				this._customers = response.AllCustomers.reduce((acc: Record<string, number>, curr: any) => ((acc[curr.id] = 1), acc), {});
			} catch (e) {
				// If unable to get permissions, fall back to base permissions.
				return;
			}
		}
	}

	get roles() {
		return this.me?.roles ?? [];
	}

	get showVGS() {
		return this.isBuyerRelations || this.hasGrainAdvisorRoleForCurrentOrg();
	}

	get isBuyerRelations() {
		return this.roles.includes(UserRole.BuyerRelations) ?? false;
	}

	get idToken() {
		return this.auth.idToken;
	}

	get accessToken() {
		return jwtDecode(this.auth.accessToken ?? '');
	}

	get userEmail() {
		return this.auth.idToken?.email;
	}

	get userName() {
		const firstName = this.auth.idToken?.given_name ?? this.me?.firstName ?? null;
		const lastname = this.auth.idToken?.family_name ?? this.me?.lastName ?? null;

		return generateFullName(firstName, lastname);
	}

	// Legacy, do not use. Only being used to prevent forcing users to re-enter price scenarios
	get auth0UserId() {
		return this.auth.user?.sub?.replace('auth0|', '') ?? null;
	}

	get userId() {
		return this.me?.id ?? null;
	}

	get isInternal() {
		const userEmailArray = this.userEmail?.split('@');
		const userEmailHost = userEmailArray?.length == 2 ? userEmailArray[1] : null;
		return userEmailHost == 'ever.ag' || userEmailHost == 'vaultdairy.com';
	}

	get showClassIii() {
		return this.productSlugs.includes('us-dairy-class-iii') || this.showInsurance;
	}

	get showClassIv() {
		return this.productSlugs.includes('us-dairy-class-iv') || this.showInsurance;
	}

	get showCheese() {
		return this.productSlugs.includes('us-dairy-cheese');
	}

	get showButter() {
		return this.productSlugs.includes('us-dairy-butter');
	}

	get showCorn() {
		return this.productSlugs.includes('grain-corn') || this.showInsurance;
	}

	get showSoybeans() {
		return this.productSlugs.includes('grain-soybeans');
	}

	get showSoybeanMeal() {
		return this.productSlugs.includes('grain-soybean-meal') || this.showInsurance;
	}

	get showNonfatDryMilk() {
		return this.productSlugs.includes('us-dairy-nonfat-milk');
	}

	get showDryWhey() {
		return this.productSlugs.includes('us-dairy-dry-whey');
	}

	get showCheeseBlocks() {
		return this.productSlugs.includes('us-dairy-cheese-block');
	}

	get showExposure() {
		return this.productSlugs.length > 0 || this.showInsurance;
	}

	get showMyData(): boolean {
		return this.showOrganizations || this.showOrganization || this.showCustomers || this.showCustomer;
	}

	canAccessOrganization(id: string): boolean {
		return !!this.organizations.some((org) => org.id === id);
	}

	canAccessCustomer(id: string): boolean {
		return !!this._customers[id];
	}

	private hasGrainAdvisorRoleForCurrentOrg() {
		const currentOrganization = this.organizations.find((org) => this.applicationScope.organizationId === org.id);
		const currentOrgEntityUser = currentOrganization?.EntityUsers.find((entityUser) => entityUser.userId === this.userId);
		return !!currentOrgEntityUser?.roles.includes(EntityUserRole.GrainAdvisor);
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'permissions-service': PermissionsService;
	}
}
