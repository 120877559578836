import Controller from '@ember/controller';
import { tracked } from '@glimmer/tracking';
import { inject as service } from '@ember/service';
import { CellComponents, SortObj, TableColumn } from 'vault-client/types/vault-table';
import { action } from '@ember/object';
import { DateTime } from 'luxon';
import { Product, Future, TypeOfInstrumentSymbolGroup } from 'vault-client/types/graphql-types';
import { OptionChains } from 'vault-client/routes/markets/product/futures/future';
import { guidFor } from '@ember/object/internals';

export default class MarketsProductFuturesFuture extends Controller {
	queryParams = ['showAllOptions'];

	@service router: any;
	@tracked model!: { optionChains: OptionChains; Future: Future; Product: Product };
	@tracked sorts: SortObj[] = [{ valuePath: 'strike', isAscending: true }];
	@tracked showAllOptions = false;
	@tracked expirationDate: string | null = null;
	@tracked optionName: string | null = null;
	@tracked historicalPricesStartDate = DateTime.now().minus({ month: 1 }).toISODate();
	@tracked historicalPricesEndDate = DateTime.now().toISODate();
	@tracked activeChart: 'Historical Prices' | 'Seasonality' = 'Historical Prices';
	@tracked columns: TableColumn[] = [
		{
			id: '0b401389-95f8-4d84-88c1-0d343baa28c5',
			name: 'Calls',
			cellComponent: CellComponents.String,
			textAlign: 'center',
			isFixed: '',
			isVisible: true,
			subcolumns: [
				{
					id: '2f1840be-1272-43fe-bd99-dbf89c557e6a',
					name: 'Implied Volatility',
					valuePath: 'options.Call.impliedVolatility',
					cellComponent: CellComponents.IntlNumberFormat,
					componentArgs: {
						minimumFractionDigits: '2',
						maximumFractionDigits: '2',
					},
					textAlign: 'right',
					isSortable: true,
					isFixed: '',
					isVisible: true,
				},
				{
					id: '680d5c08-522d-4cbc-8816-0700299caef2',
					name: 'Delta',
					valuePath: 'options.Call.delta',
					cellComponent: CellComponents.IntlNumberFormat,
					componentArgs: {
						minimumFractionDigits: '3',
						maximumFractionDigits: '3',
					},
					textAlign: 'right',
					isSortable: true,
					isFixed: '',
					isVisible: true,
				},
				{
					id: '342ee54d-552c-4641-8390-d98b191cf0cd',
					name: 'Last',
					valuePath: 'options.Call.last',
					cellComponent: CellComponents.PriceFormat,
					componentArgs: {
						displayFactorPath: 'displayFactor',
						fractionDigitsPath: 'fractionDigits',
					},
					textAlign: 'right',
					isSortable: true,
					isFixed: '',
					isVisible: true,
				},
			],
		},
		{
			id: '18c65723-06bb-486f-83cb-26b2b5e306c3',
			name: 'Strike',
			valuePath: 'strike',
			cellComponent: CellComponents.PriceFormat,
			componentArgs: {
				displayFactorPath: 'displayFactor',
				fractionDigitsPath: 'fractionDigits',
			},
			textAlign: 'right',
			isSortable: true,
			isFixed: '',
			isVisible: true,
		},
		{
			id: 'dad2fd5e-e66f-400b-8e54-fdb85f9fe61e',
			name: 'Puts',
			cellComponent: CellComponents.String,
			textAlign: 'center',
			isFixed: '',
			isVisible: true,
			subcolumns: [
				{
					id: '66151386-d4d6-474e-9877-b40b4c2c44d3',
					name: 'Last',
					valuePath: 'options.Put.last',
					cellComponent: CellComponents.PriceFormat,
					componentArgs: {
						displayFactorPath: 'displayFactor',
						fractionDigitsPath: 'fractionDigits',
					},
					textAlign: 'right',
					isSortable: true,
					isFixed: '',
					isVisible: true,
				},
				{
					id: 'c1c015d0-ac7a-4646-87dc-ba9fdbdd9073',
					name: 'Delta',
					valuePath: 'options.Put.delta',
					cellComponent: CellComponents.IntlNumberFormat,
					componentArgs: {
						minimumFractionDigits: '3',
						maximumFractionDigits: '3',
					},
					textAlign: 'right',
					isSortable: true,
					isFixed: '',
					isVisible: true,
				},
				{
					id: 'd373af00-038f-48f2-b81d-4eab6d8a26f0',
					name: 'Implied Volatility',
					valuePath: 'options.Put.impliedVolatility',
					cellComponent: CellComponents.IntlNumberFormat,
					componentArgs: {
						minimumFractionDigits: '2',
						maximumFractionDigits: '2',
					},
					textAlign: 'right',
					isSortable: true,
					isFixed: '',
					isVisible: true,
				},
			],
		},
	];

	id = guidFor(this);

	get futureExpirationDateTime() {
		return DateTime.fromISO(this.model.Future.expiresAt);
	}

	get data() {
		return this.optionName
			? this.model.optionChains[this.optionName].find((obj: any) => obj.expirationDate === this.expirationDate)?.optionsChain || []
			: [];
	}

	get barchartSymbol() {
		return this.model.Future.barchartSymbol;
	}

	get expirationDates(): string[] {
		if (!this.optionName) return [];

		return this.model.optionChains[this.optionName].map((obj: any) => obj.expirationDate).sort();
	}

	get optionNames(): string[] {
		return Object.keys(this.model.optionChains);
	}

	get futureSymbolGroup() {
		return this.model.Future.SymbolGroup;
	}

	get optionSymbolGroup() {
		return this.model.Product.InstrumentSymbolGroups.find((symbolGroup) => symbolGroup.type === TypeOfInstrumentSymbolGroup.Option);
	}

	get historicalPricesChartId() {
		return `${this.model.Product.name.toLowerCase()}-historical-prices-chart`;
	}

	get seasonalityChartId() {
		return `${this.model.Product.name.toLowerCase()}-seasonality-chart`;
	}

	get volatilityCurveChartId() {
		return `${this.model.Product.name.toLowerCase()}-volatility-curve-chart`;
	}

	get chartOptions() {
		const chartOptions = ['Historical Prices', 'Seasonality'];
		// if (this.data.length > 0) {
		// 	chartOptions.push('Volatility Curve');
		// }

		return chartOptions;
	}

	get activeChartId() {
		switch (this.activeChart) {
			case 'Historical Prices':
				return this.historicalPricesChartId;
			case 'Seasonality':
				return this.seasonalityChartId;
			// case 'Volatility Curve':
			// 	return this.volatilityCurveChartId;
		}
	}

	@action
	setExpirationDate(expirationDate: string, callback: () => void) {
		callback();
		this.expirationDate = expirationDate;
	}

	@action
	setOptionName(optionName: string, callback: () => void) {
		this.optionName = optionName;
		this.setExpirationDate(this.expirationDates[0], callback);
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'markets/product/futures/future': MarketsProductFuturesFuture;
	}
}
