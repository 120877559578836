import { A } from '@ember/array';
import Controller from '@ember/controller';
import { action } from '@ember/object';
import { tracked } from '@glimmer/tracking';
import { CellComponents, SortObj, TableColumn } from 'vault-client/types/vault-table';
import resetVaultTableScroll from 'vault-client/utils/reset-vault-table-scroll';
import checkStorageAvailable from 'vault-client/utils/check-storage-available';
import { Product } from 'vault-client/types/graphql-types';
import { UiSecondaryNavItemArgs } from 'vault-client/components/ui-secondary-nav-item';
import { analyticsCustomTracking } from 'vault-client/utils/analytics-tracking';

export default class MarketsIndexController extends Controller {
	secondaryNavItems: Omit<UiSecondaryNavItemArgs, 'uid'>[] = [
		{
			label: 'All Products',
			link: 'markets',
		},
	];

	current: { label: string; route: string; query?: string; model?: string } = {
		label: 'Markets',
		route: 'markets',
	};

	@tracked favoriteProducts: Product[] = [];
	@tracked model: any;

	@tracked sorts: SortObj[] = [
		{ valuePath: 'groupName', isAscending: true },
		{ valuePath: 'name', isAscending: true },
	];

	@tracked columns: TableColumn[] = A([
		{
			id: '50bc8586-1ca0-11ec-9621-0242ac130002',
			name: 'Product Group',
			valuePath: 'groupName',
			cellComponent: CellComponents.String,
			minWidth: 160,
			textAlign: 'left',
			isSortable: true,
			isFixed: '',
			isVisible: true,
		},
		{
			id: '80d49b5a-1ca0-11ec-9621-0242ac130002',
			name: 'Product',
			valuePath: 'name',
			cellComponent: CellComponents.String,
			minWidth: 250,
			textAlign: 'left',
			isSortable: true,
			isFixed: '',
			isVisible: true,
			linkRoute: 'markets.product',
			linkModelPath: 'id',
		},
		{
			id: 'e5453db8-6116-474c-9e28-35cdd6528122',
			name: 'Symbol',
			valuePath: 'marketDataInstrument.exchangeSymbol',
			cellComponent: CellComponents.String,
			minWidth: 110,
			textAlign: 'left',
			isSortable: false,
			isFixed: '',
			isVisible: true,
		},
		{
			id: 'dc0566aa-d9dd-4554-bbc8-6b1a2cd4a29d',
			name: 'Exp Month',
			valuePath: 'marketDataInstrument.displayExpiresAt',
			minWidth: 110,
			cellComponent: CellComponents.MonthFormat,
			textAlign: 'left',
			isSortable: false,
			isFixed: '',
			isVisible: true,
		},
		{
			id: '8624a51e-1ca0-11ec-9621-0242ac130002',
			name: 'Last Price',
			valuePath: 'marketDataInstrument',
			minWidth: 110,
			cellComponent: CellComponents.MarketPriceFormat,
			componentArgs: {
				fractionDigitsPath: 'fractionDigits',
				displayFactorPath: 'displayFactor',
			},
			textAlign: 'right',
			isSortable: false,
			isFixed: '',
			isVisible: true,
		},
		{
			id: '8d85a592-1ca0-11ec-9621-0242ac130002',
			name: 'Session Change',
			valuePath: 'marketDataInstrument',
			minWidth: 150,
			cellComponent: CellComponents.SessionChangeFormat,
			componentArgs: {
				fractionDigitsPath: 'fractionDigits',
				displayFactorPath: 'displayFactor',
			},
			textAlign: 'right',
			isSortable: false,
			isFixed: '',
			isVisible: true,
		},
	]);

	get products(): Product[] {
		const products: Product[] = this.model.Products;
		return products.map((product) => {
			const groupName = product.ProductGroup.name;
			const marketDataInstrument = product.CashInstrument ?? product.MostCurrentFuture;
			const symbolGroup = marketDataInstrument?.SymbolGroup;
			return {
				...product,
				groupName,
				marketDataInstrument,
				displayFactor: symbolGroup?.displayFactor ?? 1,
				fractionDigits: symbolGroup?.fractionDigits ?? 1,
			};
		});
	}

	get productsWithoutFavorites(): Product[] {
		return this.products.filter((p) => {
			return p.MostCurrentFuture ? !this.favoriteProducts.find((fp) => p.id === fp.id) : null;
		});
	}

	@action
	updateSorts(sorts: SortObj[]) {
		this.sorts = sorts;
		resetVaultTableScroll('markets-all-products-table');
	}

	@action
	addFavoriteProduct(product: Product) {
		analyticsCustomTracking('Added Favorite Product', { 'Product Added': `${product.name}` });
		for (const p of this.favoriteProducts) {
			if (p.id === product.id) {
				return;
			}
		}

		this.favoriteProducts = [...this.favoriteProducts, product];
		this.setStoredFavorites();
	}

	@action
	removeFavoriteProduct(product: Product) {
		analyticsCustomTracking('Removed Favorite Product', { 'Product Removed': `${product.name}` });
		this.favoriteProducts = this.favoriteProducts.filter((p) => {
			return p.id !== product.id;
		});
		this.setStoredFavorites();
	}

	@action
	setDragImage(_object: any, event: DragEvent) {
		const header = (event.target as HTMLElement).children.item(0)?.children?.item(0);
		event.dataTransfer?.setDragImage(header as Element, 0, 0);
	}

	@action
	setStoredFavorites() {
		window.localStorage.setItem('markets.favorites', JSON.stringify(this.favoriteProducts.map((p) => p.id)));
	}

	@action
	fetchStoredFavorites() {
		if (checkStorageAvailable('localStorage')) {
			// An update has been made in July 2023 to redesign the markets index page (previously called the all products page)
			// During that update we moved the markets/all-producs routes to be just markets
			// We wanted to switch the local storage key name from 'markets.all-products.favorites' to 'markets.favorites' to correspond with that change
			// However we want the users to have a seamless experience and not see their favorite products reset and in turn need to add them again
			// The following few lines of code address that and should be in the codebase for a temporary period and then get depricated - A. Hassan
			const favTransfer = window.localStorage.getItem('markets.all-products.favorites');
			if (favTransfer) {
				window.localStorage.setItem('markets.favorites', favTransfer);
				window.localStorage.removeItem('markets.all-products.favorites');
			}

			this.favoriteProducts = [];
			const temp = window.localStorage.getItem('markets.favorites');
			if (temp) {
				const storedFavoriteIDs: string[] = JSON.parse(temp);
				for (const id of storedFavoriteIDs) {
					const product = this.products.find((p) => {
						return id === p.id;
					});

					if (product) {
						this.favoriteProducts = [...this.favoriteProducts, product];
					}
				}
			}
		}
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'markets/all-product': MarketsIndexController;
	}
}
