import Controller from '@ember/controller';
import BusinessesBusinessRoute from 'vault-client/routes/businesses/business';
import { BusinessEntityRole } from 'vault-client/types/graphql-types';
import { CellComponents } from 'vault-client/types/vault-table';
import { ModelFrom } from 'vault-client/utils/type-utils';

export default class BusinessesBusinessOperations extends Controller {
	declare model: ModelFrom<BusinessesBusinessRoute>;

	get columns() {
		const baseColumns = [
			{
				id: 'dc5f99c3-87af-4b24-9671-4c1f4a3013e2',
				name: 'Name',
				valuePath: 'name',
				minWidth: 225,
				textAlign: 'left',
				headerTextAlign: 'left',
				isSortable: true,
				cellComponent: CellComponents.String,
				isFixed: '',
				isVisible: true,
				linkRoutePath: 'linkRoute',
				linkModelPath: 'id',
			},
		];

		return baseColumns;
	}

	get rows() {
		const businessRoles = this.model.Customer.businessRoles;
		const hasNonDairyProducerBusinessRole = !businessRoles.includes(BusinessEntityRole.DairyProducer);

		return [
			{
				name: hasNonDairyProducerBusinessRole ? 'Revenues' : 'Non-Milk Revenues',
				linkRoute: 'businesses.business.revenues',
			},
			{
				name: 'Expenses',
				linkRoute: 'businesses.business.expenses',
			},
		];
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your controllers.
declare module '@ember/controller' {
	// eslint-disable-next-line no-unused-vars
	interface Registry {
		'businesses/business/operations': BusinessesBusinessOperations;
	}
}
