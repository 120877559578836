import Service, { service } from '@ember/service';
import { tracked } from '@glimmer/tracking';
import ENV from 'vault-client/config/environment';
import * as Sentry from '@sentry/browser';
import { action } from '@ember/object';
import RouterService from '@ember/routing/router-service';

export default class ErrorHandler extends Service {
	@service declare router: RouterService;
	@tracked showErrorToast = false;
	timeoutId: ReturnType<typeof setTimeout> | null = null;

	handleError(error: Error, redirectToIndex = true) {
		if (ENV.environment === 'production') {
			Sentry.captureException(error);
		} else {
			console.error(error);
		}

		if (redirectToIndex) {
			this.router.transitionTo('index');
		}

		this.toggleError();
	}

	@action
	toggleError() {
		if (this.timeoutId) {
			clearTimeout(this.timeoutId);
		}

		this.showErrorToast = !this.showErrorToast;

		if (this.showErrorToast) {
			this.timeoutId = setTimeout(() => {
				this.showErrorToast = false;
			}, 8000);
		}
	}
}

// DO NOT DELETE: this is how TypeScript knows how to look up your services.
declare module '@ember/service' {
	interface Registry {
		'error-handler': ErrorHandler;
	}
}
