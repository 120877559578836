import { InsurancePolicy as InsurancePolicyInterface } from 'vault-client/types/graphql-types';
export default class InsurancePolicy {
	id;

	agencyName;
	agentFirstName;
	agentLastName;
	policyNumber;

	AIP;
	Organization;
	Owner;
	State;
	programType;

	producerName;

	constructor(policy: InsurancePolicyInterface) {
		this.id = policy.id;

		this.agencyName = policy.agencyName;
		this.agentFirstName = policy.agentFirstName;
		this.agentLastName = policy.agentLastName;
		this.policyNumber = policy.policyNumber;

		this.AIP = policy.AIP;
		this.Organization = policy.Organization;
		this.Owner = policy.Owner;
		this.State = policy.State;
		this.programType = policy.programType;

		this.producerName = policy.producerName;
	}
}
