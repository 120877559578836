import { DateTime } from 'luxon';
import Future from './future';
import Instrument from './instrument';
import { Swap as SwapInterface } from 'vault-client/types/graphql-types';
import { tracked } from '@glimmer/tracking';

export default class Swap extends Instrument {
	@tracked instrumentType = 'Swap';
	@tracked expiresAt: string;
	@tracked displayExpiresAt: string;
	@tracked priceInstrument: Future | null = null;

	constructor(instrument: SwapInterface) {
		super(instrument);

		this.expiresAt = instrument.expiresAt;
		this.displayExpiresAt = instrument.displayExpiresAt;

		if (instrument.PriceInstrument) {
			this.priceInstrument = new Future(instrument.PriceInstrument as any);
		}
	}

	get barchartSymbol() {
		return this.priceInstrument?.barchartSymbol;
	}

	get hasExpired() {
		// This should be > 0, but we don't have exact times for expirations.
		return DateTime.fromISO(this.expiresAt).diffNow().valueOf() < -86400000;
	}
}
