import { gql } from 'glimmer-apollo';
import SearchFilterComponent from 'vault-client/components/vault/ui-search-filter';

const GET_GRAIN_LOCATION = gql`
	query GetGrainLocation($id: String!) {
		GrainLocation(id: $id) {
			id
			name
		}
	}
`;

export default class GrainLocationIdFilterComponent extends SearchFilterComponent {
	query = GET_GRAIN_LOCATION;
	valuePath = 'GrainLocation.name';
}
