import Route from '@ember/routing/route';
import { gql, useQuery } from 'glimmer-apollo';
import { LocationEntity, Query_LocationArgs } from 'vault-client/types/graphql-types';

const GET_LOCATION_QUERY = gql`
	query Location($id: String!) {
		Location(id: $id) {
			id
			name
			createdAt
			updatedAt
			hasWriteAccess
			County {
				id
				name
			}
			Customer {
				id
				name
			}
			Members {
				permissions
				userEmail
				createdAt
				User {
					firstName
					id
					email
					lastName
				}
			}
			GroupEntries {
				id
				Group {
					id
					name
				}
			}
			MilkOrder {
				id
				name
			}
		}
	}
`;

export type GetLocationsQuery = {
	__typename?: 'Query';
	Location: LocationEntity | undefined;
};
export default class BusinessesBusinessLocations extends Route {
	async model() {
		const locationParams = this.paramsFor('locations.location') as { id: string };
		const getLocation = useQuery<GetLocationsQuery, Query_LocationArgs>(this, () => [
			GET_LOCATION_QUERY,
			{ variables: { id: locationParams.id } },
		]);

		await getLocation.promise;
		return getLocation;
	}
}
