import Route from '@ember/routing/route';
import { gql, useQuery } from 'glimmer-apollo';
import { tracked } from 'tracked-built-ins';
import { FeedIngredientFilterDTO, Query } from 'vault-client/types/graphql-types';

// TODO: Add Sellers and Feed Purchases to query
const GET_FEED_PURCHASES = gql`
	query LivestockFeedPurchases($feedIngredientsWhere: FeedIngredientFilterDTO!) {
		FeedIngredients(where: $feedIngredientsWhere) {
			id
			name
		}
	}
`;

export type GetFeedPurchasesQuery = {
	__typename?: 'Query';
	FeedIngredients: Query['FeedIngredients'];
};

type GetFeedPurchasesQueryVariables = {
	feedIngredientsWhere?: FeedIngredientFilterDTO;
};

export default class LivestockFeedPurchasesRoute extends Route {
	templateName = 'livestock-feed/purchases';

	queryParams = {
		startDate: { refreshModel: true },
		endDate: { refreshModel: true },
		selectedVendorId: { refreshModel: true },
		selectedFeedIngredientId: { refreshModel: true },
	};

	@tracked variables: GetFeedPurchasesQueryVariables = {};

	getFeedPurchases = useQuery<GetFeedPurchasesQuery, GetFeedPurchasesQueryVariables>(this, () => [
		GET_FEED_PURCHASES,
		{ variables: this.variables },
	]);

	generateFeedIngredientsWhere(businessId: string | null) {
		const where: FeedIngredientFilterDTO = {};

		if (businessId) {
			where.customerId = { equals: businessId };
		}

		where.FeedIngredientVersion = {
			isCurrent: {
				equals: true,
			},
		};

		return where;
	}
}
