import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import { action } from '@ember/object';
import { gql, useMutation } from 'glimmer-apollo';
import { ApolloError } from '@apollo/client/errors';
import { GrainFeedPlan, Mutation, Mutation_updateGrainFeedPlanArgs } from 'vault-client/types/graphql-types';
import { getInvalidElements, isFormValid } from 'vault-client/utils/form-validation';

const UPDATE_GRAIN_FEED_PLAN = gql`
	mutation UpdateGrainFeedPlan($id: String!, $data: GrainFeedPlanUpdateDTO!) {
		updateGrainFeedPlan(id: $id, data: $data) {
			id
		}
	}
`;

interface EditVgsFeedPlanButtonArgs {
	feedPlan: GrainFeedPlan;
}
export default class EditVgsFeedPlanButton extends Component<EditVgsFeedPlanButtonArgs> {
	@tracked showModal = false;
	@tracked errorMessage: string | null = null;

	updateVgsFeedPlan = useMutation<{ updateGrainFeedPlan: Mutation['updateGrainFeedPlan'] }, Mutation_updateGrainFeedPlanArgs>(this, () => [
		UPDATE_GRAIN_FEED_PLAN,
		{
			onComplete: (): void => {
				this.errorMessage = null;
				this.closeModal();
			},
			update: (cache) => {
				cache.evict({ fieldName: 'GrainPlans' });
				cache.evict({ fieldName: 'GrainFeedPlans' });
				cache.evict({ fieldName: 'GrainFeedPlan' });
			},
			onError: (error: ApolloError): void => {
				this.errorMessage = 'There was an error. Feed Plan did not update.';
				console.error('Error while attempting to edit Feed Plan', error.message);
			},
		},
	]);

	@tracked _tons: string | null = this.args.feedPlan.tons?.toString() ?? null;
	get tons() {
		return this._tons ?? null;
	}
	set tons(tons) {
		this._tons = tons;
	}

	@action
	openModal() {
		this.showModal = true;
	}

	@action
	closeModal() {
		this.showModal = false;
		this.errorMessage = null;
	}

	@action
	async submit() {
		if (isFormValid(document)) {
			const variables: Mutation_updateGrainFeedPlanArgs = {
				id: this.args.feedPlan.id,
				data: {
					tons: this.tons ? parseFloat(this.tons) : undefined,
				},
			};

			this.updateVgsFeedPlan.mutate(variables);
		} else {
			getInvalidElements(document);
		}
	}
}
